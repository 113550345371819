import SvgIcon from '@mui/material/SvgIcon';

export default function CloseIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M12.9792 11.9982L17.4582 7.52284C17.7289 7.25214 17.7289 6.81444 17.4582 6.5455C17.1875 6.2748 16.7498 6.2748 16.4808 6.5455L12.0001 11.0191L7.51948 6.54374C7.24878 6.27304 6.81108 6.27304 6.54214 6.54374C6.27144 6.81444 6.27144 7.25214 6.54214 7.52108L11.021 11.9965L6.54214 16.4736C6.27144 16.7443 6.27144 17.182 6.54214 17.451C6.67749 17.5863 6.85503 17.6531 7.03081 17.6531C7.20835 17.6531 7.38413 17.5863 7.51948 17.451L12.0001 12.9756L16.4808 17.4527C16.6162 17.5881 16.7919 17.6549 16.9695 17.6549C17.147 17.6549 17.3228 17.5881 17.4582 17.4527C17.7289 17.182 17.7289 16.7443 17.4582 16.4754L12.9792 11.9982Z" />
    </SvgIcon>
  );
}



