import { lazy, memo, Suspense, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Local files
import { Fallback as SharesTableFallback } from 'components/Audits/Table/Table';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useShares from 'hooks/useShares';

const SharesTable = lazy(() => import('components/Shares/Table/Table'));

const rowsPerPage = 50;

const Scheduled = ({ tab }) => {
  const offerId = useCustomSelector(state => state.offers.activityLog.id);
  const { offer_id } = useParams();
  const { setError } = useError();
  const { getShares } = useShares();
  const [page, setPage] = useState(0);
  const sharesProps = { page, rowsPerPage };

  const fetchShares = useCallback(({ offer_id, offset, orders }) => {
    getShares({ offer_id, limit: rowsPerPage, offset, orders })
    .catch(e => setError(e));
  }, [getShares, setError]);

  const handlePageChange = p => setPage(p);

  useEffect(() => {
    if (offerId) fetchShares({ offer_id, offset: page * rowsPerPage });
  }, [fetchShares, offer_id, page, offerId]);

  if (tab !== 'scheduled') return null;
  return (
    <Suspense fallback={<SharesTableFallback />}>
      <SharesTable
        {...sharesProps}
        onPageChange={handlePageChange}
      />
    </Suspense>
  );
}

export default memo(Scheduled);