import { memo } from 'react';
import PropTypes from 'prop-types';
// Local files
import image from 'assets/images/banners/user-banner-default.jpg';
import EditIcon from 'components/Common/Icons/EditIcons';
import { Root, StyledButton } from './Banner.styled';
import useApp from 'hooks/useApp';

const Banner = ({ isProfile = false, children, src = '' }) => {
  const { openHeaderDialog } = useApp();
  if (!isProfile) {
    return (
      <Root sx={{ backgroundImage: `url('${src ?? image}')` }}>
        {children}
      </Root>
    );
  }
  return (
    <Root sx={{ backgroundImage: `url('${src ?? image}')` }}>
      {children}
      <StyledButton onClick={openHeaderDialog}>
        <EditIcon />
      </StyledButton>
    </Root>
  );
};

Banner.propTypes = {
  isProfile: PropTypes.bool,
  children: PropTypes.node,
  src: PropTypes.string
};

export default memo(Banner);