import { memo, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
// Local files
import { Root, Content, Text } from './Conversation.styled';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import BaseAvatarGroup from 'components/Common/DataDisplay/BaseAvatarGroup/BaseAvatarGroup';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';
import useConversations from 'hooks/useConversations';
import useError from 'hooks/useError';

const Conversation = ({ conversation_id, type, headline, title, message, timestamp, users, highlighted = false }) => {
  const { getConversation, setLocalUserIds } = useConversations();
  const { setError } = useError();
  const { conversation: { id } } = useCustomSelector(state => state.conversations);
  const { user: { id: my_id } } = useCustomSelector(state => state.profile);
  const [loading, setLoading] = useState(false);

  const handleSelectConversation = () => id !== conversation_id && fetchConversation(conversation_id);
  const fetchConversation = (conversation_id) => {
    setLoading(true);
    setLocalUserIds(null);
    getConversation(conversation_id)
    .catch((e) => setError(e))
    .finally(() => setLoading(false))
  };

  useEffect(() => {
    id === conversation_id && document.getElementById(`chat-${conversation_id}`)?.scrollIntoView(false);
  }, [id, conversation_id]);

  return (
    <Root id={`chat-${conversation_id}`} selected={id === conversation_id} disabled={loading} onClick={handleSelectConversation}>
      <BaseAvatarGroup max={4}>
        {_map(_filter(users, user => user.id !== my_id), user => 
          <BaseAvatar
            key={user?.id}
            src={getRepresentation(user?.photo?.representations, representationVariants.TINY)}
            firstName={user?.first_name}
            lastName={user?.last_name}
          />
        )}
      </BaseAvatarGroup>
      <Content>
        {type === 'offering' && <Text>{headline}</Text>}
        <Text highlighted={highlighted}>{title}</Text>
        <Box sx={{ display: 'flex' }}>
          <Text highlighted={highlighted} sx={{ flexGrow: 1 }}>{message?.text}</Text>
          <Text sx={{ flexShrink: 0 }}>{timestamp}</Text>
        </Box>
      </Content>
    </Root>
  );
};

export default memo(Conversation);