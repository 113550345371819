import styled from '@mui/material/styles/styled';

export const Root = styled('div')({
  '& .tox-statusbar': {
    borderTop: 'none !important'
  },
  '& .tox-sidebar-wrap': {
    paddingTop: 25,
    paddingBottom: 5
  },
});