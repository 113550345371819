import { memo } from 'react';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
// Local files
import { Root, Content, Text, StyledBadge } from './Conversation.styled';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import BaseAvatarGroup from 'components/Common/DataDisplay/BaseAvatarGroup/BaseAvatarGroup';
import useConversations from 'hooks/useConversations';
import { getRepresentation } from 'helpers';
import { representationVariants } from 'helpers/constants';

const Conversation = ({ conversation, my_id }) => {
  const { setLocalConversation } = useConversations();
  const isUnread = conversation?.last_message !== null && conversation?.last_message?.user?.id !== my_id && !conversation?.last_message?.read;

  const handleSelectConversation = () => setLocalConversation(conversation);

  return (
    <Root id={`chat-${conversation?.id}`} highlighted={isUnread} onClick={handleSelectConversation}>
      <StyledBadge variant='dot' color='success' overlap="circular" invisible={!isUnread} >
        <BaseAvatarGroup max={4}>
          {_map(_filter(conversation?.users, (u) => u.id !== my_id), user =>
            <BaseAvatar
              key={user?.id}
              src={getRepresentation(user?.photo?.representations, representationVariants.TINY)}
              firstName={user?.first_name}
              lastName={user?.last_name}
            />
          )}
        </BaseAvatarGroup>
      </StyledBadge>
      <Content>
        <Text highlighted={isUnread}>{conversation?.last_message?.user?.first_name} {conversation?.last_message?.user?.last_name}</Text>
        <Text highlighted={isUnread} sx={{ flexGrow: 1 }}>{conversation?.last_message?.text}</Text>
      </Content>
      <Text sx={{ flexShrink: 0, color: '#7A7A7A' }}>{new Date(conversation?.updated_at).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</Text>
    </Root>
  );
};

export default memo(Conversation);