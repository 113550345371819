import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';

export const Folder = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  width: 185,
  minHeight: 140,
  position: 'relative',
  display: 'grid',
  '> *': {
    gridArea: '1/-1'
  },
  borderRadius: '4px',
  overflow: 'hidden',
  color: theme.palette.text.primary,
  background: 'linear-gradient(360deg, #EBF5FF 0%, rgba(235, 245, 255, 0) 100%)',
  border: '0.5px solid #0000001A',
  boxShadow: '3px 3px 5px -2px #020D174D'
}));

export const FolderContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%'
}));

export const FolderName = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0px',
  color: 'rgba(0, 0, 0, 0.8)'
}));

export const FolderInfo = styled(Typography)(() => ({
  fontSize: '10px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0px',
  color: 'rgba(0, 0, 0, 0.8)'
}));

export const StyledCheckbox = styled(Checkbox)(() => ({
  alignSelf: 'start',
  justifySelf: 'start',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  '&.Mui-checked': {
    background: 'rgba(0,0,0,.3)'
  }
}));

export const DeleteButton = styled(IconButton)({
  alignSelf: 'start',
  justifySelf: 'end',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
});

export const BlurredPlaceholder = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
  background: 'rgba(0, 0, 0, 0.5)',
  backdropFilter: 'blur(7px)',
  WebkitBackdropFilter: 'blur(7px)'
}));