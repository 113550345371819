import { memo } from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

/**
 * @param {number} days
 * @param {string=} helperText
 * @param {func} onClick
 * @returns Node
 */

const CheckboxLabel = ({ days, helperText }) => {
  return (
    <Typography sx={{ width: '100%', display: 'block', fontSize: ' .75rem', position: 'relative' }}>
      <b>{days} business days</b> after first send,
      {helperText &&
        <Typography sx={{ position: 'absolute', bottom: '-10px', left: 0, fontSize: '10px', lineHeight: 1, color: '#000' }}>
          {helperText}
        </Typography>
      }
    </Typography>
  );
};

CheckboxLabel.propTypes = {
  days: PropTypes.number.isRequired,
  helperText: PropTypes.string
};

export default memo(CheckboxLabel);