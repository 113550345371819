import { memo, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import _isUndefined from 'lodash/isUndefined';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import Uploader from 'components/Blobs/Uploader/Uploader';
import useApp from 'hooks/useApp';
import useError from 'hooks/useError';
import useProfile from 'hooks/useProfile';
import useSuccess from 'hooks/useSuccess';
import useCustomSelector from 'hooks/useCustomSelector';

const EditAvatar = () => {
  const { closeAvatarDialog } = useApp();
  const { updateProfile } = useProfile();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { open } = useCustomSelector(state => state.app.avatarDialog);
  const [avatar, setAvatar] = useState({ value: null, formattedValue: null });
  const [processing, setProcessing] = useState(false);

  const handleSubmit = () => {
    if (!!avatar.value) {
      const user = { photo: avatar.value, localPhoto: avatar.formattedValue };

      setProcessing(true);
      updateProfile(user)
      .then(() => setSuccess('Avatar successfully changed').then(closeAvatarDialog))
      .catch(e => setError(e))
      .finally(() => setProcessing(false))
    } else {
      closeAvatarDialog();
    }
  };
  const handleExited = () => setAvatar({ value: null, formattedValue: null });

  return (
    <BaseDialog
      open={open}
      onClose={closeAvatarDialog}
      otherPaperStyle={{ width: '100%', maxWidth: 360 }}
      otherContentStyle={{ padding: '24px 32px 56px !important' }}
      TransitionProps={{ onExited: handleExited }}
      title='Change Avatar'
      content={
        <Box sx={{ width: 138, margin: '0 auto' }}>
          <Uploader
            maxWidth={1000}
            maxHeight={350}
            id='userAvatar'
            fullWidth
            accept='image/*'
            label='Select File'
            formattedValue={avatar.formattedValue}
            onChange={({ newValue, newFormattedValue }) =>
              setAvatar(prev => ({
                ...prev,
                ...(!_isUndefined(newValue) && { value: newValue }),
                ...(!_isUndefined(newFormattedValue) && { formattedValue: newFormattedValue })
              }))
            }
            onDelete={() => setAvatar({ value: null, formattedValue: null })}
          />
        </Box>
      }
      actions={
        <>
          <Button
            sx={{ px: '20px', minHeight: '37px' }}
            color='error'
            disabled={processing}
            onClick={closeAvatarDialog}
          >
            Cancel
          </Button>
          <Button
            sx={{ px: '30px' }}
            variant='contained'
            color='primary'
            disabled={processing}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </>
      }
    />
  );  
};

export default memo(EditAvatar);