import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getDealspacePropertiesList as getDealspacePropertiesListAction,
  getDealspaceProperties as getDealspacePropertiesAction,
  getOfferProperties as getOfferPropertiesAction,
  getProperty as getPropertyAction,
  createDealspaceProperty as createDealspacePropertyAction,
  createOfferProperty as createOfferPropertyAction,
  updateProperty as updatePropertyAction,
  deleteProperty as deletePropertyAction,
  clearLocalDealspacePropertiesList as clearLocalDealspacePropertiesListAction,
  clearLocalDealspaceProperties as clearLocalDealspacePropertiesAction,
  clearLocalOfferProperties as clearLocalOfferPropertiesAction,

  clearLocalActiveProperty as clearLocalActivePropertyAction
} from 'actions/properties';

const useProperties = () => {
  const dispatch = useDispatch();
  const getDealspacePropertiesList = useCallback(({ dealspace_id, offset }) => dispatch(getDealspacePropertiesListAction({ dealspace_id, offset })), [dispatch]);
  const getDealspaceProperties = useCallback(({ dealspace_id, offset }) => dispatch(getDealspacePropertiesAction({ dealspace_id, offset })), [dispatch]);
  const getOfferProperties = useCallback(({ offer_id, offset }) => dispatch(getOfferPropertiesAction({ offer_id, offset })), [dispatch]);
  const getProperty = useCallback(id => dispatch(getPropertyAction(id)), [dispatch]);
  const createDealspaceProperty = useCallback(({ dealspace_id, property }) => dispatch(createDealspacePropertyAction({ dealspace_id, property })), [dispatch]);
  const createOfferProperty = useCallback(({ offer_id, property_id, property }) => dispatch(createOfferPropertyAction({ offer_id, property_id, property })), [dispatch]);
  const updateProperty = useCallback((property) => dispatch(updatePropertyAction(property)), [dispatch]);
  const deleteProperty = useCallback(({ id, reason }) => dispatch(deletePropertyAction({ id, reason })), [dispatch]);
  const clearLocalDealspacePropertiesList = useCallback(() => dispatch(clearLocalDealspacePropertiesListAction()), [dispatch]);
  const clearLocalDealspaceProperties = useCallback(() => dispatch(clearLocalDealspacePropertiesAction()), [dispatch]);
  const clearLocalOfferProperties = useCallback(() => dispatch(clearLocalOfferPropertiesAction()), [dispatch]);
  const clearLocalActiveProperty = useCallback(() => dispatch(clearLocalActivePropertyAction()), [dispatch]);

  return {
    getDealspacePropertiesList,
    getDealspaceProperties,
    getOfferProperties,
    getProperty,
    createDealspaceProperty,
    createOfferProperty,
    updateProperty,
    deleteProperty,
    clearLocalActiveProperty,
    clearLocalDealspacePropertiesList,
    clearLocalDealspaceProperties,
    clearLocalOfferProperties
  };
};

export default useProperties;