import {
  getPropertyDocumentsRoutine,
  getPropertyDocumentsAmountRoutine,
  getDealspaceDocumentsRoutine,
  getFolderDocumentsRoutine,
  getBuilderPublicDocumentsRoutine,
  getBuilderPrivateLevel1DocumentsRoutine,
  getBuilderPrivateLevel2DocumentsRoutine,
  getOfferPublicDocumentsRoutine,
  getOfferPrivateLevel1DocumentsRoutine,
  getOfferPrivateLevel2DocumentsRoutine,
  getPreviewPublicDocumentsRoutine,
  getDocumentRoutine,
  createPropertyDocumentRoutine,
  createDealspaceDocumentRoutine,
  createOfferDocumentRoutine,
  updateDocumentRoutine,
  deleteDocumentRoutine,
  clearLocalPropertyDocumentsRoutine,
  clearLocalDealspaceDocumentsRoutine,
  clearLocalFolderDocumentsRoutine,
  clearLocalOfferDocumentsRoutine,
  clearLocalActiveDocumentRoutine
} from 'actions';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _dropRight from 'lodash/dropRight';
import _map from 'lodash/map';

export const initialState = {
  property: {
    data: [],
    pagination: { limit: 25, offset: 0, count: 0, total_count: 0 }
  },
  dealspace: {
    data: [],
    pagination: { limit: 25, offset: 0, count: 0, total_count: -1 },
    fetching: true
  },
  folder: {
    data: [],
    pagination: { limit: 25, offset: 0, count: 0, total_count: -1 },
    fetching: true
  },
  offer: {
    public: {
      data: [],
      pagination: { limit: 25, offset: 0, count: 0, total_count: 0 },
      fetching: true
    },
    level1: {
      data: [],
      pagination: { limit: 25, offset: 0, count: 0, total_count: 0 },
      fetching: true
    },
    level2: {
      data: [],
      pagination: { limit: 25, offset: 0, count: 0, total_count: 0 },
      fetching: true
    }
  },
  document: { id: '', user: null, file: null, name: '' }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getPropertyDocumentsRoutine.SUCCESS: {
      const { data: { documents, pagination } } = action.payload;

      return { ...state, property: { data: [...state.property.data, ...documents], pagination } };
    }
    case getPropertyDocumentsAmountRoutine.SUCCESS: {
      const { data: { pagination } } = action.payload;

      return { ...state, property: { ...state.property, pagination } };
    }
    case getDealspaceDocumentsRoutine.REQUEST: {
      const dealspace = { ...state.dealspace, fetching: true };

      return { ...state, dealspace };
    }
    case getDealspaceDocumentsRoutine.SUCCESS: {
      const { data: { documents, pagination } } = action.payload;
      const dealspace = { data: [...state.dealspace.data, ...documents], pagination, fetching: false };

      return { ...state, dealspace };
    }
    case getDealspaceDocumentsRoutine.FAILURE: {
      const dealspace = { ...state.dealspace, fetching: false };

      return { ...state, dealspace };
    }
    case getFolderDocumentsRoutine.REQUEST: {
      const folder = { ...state.folder, fetching: true };

      return { ...state, folder };
    }
    case getFolderDocumentsRoutine.SUCCESS: {
      const { data: { documents, pagination } } = action.payload;
      const folder = { data: [...state.folder.data, ...documents], pagination, fetching: false };

      return { ...state, folder };
    }
    case getFolderDocumentsRoutine.FAILURE: {
      const folder = { ...state.folder, fetching: false };

      return { ...state, folder };
    }
    case getBuilderPublicDocumentsRoutine.SUCCESS: {
      return state;
    }
    case getBuilderPrivateLevel1DocumentsRoutine.SUCCESS: {
      return state;
    }
    case getBuilderPrivateLevel2DocumentsRoutine.SUCCESS: {
      return state;
    }
    case getOfferPublicDocumentsRoutine.REQUEST: {
      const offer = { ...state.offer, public: { ...state.offer.public, fetching: true } };

      return { ...state, offer };
    }
    case getOfferPublicDocumentsRoutine.SUCCESS: {
      const { data: { documents, pagination } } = action.payload;
      const data = [...state.offer.public.data, ...documents];
      const offer = { ...state.offer, public: { data, pagination, fetching: false } };

      return { ...state, offer };
    }
    case getOfferPublicDocumentsRoutine.FAILURE: {
      const offer = { ...state.offer, public: { ...state.offer.public, fetching: false } };

      return { ...state, offer };
    }
    case getOfferPrivateLevel1DocumentsRoutine.REQUEST: {
      const offer = { ...state.offer, level1: { ...state.offer.level1, fetching: true } };

      return { ...state, offer };
    }
    case getOfferPrivateLevel1DocumentsRoutine.SUCCESS: {
      const { data: { documents, pagination } } = action.payload;
      const data = [...state.offer.level1.data, ...documents];
      const offer = { ...state.offer, level1: { data, pagination, fetching: false } };

      return { ...state, offer };
    }
    case getOfferPrivateLevel1DocumentsRoutine.FAILURE: {
      const offer = { ...state.offer, level1: { ...state.offer.level1, fetching: false } };

      return { ...state, offer };
    }
    case getOfferPrivateLevel2DocumentsRoutine.REQUEST: {
      const offer = { ...state.offer, level2: { ...state.offer.level2, fetching: true } };

      return { ...state, offer };
    }
    case getOfferPrivateLevel2DocumentsRoutine.SUCCESS: {
      const { data: { documents, pagination } } = action.payload;
      const data = [...state.offer.level2.data, ...documents];
      const offer = { ...state.offer, level2: { data, pagination, fetching: false } };

      return { ...state, offer };
    }
    case getOfferPrivateLevel2DocumentsRoutine.FAILURE: {
      const offer = { ...state.offer, level2: { ...state.offer.level2, fetching: false } };

      return { ...state, offer };
    }
    case getPreviewPublicDocumentsRoutine.SUCCESS: {
      return state;
    }
    case getDocumentRoutine.SUCCESS: {
      const { data: { document } } = action.payload;
      const updatedDocument = { ...state.document, ...document };
      const propertyData = _map(state.property.data, item => item.id === document.id ? { ...item, ...document } : item);
      const dealspaceData = _map(state.dealspace.data, item => item.id === document.id ? { ...item, ...document } : item);

      return { ...state, document: updatedDocument, property: { ...state.property, data: propertyData }, dealspace: { ...state.dealspace, data: dealspaceData } };
    }
    case createPropertyDocumentRoutine.SUCCESS: {
      const { data: { document } } = action.payload;
      const oldPropertyData = state.property.pagination.total_count > 24 ? _dropRight(state.property.data) : state.property.data; 
      const data = [...[document], ...oldPropertyData];
      const pagination = { ...state.property.pagination, count: state.property.pagination.count + 1, total_count: state.property.pagination.total_count + 1 };

      return { ...state, property: { data, pagination } };
    }
    case createDealspaceDocumentRoutine.SUCCESS: {
      const { response: { data: { document } }, skip } = action.payload;
      
      if (skip) return state;

      const oldFolderDealspaceData = state.folder.pagination.total_count > 24 ? _dropRight(state.folder.data) : state.folder.data; 
      const folderData = [...[document], ...oldFolderDealspaceData];
      const folderPagination = { ...state.folder.pagination, count: state.folder.pagination.count + 1, total_count: state.folder.pagination.total_count + 1 };
      const folder = { data: folderData, pagination: folderPagination };
      const oldDealspaceData = state.dealspace.pagination.total_count > 24 ? _dropRight(state.dealspace.data) : state.dealspace.data; 
      const dealspaceData = [...[document], ...oldDealspaceData];
      const dealspacePagination = { ...state.dealspace.pagination, count: state.dealspace.pagination.count + 1, total_count: state.dealspace.pagination.total_count + 1 };
      const dealspace = { data: dealspaceData, pagination: dealspacePagination };

      return { ...state, folder, dealspace };
    }
    case createOfferDocumentRoutine.SUCCESS: {
      return state;
    }
    case updateDocumentRoutine.SUCCESS: {
      const { response: { status }, document } = action.payload;
      const updatedDocument = { ...state.document, ...document };
      const propertyData = _map(state.property.data, (item) => item.id === document.id ? { ...item, ...document } : item);
      const dealspaceData = _map(state.dealspace.data, (item) => item.id === document.id ? { ...item, ...document } : item);

      return {
        ...state, ...(status === 204 && {
          document: updatedDocument,
          property: { ...state.property, data: propertyData },
          dealspace: { ...state.dealspace, data: dealspaceData }
        }),
      };
    }
    case deleteDocumentRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const foundPropertyDocument = !!_find(state.property.data, d => d.id === id);
      const propertyData = foundPropertyDocument ? _filter(state.property.data, item => item.id !== id) : state.property.data;
      const propertyPagination = { ...state.property.pagination, count: state.property.pagination.count - 1, total_count: state.property.pagination.total_count - 1 };
      const foundDealspaceDocument = !!_find(state.dealspace.data, d => d.id === id);
      const dealspaceData = foundDealspaceDocument ? _filter(state.dealspace.data, item => item.id !== id) : state.dealspace.data;
      const dealspacePagination = { ...state.dealspace.pagination, count: state.dealspace.pagination.count - 1, total_count: state.dealspace.pagination.total_count - 1 };
      const foundFolderDocument = !!_find(state.folder.data, d => d.id === id);
      const folderData = foundFolderDocument ? _filter(state.folder.data, item => item.id !== id) : state.folder.data;
      const folderPagination = { ...state.folder.pagination, count: state.folder.pagination.count - 1, total_count: state.folder.pagination.total_count - 1 };

      return {
        ...state,
        ...(status === 204 && foundPropertyDocument && { property: { data: propertyData, pagination: propertyPagination } }),
        ...(status === 204 && foundDealspaceDocument && { dealspace: { data: dealspaceData, pagination: dealspacePagination } }),
        ...(status === 204 && foundFolderDocument && { folder: { data: folderData, pagination: folderPagination } })
      };
    }
    case clearLocalPropertyDocumentsRoutine.SUCCESS: {
      return { ...state, property: initialState.property }
    }
    case clearLocalDealspaceDocumentsRoutine.SUCCESS: {
      return { ...state, dealspace: initialState.dealspace };
    }
    case clearLocalFolderDocumentsRoutine.SUCCESS: {
      return { ...state, folder: initialState.folder };
    }
    case clearLocalOfferDocumentsRoutine.SUCCESS: {
      return { ...state, offer: initialState.offer };
    }
    case clearLocalActiveDocumentRoutine.SUCCESS: {
      return { ...state, document: initialState.document };
    }
    default: {
      return state;
    }
  };
};

export default reducer;