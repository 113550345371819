import styled from '@mui/material/styles/styled';
import ButtonBase from '@mui/material/ButtonBase';

export const Root = styled('div')({
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Image = styled('img')({
  minHeight: 80,
  maxHeight: 200,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  maxWidth: 600,
  display: 'block',
  userSelect: 'none',
});

export const StyledButton = styled(ButtonBase)({
  position: 'absolute',
  bottom: 12,
  right: 18,
  width: 40,
  height: 40,
  borderRadius: '50%',
  backgroundColor: '#fff',
  boxShadow: '0px 0px 7px 0px #0000001A',
})

export const FileInput = styled('input')({ display: 'none' });