import { memo, useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import { useLocation, useNavigate } from 'react-router-dom';
import _startsWith from 'lodash/startsWith';
import _includes from 'lodash/includes';
//Local files
import { Root, Wrapper, Paper, Name, Text, Img, DocPlaceholder, Time, Status, Details } from './Message.styled';
import { ReactComponent as SendedIcon } from 'assets/icons/msg-sended.svg';
import { ReactComponent as ReadedIcon } from 'assets/icons/msg-readed.svg';
import { ReactComponent as DocIcon } from 'assets/icons/filetype-doc.svg';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import ErrorIcon from 'components/Common/Icons/ErrorIcon';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useOnScreen from 'hooks/useOnScreen';

const Message = ({ message: { id, text, attachment, time: msgTime, read, user, created_at, hasError = false }, readMessage }) => {
  const { setViewAttachment, setChatsFilter } = useApp();
  const backgroundLocation = useLocation();
  const navigate = useNavigate();
  const messageRef = useRef(null);
  const isVisible = useOnScreen(messageRef);
  const { user: { id: user_id } } = useCustomSelector(state => state.profile);
  const own = user?.id === user_id;
  const time = msgTime || new Date(created_at).toLocaleString('en-US', { weekday: 'short', day: '2-digit', month: 'short', hour: 'numeric', minute: 'numeric', hour12: true });

  useEffect(() => {
    if (isVisible && user?.id !== user_id && !read) {
      readMessage(id);
    }
  }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAttachmentClick = (file) => setViewAttachment({ file }).then(() => navigate(`/attachments/${id}`, { state: { backgroundLocation } }));
  const handleUserProfileOpen = () => setChatsFilter({ filter: { dealspace_id: '', offer_id: '' } }).then(() => navigate(`/users/${user.username}`));

  return (
    <Root id={`message-${id}`} ref={messageRef} own={own}>
      <Wrapper own={own}>
        <Paper own={own}>
          {!own && (
            <ButtonBase onClick={handleUserProfileOpen}>
              <Name>{user.first_name} {user.last_name}</Name>
            </ButtonBase>
          )}
          <Text own={own}>{text}</Text>
          {!!attachment && (_startsWith(attachment?.content_type, 'image/') ?
            <Img src={_includes(attachment?.content_type, 'gif') ? attachment?.url : getRepresentation(attachment?.representations, representationVariants.TINY)} onClick={() => handleAttachmentClick(attachment)} alt='attachment' data-private /> :
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'hidden' }} >
              <DocPlaceholder onClick={() => handleAttachmentClick(attachment)}><DocIcon /></DocPlaceholder>
              <Typography variant='caption' component='div' color='textPrimary' noWrap sx={{ flexGrow: 1, fontWeight: 600 }}>{attachment?.filename}</Typography>
            </Box>
          )}
        </Paper>
        <Details>
          {own && (
            <Status>
              { !hasError && read ? <ReadedIcon /> : time === 'pending' ? <CircularProgress size={14} /> : <SendedIcon />}
              { hasError && <ErrorIcon  color='error' sx={{ marginBottom: '-5px'}} /> }
            </Status>
          )}
          {time !== 'pending' && <Time own={own}>{time}</Time> }
        </Details>
      </Wrapper>
      {!own && (
        <ButtonBase onClick={handleUserProfileOpen}>
          <BaseAvatar
            firstName={user.first_name}
            lastName={user.last_name}
            src={getRepresentation(user?.photo?.representations, representationVariants.TINY)}
            on
          />
        </ButtonBase>
      )}
    </Root>
  );
};
const WrappedComponent = (props) => <ErrorWrapper children={<Message {...props} />} />

export default memo(WrappedComponent);