import { memo } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _slice from 'lodash/slice';
//Local files
import { AdditionalContent, AddUser, BackButton, CloseButton, Content, PreviewImage, Root, StyledAvatarGroup, StyledBadge, StyledLink, Title, TypeLabel } from './Header.styled';
import { ReactComponent as AddUserIcon } from 'assets/icons/add-users.svg';
import CloseIcon from 'components/Common/Icons/CloseIcon';
import ForwardIcon from 'components/Common/Icons/ForwardIcon';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import useConversations from 'hooks/useConversations';
import useApp from 'hooks/useApp';
import { getRepresentation } from 'helpers';
import { representationVariants, chatLevels } from 'helpers/constants';

const ChatHeader = ({ 
  users, senders, user_ids, cover, tag, headline, dealspace, conversation, my_id, isMultiple, 
  isNoConversations, isMyShared, unreadConversationsLength, onClose, showMessages, onShowMessages
}) => {
  const navigate = useNavigate();
  const previousLocation = useLocation();
  const { offer_id } = useParams();
  const { setChatsFilter } = useApp();
  const { setLocalUserIds, clearLocalActiveConversation } = useConversations();

  const showNoConversationsTitle = !!!conversation.id && isNoConversations && isMultiple && user_ids !== null && !showMessages;
  const showHaveConversationsTitle = (!!conversation.id || !isNoConversations) && user_ids !== null && !showMessages;
  const showConversationUsers = (!!conversation.id && user_ids === null) || (!!user_ids?.length && showMessages);
  const showBackButton = (!!conversation.id || showMessages) && isMultiple;
  const showChangeChat = (!!conversation.id || showMessages) && senders.length > 1;
  const showAllChats = isMultiple && !!!conversation.id && !isNoConversations && user_ids === null;
  const filteredUsers = !!user_ids?.length ? _filter(senders, (s) => !!_find(user_ids, (u) => s.id === u)) : users;

  const handleOpenAllChats = () => {
    onClose();
    setChatsFilter({ filter: { offer_id } })
    .then(() => 
      navigate(`/chats/${isMyShared ? chatLevels.GROUP : chatLevels.USER}/${isMyShared ? dealspace?.group?.id : my_id}`, { state: { previousLocation } }
    ));
  };
  const handleChangeChat = () => isMultiple && setLocalUserIds(_map(users, (u) => u.id)).then(() => onShowMessages(false));
  const handleBack = () => clearLocalActiveConversation().then(() => setLocalUserIds(isNoConversations ? _map(senders, (s) => s.id) : null).then(() => onShowMessages(false)))

  return (
    <Root>
      <CloseButton onClick={onClose}><CloseIcon /></CloseButton>
      {isNoConversations && <Typography variant='subtitle1' sx={{ lineHeight: 1 }}>Chat now</Typography>}
      <Content>
        {!!cover && <PreviewImage sx={{ backgroundImage: `url('${getRepresentation(cover?.file?.representations, representationVariants.TINY)}')` }} />}
        <Box>
          <TypeLabel>{tag}</TypeLabel>
          <Title>{headline}</Title>
        </Box>
      </Content>
      {showConversationUsers && <AdditionalContent isEnd={!isMultiple || !showBackButton}>
        {showBackButton && <BackButton onClick={handleBack}><ForwardIcon />Back</BackButton>}
        <StyledAvatarGroup onClick={handleChangeChat}>
          {_map(_slice(filteredUsers, 0, 3), (user, index) =>
            <BaseAvatar
              key={user.id}
              sx={{ height: 24, width: 24, fontSize: '12px', zIndex: index }}
              src={getRepresentation(user?.photo?.representations, representationVariants.TINY)}
              firstName={user?.first_name}
              lastName={user?.last_name}
            />
          )}
          {filteredUsers.length > 3 && <Avatar sx={{ zIndex: users.length + 1 }}>+{filteredUsers.length - 3}</Avatar>}
          {showChangeChat && <AddUser sx={{ zIndex: users.length + 2 }}><AddUserIcon/></AddUser>}
        </StyledAvatarGroup>
      </AdditionalContent>}
      {showNoConversationsTitle && <Typography variant='subtitle3'>Who should be in the chat?</Typography>}
      {showHaveConversationsTitle && <Typography variant='subtitle3'>Select available participants:</Typography>}
      { showAllChats && <AdditionalContent isEnd={!isMultiple}>
        <StyledBadge badgeContent={unreadConversationsLength} color='success' overlap='circular'><Typography variant='subtitle3'>Existing chats</Typography></StyledBadge>
        <StyledLink onClick={handleOpenAllChats}>All chats</StyledLink>
      </AdditionalContent>}
    </Root>
  )
}

export default memo(ChatHeader);