import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getSharedListsRoutine,
  getMyListsRoutine,
  getListRoutine,
  createListRoutine,
  updateListRoutine,
  deleteListRoutine,
  clearLocalSharedListsRoutine,
  clearLocalMyListsRoutine,
  clearLocalActiveListRoutine
} from 'actions';
import _omit from 'lodash/omit';

const attributes = [
  'id',
  { user: ['id', 'first_name', 'last_name'] },
  { groups: ['id', { logo: ['representations'] }, 'name'] },
  'name',
  'default',
  'contacts_count'
];

export const getSharedLists = getThunkActionCreator(
  getSharedListsRoutine,
  async ({ limit = 25, offset = 0, orders = { updated_at: 'desc' }, group_id }) => {
    const params = { limit, offset, orders, attributes };

    return await axios.get(`/groups/${group_id}/lists`, { params });
  }
);
export const getMyLists = getThunkActionCreator(
  getMyListsRoutine,
  async ({ limit = 25, offset = 0, orders = { updated_at: 'desc' }, query = null }) => {
    const params = { limit, offset, orders, attributes, my: true, ...query && { query } };

    return await axios.get('/lists', { params });
  }
);
export const getList = getThunkActionCreator(
  getListRoutine,
  async id => {
    getList.getListCancel && getList.getListCancel();
    const params = { attributes };

    return await axios.get(`/lists/${id}`, { params });
  }
);
export const createList = getThunkActionCreator(
  createListRoutine,
  async list => {
    const data = { list, attributes };
    
    return await axios.post('/lists', data);
  }
);
export const updateList = getThunkActionCreator(
  updateListRoutine,
  async ({ id, ...fields }) => {
    const response = await axios.patch(`/lists/${id}`, { list: _omit(fields, 'localGroups') });

    return { response, list: { id, ...fields } };
  }
);
export const deleteList = getThunkActionCreator(
  deleteListRoutine,
  async id => {
    const response = await axios.delete(`/lists/${id}`);

    return { response, id };
  }
);
export const clearLocalSharedLists = getThunkActionCreator(clearLocalSharedListsRoutine, async () => {});
export const clearLocalMyLists = getThunkActionCreator(clearLocalMyListsRoutine, async () => {});
export const clearLocalActiveList = getThunkActionCreator(clearLocalActiveListRoutine, async () => {});