import { memo, useState } from 'react';
import Popover from '@mui/material/Popover';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// Local files
import { Banner, Description, Main, StyledButton, Title, ListItemIcon, ListItemText, ListItem } from './ProfileMenu.styled';
import AgreementsIcon from 'components/Common/Icons/AgreementsIcon';
import SettingsIcon from 'components/Common/Icons/SettingsIcon';
import LogoutIcon from 'components/Common/Icons/LogoutIcon';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import AccountSettings from '../../Dialogs/AccountSettings/AccountSettings';
import { getRepresentation } from 'helpers';
import { representationVariants } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useSessions from 'hooks/useSessions';
import useStore from 'hooks/useStore';
import useProfile from 'hooks/useProfile';

const avatarStyles = {
  width: 56,
  height: 56,
  fontSize: '24px',
  borderRadius: '50%',
  border: '1px solid #fff',
  marginTop: '-28px'
};

const ProfileMenu = ({ anchorEl, onClose }) => {
  const navigate = useNavigate();
  const { setAuthData } = useApp();
  const { deleteSession } = useSessions();
  const { clearStore } = useStore();
  const { clearLocalProfile } = useProfile();
  const { setError } = useError();
  const { user: { username, first_name, last_name, photo, header, email }, abortController } = useCustomSelector(state => ({
    user: state.profile.user,
    abortController: state.app.abortController
  }));
  const [processing, setProcessing] = useState(false);
  const [openAccountSettings, setOpenAccountSettings] = useState(false);

  const handleProfileClick = () => {
    onClose();
    // navigate(`/users/${id}`);
  };
  const handleAgreementsClick = () => {
    onClose();
    navigate('/signed_gate_agreements');
  };
  const handleLogoutClick = () => {
    onClose();
    setProcessing(true);
    clearLocalProfile()
    .then(() =>
      deleteSession(abortController)
      .then(() => clearStore().then(() => setAuthData({ open: true }).then(() => navigate('/sign_in'))))
      .catch(e => setError(e).then(() => setProcessing(false)))
    );
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
      PaperProps={{ variant: 'popup', color: 'dark', sx: { width: 260, maxWidth: 260, backgroundColor: '#33373B', mt: '8px', } }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Banner sx={{ backgroundImage: `url('${getRepresentation(header?.representations, representationVariants.SMALL)}')` }} />
      <Main>
        <BaseAvatar
          src={getRepresentation(photo?.representations, representationVariants.TINY) ?? photo?.representations[0]?.url}
          firstName={first_name}
          lastName={last_name}
          sx={avatarStyles}
        />
        <Title>{first_name} {last_name}</Title>
        <Description>{email}</Description>
        <StyledButton component={NavLink} to={`/users/${username}`} onClick={handleProfileClick}>Go to my Profile</StyledButton>
      </Main>
      <List sx={{p: '6px 0 16px'}}>
        <ListItem>
          <ListItemButton disabled={processing} onClick={() => setOpenAccountSettings(true)}>
            <ListItemIcon>
              <SettingsIcon sx={{ width: 18 }} />
            </ListItemIcon>
            <ListItemText primary='Account Settings' />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton disabled={processing} onClick={handleAgreementsClick}>
            <ListItemIcon>
              <AgreementsIcon />
            </ListItemIcon>
            <ListItemText primary='Signed Gate Agreements' />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton disabled={processing} onClick={handleLogoutClick}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary='Logout' />
          </ListItemButton>
        </ListItem>
      </List>
      <AccountSettings open={openAccountSettings} handleClose={() => setOpenAccountSettings(false)}/>
    </Popover>
  );
};

export default memo(ProfileMenu);