import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import { createResource as createResourceAction } from 'actions/resources';

const useResources = () => {
  const dispatch = useDispatch();
  const createResource = useCallback(({ resource, offer_id }) => dispatch(createResourceAction({ resource, offer_id })), [dispatch]);

  return { createResource };
};

export default useResources;