import { memo, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
// Local files
import { Root, Header, Content, Type, Title, Actions  } from './ContentAccordion.styled';
import FormSelect from 'components/Common/Inputs/FormSelect/FormSelect';
import Uploader from 'components/Blobs/Uploader/Uploader';
import FileContent from './FileContent/FileContent';
import DateField from 'components/Common/Inputs/DateField/DateField';
import { contentTypes } from 'helpers/constants';
import { getFormattedDate } from 'helpers';

const now = new Date();

const ContentAccordion = ({ data, edit = false, onChange, onDelete }) => {
  const [type, setType] = useState('');
  const [title, setTitle] = useState('');
  const [publishedAt, setPublishedAt] = useState(now.toISOString());
  const [switcher, setSwitcher] = useState('file');
  const [url, setUrl] = useState('');
  const [file, setFile] = useState({ value: null, formattedValue: null });
  const [avatar, setAvatar] = useState({ value: null, formattedValue: null });
  const [open, setOpen] = useState(false);
  const disabled = switcher === 'file' ? !type || !title || !file.value : !type || !title || !url;

  const handleFileContentChange = ({ switcherValue, urlValue, fileValue }) => {
    if (!_isUndefined(switcherValue)) {
      if (switcherValue === 'file') setUrl('');
      if (switcherValue === 'link') setFile({ value: null, formattedValue: null });
      setSwitcher(switcherValue);
    }
    !_isUndefined(urlValue) && setUrl(urlValue);
    !_isUndefined(fileValue) && setFile(fileValue);
  };
  const handleSubmitClick = () => {
    const value = {
      content_type: type,
      title,
      published_at: publishedAt,
      ...(!!url && { url }),
      ...(!!file.value && { file: file.value }),
      ...(!!avatar.value && { avatar: avatar.value })
    };
    const formattedValue = {
      content_type: type,
      title,
      published_at: publishedAt,
      ...(!!url && { url }),
      ...(!!file.value && { file: file.formattedValue }),
      ...(!!avatar.value && { avatar: avatar.formattedValue })
    };

    onChange({ value, formattedValue });
    setOpen(false);
  };
  
  useEffect(() => {
    if (edit) {
      setType(data.content_type);
      setTitle(data.title);
      setPublishedAt(data.published_at);

      !!data.avatar && setAvatar({ value: data.avatar.id, formattedValue: data.avatar });
  
      if (!!data.file) {
        setSwitcher('file');
        setFile({ value: data.file.id, formattedValue: data.file });
      }
      if (!!data.url) {
        setSwitcher('link');
        setUrl(data.url); 
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Root expanded={open} onChange={() => setOpen(prev => !prev)}>
      <Header>
        {!!data.content_type ? (
          <Box>
            <Type>{_startCase(data.content_type)}</Type>
            <Title>{data.title}</Title>
            <Typography variant='caption' color='text.primary' noWrap component='div'>{getFormattedDate(data.published_at)}</Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant='caption' color='text.primary' noWrap component='div'>Create new content</Typography>
          </Box>
        )}
      </Header>
      <Content>
        <FormSelect
          fullWidth
          margin='dense'
          label='Content Type'
          value={type}
          onChange={({ target: { value } }) => setType(value)}
        >
          {_map(contentTypes, ct => <MenuItem key={ct} value={ct}>{_startCase(ct)}</MenuItem>)}
        </FormSelect>
        <TextField
          fullWidth
          margin='dense'
          label='Title'
          value={title}
          onChange={({ target: { value } }) => setTitle(value)}
        />
        <DateField
          fullWidth
          margin='dense'
          label='Date'
          value={publishedAt}
          onChange={v => setPublishedAt(v)}
        />
        <FileContent
          switcher={switcher}
          url={url}
          file={file.formattedValue}
          onChange={handleFileContentChange}
        />
        <Typography variant='caption' component='div' pt={3} pb={1}>Choose or upload a content avatar</Typography>
        <Uploader
          id='contentAvatar'
          fullWidth
          accept='image/*'
          label='Select Avatar'
          formattedValue={avatar.formattedValue}
          onChange={({ newValue, newFormattedValue }) => setAvatar(prev => ({
            ...prev,
            ...(!_isUndefined(newValue) && { value: newValue }),
            ...(!_isUndefined(newFormattedValue) && { formattedValue: newFormattedValue })
          }))}
          onDelete={() => setAvatar({ value: null, formattedValue: null })}
        />
        <Actions>
          <Button
            color='error'
            onClick={onDelete}
          >
            Remove
          </Button>
          <Button
            variant='outlined'
            onClick={handleSubmitClick}
            disabled={disabled}
          >
            {edit ? 'Update' : 'Add'}
          </Button>
        </Actions>
      </Content>
    </Root>
  );
};

ContentAccordion.propTypes = {
  data: PropTypes.shape({
    content_type: PropTypes.string,
    title: PropTypes.string,
    published_at: PropTypes.string,
    avatar: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
    file: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
    url: PropTypes.string
  }),
  edit: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default memo(ContentAccordion);