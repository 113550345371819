import { memo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Backdrop from '@mui/material/Backdrop';
import _filter from 'lodash/filter';
//Local files
import { Content, Root } from './ChatPopover.styled';
import Messages from 'components/Messages/Messages';
import ChangeConversation from './ChangeConversation/ChangeConversation';
import ChatHeader from './Header/Header';
import List from './List/List';

const ChatPopover = ({ 
  senders, 
  user_ids, 
  offerContent, 
  conversations, 
  unreadConversationsLength, 
  conversation, 
  my_id, 
  isMyShared, 
  open, 
  onClose,
  showMessages,
  onShowMessages
}) => {
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  const isMultiple = senders.length > 1;
  const headerProps = { 
    senders, 
    users: _filter(conversation.users, (u) => u.id !== my_id), 
    user_ids, 
    ...offerContent, 
    isMultiple, 
    isNoConversations: !!!conversations.length, 
    unreadConversationsLength, 
    conversations, 
    conversation, 
    isMyShared, 
    my_id, 
    onClose,
    showMessages,
    onShowMessages
  };
  const changeConversationProps = { senders, user_ids, conversations, conversation, my_id, onShowMessages };
  const listProps = { conversations, my_id, senders };
  const isShowMessages = ((senders.length === 1 && !!!conversation.id) || (!!conversation.id && user_ids === null) || (showMessages && user_ids !== null));
  const isShowList = isMultiple && !!conversations.length && !!!conversation.id && user_ids === null;

  if (open) {
    return (
      <Backdrop
        id='bubble-chat-backdrop'
        invisible={mediaDesktop}
        sx={{ zIndex: (theme) => theme.zIndex.modal + 1, ...mediaDesktop && { display: 'contents' } }}
        open={open}
        {...!mediaDesktop && { onClick: (e) => e?.target?.id === 'bubble-chat-backdrop' && onClose() }}
      >
        <Root>
          <ChatHeader {...headerProps} />
          <Content>
            {isShowMessages ? <Messages /> : isShowList ? <List {...listProps}/> : <ChangeConversation {...changeConversationProps} />}
          </Content>
        </Root>
      </Backdrop>
    )
  }

  return null;
}

export default memo(ChatPopover);