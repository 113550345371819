import { memo, useMemo } from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _round from 'lodash/round';
import { NumericFormat } from 'react-number-format';
// Local files
import { TableCell, backgroundColor, CustomBadge, Title, Subtitle } from './Head.styled';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import Search from 'components/Common/Inputs/Search/Search';
import useCustomSelector from 'hooks/useCustomSelector';

const Head = ({ query, onQueryChange, order, orderBy, onSortChange, onConversation }) => {
  const { badgeValue, isLockedOffer, isApprovalOffer, emailOpensPercent, emailOpensUnique, visitsPercent, visitsUnique, totalCASigned, totalTracked, totalOffered } = useCustomSelector(state => ({
    badgeValue: state.offers.activityLog.approval_requested_recipients_count,
    isLockedOffer: state.offers.activityLog.locked,
    isApprovalOffer: state.offers.activityLog.total_approval_required_documents_count > 0,
    emailOpensPercent: !!state.offers.activityLog.email_delivered_recipients_count ? _round(parseFloat(state.offers.activityLog.email_opened_recipients_count / state.offers.activityLog.email_delivered_recipients_count) * 100, 2) : null,
    emailOpensUnique: state.offers.activityLog.email_opened_recipients_count,
    visitsPercent: !!state.offers.activityLog.email_delivered_recipients_count ? _round(parseFloat(state.offers.activityLog.visited_recipients_count / state.offers.activityLog.email_delivered_recipients_count) * 100, 2) : null,
    visitsUnique: state.offers.activityLog.email_delivered_recipients_count,
    totalCASigned: state.offers.activityLog.unlocked_recipients_count,
    totalTracked: state.offers.activityLog.tracked_recipients_count,
    totalOffered: state.offers.activityLog.offered_recipients_count
  }));

  const headCells = useMemo(() => {
    const cells = [
      {
        id: 'email_opens_count',
        title: 'Email Opens',
        subtitle: <span><b>{emailOpensPercent ? `${emailOpensPercent}%` : '-'}</b> (<NumericFormat value={emailOpensUnique} displayType='text' thousandSeparator=',' /> unique)</span>,
        align: 'center'
      },
      {
        id: 'visits_count',
        title: 'Offering Visits',
        subtitle: <span><b>{visitsPercent ? `${visitsPercent}%` : '-'}</b> (<NumericFormat value={visitsUnique} displayType='text' thousandSeparator=',' /> unique)</span>,
        align: 'center'
      },
      {
        id: 'locked',
        title: 'CA Signed',
        subtitle: <span>Total({<NumericFormat value={totalCASigned} displayType='text' thousandSeparator=',' />})</span>,
        align: 'center'
      },
      {
        id: 'approval_required',
        title: <Box display='flex' alignItems='center' gap='3px'>Level 2 Access <CustomBadge style={{ display: !!badgeValue ? 'block' : 'none' }}>{badgeValue}</CustomBadge></Box>,
        align: 'center'
      },
      {
        id: 'tracked',
        title: 'Tracking',
        subtitle: <span>Total({<NumericFormat value={totalTracked} displayType='text' thousandSeparator=',' />})</span>,
        align: 'center'
      },
      {
        id: 'saved',
        title: 'Saved',
        align: 'center'
      },
      {
        id: 'member',
        title: 'Group Contact',
        align: 'center',
        hideSort: true
      },
      // TODO delete later
      // {
      //   id: 'interest_level',
      //   title: 'Interest',
      //   align: 'center'
      // },
      {
        id: 'offered_amount_cents',
        title: 'Offer',
        subtitle: <span>Total({<NumericFormat value={totalOffered} displayType='text' thousandSeparator=',' />})</span>,
        align: 'center'
      },
      {
        id: 'offered_at',
        title: 'Offer Date',
        align: 'center'
      },
      {
        id: 'notes',
        title: 'Notes',
        align: 'center'
      },
      {
        id: 'property_visits',
        title: 'Property Visits/Tours',
        align: 'center'
      },
      {
        id: 'files',
        title: 'Files',
        align: 'center',
        hideSort: true
      },
      {
        id: 'created_at',
        title: 'Date Shared',
        align: 'center'
      },
      {
        id: 'visited_at',
        title: 'Last Accessed',
        align: 'center'
      },
      {
        id: 'visits_duration',
        title: 'Time Spent',
        align: 'center'
      }
    ];
    let result = [...cells];

    if (!isLockedOffer) result = _filter(result, r => r.id !== 'locked');
    if (!isApprovalOffer) result = _filter(result, r => r.id !== 'approval_required');

    return result;
  }, [isApprovalOffer, isLockedOffer, badgeValue, emailOpensPercent, emailOpensUnique, visitsPercent, visitsUnique, totalCASigned, totalTracked, totalOffered]);

  return (
    <TableHead sx={{ background: backgroundColor }}>
      <TableRow>
        <TableCell sx={{ position:  'sticky', left: '0', zIndex: { xs: 2, md: 10 }, background: backgroundColor }}>
          <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', gap: '4px' }}>
            <Button
              variant='blank'
              color='neutral'
              onClick={onConversation}
            >
              <ChatIcon />
            </Button>
            <Search
              placeholder='search recipients'
              value={query}
              onChange={v => onQueryChange(v)}
              sx={{minWidth: 200}}
            />
          </Box>
        </TableCell>
        {_map(headCells, c =>
          <TableCell
            sx={{
              whiteSpace: 'nowrap',  
              background: backgroundColor,
              '& .Mui-active .MuiTableSortLabel-icon': {
                color: '#4877F0 !important'
              }
            }}
            align={c.align}
            key={c.id}
            sortDirection={orderBy === c.id ? order : null}
          >
            {c.hideSort ?
              <Title key={c.id}>
                {c.title}
                {c.subtitle && <Subtitle>{c.subtitle}</Subtitle>}
              </Title> :
              <TableSortLabel
                active={orderBy === c.id}
                direction={orderBy === c.id ? order : 'asc'}
                onClick={() => onSortChange(c.id)}
              >
                <Title key={c.id}>
                  {c.title}
                  {c.subtitle && <Subtitle>{c.subtitle}</Subtitle>}
                </Title>
                {orderBy === c.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            }
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

Head.propTypes = {
  query: PropTypes.string.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onConversation: PropTypes.func.isRequired
};

export default memo(Head);