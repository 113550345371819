import styled from '@mui/material/styles/styled';
import MuiBadge from '@mui/material/Badge';

const Badge = styled(MuiBadge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 4,
    right: 4,
    height: 10,
    width: 10,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));

export { Badge };