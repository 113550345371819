import { memo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import { checkEmptyString } from 'helpers';
import useError from 'hooks/useError';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useLists from 'hooks/useLists';

const CreateList = () => {
  const { closeCreateListDialog } = useApp();
  const { createList } = useLists();
  const { setError } = useError();
  const { open } = useCustomSelector(state => state.app.createListDialog);
  const [listName, setListName] = useState({ value: '', error: '' });
  const [processing, setProcessing] = useState(false);

  const handleSave = () => {
    let hasError = false;

    if (!checkEmptyString(listName.value)) {
      setListName(prev => ({ ...prev, error: 'Enter a list name' }));
      hasError = true;
    };
    if (hasError) return;

    setProcessing(true);
    createList({ name: listName.value })
    .then(() => handleClose())
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };

  const handleClose = () => closeCreateListDialog();

  const handleExited = () => {
    setListName({ value: '', error: '' });
  };

  return (
    <>
      <BaseDialog
        open={open}
        onClose={handleClose}
        title='New List'
        otherPaperStyle={{ width: '100%', maxWidth: 500 }}
        TransitionProps={{ onExited: handleExited }}
        actions={
          <>
            <Button
              disabled={processing}
              color='error'
              sx={{ width: 90 }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              disabled={processing}
              color='primary'
              variant='contained'
              sx={{ width: 90 }}
              onClick={handleSave}
            >
              Save
            </Button>
          </>
        }
        content={
          <Box sx={{ p: '16px 16px 32px' }}>
            <TextField
              label='List Name'
              value={listName.value}
              error={!!listName.error}
              helperText={listName.error}
              onChange={({ target: { value } }) => setListName({ value, error: '' })}
              fullWidth
              margin='normal'
            />
          </Box>
        }
      />
    </>
  );
};

export default memo(CreateList);