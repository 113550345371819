import { memo, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import _endsWith from 'lodash/endsWith';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import { StyledTab } from './Settings.styled';
import ArrowBackIcon from 'components/Common/Icons/ArrowBackIcon';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useCustomSelector from 'hooks/useCustomSelector';
import useMembers from 'hooks/useMembers';

const Settings = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const groupFetched = useCustomSelector(state => !!state.groups.group.id);
  const { clearLocalGroupMembers } = useMembers();
  const { group_id } = useParams();
  const [tab, setTab] = useState(() => _endsWith(pathname, 'users') ? 'users' : (_endsWith(pathname, 'gate_templates') ? 'gateTemplates' : 'general'));

  const handleTabChange = (_, newValue) => {
    if (newValue !== tab) setTab(newValue);
  };

  useEffect(() => {
    return () => {
      clearLocalGroupMembers();
    };
  }, [clearLocalGroupMembers]);

  return (
    <Container
      maxWidth={false}
      sx={{ p: { xs: '16px', md: '32px 48px !important' }}}
    >
      <Button
        onClick={() => navigate(`/groups/${group_id}`)}
        startIcon={<ArrowBackIcon />}
        sx={{ fontWeight: 600 }}
      >
        Back
      </Button>
      <Tabs
        centered
        scrollButtons
        allowScrollButtonsMobile
        sx={{ minHeight: '40px' }}
        value={tab}
        onChange={handleTabChange}
      >
        <StyledTab
          label='General'
          value='general'
          LinkComponent={Link}
          to={`/groups/${group_id}/settings/general`}
        />
        <StyledTab
          label='Users'
          value='users'
          LinkComponent={Link}
          to={`/groups/${group_id}/settings/users`}
        />
      </Tabs>
      {groupFetched && <Outlet />}
    </Container>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Settings {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);