import { memo, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import _map from 'lodash/map';
// Local files
import { Grid, Root } from './Widget.styled';
import CardSkeleton from 'components/Dealspaces/Card/Skeleton/Skeleton';
import Card from 'components/Dealspaces/Card/Card';
import useCustomSelector from 'hooks/useCustomSelector';
import useViews from 'hooks/useViews';
import useError from 'hooks/useError';
import useDealspaces from 'hooks/useDealspaces';

const DealspacesWidget = () => {
  const { getViews, clearLocalViews } = useViews();
  const { getNotViewedDealspaces, clearLocalNotViewedDealspaces } = useDealspaces();
  const { setError } = useError();
  const { dealspaces } = useCustomSelector(state => ({
    dealspaces: [...state.dealspaces.notViewed.data, ...state.views.all.data]
  }));
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    setFetching(true);
    getViews({ offset: 0 })
    .then(() => 
      getNotViewedDealspaces({ offset: 0 })
      .catch(e => setError(e))
      .finally(() => setFetching(false))
    )
    .catch(e => setError(e).then(() => setFetching(false)))
    
    return () => {
      clearLocalNotViewedDealspaces();
      clearLocalViews();
    };
  }, [getViews, getNotViewedDealspaces, clearLocalViews, clearLocalNotViewedDealspaces, setError]);

  return (
    <Root variant='outlined' sx={{ p: '35px 28px 20px' }}>
      <Typography variant='body2' sx={{ color: 'rgba(0,0,0,.7)', fontWeight: 500 }}>Recently accessed Dealspaces</Typography>
     <Grid>
       {fetching ? (
         <>
           <CardSkeleton />
           <CardSkeleton />
           <CardSkeleton />
           <CardSkeleton />
         </>
       ) : _map(dealspaces, (d) => <Card key={d.id} {...d} />)}
     </Grid>
    </Root>
  );
};

export default memo(DealspacesWidget);