import { memo, useCallback, useEffect } from 'react';
import { sessionService } from 'redux-react-session';
import { useNavigate, useParams } from 'react-router-dom';
// Local files
import axios, { baseURL } from 'apis';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useAndroid from 'hooks/useAndroid';
import useApp from 'hooks/useApp';
import useChameleon from 'hooks/useChameleon';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useIOS from 'hooks/useIOS';
import useLogrocket from 'hooks/useLogrocket';
import useMixpanel from 'hooks/useMixpanel';
import useNotifications from 'hooks/useNotifications';
import useProfile from 'hooks/useProfile';
import useSessions from 'hooks/useSessions';
import useStore from 'hooks/useStore';

const Session = () => {
  const navigate = useNavigate();
  const { checked, authenticated } = useCustomSelector(state => state.session);
  const { sendTokenToAndroid } = useAndroid();
  const { sendTokenToIOS } = useIOS();
  const { setAbortController } = useApp();
  const { setError } = useError();
  const { getUnreadNotificationsCount, getNotifications } = useNotifications();
  const { recipient_token } = useParams();
  const { getShortProfile } = useProfile();
  const { setLocalSession } = useSessions();
  const { clearStore } = useStore();
  const { identify: identifyChameleon } = useChameleon();
  const { identify: identifyLogrocket } = useLogrocket();
  const { identify: identifyMixpanel } = useMixpanel();

  const processProfile = useCallback(({ data, session }) => {
    const controller = new AbortController();

    axios.defaults.signal = controller.signal;
    axios.defaults.baseURL = `${baseURL}/${session.userableType}`;
    axios.defaults.params = { ...axios.defaults.params, access_token: session.accessToken };
    axios.defaults.data = { ...axios.defaults.params, access_token: session.accessToken };

    axios.interceptors.request.use(config => config, error => {
      console.error(error);
      return Promise.reject(error);
    });
    axios.interceptors.response.use(response => response, error => {
      if (error.response.status === 401) {
        sessionService.deleteSession()
        .catch(e => console.warn(e))
        .finally(() => clearStore());
      }
      return Promise.reject(error);
    });

    setAbortController(controller)
    .then(() => {
      const userId = data.user?.id ?? data.recipient?.id ?? null;
      const firstName = data.user?.first_name ?? data.recipient?.user?.first_name ?? data.recipient?.first_name ?? 'unknown';
      const lastName = data.user?.last_name ?? data.recipient?.user?.last_name ?? data.recipient?.last_name ?? 'user';
      const email = data.user?.user ?? data.recipient?.user?.email ?? data.recipient?.email ?? 'undefined';
      const createdAt = data.user?.created_at ?? data.recipient?.created_at ?? 'undefined';

      if (data.user) getUnreadNotificationsCount().catch(e => setError(e));
      if (data.user) getNotifications({ offset: 0 }).catch(e => setError(e));
      if (data.user) sendTokenToAndroid(session.accessToken);
      if (data.user) sendTokenToIOS(session.accessToken);
      identifyChameleon({ userId, fields: { first_name: firstName, last_name: lastName, email, signed_up_at: createdAt } });
      identifyLogrocket(userId, { name: `${firstName} ${lastName}` });
      identifyMixpanel(userId);

      setLocalSession(session);
    });
  }, [// eslint-disable-line
    clearStore, getNotifications, getUnreadNotificationsCount, identifyChameleon, identifyLogrocket, identifyMixpanel, sendTokenToAndroid,
    sendTokenToIOS, setAbortController, setError, setLocalSession
  ]);

  useEffect(() => {
    const checkInitialSession = async () => {
      const session = await sessionService.loadSession().catch(e => console.warn(e));

      if (session) {
        axios.defaults.baseURL = `${baseURL}/${session.userableType}`;
        axios.defaults.params = { ...axios.defaults.params, access_token: session.accessToken };
        axios.defaults.data = { ...axios.defaults.params, access_token: session.accessToken };
  
        getShortProfile()
        .then(({ payload: { data } }) => processProfile({ data, session }))
        .catch(e => {
          if (e.response?.status === 401) {
            sessionService.deleteSession()
            .catch(e => console.warn(e))
            .finally(() => clearStore().then(() => {
              // window.location.replace('/sign_in')
            }));
          } else {
            setError(e);
          }
        });
      } else {
        setLocalSession({ accessToken: null, userableType: null }).then(() => navigate('/sign_in'));
      }
    };

    if (checked && !recipient_token) checkInitialSession();
  }, [authenticated, checked, clearStore, getShortProfile, processProfile, recipient_token, setError, setLocalSession]); // eslint-disable-line

  return null;
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Session {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);