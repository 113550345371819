import { lazy, memo, Suspense, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Local files
import { Root } from './History.styled';
import { Fallback as AuditsTableFallback } from 'components/Audits/Table/Table';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useAudits from 'hooks/useAudits';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';

const Audits = lazy(() => import('components/Audits/Table/Table'));

const rowsPerPage = 50;

const History = ({ tab }) => {
  const { offer_id } = useParams();
  const { getAudits } = useAudits();
  const { setError } = useError();
  const { fetching, showEmpty, offer } = useCustomSelector(state => ({
    offer: state.offers.activityLog,
    fetching: state.audits.all.fetching,
    showEmpty: state.audits.all.pagination.total_count === 0
  }));
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('desc');
  const auditsProps = { page, order, orderBy, rowsPerPage };

  const fetchAudits = useCallback(({ offer_id, offset, orders }) => {
    getAudits({ offer_id, limit: rowsPerPage, offset, ...(!!orders && { orders }) })
    .catch(e => setError(e));
  }, [getAudits, setError]);

  const handlePageChange = p => setPage(p);
  const handleOrdersChange = v => {
    if (orderBy === v) {
      setOrder(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(v);
    }
  };

  useEffect(() => {
    const orders = !!orderBy ? { [orderBy]: order } : null;

    if (offer.id) fetchAudits({ offer_id, offset: page * rowsPerPage, orders });
  }, [fetchAudits, offer_id, page, order, orderBy, offer.id]);
  
  if (tab !== 'history') return null;
  if (showEmpty && !fetching) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
        <Typography fontWeight={400} fontSize='16px' lineHeight='20px'>
          No history on this Offering yet. Check back later!
        </Typography>
      </Box>
    );
  }
  return (
    <Root>
      <Suspense fallback={<AuditsTableFallback />}>
        <Audits
          {...auditsProps}
          onPageChange={handlePageChange}
          onOrdersChange={handleOrdersChange}
        />
      </Suspense>
    </Root>
  );
};

History.propTypes = {
  tab: PropTypes.string.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <History {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);