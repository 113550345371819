import { memo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
// Local files
import { Icon, StyledListItemButton, Text } from './Item.styled';
import MarketFilledIcon from 'components/Common/Icons/MarketFilledIcon';
import ArchiveFilledIcon from 'components/Common/Icons/ArchiveFilledIcon';
import LockedFilledIcon from 'components/Common/Icons/LockedFilledIcon';
import ShareFilledIcon from 'components/Common/Icons/ShareFilledIcon';

/**
 * @param {string} v enum ['sharedWith_me', 'archive', 'myShared', 'market'] 
 * @returns {object}
 */

const getIconAndText = v => {
  switch (v) {
    case 'sharedWithMe': return { icon: <LockedFilledIcon />, text: 'Shared with me' };
    case 'archive': return { icon: <ArchiveFilledIcon />, text: 'Archive' };
    case 'myShared': return { icon: <ShareFilledIcon />, text: 'My shared offerings' };
    case 'market': return { icon: <MarketFilledIcon />, text: 'Market' };
    default: return { icon: null, text: '' };
  }
};

/**
 * @param {string} value
 * @param {string} type
 * @param {string} activeFilter
 * @param {function} onChange
 * @returns {node}
 */

const Item = ({ value, type, activeFilter = '', onChange = null }) => {
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { icon, text } = getIconAndText(type ?? activeFilter);
  const selected = !!onChange && value === type;

  const handleChange = () => {
    if (!onChange) return;
    onChange(type);
  };

  return (
    <StyledListItemButton
      selected={selected}
      onClick={handleChange}
      sx={{
        ...(!!activeFilter && { fontWeight: 600 }),
        ...(!!selected && { color: 'text.primary', fontWeight: 600 }),
        ...!mediaDesktop && { '&.Mui-selected': { backgroundColor: '#252B30', color: 'white' } }
      }}
    >
      <Icon>
        {icon}
      </Icon>
      <Text>{text}</Text>
    </StyledListItemButton>
  );
};

Item.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default memo(Item);