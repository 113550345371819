import styled from '@mui/material/styles/styled';

export const Canvas = styled('canvas')(({ theme }) => ({
  paddingLeft: 40,
  maxWidth: 560,
  maxHeight: 400,
  [theme.breakpoints.up(1550)]: {
    maxWidth: 800,
    maxHeight: 400
  }
}));