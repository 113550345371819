import Compressor from 'compressorjs';

export const getCompressedImage = ({ file, maxWidth = 600, maxHeight = 450, callback }) => {
  new Compressor(file, {
    quality: 1,
    maxHeight,
    maxWidth,
    success(result) {
      callback(result);
    }
  });
};