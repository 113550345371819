import styled from '@mui/material/styles/styled';

export const List = styled('ul')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  listStyle: 'none',
  margin: 0,
  padding: '8px 0 0',
  [theme.breakpoints.up('md')]: {
    padding: '60px 0 30px',
  }
}));