import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Popover from '@mui/material/Popover';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _startCase from 'lodash/startCase';
import _sortBy from 'lodash/sortBy';
// Local files
import { formStyles } from './BuilderProperties.styled';
import NumericTextField from './NumericTextField/NumericTextField';
import FormSelect from 'components/Common/Inputs/FormSelect/FormSelect';
import BuilderErrorLabel from 'components/Common/DataDisplay/BuilderErrorLabel/BuilderErrorLabel';
import PropertyDialog from 'components/Dialogs/Property/Property';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { singlePropertyDealTypes, portfolioDealTypes, assemblageDealTypes, fundDealTypes, propertyGroupings, assetClassesList, riskProfilesList } from 'helpers/constants';
import { needToUpdateField, getAskingPriceLabel } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useOffers from 'hooks/useOffers';
import useProperties from 'hooks/useProperties';

const BuilderProperties = forwardRef(({ locationsLocked, onChange }, ref) => {
  const { setError } = useError();
  const { setBuilderState, updateOffer } = useOffers();
  const { getDealspacePropertiesList, getOfferProperties, createOfferProperty, deleteProperty, clearLocalDealspacePropertiesList } = useProperties();
  const { options, fetching, offer, dealspaceId } = useCustomSelector(state => ({
    options: state.properties.list.data,
    fetching: state.offers.builder.fetching,
    offer: state.offers.builder.offer,
    dealspaceId: state.offers.builder.offer.dealspace.id
  }));
  const [properties, setProperties] = useState({ value: [], error: '' });
  const [dealType, setDealType] = useState({ value: '', error: '' });
  const [propertyGrouping, setPropertyGrouping] = useState({ value: '', error: '' });
  const [assetClasses, setAssetClasses] = useState([]);
  const [priceCents, setPriceCents] = useState({ value: 0, formattedValue: '' });
  const [leasePeriod, setLeasePeriod] = useState('');
  const [loanRatio, setLoanRatio] = useState('ltv');
  const [loanPercent, setLoanPercent] = useState('');
  const [investmentType, setInvestmentType] = useState('');
  const [customPrice, setCustomPrice] = useState('');
  const [riskProfiles, setRiskProfiles] = useState([]);
  const [mode, setMode] = useState('asking');
  const [anchorEl, setAnchorEl] = useState(null);
  const [propertyDialog, setPropertyDialog] = useState({ open: false, offerId: '', dealspaceId: '' });
  const showPropertyGrouping = useMemo(() => properties.value.length > 1, [properties.value]);
  const showDealType = useMemo(() => {
    if (properties.value.length === 1) return true;
    if (properties.value.length > 1 && !!propertyGrouping.value) return true;
    return false;
  }, [properties.value, propertyGrouping.value]);
  const dealTypes = useMemo(() => {
    if (properties.value.length === 1) return singlePropertyDealTypes;
    if (properties.value.length > 1) {
      if (propertyGrouping.value === 'portfolio') return portfolioDealTypes;
      if (propertyGrouping.value === 'assemblage') return assemblageDealTypes;
      if (propertyGrouping.value === 'fund') return fundDealTypes;
      return [];
    }
    return [];
  }, [properties.value, propertyGrouping.value]);
  const showAssetClasses = useMemo(() => !!dealType.value && dealType.value !== 'ground_lease', [dealType.value]);
  const showRiskProfiles = useMemo(() => {
    const validTypes = [
      'active_equity_investment', 'passive_equity_investment', 'preferred_equity_investment', 'partnership_opportunity', 'property_for_sale',
      'development_rights_for_sale', 'loan_for_sale', 'property_for_sale_leaseback'
    ];

    return !!_find(validTypes, vt => vt === dealType.value);
  }, [dealType.value]);
  const showPriceMode = useMemo(() => {
    const validTypes = [
      'property_for_sale', 'development_rights_for_sale', 'loan_for_sale', 'property_for_sale_leaseback', 'land_assemblage_for_sale'
    ];

    return !!_find(validTypes, vt => vt === dealType.value);
  }, [dealType.value]);
  const showPriceCents = useMemo(() => {
    if (showPriceMode) {
      return !!dealType.value && dealType.value !== 'partnership_opportunity'&& mode === 'asking';
    } else {
      return !!dealType.value && dealType.value !== 'partnership_opportunity';
    }
  }, [dealType.value, mode, showPriceMode]);
  const showLoanPercent = useMemo(() => {
    const validTypes = ['permanent_loan_request', 'construction_loan_request', 'acquisition_loan_request', 'bridge_loan_request'];

    return !!_find(validTypes, vt => vt === dealType.value);
  }, [dealType.value]);
  const showCustomPrice = useMemo(() => {
    const validTypes = [
      'partnership_opportunity', 'property_for_sale', 'development_rights_for_sale', 'loan_for_sale', 'property_for_sale_leaseback',
      'land_assemblage_for_sale'
    ];

    if (showPriceMode) {
      return !!_find(validTypes, vt => vt === dealType.value) && mode === 'custom';
    } else {
      return !!_find(validTypes, vt => vt === dealType.value);
    }
  }, [dealType.value, showPriceMode, mode]);
  const showLeasePeriods = useMemo(() => {
    return dealType.value === 'ground_lease';
  }, [dealType.value]);
  const showIvestmentTypes = useMemo(() => {
    const validTypes = ['active_equity_investment', 'passive_equity_investment', 'preferred_equity_investment'];

    return !!_find(validTypes, vt => vt === dealType.value);
  }, [dealType.value]);
  const askingPriceLabel = getAskingPriceLabel(dealType.value);

  const processOffer = o => {
    setBuilderState({ saving: true })
    .then(() =>
      updateOffer({ id: offer.id, ...o })
      .catch(e => setError(e))
      .finally(() => setBuilderState({ saving: false }))
    );
  };
  const createNewProperty = ({ offer_id, property_id = null, property = null }) => {
    setBuilderState({ saving: true })
    .then(() =>
      createOfferProperty({ offer_id, property_id, property })
      .then(({ payload: { data: { property } } }) => setProperties(prev => ({ value: [...prev.value, property], error: '' })))
      .catch(e => setError(e))
      .finally(() => setBuilderState({ saving: false }))
    );
  };
  const deleteExistingProperty = id => {
    setBuilderState({ saving: true })
    .then(() =>
      deleteProperty({ id, reason: 'offer' })
      .then(() => setProperties(prev => ({ ...prev, value: _filter(prev.value, p => p.id !== id) })))
      .catch(e => setError(e))
      .finally(() => setBuilderState({ saving: false }))
    );
  };
  const handleDelete = id => deleteExistingProperty(id);
  const handlePropertyClick = id => {
    if (!_find(properties.value, v => v.property?.id === id)) {
      createNewProperty({ offer_id: offer.id, property_id: id });
      /*** Очищаем зависимые поля если количество properties 1 или 2 ***/
      if (properties.value.length + 1 <= 2) {
        setDealType({ value: '', error: '' });
        setPropertyGrouping({ value: '', error: '' });
        setAssetClasses([]);
        setPriceCents({ value: 0, formattedValue: ''});
        setLeasePeriod('');
        setLoanRatio('');
        setLoanPercent('');
        setInvestmentType('');
        setCustomPrice('');
        setRiskProfiles([]);

        const processableOffer = {
          deal_type: null,
          asset_classes: [],
          price_cents: null,
          lease_period: null,
          loan_ratio: null,
          loan_percent: null,
          investment_type: null,
          custom_price: null,
          risk_profiles: []
        };

        processOffer(processableOffer);
      }
    }
  };
  const handlePropertyGroupingChange = value => {
    setPropertyGrouping({ value, error: '' });
    setDealType({ value: '', error: '' });
    setAssetClasses([]);
    setPriceCents({ value: 0, formattedValue: '' });
    setCustomPrice('');
    setLeasePeriod('');
    setLoanRatio('');
    setLoanPercent('');
    setInvestmentType('');
    setRiskProfiles([]);

    const processableOffer = {
      property_grouping: value,
      deal_type: null,
      asset_classes: [],
      price_cents: null,
      lease_period: null,
      loan_ratio: null,
      loan_percent: null,
      investment_type: null,
      custom_price: null,
      risk_profiles: []
    };

    processOffer(processableOffer);
  };
  const handleDealTypeChange = value => {
    setDealType({ value, error: '' });
    setAssetClasses([]);
    setPriceCents({ value: 0, formattedValue: '' });
    setCustomPrice('');
    setLeasePeriod('');
    setLoanRatio('');
    setLoanPercent('');
    setInvestmentType('');
    setRiskProfiles([]);
    
    const processableOffer = {
      deal_type: value,
      asset_classes: [],
      price_cents: null,
      lease_period: null,
      loan_ratio: null,
      loan_percent: null,
      investment_type: null,
      custom_price: null,
      risk_profiles: []
    };

    processOffer(processableOffer);
  };
  const handleAddProperty = () => setPropertyDialog({ open: true, offerId: offer.id, dealspaceId: offer.dealspace.id });
  const handleAssetClassesChange = v => {
    setAssetClasses(v);

    if (needToUpdateField(offer.asset_classes, v)) {
      processOffer({ asset_classes: v });
    }
  };
  const handlePriceCentsChange = () => {
    if (needToUpdateField(offer.price_cents ?? 0, priceCents.value * 100)) {
      processOffer({ price_cents: priceCents.value * 100, custom_price: null });
    }
  };
  const handleCustomPriceChange = v => {
    if (needToUpdateField(offer.custom_price, v)) {
      processOffer({ custom_price: !!v ? v : null, price_cents: null });
    }
  };
  const handleLoanPercentChange = v => {
    if (needToUpdateField(offer.loan_percent ?? 0, !!v ? parseInt(v) : 0)) {
      processOffer({ loan_percent: !!v ? v : null });
    }
  };
  const handleLoanRatioChange = v => {
    setLoanRatio(v);

    if (needToUpdateField(offer.loan_ratio, v)) {
      processOffer({ loan_ratio: v });
    }
  };
  const handleLeasePeriodChange = v => {
    setLeasePeriod(v);

    if (needToUpdateField(offer.lease_period, v)) {
      processOffer({ lease_period: v });
    }
  };
  const handleInvestmentTypeChange = v => {
    setInvestmentType(v);

    if (needToUpdateField(offer.investment_type, v)) {
      processOffer({ investment_type: v });
    }
  };
  const handleRiskProfilesChange = v => {
    setRiskProfiles(v);

    if (needToUpdateField(offer.risk_profiles, v)) {
      processOffer({ risk_profiles: v });
    }
  };

  /*** Получаем properties ***/
  useEffect(() => {
    if (!fetching) {
      /*** Получаем properties ***/
      getOfferProperties({ offer_id: offer.id, offset: 0 })
      .then(({ payload: { data: { properties } } }) => setProperties({ value: properties, error: '' }))
      .catch(e => setError(e));
      getDealspacePropertiesList({ dealspace_id: dealspaceId, offset: 0 })
      .catch(e => setError(e));
      if (!!offer.deal_type) setDealType({ value: offer.deal_type, error: '' });
      if (!!offer.property_grouping) setPropertyGrouping({ value: offer.property_grouping, error: '' });
      if (!!offer.asset_classes.length) setAssetClasses(offer.asset_classes);
      if (!!offer.price_cents) {
        setPriceCents({ value: offer.price_cents / 100, formattedValue: (offer.price_cents / 100).toString() });
        setMode('asking');
      };
      if (!!offer.lease_period) setLeasePeriod(offer.lease_period);
      if (!!offer.loan_ratio) setLoanRatio(offer.loanRatio);
      if (!!offer.loan_percent) setLoanPercent(offer.loan_percent);
      if (!!offer.investment_type) setInvestmentType(offer.investment_type);
      if (!!offer.custom_price) {
        setCustomPrice(offer.custom_price);
        setMode('custom');
      }
      if (!!offer.risk_profiles.length) setRiskProfiles(offer.risk_profiles);
    }

    return clearLocalDealspacePropertiesList;
  }, [fetching, offer, clearLocalDealspacePropertiesList, dealspaceId, getDealspacePropertiesList, getOfferProperties, setError]);
  useImperativeHandle(ref, () => {
    return {
      checkRequired() {
        let hasError = false;

        if (!properties.value.length) {
          setProperties(prev => ({ ...prev, error: 'Select at least one property' }));
          hasError = true;
        }
        if (!dealType.value) {
          setDealType(prev => ({ ...prev, error: 'Select deal type' }));
          hasError = true;
        }
        if (properties.value.length > 1 && !propertyGrouping.value) {
          setPropertyGrouping(prev => ({ ...prev, error: 'Select property grouping' }));
          hasError = true;
        }

        return hasError;
      }
    };
  }, [dealType, properties, propertyGrouping]);

  return (
    <Box sx={{ minHeight: 160, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '8px', mt: '30px' }}>
      <PropertyDialog
        {...propertyDialog}
        onChange={property => setProperties(prev => ({ value: [...prev.value, property], error: '' }))}
        onClose={() => setPropertyDialog({ open: false, offerId: '', dealspaceId: '' })}
      />
      <Box width='100%' sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant='caption' color='textSecondary' component='div'>Properties:*</Typography>
        <FormControlLabel
          label={<span style={{ userSelect: 'none' }}>Lock location(s) with CA</span>}
          onChange={() => onChange()}
          sx={{ mr: 0 }}
          control={
            <Checkbox
              color='secondary'
              checked={locationsLocked}
            />
          }
        />
      </Box>
      {properties.value &&
        <Box sx={{ width: '100%', mt: '-6px', mb: '8px' }}>
          {_map(properties.value, ({ id, name }) =>
            <Chip
              sx={{ m: '2px' }}
              key={id}
              onDelete={() => handleDelete(id)}
              label={
                <Box component='span' sx={{ display: 'flex', gap: '8px', color: '#3F3F3F' }}>
                  <Typography>{name}</Typography>
                </Box>
              }
            />
          )}
        </Box>
      }
      <Box width='100%'>
        <Button
          startIcon={<AddIcon />}
          onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        >
          Add Property
        </Button>
        {!!properties.error && <BuilderErrorLabel value={properties.error} />}
        <Popover
          id='addProperty'
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          {_map(options, p =>
            <Box
              key={p.id}
              sx={{
                minWidth: 200,
                ...!_find(properties.value, v => v.property?.id === p.id) && { cursor: 'pointer' },
                ...!!_find(properties.value, v => v.property?.id === p.id) ? { backgroundColor: '#e7e7e7' } : {}
              }}
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              p={2}
              onClick={() => handlePropertyClick(p.id)}
            >
              <Typography key={p.id}>{p.name}</Typography>
            </Box>
          )}
          <Button
            fullWidth
            sx={{ p: 3 }}
            startIcon={<AddIcon />}
            onClick={handleAddProperty}
          >
            Create New
          </Button>
        </Popover>
      </Box>
      <Box display='flex' flexDirection='row' flexWrap='wrap' alignItems='end' width='100%' mt='40px' mb='70px' gap='24px 16px'>
        {showPropertyGrouping &&
          <Box sx={{ width: '100%' }}>
            <FormSelect
              sx={formStyles}
              variant='standard'
              paperVariant='popup'
              paperColor='light'
              fullWidth
              label='Property Grouping'
              required
              value={propertyGrouping.value}
              error={!!propertyGrouping.error}
              onChange={({ target: { value } }) => handlePropertyGroupingChange(value)}
            >
              {_map(propertyGroupings, pg => <MenuItem key={pg} value={pg}>{_startCase(pg)}</MenuItem>)}
            </FormSelect>
            {!!propertyGrouping.error && <BuilderErrorLabel value={propertyGrouping.error} />}
          </Box>
        }
        {showDealType &&
          <Box sx={formStyles}>
            <FormSelect
              variant='standard'
              paperVariant='popup'
              paperColor='light'
              fullWidth
              label='Deal Type'
              required
              value={dealType.value}
              error={!!dealType.error}
              onChange={({ target: { value } }) => handleDealTypeChange(value)}
            >
              {_map(dealTypes, dt => <MenuItem key={dt} value={dt}>{_startCase(dt)}</MenuItem>)}
            </FormSelect>
            {!!dealType.error && <BuilderErrorLabel value={dealType.error} />}
          </Box>
        }
        {showAssetClasses &&
          <FormSelect
            sx={formStyles}
            multiple
            variant='standard'
            paperVariant='popup'
            paperColor='light'
            label='Asset Class(es)'
            value={assetClasses}
            onChange={({ target: { value } }) => handleAssetClassesChange(value)}
          >
            {_map(_sortBy(assetClassesList), ac => <MenuItem key={ac} value={ac}>{_startCase(ac)}</MenuItem>)}
          </FormSelect>
        }
        {showPriceMode &&
          <FormSelect
            sx={formStyles}
            variant='standard'
            paperVariant='popup'
            paperColor='light'
            label='Price'
            value={mode}
            onChange={({ target: { value } }) => setMode(value)}
          >
            <MenuItem value='asking'>Asking Price</MenuItem>
            <MenuItem value='custom'>Custom Value</MenuItem>
          </FormSelect>
        }
        {showPriceCents &&
          <TextField
            sx={formStyles}
            inputProps={{ min: 1 }}
            InputProps={{
              startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              inputComponent: NumericTextField
            }}
            variant='standard'
            label={askingPriceLabel}
            value={priceCents.formattedValue}
            onChange={v => setPriceCents(v)}
            onBlur={() => handlePriceCentsChange()}
          />
        }
        {showCustomPrice &&
          <TextField
            sx={formStyles}
            inputProps={{ min: 1 }}
            variant='standard'
            label='Custom Value'
            value={customPrice}
            onChange={({ target: { value } }) => setCustomPrice(value)}
            onBlur={({ target: { value } }) => handleCustomPriceChange(value)}
          />
        }
        {showLoanPercent &&
          <Box display='flex' sx={formStyles} gap='8px' justifyContent='space-between' alignItems='flex-end'>
            <TextField
              variant='standard'
              InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
              label='Loan Ratio (optional)'
              value={loanPercent}
              onChange={({ target: { value } }) => setLoanPercent(value)}
              onBlur={({ target: { value } }) => handleLoanPercentChange(value)}
            />
            <RadioGroup
              sx={{ display: 'flex', justifyContent: 'flex-end', gap: '5px' }}
              row
              value={loanRatio}
              onChange={({ target: { value } }) => handleLoanRatioChange(value)}
            >
              <FormControlLabel
                value='ltv'
                control={<Radio color='secondary' size='small' sx={{ padding: 0 }} />}
                label='LTV'
                sx={{ marginLeft: 0, marginRight: 0 }}
              />
              <FormControlLabel
                value='ltc'
                control={<Radio color='secondary' size='small' sx={{ padding: 0 }} />}
                label='LTC'
                sx={{ marginLeft: 0, marginRight: 0 }}
              />
            </RadioGroup>
          </Box>
        }
        {showLeasePeriods &&
          <RadioGroup
            sx={formStyles}
            row
            value={leasePeriod}
            onChange={({ target: { value } }) => handleLeasePeriodChange(value)}
          >
            <FormControlLabel
              value='month'
              control={<Radio color='secondary' size='small' />}
              label='/month'
            />
            <FormControlLabel
              value='year'
              control={<Radio color='secondary' size='small' />}
              label='/year'
            />
          </RadioGroup>
        }
        {showIvestmentTypes &&
          <RadioGroup
            sx={formStyles}
            row
            value={investmentType}
            onChange={({ target: { value } }) => handleInvestmentTypeChange(value)}
          >
            <FormControlLabel
              value='total'
              control={<Radio color='secondary' size='small' />}
              label='Total'
            />
            <FormControlLabel
              value='minimum'
              control={<Radio color='secondary' size='small' />}
              label='Minimum'
            />
          </RadioGroup>
        }
        {showRiskProfiles &&
          <FormSelect
            sx={formStyles}
            multiple
            variant='standard'
            paperVariant='popup'
            paperColor='light'
            label='Risk Profile(s)'
            value={riskProfiles}
            onChange={({ target: { value } }) => handleRiskProfilesChange(value)}
          >
            {_map(riskProfilesList, rp => <MenuItem key={rp} value={rp}>{_startCase(rp)}</MenuItem>)}
          </FormSelect>
        }
      </Box>
    </Box>
  );
});

const WrappedComponent = forwardRef((props, ref) => {
  return (
    <ErrorWrapper>
      <BuilderProperties {...props} ref={ref} />
    </ErrorWrapper>
  );
});

export default memo(WrappedComponent);