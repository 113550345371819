import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import Scrollbars from 'react-custom-scrollbars-2';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import _map from 'lodash/map';
// Local files
import { Root, ScrollContainer, ItemContainer, Title } from './DealspaceTabContent.styled';
import { getFormattedShortDateWithTime } from 'helpers';
import OfferingsEmpty from './OfferingsEmpty/OfferingsEmpty';
import InfoCard from '../InfoCard/InfoCard';
import InfoSavedCard from '../InfoSavedCard/InfoSavedCard';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useOffers from 'hooks/useOffers';

const DealspaceTabContent = () => {
  const navigate = useNavigate();
  const previousLocation = useLocation();
  const { dealspace_id } = useParams();
  const { setError } = useError();
  const { getDealspaceOffers, createOffer } = useOffers();
  const {
    creator, sharedOffers, sharedOffersCount, hasMoreShared, newSharedOffset, savedOffers, savedOffersCount, hasMoreSaved, newSavedOffset,
    draftOffers, draftOffersCount, hasMoreDraft, newDraftOffset
  } = useCustomSelector(state => ({
    creator: `${state.profile.user.first_name} ${state.profile.user.last_name[0]}.`,
    sharedOffers: state.offers.dealspace.shared.data,
    hasMoreShared: state.offers.dealspace.shared.pagination.total_count > state.offers.dealspace.shared.pagination.count + state.offers.dealspace.shared.offset,
    newSharedOffset: state.offers.dealspace.shared.pagination.limit + state.offers.dealspace.shared.pagination.offset,
    sharedOffersCount: state.offers.dealspace.shared.pagination.total_count,
    savedOffers: state.offers.dealspace.saved.data,
    savedOffersCount: state.offers.dealspace.saved.pagination.total_count,
    hasMoreSaved: state.offers.dealspace.saved.pagination.total_count > state.offers.dealspace.saved.pagination.count + state.offers.dealspace.saved.offset,
    newSavedOffset: state.offers.dealspace.saved.pagination.limit + state.offers.dealspace.saved.pagination.offset,
    draftOffers: state.offers.dealspace.draft.data,
    draftOffersCount: state.offers.dealspace.draft.pagination.total_count,
    hasMoreDraft: state.offers.dealspace.draft.pagination.total_count > state.offers.dealspace.draft.pagination.count + state.offers.dealspace.draft.offset,
    newDraftOffset: state.offers.dealspace.draft.pagination.limit + state.offers.dealspace.draft.pagination.offset,
    dealspace: state.dealspaces.dealspace
  }));
  const [processing, setProcessing] = useState(false);
  const emptyOffers = !sharedOffersCount && !savedOffersCount && !draftOffersCount;

  const fetchOffers = (offset, type) => {
    setProcessing(true);
    getDealspaceOffers({ dealspace_id, offset, type })
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };
  const handleCreateClick = () => {
    const offer = { name: `${getFormattedShortDateWithTime(new Date())} by ${creator}` };
    
    setProcessing(true);
    createOffer({ dealspace_id, offer })
    .then(({ payload: { data: { offer: { id } } } }) =>
      navigate(`/builder/${id}`, { state: { previousLocation, redirectPath: `/dealspaces/${dealspace_id}/offerings` } })
    )
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };
  const handleLoadMoreClick = type => {
    if (type === 'shared') fetchOffers(newSharedOffset, type);
    if (type === 'saved') fetchOffers(newSavedOffset, type);
    if (type === 'draft') fetchOffers(newDraftOffset, type);
  };

  return (
    <Root>
      <Button
        sx={{ alignSelf: 'flex-end', mb: 2 }}
        color='primary'
        variant='contained'
        onClick={handleCreateClick}
      >
        Create Offering
      </Button>
      {/* TODO: сделал костылем так как по аналогии с фидом если делаю - блок имеет высоту 0 пикселей */}
      <ScrollContainer>
        <Scrollbars autoHide autoHideTimeout={800}>
          {emptyOffers && <OfferingsEmpty />}
          {sharedOffersCount > 0 &&
            <ItemContainer>
              <Title>Shared Offerings <span>{sharedOffersCount}</span></Title>
              {_map(sharedOffers, (o) => <InfoCard key={o.id} {...o} type='shared' />)}
            </ItemContainer>
          }
          {hasMoreShared &&
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleLoadMoreClick('shared')}
              disabled={processing}
            >
              Load More
            </Button>
          }
          {savedOffersCount > 0 &&
            <ItemContainer>
              <Title>Saved Offerings <span>{savedOffersCount}</span></Title>
              {_map(savedOffers, (o) => <InfoSavedCard key={o.id} {...o} type='saved' />)}
            </ItemContainer>
          }
          {hasMoreSaved &&
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleLoadMoreClick('saved')}
              disabled={processing}
            >
              Load More
            </Button>
          }
          {draftOffersCount > 0 &&
            <ItemContainer>
              <Title>Draft <span>{draftOffersCount}</span></Title>
              {_map(draftOffers, (o) => <InfoCard key={o.id} {...o} type='draft' />)}
            </ItemContainer>
          }
          {hasMoreDraft &&
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleLoadMoreClick('draft')}
              disabled={processing}
            >
              Load More
            </Button>
          }
        </Scrollbars>
      </ScrollContainer>
    </Root>
  );
};

export default memo(DealspaceTabContent);