import { memo, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Description } from './OfferingStatus.styled';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import DateField from 'components/Common/Inputs/DateField/DateField';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import FormSelect from 'components/Common/Inputs/FormSelect/FormSelect';
import Status from 'components/Offers/Status/Status';
import { fromIsoString } from 'helpers';
import useBatch from 'hooks/useBatch';
import useError from 'hooks/useError';
import useOffers from 'hooks/useOffers';

const getAllowedStatuses = current => {
  const result = [current];

  if (current === 'active') return [...result, 'in_contract', 'ended', 'closed'];
  if (current === 'in_contract') return [...result, 'active', 'ended', 'closed'];
  if (current === 'ended') return [...result, 'active', 'in_contract', 'closed'];
  return result;
};
const getAllowedAction = current => {
  if (current === 'active') return 'activate';
  if (current === 'in_contract') return 'contract';
  if (current === 'ended') return 'end';
  if (current === 'closed') return 'close';
  return null;
};

const OfferingStatus = ({ status, expiredAt, offerId, open, onClose }) => {
  const { sendBatch } = useBatch();
  const { setError } = useError();
  const { setOfferLocalFields } = useOffers();
  const [newStatus, setNewStatus] = useState('');
  const [hasExpiration, setHasExpiration] = useState(false);
  const [newExpiredAt, setNewExpiredAt] = useState({ value: '', formattedValue: null, error: '' });
  const [processing, setProcessing] = useState(false);
  const showCheckbox = status === 'active' || status === 'in_contract';

  const toggleExpiration = ({ target: { checked } }) => {
    setHasExpiration(checked);
    
    if (!checked) {
      setNewExpiredAt({ value: '', formattedValue: null, error: '' });
    }
  };
  const handleExpiredAtChange = ({ value, formattedValue, error }) => { 
    setNewExpiredAt({ value, formattedValue, error }) 
  };
  const handleEntering = () => {
    setNewStatus(status);
    setHasExpiration(!!expiredAt);
    if (!!expiredAt) setNewExpiredAt({ value: expiredAt, formattedValue: fromIsoString(expiredAt), error: '' });
  };
  const handleExited = () => {
    setNewStatus('');
    setHasExpiration(false);
    setNewExpiredAt({ value: '', formattedValue: null, error: '' });
  };
  const handleSaveClick = () => {
    if (status === newStatus && expiredAt === newExpiredAt.value) {
      onClose();
    } else {
      setProcessing(true);

      const requests = [
        ...status !== newStatus ? [{
          method: 'post',
          url: `/offers/${offerId}/${getAllowedAction(newStatus)}`,
          params: {}
        }] : [],
        ...expiredAt !== newExpiredAt.value ? [{
          method: 'patch',
          url: `/offers/${offerId}`,
          params: { offer: { id: offerId, expired_at: newExpiredAt.value || null } }
        }] : []
      ];

      sendBatch(requests)
      .then(() => setOfferLocalFields({ id: offerId, ...expiredAt !== newExpiredAt.value && { expired_at: newExpiredAt.value || null }, ...status !== newStatus && { status: newStatus } }).then(onClose))
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
  };

  return (
    <BaseDialog
      title='Offering Status'
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{ sx: { maxWidth: 510, width: '100%' } }}
      TransitionProps={{ onExited: handleExited, onEntering: handleEntering }}
      actions={
        <>
          <Button
            color='error'
            sx={{ px: '24px', minWidth: 100, minHeight:  37 }}
            disabled={processing}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            variant='contained'
            sx={{ px: '24px', whiteSpace: 'nowrap' }}
            onClick={handleSaveClick}
          >
            Save
          </Button>
        </>
      }
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: '18px !important', }}>
        <Description>
          Statuses are visible to all recipients with whom you’ve shared this offering.
        </Description>
        <FormSelect
          variant='standard'
          paperVariant='popup'
          paperColor='light'
          sx={{ minWidth: '140px', mb: '16px' }}
          value={newStatus}
          onChange={({ target: { value } }) => setNewStatus(value)}
        >
          {_map(getAllowedStatuses(status), s => <MenuItem key={s} value={s}><Status value={s} /></MenuItem>)}
        </FormSelect>
        {showCheckbox &&
          <FormControlLabel
            label='Automatically ‘End’ Offering on a specific date'
            control={
              <Checkbox
                color='secondary'
                checked={hasExpiration}
                onChange={toggleExpiration}
              />
            }
          />
        }
        <Collapse in={hasExpiration}>
          <Box sx={{ width: { xs: '100%', sm: '300px' }, pt: '8px' }}>
            <DateField
              fullWidth
              variant='standard'
              margin='dense'
              label='Expiration Date'
              disablePast
              value={newExpiredAt.formattedValue}
              onChange={handleExpiredAtChange}
              error={!!newExpiredAt.error}
              helperText={newExpiredAt.error}
            />
          </Box>
        </Collapse>
      </DialogContent>
    </BaseDialog>
  );
};

OfferingStatus.propTypes = {
  status: PropTypes.string.isRequired,
  expiredAt: PropTypes.string.isRequired,
  offerId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <OfferingStatus {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);