import { memo, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// Local files
import { Root, Content, Title, Text } from './Notification.styled';
import Icon from './Icon/Icon';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { getShortDate } from 'helpers';
import { notificationTypes } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useError from 'hooks/useError';
import useInvitations from 'hooks/useInvitations';
import useOnScreen from 'hooks/useOnScreen';

const {
  CONTACT_INVITATION_CREATED,
  CONTACT_INVITATION_ACCEPTED,
  GROUP_INVITATION_CREATED,
  GROUP_INVITATION_ACCEPTED,
  DEALSPACE_CREATED,
  RECIPIENT_CREATED,
  OFFER_UNLOCKED,
  AGREEMENT_SIGNED,
  RECIPIENT_APPROVED,
  BOOSTER_NOTIFIED,
  SHARE_FORM_SUBMITTED
} = notificationTypes;

const Notification = ({ id, notification_type, read, user, group, title, subtitle, updated_at, dealspace, offer, invitation, selectedNotification, onRead, onSelect }) => {
  const previousLocation = useLocation();
  const navigate = useNavigate();
  const notificationRef = useRef(null);
  const isVisible = useOnScreen(notificationRef);
  const { openGroupInviteDialog } = useApp();
  const { setError } = useError();
  const { getInvitation } = useInvitations();
  const [disabled, setDisabled] = useState(false);
  const date = getShortDate(updated_at);

  const handleSelectNotification = () => {
    onSelect(id);
    if (notification_type === CONTACT_INVITATION_CREATED) navigate(`/users/${user.username}`);
    if (notification_type === CONTACT_INVITATION_ACCEPTED) return;
    if (notification_type === GROUP_INVITATION_CREATED) {
      setDisabled(true);
      getInvitation(invitation.id)
      .then(({ payload: { data: { invitation: i } } }) => openGroupInviteDialog(i))
      .catch(e => e.response?.status !== 404 && setError(e))
      .finally(() => setDisabled(false));
    }
    if (notification_type === GROUP_INVITATION_ACCEPTED) return;
    if (notification_type === DEALSPACE_CREATED) navigate(`/dealspaces/${dealspace.id}/${!!dealspace.properties_count && dealspace.root_folder ? `files/${dealspace.root_folder.id}` : 'properties'}`);
    if (notification_type === RECIPIENT_CREATED) navigate(`/deal_flow/${offer.id}`, { state: { vertical: 'sharedWithMe', horizontal: 'all' } });
    if (notification_type === OFFER_UNLOCKED) navigate(`/deal_flow/${offer.id}`, { state: { vertical: 'sharedWithMe', horizontal: 'all' } });
    if (notification_type === AGREEMENT_SIGNED) navigate(`/offerings/${offer.id}/activity_log`, { state: { previousLocation } });
    if (notification_type === RECIPIENT_APPROVED) navigate(`/deal_flow/${offer.id}`, { state: { vertical: 'sharedWithMe', horizontal: 'all' } });
    if (notification_type === BOOSTER_NOTIFIED)  navigate(`/offerings/${offer.id}/activity_log`, { state: { previousLocation } });
    if (notification_type === SHARE_FORM_SUBMITTED)  navigate(`/offerings/${offer.id}/activity_log`, { state: { previousLocation } });
  };

  useEffect(() => {
    if (isVisible && !read) {
      onRead();
    }
  }, [isVisible, read]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Root
      selected={selectedNotification === id}
      read={read}
      disabled={disabled}
      onClick={handleSelectNotification}
      ref={notificationRef}
    >
      <Icon
        type={notification_type}
        user={user}
        group={group}
        dealspace={dealspace}
        offer={offer}
        invitation={invitation}
      />
      <Content>
        <Title read={read}>{title}</Title>
        <Box sx={{ display: 'flex' }}>
          <Text read={read} sx={{ flexGrow: 1 }}>{subtitle}</Text>
          <Text read={read} sx={{ flexShrink: 0 }}>{date}</Text>
        </Box>
      </Content>
    </Root>
  );
};

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  notification_type: PropTypes.string.isRequired,
  read: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    photo: PropTypes.shape({
      representations: PropTypes.array
    })
  }),
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    logo: PropTypes.shape({
      representations: PropTypes.array
    })
  }),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  updated_at: PropTypes.string.isRequired,
  dealspace: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  offer: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    headline: PropTypes.string,
    primary_sender: PropTypes.shape({
      photo: PropTypes.shape({
        representations: PropTypes.array
      })
    })
  }),
  invitation: PropTypes.shape({
    id: PropTypes.string,
    invitationable: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      logo: PropTypes.shape({
        representations: PropTypes.array
      })
    })
  }),
  selectedNotification: PropTypes.string,
  onRead: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Notification {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);