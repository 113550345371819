import { instance as axios } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { sendBatchRoutine } from 'actions';
import _map from 'lodash/map';

export const sendBatch = getThunkActionCreator(
  sendBatchRoutine,
  async value => {
    const { accessToken, userableType } = await sessionService.loadSession().catch(e => console.warn(e));

    const requests = _map(value, v => {
      if (v.method === 'patch') return { ...v, url: `/${userableType}${v.url}`, params: { ...v.params, access_token: accessToken } };
      if (v.method === 'get') return { ...v, url: `/${userableType}${v.url}`, params: { ...v.params, access_token: accessToken } };
      if (v.method === 'post') return { ...v, url: `/${userableType}${v.url}`, params: { ...v.params, access_token: accessToken } };
      if (v.method === 'delete') return { ...v, url: `/${userableType}${v.url}`, params: { ...v.params, access_token: accessToken } };
      return v;
    });
    const data = { requests };

    return await axios.post('/batch', data);
  }
);