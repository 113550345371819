import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Root = styled(Box)(() => ({
  width: '100%',
  maxWidth: 400,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
  margin: '0 auto',
  cursor: 'pointer'
}));

export const Step = styled(Box)(() => ({
  position: 'relative',
  flexGrow: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
  padding: '0 8px',
  '& span': {
    whiteSpace: 'nowrap',
    color: '#000',
    fontWeight: 500,
    fontSize: '.75rem'
  }
}));

export const Bullet = styled(Box,
  { shouldForwardProp: (prop) => prop !== 'active' }
)(({ active, theme }) => ({
  height: 14,
  width: 14,
  borderRadius: '50%',
  background: 'rgba(0,0,0,.3)',
  ...(active && {
    background: theme.palette.secondary.main
  })
}));

export const Divider = styled(Box)({
  position: 'absolute',
  top: 7,
  left: 'calc(-50% + 20px)',
  right: 'calc(50% + 20px)',
  flex: '1 1 auto',
  height: 1,
  background: 'rgba(0,0,0, .3)'
});