import { memo, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import _isUndefined from 'lodash/isUndefined';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import Uploader from 'components/Blobs/Uploader/Uploader';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useGroups from 'hooks/useGroups';
import useSuccess from 'hooks/useSuccess';
import useApp from 'hooks/useApp';

const Group = () => {
  const navigate = useNavigate();
  const { createGroup } = useGroups();
  const { closeGroupDialog } = useApp();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { open } = useCustomSelector(state => state.app.groupDialog);
  const [name, setName] = useState('');
  const [logo, setLogo] = useState({ value: null, formattedValue: null });
  const [processing, setProcessing] = useState(false);
  const disabled = !name || processing;

  const onEntering = () => {};
  const onExited = () => {
    setName('');
    setLogo({ value: null, formattedValue: null });
    setProcessing(false);
  };
  const handleSubmit = () => {
    const group = { name, logo: logo.value, localLogo: logo.formattedValue };

    setProcessing(true);
    createGroup(group)
    .then(({ payload: { data: { group: { id } } } }) =>
      setSuccess('Group successfully created')
      .then(closeGroupDialog)
      .then(() => navigate(`/groups/${id}`))
    )
    .catch(e => setError(e).then(() => setProcessing(false)));
  };

  return (
    <BaseDialog
      open={open}
      onClose={closeGroupDialog}
      otherPaperStyle={{ width: '100%', maxWidth: 430 }}
      title='Create Group'
      TransitionProps={{ onEntering, onExited }}
      actions={
        <>
          <Button
            sx={{ px: '20px', minHeight: '37px' }}
            color='error'
            onClick={closeGroupDialog}
          >
            Cancel
          </Button>
          <Button
            sx={{ px: '30px' }}
            variant='contained'
            color='primary'
            disabled={disabled}
            onClick={handleSubmit}
          >
            Create
          </Button>
      </>}
    >
      <DialogContent sx={{ pt: '8px !important', pb: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant='caption' component='div' align='center' sx={{ fontWeight: 600, pb: '16px' }}>Upload logo</Typography>
        <Box sx={{ width: 138, margin: '0 auto' }}>
          <Uploader
            id='groupLogo'
            fullWidth
            accept='image/*'
            label='Select file'
            formattedValue={logo.formattedValue}
            onChange={({ newValue, newFormattedValue }) =>
              setLogo(prev => ({
                ...prev,
                ...(!_isUndefined(newValue) && { value: newValue }),
                ...(!_isUndefined(newFormattedValue) && { formattedValue: newFormattedValue })
              }))
            }
            onDelete={() => setLogo({ value: null, formattedValue: null })}
          />
        </Box>
        <TextField
          fullWidth
          margin='dense'
          sx={{marginTop: '32px', maxWidth: 280}}
          label='Name'
          value={name}
          error={false}
          helperText={''}
          onChange={({ target: { value } }) => setName(value)}
        />
      </DialogContent>
    </BaseDialog>
  );
};

const ErrorFallback = ({ error }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Group {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);