import styled from '@mui/material/styles/styled';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';

export const FormControlLabel = styled(props =>
  <MuiFormControlLabel
    disableTypography
    control={props.control}
    label={props.label}
    sx={{ alignItems: 'flex-start', gap: '8px', width: '100%', overflow: 'hidden', mx: 0, ...props.sx }}
  />
)({});

export const LabelContainer = styled(Box)(() => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden'
}));