import { memo, useRef } from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from 'components/Common/Icons/Search';
import { Autocomplete } from '@react-google-maps/api';
import PropTypes from 'prop-types';
// Local files
import GoogleApiWrapper from '../GoogleApiWrapper/GoogleApiWrapper';

const fieldStyles = {
  position: 'absolute',
  top: '18px',
  left: 'calc(50% - 170px)',
  width: '100%',
  maxWidth: '340px',
  background: 'rgba(255, 255, 255, 0.6)',
  backdropFilter: 'blur(20px)',
  WebkitBackdropFilter: 'blur(20px)',
  borderRadius: '8px'
};

const GoogleAutocomplete = ({ placeholder = 'Type Address', label= '', value, onChange, onSelect, types = ['address'], sx = null, ...otherProps }) => {
  const inputRef = useRef();

  const handlePlaceChange = () => {
    const place = inputRef.current.getPlace();

    if (!!place) {
      onSelect({
        value: place.formatted_address,
        formattedValue: place.formatted_address,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng()
      });
    } 
  };

  return (
    <GoogleApiWrapper type='autocomplete'>
      <Autocomplete
        onLoad={ref => inputRef.current = ref}
        onPlaceChanged={handlePlaceChange}
        options={{ componentRestrictions: { country: ['us', 'ca'] } }}
        types={types}
      >
        <TextField
          InputProps={{ startAdornment: <SearchIcon sx={{ mr: 1 }} />}}
          sx={sx || fieldStyles}
          placeholder={placeholder}
          label={label}
          fullWidth
          value={value}
          onChange={({ target: { value } }) => onChange(value)}
          {...otherProps}
        />
      </Autocomplete>
    </GoogleApiWrapper>
  );
};

GoogleAutocomplete.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default memo(GoogleAutocomplete);