import { memo, useEffect, useRef, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Scrollbars from 'react-custom-scrollbars-2';
import _find from 'lodash/find';
import _map from 'lodash/map';
// import _reduce from 'lodash/reduce';
// import _sum from 'lodash/sum';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Header, CloseButton, Section, SectionHeader, Grid, Action } from './Documents.styled';
import { ReactComponent as UploadIcon } from 'assets/icons/upload-filled.svg';
import { ReactComponent as FileUploadIcon } from 'assets/icons/file-upload.svg';
// import { ReactComponent as FolderUploadIcon } from 'assets/icons/folder-upload.svg';
import FileInput from 'components/Common/Inputs/FileInput/FileInput';
import CloseIcon from 'components/Common/Icons/CloseIcon';
import Folder from 'components/Folders/Card/Card';
import File from 'components/Documents/Card/Card';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import { getRepresentation, /*getFilesRecursively*/ } from 'helpers';
import { representationVariants } from 'helpers/constants';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useBlob from 'hooks/useBlob';
import useChecksum from 'hooks/useChecksum';
import useCustomSelector from 'hooks/useCustomSelector';
import useDocuments from 'hooks/useDocuments';
import useError from 'hooks/useError';
import useFolders from 'hooks/useFolders';

/**
 * @param {string} folderId
 * @param {bool} uploading
 * @param {number=} level 0 - public | 1, 2 - private, 3 - CA
 * @param {array} files
 * @param {array} folders
 * @param {bool} open
 * @param {function} onClose
 * @param {function} onChange
 * @param {function} onLoading
 * @returns {node}
 */

const DocumentsDrawer = ({ folderId, uploading, level, totalSelected, files, folders, open, onClose, onChange, onLoading }) => {
  const fileRef = useRef();
  const { processBlob, percent, loading: loadingBlob, fileName, fileType } = useBlob();
  const { processFiles } = useChecksum();
  const { setError } = useError();
  const { getDealspaceDocuments, clearLocalDealspaceDocuments } = useDocuments();
  const { getFolder, getFolders, clearLocalFolders, clearLocalFolder } = useFolders();
  const {
    dealspaceDocuments, dealspaceDocumentsFetching, needToLoadMoreDealspaceDocuments, newDealspaceDocumentsOffset, dealspaceFolders,
    dealspaceFoldersFetching, needToLoadMoreDealspaceFolders, newDealspaceFoldersOffset
  } = useCustomSelector(state => ({
    dealspaceDocuments: state.documents.dealspace.data,
    dealspaceDocumentsFetching: state.documents.dealspace.fetching,
    needToLoadMoreDealspaceDocuments: state.documents.dealspace.pagination.total_count > state.documents.dealspace.pagination.count + state.documents.dealspace.pagination.offset,
    newDealspaceDocumentsOffset: state.documents.dealspace.pagination.limit + state.documents.dealspace.pagination.offset,
    dealspaceFolders: state.folders.all.data,
    dealspaceFoldersFetching: state.folders.all.fetching,
    needToLoadMoreDealspaceFolders: state.folders.all.pagination.total_count > state.folders.all.pagination.count + state.folders.all.pagination.offset,
    newDealspaceFoldersOffset: state.folders.all.pagination.limit + state.folders.all.pagination.offset,
  }));
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeFolderId, setActiveFolderId] = useState(null);
  const [crumbs, setCrumbs] = useState([]);
  const title = level < 3 ? `${totalSelected} files and folders attached` : '';

  const fetchDocuments = ({ folder_id, offset }) => {
    getDealspaceDocuments({ folder_id, offset })
    .catch(e => setError(e));
  };
  const fetchFolders = ({ folder_id, offset }) => {
    getFolders({ folder_id, offset })
    .catch(e => setError(e));
  };
  const fetchFolder = folder_id => {
    clearLocalDealspaceDocuments()
    .then(() =>
      clearLocalFolders()
      .then(() =>
        getFolder(folder_id)
        .then(({ payload: { data: { folder } } }) => {
          setActiveFolderId(folder.id);
          setCrumbs([...folder.ancestor_folders, folder]);
          fetchDocuments({ folder_id, offset: 0 });
          fetchFolders({ folder_id, offset: 0 });
        })
        .catch(e => setError(e))
      )
    );
  };
  const handleCheckClick = ({ id, checked, type }) => {
    if (checked) return;

    onChange({ id, reason: 'optionSelected', type, level });
  };
  const handleFilesChange = ({ target: { files } }) => {
    const f = Array.from(files);

    onChange({ reason: 'optionUploading', type: 'file', level });
    processFiles(f, ({ file, checksum }) => {
      processBlob({ file, checksum }, blob => {
        if (!blob.id) return;

        const v = {
          file: blob.id,
          name: blob.filename,
          content_type: blob.content_type,
          ...level > 0 && level !== 3 && { locked: true },
          ...level === 2 && { approval_required: true }
        };

        onChange({ v, reason: 'optionUploaded', type: 'file', level });
      });
    });
  };
  const handleCrumbsClick = id => fetchFolder(id);
  const handleEntering = () => {
    fetchFolder(folderId);
  };
  const handleExited = () => {
    clearLocalDealspaceDocuments();
    clearLocalFolders();
    clearLocalFolder();
    setActiveFolderId(null);
    setCrumbs([]);
  };
  const handleScroll = ({ top }) => {
    if (top > 0.99) {
      if (needToLoadMoreDealspaceFolders && !dealspaceFoldersFetching) {
        fetchFolders({ folder_id: activeFolderId, offset: newDealspaceFoldersOffset })
      } else {
        if (needToLoadMoreDealspaceDocuments && !dealspaceDocumentsFetching) {
          fetchDocuments({ folder_id: activeFolderId, offset: newDealspaceDocumentsOffset });
        }
      }
    }
  };
  const handleFileUploadClick = () => {
    setAnchorEl(null);
    fileRef.current.click();
  };
  // const handleFolderUploadClick = async () => {
  //   setAnchorEl(null);
    
  //   const dirHandle = await window.showDirectoryPicker();

  //   if (!dirHandle) return;

  //   let result = [];
    
  //   for await (const fileHandle of getFilesRecursively(dirHandle)) {
  //     result.push(fileHandle);
  //   }

  //   const uploadingItems = _reduce(result, (res, val) => {
  //     if (!!_find(res, r => r.path === val.path)) {
  //       return _map(res, r => r.path === val.path ? ({ ...r, files: [...r.files, val.file] }) : r);
  //     }
  //     return [...res, { path: val.path, files: [val.file], depth: val.depth }];
  //   }, []);

  //   const rootFolder = _find(result, r => r.path.split('/').length === 1)?.path ?? null;

  //   if (rootFolder) {
  //     console.log(uploadingItems);
  //     // setUploadingItem({
  //     //   folder_id: activeFolderId,
  //     //   id: _sum(_map(result, r => r.file.lastModified + r.file.size)),
  //     //   type: 'folder',
  //     //   name: rootFolder,
  //     //   data: uploadingItems,
  //     //   length: result.length
  //     // });
  //   }
  // };
  
  useEffect(() => {
    if (!!loadingBlob) onLoading({ percent, fileName, fileType, locked: level === 1 || level === 2 });
  }, [percent, loadingBlob, fileName, fileType, level, onLoading])

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      ModalProps={{ sx: { zIndex: 1300 } }}
      PaperProps={{ sx: { height: '100%', width: { xs: '100%', md: '458px'}, overflow: 'hidden' } }}
      SlideProps={{ onExited: handleExited, onEntering: handleEntering }}
    >
      <Header>
        <CloseButton disableRipple onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <Typography variant='subtitle2' align='center' mb='28px'>
          Upload file(s) or attach from Dealspace
        </Typography>
        <Button
          variant='outlined'
          startIcon={<UploadIcon />}
          disabled={uploading}
          onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        >
          Upload from computer
        </Button>
        <Popover
          id='newFileOrFolder'
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ marginTop: '7px', padding: 0 }}
        >
          <Box minWidth={157}>
            <Action
              component={ButtonBase}
              sx={{ paddingTop: '15px', paddingBottom: '5px' }}
              onClick={handleFileUploadClick}
            >
              <FileUploadIcon style={{ marginLeft: 3 }} />
              <Typography fontWeight={400} fontSize='12px' lineHeight='24px'>
                Files Upload
              </Typography>
            </Action>
            {/* <Action
              component={ButtonBase}
              sx={{ paddingTop: '5px', paddingBottom: '5px' }}
              onClick={handleFolderUploadClick}
            >
              <FolderUploadIcon />
              <Typography fontWeight={400} fontSize='12px' lineHeight='24px'>
                Folder Upload
              </Typography>
            </Action> */}
          </Box>
        </Popover>
        <FileInput
          multiple
          ref={fileRef}
          accept='*'
          id='newButtonFile'
          onChange={handleFilesChange}
        />
      </Header>
      <Section>
        <SectionHeader>
          {crumbs.length === 1 ?
            <Typography fontSize='12px' lineHeight='23px' variant='caption' sx={{ color: 'rgba(0,0,0,.8)' }}>
              All files
            </Typography> :
            <Breadcrumbs
              crumbs={crumbs}
              onClick={handleCrumbsClick}
            />
          }
          <Typography variant='subtitle2' sx={{ color: 'rgba(0,0,0,.9)' }}>
            {title}
          </Typography>
        </SectionHeader>
        <Scrollbars autoHide autoHideTimeout={1000} onScrollFrame={handleScroll}>
          <Grid>
            {_map(dealspaceFolders, ({ id, name, documents_byte_size, documents_count }) => {
              const checked = !!_find(folders, v => v.folder?.id === id);

              return (
                <Folder
                  key={id}
                  id={id}
                  name={name}
                  checked={checked}
                  size={documents_byte_size}
                  count={documents_count}
                  onCheck={() => handleCheckClick({ id, checked, type: 'folder' })}
                  onClick={() => fetchFolder(id)}
                  isCheckbox={level !== 3}
                  hideMenu
                />
              );
            })}
            {!needToLoadMoreDealspaceFolders && _map(dealspaceDocuments, ({ id, file, created_at }) => {
              const checked = !!_find(files, v => v.document?.id === id);
              const isCA = level === 3 && file.content_type !== 'application/pdf';

              return (
                <File
                  id={id}
                  key={id}
                  disabled={uploading || isCA}
                  checked={checked}
                  onCheck={() => handleCheckClick({ id, checked, type: 'file' })}
                  loading={false}
                  title={file.filename}
                  type={file.content_type}
                  preview={getRepresentation(file.representations, representationVariants.TINY)}
                  showLastActivity
                  date={created_at}
                  isCheckbox={level !== 3 || file.content_type === 'application/pdf'}
                  hideMenu
                />
              );
            })}
            {dealspaceFoldersFetching && <Skeleton animation='wave' width={200} height={150} />}
            {dealspaceDocumentsFetching && <Skeleton animation='wave' width={200} height={150} />}
          </Grid>
        </Scrollbars>
      </Section>
    </Drawer>
  );
};


DocumentsDrawer.propTypes = {
  folderId: PropTypes.string.isRequired,
  uploading: PropTypes.bool.isRequired,
  level: PropTypes.number.isRequired,
  totalSelected: PropTypes.number.isRequired,
  files: PropTypes.array.isRequired,
  folders: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <DocumentsDrawer {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);