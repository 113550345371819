import DOMPurify from 'dompurify';
import currency from 'currency.js';
import { shortMonths } from './constants';
import _capitalize from 'lodash/capitalize';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _isEqualWith from 'lodash/isEqualWith';
import _startCase from 'lodash/startCase';
import _lowerCase from 'lodash/lowerCase';
import _toUpper from 'lodash/toUpper';

export const getRandomString = (length) => {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  
  return result;
};

export const checkValidEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;//eslint-disable-line
  
  return re.test(String(email).toLowerCase());
};

export const getFormattedDate = isoDate => {
  const date = new Date(isoDate);

  return !!isoDate ? `${shortMonths[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}` : '-';
};

export const getFormattedDateWithTime = (isoDate, utc = false) => {
  if (utc) {
    const year = parseInt(isoDate.substring(0, 4));
    const months = parseInt(isoDate.substring(5, 7));
    const days = parseInt(isoDate.substring(8, 10));
    const hours = parseInt(isoDate.substring(11, 13));
    const minutes = parseInt(isoDate.substring(14, 16));

    return !!isoDate ? `${hours >= 12 ? hours - 12 : hours}:${minutes} ${hours >= 12 ? 'PM' : 'AM'}, ${shortMonths[months - 1]} ${days}, ${year}` : '-';
  } else {
    const date = new Date(isoDate);
    const hours = date.getHours() >= 12 ? date.getHours() - 12 > 9 ? date.getHours() - 12 : `0${date.getHours() - 12}` : date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`;
    const minutes = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
    const prepand = date.getHours() >= 12 ? 'PM' : 'AM';
  
    return !!isoDate ? `${hours}:${minutes} ${prepand}, ${shortMonths[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}` : '-';
  }
};

export const getFormattedShortDateWithTime = isoDate => {
  const date = new Date(isoDate);
  const hours = date.getHours() >= 12 ? date.getHours() - 12 > 9 ? date.getHours() - 12 : `0${date.getHours() - 12}` : date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`;
  const minutes = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
  const prepand = date.getHours() >= 12 ? 'PM' : 'AM';

  return !!isoDate ? `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().substring(2)} ${hours}:${minutes} ${prepand}` : '-';
};

export const getFormattedShortDate = isoDate => {
  const date = new Date(isoDate);

  return !!isoDate ? `${shortMonths[date.getMonth()]} ${date.getDate()}` : '-';
};

export const getShortDate = isoDate => {
  const date = new Date(isoDate);

  return !!isoDate ? `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}` : '-';
};

export const getShortDateWithOutYear = isoDate => {
  const date = new Date(isoDate);

  return !!isoDate ? `${date.getMonth() + 1}/${date.getDate()}` : '-';
};

export const checkEmptyString = (str) => !!str.replace(/\s/g, '').length;

export const sanitizedData = (description) => ({
  __html: DOMPurify.sanitize(description, {
    ALLOWED_TAGS: [
      'a', 'abbr', 'acronym', 'address', 'area', 'article', 'aside', 'audio', 'b', 'bdi', 'bdo','big', 'blink', 'blockquote', 'body', 'br',
      'button', 'canvas', 'caption', 'center', 'cite', 'code', 'col', 'colgroup', 'content', 'data', 'datalist', 'dd', 'decorator', 'del',
      'details', 'dfn', 'dialog', 'dir', 'div', 'dl', 'dt', 'element', 'em', 'fieldset', 'figcaption', 'figure', 'font', 'footer', 'form',
      'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'head', 'header', 'hgroup', 'hr', 'html', 'i', 'img', 'input', 'ins', 'kbd', 'label', 'legend',
      'li', 'main', 'map', 'mark', 'marquee', 'menu','menuitem', 'meter', 'nav', 'nobr', 'ol', 'optgroup', 'option', 'output', 'p', 'picture',
      'pre', 'progress', 'q', 'rp', 'rt', 'ruby', 's', 'samp', 'section', 'select', 'shadow', 'small', 'source', 'spacer', 'span', 'strike',
      'strong', 'style', 'sub', 'summary', 'sup', 'table', 'tbody', 'td', 'template', 'textarea', 'tfoot', 'th', 'thead', 'time', 'tr', 'track',
      'tt', 'u', 'ul', 'var', 'video', 'wbr', 'iframe'
    ]
  })
});

export const getFormattedPhone = phone => {
  if (!phone) return '';
  const code = phone.substring(2, 5);
  const firstPart = phone.substring(5, 8);
  const secondPart = phone.substring(8);

  return `(${code}) ${firstPart} ${secondPart}`;
};

export const priceWithCommas = (price) => price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const isValidDate = d => d instanceof Date && !isNaN(d);

export const ucFirst = string => _startCase(_lowerCase(string));

export const getOfferTag = ({ assetGrouping, offerType }) => {
  if (assetGrouping === 'portfolio') {
    if (offerType === 'property_for_sale') return 'Property Portfolio for Sale';
    if (offerType === 'loan_for_sale') return 'Loan Portfolio for Sale';
    if (offerType === 'active_equity_investment') return 'Active Equity Investment';
    if (offerType === 'passive_equity_investment') return 'Passive Equity Investment';
    if (offerType === 'preferred_equity_investment') return 'Preferred Equity Investment';
    if (offerType === 'permanent_loan_request') return 'Permanent Loan Request';
    if (offerType === 'bridge_loan_request') return 'Bridge Loan Request';
    if (offerType === 'partnership_opportunity') return 'Partnership Opportunity';
  }
  if (assetGrouping === 'assemblage') {
    if (offerType === 'land_assemblage_for_sale') return 'Land Assemblage for Sale';
    if (offerType === 'active_equity_investment') return 'Active Equity Investment';
    if (offerType === 'passive_equity_investment') return 'Passive Equity Investment';
    if (offerType === 'preferred_equity_investment') return 'Preferred Equity Investment';
    if (offerType === 'acquisition_loan_request') return 'Acquisition Loan Request';
    if (offerType === 'bridge_loan_request') return 'Bridge Loan Request';
    if (offerType === 'partnership_opportunity') return 'Partnership Opportunity';
  }
  if (assetGrouping === 'fund') {
    if (offerType === 'active_equity_investment' || offerType === 'passive_equity_investment') return 'Fund Equity Offering';
    if (offerType === 'partnership_opportunity') return 'Fund Partnership Opportunity';
  }
  if (offerType === 'property_for_sale') return 'Property for Sale';
  if (offerType === 'development_rights_for_sale') return 'Development Rights for Sale';
  if (offerType === 'loan_for_sale') return 'Loan for Sale';
  if (offerType === 'ground_lease') return 'Ground Lease';
  if (offerType === 'property_for_sale_leaseback') return 'Property for Sale-Leaseback';
  if (offerType === 'active_equity_investment') return 'Active Equity Investment';
  if (offerType === 'passive_equity_investment') return 'Passive Equity Investment';
  if (offerType === 'preferred_equity_investment') return 'Preferred Equity Investment';
  if (offerType === 'permanent_loan_request') return 'Permanent Loan Request';
  if (offerType === 'construction_loan_request') return 'Construction Loan Request';
  if (offerType === 'acquisition_loan_request') return 'Acquisition Loan Request';
  if (offerType === 'bridge_loan_request') return 'Bridge Loan Request';
  if (offerType === 'partnership_opportunity') return 'Partnership Opportunity';
};

export const getOfferPriceLabel = ({ lease_period = null, loan_ratio = null, loan_percent = null, investment_type = null, price_cents = null, custom_price = null }) => {
  if (lease_period) return `${currency(price_cents, { fromCents: true, format: formatPrice }).format()}/${lease_period}`;
  if (loan_ratio) return `${currency(price_cents, { fromCents: true, format: formatPrice }).format()}  ${loan_percent}% ${_toUpper(loan_ratio)}`;
  if (investment_type) return `${currency(price_cents, { fromCents: true, format: formatPrice }).format()} ${_capitalize(investment_type)}`;
  if (price_cents) return `Asking ${currency(price_cents, { fromCents: true, format: formatPrice }).format()}`;

  return custom_price;
};

export const getRepresentation = (representations, variant) => {
  const foundRepresentation = _find(representations, r => r.name === variant);

  return foundRepresentation?.url ?? null;
};

export const formatPrice = currency => {
  if (!!currency.cents()) {
    return `$${Math.abs(currency.dollars()).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${currency.cents()}`;
  }
  return `$${Math.abs(currency.dollars()).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export const getComponentsOffset = ({
  mediaDesktop = false,
  pinPanel = false,
  panelWidth = '0px',
  detailsWidth = '0px',
  mediaDesktopLarge = false,
  expandPanelWidth = '0px',
  detailsLargeWidth = '0px',
  minimizeDetails = false,
  detailsMinimizeMarginLeft = '0px',
  detailsMinimizeWidth = '0px'
}) => {
  // Desktop standart
  if (mediaDesktop) {
    if (!pinPanel && !minimizeDetails) return parseInt(panelWidth) + parseInt(detailsWidth);
    if (!pinPanel && minimizeDetails) return parseInt(panelWidth) + parseInt(detailsMinimizeMarginLeft) + parseInt(detailsMinimizeWidth);
    if (pinPanel && !minimizeDetails) return parseInt(expandPanelWidth) + parseInt(detailsWidth);
    return parseInt(expandPanelWidth) + parseInt(detailsMinimizeMarginLeft) + parseInt(detailsMinimizeWidth);
  }
  if (mediaDesktopLarge) {
    if (!pinPanel && !minimizeDetails) return parseInt(panelWidth) + parseInt(detailsLargeWidth);
    if (!pinPanel && minimizeDetails) return parseInt(panelWidth) + parseInt(detailsMinimizeMarginLeft) + parseInt(detailsMinimizeWidth);
    if (pinPanel && !minimizeDetails) return parseInt(expandPanelWidth) + parseInt(detailsLargeWidth);
    return parseInt(expandPanelWidth) + parseInt(detailsMinimizeMarginLeft) + parseInt(detailsMinimizeWidth);
  }
  return 0;
};

export const needToUpdateField = (a, b) => {
  if (typeof a === 'string' || typeof b === 'string') {
    return a !== b && (checkEmptyString(a ?? '') || checkEmptyString(b ?? ''));
  }
  if (typeof a === 'number' || typeof b === 'number') {
    return a !== b;
  }
  if (typeof a === 'object' || typeof b === 'object') {
    return !_isEqual(a, b) && (!_isEmpty(a) || !_isEmpty(b));
  }
  if (Array.isArray(a) || Array.isArray(b)) {
    return !_isEqualWith(a.sort(), b.sort(), (f, s) => f === s);
  }
  return a !== b;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 B';

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const toIsoString = date => {
  let pad = function(num) {
      return (num < 10 ? '0' : '') + num;
  };

  return date.getFullYear() +
  '-' + pad(date.getMonth() + 1) +
  '-' + pad(date.getDate()) +
  'T' + pad(date.getHours()) +
  ':' + pad(date.getMinutes()) +
  ':' + pad(date.getSeconds()) +
  '.000Z';
};

export const fromIsoString = isoDate => {
  const date = new Date(isoDate);
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  
  return new Date(date);
};

export const getHeightAndWidthFromDataUrl = dataURL => new Promise(resolve => {
  const img = new Image();
  
  img.onload = () => resolve({ height: img.height, width: img.width });
  img.src = dataURL;
});

export const resizeProportionally = (type, width, height) => {
  const maxWidth = 600; // Max width for the image
  const maxHeight = type === 'header' ? 200 : 450;    // Max height for the image
  let ratio = 0;  // Used for aspect ratio
  let w = width;    // Current image width
  var h = height;  // Current image height

  // Check if the current width is larger than the max
  if (width > maxWidth) {
    ratio = maxWidth / width;   // get ratio for scaling image
    h = height * ratio;    // Reset height to match scaled image
    w = width * ratio;    // Reset width to match scaled image
  }
  // Check if current height is larger than max
  if (height > maxHeight) {
    ratio = maxHeight / height; // get ratio for scaling image
    w = width * ratio;    // Reset width to match scaled image
    h = height * ratio;    // Reset height to match scaled image
  }

  return { w, h };
};

export const getImageMetaData = (url, callback) => {
  const img = new Image();

  img.onload = () => callback(null, img);
  img.onerror = err => callback(err);
  img.src = url;
};

export async function* getFilesRecursively(entry, path = null, depth = 0) {
  if (entry.kind === 'file') {
    const file = await entry.getFile();

    if (file && file.name !== '.DS_Store') {
      yield { file, path, depth };
    }
  } else if (entry.kind === 'directory') {
    for await (const handle of entry.values()) {
      yield* getFilesRecursively(handle, !!path ? path + '/' + entry.name : entry.name, depth + 1);
    }
  }
};

export const checkVowels = str => {
  if (!str.length) return false;
  if (str[0] === 'a' || str[0] === 'e' || str[0] === 'i' || str[0] === 'o' || str[0] === 'u' || str[0] === 'y') return true;
  return false;
};

export const getAskingPriceLabel = value => {
  switch (value) {
    case 'active_equity_investment':
      return 'Equity Raise (optional)';
    case 'passive_equity_investment':
      return 'Equity Raise (optional)';
    case 'preferred_equity_investment':
      return 'Equity Raise (optional)';
    case 'ground_lease':
      return 'Lease Price (optional)';
    case 'permanent_loan_request':
      return 'Loan Amount (optional)';
    case 'construction_loan_request':
      return 'Loan Amount (optional)';
    case 'acquisition_loan_request':
      return 'Loan Amount (optional)';
    case 'bridge_loan_request':
      return 'Loan Amount (optional)';
    default:
      return '';
  }
};

export const downloadFile = ({ url, filename }, callback = null) => {
  fetch(url)
  .then((response) => response.blob())
  .then((blob) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");

    link.href = url;
    link.download = filename || 'downloaded-file';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    if (callback) callback();
  })
  .catch((error) => {
    console.error("Error fetching the file:", error);
  });
};