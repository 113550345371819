import { Fragment, memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import _map from 'lodash/map';
import _round from 'lodash/round';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
// Local files
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const AllTimeChart = ({
  hasCA, total_recipients_count, share_recipients_count, visit_recipients_count, email_delivered_recipients_count, offered_recipients_count,
  email_bounced_recipients_count, email_opened_recipients_count, visited_share_recipients_count, visited_visit_recipients_count, market,
  visited_recipients_count, unlocked_recipients_count, replied_recipients_count, tracked_recipients_count, saved_recipients_count,
  unsubscribed_recipients_count
}) => {
  const fields = [
    { title: 'Direct Shares', count: share_recipients_count, total: null, allow: true },
    { title: 'Organic (Market)', count: visit_recipients_count, total: null, allow: market },
    { title: 'Total', count: total_recipients_count, total: null, allow: market },
    { spacer: true, allow: true },
    { title: 'Delivered Email Notifications', count: email_delivered_recipients_count, total: share_recipients_count, allow: true },
    { title: 'Bounced Email Notifications', count: email_bounced_recipients_count, total: share_recipients_count, allow: true },
    { title: 'Unsubscribes', count: unsubscribed_recipients_count, total: email_delivered_recipients_count, allow: true },
    { title: 'Unique Email Opens', count: email_opened_recipients_count, total: email_delivered_recipients_count, allow: true },
    { spacer: true, allow: true },
    { title: 'Direct Unique Visitors', count: visited_share_recipients_count, total: email_delivered_recipients_count, allow: true },
    { title: 'Organic Visitors', count: visited_visit_recipients_count, total: email_delivered_recipients_count, allow: market },
    { title: 'Total Visitors', count: visited_recipients_count, total: email_delivered_recipients_count, allow: market },
    { spacer: true, allow: true },
    { title: 'CA Signed', count: unlocked_recipients_count, total: email_delivered_recipients_count, allow: hasCA },
    { title: 'Replies', count: replied_recipients_count, total: null, allow: true },
    { title: 'Tracking', count: tracked_recipients_count, total: null, allow: true },
    { title: 'Saved', count: saved_recipients_count, total: null, allow: true },
    { title: 'Offers', count: offered_recipients_count, total: null, allow: true },
  ];
  return (
    <Box py={6} width='50%'>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr', gap: '0px 20px' }}>
      {_map(fields, ({ title, count, total, allow, spacer }, index) => allow ?
        <Fragment key={index}>
          {!!spacer ? <><Box sx={{ height: '.725rem' }} /> <Box sx={{ height: '.725rem' }} /> <Box sx={{ height: '.725rem' }} /></> : (
            <>
              <Typography variant='subtitle3' textAlign='right' sx={{ display: 'block', width: '100%', whiteSpace: 'nowrap', pr: '20px' }}>{title}</Typography>
              <Typography variant='caption' textAlign='left' sx={{ display: 'block' }}>
                <NumericFormat value={count} displayType='text' thousandSeparator=',' />
              </Typography>
              <Typography variant='caption'  textAlign='left' fontWeight={700} sx={{ display: 'block' }}>
                {!!total ? `${_round(parseFloat(count / total) * 100, 1)}%` : ''}
              </Typography>
            </>
          )}
        </Fragment>
         : null
      )}
      </Box>
    </Box>
  );
};

AllTimeChart.propTypes = {
  hasCA: PropTypes.bool.isRequired,
  market: PropTypes.bool.isRequired,
  total_recipients_count: PropTypes.number,
  share_recipients_count: PropTypes.number.isRequired,
  visit_recipients_count: PropTypes.number.isRequired,
  email_delivered_recipients_count: PropTypes.number.isRequired,
  offered_recipients_count: PropTypes.number.isRequired,
  email_bounced_recipients_count: PropTypes.number.isRequired,
  email_opened_recipients_count: PropTypes.number.isRequired,
  visited_share_recipients_count: PropTypes.number.isRequired,
  visited_visit_recipients_count: PropTypes.number,
  visited_recipients_count: PropTypes.number,
  unlocked_recipients_count: PropTypes.number.isRequired,
  replied_recipients_count: PropTypes.number.isRequired,
  tracked_recipients_count: PropTypes.number.isRequired,
  saved_recipients_count: PropTypes.number.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <AllTimeChart {...props} />
    </ErrorWrapper>
  );
};
export const Fallback = () => {
  return <Skeleton variant='rectangular' width={560} height={400} />;
};

export default memo(WrappedComponent);