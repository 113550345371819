import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
// Local files
import { Image, StyledButton } from './DealspaceSettings.styled';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import DealspaceMembers from 'components/Members/Dealspace/Dealspace';
import defaultCover from 'assets/images/deal/default-cover.jpg';
import EditIcon from 'components/Common/Icons/EditIcons';
import ImagesDrawer from 'components/Drawers/Images/Images';
import { needToUpdateField, getRepresentation } from 'helpers';
import { representationVariants } from 'helpers/constants';
import useCustomSelector from 'hooks/useCustomSelector';
import useDealspaces from 'hooks/useDealspaces';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';

const DealspaceSettings = ({ open, onClose }) => {
  const { updateDealspace } = useDealspaces();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const activeDealspace = useCustomSelector(state => state.dealspaces.dealspace);
  const [name, setName] = useState('');
  const [cover, setCover] = useState({ value: null, formattedValue: null });
  const [imagesDrawerOpen, setImagesDrawerOpen] = useState(false);
  const [imageUploading, setImagesUploading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const url = !!cover.formattedValue
    ? (!!cover.formattedValue?.localUrl
        ? cover.formattedValue.localUrl
        :  getRepresentation(cover.formattedValue.representations, representationVariants.TINY)
      )
    : defaultCover;

  const handleEntering = () => {
    setName(activeDealspace.name);

    if (activeDealspace.cover) setCover({ value: activeDealspace.cover.id, formattedValue: activeDealspace.cover });
  };
  const handleExited = () => {
    setName('');
    setCover({ value: null, formattedValue: null });
    setProcessing(false);
  };
  const handleEditClick = () => setImagesDrawerOpen(true);
  const handleDrawerChange = ({ v, reason }) => {
    if (reason === 'optionUploading') setImagesUploading(true);
    if (reason === 'optionUploaded') {
      setImagesUploading(false);
      setCover({ value: v.file, formattedValue: { localUrl: v.localUrl } });
      setImagesDrawerOpen(false);
    }
    if (reason === 'optionSelected') {
      setCover({ value: v.file.id, formattedValue: { representations: v.file.representations } });
      setImagesDrawerOpen(false);
    }
  };
  const handleSaveClick = () => {
    const dealspace = {
      ...needToUpdateField(activeDealspace.name, name) && { name },
      ...needToUpdateField(activeDealspace.cover?.id, cover.value) && {
        cover: cover.value,
        localCover: cover.formattedValue
      }
    };

    if (_isEmpty(dealspace)) {
      onClose();
    } else {
      setProcessing(true);
      updateDealspace({ id: activeDealspace.id, ...dealspace })
      .then(() => setSuccess('Dealspace successfully updated').then(onClose))
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{ sx: { maxWidth: 586, width: '100%' } }}
      title='Dealspace Settings'
      TransitionProps={{ onExited: handleExited, onEntering: handleEntering }}
      actions={
        <>
          <Button
            sx={{ px: '20px', minHeight: '37px' }}
            color='error'
            disabled={processing}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            sx={{ px: '30px' }}
            variant='contained'
            color='primary'
            disabled={processing || !name}
            onClick={handleSaveClick}
          >
            Save
          </Button>
        </>
      }
    >
      <ImagesDrawer
        type='dealspaceSettings'
        value={cover.formattedValue ? cover.value : []}
        folderId={activeDealspace.root_folder.id}
        uploading={imageUploading}
        open={imagesDrawerOpen}
        onClose={() => setImagesDrawerOpen(false)}
        onChange={handleDrawerChange}
      />
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: '16px !important' }}>
        <Box sx={{ width: '100%', maxWidth: '389px', margin: '0 auto'}}>
          <TextField
            fullWidth
            placeholder='Dealspace Name'
            value={name}
            onChange={({ target: { value } }) => setName(value)}
          />
          <Typography fontWeight={500} fontSize='12px' lineHeight='15px' marginTop='28px' marginBottom='3px'>
            Dealspace Avatar
          </Typography>
          <Image
            maxWidth={155}
            maxHeight={72}
            sx={{ backgroundImage: `url('${url}')` }}
          >
            <StyledButton onClick={handleEditClick}>
              <EditIcon />
            </StyledButton>
          </Image>
          <DealspaceMembers />
        </Box>
      </DialogContent>
    </BaseDialog>
  );
};

DealspaceSettings.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default memo(DealspaceSettings);