import styled from '@mui/material/styles/styled';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  backgroundColor: '#000000',
  borderRadius: '8px',
  padding: '4px',
  '& .MuiToggleButtonGroup-grouped': {
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: '0.75rem',
    textTransform: 'capitalize',
    borderRadius: '5px',
    border: 'unset',
    padding: '4px 20px',
    margin: 0,
    '& > span': {
      marginLeft: '.3rem'
    },
    '& > .MuiTouchRipple-root': {
      marginLeft: 'unset'
    },
    '&.Mui-disabled': {
      border: 0
    },
    '&.Mui-selected': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundColor: '#484F55'
    },
    '&:not(:first-of-type)': {
      borderRadius: '5px'
    },
    '&:first-of-type': {
      borderRadius: '5px'
    }
  }
});

export const StyledAppBar = styled(AppBar)({
  backgroundColor: '#272B2F',
  boxShadow: 'none'
});

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  gap: '12px',
  flexWrap: 'wrap',
  padding: '10px 16px',
  [theme.breakpoints.up('md')]: {
    padding: '10px 22px 10px 36px'
  }
}));

export const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '5px'
});

export const StyledButton = styled((props) => (
  <Button variant='outlined' {...props} />
))({
  minWidth: 120,
  color: '#BFBFBF',
  borderColor: '#BFBFBF',
  '&:hover': {
    borderColor: '#dcdcdc'
  }
});

export const StyledPrimaryButton = styled((props) => (
  <Button variant='gradient' {...props} />
))({
  minWidth: 60
});