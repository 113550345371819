import styled from '@mui/material/styles/styled';
import ButtonBase from '@mui/material/ButtonBase';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

export const Root = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  overflow: 'hidden',
  background: theme.palette.background.default,
}));

export const NavContainer = styled(Box)({
  height: '100%',
  padding: '14px 0 14px 12px',
});

export const DetailsContainer = styled(Box)({
  flexGrow: 1,
  height: '100%',
  overflowY: 'auto',
  padding: '0px 12px 14px 12px',
});

export const DetailsPaper = styled(Paper)({
  width: '100%',
  borderRadius: '10px',
  boxShadow: 'unset'
});

export const BackButton = styled(ButtonBase)({
  position: 'fixed',
  left: 34, top: 12,
  zIndex: 2,
  minWidth: 80,
  minHeight: 37,
  fontWeight: 600,
  color: '#000',
  background: '#fff',
  borderRadius: '36px',
  paddingLeft: 4,
  paddingRight: 16,
  '& > svg': {
    height: '1rem',
    marginRight: 3
  }
});

export const InnerRoot = styled(Box)({
  position: 'relative',
  marginTop: '14px',
});

export const Logo = styled('img')(({ theme }) => ({
  userSelect: 'none',
  display: 'block',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  maxWidth: 600,
  minHeight: 80,
  maxHeight: 200,
  margin: '0 auto'
}));

export const ScrollButton = styled(ButtonBase)(({ theme }) => ({
  width: '100%',
  maxWidth: 600 - 32,
  height: 56,
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 400,
  color: 'rgba(0,0,0,.7)',
  display: 'flex',
  gap: '8px',
  borderRadius: '4px',
  margin: '0 auto',
  padding: '8px 16px'
}));

export const Description = styled('div')({
  fontSize: '0.75rem',
  paddingTop: '0px',
  '& img': {
    maxWidth: '600px'
  }
});