import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getUserConversationsRoutine,
  getGroupConversationsRoutine,
  getDealspaceConversationsRoutine,
  getOfferConversationsRoutine,
  getConversationRoutine,
  createConversationRoutine,
  createOfferConversationRoutine,
  updateConversationRoutine,
  deleteConversationRoutine,
  addUserToConversationRoutine,
  deleteUserFromConversationRoutine,
  setLocalUserIdsRoutine,
  setLocalConversationRoutine,
  clearLocalUserConversationsRoutine,
  clearLocalConversationsRoutine,
  clearLocalActiveConversationRoutine
} from 'actions';

const attributes = [
  'id', 
  { last_message: ['id', 'read', 'text', { user: ['id', 'first_name', 'last_name'] }, 'created_at'] }, 
  { users: ['id', 'first_name', 'last_name', { photo: ['id', 'representations'] }] }, 
  { conversationable: ['id', 'name', { primary_sender: [{ user: ['id'] }] }, { cover: ['representations'] }, { group: ['id'] }, 'headline', 'unread_conversations_count'] },
  'conversationable_type',
  'private',
  'updated_at'
];

export const getUserConversations = getThunkActionCreator(
  getUserConversationsRoutine,
  async ({ limit = 25, offset = 0, orders = { updated_at: 'desc' }, general = false, feed = false, query = null }) => {
    const params = { limit, offset, orders, attributes, ...general && { general }, ...feed && { feed }, ...query && { query } };
    const response = await axios.get('/conversations', { params });

    return { general, feed, response }
  }
);
export const getGroupConversations = getThunkActionCreator(
  getGroupConversationsRoutine,
  async ({ group_id, limit = 25, offset = 0, orders = { updated_at: 'desc' }, query = null }) => {
    const params = { limit, offset, orders, attributes, offer: true, ...!!query && { query } };

    return await axios.get(`/groups/${group_id}/conversations`, { params });
  }
);
export const getDealspaceConversations = getThunkActionCreator(
  getDealspaceConversationsRoutine,
  async ({ dealspace_id, limit = 25, offset = 0, orders = { updated_at: 'desc' }, query = null }) => {
    const params = { limit, offset, orders, attributes, offer: true, ...!!query && { query } };

    return await axios.get(`/dealspaces/${dealspace_id}/conversations`, { params });
  }
);
export const getOfferConversations = getThunkActionCreator(
  getOfferConversationsRoutine,
  async ({ offer_id, limit = 25, offset = 0, orders = { updated_at: 'desc' }, feed = false, offer = false, query = null }) => {
    const params = { limit, offset, orders, attributes, feed, offer, ...(!!query && { query }) };
    const response = await axios.get(`/offers/${offer_id}/conversations`, { params });

    return { response, feed };
  }
);
export const getConversation = getThunkActionCreator(
  getConversationRoutine,
  async id => {
    const params = { attributes };

    return await axios.get(`/conversations/${id}`, { params });
  }
);
export const createConversation = getThunkActionCreator(
  createConversationRoutine,
  async conversation => {
    const data = { conversation, attributes };

    const response = await axios.post('/conversations', data);

    return { response };
  }
);
export const createOfferConversation = getThunkActionCreator(
  createOfferConversationRoutine,
  async ({ offer_id, conversation, isMyShared }) => {
    const data = { conversation, attributes };
    const response = await axios.post(`/offers/${offer_id}/conversations`, data);

    return { response, isMyShared };
  }
);
export const updateConversation = getThunkActionCreator(
  updateConversationRoutine,
  async conversation => {
    const data = { conversation };

    return await axios.patch(`/conversations/${conversation.id}`, data);
  }
);
export const deleteConversation = getThunkActionCreator(
  deleteConversationRoutine,
  async id => {
    const response = await axios.delete(`/conversations/${id}`);

    return { response, id };
  }
);
export const addUserToConversation = getThunkActionCreator(
  addUserToConversationRoutine,
  async ({ conversation_id, user }) => {
    const data = { attributes };
    const response = await axios.post(`/conversations/${conversation_id}/users/${user?.id}`, data);

    return { response, conversation_id, user };
  }
);
export const deleteUserFromConversation = getThunkActionCreator(
  deleteUserFromConversationRoutine,
  async ({ conversation_id, user_id }) => {
    const response =  await axios.delete(`/conversations/${conversation_id}/users/${user_id}`);

    return { response, conversation_id, user_id };
  }
);
export const setLocalUserIds = getThunkActionCreator(setLocalUserIdsRoutine, async (user_ids = null) => user_ids);
export const setLocalConversation = getThunkActionCreator(setLocalConversationRoutine, async (conversation) => conversation);
export const clearLocalUserConversations = getThunkActionCreator(clearLocalUserConversationsRoutine, async () => {});
export const clearLocalConversations = getThunkActionCreator(clearLocalConversationsRoutine, async () => {});
export const clearLocalActiveConversation = getThunkActionCreator(clearLocalActiveConversationRoutine, async () => {});