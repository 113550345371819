import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const Root = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column'
});
export const ScrollContainer = styled(Box)({
  width: '100%', 
  overflowY: 'auto', 
  height: '70vh' 
});
export const ItemContainer = styled(Box)({
  width: '100%',
  marginBottom: 32,
  '& > button:not(:last-of-type)': {
    marginBottom: 8
  }
});
export const Title = styled((props) => (
  <Typography variant='body2' {...props} />
))({
  color: 'rgba(0,0,0,.7)',
  fontWeight: 500,
  '& > span': {
    color: 'rgba(0,0,0,.3)'
  },
  marginTop: 8,
  marginBottom: 8
});