import {
  getUnreadNotificationsCountRoutine,
  setUnreadNotificationsCountRoutine,
  getNotificationsRoutine,
  getNotificationRoutine,
  readNotificationsRoutine,
  clearNotificationsRoutine
} from 'actions';
import _find from 'lodash/find';
import _map from 'lodash/map';

export const initialState = {
  count: -1,
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 }, fetching: true }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getUnreadNotificationsCountRoutine.SUCCESS: {
      const { data: { pagination: { total_count } } } = action.payload;

      return { ...state, count: total_count };
    }
    case setUnreadNotificationsCountRoutine.SUCCESS: {
      const { count } = action.payload;

      return { ...state, count };
    }
    case getNotificationsRoutine.REQUEST: {
      const all = { ...state.all, fetching: true };

      return { ...state, all };
    }
    case getNotificationsRoutine.SUCCESS: {
      const { data: { notifications, pagination } } = action.payload;
      const all = { data: [...state.all.data, ...notifications], pagination, fetching: false };

      return { ...state, all };
    }
    case getNotificationsRoutine.FAILURE: {
      const all = { ...state.all, fetching: false };

      return { ...state, all };
    }
    case getNotificationRoutine.SUCCESS: {
      const { data: { notification } } = action.payload;
      const all = {
        ...state.all,
        data: [notification, ...state.all.data],
        pagination: { ...state.all.pagination }
      };

      return { ...state, all };
    }
    case readNotificationsRoutine.SUCCESS: {
      const { response: { status }, notification_ids } = action.payload;
      const all = { ...state.all, data: _map(state.all.data, n => !!_find(notification_ids, id => n.id === id) ? ({ ...n, read: true }) : n) };

      return { ...state, ...status === 204 && { all } };

    }
    case clearNotificationsRoutine.SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  };
};

export default reducer;