import { memo } from 'react';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import _map from 'lodash/map';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Root, NavContainer, StyledListItem } from './SharedOfferings.styled';
import Item from '../Item/Item';
import ListSkeleton from 'components/Common/Feedback/ListSkeleton/ListSkeleton';
import Search from 'components/Common/Inputs/Search/Search';
import Status from 'components/Offers/Status/Status';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useCustomSelector from 'hooks/useCustomSelector';

const SharedOfferings = ({ fetching, query, onQueryChange, type, onTypeChange, onLoadMore }) => {
  const { offerings, hasMoreOfferings, activeOfferingsCount, draftOfferingsCount, endedOfferingsCount, inContractOfferingsCount, closedOfferingsCount } = useCustomSelector(state => {
    const offerings = state.offers.shared[type].data;
    const hasMoreOfferings = state.offers.shared[type].pagination.total_count > state.offers.shared[type].pagination.count + state.offers.shared[type].pagination.offset;
    const activeOfferingsCount = state.offers.shared.active.pagination.total_count;
    const draftOfferingsCount = state.offers.shared.draft.pagination.total_count;
    const endedOfferingsCount = state.offers.shared.ended.pagination.total_count;
    const inContractOfferingsCount = state.offers.shared.inContract.pagination.total_count;
    const closedOfferingsCount = state.offers.shared.closed.pagination.total_count;

    return { offerings, hasMoreOfferings, activeOfferingsCount, draftOfferingsCount, endedOfferingsCount, inContractOfferingsCount, closedOfferingsCount };
  });

  return (
    <Root>
      <NavContainer>
        <List>
          {activeOfferingsCount > 0 &&
            <ListItem disablePadding>
              <StyledListItem onClick={() => onTypeChange('active')}>
                <Status withoutText value='active' /> Active <span>{activeOfferingsCount}</span>
              </StyledListItem>
            </ListItem>
          }
          {draftOfferingsCount > 0 &&
            <ListItem disablePadding>
              <StyledListItem onClick={() => onTypeChange('draft')}>
                <Status withoutText value='draft' /> Draft <span>{draftOfferingsCount}</span>
              </StyledListItem>
            </ListItem>
          }
          {endedOfferingsCount > 0 &&
            <ListItem disablePadding>
              <StyledListItem onClick={() => onTypeChange('ended')}>
                <Status withoutText value='ended' /> Ended <span>{endedOfferingsCount}</span>
              </StyledListItem>
            </ListItem>
          }
          {inContractOfferingsCount > 0 &&
            <ListItem disablePadding>
              <StyledListItem onClick={() => onTypeChange('inContract')}>
                <Status withoutText value='in_contract' /> In Contract <span>{inContractOfferingsCount}</span>
              </StyledListItem>
            </ListItem>
          }
          {closedOfferingsCount > 0 &&
            <ListItem disablePadding>
              <StyledListItem onClick={() => onTypeChange('closed')}>
                <Status withoutText value='closed' /> Closed <span>{closedOfferingsCount}</span>
              </StyledListItem>
            </ListItem>
          }
        </List>
      </NavContainer>
      <Container maxWidth={false} disableGutters sx={{ mx: 0, maxWidth: '1015px', display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Search placeholder='search offerings or recipients' value={query} onChange={onQueryChange} />
        {fetching && <ListSkeleton />}
        {_map(offerings, o => <Item key={o.id} {...o} {...{locationType: 'group'}} />)}
        {hasMoreOfferings &&
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Button
              disabled={fetching}
              onClick={onLoadMore}
              variant='outlined'
            >
              Show More
            </Button>
          </Box>
        }
      </Container>
    </Root>
  );
};

SharedOfferings.propTypes = {
  query: PropTypes.string.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <SharedOfferings {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);