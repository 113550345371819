import { getViewsRoutine, viewDealspaceRoutine, clearLocalViewsRoutine } from 'actions';
import _map from 'lodash/map';

export const initialState = {
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getViewsRoutine.SUCCESS: {
      const { data: { views, pagination } } = action.payload;

      return { ...state, all: { data: [...state.all.data, ..._map(views, (v) => v.dealspace)], pagination } };
    }
    case viewDealspaceRoutine.SUCCESS: {
      return state;
    }
    case clearLocalViewsRoutine.SUCCESS: {
      return { ...state, all: initialState.all };
    }
    default: {
      return state;
    }
  };
};

export default reducer;