import { memo } from 'react';
import TextField from '@mui/material/TextField';
import { AsYouType, isPossiblePhoneNumber, validatePhoneNumberLength, parsePhoneNumberFromString } from 'libphonenumber-js';
import PropTypes from 'prop-types';

const PhoneField = ({ label, fullWidth = false, margin = 'none', sx, formattedValue, error = false, helperText = '', onChange, ...otherProps }) => {
  const handleChange = ({ target: { value } }) => {
    const formattedValue = new AsYouType('US').input(value);

    if (validatePhoneNumberLength(formattedValue, 'US') !== 'TOO_LONG') {
      onChange({
        value: parsePhoneNumberFromString(value, 'US')?.number,
        formattedValue,
        error: !!formattedValue.length
          ? isPossiblePhoneNumber(formattedValue, 'US') ? '' : 'Phone is invalid'
          : ''
      });
    }
  };
  
  return (
    <TextField
      fullWidth={fullWidth}
      margin={margin}
      sx={sx}
      label={label}
      value={formattedValue}
      error={error}
      helperText={helperText}
      onChange={handleChange}
      {...otherProps}
    />
  );
};

PhoneField.propTypes = {
  label: PropTypes.string.isRequired,
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  fullWidth: PropTypes.bool,
  sx: PropTypes.object,
  formattedValue: PropTypes.string.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default memo(PhoneField);