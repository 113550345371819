import styled from '@mui/material/styles/styled';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '&:not(:last-of-type)': {
    marginBottom: 16
  }
});
const IconContainer = styled('div')({
  width: 18,
  display: 'flex',
  justifyContent: 'center',
  marginRight: 16
});

export { Container, IconContainer };