import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getChartActivitiesRoutine,
  getTableActivitiesRoutine,
  getActivityRoutine,
  clearChartActivitiesRoutine,
  clearTableActivitiesRoutine
} from 'actions';
import _isUndefined from 'lodash/isUndefined';

export const getChartActivities = getThunkActionCreator(
  getChartActivitiesRoutine,
  async ({ offer_id, unlocked = undefined, tracked = undefined, visited = undefined, email_opened = undefined }) => {
    const attributes = [
      'total_recipients_count',
      'share_recipients_count',
      'visit_recipients_count',
      'unsubscribed_recipients_count',
      'email_delivered_recipients_count',
      'email_bounced_recipients_count',
      'email_opened_recipients_count',
      'visited_share_recipients_count',
      'visited_visit_recipients_count',
      'visited_recipients_count',
      'unlocked_recipients_count',
      'replied_recipients_count',
      'tracked_recipients_count',
      'saved_recipients_count',
      'offered_recipients_count',
      'over_time_dates',
      'over_time_opens',
      'over_time_visits',
      'over_time_unlocks'
    ];
    const params = {
      attributes,
      ...!_isUndefined(unlocked) && { unlocked },
      ...!_isUndefined(tracked) && { tracked },
      ...!_isUndefined(visited) && { visited },
      ...!_isUndefined(email_opened) && { email_opened }
    };

    return await axios.get(`/offers/${offer_id}/recipients/statistics`, { params });
  }
);
export const getTableActivities = getThunkActionCreator(
  getTableActivitiesRoutine,
  async ({ offer_id, limit = 25, offset = 0, orders = { created_at: 'desc' }, query = null, unlocked = undefined, tracked = undefined, visited = undefined, email_opened = undefined }) => {
    const attributes = [
      'id',
      {
        recipient: [
          { user: ['id', 'first_name', 'last_name', 'email', 'company_name', 'device', 'actual_location', { photo: ['representations'] }] },
          'first_name',
          'last_name',
          'email'
        ]
      },
      'activity_type',
      'visits_duration',
      'created_at'
    ];
    const params = {
      limit,
      offset,
      orders,
      ...query && { query },
      attributes,
      ...!_isUndefined(unlocked) && { unlocked },
      ...!_isUndefined(tracked) && { tracked },
      ...!_isUndefined(visited) && { visited },
      ...!_isUndefined(email_opened) && { email_opened }
    };

    return await axios.get(`/offers/${offer_id}/activities`, { params });
  }
);
export const getActivity = getThunkActionCreator(
  getActivityRoutine,
  async id => {
    const attributes = [
      'id',
      { recipient: [{ user: ['id', 'first_name', 'last_name', 'email', 'company_name', 'device', 'actual_location', { photo: ['representations'] }] }] },
      'activity_type',
      'visits_duration',
      'updated_at'
    ];
    const params = { attributes };

    return await axios.get(`/activities/${id}`, { params });
  }
);
export const clearChartActivities = getThunkActionCreator(clearChartActivitiesRoutine, async () => {});
export const clearTableActivities = getThunkActionCreator(clearTableActivitiesRoutine, async () => {});