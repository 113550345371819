import { memo, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import _map from 'lodash/map';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import FormSelect from 'components/Common/Inputs/FormSelect/FormSelect';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useGroups from 'hooks/useGroups';

const Select = ({ disabled, value, onChange }) => {
  const { setError } = useError();
  const { getGroups } = useGroups();
  const { groups, hasMore, newOffset } = useCustomSelector(state => ({
    groups: state.groups.all.data,
    hasMore: state.groups.all.pagination.total_count > state.groups.all.pagination.count + state.groups.all.pagination.offset,
    newOffset: state.groups.all.pagination.limit + state.groups.all.pagination.offset
  }));
  const [loading, setLoading] = useState(false);

  const fetchGroups = offset => {
    setLoading(true);
    getGroups({ offset })
    .catch(e => setError(e))
    .finally(() => setLoading(false));
  };
  const handleScroll = ({ target }) => {
    if (target.scrollTop === target.scrollHeight - target.clientHeight && !loading && hasMore) {
      fetchGroups(newOffset);
    }
  };

  return (
    <FormSelect
      required
      disabled={disabled}
      label='Deal Group'
      SelectProps={{
        MenuProps: {
          PaperProps: { variant: 'outlined', sx: { marginTop: '4px', boxShadow: 'none !important' } }
        }
      }}
      onScroll={handleScroll}
      sx={{ flexShrink: 0, width: '100%' }}
      value={value}
      onChange={({ target: { value } }) => onChange(value)}
    >
      {_map(groups, ({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
    </FormSelect>
  );
};

Select.propTypes = {
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Select {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);