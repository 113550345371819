import { memo, useEffect, useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import _dropRight from 'lodash/dropRight';
import _last from 'lodash/last';
import _map from 'lodash/map';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Root, OfferCardSkeleton } from './List.styled';
import Card from 'components/Offers/FlowCard/FlowCard';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useCustomSelector from 'hooks/useCustomSelector';

/**
 * @param {array=} data
 * @param {string} vertical
 * @param {string} horizontal
 * @param {function} onBottom
 * @returns {node}
 */

const List = ({ data = null, vertical, horizontal, onBottom }) => {
  const observerTarget = useRef(null);
  const disabled = !!data;
  const { offers, fetching } = useCustomSelector(state => {
    const offers = state.offers.dealflow[vertical][horizontal].data;
    const fetching = state.offers.dealflow[vertical][horizontal].fetching && (state.profile.user.id || state.profile.recipient.id);
    
    return { offers, fetching };
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          onBottom();
        }
      },
      { threshold: 1 }
    );
  
    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }
  
    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current); // eslint-disable-line
      }
    };
  }, [observerTarget, onBottom]);

  return (
    <Scrollbars
      autoHide
      autoHideTimeout={800}
    >
      <Root>
        {_map(data ?? _dropRight(offers), d =>
          <Card
            key={d.id}
            disabled={disabled}
            {...d}
          />
        )}
        {!fetching && !data && <div ref={observerTarget} />}
        {!data && !!_last(offers) &&
          <Card
            disabled={disabled}
            {..._last(offers)}
          />
        }
        {fetching && !data && <OfferCardSkeleton />}
      </Root>
    </Scrollbars>
  );
};

List.propTypes = {
  data: PropTypes.array,
  vertical: PropTypes.string.isRequired,
  horizontal: PropTypes.string.isRequired,
  onBottom: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <List {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);