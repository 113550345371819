import { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
// Local files
import { Root, avatarSx } from './Header.styled';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { getRepresentation } from 'helpers';
import { representationVariants } from 'helpers/constants';

const Header = ({ disabled, user, step, onClick }) => {
  const showButton = step !== 'share';
  const buttonText = step === 'offeringInfo' ? 'Next' : 'Finalize';
  const src = user.photo ? getRepresentation(user.photo.representations, representationVariants.TINY) : null;

  return (
    <Root>
      <BaseAvatar
        src={src}
        firstName={user.first_name}
        lastName={user.last_name}
        sx={avatarSx}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant='subtitle2'>{user.first_name} {user.last_name}</Typography>
        <Typography variant='body2'>Deal Intake Form</Typography>
      </Box>
      {showButton &&
        <Button
          disabled={disabled}
          variant='gradient'
          sx={{ minWidth: 130 }}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      }
    </Root>
  );
};

Header.propTypes = {
  disabled: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    photo: PropTypes.shape({
      representations: PropTypes.array
    }),
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired
  }),
  step: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Header {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);