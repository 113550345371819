import styled from '@mui/material/styles/styled';

export const Heading = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  flexWrap: 'wrap',
  paddingBottom:  8,
});

export const Paper = styled('div')({
  width: '100%',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  boxShadow: '0px 0px 7px 0px #0000001A',
  padding: '18px',
});