import { memo, useCallback, useEffect, useState, useMemo } from 'react';
import { List, useAutocomplete, Box, TextField, Typography, MenuItem, Menu, CircularProgress } from '@mui/material';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';
import _uniqBy from 'lodash/uniqBy';
// Local files
import useCustomSelector from 'hooks/useCustomSelector';
import useConversations from 'hooks/useConversations';
import useError from 'hooks/useError';
import useContacts from 'hooks/useContacts';

const ParticipantsSelect = () => {
  const { getConversation, setLocalUserIds, createConversation, addUserToConversation, deleteUserFromConversation } = useConversations();
  const { getContactsList, clearLocalContactsList } = useContacts();
  const { setError } = useError();
  const { options, conversation_id, users, conversations } = useCustomSelector(state => ({
    options: _uniqBy(_map(state.contacts.list.data, (c) => c.user), 'id'),
    conversation_id: state.conversations.conversation.id,
    users: state.conversations.conversation.users,
    conversations: state.conversations.user.general.data
  }));
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'contactsAutocomplete',
    options,
    getOptionLabel: (option) => `${option?.first_name || ''} ${option?.last_name || ''}`,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [query, setQuery] = useState('');
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const isDeleteUser = useMemo(() => !!_find(users, (user) => user?.id === value?.id), [users, value]);
  const user = useMemo(() => _find(options, (o) => o?.id === value?.id), [options, value]);

  const fetchContacts = useCallback((offset, query) => {
    setLoading(true);
    getContactsList({ offset, query, member: true })
    .catch(e => setError(e))
    .finally(() => setLoading(false));
  }, [getContactsList, setError]);
  const handleChange = (newValue) => {
    setValue(newValue);
    setAnchorEl(document.getElementById('contactsAutocomplete'));
  };
  const handleAddOrRemoveUser = () => { 
    const conversation = _find(conversations, (conversation) => 
      _isEqual(_map(conversation.users, (user) => user.id).sort(), _map(isDeleteUser ? _filter(users, u => u.id !== value) : [...users, user], (user) => user.id).sort()));

    setProcessing(true);
    setAnchorEl(null);
    setLocalUserIds(null);

    if (!!conversation_id) {
      if (!!!conversation) {
        if (isDeleteUser) {
          users.length > 3 ? 
            deleteUserFromConversation({ conversation_id, user_id: value.id })
            .catch((e) => setError(e))
            .finally(() => setProcessing(false)) : 
            setError(`You can only delete if there are more than 3 participants in the chat room`);
        } else {
          addUserToConversation({ conversation_id, user })
          .catch((e) => setError(e))
          .finally(() => setProcessing(false));
        }
      } else {
        getConversation(conversation?.id)
        .then(() => setError(`You already have the chat with this user/s`))
        .catch((e) => setError(e))
        .finally(() => setProcessing(false));
      }
    } else {
      createConversation({ user_ids: [value.id], private: true })
      .catch((e) => setError(e))
      .finally(() => setProcessing(false));;
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => clearLocalContactsList().then(() => fetchContacts(0, query)), 500);

    return () => clearTimeout(timeout);
  }, [fetchContacts, clearLocalContactsList, query]);

  return (
    <>
      <Box {...getRootProps} sx={{ width: '280px' }}>
        <TextField
          {...getInputProps()}
          fullWidth
          placeholder='Enter email or Qwincy member'
          InputProps={{
            endAdornment: <>
              {loading && <CircularProgress color="inherit" size={20} />}
            </>
          }}
          onChange={({ target: { value } }) => setQuery(value)}
          value={query}
        />
        {groupedOptions.length > 0 ? (
          <Box 
            sx={{ backgroundColor: '#fff', position: 'absolute', zIndex: 1350, borderRadius: '8px', width: '280px' }}
          >
            <List {...getListboxProps()}>
              {groupedOptions.map((option, index) => (
                <MenuItem
                  {...getOptionProps({ option, index })} 
                  key={option.id} 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleChange(option);
                  }}>
                    <Typography noWrap align='left'>{`${option?.first_name || ''} ${option?.last_name || ''}`}</Typography>
                </MenuItem>
              ))}
            </List>
          </Box>
        ) : null}
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl && !!value}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem disabled={processing} onClick={handleAddOrRemoveUser}>{isDeleteUser ? '- Delete member' : '+ Add member'}</MenuItem>
      </Menu>
    </>
  );
};

export default memo(ParticipantsSelect);