import { memo, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Navigate, useSearchParams } from 'react-router-dom';

const NylasRedirect = () => {
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState(null);

  useEffect(() => {
    if (searchParams.has('code') && searchParams.has('state')) {
      setParams({ code: searchParams.get('code'), email: searchParams.get('state') });
    }
  }, [searchParams]);

  if (params) return <Navigate to={`/extension?code=${params.code}&email=${params.email}`} />;
  return <Typography variant='subtitle1'>Loading...</Typography>;
};

export default memo(NylasRedirect);