import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  addToTracksOfferRoutine,
  removeFromTracksOfferRoutine
} from 'actions';

export const addToTracksOffer = getThunkActionCreator(
  addToTracksOfferRoutine,
  async id => {
    const response = await axios.post(`/offers/${id}/track`);

    return { response, id };
  }
);
export const removeFromTracksOffer = getThunkActionCreator(
  removeFromTracksOfferRoutine,
  async id => {
    const response = await axios.delete(`/offers/${id}/track`);

    return { response, id };
  }
);