import { memo } from 'react';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

const Switcher = ({ value, onClick }) => {
  return (
    <Box
      width={204}
      height={31}
      sx={{ backgroundColor: '#F1F3F6', borderRadius: '5px' }}
      display='flex'
      alignItems='center'
      justifyContent='center'
      gap='10px'
      marginBottom='20px'
    >
      <ButtonBase
        sx={{ backgroundColor: value === 'signUp' ? '#3451D2' : 'transparent', width: 95, height: 27, borderRadius: '5px' }}
        onClick={() => onClick('signUp')}
      >
        <Typography fontWeight={value === 'signUp' ? 600 : 500} fontSize='12px' lineHeight='20px' color={value === 'signUp' ? '#FFFFFF' : '#000000'}>
          Sign Up
        </Typography>
      </ButtonBase>
      <ButtonBase
        sx={{ backgroundColor: value === 'signIn' ? '#3451D2' : 'transparent', width: 95, height: 27, borderRadius: '5px' }}
        onClick={() => onClick('signIn')}
      >
        <Typography fontWeight={value === 'signIn' ? 600 : 500} fontSize='12px' lineHeight='20px' color={value === 'signIn' ? '#FFFFFF' : '#000000'}>
          Sign In
        </Typography>
      </ButtonBase>
    </Box>
  );
};

export default memo(Switcher);