import { memo, useEffect, useRef, useState } from 'react';
import { useParams, useOutletContext } from 'react-router-dom';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
//Local files
import AudioPlayer from 'components/Common/AudioPlayer/AudioPlayer';
import ChatFab from 'components/Conversations/BubbleChat/ChatFab/ChatFab';
import ChatPopover from 'components/Conversations/BubbleChat/ChatPopover/ChatPopover';
import useCustomSelector from 'hooks/useCustomSelector';
import useConversations from 'hooks/useConversations';
import useMessages from 'hooks/useMessages';

const BubbleChat = ({ checking }) => {
  const audioRef = useRef(null);
  const { offer_id } = useParams();
  const [onTheBottom, setOnTheBottom] = useOutletContext();
  const { setLocalUserIds, setLocalConversation, clearLocalActiveConversation } = useConversations();
  const { clearLocalMessages } = useMessages();
  const { conversations, unreadConversations, user, senders, user_ids, offerContent, conversation, my_id, isMyShared, isHasMessages, authorizedAsUser } = useCustomSelector(state => ({
    conversations: _filter(state.conversations.user.feed.data
      .concat(state.conversations.offerings.data), (c) => c?.conversationable?.id === offer_id),
    unreadConversations: _filter(state.conversations.user.feed.data
      .concat(state.conversations.offerings.data), (c) => c?.conversationable?.id === offer_id && c?.last_message?.user?.id !== state.profile.user.id && 
      c?.last_message !== null && !c?.last_message?.read).length,
    user: state.offers.dealflow.details.offer.primary_sender?.user,
    senders: _map(_filter(state.senders.all.data, (s) => s?.user?.id !== state.profile.user.id), (s) => s.user),
    user_ids: state.conversations.user_ids,
    offerContent: {..._pick(state.offers.dealflow.details.offer, ['tag', 'headline', 'dealspace']), cover: _find(state.images.offer.data, (i) => i?.main) || null },
    conversation: state.conversations.conversation,
    my_id: state.profile.user.id,
    isMyShared: !!_find(state.senders.all.data, ({ user }) => user?.id === state.profile.user.id),
    isHasMessages: !!_find(state.conversations.user.feed.data
      .concat(state.conversations.offerings.data), (c) => c?.conversationable?.id === offer_id && !!c?.last_message),
    authorizedAsUser: state.sessions.session.userableType === 'user'
  }));
  const [chatFab, setChatFab] = useState({ open: false });
  const [chatPopover, setChatPopover] = useState({ open: false });
  const [showMessages, setShowMessages] = useState(false);
  const chatFabProps = { user, unread: !!unreadConversations };
  const chatPopoverProps = { senders, user_ids, offerContent, conversations, unreadConversationsLength: unreadConversations, conversation, my_id, isMyShared, showMessages, onShowMessages: setShowMessages };

  const handleOpenChat = () => {
    setChatPopover({ open: true });
    setChatFab({ open: false });
    if (!!conversations.length) {
      if(senders.length === 1) {
        setLocalConversation(conversations[0]);
      }
    } else {
      setLocalUserIds(_map(senders, (u) => u.id));
    }
  };

  const handleCloseChat = (clear = false) => {
    setChatPopover({ open: false });
    setChatFab({ open: !clear });
    setLocalUserIds(null);
    clearLocalActiveConversation();
    clearLocalMessages();
    setShowMessages(false);
  };

  useEffect(() => {
    return () => {
      handleCloseChat(true);
      setOnTheBottom(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    let timeout = null;
    console.log(isHasMessages)
    if (!checking && isHasMessages) {
      setTimeout(() => {
        audioRef.current?.playBubbleChatSound();
        handleOpenChat();
      }, 5000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [checking, isHasMessages]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    let timeout = null;

    if (!checking && !isHasMessages && onTheBottom) {
      setTimeout(() => {
        setChatFab({ open: true });
        audioRef.current?.playBubbleChatSound();
      }, 3000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [checking, isHasMessages, onTheBottom]);

  if (!authorizedAsUser || !offer_id) return null;
  return (
    <>
      <AudioPlayer ref={audioRef} />
      <ChatFab {...chatFab} {...chatFabProps} onOpenChat={handleOpenChat} />
      <ChatPopover 
        {...chatPopover} 
        {...chatPopoverProps} 
        onClose={() => handleCloseChat()} 
      />
    </>
  );
};

export default memo(BubbleChat);