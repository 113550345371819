import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import _get from 'lodash/get';
import axios from 'axios';
// Local files
import { errorTypes, errorMessages } from 'helpers/constants';

const { AUTHORIZE_DATA_INVALID, ACCESS_FORBIDDEN, RECORD_NOT_FOUND, TOO_MANY_REQUESTS } = errorMessages;
const { AUTHORIZE_DATA_INVALID: AUTHORIZE_DATA_INVALID_TYPE } = errorTypes;

const useError = () => {
  const { enqueueSnackbar } = useSnackbar();
  const showError = useCallback(error => {
    if (!error.isCancel) enqueueSnackbar(error, { variant: 'error' });
  }, [enqueueSnackbar]);
  const setError = useCallback((error, authorization = false) => new Promise(resolve => {
    if (axios.isCancel(error)) {
      resolve(showError({ isCancel: true }));
      console.log('CANCEL');
      return;
    }
    const type = error?.response?.data?.error?.type;
    const status = _get(error, 'response.status') || null;
    const defaultMessage = typeof error === 'string' ? error : 'Failed to load data';
    let customErrorText = false;
    let message = '';

    if (_get(error, 'response.data.error.message')) {
      message = error.response.data.error.message;
      customErrorText = true;
    }
    if (status === 401) {
      message = authorization ? AUTHORIZE_DATA_INVALID : error.response?.data?.error?.message || 'Something went wrong';
      customErrorText = true;
    }
    if (status === 403) {
      message = ACCESS_FORBIDDEN;
      customErrorText = true;
    }
    if (status === 404) {
      message = RECORD_NOT_FOUND;
      customErrorText = true;
    }
    if (status === 429) {
      message = TOO_MANY_REQUESTS;
      customErrorText = true;
    }
    if (type === AUTHORIZE_DATA_INVALID_TYPE) {
      message = AUTHORIZE_DATA_INVALID;
      customErrorText = true;
    }
    if (type === AUTHORIZE_DATA_INVALID_TYPE && !authorization) {
      resolve(null);
    } else {
      resolve(showError(customErrorText ? message : defaultMessage, status));
    }
  }), [showError]);

  return { setError };
};

export default useError;