import { memo } from 'react';
import { Routes, Route, useLocation, Outlet } from 'react-router-dom';
// Components
import Layout from 'components/App/Layout/Layout';
import GroupDetails from 'components/Groups/Details/Details';
import GroupSettings from 'components/Groups/Settings/Settings';
import GroupSettingsGeneral from 'components/Groups/Settings/General/General';
import GroupSettingsUsers from 'components/Groups/Settings/Users/Users';
import DealspaceDetails from 'components/Dealspaces/Details/Details';
import OfferDetails from 'components/Offers/Details/Details';
import ProtectedUserOrRecipientRoute from './ProtectedUserOrRecipientRoute/ProtectedUserOrRecipientRoute';
import ProtectedUserRoute from './ProtectedUserRoute/ProtectedUserRoute';
// Dialogs
import Builder from 'components/Dialogs/Builder/Builder';
// Drawers
import ActivityLog from 'components/Drawers/ActivityLog/ActivityLog';
import Chats from 'components/Drawers/Chats/Chats';
import Notifications from 'components/Drawers/Notifications/Notifications';
// Views
import Contacts from 'views/Contacts/Contacts';
import DealFlow from 'views/DealFlow/DealFlow';
import Dealspace from 'views/Dealspace/Dealspace';
import Dashboard from 'views/Dashboard/Dashboard';
import Groups from 'views/Groups/Groups';
import Group from 'views/Group/Group';
import Profile from 'views/Profile/Profile';
import SignedGateAgreements from 'views/SignedGateAgreements/SignedGateAgreements';
import User from 'views/User/User';
import Intake from 'views/Intake/Intake';
import Unsubscribe from 'views/Unsubscribe/Unsubscribe';
import Extension from 'views/Extension/Extension';
import ShareForm from 'views/ShareForm/ShareForm';
import NylasRedirect from 'views/NylasRedirect/NylasRedirect';

const App = () => {
  const location = useLocation();
  const previousLocation = location.state?.previousLocation;

  return (
    <>
      <Routes location={previousLocation || location}>
        <Route element={<Layout />}>
          <Route index element={<DealFlow />} />
          <Route path='/sign_in' element={<DealFlow mode='signIn' />} />
          <Route path='/sign_up' element={<DealFlow mode='signUp' />} />
          <Route path='/r/:recipient_token' element={<DealFlow />} />
          <Route path='/i/:invitation_token' element={<DealFlow />} />
          <Route path='/p/:invitation_token' element={<DealFlow />} />
          <Route path='/c/:conversation_id' element={<DealFlow />} />
          <Route path='/dashboard' element={<ProtectedUserRoute><Dashboard /></ProtectedUserRoute>} />
          <Route path='/groups' element={<ProtectedUserRoute><Groups /></ProtectedUserRoute>}>
            <Route path=':group_id' element={<ProtectedUserRoute><Group /></ProtectedUserRoute>}>
              <Route index element={<ProtectedUserRoute><GroupDetails /></ProtectedUserRoute>} />
              <Route path=':type_id' element={<ProtectedUserRoute><GroupDetails /></ProtectedUserRoute>} />
              <Route path='settings' element={<ProtectedUserRoute><GroupSettings /></ProtectedUserRoute>}>
                <Route index element={<ProtectedUserRoute><GroupSettingsGeneral /></ProtectedUserRoute>} />
                <Route path='general' element={<ProtectedUserRoute><GroupSettingsGeneral /></ProtectedUserRoute>} />
                <Route path='users' element={<ProtectedUserRoute><GroupSettingsUsers /></ProtectedUserRoute>} />
              </Route>
            </Route>
          </Route>
          <Route path='dealspaces/:dealspace_id' element={<ProtectedUserRoute><Dealspace /></ProtectedUserRoute>}>
            <Route path='properties/' element={<ProtectedUserRoute><DealspaceDetails type='properties' /></ProtectedUserRoute>} />
            <Route path='properties/:property_id' element={<ProtectedUserRoute><DealspaceDetails type='properties' /></ProtectedUserRoute>} />
            <Route path='notes' element={<ProtectedUserRoute><DealspaceDetails type='notes' /></ProtectedUserRoute>} />
            <Route path='files' element={<ProtectedUserRoute><DealspaceDetails type='files' /></ProtectedUserRoute>} />
            <Route path='files/:folder_id' element={<ProtectedUserRoute><DealspaceDetails type='files' /></ProtectedUserRoute>} />
            <Route path='offerings' element={<ProtectedUserRoute><DealspaceDetails type='offerings' /></ProtectedUserRoute>} />
            <Route path='offerings/:offer_id' element={<ProtectedUserRoute><DealspaceDetails type='offerings' /></ProtectedUserRoute>} />
          </Route>
          <Route path='/deal_flow' element={<DealFlow />}>
            <Route path=':offer_id' element={<ProtectedUserOrRecipientRoute><OfferDetails /></ProtectedUserOrRecipientRoute>} />
          </Route>
          <Route path='/contacts' element={<ProtectedUserRoute><Contacts /></ProtectedUserRoute>} />
          <Route path='/users/:username' element={<Profile />} />
          <Route path='/signed_gate_agreements' element={<ProtectedUserRoute><SignedGateAgreements /></ProtectedUserRoute>} />
          <Route path='/builder/:offer_id' element={<Outlet />} />
          <Route path='/offerings/:offer_id/activity_log' element={<ProtectedUserRoute><ActivityLog /></ProtectedUserRoute>} />
          <Route path='/chats/*' element={<ProtectedUserRoute><Outlet /></ProtectedUserRoute>} />
        </Route>
        <Route path='/users/:username/:user_id' element={<User />} />
        <Route path='/users/:username/intake' element={<Intake />} />
        <Route path='/users/:username/intake/:step_id' element={<Intake />} />
        <Route path='/unsubscribe/:r_t' element={<Unsubscribe />} />
        <Route path='/extension' element={<Extension />} />
        <Route path='/share_form/:offer_id' element={<ShareForm />} />
        <Route path='/nylas_redirect' element={<NylasRedirect />} />
      </Routes>
      {previousLocation &&
        <Routes>
          <Route path='/builder/:offer_id' element={<ProtectedUserRoute><Builder /></ProtectedUserRoute>} />
          <Route path='/offerings/:offer_id/activity_log' element={<ProtectedUserRoute><ActivityLog /></ProtectedUserRoute>} />
          <Route path='/chats/:chat_level/:chat_level_id' element={<ProtectedUserRoute><Chats /></ProtectedUserRoute>} />
          <Route path='/notifications' element={<ProtectedUserRoute><Notifications /></ProtectedUserRoute>} />
        </Routes>
      }
    </>
  );
};

export default memo(App);