import SvgIcon from '@mui/material/SvgIcon';

export default function SaveIcon({ color='success', ...otherProps }) {
  return (
    <SvgIcon color={color} {...otherProps} >
      <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M12 4C7.58125 4 4 7.58125 4 12C4 16.4188 7.58125 20 12 20C16.4188 20 20 16.4188 20 12C20 7.58125 16.4188 4 12 4ZM15.2703 8.77498L10.1531 13.9078L8.81871 11.5719C8.66402 11.3015 8.32183 11.2078 8.05152 11.3625C7.78121 11.5172 7.68746 11.8594 7.84214 12.1297L9.54214 15.1062C9.64527 15.2875 9.83589 15.3906 10.0312 15.3906C10.1265 15.3906 10.2218 15.3672 10.3093 15.3172C10.3593 15.2875 10.4046 15.2531 10.4421 15.2125L10.4484 15.2062L16.0656 9.57186C16.2843 9.35155 16.2843 8.9953 16.064 8.77655C15.8453 8.55467 15.4906 8.55467 15.2703 8.77498ZM14.675 18.3344C15.4938 17.9891 16.2297 17.4922 16.8609 16.8609C17.4938 16.2297 17.9891 15.4938 18.3359 14.675C18.6938 13.8281 18.875 12.9281 18.875 12C18.875 11.0719 18.6938 10.1719 18.3344 9.32344C17.9891 8.50469 17.4922 7.76875 16.8609 7.1375C16.2281 6.50625 15.4938 6.01094 14.675 5.66406C13.8281 5.30625 12.9281 5.125 12 5.125C11.0719 5.125 10.1719 5.30625 9.32344 5.66562C8.50469 6.01094 7.76875 6.50781 7.1375 7.13906C6.50625 7.77188 6.01094 8.50625 5.66406 9.325C5.30625 10.1719 5.125 11.0719 5.125 12C5.125 12.9281 5.30625 13.8281 5.66562 14.6766C6.01094 15.4953 6.50781 16.2313 7.13906 16.8625C7.77188 17.4938 8.50625 17.9891 9.325 18.3359C10.1719 18.6938 11.0719 18.875 12 18.875C12.9281 18.875 13.8281 18.6938 14.675 18.3344Z" />
    </SvgIcon>
  );
}

