import { memo, useEffect, useState, useCallback } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import _startsWith from 'lodash/startsWith';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import { LogoContainer, StyledTitle, StyledTabs, StyledNav, Badge } from './AppNavigation.styled';
import { ReactComponent as LogoIcon } from 'assets/icons/qwincy.svg';
import NotificationIcon from 'components/Common/Icons/NotificationIcon';
import Dashboard from './Dashboard/Dashboard';
import DealGroups from './DealGroups/DealGroups';
import MyDealflow from './MyDealflow/MyDealflow';
import Contacts from './Contacts/Contacts';
import Chats from './Chats/Chats';
import Action from './Action/Action';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useGroups from 'hooks/useGroups';

const AppNavigation = () => {
  const navigate = useNavigate();
  const previousLocation = useLocation();
  const { getGroups } = useGroups();
  const { setError } = useError();
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { hasUnreadNotifications, authorizedAsUser, profileFetched } = useCustomSelector(state => ({
    hasUnreadNotifications: state.notifications.count > 0,
    authorizedAsUser: state.sessions.session.userableType === 'user',
    profileFetched: !!state.profile.user.id
  }));
  const [value, setValue] = useState(1);

  const handleChange = (_, newValue) => setValue(newValue);
  const handleNotifications = () => navigate('/notifications', { state: { previousLocation } });
  const fetchGroups = useCallback(offset => {
    getGroups({ offset }).catch(e => setError(e));
  }, [getGroups, setError]);

  useEffect(() => {
    previousLocation.pathname === '/' && setValue(2);
    _startsWith(previousLocation.pathname, '/dashboard') && setValue(0);
    _startsWith(previousLocation.pathname, '/groups') && setValue(1);
    _startsWith(previousLocation.pathname, '/dealspaces') && setValue(1);
    _startsWith(previousLocation.pathname, '/deal_flow') && setValue(2);
    _startsWith(previousLocation.pathname, '/i/') && setValue(2);
    _startsWith(previousLocation.pathname, '/r/') && setValue(2);
    _startsWith(previousLocation.pathname, '/contacts') && setValue(3);
    _startsWith(previousLocation.pathname, '/conversations') && setValue(3);
    _startsWith(previousLocation.pathname, '/users') && setValue(false);
    previousLocation.pathname === '/signed_gate_agreements' && setValue(false);
  }, [previousLocation.pathname]);
  useEffect(() => {
    if (authorizedAsUser && profileFetched) fetchGroups(0);
  }, [authorizedAsUser, fetchGroups, profileFetched]);

  if (mediaDesktop) {
    return (
      <AppBar
        position='static'
        elevation={0}
        color='primary'
        sx={{ color: 'common.white', backgroundColor: 'primary.main' }}
      >
        <Toolbar sx={{ minHeight: '50px !important', height: '50px' }}>
          <LogoContainer>
            <LogoIcon /><StyledTitle>Qwincy</StyledTitle>
          </LogoContainer>
          {authorizedAsUser &&
            <StyledTabs
              value={value}
              onChange={handleChange}
            >
              <Dashboard />
              <DealGroups />
              <MyDealflow />
              <Contacts />
            </StyledTabs>
          }
          <Box flexGrow={1} />
          <Chats />
          {authorizedAsUser &&
            <IconButton sx={{ color: 'inherit' }} onClick={handleNotifications}>
              <Badge
                variant='dot'
                color='success'
                invisible={!hasUnreadNotifications}
              >
                <NotificationIcon />
              </Badge>
            </IconButton>
          }
          <Action />
        </Toolbar>
      </AppBar>
    );
  }
  return (
    <StyledNav
      showLabels
      value={value}
      onChange={handleChange}
    >
      <Dashboard mobile />
      <DealGroups mobile />
      <MyDealflow mobile />
      <Chats mobile />
      <Action mobile />
    </StyledNav>
  );
};

const ErrorFallback = ({ error }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AppNavigation {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);