import { memo, useCallback, useEffect, useRef, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/MenuRounded';
import Menu from '@mui/material/Menu';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Root, Nav, CreateButtonContainer, FilterContainer, Content, Header } from './FlowNav.styled';
import axios, { baseURL } from 'apis';
import AddIcon from 'components/Common/Icons/AddIcon';
import Search from '../Search/Search';
import VerticalFilters from 'components/Offers/FlowNav/VerticalFilters/VerticalFilters';
import HorizontalFilters from './HorizontalFilters/HorizontalFilters';
import List from 'components/Offers/List/List';
import ActiveFilterItem from './VerticalFilters/Item/Item';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useApp from 'hooks/useApp';
import useError from 'hooks/useError';
import useCustomSelector from 'hooks/useCustomSelector';
import useOffers from 'hooks/useOffers';
import InvitedUserPopover from './InvitedUserPopover/InvitedUserPopover';

const FlowNav = ({ data = null }) => {
  const searchRef = useRef(null);
  const previousLocation = useLocation();
  const navigate = useNavigate();
  const { /*openAccessBetaDialog,*/ setAuthData, openPreBuilderDialog } = useApp();
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { setError } = useError();
  const { clearFeedOfferData, clearFeedRecipientData, getFeedRecipientOffers, getFeedSharedWithMeOffers, getFeedArchiveOffers, getFeedMySharedOffers, getFeedMarketOffers, getFeedFiltersAmount } = useOffers();
  const [vertical, setVertical] = useState(previousLocation?.state?.vertical ?? 'sharedWithMe'); // ['sharedWithMe', 'archive', 'myShared', 'market']
  const [horizontal, setHorizontal] = useState(previousLocation?.state?.horizontal ?? 'all'); // ['all', 'unseen', 'tracking', 'deadlines']
  const [query, setQuery] = useState(previousLocation?.state?.query ?? '');
  const [navMobileMenu, setNavMobileMenu] = useState(null);
  const [previousLocationQuery, setPreviousLocationQuery] = useState(previousLocation?.state?.query ?? '');
  const { /*hasRecipientSession, isQwincyMember,*/ profileFetched, localSession, amount, dealflow, needToLoadMore, newOffset, fetching } = useCustomSelector(state => {
    return {
      // hasRecipientSession: !!state.profile.recipient.id,
      // isQwincyMember: !!state.profile.recipient.user?.email,
      profileFetched: !!state.profile.user.id || !!state.profile.recipient.id,
      localSession: state.sessions.session,
      amount: state.offers.dealflow.amount,
      dealflow: state.offers.dealflow,
      needToLoadMore: state.offers.dealflow[vertical][horizontal].pagination.total_count > state.offers.dealflow[vertical][horizontal].pagination.count + state.offers.dealflow[vertical][horizontal].pagination.offset,
      newOffset: state.offers.dealflow[vertical][horizontal].pagination.limit + state.offers.dealflow[vertical][horizontal].pagination.offset,
      fetching: state.offers.dealflow[vertical][horizontal].fetching
    };
  });

  const fetchRecipientOffers = useCallback(({ limit, offset, orders }) => {
    getFeedRecipientOffers({ limit, offset, orders }).catch(e => setError(e));
  }, [getFeedRecipientOffers, setError]);
  const fetchOffers = useCallback(({ types, offset, query, filter, previousLocationQuery }) => {
    const marketQuery = !!previousLocationQuery ? previousLocationQuery : query;
    if (!!_find(types, t => t === 'sharedWithMe')) getFeedSharedWithMeOffers({ offset, query, filter }).catch(e => setError(e));
    if (!!_find(types, t => t === 'archive')) getFeedArchiveOffers({ offset, query, filter }).catch(e => setError(e));
    if (!!_find(types, t => t === 'myShared')) getFeedMySharedOffers({ offset, query, filter: 'all' }).catch(e => setError(e));
    if (!!_find(types, t => t === 'market')) getFeedMarketOffers({ offset, query: marketQuery, filter }).catch(e => setError(e));
  }, [getFeedArchiveOffers, getFeedMarketOffers, getFeedMySharedOffers, getFeedSharedWithMeOffers, setError]); 
  const fetchAmounts = useCallback(types => {
    if (!!_find(types, t => t === 'sharedWithMe')) {
      getFeedFiltersAmount('sharedWithMe')
      .then(({ payload: { response: { data: { responses } } } }) => {
        const failureResponses = _filter(responses, ({ status }) => status < 200 && status > 299);

        if (!!failureResponses.length) {
          setError('Failed to load offerings amount');
        }
      })
      .catch(e => setError(e));
    }
    if (!!_find(types, t => t === 'archive')) getFeedFiltersAmount('archive').catch(e => setError(e));
    if (!!_find(types, t => t === 'myShared')) getFeedFiltersAmount('myShared').catch(e => setError(e));
    if (!!_find(types, t => t === 'market')) getFeedFiltersAmount('market').catch(e => setError(e));
  }, [getFeedFiltersAmount, setError]);
  const checkIsQwincyMember = () => {
    if (localSession.userableType === 'recipient') {
      setAuthData({ open: true });
    } else {
      navigate('/sign_in');
    }
    /**
     * Временное решение
     */
    // (isQwincyMember || !hasRecipientSession) ? setAuthData({ open: true }) : openAccessBetaDialog();
  };
  const onCreateOffer = () => {
    if (localSession.userableType === 'user') {
      openPreBuilderDialog();
    } else {
      checkIsQwincyMember();
    }
  };
  const handleQueryChange = q => {
    setPreviousLocationQuery('');
    setQuery(q);
    clearFeedOfferData({ vertical, horizontal })
    .then(() =>
      fetchOffers({ types: [vertical], offset: 0, query: q, filter: horizontal })
    );
  };
  const handleBottomScroll = () => {
    if (needToLoadMore && !fetching) {
      fetchOffers({ types: [vertical], offset: newOffset, query, filter: horizontal });
    }
  };
  const handleVerticalFilterChange = v => {
    setNavMobileMenu(null);

    if (localSession.userableType === 'user') {
      localStorage.removeItem('verticalFilter');
      if (query || previousLocationQuery) {
        setQuery('');
        setPreviousLocationQuery('');
        searchRef.current.clearQuery();
        clearFeedOfferData({ vertical, horizontal })
        .then(() => {
          navigate('/deal_flow');
          setVertical(v);
          localStorage.setItem('verticalFilter', v);
    
          if (amount[v].all === -1) fetchAmounts([v]);
          if (horizontal !== 'all') setHorizontal('all');
        });
      } else {
        navigate('/deal_flow');
        setVertical(v);
        localStorage.setItem('verticalFilter', v);
  
        if (amount[v].all === -1) fetchAmounts([v]);
        if (horizontal !== 'all') setHorizontal('all');
      }
    } else {
      checkIsQwincyMember();
    }
  };
  const handleHorizontalFilterChange = h => {
    setHorizontal(h);
    if (query || previousLocationQuery) {
      setQuery('');
      setPreviousLocationQuery('');
      searchRef.current.clearQuery();
      clearFeedOfferData({ vertical, horizontal })
      .then(() => {
        const needToFetchOffers = dealflow[vertical][h].pagination.total_count === -1;

        if (needToFetchOffers) fetchOffers({ types: [vertical], offset: 0, query: 0, filter: h });
      });
    } else {
      const needToFetchOffers = dealflow[vertical][h].pagination.total_count === -1;

      if (needToFetchOffers) fetchOffers({ types: [vertical], offset: 0, query: 0, filter: h });
    }
  };
  /**
   * Начальная загрузка - грузим первые страницы всех вертикальных табов
   */
  useEffect(() => {
    if (!!localSession.userableType && !data && profileFetched) {
      axios.defaults.baseURL = `${baseURL}/${localSession.userableType}`;
      axios.defaults.params = { ...axios.defaults.params, access_token: localSession.accessToken };
      axios.defaults.data = { ...axios.defaults.params, access_token: localSession.accessToken };

      if (localSession.userableType === 'user') {
        clearFeedOfferData({})
        .then(() => {
          const amount = !!previousLocationQuery ? 'market' : 'sharedWithMe';
  
          fetchAmounts([amount]);
          fetchOffers({ types: ['sharedWithMe', 'archive', 'myShared', 'market'], offset: 0, filter: 'all', previousLocationQuery });
        });
      }
      if (localSession.userableType === 'recipient') {
        fetchRecipientOffers({ offset: 0 });
      }
    }

    return () => {
      if (!data) {
        clearFeedRecipientData();
        localStorage.removeItem('verticalFilter');
      }
    };
  }, [localSession, data, clearFeedOfferData, fetchAmounts, fetchOffers, profileFetched, clearFeedRecipientData, fetchRecipientOffers]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Root>
      <Menu
        keepMounted
        open={!!navMobileMenu}
        anchorEl={navMobileMenu}
        onClose={() => setNavMobileMenu(null)}
        PaperProps={{ variant: 'popup', color: 'dark', sx: { mt: '2px', width: '210px' } }}
        disabled={data}
      >
        <VerticalFilters
          disabled={data}
          value={vertical}
          onChange={handleVerticalFilterChange}
        />
      </Menu>
      {mediaDesktop ? (
        <Nav>
          <CreateButtonContainer>
            <Button
              disabled={data}
              variant='soft'
              startIcon={<AddIcon />}
              sx={{ fontSize: '12px' }}
              onClick={onCreateOffer}
            >
              Create Offering
            </Button>
          </CreateButtonContainer>
          <VerticalFilters
            disabled={data}
            value={vertical}
            onChange={handleVerticalFilterChange}
          />
        </Nav>
      ) : (
        <Header>
          <Button
            variant='blank'
            color='neutral'
            sx={{ ml: '-4px' }}
            disabled={data}
            onClick={({ currentTarget }) => setNavMobileMenu(currentTarget)}
          >
            <MenuIcon />
          </Button>
          <ActiveFilterItem disabled={false} activeFilter={vertical} />
          <Button
            variant='soft'
            sx={{ width: 40, height: 40, borderRadius: '50%' }}
            disabled={data}
            onClick={onCreateOffer}
          >
            <AddIcon />
          </Button>
        </Header>
      )}
      <Content>
        <FilterContainer>
          <HorizontalFilters
            filter={vertical}
            value={horizontal}
            onChange={handleHorizontalFilterChange}
          />
          <Search
            ref={searchRef}
            disabled={data || localSession.userableType !== 'user'}
            onChange={handleQueryChange}
            value={previousLocationQuery}
          />
        </FilterContainer>
        <List
          data={data}
          vertical={vertical}
          horizontal={horizontal}
          onBottom={handleBottomScroll}
        />
        <InvitedUserPopover />
      </Content>
   </Root>
  );
};

FlowNav.propTypes = {
  data: PropTypes.array
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <FlowNav {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);