import { memo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Root, RootMobile, Tagline, HeaderMobile, TaglineContainerMobile, StatusContainerMobile, StatusContainer, NameContainer } from './Main.styled';
import MarketFilledIcon from 'components/Common/Icons/MarketFilledIcon';
import LockedFilledIcon from 'components/Common/Icons/LockedFilledIcon';
import Status from 'components/Offers/Status/Status';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';

const Main = ({ hasDraft, name, isPublic, tag, status, primary_sender }) => {
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  if (mediaDesktop) {
    return (
      <Root>
        {isPublic ? <MarketFilledIcon sx={{ mb: '2px' }} /> : <LockedFilledIcon sx={{ mb: '2px' }} />}
        <BaseAvatar
          src={getRepresentation(primary_sender?.user?.photo?.representations, representationVariants.TINY)}
          firstName={primary_sender?.user?.first_name}
          lastName={primary_sender?.user?.last_name}
        />
        <NameContainer>
          {!!tag && <Tagline>{tag}</Tagline>}
          <Typography variant='subtitle2' sx={{fontWeight: 500, fontSize: '0.75rem'}} mb='4px' noWrap>{name}</Typography>
        </NameContainer>
        <StatusContainer>
          <Status value={status} />
          {hasDraft && <Typography variant='caption' color='error' noWrap>Unsaved draft pending</Typography>}
        </StatusContainer>
      </Root>
    );
  }
  return (
    <RootMobile>
      <HeaderMobile>
        <TaglineContainerMobile>
          {!!isPublic ? <MarketFilledIcon /> : <LockedFilledIcon /> }
          {!!tag && <Tagline sx={{ fontSize: '12px'}} noWrap>{tag}</Tagline>}
        </TaglineContainerMobile>
        <Box flexGrow={1} />
        <StatusContainerMobile>
          <Status value={status} />
          {hasDraft && <Typography variant='small' color='error' noWrap>Unpublished draft</Typography> }
        </StatusContainerMobile>
      </HeaderMobile>
      <Typography variant='subtitle2' align='center' noWrap>{name}</Typography>
    </RootMobile>
  );
};

Main.propTypes = {
  hasDraft: PropTypes.bool.isRequired,
  name:  PropTypes.string.isRequired,
  isPublic: PropTypes.bool.isRequired,
  tag: PropTypes.string,
  status: PropTypes.string.isRequired,
  primary_sender: PropTypes.shape({
    user: PropTypes.shape({
      photo: PropTypes.shape({
        representations: PropTypes.array
      }),
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  })
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Main {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);