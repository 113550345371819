import SvgIcon from '@mui/material/SvgIcon';

export default function PreviewerIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" fillRule="evenodd" clipRule="evenodd"
            d="M21.0173 10.529C21.2624 11.1175 21.4382 11.6834 21.4443 11.7978V11.806V11.8101C21.4382 11.9245 21.2624 12.4905 21.0173 13.079C20.7659 13.6858 20.341 14.5644 19.7525 15.2979C18.9781 16.2623 18.028 17.0326 16.9287 17.5823C15.5679 18.2647 13.9844 18.61 12.2211 18.61C10.4599 18.61 8.87635 18.2647 7.51351 17.5823C6.41016 17.0306 5.46006 16.2603 4.68976 15.2959C4.10335 14.5644 3.6804 13.6858 3.42704 13.079C3.18185 12.4885 3.00613 11.9225 3 11.8081V11.804V11.7999C3.00613 11.6855 3.1798 11.1195 3.42499 10.531C3.67631 9.9242 4.09926 9.04561 4.68771 8.31004C5.46619 7.34155 6.41629 6.57329 7.51147 6.0257C8.86613 5.34531 10.4517 5 12.2191 5C13.9885 5 15.5741 5.34326 16.9287 6.02366C18.0321 6.57534 18.9822 7.34564 19.7525 8.31004C20.341 9.04357 20.7659 9.92216 21.0173 10.529ZM18.6098 14.3724C19.3576 13.4407 19.8092 12.1575 19.9257 11.802C19.8092 11.4465 19.3556 10.1613 18.6037 9.22142C17.9641 8.42047 17.1795 7.78298 16.2683 7.32734C15.122 6.7491 13.7592 6.45692 12.2165 6.45692C10.68 6.45692 9.31513 6.7491 8.15866 7.32734C7.24942 7.78298 6.46277 8.41842 5.82119 9.21937C5.06928 10.1593 4.61568 11.4445 4.49922 11.8C4.61568 12.1575 5.06928 13.4448 5.82119 14.3806C6.46073 15.1815 7.24533 15.819 8.15661 16.2747C9.31104 16.8529 10.6739 17.1471 12.2104 17.1471C13.7449 17.1471 15.1097 16.8529 16.2642 16.2747C17.1775 15.8149 17.9641 15.1795 18.5996 14.3826L18.6098 14.3724ZM12.2176 8.24475C10.2643 8.24475 8.67666 9.83438 8.67666 11.7857C8.67666 13.737 10.2663 15.3266 12.2176 15.3266C14.1689 15.3266 15.7585 13.737 15.7585 11.7857C15.7585 9.83438 14.1709 8.24475 12.2176 8.24475ZM13.6968 13.2589C14.0911 12.8646 14.3077 12.3415 14.3077 11.7858C14.3077 11.23 14.0911 10.7069 13.6968 10.3126C13.3024 9.91825 12.7793 9.70166 12.2236 9.70166C11.6678 9.70166 11.1448 9.91825 10.7504 10.3126C10.3561 10.7069 10.1395 11.23 10.1395 11.7858C10.1395 12.3415 10.3561 12.8646 10.7504 13.2589C11.1448 13.6533 11.6678 13.8699 12.2236 13.8699C12.7793 13.8699 13.3024 13.6533 13.6968 13.2589Z"
      />
    </SvgIcon>
  );
}

