import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

export const Root = styled(Box)({
  flexShrink: 0,
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  background: 'linear-gradient(286.08deg, rgba(210, 228, 255, 0.15) -16.96%, rgba(184, 203, 255, 0.0999489) 25.29%, rgba(0, 56, 255, 0.012) 99.54%)',
  borderBottom: '1px solid #F0F0F0',
  padding: '16px 12px 10px',
});

export const CloseButton = styled(ButtonBase)({
  position: 'absolute',
  top: 14,
  right: 12,
  width: 18,
  height: 18,
  borderRadius: '4px',
  '& svg': {
    width: 20,
    height: 'auto',
    color: '#000'
  }
});

export const Content = styled(Box)({
  display: 'flex',
  gap: '10px',
  paddingRight: '10px',
});

//main content
export const PreviewImage = styled(Box)({
  flexShrink: 0,
  width: 80,
  height: 50,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '6px',
});

export const TypeLabel = styled(Typography)(({ theme }) => ({
  display: 'block',
  fontSize: theme.typography.pxToRem(8),
  color: theme.palette.secondary.main,
  fontWeight: 700,
  textTransform: 'uppercase',
  marginBottom: '4px'
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(11),
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
}));

//additional content
export const AdditionalContent = styled(Box,
  { shouldForwardProp: (prop) => prop !== 'isEnd' }
  )(({ isEnd }) => ({
    minHeight: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: isEnd ? 'flex-end' : 'space-between'
}));

export const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    right: '-14px',
    top: '8px',
    minWidth: 16,
    height: 16,
    fontSize: '10px',
    userSelect: 'none',
  }
});

export const StyledLink = styled(Link)({
  fontSize: '0.75rem',
  fontWeight: 500,
  cursor: 'pointer'
});

export const AddUser = styled(Avatar)(({ theme }) => ({
  color: '#fff',
  backgroundColor: theme.palette.secondary.main,
  '& svg': {
    height: 14,
    width: 14,
  }
}));

export const StyledAvatarGroup = styled(AvatarGroup)({
  cursor: 'pointer',
  '& .MuiAvatarGroup-avatar': {
    height: 24,
    width: 24,
    fontSize: '12px',
  }
});

export const BackButton = styled(ButtonBase)({
  fontWeight: 500,
  fontSize: '0.75rem',
  borderRadius: '4px',
  paddingRight: 6,
  marginLeft: '-4px',
  '& svg': {
    height: 22,
    transform: 'rotate(180deg)',
    marginRight: '-2px',
  }
});