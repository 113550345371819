import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Root, StyledButton, Image, Preview, ThumbsContainer } from './BuilderImages.styled';
import { ReactComponent as SelectImagesIcon } from 'assets/icons/images-select.svg';
import { ReactComponent as CropIcon } from 'assets/icons/crop.svg';
import DropContainer from 'components/Common/Feedback/DropContainer/DropContainer';
import BuilderErrorLabel from 'components/Common/DataDisplay/BuilderErrorLabel/BuilderErrorLabel';
import Drawer from 'components/Drawers/Images/Images';
import CropDialog from 'components/Dialogs/Crop/Crop';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { representationVariants } from 'helpers/constants';
import { getRepresentation, getImageMetaData } from 'helpers';
import useBlob from 'hooks/useBlob';
import useChecksum from 'hooks/useChecksum';
import useError from 'hooks/useError';

const BuilderImages = ({ fetching, uploading, dealspaceFolderId, images, error, onChange }) => {
  const { processBlob } = useBlob();
  const { processFiles } = useChecksum();
  const { setError } = useError();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [cropDialog, setCropDialog] = useState({ open: false, type: '', src: '', width: 0, height: 0, showHeightSlider: false });
  const orderedImages = _orderBy(images, ['main'], ['desc']);

  const handleCropClick = () => {
    const activeImage = orderedImages[activeSlideIndex];
    const src = getRepresentation(activeImage.file.representations, representationVariants.MEDIUM);

    getImageMetaData(src, (err, img) => {
      if (err) {
        setError(err);
        return;
      }
      setCropDialog({ open: true, type: 'image', src, width: img.naturalWidth, height: img.naturalHeight, showHeightSlider: false });
    });
  };
  const handleDialogClose = () => setCropDialog({ open: false, type: '', src: '', width: 0, height: 0, showHeightSlider: false });
  const handleDialogSubmit = f => {
    processFiles([f], ({ file, checksum, localUrl }) => {
      processBlob({ file, checksum }, blob => {
        if (!blob.id) return;
        onChange({ reason: 'optionUpdated', id: orderedImages[activeSlideIndex].id, value: blob.id, formattedValue: { localUrl } });
        setCropDialog({ open: false, type: '', src: '', width: 0, height: 0, showHeightSlider: false });
      });
    });
  };

  if (fetching) {
    return (
      <Skeleton
        animation='wave'
        width='100%'
        height={350}
        sx={{ transform: 'scale(1, 1)' }}
      />
    );
  }
  return (
    <>
      <Drawer
        type='builder'
        value={orderedImages}
        folderId={dealspaceFolderId}
        uploading={uploading}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onChange={v => onChange(v)}
      />
      {!orderedImages.length ? (
        <Box>
          <ButtonBase variant='uploader' sx={{ height: 450, width: 600 }} onClick={() => setDrawerOpen(true)}>
            <SelectImagesIcon style={{ color: '#8AC5F3' }} />
            <Typography variant='subtitle3' mt={1}>Click to upload images <span style={{ fontWeight: 400 }}>or drag and drop</span></Typography>
            <Typography variant='caption'>PNG, JPEG, TIF, GIF  •  600 px X 450 px</Typography>
            {!!error && <BuilderErrorLabel value={error} />}
          </ButtonBase>
        </Box>
      ) : (
        <>
          <CropDialog
            {...cropDialog}
            onSubmit={handleDialogSubmit}
            onClose={handleDialogClose}
          />
          <DropContainer>
            <Root>
              <StyledButton onClick={() => setDrawerOpen(true)}>
                <EditIcon />
              </StyledButton>
              <StyledButton sx={{ right: '70px' }} onClick={handleCropClick}>
                <CropIcon />
              </StyledButton>
              <Swiper
                loop
                navigation
                spaceBetween={10}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[Thumbs, Navigation]}
                style={{
                  '--swiper-navigation-color': 'rgba(0,0,0,.8)',
                  '--swiper-pagination-color': '#fff'
                }}
                onSwiper={s => setActiveSlideIndex(s.activeIndex)}
                onSlideChange={s => setActiveSlideIndex(s.activeIndex)}
                onNavigationNext={s => setActiveSlideIndex(s.activeIndex + 1)}
                onNavigationPrev={s => setActiveSlideIndex(s.activeIndex - 1)}
              >
                {_map(orderedImages, i =>
                  <SwiperSlide key={i.id} style={{ height: 450, width: 600 }}>
                    <Image src={getRepresentation(i.file.representations, representationVariants.MEDIUM)} />
                  </SwiperSlide>
                )}
              </Swiper>
              {orderedImages.length > 1 &&
                <ThumbsContainer>
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    // loop
                    spaceBetween={10}
                    slidesPerView={6}
                    watchSlidesProgress
                    centerInsufficientSlides
                    modules={[Thumbs]}
                  >
                    {_map(orderedImages, i =>
                      <SwiperSlide key={i.id}>
                        <Preview sx={{ backgroundImage: `url('${getRepresentation(i.file.representations, representationVariants.TINY)}')` }} />
                      </SwiperSlide>
                    )}
                  </Swiper>
                </ThumbsContainer>
              }
            </Root>
          </DropContainer>
          <Divider sx={{ mt: '1rem', mb: '6px' }} />
        </>
      )}
    </>
  );
};

BuilderImages.propTypes = {
  fetching: PropTypes.bool.isRequired,
  uploading: PropTypes.bool.isRequired,
  dealspaceFolderId: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BuilderImages {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);