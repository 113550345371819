import { useCallback } from 'react';

const useIOS = () => {
  const sendTokenToIOS = useCallback(access_token => {
    if (window.webkit?.messageHandlers?.nativeapp) {
      window.webkit.messageHandlers.nativeapp.postMessage({ access_token });
    }
  }, []);

  return { sendTokenToIOS };
};

export default useIOS;