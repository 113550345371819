import axios from 'axios';
import qs from 'qs';

export const baseURL = process.env.REACT_APP_BASE_URL;
export const frameBaseURL = process.env.REACT_APP_FRAME_BASE_URL;
export const wsURL = process.env.REACT_APP_WS_URL;

export const logrocketId = process.env.REACT_APP_LOGROCKET_ID;
export const mixpanelToken = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN;
export const mode = process.env.REACT_APP_MODE;
export const tinyApiKey = process.env.REACT_APP_TINY_API_KEY;
export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
export const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
export const reportAllApiKey = process.env.REACT_APP_REPORT_ALL_API_KEY;
export const linkedInClientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
export const linkedInRedirectUri = process.env.REACT_APP_LINKEDIN_REDIRECT_URI;
export const chameleonToken = process.env.REACT_APP_CHAMELEON_TOKEN;
export const appsignalApiKey = process.env.REACT_APP_APPSIGNAL_API_KEY;
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
export const CancelToken = axios.CancelToken;
export let controller = new AbortController();

export const instance = axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json' },
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' })
});
export const extension = axios.create({
  baseURL: `${baseURL}/user`,
  headers: { 'Content-Type': 'application/json' },
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' })
});
export default axios.create({
  baseURL: `${baseURL}/user`,
  headers: { 'Content-Type': 'application/json' },
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' })
});