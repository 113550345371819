import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const Root = styled(Box)({
  minWidth: 180,
  width: '100%',
  minHeight: 100 + 60,
  textDecoration: 'none',
  boxShadow: '0 4px 10px 0 rgba(0,0,0,.1)',
  borderRadius: '6px',
  overflow: 'hidden',
  cursor: 'pointer'
});
export const Content = styled('div')({
  padding: '4px 10px 2px'
});
export const Type = styled((props) => (
  <Typography variant='small' component='div' noWrap {...props} />
))({
  color: '#7D99E0',
  fontWeight: 700,
  textTransform: 'capitalize',
  marginBottom: 2
});
export const Title = styled((props) => (
  <Typography variant='caption'  color='text.primary' noWrap component='div' {...props} />
))({
  fontWeight: 600,
  lineHeight: '1.2'
});
export const Image = styled(Box)({
  width: '100%',
  height: 100,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundColor: '#F3F5F8'
});
export const DocPlaceholder = styled(Box)({
  width: '100%',
  height: 100,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#F4F6F8',
  '& > *': {
    height: 60,
    width: 'auto',
    color: '#d3dce4'
  }
});