import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import { 
  getViews as getViewsAction,
  viewDealspace as viewDealspaceAction, 
  updateView as updateViewAction,
  clearLocalViews as clearLocalViewsAction
} from 'actions/views';

const useViews = () => {
  const dispatch = useDispatch();
  const getViews = useCallback(({ offset }) => dispatch(getViewsAction({ offset })), [dispatch]);
  const viewDealspace = useCallback(id => dispatch(viewDealspaceAction(id)), [dispatch]);
  const updateView = useCallback(id => dispatch(updateViewAction(id)), [dispatch]);
  const clearLocalViews = useCallback(id => dispatch(clearLocalViewsAction(id)), [dispatch]);

  return { getViews, viewDealspace, updateView, clearLocalViews };
};

export default useViews;