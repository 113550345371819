import { memo, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import _find from 'lodash/find';
import _map from 'lodash/map';
import { useParams } from 'react-router-dom';
// Local files
import { DropButton, StyledTextField } from './Property.styled';
import LocationDialog from 'components/Dialogs/Location/Location';
import ArrowDropDownIcon from 'components/Common/Icons/ArrowDropDownIcon';
import AddIcon from 'components/Common/Icons/AddIcon';
import MenuVert from 'components/Common/Icons/MenuVert';
import Title from '../Title/Title';
import LocationCard from 'components/Locations/Card/Card';
import Add from 'components/Locations/Add/Add';
import { checkEmptyString } from 'helpers';
import useApp from 'hooks/useApp';
import useProperties from 'hooks/useProperties';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import { DELETE_PROPERTY } from 'helpers/confirmations';

const Property = ({ onMobileNavClick }) => {
  const { setError } = useError();
  const { property_id, dealspace_id } = useParams();
  const { openDeleteDialog } = useApp();
  const { getProperty, updateProperty } = useProperties();
  const { activeProperty } = useCustomSelector(state => ({
    activeProperty: _find(state.properties.dealspace.data, d => d.id === property_id)
  }));
  const [name, setName] = useState(() => ({ value: '', error: '' }));
  const [anchorEl, setAnchorEl] = useState(null);
  const [locationDialog, setLocationDialog] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleMobileNavClick = ({ currentTarget }) => onMobileNavClick(currentTarget);
  const handleAddLocation = () => setLocationDialog(true);
  const handleNameBlur = () => {
    if (!checkEmptyString(name.value)) {
      setName(prev => ({ ...prev, error: 'Enter a property name' }));
    } else {
      if (name.value !== activeProperty.name) {
        changeProperty({ id: activeProperty.id, name: name.value });
      }
    }
  };

  const handleDeleteClick = () => { 
    setAnchorEl(null);
    openDeleteDialog({ type: DELETE_PROPERTY, id: activeProperty?.id, navigatePath: `/dealspaces/${dealspace_id}/properties` });
  };
  const changeProperty = useCallback(property => {
    setProcessing(true);
    updateProperty(property)
    .then(() => getProperty(property.id).catch(e => setError(e))
    )
    .catch(e => setError(e))
    .finally(() => setProcessing(false))
  }, [getProperty, updateProperty, setError]);

  useEffect(() => {
    if (!!property_id) {
      setName({ value: activeProperty?.name, error: '' });
    }
  }, [property_id]); // eslint-disable-line react-hooks/exhaustive-deps
  
  if (!activeProperty) return <div />;
  return (
    <Box sx={{ width: '100%' }}>
      <LocationDialog
        open={locationDialog} 
        property_id={activeProperty?.id}
        locations={activeProperty?.locations} 
        onClose={() => setLocationDialog(false)} 
        onAddLocation={changeProperty}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', }}>
        <StyledTextField
          fullWidth
          variant='standard'
          label='Title'
          value={name.value}
          onChange={({ target: { value } }) => setName({ value, error: '' })}
          onBlur={handleNameBlur}
          error={!!name.error}
          helperText={name.error}
          disabled={processing}
        />
        <DropButton onClick={handleMobileNavClick}  disabled={processing}><ArrowDropDownIcon /></DropButton>
        <Box flexGrow={1} />
        <IconButton size='small' onClick={({ currentTarget }) => setAnchorEl(currentTarget)} disabled={processing}><MenuVert /></IconButton>
        <Menu keepMounted open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} >
          <MenuItem onClick={handleDeleteClick} disabled={processing}>
            <Typography variant='caption' component='div' color='red' noWrap sx={{ fontWeight: 600 }}>Delete</Typography>
          </MenuItem>
        </Menu>
      </Box>
      <Box mt={2}>
        <Title
          label={!!activeProperty?.locations_count ? `Locations ${activeProperty?.locations_count > 0 ? `(${activeProperty?.locations_count})` : ''}` : 'Location'}
          action={<Button startIcon={<AddIcon />} disabled={processing} onClick={handleAddLocation}>Add</Button>}
        />
        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: '12px' }}>
          <Add
            sx={{ display: { xs: 'none', md: 'flex' }, width: '220px' }}
            onClick={handleAddLocation}
            disabled={processing}
          />
          {_map(activeProperty?.locations, ({ id, ...fields }) =>
            <LocationCard
              key={id}
              id={id}
              {...fields}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(Property);