import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getArchive as getArchiveAction,
  createArchive as createArchiveAction
} from 'actions/archives';

const useArchives = () => {
  const dispatch = useDispatch();
  const getArchive = useCallback(id => dispatch(getArchiveAction(id)), [dispatch]);
  const createArchive = useCallback(folder_id => dispatch(createArchiveAction(folder_id)), [dispatch]);

  return { getArchive, createArchive };
};

export default useArchives;