import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

export const Header = styled((props) => (
  <Container maxWidth={false} component={Paper} variant='glass' {...props} />
))(() => ({
  position: 'sticky',
  top: 0,
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  paddingTop: '18px',
  paddingBottom: '18px',
}));

export const DealspaceLink = styled(props => (
  <Button variant='contained' color='primary' {...props} />
))(() => (
  {
    width: 30,
    height: 30,
    minWidth: 'unset',
    position: 'relation',
    boxShadow: '0px 0px 10px 0px #00000066 !important',
    '& > div': {
      width: 30,
      height: 30,
      textTransform: 'uppercase',
      fontSize: '0.75rem',
    },
    '& > svg': {
      position: 'absolute',
      bottom: '-6px',
      right: '-5px',
    }
  }
));

export const Title = styled(Typography)(() => ({
  fontSize: '1rem',
  fontWeight: 600,
}));

export const Actions = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
});

export const ChipWrapper = styled((props) => (
  <Typography variant='inherit' component='div' {...props} />
))({
  lineHeight: '100%',
});

export const MobileActions = styled(Box)({
  position: 'fixed',
  bottom: 0, left: 0, right: 0,
  zIndex: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  //background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 84.9%)',
  padding: '24px 8px',
});

export const MobilePrimaryAction = styled(Button)(({ theme }) => ({
  height: 34,
  minWidth: 96,
  fontSize: '0.875rem',
  boxShadow: '0px 0px 10px 0px #00000066 !important',
  padding: '4px 16px',
  [theme.breakpoints.down(340)]: {
    fontSize: '.75rem',
    padding: '2px 12px',
  }
}));

export const MobileIconAction = styled(ButtonBase)({
  width: 34,
  height: 34,
  background: '#fff',
  boxShadow: '0px 0px 10px 0px #00000066',
  borderRadius: '8px',
  '& svg': {
    height: '1.25rem',
    width: 'auto',
  }
});
