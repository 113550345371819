import { memo, useRef, useState, useEffect, useLayoutEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Scrollbars from 'react-custom-scrollbars-2';
import _find from 'lodash/find';
import _map from 'lodash/map';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Header, Section, SectionHeader, Grid, CloseButton, PromptText, FileInput } from './Images.styled';
import { ReactComponent as UploadIcon } from 'assets/icons/upload-filled.svg';
import { getRepresentation } from 'helpers';
import { representationVariants } from 'helpers/constants';
import CloseIcon from 'components/Common/Icons/CloseIcon';
import FileCard from 'components/Documents/Card/Card';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useBlob from 'hooks/useBlob';
import useChecksum from 'hooks/useChecksum';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useImages from 'hooks/useImages';

const ImagesDrawer = ({ type, folderId, uploading, value, open, onClose, onChange }) => {
  const inputRef = useRef(null);
  const { processBlob, percent, loading: loadingBlob, fileName, fileType} = useBlob();
  const { processFiles } = useChecksum();
  const { setError } = useError();
  const { getDealspaceImages, clearLocalDealspaceImages } = useImages();
  const [percentProgress, setPercentProgress] = useState(0);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [fileNameBlob, setFileNameBlob] = useState('');
  const [fileTypeBlob, setFileTypeBlob] = useState('');
  const [loading, setLoading] = useState(true);
  const { dealspaceImages, dealspaceImagesCount } = useCustomSelector(state => ({
    dealspaceImages: state.images.dealspace.data,
    dealspaceImagesCount: state.images.dealspace.pagination.total_count
  }));
  const selectedImagesCount = value?.length ?? 0;

  const fetchImages = ({ folder_id, offset }) => {
    getDealspaceImages({ folder_id, offset })
    .catch(e => setError(e))
    .finally(() => setLoading(false));
  };
  const handleCheckClick = ({ id, checked }) => {
    if (checked) return;

    const foundImage = _find(dealspaceImages, di => di.id === id);

    onChange({ id, v: foundImage, reason: 'optionSelected' });
  };
  const handleFilesChange = ({ target: { files } }) => {
    const f = Array.from(files);

    onChange({ reason: 'optionUploading' });
    processFiles(f, ({ localUrl, file, checksum }) => {
      processBlob({ file, checksum }, blob => {
        if (!blob.id) return;

        const v = { file: blob.id, name: blob.filename, localUrl };
        
        onChange({ v, reason: 'optionUploaded' });
      });
    }, 0, 0, true);
  };

  const handleEntering = () => {
    fetchImages({ folder_id: folderId, offset: 0 });
  };
  const handleExited = () => {
    clearLocalDealspaceImages();
  };

  useEffect(() => {
    if (loadingBlob) {
      setPercentProgress(percent);
      setLoadingProgress(loadingBlob);
      setFileNameBlob(fileName);
      setFileTypeBlob(fileType);
    }
  }, [percent, loadingBlob, fileName, fileType])

  useLayoutEffect(() => {
    setPercentProgress(0);
    setLoadingProgress(false);
    setFileNameBlob('');
    setFileTypeBlob('');
  }, [value?.length]);

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      ModalProps={{ sx: { zIndex: 1300 } }}
      PaperProps={{ sx: { height: '100%', width: { xs: '100%', md: '460px'} } }}
      SlideProps={{ onExited: handleExited, onEntering: handleEntering }}
    >
      <Header>
        <CloseButton disableRipple onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <Typography variant='subtitle2' align='center' mb='28px'>
          Upload image(s) or attach from Dealspace
        </Typography>
        <Button
          variant='outlined'
          startIcon={<UploadIcon />}
          disabled={uploading}
          onClick={() => inputRef.current.click()}
        >
          <FileInput
            multiple
            id='imageUploader'
            ref={inputRef}
            accept='image/png, image/jpeg, image/gif, image/svg+xml, image/tiff, image/bmp'
            type='file'
            onChange={handleFilesChange}
          />
          Upload from computer
        </Button>
      </Header>
      <Section>
        <SectionHeader>
          <Typography variant='caption' sx={{ color: 'rgba(0,0,0,.8)' }}>
            Dealspace images <span style={{ color: 'rgba(0,0,0,.6)' }}>&nbsp;&nbsp;{dealspaceImagesCount}</span>
          </Typography>
          <PromptText>Select to add</PromptText>
        </SectionHeader>
        <Scrollbars>
          <Grid>
            {loading && <Skeleton animation='wave' width={200} height={150} />}
            {_map(dealspaceImages, ({ id, file, created_at }) => {
              const checked = type === 'builder' ? !!_find(value, v => v.document?.id === id) : id === value;

              return (
                <FileCard
                  key={id}
                  isCheckbox
                  disabled={uploading}
                  checked={checked}
                  onCheck={() => handleCheckClick({ id, checked })}
                  loading={false}
                  title={file.filename}
                  type={file.content_type}
                  preview={getRepresentation(file.representations, representationVariants.TINY)}
                  showLastActivity
                  date={created_at}
                  hideMenu
                />
              );
            })}
          </Grid>
        </Scrollbars>
      </Section>
      {type === 'builder' &&
        <Section sx={{ backgroundColor: 'background.paper', boxShadow: 'inset 0px 1px 0px 0px #E0E0E0' }}>
          <SectionHeader>
            <Typography variant='subtitle3' sx={{ color: 'rgba(0,0,0,.9)' }}>
              Selected images <span style={{ color: 'rgba(0,0,0,.6)' }}>&nbsp;&nbsp;{selectedImagesCount}</span>
            </Typography>
            <PromptText>Select main image</PromptText>
          </SectionHeader>
          <Scrollbars>
            <Grid>
              {_map(value, ({ id, main, file, created_at }) =>
                <FileCard
                  key={id}
                  isRadio
                  checked={main}
                  onCheck={() => onChange({ id, reason: 'mainOption' })}
                  loading={false}
                  title={file.filename}
                  type={file.content_type}
                  preview={getRepresentation(file.representations, representationVariants.TINY)}
                  date={created_at}
                  showLastActivity
                  onDelete={() => onChange({ id, reason: 'removeOption' })}
                  hideDelete
                />
              )}
              {loadingProgress && 
                <FileCard
                  key={fileNameBlob}
                  title={fileNameBlob}
                  type={fileTypeBlob}
                  percent={percentProgress}
                  loading={loadingProgress}
                />
              }
            </Grid>
          </Scrollbars>
        </Section>
      }
    </Drawer>
  );
};

ImagesDrawer.propTypes = {
  type: PropTypes.string.isRequired,
  folderId: PropTypes.string.isRequired,
  uploading: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.array, PropTypes.string
  ]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ImagesDrawer {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);