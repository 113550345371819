import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createBlob as createBlobAction } from 'actions/blobs';
import useError from './useError';

const useBlob = () => {
  const { setError } = useError();
  const dispatch = useDispatch();
  const createBlob = useCallback(blob => dispatch(createBlobAction(blob)), [dispatch]);
  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');

  const processBlob = ({ file, checksum }, callback) => {
    const blob = { name: file.name, size: file.size, type: file.type, checksum, file };

    const onUploadProgress = progressEvent => {
      const { loaded, total } = progressEvent;
  
      const progress = Math.round((loaded / total) * 100);
      
      callback({ progress });
      setPercent(progress);
    };

    setFileName(file.name);
    setFileType(file.type);
    setLoading(true);
    createBlob({ ...blob, onUploadProgress })
    .then(({ payload: { data: { blob: { id, url, content_type, filename } } } }) => callback({ id, url, content_type, filename }))
    .catch(error => setError(error))
    .finally(() => {
      setLoading(false);
      setPercent(0);
    });
  };
  const processBlobAsync = ({ file, checksum }) => new Promise((resolve, reject) => {
    const blob = { name: file.name, size: file.size, type: file.type, checksum, file };

    setFileName(file.name);
    setFileType(file.type);
    setLoading(true);
    createBlob({ ...blob })
    .then(({ payload: { data: { blob: { id, url, content_type, filename } } } }) =>
      resolve({ id, url, content_type, filename })
    )
    .catch(error => {
      setError(error);
      reject('Error');
    })
    .finally(() => {
      setLoading(false);
      setPercent(0);
    });
  });

  return { processBlob, processBlobAsync, percent, loading, fileName, fileType };
};

export default useBlob;