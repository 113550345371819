import { memo } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
//Local files
import { backdropSx, lockStyles, logoStyles, Root, Text, TextButton, Wrapper } from './InvitedUserOverlay.styled';
import { ReactComponent as LogoIcon } from 'assets/icons/qwincy.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock-filled2-gold.svg';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';

const InvitedUserOverlay = () => {
  const { setAuthData } = useApp();
  const { open, email } = useCustomSelector(state => ({
    open: !!state.offers.dealflow.details.offer.id && state.profile.recipient.offer.id !== state.offers.dealflow.details.offer.id && state.profile.recipient.id,
    email: state.profile.recipient.email
  }));

  const handleClick = mode => setAuthData({ open: true, mode });

  return (
    <Backdrop
      open={open}
      sx={backdropSx}
    >
      <Root>
        <Wrapper>
          <LogoIcon style={logoStyles} />
          <Text sx={{ my: '20px' }}>
            <span><LockIcon style={lockStyles} />This deal was privately shared</span>
            <span> with&nbsp;<b>{email}</b></span>
          </Text>
          <Button variant='gradient' onClick={() => handleClick('signUp')}>Claim my free deal feed</Button>
          <Text sx={{ mt: '10px' }}>or <TextButton onClick={() => handleClick('signIn')}>login here</TextButton></Text>
        </Wrapper>
      </Root>
    </Backdrop>
  );
};

export default memo(InvitedUserOverlay);