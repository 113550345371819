import styled from '@mui/material/styles/styled';
import illustrationSrc from 'assets/images/background/offering-empty.png'
import Typography from '@mui/material/Typography';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#A8A8A8',
  padding: '32px 16px 40px',
  [theme.breakpoints.up('md')]: {
    padding: '50px 16px 40px',
  }
}));
export const Illustration = styled(props => (
  <img src={illustrationSrc} alt='illustration' {...props} />
))(({ theme }) => ({
  display: 'block',
  width: '100%',
  maxWidth: 300,
  [theme.breakpoints.up('md')]: {
    marginTop: 8,
    marginLeft: 60
  }
}));
export const ContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px 60px',
  marginBottom: 16,
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'normal',
    marginBottom: 50,
  }
}));
export const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
  gap: '16px',
  padding: '24px 0 12px',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    gap: '20px',
    padding: 0,
  }
}));
export const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
  }
}));
export const List = styled('ul')(({ theme }) => ({
  margin: 0,
  [theme.breakpoints.up('md')]: {
    marginLeft: '-20px'
  }
}));
export const ListItem = styled('li')(({ theme }) => ({
  lineHeight: 2,
  fontSize: theme.typography.pxToRem(14),
}));


