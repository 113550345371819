import { memo } from 'react';
import styled from '@mui/material/styles/styled';
import { keyframes } from '@emotion/react'

const darkColor = 'rgba(217, 217, 217, 0.25)'
const mediumColor = 'rgba(217, 217, 217, 0.5)'
const lightColor = 'rgba(217, 217, 217, 0.1)'

const animation = keyframes`
  from, 0%, to {
    box-shadow: 20px 0 ${mediumColor}, -20px 0 ${darkColor};
    background: ${lightColor};
  }
  33% {
    box-shadow: 20px 0 ${lightColor}, -20px 0 ${mediumColor};
    background: ${darkColor};
  }
  66% {
    box-shadow: 20px 0 ${darkColor}, -20px 0 ${lightColor};
    background: ${mediumColor};
  }
`

const Root = styled('div')({
  width: '7px',
  aspectRatio: '1',
  borderRadius: '50%',
  animation: `${animation} 1.1s infinite linear`,
});

const DotsLoader = () => {
  return (
    <Root />
  )
}

export default memo(DotsLoader);