import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export const DialogPaperStyles = {
  maxWidth: 990,
  width: '100%',
  maxHeight: { xs: 'unset', md: 'min(calc(100% - 32px), 710px)' },
  height: '100%',
  my: '16px',
  display: 'flex',
  flexDirection: 'column',
}

export const StepContent = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 40,
  paddingRight: 40,
});

export const Actions = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '5px',
  padding: '34px 0 30px'
});

export const Stepper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 400,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
  paddingBottom: 32,
  paddingTop: 24,
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    paddingBottom: 24,
    paddingTop: 32,
  }
}));

export const Step = styled(Box,
  { shouldForwardProp: (prop) => prop !== 'active' }
)(({ theme, active }) => ({
  position: 'relative',
  flexGrow: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
  padding: '0 8px',
  '& span': {
    whiteSpace: 'nowrap',
    color: '#ABB3BD',
    fontWeight: 500,
    ...(active && {
      color: '#000',
      fontWeight: 500,
    }),
    [theme.breakpoints.down('md')]: {
      fontSize: '0.675rem',
    }
  },
}));

export const Bullet = styled(Box,
  { shouldForwardProp: (prop) => prop !== 'active' }
)(({ active }) => ({
  height: 14,
  width: 14,
  borderRadius: '50%',
  boxShadow: 'inset 0px 0px 0px 3px #CCD3DB',
  ...(active && {
    background: '#515151',
    boxShadow: 'none',
  })
}));

export const Divider = styled(Box)({
  position: 'absolute',
  top: 7,
  left: 'calc(-50% + 20px)',
  right: 'calc(50% + 20px)',
  flex: '1 1 auto',
  height: 2,
  background: '#F8F9FB',
});

export const UploadButton = styled((props) => (
  <Button disableRipple {...props} />
))({
  fontWeight: 700,
  textDecoration: 'underline !important',
  background: 'transparent !important',
  padding: '2px 0',
});
