import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import _find from 'lodash/find';
import _map from 'lodash/map';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import FormSelect from 'components/Common/Inputs/FormSelect/FormSelect';
import Autocomplete from 'components/Users/Autocomplete/Autocomplete';
import AccessRightMultiselect from 'components/Dealspaces/InviteMultiselect/InviteMultiselect';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { roles } from 'helpers/constants';
import { checkEmptyString } from 'helpers';
import useError from 'hooks/useError';
import useMembers from 'hooks/useMembers';
import useSuccess from 'hooks/useSuccess';

const CreateInvite = ({ groupId, prefilledRole = null, prefilledDealspace = null, open, onClose, isOwner }) => {
  const { setError } = useError();
  const { checkGroupMember, createMember, updateMember } = useMembers();
  const { setSuccess } = useSuccess();
  const [user, setUser] = useState(null);
  const [memberId, setMemberId] = useState('');
  const [currentDealspaces, setCurrentDealspaces] = useState([]);
  const [role, setRole] = useState('collaborator');
  const [accessRights, setAccessRights] = useState({ value: [], allSelected: false });
  const [message, setMessage] = useState('');
  const [processing, setProcessing] = useState(false);
  const disabled = !user || (role === 'collaborator' && (!accessRights.value.length && !accessRights.allSelected));

  const handleEntering = () => {
    if (prefilledRole) setRole('collaborator');
    if (prefilledDealspace) setAccessRights({ value: [prefilledDealspace], allSelected: false });
  };
  const handleExited = () => {
    setUser(null);
    setMemberId('');
    setCurrentDealspaces([]);
    setRole('collaborator');
    setAccessRights({ value: [], allSelected: false });
    setMessage('');
  };
  const handleUserChange = v => {
    setUser(v);

    const email = v?.inputValue ?? null;
    const user_id = v?.data?.id ?? null;

    if (prefilledDealspace && (email || user_id)) {
      checkGroupMember({ group_id: groupId, email, user_id })
      .then(({ payload: { data: { members } } }) => {
        if (members.length) {
          setMemberId(members[0].id);
          setCurrentDealspaces(members[0].dealspaces);
        }
      })
      .catch(e => setError(e));
    }
  };
  const handleSubmit = () => {
    if (prefilledDealspace) {
      if (!!currentDealspaces.length) {
        if (!!_find(currentDealspaces, d => d.id === prefilledDealspace)) {
          setSuccess('This member is already added to this dealspace').then(() => onClose());
        } else {
          const member = {
            id: memberId,
            invitation_attributes: {
              ...(!user.inputValue ? { user_id: user.id } : { email: user.inputValue }),
              ...(checkEmptyString(message) && { message })
            },
            role,
            permission: 'partial',
            dealspace_ids: [..._map(currentDealspaces, ({ id }) => id), prefilledDealspace]
          };  

          setProcessing(true);
          updateMember(member)
          .then(() => setSuccess('Permissions successfully updated').then(() => onClose(true)))
          .catch(e => setError(e))
          .finally(() => setProcessing(false));
        }
      } else {
        const member = {
          invitation_attributes: {
            ...(!user.inputValue ? { user_id: user.id } : { email: user.inputValue }),
            ...(checkEmptyString(message) && { message })
          },
          role,
          permission: 'partial',
          dealspace_ids: accessRights.value
        };
    
        setProcessing(true);
        createMember({ group_id: groupId, member })
        .then(() => setSuccess('Invite successfully sent').then(() => onClose()))
        .catch(e => setError(e))
        .finally(() => setProcessing(false));
      }
    } else {
      const member = {
        invitation_attributes: {
          ...(!user.inputValue ? { user_id: user.id } : { email: user.inputValue }),
          ...(checkEmptyString(message) && { message })
        },
        role,
        ...(role === 'collaborator' && { permission: accessRights.allSelected ? 'full' : 'partial' }),
        ...(role === 'collaborator' && !accessRights.allSelected && { dealspace_ids: accessRights.value })
      };
  
      setProcessing(true);
      createMember({ group_id: groupId, member })
      .then(() => setSuccess('Invite successfully sent').then(() => onClose()))
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
  };

  return (
    <BaseDialog
      open={open}
      onClose={() => onClose()}
      otherPaperStyle={{ width: '100%', maxWidth: 500 }}
      title='Invite Group User'
      TransitionProps={{ onEntering: handleEntering, onExited: handleExited }}
      actions={
        <>
          <Button
            variant='text'
            color='error'
            sx={{ px: '24px', minHeight: '37px'}}
            disabled={processing}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            sx={{ px: '24px'}}
            disabled={disabled || processing}
            onClick={handleSubmit}
          >
            Invite
          </Button>
        </>
      }
    >
      <Box sx={{ maxWidth: 280, margin: '0 auto', p: '24px 0 16px', '& > *': { margin: '0 0 14px !important'} }}>
        <Autocomplete
          value={user}
          onChange={handleUserChange}
        />
        {isOwner &&
          <FormSelect
            fullWidth
            disabled={!!prefilledRole}
            label='Select role'
            SelectProps={{
              MenuProps: {
                PaperProps: { variant: 'outlined', sx: { marginTop: '4px', boxShadow: 'none !important' } }
              }
            }}
            sx={{ flexShrink: 0, minWidth: 260 }}
            value={role}
            onChange={({ target: { value } }) => setRole(value)}
          >
            {_map(roles, r => <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>)}
          </FormSelect>
        }
        {role === 'collaborator' &&
          <AccessRightMultiselect
            disabled={!!prefilledDealspace}
            value={accessRights.value}
            onChange={setAccessRights}
          />
        }
        <TextField
          id='inviteMessage'
          placeholder='Add a message (optional)'
          multiline
          fullWidth
          minRows={3}
          maxRows={5}
          value={message}
          onChange={({ target: { value } }) => setMessage(value)}
        />
      </Box>
    </BaseDialog>
  );
};

CreateInvite.propTypes = {
  groupId: PropTypes.string.isRequired,
  prefilledRole: PropTypes.string,
  prefilledDealspace: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <CreateInvite {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);