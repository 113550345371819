import { memo, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useParams } from 'react-router-dom';
// Local files
import { Content, InfoText, PaperSx, Text } from './LoadingFeed.styled';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import DotsLoader from 'components/Common/Feedback/DotsLoader/DotsLoader';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';

const LoadingFeed = () => {
  const { offer_id } = useParams();
  const { openInvitedUserPopover } = useApp();
  const { state } = useLocation();
  const { isRecipientFetching, isProfileOffer, isFetched, firstName, lastName, src } = useCustomSelector(state => ({
    isRecipientFetching: state.offers.dealflow.details.fetching && !!state.profile.recipient.id,
    isProfileOffer: state.profile.recipient.offer.id === offer_id,
    isFetched: state.offers.dealflow.details.offer.id === state.profile.recipient.offer.id && !!state.offers.dealflow.details.offer.id,
    firstName: state.profile.recipient.offer?.primary_sender?.user?.first_name ?? '-',
    lastName: state.profile.recipient.offer?.primary_sender?.user?.last_name ?? '-',
    src: state.profile.recipient.offer?.primary_sender?.user?.photo ? getRepresentation(state.profile.recipient.offer?.primary_sender?.user?.photo?.representations, representationVariants.TINY) : null
  }));
  const [countdownOver, setCountdownOver] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isRecipientFetching && isProfileOffer && state?.showLoadingFeed) {
      setOpen(true);
    }
  }, [isProfileOffer, isRecipientFetching, state?.showLoadingFeed]);
  useEffect(() => {
    let timeout = null;

    if (open) {
      timeout = setTimeout(() => {
        setCountdownOver(true);
      }, 1500);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [open]);
  useEffect(() => {
    if (countdownOver && isFetched) {
      openInvitedUserPopover();
      setOpen(false);
    }
  }, [countdownOver, isFetched, openInvitedUserPopover]);

  return (
    <BaseDialog
      open={open}
      onClose={() => {}}
      PaperProps={{ sx: PaperSx }}
      actions={null}
      hideCloseButton={true}
      hideBackdrop={true}
    >
      <Content>
        <BaseAvatar
          src={src}
          firstName={firstName}
          lastName={lastName}
          sx={{ width: '52px', height: '52px', fontSize: '24px', border: '1px solid #fff' }}
        />
        <Text variant='caption'>
          <b>{firstName} {lastName}</b> has shared a deal
          to&nbsp;your&nbsp;private feed in Qwincy.
        </Text>
        <InfoText variant='small'>
          LOADING YOUR PRIVATE FEED
        </InfoText>
        <DotsLoader />
      </Content>
    </BaseDialog>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LoadingFeed {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);