import { memo, useCallback, useEffect, useMemo, useState } from 'react';
// import { Easing, Tween, update } from '@tweenjs/tween.js';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import _map from 'lodash/map';
import _flatten from 'lodash/flatten';
import _groupBy from 'lodash/groupBy';
import _reduce from 'lodash/reduce';
import _find from 'lodash/find';
import _uniqBy from 'lodash/uniqBy';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import GoogleMap from 'components/Common/Location/GoogleMap/GoogleMap';
import Card from 'components/Offers/MapCard/MapCard';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useCustomSelector from 'hooks/useCustomSelector';
import useMap from 'hooks/useMap';
import useOffers from 'hooks/useOffers';
import useImages from 'hooks/useImages';

const options = {
  mapId: '277f6b2bc652ed12',
  mapTypeControl: true,
  mapTypeControlOptions: {
    position: 1, // TOP_RIGHT
    mapTypeIds: ["roadmap", "satellite"]
  },
  zoomControl: true,
  zoomControlOptions: {
    position: 8 // RIGHT_CENTER
  },
  streetViewControl: true,
  streetViewControlOptions: {
    position: 8 // RIGHT_CENTER
  },
  fullscreenControl: true,
  gestureHandling: 'cooperative'
};

const Map = ({ preview = false, activeOffer, showLocatedMap = false }) => {
  const { getFeedMapOffers, clearLocalFeedMapOffers } = useOffers();
  const { getParcelImages } = useImages({});
  const { /*selectPolygon,*/ centerMap, clearMapData } = useMap();
  // const [isEasing, setIsEasing] = useState(true);
  const [propertyPopover, setPropertyPopover] = useState({ anchor: null, propertyName: '', locations: [] });
  const [offerPopover, setOfferPopover] = useState({ position: null, offer: null });
  const { authorizedAsUser, mapInstance, mapOffers, listOffers, activePolygons, polygons, center, zoom } = useCustomSelector(state => {
    const authorizedAsUser = state.sessions.session.userableType === 'user';
    const mapInstance = state.map.instance;
    const mapOffers = state.offers.feedMap;
    const sharedWithMeOffers = state.offers.dealflow.sharedWithMe.all.data;
    const archiveOffers = state.offers.dealflow.archive.all.data;
    const mySharedOffers = state.offers.dealflow.myShared.all.data;
    const marketOffers = state.offers.dealflow.market.all.data;
    const listOffers = [...sharedWithMeOffers, ...archiveOffers, ...mySharedOffers, ...marketOffers];
    const activePolygons = state.map.activePolygons;
    const polygons = state.map.polygons;
    const center = state.map.configs.center;
    const zoom = state.map.configs.zoom;

    return { authorizedAsUser, mapInstance, mapOffers, listOffers, activePolygons, polygons, center, zoom };
  });
  const uniqOffers = useMemo(() => _uniqBy([activeOffer, ...mapOffers, ...listOffers], 'id'), [activeOffer, listOffers, mapOffers]);
  const markers = useMemo(() => (!!activeOffer?.locations?.length && !showLocatedMap) ? _groupBy(_map(activeOffer?.locations, (l) => ({ ...l, lat: l.latitude, lng: l.longitude })), 'property.id') : [], [activeOffer, showLocatedMap]);
  const groupedMarkers = useMemo(() => !!Object.keys(markers)?.length ? _flatten(_map(Object.keys(markers), (k, i) => {
    const value = Object.values(markers)[i];

    if (k === 'undefined') {
      return value;
    } else {
      return [{ id: value[0]?.id, property_id: k, lat: _reduce(value, (sum, l) => sum + l.latitude, 0) / value?.length, lng: _reduce(value, (sum, l) => sum + l.longitude, 0) / value?.length }]
    }
  })) : [], [markers]);

  const handleBoundsChange = ({ south, north, west, east }) => {
    if (authorizedAsUser && !!mapInstance && !preview) {
      getFeedMapOffers({ latitude_gt: south, latitude_lt: north, longitude_gt: west, longitude_lt: east });
    }
  };
  const handleMarkerClick = (e,m) => {
    setPropertyPopover({ 
      anchor: e.domEvent.currentTarget, 
      propertyName: markers[m.property_id][0]?.property?.name, locations: markers[m.property_id] 
    });
  };
  const handlePolygonClick = (e,p) => {
    const isActivePolygon = !!_find(activePolygons, ap => ap === p?.id || ap === p?.value);

    if (isActivePolygon) {
      setPropertyPopover({ 
        anchor: { top: e.domEvent?.y, left: e.domEvent?.x }, 
        propertyName: markers[p.property_id][0]?.property?.name, 
        locations: markers[p.property_id] 
      });
    } else {
      const offer = _find(uniqOffers, o => o.locations.find(l => l.id === p.id));

      getParcelImages(offer.id)
      .then(({ payload: { data: { images } } }) => {
        const mainImage = _find(images, (i) => i.main);

        setOfferPopover({ 
          position: { top: e.domEvent?.y, left: e.domEvent?.x }, 
          offer: { ...offer, mainImage }
        });
      });
    }
  };
  const handleLocationClick = (location) => {
    mapInstance.fitBounds(new window.google.maps.LatLngBounds(new window.google.maps.LatLng(location.lat, location.lng)), { left: 32, top: 32, right: 32, bottom: 32 });
  };
  // const scrollToMap = useCallback((refMap) => refMap.current.scrollIntoView({ behavior: 'smooth', block: 'end' }), []);

  // Custom button that center map
  const createCenterControl = useCallback((activeOffer) => {
    const controlButton = document.createElement("button");

    controlButton.style.backgroundColor = "#fff";
    controlButton.style.border = "2px solid #fff";
    controlButton.style.borderRadius = "3px";
    controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlButton.style.color = "rgb(25,25,25)";
    controlButton.style.cursor = "pointer";
    controlButton.style.display = "flex";
    controlButton.style.justifyContent = "center";
    controlButton.style.alignItems = "center";
    controlButton.style.width = "100%";
    controlButton.style.height = "auto";
    controlButton.style.padding = "6px";
    controlButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="mdi-crosshairs-gps" width="24" height="24" viewBox="0 0 24 24"><path d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z" /></svg>`;
    controlButton.title = "Click to recenter the map";
    controlButton.type = "button";

    controlButton.addEventListener("click", () => {
      centerMap({
        minLatitude: activeOffer.min_latitude,
        minLongitude: activeOffer.min_longitude,
        maxLatitude: activeOffer.max_latitude,
        maxLongitude: activeOffer.max_longitude
      });
    });
    return controlButton;
  }, [centerMap])
  const drawCustomControl = useCallback((map, activeOffer) => {
    const centerControlDiv = document.createElement("div");
    const centerControl = createCenterControl(activeOffer);

    centerControlDiv.style.marginRight = '10px';
    centerControlDiv.style.marginTop = '4px';
    centerControlDiv.appendChild(centerControl);
    map.controls[8].push(centerControlDiv);
  }, [createCenterControl]);
  useEffect(() => {
    return () => {
      clearLocalFeedMapOffers();
      clearMapData();
    };
  }, [clearMapData, clearLocalFeedMapOffers]);
  useEffect(() => {
    if (!!mapInstance && !!activeOffer.id) {
      // if (activeOffer.locations.length === 1 && !activeOffer.visited && (!activeOffer.locations_locked || !activeOffer.locked)) {
      //   const cameraOptions = {
      //     tilt: 0,
      //     heading: 0,
      //     zoom: 13,
      //     center: { lat: (activeOffer.min_latitude + activeOffer.max_latitude) / 2, lng: (activeOffer.min_longitude + activeOffer.max_longitude) / 2 },
      //   };
      //   let animate = (time) => {
      //     update(time);
      //     requestAnimationFrame(animate);
      //   };
      //   const tween = new Tween(cameraOptions)
      //     .to({ tilt: 45, heading: 60, zoom: 18 }, 15000)
      //     .easing(Easing.Quadratic.Out)
      //     .onUpdate(() => mapInstance.moveCamera(cameraOptions))
      //     .onStop(() => {
      //       animate = null;
      //       setIsEasing(false);
      //     })
      //     .onComplete(() => tween.stop())
      //   const observer = new IntersectionObserver(([entry]) => {
      //     if (parseFloat(entry.intersectionRatio.toFixed(1)) >= 0.6 && !!animate) {
      //       scrollToMap(refMap);
      //       tween.start();
      //       animate();
      //     }
      //   }, { rootMargin: '0px', root: null, threshold: [0.1, 0.6, 1.0] });

      //   !!refMap.current && observer.observe(refMap.current);

      // }
      drawCustomControl(mapInstance, activeOffer);

      if (!!activeOffer?.locations?.length) {
        if (showLocatedMap) {
          const place = window.google.maps.importLibrary('places');
          const query = activeOffer?.main_location?.approximate_address?.replace(/[0-9]/g, '');
  
          place.then((p) => {
            const request = {
              query,
              fields: ['place_id', 'geometry']
            };
            const places = new p.PlacesService(mapInstance);
      
            places.findPlaceFromQuery(request, (places) => {
              if (places?.length) {
                const place = places[0];
                
                mapInstance.fitBounds(new window.google.maps.LatLngBounds(place?.geometry?.viewport), { left: 32, top: 32, right: 32, bottom: 32 });
                  
              } else {
                console.log('No results');
              }
            })
          });
        } else {
          centerMap({
            minLatitude: activeOffer.min_latitude,
            minLongitude: activeOffer.min_longitude,
            maxLatitude: activeOffer.max_latitude,
            maxLongitude: activeOffer.max_longitude
          });
        }
      } else {
        mapInstance.fitBounds(new window.google.maps.LatLngBounds({ north: 49.72, south: 18.82, west: -179.42, east: -66.43 }), { left: 32, top: 32, right: 32, bottom: 32 });
      }
    } 
  }, [activeOffer, mapInstance, centerMap, showLocatedMap, drawCustomControl]);

  if (!activeOffer.id) return <div />;
  return (
    <>
      <Popover
        anchorReference={!!propertyPopover.anchor?.top ? 'anchorPosition' : 'anchorEl'}
        open={!!propertyPopover?.anchor}
        onClose={() => setPropertyPopover({ anchor: null, propertyName: '', locations: [] })}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        PaperProps={{ sx: { width: '210px', minHeight: '80px', p: 1.5 } }}
        {...(!!propertyPopover.anchor?.top ? { anchorPosition: propertyPopover.anchor } : { anchorEl: propertyPopover.anchor } )}
      >
        <Typography sx={{ fontSize: '10px', fontWeight: 600 }}>{propertyPopover.propertyName}</Typography>
        <Typography sx={{ fontSize: '10px', mb: 1 }}>{propertyPopover.locations?.length} {` parcel(s)`}</Typography>
        {_map(propertyPopover.locations, (l) => 
          <Typography key={l.id} variant='body2' component='div' sx={{ fontSize: '10px', cursor: 'pointer', color: '#4877F0' }} onClick={() => handleLocationClick(l)}>{l.address}</Typography>
        )}
      </Popover>
      <Popover
        anchorPosition={offerPopover.position}
        anchorReference='anchorPosition'
        open={!!offerPopover?.position}
        onClose={() => setOfferPopover({ position: null, offer: null })}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        PaperProps={{ sx: { width: '340px', minHeight: '140px' } }}
      >
        <Card 
          {...offerPopover.offer} 
          sx={{ background: '#fff', border: 'none', cursor: 'auto', height: 'auto', gap: 1.5, minHeight: '140px', '&:hover': { border: 'none !important' } }} 
          isMapCard
        />
      </Popover>
      <GoogleMap
        zoom={zoom}
        center={center}
        polygons={polygons}
        markers={groupedMarkers}
        onPolygonClick={handlePolygonClick}
        onBoundsChange={handleBoundsChange}
        onMarkerClick={handleMarkerClick}
        options={options}
      />
    </>
  );
};

Map.propTypes = {
  preview: PropTypes.bool
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Map {...props} />
    </ErrorBoundary>
  );
};
export const Fallback = () => {
  return (
    <Skeleton
      variant='rectangular'
      animation='wave'
      width={200}
      height={50}
    />
  );
};

export default memo(WrappedComponent);