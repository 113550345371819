import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getDealspacesForChats as getDealspacesForChatsAction,
  getDealspacesList as getDealspacesListAction,
  getDealspaces as getDealspacesAction,
  getNotViewedDealspaces as getNotViewedDealspacesAction,
  getDealspace as getDealspaceAction,
  createDealspace as createDealspaceAction,
  updateDealspace as updateDealspaceAction,
  deleteDealspace as deleteDealspaceAction,
  setDealspaceUnreadCountConversations as setDealspaceUnreadCountConversationsAction,
  setDealspaceUnreadCoversationUpdated as setDealspaceUnreadCoversationUpdatedAction,
  clearLocalDealspacesForChats as clearLocalDealspacesForChatsAction,
  clearLocalDealspacesList as clearLocalDealspacesListAction,
  clearLocalDealspaces as clearLocalDealspacesAction,
  clearLocalNotViewedDealspaces as clearLocalNotViewedDealspacesAction,
  clearLocalActiveDealspace as clearLocalActiveDealspaceAction
} from 'actions/dealspaces';

const useDealspaces = () => {
  const dispatch = useDispatch();
  const getDealspacesForChats = useCallback(({ group_id, offset }) => dispatch(getDealspacesForChatsAction({ group_id, offset })), [dispatch]);
  const getDealspacesList = useCallback(({ group_id, offset }) => dispatch(getDealspacesListAction({ group_id, offset })), [dispatch]);
  const getDealspaces = useCallback(({ group_id, limit, offset, orders, query }) => dispatch(getDealspacesAction({ group_id, limit, offset, orders, query })), [dispatch]);
  const getNotViewedDealspaces = useCallback(({ offset }) => dispatch(getNotViewedDealspacesAction({ offset })), [dispatch]);
  const getDealspace = useCallback(id => dispatch(getDealspaceAction(id)), [dispatch]);
  const createDealspace = useCallback(({ group_id, dealspace }) => dispatch(createDealspaceAction({ group_id, dealspace })), [dispatch]);
  const updateDealspace = useCallback(dealspace => dispatch(updateDealspaceAction(dealspace)), [dispatch]);
  const deleteDealspace = useCallback(id => dispatch(deleteDealspaceAction(id)), [dispatch]);
  const setDealspaceUnreadCountConversations = useCallback(({ unread_offer_conversations_count, dealspace_id }) => 
    dispatch(setDealspaceUnreadCountConversationsAction({ unread_offer_conversations_count, dealspace_id }))
  , [dispatch]);
  const setDealspaceUnreadCoversationUpdated = useCallback(({ unread_conversation, dealspace_id }) => 
  dispatch(setDealspaceUnreadCoversationUpdatedAction({ unread_conversation, dealspace_id }))
, [dispatch]);
  const clearLocalDealspacesForChats = useCallback(() => dispatch(clearLocalDealspacesForChatsAction()), [dispatch]);
  const clearLocalDealspacesList = useCallback(() => dispatch(clearLocalDealspacesListAction()), [dispatch]);
  const clearLocalDealspaces = useCallback(() => dispatch(clearLocalDealspacesAction()), [dispatch]);
  const clearLocalNotViewedDealspaces = useCallback(() => dispatch(clearLocalNotViewedDealspacesAction()), [dispatch]);
  const clearLocalActiveDealspace = useCallback(() => dispatch(clearLocalActiveDealspaceAction()), [dispatch]);

  return {
    getDealspacesForChats,
    getDealspacesList,
    getDealspaces,
    getNotViewedDealspaces,
    getDealspace,
    createDealspace,
    updateDealspace,
    deleteDealspace,
    setDealspaceUnreadCountConversations,
    setDealspaceUnreadCoversationUpdated,
    clearLocalDealspacesForChats,
    clearLocalDealspacesList,
    clearLocalDealspaces,
    clearLocalNotViewedDealspaces,
    clearLocalActiveDealspace
  };
};

export default useDealspaces;