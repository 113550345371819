import {
  getOfferFromRecipientTokenRoutine,
  getActivityLogRecipientsRoutine,
  getSharedRecipientsRoutine,
  getRecipientRoutine,
  createRecipientRoutine,
  updateRecipientRoutine,
  deleteRecipientRoutine,
  notifyRecipientsRoutine,
  clearLocalActivityLogRecipientsRoutine,
  clearLocalSharedRecipientsRoutine
} from 'actions';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import _find from 'lodash/find';

export const initialState = {
  activityLog: {
    data: [],
    query: '',
    pagination: { limit: 25, offset: 0, count: 0, total_count: -1 },
    fetching: true
  },
  shared: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 } },
  recipient: {
    id: '',
    user: { id: '', first_name: '', last_name: '' },
    list: { name: '' },
    email: '',
    notes: '',
    property_visits: '',
    offered_amount_cents: 0,
    offered_percent: 0,
    offered_at: '',
    email_opens_count: 0,
    visits_count: 0,
    visits_duration: '',
    saved: false,
    bookmarked: false,
    locked: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getOfferFromRecipientTokenRoutine.SUCCESS: {
      return state;
    }
    case getActivityLogRecipientsRoutine.REQUEST: {
      const { ids } = action.payload;
      console.log(ids);
      const activityLog = { ...state.activityLog, fetching: !!ids ? false : true };

      return { ...state, activityLog };
    }
    case getActivityLogRecipientsRoutine.SUCCESS: {
      const { response: {data: { recipients, pagination }}, query, ids } = action.payload;
      const activityLog = { 
        data: !!ids ? _map(state.activityLog.data, (r) => {
                        const updatedRecipient = _find(recipients, { 'id': r.id });
                        return updatedRecipient ? { ...r, ...updatedRecipient } : r;
                      }) 
                      : recipients, 
        query, 
        pagination: !!ids ? state.activityLog.pagination : pagination, 
        fetching: false 
      };
console.log({activityLog});
      return { ...state, activityLog };
    }
    case getActivityLogRecipientsRoutine.FAILURE: {
      const activityLog = { ...state.activityLog, fetching: false };

      return { ...state, activityLog };
    }
    case getSharedRecipientsRoutine.SUCCESS: {
      const { data: { recipients, pagination } } = action.payload;

      return { ...state, shared: { data: [...state.shared.data, ...recipients], pagination } };
    }
    case getRecipientRoutine.SUCCESS: {
      const { data: { recipient } } = action.payload;
      const updatedRecipient = { ...state.recipient, ...recipient };
      const activityLog = { ...state.activityLog, data: _map(state.activityLog.data, r => r.id === recipient.id ? { ...r, ...recipient } : r) };

      return { ...state, activityLog, recipient: updatedRecipient };
    }
    case createRecipientRoutine.SUCCESS: {
      return state;
    }
    case updateRecipientRoutine.SUCCESS: {
      const { response: { status }, recipient } = action.payload;
      const updatedRecipient = { ...state.recipient, ...(!!recipient.localMember ? { recipient: { ..._omit(recipient, 'member_id'), member: recipient.localMember } } : { recipient }) };
      const activityLog = { ...state.activityLog, data: _map(state.activityLog.data, r => r.id === recipient.id ? { ...r, ...recipient } : r) };

      return { ...state, ...(status === 204 && { recipient: updatedRecipient, activityLog }) };
    }
    case deleteRecipientRoutine.SUCCESS: {
      return state;
    }
    case notifyRecipientsRoutine.SUCCESS: {
      return state;
    }
    case clearLocalActivityLogRecipientsRoutine.SUCCESS: {
      return { ...state, activityLog: initialState.activityLog };
    }
    case clearLocalSharedRecipientsRoutine.SUCCESS: {
      return { ...state, shared: initialState.shared };
    }
    default: {
      return state;
    }
  };
};

export default reducer;