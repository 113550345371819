import styled from '@mui/material/styles/styled';
import illustrationSrc from 'assets/images/background/dealspace-empty.png';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#A8A8A8',
  padding: '32px 16px 40px',
  [theme.breakpoints.up('md')]: {
    padding: '56px 16px'
  }
}));

export const Mark = styled('span')({
  fontWeight: 700,
  textDecoration: 'underline'
});

export const Illustration = styled(props => (
  <img src={illustrationSrc} alt='illustration' {...props} />
))(({ theme }) => ({
  display: 'block',
  width: '100%',
  maxWidth: 300,
  [theme.breakpoints.up('md')]: {
    marginLeft: '-40px'
  }
}));

export const ContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '32px 50px',
  paddingTop: '32px',
  [theme.breakpoints.up('md')]: {
  flexDirection: 'row',
    paddingTop: '60px'
  }
}));

export const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
  gap: '60px'
});