import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export const Heading = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '24px 12px',
  padding: '40px 24px 40px',
});

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
}));

export const Content = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '470px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 24px',
  [theme.breakpoints.up('md')]: {
    width: '470px',
  }
}));

export const Footer = styled(Box)({
  minHeight: 26,
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '4px 0px 10px',
});

export const SkipButton = styled(Button)(({theme}) => ({
  fontSize: theme.typography.pxToRem(12),
  color: '#4A4A4A !important',
  fontWeight: 400,
  fontStyle: 'italic',
  textDecoration: 'underline !important',
  whiteSpace: 'nowrap',
  backgroundColor: 'transparent !important',
  padding: 0,
  [theme.breakpoints.up('sm')]: {
    marginTop: '-32px',
  }
}));