import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

const Content = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  paddingTop: '6px',
});
const FileInput = styled('input')({ display: 'none' });

export { Content, FileInput };