import { memo, useCallback, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import _differenceWith from 'lodash/differenceWith';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Local files
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useCustomSelector from 'hooks/useCustomSelector';
import useDealspaces from 'hooks/useDealspaces';
import useError from 'hooks/useError';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250
    }
  }
};

const AssetsMultiselect = ({ value, disabled = false, onChange }) => {
  const { group_id } = useParams();
  const { getDealspacesList, clearLocalDealspacesList } = useDealspaces();
  const { setError } = useError();
  const { data, pagination } = useCustomSelector(state => state.dealspaces.list);
  const allSelected = !disabled ? _differenceWith(data, value, (a, b) => a.id === b).length === 0 : false;

  const handleAllClick = () => onChange({ value: allSelected ? [] : _map(data, d => d.id), allSelected: !allSelected });
  const handleItemClick = id => {
    const newValue = !!_find(value, v => v === id) ? _filter(value, v => v !== id) : [...value, id];

    onChange({
      value: newValue,
      allSelected: _differenceWith(data, newValue, (a, b) => a.id === b).length === 0
    });
  };
  const fetchDealspaces = useCallback((offset, group_id) => {
    getDealspacesList({ group_id, offset })
    .then(({ payload: { data: { pagination: { total_count } } } }) =>
      !total_count && onChange({ value: [], allSelected: true })
    )
    .catch(e => setError(e));
  }, [getDealspacesList, setError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (group_id) clearLocalDealspacesList().then(() => fetchDealspaces(0, group_id));

    return () => {
      if (group_id) clearLocalDealspacesList();
    }
  }, [group_id, fetchDealspaces, clearLocalDealspacesList]);

  return (
    <FormControl fullWidth disabled={disabled} margin='normal'>
      <InputLabel id='dealspaces-label'>Select dealspace access rights</InputLabel>
        <Select
          labelId='dealspaces-label'
          id='dealspaces-label-checkbox'
          multiple={!!pagination.total_count || disabled}
          value={!!pagination.total_count || disabled ? value : (allSelected ? 'all' : value)}
          input={<OutlinedInput label='Select dealspace access rights' />}
          renderValue={selected => allSelected ? 'All' : `${selected.length} Dealspace${selected.length > 1 ? 's' : ''}`}
          MenuProps={MenuProps}
        >
          <MenuItem disabled={!pagination.total_count} value='all' onClick={handleAllClick}>
            <Checkbox checked={allSelected} />
            <ListItemText primary='All current and new' />
          </MenuItem> 
          <Divider />
          {_map(data, ({ id, name }) => {
            const checked = allSelected || _map(value, v => v).indexOf(id) > -1;

            return (
              <MenuItem
                key={id}
                value={id}
                onClick={() => handleItemClick(id)}
              >
                <Checkbox checked={checked} />
                <ListItemText primary={name} />
              </MenuItem>
            );
          })}
        </Select>
    </FormControl>
  );
};

AssetsMultiselect.propTypes = {
  value: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <AssetsMultiselect {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);