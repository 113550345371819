import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getShortProfile as getShortProfileAction,
  getFullProfile as getFullProfileAction,
  unsubscribe as unsubscribeAction,
  updateProfile as updateProfileAction,
  setLocalUserCounts as setLocalUserCountsAction,
  changeProfilePassword as changeProfilePasswordAction,
  clearLocalProfile as clearLocalProfileAction
} from 'actions/profile';

const useProfile = () => {
  const dispatch = useDispatch();
  const getShortProfile = useCallback(() => dispatch(getShortProfileAction()), [dispatch]);
  const getFullProfile = useCallback(() => dispatch(getFullProfileAction()), [dispatch]);
  const unsubscribe = useCallback(recipientToken => dispatch(unsubscribeAction(recipientToken)), [dispatch]);
  const updateProfile = useCallback(user => dispatch(updateProfileAction(user)), [dispatch]);
  const setLocalUserCounts = useCallback(counts => dispatch(setLocalUserCountsAction(counts)), [dispatch]);
  const changeProfilePassword = useCallback(({ current_password, password }) => dispatch(changeProfilePasswordAction({ current_password, password })), [dispatch]);
  const clearLocalProfile = useCallback(() => dispatch(clearLocalProfileAction()), [dispatch]);

  return { getShortProfile, getFullProfile, unsubscribe, updateProfile, setLocalUserCounts, changeProfilePassword, clearLocalProfile };
};

export default useProfile;