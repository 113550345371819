import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Root = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 10, right: 40,
  width: 'min(330px, 100% - 80px)',
  height: 'min(520px, 80vh)',
  zIndex: theme.zIndex.modal + 2,
  display: 'flex',
  flexDirection: 'column',
  background: 'white',
  boxShadow: '0 1px 10px 5px rgba(0, 0, 0, 0.2)',
  borderRadius: '12px',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    top: 60, bottom: 30,
    right: 4, left: 4,
    width: 'calc(100% - 8px)',
    height: 'calc(100% - 90px)',
  }
}));


export const Content = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden'
});
