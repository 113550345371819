import { memo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
// Local files
import { Root, NavContainer } from './DealspaceTabContent.styled';
import PropertyDialog from 'components/Dialogs/Property/Property';
import FloatingButton from 'components/Common/FloatingButton/FloatingButton';
import AddIcon from 'components/Common/Icons/AddIcon';
import Navigation from '../Navigation/Navigation';
import Empty from '../Empty/Empty';
import Property from '../Property/Property';
import useCustomSelector from 'hooks/useCustomSelector';

const DealspaceTabContent = () => {
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { amount, dealspaceId } = useCustomSelector(state => ({
    amount: state.properties.dealspace.pagination.total_count,
    dealspaceId: state.dealspaces.dealspace.id
  }));
  const [anchorMobileNav, setAnchorMobileNav] = useState(null);
  const [open, setOpen] = useState(false);

  const handleMobileNavClick = anchor => setAnchorMobileNav(anchor);
  const handleMobileNavClose = () => setAnchorMobileNav(null);

  if (amount < 0) return <div />;
  if (amount === 0) return <Empty />;
  return (
    <Box width='100%'>
      <PropertyDialog
        dealspaceId={dealspaceId}
        open={open}
        onClose={() => setOpen(false)}
      />
      <Popover
        open={!!anchorMobileNav}
        anchorEl={anchorMobileNav}
        onClose={handleMobileNavClose}
        PaperProps={{ variant: 'popup', color: 'light', sx: { mt: 1 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box p={1} sx={{ maxWidth: '300px' }}>
          <Navigation 
            onCreateProperty={() => setOpen(true)}
            isDesktop={mediaDesktop}
            onCloseMobileNav={handleMobileNavClose}
          />
        </Box>
      </Popover>
      <Root>
        <NavContainer>
          <Navigation
            onCreateProperty={() => setOpen(true)}
            isDesktop={mediaDesktop}
            onCloseMobileNav={handleMobileNavClose}
          />
        </NavContainer>
        <Container maxWidth='lg' sx={{ mx: 0, p: 0, display: 'flex', flexDirection: 'column', gap: '32px' }}>
          <Property onMobileNavClick={handleMobileNavClick} />
        </Container>
      </Root>
      {!mediaDesktop &&
        <FloatingButton
          startIcon={<AddIcon />}
          onClick={() => setOpen(true)}
        >
          Add Property
        </FloatingButton>
      }
    </Box>
  );
};

export default memo(DealspaceTabContent);