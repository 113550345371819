import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import { getLocationParcel as getLocationParcelAction } from 'actions/locations';

const useLocations = () => {
  const dispatch = useDispatch();
  const getLocationParcel = useCallback((coordinates) => dispatch(getLocationParcelAction(coordinates)), [dispatch]);

  return { getLocationParcel };
};

export default useLocations;