import { memo, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import _isUndefined from 'lodash/isUndefined';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Root, StyledLink } from './Notifications.styled';
import Tiny from 'components/Tiny/Tiny';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import AdvancedEmailSettings from 'components/Drawers/AdvancedEmailSettings/AdvancedEmailSettings';

const Notifications = ({ 
  disabled, subject, message, recipientsType, notify = false, offer = null, onChange, notifyLabel, selectLabel = 'Recipients',
  subjectLabel = 'Subject line', tinyPlaceholder = 'Add optional, personalized message to this Offering', allowSettings = true,
  settings, onSettingsChange
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const drawerProps = { open: drawerOpen, data: settings };

  const renderValue = value => {
    if (value === 'all') return `All recipients (${offer?.recipients_count})`;
    if (value === 'email_opened') return `Email opened recipients (${offer?.email_opened_recipients_count})`;
    if (value === 'email_unopened') return `Unopened emails (${offer.email_unopened_recipients_count})`;
    if (value === 'visited') return `Visited offering recipients (${offer?.visited_recipients_count})`;
    if (value === 'unlocked') return `Signed CA recipients (${offer?.unlocked_recipients_count})`;
    return '';
  };
  const handleSettingsClick = () => setDrawerOpen(true);
  const handleSettingsClose = s => {
    setDrawerOpen(false);
    if (!_isUndefined(s)) onSettingsChange(s);
  };

  return (
    <Root>
      <AdvancedEmailSettings
        {...drawerProps}
        onClose={handleSettingsClose}
      />
      {!!notifyLabel &&
        <FormControlLabel
          disabled={disabled}
          control={<Checkbox color='secondary' />}
          label={notifyLabel}
          sx={{ ml: 'unset', ...!!offer && { justifyContent: 'center' } }}
          checked={notify}
          onChange={({ target: { checked: n } }) => onChange({ n })}
        />
      }
      {allowSettings && <StyledLink onClick={handleSettingsClick}>Advanced email settings</StyledLink>}
      <Collapse in={notify}>
        {!!offer &&
          <TextField
            select
            fullWidth
            disabled={disabled}
            labelId='recipients-select-label'
            id='recipients-select'
            label={selectLabel}
            value={recipientsType}
            sx={{ mt: 1.5 }}
            SelectProps={{ multiple: !offer, renderValue }}
            onChange={({ target: { value: r } }) => onChange({ r })}
          >
            <MenuItem value='all'>All recipients ({offer?.recipients_count ?? 0})</MenuItem>
            {offer?.email_opened_recipients_count > 0 &&
              <MenuItem value='email_opened'>
                Email opens ({offer?.email_opened_recipients_count ?? 0})
              </MenuItem>
            }
            {offer?.visited_recipients_count > 0 &&
              <MenuItem value='visited'>
                Visited offering ({offer?.visited_recipients_count ?? 0})
              </MenuItem>
            }
            {offer?.locked && offer?.unlocked_recipients_count > 0 &&
              <MenuItem value='unlocked'>
                Signed CA ({offer?.unlocked_recipients_count ?? 0})
              </MenuItem>
            }
            {offer?.email_unopened_recipients_count > 0 &&
              <MenuItem value='email_unopened'>
                Unopened emails ({offer.email_unopened_recipients_count})
              </MenuItem>
            }
          </TextField>
        }
        <TextField
          disabled={disabled}
          fullWidth
          label={subjectLabel}
          value={subject.value}
          sx={{ mt: 1.5, mb: 1.5 }}
          onChange={({ target: { value } }) => onChange({ s: { value, error: '' } })}
        />
        <Box width='100%' sx={{ overflowY: 'auto' }}>
          <Tiny
            id='notificationsMessage'
            value={message}
            onChange={({ v: m }) => onChange({ m })}
            plugins={['lists', 'link', 'autolink', 'emoticons', 'charmap', 'mergetags']}
            toolbar='bold italic underline forecolor indent align bullist link h2 h3 emoticons mergetags'
            otherInitProps={{
              height: 300,
              menubar: false,
              elementpath: false,
              paste_webkit_styles: 'all',
              branding: false,
              placeholder: tinyPlaceholder,
              object_resizing: false,
              mergetags_list: [
                {
                  title: 'Personalize',
                  menu: [
                    {
                      value: 'first_name',
                      title: 'First Name'
                    },
                    {
                      value: 'last_name',
                      title: 'Last Name'
                    }
                  ]
                }
              ]
            }}
          />
        </Box>
      </Collapse>
    </Root>
  );
};

Notifications.propTypes = {
  notify: PropTypes.bool.isRequired,  
  message: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  recipients: PropTypes.oneOfType([
    PropTypes.string, PropTypes.array
  ]),
  options: PropTypes.array,
  offer: PropTypes.object,
  notifyLabel: PropTypes.string,
  selectLabel: PropTypes.string,
  subjectLabel: PropTypes.string,
  tinyPlaceholder: PropTypes.string,
  allowSettings: PropTypes.bool,
  settings: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSettingsChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Notifications {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);