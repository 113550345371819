import { memo, useId } from 'react';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
// Local files
import { Root, DealspaceInfo, Image } from './Item.styled';
import image from 'assets/images/deal/default-cover.jpg';
import ArrowOutwardIcon from 'components/Common/Icons/ArrowOutwardIcon';
import InfoCard from 'components/Offers/InfoCard/InfoCard';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';

/**
 * @param {object=} dealspace
 * @param {array} offers
 * @param {string=} locationType
 * @returns {node}
 */

const Item = ({ dealspace, offers, locationType = 'dashboard' }) => {
  const randomId = useId();
  const showInfo = locationType === 'dashboard' || locationType === 'group';

  return (
    <Root key={dealspace?.id || randomId}>
      {showInfo &&
        <DealspaceInfo component={NavLink} to={`/dealspaces/${dealspace?.id}/offerings`}>
          <Image sx={{ backgroundImage: `url('${getRepresentation(dealspace?.cover?.representations, representationVariants.SMALL) ?? image}')` }} />
          <Typography variant='subtitle2'>{dealspace?.name} <ArrowOutwardIcon sx={{ mb: '-6px', ml: '4px' }} /></Typography>
        </DealspaceInfo>
      }
      {_map(offers, o => <InfoCard key={o.id} {...o} { ...{locationType, dealspace}}/>)}
    </Root>
  );
};

Item.propTypes = {
  dealspace: PropTypes.shape({
    id: PropTypes.string,
    cover: PropTypes.shape({
      representations: PropTypes.array
    }),
    name: PropTypes.string
  }),
  offers: PropTypes.array.isRequired,
  locationType: PropTypes.string
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Item {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);