import { memo } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
// Local files
import { googleApiKey as googleMapsApiKey } from 'apis';

const libraries = ['places'];
const region = 'US';
const language = 'en';

const GoogleApiWrapper = ({ children, type }) => {
  const { isLoaded, loadError } = useJsApiLoader({ googleMapsApiKey, libraries, region, language });

  if (loadError) return <div>Failed to load {type}</div>;
  return isLoaded ? children : <Skeleton animation='wave' width='100%' height='100%' />;
};

GoogleApiWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired
};

export default memo(GoogleApiWrapper);