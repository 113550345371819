import { Fragment, memo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Scrollbars from 'react-custom-scrollbars-2';
import _differenceWith from 'lodash/differenceWith';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import _startCase from 'lodash/startCase';
import _startsWith from 'lodash/startsWith';
import _sortBy from 'lodash/sortBy';
// Local files
import { ReactComponent as WebsiteIcon } from 'assets/icons/website.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin.svg';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import PhoneField from 'components/Common/Inputs/PhoneField/PhoneField';
import ContentSection from '../../Users/DialogComponents/ContentSection/ContentSection';
import Prompt from '../../Users/DialogComponents/Prompt/Prompt';
import TeammatesAutocomplete from '../../Users/Autocomplete/Autocomplete';
import GoogleAutocomplete from 'components/Common/Location/GoogleAutocomplete/GoogleAutocomplete';
import SaveProfile from '../SaveProfile/SaveProfile';
import { Item, ListItem } from './EditProfile.styled';
import { primaryActivities as primaryActivitiesList, riskProfilesList, assetClassesList } from 'helpers/constants';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useProfile from 'hooks/useProfile';
import useSuccess from 'hooks/useSuccess';
import useApp from 'hooks/useApp';

const Profile = () => {
  const { getFullProfile, updateProfile } = useProfile();
  const { closeEditProfileDialog } = useApp();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { user, open } = useCustomSelector(state => {
    const user = state.profile.user;
    const open = state.app.editProfileDialog.open;

    return { user, open };
  });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [headline, setHeadline] = useState('');
  const [location, setLocation] = useState({ value: '', formattedValue: '', latitude: null, longitude: null });
  const [title, setTitle] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [about, setAbout] = useState('');
  const [mobilePhone, setMobilePhone] = useState({ value: '', formattedValue: '', error: '' });
  const [officePhone, setOfficePhone] = useState({ value: '', formattedValue: '', error: '' });
  const [officePhoneExtension, setOfficePhoneExtension] = useState('');
  const [primaryActivities, setPrimaryActivities] = useState([]);
  const [geographicFocuses, setGeographicFocuses] = useState({ value: [], formattedValue: [] });
  const [geographicFocus, setGeographicFocus] = useState({ value: '', formattedValue: '', latitude: null, longitude: null });
  const [riskProfiles, setRiskProfiles] = useState([]);
  const [assetClasses, setAssetClasses] = useState([]);
  const [linkedinProfileUrl, setLinkedinProfileUrl] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [contents, setContents] = useState({ value: [], formattedValue: [] });
  const [teammates, setTeammates] = useState({ value: [], formattedValue: [] });
  const [processing, setProcessing] = useState(false);
  const [saveDialog, setSaveDialog] = useState(false);

  const handleTeammatesChange = value => {
    setTeammates({
      value: _map(value, v => !!v.inputValue
        ? ({ invitation_attributes: { email: v.inputValue, message: 'Test message from team invite in profile' } })
        : ({ user_id: v.id })
      ),
      formattedValue: value
    });
  };

  const onClose = () => !_isEmpty(newFields()) ? setSaveDialog(true) : closeEditProfileDialog();
  const handleClose = () => closeEditProfileDialog().then(() => setSaveDialog(false));
  const needToUpdateField = (a, b) => !_isEqual(a, b) && (!!a || !!b);
  const newFields = () => {
    const newContents = _filter(contents.value, c => _startsWith(c.id, 'new_')).filter(c => !c.destroy);
    const oldContents = _filter(contents.value, c => !_startsWith(c.id, 'new_'));
    const oldInvitationTeammates = _filter(user.teammates, t => !!t.invitation);
    const newInvitationTeammates = _filter(teammates.formattedValue, t => !t.data);
    const creatableInvitationTeammates = _differenceWith(newInvitationTeammates, oldInvitationTeammates, (a, b) => _isEqual(a.title, b.invitation.email));
    const deletableInvitationTeammates = _differenceWith(oldInvitationTeammates, newInvitationTeammates, (a, b) => _isEqual(a.invitation.email, b.title));
    const oldUserTeammates = _filter(user.teammates, t => !!t.user);
    const newUserTeammates = _filter(teammates.formattedValue, t => !!t.data);
    const creatableUserTeammates = _differenceWith(newUserTeammates, oldUserTeammates, (a, b) => _isEqual(a.id, b.user.id));
    const deletableUserTeammates = _differenceWith(oldUserTeammates, newUserTeammates, (a, b) => _isEqual(a.user.id, b.id));

    const fields = {
      ...(needToUpdateField(firstName, user.first_name) && { first_name: firstName }),
      ...(needToUpdateField(lastName, user.last_name) && { last_name: lastName }),
      ...(needToUpdateField(headline, user.headline) && { headline }),
      ...(needToUpdateField(location.value, user.location) && { location: location.value, longitude: location.longitude, latitude: location.latitude }),
      ...(needToUpdateField(title, user.title) && { title }),
      ...(needToUpdateField(companyName, user.company_name) && { company_name: companyName }),
      ...(needToUpdateField(about, user.about) && { about }),
      ...(needToUpdateField(mobilePhone.value, user.mobile_phone) && { mobile_phone: mobilePhone.value }),
      ...(needToUpdateField(officePhone.value, user.office_phone) && { office_phone: officePhone.value }),
      ...(needToUpdateField(officePhoneExtension, user.office_phone_extension) && { office_phone_extension: officePhoneExtension }),
      ...(!_isEqual(geographicFocuses.value.sort(), user.geographic_focuses.sort()) && { geographic_focuses_attributes: _map(geographicFocuses.value, (f) => _startsWith(f.id, 'new_') ? _omit(f, ['id']) : f) }),
      ...(needToUpdateField(linkedinProfileUrl, user.linkedin_profile_url) && { linkedin_profile_url: linkedinProfileUrl }),
      ...(needToUpdateField(websiteUrl, user.website_url) && { website_url: websiteUrl }),
      ...(!_isEqual(primaryActivities.sort(), user.primary_activities.sort()) && { primary_activities: primaryActivities }),
      ...(!_isEqual(riskProfiles.sort(), user.risk_profiles.sort()) && { risk_profiles: riskProfiles }),
      ...(!_isEqual(assetClasses.sort(), user.asset_classes.sort()) && { asset_classes: assetClasses }),
      ...(!_isEqual(contents.value, user.contents) && (!!newContents.length || !!oldContents.length) && {
        contents_attributes: [
          ..._map(newContents, nc => _omit(nc, ['id', 'destroy'])),
          ..._map(oldContents, oc => ({ ...oc, ...(oc.destroy && { _destroy: true }) }))
        ],
        localContents: contents.formattedValue
      }),
      ...(!_isEqual(teammates.value, user.teammates) && {
        teammates_attributes: [
          ..._map(creatableInvitationTeammates, cit => ({ invitation_attributes: { email: cit.inputValue, message: 'Test message from team invite in profile' } })),
          ..._map(creatableUserTeammates, cut => ({ user_id: cut.id })),
          ..._map(deletableInvitationTeammates, dit => ({ id: dit.id, _destroy: true })),
          ..._map(deletableUserTeammates, dut => ({ id: dut.id, _destroy: true }))
        ],
        localTeammates: teammates.formattedValue
      })
    };

    return fields;
  };
  const handleSubmit = () => {
    if (!_isEmpty(newFields())) {
      setProcessing(true);
      updateProfile(newFields())
      .then(() => 
        getFullProfile()
        .then(() => setSuccess('Profile successfully updated').then(handleClose))
        .catch((e) => setError(e))
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    } else {
      handleClose();
    }
  };
  const handleAddressChange = formattedValue => setLocation(prev => ({ ...prev, formattedValue, value: '' }));
  const handleFocusChange = formattedValue => setGeographicFocus(prev => ({ ...prev, formattedValue, value: '' }));
  const handleAddressSelect = (data) => setLocation(prev => ({ ...prev, ...data }));
  const handleFocusSelect = (focus) => { 
    setGeographicFocus({ value: '', formattedValue: '', latitude: null, longitude: null });
    setGeographicFocuses(prev => ({ 
      value: [...prev.value, ...[{ id: `new_${geographicFocuses.value?.length}`, address: focus.value, latitude: focus.latitude, longitude: focus.longitude }]], 
      formattedValue: [...prev.formattedValue, ...[{ id: `new_${geographicFocuses.value?.length}`, address: focus.value, latitude: focus.latitude, longitude: focus.longitude }]] 
    }));
  };
  const handleFocusesChange = (id) => {
    setGeographicFocuses({ 
      value: _map(_filter(geographicFocuses.value, (focus) => (_startsWith(focus.id, 'new_') && focus.id !== id) || !_startsWith(focus.id, 'new_') ), (focus) => focus.id === id ? { id: focus?.id, _destroy: true } : focus), 
      formattedValue: _filter(geographicFocuses.formattedValue, (focus) => focus.id !== id)
    });
  };

  const onEntering = () => {
    !!user.first_name && setFirstName(user.first_name);
    !!user.last_name && setLastName(user.last_name);
    !!user.headline && setHeadline(user.headline);
    !!user.location && setLocation({ value: user.location, formattedValue: user.location, latitude: user.latitude, longitude: user.longitude });
    !!user.title && setTitle(user.title);
    !!user.company_name && setCompanyName(user.company_name);
    !!user.about && setAbout(user.about);
    !!user.mobile_phone && setMobilePhone({ value: user.mobile_phone, formattedValue: user.mobile_phone, error: '' });
    !!user.office_phone && setOfficePhone({ value: user.office_phone, formattedValue: user.office_phone, error: '' });
    !!user.office_phone_extension && setOfficePhoneExtension(user.office_phone_extension);
    !!user.primary_activities && setPrimaryActivities(user.primary_activities);
    !!user.geographic_focuses?.length && setGeographicFocuses({ value: user.geographic_focuses, formattedValue: user.geographic_focuses });
    !!user.risk_profiles && setRiskProfiles(user.risk_profiles);
    !!user.asset_classes && setAssetClasses(user.asset_classes);
    !!user.linkedin_profile_url && setLinkedinProfileUrl(user.linkedin_profile_url);
    !!user.website_url && setWebsiteUrl(user.website_url);
    !!user.contents.length && setContents({ value: user.contents, formattedValue: user.contents });
    !!user.teammates.length && setTeammates({
      value: user.teammates,
      formattedValue: _map(user.teammates, t => ({
        ...(!!t.user && {
          id: t.user.id,
          data: {
            avatarPlaceholder: t.user.first_name[0] + t.user.last_name[0],
            photo: t.user.photo,
            first_name: t.user.first_name,
            last_name: t.user.last_name
          }
        }),
        title: !!t.user ? `${t.user.first_name} ${t.user.last_name}` : t.invitation.email,
      }))
    });
  };
  const onExited = () => {
    setFirstName('');
    setLastName('');
    setHeadline('');
    setLocation({ value: '', formattedValue: '', latitude: null, longitude: null });
    setTitle('');
    setCompanyName('');
    setAbout('');
    setMobilePhone({ value: '', formattedValue: '', error: '' });
    setOfficePhone({ value: '', formattedValue: '', error: '' });
    setOfficePhoneExtension('');
    setPrimaryActivities([]);
    setGeographicFocuses({ value: [], formattedValue: [] });
    setGeographicFocus({ value: '', formattedValue: '', latitude: null, longitude: null });
    setRiskProfiles([]);
    setAssetClasses([]);
    setLinkedinProfileUrl('');
    setWebsiteUrl('');
    setContents({ value: [], formattedValue: [] });
    setTeammates({ value: [], formattedValue: [] });
  };

  return (
    <>
      <SaveProfile {...{ open: saveDialog, onSave: handleSubmit, onDiscard: handleClose, onClose: setSaveDialog }} />   
      <BaseDialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth='md'
        title='Edit Profile'
        otherPaperStyle={{ borderRadius: '12px', border: '1px solid #E8E7E7', height: '100%' }}
        otherTitleStyle={{ borderBottom: '1px solid #E8E7E7' }}
        otherActionsStyle={{ borderTop: '1px solid #E8E7E7' }}
        TransitionProps={{ onEntering, onExited }}
        actions={
          <Fragment>
            <Button
              disabled={processing}
              color='error'
              sx={{ width: 90 }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={processing}
              color='primary'
              variant='contained'
              sx={{ width: 90 }}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Fragment>
        }
        content={
          <Scrollbars autoHide autoHideTimeout={1000}>
          <Box sx={{ p: { xs: '16px 16px 32px', md: '24px 8px 32px 40px' } }}>
            <Item>
              <div>
                <TextField
                  label='First Name'
                  value={firstName}
                  onChange={({ target: { value } }) => setFirstName(value)}
                  fullWidth
                  margin='normal'
                />
                <TextField
                  label='Last Name'
                  value={lastName}
                  onChange={({ target: { value } }) => setLastName(value)}
                  fullWidth
                  margin='normal'
                />
              </div>
              <Prompt
                secondary={<>Edit Name in <Link underline='always'>User Settings</Link></>}
                sx={{ marginTop: 'auto', marginBottom: '28px' }}
              />
            </Item>
            <Item>
              <TextField
                label='Headline'
                fullWidth
                margin='normal'
                value={headline}
                onChange={({ target: { value } }) => setHeadline(value)}
              />
              <Prompt secondary='Use this subtitle field to highlight your title or anything you’d like others to know.' />
            </Item>
            <Item>
              <GoogleAutocomplete
                label='My Location'
                placeholder='Select a Country, State, County or City'
                types={['(regions)']}
                value={location.formattedValue}
                onChange={handleAddressChange}
                onSelect={handleAddressSelect}
                sx={{
                  width: '100%',
                  background: 'rgba(255, 255, 255, 0.6)',
                  backdropFilter: 'blur(20px)',
                  WebkitBackdropFilter: 'blur(20px)',
                  borderRadius: '8px',
                  mt: 1,
                  mb: 1
                }}
              />
              <Prompt secondary='suggested' />
            </Item>
            <Item>
              <TextField
                label='Title'
                fullWidth
                inputProps={{ maxLength: 50 }}
                margin='normal'
                value={title}
                onChange={({ target: { value } }) => setTitle(value)}
                />
              <Prompt secondary='suggested' />
            </Item>
            <Item>
              <TextField
                label='Company Name'
                fullWidth
                margin='normal'
                value={companyName}
                onChange={({ target: { value } }) => setCompanyName(value)}
                />
              <Prompt secondary='suggested' />
            </Item>
            <Item>
              <TextField
                label='About'
                multiline
                minRows={3}
                fullWidth
                margin='normal'
                value={about}
                onChange={({ target: { value } }) => setAbout(value)}
              />
              <Prompt secondary='suggested' />
            </Item>
            <Item>
              <PhoneField
                id='mobilePhone'
                sx={{ mt: '16px', mb: '8px' }}
                fullWidth
                label='Mobile Phone'
                formattedValue={mobilePhone.formattedValue}
                error={false}
                helperText={''}
                onChange={({ value, formattedValue, error }) => setMobilePhone({ value, formattedValue, error })}
              />
              <Prompt primary='Phone numbers will not be Public, but will be shared with your Connections.' />
            </Item>
            <Item>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px', mt: '16px', mb: '8px' }}>
                <PhoneField
                  id='officePhone'
                  fullWidth
                  label='Office Phone'
                  formattedValue={officePhone.formattedValue}
                  error={false}
                  helperText={''}
                  onChange={({ value, formattedValue, error }) => setOfficePhone({ value, formattedValue, error })}
                />
                <TextField
                  label='Extension'
                  value={officePhoneExtension}
                  onChange={({ target: { value } }) => setOfficePhoneExtension(value)}
                />
              </Box>
            </Item>
            <Item>
              <Box>
                <FormControl margin='normal' fullWidth>
                  <InputLabel id='primary-activities-label'>Primary Activities</InputLabel>
                  <Select
                    labelId='primary-activities-label'
                    id='primary-activities'
                    multiple
                    value={primaryActivities}
                    onChange={({ target: { value } }) => setPrimaryActivities(value)}
                    input={<OutlinedInput label='Primary Activities' />}
                  >
                    {_map(primaryActivitiesList, pa => <MenuItem sx={{whiteSpace: 'break-spaces'}} key={pa.value} value={pa.value}>{pa.label}</MenuItem>)}
                  </Select>
                </FormControl>
                <GoogleAutocomplete
                  label='Enter Geographic Focus'
                  placeholder='Select a Country, State, County or City'
                  types={['(regions)']}
                  value={geographicFocus.formattedValue}
                  onChange={handleFocusChange}
                  onSelect={handleFocusSelect}
                  sx={{
                    width: '100%',
                    background: 'rgba(255, 255, 255, 0.6)',
                    backdropFilter: 'blur(20px)',
                    WebkitBackdropFilter: 'blur(20px)',
                    borderRadius: '8px',
                    mt: 2
                  }}
                />
                {!!geographicFocuses.formattedValue?.length && <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    background: 'rgba(255, 255, 255, 0.6)',
                    backdropFilter: 'blur(20px)',
                    WebkitBackdropFilter: 'blur(20px)',
                    border: '1px solid #E8E7E7',
                    borderRadius: '8px',
                    mt: 1, mb: 1
                  }}
                >
                  {_map(geographicFocuses.formattedValue, (focus) => 
                  <ListItem key={focus.id}>
                    <Chip
                      label={focus.address}
                      onDelete={() => handleFocusesChange(focus.id)}
                    />
                  </ListItem>)}
                </Box>}
                <FormControl margin='normal' fullWidth>
                  <InputLabel id='risk-profiles-label'>Risk Profile</InputLabel>
                  <Select
                    labelId='risk-profiles-label'
                    id='risk-profiles'
                    multiple
                    value={riskProfiles}
                    onChange={({ target: { value } }) => setRiskProfiles(value)}
                    input={<OutlinedInput label='Risk Profile' />}
                  >
                    {_map(riskProfilesList, rp => <MenuItem key={rp} value={rp}>{_startCase(rp)}</MenuItem>)}
                  </Select>
                </FormControl>
                <FormControl margin='normal' fullWidth>
                  <InputLabel id='asset-class-focus-label'>Asset Class Focus</InputLabel>
                  <Select
                    labelId='asset-class-focus-label'
                    id='asset-class-focus'
                    multiple
                    value={assetClasses}
                    onChange={({ target: { value } }) => setAssetClasses(value)}
                    input={<OutlinedInput label='Asset Class Focus' />}
                  >
                    {_map(_sortBy(assetClassesList), ac => <MenuItem key={ac} value={ac}>{_startCase(ac)}</MenuItem>)}
                  </Select>
                </FormControl>
              </Box>
              <Prompt
                primary='Want more, high quality deal flow?'
                secondary='Sharing Activities & Focus will help Qwincy and other members deliver more of what you’re looking for.'
                sx={{ mb: 'auto', mt: '32px' }}
              />
            </Item>
            <Item>
              <TextField
                InputProps={{ startAdornment: <LinkedinIcon style={{ marginRight: '8px' }} />}}
                placeholder='LinkedIn Profile URL'
                fullWidth
                margin='normal'
                value={linkedinProfileUrl}
                onChange={({ target: { value } }) => setLinkedinProfileUrl(value.toLowerCase())}
              />
              <Prompt secondary='suggested' />
            </Item>
            <Item>
              <TextField
                InputProps={{ startAdornment: <WebsiteIcon style={{ marginRight: '8px' }} />}}
                placeholder='Website URL'
                fullWidth
                margin='normal'
                value={websiteUrl}
                onChange={({ target: { value } }) => setWebsiteUrl(value.toLowerCase())}
              />
            </Item>
            <Item sx={{ alignItems: 'flex-start !important' }}>
              <TeammatesAutocomplete
                sx={{ mt: '16px', mb: '8px'}}
                multiple
                value={teammates.formattedValue}
                onChange={handleTeammatesChange}
              />
              <Prompt
                primary='Team members not on Qwincy?'
                secondary='Just type in their email--they will be invited and automatically added as soon as they join.'
                sx={{ mt: '32px' }}
              />
            </Item>
          <Item sx={{ pt: '8px', alignItems: 'flex-start !important' }}>
            <ContentSection
              data={{
                value: _filter(contents.value, c => !c.destroy),
                formattedValue: _filter(contents.formattedValue, c => !c.destroy),
              }}
              onChange={value => setContents(value)}
            />
            <Prompt
              primary={
                <>Have you...<br/>
                &nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;been featured in an article?<br/>
                &nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;written a publication?<br/>
                &nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;appeared on TV?<br/></>
              }
              secondary='Share with Content entries'
              sx={{ marginBottom: 'auto', marginTop: '4px' }}
            />
            </Item>
          </Box>
          </Scrollbars>
        }
      />
    </>
  );
};

export default memo(Profile);