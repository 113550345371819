import {
  getSavedOfferGroupsRoutine,
  getGroupsRoutine,
  getGroupRoutine,
  createGroupRoutine,
  updateGroupRoutine,
  deleteGroupRoutine,
  setGroupUnreadCountConversationsRoutine,
  clearLocalSavedOfferGroupsRoutine,
  clearLocalGroupsRoutine,
  clearLocalActiveGroupRoutine
} from 'actions';
import _isUndefined from 'lodash/isUndefined';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _dropRight from 'lodash/dropRight';
import _map from 'lodash/map';
import _omit from 'lodash/omit';

export const initialState = {
  saved: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 } },
  group: { id: '', logo: null, header: null, name: '', current_member: { role: '' }, unread_dealspaces_due_offer_conversations_count: 0, unread_dealspaces_due_dealspace_conversations_count: 0 }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getSavedOfferGroupsRoutine.SUCCESS: {
      const { data: { groups, pagination } } = action.payload;
      
      return { ...state, saved: { data: groups, pagination } };
    }
    case getGroupsRoutine.SUCCESS: {
      const { data: { groups, pagination } } = action.payload;

      return { ...state, all: { data: groups, pagination } };
    }
    case getGroupRoutine.SUCCESS: {
      const { data: { group } } = action.payload;
      const updatedGroup = { ...state.group, ...group };
      const findedGroup = !!_find(state.all.data, (item) => item.id === group.id);
      const data = _map(state.all.data, item => item.id === group.id ? { ...item, ...group } : item)

      return { ...state, 
        group: updatedGroup, 
        all: { 
          ...state.all, 
          data: findedGroup ? data : [...[group], ...data],
          ...(!findedGroup && { pagination: { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 } })
        } 
      };
    }
    case createGroupRoutine.SUCCESS: {
      const { data: { group } } = action.payload;
      const oldAllData = state.all.pagination.total_count > 24 ? _dropRight(state.all.data) : state.all.data;
      const data = [...[group], ...oldAllData];
      const pagination = { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 };

      return { ...state, all: { data, pagination } };
    }
    case updateGroupRoutine.SUCCESS: {
      const { response: { status }, group } = action.payload;
      const updatedGroup = {
        ...state.group,
        ..._omit(group, ['localLogo', 'localHeader']),
        ...(!_isUndefined(group.localLogo) && { logo: { id: group.logo, localUrl: group.localLogo.localUrl } }),
        ...(!_isUndefined(group.localHeader) && { header: { id: group.header, localUrl: group.localHeader.url } })
      };
      const data = _map(state.all.data, (item) => item.id === group.id ? { ...item, ...updatedGroup } : item);

      return { ...state, ...(status === 204 && { group: updatedGroup }), all: { ...state.all, data } };
    }
    case deleteGroupRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const data = _filter(state.all.data, (item) => item.id !== id);
      const pagination = { ...state.all.pagination, count: state.all.pagination.count - 1, total_count: state.all.pagination.total_count - 1 };

      return { ...state, ...(status === 204 && { all: { data, pagination } }) };
    }
    case setGroupUnreadCountConversationsRoutine.SUCCESS: {
      const { unread_dealspaces_due_offer_conversations_count, unread_dealspaces_due_dealspace_conversations_count, group_id } = action.payload;

      return { ...state, 
        all: { 
          ...state.all, 
          data: _map(state.all.data, (item) => item.id === group_id ? 
          { ...item, unread_dealspaces_due_offer_conversations_count, unread_dealspaces_due_dealspace_conversations_count } 
          : item) 
        } 
      };
    }
    case clearLocalSavedOfferGroupsRoutine.SUCCESS: {
      return { ...state, saved: initialState.saved };
    }
    case clearLocalGroupsRoutine.SUCCESS: {
      return { ...state, all: initialState.all }
    }
    case clearLocalActiveGroupRoutine.SUCCESS: {
      return { ...state, group: initialState.group }
    }
    default: {
      return state;
    }
  };
};

export default reducer;