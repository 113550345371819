import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

export const MainSkeleton = () => {
  return (
    <Box sx={{ minHeight: 208 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', p: '18px 0' }}>
        <Skeleton sx={{ transform: 'scale(1)', height: 34, width: 34, borderRadius: '50%' }} />
        <Skeleton sx={{ transform: 'scale(1)', height: 14, width: 90 }} />
        <Skeleton sx={{ transform: 'scale(1)', height: 14, width: {xs: '60px', md: '220px'}, ml: 'auto' }} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', p: '12px 0 32px' }}>
        <Skeleton sx={{ transform: 'scale(1)', height: 14, width: 90 }} />
        <Skeleton sx={{ transform: 'scale(1)', height: 14, width: 90 }} />
        <Skeleton sx={{ transform: 'scale(1)', height: 14, width: 90 }} />
      </Box>
      <Skeleton sx={{ transform: 'scale(1)', height: 14, width: '100%' }} />
      <Skeleton sx={{ transform: 'scale(1)', height: 14, width: 230, mt: '24px' }} />
    </Box>
  )
};

export const ImagesSkeleton = () => {
  return (
    <Box>
      <Skeleton sx={{ transform: 'scale(1)', height: 412, width: '100%' }} />
    </Box>
  )
};

export const DescriptionSkeleton = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', p: '12px 0 32px' }}>
      <Skeleton sx={{ transform: 'scale(1)', height: 14, width: '100%' }} />
      <Skeleton sx={{ transform: 'scale(1)', height: 14, width: '100%' }} />
      <Skeleton sx={{ transform: 'scale(1)', height: 14, width: '100%' }} />
      <Skeleton sx={{ transform: 'scale(1)', height: 14, width: '100%' }} />
      <Skeleton sx={{ transform: 'scale(1)', height: 14, width: '100%' }} />
    </Box>
  )
};

export const SenderSkeleton = ({ ...props }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', p: {xs: '16px', lg: '26px'} }} {...props}>
      <Skeleton sx={{ transform: 'scale(1)', height: 44, width: 44, borderRadius: '50%' }} />
      <Skeleton sx={{ transform: 'scale(1)', height: 14, width: 90 }} />
      <Skeleton sx={{ transform: 'scale(1)', height: 14, width: {xs: '60px', md: '220px'}, ml: 'auto' }} />
    </Box>
  )
};

