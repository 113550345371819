import {
  getFeedFiltersAmountRoutine,
  getFeedSharedWithMeOffersRoutine,
  getFeedArchiveOffersRoutine,
  getFeedMySharedOffersRoutine,
  getFeedMarketOffersRoutine,
  getFeedRecipientOffersRoutine,
  getFeedOfferDetailsRoutine,
  clearFeedOfferDataRoutine,
  clearFeedRecipientDataRoutine,
  //////////////////
  getPublishedOffersForChatsRoutine,
  getDealspaceOffersRoutine,
  getDealspaceOffersForChatsRoutine,
  getSharedOffersRoutine,
  getDraftSharedOffersRoutine,
  getActiveSharedOffersRoutine,
  getContactActiveOffersRoutine,
  getEndedSharedOffersRoutine,
  getInContractSharedOffersRoutine,
  getClosedSharedOffersRoutine,
  getGroupOffersForChatsRoutine,
  getBuilderDraftRoutine,
  getBuilderDraftActualFilesCountRoutine,
  getFeedMapOffersRoutine,
  getActivityLogOfferRoutine,
  getOfferActualRecipientsCountRoutine,
  getOfferPreviewRoutine,
  createOfferRoutine,
  updateOfferRoutine,
  deleteOfferRoutine,
  publishOfferRoutine,
  activateOfferRoutine,
  contractOfferRoutine,
  endOfferRoutine,
  closeOfferRoutine,
  disinterestOfferRoutine,
  saveOfferRoutine,
  duplicateOfferRoutine,
  visitOfferRoutine,
  exportOfferRoutine,
  intakeOfferRoutine,
  addToTracksOfferRoutine,
  submitOfferShareFormRoutine,
  removeFromTracksOfferRoutine,
  setOfferUnreadCountConversationsRoutine,
  setLocalOfferCountsRoutine,
  clearLocalSharedOffersRoutine,
  clearLocalContactActiveOffersRoutine,
  clearLocalDealspaceOffersRoutine,
  clearLocalOffersForChatsRoutine,
  clearLocalBuilderOfferRoutine,
  clearLocalFeedMapOffersRoutine,
  clearLocalFeedOfferRoutine,
  clearLocalActivityLogOfferRoutine,
  unlockLocalOfferRoutine,
  setOfferLocalFieldsRoutine,
  setBuilderStateRoutine
} from 'actions';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _mergeWith from 'lodash/mergeWith';
import _omit from 'lodash/omit';
import _reduce from 'lodash/reduce';
import _uniqBy from 'lodash/uniqBy';

const getInitialBuilderState = () => ({
  id: '',
  root_folder: { id: '' },
  dealspace: { id: '', name: '', root_folder: { id: '' }, group: { id: '', name: '', logo: null } },
  offer: null,
  primary_sender: {
    user: {
      first_name: '',
      last_name: '',
      photo: null
    }
  },
  properties_count: 0,
  senders_count: 0,
  name: '',
  status: '',
  version: 0,
  offer_type: '',
  headline: '',
  description: '',
  deadline_type: '',
  deadline_at: '',
  expired_at: '',
  risk_profiles: [],
  property_grouping: '',
  asset_classes: [],
  lease_period: '',
  loan_ratio: '',
  loan_percent: null,
  investment_type: '',
  price_cents: null,
  custom_price: '',
  saving_allowed: false,
  public: false,
  tracked: false,
  visited: false,
  locked: false,
  locations_locked: false,
  min_latitude: null,
  max_latitude: null,
  min_longitude: null,
  max_longitude: null,
  locations: [],
  main_location: null,
  email_style: '',
  recipients_count: 0,
  email_opened_recipients_count: 0,
  visited_recipients_count: 0,
  unlocked_recipients_count: 0,
  locked_documents_count: 0,
  locked_folders_count: 0,
  approval_required_documents_count: 0,
  approval_required_folders_count: 0,
  unlocked_documents_count: 0,
  unlocked_folder_count: 0,
  total_locked_documents_count: 0,
  total_unlocked_documents_count: 0,
  total_approval_required_documents_count: 0,
  created_at: ''
});
const getInitialDealflowState = () => ({
  id: '',
  dealspace: { id: '', name: '', group: { id: '', logo: null } },
  offer: null,
  recipients_count: 0,
  total_locked_documents_count: 0,
  total_unlocked_documents_count: 0,
  total_approval_required_documents_count: 0,
  name: '',
  shared_at: '',
  status: '',
  version: 0,
  deal_type: '',
  headline: '',
  description: '',
  deadline_type: '',
  deadline_at: '',
  expired_at: '',
  risk_profiles: [],
  property_grouping: '',
  asset_classes: [],
  lease_period: '',
  loan_ratio: '',
  loan_percent: null,
  investment_type: '',
  price_cents: null,
  custom_price: '',
  saving_allowed: false,
  public: false,
  locations_locked: false,
  locations: [],
  main_location: null,
  published_at: '',
  primary_sender: {
    user: {
      first_name: '',
      last_name: '',
      photo: null
    }
  },
  properties_count: 0,
  senders_count: 0,
  disinterested: false,
  sent: false,
  locked: false,
  tracked: false,
  visited: false,
  agreement: null,
  detailsFetched: true
});
const getInitialActivityLogState = () => ({
  id: '', 
  name: '',
  headline: '',
  public: false,
  status: '',
  tag: '',
  locked: false,
  locations_locked: false,
  total_approval_required_documents_count: 0,
  deadline_type: '',
  deadline_at: '',
  expired_at: '',
  recipients_count: 0,
  email_opened_recipients_count: 0,
  visited_recipients_count: 0,
  unlocked_recipients_count: 0,
  email_unopened_recipients_count: 0,
  approval_requested_recipients_count: 0,
  email_delivered_recipients_count: 0,
  tracked_recipients_count: 0,
  saved_recipients_count: 0,
  offered_recipients_count: 0,
  dealspace: { id: '', name: '', group: { id: '', name: '' } },
  draft_offer: { id: '' },
  primary_sender: { id: '', user: { id: '', first_name: '', last_name: '' } }
});

export const initialState = {
  dealflow: {
    amount: {
      sharedWithMe: { all: -1, unseen: 0, tracking: 0, deadlines: 0, fetching: true },
      archive: { all: -1, unseen: 0, tracking: 0, deadlines: 0, fetching: true },
      myShared: { all: -1, unseen: 0, tracking: 0, deadlines: 0, fetching: true },
      market: { all: -1, unseen: 0, tracking: 0, deadlines: 0, fetching: true }
    },
    sharedWithMe: {
      all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
      unseen: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
      tracking: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
      deadlines: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true }
    },
    archive: {
      all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
      tracking: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
      deadlines: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true }
    },
    myShared: {
      all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true }
    },
    market: {
      all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
      unseen: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
      tracking: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
      deadlines: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true }
    },
    details: { offer: getInitialDealflowState(), fetching: true }
  },
  feedMap: [],
  chats: {
    data: [], 
    pagination: { limit: 25, offset: 0, count: 0, total_count: -1 } 
  },
  shared: {
    all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
    draft: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
    active: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
    ended: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
    inContract: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
    closed: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true }
  },
  dealspace: {
    shared: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 } },
    draft: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 } },
    ended: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 } },
    saved: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 } }
  },
  contactActiveOffers: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 } },
  builder: { offer: getInitialBuilderState(), saving: false, deleting: false, fetching: true },
  activityLog: getInitialActivityLogState()
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getFeedFiltersAmountRoutine.REQUEST: {
      const dealflow = {
        ...state.dealflow,
        amount: {
          ...state.dealflow.amount,
          [action.payload]: { ...state.dealflow.amount[action.payload], fetching: true }
        }
      };

      return { ...state, dealflow };
    }
    case getFeedFiltersAmountRoutine.SUCCESS: {
      const { response: { data: { responses } }, filter } = action.payload;
      const failureResponses = _filter(responses, ({ status }) => status < 200 || status > 299);

      if (!!failureResponses.length) {
        const dealflow = { ...state.dealflow };
      
        return { ...state, dealflow };
      };
      const all = responses[0]?.body?.pagination?.total_count ?? undefined;
      const unseen = responses[1]?.body?.pagination?.total_count ?? undefined;
      const tracking = responses[2]?.body?.pagination?.total_count ?? undefined;
      const deadlines = responses[3]?.body?.pagination?.total_count ?? undefined;
      const dealflow = {
        ...state.dealflow,
        amount: {
          ...state.dealflow.amount,
          [filter]: {
            ...state.dealflow.amount[filter],
            ...!_isUndefined(all) && { all },
            ...!_isUndefined(unseen) && { unseen },
            ...!_isUndefined(tracking) && { tracking },
            ...!_isUndefined(deadlines) && { deadlines },
            fetching: false
          }
        }
      };

      return { ...state, dealflow };
    }
    case getFeedFiltersAmountRoutine.FAILURE: {
      const dealflow = {
        ...state.dealflow,
        amount: {
          ...state.dealflow.amount,
          [action.payload]: { ...state.dealflow.amount[action.payload], fetching: false }
        }
      };

      return { ...state, dealflow };
    }
    case getFeedSharedWithMeOffersRoutine.REQUEST: {
      const { filter } = action.payload;
      const dealflow = {
        ...state.dealflow,
        sharedWithMe: {
          ...state.dealflow.sharedWithMe,
          [filter]: { ...state.dealflow.sharedWithMe[filter], fetching: true }
        }
      };

      return { ...state, dealflow };
    }
    case getFeedSharedWithMeOffersRoutine.SUCCESS: {
      const { response: { data: { offers, pagination } }, filter } = action.payload;
      const dealflow = {
        ...state.dealflow,
        sharedWithMe: {
          ...state.dealflow.sharedWithMe,
          [filter]: { data: [...state.dealflow.sharedWithMe[filter].data, ...offers], pagination, fetching: false }
        }
      };

      return { ...state, dealflow };
    }
    case getFeedSharedWithMeOffersRoutine.FAILURE: {
      const { filter } = action.payload;
      const dealflow = {
        ...state.dealflow,
        sharedWithMe: {
          ...state.dealflow.sharedWithMe,
          [filter]: { ...state.dealflow.sharedWithMe[filter], fetching: false }
        }
      };

      return { ...state, dealflow };
    }
    case getFeedArchiveOffersRoutine.REQUEST: {
      const { filter } = action.payload;
      const dealflow = {
        ...state.dealflow,
        archive: {
          ...state.dealflow.archive,
          [filter]: { ...state.dealflow.archive[filter], fetching: true }
        }
      };

      return { ...state, dealflow };
    }
    case getFeedArchiveOffersRoutine.SUCCESS: {
      const { response: { data: { offers, pagination } }, filter } = action.payload;
      const dealflow = {
        ...state.dealflow,
        archive: {
          ...state.dealflow.archive,
          [filter]: { data: [...state.dealflow.archive[filter].data, ...offers], pagination, fetching: false }
        }
      };

      return { ...state, dealflow };
    }
    case getFeedArchiveOffersRoutine.FAILURE: {
      const { filter } = action.payload;
      const dealflow = {
        ...state.dealflow,
        archive: {
          ...state.dealflow.archive,
          [filter]: { ...state.dealflow.archive[filter], fetching: false }
        }
      };

      return { ...state, dealflow };
    }
    case getFeedMySharedOffersRoutine.REQUEST: {
      const { filter } = action.payload;
      const dealflow = {
        ...state.dealflow,
        myShared: {
          ...state.dealflow.myShared,
          [filter]: { ...state.dealflow.myShared[filter], fetching: true }
        }
      };

      return { ...state, dealflow };
    }
    case getFeedMySharedOffersRoutine.SUCCESS: {
      const { response: { data: { offers, pagination } }, filter } = action.payload;
      const dealflow = {
        ...state.dealflow,
        myShared: {
          ...state.dealflow.myShared,
          [filter]: { data: [...state.dealflow.myShared[filter].data, ...offers], pagination, fetching: false }
        }
      };

      return { ...state, dealflow };
    }
    case getFeedMySharedOffersRoutine.FAILURE: {
      const { filter } = action.payload;
      const dealflow = {
        ...state.dealflow,
        myShared: {
          ...state.dealflow.myShared,
          [filter]: { ...state.dealflow.myShared[filter], fetching: false }
        }
      };

      return { ...state, dealflow };
    }
    case getFeedMarketOffersRoutine.REQUEST: {
      const { filter } = action.payload;
      const dealflow = {
        ...state.dealflow,
        market: {
          ...state.dealflow.market,
          [filter]: { ...state.dealflow.market[filter], fetching: true }
        }
      };

      return { ...state, dealflow };
    }
    case getFeedMarketOffersRoutine.SUCCESS: {
      const { response: { data: { offers, pagination } }, filter } = action.payload;
      const dealflow = {
        ...state.dealflow,
        market: {
          ...state.dealflow.market,
          [filter]: { data: [...state.dealflow.market[filter].data, ...offers], pagination, fetching: false }
        }
      };

      return { ...state, dealflow };
    }
    case getFeedMarketOffersRoutine.FAILURE: {
      const { filter } = action.payload;
      const dealflow = {
        ...state.dealflow,
        market: {
          ...state.dealflow.market,
          [filter]: { ...state.dealflow.market[filter], fetching: false }
        }
      };

      return { ...state, dealflow };
    }
    case getFeedRecipientOffersRoutine.REQUEST: {
      const amount = { ...state.dealflow.amount, sharedWithMe: { ...state.dealflow.amount.sharedWithMe, fetching: true } };
      const sharedWithMe = { ...state.dealflow.sharedWithMe, all: { ...state.dealflow.sharedWithMe.all, fetching: true } };
      const dealflow = { ...state.dealflow, amount, sharedWithMe };

      return { ...state, dealflow };
    }
    case getFeedRecipientOffersRoutine.SUCCESS: {
      const { data: { offers, pagination } } = action.payload;
      const amount = {
        ...state.dealflow.amount,
        sharedWithMe: {
          ...state.dealflow.amount.sharedWithMe,
          all: pagination.total_count,
          fetching: false
        }
      };
      const sharedWithMe = {
        ...state.dealflow.sharedWithMe,
        all: {
          data: _uniqBy([...state.dealflow.sharedWithMe.all.data, ...offers], 'id'),
          pagination,
          fetching: false
        }
      };
      const dealflow = { ...state.dealflow, amount, sharedWithMe };

      return { ...state, dealflow };
    }
    case getFeedRecipientOffersRoutine.FAILURE: {
      const amount = { ...state.dealflow.amount, sharedWithMe: { ...state.dealflow.amount.sharedWithMe, fetching: false } };
      const sharedWithMe = { ...state.dealflow.sharedWithMe, all: { ...state.dealflow.sharedWithMe.all, fetching: false } };
      const dealflow = { ...state.dealflow, amount, sharedWithMe };

      return { ...state, dealflow };
    }
    case getFeedOfferDetailsRoutine.REQUEST: {
      const dealflow = { ...state.dealflow, details: { ...state.dealflow.details, fetching: true } };

      return { ...state, dealflow };
    }
    case getFeedOfferDetailsRoutine.SUCCESS: {
      const { data: { offer } } = action.payload;
      const dealflow = { ...state.dealflow, details: { offer, fetching: false } };

      return { ...state, dealflow };
    }
    case getFeedOfferDetailsRoutine.FAILURE: {
      const dealflow = { ...state.dealflow, details: { ...state.dealflow.details, fetching: false } };

      return { ...state, dealflow };
    }
    case clearFeedOfferDataRoutine.SUCCESS: {
      const { vertical, horizontal } = action.payload;

      if (!vertical && !horizontal) {
        const dealflow = {
          ...state.dealflow,
          amount: {
            sharedWithMe: { all: -1, unseen: 0, tracking: 0, deadlines: 0, fetching: true },
            archive: { all: -1, unseen: 0, tracking: 0, deadlines: 0, fetching: true },
            myShared: { all: -1, unseen: 0, tracking: 0, deadlines: 0, fetching: true },
            market: { all: -1, unseen: 0, tracking: 0, deadlines: 0, fetching: true }
          },
          sharedWithMe: {
            all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
            unseen: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
            tracking: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
            deadlines: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true }
          },
          archive: {
            all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
            tracking: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
            deadlines: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true }
          },
          myShared: {
            all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true }
          },
          market: {
            all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
            unseen: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
            tracking: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true },
            deadlines: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true }
          },
        };

        return { ...state, dealflow };
      }
      const dealflow = {
        ...state.dealflow,
        [vertical]: {
          ...state.dealflow[vertical],
          [horizontal]: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: false }
        }
      };

      return { ...state, dealflow };
    }
    case clearFeedRecipientDataRoutine.SUCCESS: {
      const amount = { ...state.dealflow.amount, sharedWithMe: { all: -1, unseen: 0, tracking: 0, deadlines: 0, fetching: true } };
      const sharedWithMe = { ...state.dealflow.sharedWithMe, all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }, fetching: true } };
      const dealflow = { ...state.dealflow, amount, sharedWithMe };
      
      return { ...state, dealflow };
    }
    case getPublishedOffersForChatsRoutine.SUCCESS: {
      const { data: { offers, pagination } } = action.payload;

      return { ...state, chats: { data: [...state.chats.data, ...offers], pagination } };
    }
    case getDealspaceOffersRoutine.SUCCESS: {
      const { response: { data: { offers, pagination } }, type } = action.payload;
      const dealspace = { ...state.dealspace, [type]: { data: _uniqBy([...state.dealspace[type].data, ...offers], 'id'), pagination } };

      return { ...state, dealspace };
    }
    case getDealspaceOffersForChatsRoutine.SUCCESS: {
      const { data: { offers, pagination } } = action.payload;

      return { ...state, chats: { data: [...state.chats.data, ...offers], pagination } };
    }
    case getSharedOffersRoutine.REQUEST: {
      const shared = { ...state.shared, all: { ...state.shared.all, fetching: true } };

      return { ...state, shared };
    }
    case getSharedOffersRoutine.SUCCESS: {
      const { data: { offers, pagination } } = action.payload;
      const data = _reduce(offers, (result, value) => {
        if (!_find(result, r => r?.dealspace?.id === value?.dealspace?.id)) {
          result.push({ dealspace: value.dealspace, offers: [_omit(value, ['dealspace'])] });
        } else {
          result = _map(result, r => r?.dealspace?.id === value?.dealspace?.id ? ({ ...r, offers: [...r.offers, _omit(value, ['dealspace'])] }) : r);
        }

        return result;
      }, [...state.shared.all.data]);

      return { ...state, shared: { ...state.shared, all: { data, pagination, fetching: false } } };
    }
    case getSharedOffersRoutine.FAILURE: {
      const shared = { ...state.shared, all: { ...state.shared.all, fetching: false } };

      return { ...state, shared };
    }
    case getDraftSharedOffersRoutine.REQUEST: {
      const shared = { ...state.shared, draft: { ...state.shared.draft, fetching: true } };

      return { ...state, shared };
    }
    case getDraftSharedOffersRoutine.SUCCESS: {
      const { data: { offers, pagination } } = action.payload;
      const data = _reduce(offers, (result, value) => {
        if (!_find(result, r => r?.dealspace?.id === value?.dealspace?.id)) {
          result.push({ dealspace: value.dealspace, offers: [_omit(value, ['dealspace'])] });
        } else {
          result = _map(result, r => r?.dealspace?.id === value?.dealspace?.id ? ({ ...r, offers: [...r.offers, _omit(value, ['dealspace'])] }) : r);
        }

        return result;
      }, [...state.shared.draft.data]);

      return { ...state, shared: { ...state.shared, draft: { data, pagination, fetching: false } } };
    }
    case getDraftSharedOffersRoutine.FAILURE: {
      const shared = { ...state.shared, draft: { ...state.shared.draft, fetching: false } };

      return { ...state, shared };
    }
    case getActiveSharedOffersRoutine.REQUEST: {
      const shared = { ...state.shared, active: { ...state.shared.active, fetching: true } };

      return { ...state, shared };
    }
    case getActiveSharedOffersRoutine.SUCCESS: {
      const { data: { offers, pagination } } = action.payload;
      const data = _reduce(offers, (result, value) => {
        if (!_find(result, r => r?.dealspace?.id === value.dealspace?.id)) {
          result.push({ dealspace: value.dealspace, offers: [_omit(value, ['dealspace'])] });
        } else {
          result = _map(result, r => r?.dealspace?.id === value?.dealspace?.id ? ({ ...r, offers: [...r.offers, _omit(value, ['dealspace'])] }) : r);
        }

        return result;
      }, [...state.shared.active.data]);

      return { ...state, shared: { ...state.shared, active: { data, pagination, fetching: false } } };
    }
    case getActiveSharedOffersRoutine.FAILURE: {
      const shared = { ...state.shared, active: { ...state.shared.active, fetching: false } };

      return { ...state, shared };
    }
    case getContactActiveOffersRoutine.SUCCESS: {
      const { offers, pagination } = action.payload.data;
      
      return { 
        ...state, 
        contactActiveOffers: { data: [...state.contactActiveOffers.data, ...offers], pagination }
      };
    }
    case getEndedSharedOffersRoutine.REQUEST: {
      const shared = { ...state.shared, ended: { ...state.shared.ended, fetching: true } };

      return { ...state, shared };
    }
    case getEndedSharedOffersRoutine.SUCCESS: {
      const { data: { offers, pagination } } = action.payload;
      const data = _reduce(offers, (result, value) => {
        if (!_find(result, r => r?.dealspace?.id === value?.dealspace?.id)) {
          result.push({ dealspace: value.dealspace, offers: [_omit(value, ['dealspace'])] });
        } else {
          result = _map(result, r => r?.dealspace?.id === value?.dealspace?.id ? ({ ...r, offers: [...r.offers, _omit(value, ['dealspace'])] }) : r);
        }

        return result;
      }, [...state.shared.ended.data]);

      return { ...state, shared: { ...state.shared, ended: { data, pagination, fetching: false } } };
    }
    case getEndedSharedOffersRoutine.FAILURE: {
      const shared = { ...state.shared, ended: { ...state.shared.ended, fetching: false } };

      return { ...state, shared };
    }
    case getInContractSharedOffersRoutine.REQUEST: {
      const shared = { ...state.shared, inContract: { ...state.shared.inContract, fetching: true } };

      return { ...state, shared };
    }
    case getInContractSharedOffersRoutine.SUCCESS: {
      const { data: { offers, pagination } } = action.payload;
      const data = _reduce(offers, (result, value) => {
        if (!_find(result, r => r?.dealspace?.id === value?.dealspace?.id)) {
          result.push({ dealspace: value.dealspace, offers: [_omit(value, ['dealspace'])] });
        } else {
          result = _map(result, r => r?.dealspace?.id === value?.dealspace?.id ? ({ ...r, offers: [...r.offers, _omit(value, ['dealspace'])] }) : r);
        }

        return result;
      }, [...state.shared.inContract.data]);

      return { ...state, shared: { ...state.shared, inContract: { data, pagination, fetching: false } } };
    }
    case getInContractSharedOffersRoutine.FAILURE: {
      const shared = { ...state.shared, inContract: { ...state.shared.inContract, fetching: false } };

      return { ...state, shared };
    }
    case getClosedSharedOffersRoutine.REQUEST: {
      const shared = { ...state.shared, closed: { ...state.shared.closed, fetching: true } };

      return { ...state, shared };
    }
    case getClosedSharedOffersRoutine.SUCCESS: {
      const { data: { offers, pagination } } = action.payload;
      const data = _reduce(offers, (result, value) => {
        if (!_find(result, r => r?.dealspace?.id === value?.dealspace?.id)) {
          result.push({ dealspace: value.dealspace, offers: [_omit(value, ['dealspace'])] });
        } else {
          result = _map(result, r => r?.dealspace?.id === value?.dealspace?.id ? ({ ...r, offers: [...r.offers, _omit(value, ['dealspace'])] }) : r);
        }

        return result;
      }, [...state.shared.closed.data]);

      return { ...state, shared: { ...state.shared, closed: { data, pagination, fetching: false } } };
    }
    case getClosedSharedOffersRoutine.FAILURE: {
      const shared = { ...state.shared, closed: { ...state.shared.closed, fetching: false } };

      return { ...state, shared };
    }
    case getGroupOffersForChatsRoutine.SUCCESS: {
      const { data: { offers, pagination } } = action.payload;

      return { ...state, chats: { data: [...state.chats.data, ...offers], pagination } };
    }
    case getOfferActualRecipientsCountRoutine.SUCCESS: {
      const { data: { offer } } = action.payload;
      const foundDealspaceOffer = !!_find(state.dealspace.data, o => o.id === offer.id);
      const foundSharedActiveOffer = !!_find(state.shared.active.data.offers, o => o.id === offer.id);
      const foundSharedClosedOffer = !!_find(state.shared.closed.data.offers, o => o.id === offer.id);
      const foundSharedDraftOffer = !!_find(state.shared.draft.data.offers, o => o.id === offer.id);
      const foundSharedEndedOffer = !!_find(state.shared.ended.data.offers, o => o.id === offer.id);

      return {
        ...state,
        ...(foundDealspaceOffer && {
          dealspace: { ...state.dealspace, data: _map(state.dealspace.data, o => o.id === offer.id ? ({ ...o, ...offer }) : o) }
        }),
        ...((foundSharedActiveOffer || foundSharedClosedOffer || foundSharedDraftOffer || foundSharedEndedOffer) && {
          shared: {
            ...state.shared,
            ...foundSharedActiveOffer && {
              ...state.shared.active,
              data: {
                ...state.shared.active.data,
                offers: _map(state.shared.active.data.offers, o => o.id === offer.id ? ({ ...o, ...offer }) : o)
              }
            },
            ...foundSharedClosedOffer && {
              ...state.shared.closed,
              data: {
                ...state.shared.closed.data,
                offers: _map(state.shared.closed.data.offers, o => o.id === offer.id ? ({ ...o, ...offer }) : o)
              }
            },
            ...foundSharedDraftOffer && {
              ...state.shared.draft,
              data: {
                ...state.shared.draft.data,
                offers: _map(state.shared.draft.data.offers, o => o.id === offer.id ? ({ ...o, ...offer }) : o)
              }
            },
            ...foundSharedEndedOffer && {
              ...state.shared.ended,
              data: {
                ...state.shared.ended.data,
                offers: _map(state.shared.ended.data.offers, o => o.id === offer.id ? ({ ...o, ...offer }) : o)
              }
            }
          }
        })
      };
    }
    case getActivityLogOfferRoutine.SUCCESS: {
      const { data: { offer } } = action.payload;

      return { ...state, activityLog: { ...state.activityLog, ...offer } };
    }
    case getBuilderDraftRoutine.REQUEST: {
      const builder = { ...state.builder, fetching: true };
      
      return { ...state, builder };
    }
    case getBuilderDraftRoutine.SUCCESS: {
      const { data: { offer } } = action.payload;
      const builder = {
        ...state.builder,
        offer: {
          ..._mergeWith(state.builder.offer, offer, (obj, src) => src ?? obj),
          localCustom: offer.offer_type === 'partnership_opportunity'
        },
        fetching: false
      };
      const shared = {
        ...state.shared,
        active: {
          ...state.shared.active,
          data: _map(state.shared.active.data, d => d.id === offer.offer?.id ? ({ ...d, draft_offer: {} }) : d)
        }
      };
      const dealspace = {
        ...state.dealspace,
        shared: {
          ...state.dealspace.shared,
          data: _map(state.dealspace.shared.data, d => d.id === offer.offer?.id ? ({ ...d, draft_offer: {} }) : d)
        }
      };

      return { ...state, builder, shared, dealspace };
    }
    case getBuilderDraftRoutine.FAILURE: {
      const builder = { ...state.builder, fetching: false };
      
      return { ...state, builder };
    }
    case getBuilderDraftActualFilesCountRoutine.SUCCESS: {
      const { data: { offer } } = action.payload;
      const builder = { ...state.builder, offer: _mergeWith(state.builder.offer, offer, (obj, src) => src ?? obj) };

      return { ...state, builder };
    }
    case getFeedMapOffersRoutine.SUCCESS: {
      const { offers } = action.payload;
      
      return { ...state, feedMap: offers };
    }
    case getOfferPreviewRoutine.SUCCESS: {
      return state;
    }
    case createOfferRoutine.SUCCESS: {
      const { data: { offer } } = action.payload;
      const data = [offer, ...state.dealspace.draft.data]
      const pagination = {
        ...state.dealspace.draft.pagination,
        count: state.dealspace.draft.pagination.count + 1,
        total_count: state.dealspace.draft.pagination.total_count + 1
      };
      
      return { ...state, dealspace: { ...state.dealspace, draft: { data, pagination } } };
    }
    case updateOfferRoutine.SUCCESS: {
      const { response: { status }, offer } = action.payload;
      const builder = { ...state.builder, offer: _mergeWith(state.builder.offer, offer, (obj, src) => !_isUndefined(src) ? src : obj) };
      const foundDealspaceDraftOffer = !!_find(state.dealspace.draft.data, d => d.id === offer.id);

      const dealspace = {
        ...state.dealspace,
        ...foundDealspaceDraftOffer && {
          draft: {
            ...state.dealspace.draft,
            data: _map(state.dealspace.draft.data, d => d.id === offer.id ? ({ ...d, ...offer }) : d),
          }
        }
      };

      return { ...state, ...(status === 204 && { builder, dealspace }) };
    }
    case deleteOfferRoutine.SUCCESS: {
      const { response: { status }, id, source } = action.payload;
      const shared = {
        ...state.shared,
        ...source === 'builder' && {
          active: {
            ...state.shared.active,
            data: _map(state.shared.active.data, d => d.id === id ? ({ ...d, draft_offer: null }) : d)
          }
        },
        ...source === 'infoCard' && { 
          draft: {
            ...state.shared.draft,
            data: _filter(state.shared.draft.data, d => d.id !== id),
            pagination: { ...state.shared.draft.pagination, count: state.shared.draft.pagination.count - 1, total_count: state.shared.draft.pagination.total_count - 1 }
          },
          all: {
            ...state.shared.all,
            data: _filter(state.shared.all.data, d => d.id !== id),
            pagination: { ...state.shared.all.pagination, count: state.shared.all.pagination.count - 1, total_count: state.shared.all.pagination.total_count - 1 }
          }
        }
      };
      const dealspace = {
        ...state.dealspace,
        ...source === 'builder' && { 
          shared: {
            ...state.dealspace.shared,
            data: _map(state.dealspace.shared.data, d => d.id === id ? ({ ...d, draft_offer: null }) : d),
          },
          draft: {
            ...state.dealspace.draft,
            ...!!_find(state.dealspace.draft.data, d => d.id === id) && {
              data: _filter(state.dealspace.draft.data, d => d.id !== id),
              pagination: {
                ...state.dealspace.draft.pagination,
                count: state.dealspace.draft.pagination.count - 1,
                total_count: state.dealspace.draft.pagination.total_count - 1
              }
            }
          }
        },
        ...source === 'infoCard' && { 
          draft: {
            ...state.dealspace.draft,
            data: _filter(state.dealspace.draft.data, d => d.id !== id),
            pagination: { ...state.dealspace.draft.pagination, count: state.dealspace.draft.pagination.count - 1, total_count: state.dealspace.draft.pagination.total_count - 1 }
          } 
        }
      };

      return { ...state, ...(status === 204 && { shared, dealspace }) };
    }
    case publishOfferRoutine.SUCCESS: {
      return state;
    }
    case activateOfferRoutine.SUCCESS: {
      return state;
    }
    case contractOfferRoutine.SUCCESS: {
      return state;
    }
    case endOfferRoutine.SUCCESS: {
      const { response: { status }, id, type } = action.payload;

      if (type === 'dealspace') {
        const foundOffer = _find(state.dealspace.shared.data, o => o.id === id);
        const shared = {
          data: _filter(state.dealspace.shared.data, d => d.id !== id),
          pagination: {
            ...state.dealspace.shared.pagination,
            ...!!foundOffer && {
              count: state.dealspace.shared.pagination.count - 1,
              total_count: state.dealspace.shared.pagination.total_count - 1
            }
          }
        };
        const ended = {
          data: [...!!foundOffer ? [foundOffer] : [], ...state.dealspace.ended.data],
          pagination: {
            ...state.dealspace.ended.pagination,
            ...!!foundOffer && {
              count: state.dealspace.ended.pagination.count + 1,
              total_count: state.dealspace.ended.pagination.total_count + 1
            }
          }
        };
        const dealspace = { ...state.dealspace, ended, shared };

        return { ...state, ...(status === 204 && { dealspace }) };
      }
      // TODO SHARED

      return state;
    }
    case closeOfferRoutine.SUCCESS: {
      return state;
    }
    case disinterestOfferRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const foundSharedWithMeAll = !!_find(state.dealflow.sharedWithMe.all.data, o => o.id === id);
      const foundSharedWithMeUnseen = !!_find(state.dealflow.sharedWithMe.unseen.data, o => o.id === id);
      const foundSharedWithMeTracking = !!_find(state.dealflow.sharedWithMe.tracking.data, o => o.id === id);
      const foundSharedWithMeDeadlines = !!_find(state.dealflow.sharedWithMe.deadlines.data, o => o.id === id);
      const foundArchiveAll = !!_find(state.dealflow.archive.all.data, o => o.id === id);
      const foundArchiveTracking = !!_find(state.dealflow.archive.tracking.data, o => o.id === id);
      const foundArchiveDeadlines = !!_find(state.dealflow.archive.deadlines.data, o => o.id === id);
      const foundMySharedAll = !!_find(state.dealflow.myShared.all.data, o => o.id === id);
      const foundMarketAll = !!_find(state.dealflow.market.all.data, o => o.id === id);
      const foundMarketUnseen = !!_find(state.dealflow.market.unseen.data, o => o.id === id);
      const foundMarketTracking = !!_find(state.dealflow.market.tracking.data, o => o.id === id);
      const foundMarketDeadlines = !!_find(state.dealflow.market.deadlines.data, o => o.id === id);
      const dealflow = {
        ...state.dealflow,
        sharedWithMe: {
          ...state.dealflow.sharedWithMe,
          ...foundSharedWithMeAll && {
            all: {
              data: _filter(state.dealflow.sharedWithMe.all.data, o => o.id !== id),
              pagination: {
                ...state.dealflow.sharedWithMe.all.pagination,
                count: state.dealflow.sharedWithMe.all.pagination.count - 1,
                total_count: state.dealflow.sharedWithMe.all.pagination.total_count - 1
              },
              fetching: false
            }
          },
          ...foundSharedWithMeUnseen && {
            unseen: {
              data: _filter(state.dealflow.sharedWithMe.unseen.data, o => o.id !== id),
              pagination: {
                ...state.dealflow.sharedWithMe.unseen.pagination,
                count: state.dealflow.sharedWithMe.unseen.pagination.count - 1,
                total_count: state.dealflow.sharedWithMe.unseen.pagination.total_count - 1
              },
              fetching: false
            }
          },
          ...foundSharedWithMeTracking && {
            tracking: {
              data: _filter(state.dealflow.sharedWithMe.tracking.data, o => o.id !== id),
              pagination: {
                ...state.dealflow.sharedWithMe.tracking.pagination,
                count: state.dealflow.sharedWithMe.tracking.pagination.count - 1,
                total_count: state.dealflow.sharedWithMe.tracking.pagination.total_count - 1
              },
              fetching: false
            }
          },
          ...foundSharedWithMeDeadlines && {
            deadlines: {
              data: _filter(state.dealflow.sharedWithMe.deadlines.data, o => o.id !== id),
              pagination: {
                ...state.dealflow.sharedWithMe.deadlines.pagination,
                count: state.dealflow.sharedWithMe.deadlines.pagination.count - 1,
                total_count: state.dealflow.sharedWithMe.deadlines.pagination.total_count - 1
              },
              fetching: false
            }
          }
        },
        archive: {
          ...state.dealflow.archive,
          ...foundArchiveAll && {
            all: {
              data: _filter(state.dealflow.archive.all.data, o => o.id !== id),
              pagination: {
                ...state.dealflow.archive.all.pagination,
                count: state.dealflow.archive.all.pagination.count - 1,
                total_count: state.dealflow.archive.all.pagination.total_count - 1
              },
              fetching: false
            }
          },
          ...foundArchiveTracking && {
            tracking: {
              data: _filter(state.dealflow.archive.tracking.data, o => o.id !== id),
              pagination: {
                ...state.dealflow.archive.tracking.pagination,
                count: state.dealflow.archive.tracking.pagination.count - 1,
                total_count: state.dealflow.archive.tracking.pagination.total_count - 1
              },
              fetching: false
            }
          },
          ...foundArchiveDeadlines && {
            deadlines: {
              data: _filter(state.dealflow.archive.deadlines.data, o => o.id !== id),
              pagination: {
                ...state.dealflow.archive.deadlines.pagination,
                count: state.dealflow.archive.deadlines.pagination.count - 1,
                total_count: state.dealflow.archive.deadlines.pagination.total_count - 1
              },
              fetching: false
            }
          }
        },
        myShared: {
          ...state.dealflow.myShared,
          ...foundMySharedAll && {
            all: {
              data: _filter(state.dealflow.myShared.all.data, o => o.id !== id),
              pagination: {
                ...state.dealflow.myShared.all.pagination,
                count: state.dealflow.myShared.all.pagination.count - 1,
                total_count: state.dealflow.myShared.all.pagination.total_count - 1
              },
              fetching: false
            }
          },
        },
        market: {
          ...state.dealflow.market,
          ...foundMarketAll && {
            all: {
              data: _filter(state.dealflow.market.all.data, o => o.id !== id),
              pagination: {
                ...state.dealflow.market.all.pagination,
                count: state.dealflow.market.all.pagination.count - 1,
                total_count: state.dealflow.market.all.pagination.total_count - 1
              },
              fetching: false
            }
          },
          ...foundMarketUnseen && {
            unseen: {
              data: _filter(state.dealflow.market.unseen.data, o => o.id !== id),
              pagination: {
                ...state.dealflow.market.unseen.pagination,
                count: state.dealflow.market.unseen.pagination.count - 1,
                total_count: state.dealflow.market.unseen.pagination.total_count - 1
              },
              fetching: false
            }
          },
          ...foundMarketTracking && {
            tracking: {
              data: _filter(state.dealflow.market.tracking.data, o => o.id !== id),
              pagination: {
                ...state.dealflow.market.tracking.pagination,
                count: state.dealflow.market.tracking.pagination.count - 1,
                total_count: state.dealflow.market.tracking.pagination.total_count - 1
              },
              fetching: false
            }
          },
          ...foundMarketDeadlines && {
            deadlines: {
              data: _filter(state.dealflow.market.deadlines.data, o => o.id !== id),
              pagination: {
                ...state.dealflow.market.deadlines.pagination,
                count: state.dealflow.market.deadlines.pagination.count - 1,
                total_count: state.dealflow.market.deadlines.pagination.total_count - 1
              },
              fetching: false
            }
          }
        }
      };
      
      return { ...state, ...status === 204 && { dealflow } };
    }
    case saveOfferRoutine.SUCCESS: {
      const { response: { status }, offer_id, dealspace_id, locationType } = action.payload;

      if (locationType === 'dealspace') {
        const offeringsStatus = !!_find(state.dealspace.shared.data, (o) => o.id === offer_id) ? 'shared' :
        !!_find(state.dealspace.draft.data, (o) => o.id === offer_id) ? 'draft' : !!_find(state.dealspace.ended.data, (o) => o.id === offer_id) ? 'ended' : 'saved';
        const data = _filter(state.dealspace[offeringsStatus].data, o => o.id !== offer_id);
        const pagination = {
          ...state.dealspace[offeringsStatus].pagination,
          count: state.dealspace[offeringsStatus].pagination.count - 1,
          total_count: state.dealspace[offeringsStatus].pagination.total_count - 1
        };

        return { ...state, ...(status === 204 && { dealspace: { ...state.dealspace, [offeringsStatus]: { data, pagination } } }) };
      }
      if (locationType === 'dashboard' || locationType === 'group') {
        const offeringsStatus = !!_find(state.shared.closed.data, (o) => o.id === offer_id) ? 'closed' : !!_find(state.shared.active.data, (o) => o.id === dealspace_id) ? 'active' :
        !!_find(state.shared.draft.data, (o) => o.id === offer_id) ? 'draft' : !!_find(state.shared.ended.data, (o) => o.id === offer_id) ? 'ended' : 'saved';
        const data = _filter(state.shared[offeringsStatus].data, o => o.id !== offer_id);
        const pagination = {
          ...state.shared[offeringsStatus].pagination,
          count: state.shared[offeringsStatus].pagination.count - 1,
          total_count: state.shared[offeringsStatus].pagination.total_count - 1
        };

        return { ...state, ...(status === 204 && { shared: { ...state.shared, [offeringsStatus]: { data, pagination } } }) };
      }
      if (locationType === 'feed') {
        const foundSharedWithMeAll = !!_find(state.dealflow.sharedWithMe.all.data, o => o.id === offer_id);
        const foundSharedWithMeUnseen = !!_find(state.dealflow.sharedWithMe.unseen.data, o => o.id === offer_id);
        const foundSharedWithMeTracking = !!_find(state.dealflow.sharedWithMe.tracking.data, o => o.id === offer_id);
        const foundSharedWithMeDeadlines = !!_find(state.dealflow.sharedWithMe.deadlines.data, o => o.id === offer_id);
        const foundArchiveAll = !!_find(state.dealflow.archive.all.data, o => o.id === offer_id);
        const foundArchiveTracking = !!_find(state.dealflow.archive.tracking.data, o => o.id === offer_id);
        const foundArchiveDeadlines = !!_find(state.dealflow.archive.deadlines.data, o => o.id === offer_id);
        const foundMySharedAll = !!_find(state.dealflow.myShared.all.data, o => o.id === offer_id);
        const foundMarketAll = !!_find(state.dealflow.market.all.data, o => o.id === offer_id);
        const foundMarketUnseen = !!_find(state.dealflow.market.unseen.data, o => o.id === offer_id);
        const foundMarketTracking = !!_find(state.dealflow.market.tracking.data, o => o.id === offer_id);
        const foundMarketDeadlines = !!_find(state.dealflow.market.deadlines.data, o => o.id === offer_id);
        const dealflow = {
          ...state.dealflow,
          sharedWithMe: {
            ...state.dealflow.sharedWithMe,
            ...foundSharedWithMeAll && {
              all: {
                ...state.dealflow.sharedWithMe.all,
                data: _map(state.dealflow.sharedWithMe.all.data, o => o.id === offer_id ? { ...o, saved_dealspace: { id: dealspace_id } } : o)
              }
            },
            ...foundSharedWithMeUnseen && {
              unseen: {
                ...state.dealflow.sharedWithMe.unseen,
                data: _map(state.dealflow.sharedWithMe.unseen.data, o => o.id === offer_id ? { ...o, saved_dealspace: { id: dealspace_id } } : o)
              }
            },
            ...foundSharedWithMeTracking && {
              tracking: {
                ...state.dealflow.sharedWithMe.tracking,
                data: _map(state.dealflow.sharedWithMe.tracking.data, o => o.id === offer_id ? { ...o, saved_dealspace: { id: dealspace_id } } : o)
              }
            },
            ...foundSharedWithMeDeadlines && {
              deadlines: {
                ...state.dealflow.sharedWithMe.deadlines,
                data: _map(state.dealflow.sharedWithMe.deadlines.data, o => o.id === offer_id ? { ...o, saved_dealspace: { id: dealspace_id } } : o)
              }
            }
          },
          archive: {
            ...state.dealflow.archive,
            ...foundArchiveAll && {
              all: {
                ...state.dealflow.archive.all,
                data: _map(state.dealflow.archive.all.data, o => o.id === offer_id ? { ...o, saved_dealspace: { id: dealspace_id } } : o)
              }
            },
            ...foundArchiveTracking && {
              tracking: {
                ...state.dealflow.archive.tracking,
                data: _map(state.dealflow.archive.tracking.data, o => o.id === offer_id ? { ...o, saved_dealspace: { id: dealspace_id } } : o)
              }
            },
            ...foundArchiveDeadlines && {
              deadlines: {
                ...state.dealflow.archive.deadlines,
                data: _map(state.dealflow.archive.deadlines.data, o => o.id === offer_id ? { ...o, saved_dealspace: { id: dealspace_id } } : o)
              }
            }
          },
          myShared: {
            ...state.dealflow.myShared,
            ...foundMySharedAll && {
              all: {
                ...state.dealflow.myShared.all,
                data: _map(state.dealflow.myShared.all.data, o => o.id === offer_id ? { ...o, saved_dealspace: { id: dealspace_id } } : o)
              }
            },
          },
          market: {
            ...state.dealflow.market,
            ...foundMarketAll && {
              all: {
                ...state.dealflow.market.all,
                data: _map(state.dealflow.market.all.data, o => o.id === offer_id ? { ...o, saved_dealspace: { id: dealspace_id } } : o)
              }
            },
            ...foundMarketUnseen && {
              unseen: {
                ...state.dealflow.market.unseen,
                data: _map(state.dealflow.market.unseen.data, o => o.id === offer_id ? { ...o, saved_dealspace: { id: dealspace_id } } : o)
              }
            },
            ...foundMarketTracking && {
              tracking: {
                ...state.dealflow.market.tracking,
                data: _map(state.dealflow.market.tracking.data, o => o.id === offer_id ? { ...o, saved_dealspace: { id: dealspace_id } } : o)
              }
            },
            ...foundMarketDeadlines && {
              deadlines: {
                ...state.dealflow.market.deadlines,
                data: _map(state.dealflow.market.deadlines.data, o => o.id === offer_id ? { ...o, saved_dealspace: { id: dealspace_id } } : o)
              }
            }
          },
          details: {
            ...state.dealflow.details,
            offer: { ...state.dealflow.details.offer, saved_dealspace: { id: dealspace_id } }
          }
        };

        return { ...state, ...(status === 204 && { dealflow }) };
      }

      return state;
    }
    case duplicateOfferRoutine.SUCCESS: {
      const { response: { data: { offer } }, type } = action.payload;

      if (type === 'dashboard' || type === 'group') {
        const foundDraftOffer = !!_find(state.shared.draft.data, d => d.id === offer.id);
        const draftData = [
          ...!foundDraftOffer ? [offer] : [],
          ...state.shared.draft.data
        ];
        const draftPagination = {
          ...state.shared.draft.pagination,
          ...!foundDraftOffer && {
            count: state.shared.draft.pagination.count + 1,
            total_count: state.shared.draft.pagination.total_count + 1
          }
        };
        const draft = { data: draftData, pagination: draftPagination };
        const foundActiveOffer = !!_find(state.shared.active.data, d => d.id === offer.id);
        const activeData = [
          ...foundActiveOffer ? [offer] : [],
          ...state.shared.active.data
        ];
        const activePagination = {
          ...state.shared.active.pagination,
          ...foundActiveOffer && {
            count: state.shared.active.pagination.count + 1,
            total_count: state.shared.active.pagination.total_count + 1
          }
        };
        const active = { data: activeData, pagination: activePagination };
        const foundInContractOffer = !!_find(state.shared.inContract.data, d => d.id === offer.id);
        const inContractData = [
          ...foundInContractOffer ? [offer] : [],
          ...state.shared.inContract.data
        ];
        const inContractPagination = {
          ...state.shared.inContract.pagination,
          ...foundInContractOffer && {
            count: state.shared.inContract.pagination.count + 1,
            total_count: state.shared.inContract.pagination.total_count + 1
          }
        };
        const inContract = { data: inContractData, pagination: inContractPagination };

        return { ...state, shared: { ...state.shared, draft, active, inContract } };
      }
      if (type === 'dealspace') {
        const foundSharedOffer = !!_find(state.dealspace.shared.data, d => d.id === offer.id);
        const sharedData = [
          ...foundSharedOffer ? [offer] : [],
          ...state.dealspace.shared.data
        ];
        const sharedPagination = {
          ...state.dealspace.shared.pagination,
          ...foundSharedOffer && {
            count: state.dealspace.shared.pagination.count + 1,
            total_count: state.dealspace.shared.pagination.total_count + 1
          }
        };
        const shared = { data: sharedData, pagination: sharedPagination };
        const foundDraftOffer = !!_find(state.dealspace.shared.data, d => d.id === offer.id);
        const draftData = [
          ...!foundDraftOffer ? [offer] : [],
          ...state.dealspace.draft.data
        ];
        const draftPagination = {
          ...state.dealspace.draft.pagination,
          ...!foundDraftOffer && {
            count: state.dealspace.draft.pagination.count + 1,
            total_count: state.dealspace.draft.pagination.total_count + 1
          }
        };
        const draft = { data: draftData, pagination: draftPagination };

        return { ...state, dealspace: { ...state.dealspace, shared, draft } };
      }

      return state;
    }
    case visitOfferRoutine.SUCCESS: {
      const { response: { status }, offer_id } = action.payload;
      const foundSharedWithMeAll = !!_find(state.dealflow.sharedWithMe.all.data, o => o.id === offer_id && !o.visited);
      const foundSharedWithMeUnseen = !!_find(state.dealflow.sharedWithMe.unseen.data, o => o.id === offer_id && !o.visited);
      const foundSharedWithMeTracking = !!_find(state.dealflow.sharedWithMe.tracking.data, o => o.id === offer_id && !o.visited);
      const foundSharedWithMeDeadlines = !!_find(state.dealflow.sharedWithMe.deadlines.data, o => o.id === offer_id && !o.visited);
      const foundArchiveAll = !!_find(state.dealflow.archive.all.data, o => o.id === offer_id && !o.visited);
      const foundArchiveTracking = !!_find(state.dealflow.archive.tracking.data, o => o.id === offer_id && !o.visited);
      const foundArchiveDeadlines = !!_find(state.dealflow.archive.deadlines.data, o => o.id === offer_id && !o.visited);
      const foundMySharedAll = !!_find(state.dealflow.myShared.all.data, o => o.id === offer_id && !o.visited);
      const foundMarketAll = !!_find(state.dealflow.market.all.data, o => o.id === offer_id && !o.visited);
      const foundMarketUnseen = !!_find(state.dealflow.market.unseen.data, o => o.id === offer_id && !o.visited);
      const foundMarketTracking = !!_find(state.dealflow.market.tracking.data, o => o.id === offer_id && !o.visited);
      const foundMarketDeadlines = !!_find(state.dealflow.market.deadlines.data, o => o.id === offer_id && !o.visited);
      const dealflow = {
        ...state.dealflow,
        amount: {
          ...state.dealflow.amount,
          sharedWithMe: {
            ...state.dealflow.amount.sharedWithMe,
            ...foundSharedWithMeAll && { unseen: state.dealflow.amount.sharedWithMe.unseen - 1 }
          },
          market: {
            ...state.dealflow.amount.market,
            ...foundMarketAll && { unseen: state.dealflow.amount.market.unseen - 1 }
          }
        },
        sharedWithMe: {
          ...state.dealflow.sharedWithMe,
          ...foundSharedWithMeAll && {
            all: {
              ...state.dealflow.sharedWithMe.all,
              data: _map(state.dealflow.sharedWithMe.all.data, o => o.id === offer_id ? { ...o, visited: true } : o)
            }
          },
          ...foundSharedWithMeUnseen && {
            unseen: {
              ...state.dealflow.sharedWithMe.unseen,
              data: _map(state.dealflow.sharedWithMe.unseen.data, o => o.id === offer_id ? { ...o, visited: true } : o)
            }
          },
          ...foundSharedWithMeTracking && {
            tracking: {
              ...state.dealflow.sharedWithMe.tracking,
              data: _map(state.dealflow.sharedWithMe.tracking.data, o => o.id === offer_id ? { ...o, visited: true } : o)
            }
          },
          ...foundSharedWithMeDeadlines && {
            deadlines: {
              ...state.dealflow.sharedWithMe.deadlines,
              data: _map(state.dealflow.sharedWithMe.deadlines.data, o => o.id === offer_id ? { ...o, visited: true } : o)
            }
          }
        },
        archive: {
          ...state.dealflow.archive,
          ...foundArchiveAll && {
            all: {
              ...state.dealflow.archive.all,
              data: _map(state.dealflow.archive.all.data, o => o.id === offer_id ? { ...o, visited: true } : o)
            }
          },
          ...foundArchiveTracking && {
            tracking: {
              ...state.dealflow.archive.tracking,
              data: _map(state.dealflow.archive.tracking.data, o => o.id === offer_id ? { ...o, visited: true } : o)
            }
          },
          ...foundArchiveDeadlines && {
            deadlines: {
              ...state.dealflow.archive.deadlines,
              data: _map(state.dealflow.archive.deadlines.data, o => o.id === offer_id ? { ...o, visited: true } : o)
            }
          }
        },
        myShared: {
          ...state.dealflow.myShared,
          ...foundMySharedAll && {
            all: {
              ...state.dealflow.myShared.all,
              data: _map(state.dealflow.myShared.all.data, o => o.id === offer_id ? { ...o, visited: true } : o)
            }
          },
        },
        market: {
          ...state.dealflow.market,
          ...foundMarketAll && {
            all: {
              ...state.dealflow.market.all,
              data: _map(state.dealflow.market.all.data, o => o.id === offer_id ? { ...o, visited: true } : o)
            }
          },
          ...foundMarketUnseen && {
            unseen: {
              ...state.dealflow.market.unseen,
              data: _map(state.dealflow.market.unseen.data, o => o.id === offer_id ? { ...o, visited: true } : o)
            }
          },
          ...foundMarketTracking && {
            tracking: {
              ...state.dealflow.market.tracking,
              data: _map(state.dealflow.market.tracking.data, o => o.id === offer_id ? { ...o, visited: true } : o)
            }
          },
          ...foundMarketDeadlines && {
            deadlines: {
              ...state.dealflow.market.deadlines,
              data: _map(state.dealflow.market.deadlines.data, o => o.id === offer_id ? { ...o, visited: true } : o)
            }
          }
        }
      };

      return { ...state, ...(status === 204 && { dealflow }) };
    }
    case exportOfferRoutine.SUCCESS: {
      return state;
    }
    case intakeOfferRoutine.SUCCESS: {
      return state;
    }
    case addToTracksOfferRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const foundSharedWithMeAll = !!_find(state.dealflow.sharedWithMe.all.data, o => o.id === id);
      const foundSharedWithMeUnseen = !!_find(state.dealflow.sharedWithMe.unseen.data, o => o.id === id);
      const foundSharedWithMeTracking = !!_find(state.dealflow.sharedWithMe.tracking.data, o => o.id === id);
      const foundSharedWithMeDeadlines = !!_find(state.dealflow.sharedWithMe.deadlines.data, o => o.id === id);
      const foundArchiveAll = !!_find(state.dealflow.archive.all.data, o => o.id === id);
      const foundArchiveTracking = !!_find(state.dealflow.archive.tracking.data, o => o.id === id);
      const foundArchiveDeadlines = !!_find(state.dealflow.archive.deadlines.data, o => o.id === id);
      const foundMySharedAll = !!_find(state.dealflow.myShared.all.data, o => o.id === id);
      const foundMarketAll = !!_find(state.dealflow.market.all.data, o => o.id === id);
      const foundMarketUnseen = !!_find(state.dealflow.market.unseen.data, o => o.id === id);
      const foundMarketTracking = !!_find(state.dealflow.market.tracking.data, o => o.id === id);
      const foundMarketDeadlines = !!_find(state.dealflow.market.deadlines.data, o => o.id === id);
      const dealflow = {
        ...state.dealflow,
        details: {
          ...state.dealflow.details,
          offer: { ...state.dealflow.details.offer, tracked: true },
        },
        amount: {
          ...state.dealflow.amount,
          sharedWithMe: {
            ...state.dealflow.amount.sharedWithMe,
            ...foundSharedWithMeAll && { tracking: state.dealflow.amount.sharedWithMe.tracking + 1 }
          },
          archive: {
            ...state.dealflow.amount.archive,
            ...foundArchiveAll && { tracking: state.dealflow.amount.archive.tracking + 1 }
          },
          market: {
            ...state.dealflow.amount.market,
            ...foundMarketAll && { tracking: state.dealflow.amount.market.tracking + 1 }
          }
        },
        sharedWithMe: {
          ...state.dealflow.sharedWithMe,
          ...foundSharedWithMeAll && {
            all: {
              ...state.dealflow.sharedWithMe.all,
              data: _map(state.dealflow.sharedWithMe.all.data, o => o.id === id ? { ...o, tracked: true } : o)
            }
          },
          ...foundSharedWithMeUnseen && {
            unseen: {
              ...state.dealflow.sharedWithMe.unseen,
              data: _map(state.dealflow.sharedWithMe.unseen.data, o => o.id === id ? { ...o, tracked: true } : o)
            }
          },
          ...foundSharedWithMeTracking && {
            tracking: {
              ...state.dealflow.sharedWithMe.tracking,
              data: _map(state.dealflow.sharedWithMe.tracking.data, o => o.id === id ? { ...o, tracked: true } : o)
            }
          },
          ...foundSharedWithMeDeadlines && {
            deadlines: {
              ...state.dealflow.sharedWithMe.deadlines,
              data: _map(state.dealflow.sharedWithMe.deadlines.data, o => o.id === id ? { ...o, tracked: true } : o)
            }
          }
        },
        archive: {
          ...state.dealflow.archive,
          ...foundArchiveAll && {
            all: {
              ...state.dealflow.archive.all,
              data: _map(state.dealflow.archive.all.data, o => o.id === id ? { ...o, tracked: true } : o)
            }
          },
          ...foundArchiveTracking && {
            tracking: {
              ...state.dealflow.archive.tracking,
              data: _map(state.dealflow.archive.tracking.data, o => o.id === id ? { ...o, tracked: true } : o)
            }
          },
          ...foundArchiveDeadlines && {
            deadlines: {
              ...state.dealflow.archive.deadlines,
              data: _map(state.dealflow.archive.deadlines.data, o => o.id === id ? { ...o, tracked: true } : o)
            }
          }
        },
        myShared: {
          ...state.dealflow.myShared,
          ...foundMySharedAll && {
            all: {
              ...state.dealflow.myShared.all,
              data: _map(state.dealflow.myShared.all.data, o => o.id === id ? { ...o, tracked: true } : o)
            }
          },
        },
        market: {
          ...state.dealflow.market,
          ...foundMarketAll && {
            all: {
              ...state.dealflow.market.all,
              data: _map(state.dealflow.market.all.data, o => o.id === id ? { ...o, tracked: true } : o)
            }
          },
          ...foundMarketUnseen && {
            unseen: {
              ...state.dealflow.market.unseen,
              data: _map(state.dealflow.market.unseen.data, o => o.id === id ? { ...o, tracked: true } : o)
            }
          },
          ...foundMarketTracking && {
            tracking: {
              ...state.dealflow.market.tracking,
              data: _map(state.dealflow.market.tracking.data, o => o.id === id ? { ...o, tracked: true } : o)
            }
          },
          ...foundMarketDeadlines && {
            deadlines: {
              ...state.dealflow.market.deadlines,
              data: _map(state.dealflow.market.deadlines.data, o => o.id === id ? { ...o, tracked: true } : o)
            }
          }
        }
      };

      return { ...state, ...(status === 204 && { dealflow }) };
    }
    case submitOfferShareFormRoutine.SUCCESS: {
      return state;
    }
    case removeFromTracksOfferRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const foundSharedWithMeAll = !!_find(state.dealflow.sharedWithMe.all.data, o => o.id === id);
      const foundSharedWithMeUnseen = !!_find(state.dealflow.sharedWithMe.unseen.data, o => o.id === id);
      const foundSharedWithMeTracking = !!_find(state.dealflow.sharedWithMe.tracking.data, o => o.id === id);
      const foundSharedWithMeDeadlines = !!_find(state.dealflow.sharedWithMe.deadlines.data, o => o.id === id);
      const foundArchiveAll = !!_find(state.dealflow.archive.all.data, o => o.id === id);
      const foundArchiveTracking = !!_find(state.dealflow.archive.tracking.data, o => o.id === id);
      const foundArchiveDeadlines = !!_find(state.dealflow.archive.deadlines.data, o => o.id === id);
      const foundMySharedAll = !!_find(state.dealflow.myShared.all.data, o => o.id === id);
      const foundMarketAll = !!_find(state.dealflow.market.all.data, o => o.id === id);
      const foundMarketUnseen = !!_find(state.dealflow.market.unseen.data, o => o.id === id);
      const foundMarketTracking = !!_find(state.dealflow.market.tracking.data, o => o.id === id);
      const foundMarketDeadlines = !!_find(state.dealflow.market.deadlines.data, o => o.id === id);
      const dealflow = {
        ...state.dealflow,
        details: {
          ...state.dealflow.details,
          offer: { ...state.dealflow.details.offer, tracked: false },
        },
        amount: {
          ...state.dealflow.amount,
          sharedWithMe: {
            ...state.dealflow.amount.sharedWithMe,
            ...foundSharedWithMeAll && { tracking: state.dealflow.amount.sharedWithMe.tracking - 1 }
          },
          archive: {
            ...state.dealflow.amount.archive,
            ...foundArchiveAll && { tracking: state.dealflow.amount.archive.tracking - 1 }
          },
          market: {
            ...state.dealflow.amount.market,
            ...foundMarketAll && { tracking: state.dealflow.amount.market.tracking - 1 }
          }
        },
        sharedWithMe: {
          ...state.dealflow.sharedWithMe,
          ...foundSharedWithMeAll && {
            all: {
              ...state.dealflow.sharedWithMe.all,
              data: _map(state.dealflow.sharedWithMe.all.data, o => o.id === id ? { ...o, tracked: false } : o)
            }
          },
          ...foundSharedWithMeUnseen && {
            unseen: {
              ...state.dealflow.sharedWithMe.unseen,
              data: _map(state.dealflow.sharedWithMe.unseen.data, o => o.id === id ? { ...o, tracked: false } : o)
            }
          },
          ...foundSharedWithMeTracking && {
            tracking: {
              ...state.dealflow.sharedWithMe.tracking,
              data: _map(state.dealflow.sharedWithMe.tracking.data, o => o.id === id ? { ...o, tracked: false } : o)
            }
          },
          ...foundSharedWithMeDeadlines && {
            deadlines: {
              ...state.dealflow.sharedWithMe.deadlines,
              data: _map(state.dealflow.sharedWithMe.deadlines.data, o => o.id === id ? { ...o, tracked: false } : o)
            }
          }
        },
        archive: {
          ...state.dealflow.archive,
          ...foundArchiveAll && {
            all: {
              ...state.dealflow.archive.all,
              data: _map(state.dealflow.archive.all.data, o => o.id === id ? { ...o, tracked: false } : o)
            }
          },
          ...foundArchiveTracking && {
            tracking: {
              ...state.dealflow.archive.tracking,
              data: _map(state.dealflow.archive.tracking.data, o => o.id === id ? { ...o, tracked: false } : o)
            }
          },
          ...foundArchiveDeadlines && {
            deadlines: {
              ...state.dealflow.archive.deadlines,
              data: _map(state.dealflow.archive.deadlines.data, o => o.id === id ? { ...o, tracked: false } : o)
            }
          }
        },
        myShared: {
          ...state.dealflow.myShared,
          ...foundMySharedAll && {
            all: {
              ...state.dealflow.myShared.all,
              data: _map(state.dealflow.myShared.all.data, o => o.id === id ? { ...o, tracked: false } : o)
            }
          },
        },
        market: {
          ...state.dealflow.market,
          ...foundMarketAll && {
            all: {
              ...state.dealflow.market.all,
              data: _map(state.dealflow.market.all.data, o => o.id === id ? { ...o, tracked: false } : o)
            }
          },
          ...foundMarketUnseen && {
            unseen: {
              ...state.dealflow.market.unseen,
              data: _map(state.dealflow.market.unseen.data, o => o.id === id ? { ...o, tracked: false } : o)
            }
          },
          ...foundMarketTracking && {
            tracking: {
              ...state.dealflow.market.tracking,
              data: _map(state.dealflow.market.tracking.data, o => o.id === id ? { ...o, tracked: false } : o)
            }
          },
          ...foundMarketDeadlines && {
            deadlines: {
              ...state.dealflow.market.deadlines,
              data: _map(state.dealflow.market.deadlines.data, o => o.id === id ? { ...o, tracked: false } : o)
            }
          }
        }
      };

      return { ...state, ...(status === 204 && state.dealflow.details.offer.id === id && { dealflow }) };
    }
    case setOfferUnreadCountConversationsRoutine.SUCCESS: {
      const { unread_conversations_count, offer_id } = action.payload;

      return { ...state, chats: { ...state.chats, data: _map(state.chats.data, item => item.id === offer_id ? { ...item, unread_conversations_count } : item) } };
    }
    case setLocalOfferCountsRoutine.SUCCESS: {
      const { offer_id, recipients_count, unlocked_recipients_count, approval_requested_recipients_count } = action.payload;

      if (!_isUndefined(approval_requested_recipients_count)) {
        const activityLog = offer_id === state.activityLog.id
          ? { ...state.activityLog, approval_requested_recipients_count }
          : state.activityLog;

        return { ...state, activityLog };
      }
      const foundDealspaceShared = !!_find(state.dealspace.shared.data, o => o.id === offer_id);
      const foundDealspaceSaved = !!_find(state.dealspace.saved.data, o => o.id === offer_id);

      return {
        ...state,
        shared: { 
          ...state.shared, 
          all: {
            ...state.shared.all,
            data: _map(state.shared.all.data, item => (!!_find(item.offers, o => o.id === offer_id)
              ? { ...item, offers: _map(item.offers, o => o.id === offer_id ? { ...o, recipients_count, unlocked_recipients_count } : o) }
              : item)
            ) 
          },
          active: {
            ...state.shared.active,
            data: _map(state.shared.active.data, item => (!!_find(item.offers, o => o.id === offer_id)
              ? { ...item, offers: _map(item.offers, o => o.id === offer_id ? { ...o, recipients_count, unlocked_recipients_count } : o) }
              : item)
            ) 
          },
          inContract: {
            ...state.shared.inContract,
            data: _map(state.shared.inContract.data, item => (!!_find(item.offers, o => o.id === offer_id)
              ? {...item, offers: _map(item.offers, o => o.id === offer_id ? { ...o, recipients_count, unlocked_recipients_count } : o)} 
              : item)
            )
          }
        },
        dealspace: {
          ...state.dealspace, 
          ...foundDealspaceShared && {
            shared: {
              ...state.dealspace.shared,
              data: _map(state.dealspace.shared.data, o => o.id === offer_id ? { ...o, recipients_count, unlocked_recipients_count } : o)
            }
          },
          ...foundDealspaceSaved && {
            saved: {
              ...state.dealspace.saved,
              data: _map(state.dealspace.saved.data, o => o.id === offer_id ? { ...o, recipients_count, unlocked_recipients_count } : o)
            }
          }
        }
      };
    }
    case clearLocalSharedOffersRoutine.SUCCESS: {
      const { type } = action.payload;
      
      if (type === 'all') return { ...state, shared: initialState.shared };
      const shared = { ...state.shared, [type]: { ...state.shared[type], data: [] } };

      return { ...state, shared };
    }
    case clearLocalContactActiveOffersRoutine.SUCCESS: {
      return { ...state, contactActiveOffers: initialState.contactActiveOffers };
    }
    case clearLocalDealspaceOffersRoutine.SUCCESS: {
      return { ...state, dealspace: initialState.dealspace };
    }
    case clearLocalOffersForChatsRoutine.SUCCESS: {
      return { ...state, chats: initialState.chats };
    }
    case clearLocalBuilderOfferRoutine.SUCCESS: {
      return { ...state, builder: { offer: getInitialBuilderState(), saving: false, deleting: false, fetching: true } };
    }
    case clearLocalFeedMapOffersRoutine.SUCCESS: {
      return { ...state, feedMap: initialState.feedMap };
    }
    case clearLocalFeedOfferRoutine.SUCCESS: {
      return { ...state, dealflow: { ...state.dealflow, details: {...state.dealflow.details, offer: getInitialDealflowState() } } };
    }
    case clearLocalActivityLogOfferRoutine.SUCCESS: {
      return state;
    }
    case unlockLocalOfferRoutine.SUCCESS: {
      const { id } = action.payload;
      const foundSharedWithMeAll = !!_find(state.dealflow.sharedWithMe.all.data, o => o.id === id);
      const foundSharedWithMeUnseen = !!_find(state.dealflow.sharedWithMe.unseen.data, o => o.id === id);
      const foundSharedWithMeTracking = !!_find(state.dealflow.sharedWithMe.tracking.data, o => o.id === id);
      const foundSharedWithMeDeadlines = !!_find(state.dealflow.sharedWithMe.deadlines.data, o => o.id === id);
      const foundArchiveAll = !!_find(state.dealflow.archive.all.data, o => o.id === id);
      const foundArchiveTracking = !!_find(state.dealflow.archive.tracking.data, o => o.id === id);
      const foundArchiveDeadlines = !!_find(state.dealflow.archive.deadlines.data, o => o.id === id);
      const foundMySharedAll = !!_find(state.dealflow.myShared.all.data, o => o.id === id);
      const foundMarketAll = !!_find(state.dealflow.market.all.data, o => o.id === id);
      const foundMarketUnseen = !!_find(state.dealflow.market.unseen.data, o => o.id === id);
      const foundMarketTracking = !!_find(state.dealflow.market.tracking.data, o => o.id === id);
      const foundMarketDeadlines = !!_find(state.dealflow.market.deadlines.data, o => o.id === id);
      const dealflow = {
        ...state.dealflow,
        sharedWithMe: {
          ...state.dealflow.sharedWithMe,
          ...foundSharedWithMeAll && {
            all: {
              ...state.dealflow.sharedWithMe.all,
              data: _map(state.dealflow.sharedWithMe.all.data, o => o.id === id ? { ...o, locked: false, agreement: {} } : o)
            }
          },
          ...foundSharedWithMeUnseen && {
            unseen: {
              ...state.dealflow.sharedWithMe.unseen,
              data: _map(state.dealflow.sharedWithMe.unseen.data, o => o.id === id ? { ...o, locked: false, agreement: {} } : o)
            }
          },
          ...foundSharedWithMeTracking && {
            tracking: {
              ...state.dealflow.sharedWithMe.tracking,
              data: _map(state.dealflow.sharedWithMe.tracking.data, o => o.id === id ? { ...o, locked: false, agreement: {} } : o)
            }
          },
          ...foundSharedWithMeDeadlines && {
            deadlines: {
              ...state.dealflow.sharedWithMe.deadlines,
              data: _map(state.dealflow.sharedWithMe.deadlines.data, o => o.id === id ? { ...o, locked: false, agreement: {} } : o)
            }
          }
        },
        archive: {
          ...state.dealflow.archive,
          ...foundArchiveAll && {
            all: {
              ...state.dealflow.archive.all,
              data: _map(state.dealflow.archive.all.data, o => o.id === id ? { ...o, locked: false, agreement: {} } : o)
            }
          },
          ...foundArchiveTracking && {
            tracking: {
              ...state.dealflow.archive.tracking,
              data: _map(state.dealflow.archive.tracking.data, o => o.id === id ? { ...o, locked: false, agreement: {} } : o)
            }
          },
          ...foundArchiveDeadlines && {
            deadlines: {
              ...state.dealflow.archive.deadlines,
              data: _map(state.dealflow.archive.deadlines.data, o => o.id === id ? { ...o, locked: false, agreement: {} } : o)
            }
          }
        },
        myShared: {
          ...state.dealflow.myShared,
          ...foundMySharedAll && {
            all: {
              ...state.dealflow.myShared.all,
              data: _map(state.dealflow.myShared.all.data, o => o.id === id ? { ...o, locked: false, agreement: {} } : o)
            }
          },
        },
        market: {
          ...state.dealflow.market,
          ...foundMarketAll && {
            all: {
              ...state.dealflow.market.all,
              data: _map(state.dealflow.market.all.data, o => o.id === id ? { ...o, locked: false, agreement: {} } : o)
            }
          },
          ...foundMarketUnseen && {
            unseen: {
              ...state.dealflow.market.unseen,
              data: _map(state.dealflow.market.unseen.data, o => o.id === id ? { ...o, locked: false, agreement: {} } : o)
            }
          },
          ...foundMarketTracking && {
            tracking: {
              ...state.dealflow.market.tracking,
              data: _map(state.dealflow.market.tracking.data, o => o.id === id ? { ...o, locked: false, agreement: {} } : o)
            }
          },
          ...foundMarketDeadlines && {
            deadlines: {
              ...state.dealflow.market.deadlines,
              data: _map(state.dealflow.market.deadlines.data, o => o.id === id ? { ...o, locked: false, agreement: {} } : o)
            }
          }
        }
      };
     
      return { ...state, dealflow };
    }
    case setOfferLocalFieldsRoutine.SUCCESS: {
      const { id } = action.payload;
      const isDetailsFetchingChange = _find(Object.keys(action.payload), (k) => k === 'detailsFetched');
      const foundSharedWithMeAll = !!_find(state.dealflow.sharedWithMe.all.data, o => o.id === id);
      const foundSharedWithMeUnseen = !!_find(state.dealflow.sharedWithMe.unseen.data, o => o.id === id);
      const foundSharedWithMeTracking = !!_find(state.dealflow.sharedWithMe.tracking.data, o => o.id === id);
      const foundSharedWithMeDeadlines = !!_find(state.dealflow.sharedWithMe.deadlines.data, o => o.id === id);
      const foundArchiveAll = !!_find(state.dealflow.archive.all.data, o => o.id === id);
      const foundArchiveTracking = !!_find(state.dealflow.archive.tracking.data, o => o.id === id);
      const foundArchiveDeadlines = !!_find(state.dealflow.archive.deadlines.data, o => o.id === id);
      const foundMySharedAll = !!_find(state.dealflow.myShared.all.data, o => o.id === id);
      const foundMarketAll = !!_find(state.dealflow.market.all.data, o => o.id === id);
      const foundMarketUnseen = !!_find(state.dealflow.market.unseen.data, o => o.id === id);
      const foundMarketTracking = !!_find(state.dealflow.market.tracking.data, o => o.id === id);
      const foundMarketDeadlines = !!_find(state.dealflow.market.deadlines.data, o => o.id === id);
      const dealflow = state.dealflow.details.offer.id === id || isDetailsFetchingChange ?
        {
          ...state.dealflow,
          details: {
            ...state.dealflow.details,
            offer: { ...state.dealflow.details.offer, ...action.payload }
          },
          sharedWithMe: {
            ...state.dealflow.sharedWithMe,
            ...foundSharedWithMeAll && {
              all: {
                ...state.dealflow.sharedWithMe.all,
                data: _map(state.dealflow.sharedWithMe.all.data, o => o.id === id ? { ...o, ...action.payload } : o)
              }
            },
            ...foundSharedWithMeUnseen && {
              unseen: {
                ...state.dealflow.sharedWithMe.unseen,
                data: _map(state.dealflow.sharedWithMe.unseen.data, o => o.id === id ? { ...o, ...action.payload } : o)
              }
            },
            ...foundSharedWithMeTracking && {
              tracking: {
                ...state.dealflow.sharedWithMe.tracking,
                data: _map(state.dealflow.sharedWithMe.tracking.data, o => o.id === id ? { ...o, ...action.payload } : o)
              }
            },
            ...foundSharedWithMeDeadlines && {
              deadlines: {
                ...state.dealflow.sharedWithMe.deadlines,
                data: _map(state.dealflow.sharedWithMe.deadlines.data, o => o.id === id ? { ...o, ...action.payload } : o)
              }
            }
          },
          archive: {
            ...state.dealflow.archive,
            ...foundArchiveAll && {
              all: {
                ...state.dealflow.archive.all,
                data: _map(state.dealflow.archive.all.data, o => o.id === id ? { ...o, ...action.payload } : o)
              }
            },
            ...foundArchiveTracking && {
              tracking: {
                ...state.dealflow.archive.tracking,
                data: _map(state.dealflow.archive.tracking.data, o => o.id === id ? { ...o, ...action.payload } : o)
              }
            },
            ...foundArchiveDeadlines && {
              deadlines: {
                ...state.dealflow.archive.deadlines,
                data: _map(state.dealflow.archive.deadlines.data, o => o.id === id ? { ...o, ...action.payload } : o)
              }
            }
          },
          myShared: {
            ...state.dealflow.myShared,
            ...foundMySharedAll && {
              all: {
                ...state.dealflow.myShared.all,
                data: _map(state.dealflow.myShared.all.data, o => o.id === id ? { ...o, ...action.payload } : o)
              }
            },
          },
          market: {
            ...state.dealflow.market,
            ...foundMarketAll && {
              all: {
                ...state.dealflow.market.all,
                data: _map(state.dealflow.market.all.data, o => o.id === id ? { ...o, ...action.payload } : o)
              }
            },
            ...foundMarketUnseen && {
              unseen: {
                ...state.dealflow.market.unseen,
                data: _map(state.dealflow.market.unseen.data, o => o.id === id ? { ...o, ...action.payload } : o)
              }
            },
            ...foundMarketTracking && {
              tracking: {
                ...state.dealflow.market.tracking,
                data: _map(state.dealflow.market.tracking.data, o => o.id === id ? { ...o, ...action.payload } : o)
              }
            },
            ...foundMarketDeadlines && {
              deadlines: {
                ...state.dealflow.market.deadlines,
                data: _map(state.dealflow.market.deadlines.data, o => o.id === id ? { ...o, ...action.payload } : o)
              }
            }
          }
        }
        : state.dealflow;

      return { ...state, ...(state.activityLog.id === id && { activityLog: { ...state.activityLog, ...action.payload }}), dealflow };
    }
    case setBuilderStateRoutine.SUCCESS: {
      const { saving, deleting } = action.payload;
      const builder = {
        ...state.builder,
        ...!_isUndefined(saving) && { saving },
        ...!_isUndefined(deleting) && { deleting }
      };

      return { ...state, builder };
    }
    default: {
      return state;
    }
  };
};

export default reducer;