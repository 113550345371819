import { memo } from 'react';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { StyledNavAction, StyledTab } from '../AppNavigation.styled';
import { ReactComponent as DashboardIcon } from 'assets/icons/appnav/dashboard.svg';
import { ReactComponent as DashboardMobileIcon } from 'assets/icons/appnav/dashboard-mobile.svg';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';

const Dashboard = ({ mobile = false }) => {
  const { setAuthData, /*openAccessBetaDialog*/ } = useApp();
  const { /*isQwincyMember, hasRecipientSession,*/ authorizedAsUser } = useCustomSelector(state => ({ 
    //   isQwincyMember: !!state.profile.recipient.user?.email,
    //   hasRecipientSession: !!state.profile.recipient.id
    authorizedAsUser: state.sessions.session.userableType === 'user'
  }));

  const checkIsQwincyMember = () => {
    setAuthData({ open: true });
    /**
     * Временное решение
     */
    // (isQwincyMember || !hasRecipientSession) ? setAuthData({ open: true }) : openAccessBetaDialog();
  };
  const handleClick = () => !authorizedAsUser && checkIsQwincyMember();

  if (!mobile) {
    return (
      <StyledTab
        component={authorizedAsUser ? NavLink : Button}
        iconPosition='start'
        to='/dashboard'
        onClick={handleClick}
        icon={<DashboardIcon />}
        value={1}
        label='Dashboard'
      />
    );
  }
  return (
    <StyledNavAction
      component={authorizedAsUser ? NavLink : Button}
      to='/dashboard'
      onClick={handleClick}
      label='Dashboard'
      value={1}
      icon={<DashboardMobileIcon />}
    />
  );
};

Dashboard.propTypes = {
  mobile: PropTypes.bool
};

const ErrorFallback = ({ error }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Dashboard {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);