import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const Root = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
});

export const NameContainer = styled(Box)({
  width: '100%',
  maxWidth: 170
});

export const StatusContainer = styled(Box)({
  flexShrink: 0,
  maxWidth: 130,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontWeight: 600,
});

export const RootMobile = styled(Box)({
  width: '100%',
});

export const HeaderMobile = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  paddingBottom: 16,
});

export const TaglineContainerMobile = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  paddingRight: 16,
  overflow: 'hidden',
});

export const StatusContainerMobile = styled(Box)({
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '2px',
});

export const Tagline = styled(props => (
  <Typography variant='small' component='div' noWrap {...props} />
))({
  color: '#7F8084',
});

export const Status = styled(Box)({
  fontSize: '0.75rem',
  color: '#87888B',
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
});
