import styled from '@mui/material/styles/styled';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import BottomNavigation from '@mui/material/BottomNavigation';
import Button from '@mui/material/Button';
import MuiBadge from '@mui/material/Badge';

export const LogoContainer = styled(Box)({
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  marginRight: 75,
  '& > svg': {
    width: '20px',
    height: 'auto'
  }
});
export const BetaLabel = styled(Box)(({ theme }) => ({
  userSelect: 'none',
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 200,
  color: '#9BB5F6',
  background: '#3D3D3D',
  borderRadius: '2px',
  padding: '2px 4px'
}));
export const StyledTitle = styled(Box)(({ theme }) => ({
  userSelect: 'none',
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 600,
  margin: '0 11px 0 10px'
}));
export const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    height: 5,
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px'
  }
}));
export const StyledTab = styled(Tab)(({ theme }) => ({
  minWidth: 'unset',
  minHeight: 'unset',
  height: 50,
  opacity: 1,
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 400,
  textTransform: 'none',
  padding: '8px 4px',
  color: theme.palette.common.white,
  marginRight: 40,
  '&.Mui-selected': {
    color: theme.palette.common.white,
    fontWeight: 400
  }
}));
export const StyledNavAction = styled(BottomNavigationAction)(({ theme }) => ({
  height: 80,
  color: `${alpha(theme.palette.common.white, theme.palette.action.disabledOpacity)}`,
  textAlign: 'center',
  paddingTop: 'unset',
  '& > span': {
    opacity: 1,
    fontSize: '0.590rem',
    fontWeight: 500,
    lineHeight: 1.5
  },
  '&.Mui-selected, &.active': {
    color: theme.palette.common.white
  }
}));
export const StyledNav = styled(BottomNavigation)(({ theme }) => ({
  height: 80,
  flexShrink: 0,
  order: 2,
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main
}));
export const StyledAccountButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  color: theme.palette.common.white,
  marginRight: '-16px'
}));

export const Badge = styled(MuiBadge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 6,
    right: 6,
    height: 10,
    width: 10,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.primary.main}`
  }
}));