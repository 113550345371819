import { memo, useState } from 'react';
import Box from '@mui/material/Box';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Scrollbars from 'react-custom-scrollbars-2';
import _map from 'lodash/map';
import _startCase from 'lodash/startCase';
import currency from 'currency.js';
import PropTypes from 'prop-types';
// Local files
import { DeleteButton, HeadCell, StyledCell, StyledRow, Text } from './Table.styled';
import { ReactComponent as DeleteIcon } from 'assets/icons/extension/delete.svg';
import { formatPrice, getShortDate } from 'helpers';
import { DELETE_EMAIL } from 'helpers/confirmations';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';

const Table = ({ onItemClick, onLoadMore }) => {
  const { openDeleteDialog } = useApp();
  const { data, fetching, needToLoadMore, newOffset } = useCustomSelector(state => ({
    data: state.emails.all.data,
    fetching: state.emails.all.fetching,
    needToLoadMore: state.emails.all.pagination.total_count > state.emails.all.pagination.count + state.emails.all.pagination.offset,
    newOffset: state.emails.all.pagination.limit + state.emails.all.pagination.offset
  }));
  const [showDeleteId, setShowDeleteId] = useState(null);

  const handleScroll = ({ target }) => {
    if (target.scrollTop === target.scrollHeight - target.clientHeight && !fetching && needToLoadMore) {
      onLoadMore(newOffset);
    }
  };
  const handleDeleteClick = (e, id) => {
    e.stopPropagation();
    openDeleteDialog({ type: DELETE_EMAIL, id });
  };

  return (
    <Box sx={{ height: 328, width: '100%', overflow: 'hidden' }}> {/* 370 -  40px - 2px*/}
      <Scrollbars autoHideTimeout={1000} onScroll={handleScroll}>
        <MuiTable stickyHeader sx={{ width: '100%', overflowX: 'hidden', background: '#fff' }}>
          <TableHead>
            <TableRow>
              <HeadCell>Sender(s)</HeadCell>
              <HeadCell>Deal Type</HeadCell>
              <HeadCell>Asset Classes</HeadCell>
              <HeadCell>Amount</HeadCell>
              <HeadCell>Address</HeadCell>
              <HeadCell>Deadlines</HeadCell>
              <HeadCell>Received</HeadCell>
              <HeadCell sx={{ width: 30, p: 0 }}></HeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_map(data, email =>
              <StyledRow
                key={email.id}
                unseen={false}
                downloaded={false}
                onMouseOver={() => setShowDeleteId(email.id)}
                onMouseLeave={() => setShowDeleteId(null)}
                onClick={() => onItemClick(email.id)}
              >
                <StyledCell sx={{ width: 110 }}>
                  <Text>{email.senders ? email.senders.join(', ') : 'Undefined'}</Text>
                </StyledCell>
                <StyledCell sx={{ width: 105 }}>
                  <Text>{_startCase(email.deal_type)}</Text>
                </StyledCell>
                <StyledCell sx={{ width: 100 }}>
                  <Text>{_map(email.asset_classes, ac => _startCase(ac)).join(', ')}</Text>
                </StyledCell>
                <StyledCell sx={{ width: 90 }}>
                  <Text>{currency(email.price_cents, { fromCents: true, format: formatPrice }).format()}</Text>
                </StyledCell>
                <StyledCell sx={{ width: 120 }}>
                  <Text>{email.addresses.join(', ')}</Text>
                </StyledCell>
                <StyledCell sx={{ width: 65, color: '#FF0000' }}>
                  {email.deadline_at && <Text>{getShortDate(email.deadline_at)}</Text>}
                </StyledCell>
                <StyledCell sx={{ width: 65 }}>
                  <Text>{getShortDate(email.received_at)}</Text>
                </StyledCell>
                <StyledCell sx={{ width: 30, p: 0 }}>
                  {showDeleteId === email.id &&
                    <DeleteButton onClick={e => handleDeleteClick(e, email.id)}>
                      <DeleteIcon />
                    </DeleteButton>
                  }
                </StyledCell>
              </StyledRow>
            )}
            </TableBody>
        </MuiTable>
        <Box sx={{ height: '30px' }} /> {/*30px footer*/}
      </Scrollbars>
    </Box>
  );
};

Table.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Table {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);