import { memo } from 'react';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _orderBy from 'lodash/orderBy';
// Local Files
import Conversation from 'components/Conversations/Conversation/Conversation';
import useCustomSelector from 'hooks/useCustomSelector';

const GeneralList = () => {
  const data = _orderBy(useCustomSelector(state => state.conversations.user.general.data), ['updated_at'], ['desc']);
  const { user: { id: user_id } } = useCustomSelector(state => state.profile);

  const getChatTitle = (users) => {
    if (users.length > 2) {
      return _map(_filter(users, (user) => user.id !== user_id), ({ first_name }) => first_name).join(', ');
    } else {
      const user = _find(users, (user) => user.id !== user_id);

      return `${user?.first_name ?? 'Deleted'} ${user?.last_name ?? ''}`;
    } 
  }

  if (!!data.length) {
    return (
      _map(data, ({ id, users, updated_at, last_message }) =>
        <Conversation 
          key={id} 
          conversation_id={id}
          type="general"
          title={getChatTitle(users)} 
          highlighted={!!last_message && user_id !== last_message?.user?.id && !last_message?.read} 
          timestamp={new Date(updated_at).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
          message={last_message} 
          users={users}
        />
      )
    );
  }

  return null;
};

export default memo(GeneralList);