import { memo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import DropUp from '@mui/icons-material/ArrowDropUp';
import DropDown from '@mui/icons-material/ArrowDropDown';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
// Local files
import { StyledButton } from './Filters.styled';
import Search from 'components/Common/Inputs/Search/Search';
import Confirmation from '../Confirmation/Confirmation';
import Drawer from '../../Drawers/Contact/Contact';
import ListsAutocomplete from 'components/Lists/Autocomplete/Autocomplete';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';

const Filters = ({ onQueryChange, checkedContacts, onRemove, onListsChange }) => {
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { openUploadContactsDialog, openListsUpdateDialog } = useApp();
  const { params, contacts, total_count } = useCustomSelector(state => {
    const params = state.contacts.all.filters;
    const contacts = state.contacts.all.data;
    const total_count = state.contacts.all.pagination.total_count;

    return { params, contacts, total_count };
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDeleteList = id => onListsChange(_filter(params.lists, l => l.id !== id));
  const handleRemove = () => setDialogOpen(true);
  const handleDialogClose = clear => {
    setDialogOpen(false);
    !!clear && onRemove([]);
  };
  const handleDrawerClose = () => setDrawerOpen(false);
  const handleListsUpdate = () => openListsUpdateDialog(_filter(contacts, (c) => _find(checkedContacts, (id) => c.id === id)));
  const handleCSVUpload = () => {
    setAnchorEl(null);
    openUploadContactsDialog();
  };
  const handleSingleCreate = () => {
    setAnchorEl(null);
    setDrawerOpen(true);
  };

  if (mediaDesktop) {
    return (
      <Box display='flex' justifyContent='space-between' alignItems='center' mt={4} mb={2}>
        <Confirmation
          ids={checkedContacts}
          open={dialogOpen}
          onClose={handleDialogClose}
        />
        <Drawer
          open={drawerOpen}
          onClose={handleDrawerClose}
        />
        <Box display='flex' alignItems='center'>
          <Search
            value={params.query}
            onChange={onQueryChange}
            placeholder='Search my contacts'
          />
          {!!checkedContacts.length &&
            <>
              <Button variant='contained' onClick={handleRemove}>Remove selected items</Button>
              <Button variant='contained' onClick={handleListsUpdate} sx={{ ml: 1 }}>Add to list</Button>
            </>
          }
        </Box>
        <Box display='flex' alignItems='center'>
          {_map(params.lists, l =>
            <Chip
              key={l.id}
              label={l.name}
              onDelete={() => handleDeleteList(l.id)}
              sx={{ m: '1px', fontWeight: 400, fontSize: '11px', lineHeight: '20px', color: '#4877F0', backgroundColor: 'rgba(72, 119, 240, 0.12)' }}
            />
          )}
          <Typography variant='caption' noWrap sx={{ ml: 2 }}>{`Showing ${total_count?.toLocaleString('en-US')} contacts`}</Typography>
          <Box width={219} ml={3}>
            <ListsAutocomplete
              label='Filter by lists'
              size='small'
              value={params.lists}
              onChange={v => onListsChange(v)}
            />
          </Box>
          <StyledButton
            sx={{ height: 38 }}
            endIcon={anchorEl ? <DropUp /> : <DropDown />}
            onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
          >
            Add Contacts
          </StyledButton>
          <Menu 
            keepMounted 
            open={!!anchorEl} 
            anchorEl={anchorEl} 
            onClose={() => setAnchorEl(null)}
            PaperProps={{ sx: { width: anchorEl?.offsetWidth } }}
          >
            <MenuItem onClick={handleSingleCreate}>
              <Typography variant='caption'noWrap>Single Entry</Typography>
            </MenuItem>
            <MenuItem onClick={handleCSVUpload}>
              <Typography variant='caption'noWrap>Upload CSV</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    );
  }
  return (
    <Box mt={4} mb={2}>
      <Confirmation
        ids={checkedContacts}
        open={dialogOpen}
        onClose={handleDialogClose}
      />
      <Drawer
        open={drawerOpen}
        onClose={handleDrawerClose}
      />
      <Box display='flex' mb={1} gap={1}>
        <ListsAutocomplete
          label='Filter by lists'
          size='small'
          value={params.lists}
          onChange={v => onListsChange(v)}
        />
        <StyledButton
          sx={{ height: 38, width: 220, marginLeft: 0 }}
          endIcon={anchorEl ? <DropUp /> : <DropDown />}
          onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        >
          Add Contacts
        </StyledButton>
        <Menu 
          keepMounted 
          open={!!anchorEl} 
          anchorEl={anchorEl} 
          onClose={() => setAnchorEl(null)}
          PaperProps={{ sx: { width: anchorEl?.offsetWidth } }}
        >
          <MenuItem onClick={handleSingleCreate}>
            <Typography variant='caption'noWrap>Single Entry</Typography>
          </MenuItem>
          <MenuItem onClick={handleCSVUpload}>
            <Typography variant='caption'noWrap>Upload CSV</Typography>
          </MenuItem>
        </Menu>
      </Box>
      <Box mb={1}>
        <Box>
          {_map(params.lists, l =>
            <Chip
              key={l.id}
              label={l.name}
              onDelete={() => handleDeleteList(l.id)}
              sx={{ m: '1px', fontWeight: 400, fontSize: '11px', lineHeight: '20px', color: '#4877F0', backgroundColor: 'rgba(72, 119, 240, 0.12)' }}
            />
          )}
        </Box>
        <Typography variant='caption' noWrap>
          Showing {total_count?.toLocaleString('en-US')} contacts
        </Typography>
      </Box>
      <Box display='flex' alignItems='center' mb={1}>
        <Search
          value={params.query}
          onChange={onQueryChange}
          placeholder='Search my contacts'
        />
        {!!checkedContacts.length &&
          <>
            <Button variant='contained' onClick={handleRemove}>Remove selected items</Button>
            <Button variant='contained' onClick={handleListsUpdate} sx={{ ml: 1 }}>Add to list</Button>
          </>
        }
      </Box>
    </Box>
  );
};

export default memo(Filters);