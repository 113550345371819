import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import GroupsSelect from 'components/Groups/Select/Select';
import DealspacesSelect from 'components/Dealspaces/Select/Select';
import DealspaceDialog from '../CreateDealspace/CreateDealspace';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { getFormattedShortDateWithTime } from 'helpers';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useOffers from 'hooks/useOffers';

const PreBuilder = () => {
  const previousLocation = useLocation();
  const navigate = useNavigate();
  const { closePreBuilderDialog } = useApp();
  const { setError } = useError();
  const { createOffer } = useOffers();
  const open = useCustomSelector(state => state.app.preBuilderDialog.open);
  const creator = useCustomSelector(state => `${state.profile.user.first_name} ${state.profile.user.last_name[0]}.`);
  const groups = useCustomSelector(state => state.groups.all.data);
  const [groupId, setGroupId] = useState(() => !!previousLocation.state?.groupId ? previousLocation.state?.groupId : (groups.length === 1 ? groups[0].id : ''));
  const [dealspaceId, setDealspaceId] = useState(previousLocation.state?.dealspaceId ?? '');
  const [processing, setProcessing] = useState(false);
  const [dealspaceDialog, setDealspaceDialog] = useState({ open: false, groupId: null, hideInvite: false });
  const disabled = !groupId || !dealspaceId || processing;

  const handleClose = () => closePreBuilderDialog();
  const handleSubmit = () => {
    const offer = { name: `${getFormattedShortDateWithTime(new Date())} by ${creator}` };
    
    setProcessing(true);
    createOffer({ dealspace_id: dealspaceId, offer })
    .then(({ payload: { data: { offer: { id } } } }) =>
      closePreBuilderDialog()
      .then(() => navigate(`/builder/${id}`, { state: { previousLocation } }))
    )
    .catch(e => {
      console.log(e);
      setError(e)
    })
    .finally(() => setProcessing(false));
  };
  const handleDealspaceChange = v => {
    if (v === 'create') {
      setDealspaceDialog({ open: true, groupId, hideInvite: true });
    } else {
      setDealspaceId(v);
    }
  };
  const handleDealspaceDialogClose = v => {
    if (v) setDealspaceId(v);
    setDealspaceDialog({ open: false, groupId: null, hideInvite: false });
  };

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      otherPaperStyle={{ width: '100%', maxWidth: 500 }}
      title='Create Offering'
      actions={
        <>
          <Button
            variant='text'
            color='error'
            onClick={handleClose}
            sx={{ fontSize: '12px', mr: '24px', py: '8px', px: '28px' }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={handleSubmit}
            sx={{ fontSize: '12px', px: '28px' }}
            disabled={disabled}
          >
            Create
          </Button>
        </>
      }
    >
      <DealspaceDialog
        {...dealspaceDialog}
        onClose={handleDealspaceDialogClose}
      />
      <Box sx={{ px: 4, pb: 3 }}>
        <Typography variant='body2' align='center' p='16px 0px 8px'>
          Specify from where this Offering should originate:
        </Typography>
        <Box>
          <GroupsSelect
            disabled={processing}
            value={groupId}
            onChange={setGroupId}
          />
        </Box>
        {!!groupId &&
          <Box sx={{ mt: 1.5 }}>
            <DealspacesSelect
              disabled={processing}
              groupId={groupId}
              value={dealspaceId}
              onChange={handleDealspaceChange}
            />
          </Box>  
        }
      </Box>
    </BaseDialog>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <PreBuilder {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);