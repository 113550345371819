import { memo, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { sessionService } from 'redux-react-session';
// Local files
import axios, { baseURL } from 'apis';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useApp from 'hooks/useApp';
import useError from 'hooks/useError';
import useRecipients from 'hooks/useRecipients';
import useSessions from 'hooks/useSessions';
import useStore from 'hooks/useStore';

const Recipient = () => {
  const navigate = useNavigate();
  const { setAuthData } = useApp();
  const { setError } = useError();
  const { recipient_token } = useParams();
  const { getOfferFromRecipientToken } = useRecipients();
  const { createSession, deleteSession } = useSessions();
  const { clearStore } = useStore();

  const createRecipientSession = useCallback(recipientToken => {
    // console.log('create new');
    createSession({ recipient_token: recipientToken })
    .then(({ payload: { data: { session } } }) =>
      setAuthData({
        mode: session.userable.user?.email ? 'signIn' : 'signUp',
        email: session.userable.email ?? session.userable.user.email,
        redirectUrl: `/deal_flow/${session.userable.offer.id}`,
        recipientToken: recipientToken
      })
      .then(() => navigate(`/deal_flow/${session.userable.offer.id}`, { state: { showLoadingFeed: true } }))
    )
    .catch(e => setError(e));
  }, [createSession, setAuthData, setError]); // eslint-disable-line

  useEffect(() => {
    if (recipient_token) {
      sessionService.loadSession()
      .then(({ userableType, accessToken }) => {
        if (userableType === 'user') {
          axios.defaults.baseURL = `${baseURL}/${userableType}`;
          axios.defaults.params = { ...axios.defaults.params, access_token: accessToken };
          axios.defaults.data = { ...axios.defaults.params, access_token: accessToken };

          getOfferFromRecipientToken(recipient_token)
          .then(({ payload: { data: { recipient: { offer: { id } } } } }) => navigate(`/deal_flow/${id}`))
          .catch(e => {
            if (e.response?.status === 401) {
              sessionService.deleteSession()
              .catch(e => console.warn(e))
              .finally(() => clearStore().then(() => window.location.replace('/sign_in')));
            } else {
              setError(e);
            }
          });
        } else {
          createRecipientSession(recipient_token);
        }
      })
      .catch(() => createRecipientSession(recipient_token));
    }
  }, [clearStore, createRecipientSession, deleteSession, getOfferFromRecipientToken, recipient_token, setAuthData, setError]); // eslint-disable-line

  return null;
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Recipient {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);