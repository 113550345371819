import styled from '@mui/material/styles/styled';
import ButtonBase from '@mui/material/ButtonBase';

const Root = styled(ButtonBase)(({ theme }) => ({
  minHeight: 140, //like File card
  backgroundColor: theme.palette.background.paper,
  border: `1px dashed ${theme.palette.primary.main}`,
  borderRadius: '6px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '24px'
}));
const FileInput = styled('input')({ display: 'none' });

export { Root, FileInput };