import { memo } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
// Local files
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import Item from './Item/Item';

const VerticalFilters = ({ disabled, value, onChange }) => {
  const verticalFilterItems = ['sharedWithMe', 'archive', 'myShared', 'market'];

  return (
    <List disablePadding>
      {_map(verticalFilterItems, i => 
        <ListItem key={i} disablePadding>
          <Item {...{value, type: i, disabled, onChange}} />
        </ListItem>
      )}
    </List>
  );
};

VerticalFilters.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <VerticalFilters {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);