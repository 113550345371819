import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getArchiveRoutine,
  createArchiveRoutine
} from 'actions';

export const getArchive = getThunkActionCreator(
  getArchiveRoutine,
  async id => {
    const attributes = ['id', { file: ['url', 'filename'] }];
    const params = { attributes };

    return await axios.get(`/archives/${id}`, { params });
  }
);
export const createArchive = getThunkActionCreator(
  createArchiveRoutine,
  async folder_id => {
    const attributes = ['id'];
    const data = { attributes };

    return await axios.post(`/folders/${folder_id}/archive`, data);
  }
);