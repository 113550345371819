import { memo } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ButtonBase from '@mui/material/ButtonBase';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
// Local files
import { Primary, Secondary } from './Breadcrumbs.styled';
import { ReactComponent as BackIcon } from 'assets/icons/files-back.svg';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';


const FoldersBreadcrumbs = ({ crumbs, onClick }) => {
  if (!crumbs.length) return <div />;
  return (
    <Box display='flex' alignItems='center'>
      {crumbs.length > 1 &&
        <IconButton onClick={() => onClick(crumbs[0].id)}>
          <BackIcon fontSize='12px' />
        </IconButton>
      }
      <Breadcrumbs sx={{ '.MuiBreadcrumbs-li': { display: 'flex' } }}>
        {_map(crumbs, (c, i) => i === crumbs.length - 1 ?
          <ButtonBase key={i} sx={{ height: 'inherit' }}>
            <Primary>{c.name === 'root' ? 'All files' : c.name}</Primary>
          </ButtonBase> :
          <ButtonBase key={i} onClick={() => onClick(c.id)}>
            <Secondary>{c.name === 'root' ? 'All files' : c.name}</Secondary>
          </ButtonBase>
        )}
      </Breadcrumbs>
    </Box>
  );
};

FoldersBreadcrumbs.propTypes = {
  crumbs: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <FoldersBreadcrumbs {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);