import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const FieldWithCounterContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '8px',
  marginTop: '60px',
  marginBottom: '40px'
});

export const CanvasContainer = styled(Box)({
  width: '100%',
  minHeight: 400,
  borderRadius: '10px'
});

export const TinyContainer = styled(Box)({
  boxShadow: '0px 0px 7px 0px #0000001A',
  width: '100%',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  '& .tox-statusbar': {
    borderTop: 'none !important'
  },
  '& .tox-sidebar-wrap': {
    paddingTop: 25,
    paddingBottom: 5
  }
});

export const Paper = styled('div')({
  width: '100%',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  boxShadow: '0px 0px 7px 0px #0000001A',
  padding: '18px'
});