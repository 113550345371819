import { memo } from 'react';
import Button from '@mui/material/Button';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';

const SaveProfile = ({ open, onDiscard, onSave, onClose }) => {

  return (
    <BaseDialog
      open={open}
      onClose={() => onClose(false)}
      maxWidth={false}
      PaperProps={{ sx: { maxWidth: 380, width: '100%' } }}
      title='Save profile changes?'
      actions={
      <>
        <Button
          variant='text'
          color='error'
          sx={{ px: '24px' }}
          onClick={onDiscard}
        >
          Discard changes
        </Button>
        <Button
          variant='contained'
          color='primary'
          sx={{ px: '24px'}}
          onClick={onSave}
        >
          Save changes
        </Button>
      </>
      }
    />
  );
};

export default memo(SaveProfile);