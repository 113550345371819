import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import _pick from 'lodash/pick';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Root, StyledContentContainer, Title, AvatarsContainer, InviteContainer } from './Card.styled';
import SettingsIcon from 'components/Common/Icons/SettingsIcon';
import AddTeamIcon from 'components/Common/Icons/AddTeamIcon';
import Team from 'components/Common/DataDisplay/Team/Team';
import PrivateLabel from './PrivateLabel/PrivateLabel';
import CreateInviteDialog from 'components/Dialogs/CreateInvite/CreateInvite';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useCustomSelector from 'hooks/useCustomSelector';

const Card = ({ fetching }) => {
  const { id, name, members, current_member } = useCustomSelector(state => {
    const members = state.members.groupList.data;
    
    return { members, ..._pick(state.groups.group, ['id', 'name', 'current_member']) };
  });
  const [inviteDialog, setInviteDialog] = useState({ open: false, groupId: '' });
  const isPrivate = true;

  const handleInviteClick = () => setInviteDialog({ open: true, groupId: id });
  const closeInviteDialog = () => setInviteDialog({ open: false, groupId: '' })

  return (
    <Root maxWidth={false} disableGutters>
      <CreateInviteDialog
        {...inviteDialog}
        isOwner={current_member?.role === 'owner'}
        onClose={closeInviteDialog}
      />
      <StyledContentContainer>
        <Box sx={{ flexGrow: 1, overflow: 'hidden', width: { xs: '100%', md: 'auto' } }}>
          {fetching
            ? <Skeleton animation='wave' width={250} variant='text' />
            : <Title component={Link} to={`/groups/${id}`} noWrap>{name}</Title>
          }
          {fetching
            ? <Skeleton animation='wave' width={100} variant='text' />
            : isPrivate && <PrivateLabel />
          }
        </Box>
        <AvatarsContainer>
          {fetching ? <Skeleton animation='wave' variant='rectangular' width='100%' /> : <Team members={members} />}
        </AvatarsContainer>
        <InviteContainer>
          <Button variant='outlined' startIcon={<AddTeamIcon />} sx={{ borderRadius: '4px' }} onClick={handleInviteClick}>
            Invite my team
          </Button>
        </InviteContainer>
      </StyledContentContainer>
      <IconButton
        style={{ color: 'inherit', lineHeight: '100%' }}
        LinkComponent={Link}
        to={`/groups/${id}/settings`}
        sx={{ mx: '-8px' }}
      >
        <SettingsIcon />
      </IconButton>
    </Root>
  );
};

Card.propTypes = {
  fetching: PropTypes.bool.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Card {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);