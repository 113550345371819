import { memo, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
// Local files
import FileInput from 'components/Common/Inputs/FileInput/FileInput';
import { ReactComponent as UploadIcon } from 'assets/icons/upload-icon.svg';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const Empty = ({ accept, onChange }) => {
  const inputRef = useRef();

  const handleClick = () => inputRef.current.click();

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      sx={{ cursor: 'pointer' }}
      onClick={handleClick}
    >
      <UploadIcon />
      <Box display='flex' gap='3px'>
        <Typography fontWeight={600} fontSize='14px' lineHeight='24px'>
          Click to upload
        </Typography>
        <Typography fontWeight={400} fontSize='14px' lineHeight='24px'>
          or drag and drop
        </Typography>
      </Box>
      <FileInput
        ref={inputRef}
        accept={accept}
        id='folderEmptyUploader'
        multiple
        onChange={onChange}
      />
    </Box>
  );
};

Empty.propTypes = {
  accept: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Empty {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);