import Typography from '@mui/material/Typography';

const SymbolCounter = ({ max = 0, current = 0 }) => {

  return (
    <Typography variant='small' component='span' sx={{ color: 'rgba(0,0,0,.6)'}} >
      <span style={{ color: '#000' }}>{current}</span> / {max}
    </Typography>
  )
}

export default SymbolCounter;