import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getOfferPublicFoldersRoutine,
  getOfferPrivateLevel1FoldersRoutine,
  getOfferPrivateLevel2FoldersRoutine,
  getBuilderPublicFoldersRoutine,
  getBuilderPrivateLevel1FoldersRoutine,
  getBuilderPrivateLevel2FoldersRoutine,
  getPreviewPublicFoldersRoutine,
  getFoldersRoutine,
  getFolderRoutine,
  getActualFolderDataRoutine,
  createFolderRoutine,
  updateFolderRoutine,
  deleteFolderRoutine,
  clearLocalOfferFoldersRoutine,
  clearLocalFoldersRoutine,
  clearLocalFolderRoutine,
  setUploadingItemRoutine,
  removeUploadingItemRoutine,
  hideUploadingItemsRoutine,
  clearUploadingItemsRoutine
} from 'actions';

export const getOfferPublicFolders = getThunkActionCreator(
  getOfferPublicFoldersRoutine,
  async ({ folder_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const attributes = ['id', 'ancestor_folders', 'name', 'documents_count', 'documents_byte_size'];
    const params = { limit, offset, unlocked: true, orders, attributes };

    return await axios.get(`/folders/${folder_id}/folders`, { params });
  }
);
export const getOfferPrivateLevel1Folders = getThunkActionCreator(
  getOfferPrivateLevel1FoldersRoutine,
  async ({ folder_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const attributes = ['id', 'ancestor_folders', 'name', 'documents_count', 'documents_byte_size'];
    const params = { limit, offset, locked: true, no_approval_required: true, orders, attributes };

    return await axios.get(`/folders/${folder_id}/folders`, { params });
  }
);
export const getOfferPrivateLevel2Folders = getThunkActionCreator(
  getOfferPrivateLevel2FoldersRoutine,
  async ({ folder_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const attributes = ['id', 'ancestor_folders', 'name', 'documents_count', 'documents_byte_size'];
    const params = { limit, offset, locked: true, approval_required: true, orders, attributes };

    return await axios.get(`/folders/${folder_id}/folders`, { params });
  }
);
export const getBuilderPublicFolders = getThunkActionCreator(
  getBuilderPublicFoldersRoutine,
  async ({ folder_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const attributes = ['id', 'ancestor_folders', 'name', 'documents_count', 'documents_byte_size', { folder: ['id'] }];
    const params = { limit, offset, unlocked: true, orders, attributes };

    return await axios.get(`/folders/${folder_id}/folders`, { params });
  }
);
export const getBuilderPrivateLevel1Folders = getThunkActionCreator(
  getBuilderPrivateLevel1FoldersRoutine,
  async ({ folder_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const attributes = ['id', 'ancestor_folders', 'name', 'documents_count', 'documents_byte_size', { folder: ['id'] }];
    const params = { limit, offset, locked: true, no_approval_required: true, orders, attributes };

    return await axios.get(`/folders/${folder_id}/folders`, { params });
  }
);
export const getBuilderPrivateLevel2Folders = getThunkActionCreator(
  getBuilderPrivateLevel2FoldersRoutine,
  async ({ folder_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const attributes = ['id', 'ancestor_folders', 'name', 'documents_count', 'documents_byte_size', { folder: ['id'] }];
    const params = { limit, offset, locked: true, approval_required: true, orders, attributes };

    return await axios.get(`/folders/${folder_id}/folders`, { params });
  }
);
export const getPreviewPublicFolders = getThunkActionCreator(
  getPreviewPublicFoldersRoutine,
  async ({ folder_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const attributes = ['id', 'ancestor_folders', 'name', 'documents_count', 'documents_byte_size'];
    const params = { limit, offset, unlocked: true, orders, attributes };

    return await axios.get(`/folders/${folder_id}/folders`, { params });
  }
);
export const getFolders = getThunkActionCreator(
  getFoldersRoutine,
  async ({ folder_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const attributes = ['id', 'ancestor_folders', 'name', 'documents_count', 'documents_byte_size'];
    const params = { limit, offset, orders, attributes };

    return await axios.get(`/folders/${folder_id}/folders`, { params });
  }
);
export const getFolder = getThunkActionCreator(
  getFolderRoutine,
  async id => {
    const attributes = ['id', 'ancestor_folders', { parent_folder: ['id', 'name'] }, 'ancestry_depth', 'name', 'documents_count', 'documents_byte_size'];
    const params = { attributes };

    return await axios.get(`/folders/${id}`, { params });
  }
);
export const getActualFolderData = getThunkActionCreator(
  getActualFolderDataRoutine,
  async id => {
    const attributes = ['id', 'ancestor_folders', { parent_folder: ['id', 'name'] }, 'ancestry_depth', 'name', 'documents_count', 'documents_byte_size'];
    const params = { attributes };

    return await axios.get(`/folders/${id}`, { params });
  }
);
export const createFolder = getThunkActionCreator(
  createFolderRoutine,
  async ({ folder_id, original_folder_id = null, folder = null, initial }) => {
    const attributes = ['id', 'ancestor_folders', 'locked', 'approval_required', { parent_folder: ['id', 'name'] }, { folder: ['id'] }, 'ancestry_depth', 'name', 'documents_count', 'documents_byte_size'];
    const data = { attributes, ...original_folder_id && { original_folder_id }, ...folder && { folder } };
    const response = await axios.post(`/folders/${folder_id}/folders`, data);

    return { response, initial };
  }
);
export const updateFolder = getThunkActionCreator(
  updateFolderRoutine,
  async ({ id, ...folder }) => {
    const data = { folder };
    const response = await axios.patch(`/folders/${id}`, data);

    return { response, folder: { id, ...folder } };
  }
);
export const deleteFolder = getThunkActionCreator(
  deleteFolderRoutine,
  async id => {
    const response = await axios.delete(`/folders/${id}`);

    return { response, id };
  }
);
export const clearLocalOfferFolders = getThunkActionCreator(clearLocalOfferFoldersRoutine, async () => {});
export const clearLocalFolders = getThunkActionCreator(clearLocalFoldersRoutine, async () => {});
export const clearLocalFolder = getThunkActionCreator(clearLocalFolderRoutine, async () => {});
export const setUploadingItem = getThunkActionCreator(setUploadingItemRoutine, async item => ({ item }));
export const removeUploadingItem = getThunkActionCreator(removeUploadingItemRoutine, async id => ({ id }));
export const hideUploadingItems = getThunkActionCreator(hideUploadingItemsRoutine, async () => {});
export const clearUploadingItems = getThunkActionCreator(clearUploadingItemsRoutine, async () => {});