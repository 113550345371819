import SvgIcon from '@mui/material/SvgIcon';

export default function ChatIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.8668 10.47C15.3325 10.47 14.9001 10.9024 14.9001 11.4367C14.9001 11.971 15.3342 12.4034 15.8668 12.4034C16.3994 12.4034 16.8317 11.971 16.8335 11.4367C16.8335 10.9024 16.3994 10.47 15.8668 10.47ZM8.13318 10.47C7.59885 10.47 7.16647 10.9024 7.16647 11.4367C7.16647 11.971 7.60061 12.4034 8.13318 12.4034C8.6675 12.4034 9.09988 11.971 9.09988 11.4367C9.09988 10.9024 8.6675 10.47 8.13318 10.47ZM11.0334 11.4367C11.0334 10.9024 11.4658 10.47 12.0001 10.47C12.5327 10.47 12.9668 10.9024 12.9668 11.4367C12.9668 11.971 12.5327 12.4034 12.0001 12.4034C11.4676 12.4034 11.0334 11.971 11.0334 11.4367Z" />
      <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M18.3469 5.45191C19.1782 6.22527 19.8303 7.12694 20.2856 8.13055C20.7584 9.17459 20.9992 10.2837 20.9974 11.4279C20.9974 12.5721 20.7584 13.6794 20.2838 14.7235C19.8268 15.7288 19.1747 16.6287 18.3451 17.4021C17.519 18.172 16.5576 18.7766 15.4872 19.1984C14.3816 19.6326 13.2075 19.854 11.9983 19.854C11.0368 19.854 10.093 19.7134 9.18957 19.4357L6.58299 20.9174C6.48632 20.9719 6.37734 21 6.27013 21C6.17346 21 6.07503 20.9771 5.98539 20.9315C5.79556 20.8365 5.66726 20.652 5.64265 20.4411L5.23663 16.9855C3.79361 15.4459 3.00092 13.4808 3.00092 11.4261C3.00092 10.2837 3.24171 9.17459 3.71452 8.13055C4.1715 7.12518 4.82359 6.22527 5.6532 5.45191C6.47929 4.68206 7.44072 4.07743 8.51112 3.6556C9.61668 3.22146 10.7908 3 12 3C13.2093 3 14.3834 3.22146 15.489 3.6556C16.5594 4.07743 17.5208 4.68206 18.3469 5.45191ZM17.4838 16.4741C18.1921 15.815 18.7458 15.0486 19.1325 14.1979C19.5315 13.3191 19.7336 12.3876 19.7353 11.4261C19.7353 10.4647 19.5332 9.53315 19.1342 8.65433C18.7475 7.80187 18.1921 7.0373 17.4856 6.37818C16.7755 5.71555 15.9476 5.19705 15.0249 4.83321C14.0669 4.45708 13.0493 4.2655 12 4.2655C10.9506 4.2655 9.93297 4.45708 8.97682 4.83321C8.05406 5.19705 7.22621 5.71731 6.51612 6.37818C5.80779 7.0373 5.25413 7.80363 4.86745 8.65433C4.46847 9.53315 4.26634 10.4647 4.26634 11.4261C4.26634 13.2172 4.98521 14.9326 6.28938 16.2544C6.38781 16.3545 6.45109 16.4846 6.46691 16.6252L6.7868 19.3443L8.80633 18.1966C8.96276 18.1069 9.15082 18.0893 9.32307 18.1473C10.1773 18.4391 11.079 18.5868 11.9982 18.5868C13.0475 18.5868 14.0652 18.3969 15.0231 18.019C15.9459 17.6552 16.7737 17.1349 17.4838 16.4741Z" />
    </SvgIcon>
  );
}

