import axios from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getShortProfileRoutine,
  getFullProfileRoutine,
  setLocalUserCountsRoutine,
  unsubscribeRoutine,
  updateProfileRoutine,
  changeProfilePasswordRoutine,
  clearLocalProfileRoutine
} from 'actions';
import _omit from 'lodash/omit';

const recipient_attributes = [
  'id',
  'email',
  'first_name',
  'last_name',
  { user: ['first_name', 'last_name', 'email'] },
  { offer: ['id', 'deal_type', { primary_sender: [{ user: ['first_name', 'last_name', { photo: ['representations'] } ]} ] }] },
  'created_at'
];
const shortAttributes = [
  'id',
  'username',
  { photo: ['id', 'representations'] },
  'email',
  'first_name',
  'last_name',
  'unread_general_conversations_count',
  'unread_feed_conversations_count',
  'unvisited_offers_count',
  'total_contacts_count',
  'member_contacts_count',
  'pending_contacts_count',
  'quarantined_contacts_count',
  'nylas_grant_id',
  'created_at'
];
const fullAttributes = [
  ...shortAttributes,
  { header: ['id', 'url'] },
  { contents: ['id', { file: ['id', 'representations', 'filename', 'url', 'content_type'] }, { avatar: ['id', 'representations', 'filename'] }, 'content_type', 'title', 'published_at', 'url'] },
  { teammates: ['id', { user: ['id', 'first_name', 'username', 'last_name', { photo: ['representations'] }]}, { invitation: ['email', 'message'] }] },
  { geographic_focuses: ['id', 'address', 'latitude', 'longitude'] },
  'headline',
  'title',
  'company_name',
  'location',
  'latitude',
  'longitude',
  'about',
  'mobile_phone',
  'office_phone',
  'office_phone_extension',
  'linkedin_profile_url',
  'website_url',
  'primary_activities',
  'risk_profiles',
  'asset_classes',
  'public_active_offers_count'
];

export const getShortProfile = getThunkActionCreator(
  getShortProfileRoutine,
  async () => {
    const { userableType } = await sessionService.loadSession().catch(e => console.warn(e));
    const params = { attributes: userableType === 'user' ? shortAttributes : recipient_attributes };

    return await axios.get('/profile', { params });
  }
);
export const getFullProfile = getThunkActionCreator(
  getFullProfileRoutine,
  async () => {
    const params = { attributes: fullAttributes };

    return await axios.get('/profile', { params });
  }
);
export const unsubscribe = getThunkActionCreator(
  unsubscribeRoutine,
  async recipient_token => {
    return await axios.post('/profile/unsubscribe', { recipient_token });
  }
);
export const updateProfile = getThunkActionCreator(
  updateProfileRoutine,
  async user => {
    const data = { user: _omit(user, ['localPhoto', 'localHeader', 'localContents', 'localTeammates']) };
    const response = await axios.patch('/profile', data);
    
    return { response, user };
  }
);
export const setLocalUserCounts = getThunkActionCreator(setLocalUserCountsRoutine, async counts => counts);
export const changeProfilePassword = getThunkActionCreator(
  changeProfilePasswordRoutine,
  async ({ current_password, password }) => {
    return await axios.post('/profile/change_password', { current_password, password });
  }
);
export const clearLocalProfile = getThunkActionCreator(clearLocalProfileRoutine, async () => {});