import { memo } from 'react';
import Paper from '@mui/material/Paper';
import ButtonBase from '@mui/material/ButtonBase';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
// Local files
import { Banner, Main, avatarStyles, Title, Link, Item, Content, Tag, Label } from './Widget.styled';
import image from 'assets/images/banners/user-banner-default.jpg';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import useCustomSelector from 'hooks/useCustomSelector';
import useContacts from 'hooks/useContacts';
import useApp from 'hooks/useApp';
import { getRepresentation } from 'helpers';
import { representationVariants, chatLevels } from 'helpers/constants';

const ProfileWidget = () => {
  const navigate = useNavigate();
  const previousLocation = useLocation();
  const { setChatsFilter } = useApp();
  const { setContactsFilters } = useContacts();
  const { id, username, first_name, last_name, header, photo, unvisited_offers_count, pending_contacts_count } = useCustomSelector(state => state.profile.user);
  const { filter, unreadMessagesCount, invitations } = useCustomSelector(state => {
    const filter = state.app.chats.filter;
    const unreadGeneralConversationsCount = state.profile.user.unread_general_conversations_count;
    const unreadFeedConversationsCount = state.profile.user.unread_feed_conversations_count;
    const hasGroupUnread = _reduce(_map(state.groups.all.data, ({ unread_dealspaces_due_offer_conversations_count, unread_dealspaces_due_dealspace_conversations_count }) => 
    unread_dealspaces_due_offer_conversations_count + unread_dealspaces_due_dealspace_conversations_count), (sum, n) => sum + n, 0);
    const unreadMessagesCount = unreadGeneralConversationsCount + unreadFeedConversationsCount + hasGroupUnread;
    const invitations = state.invitations.all.pagination.total_count

    return { filter, unreadMessagesCount, invitations };
  });

  const handleOpenChat = () => setChatsFilter({ filter }).then(() => navigate(`/chats/${chatLevels.USER}/${id}`, { state: { previousLocation } }));
  const handleOpenRequests = () => setContactsFilters({ tab: 'pending', pending: true }).then(() => navigate('/contacts'));

  return (
    <Paper variant='outlined' sx={{ overflow: 'hidden' }}>
      <Banner sx={{ backgroundImage: `url('${header?.url ?? image}')` }} />
      <Main>
        <BaseAvatar
          src={getRepresentation(photo?.representations, representationVariants.TINY)}
          firstName={first_name}
          lastName={last_name}
          sx={avatarStyles}
        />
        <Title>Welcome, {first_name}</Title>
        <Link component={NavLink} to={`/users/${username}`}>My profile</Link>
      </Main>
      <Content>
        {!!unvisited_offers_count && <Item component={NavLink} to={'/deal_flow'}><Tag label={unvisited_offers_count} /><Label>Unseen dealflow</Label></Item>}
        {!!unreadMessagesCount && <Item component={ButtonBase} onClick={handleOpenChat}><Tag label={unreadMessagesCount} /><Label>New messages</Label></Item>}
        {!!pending_contacts_count && <Item component={ButtonBase} onClick={handleOpenRequests}><Tag label={pending_contacts_count} /><Label>Pending requests</Label></Item>}
        {!!invitations && <Item><Tag label={invitations} /><Label>Invitations to collaborate</Label></Item>}
      </Content>
    </Paper>
  );
};

export default memo(ProfileWidget);