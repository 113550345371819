import { memo } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import PropTypes from 'prop-types';
// Local files
import { StyledToggleButtonGroup, CustomBadge } from './HorizontalFIlters.styled';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useCustomSelector from 'hooks/useCustomSelector';

/**
 * @param {string} filter enum ['sharedWithMe', 'archive', 'myShared', 'market']
 * @param {string} value enum ['all', 'unseen', 'tracking', 'deadlines']
 * @param {function} onChange
 * @returns {node}
 */

const HorizontalFilters = ({ filter, value, onChange }) => {
  const { hasRecipientSession, all, unseen, tracking, deadlines, fetching } = useCustomSelector(state => ({
    hasRecipientSession: !!state.profile.recipient.id,
    ...state.offers.dealflow.amount[filter]
  }));
  const showUnseen = !hasRecipientSession && filter !== 'archive' && filter !== 'myShared';
  const showTracking = !hasRecipientSession && filter !== 'myShared';
  const showDeadlines = !hasRecipientSession && filter !== 'myShared';

  if (fetching) {
    return (
      <StyledToggleButtonGroup
        disabled
        size='small'
        exclusive
        value={value}
        onChange={(_, v) => onChange(v)}
      >
        <ToggleButton value='all'>
          all
        </ToggleButton>
        {showUnseen &&
          <ToggleButton value='unseen'>
            unseen
          </ToggleButton>
        }
        {showTracking &&
          <ToggleButton value='tracking'>
            tracking
          </ToggleButton>
        }
        {showDeadlines &&
          <ToggleButton value='deadlines'>
            deadlines
          </ToggleButton>
        }
      </StyledToggleButtonGroup>
    );
  }
  return (
    <StyledToggleButtonGroup
      size='small'
      exclusive
      value={value}
      onChange={(_, v) => onChange(v)}
    >
      <ToggleButton value='all' disabled={value === 'all'}>
        all&nbsp;<span>{all > 0 ? all : ''}</span>
      </ToggleButton>
      {showUnseen &&
        <ToggleButton value='unseen' disabled={value === 'unseen'}>
          unseen&nbsp;<CustomBadge style={{ display: unseen > 0 ? 'block' : 'none', backgroundColor: 'rgba(18, 163, 59, 1)' }}>{unseen}</CustomBadge>
        </ToggleButton>
      }
      {showTracking &&
        <ToggleButton value='tracking' disabled={value === 'tracking'}>
          tracking&nbsp;<span>{tracking > 0 ? tracking : ''}</span>
        </ToggleButton>
      }
      {showDeadlines &&
        <ToggleButton value='deadlines' disabled={value === 'deadlines'}>
          deadlines&nbsp;<CustomBadge style={{ display: deadlines > 0 ? 'block' : 'none', backgroundColor: 'rgba(228, 3, 3, 1)' }}>{deadlines}</CustomBadge>
        </ToggleButton>
      }
    </StyledToggleButtonGroup>
  );
};

HorizontalFilters.propTypes = {
  filter: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <HorizontalFilters {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);