import { instance as axios } from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { sendConfirmationCodeRoutine, validateConfirmationCodeRoutine } from 'actions';

export const sendConfirmationCode = getThunkActionCreator(
  sendConfirmationCodeRoutine,
  async email => {
    const data = { email };

    return await axios.post('/user/confirmations', data);
  }
);
export const validateConfirmationCode = getThunkActionCreator(
  validateConfirmationCodeRoutine,
  async ({ email, confirmation_code }) => {
    const data = { email, confirmation_code };

    return await axios.post('/user/confirmations/validate', data);
  }
);