import { memo, useEffect, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import _map from 'lodash/map';
import _snakeCase from 'lodash/snakeCase';
// Local files
import { Root, Statuses, StatusWrapper, Title, OffersContainer } from './Widget.styled';
import Item from '../Item/Item';
import OffersWidgetSkeleton from 'components/Offers/Widget/Skeleton/Skeleton';
import Status from 'components/Offers/Status/Status';
import useCustomSelector from 'hooks/useCustomSelector';
import useOffers from 'hooks/useOffers';
import useError from 'hooks/useError';

const statuses = ['draft', 'active', 'inContract', 'ended', 'closed'];

const OffersWidget = () => {
  const { getSharedOffers, getDraftSharedOffers, getActiveSharedOffers, getEndedSharedOffers, getClosedSharedOffers, getInContractSharedOffers, clearLocalSharedOffers } = useOffers();
  const { setError } = useError();
  const [offeringsType, setOfferingsType] = useState(null);
  const { offerings, fetching, total, amounts, hasMoreOfferings, offeringsOffset } = useCustomSelector(state => {
    const offerings = state.offers.shared[offeringsType ?? 'all'].data;
    const fetching = state.offers.shared[offeringsType ?? 'all'].fetching;
    const total = state.offers.shared.all.pagination.total_count;
    const draft = state.offers.shared.draft.pagination.total_count;
    const active = state.offers.shared.active.pagination.total_count;
    const inContract = state.offers.shared.inContract.pagination.total_count;
    const ended = state.offers.shared.ended.pagination.total_count;
    const closed = state.offers.shared.closed.pagination.total_count;
    const amounts = { draft, active, inContract, ended, closed };
    const hasMoreOfferings = state.offers.shared[offeringsType ?? 'all'].pagination.total_count > state.offers.shared[offeringsType ?? 'all'].pagination.count + state.offers.shared[offeringsType ?? 'all'].pagination.offset;
    const offeringsOffset = state.offers.shared[offeringsType ?? 'all'].pagination.count + state.offers.shared[offeringsType ?? 'all'].pagination.offset;    

    return { offerings, fetching, total, amounts, hasMoreOfferings, offeringsOffset };
  });

  const handleStatusClick = v => setOfferingsType(prev => prev === v ? null : v);
  const fetchAllOfferings = useCallback(({ offset }) => getSharedOffers({ offset }).catch(e => setError(e)), [getSharedOffers, setError]);
  const fetchDraftOfferings = useCallback(({ offset }) => getDraftSharedOffers({ offset }).catch(e => setError(e)), [getDraftSharedOffers, setError]);
  const fetchActiveOfferings = useCallback(({ offset }) => getActiveSharedOffers({ offset }).catch(e => setError(e)), [getActiveSharedOffers, setError]);
  const fetchEndedOfferings = useCallback(({ offset }) => getEndedSharedOffers({ offset }).catch(e => setError(e)), [getEndedSharedOffers, setError]);
  const fetchClosedOfferings = useCallback(({ offset }) => getClosedSharedOffers({ offset }).catch(e => setError(e)), [getClosedSharedOffers, setError]);
  const fetchInContractOfferings = useCallback(({ offset }) => getInContractSharedOffers({ offset }).catch(e => setError(e)), [getInContractSharedOffers, setError]);
  const fetchOfferings = useCallback(({ type, offset }) => {
    if (!type) fetchAllOfferings({ offset });
    if (type === 'draft') fetchDraftOfferings({ offset });
    if (type === 'active') fetchActiveOfferings({ offset });
    if (type === 'ended') fetchEndedOfferings({ offset });
    if (type === 'closed') fetchClosedOfferings({ offset });
    if (type === 'inContract') fetchInContractOfferings({ offset });
  }, [fetchAllOfferings, fetchActiveOfferings, fetchClosedOfferings, fetchDraftOfferings, fetchEndedOfferings, fetchInContractOfferings]);
  const handleLoadMoreOfferings = () => {
    fetchOfferings({ type: offeringsType, offset: offeringsOffset });
  };

  useEffect(() => {
    fetchOfferings({ type: null, offset: 0 });
    fetchOfferings({ type: 'draft', offset: 0 });
    fetchOfferings({ type: 'active', offset: 0 });
    fetchOfferings({ type: 'ended', offset: 0 });
    fetchOfferings({ type: 'closed', offset: 0 });
    fetchOfferings({ type: 'inContract', offset: 0 });

    return () => clearLocalSharedOffers('all');
  }, [fetchOfferings, clearLocalSharedOffers]);

  return (
    <Root variant='outlined' sx={{ p: '35px 28px 40px' }}>
      <Title>Shared Offerings <span>{total >= 0 ? total : ''}</span></Title>
      <>
        <Statuses>
          {_map(statuses, s => {
            if (amounts[s] > 0) {
              return (
                <StatusWrapper key={s} selected={offeringsType === s} onClick={() => handleStatusClick(s)}>
                  <Status value={_snakeCase(s)} /><span>{amounts[s]}</span>
                </StatusWrapper>    
              );
            }
            return null;
          })}
        </Statuses>
        <OffersContainer disableGutters>
          {_map(offerings, (o, i) => <Item key={i} {...o} locationType='dashboard' />)}
          {fetching && <OffersWidgetSkeleton />}
        </OffersContainer>
        {hasMoreOfferings &&
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Button
              disabled={fetching}
              onClick={handleLoadMoreOfferings}
              variant='outlined'
            >
              Show More
            </Button>
          </Box>
        }
      </>
    </Root>
  );
};

export default memo(OffersWidget);