import { memo, useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';
//Local files
import { ButtonContainer, Root, StartChatButton } from './ChangeConversation.styled';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import useBatch from 'hooks/useBatch';
import useConversations from 'hooks/useConversations';
import useMessages from 'hooks/useMessages';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import { getRepresentation } from 'helpers';
import { representationVariants } from 'helpers/constants';

const ChangeConversation = ({ senders, user_ids, conversations, conversation, my_id, onShowMessages }) => {
  const { sendBatch } = useBatch();
  const { setLocalUserIds, setLocalConversation } = useConversations();
  const { clearLocalMessages } = useMessages();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const [processing, setProcessing] = useState(false);

  const handleChangeChecked = (user_id) => {
    setLocalUserIds(!!_find(user_ids, (c) => c === user_id) ? _filter(user_ids, (c) => c !== user_id) : [...user_ids, user_id])
  };
  const handleChange = () => {
    const foundedConversation = _find(conversations, ({ users }) => _isEqual(_map(_filter(users, u => u.id !== my_id), (user) => user.id).sort(), user_ids?.sort()));

    if (!!foundedConversation) {
      setLocalConversation(foundedConversation).then(() => {
        setSuccess('You already have the chat with this user/s')
        setLocalUserIds(null);
      });
    } else {
      if (!!conversation.id) {
        const users = _filter(conversation.users, (u) => u.id !== my_id);
        const usersDelete = _map(users, (user) => !!_find(user_ids, (u) => user.id === u) ? user : { ...user, _destroy: true });
        const usersPost = _map(senders, (sender) => (!!_find(user_ids, (u) => sender.id === u) && !!!_find(usersDelete, (u) => sender.id === u.id) && !!!_find(users, (u) => sender.id === u.id)) ? { ...sender, _new: true } : sender);
        const usersDifference = _filter([...usersDelete, ...usersPost], (user) => user._destroy || user._new);
        const requests = _map(usersDifference, (user) => ({
          method: user._destroy ? 'delete' : 'post',
          url: `/conversations/${conversation.id}/users/${user.id}`,
          params: {}
        }));

        setProcessing(true);
        sendBatch(requests)
        .then(() => setLocalConversation({ 
          ...conversation, 
          users: [..._filter(conversation.users, (u) => !!!_find(usersDifference, (user) => u.id === user.id && user._destroy)), ..._filter(senders, (sender) => !!_find(usersDifference, (user) => sender.id === user.id && user._new))]
          }).then(() => setLocalUserIds(null))
        )
        .catch(e => setError(e))
        .finally(() => setProcessing(false));
      } else {
        clearLocalMessages().then(() => onShowMessages(true));
      }
    }
  };

  return (
    <Root blurred={!!conversation.id}>
      <List disablePadding sx={{ flexGrow: 1, overflow: 'auto', }}>
        {_map(senders, (user) => (
          <ListItem key={user.id} disablePadding>
            <ListItemButton disablePadding sx={{ py: '0' }} onClick={() => handleChangeChecked(user.id)}>
              <Checkbox checked={user_ids?.indexOf(user.id) !== -1} color='secondary' sx={{ ml: '-8px' }} />
              <BaseAvatar
                key={user?.id}
                src={getRepresentation(user?.photo?.representations, representationVariants.TINY)}
                firstName={user?.first_name}
                lastName={user?.last_name}
                sx={{ width: 24, height: 24, fontSize: '10px' }}
              />
              <Typography variant='body2' sx={{ pl: 1.5 }}>{user.first_name} {user.last_name}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <ButtonContainer>
        <StartChatButton startIcon={<ChatIcon />} onClick={handleChange} disabled={processing || !!!user_ids?.length}>{!!conversation.id ? 'Save' : 'Start Chat'}</StartChatButton>
      </ButtonContainer>
    </Root>
  )
}

export default memo(ChangeConversation);