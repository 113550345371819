import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  getPropertyDocuments as getPropertyDocumentsAction,
  getDealspaceDocuments as getDealspaceDocumentsAction,
  getFolderDocuments as getFolderDocumentsAction,
  getBuilderPublicDocuments as getBuilderPublicDocumentsAction,
  getBuilderPrivateLevel1Documents as getBuilderPrivateLevel1DocumentsAction,
  getBuilderPrivateLevel2Documents as getBuilderPrivateLevel2DocumentsAction,
  getOfferPublicDocuments as getOfferPublicDocumentsAction,
  getOfferPrivateLevel1Documents as getOfferPrivateLevel1DocumentsAction,
  getOfferPrivateLevel2Documents as getOfferPrivateLevel2DocumentsAction,
  getPreviewPublicDocuments as getPreviewPublicDocumentsAction,
  getDocument as getDocumentAction,
  createDealspaceDocument as createDealspaceDocumentAction,
  createOfferDocument as createOfferDocumentAction,
  updateDocument as updateDocumentAction,
  deleteDocument as deleteDocumentAction,
  clearLocalPropertyDocuments as clearLocalPropertyDocumentsAction,
  clearLocalDealspaceDocuments as clearLocalDealspaceDocumentsAction,
  clearLocalFolderDocuments as clearLocalFolderDocumentsAction,
  clearLocalOfferDocuments as clearLocalOfferDocumentsAction,
  clearLocalActiveDocument as clearLocalActiveDocumentAction
} from 'actions/documents';
import useError from './useError';

const useDocuments = () => {
  const controller = useMemo(() => new AbortController(), []);
  const dispatch = useDispatch();
  const getPropertyDocuments = useCallback(({ offset, property_id }) => dispatch(getPropertyDocumentsAction({ offset, property_id })), [dispatch]);
  const getDealspaceDocuments = useCallback(({ offset, folder_id }) => dispatch(getDealspaceDocumentsAction({ offset, folder_id })), [dispatch]);
  const getFolderDocuments = useCallback(({ offset, folder_id }) => dispatch(getFolderDocumentsAction({ offset, folder_id })), [dispatch]);
  const getBuilderPublicDocuments = useCallback(({ folder_id, offset }) => dispatch(getBuilderPublicDocumentsAction({ folder_id, offset })), [dispatch]);
  const getBuilderPrivateLevel1Documents = useCallback(({ folder_id, offset }) => dispatch(getBuilderPrivateLevel1DocumentsAction({ folder_id, offset })), [dispatch]);
  const getBuilderPrivateLevel2Documents = useCallback(({ folder_id, offset }) => dispatch(getBuilderPrivateLevel2DocumentsAction({ folder_id, offset })), [dispatch]);
  const getOfferPublicDocuments = useCallback(({ folder_id, offset }) => dispatch(getOfferPublicDocumentsAction({ folder_id, offset })), [dispatch]);
  const getOfferPrivateLevel1Documents = useCallback(({ folder_id, offset }) => dispatch(getOfferPrivateLevel1DocumentsAction({ folder_id, offset })), [dispatch]);
  const getOfferPrivateLevel2Documents = useCallback(({ folder_id, offset }) => dispatch(getOfferPrivateLevel2DocumentsAction({ folder_id, offset })), [dispatch]);
  const getPreviewPublicDocuments = useCallback(({ folder_id, offset }) => dispatch(getPreviewPublicDocumentsAction({ folder_id, offset })), [dispatch]);
  const getDocument = useCallback(id => dispatch(getDocumentAction(id)), [dispatch]);
  const createDealspaceDocument = useCallback(({ folder_id, document, skip }) => dispatch(createDealspaceDocumentAction({ folder_id, document, skip, controller })), [dispatch, controller]);
  const createOfferDocument = useCallback(({ folder_id, document_id, document }) => dispatch(createOfferDocumentAction({ folder_id, document, document_id, controller })), [dispatch, controller]);
  const updateDocument = useCallback(document => dispatch(updateDocumentAction(document)), [dispatch]);
  const deleteDocument = useCallback(id => dispatch(deleteDocumentAction(id)), [dispatch]);
  const clearLocalPropertyDocuments = useCallback(() => dispatch(clearLocalPropertyDocumentsAction()), [dispatch]);
  const clearLocalDealspaceDocuments = useCallback(() => dispatch(clearLocalDealspaceDocumentsAction()), [dispatch]);
  const clearLocalFolderDocuments = useCallback(() => dispatch(clearLocalFolderDocumentsAction()), [dispatch]);
  const clearLocalOfferDocuments = useCallback(() => dispatch(clearLocalOfferDocumentsAction()), [dispatch]);
  const clearLocalActiveDocument = useCallback(() => dispatch(clearLocalActiveDocumentAction()), [dispatch]);
  const { setError } = useError();

  const fetchDocument = useCallback(id => getDocument(id).catch(e => setError(e)), [getDocument, setError]);

  return {
    createOfferDocument,
    createDealspaceDocument,
    getDealspaceDocuments,
    getFolderDocuments,
    getPropertyDocuments,
    getBuilderPublicDocuments,
    getBuilderPrivateLevel1Documents,
    getBuilderPrivateLevel2Documents,
    getOfferPublicDocuments,
    getOfferPrivateLevel1Documents,
    getOfferPrivateLevel2Documents,
    getPreviewPublicDocuments,
    fetchDocument,
    updateDocument,
    deleteDocument,
    clearLocalDealspaceDocuments,
    clearLocalFolderDocuments,
    clearLocalPropertyDocuments,
    clearLocalOfferDocuments,
    clearLocalActiveDocument
  };
};

export default useDocuments;