import {
  getBlobRoutine,
  createBlobRoutine
} from 'actions';

export const initialState = {
  blob: { id: '', representations: [] }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getBlobRoutine.SUCCESS: {
      const { data: { blob } } = action.payload;

      return { ...state, blob: { ...state.blob, ...blob } };
    }
    case createBlobRoutine.SUCCESS: {
      const { data: { blob } } = action.payload;
    
      return { ...state, blob: { ...state.blob, ...blob } };
    }
    default: {
      return state;
    }
  };
};

export default reducer;