import { memo } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
// Local files
import { StyledTextField, Text } from './Name.styled';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const Name = ({ editButton, edit, value, onChange, onBlur }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {!edit ?
        <Text>{value}</Text> :
        <StyledTextField
          fullWidth
          variant="standard"
          value={value}
          onChange={({ target: { value } }) => onChange(value)}
          onBlur={({ target: { value } }) => onBlur(value)}
        />
      }
      {editButton}
    </Box>
  );
};

Name.propTypes = {
  editButton: PropTypes.node.isRequired,
  edit: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Name {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);