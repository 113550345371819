import { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Empty = () => {
  return (
    <Box height='100%' display='flex' justifyContent='center' alignItems='center'>
      <Typography variant='h5'>Please select or create a group</Typography>
    </Box>
  );
};

export default memo(Empty);