import { lookupIpRoutine } from 'actions';

export const initialState = {
  lookup: { latitude: null, longitude: null },
  requested: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case lookupIpRoutine.SUCCESS: {
      const { data: { lookup } } = action.payload;
      
      return { ...state, lookup, requested: true };
    }
    default: {
      return state;
    }
  };
};

export default reducer;