import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  gap: '8px',
  [theme.breakpoints.up('sm')]: {
    flexWrap: 'nowrap'
  }
}));

export const Title = styled(Typography)(({ theme }) => ({
  flexBasis: '100%',
  color: theme.palette.primary.main,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 600,
  [theme.breakpoints.up('sm')]: {
    flexBasis: '200px'
  }
}));