import { memo } from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend';
import { DndProvider, useDrop } from 'react-dnd';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const DropContainer = ({ onDrop, children, sx, ...otherProps }) => {
  const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop: v => onDrop(v),
    canDrop: ({ files }) => {
      let allowDrop = true;

      // TODO
      // _forEach(files, f => {
      //   if (!!_find(allowTypes, at => at === f.type)) {
      //     allowDrop = true;
      //   }
      // });

      return allowDrop;
    },
    collect: m => ({ isOver: m.isOver(), canDrop: m.canDrop() })
  }));
  const isActive = canDrop && isOver;

  return (
    <Box
      ref={dropRef}
      sx={{
        backgroundColor: isActive ? '#F8F8F9' : 'transparent',
        boxShadow: isActive ? '0 0 0 2px #F0F0F0' : 'unset',
        borderRadius: '6px',
        ...sx
      }}
      {...otherProps}
    >
      {children}
      <Snackbar
        open={isActive}
        message="Drop files to upload them"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Box>
  );
};

const WrapperComponent = props => {
  return (
    <ErrorWrapper>
      <DndProvider backend={HTML5Backend}>
        <DropContainer {...props} />
      </DndProvider>
    </ErrorWrapper>
  );
};

export default memo(WrapperComponent);