import { memo } from 'react';
import Badge from '@mui/material/Badge';
import PropTypes from 'prop-types';
// Local files
import EditIcon from 'components/Common/Icons/EditIcons';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import { Root, StyledButton } from './Avatar.styled';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import useApp from 'hooks/useApp';

const avatarStyles = {
  width: 131,
  height: 131,
  borderRadius: '50%',
  fontSize: '56px'
};

const Avatar = ({ user, edit }) => {
  const { openAvatarDialog } = useApp();
  
  return (
    <Root>
      <Badge
        badgeContent={<StyledButton onClick={openAvatarDialog}><EditIcon /></StyledButton>}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        overlap='circular'
        invisible={!edit}
      >
        <BaseAvatar
          src={getRepresentation(user.photo?.representations, representationVariants.TINY) ?? user.photo?.representations[0]?.url}
          firstName={user.first_name}
          lastName={user.last_name}
          sx={avatarStyles}
        />
      </Badge>
    </Root>
  );
};

Avatar.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    photo: PropTypes.object
  }),
  edit: PropTypes.bool.isRequired
};

export default memo(Avatar);