import { memo, useEffect, useState } from 'react';
//Local files
import { ClaimButton, Root, Text, Title } from './InvitedUserPopover.styled';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';

const InvitedUserPopover = () => {
  const { setAuthData } = useApp();
  const { firstName, number, open } = useCustomSelector(state => ({
    firstName: state.profile.recipient.first_name,
    number: state.offers.dealflow.amount.sharedWithMe.all,
    open: state.app.invitedUserPopover.open
  }));
  const [showPopover, setShowPopover] = useState(false);

  const handleClaim = () => setAuthData({ open: true, mode: 'signUp' });

  useEffect(() => {
    let timeoutId = null;

    if (!!firstName && number > 1 && open) {
      timeoutId = setTimeout(() => {
        setShowPopover(true);
      }, 2000);
    }

    return () => clearTimeout(timeoutId);
  }, [firstName, number, open]);

  if (!showPopover || number < 2 || !firstName) return null;
  return (
    <Root>
      <Title>
        Hey {firstName},
      </Title>
      <Text>
        <b>{number}</b> opportunities have been shared to your private deal feed in Qwincy.
      </Text>
      <Text>
        <ClaimButton onClick={handleClaim}>Claim your feed</ClaimButton> to see all deals shared with you.
      </Text>
    </Root>
  );
};

export default memo(InvitedUserPopover);