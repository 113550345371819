import { memo } from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
// Local files
import { ReactComponent as AttachIcon } from 'assets/icons/attach.svg';

/**
 * @param {string} title
 * @param {string} description
 * @param {string} link
 * @returns {node}
 */

const LinkForm = ({ title, description, link }) => {
  return (
    <Box sx={{ width: 'min(430px, 100%)' }}>
      <Typography variant='caption' component='div' sx={{ fontWeight: 600 }}>{title}</Typography>
      <Typography variant='small' component='div'>{description}</Typography>
      <Box sx={{ width: '100%', display: 'flex', gap: '5px', pt: 1 }}>
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'hidden',
            backgroundColor: 'background.default',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: '6px',
            padding: '9px'
          }}
        >
          <Typography variant='small' noWrap sx={{ display: 'block', color: 'rgba(0,0,0,.6)', lineHeight: 1 }}>{link}</Typography>
        </Box>
        <ButtonBase
          sx={{
            flexShrink: 0,
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: '6px',
            padding: '9px',
          }}
          onClick={() => navigator.clipboard.writeText(link)}
        >
          <AttachIcon style={{ height: 10, width: 10 }} />
          <Typography variant='small' component='div'>Copy Link</Typography>
          </ButtonBase>
      </Box>
    </Box>
  );
};

LinkForm.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default memo(LinkForm);