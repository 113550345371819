import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getTemplatesRoutine,
  getTemplateRoutine,
  createTemplateRoutine,
  updateTemplateRoutine,
  deleteTemplateRoutine,
  clearLocalTemplatesRoutine,
  clearLocalActiveTemplateRoutine,
  setConfiguredTemplateRoutine
} from 'actions';

const attributes = ['id', 'name', 'boldsign_template_url', { file: ['url', 'content_type'] }, 'updated_at'];

export const getTemplates = getThunkActionCreator(
  getTemplatesRoutine,
  async ({ offer_id, limit = 25, offset = 0, orders = { updated_at: 'desc' }, query = '' }) => {
    const params = { limit, offset, orders, attributes, ...(!!query && { query }) };

    return await axios.get(`/offers/${offer_id}/templates`, { params });
  }
);
export const getTemplate = getThunkActionCreator(
  getTemplateRoutine,
  async id => {
    const params = { attributes };

    return await axios.get(`/templates/${id}`, { params });
  }
);
export const createTemplate = getThunkActionCreator(
  createTemplateRoutine,
  async ({ offer_id, document_id, template }) => {
    const data = {
      ...document_id && { document_id },
      ...template && { template },
      attributes
    };

    return await axios.post(`/offers/${offer_id}/templates`, data);
  }
);
export const updateTemplate = getThunkActionCreator(
  updateTemplateRoutine,
  async ({ id, ...template }) => {
    const data = { template };
    const response = await axios.patch(`/templates/${id}`, data);

    return { response, template: { id, ...template } };
  }
);
export const deleteTemplate = getThunkActionCreator(
  deleteTemplateRoutine,
  async id => {
    const response = await axios.delete(`/templates/${id}`);

    return { response, id };
  }
);
export const clearLocalTemplates = getThunkActionCreator(clearLocalTemplatesRoutine, async () => {});
export const clearLocalActiveTemplate = getThunkActionCreator(clearLocalActiveTemplateRoutine, async () => {});
export const setConfiguredTemplate = getThunkActionCreator(setConfiguredTemplateRoutine, async id => ({ id }));