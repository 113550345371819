import { memo } from 'react';
import InputBase from '@mui/material/InputBase';
import PropTypes from 'prop-types';
// Local files
import SearchIcon from 'components/Common/Icons/Search';

const Search = ({ placeholder, value, onChange, fullWidth = false, disabled = false, sx }) => {
  return (
    <InputBase
      disabled={disabled}
      startAdornment={<SearchIcon sx={{ color: 'text.secondary', fontSize: '1.5rem' }} />}
      placeholder={placeholder}
      fullWidth={fullWidth}
      sx={{
        '& input': {
          fontSize: '0.875rem',
          paddingLeft: '8px',
          fontWeight: 500
        },
        ...sx
      }}
      value={value}
      onChange={({ target: { value } }) => onChange(value)}
    />
  );
};

Search.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  sx: PropTypes.object
};

export default memo(Search);