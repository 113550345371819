import { memo } from 'react';
// Local files
import SearchResult from './SearchResult/SearchResult';
import SelectedItems from './SelectedItems/SelectedItems';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';

const FoldersBreadcrumbs = ({ variant, value, onClick }) => {
  if (variant === 'search') return <SearchResult query={value} onClick={onClick} />;
  if (variant === 'selected') return <SelectedItems data={value} onClick={onClick} />;
  if (variant === 'breadcrumbs') return <Breadcrumbs crumbs={value} />;
  return null;
};

export default memo(FoldersBreadcrumbs);