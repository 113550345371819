import { memo } from 'react';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
// Local files
import { Root, Header, Content } from './Accordion.styled';

const Accordion = ({ title, amount, children, ...otherProps }) => {
  return (
    <Root defaultExpanded {...otherProps}>
      <Header>
        <Typography variant='subtitle2' >{title}</Typography>
        {!!amount && <Chip size='small' color='success' label={amount} />}
      </Header>
      <Content>
        {children}
      </Content>
    </Root>
  );
};

export default memo(Accordion);