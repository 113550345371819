import {
  getSharedListsRoutine,
  getMyListsRoutine,
  getListRoutine,
  createListRoutine,
  updateListRoutine,
  deleteListRoutine,
  clearLocalSharedListsRoutine,
  clearLocalMyListsRoutine,
  clearLocalActiveListRoutine,
  getContactRoutine
} from 'actions';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import _reduce from 'lodash/reduce';
import _uniqBy from 'lodash/uniqBy';
import _dropRight from 'lodash/dropRight';

export const initialState = {
  shared: {
    data: [],
    pagination: { limit: 25, offset: 0, count: 0, total_count: -1 },
    loading: true
  },
  my: {
    data: [],
    pagination: { limit: 25, offset: 0, count: 0, total_count: -1 },
    loading: true
  },
  list: { id: '', name: '', default: false },
  defaultList: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getSharedListsRoutine.REQUEST: {
      const shared = { ...state.shared, loading: true };

      return { ...state, shared };
    }
    case getSharedListsRoutine.SUCCESS: {
      const { data: { lists, pagination } } = action.payload;
      const defaultList = _find(lists, l => l.default);
      const reducedLists = _reduce(lists, (result, value) => {
        const userFound = !!_find(result, r => r.user.id === value.user.id);

        if (userFound) {
          return _map(result, r => r.user.id === value.user.id
            ? ({
                ...r,
                lists: _uniqBy([
                  ...r.lists,
                  {
                    id: value.id,
                    name: value.name,
                    shared: !!value.groups.length,
                    contactsCount: value.contacts_count,
                    user: value.user
                  }
                ], 'id')
              })
            : r
          );
        }
        result.push({
          user: value.user,
          lists: [{
            id: value.id,
            name: value.name,
            shared: !!value.groups.length,
            contactsCount: value.contacts_count,
            user: value.user
          }]
        });

        return result;
      }, state.shared.data);

      return {
        ...state,
        shared: { data: reducedLists, pagination },
        loading: false,
        ...defaultList && { defaultList }
      };
    }
    case getSharedListsRoutine.FAILURE: {
      const shared = { ...state.shared, loading: false };

      return { ...state, shared };
    }
    case getMyListsRoutine.REQUEST: {
      const my = { ...state.my, loading: true };

      return { ...state, my };
    }
    case getMyListsRoutine.SUCCESS: {
      const { data: { lists, pagination } } = action.payload;

      return { ...state, my: { data: [...state.my.data, ...lists], pagination }, loading: false };
    }
    case getMyListsRoutine.FAILURE: {
      const my = { ...state.my, loading: false };

      return { ...state, my };
    }
    case getListRoutine.SUCCESS: {
      const { data: { list } } = action.payload;
      const updatedList = { ...state.list, ...list };
      const my = { ...state.my, data: _map(state.my.data, item => item.id === list.id ? { ...item, ...list } : item) };

      return { ...state, list: updatedList, my };
    }
    case createListRoutine.SUCCESS: {
      const { data: { list } } = action.payload;
      const isPageFull = state.my.pagination.count % state.my.pagination.limit === 0 && state.my.pagination.total_count > 0;
      const oldAllData = isPageFull ? _dropRight(state.my.data) : state.my.data;
      const data = [list, ...oldAllData];
      const my = {
        ...state.my,
        data,
        pagination: { ...state.my.pagination, count: isPageFull ? state.my.pagination.count : state.my.pagination.count + 1, total_count: state.my.pagination.total_count + 1 }
      };

      return { ...state, my };
    }
    case updateListRoutine.SUCCESS: {
      const { response: { status }, list } = action.payload;
      const my = {
        ...state.my,
        data: _map(state.my.data, l => l.id === list.id ? ({ ...l, ..._omit(list, ['group_ids']), ...list.localGroups && { groups: list.localGroups } }) : l )
      };

      return { ...state, ...status === 204 && { my } };
    }
    case deleteListRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const listFound = !!_find(state.my.data, l => l.id === id);
      const my = listFound ? {
        ...state.my,
        data: _filter(state.my.data, l => l.id !== id),
        pagination: {
          ...state.my.pagination,
          count: state.my.pagination.count - 1,
          total_count: state.my.pagination.total_count - 1
        }
      } : state.my;

      return { ...state, ...status === 204 && { my } };
    }
    case clearLocalSharedListsRoutine.SUCCESS: {
      return { ...state, shared: initialState.shared, defaultList: null };
    }
    case clearLocalMyListsRoutine.SUCCESS: {
      return { ...state, my: initialState.my };
    }
    case clearLocalActiveListRoutine.SUCCESS: {
      return { ...state, list: initialState.list };
    }
    case getContactRoutine.SUCCESS: {
      const { data: { contact } } = action.payload;
      const foundedNewLists = _filter(contact.lists, (list) => !list.default && !_find(state.my.data, l => list.id === l.id));
      const my = !!foundedNewLists.length ? { ...state.my, data: [...foundedNewLists, ...state.my.data] } : state.my;
      
      return { ...state, my };
    }
    default: {
      return state;
    }
  };
};

export default reducer;