import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  setAbortControllerRoutine,
  setChatsFilterRoutine,
  setNotificationsOptionsRoutine,
  setViewAttachmentRoutine,
  setAuthDataRoutine, clearAuthDataRoutine,
  openAccessOfferingDialogRoutine, closeAccessOfferingDialogRoutine,
  openAccessBetaDialogRoutine, closeAccessBetaDialogRoutine,
  openAccessFeedDialogRoutine, closeAccessFeedDialogRoutine,
  openAvatarDialogRoutine, closeAvatarDialogRoutine,
  openInviteDialogRoutine, closeInviteDialogRoutine,
  openGroupDialogRoutine, closeGroupDialogRoutine,
  openGroupInviteDialogRoutine, closeGroupInviteDialogRoutine,
  openHeaderDialogRoutine, closeHeaderDialogRoutine,
  openListsUpdateDialogRoutine, closeListsUpdateDialogRoutine,
  openUploadContactsDialogRoutine, closeUploadContactsDialogRoutine,
  openAgreementDialogRoutine, closeAgreementDialogRoutine,
  openFilesCopyDialogRoutine, closeFilesCopyDialogRoutine,
  openDeleteDialogRoutine, closeDeleteDialogRoutine,
  openEditProfileDialogRoutine, closeEditProfileDialogRoutine,
  openViewFileDialogRoutine, closeViewFileDialogRoutine,
  openLockedContentDialogRoutine, closeLockedContentDialogRoutine,
  openSendEmailNotificationDialogRoutine, closeSendEmailNotificationDialogRoutine,
  openInvitedUserPopoverRoutine, closeInvitedUserPopoverRoutine,
  openRecipientDrawerRoutine, closeRecipientDrawerRoutine,
  openCreateListDialogRoutine, closeCreateListDialogRoutine,
  openPreBuilderDialogRoutine, closePreBuilderDialogRoutine
} from 'actions';

/*** Abort Controller ***/
export const setAbortController = getThunkActionCreator(setAbortControllerRoutine, async abortController => ({ abortController }));
/*** Chats ***/
export const setChatsFilter = getThunkActionCreator(setChatsFilterRoutine, async ({ filter }) => ({ filter }));
/*** Notifications ***/
export const setNotificationsOptions = getThunkActionCreator(setNotificationsOptionsRoutine, async ({ drawer }) => ({ drawer }));
/*** Attachment ***/
export const setViewAttachment = getThunkActionCreator(setViewAttachmentRoutine, async ({ file }) => ({ file }));
/*** Auth ***/
export const setAuthData = getThunkActionCreator(
  setAuthDataRoutine,
  async ({ open, mode, recipientToken, invitationToken, redirectUrl, redirectAction, firstName, lastName, email, password, repeatPassword, confirmationCode, codeSended, codeValidated, linkedInCode }) => {
    return {
      open,
      mode,
      recipientToken,
      invitationToken,
      redirectUrl,
      redirectAction,
      firstName,
      lastName,
      email,
      password,
      repeatPassword,
      confirmationCode,
      codeSended,
      codeValidated,
      linkedInCode
    };
  });
export const clearAuthData = getThunkActionCreator(clearAuthDataRoutine, async () => {});
/*** Access Offering ***/
export const openAccessOfferingDialog = getThunkActionCreator(openAccessOfferingDialogRoutine, async () => {});
export const closeAccessOfferingDialog = getThunkActionCreator(closeAccessOfferingDialogRoutine, async () => {});
/*** Access Beta ***/
export const openAccessBetaDialog = getThunkActionCreator(openAccessBetaDialogRoutine, async () => {});
export const closeAccessBetaDialog = getThunkActionCreator(closeAccessBetaDialogRoutine, async () => {});
/*** Access Feed ***/
export const openAccessFeedDialog = getThunkActionCreator(openAccessFeedDialogRoutine, async () => {});
export const closeAccessFeedDialog = getThunkActionCreator(closeAccessFeedDialogRoutine, async () => {});
/*** Avatar ***/
export const openAvatarDialog = getThunkActionCreator(openAvatarDialogRoutine, async () => {});
export const closeAvatarDialog = getThunkActionCreator(closeAvatarDialogRoutine, async () => {});
/*** Invite ***/
export const openInviteDialog = getThunkActionCreator(openInviteDialogRoutine, async () => {});
export const closeInviteDialog = getThunkActionCreator(closeInviteDialogRoutine, async () => {});
/*** Group ***/
export const openGroupDialog = getThunkActionCreator(openGroupDialogRoutine, async () => {});
export const closeGroupDialog = getThunkActionCreator(closeGroupDialogRoutine, async () => {});
/*** Group Invite ***/
export const openGroupInviteDialog = getThunkActionCreator(openGroupInviteDialogRoutine, async (invitation) => invitation);
export const closeGroupInviteDialog = getThunkActionCreator(closeGroupInviteDialogRoutine, async () => {});
/*** Header ***/
export const openHeaderDialog = getThunkActionCreator(openHeaderDialogRoutine, async () => {});
export const closeHeaderDialog = getThunkActionCreator(closeHeaderDialogRoutine, async () => {});
/*** Lists ***/
export const openListsUpdateDialog = getThunkActionCreator(openListsUpdateDialogRoutine, async (contacts) => contacts);
export const closeListsUpdateDialog = getThunkActionCreator(closeListsUpdateDialogRoutine, async () => {});
/*** Contacts Upload ***/
export const openUploadContactsDialog = getThunkActionCreator(openUploadContactsDialogRoutine, async () => {});
export const closeUploadContactsDialog = getThunkActionCreator(closeUploadContactsDialogRoutine, async () => {});
/*** Agreement ***/
export const openAgreementDialog = getThunkActionCreator(openAgreementDialogRoutine, async url => ({ url }));
export const closeAgreementDialog = getThunkActionCreator(closeAgreementDialogRoutine, async () => {});
/*** Files Copy ***/
export const openFilesCopyDialog = getThunkActionCreator(openFilesCopyDialogRoutine, async () => {});
export const closeFilesCopyDialog = getThunkActionCreator(closeFilesCopyDialogRoutine, async () => {});
/*** Delete ***/
export const openDeleteDialog = getThunkActionCreator(openDeleteDialogRoutine, async ({ type, id, navigatePath, data }) => ({ type, id, navigatePath, data }));
export const closeDeleteDialog = getThunkActionCreator(closeDeleteDialogRoutine, async () => {});
/*** Edit Profile ***/
export const openEditProfileDialog = getThunkActionCreator(openEditProfileDialogRoutine, async () => {});
export const closeEditProfileDialog = getThunkActionCreator(closeEditProfileDialogRoutine, async () => {});
/*** View File ***/
export const openViewFileDialog = getThunkActionCreator(openViewFileDialogRoutine, async ({ id = null, file = null }) => ({ id, file }));
export const closeViewFileDialog = getThunkActionCreator(closeViewFileDialogRoutine, async () => {});
/*** Locked Content ***/
export const openLockedContentDialog = getThunkActionCreator(openLockedContentDialogRoutine, async () => {});
export const closeLockedContentDialog = getThunkActionCreator(closeLockedContentDialogRoutine, async () => {});
/*** Send Email Notification ***/
export const openSendEmailNotificationDialog = getThunkActionCreator(openSendEmailNotificationDialogRoutine, async email => ({ email }));
export const closeSendEmailNotificationDialog = getThunkActionCreator(closeSendEmailNotificationDialogRoutine, async () => {});
/*** Invited User Popover ***/
export const openInvitedUserPopover = getThunkActionCreator(openInvitedUserPopoverRoutine, async () => {});
export const closeInvitedUserPopover = getThunkActionCreator(closeInvitedUserPopoverRoutine, async () => {});
/*** Recipient Drawer ***/
export const openRecipientDrawer = getThunkActionCreator(openRecipientDrawerRoutine, async recipient => ({ recipient }));
export const closeRecipientDrawer = getThunkActionCreator(closeRecipientDrawerRoutine, async () => {});
/*** Create List ***/
export const openCreateListDialog = getThunkActionCreator(openCreateListDialogRoutine, async () => {});
export const closeCreateListDialog = getThunkActionCreator(closeCreateListDialogRoutine, async () => {});
/*** Pre Builder ***/
export const openPreBuilderDialog = getThunkActionCreator(openPreBuilderDialogRoutine, async () => {});
export const closePreBuilderDialog = getThunkActionCreator(closePreBuilderDialogRoutine, async () => {});