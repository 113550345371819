import { useCallback } from 'react';

const useAndroid = () => {
  const sendTokenToAndroid = useCallback(token => {
    if (window.Android?.onAuthTokenReceived) {
      window.Android.onAuthTokenReceived(token);
    }
  }, []);

  return { sendTokenToAndroid };
};

export default useAndroid;