import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const backgroundColor = '#F8F8F8';

export const Header = styled('div')({
  flexShrink: 0,
  width: '100%',
  height: 140,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: backgroundColor
});

export const CloseButton = styled(Button)({
  minWidth: 36,
  minHeight: 36,
  alignSelf: 'flex-end',
  backgroundColor: 'transparent !important',
  padding: 0
});

export const Section = styled(Box)({
  height: 'calc(50% - 70px)',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: backgroundColor
});

export const SectionHeader = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  padding: '16px 40px 8px '
});

export const PromptText = styled(Box)({
  color: 'rgba(0, 0, 0, .6)',
  fontStyle: 'italic',
  fontSize: '0.75rem',
  fontWeight: 600,
  marginLeft: '8px'
});

export const Grid = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(185px, 1fr))',
  gap: '8px',
  overflow: 'auto',
  padding: '8px 40px',
  [theme.breakpoints.down('sm')]: {
    '& > div': {
      width: '100%'
    }
  }
}));

export const FileInput = styled('input')({ display: 'none' });