import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  createSession as createSessionAction,
  deleteSession as deleteSessionAction,
  setLocalSession as setLocalSessionAction
} from 'actions/sessions';

const useSessions = () => {
  const dispatch = useDispatch();
  const createSession = useCallback(({ email, password, linkedin_code, recipient_token }) => dispatch(createSessionAction({ email, password, linkedin_code, recipient_token })), [dispatch]);
  const deleteSession = useCallback(controller => dispatch(deleteSessionAction(controller)), [dispatch]);
  const setLocalSession = useCallback(({ accessToken, userableType }) => dispatch(setLocalSessionAction({ accessToken, userableType })), [dispatch]);

  return { createSession, deleteSession, setLocalSession };
};

export default useSessions;