import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import InputBase from '@mui/material/InputBase';
import PropTypes from 'prop-types';
// Local files
import SearchIcon from 'components/Common/Icons/Search';

const Search = forwardRef(({ disabled = false, onChange, value = '' }, ref) => {
  const timerRef = useRef(null);
  const [query, setQuery] = useState(value ?? '');
  const [queryChanged, setQueryChanged] = useState(false);

  const handleChange = ({ target: { value } }) => {
    setQuery(value);
    if (!queryChanged) setQueryChanged(true);
  };

  useEffect(() => {
    if (queryChanged) {
      timerRef.current = setTimeout(() => {
        onChange(query);
      }, 500);
    }

    return () => clearTimeout(timerRef.current);
  }, [query, queryChanged]); // eslint-disable-line
  useEffect(() => {
    return () => {
      setQuery('');
      setQueryChanged(false);
    };
  }, []);
  useImperativeHandle(ref, () => {
    return {
      clearQuery() {
        setQuery('');
      }
    };
  }, []);

  return (
    <InputBase
      disabled={disabled}
      startAdornment={<SearchIcon sx={{ color: 'text.secondary', fontSize: '1.5rem' }} />}
      placeholder='search users, tags, locations, headlines...'
      fullWidth
      sx={{ '& input': { fontSize: '0.75rem', paddingLeft: '8px' }, '& svg': { maxHeight: '15px', width: 'auto' } }}
      value={query}
      onChange={handleChange}
    />
  );
});

Search.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default memo(Search);