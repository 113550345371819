import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

export const VersionChip = styled('div')(({ theme }) => ({
  maxWidth: 24,
  minHeight: 24,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '4px',
  padding: '2px',
  margin: '0 auto',
  marginBottom: 8
}));

export const VersionChipLabel = styled((props) => (
  <Typography variant='caption' {...props} />
))({
  color: '#fff',
  fontWeight: 500,
  lineHeight: '100%',
  padding: '0 4px',
});