import { memo, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Outlet, useParams } from 'react-router-dom';
// Local files
import Card from 'components/Groups/Card/Card';
import useError from 'hooks/useError';
import useGroups from 'hooks/useGroups';
import useMembers from 'hooks/useMembers';

const Group = () => {
  const { group_id } = useParams();
  const { setError } = useError();
  const { getGroup } = useGroups();
  const { getGroupMembersList, clearLocalGroupMembersList } = useMembers();
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    getGroup(group_id)
    .then(() =>
      getGroupMembersList({ group_id, offset: 0 })
      .then(() => setFetching(false))
      .catch(e => setError(e))
    )
    .catch(e => setError(e));

    return () => clearLocalGroupMembersList().then(() => setFetching(true));
  }, [getGroup, group_id, setError, getGroupMembersList, clearLocalGroupMembersList]);

  return (
    <Box sx={{ height: '100%' }}>
      <Card fetching={fetching} />
      <Outlet context={{ fetching }} />
    </Box>
  );
};

export default memo(Group);