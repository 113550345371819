import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { getViewsRoutine, viewDealspaceRoutine, updateViewRoutine, clearLocalViewsRoutine } from 'actions';

const attributes = [
  'id', 
  'updated_at', 
  { dealspace: [
    'id', 'own_view', 'name', 'properties_count', 'main_location', 'locations_count', 'tag', 
    { members: ['id', { user: ['id', 'first_name', 'last_name', { photo: ['representations'] }] }]},
    { cover: ['id', 'representations'] }
  ]}
];

export const getViews = getThunkActionCreator(
  getViewsRoutine,
  async ({ offset }) => {
    const params = { orders: { updated_at: 'desc' }, offset, attributes };

    return await axios.get('/views', { params });
  }
);
export const viewDealspace = getThunkActionCreator(
  viewDealspaceRoutine,
  async id => {
    return await axios.post(`/dealspaces/${id}/views`);
  }
);
export const updateView = getThunkActionCreator(
  updateViewRoutine,
  async id => {
    return await axios.patch(`/views/${id}`);
  }
);
export const clearLocalViews = getThunkActionCreator(clearLocalViewsRoutine, async () => {});