import { memo, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import { useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import Paper from 'components/Common/DataDisplay/BaseTable/Paper/Paper';
import TablePagination from 'components/Common/DataDisplay/BaseTable/TablePagination/TablePagination';
import Head from './Head/Head';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { roles, representationVariants } from 'helpers/constants';
import { getFormattedDate, getRepresentation } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useDealspaces from 'hooks/useDealspaces';
import useInvitations from 'hooks/useInvitations';
import useMembers from 'hooks/useMembers';
import useSuccess from 'hooks/useSuccess';
import useApp from 'hooks/useApp';
import { DELETE_MEMBER } from 'helpers/confirmations';

const MembersTable = ({ roleInThisGroup, query }) => {
  const { group_id } = useParams();
  const { openDeleteDialog } = useApp();
  const { notifyInvite } = useInvitations();
  const { getDealspacesList, clearLocalDealspacesList } = useDealspaces();
  const { getGroupMembers, updateMember } = useMembers();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { myId, members, pagination, dealspacesList } = useCustomSelector(state => ({ 
    myId: state.profile.user.id,
    members: state.members.group.data,
    pagination: state.members.group.pagination,
    dealspacesList: state.dealspaces.list.data
  }));
  const [page, setPage] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('user');
  const [anchorEl, setAnchorEl] = useState(null);
  const [dealspaceIds, setDealspaceIds] = useState([]);
  const fullPages = parseInt(pagination.total_count / pagination.limit);
  const partialPage = pagination.total_count % pagination.limit === 0 ? 0 : 1;
  const showPagination = fullPages + partialPage > 1;
  const canEdit = roleInThisGroup === 'owner';

  const handleRoleChange = ({ id, value }) => {
    const member = { id, role: value };

    setProcessing(true);
    updateMember(member)
    .then(() => setSuccess('Member successfully updated'))
    .catch(e => setError(e))
    .finally(() => setProcessing(false))
  };
  const handleRevokeClick = id => openDeleteDialog({ type: DELETE_MEMBER, id });
  const handleDealspacesChange = (id) => {
    const foundedMember = _find(members, (m) => m.id === id);
    const member = { 
      id, 
      dealspace_ids: dealspaceIds, 
      ...(foundedMember?.dealspaces?.length > dealspaceIds.length && foundedMember.permission === 'full' && { permission: 'partial' }),
      ...(dealspaceIds.length === dealspacesList.length && foundedMember.permission === 'partial' && { permission: 'full' }),
      ...(foundedMember?.dealspaces?.length < dealspaceIds.length && { newDealspaces: _filter(dealspacesList, (d) => !!_find(dealspaceIds, (id) => d.id === id)) })
    };

    setProcessing(true);
    setAnchorEl(null);
    updateMember(member)
    .then(() => setSuccess('Member successfully updated'))
    .catch(e => setError(e))
    .finally(() => setProcessing(false))
  };
  const handleChangeSort = (activeColumn) => {
    const isAsc = orderBy === activeColumn && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(activeColumn);
  };
  const handleResendClick = id => {
    setProcessing(true);
    notifyInvite(id)
    .then(() => setSuccess('Invitation email successfully resent!'))
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      getGroupMembers({ 
        offset: page * 25, 
        group_id,
        orders: orderBy === 'user' ? { user: { first_name: order } } : { [orderBy]: order },
        query
      })
      .catch(e => setError(e))
    }, query ? 500 : 0);

    return () => clearTimeout(timeout);
  }, [getGroupMembers, query, page, order, orderBy, setError, group_id]);
  useEffect(() => {
    if (canEdit) {
      clearLocalDealspacesList()
      .then(() => getDealspacesList({ group_id, offset: 0 }).catch(e => setError(e)));
    }

    return clearLocalDealspacesList;
  }, [group_id, canEdit, clearLocalDealspacesList, getDealspacesList, setError]);

  return (
    <Paper
      footer={showPagination &&
        <TablePagination
          rowsPerPage={25}
          count={pagination.total_count}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
        />
      }
      sx={{ marginTop: 2 }}
    >
      <Table>
        <Head
          order={order}
          orderBy={orderBy}
          changeSorting={handleChangeSort}
        />
        <TableBody>
          {_map(members, ({ id, status, permission, invitation, user, updated_at, role, dealspaces }) => (
            <TableRow
              key={id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align='left'>
                <Box display='flex'>
                  {user &&
                    <BaseAvatar
                      firstName={user?.first_name ?? ''}
                      lastName={user?.last_name ?? ''}
                      src={getRepresentation(user?.photo?.representations, representationVariants.TINY)}
                      sx={{ width: '45px', height: '45px', fontSize: '18px' }}
                    />
                  }
                  <Box ml={1}>
                    {!!invitation ? <Typography variant='body2' component={'p'}>{invitation.email ?? invitation.user.email}</Typography> : <Typography variant='body2' component={'p'} sx={{ fontWeight: 600 }}>{user?.first_name ?? ''} {user?.last_name ?? ''}</Typography>}
                    {!!user && <Typography variant='caption' color='#9FA2AC'>{user?.email ?? ''}</Typography>}
                  </Box>
                </Box>
              </TableCell>
              <TableCell align='left'>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography variant='caption'>{status}</Typography>
                  {status === 'invited' &&
                    <Button
                      variant='text'
                      sx={{ textDecoration: 'underline !important', whiteSpace: 'nowrap', py: 0 }}
                      onClick={() => handleResendClick(invitation.id)}
                      disabled={processing}
                    >
                      Resend invite
                    </Button>
                  }
                </Box>
              </TableCell>
              <TableCell align='left'>
                <Typography variant='caption'>{getFormattedDate(updated_at)}</Typography>
              </TableCell>
              <TableCell align='left'>
                <FormControl fullWidth disabled={processing}>
                  <InputLabel id='role-select-label'>Role</InputLabel>
                  <Select
                    disabled={myId === user?.id || !canEdit}
                    labelId='role-select-label'
                    id='role-select'
                    value={role}
                    label='Role'
                    renderValue={v => <span>{_find(roles, r => r.value === v)?.label ?? '-'}</span>}
                    onChange={({ target: { value } }) => handleRoleChange({ id, value })}
                    MenuProps={{ PaperProps: { variant: 'outlined', color: 'default', sx: {'& ul': {padding: 0} }} }}
                  >
                    {_map(roles, r => (
                      <MenuItem key={r.value} value={r.value} sx={{ maxWidth: 280, flexDirection: 'column', alignItems: 'flex-start', gap: '4px', py: 2, '&:not(:last-of-type)': {borderBottom:'1px solid rgba(0, 0, 0, 0.23)'} }}>
                        <Typography variant='subtitle2' color='secondary' component='div' >{r.label}</Typography>
                        <Typography variant='caption' component='div' sx={{ whiteSpace: 'normal' }}>{r.description}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell align='left'>
                <FormControl fullWidth disabled={processing}>
                  <InputLabel id='dealspaces-select-label'>Dealspaces</InputLabel>
                  <Select
                    disabled={myId === user?.id || !canEdit || permission === 'full'}
                    labelId='dealspaces-select-label'
                    id='dealspaces-select'
                    value={!!dealspaces.length ? _map(dealspaces, ({ id }) => id) : (role === 'owner' || permission === 'full') ? ['all'] : []}
                    label='Dealspaces'
                    multiple
                    renderValue={(value) => (role === 'owner' || permission === 'full') ? 'All current and new' : `${value.length} dealspace${value.length > 1 ? 's' : ''}` }
                    onChange={({ target: { value } }) => setDealspaceIds(value)}
                  >
                    {(role === 'owner' || permission === 'full') ? <MenuItem key={id} value='all'>All current and new</MenuItem> : _map(dealspacesList, ({ id, name }) => <MenuItem onClick={({ currentTarget }) => setAnchorEl(currentTarget)} key={id} value={id}>{name}</MenuItem>)}
                    <Menu
                      anchorEl={anchorEl}
                      open={!!anchorEl}
                      onClose={() => setAnchorEl(null)}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                      PaperProps={{ variant: 'popup', color: 'light', sx: { display: 'flex', alignItems: 'center', justifyContent: 'space-around', mt: '2px', width: '200px', } }}
                    >
                      <Typography align='center' variant='subtitle2'>Are you sure want to {dealspaceIds?.length > dealspaces?.length ? 'add' : 'delete'} user dealspace?</Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button
                          variant='outlined'
                          color={dealspaceIds?.length > dealspaces?.length ? 'success' : 'error'}
                          onClick={() => handleDealspacesChange(id)}
                        >
                          Yes
                        </Button>
                        <Button
                          variant='outlined'
                          color={dealspaceIds?.length > dealspaces?.length ? 'error' : 'success'}
                          sx={{ ml: 2 }}
                          onClick={() => setAnchorEl(null)}
                        >
                          No
                        </Button>
                      </Box>
                    </Menu>
                  </Select>
                </FormControl>
              </TableCell>
              {myId !== user?.id && canEdit &&
                <TableCell align='left'>
                  <Button
                    variant='outlined'
                    onClick={() => handleRevokeClick(id)}
                  >
                    Revoke Access
                  </Button>
                </TableCell>
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

MembersTable.propTypes = {
  roleInThisGroup: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <MembersTable {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);