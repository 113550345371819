import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import _filter from 'lodash/filter';
import _forEach from 'lodash/forEach';
import {
  getDealspaceImages as getDealspaceImagesAction,
  getBuilderImages as getBuilderImagesAction,
  getParcelImages as getParcelImagesAction,
  getOfferImages as getOfferImagesAction,
  getPreviewImages as getPreviewImagesAction,
  getImage as getImageAction,
  createOfferImage as createOfferImageAction,
  updateImage as updateImageAction,
  deleteImage as deleteImageAction,
  mainImage as mainImageAction,
  clearLocalDealspaceImages as clearLocalDealspaceImagesAction,
  clearLocalOfferImages as clearLocalOfferImagesAction,
  clearLocalActiveImage as clearLocalActiveImageAction
} from 'actions/images';
import useBlob from './useBlob';
import useChecksum from './useChecksum';
import useError from './useError';

const useImages = () => {
  const controller = useMemo(() => new AbortController(), []);
  const dispatch = useDispatch();
  const getDealspaceImages = useCallback(({ folder_id, offset }) => dispatch(getDealspaceImagesAction({ folder_id, offset })), [dispatch]);
  const getBuilderImages = useCallback(({ offer_id, offset }) => dispatch(getBuilderImagesAction({ offer_id, offset })), [dispatch]);
  const getParcelImages = useCallback((offer_id) => dispatch(getParcelImagesAction(offer_id)), [dispatch]);
  const getOfferImages = useCallback(({ offer_id, offset }) => dispatch(getOfferImagesAction({ offer_id, offset })), [dispatch]);
  const getPreviewImages = useCallback(({ offer_id, offset }) => dispatch(getPreviewImagesAction({ offer_id, offset })), [dispatch]);
  const getImage = useCallback(id => dispatch(getImageAction(id)), [dispatch]);
  const createOfferImage = useCallback(({ offer_id, document_id, image }) => dispatch(createOfferImageAction({ offer_id, document_id, image, controller })), [dispatch, controller]);
  const updateImage = useCallback(image => dispatch(updateImageAction(image)), [dispatch]);
  const deleteImage = useCallback(id => dispatch(deleteImageAction(id)), [dispatch]);
  const mainImage = useCallback(id => dispatch(mainImageAction(id)), [dispatch]);
  const clearLocalDealspaceImages = useCallback(() => dispatch(clearLocalDealspaceImagesAction()), [dispatch]);
  const clearLocalOfferImages = useCallback(() => dispatch(clearLocalOfferImagesAction()), [dispatch]);
  const clearLocalActiveImage = useCallback(() => dispatch(clearLocalActiveImageAction()), [dispatch]);
  const { processBlob } = useBlob();
  const { processFiles } = useChecksum();
  const { setError } = useError();
  const [processableFiles, setProcessableFiles] = useState([]);
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);

  const uploadImages = (files) => {
    setUploading(true);
    setProcessableFiles(files);
  };
  const removeImage = () => setImage(null);
  const uploadImage = ({ f, offer_id }) => {
    processFiles([f], ({ file, checksum }) => {
      processBlob({ file, checksum }, blob => {
        if (!blob.id) return;

        const image = { file: blob.id }

        if (!!offer_id) {
          createOfferImage({ offer_id, image })
          .then(() => setProcessableFiles(prev => _filter(prev, p => p.id === blob.id)))
          .catch(e => setError(e));
        }
        if (!offer_id) {
          setImage(blob);
          setProcessableFiles(prev => _filter(prev, p => p.id === blob.id));
        }
      });
    });
  };
  const cancel = () => {
    controller.abort();
    setProcessableFiles([]);
  };

  useEffect(() => {
    if (processableFiles.length) {
      processableFiles.length === 1 ? uploadImage(processableFiles[0]) : _forEach(processableFiles, (file) => uploadImage(file));
    } else {
      setUploading(false);
    }
  }, [processableFiles]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    uploading,
    image,
    removeImage,
    uploadImages,
    createOfferImage,
    updateImage,
    deleteImage,
    cancel,
    fetchImage: getImage,
    getDealspaceImages,
    getBuilderImages,
    getParcelImages,
    getOfferImages,
    getPreviewImages,
    mainImage,
    clearLocalDealspaceImages,
    clearLocalOfferImages,
    clearLocalActiveImage
  };
};

export default useImages;