import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  addToTracksOffer as addToTracksOfferAction,
  removeFromTracksOffer as removeFromTracksOfferAction
} from 'actions/tracks';

const useTracks = () => {
  const dispatch = useDispatch();
  const addToTracksOffer = useCallback(id => dispatch(addToTracksOfferAction(id)), [dispatch]);
  const removeFromTracksOffer = useCallback(id => dispatch(removeFromTracksOfferAction(id)), [dispatch]);

  return { addToTracksOffer, removeFromTracksOffer };
};

export default useTracks;