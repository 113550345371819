import { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
// Local files
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';

const BuilderErrorLabel = ({ value }) => {
  return (
    <Box display='flex' alignItems='center' gap='5px'>
      <ErrorIcon />
      <Typography fontWeight={400} fontSize='10px' lineHeight='20px' color='rgba(215, 0, 0, 1)'>{value}</Typography>
    </Box>
  );
};

BuilderErrorLabel.propTypes = {
  value: PropTypes.string.isRequired
};

export default memo(BuilderErrorLabel);