import { memo } from 'react';
import _map from 'lodash/map';
//Local files
import { ButtonContainer, Content, Root, StartChatButton } from './List.styled';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import Conversation from 'components/Conversations/BubbleChat/ChatPopover/List/Conversation/Conversation';
import useConversations from 'hooks/useConversations';

const List = ({ conversations, my_id, senders }) => {
  const { setLocalUserIds } = useConversations();

  const handleNewChat = () => setLocalUserIds(_map(senders, (u) => u.id));

  return (
    <Root>
      <Content>
        {_map(conversations, (conversation) => <Conversation key={conversation.id} {...{ conversation, my_id }} />)}
      </Content>
      <ButtonContainer>
        <StartChatButton startIcon={<ChatIcon />} onClick={handleNewChat}>New chat on this deal</StartChatButton>
      </ButtonContainer>
    </Root>
  )
}

export default memo(List);