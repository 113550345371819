import axios, { instance } from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { sessionService } from 'redux-react-session';
import {
  getFeedFiltersAmountRoutine,
  getFeedSharedWithMeOffersRoutine,
  getFeedArchiveOffersRoutine,
  getFeedMySharedOffersRoutine,
  getFeedMarketOffersRoutine,
  getFeedRecipientOffersRoutine,
  getFeedOfferDetailsRoutine,
  clearFeedOfferDataRoutine,
  clearFeedRecipientDataRoutine,
  //////////////////
  getPublishedOffersForChatsRoutine,
  getDealspaceOffersRoutine,
  getDealspaceOffersForChatsRoutine,
  getSharedOffersRoutine,
  getDraftSharedOffersRoutine,
  getActiveSharedOffersRoutine,
  getContactActiveOffersRoutine,
  getEndedSharedOffersRoutine,
  getInContractSharedOffersRoutine,
  getClosedSharedOffersRoutine,
  getGroupOffersForChatsRoutine,
  getBuilderDraftRoutine,
  getBuilderDraftActualFilesCountRoutine,
  getFeedMapOffersRoutine,
  getActivityLogOfferRoutine,
  getOfferActualRecipientsCountRoutine,
  getOfferPreviewRoutine,
  createOfferRoutine,
  updateOfferRoutine,
  deleteOfferRoutine,
  publishOfferRoutine,
  activateOfferRoutine,
  contractOfferRoutine,
  endOfferRoutine,
  closeOfferRoutine,
  disinterestOfferRoutine,
  saveOfferRoutine,
  duplicateOfferRoutine,
  visitOfferRoutine,
  exportOfferRoutine,
  intakeOfferRoutine,
  reshareActiveOffersRoutine,
  submitOfferShareFormRoutine,
  setOfferUnreadCountConversationsRoutine,
  setLocalOfferCountsRoutine,
  clearLocalDealspaceOffersRoutine,
  clearLocalOffersForChatsRoutine,
  clearLocalBuilderOfferRoutine,
  clearLocalFeedMapOffersRoutine,
  clearLocalSharedOffersRoutine,
  clearLocalContactActiveOffersRoutine,
  clearLocalFeedOfferRoutine,
  clearLocalActivityLogOfferRoutine,
  unlockLocalOfferRoutine,
  setOfferLocalFieldsRoutine,
  setBuilderStateRoutine
} from 'actions';
import _omit from 'lodash/omit';

const shortAttributes = [
  'id',
  'headline',
  'name',
  'status',
  'main_location',
  'version',
  'offer_type',
  'unread_conversations_count',
  { primary_sender: ['id', { user: ['id', 'first_name', 'last_name', { photo: ['representations'] } ]} ] },
  { dealspace: ['id', 'name', { group: ['id'] }] }
];
const activityLogAttributes = [
  'id', 
  'name',
  'headline', 
  'public', 
  'status', 
  'tag', 
  'locked',
  'locations_locked',
  'total_approval_required_documents_count',
  'total_locked_documents_count',
  'offered_recipients_count',
  'deadline_type', 
  'deadline_at', 
  'expired_at', 
  'recipients_count',
  'email_opened_recipients_count', 
  'visited_recipients_count', 
  'unlocked_recipients_count',
  'email_unopened_recipients_count',
  'approval_requested_recipients_count',
  'email_delivered_recipients_count',
  'tracked_recipients_count',
  'saved_recipients_count',
  { dealspace: ['id', 'name', { group: ['id', 'name'] }] },
  { draft_offer: ['id'] },
  { primary_sender: ['id', { user: ['id', 'first_name', 'last_name']} ] }
];
const dealspaceOffersAttributes = [
  'id',
  'name',
  'status',
  'tag',
  'deadline_type',
  'deadline_at',
  'expired_at',
  'recipients_count',
  'version',
  'visited_recipients_count', 
  'public',
  'user',
  'headline',
  'sent',
  'offer',
  'unlocked_recipients_count',
  'locations_locked', 
  'total_locked_documents_count', 
  'total_approval_required_documents_count',
  'total_unlocked_documents_count',
  { draft_offer: ['id'] },
  { offer: ['id', { primary_sender: ['id', { user: ['id', 'first_name', 'last_name', { photo: ['representations'] } ]} ] }, 'status', 'deadline_at', 'deadline_type'] },
  { dealspace: ['id', 'name', { group: ['id', 'name', { current_member: ['role'] }] }] },
  { primary_sender: ['id', { user: ['id', 'first_name', 'last_name', { photo: ['representations'] } ]} ] }
];
const feedAttributes = [
  'id',
  'tag',
  'tracked',
  'visited',
  'public',
  'deadline_at',
  'headline',
  'shared_at',
  'status',
  'senders_count',
  'asset_classes',
  'properties_count',
  'disinterested',
  'risk_profiles',
  'sent',
  { saved_dealspace: ['id', { group: ['id'] }] },
  { main_location: ['address', 'approximate_address'] },
  { primary_sender: ['id', { user: ['id', 'first_name', 'last_name', { photo: ['representations'] }] }] }
];
const sharedOffersAttributes = [
  'id',
  'tag',
  'name',
  'version',
  'status',
  'public',
  'headline',
  'recipients_count',
  'visited_recipients_count',
  'deadline_type',
  'deadline_at',
  'published_at',
  'expired_at',
  'updated_at',
  'sent',
  'unlocked_recipients_count',
  'locations_locked', 
  'total_locked_documents_count', 
  'total_approval_required_documents_count',
  'total_unlocked_documents_count',
  { draft_offer: ['id'] },
  { dealspace: ['id', 'name', { group: ['id', 'name', { current_member: ['role'] }] }, { cover: ['id', 'representations'] }] },
  { offer: ['id', 'name', 'tag', 'status', 'public'] },
  { primary_sender: ['id', { user: ['id', 'first_name', 'last_name', { photo: ['representations'] } ]} ] }
];
const recipientsAttributes = [
  'id',
  'recipients_count',
];

/******************************************************/
/******************************************************/
export const getFeedFiltersAmount = getThunkActionCreator(
  getFeedFiltersAmountRoutine,
  async filter => {
    const { accessToken } = await sessionService.loadSession().catch(e => console.warn(e));
    const requests = [
      { // ALL
        method: 'get',
        params: {
          access_token: accessToken,
          attributes: ['id'],
          limit: 0,
          ...filter === 'sharedWithMe' && { interested: true, received: true },
          ...filter === 'archive' && { disinterested: true },
          ...filter === 'myShared' && { interested: true, sent: true, statuses: ['active', 'in_contract', 'ended', 'closed'] },
          ...filter === 'market' && { interested: true, public: true, statuses: ['active', 'in_contract', 'ended', 'closed'] }
        },
        url: '/user/offers'
      },
      { // UNSEEN
        method: 'get',
        params: {
          access_token: accessToken,
          attributes: ['id'],
          limit: 0,
          unvisited: true,
          ...filter === 'sharedWithMe' && { interested: true, received: true },
          ...filter === 'archive' && { disinterested: true },
          ...filter === 'myShared' && { interested: true, sent: true, statuses: ['active', 'in_contract', 'ended', 'closed'] },
          ...filter === 'market' && { interested: true, public: true, statuses: ['active', 'in_contract', 'ended', 'closed'] }
        },
        url: '/user/offers'
      },
      { // TRACKING
        method: 'get',
        params: {
          access_token: accessToken,
          attributes: ['id'],
          limit: 0,
          tracked: true,
          ...filter === 'sharedWithMe' && { interested: true, received: true },
          ...filter === 'archive' && { disinterested: true },
          ...filter === 'myShared' && { interested: true, sent: true, statuses: ['active', 'in_contract', 'ended', 'closed'] },
          ...filter === 'market' && { public: true, statuses: ['active', 'in_contract', 'ended', 'closed'] }
        },
        url: '/user/offers'
      },
      { // DEADLINES
        method: 'get',
        params: {
          access_token: accessToken,
          attributes: ['id'],
          limit: 0,
          upcoming_deadline: true,
          ...filter === 'sharedWithMe' && { interested: true, received: true },
          ...filter === 'archive' && { disinterested: true },
          ...filter === 'myShared' && { interested: true, sent: true, statuses: ['active', 'in_contract', 'ended', 'closed'] },
          ...filter === 'market' && { interested: true, public: true, statuses: ['active', 'in_contract', 'ended', 'closed'] }
        },
        url: '/user/offers'
      }
    ];
    const data = { requests };
    const response = await instance.post('/batch', data);

    return { response, filter };
  }
);
export const getFeedSharedWithMeOffers = getThunkActionCreator(
  getFeedSharedWithMeOffersRoutine,
  async ({ limit = 7, offset = 0, orders = { shared_at: 'desc' }, query = null, filter = 'all' }) => {
    const params = {
      attributes: feedAttributes,
      limit,
      offset,
      orders,
      ...query && { query },
      ...filter === 'all' && { interested: true, received: true },
      ...filter === 'unseen' && { interested: true, unvisited: true, received: true },
      ...filter === 'tracking' && { interested: true, tracked: true, received: true },
      ...filter === 'deadlines' && { interested: true, upcoming_deadline: true, received: true }
    };
    const response = await axios.get('/offers', { params });

    return { response, filter };
  }
);
export const getFeedArchiveOffers = getThunkActionCreator(
  getFeedArchiveOffersRoutine,
  async ({ limit = 7, offset = 0, orders = { shared_at: 'desc' }, query = null, filter = 'all' }) => {
    const params = {
      attributes: feedAttributes,
      limit,
      offset,
      orders,
      ...query && { query },
      ...filter === 'all' && { disinterested: true },
      ...filter === 'tracking' && { disinterested: true, tracked: true },
      ...filter === 'deadlines' && { disinterested: true, upcoming_deadline: true }
    };
    const response = await axios.get('/offers', { params });

    return { response, filter };
  }
);
export const getFeedMySharedOffers = getThunkActionCreator(
  getFeedMySharedOffersRoutine,
  async ({ limit = 7, offset = 0, orders = { shared_at: 'desc' }, query = null, filter = 'all' }) => {
    const params = {
      attributes: feedAttributes,
      limit,
      offset,
      orders,
      ...query && { query },
      interested: true,
      sent: true,
      statuses: ['active', 'in_contract', 'ended', 'closed']
    };
    const response = await axios.get('/offers', { params });

    return { response, filter };
  }
);
export const getFeedMarketOffers = getThunkActionCreator(
  getFeedMarketOffersRoutine,
  async ({ limit = 7, offset = 0, orders = { shared_at: 'desc' }, query = null, filter = 'all' }) => {
    const params = {
      attributes: feedAttributes,
      limit,
      offset,
      orders,
      interested: true,
      public: true,
      statuses: ['active', 'in_contract', 'ended', 'closed'],
      ...query && { query },
      ...filter === 'unseen' && { unvisited: true },
      ...filter === 'tracking' && { tracked: true },
      ...filter === 'deadlines' && { upcoming_deadline: true }
    };
    const response = await axios.get('/offers', { params });

    return { response, filter };
  }
);
export const getFeedRecipientOffers = getThunkActionCreator(
  getFeedRecipientOffersRoutine,
  async ({ limit = 7, offset = 0, orders = { shared_at: 'desc' } }) => {
    const params = { attributes: feedAttributes, limit, offset, orders };

    return await axios.get('/offers', { params });
  }
);
export const getFeedOfferDetails = getThunkActionCreator(
  getFeedOfferDetailsRoutine,
  async id => {
    const attributes = [
      'id',
      'tag',
      'locked',
      'visited',
      'tracked',
      'public',
      'headline',
      'deadline_at',
      'deadline_type',
      'status',
      'lease_period',
      'loan_ratio',
      'loan_percent',
      'investment_type',
      'price_cents',
      'custom_price',
      'published_at',
      'shared_at',
      'description',
      'min_latitude', 
      'max_latitude', 
      'min_longitude', 
      'max_longitude',
      'group_header',
      'locations_locked',
      'locked_documents_count',
      'locked_folders_count',
      'approval_required_folders_count',
      'approval_required_documents_count',
      'total_locked_documents_count', 
      'total_approval_required_documents_count',
      'total_unlocked_documents_count',
      'approval_required',
      'asset_classes',
      'properties_count',
      'disinterested',
      'risk_profiles',
      'sent',
      'senders_count',
      'properties_count',
      'disinterested',
      'sent',
      { dealspace: ['id', 'name', { group: ['id', 'name'] }] },
      { root_folder: ['id'] },
      { logo: ['url'] },
      { primary_sender: ['id', { user: ['id', 'first_name', 'last_name', { photo: ['representations'] } ]} ] },
      { main_location: ['address', 'latitude', 'longitude', 'approximate_address', 'approximate_latitude', 'approximate_longitude'] },
      { saved_dealspace: ['id', 'name', { group: ['id', 'name', { logo: ['representations'] }] }] },
      { locations: ['id', { property: ['id', 'name'] }, 'address', 'latitude', 'longitude', 'min_latitude', 'max_latitude', 'min_longitude', 'max_longitude', 'parcel', 'approximate_address', 'approximate_latitude', 'approximate_longitude'] },
      { agreement: ['boldsign_document_url', { template: ['id', { file: ['url', 'filename'] }] }] }
    ];
    const params = { attributes };

    return await axios.get(`/offers/${id}`, { params });
  }
);
export const clearFeedOfferData = getThunkActionCreator(clearFeedOfferDataRoutine, async ({ vertical, horizontal }) => ({ vertical, horizontal }));
export const clearFeedRecipientData = getThunkActionCreator(clearFeedRecipientDataRoutine, async () => {});
/******************************************************/
/******************************************************/

export const getPublishedOffersForChats = getThunkActionCreator(
  getPublishedOffersForChatsRoutine,
  async offset => {
    const params = { limit: 25, offset, orders: { created_at: 'desc' }, attributes: shortAttributes, with_feed_conversations: true };

    return await axios.get('/offers', { params });
  }
);
export const getDealspaceOffersForChats = getThunkActionCreator(
  getDealspaceOffersForChatsRoutine,
  async ({ dealspace_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const params = { limit, offset, orders, attributes: shortAttributes, with_offer_conversations: true, statuses: ['active'] };

    return await axios.get(`/dealspaces/${dealspace_id}/offers`, { params });
  }
);
export const getSharedOffers = getThunkActionCreator(
  getSharedOffersRoutine,
  async ({ group_id = null, query = null, limit = 25, offset = 0, orders = { dealspace: { updated_at: 'desc' }, updated_at: 'desc' } }) => {
    const statuses = ['draft', 'active', 'in_contract', 'ended', 'closed'];
    const params = { limit, offset, orders, attributes: sharedOffersAttributes, statuses, original: true, ...!group_id && { sent: true }, ...(query && { query }) };

    return await axios.get(`${!!group_id ? `/groups/${group_id}` : ''}/offers`, { params });
  }
);
export const getDraftSharedOffers = getThunkActionCreator(
  getDraftSharedOffersRoutine,
  async ({ group_id = null, query = null, limit = 25, offset = 0, orders = { dealspace: { updated_at: 'desc' }, updated_at: 'desc' } }) => {
    const statuses = ['draft'];
    const params = { limit, offset, orders, attributes: sharedOffersAttributes, statuses, original: true, ...!group_id && { sent: true }, ...(query && { query }) };

    return await axios.get(`${!!group_id ? `/groups/${group_id}` : ''}/offers`, { params });
  }
);
export const getActiveSharedOffers = getThunkActionCreator(
  getActiveSharedOffersRoutine,
  async ({ group_id = null, query = null, limit = 25, offset = 0, orders = { dealspace: { updated_at: 'desc' }, updated_at: 'desc' } }) => {
    const statuses = ['active'];
    const params = { limit, offset, orders, attributes: sharedOffersAttributes, statuses, ...!group_id && { sent: true }, ...(query && { query }) };

    return await axios.get(`${!!group_id ? `/groups/${group_id}` : ''}/offers`, { params });
  }
);
export const getContactActiveOffers = getThunkActionCreator(
  getContactActiveOffersRoutine,
  async ({ limit = 25, offset = 0, orders = { updated_at: 'desc' }, recipient_email }) => {
    const statuses = ['active'];
    const params = { limit, offset, orders, attributes:  ['id','headline'], statuses, recipient_email };

    return await axios.get(`/offers`, { params });
  }
);
export const getEndedSharedOffers = getThunkActionCreator(
  getEndedSharedOffersRoutine,
  async ({ group_id = null, query = null, limit = 25, offset = 0, orders = { dealspace: { updated_at: 'desc' }, updated_at: 'desc' } }) => {
    const statuses = ['ended'];
    const params = { limit, offset, orders, attributes: sharedOffersAttributes, statuses, ...!group_id && { sent: true }, ...(query && { query }) };

    return await axios.get(`${!!group_id ? `/groups/${group_id}` : ''}/offers`, { params });
  }
);
export const getInContractSharedOffers = getThunkActionCreator(
  getInContractSharedOffersRoutine,
  async ({ group_id = null, query = null, limit = 25, offset = 0, orders = { dealspace: { updated_at: 'desc' }, updated_at: 'desc' } }) => {
    const statuses = ['in_contract'];
    const params = { limit, offset, orders, attributes: sharedOffersAttributes, statuses, ...!group_id && { sent: true }, ...(query && { query }) };

    return await axios.get(`${!!group_id ? `/groups/${group_id}` : ''}/offers`, { params });
  }
);
export const getClosedSharedOffers = getThunkActionCreator(
  getClosedSharedOffersRoutine,
  async ({ group_id = null, query = null, limit = 25, offset = 0, orders = { dealspace: { updated_at: 'desc' }, updated_at: 'desc' } }) => {
    const statuses = ['closed'];
    const params = { limit, offset, orders, attributes: sharedOffersAttributes, statuses, ...!group_id && { sent: true }, ...query && { query } };

    return await axios.get(`${!!group_id ? `/groups/${group_id}` : ''}/offers`, { params });
  }
);
export const getGroupOffersForChats = getThunkActionCreator(
  getGroupOffersForChatsRoutine,
  async ({ group_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const params = { limit, offset, orders, attributes: shortAttributes, with_offer_conversations: true };

    return await axios.get(`/groups/${group_id}/offers`, { params });
  }
);
export const getDealspaceOffers = getThunkActionCreator(
  getDealspaceOffersRoutine,
  async ({ dealspace_id, offset = 0, type = 'shared' }) => {
    const params = { 
      limit: 25, 
      offset, 
      orders: { updated_at: 'desc' },
      attributes: dealspaceOffersAttributes, 
      statuses: type === 'shared' ? ['active', 'in_contract', 'ended', 'closed'] : [type]
    };

    const response = await axios.get(`/dealspaces/${dealspace_id}/offers`, { params });

    return { response, type };
  }
);
export const getBuilderDraft = getThunkActionCreator( // TODO переименовать когда будет время сам экшна (на бэке гет сменился на пост)
  getBuilderDraftRoutine,
  async id => {
    const attributes = [
      'id',
      'locked',
      'locations_locked',
      'locked_documents_count',
      'locked_folders_count',
      'approval_required_folders_count',
      'approval_required_documents_count',
      'total_unlocked_documents_count',
      'total_locked_documents_count',
      'total_approval_required_documents_count',
      'status',
      'name',
      'version',
      'email_style',
      'recipients_count',
      'email_opened_recipients_count',
      'visited_recipients_count',
      'unlocked_recipients_count',
      'tag',
      'tracked',
      'visited',
      'senders_count',
      'recipients_count',
      'deal_type',
      'headline',
      'description',
      'deadline_type',
      'deadline_at',
      'expired_at',
      'risk_profiles',
      'property_grouping',
      'asset_classes',
      'lease_period',
      'loan_ratio',
      'loan_percent',
      'investment_type',
      'price_cents',
      'custom_price',
      'saving_allowed',
      'public',
      'min_latitude',
      'max_latitude',
      'min_longitude',
      'max_longitude',
      'created_at',
      'properties_count',
      { root_folder: ['id'] },
      { locations: ['id', { property: ['id', 'name'] }, 'address', 'latitude', 'longitude', 'parcel', 'approximate_address', 'approximate_latitude', 'approximate_longitude'] },
      { logo: ['id', 'url'] },
      { dealspace: ['id', 'name', { root_folder: ['id'] }, { group: ['id', 'name'] }] },
      { offer: ['id', 'headline', 'recipients_count', 'email_opened_recipients_count', 'visited_recipients_count', 'unlocked_recipients_count'] },
      { main_location: [ { property: ['name'] }, 'address', 'latitude', 'longitude', 'approximate_address'] },
      { primary_sender: ['id', { user: ['id', 'first_name', 'last_name', { photo: ['representations'] }, 'current_contact'] }] },
    ];
    const data = { attributes };

    return await axios.post(`/offers/${id}/draft`, data);
  }
);
export const getBuilderDraftActualFilesCount = getThunkActionCreator( // TODO переименовать когда будет время сам экшна (на бэке гет сменился на пост)
  getBuilderDraftActualFilesCountRoutine,
  async id => {
    const attributes = [
      'id',
      'locked_documents_count',
      'locked_folders_count',
      'approval_required_folders_count',
      'approval_required_documents_count',
      'total_unlocked_documents_count',
      'total_locked_documents_count',
      'total_approval_required_documents_count'
    ];
    const data = { attributes };

    return await axios.post(`/offers/${id}/draft`, data);
  }
);
export const getFeedMapOffers = getThunkActionCreator(
  getFeedMapOffersRoutine,
  async ({ latitude_gt, latitude_lt, longitude_gt, longitude_lt }) => {
    let offset = 0;
    let offersReady = false;
    let offers = [];
    const attributes = [
      'id',
      'tag',
      'tracked',
      'visited',
      'public',
      'deadline_at',
      'headline',
      'shared_at',
      'status',
      'senders_count',
      'asset_classes',
      'properties_count',
      'disinterested',
      'risk_profiles',
      'sent',
      { saved_dealspace: ['id', { group: ['id'] }] },
      { main_location: ['address', 'approximate_address'] },
      { primary_sender: ['id', { user: ['id', 'first_name', 'last_name', { photo: ['representations'] }] }] },
      { locations: ['id', { property: ['id', 'name'] }, 'address', 'latitude', 'longitude', 'min_latitude', 'max_latitude', 'min_longitude', 'max_longitude', 'parcel', 'approximate_address', 'approximate_latitude', 'approximate_longitude'] }
    ];

    while (!offersReady) {
      const params = { 
        limit: 25, 
        offset, 
        latitude_gt, 
        latitude_lt, 
        longitude_gt, 
        longitude_lt, 
        attributes, 
        statuses: ['active', 'in_contract']
      };
      const response = await axios.get(`/offers`, { params })
      const pagination = response.data.pagination;
      const uploadedOffers = response.data.offers;

      if (pagination.count + pagination.offset < pagination.total_count) {
        offers = [...offers, ...uploadedOffers];
        offset += 25;
      } else {
        offers = [...offers, ...uploadedOffers];
        offersReady = true;
      }
    }
    return { offers };
  }
);
export const getActivityLogOffer = getThunkActionCreator(
  getActivityLogOfferRoutine,
  async id => {
    const params = { attributes: activityLogAttributes };

    return await axios.get(`/offers/${id}`, { params });
  }
);
export const getOfferActualRecipientsCount = getThunkActionCreator(
  getOfferActualRecipientsCountRoutine,
  async id => {
    const params = { attributes: recipientsAttributes };

    return await axios.get(`/offers/${id}`, { params });
  }
);
export const getOfferPreview = getThunkActionCreator(
  getOfferPreviewRoutine,
  async id => {
    const attributes = [
      'id',
      'name',
      'tag',
      'version',
      'headline',
      'description',
      'public',
      'locked',
      'visited', 
      'tracked',
      'status',
      'lease_period',
      'loan_ratio',
      'loan_percent',
      'investment_type',
      'price_cents',
      'custom_price',
      'published_at',
      'deadline_at',
      'deadline_type',
      'min_latitude', 
      'max_latitude', 
      'min_longitude', 
      'max_longitude',
      'locations_locked',
      'locked_documents_count',
      'locked_folders_count',
      'approval_required_folders_count',
      'approval_required_documents_count',
      'total_unlocked_documents_count',
      'total_locked_documents_count',
      'total_approval_required_documents_count',
      'asset_classes',
      'properties_count',
      'disinterested',
      'risk_profiles',
      { root_folder: ['id'] },
      { logo: ['id', 'url'] },
      { offer:['id'] },
      { saved_dealspace: ['id', 'name'] },
      { main_location: ['address', 'latitude', 'longitude', 'approximate_address'] },
      { locations: ['id', {property: ['id', 'name']}, 'latitude', 'longitude', 'min_latitude', 'max_latitude', 'min_longitude', 'max_longitude', 'parcel', 'approximate_address', 'approximate_latitude', 'approximate_longitude'] },
      { primary_sender: ['id', { user: ['id', 'first_name', 'last_name', { photo: ['representations'] } ]} ] }
    ];
    const params = { attributes };

    return await axios.get(`/offers/${id}`, { params });
  }
);
export const createOffer = getThunkActionCreator(
  createOfferRoutine,
  async ({ dealspace_id, offer }) => {
    const data = { offer, attributes: dealspaceOffersAttributes };

    return await axios.post(`/dealspaces/${dealspace_id}/offers`, data);
  }
);
export const updateOffer = getThunkActionCreator(
  updateOfferRoutine,
  async ({ id, ...fields }) => {
    const data = { offer: _omit(fields, ['custom', 'standart']) };
    const response = await axios.patch(`/offers/${id}`, data);

    return { response, offer: { id, ...fields } };
  }
);
export const deleteOffer = getThunkActionCreator(
  deleteOfferRoutine,
  async ({ id, source }) => {
    const response = await axios.delete(`/offers/${id}`);

    return { response, source, id };
  }
);
export const publishOffer = getThunkActionCreator(
  publishOfferRoutine,
  async ({ id, message, notify, recipients_type = null, subject = null }) => {
    const data = {
      message,
      notify,
      ...recipients_type && { recipients_type },
      ...subject && { subject }
    };

    return await axios.post(`/offers/${id}/publish`, data);
  }
);
export const activateOffer = getThunkActionCreator(
  activateOfferRoutine,
  async id => {
    const response = await axios.post(`/offers/${id}/activate`);

    return { response, id };
  }
);
export const contractOffer = getThunkActionCreator(
  contractOfferRoutine,
  async id => {
    const response = await axios.post(`/offers/${id}/contract`);

    return { response, id };
  }
);
export const endOffer = getThunkActionCreator(
  endOfferRoutine,
  async id => {
    const response = await axios.post(`/offers/${id}/end`);

    return { response, id };
  }
);
export const closeOffer = getThunkActionCreator(
  closeOfferRoutine,
  async id => {
    const response = await axios.post(`/offers/${id}/close`);

    return { response, id };
  }
);
export const disinterestOffer  = getThunkActionCreator(
  disinterestOfferRoutine,
  async id => {
    const response = await axios.post(`/offers/${id}/disinterest`);
    
    return { response, id };
  }
);
export const saveOffer = getThunkActionCreator(
  saveOfferRoutine,
  async ({ offer_id, dealspace_id = null, group_id = null, locationType = null }) => {
    const data = { ...dealspace_id && { dealspace_id }, ...group_id && { group_id } };

    const response = await axios.post(`/offers/${offer_id}/save`, data);

    return { response, locationType, offer_id, dealspace_id };
  }
);
export const duplicateOffer = getThunkActionCreator(
  duplicateOfferRoutine,
  async ({ id, type }) => {
    const groupAttributes = [
      'id', { dealspace: ['id', 'name', 'cover'] }, 'tag', 'name', 'status',
      { offer: ['id', 'name', 'public', 'status', 'tag'] }, 'deadline_type', 'deadline_at', 'expired_at',
      'recipients_count', 'version', 'visited_recipients_count', 'public'
    ];
    const data = { attributes: type === 'group' ? groupAttributes : dealspaceOffersAttributes };
    const response = await axios.post(`/offers/${id}/duplicate`, data);

    return { response, type };
  }
);
export const visitOffer = getThunkActionCreator(
  visitOfferRoutine,
  async offer_id => {
    const response =  await axios.post(`/offers/${offer_id}/visit`);

    return { response, offer_id };
  }
);
export const exportOffer = getThunkActionCreator(
  exportOfferRoutine,
  async offer_id => {
    return await axios.post(`/offers/${offer_id}/export`, {}, { responseType: "blob" });
  }
);
export const intakeOffer = getThunkActionCreator(
  intakeOfferRoutine,
  async ({ owner_id, message = null, user, properties, offer, images, documents }) => {
    return await axios.post('/offers/intake', { owner_id, ...message && { message }, user, properties, offer, images, documents });
  }
);
export const reshareActiveOffers = getThunkActionCreator(
  reshareActiveOffersRoutine, 
  async email => {
    return await axios.post('/offers/reshare', { email })
  });
export const submitOfferShareForm = getThunkActionCreator(
  submitOfferShareFormRoutine,
  async ({ id, email, first_name, last_name, mobile_phone }) => {
    return await axios.post(`/offers/${id}/submit_share_form`, { email, first_name, last_name, mobile_phone });
  }
);
export const setOfferUnreadCountConversations = getThunkActionCreator(setOfferUnreadCountConversationsRoutine, async ({ unread_conversations_count, offer_id }) => ({ unread_conversations_count, offer_id }));
export const setLocalOfferCounts = getThunkActionCreator(setLocalOfferCountsRoutine, async ({ offer_id, recipients_count, unlocked_recipients_count, approval_requested_recipients_count }) => ({ offer_id, recipients_count, unlocked_recipients_count, approval_requested_recipients_count }));
export const clearLocalSharedOffers = getThunkActionCreator(clearLocalSharedOffersRoutine, async type => ({ type }));
export const clearLocalContactActiveOffers = getThunkActionCreator(clearLocalContactActiveOffersRoutine, async () => {});
export const clearLocalDealspaceOffers = getThunkActionCreator(clearLocalDealspaceOffersRoutine, async () => {});
export const clearLocalOffersForChats = getThunkActionCreator(clearLocalOffersForChatsRoutine, async () => {});
export const clearLocalBuilderOffer = getThunkActionCreator(clearLocalBuilderOfferRoutine, async () => {});
export const clearLocalFeedMapOffers = getThunkActionCreator(clearLocalFeedMapOffersRoutine, async () => {});
export const clearLocalFeedOffer = getThunkActionCreator(clearLocalFeedOfferRoutine, async () => {});
export const clearLocalActivityLogOffer = getThunkActionCreator(clearLocalActivityLogOfferRoutine, async () => {});
export const unlockLocalOffer = getThunkActionCreator(unlockLocalOfferRoutine, async id => ({ id }));
export const setOfferLocalFields = getThunkActionCreator(setOfferLocalFieldsRoutine, async fields => fields);
export const setBuilderState = getThunkActionCreator(setBuilderStateRoutine, async state => state);