import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getAgreementsRoutine,
  getAgreementRoutine,
  createAgreementRoutine,
  clearLocalAgreementsRoutine
} from 'actions';

export const getAgreements = getThunkActionCreator(
  getAgreementsRoutine,
  async ({ limit = 25, offset = 0, orders = { created_at: 'desc' } }) => {
    const attributes = [
      'id',
      { template: [{ offer: [ 'id', 'headline', { primary_sender: ['id', { user: ['id', 'first_name', 'last_name', { photo: ['representations'] }]}] }] },] },
      { file: ['filename', 'url'] },
      'created_at'
    ];
    const params = { limit, offset, orders, attributes };

    return await axios.get('/agreements', { params });
  }
);
export const getAgreement = getThunkActionCreator(
  getAgreementRoutine,
  async id => {
    const attributes = [
      'id',
      { template: [{ offer: [ 'id', 'headline', { primary_sender: ['id', { user: ['id', 'first_name', 'last_name', { photo: ['representations'] }]}] }] },] },
      { file: ['url'] },
      'created_at'
    ];
    const params = { attributes };

    return await axios.get(`/agreements/${id}`, { params });
  }
);
export const createAgreement = getThunkActionCreator(
  createAgreementRoutine,
  async ({ offer_id, agreement }) => {
    const attributes = ['id', 'boldsign_document_url', { template: [{ file: ['url', 'filename'] }] }];

    const data = { attributes, agreement };

    return await axios.post(`/offers/${offer_id}/agreement`, data);
  }
);
export const clearLocalAgreements = getThunkActionCreator(clearLocalAgreementsRoutine, async () => {});