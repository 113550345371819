import { useCallback } from 'react';
import LogRocket from 'logrocket';
import { mode, logrocketId } from 'apis';

const env_check = mode === 'production';

const useLogrocket = () => {
  const init = useCallback(() => {
    if (env_check) LogRocket.init(logrocketId);
  }, []);
  const identify = useCallback(id => {
    if (env_check) LogRocket.identify(id);
  }, []);

  return { init, identify };
};

export default useLogrocket;