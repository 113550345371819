import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getSavedOfferGroupsRoutine,
  getGroupsRoutine,
  getGroupRoutine,
  createGroupRoutine,
  updateGroupRoutine,
  deleteGroupRoutine,
  setGroupUnreadCountConversationsRoutine,
  clearLocalSavedOfferGroupsRoutine,
  clearLocalGroupsRoutine,
  clearLocalActiveGroupRoutine
} from 'actions';
import _omit from 'lodash/omit';

const savedAttributes = ['id', { logo: ['representations'] }, 'name'];
const attributes = [
  'id',
  'default',
  { logo: ['id', 'representations', 'filename'] },
  { header: ['id', 'url', 'filename'] },
  'name', 'unread_dealspaces_due_offer_conversations_count',
  'unread_dealspaces_due_dealspace_conversations_count',
  { current_member: ['role'] },
  'members_count',
  { members: ['id', { user: ['id', 'first_name', 'last_name', { photo: ['id', 'representations'] }] }] }
];

export const getSavedOfferGroups = getThunkActionCreator(
  getSavedOfferGroupsRoutine,
  async offer_id => {
    const params = { limit: 25, offset: 0, attributes: savedAttributes };

    return await axios.get(`/offers/${offer_id}/groups/saved`, { params });
  }
);
export const getGroups = getThunkActionCreator(
  getGroupsRoutine,
  async ({ limit = 25, offset = 0, orders = { default: 'desc' } }) => {
    const params = { limit, offset, orders, attributes };

    return await axios.get('/groups', { params });
  }
);
export const getGroup = getThunkActionCreator(
  getGroupRoutine,
  async id => {
    const params = { attributes };

    return await axios.get(`/groups/${id}`, { params });
  }
);
export const createGroup = getThunkActionCreator(
  createGroupRoutine,
  async group => {
    const data = { group: _omit(group, ['localLogo', 'localHeader']), attributes };

    return await axios.post('/groups', data);
  }
);
export const updateGroup = getThunkActionCreator(
  updateGroupRoutine,
  async ({ id, ...fields }) => {
    const data = { group: _omit(fields, ['localLogo', 'localHeader']) };
    const response = await axios.patch(`/groups/${id}`, data);

    return { response, group: { id, ...fields } };
  }
);
export const deleteGroup = getThunkActionCreator(
  deleteGroupRoutine,
  async id => {
    const response = await axios.delete(`/group/${id}`);

    return { response, id };
  }
);
export const setGroupUnreadCountConversations = getThunkActionCreator(setGroupUnreadCountConversationsRoutine, async fields => fields);
export const clearLocalSavedOfferGroups = getThunkActionCreator(clearLocalSavedOfferGroupsRoutine, async () => {});
export const clearLocalGroups = getThunkActionCreator(clearLocalGroupsRoutine, async () => {});
export const clearLocalActiveGroup = getThunkActionCreator(clearLocalActiveGroupRoutine, async () => {});