import { memo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useError from 'hooks/useError';
import useRecipients from 'hooks/useRecipients';
import useSuccess from 'hooks/useSuccess';

const GrantAccess = ({ id, user, open, onClose }) => {
  const { setError } = useError();
  const { updateRecipient } = useRecipients();
  const { setSuccess } = useSuccess();
  const [processing, setProcessing] = useState(false);
  
  const handleSubmit = () => {
    const updatableRecipient = { id, approved: true };

    setProcessing(false);
    updateRecipient(updatableRecipient)
    .then(() => setSuccess(`Level 2 access granted to ${user}`).then(onClose))
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{ sx: { maxWidth: 490, width: '100%' } }}
      title=' '
      content={
        <Box pt={1} pb={2} pl={4} pr={4}>
          <Typography fontWeight={600} fontSize='16px' lineHeight='20px' textAlign='center' mb={1.5} color='rgba(0, 0, 0, 0.8)'>
            Are you sure you would like to grant Level 2 access to this user?
          </Typography>
        </Box>
      }
      actions={
        <>
          <Button
            variant='text'
            sx={{ px: '24px' }}
            disabled={processing}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            sx={{ px: '24px'}}
            disabled={processing}
            onClick={handleSubmit}
          >
            Grant Access
          </Button>
        </>
      }
    />
  );
};

GrantAccess.propTypes = {
  id: PropTypes.string,
  user: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <GrantAccess {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);