import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: { lg: '60px' },
  padding: '32px 0',
  [theme.breakpoints.up('lg')]: {
    gap: '60px',
    padding: '32px'
  }
}));
const NavContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  width: 240,
  padding: '24px 0px',
  [theme.breakpoints.up('md')]: {
    display: 'block'
  }
}));

export { Root, NavContainer };