import {
  getMessagesRoutine,
  getMessageRoutine,
  createMessageRoutine,
  createLocalMessageRoutine,
  deleteMessageRoutine,
  deleteLocalMessageRoutine,
  readMessagesRoutine,
  setLocalMessageErrorRoutine,
  setLocalReadStatusRoutine,
  clearLocalMessagesRoutine,
  clearLocalActiveMessageRoutine
} from 'actions';
import _drop from 'lodash/drop';
import _dropRight from 'lodash/dropRight';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';

export const initialState = {
  data: [],
  upPagination: { limit: 25, offset: 0, count: 0, total_count: 0 },
  downPagination: { limit: 25, offset: 0, count: 0, total_count: 0 },
  message: { id: '', user: { id: '', first_name: '', last_name: '' }, attachment: null, text: '', read: false, created_at: '' }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getMessagesRoutine.SUCCESS: {
      const { response: { data: { messages, pagination } }, center_on_unread_message, direction, my_id } = action.payload;
      const unreadForMe = center_on_unread_message && !!my_id ? _filter(messages, (item) => !item.read && !!item.user && item.user.id !== my_id).length : 0;
      let oldMessages = _drop(messages, unreadForMe);
      if (!!unreadForMe) oldMessages = [...[{ id: 'divider' }],  ...oldMessages];
      const newMessages = _dropRight(messages, messages.length - unreadForMe);

      return { 
        ...state, 
        ...(center_on_unread_message ? {
          data: _uniqBy([...newMessages, ...oldMessages], 'id'),
          upPagination: pagination,
          downPagination: pagination
        } : {
          ...(direction === 'up' && {
            data: _uniqBy([...state.data, ...messages], 'id'),
            upPagination: pagination,
          }),
          ...(direction === 'down' && {
            data: _uniqBy([...messages, ...state.data], 'id'),
            downPagination: pagination
          })
        })
      };
    }
    case getMessageRoutine.SUCCESS: {
      const { response: { data: { message } }, isConversationMessage } = action.payload;

      return {
        ...state,
        message: { ...state.message, ...message },
        ...(isConversationMessage && !!_find(state.data, (item) => item.id !== message.id) && { 
          data: [...[message], ...state.data],
          upPagination: { 
            ...state.upPagination, 
            count: state.upPagination.count + (state.upPagination.count < state.upPagination.limit ? 1 : 0), 
            total_count: state.upPagination.total_count + 1 
          },
          downPagination: { ...state.downPagination, total_count: state.downPagination.total_count + 1 }
        })
      };
    }
    case createLocalMessageRoutine.SUCCESS: {
      return { 
        ...state,
        data: [...[action.payload], ...state.data],
        upPagination: { 
          ...state.upPagination, 
          count: state.upPagination.count + (state.upPagination.count < state.upPagination.limit ? 1 : 0), 
          total_count: state.upPagination.total_count + 1 
        },
        downPagination: { ...state.downPagination, total_count: state.downPagination.total_count + 1 }
      };
    }
    case createMessageRoutine.SUCCESS: {
      const { response: { data: { message } }, local_id } = action.payload;

      return {
        ...state,
        ...(!!_find(state.data, (item) => item.id === local_id) && { 
          data: _map(state.data.slice(0, !!state.upPagination.offset ? state.data.length : state.upPagination.limit), (item) => item.id === local_id ? message : item)
        })
      };
    }
    case deleteMessageRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return {
        ...state,
        ...(status === 204 && {
          data: _filter(state.data, (item) => item.id !== id),
          downPagination: { ...state.downPagination, total_count: state.downPagination.total_count - 1 }
        })
      };
    }
    case deleteLocalMessageRoutine.SUCCESS: {
      return {
        ...state,
        ...(!!_find(state.data, (item) => item.id === action.payload) && {
          data: _filter(state.data, (item) => item.id !== action.payload),
          upPagination: { ...state.upPagination, count: state.upPagination.count - 1, total_count: state.upPagination.total_count - 1 }
        })
      };
    }
    case readMessagesRoutine.SUCCESS: {
      const { response: { status }, message_ids } = action.payload;

      return {
        ...state,
        ...(status === 204 && { 
          data: _filter(_map(state.data, 
            (item) => !!!message_ids || !!_find(message_ids, (message) => message === item.id) ? { ...item, read: true } : item), 
            (item) => item.id !== 'divider')
        })
      };
    }
    case setLocalMessageErrorRoutine.SUCCESS: {
      const { id } = action.payload;

      return { ...state, data: _map(state.data, message => message.id === id ? { ...message, hasError: true } : message) };
    }
    case setLocalReadStatusRoutine.SUCCESS: {
      const { id } = action.payload;

      return { ...state,
        ...(!!_find(state.data, (item) => item.id === id) && { 
          data: _map(state.data, (item) => item.id === id ? { ...item, read: true } : item)
        })
      };
    }
    case clearLocalMessagesRoutine.SUCCESS: {
      return {
        ...state,
        data: initialState.data, 
        upPagination: initialState.upPagination, 
        downPagination: initialState.downPagination
      };
    }
    case clearLocalActiveMessageRoutine.SUCCESS: {
      return { ...state, message: initialState.message }
    }
    default: {
      return state;
    }
  };
};

export default reducer;