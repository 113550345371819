import { useCallback } from 'react';
import chmln from '@chamaeleonidae/chmln';
import { chameleonToken, mode } from 'apis';

const env_check = mode !== 'development';

const useChameleon = () => {
  const init = useCallback(() => {
    if (env_check) chmln.init(chameleonToken, { fastUrl: 'https://fast.chameleon.io/' });
  }, []);
  const identify = useCallback(({ userId, fields }) => {
    if (env_check) chmln.identify(userId, fields);
  }, []);
  const track = useCallback(event => {
    if (env_check) chmln.track(event);
  }, []);

  return { init, identify, track };
};

export default useChameleon;