import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';

const FloatingButton = styled((props) => (
  <Button color='primary' variant='contained' {...props} />
))(({ theme }) => ({
  position: 'absolute',
  right: 16,
  bottom: 56 + 16,
  whiteSpace: 'nowrap',
  padding: '12px',
  [theme.breakpoints.up('md')]: {
    right: 40,
    bottom: 40,
  }
}));

export default FloatingButton;