import { memo, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosRounded';
import _pick from 'lodash/pick';
import _map from 'lodash/map';
import { Parser } from 'html-to-react';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Root, NavContainer, DetailsContainer, DetailsPaper, BackButton, InnerRoot, Logo, ScrollButton, Description } from './Offer.styled';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import FlowNav from 'components/Offers/FlowNav/FlowNav';
import Main from 'components/Offers/Details/Main/Main';
import Images from 'components/Offers/Details/Images/Images';
import Files from 'components/Offers/Details/Files/Files';
import Senders from 'components/Offers/Details/Senders/Senders';
import Map from 'components/Offers/Details/Map/Map';
import { ReactComponent as ScrollIcon } from 'assets/icons/scrollToTop.svg';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { getRepresentation } from 'helpers';
import { representationVariants } from 'helpers/constants';
import Stack from '@mui/material/Stack';

const Offer = ({ offer, senders, images, publicFiles, publicFolders, onClose }) => {
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const refMain = useRef(null);
  const refFiles = useRef(null);
  const refSenders = useRef(null);
  const refMap = useRef(null);
  const mainProps = {
    ..._pick(offer, [
      'id', 'tag', 'primary_sender', 'locked', 'saved_dealspace', 'tracked', 'headline', 'status', 'lease_period', 'loan_ratio', 'loan_percent',
      'investment_type', 'price_cents', 'custom_price', 'main_location', 'published_at', 'deadline_at', 'deadline_type', 'locations_locked',
      'locked_folders_count', 'approval_required_documents_count', 'approval_required_folders_count', 'asset_classes', 'properties_count',
      'disinterested', 'risk_profiles', 'total_locked_documents_count', 'total_unlocked_documents_count', 'total_approval_required_documents_count'
    ]),
    senders,
    disabled: true,
    preview: true,
    isMyShared: false
  };
  const imagesProps = { data: images };
  const filesProps = {
    publicFiles,
    privateLevel1Files: _map([...Array(offer.locked_documents_count).keys()], f => ({ id: f, file: { filename: f, content_type: '' }, locked: true })),
    privateLevel2Files: _map([...Array(offer.approval_required_documents_count).keys()], f => ({ id: f, file: { filename: f, content_type: '' }, locked: true })),
    publicFolders,
    privateLevel1Folders: _map([...Array(offer.locked_folders_count).keys()], f => ({ id: f, name: '', documents_count: 0, documents_byte_size: 0, locked: true })),
    privateLevel2Folders: _map([...Array(offer.approval_required_folders_count).keys()], f => ({ id: f, name: '', documents_count: 0, documents_byte_size: 0, locked: true })),
    total: offer.total_locked_documents_count + offer.total_unlocked_documents_count + offer.total_approval_required_documents_count,
    showUnlock: !!offer.total_locked_documents_count || !!offer.total_approval_required_documents_count || offer.locations_locked,
    preview: true,
    offerApproved: false
  };
  const sendersProps = { data: senders, disabled: true };
  const mapProps = { activeOffer: offer, preview: true };

  const scrollToMain = () => refMain.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  const scrollToFiles = () => refFiles.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  const scrollToSenders = () => refSenders.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  const scrollToMap = () => refMap.current.scrollIntoView({ behavior: 'smooth', block: 'end' });

  if (mediaDesktop) {
    return (
      <Root>
        <NavContainer>
          <FlowNav data={[offer]} />
        </NavContainer>
        <DetailsContainer>
          <DetailsPaper>
            <InnerRoot>
              <Main
                ref={refMain}
                scrollToFiles={scrollToFiles}
                scrollToSenders={scrollToSenders}
                scrollToMap={scrollToMap}
                {...mainProps}
              />
              {!!offer.logo &&
                <Logo
                  src={offer.logo.url}
                  alt='logo'
                />
              }
              <Stack sx={{ alignItems: 'center', '& > *:not(:first-of-type)': { borderTop: '1px solid #e0e0e0'  } }}>
                <Images {...imagesProps} />
                {!!offer.description && (
                  <Container maxWidth={false} sx={{ maxWidth: '624px', overflow: 'auto', px: '12px !important', pt: 0 }}>
                    <Description>{Parser().parse(offer.description)}</Description>
                  </Container>
                )}
                <Files
                  ref={refFiles}
                  onLoadMore={() => {}}
                  onUnlock={() => {}}
                  {...filesProps}
                />
              </Stack>
              <Senders
                ref={refSenders}
                {...sendersProps}
              />
              <ScrollButton onClick={scrollToMain}>
                <ScrollIcon /><span>Scroll to top</span>
              </ScrollButton>
              <Box ref={refMap} sx={{ height: { xs: '70vh', md: '50vh', lg: '70vh' }, width: '100%' }}>
                <Map {...mapProps} />
              </Box>
            </InnerRoot>
          </DetailsPaper>
        </DetailsContainer>
      </Root>
    );
  }
  return (
    <>
      <FlowNav data={[offer]} />
      <BaseDialog
        open
        fullScreen
        hideCloseButton
        PaperProps={{ sx: { paddingTop: '80px' } }}
      >
        <BackButton onClick={onClose}>
          <ArrowBackIcon />Back
        </BackButton>
        <InnerRoot>
          <Main
            ref={refMain}
            scrollToFiles={scrollToFiles}
            scrollToSenders={scrollToSenders}
            scrollToMap={scrollToMap}
            {...mainProps}
          />
          {!!offer.group_header &&
            <Logo
              src={getRepresentation(offer.group_header.representations, representationVariants.TINY)}
              alt='logo'
            />
          }
          <Images {...imagesProps} />
          <Container maxWidth={false} sx={{ maxWidth: '624px', px: '12px !important', pt: 0 }}>
            <Divider sx={{ mb: '1rem' }} />
            <Description>{Parser().parse(offer.description)}</Description>
            <Divider sx={{ mt: '1rem' }} />
          </Container>
          <Files
            ref={refFiles}
            onLoadMore={() => {}}
            onUnlock={() => {}}
            {...filesProps}
          />
          <Senders
            ref={refSenders}
            {...sendersProps}
          />
          <ScrollButton onClick={scrollToMain}>
            <ScrollIcon /><span>Scroll to top</span>
          </ScrollButton>
          <Box
            ref={refMap}
            sx={{ height: { xs: '92vh', md: '50vh', lg: '70vh' }, width: '100%' }}
          >
            <Map {...{refMap, preview: true, activeOffer: offer }} />
          </Box>
        </InnerRoot>
      </BaseDialog>
    </>
  );
};

Offer.propTypes = {
  offer: PropTypes.object, // TODO
  senders: PropTypes.array.isRequired,
  images: PropTypes.array.isRequired,
  publicFiles: PropTypes.array.isRequired,
  publicFolders: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Offer {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);