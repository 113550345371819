import { memo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import _find from 'lodash/find';
// Local files
import { states } from 'helpers/constants';

const StatesAutocomplete = ({ value, onChange }) => {
  return (
    <Autocomplete
      freeSolo
      value={_find(states, s => s.value === value) ?? ''}
      onChange={(_, v) => onChange(v?.value ?? null)}
      options={states}
      renderInput={params => <TextField {...params} label='Select State' />}
      isOptionEqualToValue={(o, v) => !!v ? o.value === v : ''}
      getOptionLabel={o => o.label ?? ''}
    />
  );
};

export default memo(StatesAutocomplete);