import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';

export const Root = styled('div')({
  position: 'relative',
  borderRadius: 6,
});

export const ThumbsContainer = styled(Box)({
  maxWidth: 600,
  paddingTop: 8,
  margin: '0 auto',
  '& .swiper-slide-thumb-active > div': {
    boxShadow: 'inset 0 0 0 2px rgba(0,0,0,.8)',
  }
});

export const Image = styled('img')(() => ({
  userSelect: 'none',
  display: 'block',
  height: '100%',
  maxHeight: 450,
  width: 'auto',
  maxWidth: '100%',
  objectFit: 'cover',
  margin: '0 auto'
}));

export const Preview = styled(Box)(() => ({
  display: 'block',
  width: 80,
  height: 46,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

export const StyledButton = styled(ButtonBase)({
  position: 'absolute',
  top: 390,
  right: 18,
  width: 40,
  height: 40,
  borderRadius: '50%',
  backgroundColor: '#fff',
  boxShadow: '0px 0px 7px 0px #0000001A',
  zIndex: 2,
});