import styled from '@mui/material/styles/styled';
import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';

export const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  width: '100%',
  flexDirection: 'column',
  gap: '12px 6px',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    '& > label': {
      width: 'calc(50% - 3px)'
    }
  }
}));

export const DateFormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '4px 0 0',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    '& > label': {
      flexBasis: '55%'
    },
    '& > div': {
      flexBasis: '45%'
    }
  }
}));

export const FileInput = styled('input')({ display: 'none' });