import { memo, useEffect, useRef, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { FileInput } from './UploadAgreement.styled';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import FileCard from 'components/Documents/Card/Card';
import DiscardTemplate from '../DiscardTemplate/DiscardTemplate';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useBlob from 'hooks/useBlob';
import useChecksum from 'hooks/useChecksum';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useTemplates from 'hooks/useTemplates';

const UploadAgreement = ({ preview = null, documentId = null, blob = null, open, onClose, onChange = null }) => {
  const { processBlob } = useBlob();
  const { processFiles } = useChecksum();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { createTemplate } = useTemplates();
  const { offerId, configuredTemplateId } = useCustomSelector(state => ({
    offerId: state.offers.builder.offer.id,
    configuredTemplateId: state.templates.configuredTemplateId
  }));
  const inputRef = useRef(null);
  const [blobId, setBlobId] = useState(null);
  const [fileName, setFileName] = useState('');
  const [contentType, setContentType] = useState('');
  const [name, setName] = useState('Confidentiality Agreement');
  const [url, setUrl] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [fileUploading, setFileUploading] = useState(true);
  const [processing, setProcesing] = useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const [allowClose, setAllowClose] = useState(true);
  const title = preview ? 'Preview' : (!url ? 'Upload Confidentiality Agreement' : 'Place fields on document, then click ‘Create template’');
  const disabled = fileUploading || processing;

  const onEntering = () => {
    if (documentId) {
      createTemplate({ offer_id: offerId, document_id: documentId })
      .then(({ payload: { data: { template } } }) => {
        setSuccess('Template successfully created')
        .then(() => {
          setTemplateId(template.id);
          setUrl(template.boldsign_template_url);
          setAllowClose(false);
          setFileUploading(false);
        });
        onChange(template);
      })
      .catch(e => setError(e).then(() => setFileUploading(false)));
    }
    if (preview) {
      setUrl(preview);
      setFileUploading(false);
    }
    if (blob) {
      setBlobId(blob.file);
      setFileName(blob.filename);
      setContentType(blob.content_type);
      setFileUploading(false);
    }
  };
  const onExited = () => {
    setBlobId(null);
    setFileName('');
    setContentType('');
    setName('Confidentiality Agreement');
    setUrl('');
    setTemplateId('');
    setFileUploading(true);
    setProcesing(false);
    setAllowClose(true);
  };
  const handleFilesChange = ({ target: { files } }) => {
    setFileUploading(true);
    processFiles(files, ({ file, checksum }) => {
      processBlob({ file, checksum }, blob => {
        if (!blob.id) return;
        setBlobId(blob.id);
        setFileName(blob.filename);
        setContentType(blob.content_type);
        setFileUploading(false);
      });
    });
  };
  const handleSubmit = () => {
    if (!url) {
      setProcesing(true);
      createTemplate({ offer_id: offerId, template: { file: blobId, name } })
      .then(({ payload: { data: { template } } }) => {
        setSuccess('Template successfully created')
        .then(() => {
          setTemplateId(template.id);
          setUrl(template.boldsign_template_url);
          setAllowClose(false);
        });
        onChange(template);
      })
      .catch(e => setError(e))
      .finally(() => setProcesing(false));
    } else {
      handleClose();
    }
  };
  const handleClose = () => {
    if (allowClose) {
      onClose();
    } else {
      setDiscardDialogOpen(true);
    }
  };
  const handleDiscardDialogClose = result => {
    setDiscardDialogOpen(false);

    if (result) {
      onClose(templateId);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (configuredTemplateId === templateId && !!configuredTemplateId && !!templateId) {
        onClose();
      }
    }, 4000);
    
    return () => clearTimeout(timeout);    
  }, [configuredTemplateId, templateId]); // eslint-disable-line

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      maxWidth={!!url ? 'xs' : false}
      fullScreen={!!url}
      fullWidth={!!url}
      PaperProps={{ sx: { ...!url && { maxWidth: 510 }, width: '100%' } }}
      TransitionProps={{ onExited, onEntering }}
      title={title}
      customCloseButton={!allowClose ?
        <ButtonBase
          onClick={handleClose}
          sx={{ textDecoration: 'underline', position: 'absolute', top: 15, right: 15, zIndex: 1 }}
        >
          Discard template
        </ButtonBase> : null
      }
      actions={!url &&
        <>
          <Button
            variant='text'
            color='error'
            sx={{ px: '24px' }}
            disabled={processing}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            sx={{ px: '24px'}}
            disabled={disabled || processing}
            onClick={handleSubmit}
          >
            Next
          </Button>
        </>
      }
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: '16px !important', pb: '32px' }}>
        <DiscardTemplate
          open={discardDialogOpen}
          onClose={handleDiscardDialogClose}
        />
        {fileUploading ? <CircularProgress /> :
          (!!url ?
            <iframe
              src={url}
              title={name}
              width='100%'
              height='100%'
              style={{ border: 'none' }}
            /> :
            <>
              <FileCard
                title={fileName}
                type={contentType}
                hideMenu
                showFileSize
              />
              <Link
                component={ButtonBase}
                sx={{ mt: 1, mb: 4 }}
                onClick={() => inputRef.current.click()}
              >
                Replace File
                <FileInput
                  id='offerLogo'
                  ref={inputRef}
                  accept='.png,.jpg,.jpeg,.pdf,.docx'
                  type='file'
                  onChange={handleFilesChange}
                  onClick={(event) => event.target.value = ''}
                />
              </Link>
              <TextField
                label='Agreement name'
                fullWidth
                sx={{ maxWidth: 280 }}
                value={name}
                onChange={({ target: { value } }) => setName(value)}
              />
              <Box width='100%' maxWidth={280}>
                <Typography fontWeight={400} fontSize='10px' lineHeight='20px'>
                  Agreement name is visible to recipients
                </Typography>
              </Box>
            </>
          )
        }
      </DialogContent>
    </BaseDialog>
  );
};

UploadAgreement.propTypes = {
  preview: PropTypes.string,
  blob: PropTypes.object,
  documentId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <UploadAgreement {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);