import styled from '@mui/material/styles/styled';
import MuiTablePagination from '@mui/material/TablePagination';

const TablePagination = styled((props) => (
  <MuiTablePagination
    component="div"
    labelRowsPerPage={null}
    SelectProps={{ sx: { display: 'none' } }}
    {...props}
  />
))({
  '& .MuiToolbar-root': {
    paddingRight: 40
  }
});

export default TablePagination;