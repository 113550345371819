import { memo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';

const SharingWarning = ({ name, open, onClose, onSubmit }) => {
  const [processing, setProcessing] = useState(false);
  
  const handleSubmit = () => {
    setProcessing(true);
    onSubmit();
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{ sx: { maxWidth: 490, width: '100%' } }}
      title=' '
      content={
        <Box pt={1} pb={2} pl={4} pr={4}>
          <Typography fontWeight={600} fontSize='17px' lineHeight='21px' textAlign='center' mb={1.5}>
            You are not primary sender of this Offering
          </Typography>
          <Typography fontWeight={400} fontSize='14px' lineHeight='21px' textAlign='center'>
            Any notifications about this Offering will first appear to any recipient as coming from {name}.
          </Typography>
        </Box>
      }
      actions={
        <>
          <Button
            variant='text'
            sx={{ px: '24px' }}
            disabled={processing}
            onClick={onClose}
          >
            Go back
          </Button>
          <Button
            variant='contained'
            color='primary'
            sx={{ px: '24px'}}
            disabled={processing}
            onClick={handleSubmit}
          >
            Share anyway
          </Button>
        </>
      }
    />
  );
};

SharingWarning.propTypes = {
  name: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <SharingWarning {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);