import { memo } from 'react';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _orderBy from 'lodash/orderBy';
// Local Files
import Conversation from 'components/Conversations/Conversation/Conversation';
import OfferingSelect from './OfferingSelect/OfferingSelect';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useCustomSelector from 'hooks/useCustomSelector';
import useChatsLevel from 'hooks/useChatsLevel';

const OfferingsList = () => {
  const { user: { feed: { data: feedData } }, offerings: { data: offeringsData } } = useCustomSelector(state => state.conversations);
  const { user: { id: user_id } } = useCustomSelector(state => state.profile);
  const { isUserLevel } = useChatsLevel();
  const data = _orderBy(isUserLevel ? feedData : offeringsData, ['updated_at'], ['desc']);

  const getChatTitle = (users) => {
    if (users.length > 2) {
      return _map(_filter(users, (user) => user.id !== user_id), ({ first_name }) => first_name).join(', ');
    } else {
       const user = _find(users, (user) => user.id !== user_id);

       return `${user?.first_name} ${user?.last_name}`;
    } 
  };

  return (
    <>
      <Box sx={{ padding: '0 24px 12px'}}>
        <ErrorWrapper children={<OfferingSelect />} />   
      </Box>
      {_map(data, ({ id, users, conversationable, updated_at, last_message }) =>
        <Conversation 
          key={id} 
          conversation_id={id}
          type="offering"
          headline={conversationable?.name || conversationable?.headline}
          title={getChatTitle(users)} 
          highlighted={!!last_message && user_id !== last_message?.user?.id ? !last_message?.read : false} 
          timestamp={new Date(updated_at).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} 
          message={last_message} 
          users={users}
        />
      )}
    </>
  );
};

export default memo(OfferingsList);