import styled from '@mui/material/styles/styled';
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField';;

const DropButton = styled(IconButton)(({ theme }) => ({
  width: '34px',
  height: '34px',
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& input': {
    ...theme.typography.h5
  }
}));

export { DropButton, StyledTextField };