import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getSendersRoutine,
  getBuilderSendersRoutine,
  getPreviewSendersRoutine,
  getSenderRoutine,
  createSenderRoutine,
  updateSenderRoutine,
  deleteSenderRoutine,
  clearLocalSendersRoutine,
  clearLocalActiveSenderRoutine
} from 'actions';

const attributes = [
  'id',
  { user: [
    'id', 'username', 'first_name', 'last_name', 
    { photo: ['representations'] }, 
    { current_contact: ['id', 'status'] },
    { private_conversation: [
      'id', 
      { last_message: ['id', 'read', 'text', { user: ['id', 'first_name', 'last_name'] }, 'created_at'] }, 
      { users: ['id', 'email', 'office_phone', 'office_phone_extension', 'first_name', 'last_name', { photo: ['id', 'representations'] }] }, 
      { conversationable: ['id', 'name', { cover: ['representations'] }, 'headline', 'unread_conversations_count'] },
      'conversationable_type',
      'updated_at'
    ]},
    'email', 'mobile_phone', 'office_phone', 'office_phone_extension'
  ] },
  'title',
  'mobile_phone',
  'email',
  'position'
];

export const getSenders = getThunkActionCreator(
  getSendersRoutine,
  async ({ offer_id, limit = 25, offset = 0, orders = { position: 'asc' } }) => {
    const params = { limit, offset, orders, attributes };

    return await axios.get(`/offers/${offer_id}/senders`, { params });
  }
);
export const getBuilderSenders = getThunkActionCreator(
  getBuilderSendersRoutine,
  async ({ offer_id, limit = 25, offset = 0, orders = { position: 'asc' } }) => {
    const params = { limit, offset, orders, attributes };

    return await axios.get(`/offers/${offer_id}/senders`, { params });
  }
);
export const getPreviewSenders = getThunkActionCreator(
  getPreviewSendersRoutine,
  async ({ offer_id, limit = 25, offset = 0, orders = { position: 'asc' } }) => {
    const params = { limit, offset, orders, attributes };

    return await axios.get(`/offers/${offer_id}/senders`, { params });
  }
);
export const getSender = getThunkActionCreator(
  getSenderRoutine,
  async id => {
    const params = { attributes };

    return await axios.get(`/senders/${id}`, { params });
  }
);
export const createSender = getThunkActionCreator(
  createSenderRoutine,
  async ({ offer_id, sender }) => {
    const data = { sender, attributes };

    return await axios.post(`/offers/${offer_id}/senders`, data);
  }
);
export const updateSender = getThunkActionCreator(
  updateSenderRoutine,
  async ({ id, ...fields }) => {
    const data = { sender: fields };
    const response = await axios.patch(`/senders/${id}`, data);

    return { response, sender: { id, ...fields } };
  }
);
export const deleteSender = getThunkActionCreator(
  deleteSenderRoutine,
  async id => {
    const response = await axios.delete(`/senders/${id}`);

    return { response, id };
  }
);
export const clearLocalSenders = getThunkActionCreator(clearLocalSendersRoutine, async () => {});
export const clearLocalActiveSender = getThunkActionCreator(clearLocalActiveSenderRoutine, async () => {});