import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

export const Container = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  background: 'linear-gradient(302.59deg, rgba(0, 0, 0, 0.83) -27.58%, rgba(46, 49, 51, 0.83) 107.06%)',
  backdropFilter: 'blur(7.5px)',
  borderRadius: 8,
  width: 300,
  [theme.breakpoints.up('sm')]: {
    width: 420
  }
}));

export const Title = styled(Typography)(() => ({
  color: 'white',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '18px',
  marginLeft: 26
}));

export const CancelButton = styled(ButtonBase)(() => ({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
  color: 'rgba(255, 255, 255, 0.7)',
  textDecoration: 'underline'
}));

export const Content = styled(Box)(() => ({
  paddingLeft: 26,
  paddingRight: 16,
  paddingBottom: 18,
  color: 'white'
}));

export const ItemName = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px'
}));

export const Error = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
  color: '#E30000'
}));

export const Count = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
  color: 'rgba(255, 255, 255, 0.7)',
}));