import styled from '@mui/material/styles/styled';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export const Root = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  background: '#F8F9FB'
});

export const Header = styled(Box)({
  flexShrink: 0,
  width: '100%',
  background: '#fff',
  padding: '20px 24px 0'
});

export const Content = styled(Box)({
  width: '100%',
  height: '100%',
  overflow: 'auto'
});

export const TitleContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: 20,
});


export const Title = styled((props) => (
  <Typography variant='subtitle1' {...props} />
))({
  textAlign: 'center',
  color: 'rgba(0,0,0,.8)',
});

export const CloseButton = styled((props) => (
  <Button variant='blank' color='black' {...props} />
))({
  position: 'absolute',
  right: 0,
  minWidth: 'unset',
  width: '36px',
  height: '36px',
  marginRight: '-16px',
});

export const Breadcrumbs = styled(MuiBreadcrumbs)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  fontSize: theme.typography.pxToRem(11),
  color: 'rgba(0,0,0,.4)',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

export const Link = styled(MuiLink)({
  cursor: 'pointer',
  color: 'inherit',
  fontSize: 'inherit',
});

export const MainContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  gap: '24px 16px',
});

export const MainActions = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '8px',
});

export const TabsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '8px',
});

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  paddingTop: 24,
  paddingLeft: 0,
  '& .MuiTabs-indicator': {
    height: 5,
    backgroundColor: theme.palette.common.black,
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px'
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: 40,
    paddingLeft: 46,
  }
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  minWidth: 'unset',
  minHeight: 'unset',
  height: 50,
  opacity: 1,
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 400,
  textTransform: 'none',
  padding: '8px 4px',
  color: theme.palette.common.black,
  marginRight: 40,
  '&.Mui-selected': {
    color: theme.palette.common.black,
    fontWeight: 600,
  }
}));