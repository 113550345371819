import SvgIcon from '@mui/material/SvgIcon';

export default function BackIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M14.9799 17.7695C14.6615 18.109 14.1276 18.1254 13.7881 17.8069L8.26693 12.6323C8.096 12.4731 8 12.2507 8 12.0165C8 11.7824 8.096 11.5599 8.26459 11.403L13.7858 6.22841C14.1253 5.90997 14.6591 5.92636 14.9776 6.26588C15.296 6.60539 15.2796 7.13924 14.9401 7.45768L10.0769 12.0165L14.9424 16.5777C15.282 16.8961 15.2983 17.43 14.9799 17.7695Z" />
    </SvgIcon>
  );
}

