import { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const Title = ({ label, action }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
      <Typography variant='subtitle1'>{label}</Typography>
      {action}
    </Box>
  );
};

Title.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.node.isRequired
};

export default memo(Title);