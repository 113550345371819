import {
  setContactsFiltersRoutine,
  getContactsListRoutine,
  getContactsRoutine,
  getSharedContactsRoutine,
  getSuggestedContactsRoutine,
  getContactRoutine,
  createContactRoutine,
  updateContactRoutine,
  deleteContactRoutine,
  importContactsRoutine,
  validateContactEmailRoutine,
  clearLocalContactsListRoutine,
  clearLocalContactsRoutine,
  clearLocalSharedContactsRoutine,
  clearLocalActiveContactRoutine,
  acceptInvitationRoutine,
  declineInvitationRoutine,
  filterLocalContactsRoutine,
  setUploadingFileRoutine,
  clearUploadingFilesRoutine
} from 'actions';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _differenceBy from 'lodash/differenceBy';

export const initialState = {
  list: {
    data: [],
    pagination: { limit: 0, offset: 0, count: 0, total_count: 0 }
  },
  all: {
    filters: { offset: 0, orders: { created_at: 'desc' }, query: '', lists: [], tab: 'all', member: false, pending: null, quarantined: null },
    data: [],
    pagination: { limit: 0, offset: 0, count: 0, total_count: 0 },
    fetching: true
  },
  shared: {
    data: [],
    pagination: { limit: 0, offset: 0, count: 0, total_count: 0 }
  },
  contact: {
    id: '',
    user: null,
    email: '',
    title: '',
    first_name: '',
    last_name: '',
    mobile_phone: '',
    office_phone: '',
    office_phone_extension: '',
    notes: '',
    location: '',
    linkedin_profile_url: '',
    website_url: '',
    status: ''
  },
  snackbar: {
    silent: false,
    data: []
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setContactsFiltersRoutine.SUCCESS: {
      const { offset, orders, query, lists, tab, member, pending, quarantined } = action.payload;
      const filters = {
        ...state.all.filters,
        ...(!_isUndefined(offset) && { offset }),
        ...(!_isUndefined(orders) && { orders }),
        ...(!_isUndefined(query) && { query }),
        ...(!_isUndefined(lists) && { lists }),
        ...(!_isUndefined(tab) && { tab }),
        ...(!_isUndefined(member) && { member }),
        ...(!_isUndefined(pending) && { pending }),
        ...(!_isUndefined(quarantined) && { quarantined })
      };

      return { ...state, all: { ...state.all, filters }  };
    }
    case getContactsListRoutine.SUCCESS: {
      const { data: { contacts, pagination } } = action.payload;

      return { ...state, list: { data: contacts, pagination } };
    }
    case getContactsRoutine.REQUEST: {
      const all = { ...state.all, fetching: true };

      return { ...state, all };
    }
    case getContactsRoutine.SUCCESS: {
      const { data: { contacts, pagination } } = action.payload;

      return { 
        ...state, 
        all: { 
          ...state.all, 
          data: state.all.filters.offset === 0 ? contacts : [...state.all.data, ...contacts], 
          pagination,
          fetching: false 
        } 
      };
    }
    case getContactsRoutine.FAILURE: {
      const all = { ...state.all, fetching: false };

      return { ...state, all };
    }
    case getSharedContactsRoutine.SUCCESS: {
      const { data: { contacts, pagination } } = action.payload;
      const data = [...state.shared.data, ...contacts];

      return { ...state, shared: { data, pagination } };
    }
    case getSuggestedContactsRoutine.SUCCESS: {
      return state;
    }
    case getContactRoutine.SUCCESS: {
      const { data: { contact } } = action.payload;
      const foundContact = !!_find(state.all.data, d => d.id === contact.id);
      const all = { ...state.all, ...(foundContact && { data: _map(state.all.data, d => d.id === contact.id ? ({ ...d, ...contact }) : d) }) };
      
      return { ...state, all, contact: { ...state.contact, ...contact } };
    }
    case createContactRoutine.SUCCESS: {
      const { response: { data: { contact } }, isAllContacts } = action.payload;
      const allData = [contact, ...state.all.data];
      const allPagination = { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 };
      const shareData = [contact, ...state.shared.data];
      const sharePagination = { ...state.shared.pagination, count: state.shared.pagination.count + 1, total_count: state.shared.pagination.total_count + 1 };

      return {
        ...state,
        ...(isAllContacts && { all: { ...state.all, data: allData, pagination: allPagination } }),
        share: { data: shareData, pagination: sharePagination }
      };
    }
    case updateContactRoutine.SUCCESS: {
      const { response: { status }, contact } = action.payload;
      const data = !!state.all.filters.quarantined ? _filter(state.all.data, d => d.id !== contact.id) : _map(state.all.data, d => d.id === contact.id ? ({ ...d, ...contact, ...(!!contact.localLists && { lists: contact.localLists }) }) : d);
      const all = { ...state.all, data };
      
      return { ...state, ...(status === 204 && { all, contact: { ...state.contact, ...contact, ...(!!contact.localLists && { lists: contact.localLists }) } }) };
    }
    case deleteContactRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const data = _filter(state.all.data, d => d.id !== id);
      const pagination = { ...state.all.pagination, count: state.all.pagination.count - 1, total_count: state.all.pagination.total_count - 1 };

      return { ...state, ...(status === 204 && { all: { ...state.all, data, pagination } }) };
    }
    case importContactsRoutine.SUCCESS: {
      return state;
    }
    case clearLocalContactsListRoutine.SUCCESS: {
      return { ...state, list: initialState.list }
    }
    case clearLocalContactsRoutine.SUCCESS: {
      return { ...state, all: initialState.all }
    }
    case clearLocalSharedContactsRoutine.SUCCESS: {
      return { ...state, shared: initialState.shared };
    }
    case clearLocalActiveContactRoutine.SUCCESS: {
      return { ...state, contact: initialState.contact }
    }
    case acceptInvitationRoutine.SUCCESS: {
      const { response: { status }, inviter_id } = action.payload;
      const data = _filter(state.all.data, c => c?.invitation?.inviter?.id !== inviter_id);
      const pagination = { ...state.all.pagination, count: state.all.pagination.count - 1, total_count: state.all.pagination.total_count - 1 };

      return { ...state, ...(status === 204 && !!state.all.data?.length && { all: { ...state.all, data, pagination } }) };
    }
    case declineInvitationRoutine.SUCCESS: {
      const { response: { status }, inviter_id } = action.payload;
      const data = _filter(state.all.data, c => c?.invitation?.inviter?.id !== inviter_id);
      const pagination = { ...state.all.pagination, count: state.all.pagination.count - 1, total_count: state.all.pagination.total_count - 1 };

      return { ...state, ...(status === 204 && !!state.all.data?.length && { all: { ...state.all, data, pagination } }) };
    }
    case filterLocalContactsRoutine.SUCCESS: {
      const { ids } = action.payload;
      const data = _differenceBy(state.all.data, ids, 'id');
      const pagination = { ...state.all.pagination, count: state.all.pagination.count - ids?.length, total_count: state.all.pagination.total_count - ids?.length };

      return { ...state, all: { ...state.all, data, pagination } };
    }
    case setUploadingFileRoutine.SUCCESS: {
      const { file_id, filename, lines_count, progress } = action.payload;
      const foundFile = _find(state.snackbar.data, d => d.file_id === file_id);

      if (!!filename) {
        if (!!foundFile) {
          const data = _map(state.snackbar.data, d => d.file_id === file_id ? ({ file_id, filename, lines_count, progress }) : d);
          const snackbar = { ...state.snackbar, data };

          return { ...state, snackbar };
        } else {
          const data = [...state.snackbar.data, { file_id, filename, lines_count, progress }];
          const snackbar = { ...state.snackbar, data, ...progress === 0 && { silent: false } }

          return { ...state, snackbar };
        }
      } else {
        const data = _filter(state.snackbar.data, d => d.file_id !== file_id);
        const snackbar = { ...state.snackbar, data };

        return { ...state, snackbar };
      }
    }
    case validateContactEmailRoutine.SUCCESS: {
      return state;
    }
    case clearUploadingFilesRoutine.SUCCESS: {
      return { ...state, snackbar: { data: [], silent: true } };
    }
    default: {
      return state;
    }
  };
};

export default reducer;