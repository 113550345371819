import { memo, forwardRef } from 'react';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { List } from './Senders.styled';
import Sender from './Sender/Sender';
import { SenderSkeleton } from 'components/Offers/Details/Skeletons/Skeletons';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useCustomSelector from 'hooks/useCustomSelector';

/**
 * @param {array=} data
 * @param {bool=} disabled
 * @param {function} onConversation
 */

const Senders = forwardRef((props, ref) => {
  const { data = null, disabled = false, onConversation } = props;
  const { offerData, fetching } = useCustomSelector(state => ({
    fetching: state.senders.all.fetching,
    offerData: state.senders.all.data
  }));
  const senders = data ?? offerData;

  return (
    <Container ref={ref} maxWidth={false} sx={{ maxWidth: '624px', px: '12px !important' }}>
      <List>
        {!data && fetching ? <SenderSkeleton component='li' /> : _map(_orderBy(senders, ['position'], ['asc']), s =>
          <Sender
            key={s.id}
            disabled={disabled}
            onConversation={onConversation}
            {...s}
          />
        )}
      </List>
    </Container>
  );
});

Senders.propTypes = {
  data: PropTypes.array,
  disabled: PropTypes.bool,
  onConversation: PropTypes.func
};

const WrappedComponent = forwardRef((props, ref) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Senders {...props} ref={ref} />
    </ErrorBoundary>
  );
});
export const Fallback = () => {
  return (
    <Skeleton
      variant='rectangular'
      animation='wave'
      width={200}
      height={50}
    />
  );
};

export default memo(WrappedComponent);