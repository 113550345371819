import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Scrollbars from 'react-custom-scrollbars-2';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import { NumericFormat } from 'react-number-format';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import Card from 'components/Lists/Sharing/Card/Card';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useLists from 'hooks/useLists';
import useSuccess from 'hooks/useSuccess';

const SharingList = ({ open, id, onClose }) => {
  const { setError } = useError();
  const { updateList } = useLists();
  const { setSuccess } = useSuccess();
  const { userGroups, activeList } = useCustomSelector(state => ({
    userGroups: state.groups.all.data,
    activeList: _find(state.lists.my.data, d => d.id === id) ?? {},
  }));
  const [stateGroups, setStateGroups] = useState({ value: [], formattedValue: [] });
  const [processing, setProcessing] = useState(false);

  const onEntering = () => {
    setStateGroups({ value: _map(activeList.groups, g => g.id), formattedValue: activeList.groups });
  };
  const onExited = () => {
    setStateGroups({ value: [], formattedValue: [] });
    setProcessing(false);
  };
  const handleChange = groupId => {
    const found = !!_find(stateGroups.value, g => g === groupId);

    if (found) {
      setStateGroups(prev => ({
        value: _filter(prev.value, g => g !== groupId),
        formattedValue: _filter(prev.formattedValue, g => g.id !== groupId)
      }));
    } else {
      const foundUserGroup = _find(userGroups, ug => ug.id === groupId);

      setStateGroups(prev => ({
        value: [...prev.value, groupId],
        formattedValue: [...prev.formattedValue, { id: groupId, logo: foundUserGroup.logo, name: foundUserGroup.name }]
      }));
    }
  };
  const handleSubmitClick = () => {
    if (_isEqual(_map(activeList.groups, g => g.id).sort(), stateGroups.value.sort())) {
      onClose();
    } else {
      const group_ids = stateGroups.value;
      const localGroups = stateGroups.formattedValue;

      setProcessing(true);
      updateList({ id, group_ids, localGroups })
      .then(() => setSuccess('List share settings saved').then(onClose))
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
  };
  
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{ sx: { maxWidth: 705, width: '100%' } }}
      title={`‘${activeList?.name}’ List`}
      otherTitleStyle={{ pb: 0 }}
      TransitionProps={{ onEntering, onExited }}
      actions={
        <>
          <Button variant='text' sx={{ minWidth: 80 }} onClick={onClose}>
            Cancel
          </Button>
          <Button variant='contained' disabled={processing} sx={{ minWidth: 100 }} onClick={handleSubmitClick}>
            Save
          </Button>
        </>
      }
    >
      <DialogContent sx={{   }}>
        <Box sx={{ width: 'min(100%, 550px)', margin: '0 auto' }}>
          <Typography variant='caption' component='div' textAlign='center' sx={{ fontWeight: 600 }}>
            Group Share Settings
          </Typography>
          <Typography variant='body2' textAlign='center' mt={2}>
            Authorizing Deal Groups below allow Group collaborators to share Offerings created within that Group, to any of the <span style={{ fontWeight: 700 }}><NumericFormat value={activeList.contacts_count} displayType='text' thousandSeparator=',' /> contacts</span> in your list labeled <span style={{ fontWeight: 700 }}>‘{activeList.name}’</span>. Collaborators will not be able to export or download these contacts.
          </Typography>
          <Typography variant='caption' component='div' mt={2}>
            Authorize:
          </Typography>
          <Box width='100%' height={200}>
            <Scrollbars autoHide autoHideTimeout={1000}>
              {_map(userGroups, ug => {
                const checked = !!_find(stateGroups.value, g => g === ug.id);
                const name = ug.name;
                const users = _map(ug.members, m => m.user);
                const usersCount = ug.members_count;
                const props = { checked, name, users, usersCount, processing };

                return (
                  <Card
                    key={ug.id}
                    {...props}
                    onChange={() => handleChange(ug.id)}
                  />
                );
              })}
            </Scrollbars>
          </Box>
        </Box>
      </DialogContent>
    </BaseDialog>
  );
};

export default memo(SharingList);