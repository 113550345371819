import SvgIcon from '@mui/material/SvgIcon';

export default function GroupsIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M12.4373 11.0875C14.6706 11.0875 16.481 9.27702 16.481 7.04373C16.481 4.81044 14.6706 3 12.4373 3C10.204 3 8.39355 4.81044 8.39355 7.04373C8.39355 9.27702 10.204 11.0875 12.4373 11.0875ZM12.4369 9.73954C13.9257 9.73954 15.1327 8.53258 15.1327 7.04373C15.1327 5.55487 13.9257 4.34791 12.4369 4.34791C10.948 4.34791 9.74107 5.55487 9.74107 7.04373C9.74107 8.53258 10.948 9.73954 12.4369 9.73954Z" />
      <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M17.826 20.5228C20.0592 20.5228 21.8697 18.7123 21.8697 16.479C21.8697 14.2457 20.0592 12.4353 17.826 12.4353C15.5927 12.4353 13.7822 14.2457 13.7822 16.479C13.7822 18.7123 15.5927 20.5228 17.826 20.5228ZM17.8252 19.1749C19.314 19.1749 20.521 17.9679 20.521 16.479C20.521 14.9902 19.314 13.7832 17.8252 13.7832C16.3363 13.7832 15.1294 14.9902 15.1294 16.479C15.1294 17.9679 16.3363 19.1749 17.8252 19.1749Z" />
      <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M7.04373 20.5227C9.27702 20.5227 11.0875 18.7123 11.0875 16.479C11.0875 14.2457 9.27702 12.4352 7.04373 12.4352C4.81044 12.4352 3 14.2457 3 16.479C3 18.7123 4.81044 20.5227 7.04373 20.5227ZM7.04314 19.1749C8.532 19.1749 9.73896 17.9679 9.73896 16.479C9.73896 14.9902 8.532 13.7832 7.04314 13.7832C5.55428 13.7832 4.34732 14.9902 4.34732 16.479C4.34732 17.9679 5.55428 19.1749 7.04314 19.1749Z" />
    </SvgIcon>
  );
}

