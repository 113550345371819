import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getAudits as getAuditsAction,
  getAudit as getAuditAction,
  getCableCreatedAudit as getCableCreatedAuditAction,
  createAudit as createAuditAction,
  clearLocalAudits as clearLocalAuditsAction
} from 'actions/audits';

const useAudits = () => {
  const dispatch = useDispatch();
  const getAudits = useCallback(({ offer_id, limit, offset, orders }) => dispatch(getAuditsAction({ offer_id, limit, offset, orders })), [dispatch]);
  const getAudit = useCallback(id => dispatch(getAuditAction(id)), [dispatch]);
  const getCableCreatedAudit = useCallback(id => dispatch(getCableCreatedAuditAction(id)), [dispatch]);
  const createAudit = useCallback(({ offer_id, audit_type, recipients_count }) => dispatch(createAuditAction({ offer_id, audit_type, recipients_count })), [dispatch]);
  const clearLocalAudits = useCallback(() => dispatch(clearLocalAuditsAction()), [dispatch]);

  return {
    getAudits,
    getAudit,
    getCableCreatedAudit,
    createAudit,
    clearLocalAudits
  };
};

export default useAudits;