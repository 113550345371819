import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  checkGroupMember as checkGroupMemberAction,
  getGroupMembersList as getGroupMembersListAction,
  getGroupMembers as getGroupMembersAction,
  getDealspaceMembersList as getDealspaceMembersListAction,
  getDealspaceMembers as getDealspaceMembersAction,
  getMember as getMemberAction,
  createMember as createMemberAction,
  updateMember as updateMemberAction,
  deleteMember as deleteMemberAction,
  clearLocalGroupMembersList as clearLocalGroupMembersListAction,
  clearLocalGroupMembers as clearLocalGroupMembersAction,
  clearLocalDealspaceMembersList as clearLocalDealspaceMembersListAction,
  clearLocalDealspaceMembers as clearLocalDealspaceMembersAction,
  clearLocalActiveMember as clearLocalActiveMemberAction
} from 'actions/members';

const useMembers = () => {
  const dispatch = useDispatch();
  const checkGroupMember = useCallback(({ group_id, email, user_id }) => dispatch(checkGroupMemberAction({ group_id, email, user_id })), [dispatch]);
  const getGroupMembersList = useCallback(({ group_id, offset }) => dispatch(getGroupMembersListAction({ group_id, offset })), [dispatch]);
  const getGroupMembers = useCallback(({ group_id, limit, offset, orders, query }) => dispatch(getGroupMembersAction({ group_id, limit, offset, orders, query })), [dispatch]);
  const getDealspaceMembersList = useCallback(({ dealspace_id, offset }) => dispatch(getDealspaceMembersListAction({ dealspace_id, offset })), [dispatch]);
  const getDealspaceMembers = useCallback(({ dealspace_id, limit, offset, orders, status }) => dispatch(getDealspaceMembersAction({ dealspace_id, limit, offset, orders, status })), [dispatch]);
  const getMember = useCallback(id => dispatch(getMemberAction(id)), [dispatch]);
  const createMember = useCallback(({ group_id, member }) => dispatch(createMemberAction({ group_id, member })), [dispatch]);
  const updateMember = useCallback(member => dispatch(updateMemberAction(member)), [dispatch]);
  const deleteMember = useCallback(id => dispatch(deleteMemberAction(id)), [dispatch]);
  const clearLocalGroupMembersList = useCallback(() => dispatch(clearLocalGroupMembersListAction()), [dispatch]);
  const clearLocalGroupMembers = useCallback(() => dispatch(clearLocalGroupMembersAction()), [dispatch]);
  const clearLocalDealspaceMembersList = useCallback(() => dispatch(clearLocalDealspaceMembersListAction()), [dispatch]);
  const clearLocalDealspaceMembers = useCallback(() => dispatch(clearLocalDealspaceMembersAction()), [dispatch]);
  const clearLocalActiveMember = useCallback(() => dispatch(clearLocalActiveMemberAction()), [dispatch]);

  return {
    checkGroupMember,
    getGroupMembersList,
    getGroupMembers,
    getDealspaceMembersList,
    getDealspaceMembers,
    getMember,
    createMember,
    updateMember,
    deleteMember,
    clearLocalGroupMembersList,
    clearLocalGroupMembers,
    clearLocalDealspaceMembersList,
    clearLocalDealspaceMembers,
    clearLocalActiveMember
  };
};

export default useMembers;