import { memo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import _toUpper from 'lodash/toUpper';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { StyledAvatar } from './Avatar.styled';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';

const Avatar = ({ fetching = false, src, title, sx, component, onClick, to }) => {
  return (
    <StyledAvatar
      alt={title ?? ''}
      src={src}
      variant='rounded'
      sx={sx}
      component={component}
      onClick={onClick}
      to={to}
    >
      {fetching ? <Skeleton width='100%' height='100%' variant='rectangular' animation='wave' /> : _toUpper(title[0]) ?? ''}
    </StyledAvatar>
  );
};

Avatar.propTypes = {
  fetching: PropTypes.bool.isRequired,
  src: PropTypes.string,
  title: PropTypes.string,
  sx: PropTypes.object
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Avatar {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);