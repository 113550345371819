import { memo, useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import _map from 'lodash/map';
// Local files
import { Container, Grid, PaperContent, Root, Text } from './Dashboard.styled';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import DealspaceDialog from 'components/Dialogs/CreateDealspace/CreateDealspace';
import GroupsList from 'components/Groups/List/List';
import ProfileWidget from 'components/Users/Widget/Widget';
import ContactsWidget from 'components/Contacts/Widget/Widget';
import DealspacesWidget from 'components/Dealspaces/Widget/Widget';
import OffersWidget from 'components/Offers/Widget/Widget';
import useApp from 'hooks/useApp';
import useError from 'hooks/useError';
import useProfile from 'hooks/useProfile';
import useInvitations from 'hooks/useInvitations';
import useCustomSelector from 'hooks/useCustomSelector';
import useMembers from 'hooks/useMembers';

const Dashboard = () => {
  const { openPreBuilderDialog } = useApp();
  const { getInvitations } = useInvitations();
  const { getGroupMembersList, clearLocalGroupMembersList } = useMembers();
  const { getFullProfile } = useProfile();
  const { setError } = useError();
  const { groups, authorizedAsUser } = useCustomSelector(state => ({
    groups: state.groups.all.data,
    authorizedAsUser: state.sessions.session.userableType === 'user'
  }));
  const [anchorEl, setAnchorEl] = useState(null);
  const [dealspaceDialog, setDealspaceDialog] = useState({ open: false, groupId: null, redirect: false });

  const handleCreateOffer = () => openPreBuilderDialog();
  const handleDealspaceCreate = groupId => {
    setAnchorEl(null);
    getGroupMembersList({ group_id: groupId, offset: 0 })
    .then(() => setDealspaceDialog({ open: true, groupId, redirect: true }))
    .catch(e => setError(e))
  };
  const handleDealspaceDialogClose = () => setDealspaceDialog({ open: false, groupId: null, redirect: false });

  useEffect(() => {
    if (authorizedAsUser) {
      getFullProfile().catch(e => setError(e));
      getInvitations({ offset: 0 }).catch(e => setError(e));
    }

    return clearLocalGroupMembersList;
  }, [authorizedAsUser, getFullProfile, getInvitations, setError, clearLocalGroupMembersList]);

  return (
    <Root>
      <DealspaceDialog
        {...dealspaceDialog}
        onClose={handleDealspaceDialogClose}
      />
      <ErrorWrapper children={<GroupsList />} />
      <Container>
        <Grid>
          <ErrorWrapper children={<ProfileWidget />} />
          <Paper variant='outlined' sx={{ p: '14px 32px' }}>
            <PaperContent>
              <Button color='secondary' sx={{ fontWeight: 700 }} onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>+ Create Dealspace</Button>
              <Text>A private space for any type of commercial real estate deal.  Work on files, private offerings & more.  Invite team members to collaborate.</Text>
              <Divider flexItem sx={{ my: 2 }}/>
              <Button color='secondary' sx={{ fontWeight: 700 }} onClick={handleCreateOffer}>+ Create Offering</Button>
              <Text>Create powerfully interactive & trackable offerings for any deal type.  Privately share with a single recipient or a list of many simultaneously.</Text>
            </PaperContent>
          </Paper>
          <ContactsWidget />
        </Grid>
        <ErrorWrapper children={<DealspacesWidget />} />  
        <ErrorWrapper children={<OffersWidget />} />   
      </Container>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{ variant: 'popup', color: 'dark', sx: { mt: '-4px', width: '180px' } }}
      >
        <List>
          {_map(groups, ({ id, name }) =>
            <ListItemButton
              key={id}
              sx={{ '&:hover': { backgroundColor: 'background.hoverDark'} }}
              onClick={() => handleDealspaceCreate(id)}
            >
              <Typography variant='caption' noWrap ml={1.5}>{name}</Typography>
            </ListItemButton>
          )}
        </List>
      </Popover>
    </Root>
  );
};

export default memo(Dashboard);