import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

export const Description = styled(Typography)({
  maxWidth: 274,
  textAlign: 'center',
  fontSize: '0.75rem',
  color: 'rgba(0,0,0,.6)',
  marginBottom: 32
});
