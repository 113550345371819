import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getUnreadNotificationsCountRoutine,
  setUnreadNotificationsCountRoutine,
  getNotificationsRoutine,
  getNotificationRoutine,
  readNotificationsRoutine,
  clearNotificationsRoutine
} from 'actions';

const attributes = [
  'id',
  { invitation: ['id', { invitationable: ['id', 'name', { logo: ['representations'] }] }] },
  { group: ['id', 'name', { logo: ['representations'] }] },
  { dealspace: ['id', 'name', 'properties_count', { root_folder: ['id'] }, { group: ['id', 'name', { logo: ['representations'] }] }] },
  { user: ['id', 'username', 'first_name', 'last_name', { photo: ['representations'] }] },
  { offer: ['id', { primary_sender: [{ user: ['first_name', 'last_name', { photo: ['representations'] } ] }] }] },
  'read',
  'notification_type',
  'title',
  'subtitle',
  'updated_at'
];

export const getUnreadNotificationsCount = getThunkActionCreator(
  getUnreadNotificationsCountRoutine,
  async () => {
    const params = { limit: 0, offset: 0, unread: true, orders: { created_at: 'desc' }, attributes: ['id'] };

    return await axios.get(`/notifications`, { params });
  }
);
export const setUnreadNotificationsCount = getThunkActionCreator(setUnreadNotificationsCountRoutine, async count => ({ count }));
export const getNotifications = getThunkActionCreator(
  getNotificationsRoutine,
  async ({ limit = 25, offset = 0, orders = { updated_at: 'desc', read: 'desc' } }) => {
    const params = { limit, offset, orders, attributes };

    return await axios.get(`/notifications`, { params });
  }
);
export const getNotification = getThunkActionCreator(
  getNotificationRoutine,
  async id => {
    const params = { attributes };

    return await axios.get(`/notifications/${id}`, { params });
  }
);
export const readNotifications = getThunkActionCreator(
  readNotificationsRoutine,
  async notification_ids => {
    const response = await axios.post('/notifications/read', { notification_ids });

    return { response, notification_ids };
  }
);
export const clearNotifications = getThunkActionCreator(clearNotificationsRoutine, async () => {});