import { memo, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useParams } from 'react-router-dom';
// Local files
import useError from 'hooks/useError';
import useProfile from 'hooks/useProfile';

const Unsubscribed = () => {
  const { setError } = useError();
  const { r_t } = useParams();
  const { unsubscribe } = useProfile();
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    if (r_t) {
      unsubscribe(r_t)
      .then(() => setProcessing(false))
      .catch(e => setError(e));
    }
  }, [r_t, setError, unsubscribe]);

  return (
    <Box display='flex' flexDirection='column' alignItems='center' pt='26px' pb='26px'>
      <Typography fontWeight={600} fontSize='16px' lineHeight='24px' color='rgba(26, 28, 40, 1)'>
        Qwincy
      </Typography>
      <Typography fontWeight={500} fontSize='10px' lineHeight='24px' color='rgba(26, 28, 40, 1)'>
        Collaborative Dealflow Management
      </Typography>
      <Typography fontWeight={700} fontSize='14px' lineHeight='31px' color='rgba(0, 0, 0, 1)' mt='49px'>
        {processing ? 'Unsubscribing...' : 'You will no longer receive deal shares from this Qwincy user.'}
      </Typography>
      <Link fontWeight={500} fontSize='10px' lineHeight='24px' color='rgba(26, 28, 40, 1)' mt='2px' target='_blank' rel='noopener' href='https://www.qwincy.com'>
        What is Qwincy Deal Sharing?
      </Link>
    </Box>
  );
};

export default memo(Unsubscribed);