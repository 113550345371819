import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getSharedLists as getSharedListsAction,
  getMyLists as getMyListsAction,
  getList as getListAction,
  createList as createListAction,
  updateList as updateListAction,
  deleteList as deleteListAction,
  clearLocalSharedLists as clearLocalSharedListsAction,
  clearLocalMyLists as clearLocalMyListsAction,
  clearLocalActiveList as clearLocalActiveListAction
} from 'actions/lists';

const useLists = () => {
  const dispatch = useDispatch();
  const getSharedLists = useCallback(({ limit, offset, orders, group_id }) => dispatch(getSharedListsAction({ limit, offset, orders, group_id })), [dispatch]);
  const getMyLists = useCallback(({ limit, offset, orders, query }) => dispatch(getMyListsAction({ limit, offset, orders, query })), [dispatch]);
  const getList = useCallback(id => dispatch(getListAction(id)), [dispatch]);
  const createList = useCallback(list => dispatch(createListAction(list)), [dispatch]);
  const updateList = useCallback(list => dispatch(updateListAction(list)), [dispatch]);
  const deleteList = useCallback(id => dispatch(deleteListAction(id)), [dispatch])
  const clearLocalSharedLists = useCallback(() => dispatch(clearLocalSharedListsAction()), [dispatch]);
  const clearLocalMyLists = useCallback(() => dispatch(clearLocalMyListsAction()), [dispatch]);
  const clearLocalActiveList = useCallback(() => dispatch(clearLocalActiveListAction()), [dispatch]);

  return {
    getSharedLists,
    getMyLists,
    getList,
    createList,
    updateList,
    deleteList,
    clearLocalSharedLists,
    clearLocalMyLists,
    clearLocalActiveList
  };
};

export default useLists;