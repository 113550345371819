import { memo } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';

const DiscardTemplate = ({ open, onClose }) => {
  const handleClose = () => onClose(false);
  const handleConfirmClick = () => onClose(true);

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      otherPaperStyle={{ width: '100%', maxWidth: 500 }}
      title=' '
      actions={
        <>
          <Button
            variant='text'
            color='error'
            onClick={handleClose}
            sx={{ fontSize: '12px', mr: '24px', py: '8px', px: '28px' }}
          >
            Go Back
          </Button>
          <Button
            variant='contained'
            onClick={handleConfirmClick}
            sx={{ fontSize: '12px', px: '28px' }}
          >
            Discard
          </Button>
        </>
      }
    >
      <Box sx={{ px: 4, pb: 3 }}>
        <Typography align='center' p='16px 0px 8px' fontWeight={600} fontSize='16px' lineHeight='20px'>
          Discard template?
        </Typography>
      </Box>
    </BaseDialog>
  );
};

DiscardTemplate.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default memo(DiscardTemplate);