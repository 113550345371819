import styled from '@mui/material/styles/styled';
import AvatarGroup from '@mui/material/AvatarGroup';

const BaseAvatarGroup = styled(AvatarGroup)(({ theme }) => ({
  '& .MuiAvatarGroup-avatar': {
    width: 30, height: 30,
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    marginLeft: '-8px !important'
  }
}));

export default BaseAvatarGroup;

