import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import _differenceBy from 'lodash/differenceBy';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import _orderBy from 'lodash/orderBy';
import _startCase from 'lodash/startCase';
// Local files
import { DateFormContainer, StyledRadioGroup } from './Form.styled';
import SymbolCounter from 'components/Common/DataDisplay/SymbolCounter/SymbolCounter';
import DateField from 'components/Common/Inputs/DateField/DateField';
import FormSelect from 'components/Common/Inputs/FormSelect/FormSelect';
import TimePicker from 'components/Common/Inputs/TimePicker/TimePicker';
import VisibilityRadioButton from './VisibilityRadioButton/VisibilityRadioButton';
import NotificationRadioButton from './NotificationRadioButton/NotificationRadioButton';
import Senders from 'components/Senders/BuilderSenders/BuilderSenders';
import Properties from 'components/Properties/BuilderProperties/BuilderProperties';
import Canvas from './Canvas/Canvas';
import Agreement from './Agreement/Agreement';
import BuilderErrorLabel from 'components/Common/DataDisplay/BuilderErrorLabel/BuilderErrorLabel';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { checkEmptyString, fromIsoString, needToUpdateField } from 'helpers';
import { deadlineTypes } from 'helpers/constants';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useDocuments from 'hooks/useDocuments';
import useFolders from 'hooks/useFolders';
import useImages from 'hooks/useImages';
import useOffers from 'hooks/useOffers';
import useSenders from 'hooks/useSenders';
import useTemplates from 'hooks/useTemplates';

const Form = forwardRef((_, ref) => {
  const { setError } = useError();
  const { createDealspaceDocument, createOfferDocument, getBuilderPrivateLevel1Documents, getBuilderPrivateLevel2Documents, getBuilderPublicDocuments, deleteDocument } = useDocuments();
  const { getBuilderPrivateLevel1Folders, getBuilderPrivateLevel2Folders, getBuilderPublicFolders, createFolder, deleteFolder } = useFolders();
  const { getBuilderImages, createOfferImage, updateImage, deleteImage, mainImage } = useImages();
  const { updateOffer, setBuilderState, getBuilderDraftActualFilesCount } = useOffers();
  const { getBuilderSenders, createSender, updateSender, deleteSender } = useSenders();
  const { getTemplates, deleteTemplate } = useTemplates();
  const { offer, fetching } = useCustomSelector(state => state.offers.builder);
  const [privacyType, setPrivacyType] = useState('private'); // Public: true/false
  const [senders, setSenders] = useState({ value: [], error: '' });
  const [headline, setHeadline] = useState({ value: '', error: '' });
  const [hasLogo, setHasLogo] = useState(false);
  const [hasImages, setHasImages] = useState(true);
  const [logo, setLogo] = useState({ value: null, formattedValue: '', uploading: false });
  const [images, setImages] = useState({ value: [], fetching: true, uploading: false, error: '' });
  const [description, setDescription] = useState({ value: '', error: '', charsCount: 0, fetched: false });
  const [publicFiles, setPublicFiles] = useState({ value: [], fetching: true, uploading: false });
  const [publicFolders, setPublicFolders] = useState({ value: [], fetching: true, uploading: false });
  const [privateLevel1Files, setPrivateLevel1Files] = useState({ value: [], fetching: true, uploading: false });
  const [privateLevel2Files, setPrivateLevel2Files] = useState({ value: [], fetching: true, uploading: false });
  const [privateLevel1Folders, setPrivateLevel1Folders] = useState({ value: [], fetching: true, uploading: false });
  const [privateLevel2Folders, setPrivateLevel2Folders] = useState({ value: [], fetching: true, uploading: false });
  const [emailStyle, setEmailStyle] = useState('basic');
  const [hasExpiration, setHasExpiration] = useState(false);
  const [expiredAt, setExpiredAt] = useState({ value: '', formattedValue: null, error: '' });
  const [hasDeadline, setHasDeadline] = useState(false);
  const [deadlineType, setDeadlineType] = useState({ value: '', error: '' });
  const [deadlineAt, setDeadlineAt] = useState({ value: '', formattedValue: null, error: '' });
  const [templates, setTemplates] = useState({ value: [], fetching: true, error: '' });
  const [locationsLocked, setLocationsLocked] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const canvasProps = { hasImages, images, hasLogo, logo, description, publicFiles, publicFolders, privateLevel1Files, privateLevel2Files, privateLevel1Folders, privateLevel2Folders };
  const propertiesRef = useRef(null);
  const showAgreement = locationsLocked || !!privateLevel1Files.value.length || !!privateLevel2Files.value.length || !!privateLevel1Folders.value.length || !!privateLevel2Folders.value.length;

  const createNewDocument = ({ document_id = null, document = null, level }) => {
    setBuilderState({ saving: true })
    .then(() => {
      if (!document_id) {
        createDealspaceDocument({ folder_id: offer.dealspace.root_folder.id, document: _omit(document, ['locked', 'approval_required']) })
        .then(({ payload: { response: { data: { document: { id } } } } }) =>
          createOfferDocument({ folder_id: offer.root_folder.id, document_id: id, ...document && { document } })
          .then(({ payload: { data: { document } } }) => {
            if (level === 0) setPublicFiles(prev => ({ ...prev, value: [...prev.value, document] }));
            if (level === 1) setPrivateLevel1Files(prev => ({ ...prev, value: [...prev.value, document] }));
            if (level === 2) setPrivateLevel2Files(prev => ({ ...prev, value: [...prev.value, document] }));
          })
          .catch(e => setError(e))
          .finally(() => {
            getBuilderDraftActualFilesCount(offer.id).catch(e => setError(e));
            setBuilderState({ saving: false });

            if (level === 0) setPublicFiles(prev => ({ ...prev, uploading: false }));
            if (level === 1) setPrivateLevel1Files(prev => ({ ...prev, uploading: false }));
            if (level === 2) setPrivateLevel2Files(prev => ({ ...prev, uploading: false }));
          })
        )
        .catch(e => setError(e));
      } else {
        createOfferDocument({ folder_id: offer.root_folder.id, document_id, ...document && { document } })
        .then(({ payload: { data: { document } } }) => {
          if (level === 0) setPublicFiles(prev => ({ ...prev, value: [...prev.value, document] }));
          if (level === 1) setPrivateLevel1Files(prev => ({ ...prev, value: [...prev.value, document] }));
          if (level === 2) setPrivateLevel2Files(prev => ({ ...prev, value: [...prev.value, document] }));
        })
        .catch(e => setError(e))
        .finally(() => {
          getBuilderDraftActualFilesCount(offer.id).catch(e => setError(e));
          setBuilderState({ saving: false });
    
          if (level === 0) setPublicFiles(prev => ({ ...prev, uploading: false }));
          if (level === 1) setPrivateLevel1Files(prev => ({ ...prev, uploading: false }));
          if (level === 2) setPrivateLevel2Files(prev => ({ ...prev, uploading: false }));      
        });
      }
    });
  };
  const createNewFolder = ({ folder_id = null, folder = null, level }) => {
    setBuilderState({ saving: true })
    .then(() => {
      if (!folder_id) {
        createFolder({ folder_id: offer.dealspace.root_folder.id, folder: _omit(folder, ['locked', 'approval_required']) })
        .then(({ payload: { data: { folder: { id } } } }) =>
          createFolder({ folder_id: offer.root_folder.id, original_folder_id: id, ...folder && { folder } })
          .then(({ payload: { data: { folder } } }) => {
            if (level === 0) setPublicFolders(prev => ({ ...prev, value: [...prev.value, folder] }));
            if (level === 1) setPrivateLevel1Folders(prev => ({ ...prev, value: [...prev.value, folder] }));
          })
          .catch(e => setError(e))
          .finally(() => {
            getBuilderDraftActualFilesCount(offer.id).catch(e => setError(e));
            setBuilderState({ saving: false });
      
            if (level === 0) setPublicFolders(prev => ({ ...prev, uploading: false }));
            if (level === 1) setPrivateLevel1Folders(prev => ({ ...prev, uploading: false }));
            if (level === 2) setPrivateLevel2Folders(prev => ({ ...prev, uploading: false }));
          })
        )
        .catch(e => setError(e));
      } else {
        createFolder({ folder_id: offer.root_folder.id, original_folder_id: folder_id, ...folder && { folder } })
        .then(({ payload: { response: { data: { folder } } } }) => {
          if (level === 0) setPublicFolders(prev => ({ ...prev, value: [...prev.value, folder] }));
          if (level === 1) setPrivateLevel1Folders(prev => ({ ...prev, value: [...prev.value, folder] }));
          if (level === 2) setPrivateLevel2Folders(prev => ({ ...prev, value: [...prev.value, folder] }));
        })
        .catch(e => setError(e))
        .finally(() => {
          getBuilderDraftActualFilesCount(offer.id).catch(e => setError(e));
          setBuilderState({ saving: false });
    
          if (level === 0) setPublicFolders(prev => ({ ...prev, uploading: false }));
          if (level === 1) setPrivateLevel1Folders(prev => ({ ...prev, uploading: false }));
          if (level === 2) setPrivateLevel2Folders(prev => ({ ...prev, uploading: false }));
        });
      }
    });
  };
  const deleteExistingDocument = ({ id, level }) => {
    setBuilderState({ saving: true })
    .then(() =>
      deleteDocument(id)
      .then(() => {
        if (level === 0) setPublicFiles(prev => ({ ...prev, value: _filter(prev.value, v => v.id !== id) }));
        if (level === 1) setPrivateLevel1Files(prev => ({ ...prev, value: _filter(prev.value, v => v.id !== id) }));
        if (level === 2) setPrivateLevel2Files(prev => ({ ...prev, value: _filter(prev.value, v => v.id !== id) }));
      })
      .catch(e => setError(e))
      .finally(() => {
        getBuilderDraftActualFilesCount(offer.id).catch(e => setError(e));
        setBuilderState({ saving: false });

        if (level === 0) setPublicFiles(prev => ({ ...prev, uploading: false }));
        if (level === 1) setPrivateLevel1Files(prev => ({ ...prev, uploading: false }));
        if (level === 2) setPrivateLevel2Files(prev => ({ ...prev, uploading: false }));
      })
    );
  };
  const deleteExistingFolder = ({ id, level }) => {
    setBuilderState({ saving: true })
    .then(() =>
      deleteFolder(id)
      .then(() => {
        if (level === 0) setPublicFolders(prev => ({ ...prev, value: _filter(prev.value, v => v.id !== id) }));
        if (level === 1) setPrivateLevel1Folders(prev => ({ ...prev, value: _filter(prev.value, v => v.id !== id) }));
        if (level === 2) setPrivateLevel2Folders(prev => ({ ...prev, value: _filter(prev.value, v => v.id !== id) }));
      })
      .catch(e => setError(e))
      .finally(() => {
        getBuilderDraftActualFilesCount(offer.id).catch(e => setError(e));
        setBuilderState({ saving: false });

        if (level === 0) setPublicFolders(prev => ({ ...prev, uploading: false }));
        if (level === 1) setPrivateLevel1Folders(prev => ({ ...prev, uploading: false }));
        if (level === 2) setPrivateLevel2Folders(prev => ({ ...prev, uploading: false }));
      })
    );
  };
  const createNewImage = ({ offer_id, document_id = null, image = null }) => {
    setBuilderState({ saving: true })
    .then(() => {
      if (!document_id) {
        createDealspaceDocument({ folder_id: offer.dealspace.root_folder.id, document: image })
        .then(({ payload: { response: { data: { document: { id } } } } }) =>
          createOfferImage({ offer_id, document_id: id, ...image && { image } })
          .then(({ payload: { data: { image } } }) => setImages(prev => ({ ...prev, value: [...prev.value, image] })))
          .catch(e => setError(e))
          .finally(() => {
            setBuilderState({ saving: false });
            setImages(prev => ({ ...prev, uploading: false }));
          })
        )
        .catch(e => setError(e));
      } else {
        createOfferImage({ offer_id, document_id, ...image && { image } })
        .then(({ payload: { data: { image } } }) => setImages(prev => ({ ...prev, value: [...prev.value, image] })))
        .catch(e => setError(e))
        .finally(() => {
          setBuilderState({ saving: false });
          setImages(prev => ({ ...prev, uploading: false }))
        });
      }
    });
  };
  const updateExistingImage = image => {
    setBuilderState({ saving: true })
    .then(() =>
      updateImage(image)
      .then(() => setImages(prev =>
        ({
          ...prev,
          value: _map(prev.value, v => v.id === image.id
            ? ({
                ...v,
                file: {
                  ...v.file, id: image.id, representations: _map(v.file.representations, r => ({ ...r, url: image.localFile }))
                }
              })
            : v
          )
        })
      ))
      .catch(e => setError(e))
      .finally(() => setBuilderState({ saving: false }))
    );
  };
  const deleteExistingImage = id => {
    setBuilderState({ saving: true })
    .then(() =>
      deleteImage(id)
      .then(() => setImages(prev => ({ ...prev, value: _filter(prev.value, v => v.id !== id) })))
      .catch(e => setError(e))
      .finally(() => {
        setBuilderState({ saving: false });
        setImages(prev => ({ ...prev, uploading: false }))
      })
    );
  };
  const mainExistingImage = id => {
    setBuilderState({ saving: true })
    .then(() =>
      mainImage(id)
      .catch(e => setError(e))
      .finally(() => setBuilderState({ saving: false }))
    );
  };
  const processOffer = o => {
    setBuilderState({ saving: true })
    .then(() =>
      updateOffer({ id: offer.id, ...o })
      .catch(e => setError(e))
      .finally(() => setBuilderState({ saving: false }))
    );
  };
  const createNewSender = ({ offer_id, sender }) => {
    setBuilderState({ saving: true })
    .then(() =>
      createSender({ offer_id, sender })
      .then(({ payload: { data } }) => setSenders(prev => ({ value: [...prev.value, data.sender], error: '' })))
      .catch(e => setError(e))
      .finally(() => setBuilderState({ saving: false }))
    );
  };
  const updateExistingSender = sender => {
    setBuilderState({ saving: true })
    .then(() =>
      updateSender(sender)
      .catch(e => setError(e))
      .finally(() => setBuilderState({ saving: false }))
    );
  };
  const deleteExistingSender = id => {
    setBuilderState({ saving: true })
    .then(() =>
      deleteSender(id)
      .catch(e => setError(e))
      .finally(() =>setBuilderState({ saving: false }))
    );
  };
  const deleteExistingTemplate = id => {
    setBuilderState({ saving: true })
    .then(() =>
      deleteTemplate(id)
      .then(() =>
        setTemplates(prev => ({ ...prev, value: _filter(prev.value, v => v.id !== id) }))
      )
      .catch(e => setError(e))
      .finally(() => setBuilderState({ saving: false }))
    );
  };
  const handlePrivacyTypeChange = v => {
    setPrivacyType(v);
    if (v === 'market') setHasExpiration(true);
    if (!!images.error) setImages(prev => ({ ...prev, error: '' }));
    if (!!expiredAt.error) setExpiredAt(prev => ({ ...prev, error: '' }));
    if (needToUpdateField(offer.public, v === 'market')) {
      processOffer({ public: v === 'market' });
    };
  };
  const handleSendersChange = s => {
    setShowAlert(false);
    if (s.reason === 'reorderOptions') {
      setSenders({ value: s.value, error: '' });
    }
    /*** Добавили сендера ***/
    if (s.reason === 'selectOption') {
      const newSenders = _differenceBy(s.v, senders.value, 'user.id');
      
      if (!!newSenders.length) {
        const sender = { user_id: newSenders[0].user.id };

        createNewSender({ offer_id: offer.id, sender });
      }
    }
    /*** Удалили сендера откликнув опцию автокомплита ***/
    if (s.reason === 'removeOption') {
      setSenders({ value: s.v, error: '' });

      const removalSenders = _differenceBy(senders.value, s.v, 'user.id');

      if (!!removalSenders.length) {
        deleteExistingSender(removalSenders[0].id);
      }
    }
    /*** Удалили сендера иконкой на чипе ***/
    if (s.reason === 'removeChip') {
      setSenders({ value: _filter(senders.value, i => i.user.id !== s.id), error: '' });

      const removalSender = _find(senders.value, i => i.user.id === s.id);

      if (!!removalSender) {
        deleteExistingSender(removalSender.id);
      }
    }
    /*** Обновили сендера ***/
    if (s.reason === 'updateOption') {
      const foundSender = _find(senders.value, i => i.id === s.sender.id);
      const updatableSender = {
        ...!_isUndefined(s.sender.position) && needToUpdateField(foundSender.position, s.sender.position) && { position: s.sender.position },
        ...!_isUndefined(s.sender.title) && needToUpdateField(foundSender.title, s.sender.title) && { title: s.sender.title ?? '' },
        ...!_isUndefined(s.sender.email) && needToUpdateField(foundSender.email, s.sender.email) && { email: s.sender.email ?? '' },
        ...!_isUndefined(s.sender.mobilePhone) && needToUpdateField(foundSender.mobile_phone, s.sender.mobilePhone) && { mobile_phone: s.sender.mobilePhone ?? '' }
      };

      setSenders(prev => ({ value: _map(prev.value, ps => ps.id === s.sender.id ? ({ ...ps, ...updatableSender }) : ps), error: '' }) );

      if (!_isEmpty(updatableSender)) updateExistingSender({ id: foundSender.id, ...updatableSender });
    }
  };
  const handlePropertiesChange = () => {
    setLocationsLocked(prev => !prev);
    processOffer({ locations_locked: !locationsLocked });
  };
  const handleHeadlineBlur = v => {
    setShowAlert(false);
    if (needToUpdateField(offer.headline, v)) {
      processOffer({ headline: v });
    }
  };
  const handleCanvasChange = c => {
    setShowAlert(false);
    if (!_isUndefined(c.description)) {
      setDescription(prev => ({ ...prev, ...c.description }));

      if (needToUpdateField(offer.description, c.description.value)) {
        processOffer({ description: c.description.value });
      }
    }
    if (!_isUndefined(c.hasLogo)) {
      setHasLogo(c.hasLogo);
      
      if (!c.hasLogo) {
        setLogo({ value: null, formattedValue: null });

        if (!!offer.logo?.id) {
          processOffer({ logo: null });
        }
      }
    }
    if (!_isUndefined(c.logo)) {
      setLogo(prev => ({ ...prev, ...c.logo }));

      if (!!c.logo.value && needToUpdateField(offer.logo?.id, c.logo.value)) {
        processOffer({ logo: c.logo.value });
      }
    }
    if (!_isUndefined(c.hasImages)) {
      setHasImages(c.hasImages);
    }
    if (!_isUndefined(c.images)) {
      if (c.images.reason === 'optionUploading') {
        setImages(prev => ({ ...prev, uploading: true }));
      }
      if (c.images.reason === 'optionSelected') {
        setImages(prev => ({ ...prev, uploading: true }));

        createNewImage({ offer_id: offer.id, document_id: c.images.id });
      }
      if (c.images.reason === 'optionUploaded') {
        setImages(prev => ({ ...prev, uploading: true }));

        createNewImage({ offer_id: offer.id, image: c.images.v });
      }
      if (c.images.reason === 'removeOption') {
        setImages(prev => ({ ...prev, uploading: true }));
        deleteExistingImage(c.images.id);
      }
      if (c.images.reason === 'mainOption') {
        setImages(prev => ({ ...prev, value: _map(prev.value, v => ({ ...v, main: v.id === c.images.id })) }));
        mainExistingImage(c.images.id);
      }
      if (c.images.reason === 'optionUpdated') {
        const updatedImage = { id: c.images.id, file: c.images.value, localFile: c.images.formattedValue.localUrl };

        updateExistingImage(updatedImage);
      }
    }
    if (!_isUndefined(c.publicFiles)) {
      if (c.publicFiles.reason === 'optionUploading') {
        if (c.publicFiles.type === 'file') {
          setPublicFiles(prev => ({ ...prev, uploading: true }));
        } else {
          setPublicFolders(prev => ({ ...prev, uploading: true }));
        }
      }
      if (c.publicFiles.reason === 'optionSelected') {
        if (c.publicFiles.type === 'file') {
          setPublicFiles(prev => ({ ...prev, uploading: true }));
          createNewDocument({
            document_id: c.publicFiles.id,
            document: { locked: false, approval_required: false },
            level: c.publicFiles.level
          });
        } else {
          setPublicFolders(prev => ({ ...prev, uploading: true }));
          createNewFolder({
            folder_id: c.publicFiles.id,
            folder: { locked: false, approval_required: false },
            level: c.publicFiles.level
          });
        }
      }
      if (c.publicFiles.reason === 'optionUploaded') {
        if (c.publicFiles.type === 'file') {
          setPublicFiles(prev => ({ ...prev, uploading: true }));
          createNewDocument({ document: c.publicFiles.v, level: c.publicFiles.level });
        } else {
          setPublicFolders(prev => ({ ...prev, uploading: true }));
          createNewFolder({ folder: c.publicFiles.id, level: c.publicFiles.level });
        }
      }
      if (c.publicFiles.reason === 'removeOption') {
        if (c.publicFiles.type === 'file') {
          setPublicFiles(prev => ({ ...prev, uploading: true }));
          deleteExistingDocument({ id: c.publicFiles.id, level: c.publicFiles.level });
        } else {
          setPublicFolders(prev => ({ ...prev, uploading: true }));
          deleteExistingFolder({ id: c.publicFiles.id, level: c.publicFiles.level });
        }
      }
    }
    if (!_isUndefined(c.privateFiles)) {
      if (c.privateFiles.reason === 'optionUploading') {
        if (c.privateFiles.type === 'file') {
          if (c.privateFiles.level === 1) setPrivateLevel1Files(prev => ({ ...prev, uploading: true }));
          if (c.privateFiles.level === 2) setPrivateLevel2Files(prev => ({ ...prev, uploading: true }));
        } else {
          if (c.privateFiles.level === 1) setPrivateLevel1Folders(prev => ({ ...prev, uploading: true }));
          if (c.privateFiles.level === 2) setPrivateLevel2Folders(prev => ({ ...prev, uploading: true }));
        }
      }
      if (c.privateFiles.reason === 'optionSelected') {
        if (c.privateFiles.type === 'file') {
          if (c.privateFiles.level === 1) setPrivateLevel1Files(prev => ({ ...prev, uploading: true }));
          if (c.privateFiles.level === 2) setPrivateLevel2Files(prev => ({ ...prev, uploading: true }));
          createNewDocument({
            document_id: c.privateFiles.id,
            document: { locked: true, ...c.privateFiles.level === 2 && { approval_required: true } },
            level: c.privateFiles.level
          });
        } else {
          if (c.privateFiles.level === 1) setPrivateLevel2Folders(prev => ({ ...prev, uploading: true }));
          if (c.privateFiles.level === 2) setPrivateLevel2Folders(prev => ({ ...prev, uploading: true }));
          createNewFolder({
            folder_id: c.privateFiles.id,
            folder: { locked: true, ...c.privateFiles.level === 2 && { approval_required: true } },
            level: c.privateFiles.level
          });
        }
      }
      if (c.privateFiles.reason === 'optionUploaded') {
        if (c.privateFiles.type === 'file') {
          if (c.privateFiles.level === 1) setPrivateLevel1Files(prev => ({ ...prev, uploading: true }));
          if (c.privateFiles.level === 2) setPrivateLevel2Files(prev => ({ ...prev, uploading: true }));
          createNewDocument({ document: c.privateFiles.v, level: c.privateFiles.level });
        } else {
          if (c.privateFiles.level === 1) setPrivateLevel1Folders(prev => ({ ...prev, uploading: true }));
          if (c.privateFiles.level === 2) setPrivateLevel2Folders(prev => ({ ...prev, uploading: true }));
          createNewFolder({ folder: c.privateFiles.v, level: c.privateFiles.level });
        }
      }
      if (c.privateFiles.reason === 'removeOption') {
        if (c.privateFiles.type === 'file') {
          if (c.privateFiles.level === 1) setPrivateLevel1Files(prev => ({ ...prev, uploading: true }));
          if (c.privateFiles.level === 2) setPrivateLevel2Files(prev => ({ ...prev, uploading: true }));
          deleteExistingDocument({ id: c.privateFiles.id, level: c.privateFiles.level });
        } else {
          if (c.privateFiles.level === 1) setPrivateLevel1Folders(prev => ({ ...prev, uploading: true }));
          if (c.privateFiles.level === 2) setPrivateLevel2Folders(prev => ({ ...prev, uploading: true }));
          deleteExistingFolder({ id: c.privateFiles.id, level: c.privateFiles.level }); 
        }
      }
    }
  };
  const handleEmailStyleChange = v => {
    setShowAlert(false);
    setEmailStyle(v);
    if (needToUpdateField(offer.email_style, v)) {
      processOffer({ email_style: v });
    }
  };
  const toggleExpiration = ({ target: { checked } }) => {
    setShowAlert(false);
    setHasExpiration(checked);
    if (!checked) {
      setExpiredAt({ value: '', formattedValue: null, error: '' });
      processOffer({ expired_at: null });
    }
  };
  const handleExpiredAtChange = ({ value, formattedValue, error }) => {
    setShowAlert(false);
    setExpiredAt({ value, formattedValue, error });
    if (error) return;
    if (needToUpdateField(offer.expired_at, value)) {
      processOffer({ expired_at: value });
    }
  };
  const toggleDeadline = ({ target: { checked } }) => {
    setShowAlert(false);
    setHasDeadline(checked);
    if (!checked) {
      setDeadlineType({ value: '', formattedValue: null, error: '' });
      setDeadlineAt({ value: '', formattedValue: null, error: '' });
      processOffer({ deadline_type: null, deadline_at: null });
    }
  };
  const handleDeadlineTypeChange = value => {
    setShowAlert(false);
    setDeadlineType({ value, error: '' });
    if (needToUpdateField(offer.deadline_type, value)) {
      processOffer({ deadline_type: value });
    }
  };
  const handleDeadlineAtChange = ({ value, formattedValue, error }) => {
    setShowAlert(false);
    setDeadlineAt({ value, formattedValue, error });
    if (error) return;
    if (needToUpdateField(offer.deadline_at, value)) {
      processOffer({ deadline_at: value });
    }
  };
  const handleChangeTemplate = v => {
    setShowAlert(false);
    setTemplates(prev => ({ ...prev, value: [v, ...prev.value], error: '' }));
  };
  const handleRemoveTemplate = id => {
    setShowAlert(false);
    deleteExistingTemplate(id);
  };

  /*** Начальное значение ***/
  useEffect(() => {
    if (!fetching) {
      /*** Получаем изображения ***/
      getBuilderImages({ offer_id: offer.id, offset: 0 })
      .then(({ payload: { data: { images } } }) => setImages(prev => ({ ...prev, value: images }) ))
      .catch(e => setError(e))
      .finally(() => setImages(prev => ({ ...prev, fetching: false }) ));
      /*** Получаем открытые файлы ***/
      getBuilderPublicDocuments({ folder_id: offer.root_folder.id, offset: 0 })
      .then(({ payload: { data: { documents } } }) => setPublicFiles(prev => ({ ...prev, value: documents })))
      .catch(e => setError(e))
      .finally(() => setPublicFiles(prev => ({ ...prev, fetching: false })));
      /*** Получаем открытые папки ***/
      getBuilderPublicFolders({ folder_id: offer.root_folder.id, offset: 0 })
      .then(({ payload: { data: { folders } } }) => setPublicFolders(prev => ({ ...prev, value: folders })))
      .catch(e => setError(e))
      .finally(() => setPublicFolders(prev => ({ ...prev, fetching: false })));
      /*** Получаем закрытые файлы ***/
      getBuilderPrivateLevel1Documents({ folder_id: offer.root_folder.id, offset: 0 })
      .then(({ payload: { data: { documents } } }) => setPrivateLevel1Files(prev => ({ ...prev, value: documents })))
      .catch(e => setError(e))
      .finally(() => setPrivateLevel1Files(prev => ({ ...prev, fetching: false })));
      getBuilderPrivateLevel2Documents({ folder_id: offer.root_folder.id, offset: 0 })
      .then(({ payload: { data: { documents } } }) => setPrivateLevel2Files(prev => ({ ...prev, value: documents })))
      .catch(e => setError(e))
      .finally(() => setPrivateLevel2Files(prev => ({ ...prev, fetching: false })));
      /*** Получаем закрытые папки ***/
      getBuilderPrivateLevel1Folders({ folder_id: offer.root_folder.id, offset: 0 })
      .then(({ payload: { data: { folders } } }) => setPrivateLevel1Folders(prev => ({ ...prev, value: folders })))
      .catch(e => setError(e))
      .finally(() => setPrivateLevel1Folders(prev => ({ ...prev, fetching: false })));
      getBuilderPrivateLevel2Folders({ folder_id: offer.root_folder.id, offset: 0 })
      .then(({ payload: { data: { folders } } }) => setPrivateLevel2Folders(prev => ({ ...prev, value: folders })))
      .catch(e => setError(e))
      .finally(() => setPrivateLevel2Folders(prev => ({ ...prev, fetching: false })));
      /*** Получаем шаблоны ***/
      getTemplates({ offer_id: offer.id, offset: 0 })
      .then(({ payload: { data: { templates } } }) => setTemplates(prev => ({ ...prev, value: templates })))
      .catch(e => setError(e))
      .finally(() => setTemplates(prev => ({ ...prev, fetching: false })));
      /*** Получаем сендеров ***/
      getBuilderSenders({ offer_id: offer.id, offset: 0 })
      .then(({ payload: { data: { senders } } }) => setSenders({ value: _orderBy(senders, ['position'], ['asc']), error: '' }))
      .catch(e => setError(e));
      setPrivacyType(offer.public ? 'market' : 'private');
      setDescription({ value: offer.description ?? '', error: '', charsCount: 100, fetched: true });
      if (!!offer.headline) setHeadline({ value: offer.headline, error: '' });
      if (!!offer.logo) {
        setHasLogo(true);
        setLogo({ value: offer.logo.id, formattedValue: offer.logo, uploading: false });
      }
      setLocationsLocked(offer.locations_locked);
      if (!!offer.email_style) setEmailStyle(offer.email_style);
      if (offer.public) setHasExpiration(true);
      if (!!offer.expired_at) {
        setHasExpiration(true);
        setExpiredAt({ value: offer.expired_at, formattedValue: fromIsoString(offer.expired_at), error: '' });
      }
      if (!!offer.deadline_type || !!offer.deadline_at) {
        setHasDeadline(true);
        if (!!offer.deadline_type) setDeadlineType({ value: offer.deadline_type, error: '' });
        if (!!offer.deadline_at) setDeadlineAt({ value: offer.deadline_at, formattedValue: fromIsoString(offer.deadline_at), error: '' });
      }
    }
  }, [fetching, getBuilderImages, getBuilderPrivateLevel1Documents, getBuilderPrivateLevel2Documents, getBuilderPublicDocuments, getBuilderPrivateLevel1Folders, getBuilderPrivateLevel2Folders, getBuilderPublicFolders, getBuilderSenders, getTemplates, setError]); // eslint-disable-line react-hooks/exhaustive-deps
  
  useImperativeHandle(ref, () => {
    return {
      checkRequired () {
        let hasError = false;

        if (!senders.value.length) {
          setSenders(prev => ({ ...prev, error: 'Select at least one sender' }));
          setShowAlert(true);
          hasError = true;
        }
        if (!checkEmptyString(headline.value)) {
          setHeadline(prev => ({ ...prev, error: 'Headline can\'t be empty' }));
          setShowAlert(true);
          hasError = true;
        }
        if (hasDeadline) {
          if (!deadlineType.value) {
            setDeadlineType(prev => ({ ...prev, error: 'Select deadline type' }));
            setShowAlert(true);
            hasError = true;
          }
          if (!deadlineAt.value) {
            setDeadlineAt(prev => ({ ...prev, error: 'Enter deadline date' }));
            setShowAlert(true);
            hasError = true;
          }
        }
        if (hasExpiration) {
          if (!expiredAt.value) {
            setExpiredAt(prev => ({ ...prev, error: 'Enter expiration date' }));
            setShowAlert(true);
            hasError = true;
          }
        }
        if (!checkEmptyString(description.value)) {
          setDescription(prev => ({ ...prev, error: 'At least 100 characters of description required' }));
          setShowAlert(true);
          hasError = true;
        }
        if (checkEmptyString(description.value) && description.charsCount < 100) {
          setDescription(prev => ({ ...prev, error: 'At least 100 characters of description required' }));
          setShowAlert(true);
          hasError = true;
        }
        if (privacyType === 'market') {
          if (!images.value.length) {
            setImages(prev => ({ ...prev, error: 'Image required' }));
            setShowAlert(true);
            hasError = true;
          }
          if (!expiredAt.value) {
            setExpiredAt(prev => ({ ...prev, error: 'Enter expiration date' }));
            setShowAlert(true);
            hasError = true;
          }
        }
        if (locationsLocked || !!privateLevel1Files.value.length || !!privateLevel2Files.value.length || !!privateLevel1Folders.value.length || !!privateLevel2Folders.value.length) {
          if (!templates.value.length) {
            setTemplates(prev => ({ ...prev, error: 'Upload confidentiality agreement' }));
            setShowAlert(true);
            hasError = true;
          }
        }
        const propertiesFailed = propertiesRef.current.checkRequired();

        if (propertiesFailed) {
          setShowAlert(true);

          return false;
        }

        return !hasError;
      }
    };
  }, [deadlineAt, deadlineType, description, expiredAt, hasDeadline, hasExpiration, headline, images, locationsLocked, privacyType, privateLevel1Files, privateLevel2Files, privateLevel1Folders, privateLevel2Folders, senders, templates]);

  return (
    <Box sx={{ pt: '50px', pb: '100px' }}>
      {showAlert && <Alert severity='error' sx={{ mb: 2 }}>Please complete all the required fields</Alert>}
      {fetching ? <Skeleton width={400} height={800} animation='wave' variant='text' /> :
        <>
          <Typography variant='caption' color='textSecondary' component='div' mb='8px'>Select Offering Visibility:</Typography>
          <StyledRadioGroup
            value={privacyType}
            onChange={(_, v) => handlePrivacyTypeChange(v)}
            sx={{ mb: '60px' }}
          >
            <VisibilityRadioButton value='private' />
            <VisibilityRadioButton value='market' />
          </StyledRadioGroup>
          <Senders
            senders={senders}
            onChange={handleSendersChange}
          />
          <Properties
            locationsLocked={locationsLocked}
            onChange={handlePropertiesChange}
            ref={propertiesRef}
          />
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '8px', mb: '40px' }}>
            <TextField
              variant='standard'
              label='Offering Headline:'
              required
              fullWidth
              multiline
              inputProps={{ maxLength: 160 }}
              value={headline.value}
              onChange={({ target: { value } }) => setHeadline({ value, error: !checkEmptyString(value) ? 'Name can\'t be empty' : '' })}
              onBlur={({ target: { value } }) => handleHeadlineBlur(value)}
              error={!!headline.error}
              helperText={headline.error}
            />
            <SymbolCounter max={160} current={headline.value.length} />
          </Box>
          <Canvas
            {...canvasProps}
            onChange={handleCanvasChange}
          />
          <Typography variant='caption' color='textSecondary' component='div' mb='8px' mt='60px'>Select Email Notification Style:</Typography>
          <StyledRadioGroup
            value={emailStyle}
            onChange={({ target: { value } }) => handleEmailStyleChange(value)}
          >
            <NotificationRadioButton value='basic' />
            <NotificationRadioButton value='full' />
          </StyledRadioGroup>
          <DateFormContainer sx={{ pt: '42px' }}>
            <FormControlLabel
              label='Automatically ‘End’ Offering on a specific date'
              disabled={privacyType === 'market'}
              control={
                <Checkbox
                  color='secondary'
                  checked={hasExpiration}
                  onChange={toggleExpiration}
                />
              }
            />
            <Collapse in={hasExpiration}>
              <Box>
                <DateField
                  fullWidth
                  margin='dense'
                  label='Expiration Date'
                  disablePast
                  value={expiredAt.formattedValue}
                  onChange={handleExpiredAtChange}
                  error={!!expiredAt.error}
                  helperText=''
                />
                {!!expiredAt.error && <BuilderErrorLabel value={expiredAt.error} />}
              </Box>
            </Collapse>
          </DateFormContainer>
          <DateFormContainer>
            <FormControlLabel
              label='Set Offering Deadline'
              control={
                <Checkbox
                  color='secondary'
                  checked={hasDeadline}
                  onChange={toggleDeadline}
                />
              }
            />
            <Collapse in={hasDeadline}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', pt: '4px' }}>
                <Box>
                  <FormSelect
                    fullWidth
                    label='Deadline Type'
                    paperVariant='outlined'
                    paperColor='paper'
                    sx={{ flexShrink: 0, minWidth: 260 }}
                    value={deadlineType.value}
                    onChange={({ target: { value } }) => handleDeadlineTypeChange(value)}
                  >
                    {_map(deadlineTypes, t => <MenuItem key={t} value={t}>{_startCase(t)}</MenuItem>)}
                  </FormSelect>
                  {!!deadlineType.error && <BuilderErrorLabel value={deadlineType.error} />}
                </Box>
                <Box>
                  <DateField
                    fullWidth
                    label='Date'
                    value={deadlineAt.formattedValue}
                    onChange={handleDeadlineAtChange}
                    error={!!deadlineAt.error}
                    maxDate={expiredAt.formattedValue}
                    helperText=''
                  />
                  {!!deadlineAt.error && <BuilderErrorLabel value={deadlineAt.error} />}
                </Box>
                <Box>
                  <TimePicker
                    label='Time'
                    fullWidth
                    value={deadlineAt.formattedValue}
                    onChange={handleDeadlineAtChange}
                    error={false}
                    processing={false}
                  />
                </Box>
              </Box>
            </Collapse>
          </DateFormContainer>
          <Agreement
            {...templates}
            show={showAgreement}
            dealspaceFolderId={offer.dealspace.root_folder.id}
            onChange={handleChangeTemplate}
            onRemove={handleRemoveTemplate}
          />
        </>
      }
    </Box>
  );
});

const WrappedComponent = forwardRef((props, ref) => {
  return (
    <ErrorWrapper>
      <Form {...props} ref={ref} />
    </ErrorWrapper>
  );
});

export default memo(WrappedComponent);