import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
// Local files
import AppNavigation from '../AppNavigation/AppNavigation';
import Cable from 'components/Controllers/Cable/Cable';
import AuthorizationDialog from 'components/Dialogs/Authorization/Authorization';
import AccessBetaDialog from 'components/Dialogs/AccessBeta/AccessBeta';
import AccessFeedDialog from 'components/Dialogs/AccessFeed/AccessFeed';
import AccessOfferingDialog from 'components/Dialogs/AccessOffering/AccessOffering';
import AvatarDialog from 'components/Dialogs/EditAvatar/EditAvatar';
import UploadContactsDialog from 'components/Dialogs/UploadContacts/UploadContacts';
import EditProfileDialog from 'components/Dialogs/EditProfile/EditProfile';
import DeleteDialog from 'components/Dialogs/Delete/Delete';
import GroupDialog from 'components/Dialogs/Group/Group';
import GroupInvitationDialog from 'components/Dialogs/GroupInvitation/GroupInvitation';
import HeaderDialog from 'components/Dialogs/EditHeader/EditHeader';
import LockedContentDialog from 'components/Dialogs/LockedContent/LockedContent';
import ViewFileDialog from 'components/Dialogs/ViewFile/ViewFile';
import ContactsSnackbar from 'components/Contacts/Snackbar/Snackbar';
import ContentSnackbar from 'components/Contents/Snackbar/Snackbar';
import RecipientDrawer from 'components/Drawers/Recipient/Recipient';
import CreateListDialog from 'components/Dialogs/CreateList/CreateList';
import PreBuilderDialog from 'components/Dialogs/PreBuilder/PreBuilder';
import InvitationController from 'components/Controllers/Invitation/Invitation';
import RecipientController from 'components/Controllers/Recipient/Recipient';
import ConversationController from 'components/Controllers/Conversation/Conversation';
import AfterAuthController from 'components/Controllers/AfterAuth/AfterAuth';
import SessionController from 'components/Controllers/Session/Session';

const Layout = () => {
  return (
    <>
      <AfterAuthController />
      <AppNavigation />
      <AuthorizationDialog />
      <AccessBetaDialog />
      <AccessFeedDialog />
      <AccessOfferingDialog />
      <AvatarDialog />
      <Cable />
      <CreateListDialog />
      <ContactsSnackbar />
      <ContentSnackbar />
      <ConversationController />
      <DeleteDialog />
      <EditProfileDialog />
      <GroupDialog />
      <GroupInvitationDialog />
      <HeaderDialog />
      <InvitationController />
      <LockedContentDialog />
      <RecipientDrawer />
      <RecipientController />
      <SessionController />
      <ViewFileDialog />
      <UploadContactsDialog />
      <PreBuilderDialog />
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <Outlet />
      </Box>
    </>
  );
};

export default memo(Layout);