import { memo, useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
// Local files
import ArrowBackIcon from 'components/Common/Icons/ArrowBackIcon';
import MainCard from 'components/Users/MainCard/MainCard';
import DetailsCard from 'components/Users/DetailsCard/DetailsCard';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useMixpanel from 'hooks/useMixpanel';
import useProfile from 'hooks/useProfile';
import useUsers from 'hooks/useUsers';

const Profile = () => {
  const navigate = useNavigate();
  const { localSession, myUsername } = useCustomSelector(state => ({
    localSession: state.sessions.session,
    myUsername: state.profile.user.username
  }));
  const { username } = useParams();
  const { getUser } = useUsers();
  const { setError } = useError();
  const { track } = useMixpanel();
  const { getFullProfile } = useProfile();
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (localSession.checked) {
      if (myUsername === username) {
        getFullProfile()
        .then(() => setFetched(true))
        .catch(e => setError(e));
      } else {
        getUser(username)
        .then(({ payload: { data: { id } } }) => {
          setFetched(true);
          track('Profile visited', { id });
        })
        .catch(e => setError(e));
      }
    }
  }, [getFullProfile, getUser, localSession.checked, myUsername, setError, track, username]);

  if (!fetched) return null;
  return (
    <Container
      maxWidth={false}
      sx={{ py: { xs: '16px', md: '32px' }}}
    >
      {localSession.userableType === 'user' &&
        <Button
          onClick={() => navigate(-1)}
          startIcon={<ArrowBackIcon />}
          sx={{ fontWeight: 600 }}
        >
          Back
        </Button>
      }
      <Container
        maxWidth='md'
        disableGutters
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
      >
        <MainCard />
        <DetailsCard />
      </Container>
    </Container>
  );
};

export default memo(Profile);