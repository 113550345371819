import { memo } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import _map from 'lodash/map';
import { NumericFormat } from 'react-number-format';
// Local files
import { Container, Title, Content, FileName, FileInfo } from './Snackbar.styled';
import useContacts from 'hooks/useContacts';
import useCustomSelector from 'hooks/useCustomSelector';

const ContactsSnackbar = () => {
  const { clearUploadingFiles } = useContacts();
  const { data, silent } = useCustomSelector(state => state.contacts.snackbar);
  const open = !!data.length && !silent;

  const handleClose = () => clearUploadingFiles();

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      onClose={null}
    >
      <Container>
        <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
          <Title>Contacts Uploader</Title>
          <IconButton onClick={handleClose} sx={{ pr: '14px' }}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </Box>
        <Content>
          {_map(data, ({ file_id, filename, lines_count, progress }) =>
            <Box key={file_id} display='flex' justifyContent='space-between' gap={5} mb='12px'>
              <Box sx={{ maxWidth: '75%' }}>
                <FileName>&#8220;{filename}&#8221;</FileName>
                <FileInfo>uploading <NumericFormat value={lines_count} displayType='text' thousandSeparator=',' /> contacts</FileInfo>
              </Box>
              <CircularProgress
                variant='determinate'
                value={progress * 100}
                size={20}
                sx={{ color: 'rgba(59, 175, 253, 1)' }}
              />
            </Box>
          )}
        </Content>
      </Container>
    </Snackbar>
  );
};

export default memo(ContactsSnackbar);