import { memo } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';

const Item = ({ title, selected, ...otherProps }) => {
  return (
    <ListItemButton
      sx={{  width: 'inherit', overflow: 'hidden', px: '4px' }}
      {...otherProps}
    >
      <Typography
        variant='caption'
        component='div'
        noWrap
        sx={{ flexGrow: 1, fontWeight: 600, textDecoration: !selected ?  'unset' : 'underline' }}
      >
        {title}
      </Typography>
    </ListItemButton>
  );
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Item {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);