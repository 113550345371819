import { memo } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _startsWith from 'lodash/startsWith';
import PropTypes from 'prop-types'
// Local files
import ContentAccordion from '../ContentAccordion/ContentAccordion';

const ContentSection = ({ data, onChange }) => {
  const handleCreate = () => onChange({
    value: [{ id: `new_${data.value.length + 1}`, destroy: false }, ...data.value],
    formattedValue: [{ id: `new_${data.formattedValue.length + 1}`, destroy: false }, ...data.formattedValue]
  });
  const handleChange = ({ id, value, formattedValue }) => onChange({
    value: _map(data.value, d => d.id === id ? ({ ...d, ...value }) : d),
    formattedValue: _map(data.formattedValue, d => d.id === id ? ({ ...d, ...formattedValue }) : d)
  });
  const handleDelete = id => onChange({
    value: _map(data.value, d => d.id === id ? ({ ...d, destroy: true }) : d),
    formattedValue: _filter(data.formattedValue, fv => fv.id !== id)
  });

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pb: '24px' }}>
        <Typography variant='caption'>Content</Typography>
        <Button variant='outlined' onClick={handleCreate}>+ Add Content</Button>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        {_map(data.value, ({ id, ...fields }) =>
          <ContentAccordion
            key={id}
            edit={!_startsWith(id, 'new_')}
            onChange={({ value, formattedValue }) => handleChange({ id, value, formattedValue })}
            onDelete={() => handleDelete(id)}
            data={fields}
          />
        )}
      </Box>
    </Box>
  );
};

ContentSection.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.array.isRequired,
    formattedValue: PropTypes.array.isRequired
  }),
  onChange: PropTypes.func.isRequired
};

export default memo(ContentSection);