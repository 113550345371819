import { memo, useMemo, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckboxLabel from './CheckboxLabel/CheckboxLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import Scrollbars from 'react-custom-scrollbars-2';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import businessDays from 'business-days-js';
import PropTypes from 'prop-types';
// Local files
import { Actions, Content, Root, SmallText, Text, Title } from './AdvancedEmailSettings.styled';
import ShareSettingsMembers from 'components/Members/ShareSettingsMembers/ShareSettingsMembers';
import TimePicker from 'components/Common/Inputs/TimePicker/TimePicker';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { needToUpdateField, fromIsoString } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useMembers from 'hooks/useMembers';
import useShares from 'hooks/useShares';
import useSuccess from 'hooks/useSuccess';

const now = new Date();

/**
 * 
 * @param {object} data
 * @param {number} days
 * @returns 
 */

const getBoosterData = (data, days) => {
  if (!data) return { helperText: '', disabled: false };
  
  const booster = _find(data.boosters, b => b.scheduled_delivery === `days_${days}`);

  if (booster) {
    if (booster.status === 'notified') return { helperText: 'Sent', disabled: true };
    const notifyAt = new Date(booster.notify_at);

    if (notifyAt < now) return { helperText: 'Date passed. No longer available.', disabled: true };
    return { helperText: null, disabled: false };
  } else {
    const createdAt = new Date(data.created_at);
    const bDays = businessDays();
    const futureDate = bDays.addDays(createdAt, days);

    if (futureDate.toDate() < now) return { helperText: 'Date passed. No longer available.', disabled: true };
    return { helperText: null, disabled: false };
  }
};

const AdvancedEmailSettings = ({ open, data = null, onClose }) => {
  const { setError } = useError();
  const { getDealspaceMembers, clearLocalDealspaceMembers } = useMembers();
  const { getShare, updateShare } = useShares();
  const { setSuccess } = useSuccess();
  const dealspaceId = useCustomSelector(state => state.offers.activityLog.dealspace.id);
  const [members, setMembers] = useState([]);
  const [emailStyle, setEmailStyle] = useState('basic');
  const [scheduledDeliveries, setScheduledDeliveries] = useState([]);
  const [notifyAt, setNotifyAt] = useState({ value: '', formattedValue: null, error: '' });
  const [processing, setProcessing] = useState(false);
  const disabled = useMemo(() => {
    if (!members.length) return true;
    if (!!scheduledDeliveries.length && !notifyAt.formattedValue) return true;
    return false;
  }, [members, notifyAt, scheduledDeliveries]);

  const fetchMembers = ({ offset, dealspace_id, initial = false }) => {
    getDealspaceMembers({ offset, dealspace_id, status: 'active' })
    .then(({ payload: { data } }) => {
      if (initial) setMembers(data.members);
    })
    .catch(e => setError(e));
  };
  const onEntering = () => {
    fetchMembers({ offset: 0, dealspace_id: dealspaceId, initial: !data });
    if (data) {
      if (data.members) setMembers(data.members);
      setEmailStyle(data.email_style);
      if (data.scheduled_deliveries.length) setScheduledDeliveries(data.scheduled_deliveries);
      if (data.notify_at) setNotifyAt({ value: data.notify_at, formattedValue: fromIsoString(data.notify_at), error: '' });
    }
  };
  const onExited = () => {
    clearLocalDealspaceMembers();
  };
  const handleClose = (clear = false) => {
    if (processing) return;
    onClose(clear ? null : undefined);
    setMembers([]);
    setEmailStyle('basic');
    setScheduledDeliveries([]);
    setNotifyAt({ value: '', formattedValue: null, error: '' });
    setProcessing(false);
  };
  const handleCancel = () => handleClose(true);
  const handleSubmit = () => {
    const share = {
      ...(needToUpdateField(_map(data?.members ?? [], m => m.id), _map(members, m => m.id)) || !data?.id) && { member_ids: _map(members, m => m.id) },
      ...(needToUpdateField(data?.email_style, emailStyle) || !data?.id) && { email_style: emailStyle },
      ...(needToUpdateField(data?.scheduled_deliveries, scheduledDeliveries) || !data?.id) && { scheduled_deliveries: scheduledDeliveries },
      ...(needToUpdateField(data?.notify_at, notifyAt.value) || !data?.id) && { notify_at: notifyAt.value }
    };

    if (data?.id) {
      if (!_isEmpty(share)) {
        setProcessing(true);
        updateShare({ ...share, id: data.id })
        .then(() =>
          setSuccess('Advanced email settings updated')
          .then(() => {
            getShare(data.id).catch(e => setError(e))
            onClose()
          })
        )
        .catch(e => setError(e))
        .finally(() => setProcessing(false));
      } else {
        onClose();
      }
    } else {
      onClose({ ...share, members }); // Докидываем members для локального редактирования из окна шейра
    }
  };

  return (
    <Drawer
      anchor={'right'}
      open={open}
      onClose={() => !processing && handleClose()}
      PaperProps={{ sx: { width: { xs: '100%',  md: '460px' } } }}
      SlideProps={{ onEntering, onExited }}
      ModalProps={{ sx: { zIndex: 1300 } }}
    >
      <Root>
        <Title>Advanced email settings</Title>
        <Scrollbars autoHide autoHideTimeout={1000}>
          <Content>
            <Text>Set email notification reply-to</Text>
            <ShareSettingsMembers value={members} onChange={setMembers} />
            <Text sx={{ mt: 5 }}>Select Email Notification Style:</Text>
            <RadioGroup
              value={emailStyle}
              onChange={({ target: { value } }) => setEmailStyle(value)}
            >
              <FormControlLabel
                value='basic'
                control={<Radio color='secondary' size='small' />}
                label='Basic'
                sx={{ marginLeft: 0 }}
              />
              <FormControlLabel
                value='full'
                control={<Radio color='secondary' size='small'  />}
                label='Include content body'
                sx={{ marginLeft: 0 }}
              />
            </RadioGroup>
            <Text sx={{ mt: 5 }}>Boost email notification</Text>
            <SmallText sx={{ mt: 2 }}>If email notification still not opened by a recipient,</SmallText>
            <Box sx={{ pl: 2 }}>
              {_map([5, 10, 15, 20], days =>
                <FormControlLabel
                  key={days}
                  label={
                    <CheckboxLabel
                      days={days}
                      helperText={getBoosterData(data, days).helperText}
                    />
                  }
                  disabled={processing}
                  control={
                    <Checkbox
                      color='secondary'
                      disabled={getBoosterData(data, days).disabled}
                      checked={!!_find(scheduledDeliveries, sc => sc === `days_${days}`)}
                      onClick={() => setScheduledDeliveries(prev => !!_find(prev, p => p === `days_${days}`) ? _filter(prev, p => p !== `days_${days}`) : [...prev, `days_${days}`])}
                    />
                  }
                />
              )}
            </Box>
            {!!scheduledDeliveries.length &&
              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mt: 2 }}>
                <SmallText sx={{ mr: 1 }}>
                  then automatically resend email notification at (EST time)
                </SmallText>
                <TimePicker
                  label=''
                  processing={processing}
                  sx={{ width: '145px', '& input': { py: 1 } }}
                  value={notifyAt.formattedValue}
                  onChange={setNotifyAt}
                />
              </Box>
            }
          </Content>
        </Scrollbars>
        <Actions>
          <Button
            disabled={disabled}
            variant='text'
            color='error'
            sx={{ minWidth: 120 }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            disabled={disabled}
            variant='contained'
            color='primary'
            sx={{ minWidth: 120 }}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Actions>
      </Root>
    </Drawer>
  );
};

AdvancedEmailSettings.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    members: PropTypes.array,
    email_style: PropTypes.string,
    scheduled_deliveries: PropTypes.array,
    notify_at: PropTypes.string
  }),
  onClose: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <AdvancedEmailSettings {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);