import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
// Local files
import SearchIcon from 'components/Common/Icons/Search';
import CloseIcon from 'components/Common/Icons/CloseIcon';

const Search = forwardRef(({ disabled = false, onChange, fetching }, ref) => {
  const timerRef = useRef(null);
  const [query, setQuery] = useState('');
  const [queryChanged, setQueryChanged] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = ({ target: { value }, currentTarget }) => {
    setQuery(value);
    setAnchorEl(currentTarget);
    if (!queryChanged) setQueryChanged(true);
  };

  useEffect(() => {
    if (queryChanged) {
      timerRef.current = setTimeout(() => {
        onChange(query, anchorEl);
      }, 500);
    }

    return () => clearTimeout(timerRef.current);
  }, [query, queryChanged]); // eslint-disable-line
  useEffect(() => {
    return () => {
      setQuery('');
      setQueryChanged(false);
    };
  }, []);
  useImperativeHandle(ref, () => {
    return {
      clearQuery() {
        setQuery('');
      }
    };
  }, []);

  return (
    <InputBase
      disabled={disabled}
      startAdornment={<SearchIcon sx={{ color: '#507CF7', fontSize: '1.5rem', ml: '12px' }} />}
      endAdornment= {query && 
        <>
          {fetching && query.length > 2 && <CircularProgress sx={{ color: '#507CF7' }} size='24px' />}
          <IconButton onClick={ () => setQuery("") } >
            <CloseIcon sx={{ color: '#507CF7', fontSize: '1.5rem' }}  />
          </IconButton>
        </>
        }
      placeholder='Search users'
      fullWidth
      sx={{ 
        backgroundColor: '#FAFBFE',
        height: '31px',
        '& input': { color: '#507CF7', fontSize: '0.75rem', paddingLeft: '8px', '&::placeholder': { opacity: 1 } }, 
        '& svg': { maxHeight: '15px', width: 'auto' }
      }}
      value={query}
      onChange={handleChange}
    />
  );
});

Search.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  fetching: PropTypes.bool
};

export default memo(Search);