import styled from '@mui/material/styles/styled';

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  color: '#fff',
  backgroundColor: theme.palette.section.chat.background.main,
  boxShadow: '-2px 0px 6px 2px rgba(0, 0, 0, 0.08)',
  [theme.breakpoints.up('md')]: {
    flexGrow: 0
  }
}));

const Header = styled('div')({
  flexShrink: 0,
  height: 81,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderBottom: '3px solid #45464C',
  padding: '12px 24px 6px'
});

const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.palette.section.chat.text
}));

const Content = styled('div')(() => ({
  flexGrow: 1,
  overflow: 'hidden'
}));

export { Root, Header, TitleContainer, Content };