import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';

export const Root = styled('div')({
  height: '100%',
  width: '100%',
  backgroundColor: '#fff',
  overflow: 'auto',
  padding: '26px 10px 8px'
});

export const Title = styled(Typography)({
  fontSize: '14px',
  fontWeight: 700,
  color: 'rgba(0,0,0,.8)',
  textAlign: 'center',
  paddingBottom: '36px'
});

export const Subtitle = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  textAlign: 'center',
  color: 'rgba(0,0,0,.8)'
});

export const Group = styled('div')({
  minWidth: '170px',
  maxWidth: '350px',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: '7px 9px',
  paddingBottom: '30px',
  '& > *': {
    flexShrink: 0,
    flexBasis: '170px',
  }
});

export const StyledInput = styled((props) => (
  <Input disableUnderline {...props} />
))({
  backgroundColor: '#ECEEF366',
  fontSize: '14px',
  color: '#000',
  padding: '0px 12px',
  '&.Mui-error': {
    color: 'red'
  }
});

export const SubmitButton = styled((props) => (
  <Button variant='contained' color='primary' {...props} />
))({
  display: 'flex',
  height: 'auto',
  fontWeight: 600,
  whiteSpace: 'nowrap',
  borderRadius: '50px',
  padding: '3px 10px',
  margin: '0 auto',
  '& .MuiButton-icon': {
    marginRight: '4px !important'
  },
  '& svg': {
    width: 16,
    height: 'auto',
  }
});

export const FooterText = styled(Typography)({
  display: 'block',
  textAlign: 'center',
  fontSize: '12px',
  paddingTop: '36px',
});