import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useError from 'hooks/useError';
import useFolders from 'hooks/useFolders';

const Folder = ({ open, folderId, onClose, data = null }) => {
  const { setError } = useError();
  const { createFolder, updateFolder } = useFolders();
  const [name, setName] = useState('');
  const [processing, setProcessing] = useState(false);
  const edit = !!data;
  const disabled = !name || processing;

  const onEntering = () => {
    if (data) {
      setName(data.name);
    }
  };
  const onExited = () => {
    setName('');
    setProcessing(false);
  };
  const handleSubmit = () => {
    if (data) {
      if (data.name !== name) {
        const folder = { id: data.id, name };

        setProcessing(true);
        updateFolder(folder)
        .then(onClose)
        .catch(e => setError(e))
        .finally(() => setProcessing(false));
      } else {
        onClose();
      }
    } else {
      const folder = { name };

      setProcessing(true);
      createFolder({ folder_id: folderId, folder, initial: true })
      .then(onClose)
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      otherPaperStyle={{ width: '100%', maxWidth: 648 }}
      title={edit ? 'Rename Folder' : 'Create New Folder'}
      TransitionProps={{ onEntering, onExited }}
      actions={
        <Button
          variant='contained'
          color='primary'
          disabled={disabled}
          onClick={handleSubmit}
        >
          {!!edit ? 'Save' : 'Create Folder'}
        </Button>
      }
    >
      <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
        <TextField
          fullWidth
          sx={{ maxWidth: 388 }}
          placeholder='Folder Name'
          value={name}
          onChange={({ target: { value } }) => setName(value)}
        />
      </DialogContent>
    </BaseDialog>
  );
};


Folder.propTypes = {
  open: PropTypes.bool.isRequired,
  folderId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })
};

const WrappedCopmonent = props => {
  return (
    <ErrorWrapper>
      <Folder {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedCopmonent);