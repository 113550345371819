import { memo } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { toIsoString } from 'helpers';

const DateField = props => {
  const {
    withTime = false, fullWidth = false, disablePast = false, disableFuture = false, margin = 'none', variant, label, disabled = false,
    error, helperText, value, onChange, onBlur = undefined, autoFocus = false, maxDateTime = null, maxDate = null, minDateTime = null, minDate = null
  } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {withTime ?
        <DateTimePicker
          disabled={disabled}
          disablePast={disablePast}
          disableFuture={disableFuture}
          maxDateTime={maxDateTime}
          minDateTime={minDateTime}
          label={label}
          value={value === '' ? null : value}
          onChange={(newValue, context) => {
            onChange({
              value: !!newValue && !context?.validationError ? toIsoString(new Date(newValue)) : '',
              formattedValue: newValue,
              error: newValue?.toString() === 'Invalid Date' || context?.validationError ? 'Date is invalid' : ''
            })
          }}
          renderInput={p =>
            <TextField
              autoFocus={autoFocus}
              variant={variant}
              fullWidth={fullWidth}
              helperText={helperText}
              margin={margin}
              onBlur={onBlur}
              error={error}
              {...p}
            />
          }
        /> :
        <DatePicker
          disabled={disabled}
          disablePast={disablePast}
          disableFuture={disableFuture}
          maxDate={maxDate}
          minDate={minDate}
          label={label}
          value={value === '' ? null : value}
          onChange={(newValue, context) => {
            onChange({
              value: !!newValue && !context?.validationError ? toIsoString(new Date(newValue)) : '',
              formattedValue: newValue,
              error: newValue?.toString() === 'Invalid Date' || context?.validationError ? 'Date is invalid' : ''
            })
          }}
          renderInput={p =>
            <TextField
              autoFocus={autoFocus}
              variant={variant}
              fullWidth={fullWidth}
              helperText={helperText}
              error={error}
              margin={margin}
              onBlur={onBlur}
              {...p}
            />
          }
        />
      }
    </LocalizationProvider>
  );
};

DateField.propTypes = {
  fullWidth: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  margin: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  helperText: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string, PropTypes.object
  ]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <DateField {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);