import styled from '@mui/material/styles/styled';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const Root = styled((props) => (
  <Paper variant='outlined' {...props} />
))(() => ({
  width: '100%'
}));
const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridRowGap: '16px',
  gridColumnGap: '40px',
  padding: '32px 28px',
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '1fr 3fr',
    padding: '60px 40px',
  }
}));
const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  [theme.breakpoints.up('md')]: {
    gap: '60px',
  }
}));

export { Root, GridContainer, Container };