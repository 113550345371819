import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';

const StyledButton = styled((props) => (
  <Button color='primary' variant='contained' {...props} />
))(({ theme }) => ({
  marginLeft: 24,
  '& .MuiButton-endIcon':{
    borderLeft: '1px solid #000',
    height: 38,
    display: 'flex',
    alignItems: 'center',
    paddingLeft:  '8px'
  }
}));

export { StyledButton };