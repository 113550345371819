import styled from '@mui/material/styles/styled';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

export const Root = styled(Paper)({
  width: '100%',
  overflow: 'hidden'
});

export const Grid = styled(Box)({
  width: '100%',
  paddingTop: 46,
  paddingBottom: 20,
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  overflowX: 'auto',
  '& > span > a': {
    width: '100%',
    maxWidth: '260px',
  }
});