import { memo } from 'react';
import Box from '@mui/material/Box';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import Empty from 'components/Groups/Empty/Empty';
import useCustomSelector from 'hooks/useCustomSelector';

const Groups = () => {
  const { pathname } = useLocation();
  const { activeGroup, groupsFetched } = useCustomSelector(state => {
    const activeGroup = state.groups.all.data[0];
    const groupsFetched = state.groups.all.pagination.total_count >= 0;

    return { activeGroup, groupsFetched };
  });

  if (pathname === '/groups') return <Navigate to={activeGroup.id} />;
  if (!groupsFetched) return null;
  if (!activeGroup) return <Box sx={{ height: '100%' }}><Empty /></Box>;
  return <Outlet />;
};

const ErrorFallback = ({ error }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Groups {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);