import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  setMapConfigs as setMapConfigsAction,
  setMapInstance as setMapInstanceAction,
  centerMap as centerMapAction,
  selectPolygon as selectPolygonAction,
  clearMapData as clearMapDataAction,
  clearPolygons as clearPolygonsAction
} from 'actions/map';

const useMap = () => {
  const dispatch = useDispatch();
  const setMapConfigs = useCallback(({ center, zoom, listOffset, widgetOffset, detailsOffset, mapWidth }) => dispatch(setMapConfigsAction({ center, zoom, listOffset, widgetOffset, detailsOffset, mapWidth })), [dispatch]);
  const setMapInstance = useCallback(instance => dispatch(setMapInstanceAction(instance)), [dispatch]);
  const centerMap = useCallback(({ minLatitude, minLongitude, maxLatitude, maxLongitude }) => dispatch(centerMapAction({ minLatitude, minLongitude, maxLatitude, maxLongitude })), [dispatch]);
  const selectPolygon = useCallback(id => dispatch(selectPolygonAction(id)), [dispatch]);
  const clearMapData = useCallback(() => dispatch(clearMapDataAction()), [dispatch]);
  const clearPolygons = useCallback(() => dispatch(clearPolygonsAction()), [dispatch]);

  return { setMapConfigs, setMapInstance, centerMap, selectPolygon, clearMapData, clearPolygons };
};

export default useMap;