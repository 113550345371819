import { memo, useState } from 'react';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useNavigate, useLocation } from 'react-router-dom';
import _startCase from 'lodash/startCase';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Root, ShareButton, Details, Actions, Text, Caption, Item/*, Badge*/ } from './InfoCard.styled';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
//import { ReactComponent as NotificationIcon } from 'assets/icons/notification.svg';
import ConfirmDraftDialog from 'components/Dialogs/ConfirmDraft/ConfirmDraft';
import Main from './Main/Main';
import Menu from './Menu/Menu';
import PreviewDialog from 'components/Dialogs/Preview/Preview';
import SharingAccessDialog from 'components/Dialogs/SharingAccess/SharingAccess';
import ConfirmDiscardChanges from 'components/Dialogs/ConfirmDiscardChanges/ConfirmDiscardChanges';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { offerStatuses } from 'helpers/constants';
import { getShortDate } from 'helpers';
import useOffers from 'hooks/useOffers';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';

const { ACTIVE, IN_CONTRACT, SAVED, ENDED, CLOSED, ARCHIVED, DRAFT } = offerStatuses;

const InfoCard = ({
  id, status, name, headline = null, tag, dealspace = null, deadline_type, deadline_at, expired_at, primary_sender, recipients_count = null,
  version, visited_recipients_count = null, public: p, sent, type = 'shared', locationType = 'dealspace', draft_offer = null, 
  unlocked_recipients_count, locations_locked, total_locked_documents_count, total_unlocked_documents_count, total_approval_required_documents_count
}) => {
  // console.log({id, status, name, headline, tag, dealspace, deadline_type, deadline_at, expired_at, primary_sender, recipients_count, version,
  //   visited_recipients_count, p, sent, type, locationType, draft_offer});
  const navigate = useNavigate();
  const previousLocation = useLocation();
  const { duplicateOffer, deleteOffer } = useOffers();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const isOwner = dealspace?.group?.current_member?.role === 'owner';
  const [previewDialog, setPreviewDialog] = useState({ open: false, id: null });
  const [sharingDialog, setSharingDialog] = useState({ open: false, name: '', headline: '', public: false, offerId: '', groupId: '', primarySender: null });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [processing, setProcessing] = useState(false);
  const expiresDays = !!expired_at ? Math.abs(Math.round((new Date(expired_at).getTime() - new Date().getTime()) / 86_400_000)) : null;
  const showDetails = status !== ARCHIVED && status !== SAVED && status !== DRAFT;
  const showCAsSigned = !!unlocked_recipients_count && (locations_locked || !!total_locked_documents_count || !!total_approval_required_documents_count);
  const showShare = type === 'shared' && status !== DRAFT && status !== ENDED && status !== CLOSED;
  const showEdit = type === 'draft' || (type === 'shared' && (status === ACTIVE || status === IN_CONTRACT));
  const showPreview = true;
  const showDuplicate = type === 'draft' || (type === 'shared' && (status === ACTIVE || status === IN_CONTRACT));
  const showDelete = type === 'draft' || status === 'draft';
  const mainProps = { name, isPublic: p, tag, status, hasDraft: !!draft_offer, primary_sender, version };
  const isOpenActivityLog = (type === 'shared' && locationType === 'dealspace') || ((locationType === 'dashboard' || locationType === 'group') && status !== 'draft');
  const isOpenBuilder = (type === 'draft' && locationType === 'dealspace') || ((locationType === 'dashboard' || locationType === 'group') && status === 'draft');
  const allowActivityLog = isOwner || sent;

  const handleClick = () => {
    if (isOpenActivityLog) {
      if (allowActivityLog) {
        navigate(`/offerings/${id}/activity_log`, { state: { previousLocation } });
      } else {
        setError('Activity log is only available for senders and dealspace owners');
      }
    }
    if (isOpenBuilder) {
      navigate(`/builder/${id}`, { state: { previousLocation } });
    }
  };
  const handleShareClick = e => {
    e.stopPropagation();
    setSharingDialog({ open: true, name, headline, public: p, offerId: id, groupId: dealspace.group.id, primarySender: primary_sender });
  };
  //const handleNotificationsClick = e => {
  //  e.stopPropagation();
  //};
  const handleEditClick = e => {
    e.stopPropagation();

    if (type === 'shared' && !draft_offer) {
      setConfirmDialogOpen(true);
    } else {
      navigate(`/builder/${id}`, { state: { previousLocation } });
    }
  };
  const handlePreviewClick = () => setPreviewDialog({ open: true, id });
  const handleDuplicateClick = () => {
    setProcessing(true);
    duplicateOffer({ id, type: locationType })
    .then(({ payload: { response: { data: { offer: { id } } } } }) => setSuccess('Offering successfully duplicated').then(() => navigate(`/builder/${id}`, { state: { previousLocation } })))
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };
  const handleDelete = () => {
    setProcessing(true);
    deleteOffer({ id, source: 'infoCard' })
    .then(() => setSuccess('Offering draft has been deleted'))
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };
  const handleConfirmationDialogOpen = () => setShowConfirmationDialog(true);
  const handleConfirmationDialogClose = result => {
    if (result) handleDelete();
    setShowConfirmationDialog(false);
  };
  const handleConfirmClose = confirmed => {
    setConfirmDialogOpen(false);

    if (confirmed) {
      navigate(`/builder/${id}`, { state: { previousLocation } });
    }
  };
  const handlePreviewClose = () => setPreviewDialog({ open: false, id: null });

  return (
    <>
      <PreviewDialog
        {...previewDialog}
        onClose={handlePreviewClose}
      />
      <SharingAccessDialog
        {...sharingDialog}
        onClose={() => setSharingDialog({ open: false, name: '', public: false, headline: '', offerId: '', groupId: '', primarySender: null })}
      />
      <ConfirmDraftDialog
        open={confirmDialogOpen}
        onClose={handleConfirmClose}
      />
      <ConfirmDiscardChanges
        open={showConfirmationDialog}
        onClose={handleConfirmationDialogClose}
      />
      <Root
        onClick={handleClick}
        disabled={processing}
        id='infoCardRoot'
        //sx={{ backgroundColor: backgroundActive ? 'background.paper' : '#F8F9FA'}}
      >
        <Main {...mainProps} />
        <Details>
          {showDetails && (!!deadline_type || !!deadline_at) &&
            <Item sx={{ minWidth: 62 }}>
              {!!deadline_type && <Caption>{_startCase(deadline_type)}</Caption>}
              {!!deadline_at && <Text>{getShortDate(deadline_at)}</Text>}
            </Item>
          }
          {showDetails && !!expired_at &&
            <Item>
              <Caption>{`${status === ENDED ? 'Ended after' : 'Expires in'} ${expiresDays} days`}</Caption>
              <Text>{getShortDate(expired_at)}</Text>
            </Item>
          }
          {showDetails && showCAsSigned &&
            <Item>
              <Caption>CAs Signed</Caption>
              <Text>{unlocked_recipients_count}</Text>
            </Item>
          }
          {showDetails &&
            <Item>
              <Caption>Unique Visitor(s)</Caption>
              <Text>{visited_recipients_count ?? 0}</Text>
            </Item>
          }
          {showDetails &&
            <Item>
              <Caption>Shared with</Caption>
              <Text sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '5px' }}>
                <PeopleAltIcon  sx={{ color: 'rgba(0,0,0,.54)', width: 11, height: 11 }} /><span>{recipients_count ?? 0}</span>
              </Text>
            </Item>
          }
        </Details>
        <Actions>
          {showShare &&
            <ShareButton id='shareButton' onClick={handleShareClick} disabled={processing}>
              <ShareIcon />
            </ShareButton>
          }
          {/*<StyledButton onClick={handleNotificationsClick}>
            <Badge badgeContent={0} color="success">
              <NotificationIcon />
            </Badge>
          </StyledButton>*/}
          <Menu
            {...{showEdit, showPreview, showDuplicate, showDelete}}
            onEdit={handleEditClick}
            onPreview={handlePreviewClick}
            onDuplicate={handleDuplicateClick}
            onDelete={handleConfirmationDialogOpen}
            disabled={processing}
          />
        </Actions>
      </Root>
    </>
  );
};

InfoCard.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  headline: PropTypes.string,
  tag: PropTypes.string,
  unlocked_recipients_count: PropTypes.number,
  locations_locked: PropTypes.bool,
  total_locked_documents_count: PropTypes.number,
  total_unlocked_documents_count: PropTypes.number,
  total_approval_required_documents_count: PropTypes.number,
  dealspace: PropTypes.shape({
    group: PropTypes.shape({
      current_member: PropTypes.shape({
        role: PropTypes.string
      })
    })
  }),
  deadline_type: PropTypes.string,
  deadline_at: PropTypes.string,
  expired_at: PropTypes.string,
  primary_sender: PropTypes.object,
  recipients_count: PropTypes.number,
  version: PropTypes.number.isRequired,
  visited_recipients_count: PropTypes.number,
  public: PropTypes.bool.isRequired,
  sent: PropTypes.bool.isRequired,
  type: PropTypes.string,
  locationType: PropTypes.string,
  draft_offer: PropTypes.object
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <InfoCard {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);