import { memo, useCallback, useEffect, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import { StyledTab } from './Details.styled';
import PropertiesTabContent from 'components/Properties/DealspaceTabContent/DealspaceTabContent';
import NotesTabContent from 'components/Dealspaces/NotesTabContent/NotesTabContent';
import FilesTabContent from 'components/Documents/DealspaceTabContent/DealspaceTabContent';
import OfferingsTabContent from 'components/Offers/DealspaceTabContent/DealspaceTabContent';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useProperties from 'hooks/useProperties';
import useCustomSelector from 'hooks/useCustomSelector';
import useDocuments from 'hooks/useDocuments';
import useError from 'hooks/useError';
import useFolders from 'hooks/useFolders';
import useOffers from 'hooks/useOffers';
import useMembers from 'hooks/useMembers';

const Details = ({ type }) => {
  const { dealspace_id, folder_id } = useParams();
  const { fetching } = useOutletContext();
  const { getDealspaceProperties, clearLocalDealspaceProperties } = useProperties();
  const { getDealspaceDocuments, clearLocalDealspaceDocuments } = useDocuments();
  const { setError } = useError();
  const { getFolder, getFolders, clearLocalFolders, clearLocalFolder } = useFolders();
  const { getDealspaceOffers, clearLocalDealspaceOffers } = useOffers();
  const { getDealspaceMembersList, clearLocalDealspaceMembersList } = useMembers();
  const { rootFolderId, propertiesAmount, properties } = useCustomSelector(state => ({
    rootFolderId: state.dealspaces.dealspace.root_folder.id,
    propertiesAmount: state.dealspaces.dealspace.properties_count,
    properties: state.properties.dealspace.data
  }));
  const [tab, setTab] = useState(() => type ?? 'properties');

  const handleTabsChange = (_, newValue) => setTab(newValue);
  const fetchProperties = useCallback(({ dealspace_id, offset }) => {
    getDealspaceProperties({ dealspace_id, offset })
    .catch(e => setError(e));
  }, [getDealspaceProperties, setError]);
  const fetchFolders = useCallback(({ folder_id, offset }) => {
    getFolders({ folder_id, offset }).catch(e => setError(e));
  }, [getFolders, setError]);
  const fetchDocuments = useCallback(({ folder_id, offset }) => {
    getDealspaceDocuments({ folder_id, offset }).catch(e => setError(e));
  }, [getDealspaceDocuments, setError]);
  const fetchFolder = useCallback(id => {
    getFolder(id)
    .then(({ payload: { data: { folder: { id: folder_id } } } }) => {
      fetchFolders({ folder_id, offset: 0 });
      fetchDocuments({ folder_id, offset: 0 });
    })
    .catch(e => setError(e));
  }, [fetchDocuments, fetchFolders, getFolder, setError]);
  const fetchOffers = useCallback(({ dealspace_id, offset, type }) => {
    getDealspaceOffers({ dealspace_id, offset, type })
    .catch(e => setError(e));
  }, [getDealspaceOffers, setError]);
  const fetchMembers = useCallback(({ dealspace_id, offset }) => {
    getDealspaceMembersList({ dealspace_id, offset })
    .catch(e => setError(e));
  }, [getDealspaceMembersList, setError]);
  const handleLoadMoreFiles = ({ type, offset }) => {
    if (type === 'files') {
      fetchDocuments({ folder_id, offset });
    } else {
      fetchFolders({ folder_id, offset });
    }
  };

  useEffect(() => {
    clearLocalDealspaceProperties()
    .then(() => fetchProperties({ dealspace_id, offset: 0 }));
    fetchMembers({ dealspace_id, offset: 0 });

    return () => {
      clearLocalFolder();
      clearLocalDealspaceProperties();
      clearLocalDealspaceMembersList();
    };
  }, [dealspace_id, clearLocalFolder, clearLocalDealspaceProperties, clearLocalDealspaceMembersList, fetchProperties, fetchMembers]);
  useEffect(() => {
    if (folder_id) fetchFolder(folder_id);

    return () => {
      clearLocalFolders();
      clearLocalDealspaceDocuments();
    };
  }, [clearLocalDealspaceDocuments, clearLocalFolders, fetchFolder, folder_id]);
  useEffect(() => {
    fetchOffers({ dealspace_id, offset: 0, type: 'shared' });
    fetchOffers({ dealspace_id, offset: 0, type: 'saved' });
    fetchOffers({ dealspace_id, offset: 0, type: 'draft' });

    return clearLocalDealspaceOffers;
  }, [dealspace_id, fetchOffers, clearLocalDealspaceOffers]);

  return (
    <Box flex={1} display='flex' flexDirection='column'>
      {fetching ? <Skeleton variant='rectangular' width='100%' /> :
        <Tabs
          centered
          scrollButtons
          allowScrollButtonsMobile
          sx={{ minHeight: '40px' }}
          value={tab}
          onChange={handleTabsChange}
        >
          <StyledTab
            label={`Properties ${propertiesAmount > -1 ? '(' + propertiesAmount + ')' : ''}`}
            value='properties'
            LinkComponent={Link}
            to={`/dealspaces/${dealspace_id}/properties${!!properties?.length ? '/' : ''}${properties[0]?.id || ''}`}
          />
          <StyledTab
            label='Notes'
            value='notes'
            LinkComponent={Link}
            to={`/dealspaces/${dealspace_id}/notes`}
          />
          <StyledTab
            label='Files'
            value='files'
            LinkComponent={Link}
            to={`/dealspaces/${dealspace_id}/files/${rootFolderId}`}
          />
          <StyledTab
            label='Offerings'
            value='offerings'
            LinkComponent={Link}
            to={`/dealspaces/${dealspace_id}/offerings`}
          />
        </Tabs>
      }
      {!fetching && 
        <Box flex={1} display='flex' justifyContent='center'>
          {tab === 'properties' && <PropertiesTabContent />}
          {tab === 'notes' && <NotesTabContent />}
          {tab === 'files' && folder_id && <FilesTabContent onLoadMore={handleLoadMoreFiles} />}
          {tab === 'offerings' && <OfferingsTabContent />}
        </Box>
      }
    </Box>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Details {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);