import { createResourceRoutine } from 'actions';

export const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case createResourceRoutine.SUCCESS: {
      return state;
    }
    default: {
      return state;
    }
  };
};

export default reducer;