import { memo } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddIcon from 'components/Common/Icons/AddIcon';
//Local files
import { Root, Mark, Illustration, Content, ContentContainer } from './EmptyContent.styled';

const EmptyContent = ({ onCreate }) => {
  return (
    <Root>
      <Typography variant='subtitle1' align='center'>Welcome to your new Group!</Typography>
      <Typography variant='body2' align='center' sx={{ lineHeight: 2, maxWidth: 660, mx: 'auto', mt: 2 }}>
        <Mark>Groups</Mark> are private and proprietary areas where you can work on multiple deals and <Mark style={{ fontWeight: 'inherit' }}>invite team members</Mark> to collaborate.  Group content is owned by the Group Owner.
      </Typography>
      <ContentContainer>
        <Illustration />
        <Content>
          <Typography variant='body2' align='center' sx={{ lineHeight: 2, maxWidth: 370 }}>
            Deals are managed in  <Mark>Dealspaces</Mark>, where you can work on files, chat with your team and create Offerings.
          </Typography>
          <Button variant='contained' color='secondary' startIcon={<AddIcon />} onClick={onCreate}>Create Dealspace</Button>
        </Content>
      </ContentContainer>
    </Root>
  );
};

export default memo(EmptyContent);