import { memo, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _startCase from 'lodash/startCase';
import Scrollbars from 'react-custom-scrollbars-2';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Root, Content } from './Contact.styled';
import BuilderErrorLabel from 'components/Common/DataDisplay/BuilderErrorLabel/BuilderErrorLabel';
import BuilderSuccessLabel from 'components/Common/DataDisplay/BuilderSuccessLabel/BuilderSuccessLabel';
import GoogleAutocomplete from 'components/Common/Location/GoogleAutocomplete/GoogleAutocomplete';
import StatesAutocomplete from 'components/Common/DataDisplay/StatesAutocomplete/StatesAutocomplete';
import InfoWithActions from 'components/Users/InfoWithActions/InfoWithActions';
import PhoneField from 'components/Common/Inputs/PhoneField/PhoneField';
import ListsAutocomplete from '../../Lists/Autocomplete/Autocomplete';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { checkEmptyString, checkValidEmail, getShortDateWithOutYear } from 'helpers';
import { DELETE_CONTACT } from 'helpers/confirmations';
import useApp from 'hooks/useApp';
import useContacts from 'hooks/useContacts';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useCustomSelector from 'hooks/useCustomSelector';
import useUsers from 'hooks/useUsers';
import useOffers from 'hooks/useOffers';

const ContactDrawer = ({ data = null, defaultData = null, open, quick = false, onClose, onChange = null }) => {
  const navigatePath = useLocation();
  const { openDeleteDialog, openSendEmailNotificationDialog } = useApp();
  const { getContact, createContact, updateContact, validateContactEmail } = useContacts();
  const { getUsersList } = useUsers();
  const { getContactActiveOffers } = useOffers();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { tab } = useCustomSelector(state => state.contacts.all.filters);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState({ value: '', error: '' });
  const [verificationStatus, setVerificationStatus] = useState('pending');
  const [mobilePhone, setMobilePhone] = useState({ value: '', formattedValue: '', error: '' });
  const [officePhone, setOfficePhone] = useState({ value: '', formattedValue: '', error: '' });
  const [officePhoneExtension, setOfficePhoneExtension] = useState('');
  const [lists, setLists] = useState([]);
  const [title, setTitle] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [location, setLocation] = useState({ value: '', formattedValue: '' });
  const [streetAddress, setStreetAddress] = useState('');
  const [secondAddress, setSecondAddress] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [city, setCity] = useState('');
  const [linkedinProfileUrl, setLinkedinProfileUrl] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [notes, setNotes] = useState('');
  const [hasInvitation, setHasInvitation] = useState(false);
  const [processing, setProcessing] = useState(false);
  const edit = data;
  const status = !!data ? (data.sharing_subscribed ? 'Subscribed' : 'Unsubscribed') : null;
  const innerContact = !!data?.user;
  const disabled = !edit ? firstName.length < 2 || lastName.length < 2 || !checkEmptyString(email.value) ||  !!mobilePhone.error || !!officePhone.error : !!mobilePhone.error || !!officePhone.error;

  const needToUpdateField = (a, b) => !_isEqual(a, b) && (!!a || !!b);
  const handleSubmit = () => {
    const listAttributes = _filter(lists, l => !!l.inputValue);
    const listIds = _filter(lists, l => !!l.id && !l.inputValue);

    const contact = {
      ...(!innerContact && (needToUpdateField(data?.first_name, firstName) || defaultData?.firstName) && { first_name: firstName }),
      ...(!innerContact && needToUpdateField(data?.last_name, lastName) && { last_name: lastName }),
      ...(!innerContact && (needToUpdateField(data?.email !== email.value) || defaultData?.email) && { email: email.value }),
      ...(!quick && !data?.user?.mobile_phone && needToUpdateField(data?.mobile_phone, mobilePhone.value) && !mobilePhone.error && { mobile_phone: mobilePhone.value }),
      ...(!quick && !data?.user?.office_phone && needToUpdateField(data?.office_phone, officePhone.value) && !officePhone.error && { office_phone: officePhone.value }),
      ...(!quick && !data?.user?.office_phone_extension && needToUpdateField(data?.office_phone_extension, officePhoneExtension) && { office_phone_extension: officePhoneExtension }),
      ...(!quick && needToUpdateField(data?.notes, notes) && { notes }),
      ...(!quick && !data?.user?.title && needToUpdateField(data?.title, title) && { title }),
      ...(!quick && !data?.user?.company_name && needToUpdateField(data?.company_name, companyName) && { company_name: companyName }),
      ...(!quick && !data?.user?.location && needToUpdateField(data?.location, location.value) && { location: location.value }),
      ...(!quick && needToUpdateField(data?.street_address, streetAddress) && { street_address: streetAddress }),
      ...(!quick && needToUpdateField(data?.second_address, secondAddress) && { second_address: secondAddress }),
      ...(!quick && needToUpdateField(data?.state, state) && { state }),
      ...(!quick && needToUpdateField(data?.zip, zip) && { zip }),
      ...(!quick && needToUpdateField(data?.city, city) && { city }),
      ...(!quick && !data?.user?.linkedin_profile_url && needToUpdateField(data?.linkedin_profile_url, linkedinProfileUrl) && { linkedin_profile_url: linkedinProfileUrl }),
      ...(!quick && !data?.user?.website_url && needToUpdateField(data?.website_url, websiteUrl) && { website_url: websiteUrl }),
      ...(!!listAttributes.length && { lists_attributes: _map(listAttributes, la => ({ name: la.inputValue })) }),
      ...(!_isEqual(_map(_filter(data?.lists, l => !l.default), l => l.id).sort(), _map(listIds, l => l.id).sort()) && { list_ids: _map(listIds, l => l.id) })
    };

    if (data) {
      if (!_isEmpty(contact)) {
        setProcessing(true);
        updateContact({ id: data.id, ...contact})
        .then(() =>
          setSuccess('Contact updated')
          .then(() => getContact(data.id).catch(e => setError(e)))
          .then(() => {
            if (!!data?.quarantine_type) {
              getContactActiveOffers({ offset: 0, recipient_email: email.value })
              .then(({ payload: { data: { offers } }}) => { if (!!offers.length) openSendEmailNotificationDialog(email.value) })
              .catch((error) => setError(error))
            }
          })
          .then(handleClose)
        )
        .catch(e => setError(e))
        .finally(() => setProcessing(false));
      } else {
        handleClose();
      }
    } else {
      setProcessing(true);
      getUsersList({ query: email.value })
      .then(({ payload: { data: { users } } }) => {
        createContact({ 
          contact: { ...contact, ...!!email.value && !edit && hasInvitation && { invitation_attributes: {...!!users.length ? { user_id: users[0]?.id } : { email: email.value } } } }, 
          isAllContacts: tab === 'all' 
        })
        .then(({ payload: { response: { data: { contact: c } } } }) => {
          if (onChange) onChange(c);
          if (!edit && hasInvitation) setSuccess('Invitation sent successfully');
          setSuccess('Contact successfully created')
          .then(handleClose);
        })
        .catch(e => setError(e))
        .finally(() => setProcessing(false));
      })
      .catch(e => setError(e).then(() => setProcessing(false)))
    }
  };
  const handleDeleteClick = () => {
    handleClose();
    openDeleteDialog({ type: DELETE_CONTACT, id: data?.id, navigatePath });
  };
  const handleClose = () => {
    setFirstName('');
    setLastName('');
    setEmail({ value: '', error: '' });
    setVerificationStatus('');
    setMobilePhone({ value: '', formattedValue: '', error: '' });
    setOfficePhone({ value: '', formattedValue: '', error: '' });
    setOfficePhoneExtension('');
    setLists([]);
    setTitle('');
    setCompanyName('');
    setLocation({ value: '', formattedValue: '' });
    setStreetAddress('');
    setSecondAddress('');
    setState('');
    setZip('');
    setCity('');
    setLinkedinProfileUrl('');
    setWebsiteUrl('');
    setNotes('');
    setHasInvitation(false);
    onClose();
  };
  const handleEmailBlur = e => {
    if (!checkEmptyString(e) || !checkValidEmail(e)) {
      setVerificationStatus('error');
      return;
    }
    setVerificationStatus('loading')
    validateContactEmail(e)
    .then(() => setVerificationStatus('verified'))
    .catch(e => {
      if (e.response?.data?.error?.type === 'contact_already_exists') {
        setVerificationStatus('exist');
        return;
      }
      setError(e).then(() => setVerificationStatus('error'));
    });
  };
  const handleAddressChange = formattedValue => setLocation(prev => ({ ...prev, formattedValue, value: '' }));
  const handleAddressSelect = ({ value, formattedValue }) => setLocation({ value, formattedValue });
  const toggleInvitation = ({ target: { checked } }) => setHasInvitation(checked);
  const onEntering = () => {
    setFirstName(data?.user?.first_name ?? data?.first_name ?? defaultData?.firstName ?? '');
    setLastName(data?.user?.last_name ?? data?.last_name ?? '');
    setEmail({ value: data?.user?.email ?? data?.email ?? defaultData?.email ?? '', error: !!data?.quarantine_type ? `Invalid email address - ${_startCase(data.quarantine_type)} on ${getShortDateWithOutYear(data.quarantined_at)}` : '' });
    setMobilePhone({ value: data?.user?.mobile_phone ?? data?.mobile_phone ?? '', formattedValue: data?.user?.mobile_phone ?? data?.mobile_phone ?? '', error: '' });
    setOfficePhone({ value: data?.user?.office_phone ?? data?.office_phone ?? '', formattedValue: data?.user?.office_phone ?? data?.office_phone ?? '', error: '' });
    setOfficePhoneExtension(data?.user?.office_phone_extension ?? data?.office_phone_extension ?? '');
    setLists(_filter(data?.lists, l => !l.default));
    setNotes(data?.notes ?? '');
    setTitle(data?.user?.title ?? data?.title ?? '');
    setCompanyName(data?.user?.company_name ?? data?.company_name ?? '');
    setLocation({ value: data?.user?.location ?? data?.location ?? '', formattedValue: data?.user?.location ?? data?.location ?? '' });
    setStreetAddress(data?.street_address ?? '');
    setSecondAddress(data?.second_address ?? '');
    setState(data?.state ?? '');
    setZip(data?.zip ?? '');
    setCity(data?.city ?? '');
    setLinkedinProfileUrl(data?.user?.linkedin_profile_url ?? data?.linkedin_profile_url ?? '');
    setWebsiteUrl(data?.user?.website_url ?? data?.website_url ?? '');

    if (defaultData?.email) {
      handleEmailBlur(defaultData.email);
    }
  };
  const onExited = () => handleClose();

  return (
    <Drawer
      anchor={'right'}
      open={open}
      onClose={() => !processing && handleClose()}
      PaperProps={{ sx: { width: { xs: '100%',  md: '420px' } } }}
      SlideProps={{ onEntering, onExited }}
      ModalProps={{ sx: { zIndex: 1300 } }}
    >
      <Root>
        <Typography textAlign='center' sx={{ p: 4, fontWeight: 600, fontSize: '16px', lineHeight: '23px' }}>
          {data ? 'Edit' : 'Create'} Contact
        </Typography>
        {!!data?.user &&
          <InfoWithActions
            closeContactDrawer={handleClose}
            {..._pick(data.user, ['id', 'username', 'photo', 'private_conversation', 'first_name', 'last_name', 'mobile_phone'])}
          />
        }
        <Scrollbars autoHide autoHideTimeout={1000}>
          <Content>
            <TextField
              disabled={innerContact}
              label='First Name'
              value={firstName}
              onChange={({ target: { value } }) => setFirstName(value)}
              fullWidth
              margin='none'
            />
            <TextField
              disabled={innerContact}
              label='Last Name'
              value={lastName}
              onChange={({ target: { value } }) => setLastName(value)}
              fullWidth
              margin='none'
            />
            <Box>
              <TextField
                fullWidth
                disabled={innerContact}
                label='Email'
                value={email.value}
                onChange={({ target: { value } }) => setEmail({ value, error: '' })}
                onBlur={({ target: { value } }) => {
                  if (needToUpdateField(data?.email !== email.value)) {
                    handleEmailBlur(value)
                  }
                }}
                error={verificationStatus === 'error' || !!email.error}
                sx={{ mb: '5.5px' }}
                margin='none'
                InputProps={{ autoComplete: 'email' }}
              />
              {verificationStatus === 'error' && <BuilderErrorLabel value='Invalid email' />}
              {verificationStatus === 'verified' && <BuilderSuccessLabel value='Email address verified' />}
              {verificationStatus === 'exist' && <BuilderErrorLabel value='Email address exist' />}
              {verificationStatus === 'loading' && 
                <Typography fontWeight={400} fontSize='10px' lineHeight='20px' color='rgba(69, 70, 75, 1)'>
                  Loading...
                </Typography> 
              }
              {!!email.error && <BuilderErrorLabel value={email.error} />}
            </Box>
            {!quick &&
              <PhoneField
                disabled={!!data?.user?.mobile_phone}
                id='mobilePhone'
                fullWidth
                label='Mobile Phone'
                formattedValue={mobilePhone.formattedValue}
                error={false}
                helperText={''}
                onChange={({ value, formattedValue, error }) => setMobilePhone({ value, formattedValue, error })}
              />
            }
            {!quick &&
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                <PhoneField
                  disabled={!!data?.user?.office_phone}
                  id='officePhone'
                  fullWidth
                  label='Office Phone'
                  formattedValue={officePhone.formattedValue}
                  error={false}
                  helperText={''}
                  onChange={({ value, formattedValue, error }) => setOfficePhone({ value, formattedValue, error })}
                />
                <TextField
                  disabled={!!data?.user?.office_phone_extension}
                  label='Ext'
                  margin='none'
                  value={officePhoneExtension}
                  onChange={({ target: { value } }) => setOfficePhoneExtension(value)}
                />
              </Box>
            }
            <ListsAutocomplete
              value={lists}
              onChange={setLists}
            />
            {!quick &&
              <>
                <TextField
                  disabled={!!data?.user?.title}
                  inputProps={{ maxLength: 50 }}
                  label='Title'
                  margin='none'
                  fullWidth
                  value={title}
                  onChange={({ target: { value } }) => setTitle(value)}
                />
                <TextField
                  disabled={!!data?.user?.company_name}
                  label='Company Name'
                  margin='none'
                  fullWidth
                  value={companyName}
                  onChange={({ target: { value } }) => setCompanyName(value)}
                />
                <GoogleAutocomplete
                  disabled={!!data?.user?.location}
                  label='Location'
                  types={['(regions)']}
                  value={location.formattedValue}
                  onChange={handleAddressChange}
                  onSelect={handleAddressSelect}
                  sx={{
                    width: '100%',
                    background: 'rgba(255, 255, 255, 0.6)',
                    backdropFilter: 'blur(20px)',
                    WebkitBackdropFilter: 'blur(20px)',
                    borderRadius: '8px',
                    mt: 1,
                    mb: 1
                  }}
                />
                <TextField
                  label='Street Address'
                  margin='none'
                  fullWidth
                  value={streetAddress}
                  onChange={({ target: { value } }) => setStreetAddress(value)}
                />
                <TextField
                  label='Second Address'
                  margin='none'
                  fullWidth
                  value={secondAddress}
                  onChange={({ target: { value } }) => setSecondAddress(value)}
                />
                <StatesAutocomplete
                  value={state}
                  onChange={v => setState(v)}
                />
                <TextField
                  label='Zip Code'
                  margin='none'
                  fullWidth
                  value={zip}
                  onChange={({ target: { value } }) => setZip(value)}
                />
                <TextField
                  label='City'
                  margin='none'
                  fullWidth
                  value={city}
                  onChange={({ target: { value } }) => setCity(value)}
                />
                <TextField
                  disabled={!!data?.user?.linkedin_profile_url}
                  label='LinkedIn Profile URL'
                  margin='none'
                  fullWidth
                  value={linkedinProfileUrl}
                  onChange={({ target: { value } }) => setLinkedinProfileUrl(value)}
                />
                <TextField
                  disabled={!!data?.user?.website_url}
                  label='Website URL'
                  margin='none'
                  fullWidth
                  value={websiteUrl}
                  onChange={({ target: { value } }) => setWebsiteUrl(value)}
                />
                <TextField
                  label='Notes'
                  margin='none'
                  fullWidth
                  multiline
                  minRows={5}
                  value={notes}
                  onChange={({ target: { value } }) => setNotes(value)}
                />
              </>
            }
          </Content>
        </Scrollbars>
        <Box display='flex' justifyContent='center' flexDirection='column'>
          {edit ?
            <Box sx={{ display: status ? 'block' : 'none' }} padding='16px 32px'>
              <Typography fontWeight={400} fontSize='14px' lineHeight='24px' color='rgba(69, 70, 75, 1)'>
                Deal sharing consent status:  {status}
              </Typography> 
            </Box> :
            <FormControlLabel
              label='Invite contact to Qwincy'
              sx={{ justifyContent: 'center' }}
              control={
                <Checkbox
                  color='secondary'
                  checked={hasInvitation}
                  onChange={toggleInvitation}
                />
              }
            />
          }
          <Box display='flex' justifyContent='center' gap='4px' mb={2}>
            <Button
              disabled={processing}
              variant='text'
              color='error'
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              disabled={processing || disabled || !!email.error || verificationStatus === 'error' || (needToUpdateField(data?.email !== email.value) && verificationStatus !== 'verified')}
              variant='contained'
              color='primary'
              onClick={handleSubmit}
            >
              Save
            </Button>
            {data &&
              <Button
                disabled={processing}
                variant='text'
                onClick={handleDeleteClick}
              >
                Delete
              </Button>
            }
          </Box>
        </Box>
      </Root>
    </Drawer>
  );
};

ContactDrawer.propTypes = {
  defaultData: PropTypes.shape({
    firstName: PropTypes.string,
    email: PropTypes.string
  }),
  data: PropTypes.object,
  open: PropTypes.bool.isRequired,
  quick: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ContactDrawer {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);