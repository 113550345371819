import {
  getDealspacePropertiesListRoutine,
  getDealspacePropertiesRoutine,
  getOfferPropertiesRoutine,
  getPropertyRoutine,
  createDealspacePropertyRoutine,
  createOfferPropertyRoutine,
  updatePropertyRoutine,
  deletePropertyRoutine,
  clearLocalDealspacePropertiesListRoutine,
  clearLocalDealspacePropertiesRoutine,
  clearLocalOfferPropertiesRoutine,
  clearLocalActivePropertyRoutine
} from 'actions';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _dropRight from 'lodash/dropRight';
import _map from 'lodash/map';

export const initialState = {
  list: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 } },
  dealspace: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 } },
  offer: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 } },
  property: { id: '', name: '', description: '', main_location: null, locations_count: 0 }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getDealspacePropertiesListRoutine.SUCCESS: {
      const { data: { properties, pagination } } = action.payload;

      return { ...state, list: { data: [...state.list.data, ...properties], pagination } };
    }
    case getDealspacePropertiesRoutine.SUCCESS: {
      const { data: { properties, pagination } } = action.payload;

      return { ...state, dealspace: { data: [...state.dealspace.data, ...properties], pagination } };
    }
    case getOfferPropertiesRoutine.SUCCESS: {
      const { data: { properties, pagination } } = action.payload;

      return { ...state, offer: { data: [...state.offer.data, ...properties], pagination } };
    }
    case getPropertyRoutine.SUCCESS: {
      const { data: { property } } = action.payload;
      const updatedProperty = { ...state.property, ...property };
      const dealspace = { ...state.dealspace, data: _map(state.dealspace.data, p => p.id === property.id ? property : p) };

      return { ...state, dealspace, property: updatedProperty };
    }
    case createDealspacePropertyRoutine.SUCCESS: {
      const { data: { property } } = action.payload;
      const dealspaceOldData = state.dealspace.pagination.total_count > 24 ? _dropRight(state.dealspace.data) : state.dealspace.data;
      const dealspaceData = [property, ...dealspaceOldData];
      const dealspacePagination = { ...state.dealspace.pagination, count: state.dealspace.pagination.count + 1, total_count: state.dealspace.pagination.total_count + 1 };
      const dealspace = { data: dealspaceData, pagination: dealspacePagination };
      const listData = [property, ...state.list.data];
      const listPagination = { ...state.list.pagination, count: state.list.pagination.count + 1, total_count: state.list.pagination.total_count + 1 };
      const list = { data: listData, pagination: listPagination };

      return { ...state, dealspace, list };
    }
    case createOfferPropertyRoutine.SUCCESS: {
      const { data: { property } } = action.payload;
      const offerOldData = state.offer.pagination.total_count > 24 ? _dropRight(state.offer.data) : state.offer.data;
      const offerData = [property, ...offerOldData];
      const offerPagination = { ...state.offer.pagination, count: state.offer.pagination.count + 1, total_count: state.offer.pagination.total_count + 1 };
      const offer = { data: offerData, pagination: offerPagination };

      return { ...state, offer };
    }
    case updatePropertyRoutine.SUCCESS: {
      const { response: { status }, property } = action.payload;
      const foundDealspaceProperty = !!_find(state.dealspace.data, d => d.id === property.id);
      const foundOfferProperty = !!_find(state.offer.data, d => d.id === property.id);
      const updatedProperty = { ...state.property, ...property };
      
      if (foundDealspaceProperty) {
        const data = _map(state.dealspace.data, item => item.id === property.id ? { ...item, ...property } : item);

        return { ...state, ...(status === 204 && { property: updatedProperty }), dealspace: { ...state.dealspace, data } };
      }
      if (foundOfferProperty) {
        const data = _map(state.offer.data, item => item.id === property.id ? { ...item, ...property } : item);

        return { ...state, ...(status === 204 && { property: updatedProperty }), offer: { ...state.offer, data } };
      }

      return state;
    }
    case deletePropertyRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const foundDealspaceProperty = !!_find(state.dealspace.data, d => d.id === id);
      const foundOfferProperty = !!_find(state.offer.data, d => d.id === id);
      let data = [];
      let pagination = {};
      
      if (foundDealspaceProperty) {
        data = _filter(state.dealspace.data, item => item.id !== id);
        pagination = { ...state.dealspace.pagination, count: state.dealspace.pagination.count - 1, total_count: state.dealspace.pagination.total_count - 1 };
      }
      if (foundOfferProperty) {
        data = _filter(state.offer.data, item => item.id !== id);
        pagination = { ...state.offer.pagination, count: state.offer.pagination.count - 1, total_count: state.offer.pagination.total_count - 1 };
      }

      return {
        ...state,
        ...(status === 204 && foundDealspaceProperty && { dealspace: { data, pagination } }),
        ...(status === 204 && foundOfferProperty && { offer: { data, pagination } })
      };
    }
    case clearLocalDealspacePropertiesListRoutine.SUCCESS: {
      return { ...state, list: initialState.list }
    }
    case clearLocalDealspacePropertiesRoutine.SUCCESS: {
      return { ...state, dealspace: initialState.dealspace }
    }
    case clearLocalOfferPropertiesRoutine.SUCCESS: {
      return { ...state, offer: initialState.offer }
    }
    case clearLocalActivePropertyRoutine.SUCCESS: {
      return { ...state, property: initialState.property }
    }
    default: {
      return state;
    }
  };
};

export default reducer;