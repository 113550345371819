import { combineReducers } from 'redux';
import { clearStoreRoutine } from 'actions';
import { sessionReducer as session } from 'redux-react-session';

// Activities
import activities from './activities/activities';
// Agreements
import agreements from './agreements/agreements';
// Archives
import archives from './archives/archives';
// App
import app from './app/app';
//Audits
import audits from './audits/audits';
// Batch
import batch from './batch/batch';
// Blobs
import blobs from './blobs/blobs';
// Cofirmations
import confirmations from './confirmations/confirmations';
// Contacts
import contacts from './contacts/contacts';
// Conversations
import conversations from './conversations/conversations';
// Dealspaces
import dealspaces from './dealspaces/dealspaces';
// Documents
import documents from './documents/documents';
// Emails
import emails from './emails/emails';
// Folders
import folders from './folders/folders';
// Groups
import groups from './groups/groups';
// Images
import images from './images/images';
// Invitations
import invitations from './invitations/invitations';
// Lists
import lists from './lists/lists';
// Lookup
import lookup from './lookup/lookup';
// Map
import map from './map/map';
// Members
import members from './members/members';
// Messages
import messages from './messages/messages';
// Notifications
import notifications from './notifications/notifications';
// Nylas
import nylasUsers from './nylasUsers/nylasUsers';
// Offerings
import offers from './offers/offers';
// Profile
import profile from './profile/profile';
// Properties
import properties from './properties/properties';
// Recipients
import recipients from './recipients/recipients';
// Resources
import resources from './resources/resources';
// Senders
import senders from './senders/senders';
// Sessions
import sessions from './sessions/sessions';
// Shares
import shares from './shares/shares';
// Templates
import templates from './templates/templates';
// Users
import users from './users/users';
// Views
import views from './views/views';

const appReducer = combineReducers({
  activities,
  agreements,
  archives,
  app,
  audits,
  batch,
  blobs,
  confirmations,
  contacts,
  conversations,
  dealspaces,
  documents,
  emails,
  folders,
  groups,
  images,
  invitations,
  lists,
  lookup,
  map,
  members,
  messages,
  notifications,
  nylasUsers,
  offers,
  profile,
  properties,
  recipients,
  resources,
  senders,
  session,
  sessions,
  shares,
  templates,
  users,
  views
});

const rootReducer = (state, action) => {
  if (clearStoreRoutine.isSuccessAction(action)) {
    // const session = { authenticated: false, checked: false, invalid: false, user: {} };

    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
