import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';

const Root = styled(Box)({
  minHeight: 190,
  width: '100%',
  position: 'relative',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center'
});
const StyledButton = styled(ButtonBase)(({ theme }) => ({
  height: 32,
  width: 32,
  color: theme.palette.primary.main,
  backgroundColor: 'rgba(255,255,255,.85)',
  borderRadius: '4px',
  position: 'absolute',
  bottom: 8,
  right: 8
}));

export { Root, StyledButton };