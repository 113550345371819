import { memo, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Local files
import Title from 'components/Folders/Title/Title';
import NewButton from 'components/Folders/NewButton/NewButton';
import Navigation from 'components/Folders/Navigation/Navigation';
import Details from 'components/Folders/Details/Details';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useCustomSelector from 'hooks/useCustomSelector';

const DealspaceTabContent = ({ onLoadMore }) => {
  const titleVariant = 'breadcrumbs';
  const { dealspace_id } = useParams();
  const { rootFolderId, breadcrumbs } = useCustomSelector(state => ({
    rootFolderId: state.dealspaces.dealspace.root_folder.id,
    breadcrumbs: state.folders.breadcrumbs
  }));
  const [folders] = useState({ id: 'root', label: 'All Files', path: `/dealspaces/${dealspace_id}/files/${rootFolderId}`, children: [] });
  const [selectedItems, setSelectedItems] = useState([]);
  const [query, setQuery] = useState('test');
  const crumbs = _map(breadcrumbs, b => ({ ...b, path: b.path.replace(':dealspace_id', dealspace_id) }));
  const titleValue = useMemo(() => {
    if (titleVariant === 'search') return query;
    if (titleVariant === 'breadcrumbs') return crumbs;
    if (titleVariant === 'selected') return selectedItems;
    return null;
  }, [crumbs, query, selectedItems, titleVariant]);

  const handleTitleClick = v => {
    if (titleVariant === 'search') {
      setQuery(''); // TODO
    }
    if (titleVariant === 'selected') {
      setSelectedItems([]); // TODO
    }
  };

  return (
    <Box
      marginTop={{ xs: 0, md: '100px' }}
      display='flex'
      flexDirection='column'
      flex={1}
      maxWidth={{ sm: 400, md: 800, lg: 1100, xl: 1400 }}
    >
      <Box
        borderBottom='0.5px solid rgba(217, 217, 217, 1)'
        marginBottom='14px'
        ml={{ xs: 0, md: 4 }}
        mr={{ xs: 0, md: 4 }}
        paddingBottom='10px'
        display='flex'
        justifyContent='space-between'
      >
        <Title
          variant={titleVariant}
          value={titleValue}
          onClick={handleTitleClick}
        />
        <NewButton />
      </Box>
      <Box
        display='flex'
        flex={1}
        pl={{ xs: 0, md: 4 }}
        pr={{ xs: 0, md: 4 }}
      >
        <Box
          overflow='auto'
          width={{ xs: '45%', md: '20%' }}
          p={{ xs: 0, md: 1 }}
        >
          <Navigation folders={folders} />
        </Box>
        <Box
          overflow='auto'
          width={{ xs: '55%', md: '80%' }}
          p={{ xs: 0, md: 1 }}
        >
          <Details onLoadMore={onLoadMore} />
        </Box>
      </Box>
    </Box>
  );
};

DealspaceTabContent.propTypes = {
  onLoadMore: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <DealspaceTabContent {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);