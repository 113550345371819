import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export const Root = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  marginBottom: 8,
  width: '100%',
  textAlign: 'left',
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  background: '#fff',
  borderRadius: '6px',
  padding: '12px 8px 20px  16px',
  '&:hover': {
    boxShadow: '1px 1px 4px 0px #00000026'
  },
  [theme.breakpoints.up('md')]: {
    gap: '28px',
    padding: '20px 4px 20px 32px'
  }
}));


export const UserContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '6px'
});

export const UserCaption = styled((props) => (
  <Typography variant='small' {...props} />
))({
  color: 'rgba(0,0,0,.4)',
  textAlign: 'center',
  whiteSpace: 'nowrap'
});

export const InfoContainer = styled('div')({
  flexGrow: 1
});

export const StyledButton = styled(props => (
  <Button variant='blank' color='black' {...props} />
))(({ theme}) => ({
  flexShrink: 0,
  '& svg': {
    height: 20,
    width: 'auto'
  },
  [theme.breakpoints.up('md')]: {
    width: 60,
    height: 60,
    borderRadius: '50%'
  }
}));