import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export const Root = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  gap: '30px',
  backgroundColor: '#FAFAFE',
  padding: '18px 20px 0'
});

export const LeftSide = styled(Box)({
  flexShrink: 0,
  flexBasis: 'calc(50% - 15px)',
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden'
});

export const LeftSideHeader = styled(Box)({
  flexShrink: 0,
  width: '100%'
});

export const BackButton = styled(Button)({
  minWidth: 'unset',
  width: 26,
  height: 26,
  borderRadius: '50%',
  background: '#404247 !important',
  padding: 'unset'
});

export const LeftSideFormContainer = styled(props => <Box sx={{ pt: .5 }} {...props} />)({
  flexGrow: 1,
  width: '100%',
  paddingBottom: '32px',
  paddingRight: '16px',

  '& > *': {
    marginBottom: '8px'
  }
});

export const LeftSideHeaderTitle = styled(Typography)({
  color: '#000',
  fontWeight: 600,
  fontSize: '18px'
});

export const LeftSideHeaderSubtitle = styled(Box)(({ theme }) => ({
  color: '#404247',
  fontWeight: 500,
  fontSize: '12px',
  paddingTop: theme.spacing(1.5)
}));

export const Peoples = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '26px',

  '& > *:not(:last-child)': {
    marginBottom: theme.spacing(0.5)
  }
}));

export const Footer = styled(Box)({
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
  width: '100%',
  height: 30,
  backgroundColor: 'rgba(250,250,254,0.88)',
  backdropFilter: 'blur(4px)',
  WebkitBackdropFilter: 'blur(4px)'
});

export const RightSide = styled(Box)({
  flexShrink: 0,
  flexBasis: 'calc(50% - 15px)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  paddingBottom: '32px'
});

export const SummaryContainer = styled(Box)({
  flexGrow: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  color: '#2E53D6',
  borderRadius: '8px',
  border: '1px solid #7090E9',
  background: 'linear-gradient(270deg, #BDD3FE 0%, #E2E8FF 100%)',
  padding: '14px 15px',
  overflow: 'hidden'
});

export const Summary = styled(Box)({
  flexGrow: 1,
  overflow: 'auto',
  color: 'inherit',
  fontSize: '11px',
  fontWeight: 500
});

export const More = styled(Box)({
  color: 'inherit',
  fontSize: '11px',
  fontWeight: 500,
  textDecoration: 'underline',
  cursor: 'pointer'
})

export const Fields = styled(Box)({
  flexShrink: 0,
  display: 'flex',
  gap: '10px'
});