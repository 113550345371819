import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getEmails as getEmailsAction,
  getEmail as getEmailAction,
  deleteEmail as deleteEmailAction,
  clearLocalEmails as clearLocalEmailsAction,
  clearLocalEmail as clearLocalEmailAction
} from 'actions/emails';

const useEmails = () => {
  const dispatch = useDispatch();
  const getEmails = useCallback(({ nylas_user_id, limit, offset, orders }) => dispatch(getEmailsAction({ nylas_user_id, limit, offset, orders })), [dispatch]);
  const getEmail = useCallback(id => dispatch(getEmailAction(id)), [dispatch]);
  const deleteEmail = useCallback(id => dispatch(deleteEmailAction(id)), [dispatch]);
  const clearLocalEmails = useCallback(() => dispatch(clearLocalEmailsAction()), [dispatch]);
  const clearLocalEmail = useCallback(() => dispatch(clearLocalEmailAction()), [dispatch]);

  return {
    getEmails,
    getEmail,
    deleteEmail,
    clearLocalEmails,
    clearLocalEmail
  };
};

export default useEmails;