import {
  createNylasUserRoutine,
  getNylasUserRoutine,
  deleteNylasUserRoutine
} from 'actions';

export const initialState = {
  nylasUser: {
    id: null,
    email: null,
    grant_id: null
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case createNylasUserRoutine.SUCCESS: {
      const { data: { nylas_user } } = action.payload;

      return { ...state, nylasUser: nylas_user };
    }
    case getNylasUserRoutine.SUCCESS: {
      const { data: { nylas_user } } = action.payload;

      return { ...state, nylasUser: nylas_user };
    }
    case deleteNylasUserRoutine.SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  };
};

export default reducer;