import { memo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';
// Local files
import { StyledStack } from 'views/Intake/Intake.styled';
import BuilderErrorLabel from 'components/Common/DataDisplay/BuilderErrorLabel/BuilderErrorLabel';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const Share = ({ step, firstName, password, repeatPassword, disabled, onChange, onSubmit }) => {  
  const [showPassword, setShowPassword] = useState(false);
  
  if (step !== 'share') return null;
  return (
    <StyledStack sx={{ alignItems: 'center', gap: '24px', pt: '125px' }}>
      <Typography variant='body1' sx={{ maxWidth: 520, textAlign: 'center', mb: '20px' }}>
        Lastly, create a  password so you can update your submission anytime
        and check when {firstName} sees your Offering!
      </Typography>
      <Box width='100%'>
        <TextField
          fullWidth
          variant='standard'
          label='Create a password'
          value={password.value}
          onChange={({ target: { value } }) => onChange({ password: { value, error: '' }, ...!!repeatPassword.value && { repeatPassword: { ...repeatPassword, error: '' } } })}
          error={!!password.error}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(prev => !prev)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {!!password.error && <BuilderErrorLabel value={password.error} />}
      </Box>
      <Box width='100%'>
        <TextField
          fullWidth
          variant='standard'
          label='Retype password'
          value={repeatPassword.value}
          onChange={({ target: { value } }) => onChange({ repeatPassword: { value, error: '' }, ...!!password.value && { password: { ...password, error: '' } } })}
          error={!!repeatPassword.error}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(prev => !prev)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {!!repeatPassword.error && <BuilderErrorLabel value={repeatPassword.error} />}
      </Box>
      <Button
        disabled={disabled}
        variant='gradient'
        sx={{ minWidth: { xs: 200, md: 400 }, fontSize: '1.125rem', display: 'flex', p: '28px',  m: '80px auto 140px' }}
        onClick={onSubmit}
      >
        Share Now & Create Account
      </Button>
    </StyledStack>
  );
};

Share.propTypes = {
  step: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  password: PropTypes.shape({
    value: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired
  }),
  repeatPassword: PropTypes.shape({
    value: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired
  }),
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Share {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);