import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const backgroundColor = '#F8F8F8';

export const Header = styled('div')({
  flexShrink: 0,
  width: '100%',
  height: 140,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: backgroundColor
});

export const CloseButton = styled(Button)({
  minWidth: 36,
  minHeight: 36,
  alignSelf: 'flex-end',
  backgroundColor: 'transparent !important',
  padding: 0
});

export const Section = styled(Box)({
  flexGrow:  1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  backgroundColor: '#fff',
  padding: '16px 0 40px'
});

export const SectionHeader = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  padding: '16px 40px 8px'
});

export const Grid = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(185px, 1fr))',
  gap: '8px',
  overflow: 'auto',
  padding: '8px 40px',
  [theme.breakpoints.down('sm')]: {
    '& > div': {
      width: '100%'
    }
  }
}));

export const Action = styled(Box)(({ theme }) => ({
  paddingLeft: '14px',
  width: '100%',
  display: 'flex',
  gap: '13px',
  justifyContent: 'flex-start',
  alignItems: 'center'
}));