import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

export const avatarStyles = {
  width: 72,
  height: 72,
  fontSize: '28px',
  borderRadius: '50%',
  boxShadow: '0px 4px 12px 0px #00000040',
  marginTop: '-36px'
};

export const Banner = styled(Box)({
  minHeight: 100,
  width: '100%',
  position: 'relative',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center'
});

export const Main = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 24px 16px'
});

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: theme.typography.pxToRem(18),
  color: 'rgba(0, 0, 0, 0.7)',
  textAlign: 'center',
  padding: '22px 8px 12px',
}));

export const Link = styled(MuiLink)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
 color: '#000',
}));

export const Content = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '45px 45px 30px',
  marginTop: 'auto',
});

export const Item = styled(Box)({
  width: '100%',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '10px',
});

export const Tag = styled(Chip)(({ theme }) => ({
  flexShrink: 0,
  minWidth: 18,
  minHeight: 18,
  width: 'auto',
  height: 'auto',
  fontSize: theme.typography.pxToRem(12),
  lineHeight: 1,
  fontWeight: 500,
  color: '#fff',
  background: '#12A33B',
  padding: '3px',
  '& .MuiChip-label': {
    padding: 0,
  }
}));

export const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(12),
  color: 'rgba(0, 0, 0, 0.7)',
}));