import styled from '@mui/material/styles/styled';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& input': {
    ...theme.typography.h5
  }
}));
const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.typography.pxToRem(22),
  fontWeight: 600
}));

export { StyledTextField, Text };