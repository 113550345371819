import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddIcon from 'components/Common/Icons/AddIcon';
import { useParams } from 'react-router-dom';
// Local files
import { Root, Mark, Illustration } from './Empty.styled';
import PropertyDialog from 'components/Dialogs/Property/Property';

const Empty = () => {
  const { dealspace_id } = useParams();
  const [open, setOpen] = useState(false);

  const handleAddClick = () => setOpen(true);

  return (
    <Root>
      <PropertyDialog
        dealspaceId={dealspace_id}
        open={open}
        onClose={() => setOpen(false)}
      />
      <Typography variant='subtitle1' align='center'>Add one or more Properties to this Dealspace</Typography>
      <Typography variant='body2' align='center' sx={{ lineHeight: 1.8, maxWidth: 770 }}>
        A <Mark>Property</Mark> is a parcel, or set of adjacent parcels.<br/>
        Add multiple Properties to this Dealspace when working on a portfolio or land assemblage.
      </Typography>
      <Illustration />
      <Button variant='contained' color='secondary' startIcon={<AddIcon />} sx={{ px: 3 }} onClick={handleAddClick}>Add Property</Button>
    </Root>
  );
};

export default memo(Empty);