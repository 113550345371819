import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// Local files
import AddIcon from 'components/Common/Icons/AddIcon';
import { ReactComponent as DragIcon } from 'assets/icons/hand-drag.svg';
import { FileInput, Content } from './FileUploader.styled';

const FileUploader = ({ multiple, onClick, accept, id, onChange, label }, ref) => {
  return (
    <Box
      onClick={onClick}
      display='flex'
      justifyContent='center'
      alignItems='center'
      flex={1}
      width='100%'
    >
      <Box>
        <FileInput
          ref={ref}
          accept={accept}
          id={id}
          type="file"
          multiple={multiple}
          onChange={onChange}
          onClick={(event) => event.target.value = ''}
        />
        <DragIcon style={{ width: 50, height: 'auto' }} />
        <Content>
          <AddIcon />
          <Typography variant='subtitle0'>{label}</Typography>
        </Content>
      </Box>
    </Box>
  );
};

export default forwardRef(FileUploader);