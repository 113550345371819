import { memo, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Actions, AvatarContainer, ContactLink, ContactsContainer, Content, Name, Role, Root, StyledAvatar } from './Sender.styled';
import AudioPlayer from 'components/Common/AudioPlayer/AudioPlayer';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { ReactComponent as PhoneIcon } from 'assets/icons/call.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as AddConnectIcon } from 'assets/icons/connect-to-add.svg';
import { ReactComponent as ConnectPendingIcon } from 'assets/icons/connect-pending.svg';
import { ReactComponent as ConnectedIcon } from 'assets/icons/connect-done.svg';
import { ReactComponent as SubscribedIcon } from 'assets/icons/subscribed.svg';
import { getRepresentation } from 'helpers';
import { representationVariants } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useContacts from 'hooks/useContacts';
import useError from 'hooks/useError';
import useSenders from 'hooks/useSenders';
import useSuccess from 'hooks/useSuccess';

const Sender = ({ id, title = null, email = null, mobile_phone = null, user, disabled, onConversation = null }) => {
  const audioRef = useRef(null);
  const { /*openAccessBetaDialog,*/ setAuthData } = useApp();
  const { createContact } = useContacts();
  const { setError } = useError();
  const { getSender } = useSenders();
  const { setSuccess } = useSuccess();
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { /*hasRecipientSession, isQwincyMember,*/ authorizedAsUser, my_id } = useCustomSelector(state => ({
    // hasRecipientSession: !!state.profile.recipient.id,
    // isQwincyMember: !!state.profile.recipient.user?.email,
    authorizedAsUser: state.sessions.session.userableType === 'user',
    my_id: state.profile.user.id
  }));
  const [processing, setProcessing] = useState(false);
  const showActions = user?.id !== my_id;
  const isConnected = !!user.current_contact && user.current_contact?.status === 'active';
  const isPending = !!user.current_contact && user.current_contact?.status === 'invited';
  const showSubscribed = isPending || isConnected;
  const showEmail = !!email || !!user.email;
  const showPhone = !!mobile_phone || (!!user.office_phone && user.office_phone_extension);

  const checkIsQwincyMember = () => {
    // if (isQwincyMember || !hasRecipientSession) {
      setAuthData({ open: true, redirectAction: { type: 'connect_click', data: { user } } });
    // } else {
    //   openAccessBetaDialog();
    // }
  };
  const handleConnectClick = () => {
    if (authorizedAsUser) {
      const contact = { invitation_attributes: { user_id: user?.id } };
    
      setProcessing(true);
      createContact({ contact })
      .then(() => {
        audioRef.current.playConnectionRequestSound();
        setSuccess('Connection invitation sent');
        setSuccess(`You are now Subscribed to ${user.first_name} ${user.last_name}`);
        getSender(id)
        .catch(e => setError(e))
        .finally(() => setProcessing(false));
      })
      .catch(e => setError(e).then(() => setProcessing(false)));
    } else {
      checkIsQwincyMember();
    }
  };

  if (!!user) {
    return (
      <Root>
        <Content>
          <AvatarContainer
            component={disabled ? Box : NavLink}
            to={`/users/${user.username}`}
          >
            <StyledAvatar src={getRepresentation(user.photo?.representations, representationVariants.TINY)}>
              {user.first_name && user.last_name ? `${user.first_name[0]}${user.last_name[0]}` : ''}
            </StyledAvatar>
          </AvatarContainer>
          <ContactsContainer>
            <Name component={disabled ? Box : NavLink} to={`/users/${user.username}`} noWrap>{user.first_name} {user.last_name}</Name>
            <Role noWrap>{title}</Role>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '11px' }}>
              {showEmail &&
                <ContactLink href={`mailto:${email ?? user.email}`}>
                  <MailIcon />
                </ContactLink>
              }
              {mediaDesktop && showPhone &&
                <ContactLink href={`tel:${mobile_phone ?? user.office_phone}`}>
                  <PhoneIcon />
                </ContactLink>
              }
            </Box>
          </ContactsContainer>
        </Content>
        {showActions &&
          <Actions>
            {!mediaDesktop && showPhone &&
              <Button
                variant='outlined'
                color='neutral'
                component='a'
                href={`tel:${mobile_phone ?? user.office_phone}`}
                startIcon={<PhoneIcon />}
                sx={{ minHeight: 30, color: 'rgba(0,0,0,.8)', px: 1.5 }}
                disabled={processing || disabled}
              >
                Call
              </Button>
            }
            {isConnected ?
              <Button disabled={disabled} sx={{ px: 0 }}>
                <ConnectedIcon style={{ marginRight: '2px' }}/>Connected
              </Button> : (isPending ?
                <Button sx={{ color: 'rgba(0,0,0,.4)' }} disabled={disabled}>
                  <ConnectPendingIcon /><i>Pending</i>
                </Button> :
                <Button
                  variant='gradient'
                  startIcon={<AddConnectIcon />}
                  onClick={handleConnectClick}
                  disabled={processing || disabled}
                >
                  Connect
                </Button>
              )
            }
            {showSubscribed &&
              <Button disabled={disabled} sx={{ px: 0 }}>
                <SubscribedIcon style={{ marginRight: '2px', marginTop: '-2px' }} />Subscribed
              </Button>
            }
            <Button
              variant='outlined'
              color='neutral'
              startIcon={<ChatIcon />}
              sx={{ order: { xs: '-1', md: 'unset' }, minHeight: 30, color: 'rgba(0,0,0,.8)', px: 1.5 }}
              onClick={() => !!onConversation ? onConversation([user?.id]) : {}}
              disabled={processing || disabled}
            >
              Chat
            </Button>
          </Actions>
        }
        <AudioPlayer ref={audioRef} />
      </Root>
    );
  }
  return null;
};

Sender.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  email: PropTypes.string,
  mobile_phone: PropTypes.string,
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    photo: PropTypes.object
  }),
  disabled: PropTypes.bool.isRequired,
  onConversation: PropTypes.func
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Sender {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);