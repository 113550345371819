import { memo, useRef, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import { useNavigate } from 'react-router-dom';
// Local files
import { Root, Content, AddButton, InfoItem } from './Recipient.styled';
import Scrollbars from 'react-custom-scrollbars-2';
import DateField from 'components/Common/Inputs/DateField/DateField';
import AddIcon from 'components/Common/Icons/AddIcon';
import { ReactComponent as AttachIcon } from 'assets/icons/attach.svg';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { getFormattedPhone, needToUpdateField } from 'helpers';
import useApp from 'hooks/useApp';
import useBlob from 'hooks/useBlob';
import useChecksum from 'hooks/useChecksum';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useRecipients from 'hooks/useRecipients';

const now = new Date();

const Recipient = () => {
  const navigate = useNavigate();
  const fileRef = useRef(null);
  const { closeRecipientDrawer, openViewFileDialog } = useApp();
  const { processBlob } = useBlob();
  const { processFiles } = useChecksum();
  const { setError } = useError();
  const { updateRecipient } = useRecipients();
  const { open, recipient } = useCustomSelector(state => state.app.recipientDrawer)
  const [price, setPrice] = useState('');
  const [date, setDate] = useState({ value: null, formattedValue: null, error: '' });
  const [notes, setNotes] = useState('');
  const [files, setFiles] = useState([]);
  const [processing, setProcessing] = useState(false);

  const handleClose = () => closeRecipientDrawer();
  const onEntering = () => {
    if (recipient.offered_amount_cents) setPrice(recipient.offered_amount_cents / 100);
    if (recipient.offered_at) setDate({ value: recipient.offered_at, formattedValue: new Date(recipient.offered_at), error: '' });
    if (recipient.notes) setNotes(recipient.notes);
    if (!!recipient.files.length) setFiles(recipient.files);
  };
  const onExited = () => {
    setPrice('');
    setDate({ value: null, formattedValue: null, error: '' });
    setNotes('');
    setFiles([]);
    setProcessing(false);
  };
  const handleVisitProfileClick = () => {
    closeRecipientDrawer()
    .then(() => navigate(`/users/${recipient.user.username}`));
  };
  const handleDateChange = ({ value, formattedValue, error }) => {
    const diff = now - formattedValue;
    const e = typeof formattedValue !== 'object' || (diff && diff >= 0) ? 'Invalid Date' : error;

    setDate({ value, formattedValue, error: e });
  };
  const handleFileClick = file => openViewFileDialog({ file });
  const handleFileChange = newFiles => {
    if (!!newFiles[0]) handleAddFile(newFiles[0])
  };
  const handleAddFile = value => {
    processFiles([value], ({ file, checksum }) => {
      processBlob({ file, checksum }, blob => {
        if (!blob.id) return;

        setFiles(prev => [...prev, blob]);
      });
    });
  };
  const handleSubmit = () => {
    const r = {
      ...needToUpdateField(recipient.offered_amount_cents, parseInt(price) * 100) && { offered_amount_cents: parseInt(price) * 100 },
      ...needToUpdateField(recipient.offered_at, date.value) && { offered_at: date.value },
      ...needToUpdateField(recipient.notes, notes) && { notes },
      ...needToUpdateField(_map(recipient.files, f => f.id), _map(files, f => f.id).sort()) && { files: _map(files, f => f.id), localFiles: files }
    };

    if (!_isEmpty(r)) {
      setProcessing(true);
      updateRecipient({ id: recipient.id, ...r })
      .then(handleClose)
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    } else {
      handleClose();
    }
  };

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={() => !processing && handleClose()}
      PaperProps={{ sx: { width: { xs: '100%',  md: '420px' } } }}
      SlideProps={{ onEntering, onExited }}
      ModalProps={{ sx: { zIndex: 1300 } }}
    >
      {recipient &&
        <Root>
          <Box sx={{
            display: 'flex',
            flexDirection:  'column',
            gap: '32px'
          }}>
            <Typography variant='subtitle1' sx={{ fontWeight: 700 }}>
              {recipient.user?.first_name ?? recipient.first_name} {recipient.user?.last_name ?? recipient.last_name}
            </Typography>
            <Box sx={{ width: '100%', display: (!recipient.user?.title && !recipient.user?.company_name && !recipient.user) ? 'contents' : 'block' }}>
              {recipient.user?.title &&
                <Typography variant='caption' sx={{ display: 'block' }}>
                  {recipient.user.title}
                </Typography>
              }
              {recipient.user?.company_name &&
                <Typography variant='caption' sx={{ display: 'block' }}>
                  {recipient.user.company_name}
                </Typography>
              }
              {recipient.user &&
                <ButtonBase sx={{ alignSelf: 'flex-start' }} onClick={handleVisitProfileClick}>
                  <Typography variant='subtitle3' sx={{ textDecoration: 'underline' }}>
                    Visit profile
                  </Typography>
                </ButtonBase>
              }
            </Box>
            <Box sx={{ width: '100%' }}>
              {recipient.user?.mobile_phone &&
                <InfoItem>
                  <span>Mobile</span>
                  <span><b>{getFormattedPhone(recipient.user.mobile_phone)}</b></span>
                </InfoItem>
              }
              {recipient.user?.office_phone &&
                <InfoItem>
                  <span>Office</span>
                  <span><b>{getFormattedPhone(recipient.user.office_phone)}</b></span>
                </InfoItem>
              }
              {recipient.user?.office_phone_extension &&
                <InfoItem>
                  <span>Office Ext</span>
                  <span><b>{recipient.user.office_phone_extension}</b></span>
                </InfoItem>
              }
              <InfoItem>
                <span>Email</span>
                <span><b>{recipient.user?.email ?? recipient.email}</b></span>
              </InfoItem>
            </Box>
          </Box>
          <Scrollbars autoHide autoHideTimeout={1000}>
            <Content>
              <TextField
                fullWidth
                label='Offer'
                value={price}
                onChange={({ target: { value } }) => setPrice(value)}
                InputProps={{ startAdornment: <InputAdornment position="start" sx={{ mr: '3px', '& > *':  { fontSize: '0.75rem' } }}>$</InputAdornment> }}
              />
              <DateField
                autoFocus
                fullWidth
                label='Date'
                disabled={processing}
                disablePast
                value={date.formattedValue}
                helperText={date.error}
                onChange={v => handleDateChange(v)}
              />
              <TextField
                multiline
                fullWidth
                minRows={5}
                maxRows={10}
                label='Notes'
                value={notes}
                onChange={({ target: { value } }) => setNotes(value)}
              />
              <Typography variant='caption' mt={2} mb={1}>Files</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', gap: '5px' }}>
                {_map(files, f =>
                  <Button
                    key={f.id}
                    variant='outlined'
                    color='neutral'
                    startIcon={<AttachIcon />}
                    onClick={() => handleFileClick(f)}
                    sx={{ px: 1.5 }}
                  >
                    {f.filename}
                  </Button>
                )}
                <AddButton
                  disabled={processing}
                  onClick={() => fileRef.current.click()}
                >
                  <AddIcon />
                  <input
                    hidden
                    ref={fileRef}
                    accept='*'
                    type='file'
                    onChange={({ target: { files: fl } }) => handleFileChange(fl)}
                  />
                </AddButton>
              </Box>
            </Content>
          </Scrollbars>
          <Box display='flex' justifyContent='center' gap='4px' mb={2}>
            <Button
              disabled={processing}
              variant='text'
              color='error'
              sx={{ minWidth: 100 }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              disabled={processing}
              variant='contained'
              color='primary'
              sx={{ minWidth: 100 }}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Box>
        </Root>
      }
    </Drawer>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Recipient {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);