import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from 'components/Common/Icons/AddIcon';
import { useLocation, useNavigate } from 'react-router-dom';
// Local files
import { Root, Illustration, Content, ContentContainer, Title, List, ListItem } from './OfferingsEmpty.styled';
import { getFormattedShortDateWithTime } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useOffers from 'hooks/useOffers';

const OfferingsEmpty = () => {
  const previousLocation = useLocation();
  const navigate = useNavigate();
  const { setError } = useError();
  const { createOffer } = useOffers();
  const { dealspaceId, creator } = useCustomSelector(state => ({
    dealspaceId: state.dealspaces.dealspace.id,
    creator: `${state.profile.user.first_name} ${state.profile.user.last_name[0]}.`
  }));
  const [processing, setProcessing] = useState(false);

  const handleCreateClick = () => {
    const offer = { name: `${getFormattedShortDateWithTime(new Date())} by ${creator}` };
    
    setProcessing(true);
    createOffer({ dealspace_id: dealspaceId, offer })
    .then(({ payload: { data: { offer: { id } } } }) =>
      navigate(`/builder/${id}`, { state: { previousLocation, redirectPath: `/dealspaces/${dealspaceId}/offerings` } })
    )
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };
  
  return (
    <Root>
      <ContentContainer>
        <Illustration />
        <Content>
          <Title variant='subtitle1'>Share private, live Offerings with anyone, for any deal type</Title>
          <List>
            <ListItem>Share with 1 person, or list of thousands</ListItem>
            <ListItem>Share with both members & nonmembers</ListItem>
            <ListItem>Lock locations or contents with CAs</ListItem>
            <ListItem>Make updates in real-time</ListItem>
            <ListItem>Track activity</ListItem>
          </List>
        </Content>
      </ContentContainer>
      <Button
        variant='contained'
        color='secondary'
        startIcon={<AddIcon />}
        onClick={handleCreateClick}
        disabled={processing}
      >
        Create Offering
      </Button>
    </Root>
  );
};

export default memo(OfferingsEmpty);