import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';

export const StyledCheckbox = styled(Checkbox)(() => ({
  position: 'absolute',
  top: 0, left: 0, right: 0, bottom: 0,
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  '&.Mui-checked': {
    background: 'rgba(0,0,0,.3)'
  }
}));
export const StyledRadio = styled(Radio)(() => ({
  position: 'absolute',
  top: 0, left: 0, right: 0, bottom: 0,
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  '&.Mui-checked': {
    background: 'rgba(0,0,0,.3)'
  }
}));

export const BlurredPlaceholder = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
  background: 'rgba(0, 0, 0, 0.5)',
  backdropFilter: 'blur(7px)',
  WebkitBackdropFilter: 'blur(7px)',
}));

export  const Root = styled(Box)(({ theme }) => ({
  width: 185,
  minHeight: 90 + 50,
  display: 'block',
  textAlign: 'left',
  textDecoration: 'none',
  borderRadius: '4px',
  overflow: 'hidden',
  position: 'relative',
  color: theme.palette.text.primary,
  background: theme.palette.background.paper,
  border: '0.5px solid #0000001A',
  '&:hover': {
    boxShadow: '3px 3px 5px -2px #020D174D',
  }
}));

export const DocPlaceholder = styled(Box)({
  width: '100%',
  height: 90,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#F4F6F8',
  cursor: 'pointer',
  userSelect: 'none',
  '& > *': {
    maxHeight: 36,
  }
});

export const Image = styled(Box)({
  width: '100%',
  height: 90,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  cursor: 'pointer',
});

export const LoadingPlaceholder = styled(Box)({
  height: 90,
  position: 'absolute',
  top: 0, right: 0, left: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(85,86,87,0.9)',
});

export const Title = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  fontSize: theme.typography.pxToRem(11),
  fontWeight: 600,
  color: 'rgba(0, 0, 0, 0.8)',
}));

export const Description = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  fontSize: theme.typography.pxToRem(10),
  fontWeight: 200,
  color: '#8C8C8C',
  userSelect: 'none'
}));

export const CancelButton = styled(Button)({
  width: 30,
  height: 30,
  position: 'absolute',
  top: 0, right: 0,
  color: 'rgba(0,0,0,.6)',
  borderRadius: '4px',
});

export const ErrorInfo = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(10),
  color: theme.palette.error.main,
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  '& > svg': {
    height: 14,
    width: 'auto',
  }
}));