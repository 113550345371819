import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getDealspacesListRoutine,
  getDealspacesRoutine,
  getDealspacesForChatsRoutine,
  getNotViewedDealspacesRoutine,
  getDealspaceRoutine,
  createDealspaceRoutine,
  updateDealspaceRoutine,
  deleteDealspaceRoutine,
  setDealspaceUnreadCountConversationsRoutine,
  setDealspaceUnreadCoversationUpdatedRoutine,
  clearLocalDealspacesForChatsRoutine,
  clearLocalDealspacesListRoutine,
  clearLocalDealspacesRoutine,
  clearLocalNotViewedDealspacesRoutine,
  clearLocalActiveDealspaceRoutine
} from 'actions';
import _omit from 'lodash/omit';

const listAttributes = ['id', 'name'];
const attributes = [
  'id',
  'name',
  'main_location',
  'locations_count',
  'properties_count',
  'unread_conversation',
  'unread_offer_conversations_count',
  'own_view',
  { group: ['id', 'name', { current_member: ['role'] }] },
  { conversation: ['id'] }
];
const fullAttributes = [
  ...attributes,
  'notes',
  {
    conversation:  
    ['id', 
    { last_message: ['id', 'read', 'text', { user: ['id', 'first_name', 'last_name'] }, 'created_at'] }, 
    { users: ['id', 'first_name', 'last_name', { photo: ['id', 'representations'] }] }, 
    { conversationable: ['id', 'name', 'headline', 'unread_conversations_count', { group: ['id'] }] },
    'conversationable_type',
    'updated_at']
  },
  { cover: ['id', 'representations'] },
  { root_folder: ['id'] }
]; 

export const getDealspacesList = getThunkActionCreator(
  getDealspacesListRoutine,
  async ({ group_id, offset = 0 }) => {
    const params = { limit: 25, offset, orders: { updated_at: 'desc' }, attributes: listAttributes };

    return await axios.get(`/groups/${group_id}/dealspaces`, { params });
  }
);
export const getDealspaces = getThunkActionCreator(
  getDealspacesRoutine,
  async ({ group_id, limit = 25, offset = 0, orders = { updated_at: 'desc' }, query = null }) => {
  
    const attributes = [
      'id',
      'name',
      'properties_count',
      'own_view',
      'main_location',
      'locations_count',
      { root_folder: ['id'] },
      { group: ['id', 'name'] },
      { cover: ['id', 'representations'] },
      { members: ['id', { user: ['id', 'first_name', 'last_name', { photo: ['representations'] }] }]}
    ];
    const params = { limit, offset, orders, attributes, ...!!query && { query } };

    return await axios.get(`/groups/${group_id}/dealspaces`, { params });
  }
);
export const getDealspacesForChats = getThunkActionCreator(
  getDealspacesForChatsRoutine,
  async ({ group_id, offset = 0 }) => {
    const params = { limit: 25, offset, orders: { updated_at: 'desc' }, attributes: fullAttributes };

    return await axios.get(`/groups/${group_id}/dealspaces`, { params });
  }
);
export const getNotViewedDealspaces = getThunkActionCreator(
  getNotViewedDealspacesRoutine,
  async ({ offset = 0 }) => {
    const attributes = [
      'id',
      'name',
      'properties_count',
      'locations_count',
      'own_view',
      'main_location',
      'updated_at',
      { members: ['id', { user: ['id', 'first_name', 'last_name', { photo: ['representations'] }] }]},
      { cover: ['id', 'representations'] }
    ];
    const params = { limit: 25, offset, orders: { updated_at: 'desc' }, attributes, not_viewed: true };

    return await axios.get('/dealspaces', { params });
  }
);
export const getDealspace = getThunkActionCreator(
  getDealspaceRoutine,
  async id => {
    const params = { attributes: fullAttributes };

    return await axios.get(`/dealspaces/${id}`, { params });
  }
);
export const createDealspace = getThunkActionCreator(
  createDealspaceRoutine,
  async ({ group_id, dealspace }) => {
    const data = { dealspace: _omit(dealspace, ['localCover']), attributes };

    return await axios.post(`/groups/${group_id}/dealspaces`, data);
  }
);
export const updateDealspace = getThunkActionCreator(
  updateDealspaceRoutine,
  async ({ id, ...fields }) => {
    const data = { dealspace: _omit(fields, ['localCover']) };
    const response = await axios.patch(`/dealspaces/${id}`, data);

    return { response, dealspace: { id, ...fields } };
  }
);
export const deleteDealspace = getThunkActionCreator(
  deleteDealspaceRoutine,
  async id => {
    const response = await axios.delete(`/dealspaces/${id}`);

    return { response, id };
  }
);
export const setDealspaceUnreadCountConversations = getThunkActionCreator(
  setDealspaceUnreadCountConversationsRoutine, 
  async ({ unread_offer_conversations_count, dealspace_id }) => ({ unread_offer_conversations_count, dealspace_id })
);
export const setDealspaceUnreadCoversationUpdated = getThunkActionCreator(
  setDealspaceUnreadCoversationUpdatedRoutine, 
  async ({ unread_conversation, dealspace_id }) => ({ unread_conversation, dealspace_id })
);
export const clearLocalDealspacesForChats = getThunkActionCreator(clearLocalDealspacesForChatsRoutine, async () => {});
export const clearLocalDealspacesList = getThunkActionCreator(clearLocalDealspacesListRoutine, async () => {});
export const clearLocalDealspaces = getThunkActionCreator(clearLocalDealspacesRoutine, async () => {});
export const clearLocalNotViewedDealspaces = getThunkActionCreator(clearLocalNotViewedDealspacesRoutine, async () => {});
export const clearLocalActiveDealspace = getThunkActionCreator(clearLocalActiveDealspaceRoutine, async () => {});