import { memo } from 'react';
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend';
import { DndProvider, useDrop } from 'react-dnd';
import Box from '@mui/material/Box';
// import _find from 'lodash/find';
// import _forEach from 'lodash/forEach';
import PropTypes from 'prop-types';
// Local files
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const DropZone = ({ children, allowTypes, onDrop, ...props }) => {
  const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop: v => onDrop(v),
    canDrop: ({ files }) => {
      let allowDrop = true;

      // TODO
      // _forEach(files, f => {
      //   if (!!_find(allowTypes, at => at === f.type)) {
      //     allowDrop = true;
      //   }
      // });

      return allowDrop;
    },
    collect: m => ({ isOver: m.isOver(), canDrop: m.canDrop() })
  }));
  const isActive = canDrop && isOver;

  return (
    <Box
      ref={dropRef}
      border={isActive ? '2px solid rgba(236, 236, 236, 1)' : 'none'}
      height='100%'
      borderRadius='8px'
      {...props}
    >
      {children}
    </Box>
  );
};

DropZone.propTypes = {
  children: PropTypes.any,
  allowTypes: PropTypes.array.isRequired,
  onDrop: PropTypes.func.isRequired,
  display: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  gridTemplateColumns: PropTypes.object,
  gridAutoRows: PropTypes.string,
  rowGap: PropTypes.string,
  overflow: PropTypes.string
};

const WrapperComponent = props => {
  return (
    <ErrorWrapper>
      <DndProvider backend={HTML5Backend}>
        <DropZone {...props} />
      </DndProvider>
    </ErrorWrapper>
  );
};

export default memo(WrapperComponent);