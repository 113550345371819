import styled from '@mui/material/styles/styled';
import MuiTableCell from '@mui/material/TableCell';

export const TableCell = styled(MuiTableCell)({
  minHeight: 66,
  height: 66,
  fontSize: '0.75rem',
  color: 'rgba(0,0,0,.7)',
  padding: '2px 12px',
});
