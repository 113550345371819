import {
  getArchiveRoutine,
  createArchiveRoutine
} from 'actions';
import _filter from 'lodash/filter';

export const initialState = {
  archives: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getArchiveRoutine.SUCCESS: {
      const { data: { archive: { id } } } = action.payload;
      const archives = _filter(state.archives, a => a !== id);

      return { ...state, archives };
    }
    case createArchiveRoutine.SUCCESS: {
      const { data: { archive: { id } } } = action.payload;
      const archives = [...state.archives, id];
      
      return { ...state, archives };
    }
    default: {
      return state;
    }
  };
};

export default reducer;