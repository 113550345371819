import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import { Heading, Title, Content, Footer, SkipButton } from './AccessFeed.styled';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';

const AccessFeed = () => {
  const { closeAccessFeedDialog, setAuthData } = useApp();
  const { open, primarySender, recipient } = useCustomSelector(state => ({
    open: state.app.accessFeedDialog.open,
    sender: state.profile.recipient.offer.primary_sender,
    recipient: state.profile.recipient
  }));
  const recipientEmail = recipient?.email ?? recipient?.user?.email ?? '';
  const [email, setEmail] = useState('');

  const handleClose = () => closeAccessFeedDialog();
  const handleEntering = () => setEmail(recipientEmail);
  const handleExited = () => setEmail('');
  const handleSignupClick = () => setAuthData({ open: true });

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited, onEntering: handleEntering }}
    >
      <Content>
        <Heading>
          <BaseAvatar
            src={getRepresentation(primarySender?.user?.photo?.representations, representationVariants.TINY)}
            firstName={primarySender?.user?.first_name}
            lastName={primarySender?.user?.last_name}
          />
          <Title sx={{ display: 'inline-block' }}>
            <b>{primarySender?.user?.first_name} {primarySender?.user?.last_name}</b> has invited you to Qwincy
          </Title>
        </Heading>
        <Typography variant='body2' align='center' mb={3}>
          Join Free to claim your <b>private Deal Feed.</b><br/>
          Get more offerings from {primarySender?.user?.first_name} & others in your network
          by connecting on Qwincy
        </Typography>
        <TextField
          type='email'
          label='Email Address'
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
          fullWidth
          sx={{ maxWidth: '310px' }}
        />
        <Button variant='contained' color='primary' sx={{ minWidth: '120px', mt: '40px' }} onClick={handleSignupClick}>Sign Up</Button>
        <Footer>
          <SkipButton onClick={handleClose}>No thanks</SkipButton>
        </Footer>
      </Content>
    </BaseDialog>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AccessFeed {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);