import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const Root = styled(Box)(({ theme }) => ({
  height: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '32px',
  background: '#fff',
  overflow: 'auto',
  padding: '40px 18px',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: '64px'
  }
}));

export const Image = styled('img')({
  display: 'block',
})

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    textAlign: 'left',
  }
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(20),
  fontWeight: 600,
  color: 'rgba(0,0,0,.6)',
  marginBottom: 12,
}));

export const Description = styled(Typography)(({ theme }) => ({
  maxWidth: 370,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  color: 'rgba(0,0,0,.6)',
  marginBottom: 32,
}));