import { memo } from 'react';
import Box from '@mui/material/Box';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Root } from './TextEditor.styled';
import Tiny from 'components/Tiny/Tiny';
import BuilderErrorLabel from 'components/Common/DataDisplay/BuilderErrorLabel/BuilderErrorLabel';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';

const TextEditor = ({ offerId, value, error, onChange }) => {
  return (
    <Root>
      <Tiny
        id='builderDescciption'
        offerId={offerId}
        value={value}
        onChange={({ v, c }) => onChange({ v, c })}
        plugins={['lists', 'link', 'autolink', 'emoticons', 'charmap', 'formatpainter', 'table', 'advtable', 'image', 'media', 'quickbars', 'wordcount', 'powerpaste', 'autoresize']}
        toolbar={'bold italic underline forecolor fontfamily fontsize indent align hr bullist numlist link h2 h3 emoticons image media table formatpainter blockquote'}
        otherInitProps={{
          min_height: 400,
          max_width: 600,
          menubar: false,
          placeholder: 'Begin typing content body',
          skin: 'borderless',
          paste_webkit_styles: 'all',
          // smart_paste: true,
          elementpath: false,
          branding: false,
          object_resizing: true,
          image_title: true,
          file_picker_types: 'image media',
          content_style: 'body { margin: 0px; padding: 0px } img { max-width: 100% } table { max-width: 100% } td { word-break: break-all }'
        }}
      />
      {!!error &&
        <Box display='flex' justifyContent='center' mb={1}>
          <BuilderErrorLabel value={error} />
        </Box>
      }
    </Root>
  );
};

TextEditor.propTypes = {
  offerId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <TextEditor {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);