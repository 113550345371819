import { memo } from 'react';
import Box from '@mui/material/Box';
// Local files
import { Content, Title } from './ContentBase.styled';

const ContentBase = ({ title, children, bottomText }) => {
  return (
    <Content>
      <Title noWrap>{title}</Title>
      <Box>
        {children}
        {!!bottomText && bottomText}
      </Box>
    </Content>
  );
};

export default memo(ContentBase);