import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

const DesktopContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  gap: '12px 8px',
  padding: '32px 12px 32px 12px',
  [theme.breakpoints.up('md')]: {
    flexWrap: 'nowrap',
    padding: '32px 32px 32px 60px',
  }
}));
const MobileContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '12px 8px',
  padding: '20px 12px 32px 12px',
  [theme.breakpoints.up('md')]: {
    flexWrap: 'nowrap',
    padding: '32px 32px 32px 60px',
  }
}));
const DesktopTextContent = styled('div')(({ theme }) => ({
  flexGrow: 1,
  order: 3,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  paddingLeft: '16px',
  [theme.breakpoints.up('md')]: {
    order: 2,
    width: 'auto',
  }
}));
const MobileTextContent = styled('div')(({ theme }) => ({
  flexGrow: 1,
  order: 2,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px'
}));
const DesktopActions = styled('div')(({ theme }) => ({
  flexShrink: 0,
  order: 2,
  display: 'flex',
  gap: '8px',
  marginLeft: 'auto',
  [theme.breakpoints.up('md')]: {
    order: 3,
    marginLeft: 'unset',
  }
}));
const MobileActions = styled('div')(({ theme }) => ({
  flexShrink: 0,
  order: 3,
  display: 'flex',
  justifyContent: 'center',
  gap: '8px',
  [theme.breakpoints.up('md')]: {
    order: 3
  }
}));
const Title = styled((props) => (
  <Typography {...props} />
))({
  fontWeight: 600,
  fontSize: '18px'
});
const Headline = styled((props) => (
  <Typography variant='body2' {...props} />
))({
  color: '#45464C'
});
const LocationContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  color: '#9FA2AC',
  marginLeft: '-5px'
});

export { DesktopContent, MobileContent, DesktopTextContent, MobileTextContent, DesktopActions, MobileActions, Title, Headline, LocationContainer };