import { memo } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const OffersWidgetSkeleton = () => {
  return (
    <Box>
      <Skeleton animation='wave' width={160} sx={{ margin: '0 auto' }} />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', mb: '8px', mt: '46px' }}>
        <Skeleton animation='wave' variant='rounded' width={54} height={34} />
        <Skeleton animation='wave'  width={180} />
      </Box>
      <Skeleton animation='wave' />
      <Skeleton animation='wave' />
      <Skeleton animation='wave' />
      <Skeleton animation='wave' />
    </Box>
  );
};

export default memo(OffersWidgetSkeleton);