import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import _startCase from 'lodash/startCase';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Root, UserCaption, UserContainer, InfoContainer } from './InfoSavedCard.styled';
import Menu from '../InfoCard/Menu/Menu';
import MarketFilledIcon from 'components/Common/Icons/MarketFilledIcon';
import LockedFilledIcon from 'components/Common/Icons/LockedFilledIcon';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import Status from 'components/Offers/Status/Status';
import PreviewDialog from 'components/Dialogs/Preview/Preview';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { representationVariants } from 'helpers/constants';
import { getRepresentation, getShortDate } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';

const InfoSavedCard = ({ id, offer, primary_sender, headline, public: p }) => {
  const navigate = useNavigate();
  const myId = useCustomSelector(state => state.profile.user.id);
  const [previewDialog, setPreviewDialog] = useState({ open: false, id: null });
  const deadline = !!offer?.deadline_at ? Math.round((new Date(offer?.deadline_at).getTime() - new Date().getTime()) / 86_400_000) : null;
  
  const handleClick = () => {
    if (primary_sender?.user?.id === myId && !!offer) {
      navigate(`/deal_flow/${offer.id}`);
    } else {
      setPreviewDialog({ open: true, id });
    }
  };
  const handlePreviewClick = () => setPreviewDialog({ open: true, id });
  const handlePreviewClose = () => setPreviewDialog({ open: false, id: null });

  return (
    <>
      <PreviewDialog
        {...previewDialog}
        onClose={handlePreviewClose}
      />
      <Root onClick={handleClick}>
        {p ? <MarketFilledIcon sx={{ mb: '2px' }} /> : <LockedFilledIcon sx={{ mb: '2px' }} />}
        <UserContainer>
          <UserCaption>Saved by:</UserCaption>
          <BaseAvatar
            src={getRepresentation(primary_sender?.user?.photo?.representations, representationVariants.TINY)}
            firstName={primary_sender?.user?.first_name}
            lastName={primary_sender?.user?.last_name}
          />
        </UserContainer>
        {!!offer && <UserContainer>
          <UserCaption>Shared by:</UserCaption>
          <BaseAvatar
            src={getRepresentation(offer?.primary_sender?.user?.photo?.representations, representationVariants.TINY)}
            firstName={offer?.primary_sender?.user?.first_name}
            lastName={offer?.primary_sender?.user?.last_name}
          />
        </UserContainer>}
        <InfoContainer>
          <Typography variant='caption'>{headline}</Typography>
          <Box sx={{ display: 'flex', gap: '16px', pt: '6px' }}>
            <Status value={offer?.status || 'ended'} />
            {!!deadline && <Typography variant='caption' color='error'>{deadline < 0 ? `${_startCase(offer?.deadline_type)} passed ${getShortDate(offer?.deadline_at)}` :`${_startCase(offer?.deadline_type)} in ${deadline} days`}</Typography>}
          </Box>
        </InfoContainer>
        <Menu
          showEdit={false}
          showPreview
          showDuplicate={false}
          showDelete={false}
          onEdit={() => {}}
          onPreview={handlePreviewClick}
          onDuplicate={() => {}}
          onDelete={() => {}}
        />
      </Root>
    </>
  );
};

InfoSavedCard.propTypes = {
  status: PropTypes.string.isRequired,
  deadline_at: PropTypes.string,
  user: PropTypes.shape({
    photo: PropTypes.shape({
      representations: PropTypes.array
    }),
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }),
  primary_sender: PropTypes.shape({
    user: PropTypes.shape({
      photo: PropTypes.shape({
        representations: PropTypes.array
      }),
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  }),
  headline: PropTypes.string,
  public: PropTypes.bool.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <InfoSavedCard {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);