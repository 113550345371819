import { memo, useState, useLayoutEffect } from 'react';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Skeleton from '@mui/material/Skeleton';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
//Local files
import { Root, Header, TextButton, Grid } from './BuilderPublicFiles.styled';
import { ReactComponent as UploadIcon } from 'assets/icons/upload-cloud.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/folder-outlined.svg';
import Drawer from '../../Drawers/Documents/Documents';
import DropContainer from 'components/Common/Feedback/DropContainer/DropContainer';
import File from 'components/Documents/Card/Card';
import Folder from 'components/Folders/Card/Card';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { getRepresentation, formatBytes } from 'helpers';
import { representationVariants } from 'helpers/constants';
import useCustomSelector from 'hooks/useCustomSelector';

const BuilderPublicFiles = ({ dealspaceFolderId, uploading, fetching, files, folders, onChange }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [percentProgress, setPercentProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const filesSize = _reduce(files, (accumulator, currentValue) => accumulator + currentValue.file.byte_size, 0);
  const foldersSize = _reduce(folders, (accumulator, currentValue) => accumulator + currentValue.documents_byte_size, 0);
  const total = useCustomSelector(state => state.offers.builder.offer.total_unlocked_documents_count);
  const showEmpty = !files.length && !folders.length && !loading;

  const handleDrop = ({ files }) => {
    console.log(files);
  };
  const onLoading = ({ percent, fileName, fileType, locked }) => {
    if (!locked) {
      setLoading(true);
      setPercentProgress(percent);
      setFileName(fileName);
      setFileType(fileType);
    }
  };

  useLayoutEffect(() => {
    setLoading(false);
    setPercentProgress(0);
    setFileName('');
    setFileType('');
  }, [files.length])

  if (fetching) {
    return (
      <Skeleton
        animation='wave'
        width='100%'
        height={82}
        sx={{ transform: 'scale(1, 1)' }}
      />
    );
  }
  return (
    <>
      <Drawer
        files={files}
        folders={folders}
        folderId={dealspaceFolderId}
        uploading={uploading}
        open={drawerOpen}
        level={0}
        totalSelected={total}
        onClose={() => setDrawerOpen(false)}
        onChange={v => onChange(v)}
        onLoading={onLoading}
      />
      {showEmpty ?
        <ButtonBase variant='uploader' sx={{ height: 82, marginBottom: '3px' }} onClick={() => setDrawerOpen(true)}>
          <Header sx={{ position: 'absolute', top: 14, left: 16 }}><FolderIcon /><Typography variant='caption'>Files</Typography></Header>
          <UploadIcon style={{ color: '#8AC5F3' }} />
          <Typography variant='subtitle3' mt={1}>Click to upload <span style={{ fontWeight: 400 }}>or drag and drop</span></Typography>
        </ButtonBase> :
        <DropContainer mb='30px' onDrop={handleDrop}>
          <Root>
            <Header pt={1} pb={2}>
              <FolderIcon /><Typography variant='caption' noWrap sx={{ flexGrow: 1 }}>Files  {total}  ({formatBytes(filesSize + foldersSize)})</Typography>
              <TextButton onClick={() => setDrawerOpen(true)} disabled={uploading}>
                + Attach Files
              </TextButton>
            </Header>
            <Grid>
              {_map(folders, f =>
                <Folder
                  id={f.id}
                  key={f.id}
                  name={f.name}
                  size={f.documents_byte_size}
                  count={f.documents_count}
                  onDelete={() => onChange({ reason: 'removeOption', type: 'folder', id: f.id, level: 0 })}
                  hideMenu
                />
              )}
              {_map(files, f =>
                <File
                  id={f.id}
                  key={f.id}
                  title={f.name}
                  type={f.file.content_type}
                  showLastActivity
                  date={f.created_at}
                  preview={getRepresentation(f.file.representations, representationVariants.TINY)}
                  onDelete={() => onChange({ reason: 'removeOption', type: 'file', id: f.id, level: 0 })}
                />
              )}
              {loading && 
                <File
                  id={fileName}
                  key={fileName}
                  title={fileName}
                  type={fileType}
                  percent={percentProgress}
                  loading={loading}
                />
              }
            </Grid>
          </Root>
        </DropContainer>
      }
    </>
  );
};

BuilderPublicFiles.propTypes = {
  dealspaceFolderId: PropTypes.string.isRequired,
  uploading: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  files: PropTypes.array.isRequired,
  folders: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BuilderPublicFiles {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);