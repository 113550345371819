import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Root = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '30px',
  padding: '28px 24px 24px'
});

export const avatarSx = {
  flexShrink: 0,
  width: 52,
  height: 52,
  borderRadius: '50%',
  fontSize: '24px'
};