import { memo } from 'react';
import PropTypes from 'prop-types';
// Local files
import { Root, Icon, Name, Size } from './FileItem.styled';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

/**
 * File item card
 * @param {string} icon
 * @param {string} name
 * @param {string} size
 * @returns {node} FileItem
 */

const FileItem = ({ icon, name, size }) => {
  return (
    <Root>
      <Icon alt={name} src={icon} />
      <Name>{name}</Name>
      <Size>{size}</Size>
    </Root>
  );
};

FileItem.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <FileItem {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);