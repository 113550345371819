import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Root = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%'
});

export const DealspaceInfo = styled(Box)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  gap: '22px',
  marginBottom: '8px'
}));

export const Image = styled(Box)({
  width: 54,
  height: 34,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center'
});