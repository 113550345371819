import { memo } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import Profile from './Profile/Profile';
import { StyledNavAction } from '../AppNavigation.styled';
import { ReactComponent as LoginIcon } from 'assets/icons/appnav/login.svg';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';

const Action = ({ mobile = false }) => {
  const navigate = useNavigate();
  const { setAuthData, /*openAccessBetaDialog*/ } = useApp();
  const { /*hasRecipientToken,*/ authorizedAsUser, authorizedAsRecipient, recipientEmail } = useCustomSelector(state => ({
    // hasRecipientToken: !!state.app.authDialog.invitationToken,
    authorizedAsUser: state.sessions.session.userableType === 'user',
    authorizedAsRecipient: state.sessions.session.userableType === 'recipient',
    recipientEmail: state.profile.recipient.email
  }));

  const handleAuthClick = mode => {
    // if (hasRecipientToken) {
    //   openAccessBetaDialog();
    // } else {
      if (authorizedAsRecipient) {
        setAuthData({ mode, open: true });
      } else {
        setAuthData({ mode }).then(() => navigate('/sign_in'));
      }
    // }
  };

  if (!mobile) {
    if (authorizedAsUser) return <Profile />;
    if (authorizedAsRecipient) return (
      <Typography variant='caption' sx={{ color: 'common.white', textAlign: 'right' }}>
        <b>Get all your deals in one place.</b>
        &nbsp;
        <Link
          color='inherit'
          sx={{ cursor: 'pointer' }}
          onClick={() => handleAuthClick('signUp')}
        >
          Claim free deal feed for {recipientEmail}
        </Link>
        &nbsp;
        or
        &nbsp;
        <Link
          color='inherit'
          sx={{ cursor: 'pointer' }}
          onClick={() => handleAuthClick('signIn')}
        >
          login
        </Link>
      </Typography>
    );
    return <Button sx={{ color: 'common.white', textDecoration: 'underline' }} onClick={() => handleAuthClick('signIn')}>log in</Button>;
  }
  if (authorizedAsUser) return <Profile mobile />;
  return (
    <StyledNavAction
      component={Button}
      onClick={() => handleAuthClick('signIn')}
      label='log in'
      sx={{ textDecoration: 'underline' }}
      value={3}
      icon={<LoginIcon sx={{ height: '30px' }} />}
    />
  );
};

Action.propTypes = {
  mobile: PropTypes.bool
};

const ErrorFallback = ({ error }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Action {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);