import styled from '@mui/material/styles/styled';
import FormControlLabel from '@mui/material/FormControlLabel';

export const StyledControl = styled(FormControlLabel)(({ theme }) => ({
  width: '100%',
  color: 'rgba(0,0,0,.4)',
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '6px',
  padding: '20px',
  marginLeft: 0,
  marginRight: 0,
  '&:has(.Mui-checked)': {
    color: 'rgba(0,0,0,.8)',
    backgroundColor: '#E9EFFD',
    borderColor: '#2196F3',
  },
  '& .MuiFormControlLabel-label': {
    flexGrow: 1,
  }
}));

export const StyledLabel = styled('span')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '6px',
  '& > svg': {
    flexShrink: 0,
    height: 28,
    width: 'auto'
  }
});

export const LabelContent = styled('span')({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px'
});

export const Title = styled('span')(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 500,
  '& > span': {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 400,
    fontStyle: 'italic',
    marginLeft: '.4rem'
  }
}));

export const Description = styled('span')(({ theme }) => ({
  fontSize: theme.typography.pxToRem(10),
  '& > span': {
    fontStyle: 'italic',
  }
}));
