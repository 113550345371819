import { memo } from 'react';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

const ListSkeleton = () => {
  return (
    <Paper sx={{ p: 4 }}>
      <Skeleton animation='wave' />
      <Skeleton animation='wave' />
      <Skeleton animation='wave' />
      <Skeleton animation='wave' />
      <Skeleton animation='wave' />
    </Paper>
  );
};

export default memo(ListSkeleton);