import { memo, useState } from 'react';
import _pick from 'lodash/pick';
// Local files
import { Root } from './NotesTabContent.styled';
import Tiny from 'components/Tiny/Tiny';
import { needToUpdateField } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useDealspaces from 'hooks/useDealspaces';

const NotesTabContent = () => {
  const { setError } = useError();
  const { updateDealspace } = useDealspaces();
  const { id, notes } = useCustomSelector(state => _pick(state.dealspaces.dealspace, ['id', 'notes']));
  const [value, setValue] = useState(() => notes ?? '');

  const handleChange = ({ v }) => {
    setValue(v);

    if (needToUpdateField(notes, v)) {
      updateDealspace({ id, notes: v }).catch(e => setError(e));
    }
  };

  return (
    <Root>
      <Tiny
        id='dealspaceNotes'
        value={value}
        onChange={handleChange}
        plugins={['lists', 'link', 'autolink', 'emoticons', 'charmap', 'formatpainter', 'table', 'advtable', 'image', 'media', 'autoresize', 'quickbars', 'powerpaste']}
        toolbar={'bold italic underline forecolor fontfamily fontsize indent align hr bullist numlist link h2 h3 emoticons image media table formatpainter blockquote'}
        otherInitProps={{
          width: '100%',
          menubar: false,
          placeholder: 'Type any dealspace notes here',
          skin: 'borderless',
          paste_webkit_styles: 'all',
          elementpath: false,
          branding: false,
          object_resizing: true,
          image_title: true,
          file_picker_types: 'image media',
        }}
      />
    </Root>
  );
}

export default memo(NotesTabContent);