// Local files
import { Root, Line, Chip } from './NewDivider.styled';

const NewDivider= ({ divider }) => {

  return (
    <Root ref={divider}>
      <Line />
      <Chip><span>New</span></Chip>
    </Root>
  );
};

export default NewDivider;