import { memo, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MaterialLink from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Scrollbars from 'react-custom-scrollbars-2';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';
// Local files
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { Head, HeadControls, StyledBreadcrumbs } from './Dealspace.styled';
import Team from 'components/Common/DataDisplay/Team/Team';
import ArrowBackIcon from 'components/Common/Icons/ArrowBackIcon';
import SettingsIcon from 'components/Common/Icons/SettingsIcon';
import DealspaceSettingsDialog from 'components/Dialogs/DealspaceSettings/DealspaceSettings';
import { DELETE_DEALSPACE } from 'helpers/confirmations';
import { chatLevels } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useConversations from 'hooks/useConversations';
import useDealspaces from 'hooks/useDealspaces';
import useError from 'hooks/useError';
import useViews from 'hooks/useViews';

const Dealspaces = () => {
  const previousLocation = useLocation();
  const navigate = useNavigate();
  const { dealspace_id } = useParams();
  const { openDeleteDialog } = useApp();
  const { setLocalConversation } = useConversations();
  const { getDealspace, clearLocalActiveDealspace } = useDealspaces();
  const { setError } = useError();
  const { viewDealspace, updateView } = useViews();
  const { groupId, groupName, dealspaceName, members, conversation, /*unreadOfferConversationsCount, unreadConversation*/ } = useCustomSelector(state => ({
    groupId: state.dealspaces.dealspace.group.id,
    groupName: state.dealspaces.dealspace.group.name,
    dealspaceName: state.dealspaces.dealspace.name,
    members: state.members.dealspaceList.data,
    conversation: state.dealspaces.dealspace.conversation,
    unreadOfferConversationsCount: state.dealspaces.dealspace.unread_offer_conversations_count,
    unreadConversation: state.dealspaces.dealspace.unread_conversation,
  }));
  const [dealspaceDialogOpen, setDealspaceDialogOpen] = useState(false);
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);
  const [fetching, setFetching] = useState(true);

  const handleGoBack = () => navigate(`/groups/${groupId}/dealspaces`);
  const handleEditClick = () => {
    setMoreAnchorEl(null);
    setDealspaceDialogOpen(true);
  };
  const handleDeleteClick = () => {
    setMoreAnchorEl(null);
    openDeleteDialog({ type: DELETE_DEALSPACE, id: dealspace_id, navigatePath: `/groups/${groupId}/dealspaces` });
  };
  const handleOpenConversation = () => {
    setLocalConversation(conversation)
    .then(() => navigate(`/chats/${chatLevels.DEALSPACE}/${dealspace_id}`, { state: { previousLocation } }))
  };
  const handleDealspaceDialogClose = () => setDealspaceDialogOpen(false);

  useEffect(() => {
    getDealspace(dealspace_id)
    .then(({ payload: { data: { dealspace: { id, own_view } } } }) => {
      if (!own_view) {
        viewDealspace(id).catch(e => setError(e));
      } else {
        updateView(own_view.id).catch(e => setError(e))
      }
    })
    .catch(e => setError(e))
    .finally(() => setFetching(false));

    return clearLocalActiveDealspace;
  }, [dealspace_id, getDealspace, clearLocalActiveDealspace, setError, viewDealspace, updateView]);

  return (
    <Scrollbars autoHide autoHideTimeout={1000}>
      <DealspaceSettingsDialog
        open={dealspaceDialogOpen}
        onClose={handleDealspaceDialogClose}
      />
      <Box
        display='flex'
        flexDirection='column'
        sx={{ backgroundColor: 'white', minHeight: '100%', padding: { xs: '16px 12px', md: '32px 48px' }}}
      >
        <Head>
          <StyledBreadcrumbs>
            {fetching ? <Skeleton animation='wave' variant='text' width='100%' /> :
              <Box
                component={MaterialLink}
                underline="hover"
                onClick={handleGoBack}
                sx={{ display: 'flex', alignItems: 'center', gap: '6px', cursor: 'pointer' }}
              >
                <ArrowBackIcon sx={{ color: 'text.primary' }} />
                <Typography variant='body1' color='textPrimary' sx={{ fontWeight: 700 }}>{groupName}</Typography>
              </Box>
            }
            {fetching ? <Skeleton animation='wave' variant='text' width='100%' /> :
              <Typography variant='body1' noWrap sx={{ color: '#979797', fontWeight: 500 }}>
                {dealspaceName}
              </Typography>
            }
          </StyledBreadcrumbs>
          <HeadControls>
            <Team members={members} />
            <Button variant='blank' color='neutral' onClick={handleOpenConversation}><ChatIcon style={{ width: 20, height: 20 }} /></Button>
            <Box sx={{ flexGrow: 1, display: { md: 'none' }}} />
            <IconButton size='small' onClick={({ currentTarget }) => setMoreAnchorEl(currentTarget)}>
              <SettingsIcon />
            </IconButton>
            <Menu keepMounted open={!!moreAnchorEl} anchorEl={moreAnchorEl} onClose={() => setMoreAnchorEl(null)}>
              <MenuItem onClick={handleEditClick}>
                <Typography variant='caption' component='div' noWrap sx={{ fontWeight: 600 }}>Edit</Typography>
              </MenuItem>
              <MenuItem onClick={handleDeleteClick}>
                <Typography variant='caption' component='div' color='red' noWrap sx={{ fontWeight: 600 }}>Delete</Typography>
              </MenuItem>
            </Menu>
          </HeadControls>
        </Head>
        <Outlet context={{ fetching }} />
      </Box>
    </Scrollbars>
  );
};

export default memo(Dealspaces);