import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import MuiContainer from '@mui/material/Container';
import Box from '@mui/material/Box';

export const Root = styled(MuiContainer)(({ theme }) => ({
  maxWidth: '1280px !important',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '40px 18px',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    padding: '30px 56px 80px 30px',
  }
}));

export const Container = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  overflow: 'hidden'
});

export const Grid = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px 8px',
  '& > *': {
    flexGrow: 1,
  },
  [theme.breakpoints.up('md')]: {
    flexWrap: 'nowrap',
    '& > *': {
      width: '100%',
    },
  }
}));

export const PaperContent = styled(Box)(({ theme }) => ({
  width: 'minmax(100%, 290px)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px 8px',
  [theme.breakpoints.up('md')]: {
  }
}));

export const Text = styled(Typography)({
  fontSize: '0.75rem',
  color: 'rgba(0,0,0,.5)',
  textAlign: 'center',
});