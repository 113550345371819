import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  checkGroupMemberRoutine,
  getGroupMembersListRoutine,
  getGroupMembersRoutine,
  getDealspaceMembersListRoutine,
  getDealspaceMembersRoutine,
  getMemberRoutine,
  createMemberRoutine,
  updateMemberRoutine,
  deleteMemberRoutine,
  clearLocalGroupMembersListRoutine,
  clearLocalGroupMembersRoutine,
  clearLocalDealspaceMembersListRoutine,
  clearLocalDealspaceMembersRoutine,
  clearLocalActiveMemberRoutine
} from 'actions';

const attributes = [
  'id',
  { user: ['id', 'first_name', 'last_name', 'email', 'mobile_phone', 'office_phone', 'office_phone_extension', { photo: ['representations'] }] },
  { invitation: ['id', 'email', { user: ['id', 'email'] }] },
  { dealspaces: ['id', 'name'] },
  'status',
  'role',
  'permission',
  'updated_at'
];
const listAttributes = ['id', { user: ['id', { photo: ['representations'] }, 'first_name', 'last_name'] }, 'permission'];

export const checkGroupMember = getThunkActionCreator(
  checkGroupMemberRoutine,
  async ({ email = null, user_id = null, group_id }) => {
    const attributes = ['id', { dealspaces: ['id'] }, { invitation: ['id', 'email', { user: ['id', 'email'] }] }];
    const params = { limit: 1, offset: 0, attributes, ...user_id && { user_id }, ...email && { query: email } };

    return await axios.get(`/groups/${group_id}/members`, { params });
  }
);
export const getGroupMembersList = getThunkActionCreator(
  getGroupMembersListRoutine,
  async ({ group_id, offset = 0, query = null }) => {
    const params = { limit: 25, offset, orders: { updated_at: 'desc' }, attributes: listAttributes, ...query && { query } };

    return await axios.get(`/groups/${group_id}/members`, { params });
  }
);
export const getGroupMembers = getThunkActionCreator(
  getGroupMembersRoutine,
  async ({ group_id, limit = 25, offset = 0, orders = { updated_at: 'desc' }, query = null }) => {
    const params = { limit, offset, orders, attributes, ...query && { query } };

    return await axios.get(`/groups/${group_id}/members`, { params });
  }
);
export const getDealspaceMembersList = getThunkActionCreator(
  getDealspaceMembersListRoutine,
  async ({ dealspace_id, offset = 0 }) => {
    const attributes = [
      'id',
      { user: ['id', 'first_name', 'last_name', { photo: ['representations'] }] },
      { invitation: ['id', 'email', { user: ['id', 'email', 'first_name', 'last_name', { photo: ['representations'] }] }] },
      { dealspaces: ['id'] },
      'status',
      'role',
      'permission'
    ];
    const params = { limit: 25, offset, orders: { updated_at: 'desc' }, attributes };

    return await axios.get(`/dealspaces/${dealspace_id}/members`, { params });
  }
);
export const getDealspaceMembers = getThunkActionCreator(
  getDealspaceMembersRoutine,
  async ({ dealspace_id, limit = 25, offset = 0, orders = { updated_at: 'desc' }, status = null }) => {
    const params = { limit, offset, orders, attributes, ...status && { status } };

    return await axios.get(`/dealspaces/${dealspace_id}/members`, { params });
  }
);
export const getMember = getThunkActionCreator(
  getMemberRoutine,
  async id => {
    const params = { attributes };

    return await axios.get(`/members/${id}`, { params });
  }
);
export const createMember = getThunkActionCreator(
  createMemberRoutine,
  async ({ group_id, member }) => {
    const attributes = [
      'id',
      { user: ['id', 'first_name', 'last_name', { photo: ['representations'] }] },
      { invitation: ['id', 'email', { user: ['id', 'email', 'first_name', 'last_name', { photo: ['representations'] }] }] },
      { dealspaces: ['id'] },
      'status',
      'role',
      'permission'
    ];
    const data = { member, attributes };

    return await axios.post(`/groups/${group_id}/members`, data);
  }
);
export const updateMember = getThunkActionCreator(
  updateMemberRoutine,
  async ({ id, ...fields }) => {
    const data = { member: fields };
    const response = await axios.patch(`/members/${id}`, data);

    return { response, member: { id, ...fields } };
  }
);
export const deleteMember = getThunkActionCreator(
  deleteMemberRoutine,
  async id => {
    const response = await axios.delete(`/members/${id}`);

    return { response, id };
  }
);
export const clearLocalGroupMembersList = getThunkActionCreator(clearLocalGroupMembersListRoutine, async () => {});
export const clearLocalGroupMembers = getThunkActionCreator(clearLocalGroupMembersRoutine, async () => {});
export const clearLocalDealspaceMembersList = getThunkActionCreator(clearLocalDealspaceMembersListRoutine, async () => {});
export const clearLocalDealspaceMembers = getThunkActionCreator(clearLocalDealspaceMembersRoutine, async () => {});
export const clearLocalActiveMember = getThunkActionCreator(clearLocalActiveMemberRoutine, async () => {});