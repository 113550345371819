import {
  getChartActivitiesRoutine,
  getTableActivitiesRoutine,
  getActivityRoutine,
  clearChartActivitiesRoutine,
  clearTableActivitiesRoutine
} from 'actions';

export const initialState = {
  chart: {
    data: {
      total_recipients_count: 0,
      share_recipients_count: 0,
      visit_recipients_count: 0,
      unsubscribed_recipients_count: 0,
      email_delivered_recipients_count: 0,
      email_bounced_recipients_count: 0,
      email_opened_recipients_count: 0,
      visited_share_recipients_count: 0,
      visited_visit_recipients_count: 0,
      visited_recipients_count: 0,
      unlocked_recipients_count: 0,
      replied_recipients_count: 0,
      tracked_recipients_count: 0,
      saved_recipients_count: 0,
      offered_recipients_count: 0,
      over_time_dates: [],
      over_time_opens: [],
      over_time_visits: [],
      over_time_unlocks: []
    },
    fetching: true
  },
  table: {
    data: [],
    pagination: { limit: 25, offset: 0, count: 0, total_count: -1 },
    fetching: true
  },
  activity: {
    id: ''
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getChartActivitiesRoutine.REQUEST: {
      const chart = { ...state.chart, fetching: true };

      return { ...state, chart };
    }
    case getChartActivitiesRoutine.SUCCESS: {
      const { data: { statistics } } = action.payload;
      const chart = { data: statistics, fetching: false };

      return { ...state, chart };
    }
    case getChartActivitiesRoutine.FAILURE: {
      const chart = { ...state.chart, fetching: false };

      return { ...state, chart };
    }
    case getTableActivitiesRoutine.REQUEST: {
      const table = { ...state.table, fetching: true };

      return { ...state, table };
    }
    case getTableActivitiesRoutine.SUCCESS: {
      const { data: { activities, pagination } } = action.payload;
      const table = { data: activities, pagination, fetching: false };

      return { ...state, table };
    }
    case getTableActivitiesRoutine.FAILURE: {
      const table = { ...state.table, fetching: false };

      return { ...state, table };
    }
    case getActivityRoutine.SUCCESS: {
      const { data: { activity } } = action.payload;

      return { ...state, activity };
    }
    case clearChartActivitiesRoutine.SUCCESS: {
      return { ...state, chart: initialState.chart };
    }
    case clearTableActivitiesRoutine.SUCCESS: {
      return { ...state, table: initialState.table };
    }
    default: {
      return state;
    }
  };
};

export default reducer;