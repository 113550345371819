import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getShares as getSharesAction,
  getShare as getShareAction,
  createShare as createShareAction,
  updateShare as updateShareAction,
  clearLocalShares as clearLocalSharesAction
} from 'actions/shares';

const useShares = () => {
  const dispatch = useDispatch();
  const getShares = useCallback(({ offer_id, limit, offset, orders, query }) => dispatch(getSharesAction({ offer_id, limit, offset, orders, query })), [dispatch]);
  const getShare = useCallback(id => dispatch(getShareAction(id)), [dispatch]);
  const createShare = useCallback(({ offer_id, share }) => dispatch(createShareAction({ offer_id, share })), [dispatch]);
  const updateShare = useCallback(share => dispatch(updateShareAction(share)), [dispatch]);
  const clearLocalShares = useCallback(() => dispatch(clearLocalSharesAction()), [dispatch]);

  return {
    getShares,
    getShare,
    createShare,
    updateShare,
    clearLocalShares
  };
};

export default useShares;