import { memo } from 'react';
import ReactMapboxGl from "react-mapbox-gl";
import { mapboxAccessToken } from 'apis';


const Map = ReactMapboxGl({
  accessToken: mapboxAccessToken
});

const ExtensionMap = () => {
  return (
    <Map
      zoom={[2.5]}
      pitch={[50]}
      style="mapbox://styles/btannous/clt6neq9a004501pc9ajpbbtg" //eslint-disable-line
    />
  );
}

export default memo(ExtensionMap);
