import { memo } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
// Local files
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';

const ProtectedUserRoute = ({ children }) => {
  const { setAuthData } = useApp();
  const { pathname } = useLocation();
  const { localSession, userFetched } = useCustomSelector(state => ({
    localSession: state.sessions.session,
    userFetched: !!state.profile.user.id
  }));

  if (!localSession.checked) return null;
  if (localSession.accessToken && !userFetched) return null;
  if (!localSession.accessToken || localSession.userableType !== 'user') {
    setAuthData({ redirectUrl: pathname });

    return <Navigate to='/sign_in' replace />
  };
  return children;
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <ProtectedUserRoute {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);