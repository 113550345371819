import styled from '@mui/material/styles/styled';

const Root = styled('div')({
  height: 100,
  position: 'relative',
  padding: '10px 0'
});

const Line = styled('div')({
  height: 1,
  backgroundColor: '#D0F3DE',
});

const Chip = styled('div')({
  position: 'absolute',
  top: 0, right: 'calc(50% - 25px)',
  height: 20,
  width: 50,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '100%',
  color: '#00B84A',
  backgroundColor: '#D0F3DE',
  borderRadius: '25px',
});

export { Root, Line, Chip };