import { memo } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const CardSkeleton = () => {
  return (
    <Box sx={{ minWidth: 230, width: '100%', minHeight: 212 }}>
      <Skeleton variant="rectangular" sx={{ width: '100%', height: 180 }} />
      <Box sx={{ pt: 0.5 }}>
        <Skeleton />
        <Skeleton width="60%" />
      </Box>
    </Box>
  );
};

export default memo(CardSkeleton);