import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import _pick from 'lodash/pick';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import ArrowDropDownIcon from 'components/Common/Icons/ArrowDropDownIcon';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import ProfileMenu from 'components/Users/ProfileMenu/ProfileMenu';
import { StyledNavAction, StyledAccountButton } from '../../AppNavigation.styled';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';

const Profile = ({ mobile = false }) => {
  const { isRecipient, email, first_name, last_name, photo } = useCustomSelector(state => ({
    isRecipient: !!state.profile.recipient.id,
    email: state.profile.recipient.email,
    ..._pick(state.profile.user, ['first_name', 'last_name', 'photo'])
  }));
  const [anchorEl, setAnchorEl] = useState(null);
  const username = isRecipient ? email : `${first_name} ${last_name}`;

  const handleClick = ({ currentTarget }) => setAnchorEl(currentTarget);

  if (!mobile) return (
    <>
      <ProfileMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      />
      <StyledAccountButton onClick={handleClick}>
        <BaseAvatar
          firstName={isRecipient ? email : first_name}
          lastName={last_name}
          src={getRepresentation(photo?.representations, representationVariants.TINY) ?? photo?.representations[0]?.url}
        />
        <Typography variant='caption' p='0 4px 0 10px'>{username}</Typography>
        <ArrowDropDownIcon />
      </StyledAccountButton>
    </>
  );
  return (
    <>
      <ProfileMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      />
      <StyledNavAction
        label='Profile'
        value={3}
        icon={
          <BaseAvatar
            sx={{ height: 30, width: 30 }}
            firstName={!!email ? email : first_name}
            lastName={last_name}
            src={getRepresentation(photo?.representations, representationVariants.TINY) ?? photo?.representations[0]?.url}
          />
        }
        onClick={handleClick}
      />
    </>
  );
};

Profile.propTypes = {
  mobile: PropTypes.bool
};

const ErrorFallback = ({ error }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Profile {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);