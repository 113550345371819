import { memo, useState } from 'react';
import Link from '@mui/material/Link';
import { useParams } from 'react-router-dom';
// Local files
import { FooterText, Group, Root, StyledInput, SubmitButton, Title, Subtitle } from './ShareForm.styled';
import { ReactComponent as LogoIcon } from 'assets/icons/qwincy.svg';
import { checkValidEmail } from 'helpers';
import StyledPhoneField from 'components/Common/Inputs/StyledPhoneField/StyledPhoneField';
import useError from 'hooks/useError';
import useOffers from 'hooks/useOffers';

const ShareForm = () => {
  const { offer_id } = useParams();
  const { setError } = useError();
  const { submitOfferShareForm } = useOffers();
  const [mode, setMode] = useState('pending');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobilePhone, setMobilePhone] = useState({ value: '', formattedValue: '', error: '' });
  const [email, setEmail] = useState('');
  const [processing, setProcessing] = useState(false);
  const disabled = firstName.length < 2 || lastName.length < 2 || !mobilePhone.value || !!mobilePhone.error || !checkValidEmail(email) || processing;
  const emailError = !!email && !checkValidEmail(email);

  const handleSubmit = () => {
    if (mode === 'pending') {
      setProcessing(true);
      submitOfferShareForm({ id: offer_id, first_name: firstName, last_name: lastName, email, mobile_phone: mobilePhone.value })
      .then(() => setMode('success'))
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    } else {
      window.open('/sign_in', '_blank').focus();
    }
  };

  return (
    <Root>
      <Title>{mode === 'pending' ? 'Get full details on this listing' : 'Success! Check your email.'}</Title>
      {mode === 'pending' ?
        <Group>
          <StyledInput
            placeholder='First name'
            value={firstName}
            onChange={({ target: { value } }) => setFirstName(value)}
          />
          <StyledInput
            placeholder='Last name'
            value={lastName}
            onChange={({ target: { value } }) => setLastName(value)}
          />
          <StyledPhoneField
            value={mobilePhone.formattedValue}
            onChange={v => setMobilePhone(v)}
            error={!!mobilePhone.error}
          />
          <StyledInput
            placeholder='Email'
            value={email}
            onChange={({ target: { value } }) => setEmail(value)}
            error={emailError}
          />
        </Group> :
        <>
          <Subtitle>
            Please check spam if you don’t receive email in the next few minutes.
          </Subtitle>
          <Subtitle sx={{ mt: '17px', mb: '21px' }}>
            or
          </Subtitle>
        </>
      }
      <SubmitButton
        disabled={disabled}
        startIcon={<LogoIcon />}
        onClick={handleSubmit}
      >
        {mode === 'pending' ? 'Share with me' : 'Sign In'}
      </SubmitButton>
      <FooterText>
        {mode === 'pending' ? 'Powered by' : 'No account?'} <Link component='a' href={mode === 'pending' ? 'https://app.qwincy.com/' : 'https://app.qwincy.com/sign_up'} target='_blank' rel='noreferrer'>{mode === 'pending' ? 'Qwincy Deal Sharing' : 'Sign up free'}</Link>
      </FooterText>
    </Root>
  );
};

export default memo(ShareForm);