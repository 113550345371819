import { memo, forwardRef } from 'react';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import _map from 'lodash/map';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { FileCardSkeleton, Grid, Header, /*MoreButton,*/ StyledButton, Title } from './Files.styled';
import LockedFilledIcon from 'components/Common/Icons/LockedFilledIcon';
import File from 'components/Documents/Card/Card';
import Folder from 'components/Folders/Card/Card';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { representationVariants } from 'helpers/constants';
import { getRepresentation, formatBytes } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';

const Files = forwardRef((props, ref) => {
  const { publicFiles, publicFolders, privateLevel1Files, privateLevel2Files, privateLevel1Folders, privateLevel2Folders, total, preview = false, offerApproved = false, isMyShared = false, showUnlock, onUnlock } = props;
  const { publicFilesFetching, publicFoldersFetching, privateLevel1FilesFetching, privateLevel2FilesFetching, privateLevel1FoldersFetching, privateLevel2FoldersFetching } = useCustomSelector(state => ({
    publicFilesFetching: state.documents.offer.public.fetching && !!state.profile.user.id,
    publicFoldersFetching: state.folders.offer.public.fetching && !!state.profile.user.id,
    privateLevel1FilesFetching: state.documents.offer.level1.fetching && !!state.profile.user.id,
    privateLevel2FilesFetching: state.documents.offer.level2.fetching && !!state.profile.user.id,
    privateLevel1FoldersFetching: state.folders.offer.level1.fetching && !!state.profile.user.id,
    privateLevel2FoldersFetching: state.folders.offer.level2.fetching && !!state.profile.user.id
  }));

  if (!total) return null;
  return (
    <Container ref={ref} maxWidth={false} sx={{ maxWidth: '624px', px: '12px !important' }}>
      <Header>
        <Title variant='caption'>
          Files: <span>{total}</span>
        </Title>
        {showUnlock &&
          <StyledButton onClick={onUnlock}>
            <LockedFilledIcon />Unlock
          </StyledButton>
        }
      </Header>
      <Grid>
        {!preview && publicFoldersFetching && <FileCardSkeleton />}
        {_map(publicFolders, f =>
          <Folder
            key={f.id}
            id={f.id}
            name={f.name}
            size={f.documents_byte_size}
            count={f.documents_count}
            hideMenu
            downloadable
          />
        )}
        {!preview && privateLevel1FoldersFetching && <FileCardSkeleton />}
        {_map(privateLevel1Folders, f =>
          <Folder
            key={f.id}
            id={f.id}
            name={f.name}
            size={f.documents_byte_size}
            count={f.documents_count}
            blurred={!isMyShared && showUnlock && f.locked}
            hideMenu
            downloadable
            onClick={onUnlock}
          />
        )}
        {!preview && privateLevel2FoldersFetching && <FileCardSkeleton />}
        {_map(privateLevel2Folders, f =>
          <Folder
            key={f.id}
            id={f.id}
            name={f.name}
            size={f.documents_byte_size}
            count={f.documents_count}
            blurred={!isMyShared && showUnlock && f.locked}
            pending={!isMyShared && !offerApproved && f.approval_required}
            hideMenu
            downloadable
            onClick={onUnlock}
          />
        )}
        {!preview && publicFilesFetching && <FileCardSkeleton />}
        {_map(publicFiles, f =>
          <File
            key={f.id}
            id={f.id}
            showFileSize
            type={f.file?.content_type}
            preview={getRepresentation(f.file?.representations, representationVariants.TINY)}
            hideMenu
            title={f.file?.filename}
            size={formatBytes(f.file?.byte_size)}
          />
        )}
        {!preview && privateLevel1FilesFetching && <FileCardSkeleton />}
        {_map(privateLevel1Files, f =>
          <File
            key={f.id}
            id={f.id}
            showFileSize
            type={f.file?.content_type}
            preview={getRepresentation(f.file?.representations, representationVariants.TINY)}
            hideMenu
            title={f.file?.filename}
            size={formatBytes(f.file?.byte_size)}
            blurred={!isMyShared && showUnlock && f?.locked}
            onClick={onUnlock}
          />
        )}
        {!preview && privateLevel2FilesFetching && <FileCardSkeleton />}
        {_map(privateLevel2Files, f =>
          <File
            key={f.id}
            id={f.id}
            showFileSize
            type={f.file?.content_type}
            preview={getRepresentation(f.file?.representations, representationVariants.TINY)}
            hideMenu
            title={f.file?.filename}
            size={formatBytes(f.file?.byte_size)}
            blurred={!isMyShared && showUnlock && f?.locked}
            pending={!isMyShared && !offerApproved && f.approval_required}
            onClick={onUnlock}
          />
        )}
        {/** TODO Пагинация **/}
        {/*showMore &&
          <MoreButton onClick={onLoadMore}>
            Show More
          </MoreButton>
        */}
      </Grid>
    </Container>
  );
});

Files.propTypes = {
  publicFiles: PropTypes.array.isRequired,
  privateLevel1Files: PropTypes.array.isRequired,
  privateLevel2Files: PropTypes.array.isRequired,
  publicFolders: PropTypes.array.isRequired,
  privateLevel1Folders: PropTypes.array.isRequired,
  privateLevel2Folders: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  preview: PropTypes.bool,
  offerApproved: PropTypes.bool,
  isMyShared: PropTypes.bool,
  showUnlock: PropTypes.bool.isRequired
};

const WrappedComponent = forwardRef((props, ref) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Files {...props} ref={ref} />
    </ErrorBoundary>
  );
});
export const Fallback = () => {
  return (
    <Skeleton
      variant='rectangular'
      animation='wave'
      width={200}
      height={50}
    />
  );
};

export default memo(WrappedComponent);