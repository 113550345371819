import { memo, useCallback, useEffect, useRef, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _includes from 'lodash/includes';
import _orderBy from 'lodash/orderBy';
import _replace from 'lodash/replace';
import { NavLink, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import { Content, Header, TitleContainer, Breadcrumbs, Link, Root, Title, CloseButton, MainContainer, MainActions, StyledTabs, StyledTab, TabsContainer } from './ActivityLog.styled';
import { ReactComponent as MenuIcon } from 'assets/icons/menu-vert.svg';
import LockedFilledIcon from 'components/Common/Icons/LockedFilledIcon';
import CloseIcon from 'components/Common/Icons/CloseIcon';
import OfferingStatus from 'components/Dialogs/OfferingStatus/OfferingStatus';
import ShareForm from 'components/Dialogs/ShareForm/ShareForm';
import Analytics from 'components/Recipients/ActivityLog/Analytics/Analytics';
import Actions from 'components/Recipients/ActivityLog/Actions/Actions';
import Skeleton from 'components/Recipients/ActivityLog/Skeleton/Skeleton';
import MainInfo from 'components/Recipients/ActivityLog/MainInfo/MainInfo';
import ConfirmDraft from 'components/Dialogs/ConfirmDraft/ConfirmDraft';
import Shared from 'components/Recipients/ActivityLog/Shared/Shared';
import History from 'components/Recipients/ActivityLog/History/History';
import Scheduled from 'components/Recipients/ActivityLog/Scheduled/Scheduled';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { offerStatuses } from 'helpers/constants';
import { getShortDate } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useMembers from 'hooks/useMembers';
import useMixpanel from 'hooks/useMixpanel';
import useOffers from 'hooks/useOffers';
import useRecipients from 'hooks/useRecipients';
import useSenders from 'hooks/useSenders';
import useSuccess from 'hooks/useSuccess';
import useActivities from 'hooks/useActivities';
import useAudits from 'hooks/useAudits';

const { ACTIVE, IN_CONTRACT, ENDED, CLOSED } = offerStatuses;

const ActivityLogDrawer = () => {
  const shareRef = useRef(null);
  const previousLocation = useLocation();
  const navigate = useNavigate();
  const { offer_id } = useParams();
  const [searchParams] = useSearchParams();
  const { setError } = useError();
  const { getDealspaceMembers, clearLocalDealspaceMembers } = useMembers();
  const { track } = useMixpanel();
  const { getActivityLogOffer, exportOffer } = useOffers();
  const { updateRecipient, clearLocalActivityLogRecipients } = useRecipients();
  const { clearTableActivities } = useActivities();
  const { clearLocalAudits } = useAudits();
  const { getSenders, clearLocalSenders } = useSenders();
  const { setSuccess } = useSuccess();
  const { offer, recipients, senders, totalAmount, activityLogRecipientsQuery, sharesAmount } = useCustomSelector(state => ({
    offer: state.offers.activityLog,
    recipients: state.recipients.activityLog.data,
    senders: _orderBy(state.senders.all.data, ['position'], ['asc']),
    totalAmount: state.recipients.activityLog.pagination.total_count,
    activityLogRecipientsQuery: state.recipients.activityLog.query,
    sharesAmount: state.shares.all.pagination.total_count
  }));
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState('shared');
  const [filters, setFilters] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [shareFormDialog, setShareFormDialog] = useState({ open: false, offerId: '' });
  const [statusDialog, setStatusDialog] = useState({ open: false, status: '', expiredAt: '', offerId: '' });
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const mainInfoProps = { senders, ..._pick(offer, ['name', 'public', 'status', 'tag', 'deadline_type', 'deadline_at']) };
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const prevOpen = useRef(open);
  const showShare = offer.status !== ENDED && offer.status !== CLOSED;
  const showEmpty = offer.id && totalAmount === 0 && activityLogRecipientsQuery.length === 0;
  const sharedProps = { ref: shareRef, tab, filters };
  const analyticsProps = { tab, filters, hasCA: offer.locations_locked || !!offer.total_approval_required_documents_count || !!offer.total_locked_documents_count, market: offer.public };
  const historyProps = { tab };
  const scheduledProps = { tab };
  const showEmbedShare = offer.public && (offer.status === ACTIVE || offer.status === IN_CONTRACT);

  const handleEditClick = () => {
    if (!offer.draft_offer) {
      setConfirmOpen(true);
    } else {
      navigate(`/builder/${offer.id}`, { state: { previousLocation } });
    }
  };
  const handleConfirmClose = confirmed => {
    setConfirmOpen(false);
    
    if (confirmed) {
      navigate(`/builder/${offer.id}`, { state: { previousLocation } });
    }
  };
  const handleTabChange = (_, newValue) => {
    if (!_isEmpty(filters)) setFilters([]);
    setTab(newValue);
  };
  const handleStatusClick = () => setStatusDialog({ open: true, status: offer.status, expiredAt: offer.expired_at, offerId: offer.id });
  const handleStatusClose = () => setStatusDialog({ open: false, status: '', expiredAt: '', offerId: '' });
  const handleExportLog = () => {
    setMenuAnchorEl(null);
    setLoading(true);
    exportOffer(offer_id)
    .then(({ payload : { data } }) => {
      const link = document.createElement('a');
      const fileName = `${offer.name} Export ${getShortDate(new Date())}.xlsx`;
      link.href = URL.createObjectURL(data);
      link.download = _replace(fileName, /\s/g, '_');
      link.click();
      URL.revokeObjectURL(link.href);
    })
    .catch(e => setError(e))
    .finally(() => setLoading(false));
  };
  const fetchSenders = useCallback(({ offer_id, offset }) => {
    getSenders({ offer_id, offset })
    .catch(e => setError(e));
  }, [getSenders, setError]);
  const fetchMembers = useCallback(({ dealspace_id, offset }) => {
    getDealspaceMembers({ dealspace_id, offset, status: 'active' })
    .catch(e => setError(e));
  }, [getDealspaceMembers, setError]);
  
  const handleFiltersChange = ({ reason, value }) => {
    if (reason === 'selectItem') {
      const foundFilter = !!_find(filters, f => f === value);
      setFilters(prev => foundFilter ? _filter(prev, p => p !== value) : [...prev, value]);
    }
    if (reason === 'removeItem') {
      setFilters(prev => _filter(prev, p => p !== value));
    }
  };
  const handleShareClick = () => shareRef.current.openSharingDialog();
  const handleShareForm = () => setShareFormDialog({ open: true, offerId: offer_id });
  const handleShareFormClose = () => setShareFormDialog({ open: false, offerId: '' });
  const handleClose = () => {
    setOpen(false);
    prevOpen.current = true;
  }
  const handleDealflowClick = () => navigate(`/deal_flow/${offer.id}`, { state: { vertical: 'myShared' } });
  /*** Recipients notify ***/
  const handleNotifyClick = () => {
    setMenuAnchorEl(null);
    shareRef.current.openNotifyDialog();
  };
  const onEntering = () => {

  };
  const onExited = () => {
    clearLocalActivityLogRecipients();
    clearLocalAudits();
    clearTableActivities();
  };

  // Для плавного открытия с анимацией
  useEffect(() => {
    setOpen(true);
  }, []);
  useEffect(() => {
    if (prevOpen.current && !open) {
      const defaultPath = !previousLocation.state || _includes(previousLocation?.state?.previousLocation?.pathname, 'builder') || previousLocation?.state?.default ? `/dealspaces/${offer.dealspace.id}/offerings` : null;
      const path = defaultPath ?? previousLocation?.state?.previousLocation?.state?.previousLocation?.pathname ?? previousLocation?.state?.previousLocation?.pathname;

      navigate(path);
    }
  }, [open, offer, previousLocation]); // eslint-disable-line
  useEffect(() => {
    setFetching(true);
    getActivityLogOffer(offer_id)
    .then(({ payload: { data } }) => {
      const recipientId = searchParams.get('recipient_id');
      const fullName = searchParams.get('full_name');

      if (recipientId) {
        const updatableRecipient = { id: recipientId, approved: true };

        updateRecipient(updatableRecipient)
        .then(() => setSuccess(`Level 2 access granted to ${fullName}`))
        .catch(e => setError(e));
      }
      track('Visit activity log', { 'offer_id': data.id });
      clearLocalSenders().then(() => fetchSenders({ offer_id: data.offer.id, offset: 0 }));
      fetchMembers({ dealspace_id: data.offer.dealspace.id, offset: 0 });
      setFetching(false);
    })
    .catch(e => setError(e));

    return () => {
      clearLocalDealspaceMembers();
    };
  }, [clearLocalSenders, clearLocalDealspaceMembers, fetchMembers, fetchSenders, getActivityLogOffer, offer_id, setError, searchParams, setSuccess, updateRecipient, track]);

  return (
    <>
      <OfferingStatus
        {...statusDialog}
        onClose={handleStatusClose}
      />
      <ShareForm
        {...shareFormDialog}
        onClose={handleShareFormClose}
      />
      <ConfirmDraft
        open={confirmOpen}
        onClose={handleConfirmClose}
      />
      <Drawer
        anchor="bottom"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { width: '100%', height: 'calc(100% - 50px)' },
          onClick: e => e.stopPropagation()
        }}
        SlideProps={{ onEntering, onExited }}
        ModalProps={{ disableEnforceFocus: true }}
      >
        <Root>
          {fetching ? <Skeleton /> : (
            <Header>
              <TitleContainer>
                <Breadcrumbs>
                  <Link
                    component={NavLink}
                    to={`/groups/${offer?.dealspace?.group?.id}/dealspaces`}
                    underline='hover'
                  >
                    {offer?.dealspace?.group.name}
                  </Link>
                  <Link
                    component={NavLink}
                    to={`/dealspaces/${offer.dealspace?.id}/offerings`}
                    underline='hover'
                  >
                    {offer?.dealspace?.name}
                  </Link>
                </Breadcrumbs>
                <Title>Manage Offering</Title>
                <CloseButton onClick={handleClose}><CloseIcon /></CloseButton>
              </TitleContainer>
              <MainContainer>
                <MainInfo
                  {...mainInfoProps}
                  onStatusClick={handleStatusClick}
                />
                <MainActions>
                  <Button
                    variant='outlined'
                    color='neutral'
                    sx={{ px: '28px' }}
                    onClick={handleDealflowClick}
                  >
                    View Offering
                  </Button>
                  {!!offer.draft_offer ?
                    <ButtonBase
                      sx={{
                        height: 30,
                        px: '25px',
                        borderRadius: 2,
                        backgroundColor: 'rgba(215, 0, 0, 0.05)',
                        color: 'rgba(215, 0, 0, 1)',
                        fontWeight: 500,
                        fontSize: 12,
                        lineHeight: 23
                      }}
                      onClick={handleEditClick}
                    >
                      Edit Pending Draft
                    </ButtonBase> :
                    <Button
                      variant='outlined'
                      color='neutral'
                      sx={{ px: '25px' }}
                      onClick={handleEditClick}
                    >
                      Edit Offering
                    </Button>
                  }
                  {showShare &&
                    <Button
                      variant='gradient'
                      sx={{ minWidth: 90 }}
                      onClick={handleShareClick}
                    >
                      {!offer.public && <LockedFilledIcon sx={{ color: 'inherit', fontSize: '16px', mr: '4px' }} />}Share
                    </Button>
                  }
                  {recipients.length !== 0 &&
                    <> 
                      <Button
                        variant='outlined'
                        color='neutral'
                        onClick={({ currentTarget }) => setMenuAnchorEl(currentTarget)}
                      >
                        <MenuIcon style={{ color: '#272B2F'}} />
                      </Button>
                      <Menu
                        anchorEl={menuAnchorEl}
                        open={!!menuAnchorEl}
                        onClose={() => setMenuAnchorEl(null)}
                        onClick={(event) => { event.stopPropagation() }} /*fix for open full view on mobile*/
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        PaperProps={{ variant: 'popup', color: 'light', sx: { mt: '2px', width: '137px' } }}
                      >
                        <MenuItem
                          sx={{ px: '24px', '&:hover': { backgroundColor: 'background.hoverDark'} }}
                          disabled={loading}
                          onClick={handleNotifyClick}
                        >
                          <Typography variant='caption'>Bulk send email</Typography>
                        </MenuItem>
                        <MenuItem
                          sx={{ px: '24px', '&:hover': { backgroundColor: 'background.hoverDark'} }}
                          disabled={loading}
                          onClick={handleExportLog}
                        >
                          <Typography variant='caption'>Export</Typography>
                        </MenuItem>
                        {showEmbedShare &&
                          <MenuItem
                            sx={{ px: '24px', '&:hover': { backgroundColor: 'background.hoverDark'} }}
                            disabled={loading}
                            onClick={handleShareForm}
                          >
                            <Typography variant='caption'>Embed share form</Typography>
                          </MenuItem>
                        }
                      </Menu>
                    </>
                  }
                </MainActions>
              </MainContainer>
              {!showEmpty &&
                <TabsContainer>
                  <StyledTabs value={tab} onChange={handleTabChange}>
                    <StyledTab
                      disableRipple
                      value='shared'
                      label={<span>Shared with <span style={{ fontWeight: 400 }}>{totalAmount > 0 ? `(${totalAmount})` : ''}</span></span>}
                    />
                    <StyledTab
                      disableRipple
                      value='activityLog'
                      label='Activity Log'
                    />
                    <StyledTab
                      disableRipple
                      value='history'
                      label='History'
                    />
                    <StyledTab
                      disableRipple
                      value='scheduled'
                      label={<span>Scheduled <span style={{ fontWeight: 400 }}>{sharesAmount > 0 ? `(${sharesAmount})` : ''}</span></span>}
                    />
                  </StyledTabs>
                  <Box flexGrow={1} />
                  <Actions
                    filters={filters}
                    tab={tab}
                    onChange={handleFiltersChange}
                    onExport={handleExportLog}
                  />
                </TabsContainer>
              }
            </Header>
          )}
          <Content>
            <Shared {...sharedProps} />
            <Analytics {...analyticsProps} />
            <History {...historyProps} />
            <Scheduled {...scheduledProps} />
          </Content>
        </Root>
      </Drawer>
    </>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ActivityLogDrawer {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);