import {
  /*** Abort Controller ***/
  setAbortControllerRoutine,
  /*** Chat ***/
  setChatsFilterRoutine,
  /*** Notifications ***/
  setNotificationsOptionsRoutine,
  /*** Attachments ***/
  setViewAttachmentRoutine,
  /*** Auth ***/
  setAuthDataRoutine, clearAuthDataRoutine,
  /*** Access Offering ***/
  openAccessOfferingDialogRoutine, closeAccessOfferingDialogRoutine,
  /*** Access Beta ***/
  openAccessBetaDialogRoutine, closeAccessBetaDialogRoutine,
  /*** Access Feed ***/
  openAccessFeedDialogRoutine, closeAccessFeedDialogRoutine,
  /*** Avatar ***/
  openAvatarDialogRoutine, closeAvatarDialogRoutine,
  /*** Invite ***/
  openInviteDialogRoutine, closeInviteDialogRoutine,
  /*** Group ***/
  openGroupDialogRoutine, closeGroupDialogRoutine,
  /*** Group Invite ***/
  openGroupInviteDialogRoutine, closeGroupInviteDialogRoutine,
  /*** Header ***/
  openHeaderDialogRoutine, closeHeaderDialogRoutine,
  /*** Lists Update ***/
  openListsUpdateDialogRoutine, closeListsUpdateDialogRoutine,
  /*** Contacts Upload ***/
  openUploadContactsDialogRoutine, closeUploadContactsDialogRoutine,
  /*** Agreement ***/
  openAgreementDialogRoutine, closeAgreementDialogRoutine,
  /*** Files ***/
  openFilesCopyDialogRoutine, closeFilesCopyDialogRoutine,
  /*** Delete ***/
  openDeleteDialogRoutine, closeDeleteDialogRoutine,
  /*** Profile edit ***/
  openEditProfileDialogRoutine, closeEditProfileDialogRoutine,
  /*** View File ***/
  openViewFileDialogRoutine, closeViewFileDialogRoutine,
  /*** Locked Content ***/
  openLockedContentDialogRoutine, closeLockedContentDialogRoutine,
  /*** Send Email Notification ***/
  openSendEmailNotificationDialogRoutine, closeSendEmailNotificationDialogRoutine,
  /*** Invited User Popover ***/
  openInvitedUserPopoverRoutine, closeInvitedUserPopoverRoutine,
  /*** Recipient Drawer ***/
  openRecipientDrawerRoutine, closeRecipientDrawerRoutine,
  /*** Create List ***/
  openCreateListDialogRoutine, closeCreateListDialogRoutine,
  /*** Create List ***/
  openPreBuilderDialogRoutine, closePreBuilderDialogRoutine
} from 'actions';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';

export const initialState = {
  abortController: null,
  chats: { filter: { dealspace_id: '', offer_id: '' } },
  contactsDrawer: { open: false, data: null },
  notifications: { drawer: { open: false } },
  authDialog: {
    open: false,
    mode: 'signIn', // mode: ['signId', 'signUp', 'forgotPassword']
    recipientToken: '',
    invitationToken: '',
    redirectUrl: '/dashboard',
    redirectAction: null,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    repeatPassword: '',
    confirmationCode: '',
    codeSended: false,
    codeValidated: false,
    linkedInCode: ''
  },
  accessOfferingDialog: { open: false },
  accessBetaDialog: { open: false },
  accessFeedDialog: { open: false },
  avatarDialog: { open: false },
  inviteDialog: { open: false },
  groupDialog: { open: false },
  groupInviteDialog: { open: false, invitation: null },
  headerDialog: { open: false },
  listsUpdateDialog: { open: false, contacts: [] },
  attachment: { file: null },
  uploadContactsDialog: { open: false },
  agreementDialog: { open: false, url: '' },
  filesCopyDialog: { open: false },
  deleteDialog: { open: false, type: null, id: null, navigatePath: null, data: null },
  editProfileDialog: { open: false },
  viewFileDialog: { open: false, id: '', file: null },
  lockedContentDialog: { open: false },
  sendEmailNotificationDialog: { open: false, email: '' },
  invitedUserPopover: { open: false },
  recipientDrawer: { open: false, recipient: null },
  createListDialog: { open: false },
  preBuilderDialog: { open: false }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setAbortControllerRoutine.SUCCESS: {
      const { abortController } = action.payload;

      return { ...state, abortController };
    }
    case setChatsFilterRoutine.SUCCESS: {
      const { filter } = action.payload;
      const updatable = {
        ...(!_isEqual(state.chats.filter, filter) && { filter })
      };

      return { ...state,
        ...(!_isEmpty(updatable) && { chats: { ...state.chats, ...updatable } }),
      };
    }
    case setNotificationsOptionsRoutine.SUCCESS: {
      const { drawer } = action.payload;
      const updatable = {
        ...(!_isEqual(state.notifications.drawer, drawer) && { drawer })
      };

      return { ...state,
        ...(!_isEmpty(updatable) && { notifications: { ...state.notifications, ...updatable } }),
      };
    }
    case setViewAttachmentRoutine.SUCCESS: {
      const { file } = action.payload;

      return { ...state, attachment: { ...state.attachment, file } };
    }
    case setAuthDataRoutine.SUCCESS: {
      const { open, mode, recipientToken, invitationToken, redirectUrl, redirectAction, firstName, lastName, email, password, repeatPassword, confirmationCode, codeSended, codeValidated, linkedInCode } = action.payload;
      const authDialog = {
        ...state.authDialog,
        ...!_isUndefined(open) && { open },
        ...!_isUndefined(mode) && { mode },
        ...!_isUndefined(recipientToken) && { recipientToken },
        ...!_isUndefined(invitationToken) && { invitationToken },
        ...!_isUndefined(redirectUrl) && { redirectUrl },
        ...!_isUndefined(redirectAction) && { redirectAction },
        ...!_isUndefined(firstName) && { firstName },
        ...!_isUndefined(lastName) && { lastName },
        ...!_isUndefined(email) && { email },
        ...!_isUndefined(password) && { password },
        ...!_isUndefined(repeatPassword) && { repeatPassword },
        ...!_isUndefined(confirmationCode) && { confirmationCode },
        ...!_isUndefined(codeSended) && { codeSended },
        ...!_isUndefined(codeValidated) && { codeValidated },
        ...!_isUndefined(linkedInCode) && { linkedInCode }
      };

      return { ...state, authDialog };
    }
    case clearAuthDataRoutine.SUCCESS: {
      return { ...state, authDialog: initialState.authDialog };
    }
    case openAccessOfferingDialogRoutine.SUCCESS: {
      return { ...state, accessOfferingDialog: { open: true } };
    }
    case closeAccessOfferingDialogRoutine.SUCCESS: {
      return { ...state, accessOfferingDialog: { open: false } };
    }
    case openAccessBetaDialogRoutine.SUCCESS: {
      return { ...state, accessBetaDialog: { open: true } };
    }
    case closeAccessBetaDialogRoutine.SUCCESS: {
      return { ...state, accessBetaDialog: { open: false } };
    }
    case openAccessFeedDialogRoutine.SUCCESS: {
      return { ...state, accessBetaDialog: { open: true } };
    }
    case closeAccessFeedDialogRoutine.SUCCESS: {
      return { ...state, accessFeedDialog: { open: false } };
    }
    case openAvatarDialogRoutine.SUCCESS: {
      return { ...state, avatarDialog: { open: true } };
    }
    case closeAvatarDialogRoutine.SUCCESS: {
      return { ...state, avatarDialog: { open: false } };
    }
    case openInviteDialogRoutine.SUCCESS: {
      return { ...state, inviteDialog: { open: true } };
    }
    case closeInviteDialogRoutine.SUCCESS: {
      return { ...state, inviteDialog: { open: false } };
    }
    case openGroupDialogRoutine.SUCCESS: {
      return { ...state, groupDialog: { open: true } };
    }
    case closeGroupDialogRoutine.SUCCESS: {
      return { ...state, groupDialog: initialState.groupDialog };
    }
    case openGroupInviteDialogRoutine.SUCCESS: {
      return { ...state, groupInviteDialog: { open: true, invitation: action.payload } };
    }
    case closeGroupInviteDialogRoutine.SUCCESS: {
      return { ...state, groupInviteDialog: initialState.groupInviteDialog };
    }
    case openHeaderDialogRoutine.SUCCESS: {
      return { ...state, headerDialog: { open: true } };
    }
    case closeHeaderDialogRoutine.SUCCESS: {
      return { ...state, headerDialog: { open: false } };
    }
    case openListsUpdateDialogRoutine.SUCCESS: {
      return { ...state, listsUpdateDialog: { open: true, contacts: action.payload } };
    }
    case closeListsUpdateDialogRoutine.SUCCESS: {
      return { ...state, listsUpdateDialog: initialState.listsUpdateDialog };
    }
    case openUploadContactsDialogRoutine.SUCCESS: {
      return { ...state, uploadContactsDialog: { open: true } };
    }
    case closeUploadContactsDialogRoutine.SUCCESS: {
      return { ...state, uploadContactsDialog: initialState.uploadContactsDialog };
    }
    case openAgreementDialogRoutine.SUCCESS: {
      const { url } = action.payload;

      return { ...state, agreementDialog: { open: true, url } };
    }
    case closeAgreementDialogRoutine.SUCCESS: {
      return { ...state, agreementDialog: initialState.agreementDialog };
    }
    case openFilesCopyDialogRoutine.SUCCESS: {
      return { ...state, filesCopyDialog: { open: true } };
    }
    case closeFilesCopyDialogRoutine.SUCCESS: {
      return { ...state, filesCopyDialog: initialState.filesCopyDialog };
    }
    case openDeleteDialogRoutine.SUCCESS: {
      const { type, id, navigatePath = null, data = null } = action.payload;
      const deleteDialog = { ...state.deleteDialog, open: true, type, id, ...navigatePath && { navigatePath }, ...data && { data } }

      return { ...state, deleteDialog };
    }
    case closeDeleteDialogRoutine.SUCCESS: {
      return { ...state, deleteDialog: initialState.deleteDialog };
    }
    case openEditProfileDialogRoutine.SUCCESS: {
      return { ...state, editProfileDialog: { open: true } };
    }
    case closeEditProfileDialogRoutine.SUCCESS: {
      return { ...state, editProfileDialog: initialState.editProfileDialog };
    }
    case openViewFileDialogRoutine.SUCCESS: {
      const { id, file } = action.payload;
      const viewFileDialog = {
        open: true,
        ...id && { id },
        ...file && { file }
      }

      return { ...state, viewFileDialog };
    }
    case closeViewFileDialogRoutine.SUCCESS: {
      return { ...state, viewFileDialog: initialState.viewFileDialog };
    }
    case openLockedContentDialogRoutine.SUCCESS: {
      return { ...state, lockedContentDialog: { open: true } };
    }
    case closeLockedContentDialogRoutine.SUCCESS: {
      return { ...state, lockedContentDialog: initialState.lockedContentDialog };
    }
    case openSendEmailNotificationDialogRoutine.SUCCESS: {
      const { email } = action.payload;

      return { ...state, sendEmailNotificationDialog: { open: true, email } };
    }
    case closeSendEmailNotificationDialogRoutine.SUCCESS: {
      return { ...state, sendEmailNotificationDialog: initialState.sendEmailNotificationDialog };
    }
    case openInvitedUserPopoverRoutine.SUCCESS: {
      return { ...state, invitedUserPopover: { open: true } };
    }
    case closeInvitedUserPopoverRoutine.SUCCESS: {
      return { ...state, invitedUserPopover: { open: false } };
    }
    case openRecipientDrawerRoutine.SUCCESS: {
      const { recipient } = action.payload;

      return { ...state, recipientDrawer: { open: true, recipient } };
    }
    case closeRecipientDrawerRoutine.SUCCESS: {
      return { ...state, recipientDrawer: { open: false, recipient: null } };
    }
    case openCreateListDialogRoutine.SUCCESS: {
      return { ...state, createListDialog: { open: true } };
    }
    case closeCreateListDialogRoutine.SUCCESS: {
      return { ...state, createListDialog: initialState.createListDialog };
    }
    case openPreBuilderDialogRoutine.SUCCESS: {
      return { ...state, preBuilderDialog: { open: true } };
    }
    case closePreBuilderDialogRoutine.SUCCESS: {
      return { ...state, preBuilderDialog: { open: false } };
    }
    default: {
      return state;
    }
  };
};

export default reducer;