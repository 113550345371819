import {
  getSendersRoutine,
  getBuilderSendersRoutine,
  getPreviewSendersRoutine,
  getSenderRoutine,
  createSenderRoutine,
  updateSenderRoutine,
  deleteSenderRoutine,
  readMessagesRoutine,
  clearLocalSendersRoutine,
  clearLocalActiveSenderRoutine
} from 'actions';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _dropRight from 'lodash/dropRight';
import _map from 'lodash/map';

export const initialState = {
  all: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 }, fetching: true },
  builder: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  sender: { id: '', user: { id: '', first_name: '', last_name: '' }, primary: false, title: '', office_phone: '', office_phone_extension: '', mobile_phone: '', email: '' }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getSendersRoutine.REQUEST: {
      const all = { ...state.all, fetching: true };

      return { ...state, all };
    }
    case getSendersRoutine.SUCCESS: {
      const { data: { senders, pagination } } = action.payload;

      return { ...state, all: { data: [...state.all.data, ...senders], pagination, fetching: false } };
    }
    case getSendersRoutine.FAILURE: {
      const all = { ...state.all, fetching: false };

      return { ...state, all };
    }
    case getBuilderSendersRoutine.SUCCESS: {
      const { data: { senders, pagination } } = action.payload;

      return { ...state, builder: { data: senders, pagination } };
    }
    case getPreviewSendersRoutine.SUCCESS: {
      return state;
    }
    case getSenderRoutine.SUCCESS: {
      const { data: { sender } } = action.payload;
      const updatedSender = { ...state.sender, ...sender };
      const foundInAll = !!_find(state.all.data, d => d.id === sender.id);

      const all = { ...state.all, ...(foundInAll && { data: _map(state.all.data, d => d.id === sender.id ? ({ ...d, ...sender }) : d) }) };

      return { ...state, sender: updatedSender, all };
    }
    case createSenderRoutine.SUCCESS: {
      const { data: { sender } } = action.payload;
      const oldAllData = state.all.pagination.total_count > 24 ? _dropRight(state.all.data) : state.all.data;
      const data = [...[sender], ...oldAllData];
      const pagination = { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 };

      return { ...state, all: { data, pagination } };
    }
    case updateSenderRoutine.SUCCESS: {
      const { response: { status }, sender } = action.payload;
      const updatedSender = { ...state.sender, ...sender };
      const data = _map(state.all.data, (item) => item.id === sender.id ? { ...item, ...sender } : item);

      return { ...state, ...(status === 204 && { sender: updatedSender }), all: { ...state.all, data } };
    }
    case deleteSenderRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const data = _filter(state.all.data, (item) => item.id !== id);
      const pagination = { ...state.all.pagination, count: state.all.pagination.count - 1, total_count: state.all.pagination.total_count - 1 };

      return { ...state, ...(status === 204 && { all: { data, pagination } }) };
    }
    case readMessagesRoutine.SUCCESS: {
      const { response: { status }, message_ids } = action.payload;
      
      return { ...state,
        ...(status === 204 && { 
          ...(!!_find(state.all.data, (item) => _find(message_ids, (message) => message === item.user?.private_conversation?.last_message?.id)) && {
            all: {...state.all, data: _map(state.all.data, (item) => !!_find(message_ids, (message) => message === item.user?.private_conversation?.last_message?.id) ? 
              { ...item, user: { ...item.user, private_conversation: { ...item.user.private_conversation, last_message: {...item.user.private_conversation.last_message, read: true } } } } : item ) } 
          })
        })
      }
    } 
    case clearLocalSendersRoutine.SUCCESS: {
      return { ...state, all: initialState.all }
    }
    case clearLocalActiveSenderRoutine.SUCCESS: {
      return { ...state, sender: initialState.sender }
    }
    default: {
      return state;
    }
  };
};

export default reducer;