import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import MuiBadge from '@mui/material/Badge';
import Box from '@mui/material/Box';

export const Root = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  marginBottom: 8,
  width: '100%',
  textAlign: 'left',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '24px',
  borderRadius: '6px',
  background: '#fff',
  padding: '12px 16px 20px',
  '&:hover': {
    boxShadow: '1px 1px 4px 0px #00000026',
  },
  [theme.breakpoints.up('md')]: {
    flexWrap: 'nowrap',
    gap: '30px',
    padding: '20px 4px 20px 32px'
  }
}));

export const Details = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: '100%',
  display: 'flex',
  justifyContent: 'center',
  //gap: '32px 12px',
  gap: '32px 24px',
  overflow: 'auto',
  [theme.breakpoints.up('md')]: {
    flexBasis: 'unset',
    justifyContent: 'flex-end'
  }
}));

export const Actions = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  flexBasis: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  zIndex: 1,
  [theme.breakpoints.up('md')]: {
    flexBasis: 'unset',
    justifyContent: 'flex-end',
    gap: '2px'
  }
}));

export const Item = styled(Box)({
  textAlign: 'right'
});

export const Text = styled(props => (
  <Typography variant='caption' component='div' {...props} />
))({
  color: 'rgba(0,0,0,.7)',
  whiteSpace: 'nowrap'
});

export const Caption = styled(props => (
  <Typography variant='small' component='div' {...props} />
))({
  color: 'rgba(0,0,0,.4)',
  marginBottom: 4,
  whiteSpace: 'nowrap'
});

export const ShareButton = styled(ButtonBase)({
  flexShrink: 0,
  width: 60,
  height: 60,
  background: '#F6F7FA',
  border: '1px solid #F4F4F4',
  borderRadius: '50%',
  '&:hover': {
    background: '#F4F4F4',
  },
  '& svg': {
    color: 'rgba(0,0,0,.7)',
    height: 24,
    width: 'auto'
  }
});

export const Badge = styled(props => (
  <MuiBadge color='success' {...props} />
))(({ theme }) => ({
  '& .MuiBadge-badge': {
    minWidth: 15,
    minHeight: 15,
    height: 'auto',
    width: 'auto',
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 600,
    lineHeight: '100%',
    padding: '0px 2px 2px'
  }
}));
