import styled from '@mui/material/styles/styled';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';

export const Root = styled(ListItemButton,
  { shouldForwardProp: (prop) => prop !== 'highlighted' }
)(({ theme, highlighted }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  backgroundColor: highlighted ? '#fff' : 'rgba(255,255,255,0.7)',
  borderBottom: `2px solid ${theme.palette.background.default}`,
  overflow: 'hidden',
  padding: '12px 28px',
}));

export const Content = styled('div')({
  flexGrow: 1,
  overflow: 'hidden',
});

export const Text = styled((props) => (
    <Typography variant='caption' component='div' noWrap {...props} />),
{ shouldForwardProp: (prop) => prop !== 'highlighted' })(({ highlighted }) => ({
  fontWeight: 400,
  ...(highlighted && {
    fontWeight: 700
  })
}));

export const StyledAvatar = styled(Avatar)(( { theme }) => ({
  width: 34,
  height: 34,
  color: theme.palette.section.chat.text,
  backgroundColor: theme.palette.section.chat.background.dark,
}));

export const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    height: 12,
    minWidth: 12,
    borderRadius: '50%',
    border: '2px solid #fff'
  }
});