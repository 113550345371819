import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  /*** Abort Controller ***/
  setAbortController as setAbortControllerAction,
  /*** Chat ***/
  setChatsFilter as setChatsFilterAction,
  /*** Notifications ***/
  setNotificationsOptions as setNotificationsOptionsAction,
  /*** Attachment ***/
  setViewAttachment as setViewAttachmentAction,
  /*** Auth ***/
  setAuthData as setAuthDataAction,
  clearAuthData as clearAuthDataAction,
  /*** Access Offering ***/
  openAccessOfferingDialog as openAccessOfferingDialogAction,
  closeAccessOfferingDialog as closeAccessOfferingDialogAction,
  /*** Access Beta ***/
  openAccessBetaDialog as openAccessBetaDialogAction,
  closeAccessBetaDialog as closeAccessBetaDialogAction,
  /*** Access Feed ***/
  openAccessFeedDialog as openAccessFeedDialogAction,
  closeAccessFeedDialog as closeAccessFeedDialogAction,
  /*** Avatar ***/
  openAvatarDialog as openAvatarDialogAction,
  closeAvatarDialog as closeAvatarDialogAction,
  /*** Invite ***/
  openInviteDialog as openInviteDialogAction,
  closeInviteDialog as closeInviteDialogAction,
  /*** Group ***/
  openGroupDialog as openGroupDialogAction,
  closeGroupDialog as closeGroupDialogAction,
  /*** Group Invite ***/
  openGroupInviteDialog as openGroupInviteDialogAction,
  closeGroupInviteDialog as closeGroupInviteDialogAction,
  /*** Header ***/
  openHeaderDialog as openHeaderDialogAction,
  closeHeaderDialog as closeHeaderDialogAction,
  /*** Lists Update ***/
  openListsUpdateDialog as openListsUpdateDialogAction,
  closeListsUpdateDialog as closeListsUpdateDialogAction,
  /*** Upload Contacts ***/
  openUploadContactsDialog as openUploadContactsDialogAction,
  closeUploadContactsDialog as closeUploadContactsDialogAction,
  /*** Agreement ***/
  openAgreementDialog as openAgreementDialogAction,
  closeAgreementDialog as closeAgreementDialogAction,
  /*** Files ***/
  openFilesCopyDialog as openFilesCopyDialogAction,
  closeFilesCopyDialog as closeFilesCopyDialogAction,
  /*** Delete ***/
  openDeleteDialog as openDeleteDialogAction,
  closeDeleteDialog as closeDeleteDialogAction,
  /*** Profile edit ***/
  openEditProfileDialog as openEditProfileDialogAction,
  closeEditProfileDialog as closeEditProfileDialogAction,
  /*** View File ***/
  openViewFileDialog as openViewFileDialogAction,
  closeViewFileDialog as closeViewFileDialogAction,
  /*** View File ***/
  openLockedContentDialog as openLockedContentDialogAction,
  closeLockedContentDialog as closeLockedContentDialogAction,
  /*** Send Email Notification ***/
  openSendEmailNotificationDialog as openSendEmailNotificationDialogAction,
  closeSendEmailNotificationDialog as closeSendEmailNotificationDialogAction,
  /*** Invited User Popover ***/
  openInvitedUserPopover as openInvitedUserPopoverAction,
  closeInvitedUserPopover as closeInvitedUserPopoverAction,
  /*** Recipient Drawer ***/
  openRecipientDrawer as openRecipientDrawerAction,
  closeRecipientDrawer as closeRecipientDrawerAction,
  /*** Profile edit ***/
  openCreateListDialog as openCreateListDialogAction,
  closeCreateListDialog as closeCreateListDialogAction,
  /*** Pre Builder ***/
  openPreBuilderDialog as openPreBuilderDialogAction,
  closePreBuilderDialog as closePreBuilderDialogAction
} from 'actions/app';

const useApp = () => {
  const dispatch = useDispatch();
  const setAbortController = useCallback(abortController => dispatch(setAbortControllerAction(abortController)), [dispatch]);
  const setChatsFilter = useCallback(({ filter }) => dispatch(setChatsFilterAction({ filter })), [dispatch]);
  const setNotificationsOptions = useCallback(({ drawer }) => dispatch(setNotificationsOptionsAction({ drawer })), [dispatch]);
  const setViewAttachment = useCallback(({ file }) => dispatch(setViewAttachmentAction({ file })), [dispatch]);
  const setAuthData = useCallback(({ open, mode, recipientToken, invitationToken, redirectUrl, redirectAction, firstName, lastName, email, password, repeatPassword, confirmationCode, codeSended, codeValidated, linkedInCode }) => dispatch(setAuthDataAction({ open, mode, recipientToken, invitationToken, redirectUrl, redirectAction, firstName, lastName, email, password, repeatPassword, confirmationCode, codeSended, codeValidated, linkedInCode })), [dispatch]);
  const clearAuthData = useCallback(() => dispatch(clearAuthDataAction()), [dispatch]);
  const openAccessOfferingDialog = useCallback(() => dispatch(openAccessOfferingDialogAction()), [dispatch]);
  const closeAccessOfferingDialog = useCallback(() => dispatch(closeAccessOfferingDialogAction()), [dispatch]);
  const openAccessBetaDialog = useCallback(() => dispatch(openAccessBetaDialogAction()), [dispatch]);
  const closeAccessBetaDialog = useCallback(() => dispatch(closeAccessBetaDialogAction()), [dispatch]);
  const openAccessFeedDialog = useCallback(() => dispatch(openAccessFeedDialogAction()), [dispatch]);
  const closeAccessFeedDialog = useCallback(() => dispatch(closeAccessFeedDialogAction()), [dispatch]);
  const openAvatarDialog = useCallback(() => dispatch(openAvatarDialogAction()), [dispatch]);
  const closeAvatarDialog = useCallback(() => dispatch(closeAvatarDialogAction()), [dispatch]);
  const openInviteDialog = useCallback(() => dispatch(openInviteDialogAction()), [dispatch]);
  const closeInviteDialog = useCallback(() => dispatch(closeInviteDialogAction()), [dispatch]);
  const openGroupDialog = useCallback((invitation) => dispatch(openGroupDialogAction(invitation)), [dispatch]);
  const closeGroupDialog = useCallback(() => dispatch(closeGroupDialogAction()), [dispatch]);
  const openGroupInviteDialog = useCallback((invitation) => dispatch(openGroupInviteDialogAction(invitation)), [dispatch]);
  const closeGroupInviteDialog = useCallback(() => dispatch(closeGroupInviteDialogAction()), [dispatch]);
  const openHeaderDialog = useCallback(() => dispatch(openHeaderDialogAction()), [dispatch]);
  const closeHeaderDialog = useCallback(() => dispatch(closeHeaderDialogAction()), [dispatch]);
  const openListsUpdateDialog = useCallback((contacts) => dispatch(openListsUpdateDialogAction(contacts)), [dispatch]);
  const closeListsUpdateDialog = useCallback(() => dispatch(closeListsUpdateDialogAction()), [dispatch]);
  const openUploadContactsDialog = useCallback(() => dispatch(openUploadContactsDialogAction()), [dispatch]);
  const closeUploadContactsDialog = useCallback(() => dispatch(closeUploadContactsDialogAction()), [dispatch]);
  const openAgreementDialog = useCallback(url => dispatch(openAgreementDialogAction(url)), [dispatch]);
  const closeAgreementDialog = useCallback(() => dispatch(closeAgreementDialogAction()), [dispatch]);
  const openFilesCopyDialog = useCallback(() => dispatch(openFilesCopyDialogAction()), [dispatch]);
  const closeFilesCopyDialog = useCallback(() => dispatch(closeFilesCopyDialogAction()), [dispatch]);
  const openDeleteDialog = useCallback(({ type, id, navigatePath, data }) => dispatch(openDeleteDialogAction({ type, id, navigatePath, data })), [dispatch]);
  const closeDeleteDialog = useCallback(() => dispatch(closeDeleteDialogAction()), [dispatch]);
  const openEditProfileDialog = useCallback(() => dispatch(openEditProfileDialogAction()), [dispatch]);
  const closeEditProfileDialog = useCallback(() => dispatch(closeEditProfileDialogAction()), [dispatch]);
  const openViewFileDialog = useCallback(({ id, file }) => dispatch(openViewFileDialogAction({ id, file })), [dispatch]);
  const closeViewFileDialog = useCallback(() => dispatch(closeViewFileDialogAction()), [dispatch]);
  const openLockedContentDialog = useCallback(() => dispatch(openLockedContentDialogAction()), [dispatch]);
  const closeLockedContentDialog = useCallback(() => dispatch(closeLockedContentDialogAction()), [dispatch]);
  const openSendEmailNotificationDialog = useCallback((email) => dispatch(openSendEmailNotificationDialogAction(email)), [dispatch]);
  const closeSendEmailNotificationDialog = useCallback(() => dispatch(closeSendEmailNotificationDialogAction()), [dispatch]);
  const openInvitedUserPopover = useCallback(() => dispatch(openInvitedUserPopoverAction()), [dispatch]);
  const closeInvitedUserPopover = useCallback(() => dispatch(closeInvitedUserPopoverAction()), [dispatch]);
  const openRecipientDrawer = useCallback(recipient => dispatch(openRecipientDrawerAction(recipient)), [dispatch]);
  const closeRecipientDrawer = useCallback(() => dispatch(closeRecipientDrawerAction()), [dispatch])
  const openCreateListDialog = useCallback(() => dispatch(openCreateListDialogAction()), [dispatch]);
  const closeCreateListDialog = useCallback(() => dispatch(closeCreateListDialogAction()), [dispatch]);
  const openPreBuilderDialog = useCallback(() => dispatch(openPreBuilderDialogAction()), [dispatch]);
  const closePreBuilderDialog = useCallback(() => dispatch(closePreBuilderDialogAction()), [dispatch]);

  return {
    setAbortController,
    setChatsFilter,
    setNotificationsOptions,
    setViewAttachment,
    setAuthData, clearAuthData,
    openAccessOfferingDialog, closeAccessOfferingDialog,
    openAccessBetaDialog, closeAccessBetaDialog,
    openAccessFeedDialog, closeAccessFeedDialog,
    openAvatarDialog, closeAvatarDialog,
    openInviteDialog, closeInviteDialog,
    openGroupDialog, closeGroupDialog,
    openGroupInviteDialog, closeGroupInviteDialog,
    openHeaderDialog, closeHeaderDialog,
    openListsUpdateDialog, closeListsUpdateDialog,
    openUploadContactsDialog, closeUploadContactsDialog,
    openAgreementDialog, closeAgreementDialog,
    openFilesCopyDialog, closeFilesCopyDialog,
    openDeleteDialog, closeDeleteDialog,
    openEditProfileDialog, closeEditProfileDialog,
    openViewFileDialog, closeViewFileDialog,
    openLockedContentDialog, closeLockedContentDialog,
    openSendEmailNotificationDialog, closeSendEmailNotificationDialog,
    openInvitedUserPopover, closeInvitedUserPopover,
    openRecipientDrawer, closeRecipientDrawer,
    openCreateListDialog, closeCreateListDialog,
    openPreBuilderDialog, closePreBuilderDialog
  };
};

export default useApp;