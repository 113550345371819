import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';

export const iconStyles = {
  flexShrink: 0,
  transform: 'rotate(270deg)'
}

export const Root = styled(ButtonBase)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  right: 40,
  width: 190,
  height: 50,
  textAlign: 'left',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '16px',
  background: 'white',
  borderRadius: '8px 8px 0 0',
  boxShadow: '0 1px 10px 5px rgba(0, 0, 0, 0.2)',
  padding: '9px 13px',
  zIndex: theme.zIndex.fab,
}));

export const MobileRoot = styled(Button)(({ theme }) => ({
  height: 34,
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  background: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: '0 1px 10px 5px rgba(0, 0, 0, 0.2)',
  padding: '4px 12px',
  '&:hover': {
    background: theme.palette.background.paper,
  },
}));