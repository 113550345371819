import styled from '@mui/material/styles/styled';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Root = styled(Container)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '16px',
  overflow: 'hidden',
  borderRadius: '14px',
  padding: '12px 28px',
  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
    padding: '18px 28px',
  },
  [theme.breakpoints.up(1300)]: {
    maxWidth: 'calc(100% - 52px)',
    //52: паддинги и размер лого 24, 20, 10 минус паддинги Рута 28 с обеих сторон *2
  }
}));

export const StyledContentContainer = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  flexGrow: 1,
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  gap: '8px 4px',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: '8px 16px '
  }
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.pxToRem(24),
  fontWeight: 700,
  textDecoration: 'none',
  [theme.breakpoints.up('md')]: {
    minHeight: 56,
    lineHeight: 1.4,
    fontSize: theme.typography.pxToRem(28)
  }
}));

export const AvatarsContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    minHeight: 56,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 2,
    marginLeft: 8,
  }
}));

export const InviteContainer = styled(Box)(({ theme }) => ({
  marginLeft: 8,
  [theme.breakpoints.up('md')]: {
    minHeight: 56,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 0,
  }
}));