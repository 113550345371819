import { memo,useCallback, useEffect, useRef, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import Header from 'components/Offers/Builder/Header/Header';
import BuilderForm from 'components/Offers/Builder/Form/Form';
import Preview from '../Preview/Preview';
import useAudits from 'hooks/useAudits';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useOffers from 'hooks/useOffers';
import useMixpanel from 'hooks/useMixpanel';
import useProperties from 'hooks/useProperties';
import useSuccess from 'hooks/useSuccess';
import useTemplates from 'hooks/useTemplates';

const Builder = () => {
  const navigate = useNavigate();
  const headerRef = useRef(null);
  const scrollRef = useRef(null);
  const formRef = useRef(null);
  const { state } = useLocation();
  const { offer_id } = useParams();
  const { setError } = useError();
  const { createAudit } = useAudits();
  const { getBuilderDraft, clearLocalBuilderOffer } = useOffers();
  const { track } = useMixpanel();
  const { clearLocalOfferProperties } = useProperties();
  const { setSuccess } = useSuccess();
  const { clearLocalTemplates } = useTemplates();
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { builderOffer } = useCustomSelector(state => ({ builderOffer: state.offers.builder.offer }));
  const [previewDialog, setPreviewDialog] = useState({ open: false, offer: null });

  const handleClose = showSuccessSnackbar => {
    if (showSuccessSnackbar) {
      createAudit({ offer_id, audit_type: 'draft_saved' }).catch(e => setError(e));
      setSuccess('Offering draft has been saved');
    }
    track(`Save a ${builderOffer.privacy_type === 'private' ? 'private' : 'market'} offering`);
    navigate(state?.redirectPath ?? -1);
  };
  const handlePreviewClick = () => {
    const headerChecked = headerRef.current.checkRequired();
    const formChecked = formRef.current.checkRequired();

    if (!headerChecked || !formChecked) {
      scrollRef.current.scrollIntoView();
      return;
    }

    getBuilderDraft(builderOffer.id)
    .then(({ payload: { data: { offer } } }) => setPreviewDialog({ open: true, offer }))
    .catch(e => setError(e));
  };
  const handlePreviewClose = () => {
    setPreviewDialog({ open: false, offer: null });
  };
  
  const getBuilderAndInnerData = useCallback(id => {
    getBuilderDraft(id).catch(e => setError(e));
  }, [getBuilderDraft, setError]);

  useEffect(() => {
    getBuilderAndInnerData(offer_id);
  }, [offer_id, getBuilderAndInnerData]);
  /**
   * Чистим локальное хранилище при выходе с билдера
   */
  useEffect(() => {
    return () => {
      clearLocalBuilderOffer();
      clearLocalOfferProperties();
      clearLocalTemplates();
    }
  }, [clearLocalBuilderOffer, clearLocalOfferProperties, clearLocalTemplates]);

  return (
    <BaseDialog
      disableEnforceFocus
      open
      onClose={() => {}}
      scroll='body'
      hideCloseButton
      fullWidth
      maxWidth='lg'
      PaperProps={{ sx: { backgroundColor: '#FAFAFA', height: 'auto', overflowY: 'unset' } }}
      fullScreen={!mediaDesktop}
    >
      <Preview
        {...previewDialog}
        onClose={handlePreviewClose}
      />
      <Header
        ref={headerRef}
        onClose={handleClose}
        onPreview={handlePreviewClick}
      />
      <div ref={scrollRef} />
      <Container maxWidth={false} sx={{ maxWidth: '668px', px: '16px !important' }}>
        <BuilderForm ref={formRef} />
      </Container>
    </BaseDialog>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Builder {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);