import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
//Local files
import { iconStyles, MobileRoot, Root } from './ChatFab.styled';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import ForwardIcon from 'components/Common/Icons/ForwardIcon';
import { getRepresentation } from 'helpers';
import { representationVariants } from 'helpers/constants';

const ChatFab = ({ open, unread, user, disabled = false, onOpenChat }) => {
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));//like DealFlow

  if (open && mediaDesktop) {
    return (
      <Root onClick={onOpenChat} disabled={disabled}>
        <Badge variant='dot' color='success' overlap="circular" invisible={!unread} sx={{ '& .MuiBadge-badge': { height: 12, minWidth: 12, borderRadius: '50%', border: '2px solid #fff'} }}>
          <BaseAvatar
            key={user?.id}
            src={getRepresentation(user?.photo?.representations, representationVariants.TINY)}
            firstName={user?.first_name}
            lastName={user?.last_name}
          />
        </Badge>
        <Typography variant='subtitle1' sx={{ flexGrow: 1 }}>Chat now</Typography>
        <ForwardIcon style={iconStyles} />
      </Root>
    );
  }

  if (open && !mediaDesktop) {
    return (
      <MobileRoot onClick={onOpenChat} disabled={disabled}>
        <Badge variant='dot' color='success' overlap="circular" invisible={!unread} sx={{ '& .MuiBadge-badge': { height: 8, minWidth: 8, borderRadius: '50%', border: '2px solid #fff'} }}>
          <BaseAvatar
            key={user?.id}
            src={getRepresentation(user?.photo?.representations, representationVariants.TINY)}
            firstName={user?.first_name}
            lastName={user?.last_name}
            sx={{ width: 22, height: 22, fontSize: '14px'}}
          />
        </Badge>
        <Typography variant='subtitle2' sx={{ flexGrow: 1 }}>Chat now</Typography>
      </MobileRoot>
    );
  }

  return null;
}

export default ChatFab;