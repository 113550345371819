import { memo } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
// Local files
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import BaseAvatarGroup from 'components/Common/DataDisplay/BaseAvatarGroup/BaseAvatarGroup';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { getRepresentation } from 'helpers';
import { representationVariants } from 'helpers/constants';

const Card = ({ checked, name, users, usersCount, processing, onChange }) => {
  return (
    <Box display='flex' alignItems='center' gap='10px'>
      <Checkbox color='secondary' disabled={processing} checked={checked} onChange={onChange} />
      <Typography variant='caption' sx={{ display: 'block', flexBasis: '30%', cursor: 'pointer' }} onClick={() => !processing && onChange()}>
        {name}
      </Typography>
      <BaseAvatarGroup max={5} spacing='small'>
        {_map(users, ({ id, photo, first_name, last_name }) =>
          <BaseAvatar
            key={id}
            src={getRepresentation(photo?.representations, representationVariants.TINY)}
            firstName={first_name}
            lastName={last_name}
          />
        )}
      </BaseAvatarGroup>
      {usersCount > 5 && <Typography fontSize='12px'>and {usersCount - 5} more</Typography>}
    </Box>
  );
};

Card.propTypes = {
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  usersCount: PropTypes.number.isRequired,
  processing: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Card {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);