import { memo } from 'react';
import Radio from '@mui/material/Radio';
//Local files
import { Description, LabelContent, StyledControl, StyledLabel, Title } from './VisibilityRadioButton.styled';
import LockedFilledIcon from 'components/Common/Icons/LockedFilledIcon';
import MarketFilledIcon from 'components/Common/Icons/MarketFilledIcon';

const VisibilityRadioButton = ({ value }) => {
  switch (value) {
    case 'private':
      return (
        <StyledControl
          value={value}
          control={<Radio color='secondary' />}
          label={
            <StyledLabel>
              <LockedFilledIcon />
              <LabelContent>
                <Title>Private Offering<span>Default</span></Title>
                <Description>Only share with whom I specify</Description>
              </LabelContent>
            </StyledLabel>
          }
        />
      )
    case 'market':
      return (
        <StyledControl
          value={value}
          control={<Radio color='secondary' />}
          label={
          <StyledLabel>
            <MarketFilledIcon />
            <LabelContent>
              <Title>Market</Title>
              <Description>Visible to the Qwincy network</Description>
            </LabelContent>
          </StyledLabel>
          }
        />
      )
    default:
      return null;
  }
}

export default memo(VisibilityRadioButton);