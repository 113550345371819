import { memo } from 'react';
import { ButtonBase } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import _map from 'lodash/map';
import { useLocation, useNavigate } from 'react-router-dom';
// Local files
import { ReactComponent as MenuIcon } from 'assets/icons/menu-vert.svg';
import BaseAvatarGroup from 'components/Common/DataDisplay/BaseAvatarGroup/BaseAvatarGroup';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import ParticipantsSelect from 'components/Contacts/ParticipantsSelect/ParticipantsSelect';
import useCustomSelector from 'hooks/useCustomSelector';
import useConversations from 'hooks/useConversations';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';

const UserContent= () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { setLocalUserIds, clearLocalChatsData } = useConversations();
  const { users, isOffer, offerId, name, headline } = useCustomSelector(state => ({ 
    users: state.conversations.conversation.users,
    isOffer: state.conversations.conversation.conversationable_type === 'offer',
    offerId: state.conversations.conversation.conversationable?.id,
    name: state.conversations.conversation.conversationable?.name,
    headline: state.conversations.conversation.conversationable?.headline
  }));
  const { user_ids } = useCustomSelector(state => state.conversations);

  const handleClick = () => { 
    clearLocalChatsData();
    navigate(`/deal_flow/${offerId}`, { state: { vertical: 'sharedWithMe', horizontal: 'all' } });
  };

  return (
    <Box sx={{ flexGrow: 1, width: '100%', display: 'flex', gap: '4px', alignItems: 'center', overflow: 'hidden', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}>
      {!!!user_ids && <BaseAvatarGroup max={4}>
        {_map(users, user =>
          <BaseAvatar
            key={user?.id}
            src={getRepresentation(user?.photo?.representations, representationVariants.TINY)}
            firstName={user?.first_name}
            lastName={user?.last_name}
          />
        )}
      </BaseAvatarGroup>}
      <Typography component='div' noWrap sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        {isOffer && <ButtonBase sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left' }} onClick={handleClick}>
          <Typography variant='subtitle2' noWrap sx={{ textDecoration: 'underline' }}>{headline}</Typography>
          <Typography variant='caption' noWrap>{name}</Typography>
        </ButtonBase>}
        <Typography variant='caption' noWrap sx={{ textAlign: 'left', fontWeight: 600 }}>{_map(users, ({ first_name }) => first_name).join(', ')}</Typography>
      </Typography>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        { !!user_ids && ((!!state?.offer_id || !!state?.my_id) ? <Typography variant='subtitle2' pl='12px'>{!!state?.offer_id ? 'Create Feed Chat' : 'Create Private Chat'}</Typography> : <ParticipantsSelect /> )}
        { users?.length > 2 &&
        <IconButton
          edge="end"
          color="primary"
          aria-label="open menu"
          sx={{ mr: 0.5 }}
          onClick={() => setLocalUserIds(!!user_ids ? null : 'user_id')}
        >
          <MenuIcon width={16} height={16} />
        </IconButton>}
      </Box>
    </Box>
  );
};

export default memo(UserContent);