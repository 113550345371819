import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import background from 'assets/images/background/authorization.jpg';

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: '800px',
    minHeight: '460px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch'
  },
  '& > *': {
    flex: '1 0',
    minWidth: 400
  }
}));

export const Image = styled(Box)(({ theme }) => ({
  minHeight: 280,
  position: 'relative',
  color: '#fff',
  backgroundColor: theme.palette.primary.main,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url('${background}')`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px'
}));

export const Content = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '44px 56px 32px'
});

export const PrimaryButton = styled(props => (
  <Button variant='contained' color='secondary' {...props} />
))({
  minWidth: 100
});