import { memo } from 'react';
import Avatar from '@mui/material/Avatar';
import _toUpper from 'lodash/toUpper';
import PropTypes from 'prop-types';

const BaseAvatar = ({ src = null, firstName, lastName, sx = {} }) => {
  return (
    <Avatar
      alt={`${firstName ?? ''} ${lastName ?? ''}`}
      src={src}
      sx={{
        width: 30, height: 30,
        fontSize: 14,
        fontWeight: 700,
        backgroundColor: 'primary.main',
        ...sx
      }}
    >
      {firstName && lastName ? `${_toUpper(firstName[0])}${_toUpper(lastName[0])}` : ''}
    </Avatar>
  );
};

BaseAvatar.propTypes = {
  src: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  sx: PropTypes.object
};

export default memo(BaseAvatar);