import { memo } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
// Local files
import { Search } from './SearchResult.styled';
import { ReactComponent as BackIcon } from 'assets/icons/files-back.svg';

const SearchResult = ({ query, onClick }) => {
  return (
    <Box display='flex' alignItems='center'>
      <IconButton onClick={onClick}>
        <BackIcon />
      </IconButton>
      <Search>Search results for ‘{query}’</Search>
    </Box>
  );
};

SearchResult.propTypes = {
  query: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default memo(SearchResult);