import { memo, useCallback, useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import _map from 'lodash/map';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import FormSelect from 'components/Common/Inputs/FormSelect/FormSelect';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useCustomSelector from 'hooks/useCustomSelector';
import useDealspaces from 'hooks/useDealspaces';
import useError from 'hooks/useError';

const Select = ({ groupId, value, disabled, onChange, fullWidth = false }) => {
  const { setError } = useError();
  const { getDealspacesList, clearLocalDealspacesList } = useDealspaces();
  const { dealspaces, hasMore, newOffset } = useCustomSelector(state => ({
    dealspaces: state.dealspaces.list.data,
    hasMore: state.dealspaces.list.pagination.total_count > state.dealspaces.list.pagination.count + state.dealspaces.list.pagination.offset,
    newOffset: state.dealspaces.list.pagination.limit + state.dealspaces.list.pagination.offset
  }));
  const [loading, setLoading] = useState(false);

  const fetchDealspaces = useCallback(({ offset, groupId }) => {
    setLoading(true);
    getDealspacesList({ group_id: groupId, offset })
    .catch(e => setError(e))
    .finally(() => setLoading(false));
  }, [getDealspacesList, setError]);
  const handleScroll = ({ target }) => {
    if (target.scrollTop === target.scrollHeight - target.clientHeight && !loading && hasMore) {
      fetchDealspaces({ offset: newOffset, groupId });
    }
  };

  useEffect(() => {
    clearLocalDealspacesList()
    .then(() => fetchDealspaces({ offset: 0, groupId }));

    return clearLocalDealspacesList;
  }, [groupId, clearLocalDealspacesList, fetchDealspaces]);

  return (
    <FormSelect
      disabled={disabled}
      label='Dealspace'
      SelectProps={{
        MenuProps: {
          PaperProps: { variant: 'outlined', sx: { marginTop: '4px', boxShadow: 'none !important' } }
        }
      }}
      onScroll={handleScroll}
      sx={{ flexShrink: 0 }}
      value={value}
      onChange={({ target: { value } }) => onChange(value)}
      fullWidth
      required
    >
      <MenuItem value='create'>+ Create New Dealspace</MenuItem>
      {_map(dealspaces, ({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
    </FormSelect>
  );
};

Select.propTypes = {
  groupId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Select {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);