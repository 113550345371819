import { memo, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import MarketFilledIcon from 'components/Common/Icons/MarketFilledIcon';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useChameleon from 'hooks/useChameleon';
import useError from 'hooks/useError';
import useOffers from 'hooks/useOffers';
import useSuccess from 'hooks/useSuccess';

const MarketPublishOffering = ({ id, open, onClose, onPublish }) => {
  const { track: trackChameleon } = useChameleon();
  const { setError } = useError();
  const { publishOffer } = useOffers();
  const { setSuccess } = useSuccess();
  const [processing, setProcessing] = useState(false);

  const handlePublishClick = () => {
    setProcessing(true);

    publishOffer({ id, notify: false })
    .then(() => {
      trackChameleon('Market offering published');
      setSuccess('Your Market Offering is now Active and visible to anyone.').then(onPublish);
    })
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };
  
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{ sx: { maxWidth: 700, width: '100%' } }}
      actions={
        <>
          <Button
            sx={{ minWidth: 100 }}
            disabled={processing}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant='gradient'
            sx={{ minWidth: 140 }}
            disabled={processing}
            onClick={handlePublishClick}
          >
            Publish Now
          </Button>
        </>
      }
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: '56px !important', pb: '40px' }}>
        <MarketFilledIcon style={{ height: 60, width: 'auto', color: 'rgba(0,0,0,.8)' }} />
        <Typography  align='center' sx={{ fontSize: '1.25rem', fontWeight: 600, color: 'rgba(0,0,0,.8)', mt: '8px', mb: '30px' }}>You are about to publish to the Market </Typography>
        <ul style={{ margin: 0 }}>
          <li>
            <Typography
              variant='body2'
              component='span'
            >
              Your offering will be visible to the Qwincy community.
            </Typography>
          </li>
          <li>
            <Typography
              variant='body2'
              component='span'
            >
              Your offering will be shared to the Feeds of your Subscribers
            </Typography>
          </li>
        </ul>
        <Typography
          variant='body2'
          component='span'
          sx={{ pt: '35px' }}
        >
          You can additionally share your opportunity to specific recipients once published.
        </Typography>
      </DialogContent>
    </BaseDialog>
  );
};

MarketPublishOffering.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <MarketPublishOffering {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);