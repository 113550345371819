import styled from '@mui/material/styles/styled';
import ButtonBase from '@mui/material/ButtonBase';

export const Root = styled(ButtonBase)({
  width: 48,
  height: 48,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '2px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});