import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getDealspaceImagesRoutine,
  getOfferImagesRoutine,
  getParcelImagesRoutine,
  getBuilderImagesRoutine,
  getPreviewImagesRoutine,
  getImageRoutine,
  createOfferImageRoutine,
  updateImageRoutine,
  deleteImageRoutine,
  mainImageRoutine,
  clearLocalDealspaceImagesRoutine,
  clearLocalOfferImagesRoutine,
  clearLocalActiveImageRoutine
} from 'actions';
import _omit from 'lodash/omit';

const attributes = ['id', 'main', { document: ['id'] }, { file: ['id', 'representations', 'url', 'filename', 'content_type'] }, 'created_at'];

export const getDealspaceImages = getThunkActionCreator(
  getDealspaceImagesRoutine,
  async ({ folder_id, limit = 25, offset = 0, orders = { created_at: 'desc' } }) => {
    const content_types = ['image/png', 'image/jpeg', 'image/gif', 'mage/svg+xml', 'image/tiff', 'image/bmp'];
    const params = { limit, offset, orders, content_types, attributes };

    return await axios.get(`/folders/${folder_id}/documents`, { params });
  }
);
export const getBuilderImages = getThunkActionCreator(
  getBuilderImagesRoutine,
  async ({ offer_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const attributes = ['id', 'main', { document: ['id'] }, { file: ['id', 'representations', 'url', 'filename', 'content_type'] }, 'created_at'];
    const params = { limit, offset, orders, attributes };

    return await axios.get(`/offers/${offer_id}/images`, { params });
  }
);
export const getOfferImages = getThunkActionCreator(
  getOfferImagesRoutine,
  async ({ offer_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const attributes = ['id', 'main', { document: ['id'] }, { file: ['id', 'representations', 'url', 'filename', 'content_type'] }, 'created_at'];
    const params = { limit, offset, orders, attributes };

    return await axios.get(`/offers/${offer_id}/images`, { params });
  }
);
export const getParcelImages = getThunkActionCreator(
  getParcelImagesRoutine,
  async offer_id => {
    const attributes = ['id', 'main', { file: ['id', 'representations'] }];
    const params = { limit: 25, offset: 0, orders: { updated_at: 'desc' }, attributes };

    return await axios.get(`/offers/${offer_id}/images`, { params });
  }
);
export const getPreviewImages = getThunkActionCreator(
  getPreviewImagesRoutine,
  async ({ offer_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const params = { limit, offset, orders, attributes };

    return await axios.get(`/offers/${offer_id}/images`, { params });
  }
);
export const getImage = getThunkActionCreator(
  getImageRoutine,
  async id => {
    const params = { attributes };

    return await axios.get(`/images/${id}`, { params });
  }
);
export const createOfferImage = getThunkActionCreator(
  createOfferImageRoutine,
  async ({ offer_id, document_id = null, image = null, controller }) => {
    const data = { attributes, ...document_id && { document_id }, ...image && { image } };
    const config = { signal: controller.signal };

    return await axios.post(`/offers/${offer_id}/images`, data, config);
  }
);
export const updateImage = getThunkActionCreator(
  updateImageRoutine,
  async ({ id, ...image }) => {
    const data = { image: _omit(image, ['localFile']) };

    return await axios.patch(`/images/${id}`, data);
  }
);
export const deleteImage = getThunkActionCreator(
  deleteImageRoutine,
  async id => {
    const response = await axios.delete(`/images/${id}`);

    return { response, id };
  }
);
export const mainImage = getThunkActionCreator(
  mainImageRoutine,
  async id => {
    const response = await axios.post(`/images/${id}/main`);

    return { response, id };
  }
);
export const clearLocalDealspaceImages = getThunkActionCreator(clearLocalDealspaceImagesRoutine, async () => {});
export const clearLocalOfferImages = getThunkActionCreator(clearLocalOfferImagesRoutine, async () => {});
export const clearLocalActiveImage = getThunkActionCreator(clearLocalActiveImageRoutine, async () => {});