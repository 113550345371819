import { memo } from 'react';
import { ButtonBase } from '@mui/material';
import Typography from '@mui/material/Typography';
import _map from 'lodash/map';
import { useNavigate, useLocation } from 'react-router-dom';
// Local files
import BaseAvatarGroup from 'components/Common/DataDisplay/BaseAvatarGroup/BaseAvatarGroup';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';
import useConversations from 'hooks/useConversations';

const GroupContent= () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { clearLocalChatsData } = useConversations();
  const { users, isOffer, id, name, offerDealspaceName } = useCustomSelector(state => ({ 
    users: state.conversations.conversation.users,
    isOffer: state.conversations.conversation.conversationable_type === 'offer',
    id: state.conversations.conversation.conversationable?.id,
    name: state.conversations.conversation.conversationable?.name,
    offerDealspaceName: state.conversations.conversation.conversationable?.dealspace?.name
  }));

  const handleClick = () => { 
    clearLocalChatsData();
    navigate(`/${isOffer ? `deal_flow/${id}` : `dealspaces/${id}/files`}`, { ...isOffer && { state: { vertical: 'myShared', horizontal: 'all' } } });
  };
  
  if (!!id || !!state?.offer_id) {
    return (
      <>
        <ButtonBase sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left', overflow: 'hidden' }} onClick={handleClick}>
          <Typography variant='subtitle2' noWrap sx={{ width: '100%', textDecoration: 'underline' }}>{name}</Typography>
          <Typography variant='caption'  noWrap sx={{ width: '100%' }}>{offerDealspaceName}</Typography>
        </ButtonBase>
        {!!state?.offer_id && <Typography variant='subtitle2' pl='12px'>Create Offering Chat</Typography> }
        <BaseAvatarGroup max={4}>
          {_map(users, user =>
            <BaseAvatar
              key={user?.id}
              src={getRepresentation(user?.photo?.representations, representationVariants.TINY)}
              firstName={user?.first_name}
              lastName={user?.last_name}
            />
          )}
        </BaseAvatarGroup>
      </>
    );
  }

  return null;
};

export default memo(GroupContent);