import {
  createSessionRoutine,
  deleteSessionRoutine,
  setLocalSessionRoutine
} from 'actions';

export const initialState = {
  session: { accessToken: null, userableType: null, checked: false },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case createSessionRoutine.SUCCESS: {
      return state;
    }
    case deleteSessionRoutine.SUCCESS: {
      const { status } = action.payload;

      if (status === 204) return initialState;
      return state;
    }
    case setLocalSessionRoutine.SUCCESS: {
      const { accessToken, userableType } = action.payload;
      const session = { accessToken, userableType, checked: true };

      return { ...state, session };
    }
    default: {
      return state;
    }
  };
};

export default reducer;