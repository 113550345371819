import styled from '@mui/material/styles/styled';
import Paper from '@mui/material/Paper';

const Root = styled(Paper)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  border: '1px solid #D9D9D9',
  borderRadius: '50px',
  padding: '8px 16px',
});

export { Root };