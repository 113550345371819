import styled from '@mui/material/styles/styled';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.text.secondary,
  paddingLeft: 12,
  paddingRight: 12,
  '&.Mui-selected': {
    backgroundColor: '#F7F9FD',
    color: theme.palette.text.primary,
    fontWeight: 600
  }
}));

export const Icon = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 6,
}));

export const Text = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(12),
  }
}));