import {
  setMapInstanceRoutine,
  setMapConfigsRoutine,
  centerMapRoutine,
  selectPolygonRoutine,
  clearMapDataRoutine,
  clearPolygonsRoutine,
  getFeedOfferDetailsRoutine,
  getOfferPreviewRoutine,
  getBuilderDraftRoutine,
  getFeedMapOffersRoutine
} from 'actions';
import WKT from 'terraformer-wkt-parser';
import _isUndefined from 'lodash/isUndefined';
import _isEqual from 'lodash/isEqual';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _uniqBy from 'lodash/uniqBy';
import _uniqWith from 'lodash/uniqWith';

export const initialState = {
  instance: null,
  configs: {
    center: { lat: 25.778559, lng: -80.311679 },
    zoom: 10,
    listOffset: 0,
    widgetOffset: 0,
    detailsOffset: 0,
    mapWidth: 0
  },
  bounds: { maxLatitude: null, maxLongitude: null, minLatitude: null, minLongitude: null },
  polygons: [],
  activePolygons: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setMapInstanceRoutine.SUCCESS: {
      const { instance } = action.payload;

      return { ...state, instance };
    }
    case setMapConfigsRoutine.SUCCESS: {
      const { center, zoom, listOffset, widgetOffset, detailsOffset, mapWidth } = action.payload;
      const configs = {
        ...state.configs,
        ...(!_isUndefined(center) && { center }),
        ...(!_isUndefined(zoom) && { zoom }),
        ...(!_isUndefined(listOffset) && { listOffset }),
        ...(!_isUndefined(widgetOffset) && { widgetOffset }),
        ...(!_isUndefined(detailsOffset) && { detailsOffset }),
        ...(!_isUndefined(mapWidth) && { mapWidth })
      };
      // console.log(configs);
      return { ...state, configs };
    }
    case centerMapRoutine.SUCCESS: {
      const minLat = action.payload.minLatitude ?? state.bounds.minLatitude;
      const minLng = action.payload.minLongitude ?? state.bounds.minLongitude;
      const maxLat = action.payload.maxLatitude ?? state.bounds.maxLatitude;
      const maxLng = action.payload.maxLongitude ?? state.bounds.maxLongitude;
      const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(minLat, minLng),
        new window.google.maps.LatLng(maxLat, maxLng)
      );
      state.instance.fitBounds(bounds, { left: 0, top: 50, right: 0, bottom: 50 });

      return state;
    }
    case selectPolygonRoutine.SUCCESS: {
      const { id } = action.payload;
      
      return { ...state, activePolygons: [...state.activePolygons, id] };
    }
    case getBuilderDraftRoutine.SUCCESS: {
      const { data: { offer: { locations, min_latitude, max_latitude, min_longitude, max_longitude } } } = action.payload;
      const locationsPolygons = _map(_filter(locations, l => !!l.parcel), ({ id, parcel, property }) => {
        const parsedParcel = WKT.parse(parcel);
  
        return { id, property_id: property.id, paths: _uniqWith(parsedParcel.coordinates[0][0].map(i => ({ lat: i[1], lng: i[0] })), _isEqual), parcel };
      });
      const polygons = _uniqBy(locationsPolygons, 'parcel');
      const bounds = { maxLatitude: max_latitude, maxLongitude: max_longitude, minLatitude: min_latitude, minLongitude: min_longitude };
      
      return { ...state, polygons, activePolygons: _map(polygons, p => p.id), bounds };
    }
    case getFeedOfferDetailsRoutine.SUCCESS: {
      const { data: { offer: { locations, min_latitude, max_latitude, min_longitude, max_longitude, main_location } } } = action.payload;
      const center = { lat: main_location.approximate_latitude, lng: main_location.approximate_longitude };
      const locationsPolygons = _map(_filter(locations, l => !!l.parcel), ({ id, parcel, property }) => {
        const parsedParcel = WKT.parse(parcel);
  
        return { id, property_id: property.id, paths: _uniqWith(parsedParcel.coordinates[0][0].map(i => ({ lat: i[1], lng: i[0] })), _isEqual), parcel };
      });
      const polygons = _uniqBy([...state.polygons, ...locationsPolygons], 'parcel');
      const bounds = { maxLatitude: max_latitude, maxLongitude: max_longitude, minLatitude: min_latitude, minLongitude: min_longitude };

      return { ...state, configs: { ...state.configs, center }, polygons, activePolygons: _map(locationsPolygons, p => p.id), bounds };
    }
    case getOfferPreviewRoutine.SUCCESS: {
      const { data: { offer: { locations, min_latitude, max_latitude, min_longitude, max_longitude } } } = action.payload;
      const locationsPolygons = _map(_filter(locations, l => !!l.parcel), ({ id, parcel, property }) => {
        const parsedParcel = WKT.parse(parcel);
  
        return { id, property_id: property.id, paths: _uniqWith(parsedParcel.coordinates[0][0].map(i => ({ lat: i[1], lng: i[0] })), _isEqual), parcel };
      });
      const polygons = _uniqBy(locationsPolygons, 'parcel');
      const bounds = { maxLatitude: max_latitude, maxLongitude: max_longitude, minLatitude: min_latitude, minLongitude: min_longitude };

      return { ...state, polygons, activePolygons: _map(polygons, p => p.id), bounds };
    }
    case getFeedMapOffersRoutine.SUCCESS: {
      const { offers } = action.payload;
      const locationPolygons = _map(_reduce(offers, (res, i) => _filter([...res, ...i.locations], l => !!l.parcel), []), ({ id, parcel, property }) => {
        const parsedParcel = WKT.parse(parcel);
  
        return { id, property_id: property.id, paths: _uniqWith(parsedParcel.coordinates[0][0].map(i => ({ lat: i[1], lng: i[0] })), _isEqual), parcel };
      });
      const polygons = _uniqBy([...state.polygons, ...locationPolygons], 'parcel');

      return { ...state, polygons };
    }
    case clearMapDataRoutine.SUCCESS: {
      return {
        ...state,
        instance: null,
        configs: {
          center: { lat: 25.778559, lng: -80.311679 },
          zoom: 10,
          listOffset: 0,
          widgetOffset: 0,
          detailsOffset: 0,
          mapWidth: 0
        },
        bounds: { maxLatitude: null, maxLongitude: null, minLatitude: null, minLongitude: null }
      };
    }
    case clearPolygonsRoutine.SUCCESS: {
      return { ...state, polygons: [], activePolygons: [] };
    }
    default: {
      return state;
    }
  };
};

export default reducer;