import styled from '@mui/material/styles/styled';
import Card from '@mui/material/Card';

const CardStyled = styled(Card)(({ theme }) => ({
  flexGrow: 1,
  width: '45%', 
  margin: 20,
  background: theme.palette.section.chat.primary,
  borderRadius: '8px 8px 0px 8px'
}));

export { CardStyled };