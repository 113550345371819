import { extension } from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { createNylasUserRoutine, getNylasUserRoutine, deleteNylasUserRoutine } from 'actions';

const attributes = ['id', 'email', 'grant_id'];

export const createNylasUser = getThunkActionCreator(
  createNylasUserRoutine,
  async nylas_code => {
    return await extension.post('/nylas_users', { nylas_code, attributes });
  }
);

export const getNylasUser = getThunkActionCreator(
  getNylasUserRoutine,
  async email => {
    return await extension.get(`/nylas_users/${email}`, { params: { attributes } })
  }
);

export const deleteNylasUser = getThunkActionCreator(
  deleteNylasUserRoutine,
  async email => {
    return await extension.delete(`/nylas_users/${email}`);
  }
);