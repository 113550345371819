import {
  getDealspacesForChatsRoutine,
  getDealspacesListRoutine,
  getDealspacesRoutine,
  getNotViewedDealspacesRoutine,
  getDealspaceRoutine,
  createDealspaceRoutine,
  updateDealspaceRoutine,
  deleteDealspaceRoutine,
  setDealspaceUnreadCountConversationsRoutine,
  setDealspaceUnreadCoversationUpdatedRoutine,
  readMessagesRoutine,
  clearLocalDealspacesForChatsRoutine,
  clearLocalDealspacesListRoutine,
  clearLocalDealspacesRoutine,
  clearLocalNotViewedDealspacesRoutine,
  clearLocalActiveDealspaceRoutine,
  createDealspacePropertyRoutine,
  deletePropertyRoutine
} from 'actions';
import _filter from 'lodash/filter';
import _dropRight from 'lodash/dropRight';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _omit from 'lodash/omit';
import _uniqBy from 'lodash/uniqBy';

export const initialState = {
  list: {
    data: [],
    pagination: { limit: 25, offset: 0, count: 0, total_count: 0 }
  },
  group: {
    data: [],
    pagination: { limit: 25, offset: 0, count: 0, total_count: -1 }
  },
  chats: {
    data: [],
    pagination: { limit: 25, offset: 0, count: 0, total_count: 0 }
  },
  notViewed: {
    data: [],
    pagination: { limit: 25, offset: 0, count: 0, total_count: 0 }
  },
  dealspace: {
    fetching: true,
    id: '',
    root_folder: { id: '' },
    group: { id: '', current_member: { role: '' } },
    name: '', conversation: null,
    unread_offer_conversations_count: 0,
    properties_count: 0,
    locations_count: 0,
    unread_conversation: true,
    notes: ''
  },
  createdDealspace: { // Временное решение для возможности отследить создание с любого места
    id: '',
    name: ''
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getDealspacesForChatsRoutine.SUCCESS: {
      const { data: { dealspaces, pagination } } = action.payload;

      return { ...state, chats: { data: [...state.chats.data, ...dealspaces], pagination } };
    }
    case getDealspacesListRoutine.SUCCESS: {
      const { data: { dealspaces, pagination } } = action.payload;

      return { ...state, list: { data: [...state.list.data, ...dealspaces], pagination } };
    }
    case getDealspacesRoutine.SUCCESS: {
      const { data: { dealspaces, pagination } } = action.payload;

      return { ...state, group: { data: _uniqBy([...state.group.data, ...dealspaces], 'id'), pagination } };
    }
    case getNotViewedDealspacesRoutine.SUCCESS: {
      const { data: { dealspaces, pagination } } = action.payload;

      return { ...state, notViewed: { data: _uniqBy([...state.notViewed.data, ...dealspaces], 'id'), pagination } };
    }
    case getDealspaceRoutine.REQUEST: {
      const dealspace = { ...state.dealspace, fetching: true };

      return { ...state, dealspace };
    }
    case getDealspaceRoutine.SUCCESS: {
      const { data: { dealspace } } = action.payload;
      const updatedDealspace = { ...state.dealspace, ...dealspace, fetching: false };
      const data = _map(state.group.data, (item) => item.id === dealspace.id ? { ...item, ...dealspace } : item);

      return { ...state, dealspace: updatedDealspace, group: { ...state.group, data } };
    }
    case getDealspaceRoutine.FAILURE: {
      const dealspace = { ...state.dealspace, fetching: false };

      return { ...state, dealspace };
    }
    case createDealspaceRoutine.SUCCESS: {
      const { data: { dealspace } } = action.payload;
      const oldGroupData = state.group.pagination.total_count > 24 ? _dropRight(state.group.data) : state.group.data;
      const groupData = [dealspace, ...oldGroupData];
      const groupPagination = { ...state.group.pagination, count: state.group.pagination.count + 1, total_count: state.group.pagination.total_count + 1 };
      const group = { data: groupData, pagination: groupPagination };
      const groupAmount = state.groupAmount + 1;
      const listData = [dealspace, ...state.list.data];
      const listPagination = { ...state.list.pagination, count: state.list.pagination.count + 1, total_count: state.list.pagination.total_count + 1 };
      const list = { data: listData, pagination: listPagination };

      return { ...state, group, groupAmount, list };
    }
    case updateDealspaceRoutine.SUCCESS: {
      const { response: { status }, dealspace } = action.payload;
      const updatedDealspace = { ...state.dealspace, ..._omit(dealspace, ['localCover']), ...(!!dealspace.localCover && { cover: dealspace.localCover }) };

      const data = _map(state.group.data, (item) => item.id === dealspace.id ? { ...item, ...dealspace } : item);

      return { ...state, ...(status === 204 && { dealspace: updatedDealspace }), group: { ...state.group, data } };
    }
    case deleteDealspaceRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const data = _filter(state.group.data, (item) => item.id !== id);
      const pagination = { ...state.group.pagination, count: state.group.pagination.count - 1, total_count: state.group.pagination.total_count - 1 };

      return { ...state, ...(status === 204 && { group: { data, pagination } }) };
    }
    case setDealspaceUnreadCountConversationsRoutine.SUCCESS: {
      const { unread_offer_conversations_count, dealspace_id } = action.payload;

      return { ...state, 
        chats: { ...state.chats, data: _map(state.chats.data, (item) => item.id === dealspace_id ? { ...item, unread_offer_conversations_count } : item) },
        ...(state.dealspace.id === dealspace_id && { dealspace: { ...state.dealspace, unread_offer_conversations_count } })
      };
    }
    case setDealspaceUnreadCoversationUpdatedRoutine.SUCCESS: {
      const { unread_conversation, dealspace_id } = action.payload;

      return { ...state, 
        chats: { ...state.chats, data: _map(state.chats.data, (item) => item.id === dealspace_id ? { ...item, unread_conversation } : item) },
        ...(state.dealspace.id === dealspace_id && { dealspace: { ...state.dealspace, unread_conversation } })
      };
    }
    case readMessagesRoutine.SUCCESS: {
      const { response: { status }, message_ids } = action.payload;
      
      return { ...state,
        ...(status === 204 && { 
          ...(!!_find(state.chats.data, (item) => _find(message_ids, (message) => message === item.conversation?.last_message?.id)) && { 
            chats: { ...state.chats, data: _map(state.chats.data, (item) => !!_find(message_ids, (id) => id === item.conversation?.last_message?.id) ? 
            { ...item, conversation: { ...item.conversation, last_message: { ...item.conversation.last_message, read: true } } } : item ) } 
          }),
          ...(!!_find(message_ids, (message) => message === state.dealspace.conversation?.last_message?.id) && 
            { dealspace: { ...state.dealspace, conversation: {...state.dealspace.conversation, last_message: { ...state.dealspace.conversation.last_message, read: true } } } 
          })
        })
      }
    } 
    case clearLocalDealspacesForChatsRoutine.SUCCESS: {
      return { ...state, chats: initialState.chats }
    }
    case clearLocalDealspacesListRoutine.SUCCESS: {
      return { ...state, list: initialState.list }
    }
    case clearLocalDealspacesRoutine.SUCCESS: {
      return { ...state, group: initialState.group }
    }
    case clearLocalNotViewedDealspacesRoutine.SUCCESS: {
      return { ...state, notViewed: initialState.notViewed }
    }
    case clearLocalActiveDealspaceRoutine.SUCCESS: {
      return { ...state, dealspace: initialState.dealspace }
    }
    case createDealspacePropertyRoutine.SUCCESS: {
      return { ...state, dealspace: { ...state.dealspace, properties_count: state.dealspace.properties_count + 1 } };
    }
    case deletePropertyRoutine.SUCCESS: {
      const { response: { status }, reason } = action.payload;
      const dealspace = status === 204 && reason === 'dealspace' ? { ...state.dealspace, properties_count: state.dealspace.properties_count - 1 } : state.dealspace;

      return { ...state, dealspace };
    }
    default: {
      return state;
    }
  };
};

export default reducer;