import SvgIcon from '@mui/material/SvgIcon';

export default function ArchiveIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M16.5071 18.9999H7.49287C6.11677 18.9999 5 17.8832 5 16.5071V8.4501C5 8.31043 5.03992 8.1908 5.13967 8.07115L7.45294 5.21938C7.57258 5.07971 7.73214 5 7.9116 5H16.0683C16.2477 5 16.4073 5.07971 16.5269 5.21938L18.8602 8.07115C18.94 8.17089 18.9999 8.31042 18.9999 8.4501V16.5071C18.9999 17.8832 17.8831 18.9999 16.507 18.9999H16.5071ZM6.19658 8.66947V16.527C6.19658 17.2449 6.77485 17.8232 7.4928 17.8232H16.507C17.225 17.8232 17.8032 17.2449 17.8032 16.527L17.8034 8.66947L15.7891 6.19651H8.21074L6.19658 8.66947Z" />
      <path fill="currentColor" d="M18.3826 9.68153H6.15765C5.82832 9.68153 5.54019 9.45718 5.54019 9.16385C5.54019 8.88774 5.80778 8.64616 6.15765 8.64616H18.3826C18.7119 8.64616 19 8.87052 19 9.16385C19 9.43996 18.7324 9.68153 18.3826 9.68153Z" />
      <path fill="currentColor" d="M11.9998 16.6267C11.6807 16.6267 11.4015 16.3674 11.4015 16.0284V11.0426C11.4015 10.7235 11.6608 10.4443 11.9998 10.4443C12.3189 10.4443 12.5981 10.7036 12.5981 11.0426V16.0284C12.5981 16.3674 12.3189 16.6267 11.9998 16.6267Z" />
      <path fill="currentColor" d="M11.9999 16.6266C11.8403 16.6266 11.7008 16.5668 11.581 16.4472L9.68649 14.5526C9.44722 14.3134 9.44722 13.9543 9.68649 13.715C9.92576 13.4758 10.2848 13.4758 10.5241 13.715L11.9999 15.2108L13.4757 13.7349C13.715 13.4957 14.074 13.4957 14.3133 13.7349C14.5526 13.9742 14.5526 14.3333 14.3133 14.5726L12.4188 16.4671C12.299 16.5667 12.1595 16.6267 11.9999 16.6267V16.6266Z" />
    </SvgIcon>
  );
}
