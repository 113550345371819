import { memo, useCallback, useEffect, useRef, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Actions, Stepper, Step, Bullet, Divider, StepContent, DialogPaperStyles } from './SharingAccess.styled';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import AudioPlayer from 'components/Common/AudioPlayer/AudioPlayer';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import Recipients from '../../Recipients/Sharing/Sharing';
import Notifications from 'components/Recipients/Notifications/Notifications';
import SharingWarningDialog from '../SharingWarning/SharingWarning';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useAudits from 'hooks/useAudits';
import useBatch from 'hooks/useBatch';
import useChameleon from 'hooks/useChameleon';
import useContacts from 'hooks/useContacts';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useMixpanel from 'hooks/useMixpanel';
import useOffers from 'hooks/useOffers';
import useShares from 'hooks/useShares';
import useSuccess from 'hooks/useSuccess';

const SharingAccess = ({ name, headline, offerId, groupId, primarySender, open, onClose }) => {
  const audioRef = useRef(null);
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { createAudit } = useAudits();
  const { sendBatch } = useBatch();
  const { track: trackChameleon } = useChameleon();
  const { getSharedContacts, getSuggestedContacts, clearLocalSharedContacts } = useContacts();
  const { setError } = useError();
  const { track: trackMixpanel } = useMixpanel();
  const { getOfferActualRecipientsCount } = useOffers();
  const { createShare } = useShares();
  const { setSuccess } = useSuccess();
  const { myId, username, firstName, defaultList } = useCustomSelector(state => ({
    myId: state.profile.user.id,
    username: state.profile.user.first_name,
    firstName: state.profile.user.first_name,
    defaultList: state.lists.defaultList
  }));
  const [query, setQuery] = useState('');
  const [activeList, setActiveList] = useState(null);
  const [localOfferId, setLocalOfferId] = useState(''); // Для получения корректного числа реципиентов в onExited
  const [creatableRecipients, setCreatableRecipients] = useState([]);
  const [exclusionRecipients, setExclusionRecipients] = useState([]);
  const [totalUnique, setTotalUnique] = useState(0);
  const [notify, setNotify] = useState(true);
  const [subject, setSubject] = useState({ value: '', error: '' });
  const [message, setMessage] = useState(() => `Hi {{First_name}},<br /><br />Please review the opportunity below and let me know if you have any questions.<br /><br />Sincerely,<br /><br />${firstName}`);
  const [step, setStep] = useState('recipients'); // recipients/notifications
  const [fetching, setFetching] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [needToUpdateRecipientsCount, setNeedToUpdatesRecipientsCount] = useState(false);
  const [sharingWarningDialog, setSharingWarningDialog] = useState({ open: false, name: '' });
  const [advancedSettings, setAdvancedSettings] = useState(null);
  const disabled = processing || !creatableRecipients.length;
  const recipientsProps = { query, fetching, totalUnique, activeList, groupId, creatableRecipients, exclusionRecipients };
  const notificationsProps = { subject, notify, message, allowSettings: true, settings: advancedSettings };

  const fetchContacts = useCallback(({ offset, query, list_ids, offer_id }) => {
    setFetching(true);
    getSharedContacts({ offset, list_ids, offer_id, query })
    .catch(e => setError(e))
    .finally(() => setFetching(false));
  }, [getSharedContacts, setError]);
  const handleEntering = () => {
    setSubject({ value: headline, error: '' });
    setLocalOfferId(offerId);
  };
  const handleExited = () => {
    if (needToUpdateRecipientsCount) {
      getOfferActualRecipientsCount(localOfferId)
      .catch(e => setError(e))
      .finally(() => setLocalOfferId(''));
    }
    setActiveList(null);
    setMessage('');
    setNotify(true);
    setStep('recipients');
    setProcessing(false);
    setFetching(false);
    setCreatableRecipients([]);
    setExclusionRecipients([]);
    setTotalUnique(0);
    clearLocalSharedContacts();
  };
  const handleContactsScroll = newOffset => {
    fetchContacts({
      query,
      offset: newOffset,
      offer_id: offerId,
      lists_ids: [activeList ? activeList.id : defaultList?.id]
    });
  };
  const handleRecipientsChange = ({ reason, data }) => {
    if (reason === 'queryChanged') setQuery(data.query);
    if (reason === 'activeListChanged') setActiveList(data.activeList);
    if (reason === 'selectList') {
      if (!!_find(creatableRecipients, lr => lr.id === data.id)) {
        setCreatableRecipients(prev => _filter(prev, p => p.id !== data.id));
      } else {
        setCreatableRecipients(prev => [...prev, { type: 'list', ...data }]);
      }
    }
    if (reason === 'addItem') {
      setQuery('');
      setCreatableRecipients(prev => [...prev, { type: 'individual', ...data }]);
    }
    if (reason === 'selectItem') {
      if (data.selected) {
        setCreatableRecipients(prev => _filter(prev, p => p.id !== data.id));
        if (!_find(exclusionRecipients, r => r.id === data.id)) setExclusionRecipients(prev => [...prev, data]);
      } else {
        if (activeList) {
          if (!!_find(creatableRecipients, r => r.id === activeList.id)) {
            if (!!_find(exclusionRecipients, r => r.id === data.id)) {
              setExclusionRecipients(prev => _filter(prev, p => p.id !== data.id));
            } else {
              setExclusionRecipients(prev => [...prev, { ...data, listId: activeList.id }]);
            }
          } else {
            const itemFound = !!_find(creatableRecipients, r => r.id === data.id);
    
            setCreatableRecipients(prev => itemFound ? _filter(prev, p => p.id !== data.id) : [...prev, { type: 'individual', ...data }]);
          }
        } else {
          if (!_find(creatableRecipients, r => r.id === data.id)) setCreatableRecipients(prev => [...prev, { type: 'individual', ...data }]);
          setExclusionRecipients(prev => _filter(prev, p => p.id !== data.id))  
        }
      }
    }
    if (reason === 'deleteAll') {
      setCreatableRecipients([]);
      setExclusionRecipients([]);
      setTotalUnique(0);
    }
    if (reason === 'deleteList') {
      setCreatableRecipients(prev => _filter(prev, p => p.id !== data.id));
    }
    if (reason === 'deleteItem') {
      setCreatableRecipients(prev => _filter(prev, p => p.id !== data.id));
    }
    if (reason === 'returnItem') {
      setExclusionRecipients(prev => _filter(prev, p => p.id !== data.id));
    }
  };
  const handleNotificationsChange = ({ s, n, m }) => {
    if (!_isUndefined(s)) setSubject(s);
    if (!_isUndefined(n)) setNotify(n);
    if (!_isUndefined(m)) setMessage(m);
  };
  const handleSharingWarningDialogClose = () => setSharingWarningDialog({ open: false, name: '' });
  const handleSubmit = () => {
    if (notify && !subject.value) {
      setSubject(prev => ({ ...prev, error: 'Subject can\'t be empty' }));
      return;
    }

    if (myId === primarySender.user.id) {
      processRecipients(false);  
    } else {
      setSharingWarningDialog({ open: true, name: `${primarySender.user.first_name} ${primarySender.user.last_name}` });
    }
  };
  const handleDialogSubmit = () => {
    processRecipients(true);
  };
  const processRecipients = closeDialog => {
    const cr = _map(creatableRecipients, r => {
      if (r.type === 'individual') {
        return {
          ...notify && { notify },
          recipient: {
            ...r.invitation && r.invitation.inviter && r.invitation.user && { user_id: r.invitation.inviter.id === myId ? r.invitation.user.id : r.invitation.inviter.id },
            ...r.user && { user_id: r.user.id },
            ...r.email && { email: r.email },
            ...r.first_name && { first_name: r.first_name },
            ...r.last_name && { last_name: r.last_name },
            ...notify && !!subject.value && { subject: subject.value },
            ...notify && message && { message }
          }
        };  
      } else {
        return {
          list_id: r.id,
          ...!!exclusionRecipients.length && { excluded_contact_ids: _map(exclusionRecipients, ({ id }) => id) },
          ...notify && { notify },
          ...notify && !!subject.value && {
            recipient: {
              subject: subject.value,
              ...!!message && { message }
            }
          }
        };
      }
    });
    const requests = _map(cr, r => ({
      method: 'post',
      url: `/offers/${offerId}/recipients`,
      params: r
    }));

    setNeedToUpdatesRecipientsCount(true); // Определяем нужно ли получать актуальное количество реципиентов
    setProcessing(true);
    if (advancedSettings) {
      createShare({ offer_id: offerId, share: _omit(advancedSettings, ['members']) })
      .then(({ payload: { data: { share } } }) =>
        sendBatch(_map(requests, r => ({ ...r, params: { ...r.params, share_id: share.id } })))
        .then(({ payload: { data: { responses } } }) => {
          const failureResponses = _filter(responses, ({ status }) => status < 200 || status > 299);

          if (!!failureResponses.length) {
            setError('Failed to sharing access');
          } else {
            audioRef.current.playNotificationSound();
            trackMixpanel('Share offering', { id: offerId });
            createAudit({ offer_id: offerId, audit_type: 'shared', recipients_count: totalUnique })
            .catch(e => setError(e)
            .finally(() => trackChameleon('Offering shared')));
            setSuccess(`Offering successfully shared to ${totalUnique} private deal feed(s)`);
            if (closeDialog) handleSharingWarningDialogClose();
            if (notify) {
              createAudit({ offer_id: offerId, audit_type: 'email_sent', recipients_count: totalUnique }).catch(e => setError(e));
              setSuccess(`Email notification successfully sent to ${totalUnique} recipients`).then(() => onClose(true));
            } else {
              onClose(true);
            }
          }
        })
        .catch(e => setError(e))
        .finally(() => setProcessing(false))
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false))
    } else {
      sendBatch(requests)
      .then(({ payload: { data: { responses } } }) => {
        const failureResponses = _filter(responses, ({ status }) => status < 200 || status > 299);

        if (!!failureResponses.length) {
          setError('Failed to sharing access');
        } else {
          
          audioRef.current.playNotificationSound();
          trackMixpanel('Share offering', { id: offerId });
          createAudit({ offer_id: offerId, audit_type: 'shared', recipients_count: totalUnique })
          .catch(e => setError(e)
          .finally(() => trackChameleon('Offering shared')));
          setSuccess(`Offering successfully shared to ${totalUnique} private deal feed(s)`);
          if (closeDialog) handleSharingWarningDialogClose();
          if (notify) {
            createAudit({ offer_id: offerId, audit_type: 'email_sent', recipients_count: totalUnique }).catch(e => setError(e));
            setSuccess(`Email notification successfully sent to ${totalUnique} recipients`).then(() => onClose(true));
          } else {
            onClose(true);
          }
        }
      })
      .catch(e => setError(e).then(() => setProcessing(false)));
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (open && defaultList) {
        clearLocalSharedContacts()
        .then(() =>
          fetchContacts({
            query,
            offset: 0,
            offer_id: offerId,
            list_ids: [activeList ? activeList.id : defaultList.id]
          })
        );
      }
    }, query ? 500 : 0);
    
    return () => {
      clearTimeout(timeout);
      clearLocalSharedContacts();
    };
  }, [clearLocalSharedContacts, fetchContacts, activeList, query, open, offerId, defaultList]);
  useEffect(() => {
    const list_ids = _map(_filter(creatableRecipients, cr => cr.type === 'list'), ({ id }) => id);
    const individuals = _filter(creatableRecipients, cr => cr.type === 'individual').length;
    const included_contact_ids = _map(_filter(creatableRecipients, cr => cr.type === 'individual'), ({ id }) => id);
    const excluded_contact_ids = _map(exclusionRecipients, ({ id }) => id);
    
    if (open) {
      if (!!list_ids.length) {
        getSuggestedContacts({ list_ids, included_contact_ids, excluded_contact_ids })
        .then(({ payload: { data } }) => setTotalUnique(data.pagination.total_count))
        .catch(e => setError(e));
      } else {
        setTotalUnique(individuals);
      }
    }
  }, [open, creatableRecipients, exclusionRecipients, getSuggestedContacts, setError]);

  return (
    <BaseDialog
      open={open}
      onClose={() => onClose(false)}
      maxWidth={false}
      PaperProps={{
        sx: DialogPaperStyles,
        onClick: e => e.stopPropagation()
      }}
      onBackdropClick={e => e.stopPropagation()}
      fullScreen={!mediaDesktop}
      TransitionProps={{ onExited: handleExited, onEntering: handleEntering }}
    >
      <SharingWarningDialog
        {...sharingWarningDialog}
        onClose={handleSharingWarningDialogClose}
        onSubmit={handleDialogSubmit}
      />
      <Typography sx={{ fontSize: { xs: '1rem', md: '1.25rem' }, fontWeight: 600, p: '32px 20px 0 40px',  }}>
        <ShareIcon style={{ maxHeight: 20, flexShrink: 0, display: 'inline', marginRight: '8px', marginBottom: '-3px' }} />
        Sharing access to “{name}”
      </Typography>
      <Stepper>
        <Step active={step === 'recipients'}>
          <Bullet active={step === 'recipients'} />
          <span>{totalUnique} contact(s) selected</span>
        </Step>
        <Step active={step === 'notifications'}>
          <Divider />
          <Bullet active={step === 'notifications'} />
          <span>Notifications</span>
        </Step>
      </Stepper>
      <StepContent>
        {step === 'recipients' &&
          <>
            <Recipients
              {...recipientsProps}
              onChange={handleRecipientsChange}
              onScroll={handleContactsScroll}
            />
            <Actions>
              <Typography variant='caption' sx={{ flexGrow: 1, display: 'flex', alignItems: 'baseline', flexWrap: 'wrap', gap: '4px' }}>
                <span><LockIcon style={{ marginRight: '4px', marginBottom: '-2px' }} />You are only accessing:  {username}’s Contacts</span>
              </Typography>
              <Button
                variant='contained'
                color='primary'
                sx={{ minWidth: 156 }}
                onClick={() => setStep('notifications')}
                disabled={disabled}
              >
                Next
              </Button>
            </Actions>
          </>
        }
        {step === 'notifications' &&
          <>
            <Notifications
              {...notificationsProps}
              notifyLabel={<><b style={{ marginRight: '.75rem' }}>Notify recipients with an email</b> (suggested)</>}
              subjectLabel='Email Subject line'
              onChange={handleNotificationsChange}
              onSettingsChange={setAdvancedSettings}
            />
            <Actions>
              <Button
                variant='outlined'
                color='primary'
                sx={{ minWidth: 110 }}
                onClick={() => setStep('recipients')}
              >
                Previous
              </Button>
              <Button
                variant='contained'
                color='primary'
                sx={{ minWidth: 156 }}
                onClick={handleSubmit}
                disabled={disabled}
              >
                Share Now
              </Button>
            </Actions>
          </>
        }
        <AudioPlayer ref={audioRef} />
      </StepContent>
    </BaseDialog>
  );
};

SharingAccess.propTypes = {
  headline: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  offerId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  primarySender: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <SharingAccess {...props} />
    </ErrorBoundary>
  );
};
export const Fallback = () => {
  return (
    <BaseDialog
      open={false}
      onClose={() => {}}
    />
  );
};

export default memo(WrappedComponent);