import { memo, useRef } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
// Local files
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { ReactComponent as CheckIcon } from 'assets/icons/success.svg';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import { representationVariants, dragAndDropItemTypes } from 'helpers/constants';
import { getRepresentation, checkEmptyString } from 'helpers';

const Card = ({ index, id, titleChecked, title, emailChecked, email, mobilePhoneChecked, mobilePhone, user, onChange, onBlur, onMove, onDrop }) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: dragAndDropItemTypes.SENDER,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item, monitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
      onMove(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop(item) {
      onDrop(item)
    }
  });
  const [{ isDragging }, drag] = useDrag({
    type: dragAndDropItemTypes.SENDER,
    item: () => ({ id, index }),
    collect: (monitor) => ({ isDragging: monitor.isDragging() })
  })
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Box
      ref={ref}
      data-handler-id={handlerId}
      position='relative'
      sx={{
        cursor: 'pointer',
        backgroundColor: index === 0 ? 'tertiary.main' : 'tertiary.light',
        width: '100%',
        borderRadius: '8px',
        padding: '20px 24px',
        '&:not(:last-child)': { marginBottom: '12px' },
        opacity
      }}
    >
      <DragIcon style={{ position: 'absolute', left: '8px', top: '25px' }} />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
        <BaseAvatar
          src={getRepresentation(user.photo?.representations, representationVariants.TINY)}
          firstName={user.first_name}
          lastName={user.last_name}
          sx={{ border: '1px solid #fff' }}
        />
        <Typography variant='subtitle2' noWrap sx={{ flexGrow: 1, color: 'rgba(0,0,0,.9)', ml: '6px' }}>
          {user.first_name} {user.last_name}
        </Typography>
        {index === 0 &&
          <>
            <CheckIcon style={{ height: '10px', width: 'auto', color: '#6C95FF'}} />
            <Typography variant='caption' sx={{ color: 'rgba(0,0,0,1)' }}>Primary</Typography>
          </>
        }
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', mt: 2, mb: 1 }}>
        <Checkbox
          color='secondary'
          checked={titleChecked}
          onChange={({ target: { checked } }) => onChange({
            titleChecked: checked,
            ...!checked && { title: { value: '', error: '' } }
          })}
        />
        <TextField
          label='Title'
          variant='standard'
          fullWidth
          value={title.value}
          onChange={({ target: { value } }) => onChange({ title: { value, error: '' } })}
          onBlur={({ target: { value } }) => onBlur({ title: { value, error: !checkEmptyString(value) && titleChecked ? 'Enter title' : '' } })}
          error={!!title.error}
          helperText={title.error}
          inputProps={{ maxLength: 50 }}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', mt: 2, mb: 1 }}>
        <Checkbox
          color='secondary'
          checked={mobilePhoneChecked}
          onChange={({ target: { checked } }) => onChange({
            mobilePhoneChecked: checked,
            ...!checked && { mobilePhone: { value: '', error: '' } }
          })}
        />
        <TextField
          label='Mobile phone'
          variant='standard'
          fullWidth
          value={mobilePhone.value}
          onChange={({ target: { value } }) => onChange({ mobilePhone: { value, error: '' } })}
          onBlur={({ target: { value } }) => onBlur({ mobilePhone: { value, error: !checkEmptyString(value) && mobilePhoneChecked ? 'Enter mobile phone' : '' } })}
          error={!!mobilePhone.error}
          helperText={mobilePhone.error}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', mt: 2, mb: 1 }}>
        <Checkbox
          color='secondary'
          checked={emailChecked}
          onChange={({ target: { checked } }) => onChange({
            emailChecked: checked,
            ...!checked && { email: { value: '', error: '' } }
          })}
        />
        <TextField
          label='Email'
          variant='standard'
          fullWidth
          value={email.value}
          onChange={({ target: { value } }) => onChange({ email: { value, error: '' } })}
          onBlur={({ target: { value } }) => onBlur({ email: { value, error: !checkEmptyString(value) && emailChecked ? 'Enter email' : '' } })}
          error={!!email.error}
          helperText={email.error}
        />
      </Box>
    </Box>
  );
};

Card.propTypes = {
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  titleChecked: PropTypes.bool.isRequired,
  title: PropTypes.shape({
    value: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired
  }),
  emailChecked: PropTypes.bool.isRequired,
  email: PropTypes.shape({
    value: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired
  }),
  mobilePhoneChecked: PropTypes.bool.isRequired,
  mobilePhone: PropTypes.shape({
    value: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired
  }),
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Card {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);