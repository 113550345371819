import { memo, useCallback, useState } from 'react';
import { GoogleMap, Marker, Polygon } from '@react-google-maps/api';
import Box from '@mui/material/Box';
import _find from 'lodash/find';
import _map from 'lodash/map';
// Local files
import GoogleApiWrapper from '../GoogleApiWrapper/GoogleApiWrapper';
import useCustomSelector from 'hooks/useCustomSelector';
import useMap from 'hooks/useMap';

const containerStyle = {
  with: '100%',
  height: '100%'
};
const polygonOptions = {
  fillOpacity: 1,
  strokeOpacity: 1,
  strokeWeight: 0.5,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1
};
// const USA = { north: 49.72, south: 18.82, west: -179.42, east: -66.43 };

const Map = ({ center, zoom, markers = [], polygons = [], options = {}, onClick, onMarkerClick, onPolygonClick = null, onBoundsChange = null }) => {
  const { instance, activePolygons } = useCustomSelector(state => state.map);
  const { setMapInstance } = useMap();
  const [map, setMap] = useState(null);
  const handleLoad = useCallback(mapInstance => {
    if (window.REP?.Layer?.Google) {
      window.REP.Layer.Google.Initialize(mapInstance, { 'Return_Buildings': true, 'Parcels_OutlineColor': '#9DB4F1' });
      window.REP.Layer.Google.MIN_ZOOM = 19;
    }
    setMap(mapInstance);
  }, []);
  const handleIdle = () => {
    if (!instance) setMapInstance(map);
    if (!!onBoundsChange) onBoundsChange(map.getBounds().toJSON());
  };
  const handleUnmount = () => {
    setMap(null);
  };

  return (
    <GoogleApiWrapper type='map'>
      <Box sx={{ width: '100%', height: '100%', backgroundColor: 'LightSteelBlue' }}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoom}
          onLoad={handleLoad}
          onClick={onClick}
          onIdle={handleIdle}
          onUnmount={handleUnmount}
          options={{ ...options }}
        >
          {_map(markers, (m, i) =>
            <Marker
              key={i}
              title={m?.property_id}
              position={{ lat: m.lat, lng: m.lng }}
              onClick={(e) => onMarkerClick(e,m)}
              {...m}
            />
          )}
          {_map(polygons, (p, i) =>
            <Polygon
              key={i}
              paths={p.paths}
              options={{ ...polygonOptions, fillColor: !!_find(activePolygons, ap => ap === p?.id || ap === p?.value) ? 'rgba(226, 255, 47, 0.25)' : 'rgba(35, 97, 255, 0.18)' }}
              onClick={(e) => !!onPolygonClick && onPolygonClick(e,p)}
            />
          )}
        </GoogleMap>
      </Box>
    </GoogleApiWrapper>
  );
};

export default memo(Map);