import { memo, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import _random from 'lodash/random';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { tinyApiKey } from 'apis';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useBlob from 'hooks/useBlob';
import useChecksum from 'hooks/useChecksum';
import useError from 'hooks/useError';
import useResources from 'hooks/useResources';

const Tiny = ({ id, offerId = null, value, plugins, toolbar, toolbarMode = 'wrap', otherInitProps, onChange }) => {
  const editorRef = useRef(null);
  const { processBlob } = useBlob();
  const { processFiles } = useChecksum();
  const { setError } = useError();
  const { createResource } = useResources();
  const [localValue, setLocalValue] = useState(() => value ?? '');

  const handleBlur = () => onChange({
    v: localValue,
    c: editorRef.current.plugins.wordcount?.body?.getCharacterCount() ?? 0,
    t: editorRef.current.getContent({ format: 'text' })
  });
  const imagesUploadHandler = (blobInfo, progress) => new Promise((resolve, reject) => {
    const files = [blobInfo.blob()];
    progress(0);

    try {
      processFiles(files, ({ file, checksum }) => {
        progress(_random(10, 60));
        processBlob({ file, checksum }, blob => {
          if (!blob.id) return;

          progress(_random(60, 90));
          createResource({ resource: { file: blob.id }, offer_id: offerId })
          .then(({ payload: { data: { resource: { file: { url } } } } }) => {
            progress(100);
            resolve(url);
          })
          .catch(e => setError(e).then(() => reject(e)));
        });
      }); 
    } catch (error) {
      reject(error);
    }
  });

  return (
    <Editor
      id={id}
      apiKey={tinyApiKey}
      onInit={(_, editor) => editorRef.current = editor}
      value={localValue}
      onEditorChange={v => setLocalValue(v)}
      onBlur={handleBlur}
      onLoadContent={() => {
        const builderSelector = document.querySelector('.tox-sidebar-wrap');
        const otherSelector = document.querySelector('.css-sgixw5 .tox-sidebar-wrap');
        
        if (builderSelector) builderSelector.style.paddingTop = '5px';
        if (otherSelector) otherSelector.style.paddingTop = '5px';
      }}
      init={{
        plugins,
        toolbar,
        toolbar_mode: toolbarMode,
        images_upload_handler: imagesUploadHandler,
        width: 620,
        content_style: 'body { margin:10px } body::before { font-style: italic }',
        init_instance_callback: e => e.container?.getElementsByClassName('tox-statusbar__wordcount')[0]?.click(),
        ...otherInitProps
      }}
    />
  );
};

Tiny.propTypes = {
  id: PropTypes.string.isRequired,
  offerId: PropTypes.string,
  value: PropTypes.string.isRequired,
  plugins: PropTypes.array.isRequired,
  toolbar: PropTypes.string.isRequired,
  toolbarMode: PropTypes.string,
  otherInitProps: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Tiny {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);