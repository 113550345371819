import { memo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// Local files
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';

const ProtectedUserOrRecipientRoute = ({ children }) => {
  const { setAuthData } = useApp();
  const { pathname } = useLocation();
  const { localSession, profileFetched } = useCustomSelector(state => ({
    localSession: state.sessions.session,
    profileFetched: state.profile.recipient.id || state.profile.user.id
  }));

  if (!localSession.checked) return null;
  if (localSession.accessToken && !profileFetched) return null;
  if (!localSession.accessToken) {
    setAuthData({ redirectUrl: pathname });

    return <Navigate to='/sign_in' />
  };
  return children;
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <ProtectedUserOrRecipientRoute {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);