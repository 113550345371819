import { memo, useState, useCallback } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import _isUndefined from 'lodash/isUndefined';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import ImageUploaderWithVariants from 'components/Blobs/ImageUploaderWithVariants/ImageUploaderWithVariants';
import useApp from 'hooks/useApp';
import useError from 'hooks/useError';
import useProfile from 'hooks/useProfile';
import useSuccess from 'hooks/useSuccess';
import useCustomSelector from 'hooks/useCustomSelector';
import { defaultUserBanners } from 'helpers/constants';

const EditHeader = () => {
  const { closeHeaderDialog } = useApp();
  const { updateProfile } = useProfile();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { open } = useCustomSelector(state => state.app.headerDialog);
  const [image, setImage] = useState({ value: null, formattedValue: null });
  const [processing, setProcessing] = useState(false);

  const onExited = () => setImage({ value: null, formattedValue: null });
  const handleSubmit = () => {
    if (!!image.value) {
      const user = { header: image.value, localHeader: image.formattedValue };

      setProcessing(true);
      updateProfile(user)
      .then(() => setSuccess('Header successfully changed').then(closeHeaderDialog))
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    } else {
      closeHeaderDialog();
    }
  };
  const handleChangeProcessing = useCallback((processing) => setProcessing(processing), []);

  return (
    <BaseDialog
      open={open}
      onClose={closeHeaderDialog}
      otherPaperStyle={{ width: '100%', maxWidth: 430 }}
      title='Change Header'
      TransitionProps={{ onExited }}
      actions={
        <>
          <Button
            sx={{ px: '20px', minHeight: '37px' }}
            color='error'
            disabled={processing}
            onClick={closeHeaderDialog}
          >
            Cancel
          </Button>
          <Button
            sx={{ px: '30px' }}
            variant='contained'
            color='primary'
            disabled={processing}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </>
      }
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: '16px !important', pb: '40px' }}>
        <Typography variant='caption' component='div' align='center' sx={{ fontWeight: 600, pb: '16px' }}>Choose or Upload a picture</Typography>
        <ImageUploaderWithVariants 
          id='header'
          accept='image/*'
          variants={defaultUserBanners} 
          formattedValue={image.formattedValue}
          onChange={({ newValue, newFormattedValue }) => 
            setImage(prev => ({
              ...prev,
              ...(!_isUndefined(newValue) && { value: newValue }),
              ...(!_isUndefined(newFormattedValue) && { formattedValue: newFormattedValue })
            }))
          }
          changeProcessing={handleChangeProcessing}
        />
      </DialogContent>
    </BaseDialog>
  );  
};

export default memo(EditHeader);