import { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Scrollbars from 'react-custom-scrollbars-2';
import _find from 'lodash/find';
import _pick from 'lodash/pick';
import _startCase from 'lodash/startCase';
import { Parser } from 'html-to-react';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Content, Header, Paper, Logo, Photo, Description, Root, SenderAvatar, StyledButton, StyledDivider, Tag } from './Email.styled';
import { ReactComponent as LocationIcon } from 'assets/icons/location-rounded.svg';
import { ReactComponent as DeadlineIcon } from 'assets/icons/offer-deadline.svg';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { getRepresentation, getOfferPriceLabel } from 'helpers';
import { representationVariants } from 'helpers/constants';

const Email = ({ offer, senders, images }) => {
  const primarySender = senders[0];
  const mainImage = _find(images, i => i.main);
  const price = getOfferPriceLabel(_pick(offer, ['lease_period', 'loan_ratio', 'loan_percent', 'investment_type', 'price_cents', 'custom_price']));
  const showFullInfo = offer.email_style === 'full';
  const deadline = !!offer.deadline_at ? Math.round((new Date(offer.deadline_at).getTime() - new Date().getTime()) / 86_400_000) : null;
  const showPropertyName = !offer.locations_locked && offer.properties_count === 1;

  return (
    <Scrollbars>
      <Root>
        <Header>
          <Typography variant='subtitle2' mb='4px'>{offer.headline}</Typography>
          {primarySender && <Typography variant='caption'>{primarySender.user.first_name} {primarySender.user.last_name} via Qwincy</Typography>}
        </Header>
        <Content>
          {primarySender &&
            <>
              {!!primarySender.user.photo && <SenderAvatar src={getRepresentation(primarySender.user.photo.representations, representationVariants.TINY)} />}
              <Typography variant='subtitle1' align='center' mt='16px'>
                {primarySender.user.first_name} {primarySender.user.last_name} shared an Offering with you!
              </Typography>
            </>
          }
          <StyledDivider />
          <Typography variant='caption' sx={{ fontWeight: 200, color: 'rgba(0,0,0,.8)', pt: '20px' }}>
            {'<Your custom message will be inserted here>'}
          </Typography>
          <Paper>
            <Box sx={{ padding: { xs: '24px 12px', md: '47px 25px 40px' } }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '8px', flexWrap: 'wrap' }}>
                <Tag>{offer.tag}</Tag>
                <Typography variant='subtitle3' sx={{ color: 'rgba(81, 81, 93, 1)' }}>
                  {price}
                </Typography>
              </Box>
              <Typography fontWeight={600} fontSize='16px' lineHeight='31px' mt='48px' mb='0px'>
                {offer.headline}
              </Typography>
              <Box display='flex' alignItems='center' mt='10px' mb='23px'>
                {offer.main_location &&
                  <Box display='flex' alignItems='center' gap='2px' width='100%'>
                    <LocationIcon />
                    <Typography variant='caption' component='div' sx={{ color: '#6B6B77', ml: .5 }}>
                      {showPropertyName ? offer.main_location.property.name : offer.main_location.approximate_address}
                    </Typography>
                  </Box>
                }
                {deadline > 0 && deadline <= 30 &&
                  <Box display='flex' alignItems='center' sx={{ width: '100%', justifyContent: 'flex-end', gap: '2px', color: 'error.main' }} >
                    <DeadlineIcon />
                    <Typography variant='caption'>{_startCase(offer.deadline_type)} in {deadline} days</Typography>
                  </Box>
                }
              </Box>
              {showFullInfo && !!offer.logo &&
                <Logo
                  src={offer.logo.url}
                  alt='logo'
                />
              }
              {mainImage &&
                <Box height={450} width={600}>
                  <Photo
                    src={getRepresentation(mainImage.file.representations, representationVariants.MEDIUM)}
                    alt='main'
                  />
                </Box>
              }
              {showFullInfo &&
                <Description>
                  {Parser().parse(offer.description)}
                </Description>
              }
            </Box>
            <Box sx={{ padding: '0px 12px 40px 12px' }}>
              <StyledButton>View Offering</StyledButton>
            </Box>
          </Paper>
          <Box sx={{ pt: '70px', display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#1A1C28' }}>
            <Typography variant='small' sx={{ fontStyle: 'italic' }}>Sent via</Typography>
            <Typography variant='subtitle1'>Qwincy</Typography>
            <Typography variant='caption' sx={{ fontWeight: 500 }}>Collaborative Dealflow Management</Typography>
          </Box>
          <Typography variant='small' pt='12px'>Qwincy, Inc.  |  1100 Brickell Bay Drive, Miami, FL 33132</Typography>
        </Content>
      </Root>
    </Scrollbars>
  );
};

Email.propTypes = {
  offer: PropTypes.shape({
    lease_period: PropTypes.string,
    loan_ratio: PropTypes.string,
    loan_percent: PropTypes.number,
    investment_type: PropTypes.string,
    price_cents: PropTypes.number,
    custom_price: PropTypes.string,
    email_style: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    public: PropTypes.bool.isRequired,
    tag: PropTypes.string,
    description: PropTypes.string,
    main_location: PropTypes.shape({
      address: PropTypes.string
    })
  }),
  senders: PropTypes.array.isRequired,
  images: PropTypes.array.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Email {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);