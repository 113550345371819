import styled from '@mui/material/styles/styled';
import ButtonBase from '@mui/material/ButtonBase';

const Root = styled(ButtonBase)(({ theme }) => ({
  minWidth: 'unset',
  width: 30,
  height: 34,
  position: 'absolute',
  bottom: 48,
  left: 0,
  backgroundColor: theme.palette.background.surface,
  boxShadow: '4px 0px 7px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: '0 5px 5px 0'
}));

export { Root };