import { memo, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import _find from 'lodash/find';
// Local files
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { chatLevels } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useConversations from 'hooks/useConversations';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useSenders from 'hooks/useSenders';

const Conversation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuthData, setChatsFilter } = useApp();
  const { getConversation } = useConversations();
  const { myId, localSession } = useCustomSelector(state => ({
    myId: state.profile.user.id,
    localSession: state.sessions.session
  }));
  const { setError } = useError();
  const { conversation_id } = useParams();
  const { getSenders, clearLocalSenders } = useSenders();

  useEffect(() => {
    if (conversation_id && localSession.checked) {
      if (localSession.userableType === 'user') {
        if (myId) {
          getConversation(conversation_id)
          .then(({ payload: { data: { conversation: { conversationable, conversationable_type } } } }) => {
            if (conversationable_type === 'offer') {
              getSenders({ offset: 0, offer_id: conversationable.id })
              .then(({ payload: { data: { senders } } }) => {
                const isGroup = !!_find(senders, (sender) => sender.user.id === myId);

                setChatsFilter({ filter: { dealspace_id: '', offer_id: '' } })
                .then(() =>
                  navigate(`/chats/${isGroup ? chatLevels.GROUP : chatLevels.USER}/${isGroup ? conversationable?.id : myId}`, {
                    state: { previousLocation: { ...location, pathname: '/dashboard' } }
                  })
                )
              })
              .catch(e => setError(e))
              .finally(clearLocalSenders);
            } else {
              const isDealspace = conversationable_type === 'dealspace';
  
              setChatsFilter({ filter: { dealspace_id: '', offer_id: '' } })
              .then(() =>
                navigate(`/chats/${isDealspace ? chatLevels.DEALSPACE : chatLevels.USER}/${isDealspace ? conversationable?.id : myId}`, {
                  state: { previousLocation: { ...location, pathname: '/dashboard' } }
                })
              )
            }
          })
          .catch(e => setError(e));
        }
      } else {
        // setAuthData({ open: true });
      }
    }
  }, [clearLocalSenders, conversation_id, getConversation, getSenders, localSession, location, myId, setAuthData, setChatsFilter, setError]); // eslint-disable-line

  return null;
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Conversation {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);