import { memo, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Scrollbars from 'react-custom-scrollbars-2';
import Button from '@mui/material/Button';
import _map from 'lodash/map';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useOffers from 'hooks/useOffers';

const SendEmailNotification = () => {
  const { closeSendEmailNotificationDialog } = useApp();
  const { setError } = useError();
  const { getContactActiveOffers, clearLocalContactActiveOffers, reshareActiveOffers } = useOffers();
  const { setSuccess } = useSuccess();
  const { open, email, contactActiveOffers, needToLoadMoreContactActiveOffers, newContactActiveOffersOffset } = useCustomSelector(state => ({
    open: state.app.sendEmailNotificationDialog.open,
    email: state.app.sendEmailNotificationDialog.email,
    contactActiveOffers: state.offers.contactActiveOffers.data,
    needToLoadMoreContactActiveOffers: state.offers.contactActiveOffers.pagination.total_count > state.offers.contactActiveOffers.pagination.count + state.offers.contactActiveOffers.pagination.offset,
    newContactActiveOffersOffset: state.offers.contactActiveOffers.pagination.limit + state.offers.contactActiveOffers.pagination.offset,
  }));
  const [processing, setProcessing] = useState(false);
  const [fetching, setFetching] = useState(false);

  const fetchContactActiveOffers = useCallback((offset, email) => {
    setFetching(true);
    getContactActiveOffers({ offset, recipient_email: email })
    .catch((error) => setError(error))
    .finally(() => setFetching(false))
  }, [getContactActiveOffers, setError]);
  
  const handleSubmit = () => {
    setProcessing(false);
    reshareActiveOffers(email)
    .then(() => setSuccess('Email notification sent').then(handleClose))
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };

  const handleScroll = ({ top }) => {
    if (top > 0.99) {
      if (needToLoadMoreContactActiveOffers && !fetching) {
        fetchContactActiveOffers(newContactActiveOffersOffset, email)
      }
    }
  };

  const handleClose = () => {
    clearLocalContactActiveOffers();
    closeSendEmailNotificationDialog();
  };

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      maxWidth={false}
      PaperProps={{ sx: { maxWidth: 642, width: '100%' } }}
      title=' '
      content={
        <Box pt={1} pb={2} pl={4} pr={4}>
          <Typography fontWeight={600} fontSize='14px' lineHeight='21px' textAlign='center' mb={1.5} color='rgba(0, 0, 0, 0.8)'>
            This contact has been updated. 
          </Typography>
          <Typography fontWeight={600} fontSize='14px' lineHeight='21px' textAlign='center' mb={1.5} color='rgba(0, 0, 0, 0.8)'> 
            The following Offerings have been reshared to the the new email address: 
          </Typography>
          <Box width='100%' height={100}>
            <Scrollbars autoHide autoHideTimeout={1000} onScrollFrame={handleScroll}>
              {_map(contactActiveOffers, o => 
                <Typography key={o.id} fontWeight={600} fontSize='14px' lineHeight='21px' textAlign='center' mb={1.5} color='rgba(0, 0, 0, 0.8)'> 
                  {o.headline}
                </Typography>
              )}
            </Scrollbars>
          </Box>
          <Typography fontWeight={600} fontSize='14px' lineHeight='21px' textAlign='center' mb={1.5} color='rgba(0, 0, 0, 0.8)'>
            Would you like to send the email notification about these Offerings?
          </Typography>
        </Box>
      }
      actions={
        <>
          <Button
            variant='text'
            sx={{ px: '24px' }}
            disabled={processing}
            onClick={handleClose}
          >
            Don’t send
          </Button>
          <Button
            variant='contained'
            color='primary'
            sx={{ px: '24px'}}
            disabled={processing}
            onClick={handleSubmit}
          >
            Send email notification
          </Button>
        </>
      }
    />
  );
};

export default memo(SendEmailNotification);