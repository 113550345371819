import { memo, useState, useCallback } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Scrollbars from 'react-custom-scrollbars-2';
import _find from 'lodash/find';
import _map from 'lodash/map';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import Card from 'components/Lists/Settings/Card/Card';
import DeleteListDialog from '../DeleteList/DeleteList';
import SharingListDialog from '../SharingList/SharingList';
import useCustomSelector from 'hooks/useCustomSelector';
import useApp from 'hooks/useApp';
import useError from 'hooks/useError';
import useLists from 'hooks/useLists';

const ListSettings = ({ open, onClose }) => {
  const { openCreateListDialog } = useApp();
  const { getMyLists } = useLists();
  const { setError } = useError();
  const { lists, totalAmount, needToLoadMore, newOffset } = useCustomSelector(state => ({
    lists: state.lists.my.data,
    totalAmount: state.lists.my.pagination.total_count,
    needToLoadMore: state.lists.my.pagination.offset + state.lists.my.pagination.count < state.lists.my.pagination.total_count && !state.lists.my.loading,
    newOffset: state.lists.my.pagination.limit + state.lists.my.pagination.offset
  }));
  const [deleteDialog, setDeleteDialog] = useState({ id: null, open: false });
  const [sharingDialog, setSharingDialog] = useState({ open: false, id: null });

  const onExited = () => {};
  const fetchLists = useCallback(offset => {
    getMyLists({ offset, not_def: true })
    .catch(e => setError(e));
  }, [getMyLists, setError]);
  const handleShareClick = id => {
    const foundList = _find(lists, l => l.id === id);

    if (!!foundList) {
      setSharingDialog({ open: true, id });
    }
  };
  const handleDeleteClick = id => {
    setDeleteDialog({ id, open: true });
  };
  const handleDeleteDialogClose = () => setDeleteDialog({ id: null, open: false });
  const handleSharingDialogClose = () => setSharingDialog({ open: false, id: null });
  const handleScroll = ({ top }) => {
    if (top > 0.99) {
      if (needToLoadMore) {
        fetchLists(newOffset);
      }
    }
  };
  
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{ sx: { maxWidth: 730, width: '100%' } }}
      title='Manage Contact Lists'
      TransitionProps={{ onExited }}
      actions={<></>}
    >
      <DeleteListDialog {...deleteDialog} onClose={handleDeleteDialogClose} />
      <SharingListDialog {...sharingDialog} onClose={handleSharingDialogClose} />
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: 'min(100%, 570px)', margin: '0 auto' }}>
          <Box display='flex' justifyContent='space-between' width='100%'>
            <Typography fontWeight={500} fontSize='16px' lineHeight='32.32px'>
              Lists ({totalAmount})
            </Typography>
            <Button variant='blank' color='neutral' sx={{ fontSize: '1rem' }} onClick={() => openCreateListDialog()}>
              + New List
            </Button>
          </Box>
          <Box width='100%' height={400} mt='24px'>
            <Scrollbars autoHide autoHideTimeout={1000} onScrollFrame={handleScroll}>
              {_map(lists, (l, i) =>
                <Card
                  key={l.id ?? i}
                  {...l}
                  onShare={handleShareClick}
                  onDelete={handleDeleteClick}
                />
              )}
            </Scrollbars>
          </Box>
        </Box>
      </DialogContent>
    </BaseDialog>
  );
};

export default memo(ListSettings);