import { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
// Local files
import { Input } from './FileInput.styled';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const FileInput = forwardRef(({ accept, id, multiple = false, onChange }, ref) => {
  return (
    <Input
      ref={ref}
      accept={accept}
      id={id}
      type='file'
      multiple={multiple}
      onChange={onChange}
      onClick={event => event.target.value = ''}
    />
  );
});

FileInput.propTypes = {
  accept: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = forwardRef((props, ref) => {
  return (
    <ErrorWrapper>
      <FileInput ref={ref} {...props} />
    </ErrorWrapper>
  );
});

export default memo(WrappedComponent);