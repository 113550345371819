import { memo, useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import _map from 'lodash/map';
import { useNavigate, NavLink } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { StyledNavAction, StyledTab } from '../AppNavigation.styled';
import GroupsIcon from 'components/Common/Icons/GroupsIcon';
import KeyboardArrowDownIcon from 'components/Common/Icons/KeyboardArrowDownIcon';
import GroupAvatar from 'components/Groups/Avatar/Avatar';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';

const Content = ({ data, onClick, onCreate }) => {
  return (
    <List>
      {_map(data, ({ id, logo, name }) =>
        <ListItemButton
          key={id}
          sx={{ '&:hover': { backgroundColor: 'background.hoverDark'} }}
          onClick={() => onClick(id)}
        >
          <GroupAvatar 
            component={NavLink}
            title={name?.slice(0,1)}
            fetching={false}
            src={getRepresentation(logo?.representations, representationVariants.TINY)}
            sx={{ width: '30px !important', height: '30px !important', fontSize: '12px !important', background: '#fff !important', color: '#000 !important' }}
            to={`/groups/${id}`}
          />
          <Typography variant='caption' noWrap ml={1.5}>{name}</Typography>
        </ListItemButton>
      )}
      <ListItemButton sx={{ px: '24px', '&:hover': { backgroundColor: 'background.hoverDark'} }} onClick={onCreate}>
        <AddIcon /> <Typography variant='caption' ml={1.5}>Create</Typography>
      </ListItemButton>
    </List>
  );
};

const DealGroups = ({ mobile = false }) => {
  const navigate = useNavigate();
  const { setAuthData, /*openAccessBetaDialog,*/ openGroupDialog } = useApp();
  const { groups, authorizedAsUser, /*hasRecipientSession, isQwincyMember*/ } = useCustomSelector(state => ({
    groups: state.groups.all.data,
    authorizedAsUser: state.sessions.session.userableType === 'user',
    hasRecipientSession: !!state.profile.recipient.id,
    isQwincyMember: !!state.profile.recipient.user?.email
  }));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMouseOver = ({ currentTarget }) => {
    if (authorizedAsUser) setAnchorEl(currentTarget);
  };
  const checkIsQwincyMember = () => {
    setAuthData({ open: true });
    /**
     * Временное решение
     */
    // (isQwincyMember || !hasRecipientSession) ? setAuthData({ open: true }) : openAccessBetaDialog();
  };
  const handleClick = ({ currentTarget }) => {
    if (authorizedAsUser) {
      setAnchorEl(currentTarget);
    } else {
      checkIsQwincyMember();
    }
  };
  const handleGroupClick = id => {
    setAnchorEl(null);
    navigate(`/groups/${id}/dealspaces`);
  };
  const handleCreateClick = () => {
    setAnchorEl(null);
    openGroupDialog();
  };

  if (!mobile) return (
    <>
      <StyledTab
        component={Button}
        iconPosition="start"
        icon={<GroupsIcon />}
        label={<><span>Deal Groups</span> <KeyboardArrowDownIcon sx={{ opacity: '0.65', height: '16px', mb: '-1px' }} /></>}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        value={0}
      />
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{ variant: 'popup', color: 'dark', sx: { mt: '2px', width: '180px' } }}
      >
        <Content
          data={groups}
          onClick={handleGroupClick}
          onCreate={handleCreateClick}
        />
      </Popover>
    </>
  );
  return (
    <>
      <StyledNavAction
        component={Button}
        label='Deal Groups'
        value={0}
        icon={<GroupsIcon sx={{width: 30, height: 30}} />}
        onClick={handleClick}
      />
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        PaperProps={{ variant: 'popup', color: 'dark', sx: { mt: '-4px', width: '180px' } }}
      >
        <Content
          data={groups}
          onClick={handleGroupClick}
          onCreate={handleCreateClick}
        />
      </Popover>
    </>
  );
};

DealGroups.propTypes = {
  mobile: PropTypes.bool
};

const ErrorFallback = ({ error }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <DealGroups {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);