import { memo } from 'react';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import _map from 'lodash/map';
import { useNavigate } from 'react-router-dom';
// Local files
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';

const Team = ({ activeUser }) => {
  const navigate = useNavigate();

  const handeUserClick = username => navigate(`/users/${username}`);

  return (
    <div>
      <Typography variant='caption' color='text.hint' component='div'>{activeUser.first_name}’s Team</Typography>
      <List>
        {_map(activeUser.teammates.filter(t => !!t.user), ({ id, user }) => (
          <ListItem
            disableGutters key={id}
            sx={{ ...(!!user && { cursor: 'pointer' }) }}
            onClick={() => !!user ? handeUserClick(user.username) : {}}
          >
            <BaseAvatar
              src={getRepresentation(user?.photo?.representations, representationVariants.TINY)}
              firstName={user?.first_name}
              lastName={user.last_name}
              sx={{ width: '36px', height: '36px', marginRight: '16px' }}
            />
            <Typography variant='caption' component='div'>
              {user.first_name} {user.last_name}
            </Typography>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default memo(Team);