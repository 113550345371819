import { memo, forwardRef, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import Skeleton from '@mui/material/Skeleton';
import _startCase from 'lodash/startCase';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import _toUpper from 'lodash/toUpper';
import _find from 'lodash/find';
// Local files
import { Actions, ChipWrapper, DealspaceLink, Header, MobileActions, /*MobileIconAction,*/ MobilePrimaryAction, Title } from './Main.styled';
import { ReactComponent as SavedSuccessIcon } from 'assets/icons/offer-saved-success-badge.svg';
import { ReactComponent as TargetOffIcon } from 'assets/icons/target.svg';
import { ReactComponent as TargetOnIcon } from 'assets/icons/target-colored.svg';
import { ReactComponent as DeadlineRedIcon } from 'assets/icons/offer-deadline.svg';
import { ReactComponent as DeadlineGrayIcon } from 'assets/icons/offer-deadline-gray.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location-small.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu-vert.svg';
import { ReactComponent as SignedIcon } from 'assets/icons/lock-opened-colored.svg';
import { ReactComponent as LockedFilledIcon } from 'assets/icons/lock-filled2.svg';
import { ReactComponent as PropertyIcon } from 'assets/icons/company.svg';
import { ReactComponent as PendingIcon } from 'assets/icons/pending.svg';
import BubbleChat from 'components/Conversations/BubbleChat/BubbleChat';
import KeyboardArrowDownIcon from 'components/Common/Icons/KeyboardArrowDownIcon';
import ArchiveIcon from 'components/Common/Icons/ArchiveIcon';
import PrintIcon from 'components/Common/Icons/PrintIcon';
import GroupAvatar from 'components/Groups/Avatar/Avatar';
import BaseAvatarGroup from 'components/Common/DataDisplay/BaseAvatarGroup/BaseAvatarGroup';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import OfferStatus from 'components/Offers/Status/Status';
import GroupTeam from 'components/Common/DataDisplay/Team/Team';
import DealspaceDialog from 'components/Dialogs/CreateDealspace/CreateDealspace';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { representationVariants } from 'helpers/constants';
import { getOfferPriceLabel, getRepresentation } from 'helpers';
import useApp from 'hooks/useApp';
import useError from 'hooks/useError';
import useTracks from 'hooks/useTracks';
import useOffers from 'hooks/useOffers';
import useSuccess from 'hooks/useSuccess';
import useMembers from 'hooks/useMembers';
import useCustomSelector from 'hooks/useCustomSelector';

const Main = forwardRef((props, ref) => {
  const {
    scrollToFiles, scrollToSenders, scrollToMap, id, tag, primary_sender, locked, saved_dealspace, tracked, headline, status,
    lease_period, disabled, public: p, loan_ratio, loan_percent, investment_type, price_cents, custom_price, main_location, shared_at,
    deadline_at, deadline_type, senders, preview = false, locations_locked, asset_classes, onUnlock = null, approval_required,
    properties_count, risk_profiles, disinterested, isMyShared = false, total_locked_documents_count,
    total_unlocked_documents_count, total_approval_required_documents_count, checking
  } = props;
  const previousLocation = useLocation();
  const navigate = useNavigate();
  const { setAuthData, /*openAccessBetaDialog*/ } = useApp();
  const { disinterestOffer } = useOffers();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { addToTracksOffer, removeFromTracksOffer } = useTracks();
  const { getGroupMembersList, clearLocalGroupMembersList } = useMembers();
  const { authorizedAsUser, groups, myId, /*isQwincyMember, hasRecipientSession*/ } = useCustomSelector(state => ({
    authorizedAsUser: state.sessions.session.userableType === 'user',
    groups: state.groups.all.data,
    myId: state.profile.user.id,
    // isQwincyMember: !!state.profile.recipient.user?.email,
    // hasRecipientSession: !!state.profile.recipient.id
  }));
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [dealspaceDialog, setDealspaceDialog] = useState({ open: false, offerId: null, groupId: null, prefilledName: null });
  const duration = !!shared_at ? `${Math.round((new Date().getTime() - new Date(shared_at).getTime()) / 86_400_000)} days ago` : null;
  const sharedAt = !!shared_at ? new Date(shared_at).toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' }) : null;
  const deadline = !!deadline_at ? Math.round((new Date(deadline_at).getTime() - new Date().getTime()) / 86_400_000) : null;
  const showRedDeadline = !!deadline_at && deadline < 30 && deadline >= 0;
  const showGrayDeadline = !!deadline_at && deadline < 0;
  const formattedDeadline = !!deadline_at ? new Date(deadline_at).toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }) : null;
  const allowSave = !locked && !disinterested && !approval_required;
  const isSaved = !!saved_dealspace?.id;
  const showActions = !isMyShared || preview;
  const verticalFilter = localStorage.getItem('verticalFilter');
  const showBubbleChats = !preview && (!!!_find(['archive', 'market', 'myShared'], (f) => f === verticalFilter) || primary_sender?.user?.id !== myId);
  const isShowLockedIcon = !isMyShared && (locations_locked || total_approval_required_documents_count > 0 || total_locked_documents_count > 0) && !disinterested;
  const filesCount = total_locked_documents_count + total_unlocked_documents_count + total_approval_required_documents_count;

  const checkIsQwincyMember = () => {
    setAuthData({ open: true });
    /**
     * Временное решение
     */
    // (isQwincyMember || !hasRecipientSession) ? setAuthData({ open: true }) : openAccessBetaDialog();
  };
  const handleTargetClick = () => {
    if (authorizedAsUser) {
      if (tracked) {
        setProcessing(true);
        removeFromTracksOffer(id)
        .catch(e => setError(e))
        .finally(() => setProcessing(false));
      } else {
        setProcessing(true);
        addToTracksOffer(id)
        .catch(e => setError(e))
        .finally(() => setProcessing(false));
      }
    } else {
      checkIsQwincyMember();
    }
  };
  const handleSaveClick = groupId => {
    setPopoverAnchorEl(null);
    clearLocalGroupMembersList()
    .then(() => 
      getGroupMembersList({ group_id: groupId, offset: 0 })
      .then(() => {
        const prefilledName = !!main_location
          ? (properties_count === 1
              ? main_location.address
              : (headline.length < 45 ? headline : `${headline.substring(0, 45)}...`)
            )
          : null;

        setDealspaceDialog({ open: true, offerId: id, groupId, prefilledName });
      })
      .catch(e => setError(e))
    );
  };
  const handleSaveMenu = ({ currentTarget }) => {
    if (authorizedAsUser) {
      setPopoverAnchorEl(currentTarget);
    } else {
      checkIsQwincyMember();
    }
  };
  const handleMenuClick = ({ currentTarget }) => { 
    if (authorizedAsUser) {
      setMenuAnchorEl(currentTarget);
    } else {
      checkIsQwincyMember();
    }
  };
  const handleFilesClick = () => scrollToFiles();
  const handleSendersClick = () => scrollToSenders();
  const handleAddressClick = () => scrollToMap();
  const handleArchiveClick = () => {
    setMenuAnchorEl(null);
    disinterestOffer(id)
    .then(() => setSuccess('Offering successfully archived'))
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };
  const handleManageClick = () => navigate(`/offerings/${id}/activity_log`, { state: { previousLocation } });
  const handleDealspaceDialogClose = () => setDealspaceDialog({ open: false, offerId: null, groupId: null, prefilledName: null });

  if (checking) return <Skeleton animation='wave' width='100%' height={50} />;
  if (mediaDesktop) {
    return (
      <>
        <DealspaceDialog
          {...dealspaceDialog}
          onClose={handleDealspaceDialogClose}
        />
        {showBubbleChats && <BubbleChat {...{ checking }} />}
        <Popover
          anchorEl={popoverAnchorEl}
          open={!!popoverAnchorEl}
          onClose={() => setPopoverAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{ variant: 'popup', color: 'dark', sx: { mt: '4px', width: '180px' } }}
        >
          <List>
            {_map(groups, ({ id: group_id, name }) =>
              <ListItemButton
                key={group_id}
                sx={{ '&:hover': { backgroundColor: 'background.hoverDark'} }}
                onClick={() => handleSaveClick(group_id)}
              >
                <Typography variant='caption' noWrap ml={1.5}>{name}</Typography>
              </ListItemButton>
            )}
          </List>
        </Popover>
        <Menu
          anchorEl={menuAnchorEl}
          open={!!menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
          onClick={(event) => { event.stopPropagation() }} /*fix for open full view on mobile*/
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          PaperProps={{ variant: 'popup', color: 'dark', sx: { mt: '2px', width: '180px' } }}
        >
          {!p && !disinterested &&
            <MenuItem
              sx={{ px: '24px', '&:hover': { backgroundColor: 'background.hoverDark'} }}
              disabled={processing || disabled}
              onClick={handleArchiveClick}
            >
              <ArchiveIcon /> <Typography variant='caption' ml={1.5}>Archive</Typography>
            </MenuItem>
          }
          <MenuItem
            sx={{ px: '24px', '&:hover': { backgroundColor: 'background.hoverDark'} }}
            disabled={processing || disabled}
          >
            <PrintIcon /> <Typography variant='caption' ml={1.5}>Print</Typography>
          </MenuItem>
        </Menu>
        <Header>
          {!!primary_sender &&
            <BaseAvatarGroup max={4} onClick={handleSendersClick} sx={{ cursor: 'pointer' }}>
              {_map(_orderBy(senders, ['position'], ['asc']), s => 
                <BaseAvatar
                  key={s?.user?.id}
                  src={getRepresentation(s?.user?.photo?.representations, representationVariants.TINY)}
                  firstName={s?.user?.first_name}
                  lastName={s?.user?.last_name}
                />
              )}
            </BaseAvatarGroup>
            }
          {!!primary_sender &&
            <Typography variant='caption' noWrap sx={{ color: 'rgba(0,0,0,.7)', fontWeight: 500, cursor: 'pointer' }} onClick={handleSendersClick}>
              {primary_sender.user.first_name} {primary_sender.user.last_name} {senders.length > 1 ? `+${senders.length - 1}` : ''}
            </Typography>
          }
          <Box flexGrow={1} />
          <Actions>
            {isShowLockedIcon && !locked && approval_required && <PendingIcon />}
            {isShowLockedIcon &&
              <>    
                {locked && !isMyShared ? 
                  <>
                    <LockedFilledIcon style={{ width: 16, height: 16, marginRight: 10 }} /> 
                    <Button
                      disabled={processing || disabled}
                      variant='contained'
                      color='primary'
                      onClick={onUnlock}
                    >
                      <Typography variant='caption' noWrap>Sign CA</Typography>
                    </Button>
                  </> :
                  <Button
                    disabled={processing || disabled}
                    variant='blank'
                    color='black'
                    component='div'
                    sx={{ px: 0, cursor: 'pointer' }}
                    onClick={onUnlock}
                  >
                    <SignedIcon style={{ width: 16, height: 16 }} />
                  </Button>
                }
              </>    
            }
            {showActions && !disinterested && <>
              {isSaved &&
                <DealspaceLink
                  component={NavLink}
                  to={`/dealspaces/${saved_dealspace?.id}/files`}
                >
                  <GroupAvatar 
                    src={getRepresentation(saved_dealspace?.group?.logo?.representations, representationVariants.TINY)} 
                    title={_toUpper(saved_dealspace?.group?.name[0])} 
                    fetching={processing || disabled}
                  />
                  <SavedSuccessIcon />
                </DealspaceLink>
              }
              {allowSave &&
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleSaveMenu}
                  disabled={processing || disabled}
                  endIcon={<KeyboardArrowDownIcon sx={{ mx: '-3px' }} />}
                >
                  <Typography variant='caption' noWrap>Save to my Pipeline</Typography>
                </Button>
              }
              <Button
                variant='outlined'
                color='neutral'
                onClick={handleTargetClick}
                disabled={processing || disabled}
              >
                {tracked ? <TargetOnIcon /> : <TargetOffIcon style={{ color: 'rgba(0,0,0,.7)' }} /> }
              </Button>
              <Button
                disabled={processing || disabled}
                variant='outlined'
                color='neutral'
                onClick={handleMenuClick}
              >
                <MenuIcon style={{ color: '#272B2F'}} />
              </Button>
            </>}
            {!showActions && !disinterested &&
            <Button
              variant='contained'
              color='primary'
              onClick={handleManageClick}
              disabled={processing || disabled}
            >
              Manage my Offering
            </Button>}
          </Actions>
        </Header>
        <Container ref={ref} maxWidth={false}>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: '4px', overflow: 'hidden', pt: '12px', pb: '32px' }}>
            <OfferStatus value={status} />
            <Box display='flex' gap='4px' maxWidth='70%' overflow='hidden' borderRadius={16}>
              {tag &&
                <ChipWrapper>
                  <Chip
                    label={tag}
                    size='small'
                    color='secondary'
                    sx={{ flexShrink: 1, '&:not(:first-of-type)': { ml: '6px', mr: '6px' } }}
                  />
                </ChipWrapper>
              }
              {(!!asset_classes.length || !!risk_profiles.length) &&
                <>
                  {_map([...asset_classes, ...risk_profiles], (label) => <ChipWrapper key={label}><Chip label={_startCase(label)} size='small' color='tertiary' sx={{ flexShrink: 1 }} /></ChipWrapper>)}
                </>
              }
            </Box>
            {!!filesCount &&
              <Chip
                size='small'
                color='tertiary'
                sx={{ flexShrink: 1 }}
                onClick={handleFilesClick}
                label={<Box sx={{ display: 'flex', alignItems: 'center'}}><span>Files</span><ArrowDropDownIcon sx={{ mr: '-8px', height: 20 }} /></Box>}
              />
            }
            <Typography variant='subtitle2' component='div' sx={{ fontSize: '1rem', whiteSpace: 'nowrap', ml: 'auto'}}>
              {getOfferPriceLabel({ lease_period, loan_ratio, loan_percent, investment_type, price_cents, custom_price })}
            </Typography>
          </Box>
          <Title>{headline}</Title>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px', pt: '8px', pb: '14px'}}>
            {!!main_location && <Box sx={{ display: 'flex', alignItems: 'flex-start', color: 'rgba(0,0,0,.7)', cursor: 'pointer' }} onClick={handleAddressClick}>
              { properties_count > 1 ?
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <PropertyIcon style={{ flexShrink: 0 }} />
                <Typography variant='caption' color='textSecondary' noWrap sx={{ flexGrow: 1 }}>Properties {properties_count}</Typography>
              </Box> : <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                {(locations_locked && locked && !isMyShared) ? <LockedFilledIcon style={{ width: 12, height: 12 }} /> : <LocationIcon style={{ flexShrink: 0 }} />}
                <Typography variant='caption' sx={{ ml: .5 }}>{main_location.address || main_location.approximate_address}</Typography>
              </Box>}
            </Box>}
            <Box sx={{ display: 'flex', flexDirection: 'column',  alignItems: 'flex-end' }}>
              {sharedAt &&
                <Box sx={{ display: 'flex', alignItems: 'center', color: 'rgba(0,0,0,.7)' }}>
                  <Typography variant='caption' sx={{ fontWeight: 400, whiteSpace: 'nowrap' }}>
                    {sharedAt}
                  </Typography>
                  <Typography variant='caption' sx={{ fontWeight: 200, whiteSpace: 'nowrap' }} ml={1}>
                    {duration}
                  </Typography>
                </Box>
              }
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', color: 'error.main' }}>
                {showRedDeadline &&
                  <Box display='flex' alignItems='center' sx={{ width: '100%', justifyContent: 'flex-end', gap: '2px', color: 'error.main' }} >
                    <DeadlineRedIcon />
                    <Typography variant='caption'>{_startCase(deadline_type)} in {deadline} days</Typography>
                  </Box>
                }
                {showGrayDeadline &&
                  <Box display='flex' alignItems='center' sx={{ width: '100%', justifyContent: 'flex-end', gap: '2px', color: 'action.active'}}>
                    <DeadlineGrayIcon color='action' />
                    <Typography variant='caption'>
                      {_startCase(deadline_type)} passed {formattedDeadline}
                    </Typography>
                  </Box>
                }
              </Box>
            </Box>
          </Box>
        </Container>
      </>
    );
  }
  return (
    <>
      <DealspaceDialog
        {...dealspaceDialog}
        onClose={handleDealspaceDialogClose}
      />
      <Container ref={ref} maxWidth={false}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px', py: '12px' }}>
          <OfferStatus value={status} withoutText={!mediaDesktop} />
          {tag && <Chip label={tag} size='small' color='secondary' sx={{ flexShrink: 1, ml: '4px', mr: 1 }} />}
          {(!!asset_classes.length || !!risk_profiles.length) && <Box sx={{ width: '90%', mb: '4px' }}>
            {_map([...asset_classes, ...risk_profiles], (label) => <Chip key={label} label={_startCase(label)} size='small' color='tertiary' sx={{ flexShrink: 1 }} />)}
          </Box>}
          <Box sx={{ cursor: 'pointer', ml: 'auto' }} onClick={handleSendersClick}>
            <GroupTeam members={senders} />
          </Box>
        </Box>
        <Title sx={{ pt: '16px', pb: '8px' }}>{headline}</Title>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', gap: '8px', pb: '16px' }}>
          {!!main_location && <Box sx={{ display: 'flex', alignItems: 'flex-start', color: 'rgba(0,0,0,.7)', cursor: 'pointer' }} onClick={handleAddressClick}>
            {properties_count > 1 ?
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <PropertyIcon style={{ flexShrink: 0 }} />
              <Typography variant='caption' color='textSecondary' noWrap sx={{ flexGrow: 1 }}>Properties {properties_count}</Typography>
            </Box> : <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '2px' }}>
              {locations_locked && locked ? <LockedFilledIcon style={{ width: 12, height: 12 }} /> : <LocationIcon style={{ flexShrink: 0, marginTop: '4px' }} />}
              <Typography variant='caption' sx={{ ml: .5 }}>{main_location.address || main_location.approximate_address}</Typography>
            </Box>}
          </Box>}
          <Typography variant='subtitle3' component='div' sx={{ whiteSpace: 'nowrap', ml: 'auto'}}>
            {getOfferPriceLabel({ lease_period, loan_ratio, loan_percent, investment_type, price_cents, custom_price })}
          </Typography>
        </Box>
      </Container>
      <MobileActions>
        {showActions && !disinterested &&
          <>
            {/*<MobileIconAction
              disabled={disabled}
              onClick={handleTargetClick}
            >
              {tracked ? <TargetOnIcon /> : <TargetOffIcon style={{ color: 'rgba(0,0,0,.7)' }} />}
            </MobileIconAction>*/}
            {/*{isSaved &&
              <DealspaceLink
                component={NavLink}
                to={`/dealspaces/${saved_dealspace?.id}/files`}
                sx={{ height: 34, width: 34 }}
              >
                <GroupAvatar
                  src={getRepresentation(saved_dealspace?.group?.logo?.representations, representationVariants.TINY)} 
                  title={_toUpper(saved_dealspace?.name[0])} 
                  fetching={processing || disabled}
                />
                <SavedSuccessIcon />
              </DealspaceLink>
            }*/}
            {isShowLockedIcon && locked && !isMyShared &&
              <MobilePrimaryAction
                disabled={processing || disabled}
                variant='contained'
                color='primary'
                onClick={onUnlock}
              >
                Sign CA
              </MobilePrimaryAction>
            }
            {allowSave &&
              <MobilePrimaryAction
                disabled={disabled}
                variant='contained'
                color='primary'
                onClick={handleSaveMenu}
              >
                Save
              </MobilePrimaryAction>
            }
            {showBubbleChats && <BubbleChat {...{ checking }} />}
          </>
        }
        {!showActions && !disinterested &&
          <MobilePrimaryAction
            variant='contained'
            color='primary'
            onClick={handleManageClick}
            disabled={processing || disabled}
          >
            Manage my Offering
          </MobilePrimaryAction>
        }
      </MobileActions>
      <Popover
        anchorEl={popoverAnchorEl}
        open={!!popoverAnchorEl}
        onClose={() => setPopoverAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        PaperProps={{ variant: 'popup', color: 'dark', sx: { mt: '4px', width: '180px' } }}
      >
        <List>
          {_map(groups, ({ id: group_id, name }) =>
            <ListItemButton
              key={group_id}
              sx={{ '&:hover': { backgroundColor: 'background.hoverDark'} }}
              onClick={() => handleSaveClick(group_id)}
            >
              <Typography variant='caption' noWrap ml={1.5}>{name}</Typography>
            </ListItemButton>
          )}
        </List>
      </Popover>
    </>
  );
});

Main.propTypes = {
  scrollToFiles: PropTypes.func.isRequired,
  scrollToSenders: PropTypes.func.isRequired,
  scrollToMap: PropTypes.func.isRequired,
  primary_sender: PropTypes.shape({
    user: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      photo: PropTypes.object
    })
  }),
  tag: PropTypes.string,
  locked: PropTypes.bool.isRequired,
  saved_dealspace: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  tracked: PropTypes.bool.isRequired,
  headline: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  lease_period: PropTypes.string,
  loan_ratio: PropTypes.string,
  loan_percent: PropTypes.number,
  investment_type: PropTypes.string,
  price_cents: PropTypes.number,
  custom_price: PropTypes.string,
  main_location: PropTypes.shape({
    address: PropTypes.string
  }),
  shared_at: PropTypes.string.isRequired,
  deadline_at: PropTypes.string,
  deadline_type: PropTypes.string,
  total_locked_documents_count: PropTypes.number.isRequired,
  total_unlocked_documents_count: PropTypes.number.isRequired,
  total_approval_required_documents_count: PropTypes.number.isRequired,
  senders: PropTypes.array.isRequired,
  preview: PropTypes.bool
};

const WrappedComponent = forwardRef((props, ref) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Main {...props} ref={ref} />
    </ErrorBoundary>
  );
});
export const Fallback = () => {
  return (
    <Skeleton
      variant='rectangular'
      animation='wave'
      width={200}
      height={50}
    />
  );
};

export default memo(WrappedComponent);