import { memo, useCallback, useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import DeleteIcon from '@mui/icons-material/Delete';
import _drop from 'lodash/drop';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
// Local files
import { Container, Title, CancelButton, Content, ItemName, Error, Count } from './Snackbar.styled';
import { ReactComponent as FolderIcon } from 'assets/icons/folder.svg';
import useBlob from 'hooks/useBlob';
import useChecksum from 'hooks/useChecksum';
import useCustomSelector from 'hooks/useCustomSelector';
import useDocuments from 'hooks/useDocuments';
import useError from 'hooks/useError';
import useFolders from 'hooks/useFolders';

const ContentSnackbar = () => {
  const { processBlob, processBlobAsync } = useBlob();
  const { processFile, processFileAsync } = useChecksum();
  const { createDealspaceDocument } = useDocuments();
  const { setError } = useError();
  const { getActualFolderData, createFolder, hideUploadingItems, removeUploadingItem, clearUploadingItems } = useFolders();
  const { data, silent } = useCustomSelector(state => state.folders.snackbar);
  const [processableItems, setProcessableItems] = useState([]);
  const open = !!processableItems.length && !silent;

  const handleClose = () => hideUploadingItems();
  const handleCancelClick = () => clearUploadingItems();
  const uploadFile = useCallback(item => {
    processFile(item.file, ({ file, checksum }) => {
      processBlob({ file, checksum }, blob => {
        if (blob.progress) {
          setProcessableItems(prev => _map(prev, p => p.id === item.id ? ({ ...p, progress: blob.progress }) : p));
        } else {
          const document = { name: blob.filename, file: blob.id };

          createDealspaceDocument({ folder_id: item.folder_id, document })
          .then(() => {
            removeUploadingItem(item.id);
            setProcessableItems(prev => _filter(prev, p => p.id !== item.id));
          })
          .catch(e => {
            setProcessableItems(prev => _map(prev, p => p.id === item.id ? ({ ...p, status: 'failed' }) : p));
            setError(e);
          });
        }
      });
    });
  }, [createDealspaceDocument, processBlob, processFile, removeUploadingItem, setError]);
  /**
   * 
   * @param {string} folderId
   * @param {string} path
   * @param {array} createdFolders
   * @param {bool=} initial
   * @return {object}
   */
  const createFolders = async ({ folderId, path, createdFolders, initial = false }) => {
    const folders = path.split('/');
    const alreadyCreatedFolder = _find(createdFolders, f => f.name === folders[0]);

    if (alreadyCreatedFolder) {
      if (folders.length > 1) {
        return createFolders({
          folderId: alreadyCreatedFolder.id,
          path: _drop(folders).join('/'),
          createdFolders,
          initial: false
        });
      } else {
        return null;
      }
    } else {
      const r = await createFolder({ folder_id: folderId, folder: { name: folders[0] }, initial }).catch(e => setError(e));

      if (folders.length > 1) {
        return createFolders({
          folderId: r.payload.response.data.folder.id,
          path: _drop(folders).join('/'),
          createdFolders: [...createdFolders, r.payload.response.data.folder],
          initial: false
        });
      } else {
        return {
          folder: r.payload.response.data.folder,
          createdFolders: [...createdFolders, r.payload.response.data.folder],
          ...initial && { root: r.payload.response.data.folder.id }
        };
      } 
    }
  };
  const uploadFiles = useCallback(async ({ item, files, root, folderId }) => {
    for (const f of files) {
      const { file, checksum } = await processFileAsync(f);
      const blob = await processBlobAsync({ file, checksum });

      const document = { name: blob.filename, file: blob.id };

      createDealspaceDocument({ folder_id: folderId, document, skip: true })
      .then(() => {
        setProcessableItems(prev => {
          const fi = _find(prev, p => p.id === item.id);

          if (fi && fi.completed === fi.all - 1) {
            getActualFolderData(root)
            .catch(e => setError(e))
            .finally(() => {
              removeUploadingItem(item.id);
              setProcessableItems(prev => _filter(prev, p => p.id !== item.id));
            });
          }
          return _map(prev, p => p.id === item.id ? ({ ...p, completed: p.completed + 1, progress: p.all / (p.completed + 1) * 100 }) : p)
        });
      })
      .catch(e => setError(e));
    };
  }, [createDealspaceDocument, getActualFolderData, processBlobAsync, processFileAsync, removeUploadingItem, setError]);
  const uploadFolder = useCallback(async item => {
    let rootFolder = null;
    let createdFolders = [];

    for (const d of _orderBy(item.data, ['depth'], ['asc'])) {
      const res = await createFolders({ folderId: item.folder_id, path: d.path, createdFolders, initial: true });
      createdFolders = res.createdFolders;
      if (res.root) rootFolder = res.root;

      uploadFiles({ item, files: d.files, root: rootFolder, folderId: res.folder.id });
    }
  }, [uploadFiles]); // eslint-disable-line
  const processItem = useCallback(item => {
    if (item.type === 'file') uploadFile(item);
    if (item.type === 'folder') uploadFolder(item);
  }, [uploadFile, uploadFolder]);
  const handleRetryClick = id => {
    const foundItem = _find(processableItems, pi => pi.id === id);

    if (!!foundItem) uploadFile(foundItem);
  };
  const handleDeleteClick = id => setProcessableItems(prev => _filter(prev, p => p.id !== id));

  useEffect(() => {
    _forEach(data, d => {
      setProcessableItems(prev => {
        const itemFound = _find(prev, p => p.id === d.id)
        
        if (!itemFound) {
          processItem(d);

          return [
            ...prev,
            ({
              ...d,
              ...d.type === 'folder' && { all: d.length, completed: 0 },
              progress: 0,
              status: 'uploading'
            })
          ];
        } else {
          return prev;
        }
      });
    });
  }, [data, processableItems, processItem]);

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      onClose={null}
    >
      <Container>
        <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
          <Title>File Uploader</Title>
          <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
          <IconButton onClick={handleClose} sx={{ pr: '14px' }}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </Box>
        <Content>
          {_map(processableItems, ({ id, type, name, all, completed, progress, status }) =>
            <Box key={id} display='flex' justifyContent='space-between' gap={5} mb='12px'>
              <Box sx={{ maxWidth: '75%' }} display='flex' gap='7px'>
                {type === 'folder' && <FolderIcon />}
                <ItemName>&#8220;{name}&#8221;</ItemName>
              </Box>
              {status === 'uploading' &&
                <Box display='flex' gap='28px'>
                  {type === 'folder' && <Count>{completed} of {all}</Count>}
                  <CircularProgress
                    variant='determinate'
                    value={progress}
                    size={20}
                    sx={{ color: 'rgba(59, 175, 253, 1)' }}
                  />
                </Box>
              }
              {status === 'failed' &&
                <Box display='flex' gap='5px' alignItems='center'>
                  <Error>failed</Error>
                  <ReplayIcon
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleRetryClick(id)}
                  />
                  <DeleteIcon
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleDeleteClick(id)}
                  />
                </Box>
              }
            </Box>
          )}
        </Content>
      </Container>
    </Snackbar>
  );
};

export default memo(ContentSnackbar);