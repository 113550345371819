import { memo } from 'react';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import _find from 'lodash/find';
import _startCase from 'lodash/startCase';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Root, ChipsContainer, MainImage } from './MapCard.styled';
import { ReactComponent as TargetOffIcon } from 'assets/icons/target.svg';
import { ReactComponent as TargetIcon } from 'assets/icons/target-colored.svg';
import { ReactComponent as DeadlineRedIcon } from 'assets/icons/offer-deadline.svg';
import { ReactComponent as DeadlineGrayIcon } from 'assets/icons/offer-deadline-gray.svg';
import { ReactComponent as SavedIcon } from 'assets/icons/offer-saved-colored.svg';
import { ReactComponent as SignedIcon } from 'assets/icons/lock-opened-colored.svg';
import { ReactComponent as LockedFilledIcon } from 'assets/icons/lock-filled.svg';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import Status from 'components/Offers/Status/Status';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';

/**
 * @param {string} id
 * @param {bool} tracked
 * @param {object=} saved_dealspace
 * @param {bool} visited
 * @param {string=} tag
 * @param {array=} asset_classes
 * @param {object=} main_location
 * @param {string} headline
 * @param {string=} published_at
 * @param {string=} shared_at
 * @param {object} primary_sender
 * @param {number} senders_count
 * @param {string=} deadline_at
 * @param {string} status
 * @param {bool} disabled
 * @param {bool} locked
 * @param {number} total_locked_documents_count
 * @param {bool} locations_locked
 * @param {bool} disinterested
 * @param {array=} risk_profiles
 * @param {object=} mainImage
 * @param {object} sx
 * @param {object} current_sender
 * @returns {node}
 */

const Card = ({ 
  id, tracked, saved_dealspace, visited, tag, asset_classes, main_location, headline, published_at, shared_at, primary_sender, sent,
  senders_count, deadline_at, status, disabled, locked, total_locked_documents_count, total_approval_required_documents_count, locations_locked,
  disinterested, risk_profiles, mainImage, sx = null
}) => {
  const navigate = useNavigate();
  const { offer_id } = useParams();
  const { /*openAccessBetaDialog,*/ setAuthData } = useApp();
  //  Это костыль, чтобы в табе shared with me показывать все оферы как просмотренные изначально, сделан на скорую ибо нужно оч много менять логики на бэке.
  const { /*hasRecipientSession, isQwincyMember,*/ authorizedAsUser, sharedWithMe, archive, myShared, market } = useCustomSelector(state => ({
    // hasRecipientSession: !!state.profile.recipient.id,
    // isQwincyMember: !!state.profile.recipient.user?.email,
    authorizedAsUser: state.sessions.session.userableType === 'user',
    sharedWithMe: state.offers.dealflow.sharedWithMe.all.data,
    archive: state.offers.dealflow.archive.all.data,
    myShared: state.offers.dealflow.myShared.all.data,
    market: state.offers.dealflow.market.all.data
  }));
  const selected = id === offer_id;
  const showTargetIcon = !sent;
  const showSavedIcon = !sent && !!saved_dealspace
  const cardBackground = selected ? 'linear-gradient(109.11deg, #F0F6FF 18.48%, #E1ECFD 86.38%)' : (visited || sent) ? '#F1F3F6' : '#fff';
  const cardBorderColor = selected ? '#4786FF' : (visited || sent) ? '#F1F3F6' : '#fff';
  const showMainDate = !!published_at || !!shared_at;
  const deadline = !!deadline_at ? Math.round((new Date(deadline_at).getTime() - new Date().getTime()) / 86_400_000) : false;
  const showRedDeadline = !!deadline_at && deadline < 30 && deadline >= 0;
  const showGrayDeadline = !!deadline_at && deadline < 0;
  const showLockedIcon = !sent && (total_locked_documents_count > 0 || total_approval_required_documents_count > 0 || locations_locked) && !disinterested;

  const handleClick = () => {
    const foundSharedWithMe = !!_find(sharedWithMe, o => o.id === id);
    const foundArchive = !!_find(archive, o => o.id === id);
    const foundMyShared = !!_find(myShared, o => o.id === id);
    const foundMarket = !!_find(market, o => o.id === id);

    if (offer_id !== id) {
      let vertical = null;
      let horizontal = null;

      if (foundSharedWithMe) {
        vertical = 'sharedWithMe';
        horizontal = 'all';
      }
      if (foundArchive) {
        vertical = 'archive';
        horizontal = 'all';
      }
      if (foundMyShared) {
        vertical = 'myShared';
        horizontal = 'all';
      }
      if (foundMarket) {
        vertical = 'market';
        horizontal = 'all';
      }
      
      navigate(`/deal_flow/${id}`, { state: { ...vertical && { vertical }, ...horizontal && { horizontal } } });
    }
  };
  const checkIsQwincyMember = () => {
    setAuthData({ open: true });
    /**
     * Временное решение
     */
    // (isQwincyMember || !hasRecipientSession) ? setAuthData({ open: true }) : openAccessBetaDialog();
  };

  if (!id) return null;
  return (
    <Root sx={{ background: cardBackground, borderColor: cardBorderColor, ...sx }}>
      <ChipsContainer>
        {showTargetIcon && (tracked ? <TargetIcon /> : <TargetOffIcon style={{ color: 'rgba(0,0,0,.3)' }} />)}
        <Status value={status} withoutText />
        <Box
          sx={{
            display: 'flex',
            width: '99%',
            gap: '4px',
            overflow: 'hidden',
            borderRadius: 16,
            position: 'relative'
          }}
        >
          {!!tag &&
            <Typography component='div'>
              <Chip label={tag} size='small' color='secondary' />
            </Typography>
          }
          {!!asset_classes?.length &&
            <Typography component='div'>
              <Chip label={_startCase(asset_classes[0])} size='small' color='tertiary' />
            </Typography>
          }
          {!!risk_profiles?.length &&
            <Typography component='div'>
              <Chip label={_startCase(risk_profiles[0])} size='small' color='tertiary' />
            </Typography>
          }
        </Box>
        <Box flex='1 1' />
        {showSavedIcon && <SavedIcon />}
        {showRedDeadline && <DeadlineRedIcon />}
        {showGrayDeadline && <DeadlineGrayIcon />}
      </ChipsContainer>
      <Box display='flex' mb={.5}>
        <Typography variant='caption' noWrap sx={{ flexGrow: 1, fontWeight: 500 }}>{headline}</Typography>
        {showMainDate &&
          <Typography variant='caption'>
            {new Date(sent ? shared_at : published_at).toLocaleString('en-US', { year: '2-digit', month: 'numeric', day: 'numeric' })}
          </Typography>
        }
      </Box>
      {!!mainImage &&
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <MainImage
            src={getRepresentation(mainImage?.file?.representations, representationVariants.TINY)}
            alt='mainImage-map'
            loading='lazy'
          />
        </Box>
      }
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', justifyContent: 'space-between' }}>
        <BaseAvatar
          src={getRepresentation(primary_sender.user.photo?.representations, representationVariants.TINY)}
          firstName={primary_sender.user.first_name ?? ''}
          lastName={primary_sender.user.last_name ?? ''}
          sx={{ flexShrink: 0, width: '19px', height: '19px', fontSize: '10px' }}
        />
        <Typography variant='small' sx={{ flexShrink: 0 }}>
          {primary_sender.user.first_name} {primary_sender.user.last_name} {senders_count > 1 ? `+${senders_count - 1}` : ''}
        </Typography>
        <Box flexGrow={1} />
        <Typography variant='small' noWrap>
          {main_location.address || main_location.approximate_address}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
          {showLockedIcon && (locked && !sent ? <LockedFilledIcon /> : <SignedIcon />)}
          <Button
            variant='contained'
            disabled={disabled}
            onClick={() => authorizedAsUser ? handleClick() : checkIsQwincyMember()}
          >
            Go to
          </Button>
        </Box>
      </Box>
    </Root>
  );
};

Card.propTypes = {
  id: PropTypes.string.isRequired,
  tracked: PropTypes.bool.isRequired,
  saved_dealspace: PropTypes.object,
  visited: PropTypes.bool.isRequired,
  tag: PropTypes.string,
  asset_classes: PropTypes.array,
  main_location: PropTypes.shape({
    address: PropTypes.string,
    approximate_address: PropTypes.string
  }),
  headline: PropTypes.string.isRequired,
  published_at: PropTypes.string,
  shared_at: PropTypes.string,
  primary_sender: PropTypes.shape({
    user: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      photo: PropTypes.shape({
        representations: PropTypes.array
      })
    })
  }),
  senders_count: PropTypes.number.isRequired,
  deadline_at: PropTypes.string,
  status: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  locked: PropTypes.bool.isRequired,
  total_locked_documents_count: PropTypes.number.isRequired,
  total_approval_required_documents_count: PropTypes.number.isRequired,
  locations_locked: PropTypes.bool.isRequired,
  disinterested: PropTypes.bool.isRequired,
  risk_profiles: PropTypes.array,
  mainImage: PropTypes.shape({
    file: PropTypes.shape({
      representations: PropTypes.array
    })
  }),
  sx: PropTypes.object,
  current_sender: PropTypes.object
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Card {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);