import { memo, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Badge from '@mui/material/Badge';
import _isUndefined from 'lodash/isUndefined';
// Local files
import { StyledTab, Root, Container } from './Contacts.styled';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import Filters from 'components/Contacts/Filters/Filters';
import Table from 'components/Contacts/Table/Table';
import ListsUpdate from 'components/Dialogs/ListsUpdate/ListsUpdate';
import Drawer from 'components/Drawers/Contact/Contact';
import SendEmailNotification from 'components/Dialogs/SendEmailNotification/SendEmailNotification';
import useContacts from 'hooks/useContacts';
import useCustomSelector from 'hooks/useCustomSelector';

const Contacts = () => {
  const { setContactsFilters } = useContacts();
  const { tab, pendingContactsCount, quarantinedСontactsСount, needToLoadMore, newOffset, fetching, open, contacts } = useCustomSelector(state => ({ 
    tab: state.contacts.all.filters.tab,
    pendingContactsCount: state.profile.user.pending_contacts_count,
    quarantinedСontactsСount: state.profile.user.quarantined_contacts_count,
    needToLoadMore: state.contacts.all.pagination.total_count > state.contacts.all.pagination.count + state.contacts.all.pagination.offset,
    newOffset: state.contacts.all.pagination.limit + state.contacts.all.pagination.offset,
    fetching: state.contacts.all.fetching,
    open: state.app.listsUpdateDialog.open,
    contacts: state.app.listsUpdateDialog.contacts
  }));
  const [checkedContacts, setCheckedContacts] = useState([]);
  const [drawer, setDrawer] = useState({ open: false, data: {} });

  const handleTabChange = (_, newTab) => setContactsFilters({ tab: newTab, member: newTab === 'connections', pending: newTab === 'pending', quarantined: newTab === 'quarantined', offset: 0 });
  const handleTableChange = ({ order, orderBy, top }) => {
    setContactsFilters({
      ...(top > 0.99 && needToLoadMore && !fetching &&  {
        offset: newOffset
      }),
      ...((!_isUndefined(orderBy) || !_isUndefined(order)) && {
        orders: orderBy === 'first_name'
          ? { user: { first_name: order } }
          : (orderBy === 'last_name'
            ? { user: { last_name: order } }
            : orderBy === 'email'
              ? { user: { email: order } }
              : { [orderBy]: order }
        )
      })
    });
  };
  const handleEditClick = data => setDrawer({ data, open: true });
  const handleDrawerClose = () => setDrawer({ data: {}, open: false });

  useEffect(() => {
    return () => setContactsFilters({ tab: 'all' });
  }, [setContactsFilters]);
  useEffect(() => {
    if (!open && contacts.length === 0) {
      setCheckedContacts([])
    }
  }, [open, contacts]);

  return (
    <Root>
      <SendEmailNotification />
      <Drawer
        {...drawer}
        onClose={handleDrawerClose}
      />
      <AppBar position='sticky' variant='outlined' sx={{ border: 0, backgroundColor: '#F1F2F5', color: 'inherit' }}>
        <Box pt={4}>
          <Box display='flex' justifyContent='center'>
            <Tabs
              centered
              scrollButtons
              allowScrollButtonsMobile
              sx={{ minHeight: '40px' }}
              value={tab}
              onChange={handleTabChange}
            >
              <StyledTab
                label='All'
                value='all'
              />
              <StyledTab
                label='Connections'
                value='connections'
              />
              <StyledTab
                label={<Badge badgeContent={pendingContactsCount} color='success' overlap='circular' sx={{ ...(!!pendingContactsCount && { width: 100 }) }}>Pending</Badge>}
                value='pending'
              />
              {!!quarantinedСontactsСount && <StyledTab
                label={<Badge badgeContent={quarantinedСontactsСount} color='error' overlap='circular' sx={{ width: 140 }}>Quarantined</Badge>}
                value='quarantined'
              />}
            </Tabs>
          </Box>
          <ErrorWrapper>
            <Filters
              onQueryChange={query => setContactsFilters({ query, page: 0 })}
              checkedContacts={checkedContacts}
              onRemove={setCheckedContacts}
              onListsChange={lists => setContactsFilters({ lists })}
            />
          </ErrorWrapper>
        </Box>
      </AppBar>
      <Container>
        <ErrorWrapper>
          <Table
            onChange={handleTableChange}
            checkedContacts={checkedContacts}
            onCheckedContactsChange={setCheckedContacts}
            onEdit={handleEditClick}
          />
        </ErrorWrapper>
      </Container>
      <ErrorWrapper>
        <ListsUpdate />
      </ErrorWrapper>
    </Root>
  );
};

export default memo(Contacts);