import { memo, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { ErrorBoundary } from 'react-error-boundary';
import _map from 'lodash/map';
import { useParams } from 'react-router-dom';
// Local files
import { Actions, StyledButton, Footer, SkipButton } from './LockedContent.styled';
import LockedFilledIcon from 'components/Common/Icons/LockedFilledIcon';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useAgreements from 'hooks/useAgreements';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useOffers from 'hooks/useOffers';

const LockedContent = () => {
  const { offer_id } = useParams();
  const { createAgreement } = useAgreements();
  const { closeLockedContentDialog, openAgreementDialog } = useApp();
  const { setError } = useError();
  const { setOfferLocalFields } = useOffers();
  const { open, templates } = useCustomSelector(state => ({
    open: state.app.lockedContentDialog.open,
    templates: state.templates.offer.data
  }));
  const [processing, setProcessing] = useState(false);

  const handleCreateAndUnlock = template_id => {
    setProcessing(true);
    createAgreement({ offer_id, agreement: { template_id } })
    .then(({ payload: { data: { agreement } } }) => 
      setOfferLocalFields({ id: offer_id, agreement })
      .then(() =>
        closeLockedContentDialog()
        .then(() => openAgreementDialog(agreement?.boldsign_document_url))
      )
    )
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };

  return (
    <BaseDialog
      open={open}
      onClose={closeLockedContentDialog}
      maxWidth={false}
      PaperProps={{ sx: { maxWidth: 510, width: '100%' } }}
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: '60px !important', }}>
        <LockedFilledIcon sx={{ width: 38, height: 38, mb: '4px' }} />
        <Typography variant='subtitle2' align='center' sx={{ fontWeight: 500, mb: '8px' }}>
          Locked Content
        </Typography>
        <Typography variant='small' align='center' sx={{ maxWidth: 367, mb: '24px' }}>
          To view and save all the content of this Offering, please review and sign the enclosed confidentiality agreement.
        </Typography>
        <Actions>
          {_map(templates, t =>
            <StyledButton
              key={t.id}
              disabled={processing}
              onClick={() => handleCreateAndUnlock(t.id)}
            >
              <Typography noWrap sx={{ fontSize: '12px', fontWeight: 500 }}>{t?.name}</Typography>
            </StyledButton>
          )}
        </Actions>
        <Footer>
          <SkipButton
            disabled={processing}
            onClick={closeLockedContentDialog}
          >
            Not now
          </SkipButton>
        </Footer>
      </DialogContent>
    </BaseDialog>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LockedContent {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);