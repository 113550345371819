import { memo } from 'react';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import Item from './Item/Item';
import AddIcon from 'components/Common/Icons/AddIcon';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useCustomSelector from 'hooks/useCustomSelector';

const Navigation = ({ onCreateProperty = null, onCloseMobileNav = null, isDesktop = false }) => {
  const navigate = useNavigate();
  const { item_id } = useParams();
  const { properties, dealspaceId } = useCustomSelector(state => ({
    properties: state.properties.dealspace.data,
    dealspaceId: state.dealspaces.dealspace.id
  }));

  const handleAssetSelect = (id) => { 
    !!onCloseMobileNav && onCloseMobileNav();
    item_id !== id && navigate(`/dealspaces/${dealspaceId}/properties/${id}`);
  };

  return (
    <Box>
      <List sx={{ p: 0, mb: 2 }}>
        {_map(properties, ({ id, name }) =>
          <Item
            key={id}
            title={name}
            selected={id === item_id}
            onClick={() => handleAssetSelect(id)}
          />
        )}
      </List>
      {isDesktop && <Button
        variant='contained'
        startIcon={<AddIcon />}
        sx={{ px: 2 }}
        onClick={onCreateProperty}
      >
        Add Property
      </Button>}
    </Box>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Navigation {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);