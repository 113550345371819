import {
  getShortProfileRoutine,
  getFullProfileRoutine,
  unsubscribeRoutine,
  updateProfileRoutine,
  setLocalUserCountsRoutine,
  clearLocalProfileRoutine
} from 'actions';
import _map from 'lodash/map';

export const initialState = {
  user: {
    id: '',
    username: '',
    photo: null,
    header: null,
    headline: '',
    title: '',
    company_name: '',
    location: '',
    latitude: 0,
    longitude: 0,
    about: '',
    unread_general_conversations_count: 0,
    unread_feed_conversations_count: 0,
    public_active_offers_count: 0,
    quarantined_contacts_count: 0,
    email: '',
    first_name: '',
    last_name: '',
    mobile_phone: '',
    office_phone: '',
    office_phone_extension: '',
    geographic_focuses: [],
    linkedin_profile_url: '',
    website_url: '',
    primary_activities: [],
    risk_profiles: [],
    asset_classes: [],
    contents: [],
    teammates: []
  },
  recipient: {
    id: '',
    email: '',
    offer: {
      id: '',
      deal_type: ''
    }
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getShortProfileRoutine.SUCCESS: {
      const { data: { user, recipient } } = action.payload;

      return {
        ...state,
        user: !!user ? { ...state.user, ...user } : initialState.user,
        recipient: !!recipient ? { ...state.recipient, ...recipient } : initialState.recipient
      };
    }
    case getFullProfileRoutine.SUCCESS: {
      const { data: { user } } = action.payload;

      return { ...state, user: { ...state.user, ...user } };
    }
    case unsubscribeRoutine.SUCCESS: {
      return state;
    }
    case updateProfileRoutine.SUCCESS: {
      const { response: { status }, user } = action.payload;

      const updatedUser = {
        ...state.user,
        ...user,
        ...(!!user.photo && { photo: { id: user.photo, representations: [{ url: user.localPhoto.localUrl }] } }),
        ...(!!user.header && { header: { id: user.header, url: user.localHeader.localUrl } }),
        ...(!!user.localContents && { contents: user.localContents }),
        ...(!!user.localTeammates && { teammates: _map(user.localTeammates, ({ id, title, data }) => ({
          ...(id && { id }),
          ...(!!data ? {
            user: {
              id: data.id,
              first_name: data.first_name,
              last_name: data.last_name,
              photo: data.photo
            }
          } : {
            invitation: {
              email: title
            }
          })
        }))})
      };

      return { ...state, ...(status === 204 && { user: updatedUser }) };
    }
    case setLocalUserCountsRoutine.SUCCESS: {
      return { ...state, user: { ...state.user, ...action.payload } };
    }
    case clearLocalProfileRoutine.SUCCESS: {
      return { ...state, ...initialState };
    }
    default: {
      return state;
    }
  };
}

export default reducer;