import { memo } from 'react';
import Button from '@mui/material/Button';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';

const UnlockRecipient = ({ open, recipient, processing, onClose, onUnlock }) => {
  const handleUnlock = () => onUnlock(recipient);
  const handleCancel = () => onClose();

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{ sx: { maxWidth: 380, width: '100%' } }}
      title='Are you sure you would like to manually unlock the content for this user?'
      actions={
      <>
        <Button
          variant='text'
          sx={{ px: '24px' }}
          disabled={processing}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          sx={{ px: '24px'}}
          disabled={processing}
          onClick={handleUnlock}
        >
          Unlock now
        </Button>
      </>
      }
    />
  );
};

export default memo(UnlockRecipient);