import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getUnreadNotificationsCount as getUnreadNotificationsCountAction,
  setUnreadNotificationsCount as setUnreadNotificationsCountAction,
  getNotifications as getNotificationsAction,
  getNotification as getNotificationAction,
  readNotifications as readNotificationsAction,
  clearNotifications as clearNotificationsAction
} from 'actions/notifications';

const useNotifications = () => {
  const dispatch = useDispatch();
  const getUnreadNotificationsCount = useCallback(() => dispatch(getUnreadNotificationsCountAction()), [dispatch]);
  const setUnreadNotificationsCount = useCallback(count => dispatch(setUnreadNotificationsCountAction(count)), [dispatch]);
  const getNotifications = useCallback(({ limit, offset, orders }) => dispatch(getNotificationsAction({ limit, offset, orders })), [dispatch]);
  const getNotification = useCallback(id => dispatch(getNotificationAction(id)), [dispatch]);
  const readNotifications = useCallback(notification_ids => dispatch(readNotificationsAction(notification_ids)), [dispatch]);
  const clearNotifications = useCallback(() => dispatch(clearNotificationsAction()), [dispatch]);

  return { getUnreadNotificationsCount, setUnreadNotificationsCount, getNotifications, getNotification, readNotifications, clearNotifications };
};

export default useNotifications;