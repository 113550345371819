import { memo } from 'react';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import _map from 'lodash/map';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { TableCell, Title } from './TableSortingCell.styled';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';

const TableSortingCell = ({ order, orderBy, headCells, changeSorting, sx }) => {
  return _map(headCells, ({ id, title, hideSort, align = 'inherit' }) =>
    <TableCell sx={sx} align={align} key={id} sortDirection={orderBy === id ? order : null}>
      {hideSort
        ? <Title key={id}>{title}</Title>
        : <TableSortLabel
            active={orderBy === id}
            direction={orderBy === id ? order : 'asc'}
            onClick={() => changeSorting(id)}
          >
            <Title>{title}</Title>
            {orderBy === id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
      }
    </TableCell>
  );
};

TableSortingCell.propTypes = {
  sx: PropTypes.object,
  order: PropTypes.string.isRequired, 
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired,
  changeSorting: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <TableSortingCell {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);