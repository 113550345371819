import { memo, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import _map from 'lodash/map';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
//Local files
import { Root, Header, TextButton, Grid, FileInput } from './IntakeFiles.styled';
import { ReactComponent as UploadIcon } from 'assets/icons/upload-cloud.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/folder-outlined.svg';
import DropContainer from 'components/Common/Feedback/DropContainer/DropContainer';
import File from 'components/Documents/Card/Card';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useBlob from 'hooks/useBlob';
import useChecksum from 'hooks/useChecksum';

const allowTypes = [
  'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/gif', 'image/jpeg', 'image/png',
  'application/pdf', 'image/svg+xml', 'image/tiff', 'image/webp'
];
const accept = 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/gif,image/jpeg,image/png,application/pdf,image/svg+xml,image/tiff,image/webp';
const now = new Date().toISOString();

const IntakeFiles = ({ value, onChange }) => {
  const filesRef = useRef(null);
  const { processBlob } = useBlob();
  const { processFiles } = useChecksum();
  const [loading, setLoading] = useState(false);
  const empty = !value.length && !loading;

  const handleUploadClick = () => filesRef.current.click();
  const handleFilesChange = ({ target: { files } }) => {
    processFiles(files, ({ file, checksum }) => {
      setLoading(true);
      processBlob({ file, checksum }, blob => {
        if (!blob.id) return;
        onChange({ reason: 'add', v: blob });
        setLoading(false);
      });
    });
  };
  const handleDrop = ({ files }) => {
    processFiles(files, ({ file, checksum }) => {
      setLoading(true);
      processBlob({ file, checksum }, blob => {
        if (!blob.id) return;

        onChange({ reason: 'add', v: blob });
        setLoading(false);
      });
    });
  };

  if (empty) {
    return (
      <Box>
        <FileInput
          multiple
          type='file'
          accept={accept}
          ref={filesRef}
          onChange={handleFilesChange}
        />
        <DropContainer onDrop={handleDrop} allowTypes={allowTypes}>
          <ButtonBase variant='uploader' sx={{ height: 82, marginBottom: '3px' }} onClick={handleUploadClick}>
            <Header sx={{ position: 'absolute', top: 14, left: 16 }}>
              <FolderIcon /><Typography variant='caption'>Files</Typography>
            </Header>
            <UploadIcon style={{ color: '#8AC5F3' }} />
            <Typography variant='subtitle3' mt={1}>
              Click to upload <span style={{ fontWeight: 400 }}>or drag and drop</span>
            </Typography>
          </ButtonBase>
        </DropContainer>
      </Box>
    );
  }
  return (
    <>
      <FileInput
        multiple
        type='file'
        accept={accept}
        ref={filesRef}
        onChange={handleFilesChange}
      />
      <DropContainer mb='30px' onDrop={handleDrop} allowTypes={allowTypes}>
        <Root>
          <Header pt={1} pb={2}>
            <FolderIcon /><Typography variant='caption' noWrap sx={{ flexGrow: 1 }}>Files</Typography>
            <TextButton onClick={handleUploadClick} disabled={loading}>
              + Attach Files
            </TextButton>
          </Header>
          <Grid>
            {_map(value, f =>
              <File
                id={f.id}
                key={f.id}
                title={f.filename}
                type={f.content_type}
                showLastActivity
                date={now}
                preview={f.localUrl}
                onDelete={() => onChange({ reason: 'remove', v: f.id })}
              />
            )}
            {loading &&
              <File
                title='Loading'
                type=''
                loading
              />
            }
          </Grid>
        </Root>
      </DropContainer>
    </>
  );
};

IntakeFiles.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <IntakeFiles {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);