import { memo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

function PercentProgress({value, color = ''}) {

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress color='secondary' variant='determinate' value={value} sx={color ? { color: color } : {}}/>
      <Box sx={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant='caption' component='div' sx={color ? { color: color } : {}}>
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

PercentProgress.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
};

export default memo(PercentProgress);
