import styled from '@mui/material/styles/styled';
import MuiPaper from '@mui/material/Paper';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  backgroundColor: theme.palette.section.table.background,
  borderRadius: '10px',
  border: '1px solid #E8E8E8',
  overflow: 'hidden',
}));

const LeftMargin = styled((props) => (
  <MuiPaper variant='glass' {...props} />
))(({ theme }) => ({
  position: 'absolute',
  top: 0, bottom: 0,
  left: 0, width: 32,
  background: theme.palette.section.table.backgroundGlass,
  zIndex: 10,
  borderTopLeftRadius: '10px',
  borderBottomLeftRadius: '10px',
}));

const RightMargin = styled((props) => (
  <MuiPaper variant='glass' {...props} />
))(({ theme }) => ({
  position: 'absolute',
  top: 0, bottom: 0,
  right: 0, width: 32,
  background: theme.palette.section.table.backgroundGlass,
  zIndex: 10,
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
}));

const Container = styled('div')({
  width: '100%',
  overflow: 'auto',
  padding: '0 40px',
});

export { Root, LeftMargin, RightMargin, Container };