import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export const StyledButton = styled(props => (
  <Button variant='outlined' color='neutral' {...props} />
))(() => ({
  width: '100%',
  color: 'rgba(0,0,0,.8)'
}));

export const Actions = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '5px',
});

export const Footer = styled(Box)({
  minHeight: 35,
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px 24px 4px'
});

export const SkipButton = styled(Button)(({theme}) => ({
  fontSize: theme.typography.pxToRem(12),
  color: '#4A4A4A !important',
  fontWeight: 400,
  fontStyle: 'italic',
  textDecoration: 'underline !important',
  whiteSpace: 'nowrap',
  backgroundColor: 'transparent !important',
  padding: '0 2px',
  marginTop: '-8px'
}));