import { forwardRef, memo, useImperativeHandle, useRef } from 'react';
// Local files
import connectionRequestSound from 'assets/audio/connection_request.mp3';
import newMessageSound from 'assets/audio/new_message.mp3';
import newPrivateOfferingSound from 'assets/audio/new_private_offering.mp3';
import notificationSound from 'assets/audio/notification.mp3';
import unlockOfferingSound from 'assets/audio/unlock_offering.mp3';
import bubbleChatSound from 'assets/audio/bubble-pop.wav';

const AudioPlayer = forwardRef((_, ref) => {
  const connectionRequestSoundPlayer = useRef(null);
  const newMessageSoundPlayer = useRef(null);
  const newPrivateOfferingSoundPlayer = useRef(null);
  const notificationSoundPayer = useRef(null);
  const unlockOfferingSoundPlayer = useRef(null);
  const bubbleChatSoundPlayer = useRef(null);

  useImperativeHandle(ref, () => {
    return {
      playConnectionRequestSound() {
        connectionRequestSoundPlayer.current.play();
      },
      playNewMessageSound() {
        newMessageSoundPlayer.current.play();
      },
      playNewPrivateOfferingSound() {
        newPrivateOfferingSoundPlayer.current.play();
      },
      playNotificationSound() {
        notificationSoundPayer.current.play();
      },
      playUnlockOfferingSound() {
        unlockOfferingSoundPlayer.current.play();
      },
      playBubbleChatSound() {
        bubbleChatSoundPlayer.current.play();
      },
    };
  }, []);

  return (
    <>
      <audio ref={connectionRequestSoundPlayer} src={connectionRequestSound} />
      <audio ref={newMessageSoundPlayer} src={newMessageSound} />
      <audio ref={newPrivateOfferingSoundPlayer} src={newPrivateOfferingSound} />
      <audio ref={notificationSoundPayer} src={notificationSound} />
      <audio ref={unlockOfferingSoundPlayer} src={unlockOfferingSound} />
      <audio ref={bubbleChatSoundPlayer} src={bubbleChatSound} />
    </>
  );
});

export default memo(AudioPlayer);