import { memo } from 'react';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
// Local files
import { FormControlLabel, LabelContainer } from './CheckboxForm.styled';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

/**
 * Custom checkbox form for download items
 * @param {node} label
 * @param {object=} sx
 * @returns {node} CheckboxForm
 */

const CheckboxForm = ({ label, sx = undefined, checked, onChange }) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={onChange} sx={{ p: 0, '& svg': { width: 18, height: 18 } }} />}
      label={<LabelContainer>{label}</LabelContainer>}
      sx={sx}
    />
  );
};

CheckboxForm.propTypes = {
  label: PropTypes.node.isRequired,
  sx: PropTypes.object,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <CheckboxForm {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);