import { memo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import _map from 'lodash/map';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars-2';
// Local files
import { TableCell } from './Table.styled';
import TablePagination from 'components/Common/DataDisplay/BaseTable/TablePagination/TablePagination';
import Head from './Head/Head';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { representationVariants } from 'helpers/constants';
import { getRepresentation, getFormattedDateWithTime } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';

const AuditsTable = ({ page, rowsPerPage, order, orderBy, onPageChange,onOrdersChange }) => {
  const { fetching, audits, auditsTotalCount } = useCustomSelector((state) => ({
    fetching: state.audits.all.fetching,
    audits: state.audits.all.data,
    auditsTotalCount: state.audits.all.pagination.total_count
  }));

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ height: '100%', weight: '100%' }}
    >
      <Scrollbars autoHideTimeout={1000}>
        <Table
          stickyHeader
          sx={{ background: '#fff', borderTop: '1px solid #EAEAEA' }}
        >
          <Head
            order={order}
            orderBy={orderBy}
            onSortChange={onOrdersChange}
          />
          <TableBody>
            {fetching ? (
              <TableRow>
                <TableCell><Skeleton animation="wave" /></TableCell>
                <TableCell><Skeleton animation="wave" /></TableCell>
                <TableCell><Skeleton animation="wave" /></TableCell>
              </TableRow>
            ) : (
              _map(audits, ({ id, user, updated_at, description }) => {
                const photoSrc = !!user
                  ? getRepresentation(
                      user.photo?.representations,
                      representationVariants.TINY
                    )
                  : null;
                const firstName = user?.first_name ?? '';
                const lastName = user?.last_name ?? '';
                const updatedAt = getFormattedDateWithTime(updated_at);

                return (
                  <TableRow hover={false} key={id}>
                    <TableCell align="left">
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          whiteSpace: 'nowrap',
                          gap: '4px',
                        }}
                      >
                        <BaseAvatar
                          src={photoSrc}
                          firstName={firstName}
                          lastName={lastName}
                          sx={{ mx: 1.5 }}
                        />
                        <Typography variant="caption" pr={2}>
                          {firstName} {lastName}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left" >
                      {updatedAt}
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: 700 }} >{description}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </Scrollbars>
      <TablePagination
        sx={{ overflow: 'hidden' }}
        backIconButtonProps={fetching ? { disabled: fetching } : undefined}
        nextIconButtonProps={fetching ? { disabled: fetching } : undefined}
        count={auditsTotalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, newPage) => onPageChange(newPage)}
      />
    </Box>
  );
};

AuditsTable.propTypes = {
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onOrdersChange: PropTypes.func.isRequired
};

const WrappedComponent = (props) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AuditsTable {...props} />
    </ErrorBoundary>
  );
};

export const Fallback = () => {
  return (
    <TableRow>
      <TableCell><Skeleton animation="wave" /></TableCell>
      <TableCell><Skeleton animation="wave" /></TableCell>
      <TableCell><Skeleton animation="wave" /></TableCell>
    </TableRow>
  );
};

export default memo(WrappedComponent);
