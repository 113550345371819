import { forwardRef, memo } from 'react';
import TextField from '@mui/material/TextField';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import { fieldSx } from './StyledPhoneField.styled';

const CustomPhoneField = forwardRef(function CustomPhoneField(props, ref) {
  const { value, onChange, onBlur, onFocus, className, ...other } = props;

  return (
    <IntlTelInput
      ref={ref}
      value={value}
      inputClassName={className}
      onPhoneNumberChange={onChange}
      onPhoneNumberBlur={onBlur}
      onPhoneNumberFocus={onFocus}
      style={{ paddingTop: '12px', paddingBottom: '12px', width: '100%' }}
      preferredCountries={['us']}
      {...other}
    />
  );
});

const StyledPhoneField = ({
  label,
  fullWidth = false,
  margin = 'none',
  variant = 'standard',
  sx = {},
  formattedValue,
  error = false,
  helperText = '',
  onKeyDown = undefined,
  onChange,
  ...otherProps
}) => {
  const handleChange = (valid, formattedValue, data) => {
    onChange({
      value: '+' + data.dialCode + formattedValue,
      formattedValue,
      error: !valid ? 'Phone number invalid' : ''
    });
  };

  return (
    <TextField
      fullWidth={fullWidth}
      margin={margin}
      sx={{ ...fieldSx, ...sx }}
      variant={variant}
      label={label}
      value={formattedValue}
      error={error}
      onChange={handleChange}
      InputProps={{ onKeyDown, disableUnderline: true, inputComponent: CustomPhoneField }}
      InputLabelProps={{ shrink: true }}
      {...otherProps}
    />
  );
};

const ErrorFallback = ({ error }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <StyledPhoneField {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);