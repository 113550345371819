import styled from '@mui/material/styles/styled';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

export const StyledAppBar = styled(AppBar)({
  position: 'sticky',
  top: 0,
  backgroundColor: '#3B3B3B',
  boxShadow: 'none',
  zIndex: 10,
});

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  gap: '12px',
  flexWrap: 'wrap',
  padding: '20px 16px',
  [theme.breakpoints.up(760)]: {
    flexWrap: 'nowrap',
  },
  [theme.breakpoints.up('md')]: {
    padding: '20px 22px 20px 36px',
  }
}));

export const Content = styled('div')({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  gap: '12px'
});

export const Info = styled('div')({});

export const StatusContainer = styled('div')({
  flexGrow: 1,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 10px'
});
export const SaveInfoContainer = styled('div')({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-end',
  paddingRight: 16
});

export const Description = styled('div')({
  display: 'flex',
  gap: '16px',
  pt: '2px'
});

export const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '5px'
});

export const StyledButton = styled((props) => (
  <Button variant='outlined' {...props} />
))({
  minWidth: 120,
  color: '#BFBFBF',
  borderColor: '#BFBFBF',
  whiteSpace: 'nowrap',
  '&:hover': {
    borderColor: '#dcdcdc',
  }
});

export const StyledPrimaryButton = styled((props) => (
  <Button variant='gradient' {...props} />
))({
  minWidth: 110,
  whiteSpace: 'nowrap',
});