import { lazy, memo, Suspense, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import _find from 'lodash/find';
import _isUndefined from 'lodash/isUndefined';
import _pick from 'lodash/pick';
import { useParams } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars-2';
import PropTypes from 'prop-types';
//Local files
import { Root } from './Analytics.styled';
import { Fallback as AllTimeChartFallback } from 'components/Activities/AllTimeChart/AllTimeChart';
import { Fallback as OverTimeChartFallback } from 'components/Activities/OverTimeChart/OverTimeChart';
import { Fallback as TableFallback } from 'components/Activities/Table/Table';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useActivities from 'hooks/useActivities';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';

const AllTimeChart = lazy(() => import('components/Activities/AllTimeChart/AllTimeChart'));
const OverTimeChart = lazy(() => import('components/Activities/OverTimeChart/OverTimeChart'));
const Table = lazy(() => import('components/Activities/Table/Table'));

const rowsPerPage = 50;

const Analytics = ({ tab, filters, hasCA, market }) => {
  const { offer_id } = useParams();
  const { getChartActivities, getTableActivities, clearChartActivities } = useActivities();
  const { setError } = useError();
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const { showEmpty, showCharts, statistics, offer } = useCustomSelector(state => ({
    showEmpty: state.activities.table.pagination.total_count === 0 && !state.activities.table.fetching && query.length === 0,
    showCharts: state.recipients.activityLog.pagination.total_count >= 20,
    statistics: state.activities.chart.data,
    offer: state.offers.activityLog
  }));
  const allTimeChartProps = {
    hasCA,
    market,
    ..._pick(statistics, [
      'total_recipients_count', 'share_recipients_count', 'visit_recipients_count', 'email_delivered_recipients_count', 'offered_recipients_count',
      'email_bounced_recipients_count', 'email_opened_recipients_count', 'visited_share_recipients_count', 'visited_visit_recipients_count',
      'visited_recipients_count', 'unlocked_recipients_count', 'replied_recipients_count', 'tracked_recipients_count', 'saved_recipients_count',
      'unsubscribed_recipients_count'
    ])
  };
  const overTimeChartProps = _pick(statistics, ['over_time_dates', 'over_time_opens', 'over_time_unlocks', 'over_time_visits']);
  const tableProps = { page, query, order, orderBy, rowsPerPage };

  const fetchChartActivities = useCallback(({ offer_id, filters }) => {
    const params = {
      offer_id,
      ...!!_find(filters, f => f === 'unlocked') && { unlocked: true },
      ...!!_find(filters, f => f === 'tracked') && { tracked: true },
      ...!!_find(filters, f => f === 'visited') && { visited: true },
      ...!!_find(filters, f => f === 'email_opened') && { email_opened: true }
    }
    getChartActivities(params).catch(e => setError(e));
  }, [getChartActivities, setError]);
  const fetchTableActivities = useCallback(({ offer_id, offset, query, order, orderBy, filters }) => {
    const params = {
      offer_id,
      limit: rowsPerPage,
      offset,
      query,
      orders: { [orderBy]: order },
      ...!!_find(filters, f => f === 'unlocked') && { unlocked: true },
      ...!!_find(filters, f => f === 'tracked') && { tracked: true },
      ...!!_find(filters, f => f === 'visited') && { visited: true },
      ...!!_find(filters, f => f === 'email_opened') && { email_opened: true }
    };

    getTableActivities(params).catch(e => setError(e));
  }, [getTableActivities, setError]);
  const handleTableChange = v => {
    if (!_isUndefined(v.query)) setQuery(v.query);
    if (!_isUndefined(v.order)) setOrder(v.order);
    if (!_isUndefined(v.orderBy)) setOrderBy(v.orderBy);
    setPage(0);
  };
  const handlePageChange = p => setPage(p);
  /*** CHART ***/
  useEffect(() => {
    if (offer.id) fetchChartActivities({ offer_id, filters });

    return () => {
      clearChartActivities();
    };
  }, [clearChartActivities, fetchChartActivities, offer_id, filters, offer.id]);
  /*** TABLE ***/
  useEffect(() => {
    let timeout = setTimeout(() => {
      if (offer.id) fetchTableActivities({ offer_id, offset: page * rowsPerPage, query, order, orderBy, filters });
    }, !!query ? 500 : 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [fetchTableActivities, offer.id, offer_id, page, query, order, orderBy, filters]);

  if (tab !== 'activityLog') return null;
  if (showEmpty) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
        <Typography fontWeight={400} fontSize='16px' lineHeight='20px'>
          No activity on this Offering yet. Check back later!
        </Typography>
      </Box>
    );
  }
  return (
    <Root>
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
      >
        {showCharts &&
          <Box display='flex'>
            <Suspense fallback={<AllTimeChartFallback />}>
              <AllTimeChart {...allTimeChartProps} />
            </Suspense>
            <Suspense fallback={<OverTimeChartFallback />}>
              <OverTimeChart {...overTimeChartProps} />
            </Suspense>
          </Box>
        }
        <Suspense fallback={<TableFallback />}>
          <Table {...tableProps} onChange={handleTableChange} onPageChange={handlePageChange} />
        </Suspense>
      </Scrollbars>
    </Root>
  );
};

Analytics.propTypes = {
  tab: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
  locked: PropTypes.bool.isRequired,
  market: PropTypes.bool.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Analytics {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);