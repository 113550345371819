import { memo, useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import Drawer from '../../Drawers/Senders/Senders';
import EditIcon from 'components/Common/Icons/EditIcons';
import BuilderErrorLabel from 'components/Common/DataDisplay/BuilderErrorLabel/BuilderErrorLabel';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useMembers from 'hooks/useMembers';

const BuilderSenders = ({ senders, onChange }) => {
  const { setError } = useError();
  const { getDealspaceMembers, clearLocalDealspaceMembers } = useMembers();
  const { dealspaceId, members, ownId } = useCustomSelector(state => ({
    dealspaceId: state.offers.builder.offer.dealspace.id,
    members: state.members.dealspace.data,
    ownId: state.profile.user.id
  }));
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchMembers = useCallback(({ offset, dealspace_id }) => {
    setLoading(true);
    getDealspaceMembers({ offset, dealspace_id, status: 'active' })
    .catch(e => setError(e))
    .finally(() => setLoading(false));
  }, [getDealspaceMembers, setError]);

  const handleChange = (_, v, reason) => onChange({ v, reason });
  const handleDelete = id => onChange({ id, reason: 'removeChip' });
  const handleClick = () => setOpenDrawer(true);
  const handleSendersChange = sender => onChange({ sender, reason: 'updateOption' });
  const handleSendersReorder = value => onChange({ value, reason: 'reorderOptions' });

  useEffect(() => {
    fetchMembers({ offset: 0, dealspace_id: dealspaceId });

    return clearLocalDealspaceMembers;
  }, [dealspaceId, fetchMembers, clearLocalDealspaceMembers]);

  const renderOption = (p, o) => (
    <Box sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' }, gap: '8px', color: '#3F3F3F' }} {...p}>
      {o.id &&
        <Avatar
          alt={`${o.user.first_name} ${o.user.last_name}`}
          src={getRepresentation(o.user?.photo?.representations, representationVariants.TINY)}
          sx={{ width: 24, height: 24, textTransform: 'uppercase', boxShadow: 'inset 0px 0px 0px 1px white' }}
        >
          {o.user.first_name[0]}
        </Avatar>
      }
      <Typography variant='body2' sx={{ flexBasis: { xs: 'calc(100% - 24px - 8px)', md: 'calc(40% - 24px - 8px - 8px)'} }}>
        {o.user.first_name} {o.user.last_name}
      </Typography>
      <Typography variant='body2' sx={{ flexBasis: { xs: '100%', md: '60%' }, fontStyle: 'italic' }}>
        {o.role}
      </Typography>
    </Box>
  );
  const renderInput = p => (
    <TextField
      {...p}
      variant='standard'
      label='Shared by:'
      required
      InputProps={{
        ...p.InputProps,
        endAdornment: <>
          {loading && <CircularProgress color="inherit" size={20} />}
        </>
      }}
    />
  );
  const renderTags = p => _map(p, (s, i) =>
    <Chip
      key={s.id}
      sx={{ backgroundColor: i === 0 ? 'tertiary.main' : 'tertiary.light', m: '4px' }}
      onDelete={s.user?.id !== ownId ? () => handleDelete(s.user.id) : undefined}
      onClick={() => handleClick()}
      avatar={
        <Avatar
          alt={s.user.first_name}
          src={getRepresentation(s.user?.photo?.representations, representationVariants.TINY)}
          sx={{ width: 24, height: 24, textTransform: 'uppercase', boxShadow: 'inset 0px 0px 0px 1px white' }}
        >
          {s.user.first_name[0]}
        </Avatar>
      }
      label={
        <Box component='span' sx={{ display: 'flex', gap: '8px', color: '#3F3F3F' }}>
          <Typography>
            {s.user.first_name} {s.user.last_name}
          </Typography>
          <EditIcon sx={{ maxHeight: '20px' }} />
        </Box>
      }
    />
  );

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Drawer
          data={senders.value}
          open={openDrawer}
          onChange={handleSendersChange}
          onReorder={handleSendersReorder}
          onClose={() => setOpenDrawer(false)}
        />
      </DndProvider>
      <Autocomplete
        fullWidth
        multiple
        loading={loading}
        options={members}
        renderOption={renderOption}
        renderTags={renderTags}
        getOptionLabel={o => `${o.user.first_name} ${o.user.last_name}`}
        isOptionEqualToValue={(o, v) => o.user.id === v.user.id}
        value={senders.value}
        onChange={handleChange}
        renderInput={renderInput}
      />
      {!!senders.error && <BuilderErrorLabel value={senders.error} />}
    </>
  );
};

BuilderSenders.propTypes = {
  senders: PropTypes.shape({
    value: PropTypes.array.isRequired,
    error: PropTypes.string.isRequired
  }),
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BuilderSenders {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);