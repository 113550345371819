import { memo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import _map from 'lodash/map';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import Card from '../Card/Card';
import CardSkeleton from '../Card/Skeleton/Skeleton';
import Search from 'components/Common/Inputs/Search/Search';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useCustomSelector from 'hooks/useCustomSelector';

const Grid = ({ fetching, query, onQueryChange, onLoadMore }) => {
  const { dealspaces, hasMoreDealspaces } = useCustomSelector(state => {
    const dealspaces = state.dealspaces.group.data;
    const hasMoreDealspaces = state.dealspaces.group.pagination.total_count > state.dealspaces.group.pagination.count + state.dealspaces.group.pagination.offset;

    return { dealspaces, hasMoreDealspaces };
  });

  return (
    <>
      <Search
        placeholder='search dealspaces'
        value={query}
        onChange={onQueryChange}
      />
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(230px, 1fr))', gridRowGap: '20px', gridColumnGap: '16px' }}>
        {_map(dealspaces, d => <Card key={d.id} {...d} />)}
        {fetching &&
          <>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </>
        }
      </Box>
      {hasMoreDealspaces &&
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button
            disabled={fetching}
            onClick={onLoadMore}
            variant='outlined'
          >
            Show More
          </Button>
        </Box>
      }
    </>
  );
};

Grid.propTypes = {
  fetching: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Grid {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);