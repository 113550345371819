import { memo, useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosRounded';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';
import _includes from 'lodash/includes';
//Local files
import { BackButton, DetailsContainer, DetailsPaper, EmptyContainer, NavContainer, Root } from './DealFlow.styled';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import FlowNav from 'components/Offers/FlowNav/FlowNav';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useDocuments from 'hooks/useDocuments';
import useFolders from 'hooks/useFolders';
import useImages from 'hooks/useImages';
import useMap from 'hooks/useMap';
import useMixpanel from 'hooks/useMixpanel';
import useOffers from 'hooks/useOffers';
import useSenders from 'hooks/useSenders';
import useSuccess from 'hooks/useSuccess';
import useTemplates from 'hooks/useTemplates';
import LoadingFeed from 'components/Dialogs/LoadingFeed/LoadingFeed';
import InvitedUserOverlay from 'components/Offers/Details/InvitedUserOverlay/InvitedUserOverlay';

const DealFlow = ({ mode }) => {
  // const observerTarget = useRef(null);
  const navigate = useNavigate();
  const { setAuthData } = useApp();
  const { offer_id } = useParams();
  const { pathname } = useLocation();
  const { clearLocalOfferDocuments } = useDocuments();
  const { clearLocalOfferFolders } = useFolders();
  const { clearLocalOfferImages } = useImages();
  const { clearPolygons } = useMap();
  const { track } = useMixpanel();
  const { clearFeedOfferData, clearLocalFeedOffer } = useOffers();
  const { clearLocalSenders } = useSenders();
  const { clearLocalTemplates } = useTemplates();
  const { setSuccess } = useSuccess();
  const [onTheBottom, setOnTheBottom] = useState(false);
  const { username, authorizedAsUser } = useCustomSelector(state => ({
    username: state.profile.user.username,
    authorizedAsUser: state.sessions.session.userableType === 'user'
  }));
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const openMobile = useMemo(() => _includes(pathname, 'deal_flow') && !!offer_id, [pathname, offer_id]);
  const intakeLink = `${window.location.origin}/users/${username}/intake`;

  const handleCloseDialog = () => navigate('/deal_flow');
  const handleCopyLink = () => {
    navigator.clipboard.writeText(intakeLink);
    track('Copied link to feed intake form');
    setSuccess('Copied to clipboard');
  };
  const onScroll = ({ target }) => {
    if ((target.scrollTop + 80) >= (target.scrollHeight - target.offsetHeight)) {
      setOnTheBottom(true);
    }
  };

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     entries => {
  //       if (entries[0].isIntersecting && offer_id) {
  //         console.log('here');
  //       }
  //     },
  //     { threshold: 1 }
  //   );
  
  //   if (observerTarget.current) {
  //     observer.observe(observerTarget.current);
  //   }
  
  //   return () => {
  //     if (observerTarget.current) {
  //       observer.unobserve(observerTarget.current); // eslint-disable-line
  //     }
  //   };
  // }, [observerTarget, offer_id]);
  useEffect(() => {
    if (mode) setAuthData({ mode, open: true });
  }, [mode, setAuthData]);
  useEffect(() => {
    return () => {
      clearFeedOfferData({});
      clearLocalFeedOffer();
      clearLocalOfferImages();
      clearLocalOfferDocuments();
      clearLocalOfferFolders();
      clearLocalSenders();
      clearLocalTemplates();
      clearPolygons();
    };
  }, [clearFeedOfferData, clearLocalFeedOffer, clearLocalOfferImages, clearLocalOfferDocuments, clearLocalOfferFolders, clearLocalSenders, clearLocalTemplates, clearPolygons]);

  if (mediaDesktop) {
    return (
      <Root>
        <LoadingFeed />
        <NavContainer>
          <FlowNav />
        </NavContainer>
        <DetailsContainer onScroll={onScroll}>
          {!offer_id && authorizedAsUser ?
            <EmptyContainer>
              <Typography variant='subtitle2' align='center' sx={{ color: 'black' }}>
                Get more deals directly to your Private Feed
              </Typography>
              <Typography variant='caption' align='center' component='div' sx={{ fontWeight: 500, color: 'black', pb: '24px' }}>
                Invite your brokers or borrowers to share deals using this link:
              </Typography>
              <Box sx={{ maxWidth: '100%', overflow: 'hidden', display: 'flex', gap: '6px' }}>
                <Paper variant='outlined' color='paper' sx={{ overflow: 'hidden', p: '9px'}}>
                  <Typography variant='small' noWrap sx={{ display: 'block', color: 'rgba(0,0,0,.6)', lineHeight: 1 }}>
                    {intakeLink}
                  </Typography>
                </Paper>
                <Button
                  variant='gradient'
                  startIcon={<LinkIcon />}
                  sx={{ flexShrink: 0 }}
                  onClick={handleCopyLink}
                >
                  Copy Link
                </Button>
              </Box>
            </EmptyContainer> :
            <DetailsPaper>
              <InvitedUserOverlay />
              <Outlet context={[onTheBottom, setOnTheBottom]} />
            </DetailsPaper>
          }
          {/* <div ref={observerTarget} /> */}
        </DetailsContainer>
      </Root>
    );
  }
  return (
    <>
      <FlowNav />
      <BaseDialog
        open={openMobile}
        fullScreen
        hideCloseButton
        PaperProps={{ sx: { paddingTop: '54px' }, onScroll }}
        onClose={() => {}}
      >
        <BackButton onClick={handleCloseDialog}><ArrowBackIcon />Back</BackButton>
        <InvitedUserOverlay />
        <LoadingFeed />
        <Outlet context={[onTheBottom, setOnTheBottom]} />
      </BaseDialog>
    </>
  );
};

export default memo(DealFlow);