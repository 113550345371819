import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getOfferFromRecipientToken as getOfferFromRecipientTokenAction,
  getActivityLogRecipients as getActivityLogRecipientsAction,
  getSharedRecipients as getSharedRecipientsAction,
  getRecipient as getRecipientAction,
  createRecipient as createRecipientAction,
  updateRecipient as updateRecipientAction,
  deleteRecipient as deleteRecipientAction,
  notifyRecipients as notifyRecipientsAction,
  clearLocalActivityLogRecipients as clearLocalActivityLogRecipientsAction,
  clearLocalSharedRecipients as clearLocalSharedRecipientsAction
} from 'actions/recipients';

const useRecipients = () => {
  const dispatch = useDispatch();
  const getOfferFromRecipientToken = useCallback(recipient_token => dispatch(getOfferFromRecipientTokenAction(recipient_token)), [dispatch]);
  const getActivityLogRecipients = useCallback(({ offer_id, limit, offset, query, orders, filters, ids }) => dispatch(getActivityLogRecipientsAction({ offer_id, limit, offset, query, orders, filters, ids })), [dispatch]);
  const getSharedRecipients = useCallback(({ offer_id, limit, offset, orders }) => dispatch(getSharedRecipientsAction({ offer_id, limit, offset, orders })), [dispatch]);
  const getRecipient = useCallback(id => dispatch(getRecipientAction(id)), [dispatch]);
  const createRecipient = useCallback(({ offer_id, recipient }) => dispatch(createRecipientAction({ offer_id, recipient })), [dispatch]);
  const updateRecipient = useCallback(recipient => dispatch(updateRecipientAction(recipient)), [dispatch]);
  const deleteRecipient = useCallback(({ id, count }) => dispatch(deleteRecipientAction({ id, count })), [dispatch]);
  const notifyRecipients = useCallback(({ offer_id, recipient_id, recipients_type, subject, message }) => dispatch(notifyRecipientsAction({ offer_id, recipient_id, recipients_type, subject, message })), [dispatch]);
  const clearLocalActivityLogRecipients = useCallback(() => dispatch(clearLocalActivityLogRecipientsAction()), [dispatch]);
  const clearLocalSharedRecipients = useCallback(() => dispatch(clearLocalSharedRecipientsAction()), [dispatch]);

  return {
    getOfferFromRecipientToken,
    getActivityLogRecipients,
    getSharedRecipients,
    getRecipient,
    createRecipient,
    updateRecipient,
    deleteRecipient,
    notifyRecipients,
    clearLocalActivityLogRecipients,
    clearLocalSharedRecipients
  };
};

export default useRecipients;