import { memo, useCallback, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import Chart from 'chart.js/auto';
import _map from 'lodash/map';
import _max from 'lodash/max';
import PropTypes from 'prop-types';
// Local files
import { Canvas } from './OverTimeChart.styled';
import { getFormattedShortDate } from 'helpers';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const findDivider = v => {
  if (v % 4 === 0) return v;

  let result = v;

  while (result % 4 !== 0) {
    result++;
  }

  return result;
};

const OverTimeChart = ({ over_time_dates, over_time_opens, over_time_unlocks, over_time_visits }) => {
  const canvasRef = useRef(null);
  const chartRef = useRef(null);
  const theme = useTheme();
  const formattedDates = _map(over_time_dates, d => getFormattedShortDate(d));
  const yMax = _max([...over_time_opens, ...over_time_unlocks, ...over_time_visits]);
  const divider = findDivider(yMax);
  const step = divider / 4;
  const chart = useCallback(ctx => {
    ctx.restore();

    if (chartRef.current) chartRef.current.destroy();

    chartRef.current = new Chart(ctx, {
      type: 'line',
      responsive: true,
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Activity Over Time',
            font: { weight: 500, family: '"Plus Jakarta Sans", sans-serif' },
            padding: 40,
          },
          //при клике на лейблы график фильтруется
          legend: {
            display: false,
          },
          tooltip: {
            titleFont: { weight: 600, family: '"Plus Jakarta Sans", sans-serif' },
            titleMarginBottom: 8,
            bodyFont: { weight: 400, family: '"Plus Jakarta Sans", sans-serif' },
            boxPadding: 6,
          }
        },
        elements: {
          line: {
            borderWidth: 1,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 5,
          }
        },
        scales: {
          x: {
            ticks: {
              font: { weight: 400, family: '"Plus Jakarta Sans", sans-serif' },
              padding: 20,
            },
            border: {
              width: 2,
              color: '#EEF1F6',
            },
            grid: {
              drawTicks: false,
              lineWidth: 0,
            }
          },
          y: {
            min: 0,
            max: divider,
            ticks: {
              stepSize: step,
              font: { weight: 400, family: '"Plus Jakarta Sans", sans-serif' },
              padding: 20,
            },
            border: { width: 0 },
            grid: {
              drawTicks: false,
              lineWidth: 2,
              color: '#EEF1F6',
            }
          }
        },
      },
      data: {
        labels: formattedDates,
        datasets: [
          {
            label: 'Opens',
            data: over_time_opens,
            borderColor: theme.palette.section.activity.emailOpens,
            backgroundColor: theme.palette.section.activity.emailOpens,
          },
          {
            label: 'Visits',
            data: over_time_visits,
            borderColor: theme.palette.section.activity.visits,
            backgroundColor: theme.palette.section.activity.visits,
          },
          {
            label: 'CA Signed',
            data: over_time_unlocks,
            borderColor: theme.palette.section.activity.caSigned,
            backgroundColor: theme.palette.section.activity.caSigned,
          }
        ]
      }
    });
  }, [formattedDates, over_time_opens, over_time_unlocks, over_time_visits, step, theme, divider]);

  const fetchStatistics = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    chart(ctx);
  }, [chart]);

  useEffect(() => {
    fetchStatistics();
  }, [fetchStatistics]);

  return (
    <Canvas ref={canvasRef}>
      Your browser does not support the HTML5 canvas tag
    </Canvas>
  );
};

OverTimeChart.propTypes = {
  over_time_dates: PropTypes.array.isRequired,
  over_time_opens: PropTypes.array.isRequired,
  over_time_unlocks: PropTypes.array.isRequired,
  over_time_visits: PropTypes.array.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <OverTimeChart {...props} />
    </ErrorWrapper>
  );
};
export const Fallback = () => {
  return <Skeleton variant='rectangular' width={560} height={400} />;
};

export default memo(WrappedComponent);