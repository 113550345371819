import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export const backdropSx = {
  background: 'rgba(255,255,255,0.7)',
  backdropFilter: 'blur(8px)',
  WebkitBackdropFilter: 'blur(8px)',
  top: { xs: 0, md: '50px' },//header height
  left: { xs: 0, md: '554px' },//554 = 12 + 180 + 350 + 12 paddings and FlowNav width
  zIndex: (theme) => theme.zIndex.fab - 50,//Back button - 50
}

export const logoStyles = {
  flexShrink: 0,
  height: '34px',
  width: 'auto',
  color: '#fff'
}

export const lockStyles = {
  marginRight: '.3rem'
}

export const Root = styled('div')(() => ({
  width: '100%',
  maxWidth: 274,
  minHeight: 204,
  background: 'linear-gradient(143deg, rgba(12, 29, 69, 0.88) -2.33%, rgba(41, 47, 53, 0.88) 64.67%, rgba(31, 64, 98, 0.88) 109.72%);',
  borderRadius: '6px',
  backdropFilter: 'blur(8px)',
  WebkitBackdropFilter: 'blur(8px)',
  padding: '24px 12px',
}));

export const Wrapper = styled('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Text = styled(Typography)(({ theme }) => ({
  color: '#fff',
  fontSize: theme.typography.pxToRem(12),
  textAlign: 'center',
  '& span': {
    textAlign: 'center',
  }
}));

export const TextButton = styled(Link)({
  cursor: 'pointer',
  font: 'inherit',
  color: 'inherit',
  textDecoration: 'underline',
});