import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

const Container = styled('div')({
  display: 'flex',
  gap: '8px',
  '&:not(:last-of-type)': {
    marginBottom: 24
  }
});
const Title = styled((props) => (
  <Typography variant='caption' color='text.hint' component='div' {...props} />
))({
  flexShrink: 0,
  flexBasis: '140px'
});
const Text = styled((props) => (
  <Typography variant='caption' component='div' {...props} />
))({
  flexGrow: 1
});

export { Container, Title, Text };