import { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
// Local files
import { ReactComponent as SuccessIcon } from 'assets/icons/intake-success.svg';

const BuilderSuccessLabel = ({ value }) => {
  return (
    <Box display='flex' alignItems='center' gap='5px'>
      <SuccessIcon />
      <Typography fontWeight={400} fontSize='10px' lineHeight='20px' color='rgba(46, 183, 52, 1)'>{value}</Typography>
    </Box>
  );
};

BuilderSuccessLabel.propTypes = {
  value: PropTypes.string.isRequired
};

export default memo(BuilderSuccessLabel);