import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { createResourceRoutine } from 'actions';

export const createResource = getThunkActionCreator(
  createResourceRoutine,
  async ({ offer_id, resource }) => {
    const attributes = ['id', { file: ['url'] }];
    const data = { attributes, resource };

    return await axios.post(`/offers/${offer_id}/resources`, data);
  }
);