import { memo } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import _startCase from 'lodash/startCase';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { representationVariants } from 'helpers/constants';
import { getRepresentation, checkVowels } from 'helpers';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';

const AccessOffering = () => {
  const navigate = useNavigate();
  const mediaMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { closeAccessOfferingDialog } = useApp();
  const { open, primarySender, dealType, offerId } = useCustomSelector(state => ({
    open: state.app.accessOfferingDialog.open,
    primarySender: state.profile.recipient.offer.primary_sender,
    dealType: state.profile.recipient.offer.deal_type,
    offerId: state.profile.recipient.offer.id
  }));

  const handleClose = () => closeAccessOfferingDialog();
  const handleEntering = () => {};
  const handleExited = () => {
    if (mediaMobile) {
      navigate(`/deal_flow/${offerId}`);
    }
  };
  const handleAccessClick = () => handleClose();

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: '100%', maxWidth: '511px' } }}
      TransitionProps={{ onExited: handleExited, onEntering: handleEntering }}
      actions={null}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: '60px 78px' }}>
        <BaseAvatar
          src={getRepresentation(primarySender?.user?.photo?.representations, representationVariants.TINY)}
          firstName={primarySender?.user?.first_name ?? ' '}
          lastName={primarySender?.user?.last_name ?? ' '}
          sx={{ width: '58px', height: '58px', fontSize: '24px', boxShadow: '0px 1px 19px 0px #00000030' }}
        />
        <Typography fontSize='18px' lineHeight='29px' fontWeight={500} align='center' sx={{ mt: '16px', mb: '42px'}}>
          {primarySender?.user?.first_name} {primarySender?.user?.last_name} has shared a{checkVowels(dealType) ? 'n' : ''} {_startCase(dealType)} to your private deal feed in Qwincy.
        </Typography>
        <Button
          variant='contained'
          sx={{ width: 286, height: 49, background: 'linear-gradient(131.52deg, #4473EB 12.56%, #3149CD 98.19%)' }}
          onClick={handleAccessClick}
        >
          Enter my Deal Feed
        </Button>
      </Box>
    </BaseDialog>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AccessOffering {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);