import styled from '@mui/material/styles/styled';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const Root = styled('li')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '8px 16px',
  overflowX: 'hidden',
  padding: '0px 16px 16px',
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [theme.breakpoints.up(440)]: {
    padding: '0px 30px 16px',
  },
  [theme.breakpoints.up('lg')]: {
    flexWrap: 'nowrap',
    padding: '0px 26px',
  }
}));

export const Content = styled('div')(() => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  gap: '28px',
  padding: '28px 0px',
  overflowX: 'hidden',
}));

export const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    flexWrap: 'wrap',
    gap: '6px',
  }
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  fontWeight: 600,
  textTransform: 'uppercase',
  color: theme.palette.tertiary.contrastText,
  backgroundColor: theme.palette.tertiary.main,
}));

export const AvatarContainer = styled(Box)(({ theme }) => ({
  width: 44,
  height: 44,
  textDecoration: 'none',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '50%',
  boxShadow: '2px 2px 4px 0px #0000001A',
  padding: '2px',
}));

export const ContactsContainer = styled('div')({
  width: 'inherit',
  overflow: 'hidden',
});

export const Name = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  textDecoration: 'none',
  fontWeight: 600,
  color: 'rgba(0,0,0,.8)',
  whiteSpace: 'nowrap',
}));

export const Role = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(10),
  fontWeight: 400,
  color: 'rgba(0,0,0,.8)',
  whiteSpace: 'nowrap',
}));

export const ContactLink = styled('a')(() => ({
  color: 'rgba(0,0,0,.4)',
}));