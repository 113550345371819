import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getInvitationsRoutine,
  setInvitationTokenRoutine,
  getInvitationRoutine,
  acceptInvitationRoutine,
  declineInvitationRoutine,
  notifyInviteRoutine
} from 'actions';

const attributes = [
  'id',
  { user: ['email'] },
  { inviter: ['first_name', 'last_name', { photo: ['representations'] }] },
  { invitationable: ['id', 'username', 'name', { logo: ['id', 'representations', 'filename'] }] },
  'invitation_type',
  'invitation_token',
  'email'
];

export const setInvitationToken = getThunkActionCreator(setInvitationTokenRoutine, async invitation_token => ({ invitation_token }));
export const getInvitations = getThunkActionCreator(
  getInvitationsRoutine,
  async ({ limit = 25, offset = 0, orders = { updated_at: 'desc' }, invitation_type = null }) => {
    const params = { limit, offset, orders, attributes, ...(invitation_type && { invitation_type }) };

    return await axios.get('/invitations', { params });
  }
);
export const getInvitation = getThunkActionCreator(
  getInvitationRoutine,
  async invitation_token => {
    const params = { attributes, ...!!invitation_token && { invitation_token } };

    return await axios.get(`/invitations/${invitation_token}`, { params });
  }
);
export const acceptInvitation = getThunkActionCreator(
  acceptInvitationRoutine,
  async ({ invitation_token, inviter_id }) => {
    const response = await axios.post(`/invitations/${invitation_token}/accept`);

    return { response, inviter_id };
  }
);
export const declineInvitation = getThunkActionCreator(
  declineInvitationRoutine,
  async ({ invitation_token, inviter_id = null }) => {
    const response = await axios.post(`/invitations/${invitation_token}/decline`);

    return { response, inviter_id };
  }
);
export const notifyInvite = getThunkActionCreator(
  notifyInviteRoutine,
  async id => {
    return await axios.post(`/invitations/${id}/notify`);
  }
);