import { memo } from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
// Local files
import { ReactComponent as CompanyIcon } from 'assets/icons/company.svg';
import { ReactComponent as WebsiteIcon } from 'assets/icons/website.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin.svg';
import { Container, IconContainer } from './Links.styled';

const Links = ({ activeUser }) => {

  const checkCorrectUrl = (url) => !!url && !/^https?:\/\//i.test(url) ? 'https://' + url : url;

  return (
    <div>
      {activeUser.title &&
        <Container>
          <IconContainer>
            <CompanyIcon />
          </IconContainer>
          
          <Typography
            variant='caption'
            color='text.secondary'
          >
            {activeUser.title ?? ''}
          </Typography>
        </Container>
      }
      {activeUser.company_name &&
        <Container>
          <IconContainer>
            <CompanyIcon />
          </IconContainer>

          <Typography
            variant='caption'
            color='text.secondary'
            noWrap
          >
            {activeUser.company_name ?? ''}
          </Typography>
        </Container>
      }
      {activeUser.website_url &&
        <Container>
          <IconContainer>
            <WebsiteIcon />
          </IconContainer>
          <Typography
            variant='caption'
            color='text.secondary'
            component={Link}
            href={checkCorrectUrl(activeUser.website_url)}
            target='_blank'
            underline='hover'
            noWrap
          >
            Website
          </Typography>
        </Container>
      }
      {activeUser.linkedin_profile_url &&
        <Container>
          <IconContainer>
            <LinkedinIcon />
          </IconContainer>
          <Typography
            variant='caption'
            color='text.secondary'
            component={Link}
            href={checkCorrectUrl(activeUser.linkedin_profile_url)}
            target='_blank'
            underline='hover'
            noWrap
          >
            LinkedIn profile
          </Typography>
        </Container>
      }
    </div>
  );
};

export default memo(Links);