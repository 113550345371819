import { memo, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { ErrorBoundary } from 'react-error-boundary';
import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';
import _uniq from 'lodash/uniq';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import ListsAutocomplete from 'components/Lists/Autocomplete/Autocomplete';
import useCustomSelector from 'hooks/useCustomSelector';
import useApp from 'hooks/useApp';
import useSuccess from 'hooks/useSuccess';
import useError from 'hooks/useError';
import useBatch from 'hooks/useBatch';
import useContacts from 'hooks/useContacts';

const ListsUpdate = () => {
  const { closeListsUpdateDialog } = useApp();
  const { sendBatch } = useBatch();
  const { getContacts, getContact } = useContacts();
  const { setSuccess } = useSuccess();
  const { setError } = useError();
  const { open, contacts } = useCustomSelector(state => state.app.listsUpdateDialog);
  const params = useCustomSelector(state => state.contacts.all.filters);
  const [lists, setLists] = useState([]);
  const [processing, setProcessing] = useState(false);

  const fetchContacts = () => {
    setProcessing(true);
    getContacts(params)
    .then(() => setProcessing(false))
    .catch(e => setError(e));
  };
  const handleExited = () => setLists([]);
  const handleClose = () => closeListsUpdateDialog();
  const handleSubmit = () => {
    const listAttributes = _filter(lists, l => !!l.inputValue);
    const listIds = _filter(lists, l => !!l.id && !!!l.inputValue);

    const requests = _map(contacts, c => ({
      method: 'patch',
      url: `/contacts/${c.id}`,
      params: { contact: {
        ...(!!listAttributes.length && { lists_attributes: _map(listAttributes, la => ({ name: la.inputValue })) }),
        ...(!_isEqual(_map(_filter(c?.lists, l => !l.default), l => l.id).sort(), _uniq(_map(listIds, l => l.id).concat(_map(_filter(c?.lists, l => !l.default), l => l.id)), ['id']).sort()) && { 
          list_ids: _map(listIds, l => l.id).concat(_map(_filter(c?.lists, l => !l.default), l => l.id)) 
        })
      } }
    }));
    if (_find(requests, ({ params }) => !!Object.keys(params.contact)?.length)) {
      setProcessing(true);
      sendBatch(_filter(requests, ({ params }) => !!Object.keys(params.contact)?.length))
      .then(({ payload: { data: { responses } } }) => { 
        if(_find(responses, (r) => r.status === 422)) {
        const contactIdWithNewLists = _find(requests, (r) => !!r.params?.contact?.lists_attributes?.length)?.url?.substring(14);

          getContact(contactIdWithNewLists)
          .then(({ payload: { data: { contact: { lists } } } }) => 
            sendBatch(_map(_filter(requests, (r) => !_includes(r.url, contactIdWithNewLists)), (request) => 
            ({ ...request, params: { contact: { 
              list_ids: [
                ..._map(_filter(_find(contacts, (c) => _includes(request.url, c.id))?.lists, (list) => !list.default), (list) => list.id),
                ..._map(_filter(lists, l => !l.default && !!_find(request.params.contact.lists_attributes, (la) => la?.name === l?.name)), l => l.id)
              ] 
            } } })))
            .then(() => setSuccess('Contacts successfully updated').then(() => handleClose().then(() => fetchContacts())))
            .catch(e => setError(e).then(() => setProcessing(false)))
          )
          .catch(e => setError(e).then(() => setProcessing(false)))
        } else {
          setSuccess('Contacts successfully updated').then(() => handleClose().then(() => fetchContacts()));
          setProcessing(false);
        }
      })
      .catch(e => setError(e).then(() => setProcessing(false)))
    } else {
      handleClose();
    }
  };

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      maxWidth={false}
      PaperProps={{ sx: { maxWidth: 510, width: '100%' } }}
      title='Select list(s) where contacts will be added'
      TransitionProps={{ onExited: handleExited }}
      actions={
      <>
        <Button
          variant='text'
          color='error'
          sx={{ px: '24px' }}
          disabled={processing}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          sx={{ px: '24px'}}
          disabled={processing || !!!lists.length}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </>
      }
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: '16px !important', pb: '32px' }}>
        <ListsAutocomplete
          value={lists}
          onChange={setLists}
        />
      </DialogContent>
    </BaseDialog>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ListsUpdate {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);