import { memo, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
// Local files
import useBlob from 'hooks/useBlob';
import useChecksum from 'hooks/useChecksum';

const FileContent = ({ switcher, url, file, onChange }) => {
  const { processBlob } = useBlob();
  const { processFiles } = useChecksum();
  const [processing, setProcessing] = useState();

  const handleFileSelected = f => {
    setProcessing(true);
    processFiles([f], ({ file, checksum }) => {
      processBlob({ file, checksum }, blob => {
        if (!blob.id) return;

        const updloadedFile = { name: blob.filename, file: blob.id };

        setProcessing(false);
        onChange({ fileValue: { value: updloadedFile.file, formattedValue: updloadedFile }});
      });
    });
  };

  return (
    <RadioGroup
      value={switcher}
      onChange={(_, value) => onChange({ switcherValue: value })}
      sx={{ marginTop: '24px' }}
    >
      <FormControlLabel
        value='file'
        disableTypography
        sx={{ width: '100%', mx: 0 }}
        control={<Radio color='secondary' />}
        label={
          !file ? (
            <Button
              variant='outlined'
              component='label'
              disabled={switcher !== 'file'}
            >
              {processing ? 'Processing...' : 'Browse File'}
              <input hidden accept='*' type='file' onChange={({ target: { files } }) => files[0] && handleFileSelected(files[0])} />
            </Button>
          ) : (
            <Box component='span' sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'hidden' }}>
              <Typography variant='caption' noWrap pr={1}>{file.name ?? file.filename ?? ''}</Typography>
              <Button variant='outlined' onClick={() => onChange({ fileValue: { value: null, formattedValue: null } })}>Delete</Button>
            </Box>
          )
        }
      />
      <FormControlLabel
        value='link'
        disableTypography
        sx={{ width: '100%', mx: 0 }}
        control={<Radio color='secondary' />}
        label={
          <TextField
            fullWidth
            disabled={switcher !== 'link'}
            margin='dense'
            label='Link to Content'
            value={url}
            onChange={({ target: { value } }) => onChange({ urlValue: value.toLowerCase() })}
          />
        }
      />
    </RadioGroup>
  );
};

export default memo(FileContent);