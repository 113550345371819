import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getTemplates as getTemplatesAction,
  getTemplate as getTemplateAction,
  createTemplate as createTemplateAction,
  updateTemplate as updateTemplateAction,
  deleteTemplate as deleteTemplateAction,
  clearLocalTemplates as clearLocalTemplatesAction,
  clearLocalActiveTemplate as clearLocalActiveTemplateAction,
  setConfiguredTemplate as setConfiguredTemplateAction
} from 'actions/templates';

const useTemplates = () => {
  const dispatch = useDispatch();
  const getTemplates = useCallback(({ offer_id, limit, offset, orders, query }) => dispatch(getTemplatesAction({ offer_id, limit, offset, orders, query })), [dispatch]);
  const getTemplate = useCallback(id => dispatch(getTemplateAction(id)), [dispatch]);
  const createTemplate = useCallback(({ offer_id, document_id, template }) => dispatch(createTemplateAction({ offer_id, document_id, template })), [dispatch]);
  const updateTemplate = useCallback(template => dispatch(updateTemplateAction(template)), [dispatch]);
  const deleteTemplate = useCallback(id => dispatch(deleteTemplateAction(id)), [dispatch]);
  const clearLocalTemplates = useCallback(() => dispatch(clearLocalTemplatesAction()), [dispatch]);
  const clearLocalActiveTemplate = useCallback(() => dispatch(clearLocalActiveTemplateAction()), [dispatch]);
  const setConfiguredTemplate = useCallback(id => dispatch(setConfiguredTemplateAction(id)), [dispatch]);

  return {
    getTemplates,
    getTemplate,
    createTemplate,
    updateTemplate,
    deleteTemplate,
    clearLocalTemplates,
    clearLocalActiveTemplate,
    setConfiguredTemplate
  };
};

export default useTemplates;