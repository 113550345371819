import { createThunkRoutine } from 'redux-thunk-routine';

// Activities
export const getChartActivitiesRoutine = createThunkRoutine('GET_CHART_ACTIVITIES');
export const getTableActivitiesRoutine = createThunkRoutine('GET_TABLE_ACTIVITIES');
export const getActivityRoutine = createThunkRoutine('GET_ACTIVITY');
export const clearChartActivitiesRoutine = createThunkRoutine('CLEAR_CHART_ACTIVITIES');
export const clearTableActivitiesRoutine = createThunkRoutine('CLEAR_TABLE_ACTIVITIES');
// Agreements
export const getAgreementsRoutine = createThunkRoutine('GET_AGREEMENTS');
export const getAgreementRoutine = createThunkRoutine('GET_AGREEMENT');
export const createAgreementRoutine = createThunkRoutine('CREATE_AGREEMENT');
export const clearLocalAgreementsRoutine = createThunkRoutine('CLEAR_LOCAL_AGREEMENTS');
// Archives
export const getArchiveRoutine = createThunkRoutine('GET_ARCHIVE');
export const createArchiveRoutine = createThunkRoutine('CREATE_ARCHIVE');
// App
export const setAbortControllerRoutine = createThunkRoutine('SET_ABORT_CONTROLLER');
export const setChatsFilterRoutine = createThunkRoutine('SET_CHATS_FILTER');
export const setNotificationsOptionsRoutine = createThunkRoutine('SET_NOTIFICATIONS_OPTIONS');
export const setViewAttachmentRoutine = createThunkRoutine('SET_VIEW_ATTACHMENT');
export const setAuthDataRoutine = createThunkRoutine('SET_AUTH_DATA');
export const clearAuthDataRoutine = createThunkRoutine('CLEAR_AUTH_DATA');
export const openAccessOfferingDialogRoutine = createThunkRoutine('OPEN_ACCESS_OFFERING_DIALOG');
export const closeAccessOfferingDialogRoutine = createThunkRoutine('CLOSE_ACCESS_OFFERING_DIALOG');
export const openAccessBetaDialogRoutine = createThunkRoutine('OPEN_ACCESS_BETA_DIALOG');
export const closeAccessBetaDialogRoutine = createThunkRoutine('CLOSE_ACCESS_BETA_DIALOG');
export const openAccessFeedDialogRoutine = createThunkRoutine('OPEN_ACCESS_FEED_DIALOG');
export const closeAccessFeedDialogRoutine = createThunkRoutine('CLOSE_ACCESS_FEED_DIALOG');
export const openInviteDialogRoutine = createThunkRoutine('OPEN_INVITE_DIALOG');
export const closeInviteDialogRoutine = createThunkRoutine('CLOSE_INVITE_DIALOG');
export const openGroupDialogRoutine = createThunkRoutine('OPEN_GROUP_DIALOG');
export const closeGroupDialogRoutine = createThunkRoutine('CLOSE_GROUP_DIALOG');
export const openGroupInviteDialogRoutine = createThunkRoutine('OPEN_GROUP_INVITE_DIALOG');
export const closeGroupInviteDialogRoutine = createThunkRoutine('CLOSE_GROUP_INVITE_DIALOG');
export const openListsUpdateDialogRoutine = createThunkRoutine('OPEN_LISTS_UPDATE_DIALOG');
export const closeListsUpdateDialogRoutine = createThunkRoutine('CLOSE_LISTS_UPDATE_DIALOG');
export const openUploadContactsDialogRoutine = createThunkRoutine('OPEN_UPLOAD_CONTACTS_DIALOG');
export const closeUploadContactsDialogRoutine = createThunkRoutine('CLOSE_UPLOAD_CONTACTS_DIALOG');
export const openAgreementDialogRoutine = createThunkRoutine('OPEN_AGREEMENT_DIALOG');
export const closeAgreementDialogRoutine = createThunkRoutine('CLOSE_AGREEMENT_DIALOG');
export const openFilesCopyDialogRoutine = createThunkRoutine('OPEN_FILES_COPY_DIALOG');
export const closeFilesCopyDialogRoutine = createThunkRoutine('CLOSE_FILES_COPY_DIALOG');
export const openDeleteDialogRoutine = createThunkRoutine('OPEN_DELETE_DIALOG');
export const closeDeleteDialogRoutine = createThunkRoutine('CLOSE_DELETE_DIALOG');
export const openEditProfileDialogRoutine = createThunkRoutine('OPEN_EDIT_PROFILE_DIALOG');
export const closeEditProfileDialogRoutine = createThunkRoutine('CLOSE_EDIT_PROFILE_DIALOG');
export const openViewFileDialogRoutine = createThunkRoutine('OPEN_VIEW_FILE_DIALOG');
export const closeViewFileDialogRoutine = createThunkRoutine('CLOSE_VIEW_FILE_DIALOG');
export const openAvatarDialogRoutine = createThunkRoutine('OPEN_AVATAR_DIALOG');
export const closeAvatarDialogRoutine = createThunkRoutine('CLOSE_AVATAR_DIALOG');
export const openHeaderDialogRoutine = createThunkRoutine('OPEN_HEADER_DIALOG');
export const closeHeaderDialogRoutine = createThunkRoutine('CLOSE_GEADER_DIALOG');
export const openLockedContentDialogRoutine = createThunkRoutine('OPEN_LOCKED_CONTENT_DIALOG');
export const closeLockedContentDialogRoutine = createThunkRoutine('CLOSE_LOCKED_CONTENT_DIALOG');
export const openSendEmailNotificationDialogRoutine = createThunkRoutine('OPEN_SEND_EMAIL_NOTIFICATION_DIALOG');
export const closeSendEmailNotificationDialogRoutine = createThunkRoutine('CLOSE_SEND_EMAIL_NOTIFICATION_DIALOG');
export const openInvitedUserPopoverRoutine = createThunkRoutine('OPEN_INVITED_USER_POPOVER');
export const closeInvitedUserPopoverRoutine = createThunkRoutine('CLOSE_INVITED_USER_POPOVER');
export const openRecipientDrawerRoutine = createThunkRoutine('OPEN_RECIPIENT_DRAWER');
export const closeRecipientDrawerRoutine = createThunkRoutine('CLOSE_RECIPIENT_DRAWER');
export const openCreateListDialogRoutine = createThunkRoutine('OPEN_CREATE_LIST_DIALOG');
export const closeCreateListDialogRoutine = createThunkRoutine('CLOSE_CREATE_LIST_DIALOG');
export const openPreBuilderDialogRoutine = createThunkRoutine('OPEN_PRE_BUILDER_DIALOG');
export const closePreBuilderDialogRoutine = createThunkRoutine('CLOSE_PRE_BUILDER_DIALOG');
// Audits
export const getAuditsRoutine = createThunkRoutine('GET_AUDITS');
export const getAuditRoutine = createThunkRoutine('GET_AUDIT');
export const getCableCreatedAuditRoutine = createThunkRoutine('GET_CABLE_CREATED_AUDIT');
export const createAuditRoutine = createThunkRoutine('CREATE_AUDIT');
export const clearLocalAuditsRoutine = createThunkRoutine('CLEAR_LOCAL_AUDITS');
// Batch
export const sendBatchRoutine = createThunkRoutine('SEND_BATCH');
// Blobs
export const getBlobRoutine = createThunkRoutine('GET_BLOB');
export const createBlobRoutine = createThunkRoutine('CREATE_BLOB');
// Confirmations
export const sendConfirmationCodeRoutine = createThunkRoutine('SEND_CONFIRMATION_CODE');
export const validateConfirmationCodeRoutine = createThunkRoutine('VALIDATE_CONFIRMATION_CODE');
// Contacts
export const setContactsFiltersRoutine = createThunkRoutine('SET_CONTACTS_FILTERS');
export const getContactsListRoutine = createThunkRoutine('GET_CONTACTS_LIST');
export const getContactsRoutine = createThunkRoutine('GET_CONTACTS');
export const getSharedContactsRoutine = createThunkRoutine('GET_SHARED_CONTACTS');
export const getSuggestedContactsRoutine = createThunkRoutine('GET_SUGGESTED_CONTACTS');
export const getContactRoutine = createThunkRoutine('GET_CONTACT');
export const createContactRoutine = createThunkRoutine('CREATE_CONTACT');
export const updateContactRoutine = createThunkRoutine('UPDATE_CONTACT');
export const deleteContactRoutine = createThunkRoutine('DELETE_CONTACT');
export const importContactsRoutine = createThunkRoutine('IMPORT_CONTACTS');
export const validateContactEmailRoutine = createThunkRoutine('VALIDATE_CONTACT_EMAIL');
export const clearLocalContactsListRoutine = createThunkRoutine('CLEAR_LOCAL_CONTACTS_LIST');
export const clearLocalContactsRoutine = createThunkRoutine('CLEAR_LOCAL_CONTACTS');
export const clearLocalSharedContactsRoutine = createThunkRoutine('CLEAR_LOCAL_SHARED_CONTACTS');
export const clearLocalActiveContactRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_CONTACT');
export const filterLocalContactsRoutine = createThunkRoutine('FILTER_LOCAL_CONTACTS');
export const setUploadingFileRoutine = createThunkRoutine('SET_UPLOADED_FILE_ROUTINE');
export const clearUploadingFilesRoutine = createThunkRoutine('CLEAR_UPLOADING_FILES');
// Conversations
export const getUserConversationsRoutine = createThunkRoutine('GET_USER_CONVERSATIONS');
export const getGroupConversationsRoutine = createThunkRoutine('GET_GROUP_CONVERSATIONS');
export const getDealspaceConversationsRoutine = createThunkRoutine('GET_DEALSPACE_CONVERSATIONS');
export const getOfferConversationsRoutine = createThunkRoutine('GET_OFFER_CONVERSATIONS');
export const getConversationRoutine = createThunkRoutine('GET_CONVERSATION');
export const prefillConversationRoutine = createThunkRoutine('PREFILL_CONVERSATION');
export const createConversationRoutine = createThunkRoutine('CREATE_CONVERSATION');
export const createOfferConversationRoutine = createThunkRoutine('CREATE_OFFER_CONVERSATION');
export const updateConversationRoutine = createThunkRoutine('UPDATE_CONVERSATION');
export const deleteConversationRoutine = createThunkRoutine('DELETE_CONVERSATION');
export const addUserToConversationRoutine = createThunkRoutine('ADD_USER_TO_CONVERSATION');
export const deleteUserFromConversationRoutine = createThunkRoutine('DELETE_USER_FROM_CONVERSATION');
export const setLocalUserIdsRoutine = createThunkRoutine('SET_LOCAL_USER_IDS');
export const setLocalConversationRoutine = createThunkRoutine('SET_LOCAL_CONVERSATION')
export const clearLocalUserConversationsRoutine = createThunkRoutine('CLEAR_LOCAL_USER_CONVERSATIONS');
export const clearLocalConversationsRoutine = createThunkRoutine('CLEAR_LOCAL_CONVERSATIONS');
export const clearLocalActiveConversationRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_CONVERSATION');
// Dealspaces
export const getDealspacesForChatsRoutine = createThunkRoutine('GET_DELSPACES_FOR_CHATS');
export const getDealspacesListRoutine = createThunkRoutine('GET_DELSPACES_LIST');
export const getDealspacesRoutine = createThunkRoutine('GET_DEALSPACES');
export const getNotViewedDealspacesRoutine = createThunkRoutine('GET_NOT_VIEWED_DELSPACES');
export const getDealspaceRoutine = createThunkRoutine('GET_DEALSPACE');
export const createDealspaceRoutine = createThunkRoutine('CREATE_DEALSPACE');
export const updateDealspaceRoutine = createThunkRoutine('UPDATE_DEALSPACE');
export const deleteDealspaceRoutine = createThunkRoutine('DELETE_DEALSPACE');
export const setDealspaceUnreadCountConversationsRoutine = createThunkRoutine('SET_DEALSPACE_UNREAD_COUNT_CONVERSATIONS');
export const setDealspaceUnreadCoversationUpdatedRoutine = createThunkRoutine('SET_DEALSPACE_UNREAD_CONVERSATON_UPDATED');
export const clearLocalDealspacesForChatsRoutine = createThunkRoutine('CLEAR_LOCAL_DEALSPACES_FOR_CHATS');
export const clearLocalDealspacesListRoutine = createThunkRoutine('CLEAR_LOCAL_DEALSPACES_LIST');
export const clearLocalDealspacesRoutine = createThunkRoutine('CLEAR_LOCAL_DEALSPACES');
export const clearLocalNotViewedDealspacesRoutine = createThunkRoutine('CLEAR_LOCAL_NOT_VIEWED_DEALSPACES');
export const clearLocalActiveDealspaceRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_DEALSPACE');
// Documents
export const getPropertyDocumentsRoutine = createThunkRoutine('GET_PROPERTY_DOCUMENTS');
export const getPropertyDocumentsAmountRoutine = createThunkRoutine('GET_PROPERTY_DOCUMENTS_AMOUNT');
export const getDealspaceDocumentsRoutine = createThunkRoutine('GET_DEALSPACE_DOCUMENTS');
export const getFolderDocumentsRoutine = createThunkRoutine('GET_FOLDER_DOCUMENTS');
export const getBuilderPublicDocumentsRoutine = createThunkRoutine('GET_BUILDER_PUBLIC_DOCUMENTS');
export const getBuilderPrivateLevel1DocumentsRoutine = createThunkRoutine('GET_BUILDER_PRIVATE_LEVEL_1_DOCUMENTS');
export const getBuilderPrivateLevel2DocumentsRoutine = createThunkRoutine('GET_BUILDER_PRIVATE_LEVEL_2_DOCUMENTS');
export const getOfferPublicDocumentsRoutine = createThunkRoutine('GET_OFFER_PUBLIC_DOCUMENTS');
export const getOfferPrivateLevel1DocumentsRoutine = createThunkRoutine('GET_OFFER_PRIVATE_LEVEL_1_DOCUMENTS');
export const getOfferPrivateLevel2DocumentsRoutine = createThunkRoutine('GET_OFFER_PRIVATE_LEVEL_2_DOCUMENTS');
export const getPreviewPublicDocumentsRoutine = createThunkRoutine('GET_PREVIEW_PUBLIC_DOCUMENTS');
export const getDocumentRoutine = createThunkRoutine('GET_DOCUMENT');
export const createPropertyDocumentRoutine = createThunkRoutine('CREATE_PROPERTY_DOCUMENT');
export const createDealspaceDocumentRoutine = createThunkRoutine('CREATE_DEALSPACE_DOCUMENT');
export const createOfferDocumentRoutine = createThunkRoutine('CREATE_OFFER_DOCUMENT');
export const updateDocumentRoutine = createThunkRoutine('UPDATE_DOCUMENT');
export const deleteDocumentRoutine = createThunkRoutine('DELETE_DOCUMENT');
export const clearLocalPropertyDocumentsRoutine = createThunkRoutine('CLEAR_LOCAL_PROPERTY_DOCUMENTS');
export const clearLocalDealspaceDocumentsRoutine = createThunkRoutine('CLEAR_LOCAL_DEALSPACE_DOCUMENTS');
export const clearLocalFolderDocumentsRoutine = createThunkRoutine('CLEAR_LOCAL_FOLDER_DOCUMENTS');
export const clearLocalOfferDocumentsRoutine = createThunkRoutine('CLEAR_LOCAL_OFFER_DOCUMENTS');
export const clearLocalActiveDocumentRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_DOCUMENT');
// Emails
export const getEmailsRoutine = createThunkRoutine('GET_EMAILS');
export const getEmailRoutine = createThunkRoutine('GET_EMAIL');
export const deleteEmailRoutine = createThunkRoutine('DELETE_EMAIL');
export const clearLocalEmailsRoutine = createThunkRoutine('CLEAR_LOCAL_EMAILS');
export const clearLocalEmailRoutine = createThunkRoutine('CLEAR_LOCAL');
// Folders
export const getBuilderPublicFoldersRoutine = createThunkRoutine('GET_BUILDER_PUBLIC_FOLDERS');
export const getBuilderPrivateLevel1FoldersRoutine = createThunkRoutine('GET_BUILDER_PRIVATE_LEVEL_1_FOLDERS');
export const getBuilderPrivateLevel2FoldersRoutine = createThunkRoutine('GET_BUILDER_PRIVATE_LEVEL_2_FOLDERS');
export const getOfferPublicFoldersRoutine = createThunkRoutine('GET_OFFER_PUBLIC_FOLDERS');
export const getOfferPrivateLevel1FoldersRoutine = createThunkRoutine('GET_OFFER_PRIVATE_LEVEL_1_FOLDERS');
export const getOfferPrivateLevel2FoldersRoutine = createThunkRoutine('GET_OFFER_PRIVATE_LEVEL_2_FOLDERS');
export const getPreviewPublicFoldersRoutine = createThunkRoutine('GET_PREVIEW_PUBLIC_FOLDERS');
export const getFoldersRoutine = createThunkRoutine('GET_FOLDERS');
export const getFolderRoutine = createThunkRoutine('GET_FOLDER');
export const getActualFolderDataRoutine = createThunkRoutine('GET_ACTUAL_FOLDER_DATA');
export const createFolderRoutine = createThunkRoutine('CREATE_FOLDER');
export const updateFolderRoutine = createThunkRoutine('UPDATE_FOLDER');
export const deleteFolderRoutine = createThunkRoutine('DELETE_FOLDER');
export const clearLocalOfferFoldersRoutine = createThunkRoutine('CLEAR_LOCAL_OFFER_FOLDERS');
export const clearLocalFoldersRoutine = createThunkRoutine('CLEAR_LOCAL_FOLDERS');
export const clearLocalFolderRoutine = createThunkRoutine('CLEAR_LOCAL_FOLDER');
export const setUploadingItemRoutine = createThunkRoutine('SET_UPLOADING_ITEM');
export const removeUploadingItemRoutine = createThunkRoutine('REMOVE_UPLOADING_ITEM');
export const hideUploadingItemsRoutine = createThunkRoutine('HIDE_UPLOADING_ITEMS');
export const clearUploadingItemsRoutine = createThunkRoutine('CLEAR_UPLOADING_ITEMS');
// Groups
export const getSavedOfferGroupsRoutine = createThunkRoutine('GET_SAVED_OFFER_GROUPS');
export const getGroupsRoutine = createThunkRoutine('GET_GROUPS');
export const getGroupRoutine = createThunkRoutine('GET_GROUP');
export const createGroupRoutine = createThunkRoutine('CREATE_GROUP');
export const updateGroupRoutine = createThunkRoutine('UPDATE_GROUP');
export const deleteGroupRoutine = createThunkRoutine('DELETE_GROUP');
export const setGroupUnreadCountConversationsRoutine = createThunkRoutine('SET_GROUP_UNREAD_COUNT_CONVERSATIONS');
export const clearLocalSavedOfferGroupsRoutine = createThunkRoutine('CLEAR_LOCAL_SAVED_OFFER_GROUPS');
export const clearLocalGroupsRoutine = createThunkRoutine('CLEAR_LOCAL_GROUPS');
export const clearLocalActiveGroupRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_GROUP');
// Images
export const getDealspaceImagesRoutine = createThunkRoutine('GET_DEALSPACE_IMAGES');
export const getBuilderImagesRoutine = createThunkRoutine('GET_BUILDER_IMAGES');
export const getOfferImagesRoutine = createThunkRoutine('GET_OFFER_IMAGES');
export const getParcelImagesRoutine = createThunkRoutine('GET_PARCEL_IMAGES');
export const getPreviewImagesRoutine = createThunkRoutine('GET_PREVIEW_IMAGES');
export const getImageRoutine = createThunkRoutine('GET_IMAGE');
export const createOfferImageRoutine = createThunkRoutine('CREATE_OFFER_IMAGE');
export const updateImageRoutine = createThunkRoutine('UPDATE_IMAGE');
export const deleteImageRoutine = createThunkRoutine('DELETE_IMAGE');
export const mainImageRoutine = createThunkRoutine('MAIN_IMAGE');
export const clearLocalDealspaceImagesRoutine = createThunkRoutine('CLEAR_LOCAL_DEALSPACE_IMAGES');
export const clearLocalOfferImagesRoutine = createThunkRoutine('CLEAR_LOCAL_OFFER_IMAGES');
export const clearLocalActiveImageRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_IMAGE');
// Invitation
export const setInvitationTokenRoutine = createThunkRoutine('SET_INVITATION_TOKEN');
export const getInvitationsRoutine = createThunkRoutine('GET_INVITATIONS');
export const getInvitationRoutine = createThunkRoutine('GET_INVITATION');
export const acceptInvitationRoutine = createThunkRoutine('ACCEPT_INVITATION');
export const declineInvitationRoutine = createThunkRoutine('DECLINE_INVITATION');
export const notifyInviteRoutine = createThunkRoutine('NOTIFY_INVITE');
// Lists
export const getSharedListsRoutine = createThunkRoutine('GET_SHARED_LISTS');
export const getMyListsRoutine = createThunkRoutine('GET_MY_LISTS');
export const getListRoutine = createThunkRoutine('GET_LIST');
export const createListRoutine = createThunkRoutine('CREATE_LIST');
export const updateListRoutine = createThunkRoutine('UPDATE_LIST');
export const deleteListRoutine = createThunkRoutine('DELETE_LIST');
export const clearLocalSharedListsRoutine = createThunkRoutine('CLEAR_LOCAL_SHARED_LISTS');
export const clearLocalMyListsRoutine = createThunkRoutine('CLEAR_LOCAL_MY_LISTS');
export const clearLocalActiveListRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_LIST');
// Locations
export const getLocationParcelRoutine = createThunkRoutine('GET_LOCATION_PARCEL');
// Lookup
export const lookupIpRoutine = createThunkRoutine('LOOKUP_IP');
// Map
export const setMapInstanceRoutine = createThunkRoutine('SET_MAP_INSTANCE');
export const setMapConfigsRoutine = createThunkRoutine('SET_MAP_CONFIGS');
export const centerMapRoutine = createThunkRoutine('CENTER_MAP');
export const selectPolygonRoutine = createThunkRoutine('SELECT_POLYGON');
export const clearMapDataRoutine = createThunkRoutine('CLEAR_LOCAL_MAP_DATA');
export const clearPolygonsRoutine = createThunkRoutine('CLEAR_POLYGONS');
// Members
export const checkGroupMemberRoutine = createThunkRoutine('CHECK_GROUP_MEMBER');
export const getGroupMembersListRoutine = createThunkRoutine('GET_GROUP_MEMBERS_LIST');
export const getGroupMembersRoutine = createThunkRoutine('GET_GROUP_MEMBERS');
export const getDealspaceMembersListRoutine = createThunkRoutine('GET_DEALSPACE_MEMBERS_LIST');
export const getDealspaceMembersRoutine = createThunkRoutine('GET_DEALSPACE_MEMBERS');
export const getMemberRoutine = createThunkRoutine('GET_MEMBER');
export const createMemberRoutine = createThunkRoutine('CREATE_MEMBER');
export const updateMemberRoutine = createThunkRoutine('UPDATE_MEMBER');
export const deleteMemberRoutine = createThunkRoutine('DELETE_MEMBER');
export const clearLocalGroupMembersListRoutine = createThunkRoutine('CLEAR_LOCAL_GROUP_MEMBERS');
export const clearLocalGroupMembersRoutine = createThunkRoutine('CLER_LOCAL_GROUP_MEMBERS');
export const clearLocalDealspaceMembersListRoutine = createThunkRoutine('CLER_LOCAL_DEALSPACE_MEMBERS_LIST');
export const clearLocalDealspaceMembersRoutine = createThunkRoutine('CLER_LOCAL_DEALSPACE_MEMBERS');
export const clearLocalActiveMemberRoutine = createThunkRoutine('CLER_LOCAL_ACTIVE_MEMBER');
// Messages
export const getMessagesRoutine = createThunkRoutine('GET_MESSAGES');
export const getMessageRoutine = createThunkRoutine('GET_MESSAGE');
export const createMessageRoutine = createThunkRoutine('CREATE_MESSAGE');
export const createLocalMessageRoutine = createThunkRoutine('CREATE_LOCAL_MESSAGE');
export const deleteMessageRoutine = createThunkRoutine('DELETE_MESSAGE');
export const deleteLocalMessageRoutine = createThunkRoutine('DELETE_LOCAL_MESSAGE');
export const readMessagesRoutine = createThunkRoutine('READ_MESSAGES');
export const setLocalMessageErrorRoutine = createThunkRoutine('SET_LOCAL_MESSAGE_ERROR');
export const setLocalReadStatusRoutine = createThunkRoutine('SET_LOCAL_READ_STATUS');
export const clearLocalMessagesRoutine = createThunkRoutine('CLER_LOCAL_MESSAGES');
export const clearLocalActiveMessageRoutine = createThunkRoutine('CLER_LOCAL_ACTIVE_MESSAGE');
// Notifications
export const getUnreadNotificationsCountRoutine = createThunkRoutine('GET_UNREAD_NOTIFICATIONS_COUNT');
export const setUnreadNotificationsCountRoutine = createThunkRoutine('SET_UNREAD_NOTIFICATIONS_COUNT');
export const getNotificationsRoutine = createThunkRoutine('GET_NOTIFICATIONS');
export const getNotificationRoutine = createThunkRoutine('GET_NOTIFICATION');
export const readNotificationsRoutine = createThunkRoutine('READ_NOTIFICATIONS');
export const clearNotificationsRoutine = createThunkRoutine('CLEAR_NOTIFICATIONS');
// Nylas Users
export const createNylasUserRoutine = createThunkRoutine('CREATE_NYLAS_USER');
export const getNylasUserRoutine = createThunkRoutine('GET_NYLAS_USER');
export const deleteNylasUserRoutine = createThunkRoutine('DELETE_NYLAS_USER');
// Offers
/*** FEED ***/
export const getFeedFiltersAmountRoutine = createThunkRoutine('GET_FEED_FILTERS_AMOUNT');
export const getFeedSharedWithMeOffersRoutine = createThunkRoutine('GET_FEED_SHARED_WITH_ME_OFFERS');
export const getFeedArchiveOffersRoutine = createThunkRoutine('GET_FEED_ARCHIVE_OFFERS');
export const getFeedMySharedOffersRoutine = createThunkRoutine('GET_FEED_MY_SHARED_OFFERS');
export const getFeedMarketOffersRoutine = createThunkRoutine('GET_FEED_MARKET_OFFERS');
export const getFeedRecipientOffersRoutine = createThunkRoutine('GET_FEED_RECIPIENT_OFFERS');
export const getFeedOfferDetailsRoutine = createThunkRoutine('GET_FEED_OFFER_DETAILS');
export const clearFeedOfferDataRoutine = createThunkRoutine('CLEAR_FEED_OFFER_DATA');
export const clearFeedRecipientDataRoutine = createThunkRoutine('CLEAR_FEED_RECIPIENT_DATA');
/*** END FEED ***/
export const getPublishedOffersForChatsRoutine = createThunkRoutine('GET_PUBLISHED_OFFERS_FOR_CHATS');
export const getDealspaceOffersRoutine = createThunkRoutine('GET_DEALSPACE_OFFERS');
export const getDealspaceOffersForChatsRoutine = createThunkRoutine('GET_DEALSPACE_OFFERS_FOR_CHATS');
export const getSharedOffersRoutine = createThunkRoutine('GET_SHARED_OFFERS');
export const getDraftSharedOffersRoutine = createThunkRoutine('GET_SHARED_DRAFT_OFFERS');
export const getActiveSharedOffersRoutine = createThunkRoutine('GET_SHARED_ACTIVE_OFFERS');
export const getContactActiveOffersRoutine = createThunkRoutine('GET_CONTACT_ACTIVE_OFFERS');
export const getEndedSharedOffersRoutine = createThunkRoutine('GET_SHARED_ENDED_OFFERS');
export const getInContractSharedOffersRoutine = createThunkRoutine('GET_SHARED_SAVED_OFFERS');
export const getClosedSharedOffersRoutine = createThunkRoutine('GET_SHARED_ARCHIVED_OFFERS');
export const getGroupOffersForChatsRoutine = createThunkRoutine('GET_SHARED_OFFERS_FOR_CHATS');
export const getBuilderDraftRoutine = createThunkRoutine('GET_BUILDER_OFFER');
export const getBuilderDraftActualFilesCountRoutine = createThunkRoutine('GET_BUILDER_DRAFT_ACTUAL_FILES_COUNT');
export const getFeedMapOffersRoutine = createThunkRoutine('GET_FEED_MAP_OFFERS');
export const getActivityLogOfferRoutine = createThunkRoutine('GET_ACTIVITY_LOG_OFFER');
export const getOfferActualRecipientsCountRoutine = createThunkRoutine('GET_OFFER_ACTUAL_RECIPIENTS_COUNT');
export const getOfferPreviewRoutine = createThunkRoutine('GET_OFFER_PREVIEW');
export const createOfferRoutine = createThunkRoutine('CREATE_OFFER');
export const updateOfferRoutine = createThunkRoutine('UPDATE_OFFER');
export const deleteOfferRoutine = createThunkRoutine('DELETE_OFFER');
export const publishOfferRoutine = createThunkRoutine('PUBLISH_OFFER');
export const activateOfferRoutine = createThunkRoutine('ACTIVATE_OFFER');
export const contractOfferRoutine = createThunkRoutine('CONTRACT_OFFER');
export const endOfferRoutine = createThunkRoutine('END_OFFER');
export const closeOfferRoutine = createThunkRoutine('CLOSE_OFFER');
export const disinterestOfferRoutine = createThunkRoutine('DISINTEREST_OFFER');
export const duplicateOfferRoutine = createThunkRoutine('DUPLICATE_OFFER');
export const saveOfferRoutine = createThunkRoutine('SAVE_OFFER');
export const visitOfferRoutine = createThunkRoutine('VISIT_OFFER');
export const exportOfferRoutine = createThunkRoutine('EXPORT_OFFER');
export const intakeOfferRoutine = createThunkRoutine('INTAKE_OFFER');
export const reshareActiveOffersRoutine = createThunkRoutine('RESHARE_ACTIVE_OFFERS');
export const submitOfferShareFormRoutine = createThunkRoutine('SUBMIT_OFFER_SHARE_FORM');
export const setOfferUnreadCountConversationsRoutine = createThunkRoutine('SET_OFFER_UNREAD_COUNT_CONVERSATIONS');
export const setLocalOfferCountsRoutine = createThunkRoutine('SET_LOCAL_OFFER_COUNTS');
export const clearLocalSharedOffersRoutine = createThunkRoutine('CLEAR_LOCAL_SHARED_OFFERS');
export const clearLocalContactActiveOffersRoutine = createThunkRoutine('CLEAR_LOCAL_CONTACT_ACTIVE_OFFERS');
export const clearLocalDealspaceOffersRoutine = createThunkRoutine('CLEAR_LOCAL_DEALSPACE_OFFERS');
export const clearLocalOffersForChatsRoutine = createThunkRoutine('CLEAR_LOCAL_OFFERS_FOR_CHATS');
export const clearLocalBuilderOfferRoutine = createThunkRoutine('CLEAR_LOCAL_BUILDER_OFFER');
export const clearLocalFeedMapOffersRoutine = createThunkRoutine('CLEAR_LOCAL_FEED_MAP_OFFERS');
export const clearLocalFeedOfferRoutine = createThunkRoutine('CLEAR_LOCAL_FEED_OFFER');
export const clearLocalActivityLogOfferRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVITY_LOG_OFFER');
export const unlockLocalOfferRoutine = createThunkRoutine('UNLOCK_LOCAL_OFFER');
export const setOfferLocalFieldsRoutine = createThunkRoutine('SET_FEED_OFFER_LOCAL_FIELDS');
export const setBuilderStateRoutine = createThunkRoutine('SET_BUILDER_STATE');
// Profile
export const getShortProfileRoutine = createThunkRoutine('GET_SHORT_PROFILE');
export const getFullProfileRoutine = createThunkRoutine('GET_FULL_PROFILE');
export const unsubscribeRoutine = createThunkRoutine('UNSUBSCRIBE');
export const updateProfileRoutine = createThunkRoutine('UPDATE_PROFILE');
export const setLocalUserCountsRoutine = createThunkRoutine('SET_LOCAL_USER_COUNTS');
export const changeProfilePasswordRoutine = createThunkRoutine('CHANGE_PROFILE_PASSWORD');
export const clearLocalProfileRoutine = createThunkRoutine('CLEAR_LOCAL_PROFILE');
// Properties
export const getDealspacePropertiesListRoutine = createThunkRoutine('GET_DEALSPACE_PROPERTIES_LIST');
export const getDealspacePropertiesRoutine = createThunkRoutine('GET_DEALSPACE_PROPERTIES');
export const getOfferPropertiesRoutine = createThunkRoutine('GET_OFFER_PROPERTIES');
export const getPropertyRoutine = createThunkRoutine('GET_PROPERTY');
export const createDealspacePropertyRoutine = createThunkRoutine('CREATE_DEALSPACE_PROPERTY');
export const createOfferPropertyRoutine = createThunkRoutine('CREATE_OFFER_PROPERTY');
export const updatePropertyRoutine = createThunkRoutine('UPDATE_PROPERTY');
export const deletePropertyRoutine = createThunkRoutine('DELETE_PROPERTY');
export const clearLocalDealspacePropertiesListRoutine = createThunkRoutine('CLEAR_LOCAL_DEALSPACE_PROPERTIES_LIST');
export const clearLocalDealspacePropertiesRoutine = createThunkRoutine('CLEAR_LOCAL_DEALSPACE_PROPERTIES');
export const clearLocalOfferPropertiesRoutine = createThunkRoutine('CLEAR_LOCAL_OFFER_PROPERTIES');
export const clearLocalActivePropertyRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_PROPERTY');
// Recipients
export const getOfferFromRecipientTokenRoutine = createThunkRoutine('GET_OFFER_FROM_RECIPIENT_TOKEN');
export const getActivityLogRecipientsRoutine = createThunkRoutine('GET_ACTIVITY_LOG_RECIPIENTS');
export const getSharedRecipientsRoutine = createThunkRoutine('GET_SHARED_RECIPIENTS');
export const getRecipientRoutine = createThunkRoutine('GET_RECIPIENT');
export const createRecipientRoutine = createThunkRoutine('CREATE_RECIPIENT');
export const updateRecipientRoutine = createThunkRoutine('UPDATE_RECIPIENT');
export const deleteRecipientRoutine = createThunkRoutine('DELETE_RECIPIENT');
export const notifyRecipientsRoutine = createThunkRoutine('NOTIFY_RECIPIENT');
export const clearLocalActivityLogRecipientsRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVITY_LOG_RECIPIENTS');
export const clearLocalSharedRecipientsRoutine = createThunkRoutine('CLEAR_LOCAL_SHARED_RECIPIENTS');
// Resources
export const createResourceRoutine = createThunkRoutine('CREATE_RESOURCE');
// Senders
export const getSendersRoutine = createThunkRoutine('GET_SENDERS');
export const getBuilderSendersRoutine = createThunkRoutine('GET_BUILDER_SENDERS');
export const getPreviewSendersRoutine = createThunkRoutine('GET_PREVIEW_SENDERS');
export const getSenderRoutine = createThunkRoutine('GET_SENDER');
export const createSenderRoutine = createThunkRoutine('CREATE_SENDER');
export const updateSenderRoutine = createThunkRoutine('UPDATE_SENDER');
export const deleteSenderRoutine = createThunkRoutine('DELETE_SENDER');
export const clearLocalSendersRoutine = createThunkRoutine('CLEAR_LOCAL_SENDER');
export const clearLocalActiveSenderRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_SENDER');
// Sessions
export const createSessionRoutine = createThunkRoutine('CREATE_SESSION');
export const deleteSessionRoutine = createThunkRoutine('DELETE_SESSION');
export const setLocalSessionRoutine = createThunkRoutine('SET_LOCAL_SESSION');
// Shares
export const getSharesRoutine = createThunkRoutine('GET_SHARES');
export const getShareRoutine = createThunkRoutine('GET_SHARE');
export const createShareRoutine = createThunkRoutine('CREATE_SHARE');
export const updateShareRoutine = createThunkRoutine('UPDATE_SHARE');
export const clearLocalSharesRoutine = createThunkRoutine('CLEAR_LOCAL_SHARES');
// Store
export const clearStoreRoutine = createThunkRoutine('CLEAR_STORE');
// Templates
export const getTemplatesRoutine = createThunkRoutine('GET_TEMPLATES');
export const getTemplateRoutine = createThunkRoutine('GET_TEMPLATE');
export const createTemplateRoutine = createThunkRoutine('CREATE_TEMPLATE');
export const updateTemplateRoutine = createThunkRoutine('UPDATE_TEMPLATE');
export const deleteTemplateRoutine = createThunkRoutine('DELETE_TEMPLATE');
export const clearLocalTemplatesRoutine = createThunkRoutine('CLEAR_OFFER_TEMPLATES');
export const clearLocalActiveTemplateRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_TEMPLATE');
export const setConfiguredTemplateRoutine = createThunkRoutine('SET_CONFIGURED_TEMPLATE');
// Tracks
export const addToTracksOfferRoutine = createThunkRoutine('ADD_TO_TRACKS_OFFER');
export const removeFromTracksOfferRoutine = createThunkRoutine('REMOVE_FROM_TRACKS_OFFER');
// Users
export const getSuggestedUsersRoutine = createThunkRoutine('GET_SUGGESTED_USERS');
export const getUsersListRoutine = createThunkRoutine('GET_USERS_LIST');
export const getUsersWidgetListRoutine = createThunkRoutine('GET_USERS_WIDGET_LIST');
export const getUserRoutine = createThunkRoutine('GET_USER');
export const getPublicUserRoutine = createThunkRoutine('GET_PUBLIC_USER');
export const getPublicUserVCardRoutine = createThunkRoutine('GET_PUBLIC_USER_V_CARD');
export const getIntakeUserRoutine = createThunkRoutine('GET_INTAKE_USER');
export const createUserRoutine = createThunkRoutine('CREATE_USER');
export const changeUserPasswordRoutine = createThunkRoutine('CHANGE_USER_PASSWORD');
export const validateUserRoutine = createThunkRoutine('VALIDATE_USER');
export const validateUserEmailRoutine = createThunkRoutine('VALIDATE_USER_EMAIL');
export const clearLocalSuggestedUsersRoutine = createThunkRoutine('CLEAR_LOCAL_SUGGESTED_USERS');
export const clearLocalUsersListRoutine = createThunkRoutine('CLEAR_LOCAL_USERS_LIST');
export const clearLocalUsersWidgetListRoutine = createThunkRoutine('CLEAR_LOCAL_USERS_WIDGET_LIST');
export const clearLocalActiveUserRoutine = createThunkRoutine('CLEAR_LOCAL_ACTIVE_USER');
// Views
export const getViewsRoutine = createThunkRoutine('GET_VIEWS');
export const viewDealspaceRoutine = createThunkRoutine('VIEW_DEALSPACE');
export const updateViewRoutine = createThunkRoutine('UPDATE_VIEW');
export const clearLocalViewsRoutine = createThunkRoutine('CLEAR_LOCAL_VIEWS');