import styled from '@mui/material/styles/styled';
import ButtonBase from '@mui/material/ButtonBase';

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  height: 32,
  width: 32,
  backgroundColor: 'rgba(255,255,255,.85)',
  color: theme.palette.primary.main,
  borderRadius: '50%',
  fontSize: '1.3rem'
}));
const Root = styled('div')({
  width: 135,
  height: 135,
  backgroundColor: '#fff',
  borderRadius: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '-75px'
});

export { StyledButton, Root };