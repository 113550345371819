import SvgIcon from '@mui/material/SvgIcon';

export default function ClockIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M12 20C9.87823 20 7.84346 19.1572 6.3432 17.6568C4.84281 16.1565 4 14.1219 4 12C4 9.8781 4.84281 7.84346 6.3432 6.3432C7.84346 4.84281 9.8781 4 12 4C14.1219 4 16.1565 4.84281 17.6568 6.3432C19.1572 7.84346 20 9.8781 20 12C19.9974 14.1209 19.1538 16.1541 17.6541 17.654C16.1542 19.1538 14.1209 19.9974 12.0001 19.9999L12 20ZM12 5.45455C10.2641 5.45455 8.59917 6.14421 7.37177 7.3716C6.14438 8.59913 5.45472 10.2641 5.45472 11.9998C5.45472 13.7356 6.14438 15.4007 7.37177 16.6281C8.59931 17.8554 10.2642 18.5451 12 18.5451C13.7358 18.5451 15.4008 17.8554 16.6282 16.6281C17.8556 15.4005 18.5453 13.7356 18.5453 11.9998C18.5433 10.2645 17.8531 8.60076 16.6259 7.37368C15.3989 6.14653 13.7352 5.45635 11.9998 5.4543L12 5.45455Z" />
      <path fill="currentColor" d="M14.1814 14.9093C13.9885 14.9094 13.8036 14.8327 13.6675 14.6959L11.4856 12.5141C11.3493 12.3779 11.2724 12.1929 11.2723 12.0002V7.63653C11.2723 7.37666 11.411 7.13665 11.6359 7.00664C11.8609 6.87677 12.1382 6.87677 12.3632 7.00664C12.5882 7.13665 12.7269 7.37665 12.7269 7.63653V11.6989L14.6953 13.6679C14.8316 13.8042 14.908 13.9892 14.908 14.182C14.908 14.3747 14.8314 14.5595 14.6952 14.6959C14.559 14.8323 14.3742 14.909 14.1814 14.9091L14.1814 14.9093Z" />
    </SvgIcon>
  );
}

/*
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="" fill="#757575"/>
  <path d="" fill="#757575"/>
</svg>
*/
