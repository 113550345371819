import styled from '@mui/material/styles/styled';
import MuiTableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

export const hintColor = 'rgba(0,0,0,.1)'

export const TableCell = styled(MuiTableCell)({
  minHeight: 66,
  height: 66,
  fontSize: '0.75rem',
  color: 'rgba(0,0,0,.7)',
  padding: '2px 12px',
});

export const AddButton = styled(Button)({
  width: 30,
  height: 30,
  color: 'rgba(0,0,0,.3)',
  background: '#F8F9FB',
  padding: '2px',
  '& svg': {
    fontSize: '1.25rem',
  }
});
export const StyledIconButton = styled(IconButton)({
  color: 'rgba(248, 249, 251, 1)',
  background: '#F8F9FB',
  padding: '10px',
  borderRadius: 6
});
