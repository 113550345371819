import { forwardRef, lazy, memo, Suspense, useCallback, useEffect, useImperativeHandle, useState, useRef } from 'react';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _startCase from 'lodash/startCase';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Local files
import { Fallback as EmptyFallback } from '../Empty/Empty';
import { Fallback as RecipientsFallback } from '../../Table/Table';
import { Fallback as SharingAccessDialogFallback } from 'components/Dialogs/SharingAccess/SharingAccess';
import { Fallback as NotifyRecipientsDialogFallback } from 'components/Dialogs/NotifyRecipients/NotifyRecipients';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useRecipients from 'hooks/useRecipients';
import useSuccess from 'hooks/useSuccess';

const Empty = lazy(() => import('../Empty/Empty'));
const Recipients = lazy(() => import('../../Table/Table'));
const SharingAccessDialog = lazy(() => import('components/Dialogs/SharingAccess/SharingAccess'));
const NotifyRecipientsDialog = lazy(() => import('components/Dialogs/NotifyRecipients/NotifyRecipients'));

const rowsPerPage = 50;

const Shared = forwardRef(({ tab, filters }, ref) => {
  const { offer_id } = useParams();
  const { setError } = useError();
  const { getActivityLogRecipients, notifyRecipients } = useRecipients();
  const { setSuccess } = useSuccess();
  const { offer, recipients, recipientIds, totalAmount } = useCustomSelector(state => ({
    offer: state.offers.activityLog,
    recipients: state.recipients.activityLog.data,
    recipientIds: _map(state.recipients.activityLog.data, r => r.id),
    totalAmount: state.recipients.activityLog.pagination.total_count,
  }));
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('desc');
  const [selectedItems, setSelectedItems] = useState([]);
  const [notifyRecipientsDialog, setNotifyRecipientsDialog] = useState({ open: false, recipient: null });
  const [loading, setLoading] = useState(false);
  const [sharingDialog, setSharingDialog] = useState({ open: false, name: '', public: false, headline: '', offerId: '', groupId: '', primarySender: null });
  const showEmpty = offer.id && totalAmount === 0 && query.length === 0;
  const emptyProps = { ..._pick(offer, ['id', 'name', 'headline', 'public', 'primary_sender']), groupId: offer.dealspace.group.id };
  const recipientsProps = { page, query, order, orderBy, selectedItems, rowsPerPage };
  const [isFirstFetching, setIsFirstFetching] = useState(true);
  const prevQuery = useRef(query);
  const prevParams = useRef({ page, order, orderBy, filters, offer_id });
  const intervalRef = useRef(null);

  const fetchRecipients = useCallback(({ offer_id, offset, query, orders, filters, ids }) => {
    getActivityLogRecipients({ offer_id, offset, query, ...(!!orders && { orders }), filters, ...(!!ids && { ids }) })
    .catch(e => setError(e));
  }, [getActivityLogRecipients, setError]);

  const handleQueryChange = q => {
    setQuery(q);
    setPage(0);
  }
  const handlePageChange = p => setPage(p);
  const handleOrdersChange = v => {
    if (orderBy === v) {
      setOrder(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(v);
    }
  };
  const handleSelectedItemChange = ({ id, checked }) => {
    if (id === 'all') {
      setSelectedItems(checked ? _map(recipients, r => r.id) : []);
    } else {
      setSelectedItems(prev => !!_find(selectedItems, si => si === id)
        ? _filter(prev, p => p !== id)
        : [...prev, id]
      );
    }
  };
  const handleNotifyRecipientsClick = (recipient_id = null, recipients_type = null, subject, message) => {
    setLoading(true);
    notifyRecipients({ offer_id, recipient_id, recipients_type, subject, message })
    .then(() => 
      setSuccess(`Email notification successfully sent ${recipients_type ? `to ${_startCase(recipients_type)} recipients` : ''}`)
      .then(() => setNotifyRecipientsDialog({ open: false, recipient: null }))
    )
    .catch(e => setError(e))
    .finally(() => setLoading(false));
  };
  const handleSharingDialogClose = needToUpdateRecipients => {
    setSharingDialog({ open: false, name: '', public: false, headline: '', offerId: '', groupId: '', primarySender: null });
    
    if (needToUpdateRecipients) { // Тут нам надо обновить реципиентов. Если что-то введено в поле поиска - это и так триггернет запрос
      if (!!query || page !== 0) {
        setQuery('');
        setPage(0);
       } else {
        if (offer.id) fetchRecipients({ offer_id, offset: 0, query: '', orders: !!orderBy ? { [orderBy]: order } : null, filters });
      }
    }
  };
  const handleEmptyUpdate = () => {
    if (offer.id) fetchRecipients({ offer_id, offset: 0, query, orders: !!orderBy ? { [orderBy]: order } : null, filters });
  };

  useEffect(() => {
    // функция для обработки поиска и изменений параметров, при передаче параметра ids делаем get запрос для апдейта загруженных данных
    const handleSearchAndActionRecipients = (ids = null) => {
      const orders = !!orderBy ? { [orderBy]: order } : null;

      if (offer.id) {
        setIsFirstFetching(false);
        fetchRecipients({ offer_id, offset: page * rowsPerPage, query, orders, filters, ids });
      }
    };
    // загружаем данные при первом запуске
    if (isFirstFetching) {
      handleSearchAndActionRecipients();
    }
  
    const timeout = setTimeout(() => {
      // проверка изменений в  поиске
      if (prevQuery.current !== query) {
        // если есть изменения в поиске - очищаем интервал
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
  
        // вызов функции без ожидания
        handleSearchAndActionRecipients();
  
        // обновление предыдущего поиска
        prevQuery.current = query;
      } else {
        // проверка изменений параметров
        const paramsChanged =
          prevParams.current.page !== page ||
          prevParams.current.order !== order ||
          prevParams.current.orderBy !== orderBy ||
          prevParams.current.filters !== filters ||
          prevParams.current.offer_id !== offer_id;
  
        if (paramsChanged) {
          // если параметры изменились, очищаем интервал и вызываем функцию
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
          }
          handleSearchAndActionRecipients();
  
          // обновляем предыдущие параметры
          prevParams.current = { page, order, orderBy, filters, offer_id };
        } else {
          // если параметры не изменились, установливаем интервал каждые 20 секунд
          if (!intervalRef.current && !!recipientIds.length) {
            intervalRef.current = setInterval(() => {
              handleSearchAndActionRecipients(recipientIds);
            }, 20000);
          }
        }
      }
    }, 500);
  
    // очищаем интервал при изменениях
    return () => {
      clearTimeout(timeout);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [fetchRecipients, offer.id, page, order, orderBy, filters, offer_id, query, recipientIds]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => {
    return {
      openSharingDialog() {
        setSharingDialog({
          open: true,
          name: offer.name,
          public: offer.public,
          headline: offer.headline,
          offerId: offer.id,
          groupId: offer.dealspace.group.id,
          primarySender: offer.primary_sender
        });
      },
      openNotifyDialog() {
        setNotifyRecipientsDialog({ open: true, recipient: null });
      }
    };
  }, [offer]);

  if (showEmpty) {
    return (
      <>
        <Suspense fallback={<SharingAccessDialogFallback />}>
          <SharingAccessDialog
            {...sharingDialog}
            data={recipients}
            onClose={handleSharingDialogClose}
          />
        </Suspense>
        <Suspense fallback={<NotifyRecipientsDialogFallback />}>
          <NotifyRecipientsDialog
            {...notifyRecipientsDialog}
            processing={loading}
            offer={offer}
            onClose={() => setNotifyRecipientsDialog({ open: false, recipient: null })}
            onSend={handleNotifyRecipientsClick}
          />
        </Suspense>
        <Suspense fallback={<EmptyFallback />}>
          <Empty {...emptyProps} onUpdate={handleEmptyUpdate} />  
        </Suspense>
      </>
    );
  }
  if (tab !== 'shared') {
    return (
      <>
        <Suspense fallback={<SharingAccessDialog />}>
          <SharingAccessDialog
            {...sharingDialog}
            data={recipients}
            onClose={handleSharingDialogClose}
          />
        </Suspense>
        <Suspense fallback={<NotifyRecipientsDialogFallback />}>
          <NotifyRecipientsDialog
            {...notifyRecipientsDialog}
            processing={loading}
            offer={offer}
            onClose={() => setNotifyRecipientsDialog({ open: false, recipient: null })}
            onSend={handleNotifyRecipientsClick}
          />
        </Suspense>
      </>
    );
  }
  return (
    <>
      <Suspense fallback={<SharingAccessDialogFallback />}>
        <SharingAccessDialog
          {...sharingDialog}
          data={recipients}
          onClose={handleSharingDialogClose}
        />
      </Suspense>
      <Suspense fallback={<NotifyRecipientsDialogFallback />}>
        <NotifyRecipientsDialog
          {...notifyRecipientsDialog}
          processing={loading}
          offer={offer}
          onClose={() => setNotifyRecipientsDialog({ open: false, recipient: null })}
          onSend={handleNotifyRecipientsClick}
        />
      </Suspense>
      <Suspense fallback={<RecipientsFallback />}>
        <Recipients
          {...recipientsProps}
          onPageChange={handlePageChange}
          onQueryChange={handleQueryChange}
          onOrdersChange={handleOrdersChange}
          onSelectedItemsChange={handleSelectedItemChange}
          onNotifyRecipientsDialog={setNotifyRecipientsDialog}
        />
      </Suspense>
    </>
  );
});

Shared.propTypes = {
  tab: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired
};

const WrappedComponent = forwardRef((props, ref) => {
  return (
    <ErrorWrapper>
      <Shared {...props} ref={ref} />
    </ErrorWrapper>
  );
});

export default memo(WrappedComponent);