import { extension } from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getEmailsRoutine,
  getEmailRoutine,
  deleteEmailRoutine,
  clearLocalEmailsRoutine,
  clearLocalEmailRoutine
} from 'actions';

export const getEmails = getThunkActionCreator(
  getEmailsRoutine,
  async ({ nylas_user_id, limit = 25, offset = 0, orders = { created_at: 'desc' }, query = null }) => {
    const attributes = [
      'id',
      'senders',
      'deal_type',
      'asset_classes',
      'price_cents',
      'addresses',
      'status',
      'deadline_at',
      'received_at'
    ];
    const params = { limit, offset, orders, attributes, status: 'selected', ...query && { query } };

    return await extension.get(`/nylas_users/${nylas_user_id}/emails`, { params });
  }
);
export const getEmail = getThunkActionCreator(
  getEmailRoutine,
  async id => {
    const attributes = [
      'id',
      { attachments: ['id', 'filename', 'content_type', 'byte_size'] },
      { images: ['id'] },
      'senders',
      'deal_type',
      'asset_classes',
      'price_cents',
      'addresses',
      'deadline_at',
      'received_at',
      'links',
      'summary',
      'nylas_message_id',
      'status'
    ];
    const params = { attributes };

    return await extension.get(`/emails/${id}`, { params });
  }
);
export const deleteEmail = getThunkActionCreator(
  deleteEmailRoutine,
  async id => {
    const response = await extension.delete(`/emails/${id}`);

    return { response, id };
  }
);
export const clearLocalEmails = getThunkActionCreator(clearLocalEmailsRoutine, async () => {});
export const clearLocalEmail = getThunkActionCreator(clearLocalEmailRoutine, async () => {});