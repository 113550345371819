import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  setContactsFiltersRoutine,
  getContactsListRoutine,
  getContactsRoutine,
  getSharedContactsRoutine,
  getSuggestedContactsRoutine,
  getContactRoutine,
  createContactRoutine,
  updateContactRoutine,
  deleteContactRoutine,
  importContactsRoutine,
  validateContactEmailRoutine,
  clearLocalContactsListRoutine,
  clearLocalContactsRoutine,
  clearLocalSharedContactsRoutine,
  clearLocalActiveContactRoutine,
  filterLocalContactsRoutine,
  setUploadingFileRoutine,
  clearUploadingFilesRoutine
} from 'actions';

const listAttributes = ['id', { user: ['id', 'username', 'first_name', 'last_name', 'email'] }, 'first_name', 'last_name', 'email'];
const fullAttributes = [
  'id',
  { 
    user: [
      'id', 'username', 'first_name', 'last_name', 'email', 'title', 'linkedin_profile_url', 'website_url',
      'location', 'street_address', 'second_address', 'state', 'zip', 'city', 
      'mobile_phone', 'office_phone', 'office_phone_extension', 'company_name', 'headline',
      { photo: ['representations'] },
      { private_conversation: [
        'id', 
        { last_message: ['id', 'read', 'text', { user: ['id', 'first_name', 'last_name'] }, 'created_at'] }, 
        { users: ['id', 'first_name', 'last_name', { photo: ['id', 'representations'] }] }, 
        { conversationable: ['id', 'name', { cover: ['representations'] }, 'headline', 'unread_conversations_count'] },
        'updated_at'
      ]}
    ] 
  },
  { suggested_user: ['username'] },
  { invitation: [
    { inviter: ['id', 'first_name', 'last_name', { photo: ['id', 'representations'] }, 'email', 'title', 'location', 'street_address', 'second_address', 'state', 'zip', 'city', 'linkedin_profile_url', 'website_url', 'mobile_phone', 'office_phone', 'office_phone_extension', 'company_name'] }, 
    { user: ['id', 'first_name', 'last_name', { photo: ['id', 'representations'] }, 'email', 'title', 'location', 'street_address', 'second_address', 'state', 'zip', 'city', 'linkedin_profile_url', 'website_url', 'mobile_phone', 'office_phone', 'office_phone_extension', 'company_name'] },  
    'invitation_token'
  ] },
  'first_name',
  'last_name',
  'email',
  'quarantine_type',
  'quarantined_at',
  'title',
  'mobile_phone',
  'office_phone',
  'office_phone_extension',
  'notes',
  'created_at',
  { lists: ['id', { groups: ['id', 'name'] }, 'name', 'contacts_count', 'default'] },
  'company_name',
  'location',
  'street_address',
  'second_address',
  'state',
  'zip',
  'city',
  'linkedin_profile_url',
  'website_url',
  'status',
  'sharing_subscribed'
];

export const setContactsFilters = getThunkActionCreator(
  setContactsFiltersRoutine,
  async ({ offset, orders, query, lists, tab, member, pending, quarantined }) => ({ offset, orders, query, lists, tab, member, pending, quarantined })
);
export const getContactsList = getThunkActionCreator(
  getContactsListRoutine,
  async ({ offset = 0, query, member = false, not_member = false }) => {
    const params = {
      limit: 25,
      offset,
      orders: { first_name: 'asc' },
      attributes: listAttributes,
      ...member && { member },
      ...query && { query },
      ...not_member && { not_member }
    };

    return await axios.get('/contacts', { params });
  }
);
export const getContacts = getThunkActionCreator(
  getContactsRoutine,
  async ({ limit = 25, offset = 0, lists = null, orders = { updated_at: 'desc' }, query = null, pending = null, member = null, quarantined = null }) => {
    const params = {
      limit,
      offset,
      orders,
      my: true,
      attributes: fullAttributes,
      ...pending && { pending },
      ...quarantined ? { quarantined } : { not_quarantined: true },
      ...member && { member },
      ...lists && { list_ids: lists.map(l => l.id) },
      ...query && { query }
    };

    return await axios.get('/contacts', { params });
  }
);
export const getSharedContacts = getThunkActionCreator(
  getSharedContactsRoutine,
  async ({ limit = 25, offset = 0, list_ids, orders = { updated_at: 'desc' }, query = null, offer_id = null }) => {
    const attributes = [
      'id',
      { user: ['id', 'username', 'first_name', 'last_name', 'email', { photo: ['representations'] }] },
      {
        invitation: [
          { inviter: ['id', 'first_name', 'last_name', 'email', { photo: ['id', 'representations'] }] },
          { user: ['id', 'first_name', 'last_name', 'email', { photo: ['id', 'representations'] }] }
        ]
      },
      { lists: ['id'] },
      'first_name',
      'last_name',
      'email',
      'recipiented',
      'shared_names',
      { owner: ['id'] }
    ];
    const params = {
      limit,
      offset,
      orders,
      attributes,
      sharing_subscribed: true,
      not_quarantined: true,
      list_ids,
      ...query && { query },
      ...offer_id && { offer_id }
    };

    return await axios.get('/contacts', { params });
  }
);
export const getSuggestedContacts = getThunkActionCreator(
  getSuggestedContactsRoutine,
  async ({ list_ids, included_contact_ids, excluded_contact_ids }) => {
    const attributes = ['id'];
    const data = { attributes, list_ids, included_contact_ids, excluded_contact_ids, limit: 0 };

    return await axios.post('/contacts/suggest', data);
  }
);
export const getContact = getThunkActionCreator(
  getContactRoutine,
  async id => {
    const params = { attributes: fullAttributes };

    return await axios.get(`/contacts/${id}`, { params });
  }
);
export const createContact = getThunkActionCreator(
  createContactRoutine,
  async ({ contact, isAllContacts = true }) => {
    const data = { contact, attributes: fullAttributes };
    const response =  await axios.post('/contacts', data);

    return { response, isAllContacts };
  }
);
export const updateContact = getThunkActionCreator(
  updateContactRoutine,
  async ({ id, ...fields }) => {
    const response = await axios.patch(`/contacts/${id}`, { contact: fields });

    return { response, contact: { id, ...fields } };
  }
);
export const deleteContact = getThunkActionCreator(
  deleteContactRoutine,
  async id => {
    const response = await axios.delete(`/contacts/${id}`);

    return { response, id };
  }
);
export const importConcatcs = getThunkActionCreator(
  importContactsRoutine,
  async ({ file, contact }) => {
    const data = { file, contact };

    return await axios.post('/contacts/import', data);
  }
);
export const validateContactEmail = getThunkActionCreator(
  validateContactEmailRoutine, 
  async (email) => {
    return await axios.post('/contacts/validate_email', { email })
  });
export const clearLocalContactsList = getThunkActionCreator(clearLocalContactsListRoutine, async () => {});
export const clearLocalContacts = getThunkActionCreator(clearLocalContactsRoutine, async () => {});
export const clearLocalSharedContacts = getThunkActionCreator(clearLocalSharedContactsRoutine, async () => {});
export const clearLocalActiveContact = getThunkActionCreator(clearLocalActiveContactRoutine, async () => {});
export const filterLocalContacts = getThunkActionCreator(filterLocalContactsRoutine, async (ids) => ({ ids }));
export const setUploadingFile = getThunkActionCreator(setUploadingFileRoutine, async ({ file_id, filename, lines_count, progress }) => ({ file_id, filename, lines_count, progress }));
export const clearUploadingFiles = getThunkActionCreator(clearUploadingFilesRoutine, async () => {});