import { memo, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
// Local files
import { ReactComponent as EnabledSharedListIcon } from 'assets/icons/shared-list-enabled-small.svg';
import { ReactComponent as DisabledSharedListIcon } from 'assets/icons/shared-list-disabled.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-list.svg';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useError from 'hooks/useError';
import useLists from 'hooks/useLists';

const Card = ({ id, name, groups, contacts_count, onShare, onDelete }) => {
  const { setError } = useError();
  const { updateList } = useLists();
  const [processing, setProcessing] = useState(false);
  const enabledIcon = !!groups.length;

  const handleBlur = ({ target: { value } }) => {
    setProcessing(true);
    updateList({ id, name: value })
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };

  return (
    <Box display='flex' alignItems='center' gap='16px' marginBottom='6px'>
      <TextField
        fullWidth
        sx={{ flexBasis: '68%', '& input': { py: '.75rem' } }}
        variant='outlined'
        defaultValue={name}
        disabled={processing}
        onBlur={handleBlur}
      />
      <Typography sx={{ flexShrink: 0, fontSize: '.75rem', fontWeight: 500, color: 'rgba(0, 0, 0, 0.3)', whiteSpace: 'nowrap',  marginRight: 'auto' }}>
        {contacts_count}
      </Typography>
      <Button variant='blank' color='neutral' onClick={() => onShare(id)}>
        {enabledIcon ? <EnabledSharedListIcon /> : <DisabledSharedListIcon />}
      </Button>
      <Button variant='blank' color='neutral' onClick={() => onDelete(id)}>
        <DeleteIcon />
      </Button>
    </Box>
  );
};

Card.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  contacts_count: PropTypes.number.isRequired,
  onShare: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Card {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);