import { memo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// Local files
import Table from 'components/Agreements/Table/Table';

const SignedGateAgreements = () => {
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  if (mediaDesktop) {
    return (
      <Box pt={8} pb={8} pl={12} pr={12}>
        <Typography fontWeight={600} fontSize='20px' lineHeight='15px' pb={3}>
          Signed Confidentiality Agreements
        </Typography>
        <Table />
      </Box>
    );
  }
  return (
    <Box pt={1} pb={1} pl={1.5} pr={1.5}>
      <Typography fontWeight={600} fontSize='20px' lineHeight='15px' textAlign='center' mt={2} mb={2}>
        Signed Confidentiality Agreements
      </Typography>
      <Table mobile />
    </Box>
  );
};

export default memo(SignedGateAgreements);