import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import MuiButton from '@mui/material/Button';
import MuiBadge from '@mui/material/Badge';

const Root = styled('div')({
  flexShrink: 0,
  width: '100%',
  padding: '40px 24px 24px'
});
const Title = styled((props) => (
  <Typography variant='subtitle1' {...props} />
))({
  textAlign: 'center',
  paddingTop: '12px'
});
const Actions = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  gap: '8px',
  paddingTop: '12px'
});
const StyledButton = styled(MuiButton)({
  flexShrink: 0,
  minWidth: 'unset',
  color: '#5B5B5B',
  borderRadius: '8px',
  padding: '8px'
});
const Badge = styled(MuiBadge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 5,
    right: 5,
    minWidth: 6,
    width: 6,
    height: 6,
    border: `1px solid ${theme.palette.background.paper}`
  }
}));

export { Root, Title, Actions, StyledButton, Badge };