import axios, { instance } from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { sessionService } from 'redux-react-session';
import {
  getSuggestedUsersRoutine,
  getUsersListRoutine,
  getUsersWidgetListRoutine,
  getUserRoutine,
  getPublicUserRoutine,
  getPublicUserVCardRoutine,
  getIntakeUserRoutine,
  createUserRoutine,
  changeUserPasswordRoutine,
  validateUserRoutine,
  validateUserEmailRoutine,
  clearLocalSuggestedUsersRoutine,
  clearLocalUsersListRoutine,
  clearLocalUsersWidgetListRoutine,
  clearLocalActiveUserRoutine
} from 'actions';

const listAttributes = ['id', 'first_name', 'last_name', 'email', { photo: ['id', 'representations'] }];
const widgetListAttributes = ['id', 'first_name', 'last_name', 'headline', 'username', { photo: ['id', 'representations'] }];


export const getSuggestedUsers = getThunkActionCreator(
  getSuggestedUsersRoutine,
  async ({ offer_id, query = null, offset = 0 }) => {
    const params = { limit: 25, offset, orders: { first_name: 'asc' }, attributes: listAttributes, ...(!!query && { query }) };

    return await axios.get(`/offers/${offer_id}/users/suggested`, { params });
  }
);
export const getUsersList = getThunkActionCreator(
  getUsersListRoutine,
  async ({ query = null, suitable_by_group_id = null }) => {
    const params = { orders: { first_name: 'asc' }, attributes: listAttributes, ...query && { query }, ...suitable_by_group_id && { suitable_by_group_id } };

    return await axios.get('/users', { params });
  }
);
export const getUsersWidgetList = getThunkActionCreator(
  getUsersWidgetListRoutine,
  async (query = null) => {
    const params = { orders: { first_name: 'asc' }, attributes: widgetListAttributes, limit: 4, ...query && { query } };

    return await axios.get('/users', { params });
  }
);
export const getUser = getThunkActionCreator(
  getUserRoutine,
  async username => {
    const attributes = [
      ...listAttributes,
      { current_contact: ['id', 'status', { user: ['id'] }] },
      { header: ['id', 'url'] },
      { contents: ['id', { file: ['id', 'representations'] }, { avatar: ['id', 'representations'] }, 'title', 'published_at', 'url'] },
      { teammates: ['id', { user: ['id', 'username', 'first_name', 'last_name', { photo: ['representations'] }]}, { invitation: ['email', 'message'] }] },
      { private_conversation: [
        'id', 
        { last_message: ['id', 'read', 'text', { user: ['id', 'first_name', 'last_name'] }, 'created_at'] }, 
        { users: ['id', 'first_name', 'last_name', { photo: ['id', 'representations'] }] }, 
        { conversationable: ['id', 'name', { cover: ['representations'] }, 'headline', 'unread_conversations_count'] },
        'conversationable_type',
        'updated_at'
      ]},
      { geographic_focuses: ['id', 'address', 'latitude', 'longitude'] },
      'headline',
      'title',
      'company_name',
      'location',
      'latitude',
      'longitude',
      'about',
      'mobile_phone',
      'office_phone',
      'office_phone_extension',
      'linkedin_profile_url',
      'website_url',
      'primary_activities',
      'risk_profiles',
      'asset_classes',
      'public_active_offers_count'
    ];
    try {
      const { accessToken } = await sessionService.loadSession().catch(e => console.warn(e));

      return await instance.get(`/user/users/${username}`, { params: { access_token: accessToken, attributes } });
    } catch (error) {
      return await instance.get(`/user/users/${username}`, { params: { attributes } });  
    }
    
  }
);
export const getPublicUser = getThunkActionCreator(
  getPublicUserRoutine,
  async username => {
    const attributes = [
      'id',
      'first_name',
      'last_name',
      { header: ['representations'] },
      { photo: ['representations'] },
      'username',
      'headline'
    ];

    return await instance.get(`/user/users/${username}`, { params: { attributes } });
  }
);
export const getPublicUserVCard = getThunkActionCreator(
  getPublicUserVCardRoutine,
  async username => {
    return await instance.get(`/user/users/${username}/vcard`, { params: {} });
  }
);
export const getIntakeUser = getThunkActionCreator(
  getIntakeUserRoutine,
  async username => {const attributes = ['id', 'first_name', 'last_name', { photo: ['representations'] }];

    return await instance.get(`/user/users/${username}`, { params: { attributes } });
  }
);
export const createUser = getThunkActionCreator(
  createUserRoutine,
  async ({ user, confirmation_code, recipient_token = null, invitation_token = null, linkedin_code = null }) => {
    const data = { 
      user, 
      confirmation_code, 
      ...recipient_token && { recipient_token },
      ...invitation_token && { invitation_token },
      ...linkedin_code && { linkedin_code }
    };

    return await instance.post('/user/users', data);
  }
);
export const changeUserPassword = getThunkActionCreator(changeUserPasswordRoutine,
  async ({ email, password, confirmation_code }) => {
    const data = { email, password, confirmation_code };

    return await instance.post('/user/users/change_password', data);
  }
);
export const validateUser = getThunkActionCreator(
  validateUserRoutine,
  async ({ user, validation_attributes = null }) => {
    return await instance.post('/user/users/validate', { user, ...validation_attributes && { validation_attributes } });
  }
);
export const validateUserEmail = getThunkActionCreator(validateUserEmailRoutine, async email => await instance.post('/user/users/validate_email', { email }));
export const clearLocalSuggestedUsers = getThunkActionCreator(clearLocalSuggestedUsersRoutine, async () => {});
export const clearLocalUsersList = getThunkActionCreator(clearLocalUsersListRoutine, async () => {});
export const clearLocalUsersWidgetList = getThunkActionCreator(clearLocalUsersWidgetListRoutine, async () => {});
export const clearLocalActiveUser = getThunkActionCreator(clearLocalActiveUserRoutine, async () => {});