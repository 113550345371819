import { memo, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _sum from 'lodash/sum';
import _reduce from 'lodash/reduce';
// Local files
import { Action } from './NewButton.styled';
import FileInput from 'components/Common/Inputs/FileInput/FileInput';
import { ReactComponent as FileUploadIcon } from 'assets/icons/file-upload.svg';
import { ReactComponent as FolderUploadIcon } from 'assets/icons/folder-upload.svg';
import { ReactComponent as FolderNewIcon } from 'assets/icons/folder-new.svg';
import { contentAcceptTypes } from 'helpers/constants';
import { getFilesRecursively } from 'helpers';
import FolderDialog from 'components/Dialogs/Folder/Folder';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useFolders from 'hooks/useFolders';

const NewButton = () => {
  const fileRef = useRef();
  const { folder_id } = useParams();
  const { setUploadingItem } = useFolders();
  const [anchorEl, setAnchorEl] = useState(null);
  const [folderDialog, setFolderDialog] = useState({ open: false, folderId: '' });

  const handleDialogClose = () => setFolderDialog({ open: false, folderId: '' });
  const handleFileUploadClick = () => {
    setAnchorEl(null);
    fileRef.current.click();
  };
  const handleFolderUploadClick = async () => {
    setAnchorEl(null);
    
    const dirHandle = await window.showDirectoryPicker();

    if (!dirHandle) return;

    let result = [];
    
    for await (const fileHandle of getFilesRecursively(dirHandle)) {
      result.push(fileHandle);
    }

    const uploadingItems = _reduce(result, (res, val) => {
      if (!!_find(res, r => r.path === val.path)) {
        return _map(res, r => r.path === val.path ? ({ ...r, files: [...r.files, val.file] }) : r);
      }
      return [...res, { path: val.path, files: [val.file], depth: val.depth }];
    }, []);

    const rootFolder = _find(result, r => r.path.split('/').length === 1)?.path ?? null;

    if (rootFolder) {
      setUploadingItem({
        folder_id,
        id: _sum(_map(result, r => r.file.lastModified + r.file.size)),
        type: 'folder',
        name: rootFolder,
        data: uploadingItems,
        length: result.length
      });
    }
  };
  const handleFolderClick = () => {
    setAnchorEl(null);
    setFolderDialog({ open: true, folderId: folder_id });
  };
  const handleFilesChange = ({ target: { files } }) => {
    _forEach(files, file => {
      setUploadingItem({
        folder_id,
        id: file.lastModified + file.size,
        type: 'file',
        name: file.name,
        file
      });
    });
  };

  return (
    <Box>
      <FolderDialog
        {...folderDialog}
        onClose={handleDialogClose}
      />
      <Button
        variant='outlined'
        startIcon={<Add />}
        sx={{ borderColor: 'rgba(0, 0, 0, 0.4)', borderWidth: '0.5px' }}
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
      >
        New
      </Button>
      <Popover
        id='newFileOrFolder'
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ marginTop: '7px', padding: 0 }}
      >
        <Box minWidth={157}>
          <Action
            component={ButtonBase}
            sx={{ paddingTop: '15px', paddingBottom: '5px' }}
            onClick={handleFileUploadClick}
          >
            <FileUploadIcon style={{ marginLeft: 3 }} />
            <Typography fontWeight={400} fontSize='12px' lineHeight='24px'>
              Files Upload
            </Typography>
          </Action>
          <Action
            component={ButtonBase}
            sx={{ paddingTop: '5px', paddingBottom: '5px' }}
            onClick={handleFolderUploadClick}
          >
            <FolderUploadIcon />
            <Typography fontWeight={400} fontSize='12px' lineHeight='24px'>
              Folder Upload
            </Typography>
          </Action>
          <Action
            component={ButtonBase}
            sx={{ paddingTop: '5px', paddingBottom: '15px' }}
            onClick={handleFolderClick}
          >
            <FolderNewIcon />
            <Typography fontWeight={400} fontSize='12px' lineHeight='24px'>
              Folder
            </Typography>
          </Action>
        </Box>
      </Popover>
      <FileInput
        multiple
        ref={fileRef}
        accept={contentAcceptTypes}
        id='newButtonFile'
        onChange={handleFilesChange}
      />
    </Box>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <NewButton {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);