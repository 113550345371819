import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import _isEmpty from 'lodash/isEmpty';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import { Title, Heading, Content, Img, TextContent, Link, Footer, SkipButton } from './AccessBeta.styled';
import animation from 'assets/images/animation.gif';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';

const AccessBeta = () => {
  const { closeAccessBetaDialog, setAuthData } = useApp();
  const { open, primarySender, inviter } = useCustomSelector(state => ({
    open: state.app.accessBetaDialog.open,
    primarySender: state.profile.recipient.offer.primary_sender,
    inviter: state.invitations.invitation.inviter
  }));
  const user = !_isEmpty(primarySender) ? primarySender.user : inviter ?? {};
  const [processing, setProcessing] = useState(false);
  const disabled = processing;

  const handleClose = () => closeAccessBetaDialog();
  const handleExited = () => setProcessing(false);
  const handleJoinClick = () => setAuthData({ open: true });

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      maxWidth={false}
      TransitionProps={{ onExited: handleExited }}
    >
      <Box>
        <Heading>
          <BaseAvatar
            src={getRepresentation(user?.photo?.representations, representationVariants.TINY)}
            firstName={user.first_name}
            lastName={user.last_name}
          />
          <Title sx={{ display: 'inline-block' }}>
            <b>{user.first_name} {user.last_name}</b> has invited you to join Qwincy’s Beta release
          </Title>
        </Heading>
        <Content>
          <Img src={animation} alt='animation' data-private />
          <TextContent>
            <Title align='center' sx={{ fontWeight: 700 }}>
              Work on this opportunity by creating a private Dealspace in 1-click.
            </Title>
            <Typography variant='body2' align='center' pt='14px' pb='24px'>
              Privately <b>work on files, chat,</b> and <b>create Offerings</b>&nbsp;— just like this one — for equity raises, loan requests, property sales and more.
            </Typography>
            <Link href='https://www.qwincy.com/' target='_blank'>Learn more</Link>
            <Button
              variant='contained'
              color='primary'
              sx={{ minWidth: '120px' }}
              disabled={disabled}
              onClick={handleJoinClick}
            >
              Join Free
            </Button>
          </TextContent>
        </Content>
        <Footer>
          <SkipButton onClick={handleClose}>No thanks</SkipButton>
        </Footer>
      </Box>
    </BaseDialog>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AccessBeta {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);