import { memo, useRef } from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
// Local files
import { Root, Image, StyledButton, FileInput } from './Logo.styled';
import PercentProgress from 'components/Common/Feedback/PercentProgress/PercentProgress';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { ReactComponent as SelectImageIcon } from 'assets/icons/image-select.svg';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import useBlob from 'hooks/useBlob';
import useChecksum from 'hooks/useChecksum';

const Logo = ({ value, onChange }) => {
  const inputRef = useRef(null);
  const { processBlob, percent, loading } = useBlob();
  const { processFiles } = useChecksum();
  
  const handleUploadClick = () => inputRef.current.click();
  const handleEditClick = () => inputRef.current.click();
  const handleFilesChange = ({ target: { files } }) => {
    const f = Array.from(files);

    processFiles(f, ({ file, checksum, localUrl }) => {
      processBlob({ file, checksum }, blob => {
        if (!blob.id) return;
        onChange({ value: blob.id, formattedValue: { localUrl } });
      });
    });
  };
  
  if (!value) {
    return (
      <ButtonBase
        variant='uploader'
        sx={{ height: 120, width: 120, p: 1 }}
        disable={loading}
        onClick={handleUploadClick}
      >
        {loading ? <PercentProgress value={percent} /> :
          <>
            <FileInput
              id='offerLogo'
              ref={inputRef}
              accept='image/png, image/jpeg, image/tiff, image/gif'
              type='file'
              onChange={handleFilesChange}
              onClick={(event) => event.target.value = ''}
            />
            <SelectImageIcon style={{ color: '#8AC5F3' }} />
            <Typography variant='subtitle3' mt={1}>Click to upload your logo</Typography>
          </>
        }
      </ButtonBase>
    );
  }
  return (
    <Root>
      <Image
        src={value.localUrl || getRepresentation(value.representations, representationVariants.SMALL)}
        alt='logo'
      />
      <StyledButton onClick={handleEditClick} disabled={loading}>
        {loading ? <CircularProgress /> : <EditIcon />}
      </StyledButton>
      <FileInput
        id='offerLogo'
        ref={inputRef}
        accept='image/png, image/jpeg, image/tiff, image/gif'
        type='file'
        onChange={handleFilesChange}
        onClick={(event) => event.target.value = ''}
      />
    </Root>
  );
};

Logo.propTypes = {
  value: PropTypes.shape({
    localUrl: PropTypes.string,
    representations: PropTypes.array
  }),
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Logo {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);