import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getDealspacePropertiesListRoutine,
  getDealspacePropertiesRoutine,
  getOfferPropertiesRoutine,
  getPropertyRoutine,
  createDealspacePropertyRoutine,
  createOfferPropertyRoutine,
  updatePropertyRoutine,
  deletePropertyRoutine,
  clearLocalDealspacePropertiesListRoutine,
  clearLocalDealspacePropertiesRoutine,
  clearLocalOfferPropertiesRoutine,
  clearLocalActivePropertyRoutine
} from 'actions';
import _omit from 'lodash/omit';

const listAttributes = ['id', 'name', { property: ['id'] }];
const shortAttributes = ['id', 'name', 'description', { property: ['id'] }, { locations: ['id', 'address', 'latitude', 'longitude', 'parcel'] }, 'locations_count'];
const fullAttributes = ['id', 'name', 'description', { main_location: ['address'] }, { locations: ['id', 'address', 'latitude', 'longitude', 'parcel'] }, 'locations_count'];

export const getDealspacePropertiesList = getThunkActionCreator(
  getDealspacePropertiesListRoutine,
  async ({ dealspace_id, offset }) => {
    const params = { limit: 25, offset, orders: { updated_at: 'desc' }, attributes: listAttributes };

    return await axios.get(`/dealspaces/${dealspace_id}/properties`, { params });
  }
);
export const getDealspaceProperties = getThunkActionCreator(
  getDealspacePropertiesRoutine,
  async ({ dealspace_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const params = { limit, offset, orders, attributes: shortAttributes };

    return await axios.get(`/dealspaces/${dealspace_id}/properties`, { params });
  }
);
export const getOfferProperties = getThunkActionCreator(
  getOfferPropertiesRoutine,
  async ({ offer_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const attributes = ['id', 'name', { property: ['id'] }];
    const params = { limit, offset, orders, attributes };
    const url = `/offers/${offer_id}/properties`;

    return await axios.get(url, { params });
  }
);
export const getProperty = getThunkActionCreator(
  getPropertyRoutine,
  async id => {
    const params = { attributes: fullAttributes };

    return await axios.get(`/properties/${id}`, { params });
  }
);
export const createDealspaceProperty = getThunkActionCreator(
  createDealspacePropertyRoutine,
  async ({ dealspace_id, property }) => {
    const attributes = ['id', 'name', 'description', { locations: ['id', 'address', 'latitude', 'longitude', 'parcel'] }, { property: ['id'] }];
    const data = { property, attributes };

    return await axios.post(`/dealspaces/${dealspace_id}/properties`, data);
  }
);
export const createOfferProperty = getThunkActionCreator(
  createOfferPropertyRoutine,
  async ({ offer_id, property_id = null, property = null }) => {
    const attributes = ['id', 'name', { locations: ['id', 'address', 'latitude', 'longitude', 'parcel'] }, { property: ['id'] }];
    const data = { ...property_id && { property_id }, ...property && { property }, attributes };

    return await axios.post(`/offers/${offer_id}/properties`, data);
  }
);
export const updateProperty = getThunkActionCreator(
  updatePropertyRoutine,
  async ({ id, ...fields }) => {
    const data = { property: _omit(fields, ['locations']) };
    const response = await axios.patch(`/properties/${id}`, data);

    return { response, property: { id, ..._omit(fields, ['locations_attributes']) } };
  }
);
export const deleteProperty = getThunkActionCreator(
  deletePropertyRoutine,
  async ({ id, reason }) => {
    const response = await axios.delete(`/properties/${id}`);

    return { response, id, reason };
  }
);
export const clearLocalDealspacePropertiesList = getThunkActionCreator(clearLocalDealspacePropertiesListRoutine, async () => {});
export const clearLocalDealspaceProperties = getThunkActionCreator(clearLocalDealspacePropertiesRoutine, async () => {});
export const clearLocalOfferProperties = getThunkActionCreator(clearLocalOfferPropertiesRoutine, async () => {});
export const clearLocalActiveProperty = getThunkActionCreator(clearLocalActivePropertyRoutine, async () => {});