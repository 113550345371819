import styled from '@mui/material/styles/styled';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 14px 24px !important',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    padding: '14px !important',
  }
}));

export const MapContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '300px',
  borderRadius: '3px',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    width: '50%',
    height: '510px',
  }
}));

export const AddParcelButton = styled(ButtonBase)({
  width: '130px',
  position: 'absolute',
  bottom: '24px',
  left: 'calc(50% - 65px)',
  background: '#fff',
  boxShadow: '2px 2px 4px 0px #0000004D',
  borderRadius: '8px',
  padding: '10px 12px',
  fontWeight: 600,
});

export const ContentContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '500px',
  padding: '32px 40px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '40px',
  [theme.breakpoints.up('md')]: {
    width: '50%',
    height: '510px',
  }
}));

export const ListHeading = styled(Typography)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 1,
  fontSize: theme.typography.pxToRem(12),
  color: 'rgba(0,0,0,.6)',
  background: '#fff',
  borderBottom: '1px solid',
  borderColor: theme.palette.divider,
  padding: '8px 0',
}));

export const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
});