import { memo, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _isUndefined from 'lodash/isUndefined';
import _startCase from 'lodash/startCase'
import _sortBy from 'lodash/sortBy';
import { AsYouType, isPossiblePhoneNumber, validatePhoneNumberLength, parsePhoneNumberFromString } from 'libphonenumber-js';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// Local files
import { StyledStack } from 'views/Intake/Intake.styled';
import { FieldsGroup } from './OfferingInfo.styled';
import BuilderErrorLabel from 'components/Common/DataDisplay/BuilderErrorLabel/BuilderErrorLabel';
import BuilderSuccessLabel from 'components/Common/DataDisplay/BuilderSuccessLabel/BuilderSuccessLabel';
import IntakePropertyDialog from 'components/Dialogs/IntakeProperty/IntakeProperty';
import FormSelect from 'components/Common/Inputs/FormSelect/FormSelect';
import NumericTextField from 'components/Properties/BuilderProperties/NumericTextField/NumericTextField';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { checkEmptyString, checkValidEmail, getAskingPriceLabel } from 'helpers';
import { singlePropertyDealTypes, portfolioDealTypes, assemblageDealTypes, fundDealTypes, propertyGroupings, assetClassesList, riskProfilesList } from 'helpers/constants';
import useError from 'hooks/useError';
import useUsers from 'hooks/useUsers';

const OfferingInfo = ({
  step, firstName, lastName, email, phone, properties, dealType, propertyGrouping, customPrice, assetClasses, priceCents, leasePeriod, loanRatio,
  loanPercent, investmentType, riskProfiles, onChange, onSubmit
}) => {
  const navigate = useNavigate();
  const { setError } = useError();
  const { validateUserEmail } = useUsers();
  const [verificationStatus, setVerificationStatus] = useState('pending');
  const [intakePropertyDialog, setIntakePropertyDialog] = useState({ open: false, property: null });
  const [mode, setMode] = useState('asking');
  const showPropertyGrouping = useMemo(() => properties.value.length > 1, [properties.value]);
  const showDealType = useMemo(() => {
    if (properties.value.length === 1) return true;
    if (properties.value.length > 1 && !!propertyGrouping.value) return true;
    return false;
  }, [properties.value, propertyGrouping.value]);
  const dealTypes = useMemo(() => {
    if (properties.value.length === 1) return singlePropertyDealTypes;
    if (properties.value.length > 1) {
      if (propertyGrouping.value === 'portfolio') return portfolioDealTypes;
      if (propertyGrouping.value === 'assemblage') return assemblageDealTypes;
      if (propertyGrouping.value === 'fund') return fundDealTypes;
      return [];
    }
    return [];
  }, [properties.value, propertyGrouping.value]);
  const showAssetClasses = useMemo(() => !!dealType.value && dealType.value !== 'ground_lease', [dealType.value]);
  const showRiskProfiles = useMemo(() => {
    const validTypes = [
      'active_equity_investment', 'passive_equity_investment', 'preferred_equity_investment', 'partnership_opportunity', 'property_for_sale',
      'development_rights_for_sale', 'loan_for_sale', 'property_for_sale_leaseback'
    ];

    return !!_find(validTypes, vt => vt === dealType.value);
  }, [dealType.value]);
  const showPriceMode = useMemo(() => {
    const validTypes = [
      'property_for_sale', 'development_rights_for_sale', 'loan_for_sale', 'property_for_sale_leaseback', 'land_assemblage_for_sale'
    ];

    return !!_find(validTypes, vt => vt === dealType.value);
  }, [dealType.value]);
  const showPriceCents = useMemo(() => {
    if (showPriceMode) {
      return !!dealType.value && dealType.value !== 'partnership_opportunity'&& mode === 'asking';
    } else {
      return !!dealType.value && dealType.value !== 'partnership_opportunity';
    }
  }, [dealType.value, mode, showPriceMode]);
  const showLoanPercent = useMemo(() => {
    const validTypes = ['permanent_loan_request', 'construction_loan_request', 'acquisition_loan_request', 'bridge_loan_request'];

    return !!_find(validTypes, vt => vt === dealType.value);
  }, [dealType.value]);
  const showCustomPrice = useMemo(() => {
    const validTypes = [
      'partnership_opportunity', 'property_for_sale', 'development_rights_for_sale', 'loan_for_sale', 'property_for_sale_leaseback',
      'land_assemblage_for_sale'
    ];

    if (showPriceMode) {
      return !!_find(validTypes, vt => vt === dealType.value) && mode === 'custom';
    } else {
      return !!_find(validTypes, vt => vt === dealType.value);
    }
  }, [dealType.value, showPriceMode, mode]);
  const showLeasePeriods = useMemo(() => {
    return dealType.value === 'ground_lease';
  }, [dealType.value]);
  const showIvestmentTypes = useMemo(() => {
    const validTypes = ['active_equity_investment', 'passive_equity_investment', 'preferred_equity_investment'];

    return !!_find(validTypes, vt => vt === dealType.value);
  }, [dealType.value]);
  const askingPriceLabel = getAskingPriceLabel(dealType.value);

  const handleEmailBlur = e => {
    if (!checkEmptyString(e) || !checkValidEmail(e)) {
      setVerificationStatus('error');
      return;
    }
    validateUserEmail(e)
    .then(() => setVerificationStatus('verified'))
    .catch(e => {
      if (e.response?.data?.error?.type === 'user_already_exists') {
        setVerificationStatus('exist');
        setTimeout(() => navigate('/sign_in'), 3000);
        return;
      }
      setError(e).then(() => setVerificationStatus('error'));
    });
  };
  const handleChange = ({ firstName, lastName, email, phone }) => {
    if (!_isUndefined(firstName)) onChange({ firstName });
    if (!_isUndefined(lastName)) onChange({ lastName });
    if (!_isUndefined(email)) {
      setVerificationStatus('pending');
      onChange({ email });
    }
    if (!_isUndefined(phone)) {
      const formattedValue = new AsYouType('US').input(phone);

      if (validatePhoneNumberLength(formattedValue, 'US') !== 'TOO_LONG') {
        onChange({
          phone: {
            value: parsePhoneNumberFromString(phone, 'US')?.number ?? '',
            formattedValue,
            error: !!formattedValue.length
              ? isPossiblePhoneNumber(formattedValue, 'US') ? '' : 'Phone is invalid'
              : ''
          }
        });
      }
    }
  };
  const handleAddProperty = () => {
    setIntakePropertyDialog({ open: true, property: null });
  };
  const handleIntakePropertyDialogClose = (v = null) => {
    setIntakePropertyDialog({ open: false, property: null });

    if (v) {
      onChange({
        properties: {
          value: !!_find(properties.value, p => p.id === v.id)
            ? _map(properties.value, p => p.id === v.id ? v : p)
            : [...properties.value, v],
          error: ''
        }
      });
    }
  };
  const handleClickProperty = name => {
    setIntakePropertyDialog({ open: true, property: _find(properties.value, v => v.name === name) });
  };
  const handleDeleteProperty = name => {
    onChange({ properties: { value: _filter(properties.value, p => p.name !== name), error: '' } });
  };
  const handlePropertyGroupingChange = value => {
    onChange({
      propertyGrouping: { value, error: '' },
      dealType: { value: '', error: '' },
      assetClasses: [],
      priceCents: { value: 0, formattedValue: '' },
      customPrice: '',
      leasePeriod: '',
      loanRatio: '',
      loanPercent: '',
      investmentType: '',
      riskProfiles: []
    });
  };
  const handleDealTypeChange = value => {
    onChange({
      dealType: { value, error: '' },
      assetClasses: [],
      priceCents: { value: 0, formattedValue: '' },
      customPrice: '',
      leasePeriod: '',
      loanRatio: '',
      loanPercent: '',
      investmentType: '',
      riskProfiles: []
    });
  };
  
  if (step !== 'offering_info') return null;
  return (
    <StyledStack>
      <FieldsGroup>
        <Box>
          <TextField
            fullWidth
            variant='standard'
            label='First Name'
            value={firstName.value}
            onChange={({ target: { value } }) => handleChange({ firstName: { value, error: '' } })}
            error={!!firstName.error}
            InputProps={{ autoComplete: 'given-name' }}
          />
          {!!firstName.error && <BuilderErrorLabel value={firstName.error} />}
        </Box>
        <Box>
          <TextField
            fullWidth
            variant='standard'
            label='Last Name'
            value={lastName.value}
            onChange={({ target: { value } }) => handleChange({ lastName: { value, error: '' } })}
            error={!!lastName.error}
            InputProps={{ autoComplete: 'family-name' }}
          />
          {!!lastName.error && <BuilderErrorLabel value={lastName.error} />}
        </Box>
        <Box>
          <TextField
            fullWidth
            variant='standard'
            label='Email'
            value={email.value}
            onChange={({ target: { value } }) => handleChange({ email: { value, error: '' } })}
            onBlur={({ target: { value } }) => handleEmailBlur(value)}
            error={verificationStatus === 'error' || !!email.error}
            sx={{ mb: '5.5px' }}
            InputProps={{ autoComplete: 'email' }}
          />
          {verificationStatus === 'error' && <BuilderErrorLabel value='Invalid email' />}
          {verificationStatus === 'verified' && <BuilderSuccessLabel value='Email verified' />}
          {verificationStatus === 'exist' && <BuilderSuccessLabel value='Account exist' />}
          {!!email.error && <BuilderErrorLabel value={email.error} />}
        </Box>
        <Box>
          <TextField
            fullWidth
            variant='standard'
            label='Phone'
            value={phone.formattedValue}
            onChange={({ target: { value } }) => handleChange({ phone: value })}
            error={!!phone.error}
            InputProps={{ autoComplete: 'tel' }}
          />
          {!!phone.error && <BuilderErrorLabel value={phone.error} />}
        </Box>
      </FieldsGroup>
      <Box sx={{ minHeight: 160, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '8px', mt: '30px' }}>
        <IntakePropertyDialog
          {...intakePropertyDialog}
          onClose={handleIntakePropertyDialogClose}
        />
        {properties.value &&
          <Box sx={{ width: '100%', mt: '-6px', mb: '8px' }}>
            {_map(properties.value, ({ name }) =>
              <Chip
                sx={{ m: '2px' }}
                key={name}
                onClick={() => handleClickProperty(name)}
                onDelete={() => handleDeleteProperty(name)}
                label={
                  <Box component='span' sx={{ display: 'flex', gap: '8px', color: '#3F3F3F' }}>
                    <Typography>{name}</Typography>
                  </Box>
                }
              />
            )}
          </Box>
        }
        <Box width='100%'>
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddProperty}
          >
            Add Property
          </Button>
          {!!properties.error && <BuilderErrorLabel value={properties.error} />}
        </Box>
        <Box display='flex' flexDirection='row' flexWrap='wrap' alignItems='end' width='100%' mt='40px' mb='70px' gap='24px 16px'>
          {showPropertyGrouping &&
            <Box sx={{ width: '100%' }}>
              <FormSelect
                sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}
                variant='standard'
                paperVariant='popup'
                paperColor='light'
                fullWidth
                label='Property Grouping'
                required
                value={propertyGrouping.value}
                error={!!propertyGrouping.error}
                onChange={({ target: { value } }) => handlePropertyGroupingChange(value)}
              >
                {_map(propertyGroupings, pg => <MenuItem key={pg} value={pg}>{_startCase(pg)}</MenuItem>)}
              </FormSelect>
              {!!propertyGrouping.error && <BuilderErrorLabel value={propertyGrouping.error} />}
            </Box>
          }
          {showDealType &&
            <Box sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}>
              <FormSelect
                variant='standard'
                paperVariant='popup'
                paperColor='light'
                fullWidth
                label='Deal Type'
                required
                value={dealType.value}
                error={!!dealType.error}
                onChange={({ target: { value } }) => handleDealTypeChange(value)}
              >
                {_map(dealTypes, dt => <MenuItem key={dt} value={dt}>{_startCase(dt)}</MenuItem>)}
              </FormSelect>
              {!!dealType.error && <BuilderErrorLabel value={dealType.error} />}
            </Box>
          }
          {showAssetClasses &&
            <FormSelect
              sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}
              multiple
              variant='standard'
              paperVariant='popup'
              paperColor='light'
              label='Asset Class(es)'
              value={assetClasses}
              onChange={({ target: { value } }) => onChange({ assetClasses: value })}
            >
              {_map(_sortBy(assetClassesList), ac => <MenuItem key={ac} value={ac}>{_startCase(ac)}</MenuItem>)}
            </FormSelect>
          }
          {showPriceMode &&
            <FormSelect
              sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}
              variant='standard'
              paperVariant='popup'
              paperColor='light'
              label='Price'
              value={mode}
              onChange={({ target: { value } }) => setMode(value)}
            >
              <MenuItem value='asking'>Asking Price</MenuItem>
              <MenuItem value='custom'>Custom Value</MenuItem>
            </FormSelect>
          }
          {showPriceCents &&
            <TextField
              sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}
              inputProps={{ min: 1 }}
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                inputComponent: NumericTextField
              }}
              variant='standard'
              label={askingPriceLabel}
              value={priceCents.formattedValue}
              onChange={v => onChange({ priceCents: v })}
            />
          }
          {showCustomPrice &&
            <TextField
              sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}
              inputProps={{ min: 1 }}
              variant='standard'
              label='Custom Value'
              value={customPrice}
              onChange={({ target: { value } }) => onChange({ customPrice: value })}
            />
          }
          {showLoanPercent &&
            <Box
              display='flex'
              sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}
              gap='8px'
              justifyContent='space-between'
              alignItems='flex-end'
            >
              <TextField
                variant='standard'
                InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
                label='Loan Ratio (optional)'
                value={loanPercent}
                type='number'
                onChange={({ target: { value } }) => onChange({ loanPercent: value })}
              />
              <RadioGroup
                sx={{ display: 'flex', justifyContent: 'flex-end', gap: '5px' }}
                row
                value={loanRatio}
                onChange={({ target: { value } }) => onChange({ loanRatio: value })}
              >
                <FormControlLabel
                  value='ltv'
                  control={<Radio color='secondary' size='small' sx={{ padding: 0 }} />}
                  label='LTV'
                  sx={{ marginLeft: 0, marginRight: 0 }}
                />
                <FormControlLabel
                  value='ltc'
                  control={<Radio color='secondary' size='small' sx={{ padding: 0 }} />}
                  label='LTC'
                  sx={{ marginLeft: 0, marginRight: 0 }}
                />
              </RadioGroup>
            </Box>
          }
          {showLeasePeriods &&
            <RadioGroup
              sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}
              row
              value={leasePeriod}
              onChange={({ target: { value } }) => onChange({ leasePeriod: value })}
            >
              <FormControlLabel
                value='month'
                control={<Radio color='secondary' size='small' />}
                label='/month'
              />
              <FormControlLabel
                value='year'
                control={<Radio color='secondary' size='small' />}
                label='/year'
              />
            </RadioGroup>
          }
          {showIvestmentTypes &&
            <RadioGroup
              sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}
              row
              value={investmentType}
              onChange={({ target: { value } }) => onChange({ investmentType: value })}
            >
              <FormControlLabel
                value='total'
                control={<Radio color='secondary' size='small' />}
                label='Total'
              />
              <FormControlLabel
                value='minimum'
                control={<Radio color='secondary' size='small' />}
                label='Minimum'
              />
            </RadioGroup>
          }
          {showRiskProfiles &&
            <FormSelect
              sx={{ width: { xs: '100%', md: 'calc(50% - 8px)' } }}
              multiple
              variant='standard'
              paperVariant='popup'
              paperColor='light'
              label='Risk Profile(s)'
              value={riskProfiles}
              onChange={({ target: { value } }) => onChange({ riskProfiles: value })}
            >
              {_map(riskProfilesList, rp => <MenuItem key={rp} value={rp}>{_startCase(rp)}</MenuItem>)}
            </FormSelect>
          }
        </Box>
      </Box>
      <Button
        variant='gradient'
        sx={{ minWidth: 130, display: 'flex', m: '80px auto 140px' }}
        onClick={onSubmit}
      >
        Next
      </Button>
    </StyledStack>
  );
};

OfferingInfo.propTypes = {
  step: PropTypes.string.isRequired,
  firstName: PropTypes.shape({
    value: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired
  }),
  lastName: PropTypes.shape({
    value: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired
  }),
  email: PropTypes.shape({
    value: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired
  }),
  phone: PropTypes.shape({
    value: PropTypes.string.isRequired,
    formattedValue: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired
  }),
  properties: PropTypes.shape({
    value: PropTypes.array.isRequired,
    error: PropTypes.string.isRequired
  }),
  dealType: PropTypes.shape({
    value: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired
  }),
  propertyGrouping: PropTypes.shape({
    value: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired
  }),
  customPrice: PropTypes.string.isRequired,
  assetClasses: PropTypes.array.isRequired,
  priceCents: PropTypes.shape({
    value: PropTypes.number.isRequired,
    formattedValue: PropTypes.string.isRequired
  }),
  leasePeriod: PropTypes.string.isRequired,
  loanRatio: PropTypes.string.isRequired,
  loanPercent: PropTypes.string.isRequired,
  investmentType: PropTypes.string.isRequired,
  riskProfiles: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <OfferingInfo {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);