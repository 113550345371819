import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Container = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px 16px',
  '& > *': {
    flexBasis: '180px'
  }
});
