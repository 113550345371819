import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '64px',
  backgroundColor: '#fff',
  borderBottom: '1px solid #F3F5F8',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '8px 16px 8px 0px',
  [theme.breakpoints.up('md')]: {
    height: '100px',
    padding: '8px 32px',
  }
}));

export const BackButton = styled(Button)(({ theme }) => ({
  minWidth: 'unset',
  borderRadius: '0 5px 5px 0',
  padding: '8px 8px',
  '&:hover': {
    background: theme.palette.background.default
  }
}));
