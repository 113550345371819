import { memo, useState, useRef, useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import _map from 'lodash/map';
// Local files
import { Root, ImagePreview, VariantsContainer, UploadContainer, FileInput } from './ImageUploaderWithVariants.styled';
import { ReactComponent as UploadIcon } from 'assets/icons/upload-image-large.svg';
import Variant from 'components/Blobs/ImageUploaderWithVariants/Variant/Variant';
import useFilesChange from 'hooks/useFilesChange';
import { getRepresentation } from 'helpers';
import { representationVariants } from 'helpers/constants';

const ImageUploaderWithVariants = ({ id, accept, formattedValue, variants, changeProcessing, onChange }) => {
  const inputRef = useRef(null);
  const { loading, changeFiles } = useFilesChange(false, onChange);
  const [cover, setCover]  = useState(null);

  useEffect(() => {
    changeProcessing(loading)
  }, [loading, changeProcessing]);

  const handleCoverSelect = (item) => {
    fetch(item.src)
    .then((r) => 
      r.blob()
      .then((b) => {
        const file = new File([b], item.name, { type: b.type });

        setCover(item.id);
        changeFiles([file]);
      })
    )
  }
  const handleUploadClick = () => inputRef.current.click();
  const handleFilesChange = ({ target: { files } }) => { 
    setCover(null);
    changeFiles(files);
  };

  return (
    <Root>
      { (loading || !!!formattedValue) ? <Skeleton animation='wave' variant='rounded' width={80} height={80} /> :
      <ImagePreview src={formattedValue?.localUrl || getRepresentation(formattedValue?.representations, representationVariants.SMALL)} alt='banner' /> }
      <VariantsContainer>
        {_map(variants, (item) =>
          <Variant
            key={item.id}
            src={item.src}
            selected={item.id === cover}
            disabled={loading}
            onClick={() => handleCoverSelect(item)}
          />
        )}
        <FileInput
          id={id}
          ref={inputRef}
          accept={accept}
          type='file'
          disabled={loading}
          onChange={handleFilesChange}
          onClick={(event) => event.target.value = ''}
        />
        <UploadContainer onClick={handleUploadClick}>
          <UploadIcon />
        </UploadContainer>
      </VariantsContainer>
    </Root>
  );
};

export default memo(ImageUploaderWithVariants);