import { memo, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
// Local files
import Select from 'components/Conversations/Common/FormDarkSelect/FormDarkSelect';
import useApp from 'hooks/useApp';
import useChatsLevel from 'hooks/useChatsLevel';
import useCustomSelector from 'hooks/useCustomSelector';

const OfferingSelect = () => {
  const { setChatsFilter } = useApp();
  const { filter } = useCustomSelector(state => state.app.chats);
  const { chats: { data } } = useCustomSelector(state => state.dealspaces);
  const { chats: { data: offersData } } = useCustomSelector(state => state.offers);
  const { isGroupLevel } = useChatsLevel();
  const [open, setOpen] = useState('');
  const [label, setLabel] = useState('All offerings');

  const findOffer = useCallback(() => _find(offersData, (offer) => offer?.id === filter.offer_id), [filter.offer_id, offersData]);
  const handleSelect = ({ dealspace_id = null, offer_id = null }) => { 
    setChatsFilter({ 
      filter: { 
        offer_id: (!!offer_id || filter.offer_id !== offer_id) ? offer_id : null, 
        dealspace_id: (!!dealspace_id || filter.dealspace_id !== dealspace_id) ? dealspace_id : null,  
      } 
    }) 
  };
  const handleUnselectAll = () => setChatsFilter({ filter: { offer_id : null, dealspace_id : null } })

  useEffect(() => {
    !!!filter.dealspace_id && !!!filter.offer_id && setLabel('All offerings');
    !!filter.offer_id && setLabel(findOffer()?.name || findOffer()?.headline);
    !!filter.dealspace_id && setLabel(_find(data, (dealspace) => dealspace?.id === filter.dealspace_id)?.name);
  }, [filter, data, findOffer, offersData]);

  return (
    <Select defaultValue='All offerings' label={label}>
      <ListItemButton
        onClick={handleUnselectAll}
        selected={label === 'All offerings'}
      >
        <ListItemText primary='All offerings' />
      </ListItemButton>
      { isGroupLevel ? _map(data, ({ id: dealspace_id, name }) => <Box key={dealspace_id}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }} >
          <ListItemButton
            onClick={() => handleSelect({ dealspace_id, name })}
            selected={filter.dealspace_id === dealspace_id}
          >
            <ListItemText primary={name} primaryTypographyProps={{ noWrap: true }} />
          </ListItemButton>
          {open === dealspace_id ? 
            <IconButton disableRipple onClick={() => setOpen('')}><ExpandLess /></IconButton> : 
            <IconButton disableRipple onClick={() => setOpen(dealspace_id)}><ExpandMore /></IconButton>}
        </Box>
        <Collapse in={open === dealspace_id} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {_map(_filter(offersData, ({ dealspace }) => dealspace?.id === dealspace_id), ({ id: offer_id, name })  => {
              return (
                <ListItemButton
                  key={offer_id}
                  sx={{ pl: 4 }}
                  onClick={() => handleSelect({ offer_id, name })}
                  selected={filter.offer_id === offer_id}
                >
                  <ListItemText primary={name} primaryTypographyProps={{ noWrap: true }} />
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      </Box> ) : 
      <List component='div' disablePadding>
        {_map(offersData, ({ id: offer_id, headline })  => {
          return (
            <ListItemButton
              key={offer_id}
              sx={{ pl: 4 }}
              onClick={() => handleSelect({ offer_id, headline })}
              selected={filter.offer_id === offer_id}
            >
              <ListItemText primary={headline} primaryTypographyProps={{ noWrap: true }} />
            </ListItemButton>
          );
        })}
      </List> }
    </Select>
  )
}

export default memo(OfferingSelect);