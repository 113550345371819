import { memo, useCallback, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListSubheader from '@mui/material/ListSubheader';
import Button from '@mui/material/Button';
import _find from 'lodash/find';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
// Local files
import { ReactComponent as SharedListIcon } from 'assets/icons/shared-list-enabled.svg';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useLists from 'hooks/useLists';

const SharedSelect = ({ groupId, value, selectedLists, onChange }) => {
  const { setError } = useError();
  const { getSharedLists, clearLocalSharedLists } = useLists();
  const { options, needToLoadMore, newOffset } = useCustomSelector(state => ({
    options: state.lists.shared.data,
    needToLoadMore: state.lists.shared.pagination.offset + state.lists.shared.pagination.count < state.lists.shared.pagination.total_count && !state.lists.shared.loading,
    newOffset: state.lists.shared.pagination.limit + state.lists.shared.pagination.offset
  }));

  const fetchLists = useCallback(({ offset, group_id }) => {
    getSharedLists({ offset, group_id })
    .catch(e => setError(e));
  }, [getSharedLists, setError]);
  const onScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    if (scrollTop + clientHeight + 65 >= scrollHeight && needToLoadMore) {
      fetchLists({ offset: newOffset, group_id: groupId });
    }
  };
  const renderValue = () => '1 list selected';
  const handleButtonClick = (e, l, alreadySelected) => {
    e.stopPropagation();
    onChange({ type: alreadySelected ? 'remove' : 'add', value: l });
  };
  const handleItemClick = value => {
    onChange({ type: 'select', value });
  };

  useEffect(() => {
    if (!!groupId) fetchLists({ offset: 0, group_id: groupId });

    return () => {
      if (!!groupId) clearLocalSharedLists();
    };
  }, [clearLocalSharedLists, fetchLists, groupId]);

  return (
    <FormControl sx={{ width: '50%' }}>
      <InputLabel htmlFor='sharedListsSelect'>
        Filter by lists
      </InputLabel>
      <Select
        id='sharedListsSelect'
        label='Filter by lists'
        value={value ?? ''}
        renderValue={renderValue}
        onChange={() => {}}
        MenuProps={{ PaperProps: { style: { maxHeight: '50vh' }, onScroll } }}
      >
        {_map(options, o =>
          <div key={o.user.id}>
            <ListSubheader sx={{ display: 'flex', alignItems: 'center', gap: '6px', py: 1.5 }}>
              <SharedListIcon width={20} />
              <Typography variant='body2'>
                {o.user.first_name} {o.user.last_name}
              </Typography>
            </ListSubheader>
            {_map(o.lists, l => {
              const alreadySelected = !!_find(selectedLists, sl => sl === l.id);

              return (
                <MenuItem
                  key={l.id}
                  sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', py: 1 }}
                  onClick={() => handleItemClick(l)}
                >
                  <Box display='flex' alignItems='baseline' gap='6px'>
                    <Typography variant='body2'>
                      {l.name}
                    </Typography>
                    <Typography variant='small' color='text.hint'>
                      {l.contactsCount}
                    </Typography>
                  </Box>
                  <Button
                    variant='text'
                    color={alreadySelected ? 'error' : 'primary'}
                    sx={{ fontWeight: 500, fontSize: '.675rem', mr: '-8px' }}
                    onClick={e => handleButtonClick(e, l, alreadySelected)}
                  >
                    {alreadySelected ? '- remove list' : '+ entire list'}
                  </Button>
              </MenuItem>
              );
            })}
          </div>
        )}
      </Select>
    </FormControl>
  );
};
SharedSelect.propTypes = {
  groupId: PropTypes.string.isRequired,
  value: PropTypes.object,
  selectedLists: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <SharedSelect {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);