import { memo } from 'react';
import ForwardIcon from 'components/Common/Icons/ForwardIcon';
// Local files
import { Root } from './CloseButton.styled';

const CloseButton = ({ onClick }) => {
  return (
    <Root
      onClick={onClick}
    >
      <ForwardIcon />
    </Root>
  );
};

export default memo(CloseButton);