import styled from '@mui/material/styles/styled';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion from '@mui/material/Accordion';
// Local files
import KeyboardArrowDownIcon from 'components/Common/Icons/KeyboardArrowDownIcon';

const Root = styled(props => (
  <MuiAccordion elevation={0} {...props} />
))({
  color: '#fff',
  backgroundColor: 'transparent',
  borderRadius: '6px !important',
  border: 'none',
  padding: 0,
  marginBottom: '10px',
  '&:before': {
    display: 'none'
  },
  '&.Mui-expanded': {
    margin: '0 0 10px 0 !important'
  }
});

const Header = styled(props => (
  <MuiAccordionSummary expandIcon={<KeyboardArrowDownIcon sx={{ color: '#AAAEB2', }} />} {...props} />
))({
  minHeight: 56,
  flexDirection: 'row-reverse',
  padding: '0 16px 0 20px',
  '&.Mui-expanded': {
    minHeight: 56,
  },
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
    '&.Mui-expanded': {
      margin: 0
    }
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent'
  }
});

const Content = styled(MuiAccordionDetails)({
  padding: 0,
});

export { Root, Header, Content };