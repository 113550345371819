import styled from '@mui/material/styles/styled';
import ButtonBase from '@mui/material/ButtonBase';

export const LinkedinButton = styled(ButtonBase)({
  gap: '11px',
  fontSize: '12px',
  fontWeight: 400,
  color: '#595656',
  background: 'rgba(221,223,225,0.74)',
  borderRadius: '4px',
  padding: '6px 24px'
});