import { memo } from 'react';
import _map from 'lodash/map';
import Scrollbars from 'react-custom-scrollbars-2';
import { useParams } from 'react-router-dom';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _sum from 'lodash/sum';
import _reduce from 'lodash/reduce';
import PropTypes from 'prop-types';
// Local files
import DropZone from './DropZone/DropZone';
import Empty from './Empty/Empty';
import Folder from '../Card/Card';
import File from 'components/Documents/Card/Card';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { getRepresentation, getFilesRecursively } from 'helpers';
import { DELETE_DOCUMENT } from 'helpers/confirmations';
import { representationVariants, contentAcceptTypes } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useFolders from 'hooks/useFolders';

const allowTypes = [
  'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/gif', 'image/jpeg', 'image/png',
  'application/pdf', 'image/svg+xml', 'image/tiff', 'image/webp'
];

const Details = ({ onLoadMore }) => {
  const { folder_id } = useParams();
  const { openDeleteDialog } = useApp();
  const { setUploadingItem } = useFolders();
  const { folders, foldersFetching, needToLoadMoreFolders, newFoldersOffset, files, filesFetching, needToLoadMoreFiles, newFilesOffset } = useCustomSelector(state => ({
    folders: state.folders.all.data,
    foldersFetching: state.folders.all.fetching,
    needToLoadMoreFolders: state.folders.all.pagination.total_count > state.folders.all.pagination.count + state.folders.all.pagination.offset,
    newFoldersOffset: state.folders.all.pagination.limit + state.folders.all.pagination.offset,
    files: state.documents.dealspace.data,
    filesFetching: state.documents.dealspace.fetching,
    needToLoadMoreFiles: state.documents.dealspace.pagination.total_count > state.documents.dealspace.pagination.count + state.documents.dealspace.pagination.offset,
    newFilesOffset: state.documents.dealspace.pagination.limit + state.documents.dealspace.pagination.offset
  }));
  const showEmpty = !folders.length && !files.length && !foldersFetching && !filesFetching;

  const processFolder = async handle => {
    let result = [];
    
    for await (const fileHandle of getFilesRecursively(handle)) {
      result.push(fileHandle);
      console.log(fileHandle);
    }
    console.log({result});
    const uploadingItems = _reduce(result, (res, val) => {
      if (!!_find(res, r => r.path === val.path)) {
        return _map(res, r => r.path === val.path ? ({ ...r, files: [...r.files, val.file] }) : r);
      }
      return [...res, { path: val.path, files: [val.file], depth: val.depth }];
    }, []);

    const rootFolder = _find(result, r => r.path.split('/').length === 1)?.path ?? null;

    if (rootFolder) {
      setUploadingItem({
        folder_id,
        id: _sum(_map(result, r => r.file.lastModified + r.file.size)),
        type: 'folder',
        name: rootFolder,
        data: uploadingItems,
        length: result.length
      });
    }
  };
  const processFile = f => {
    setUploadingItem({
      folder_id,
      id: f.lastModified + f.size,
      type: 'file',
      name: f.name,
      file: f
    });
  };

  const handleDrop = async e => {
    for (const item of e.items) {
      item.getAsFileSystemHandle().then(h => {
        if (h.kind === 'directory') {
          processFolder(h);
        } else {
          h.getFile().then(f => processFile(f));
        }
      });
    }
  };
  const handleFilesChange = ({ target: { files } }) => {
    _forEach(files, f => {
      setUploadingItem({
        folder_id,
        id: f.lastModified + f.size,
        type: 'file',
        name: f.name,
        file: f
      });
    });
  };
  const handleDeleteFileClick = id => openDeleteDialog({ type: DELETE_DOCUMENT, id });
  const handleScroll = ({ top }) => {
    if (top > 0.99) {
      if (needToLoadMoreFolders && !foldersFetching) {
        onLoadMore({ type: 'folders', offset: newFoldersOffset });
      } else {
        if (needToLoadMoreFiles && !filesFetching) {
          onLoadMore({ type: 'files', offset: newFilesOffset })
        }
      }
    }
  };

  if (showEmpty) {
    return (
      <DropZone
        display='flex'
        justifyContent='center'
        alignItems='center'
        allowTypes={allowTypes}
        onDrop={handleDrop}
      >
        <Empty accept={contentAcceptTypes} onChange={handleFilesChange} />
      </DropZone>
    );
  }
  return (
    <Scrollbars autoHide autoHideTimeout={1000} onScrollFrame={handleScroll}>
      <DropZone
        display='grid'
        gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr 1fr', lg: '1fr 1fr 1fr 1fr' }}
        gridAutoRows='140px'
        rowGap='24px'
        overflow='auto'
        allowTypes={allowTypes}
        onDrop={handleDrop}
      >
        {_map(folders, f =>
          <Folder
            key={f.id}
            id={f.id}
            name={f.name}
            size={f.documents_byte_size}
            count={f.documents_count}
          />
        )}
        {!needToLoadMoreFolders && _map(files, f =>
          <File key={f.id}
            id={f.id}
            title={f.name}
            date={f.created_at}
            type={f.file.content_type}
            preview={getRepresentation(f.file.representations, representationVariants.TINY)}
            onDelete={() => handleDeleteFileClick(f.id)}
          />
        )}
      </DropZone>
    </Scrollbars>
  );
};

Details.propTypes = {
  onLoadMore: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Details {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);