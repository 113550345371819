import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';

export const searchStyles = {
  width: '100%',
  border: '1px solid #DEDEDE',
  boxShadow: '0px 0px 2px 0px #2238FF40',
  borderRadius: '8px',
  paddingLeft: '18px',
  '& input': {
    height: '44px',
    paddingLeft: '12px',
  }
}

export const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  gap: '20px 12px',
  [theme.breakpoints.up('md')]: {
    minHeight: 300,
    gridTemplateColumns: 'repeat(2, 1fr)',
  }
}));

export const OptionsContainer = styled('div')(({ theme }) => ({
  height: '300px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    height: '100%',
  },
}));

export const OptionsPaper = styled('div')({
  flexGrow: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  background: '#fff',
  border: '1px solid #F1F1F1',
  borderRadius: '8px',
  padding: '16px 0 0',
  overflow: 'hidden',
});

export const StyledListItemButton = styled(ListItemButton)(() => ({
  position: 'relative',
  gap: '16px',
  padding: '8px 16px',
  '&.Mui-selected': {
    backgroundColor: '#fff',
    '& *': {
      fontWeight: '600 !important',
    }
  }
}));

export const ResultContainer = styled('div')(({ theme }) => ({
  height: '300px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F8F9FB',
  border: '2px solid #F8F9FB',
  borderRadius: '8px',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    height: '100%',
  },
}));

export const ResultHeader = styled('div')({
  height: 55 + 10,
  color: '#000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '20px 12px 24px 16px',
});

export const ResultListItem = styled(ListItem)(() => ({
  minHeight: 55,
  gap: '8px',
  fontWeight: '600 !important',
  backgroundColor: '#fff',
  marginBottom: '2px'
}));

export const ItemAvatar = styled('div')({
  width: 30,
  height: 30
});

export const ItemTitle = styled((props) => (
  <Typography variant='caption' noWrap  {...props} />
))({
  fontWeight: 600
});

export const ItemSubtitle = styled((props) => (
  <Typography variant='caption' noWrap {...props} />
))({
  color: 'rgba(0,0,0,.5)',
  fontWeight: 400
});

export const ItemDescription = styled((props) => (
  <Typography variant='caption' noWrap {...props} />
))({
  display: 'block'
});

export const ListIconContainer = styled('div')({
  width: 30,
  height: 30,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F8F9FB',
  borderRadius: '50%',
  '& > svg': {
    maxWidth: 14
  }
});