import { memo, useState, useEffect, useCallback, useRef } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import { useNavigate, NavLink } from 'react-router-dom';
import _map from 'lodash/map';
// Local files
import { Actions, IconContainer, Root, StyledButton, StyledLink, SearchContent, InviteLink } from './Widget.styled';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import Drawer from '../../Drawers/Contact/Contact';
import Search from '../Search/Search';
import CreateContactInviteDialog from 'components/Dialogs/CreateContactInvite/CreateContactInvite';
import { getRepresentation } from 'helpers';
import { representationVariants } from 'helpers/constants';
import { ReactComponent as UploadIcon } from 'assets/icons/dashboard/upload.svg';
import { ReactComponent as AddIcon } from 'assets/icons/dashboard/add.svg';
import { ReactComponent as SyncIcon } from 'assets/icons/dashboard/sync.svg';
import useApp from 'hooks/useApp';
import useContacts from 'hooks/useContacts';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useUsers from 'hooks/useUsers';

const ContactsWidget = () => {
  const navigate = useNavigate();
  const { openUploadContactsDialog } = useApp();
  const { setContactsFilters } = useContacts();
  const { getUsersWidgetList, clearLocalUsersWidgetList } = useUsers();
  const { setError } = useError();
  const searchRef = useRef(null);
  const { total_contacts_count, member_contacts_count, pending_contacts_count } = useCustomSelector(state => state.profile.user);
  const {data, fetching }= useCustomSelector(state => state.users.widgetList);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [contactInviteDialog, setContactInviteDialog] = useState({ open: false });
  const [query, setQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAll = () => setContactsFilters({ tab: 'all', pending: false, member: false }).then(() => navigate('/contacts'));
  const handleRequests = () => setContactsFilters({ tab: 'pending', pending: true, member: false }).then(() => navigate('/contacts'));
  const handleConnections = () => setContactsFilters({ tab: 'connections', member: true, pending: false }).then(() => navigate('/contacts'));
  const handleDrawerClose = () => setDrawerOpen(false);
  const handleQueryChange = (q, currentTarget) => {
    clearLocalUsersWidgetList();
    setAnchorEl(currentTarget);
    setQuery(q);
  };
  const fetchUsers = useCallback((query) => {
    getUsersWidgetList(query)
    .catch(e => setError(e))
  },[getUsersWidgetList, setError]);
  const handleClose = () => {
    setAnchorEl(null);
    searchRef.current.clearQuery();
    clearLocalUsersWidgetList();
  };
  const handleContactInviteClick = () => {
    handleClose();
    setContactInviteDialog({ open: true });
  }
  const closeContactInviteDialog = () => setContactInviteDialog({ open: false })

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (query.length > 2) clearLocalUsersWidgetList().then(() => fetchUsers(query))
    }, !!query ? 500 : 0);
    
    return () => clearTimeout(timeout);
  }, [query, fetchUsers, clearLocalUsersWidgetList, setError]);

  return (
    <Root variant='outlined' sx={{ p: '30px 32px 24px' }}>
      <CreateContactInviteDialog
        {...contactInviteDialog}
        onClose={closeContactInviteDialog}
      />
      <Drawer
        open={drawerOpen}
        onClose={handleDrawerClose}
      />
      <Typography variant='subtitle2' component='div' sx={{ color: 'rgba(0,0,0,.7)' }}>My Contacts</Typography>
      <Box>
        <Box width='100%' sx={{ mb: '13px' }}>
          <Search
            ref={searchRef}
            onChange={handleQueryChange}
            fetching={fetching}
          />
          {query.length > 2 && <Popover
            id='searchUsers'
            disableAutoFocus
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={{ boxShadow :'0px 2px 4px 0px #00000040' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} 
            transformOrigin={{ vertical: 'top', horizontal: 'center' }} 
          >
         <SearchContent> 
          {!fetching && 
          <>
            <Box sx={{ display: 'flex', mt: '16px', mb: '16px', ml: '16px' }}>
              <Typography sx={{fontSize: '12px', fontWeight: 400, color: '#000', mr: '5px'}}>
                Not seeing “{query}”?  
              </Typography>
              <InviteLink sx={{fontSize: '12px', fontWeight: 400, color: '#000'}} component={'button'} onClick={handleContactInviteClick}>Invite now</InviteLink>
            </Box>
             <List>
              {_map(data, ({ id, first_name, last_name, photo, headline, username }) => {

                return (
                <ListItemButton
                  key={id}
                  component={NavLink}
                  sx={{height: '39px', mb: '10px'}}
                  to={`/users/${username}`}
                >
                  <Box sx ={{ display: 'flex', margin: '0 auto', width: '275px', height: '39px' }}>
                    <BaseAvatar
                      src={getRepresentation(photo?.representations, representationVariants.TINY)}
                      firstName={first_name}
                      lastName={last_name}
                      sx={{ width: 37, height: 37, fontSize: 12, marginRight: '18px' }}
                    />
                    <ListItemText
                      primary={`${first_name} ${last_name}`}
                      secondary={headline}
                      primaryTypographyProps={{ fontWeight: 500, fontSize: '12px', noWrap: true }}
                      secondaryTypographyProps={{ fontWeight: 400, fontSize: '10px', noWrap: true, component: 'div' }}
                    />
                  </Box>
                </ListItemButton>
              )})}
            </List>
          </>
          }
          </SearchContent>
          </Popover>}
        </Box>
        <StyledLink onClick={handleAll}>
          <Typography variant='body2' component='div' sx={{ fontWeight: 200,  }}>Contacts</Typography>
          <Typography variant='body2' component='div' sx={{ fontWeight: 500, textAlign: 'center', px: .5 }}>{total_contacts_count}</Typography>
        </StyledLink>
        <StyledLink onClick={handleConnections}>
          <Typography variant='body2' component='div' sx={{ fontWeight: 200,  }}>Connections</Typography>
          <Typography variant='body2' component='div' sx={{ fontWeight: 500, textAlign: 'center', px: .5 }}>{member_contacts_count}</Typography>
        </StyledLink>
        <StyledLink onClick={handleRequests}>
          <Typography variant='body2' component='div' sx={{ fontWeight: 200,  }}>Pending requests</Typography>
          <Typography variant='body2' component='div' sx={{ fontWeight: 500, textAlign: 'center', px: .5 }}>{pending_contacts_count}</Typography>
        </StyledLink>
      </Box>
      <Actions>
        <StyledButton onClick={openUploadContactsDialog}><UploadIcon /><span>Upload</span></StyledButton>
        <StyledButton onClick={() => setDrawerOpen({ open: true })}><IconContainer><AddIcon /></IconContainer><span>Add Contact</span></StyledButton>
        <StyledButton><SyncIcon /><span>Sync</span></StyledButton>
      </Actions>
    </Root>
  );
};

export default memo(ContactsWidget);