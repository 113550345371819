import { memo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Thumbs } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
// Local files
import { Root, StyledButton, Image, Preview, ThumbsContainer, FileInput } from './IntakeImages.styled';
import { ReactComponent as SelectImagesIcon } from 'assets/icons/images-select.svg';
import { ReactComponent as CropIcon } from 'assets/icons/crop.svg';
import BuilderErrorLabel from 'components/Common/DataDisplay/BuilderErrorLabel/BuilderErrorLabel';
import DropContainer from 'components/Common/Feedback/DropContainer/DropContainer';
import CropDialog from 'components/Dialogs/Crop/Crop';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { getImageMetaData } from 'helpers';
import useBlob from 'hooks/useBlob';
import useChecksum from 'hooks/useChecksum';
import useError from 'hooks/useError';

const allowTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/tiff'];
const accept = 'image/png,image/jpeg,image/tiff,image/gif';

const IntakeImages = ({ value, error, onChange }) => {
  const filesRef = useRef(null);
  const { processBlob } = useBlob();
  const { processFiles } = useChecksum();
  const { setError } = useError();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [cropDialog, setCropDialog] = useState({ open: false, type: '', src: '', width: 0, height: 0, showHeightSlider: false });
  const empty = !value.length;

  const handleUploadClick = () => filesRef.current.click();
  const handleFilesChange = ({ target: { files } }) => {
    processFiles(files, ({ file, checksum, localUrl }) => {
      setLoading(true);
      processBlob({ file, checksum }, blob => {
        if (!blob.id) return;

        onChange({ reason: 'add', v: { file: blob.id, name: blob.filename, localUrl } });
        setLoading(false);
      });
    });
  };
  const handleDrop = ({ files }) => {
    processFiles(files, ({ file, checksum, localUrl }) => {
      setLoading(true);
      processBlob({ file, checksum }, blob => {
        if (!blob.id) return;

        onChange({ reason: 'add', v: { file: blob.id, name: blob.filename, localUrl } });
        setLoading(false);
      });
    });
  };
  const handleCropClick = () => {
    const activeImage = value[activeSlideIndex];
    const src = activeImage.localUrl;

    getImageMetaData(src, (err, img) => {
      if (err) {
        setError(err);
        return;
      }
      setCropDialog({ open: true, type: 'image', src, width: img.naturalWidth, height: img.naturalHeight, showHeightSlider: false });
    });
  };
  const handleDeleteClick = () => {
    const activeImage = value[activeSlideIndex];
    const file = activeImage.file;

    onChange({ reason: 'remove', v: file });
  };
  const handleDialogClose = () => setCropDialog({ open: false, type: '', src: '', width: 0, height: 0, showHeightSlider: false });
  const handleDialogSubmit = f => {
    processFiles([f], ({ file, checksum, localUrl }) => {
      processBlob({ file, checksum }, blob => {
        if (!blob.id) return;

        onChange({ reason: 'update', v: { file: blob.id, localUrl }, i: activeSlideIndex });
        setCropDialog({ open: false, type: '', src: '', width: 0, height: 0, showHeightSlider: false });
      });
    });
  };

  if (empty) {
    return (
      <Box>
        <FileInput
          multiple
          type='file'
          accept={accept}
          ref={filesRef}
          onChange={handleFilesChange}
        />
        <DropContainer onDrop={handleDrop} allowTypes={allowTypes}>
          <ButtonBase variant='uploader' sx={{ height: 450, width: 600 }} onClick={handleUploadClick} disabled={loading}>
            {loading ?
              <Typography variant='subtitle3' mt={1}>
                Loading...
              </Typography> :
              <>
                <SelectImagesIcon style={{ color: '#8AC5F3' }} />
                <Typography variant='subtitle3' mt={1}>
                  Click to upload images <span style={{ fontWeight: 400 }}>or drag and drop</span>
                </Typography>
                <Typography variant='caption'>
                  PNG, JPEG, TIFF, GIF  •  600 px X 450 px
                </Typography>
              </>
            }
          </ButtonBase>
        </DropContainer>
        {!!error && <BuilderErrorLabel value={error} />}
      </Box>
    );
  }
  return (
    <>
      <CropDialog
        {...cropDialog}
        onSubmit={handleDialogSubmit}
        onClose={handleDialogClose}
      />
      <FileInput
        multiple
        type='file'
        accept={accept}
        ref={filesRef}
        onChange={handleFilesChange}
      />
      <DropContainer onDrop={handleDrop} allowTypes={allowTypes}>
        <Root>
          <StyledButton sx={{ position: 'absolute', top: '412px', right: '12px' }} onClick={handleUploadClick}>
            <AddIcon />
          </StyledButton>
          <StyledButton sx={{ position: 'absolute', top: '12px', right: '12px' }} onClick={handleCropClick}>
            <CropIcon />
          </StyledButton>
          <StyledButton sx={{ position: 'absolute', top: '62px', right: '12px' }} onClick={handleDeleteClick}>
            <DeleteIcon />
          </StyledButton>
            <Swiper
              spaceBetween={10}
              // thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Thumbs]}
              style={{
                '--swiper-navigation-color': 'rgba(0,0,0,.8)',
                '--swiper-pagination-color': '#fff'
              }}
              onSwiper={s => setActiveSlideIndex(s.activeIndex)}
              onSlideChange={s => setActiveSlideIndex(s.activeIndex)}
            >
              {loading ?
                <Box width={600} height={450} display='flex' justifyContent='center' alignItems='center'>
                  <Typography variant='subtitle3' mt={1}>
                    Loading...
                  </Typography>
                </Box> : _map(value, i =>
                <SwiperSlide key={i.file} style={{ height: 450, width: 600 }}>
                  <Image src={i.localUrl} />
                </SwiperSlide>
              )}
            </Swiper>
          
          {value.length > 1 &&
            <ThumbsContainer>
              <Swiper
                onSwiper={setThumbsSwiper}
                // loop
                spaceBetween={10}
                slidesPerView={4}
                freeMode
                watchSlidesProgress
                centerInsufficientSlides
                modules={[FreeMode, Thumbs]}
              >
                {_map(value, i =>
                  <SwiperSlide key={i.file}>
                    <Preview sx={{ backgroundImage: `url('${i.localUrl}')` }} />
                  </SwiperSlide>
                )}
              </Swiper>
            </ThumbsContainer>
          }
        </Root>
      </DropContainer>
      <Divider sx={{ mt: '1rem', mb: '6px' }} />
    </>
  );
};

IntakeImages.propTypes = {
  value: PropTypes.array.isRequired,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <IntakeImages {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);