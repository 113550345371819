import { memo, useCallback, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import update from 'immutability-helper';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import CloseIcon from 'components/Common/Icons/CloseIcon';
import Card from '../../Senders/Card/Card';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { checkEmptyString } from 'helpers';

const SendersDrawer = ({ data, open, onChange, onReorder, onClose }) => {
  const [senders, setSenders] = useState([]);

  const onEntering = () => {
    setSenders(_map(data, d => ({
      user: d.user,
      id: d.id,
      titleChecked: !!d.title,
      title: { value: d.title ?? '', error: '' },
      emailChecked: !!d.email,
      email: { value: d.email ?? '', error: '' },
      mobilePhoneChecked: !!d.mobile_phone,
      mobilePhone: { value: d.mobile_phone ?? '', error: '' },
      position: d.position
    })))
  };
  const onExited = () => setSenders([]);
  const handleChange = useCallback(({ id, primary, titleChecked, title, emailChecked, email, mobilePhoneChecked, mobilePhone }) => {
    if (!_isUndefined(primary)) {
      setSenders(prev => _map(prev, p => p.id === id && primary ? ({ ...p, primary: true }) : ({ ...p, primary: false })));

      const foundBuilderSender = _find(data, d => d.id === id);
      const updatableSender = { id: foundBuilderSender.id, primary };

      onChange(updatableSender);
    }
    if (!_isUndefined(titleChecked) || !_isUndefined(emailChecked) || !_isUndefined(mobilePhoneChecked)) {
      setSenders(prev => _map(prev, p => p.id === id ? ({
        ...p,
        ...(!_isUndefined(titleChecked) && { titleChecked }),
        ...(!_isUndefined(title) && { title }),
        ...(!_isUndefined(emailChecked) && { emailChecked }),
        ...(!_isUndefined(email) && { email }),
        ...(!_isUndefined(mobilePhoneChecked) && { mobilePhoneChecked }),
        ...(!_isUndefined(mobilePhone) && { mobilePhone })
      }) : p));

      if (!!title?.error || !!email?.error || !!mobilePhone?.error) return;
      const foundBuilderSender = _find(data, d => d.id === id);
      const foundStateSender = _find(senders, d => d.id === id);
      const updatableSender = {
        id: foundBuilderSender.id,
        ...!_isUndefined(titleChecked) && {
          title: !_isUndefined(title) ? title.value : foundStateSender.title.value
        },
        ...!_isUndefined(emailChecked) && {
          email: !_isUndefined(email) ? email.value : foundStateSender.email.value
        },
        ...!_isUndefined(mobilePhoneChecked) && {
          mobilePhone: !_isUndefined(mobilePhone) ? mobilePhone.value : foundStateSender.mobilePhone.value
        }
      };

      onChange(updatableSender);
    } else {
      setSenders(prev => _map(prev, p => p.id === id ? ({
        ...p,
        ...(!_isUndefined(title) && { title }),
        ...(!_isUndefined(email) && { email }),
        ...(!_isUndefined(mobilePhone) && { mobilePhone })
      }) : p));
    }
  }, [data, senders]); // eslint-disable-line 
  const handleBlur = useCallback(({ id, title, mobilePhone, email }) => {
    if (!!title?.error || !!email?.error || !!mobilePhone?.error) return;

    const foundBuilderSender = _find(data, d => d.id === id);
    const foundStateSender = _find(senders, d => d.id === id);

    if (!_isUndefined(title) && !foundStateSender.titleChecked) return;
    if (!_isUndefined(email) && !foundStateSender.emailChecked) return;
    if (!_isUndefined(mobilePhone) && !foundStateSender.mobilePhoneChecked) return;

    const updatableSender = {
      id: foundBuilderSender.id,
      ...!_isUndefined(title) && { title: title.value },
      ...!_isUndefined(email) && { email: email.value },
      ...!_isUndefined(mobilePhone) && { mobilePhone: mobilePhone.value },
    };

    onChange(updatableSender);
  }, [data, senders]); // eslint-disable-line
  const handleClose = () => {
    let hasError = false;

    _forEach(senders, s => {
      if (s.titleChecked && !checkEmptyString(s.title.value)) hasError = true;
      if (s.mobilePhoneChecked && !checkEmptyString(s.mobilePhone.value)) hasError = true;
      if (s.emailChecked && !checkEmptyString(s.email.value)) hasError = true;
    });

    if (hasError) {
      setSenders(_map(senders, s => ({  
        ...s,
        title: { ...s.title, error: s.titleChecked && !checkEmptyString(s.title.value) ? 'Enter title' : '' },
        mobilePhone: { ...s.mobilePhone, error: s.mobilePhoneChecked && !checkEmptyString(s.mobilePhone.value) ? 'Enter mobile phone' : '' },
        email: { ...s.email, error: s.emailChecked && !checkEmptyString(s.email.value) ? 'Enter email' : '' }
      })));
    }

    if (hasError) return;
    onClose();
  };
  const handleMoveCard = useCallback((dragIndex, hoverIndex) => {
    setSenders(prev => {
      const updatedSenders = update(prev, { $splice: [[dragIndex, 1], [hoverIndex, 0, prev[dragIndex]] ] });

      onReorder(_map(updatedSenders, us => ({
        email: us.email.value,
        id: us.id,
        mobile_phone: us.mobilePhone.value,
        position: us.position,
        title: us.title.value,
        user: us.user
      })));

      return updatedSenders;
    });
  }, []); // eslint-disable-line
  const handleDropCard = useCallback(({ id, index }) => {
    const foundBuilderSender = _find(data, d => d.id === id);
    const updatableSender = { id: foundBuilderSender.id, position: index };

    onChange(updatableSender);
  }, [data]); // eslint-disable-line
  const renderSender = useCallback((s, i) => {
    return (
      <Card
        {...s}
        key={s.id}
        index={i}
        onChange={data => handleChange({ id: s.id, ...data })}
        onBlur={data => handleBlur({ id: s.id, ...data })}
        onMove={handleMoveCard}
        onDrop={({ index }) => handleDropCard({ id: s.id, index })}
      />
    )
  }, [handleBlur, handleChange, handleMoveCard, handleDropCard]);

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={handleClose}
      SlideProps={{ onEntering, onExited }}
      ModalProps={{ sx: { zIndex: 1300 } }}
      PaperProps={{ sx: { height: '100%', width: { xs: '100%', md: '400px' } } }}
    >
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', padding: '8px' }}>
        <Button
          onClick={handleClose}
          disableRipple
          sx={{ minWidth: 36, minHeight: 36, padding: 0, backgroundColor: 'transparent !important' }}
        >
          <CloseIcon />
        </Button>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 30px 50px' }}>
        <Typography variant='subtitle2' align='center' mb='30px'>
          Deal team contact details
        </Typography>
        <Typography variant='caption' align='center' sx={{ maxWidth: '240px', mb: '40px' }}>
          Complete fields you would like visible to the recipient user. Drag members to reorder position on the Offering.
        </Typography>
        <Box width='100%'>
          {_map(senders, (s, i) => renderSender(s, i))}
        </Box>
      </Box>
    </Drawer>
  );
};

SendersDrawer.propTypes = {
  data: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <SendersDrawer {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);