import { memo, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import Table from 'components/Members/Table/Table';
import Search from 'components/Common/Inputs/Search/Search';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useCustomSelector from 'hooks/useCustomSelector';

const Users = () => {
  const allUsers = useCustomSelector(state => state.members.group.pagination.total_count);
  const data = useCustomSelector(state => state.members.group.data);
  const owners = useMemo(() => _filter(data, (member) => member.role === 'owner').length, [data]);
  const roleInThisGroup = useCustomSelector(state => state.groups.group.current_member.role);
  const [query, setQuery] = useState('');

  const members = allUsers - owners;

  const info = [
    { label: 'Total users', value: allUsers },
    { label: 'Owners', value: owners },
    { label: 'Collaborators', value: members }
  ];

  return (
    <Container maxWidth='lg' disableGutters>
      <Box display='flex' justifyContent='space-between' alignItems='center' sx={{ flexWrap: {xs: 'wrap', sm: 'nowrap'}, gap: '16px',  pt: { xs: '16px', sm: '60px'}, pb: 4 }}>
        <Box display='flex' pl={2}>
          {_map(info, ({ label, value }) => (
            <Box key={label} sx={{ mr: { xs: 2, sm: 5} }}>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '12px',
                  color: '#9FA2AC',
                  marginBottom: '2px'
                }}
              >
                {label}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '24px',
                  color: '#565F66',
                  textAlign: 'center'
                }}
              >
                {value}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box pl={2}>
        <Search
          value={query}
          onChange={setQuery}
          placeholder='Search users'
        />
      </Box>
      <Table roleInThisGroup={roleInThisGroup} query={query} />
    </Container>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Users {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);