import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import useCustomSelector from 'hooks/useCustomSelector';
import useApp from 'hooks/useApp';
import useSuccess from 'hooks/useSuccess';
import useError from 'hooks/useError';
import useInvitations from 'hooks/useInvitations';
import useGroups from 'hooks/useGroups';

const GroupInvitation = () => {
  const navigate = useNavigate();
  const { closeGroupInviteDialog } = useApp();
  const { acceptInvitation, declineInvitation } = useInvitations();
  const { getGroup } = useGroups();
  const { setSuccess } = useSuccess();
  const { setError } = useError();
  const { open, invitation } = useCustomSelector(state => state.app.groupInviteDialog);
  const [processing, setProcessing] = useState(false);
  const title = invitation ? `${invitation.inviter?.first_name ?? 'Unknown'} ${invitation.inviter?.last_name ?? 'Unknown'} has invited you to join ${invitation.invitationable?.name ?? 'Unknown'}` : '';

  const handleAccept = () => {
    setProcessing(true);
    acceptInvitation({ invitation_token: invitation?.invitation_token })
    .then(() => 
      getGroup(invitation?.invitationable?.id)
      .then(({ payload: { data: { group } } }) => setSuccess('Invite successfully accepted').then(() => closeGroupInviteDialog().then(() => navigate(`/groups/${group.id}/dealspaces`))))
      .catch(e => setError(e))
      .finally(() => setProcessing(false))
    )
    .catch(e => setError(e).then(() => setProcessing(false)))

  };
  const handleDeny = () => {
    setProcessing(true);
    declineInvitation({ invitation_token: invitation?.invitation_token })
    .then(() => setSuccess('Invite successfully denied').then(closeGroupInviteDialog))
    .catch(e => setError(e))
    .finally(() => setProcessing(false))
  };

  return (
    <BaseDialog
      open={open}
      onClose={closeGroupInviteDialog}
      maxWidth={false}
      PaperProps={{ sx: { maxWidth: 380, width: '100%' } }}
      title={title}
      actions={
        <>
          <Button
            variant='text'
            color='error'
            sx={{ px: '24px' }}
            disabled={processing}
            onClick={handleDeny}
          >
            Deny
          </Button>
          <Button
            variant='contained'
            color='primary'
            sx={{ px: '24px'}}
            disabled={processing}
            onClick={handleAccept}
          >
            Accept
          </Button>
        </>
      }
    />
  );
};

export default memo(GroupInvitation);