import axios, { baseURL } from 'apis';
import { sessionService } from 'redux-react-session';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  createSessionRoutine,
  deleteSessionRoutine,
  setLocalSessionRoutine
} from 'actions';

const attributes = [
  'access_token',
  'userable_type',
  {
    userable: [
      'email',
      { offer: ['id'] },
      { user: ['email'] }
    ]
  }
];

export const createSession = ({ email = null, password = null, linkedin_code = null, recipient_token = null }) => async dispatch => {
  dispatch(
    createSessionRoutine.request({
      ...email && { email },
      ...password && { password },
      ...linkedin_code && { linkedin_code },
      ...recipient_token && { recipient_token }
    })
  );

  try {
    const data = {
      ...email && { email },
      ...password && { password },
      ...linkedin_code && { linkedin_code },
      ...recipient_token && { recipient_token },
      attributes
    };
    if (recipient_token) {
      axios.defaults.baseURL = `${baseURL}/recipient`;
    } else {
      axios.defaults.baseURL = `${baseURL}/user`;
    }
    const response = await axios.post('/sessions', data);
    const session = await sessionService.loadSession().catch(e => console.warn(e));
    const user = await sessionService.loadUser().catch(e => console.warn(e));

    if (session) {
      axios.defaults.baseURL = `${baseURL}/recipient`;

      await axios.delete('/sessions', { params: { access_token: session.access_token ?? session.accessToken } })
      .then(() => dispatch(deleteSessionRoutine.success({ status: 204 })))
      .catch(e => console.error(e));
      
      if (user) await sessionService.deleteUser().catch(e => console.error(e));
      await sessionService.deleteSession().catch(e => console.error(e));
    }
    axios.defaults.params = { ...axios.defaults.params, access_token: response.data.session.access_token };
    axios.defaults.data = { ...axios.defaults.data, access_token: response.data.session.access_token };

    await sessionService.saveSession({ accessToken: response.data.session.access_token, userableType: response.data.session.userable_type }).catch(e => console.error(e));

    return dispatch(createSessionRoutine.success(response));
  } catch (error) {
    dispatch(createSessionRoutine.failure(error));

    throw error;
  }
};
export const deleteSession = controller => async dispatch => {
  dispatch(deleteSessionRoutine.request(controller));

  try {
    const response = await axios.delete('/sessions');
    
    await sessionService.deleteUser().catch(e => console.error(e));
    await sessionService.deleteSession().catch(e => console.error(e));
  
    if (controller) controller.abort();
    
    axios.defaults.baseURL = `${baseURL}/user`;
    axios.defaults.signal = undefined;
    axios.defaults.params = undefined;
    axios.defaults.data = undefined;

    dispatch(deleteSessionRoutine.success(response));
  } catch (error) {
    dispatch(deleteSessionRoutine.failure(error));
  }
};
export const setLocalSession = getThunkActionCreator( setLocalSessionRoutine, async ({ accessToken, userableType }) => ({ accessToken, userableType }));