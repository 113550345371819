import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getSenders as getSendersAction,
  getBuilderSenders as getBuilderSendersAction,
  getPreviewSenders as getPreviewSendersAction,
  getSender as getSenderAction,
  createSender as createSenderAction,
  updateSender as updateSenderAction,
  deleteSender as deleteSenderAction,
  clearLocalSenders as clearLocalSendersAction,
  clearLocalActiveSender as clearLocalActiveSenderAction
} from 'actions/senders';

const useSenders = () => {
  const dispatch = useDispatch();
  const getSenders = useCallback(({ offer_id, limit, offset }) => dispatch(getSendersAction({ offer_id, limit, offset })), [dispatch]);
  const getBuilderSenders = useCallback(({ offer_id, limit, offset }) => dispatch(getBuilderSendersAction({ offer_id, limit, offset })), [dispatch]);
  const getPreviewSenders = useCallback(({ offer_id, limit, offset }) => dispatch(getPreviewSendersAction({ offer_id, limit, offset })), [dispatch]);
  const getSender = useCallback(id => dispatch(getSenderAction(id)), [dispatch]);
  const createSender = useCallback(({ offer_id, sender }) => dispatch(createSenderAction({ offer_id, sender })), [dispatch]);
  const updateSender = useCallback(sender => dispatch(updateSenderAction(sender)), [dispatch]);
  const deleteSender = useCallback(id => dispatch(deleteSenderAction(id)), [dispatch]);
  const clearLocalSenders = useCallback(() => dispatch(clearLocalSendersAction()), [dispatch]);
  const clearLocalActiveSender = useCallback(() => dispatch(clearLocalActiveSenderAction()), [dispatch]);

  return {
    getSenders,
    getBuilderSenders,
    getPreviewSenders,
    getSender,
    createSender,
    updateSender,
    deleteSender,
    clearLocalSenders,
    clearLocalActiveSender
  };
};

export default useSenders;