import { memo } from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
// Local files
import { TableCell, backgroundColor } from './Head.styled';
import TableSortingCell from 'components/Common/DataDisplay/BaseTable/TableSortingCell/TableSortingCell';
import Search from 'components/Common/Inputs/Search/Search';

const headCells = [
  { id: 'activity_type', title: 'Action', align: 'left' },
  { id: 'updated_at', title: 'Date', align: 'left' },
  { id: 'device', title: 'Device', align: 'left' },
  { id: 'location', title: 'Location', align: 'left' }
];

const Head = ({ query, onQueryChange, order, orderBy, onSortChange }) => {
  return (
    <TableHead sx={{ background: backgroundColor }}>
      <TableRow>
        <TableCell sx={{ position:  'sticky', left: '0', zIndex: { xs: 2, md: 10 }, background: backgroundColor }}>
          <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', gap: '4px' }}>
            <Search
              placeholder='search recipients'
              value={query}
              onChange={v => onQueryChange(v)}
            />
          </Box>
        </TableCell>
        <TableSortingCell
          sx={{
            whiteSpace: 'nowrap',
            background: backgroundColor,
            '& .Mui-active .MuiTableSortLabel-icon': {
              color: '#4877F0 !important'
            }
          }}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          changeSorting={onSortChange}
        />
      </TableRow>
    </TableHead>
  );
};

Head.propTypes = {
  query: PropTypes.string.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onSortChange: PropTypes.func.isRequired
};

export default memo(Head);