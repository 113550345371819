import styled from '@mui/material/styles/styled';
import MuiLink from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export const avatarSx = {
  flexShrink: 0,
  width: 52,
  height: 52,
  borderRadius: '50%',
  fontSize: '24px'
}

export const Root = styled(Box)({
  height: '100%',
  backgroundColor: '#fff'
});

export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '30px',
  padding: '28px 24px 24px'
});

export const StyledStack = styled(Stack)({
  maxWidth: 636,
  marginLeft: 'auto',
  marginRight: 'auto'
});

export const Link = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecorationColor: theme.palette.secondary.main,
  fontSize: '0.675rem',
  fontWeight: 500,
  marginTop: 24
}));