import { memo } from 'react';
import PropTypes from 'prop-types';
// Local files
import { Root, Step, Bullet, Divider } from './Stepper.styled';

/**
 * 
 * @param {string} value
 * @param {func} onChange
 * @returns {node}
 */

const Stepper = ({ value, onChange }) => {
  const handleStepClick = s => {
    if (value !== s) {
      onChange(s);
    }
  }

  return (
    <Root>
      <Step
        active={value === 'offering_info'}
        onClick={() => handleStepClick('offering_info')}
      >
        <Bullet active={value === 'offering_info'} />
        <span>Offering Info</span>
      </Step>
      <Step
        active={value === 'add_files'}
        onClick={() => handleStepClick('add_files')}
      >
        <Divider />
        <Bullet active={value === 'add_files'} />
        <span>Add Files</span>
      </Step>
      <Step
        active={value === 'share'}
        onClick={() => handleStepClick('share')}
      >
        <Divider />
        <Bullet active={value === 'share'} />
        <span>Share</span>
      </Step>
    </Root>
  );
};

Stepper.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default memo(Stepper);