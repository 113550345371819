import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars-2';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
// Local files
import { Root, Content, Badge, StyledTabs, StyledTab } from './Nav.styled';
import { chatLevels, representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import UserAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import GroupAvatar from 'components/Groups/Avatar/Avatar';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useCustomSelector from 'hooks/useCustomSelector';
import useConversations from 'hooks/useConversations';
import useChatsLevel from 'hooks/useChatsLevel';
import useDealspaces from 'hooks/useDealspaces';
import useMessages from 'hooks/useMessages';
import useOffers from 'hooks/useOffers';
import useError from 'hooks/useError';
import useApp from 'hooks/useApp';

const { USER, GROUP } = chatLevels;

const Nav = ({ mobile = false }) => {
  const navigate = useNavigate();
  const previousLocation = useLocation();
  const { setError } = useError();
  const { setChatsFilter } = useApp();
  const { clearLocalActiveConversation } = useConversations();
  const { clearLocalMessages } = useMessages();
  const { getDealspacesForChats, clearLocalDealspacesForChats } = useDealspaces();
  const { getPublishedOffersForChats, getDealspaceOffersForChats, getGroupOffersForChats, clearLocalOffersForChats } = useOffers();
  const { isDealspaceLevel, isGroupLevel, isUserLevel, chat_level, chat_level_id } = useChatsLevel();
  const { all: { data } } = useCustomSelector(state => state.groups);
  const { id: my_id, first_name, last_name, photo, unread_general_conversations_count, unread_feed_conversations_count } = useCustomSelector(state => state.profile.user);
  const [processing, setProcessing] = useState(false);

  const fetchDealspaces = useCallback((offset, group_id) => {
    clearLocalDealspacesForChats()
    .then(() => 
      getDealspacesForChats({ group_id, offset })
      .catch(e => setError(e))
      .finally(() => setProcessing(false))
    )
  }, [getDealspacesForChats, clearLocalDealspacesForChats, setError]);
  const fetchOffers = useCallback((offset, id) => {
    setProcessing(true);
    clearLocalOffersForChats()
    .then(() => {
      isUserLevel && getPublishedOffersForChats(offset).catch(e => setError(e))
      isDealspaceLevel && getDealspaceOffersForChats({ offset, dealspace_id: id }).catch(e => setError(e))
      isGroupLevel && getGroupOffersForChats({ offset, group_id: id }).catch(e => setError(e))
    })
  }, [ 
      isUserLevel,
      isDealspaceLevel,
      isGroupLevel,
      getPublishedOffersForChats, 
      getDealspaceOffersForChats, 
      getGroupOffersForChats, 
      clearLocalOffersForChats, 
      setError
    ]);

  useEffect(() => {
    fetchOffers(0, chat_level_id);
    isGroupLevel ? fetchDealspaces(0, chat_level_id) : setProcessing(false);
  }, [chat_level_id, isGroupLevel, fetchOffers, fetchDealspaces]);

  const handleChange = (_, newValue) => { 
    if (!processing) {
      clearLocalActiveConversation()
      .then(() => 
        clearLocalMessages()
        .then(() => setChatsFilter({ filter: { dealspace_id: '', offer_id: '' } }).then(() => navigate(`/chats/${newValue}`, { state: previousLocation.state })))
      );
    }
  };

  return (
    <Root>
      <Scrollbars autoHide autoHideTimeout={1000}>
        <Content sx={{ ...mobile && { paddingTop: '65px' } }}>
          <StyledTabs
            orientation='vertical'
            value={`${isDealspaceLevel ? GROUP : chat_level || USER}/${chat_level_id || my_id}`}
            onChange={handleChange}
          >
            <StyledTab
              disableRipple
              disabled={processing}
              value={`${USER}/${my_id}`}
              label={
                <Badge variant="dot" invisible={!!!unread_general_conversations_count && !!!unread_feed_conversations_count} color='success'>
                  <UserAvatar
                    firstName={first_name}
                    lastName={last_name}
                    src={getRepresentation(photo?.representations, representationVariants.TINY)}
                    sx={{ width: '36px !important', height: '36px !important' }}
                  />
                </Badge>
              }
            />
            { !!data.length && _map(data, ({ id, logo, name, unread_dealspaces_due_offer_conversations_count, unread_dealspaces_due_dealspace_conversations_count }) => 
            <StyledTab
              key={id}
              disabled={processing}
              disableRipple
              value={`${GROUP}/${id}`}
              label={
                <Badge variant="dot" invisible={!!!unread_dealspaces_due_offer_conversations_count && !!!unread_dealspaces_due_dealspace_conversations_count} color='success'>
                  <GroupAvatar
                    title={name.slice(0,1)}
                    src={getRepresentation(logo?.representations, representationVariants.TINY)}
                    sx={{ width: '36px !important', height: '36px !important', fontSize: '16px !important' }}
                    fetching={processing}
                  />
                </Badge>
              }
            /> )}
          </StyledTabs>
        </Content>
      </Scrollbars>
    </Root>
  );
};

Nav.propTypes = {
  mobile: PropTypes.bool
};

const WrapperComponent = props => {
  return (
    <ErrorWrapper>
      <Nav {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrapperComponent);