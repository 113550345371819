import { memo, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import Scrollbars from 'react-custom-scrollbars-2';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _intersectionBy from 'lodash/intersectionBy';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { OptionsContainer, Root, OptionsPaper, StyledListItemButton, ResultContainer, ResultHeader, ResultListItem, ItemTitle, ItemSubtitle, ItemDescription, searchStyles, ListIconContainer, ItemAvatar } from './Sharing.styled';
import CloseIcon from 'components/Common/Icons/CloseIcon';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import Search from 'components/Common/Inputs/Search/Search';
import Drawer from 'components/Drawers/Contact/Contact';
import ListsSelect from 'components/Lists/Sharing/Select/Select';
import { ReactComponent as ListIcon } from 'assets/icons/list.svg';
import { ReactComponent as SharedIcon } from 'assets/icons/shared.svg';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { checkEmptyString, checkValidEmail, getRepresentation } from 'helpers';
import { representationVariants } from 'helpers/constants';
import useCustomSelector from 'hooks/useCustomSelector';

const Recipients = ({ query, fetching, totalUnique, activeList, groupId, creatableRecipients, exclusionRecipients, onChange, onScroll }) => {
  const { myId, lists, contacts, totalAmount, needToLoadMore, newOffset, defaultList } = useCustomSelector(state => ({
    myId: state.profile.user.id,
    lists: state.lists.shared.data,
    contacts: state.contacts.shared.data,
    totalAmount: state.contacts.shared.pagination.total_count,
    needToLoadMore: state.contacts.shared.pagination.total_count > state.contacts.shared.pagination.count + state.contacts.shared.pagination.offset,
    newOffset: state.contacts.shared.pagination.limit + state.contacts.shared.pagination.offset,
    defaultList: state.lists.defaultList
  }));
  const [drawer, setDrawer] = useState({ open: false, defaultData: null });
  const showCreateBlock = checkEmptyString(query);
  const allListContactsSelected = !!_find(creatableRecipients, lr => lr.id === activeList?.id);

  const handleAddClick = () => setDrawer({
    open: true,
    defaultData: checkEmptyString(query) ? { ...checkValidEmail(query) ? { email: query } : { firstName: query } } : null
  });
  const handleDrawerClose = () => setDrawer({ open: false, defaultData: null });
  const handleContactAdded = v => {
    onChange({
      reason: 'addItem',
      data: { id: v.id, email: v.email, first_name: v.first_name, last_name: v.last_name }
    })
  };
  const handleContactsScroll = ({ top }) => {
    if (top > 0.99 && needToLoadMore && !fetching) {
      onScroll(newOffset);
    }
  };
  const getSelectedState = ({ recipiented, allListContactsSelected, id, lists }) => {
    if (recipiented) return true;
    if (!!_intersectionBy(lists, creatableRecipients, 'id').length && !_find(exclusionRecipients, v => v.id === id)) return true;
    if (allListContactsSelected) {
      return !_find(exclusionRecipients, v => v.id === id);
    } else {
      return !!_find(creatableRecipients, v => v.id === id);
    }
  };
  const handleSelectChange = () => {
    _forEach(lists, l => {
      const res = _find(l.lists, list => list.id === activeList.id);

      if (res) {
        onChange({ reason: 'selectList', data: { ...res, user: l.user } });
      }
    });
  };
  const handleListsChange = ({ type, value }) => {
    if (type === 'add') {
      _forEach(lists, l => {
        const res = _find(l.lists, list => list.id === value.id);

        if (res) {
          onChange({ reason: 'selectList', data: { ...res, user: l.user } });
        }
      });
    }
    if (type === 'remove') {
      _forEach(lists, l => {
        const res = _find(l.lists, list => list.id === value.id);
  
        if (res) {
          onChange({ reason: 'selectList', data: { ...res, user: l.user } });
        }
      });
    }
    if (type === 'select') {
      onChange({ reason: 'activeListChanged', data: { activeList: value.id !== defaultList.id ? value : null } });
    }
  };

  return (
    <Root>
      <Drawer
        {...drawer}
        quick
        onClose={handleDrawerClose}
        onChange={handleContactAdded}
      />
      <OptionsContainer>
        <Box display='flex' gap='8px'>
          <Search
            placeholder='Enter email or name'
            value={query}
            onChange={v => onChange({ reason: 'queryChanged', data: { query: v } })}
            sx={searchStyles}
          />
        </Box>
        <OptionsPaper>
          <Box display='flex' alignItems='center' gap='8px' paddingLeft={3} paddingRight={3}>
            <ListsSelect
              groupId={groupId}
              value={activeList}
              selectedLists={_filter(creatableRecipients, r => r.type === 'list').map(l => l.id)}
              onChange={handleListsChange}
            />
            <Box display='flex' gap='5px' alignItems='center'>
              <Chip
                label={activeList?.name ?? defaultList?.name ?? ''}
                onDelete={activeList && activeList?.id !== defaultList?.id ? () => onChange({ reason: 'activeListChanged', data: { activeList: null } }) : undefined}
              />
              {activeList && activeList?.user?.id !== myId &&
                <Typography variant='caption'>
                  Shared by <b>{activeList.user.first_name} {activeList.user.last_name}</b>
                </Typography>
              }
              {(!activeList || activeList?.id === defaultList?.id) &&
                <Typography variant='caption'>
                  (default list)
                </Typography>
              }
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: !!activeList ? 'space-between' : 'flex-end', p: '12px 16px 4px' }}>
            {activeList &&
              <FormControlLabel
                onChange={handleSelectChange}
                control={
                  <Checkbox
                    checked={allListContactsSelected}
                    color='secondary'
                  />
                }
                label='Select Entire List'
                sx={{ ml: 'unset' }}
              />
            }
            <Typography variant='caption'>
              <b>{totalAmount}</b> unique contacts
            </Typography>
          </Box>
          {showCreateBlock &&
            <Box display='flex' justifyContent='space-between' p={2}>
              <Typography>{query}</Typography>
              <Button
                variant='text'
                onClick={handleAddClick}
                startIcon={<AddIcon />}
              >
                Create New Contact
              </Button>
            </Box>
          }
          <Divider sx={{ borderColor: '#F1F1F1' }} />
          <Box sx={{ flexGrow: 1 }}>
            <Scrollbars autoHide autoHideTimeout={1000} onScrollFrame={handleContactsScroll}>
              <List disablePadding>
                {_map(contacts, ({ id, first_name, last_name, email: e, user, lists, invitation, recipiented, owner, shared_names }) => {
                  let invitationData = null;
                  if (!!invitation?.inviter && invitation?.inviter?.id !== myId) invitationData = invitation?.inviter;
                  if (!!invitation?.user && invitation?.user?.id !== myId) invitationData = invitation?.user;
                  const avatar = getRepresentation(user?.photo?.representations ?? invitationData?.photo?.representations, representationVariants.TINY);
                  const firstName = user?.first_name ?? invitationData?.first_name ?? first_name;
                  const lastName = user?.last_name ?? invitationData?.last_name ?? last_name;
                  const email = user?.email ?? invitationData?.email ?? e;
                  const name = firstName + ' ' + lastName;
                  const selected = getSelectedState({ recipiented, allListContactsSelected, id, lists });

                  return (
                    <ListItem key={id} disablePadding>
                      <StyledListItemButton
                        selected={selected}
                        onClick={() => !recipiented ? onChange({
                          reason: 'selectItem',
                          data: {
                            id,
                            selected,
                            ...user && { user },
                            ...invitation && { invitation },
                            ...email && first_name && last_name && { email, first_name, last_name }
                          }
                        }) : {}}
                      >
                        {recipiented &&
                          <Box sx={{ position: 'absolute', left: 2, top: 'calc(50% - 8px)' }}>
                            <Tooltip title='Shared' placement='left'><SharedIcon style={{ height: '16px' }} /></Tooltip>
                          </Box>
                        }
                        <Checkbox
                          disabled={recipiented}
                          color='secondary'
                          checked={selected}
                          sx={{ flexShrink: 0, mr: '-12px' }}
                        />
                        <BaseAvatar
                          src={avatar}
                          firstName={firstName}
                          lastName={lastName}
                        />
                        <ItemTitle sx={{ flexBasis: '25%', flexShrink: 0 }}>{name}</ItemTitle>
                        <Box sx={{ overflow: 'hidden' }}>
                          <ItemDescription>{email}</ItemDescription>
                        </Box>
                      </StyledListItemButton>
                    </ListItem>
                  );
                })}
                {fetching && <LinearProgress />}
              </List>
            </Scrollbars>
          </Box>
        </OptionsPaper>
      </OptionsContainer>
      <ResultContainer>
        <ResultHeader>
          <Typography variant='subtitle2'>
            {totalUnique} unique selected
          </Typography>
          {!!totalUnique && <Button onClick={() => onChange({ reason: 'deleteAll' })}>Deselect all</Button>}
        </ResultHeader>
        <Box sx={{ flexGrow: 1 }}>
          <Scrollbars autoHide autoHideTimeout={1000}>
            <List disablePadding>
              {_map(_orderBy(creatableRecipients, ['type'], ['desc']), ({ type, id, name, contactsCount, user, email: e, first_name, last_name, invitation }) => {
                if (type === 'list') {
                  return (
                    <ResultListItem
                      key={id}
                      secondaryAction={
                        <Button
                          variant='blank'
                          color='black'
                          sx={{ width: 42, height: 42, mr: '-12px' }}
                          onClick={() => onChange({ reason: 'deleteList', data: { id } })}
                        >
                          <CloseIcon />
                        </Button>
                      }
                    >
                      <ListIconContainer>
                        <ListIcon />
                      </ListIconContainer>
                      <Box display='flex' flexDirection='column' sx={{ flexBasis: '30%', flexShrink: 0, overflow: 'hidden' }}>
                        <ItemTitle>{name}</ItemTitle>
                        <ItemSubtitle>{user.first_name} {user.last_name}</ItemSubtitle>
                      </Box>
                      <ItemDescription><b>{contactsCount}</b> Contacts</ItemDescription>
                    </ResultListItem>
                  );
                } else {
                  let invitationData = null;
                  if (!!invitation?.inviter && invitation?.inviter?.id !== myId) invitationData = invitation?.inviter;
                  if (!!invitation?.user && invitation?.user?.id !== myId) invitationData = invitation?.user;
                  const avatar = getRepresentation(user?.photo?.representations ?? invitationData?.photo?.representations, representationVariants.TINY);
                  const firstName = user?.first_name ?? invitationData?.first_name ?? first_name;
                  const lastName = user?.last_name ?? invitationData?.last_name ?? last_name;
                  const email = user?.email ?? invitationData?.email ?? e;
                  const name = firstName + ' ' + lastName;

                  return (
                    <ResultListItem
                      key={id}
                      secondaryAction={
                        <Button
                          variant='blank'
                          color='black'
                          sx={{ width: 42, height: 42, mr: '-12px' }}
                          onClick={() => onChange({ reason: 'deleteItem', data: { id } })}
                        >
                          <CloseIcon />
                        </Button>
                      }
                    >
                      <ItemAvatar>
                        <BaseAvatar
                          src={avatar}
                          firstName={firstName}
                          lastName={lastName}
                        />
                      </ItemAvatar>
                      <ItemTitle sx={{ flexBasis: '30%', flexShrink: 0, fontWeight: 600 }}>{name}</ItemTitle>
                      <ItemDescription>{email}</ItemDescription>
                    </ResultListItem>
                  );
                }
              })}
              {_map(exclusionRecipients, ({ id, user, email: e, first_name, last_name, invitation }) => {
                let invitationData = null;
                if (!!invitation?.inviter && invitation?.inviter?.id !== myId) invitationData = invitation?.inviter;
                if (!!invitation?.user && invitation?.user?.id !== myId) invitationData = invitation?.user;
                const avatar = getRepresentation(user?.photo?.representations ?? invitationData?.photo?.representations, representationVariants.TINY);
                const firstName = user?.first_name ?? invitationData?.first_name ?? first_name;
                const lastName = user?.last_name ?? invitationData?.last_name ?? last_name;
                const email = user?.email ?? invitationData?.email ?? e;
                const name = firstName + ' ' + lastName;
                
                return (
                  <ResultListItem
                    key={id}
                    sx={{ backgroundColor: '#FFE5E5' }}
                    secondaryAction={
                      <Box>
                        <Typography
                          fontWeight={600}
                          fontSize='10px'
                          lineHeight='22px'
                          color='#B80101'
                        >
                          - Removed
                        </Typography>
                        <Typography
                          fontWeight={600}
                          fontSize='10px'
                          lineHeight='22px'
                          color='#000000'
                          sx={{ cursor: 'pointer' }}
                          onClick={() => onChange({ reason: 'returnItem', data: { id } })}
                        >
                          + Add Back
                        </Typography>
                      </Box>
                    }
                  >
                    <ItemAvatar>
                      <BaseAvatar
                        src={avatar}
                        firstName={firstName}
                        lastName={lastName}
                      />
                    </ItemAvatar>
                    <ItemTitle sx={{ fontWeight: 600 }}>{name}</ItemTitle>
                    <ItemDescription>{email}</ItemDescription>
                  </ResultListItem>
                );
              })}
            </List>
          </Scrollbars>
        </Box>
      </ResultContainer>
    </Root>
  );
};

Recipients.propTypes = {
  query: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  totalUnique: PropTypes.number.isRequired,
  activeList: PropTypes.object,
  groupId: PropTypes.string.isRequired,
  creatableRecipients: PropTypes.array.isRequired,
  exclusionRecipients: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Recipients {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);