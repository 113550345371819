import { memo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Typography from '@mui/material/Typography';

const ErrorFallback = ({ error }) => {
  return (
    <div role='alert' style={{ padding: 48 }}>
      <Typography variant='subtitle1' sx={{ mb: 1 }} color='error'>Something went wrong:</Typography>
      <Typography variant='subtitle2' sx={{ mb: 1 }}>Please reload page. If it doesn't help contact our support.</Typography>
      <Typography variant='body2' color='textSecondary'>{error.message}</Typography>
    </div>
  );
};

const ErrorWrapper = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {children}
    </ErrorBoundary>
  );
};

export default memo(ErrorWrapper);