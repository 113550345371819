import styled from '@mui/material/styles/styled';

const Root = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  overflow: 'hidden'
});

export { Root };