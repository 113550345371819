import { memo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// Local files
import { Actions, AddParcelButton, ContentContainer, ListHeading, MapContainer, StyledDialogContent } from './Property.styled';
import CloseIcon from 'components/Common/Icons/CloseIcon';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import GoogleAutocomplete from 'components/Common/Location/GoogleAutocomplete/GoogleAutocomplete';
import Map from 'components/Locations/Map/Map';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { checkEmptyString } from 'helpers';
import useError from 'hooks/useError';
import useProperties from 'hooks/useProperties';
import useSuccess from 'hooks/useSuccess';
import useMap from 'hooks/useMap';

const Property = ({ dealspaceId = null, offerId = null, open, onChange = null, onClose }) => {
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const navigate = useNavigate()
  const { setError } = useError();
  const { createOfferProperty, createDealspaceProperty } = useProperties();
  const { selectPolygon } = useMap();
  const { setSuccess } = useSuccess();
  const [name, setName] = useState({ value: '', error: '' });
  const [inputAddress, setInputAddress] = useState(''); 
  const [addresses, setAddresses] = useState({ value: [], error: '' });
  const [polygon, setPolygon] = useState(null);
  const [processing, setProcessing] = useState(false);
  const disabled = processing;

  const handleCancelClick = () => onClose();
  const handleSaveClick = () => {
    let hasError = false;
    if (!checkEmptyString(name.value)) {
      setName(prev => ({ ...prev, error: 'Enter a property name' }));
      hasError = true;
    };
    if (!addresses.value.length) {
      setAddresses(prev => ({ ...prev, error: 'At least 1 parcel required to be added to save a property' }));
      hasError = true;
    }
    if (hasError) return;

    const property = { 
      name: name.value,
      ...!!addresses.value.length && {
        locations_attributes: _map(addresses.value, a => ({
          address: a.value,
          latitude: a.latitude,
          longitude: a.longitude,
          ...a.parcel && { parcel: a.parcel }
        }))
      }
    };

    setProcessing(true);
    createDealspaceProperty({ dealspace_id: dealspaceId, property })
    .then(({ payload: { data } }) => {
      if (!!offerId) {
        createOfferProperty({ offer_id: offerId, property_id: data.property.id })
        .then(({ payload: { data: { property } } }) => { 
          onChange(property);
          setProcessing(false);
        })
        .catch(e => setError(e).then(() => setProcessing(false)))
        .finally(() => setSuccess('Property successfully created').then(() => handleClose()));
      } else {
        setSuccess('Property successfully created').then(() => { 
          setProcessing(false);
          handleClose(property.id);
        });
      }
    })
    .catch(e => setError(e).then(() => setProcessing(false)));
  };

  const handleExited = () => {
    setName({ value: '', error: '' });
    setAddresses({ value: [], error: '' });
    setProcessing(false);
  };

  const handleAddressSelect = data => {
    setAddresses(prev => ({ value: [...prev.value, data], error: '' }));
    setName({ value: data.value.substring(0, data.value.indexOf(',')) });
    setInputAddress('');
  };
  const handleAddParcel = () => {
    selectPolygon(polygon?.value)
    .then(() => {
      setAddresses(prev => ({ value: [...prev.value, polygon], error: '' }));
      setName({ value: polygon.value.substring(0, polygon.value.indexOf(',')) });
      setPolygon(null);
    })
  };
  const handleClose = (property_id = null) => {
    onClose();
    !!property_id && navigate(`/dealspaces/${dealspaceId}/properties/${property_id}`);
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      otherPaperStyle={{ width: '100%', maxWidth: 1010  }}
      fullScreen={!mediaDesktop}
      title=''
      TransitionProps={{ onExited: handleExited }}
    >
      <StyledDialogContent>
        <MapContainer>
          <Map
            onAddressSelect={value => setAddresses({ value, error: '' })}
            onPolygonSelect={setPolygon}
            addresses={addresses.value}
            polygon={polygon}
          />
          <GoogleAutocomplete
            placeholder='Search an address'
            value={inputAddress}
            onChange={setInputAddress}
            onSelect={handleAddressSelect}
          />
          {!!polygon && <AddParcelButton disabled={!!!polygon} onClick={handleAddParcel}>Add Parcel</AddParcelButton>}
        </MapContainer>
        <ContentContainer>
          <TextField
            placeholder='Type a Property Name'
            variant='standard'
            fullWidth
            InputProps={{ disableUnderline: true }}
            value={name.value}
            error={!!name.error}
            helperText={name.error}
            onChange={({ target: { value } }) => setName({ value, error: '' })}
          />
          {!!addresses.value.length ? (
            <Box sx={{  width: '100%', overflow: 'auto' }}>
              <ListHeading>Property Parcels ({addresses.value.length})</ListHeading>
              <List disablePadding sx={{ minHeight: 200 }}>
                {_map(addresses.value, a =>
                  <ListItem
                    key={a.value}
                    disableGutters
                    sx={{ color: '#696969' }}
                    secondaryAction={
                      <Button
                        variant='blank'
                        color='black'
                        onClick={() => setAddresses(prev => ({ value: _filter(prev.value, p => p.value !== a.value), error: '' }))}
                      >
                        <CloseIcon />
                      </Button>
                    }
                  >
                    {a.formattedValue}
                  </ListItem>
                )}
              </List>
            </Box>
          ) : (
            <Box>
              <Typography sx={{ fontSize: 18, maxWidth: 380, fontWeight: 600, textAlign: 'center', mx: 'auto', pb: '16px' }}>
                Search address or click parcel <br/>
                directly from the map  
              </Typography>
              <Typography sx={{ fontSize: 12, maxWidth: 325, textAlign: 'center', mx: 'auto', paddingBottom: '50px' }}>
                <b>NOTE:</b> Multiple parcels may be added for a single property, however, if this is related to a portfolio or assemblage, multiple properties should be added.
              </Typography>
              {!!addresses.error &&
                <Typography fontWeight={400} color='#E30000' fontSize='0.75rem' lineHeight={1.66}>
                  {addresses.error}
                </Typography>
              }
            </Box>
          )}
          <Actions>
            <Button
              sx={{ px: '30px' }}
              color='error'
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              sx={{ px: '30px' }}
              variant='contained'
              color='primary'
              disabled={disabled}
              onClick={handleSaveClick}
            >
              Save Property
            </Button>
          </Actions>

        </ContentContainer>
      </StyledDialogContent>
    </BaseDialog>
  );
};

Property.propTypes = {
  dealspaceId: PropTypes.string,
  offerId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onClose: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Property {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);