import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getAuditsRoutine,
  getAuditRoutine,
  getCableCreatedAuditRoutine,
  createAuditRoutine,
  clearLocalAuditsRoutine
} from 'actions';

const attributes = [
  'id',
  { user: ['id', 'first_name', 'last_name', { photo: ['representations'] }] },
  'description',
  'created_at',
  'updated_at'
];

export const getAudits = getThunkActionCreator(
  getAuditsRoutine,
  async ({ offer_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const params = { 
      limit, 
      offset, 
      orders, 
      attributes
    };

    return await axios.get(`/offers/${offer_id}/audits`, { params });
  }
);
export const getAudit = getThunkActionCreator(
  getAuditRoutine,
  async id => {
    const params = { attributes };

    return await axios.get(`/audits/${id}`, { params });
  }
);
export const getCableCreatedAudit = getThunkActionCreator(
  getCableCreatedAuditRoutine,
  async id => {
    const params = { attributes };

    return await axios.get(`/audits/${id}`, { params });
  }
);
export const createAudit = getThunkActionCreator(
  createAuditRoutine,
  async ({ offer_id, audit_type, recipients_count }) => {
    const data = { audit_type, ...!!recipients_count && { recipients_count }, attributes };
    const url = `/offers/${offer_id}/audits`;

    return await axios.post(url, data);
  }
);
export const clearLocalAudits = getThunkActionCreator(clearLocalAuditsRoutine, async () => {});