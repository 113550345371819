import { memo } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const UploadedImage = ({ id, disabled, onClick, showImage, width = null, src, filename }) => {
  return (
    <Box mt={1} mb={1} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <IconButton
        disabled={disabled}
        onClick={() => onClick(id)}
        color='error'
      >
        <CloseIcon />
      </IconButton>
      {showImage &&
        <img
          style={{ flexShrink: 0, height: 'auto', width: width || '100%', objectFit: 'cover', borderRadius: '12px' }}
          src={src}
          alt='preview'
          data-private
        />
      }
      { !!filename && <Typography noWrap align='left' sx={{ textAlign: 'center', flexGrow: 1, pl: 1 }}>{filename}</Typography> }
    </Box>
  );
};

UploadedImage.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  showImage: PropTypes.bool,
  src: PropTypes.string.isRequired,
  filename: PropTypes.string
};

export default memo(UploadedImage);