import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import ListsAutocomplete from 'components/Lists/Autocomplete/Autocomplete';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { checkEmptyString, checkValidEmail } from 'helpers';
import useContacts from 'hooks/useContacts';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';

const CreateContactInvite = ({ open, onClose }) => {
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { createContact } = useContacts();
  const [firstName, setFirstName] = useState({ value: '', error: '' });
  const [lastName, setLastName] = useState({ value: '', error: '' });
  const [email, setEmail] = useState({ value: '', error: '' });
  const [lists, setLists] = useState([]);
  const [processing, setProcessing] = useState(false);

  const handleExited = () => {
    setFirstName({ value: '', error: '' });
    setLastName({ value: '', error: '' });
    setEmail({ value: '', error: '' });
    setLists([]);
  };
  
  const handleSubmit = () => {
    let hasError = false;
    const listAttributes = _filter(lists, l => !!l.inputValue);
    const listIds = _filter(lists, l => !!l.id && !!!l.inputValue);
    
    if (!checkValidEmail(email.value)) {
      setEmail(prev => ({ ...prev, error: 'Email is invalid' }));
      hasError = true;
    }
    if (!checkEmptyString(firstName.value)) {
      setFirstName(prev => ({ ...prev, error: 'First Name can\'t be empty' }));
      hasError = true;
    }
    if (!checkEmptyString(lastName.value)) {
      setLastName(prev => ({ ...prev, error: 'Last Name can\'t be empty' }));
      hasError = true;
    }

    if (hasError) return;

    const contact = {
      first_name: firstName.value ,
      last_name: lastName.value,
      email: email.value,
      invitation_attributes: { email: email.value },
      ...(!!listAttributes.length && { lists_attributes: _map(listAttributes, la => ({ name: la.inputValue })) }),
      ...(!!listIds.length && { list_ids: _map(listIds, l => l.id) }) 
    };

    setProcessing(true);
    createContact({contact})
    .then(() => {
        setSuccess('Invitation sent successfully');
        setSuccess('Saved to contact list');
    })
    .then(() => onClose(true))
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };

  return (
    <BaseDialog
      open={open}
      onClose={() => onClose()}
      otherPaperStyle={{ width: '100%', maxWidth: 500 }}
      title='Invite to Qwincy'
      TransitionProps={{ onExited: handleExited }}
      actions={
        <>
          <Button
            variant='text'
            color='error'
            sx={{ px: '24px', minHeight: '37px'}}
            disabled={processing}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            sx={{ px: '24px'}}
            disabled={processing}
            onClick={handleSubmit}
          >
            Invite
          </Button>
        </>
      }
    >
      <Box sx={{ maxWidth: 290, margin: '0 auto', p: '24px 0 16px', '& > *': { margin: '0 0 14px !important'} }}>
        <Box sx={{ display: 'flex' }}>
          <TextField
            label='First Name'
            value={firstName.value}
            onChange={({ target: { value } }) => setFirstName({ value, error: '' })}
            sx={{ '& > div': {  weight: '132px', }, mr: '6px' }}
            fullWidth
            margin='dense'
            error={!!firstName.error}
            helperText={firstName.error}
          />
          <TextField
            label='Last Name'
            value={lastName.value}
            onChange={({ target: { value } }) => setLastName({ value, error: '' })}
            sx={{ '& > div': { weight: '132px', } }}
            fullWidth
            margin='dense'
            error={!!lastName.error}
            helperText={lastName.error}
          />
        </Box>    
        <TextField
          label='Email'
          value={email.value}
          onChange={({ target: { value } }) => setEmail({ value, error: '' })}
          fullWidth
          margin='dense'
          error={!!email.error}
          helperText={email.error}
        />
        <Typography sx={{fontSize: '10px', fontWeight: 400, lineHeight: '20px', color: '#616161', mt: '16px'}}>
         Keep your contacts organized.  Let’s classify this contact now:
        </Typography>
        <ListsAutocomplete
          label='List(s) (optional)'
          value={lists}
          onChange={setLists}
        />
      </Box>
    </BaseDialog>
  );
};

CreateContactInvite.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <CreateContactInvite {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);