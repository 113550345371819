import { FileChecksum } from 'helpers/FileChecksum';
import { getCompressedImage } from 'helpers/ImageCompressor';
import { getHeightAndWidthFromDataUrl } from 'helpers';
import _forEach from 'lodash/forEach';
import _includes from 'lodash/includes';
import useError from './useError';

const useChecksum = () => {
  const { setError } = useError();
  const error = (err) => setError(err);

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      getHeightAndWidthFromDataUrl(reader.result).then(dimensions => {
        resolve({
          localUrl: reader.result,
          width: dimensions.width,
          height: dimensions.height
        });
      });
    }
    reader.onerror = error => reject(error);
  });

  const processFiles = (files, callback, maxHeight, maxWidth, disableCompressing = false) => {
    _forEach(files, file => {
      if (_includes(file.type, 'image/') && !_includes(file.type, 'gif')) {
        if (disableCompressing) {
          const uploadedFile = new File([file], file.name, { type: file.type });

          toBase64(uploadedFile)
          .then(({ localUrl, width, height }) => {
            FileChecksum.create(uploadedFile, (err, checksum) => !!err
              ? error(err)
              : callback({ file: uploadedFile, checksum, localUrl, width, height })
            );
          }, error => setError(error));
        } else {
          getCompressedImage({ file, maxHeight, maxWidth, callback: compressedImage => {
            const uploadedFile = new File([compressedImage], file.name, { type: file.type });

            toBase64(uploadedFile)
            .then(({ localUrl, width, height }) => {
              FileChecksum.create(uploadedFile, (err, checksum) => !!err
                ? error(err)
                : callback({ file: uploadedFile, checksum, localUrl, width, height })
              );
            }, error => setError(error));
          }});
        }
      } else {
        FileChecksum.create(file, (err, checksum) => !!err
          ? error(err)
          : callback({ file, checksum, localUrl: null })
        );
      }
    });
  };
  const processFile = (file, callback) => {
    if (_includes(file.type, 'image/') && !_includes(file.type, 'gif')) {
      getCompressedImage({ file, callback: compressedImage => {
        const uploadedFile = new File([compressedImage], file.name, { type: file.type });

        toBase64(uploadedFile)
        .then(({ localUrl, width, height }) => {
          FileChecksum.create(uploadedFile, (err, checksum) => !!err
            ? error(err)
            : callback({ file: uploadedFile, checksum, localUrl, width, height })
          );
        }, error => setError(error));
      }});
    } else {
      FileChecksum.create(file, (err, checksum) => !!err
        ? error(err)
        : callback({ file, checksum, localUrl: null })
      );
    }
  };
  const processFileAsync = file => new Promise((resolve, reject) => {
    FileChecksum.create(file, (err, checksum) => {
      if (!!err) {
        reject(err);
      } else {
        resolve({ file, checksum, localUrl: null });
      }
    });
  });

  return { processFiles, processFile, processFileAsync };
};

export default useChecksum;