import { memo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import AddIcon from 'components/Common/Icons/AddIcon';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import { useNavigate } from 'react-router-dom';
// Local files
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import CreateInviteDialog from 'components/Dialogs/CreateInvite/CreateInvite';
import { getRepresentation } from 'helpers';
import { representationVariants } from 'helpers/constants';
import { DELETE_DEALSPACE_MEMBER } from 'helpers/confirmations';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useInvitations from 'hooks/useInvitations';
import useMembers from 'hooks/useMembers';
import useSuccess from 'hooks/useSuccess';

const Dealspace = () => {
  const navigate = useNavigate();
  const { openDeleteDialog } = useApp();
  const { setError } = useError();
  const { notifyInvite } = useInvitations();
  const { getDealspaceMembersList, clearLocalDealspaceMembersList } = useMembers();
  const { setSuccess } = useSuccess();
  const { dealspaceId, groupId, isOwner, members, totalAmount, needToLoadMore, newOffset } = useCustomSelector(state => ({
    dealspaceId: state.dealspaces.dealspace.id,
    groupId: state.dealspaces.dealspace.group.id,
    isOwner: state.dealspaces.dealspace.group.current_member?.role === 'owner',
    members: state.members.dealspaceList.data,
    totalAmount: state.members.dealspaceList.pagination.total_count,
    needToLoadMore: state.members.dealspaceList.pagination.total_count > state.members.dealspaceList.pagination.count + state.members.dealspaceList.pagination.offset,
    newOffset: state.members.dealspaceList.pagination.limit + state.members.dealspaceList.pagination.offset
  }));
  const [fetching, setFetching] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [inviteDialog, setInviteDialog] = useState({ open: false, groupId: '', prefilledRole: null, prefilledDealspace: null });

  const handleInviteClick = () => setInviteDialog({ open: true, groupId, prefilledRole: 'collaborator', prefilledDealspace: dealspaceId });
  const closeInviteDialog = (needToUpdate = false) => {
    if (needToUpdate) {
      setFetching(true);
      clearLocalDealspaceMembersList()
      .then(() =>
        getDealspaceMembersList({ dealspace_id: dealspaceId, offset: 0 })
        .catch(e => setError(e))
        .finally(() => setFetching(false))
      )
    }
    setInviteDialog({ open: false, groupId: '', prefilledRole: null, prefilledDealspace: null });
  };
  const handleActionClick = ({ id, status, permission, dealspaces }) => {
    if (status === 'invited') {
      const invitationId = _find(members, m => m.id === id)?.invitation?.id ?? null;

      if (invitationId) {
        setProcessing(true);
        notifyInvite(invitationId)
        .then(() => setSuccess('Invite successfully sended'))
        .catch(e => setError(e))
        .finally(() => setProcessing(false));
      }
    } else {
      if (permission === 'full') {
        navigate(`/groups/${groupId}/settings/users`);
      } else {
        openDeleteDialog({ type: DELETE_DEALSPACE_MEMBER, id, data: { dealspace_ids: _filter(dealspaces, d => d.id !== dealspaceId).map(d => d.id) } });
      }
    }
  };
  const handleLoadMore = () => {
    setFetching(true);
    getDealspaceMembersList({ dealspace_id: dealspaceId, offset: newOffset })
    .catch(e => setError(e))
    .finally(() => setFetching(false));
  };

  return (
    <Box mt='47px'>
      <CreateInviteDialog
        {...inviteDialog}
        isOwner={isOwner}
        onClose={closeInviteDialog}
      />
      <Box display='flex' justifyContent='space-between' alignItems='center' mb={.5}>
        <Typography fontWeight={500} fontSize='12px' lineHeight='15px' color='rgba(86, 95, 102, 1)'>
          Authorized Users ({totalAmount})
        </Typography>
        <ButtonBase onClick={handleInviteClick}>
          <AddIcon />
          <Typography fontWeight={600} fontSize='12px' lineHeight='15px' color='rgba(86, 95, 102, 1)'>
            Invite User
          </Typography>
        </ButtonBase>
      </Box>
      <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.7)' }} />
      {_map(members, ({ id, user, invitation, status, role, permission, dealspaces }) => {
        const firstName = user?.first_name ?? invitation?.user?.first_name ?? invitation?.email ?? '';
        const lastName = user?.last_name  ?? invitation?.user?.last_name ?? '';
        const photo = user?.photo ?? invitation?.user?.photo ?? null;
        const actionText = permission === 'full' ? <>Manage in <u>Group settings</u></> : (status === 'active' ? <u>Revoke access</u> : <>Invited<br /><u>Resend Invite</u></>)

        return (
          <Box key={id} display='flex' justifyContent='space-between' alignItems='center' mt='25px'>
            <Box display='flex' gap='17px'>
              <BaseAvatar
                firstName={firstName}
                lastName={lastName}
                src={photo ? getRepresentation(photo?.representations, representationVariants.TINY) : null}
                sx={{ width: '33px', height: '33px', fontSize: '16px' }}
              />
              <Box>
                <Typography fontWeight={500} fontSize='12px' lineHeight='15px' color='rgba(86, 95, 102, 1)'>
                  {firstName} {lastName}
                </Typography>
                <Typography fontWeight={500} fontSize='10px' lineHeight='21px' color='rgba(159, 162, 172, 1)'>
                  {role}
                </Typography>
              </Box>
            </Box>
            {role !== 'owner' &&
              <ButtonBase onClick={() => handleActionClick({ id, status, permission, dealspaces })} disabled={processing}>
                <Typography fontWeight={500} fontSize='10px' lineHeight='14px' color='rgba(0, 0, 0, 0.7)'>
                  {actionText}
                </Typography>
              </ButtonBase>
            }
          </Box>
        );
      })}
      {needToLoadMore &&
        <Box display='flex' justifyContent='center'>
          <ButtonBase onClick={handleLoadMore} disabled={fetching}>
            <AddIcon />
            <Typography fontWeight={600} fontSize='12px' lineHeight='15px' color='rgba(86, 95, 102, 1)'>
              Load More
            </Typography>
          </ButtonBase>
        </Box>
      }
    </Box>
  );
};

export default memo(Dealspace);