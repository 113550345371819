import styled from '@mui/material/styles/styled';
import ButtonBase from '@mui/material/ButtonBase';

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  height: 32,
  width: 32,
  color: theme.palette.primary.main,
  backgroundColor: 'rgba(255,255,255,.85)',
  borderRadius: '4px',
  bottom: 24,
  right: 24
}));

export { StyledButton };