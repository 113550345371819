import { memo } from 'react';
import PropTypes from 'prop-types';
// Local files
import { Root, BackButton } from './Header.styled';
import DealspaceContent from './DealspaceContent/DealspaceContent';
import GroupContent from './GroupContent/GroupContent';
import UserContent from './UserContent/UserContent';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import BackIcon from 'components/Common/Icons/BackIcon';
import useChatsLevel from 'hooks/useChatsLevel';

const Header = ({ onBack = null }) => {
  const { isDealspaceLevel, isGroupLevel, isUserLevel } = useChatsLevel();

  if (isDealspaceLevel) {
    return (
      <Root>
        {onBack && <BackButton onClick={onBack}><BackIcon /></BackButton>}
        <ErrorWrapper children={<DealspaceContent />} /> 
      </Root>
    );
  }
  if (isGroupLevel) {
    return (
      <Root>
        {onBack && <BackButton onClick={onBack}><BackIcon /></BackButton>}
        <ErrorWrapper children={<GroupContent />} /> 
      </Root>
    );
  }
  if (isUserLevel) {
    return (
      <Root>
        {onBack && <BackButton onClick={onBack}><BackIcon /></BackButton>}
        <ErrorWrapper children={<UserContent />} /> 
      </Root>
    );
  }

  return null;
};

Header.propTypes = {
  onBack: PropTypes.func
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Header {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);