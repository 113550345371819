import { memo } from 'react';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _startCase from 'lodash/startCase';
// Local files
import { Container, Title, Text } from './Focus.styled';
import { primaryActivities } from 'helpers/constants';

const Focus = ({ activeUser }) => {
  return (
    <div>
      { !!activeUser.primary_activities.length && <Container>
        <Title>Primary Activities</Title>
        <Text>{_map(_filter(primaryActivities, ({ value }) => !!_find(activeUser.primary_activities, (pa) => pa === value)), ({ label }) => label).join(', ')}</Text>
      </Container> }
      { !!activeUser.risk_profiles.length && <Container>
        <Title>Risk Profile</Title>
        <Text>{_map(activeUser.risk_profiles, pa => _startCase(pa)).join(', ')}</Text>
      </Container> }
      { !!activeUser.asset_classes.length && <Container>
        <Title>Asset Class Focus</Title>
        <Text>{_map(activeUser.asset_classes, pa => _startCase(pa)).join(', ')}</Text>
      </Container> }
    </div>
  );
};

export default memo(Focus);