import { memo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';

const ShareSettingsMembers = ({ value, onChange }) => {
  const members = useCustomSelector(state => state.members.dealspace.data);

  const renderOption = (p, o) => (
    <Box sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' }, gap: '8px', color: '#3F3F3F' }} {...p}>
      {o.id &&
        <Avatar
          alt={`${o.user.first_name} ${o.user.last_name}`}
          src={getRepresentation(o.user?.photo?.representations, representationVariants.TINY)}
          sx={{ width: 24, height: 24, textTransform: 'uppercase', boxShadow: 'inset 0px 0px 0px 1px white' }}
        >
          {o.user.first_name[0]}
        </Avatar>
      }
      <Typography variant='body2' sx={{ flexBasis: { xs: 'calc(100% - 24px - 8px)', md: 'calc(40% - 24px - 8px - 8px)'} }}>
        {o.user.first_name} {o.user.last_name}
      </Typography>
      <Typography variant='body2' sx={{ flexBasis: { xs: '100%', md: '60%' }, fontStyle: 'italic' }}>
        {o.role}
      </Typography>
    </Box>
  );
  const renderInput = p => (
    <TextField
      {...p}
      variant='standard'
      label='Reply-to'
      required
      InputProps={{ ...p.InputProps }}
    />
  );
  const renderTags = p => _map(p, s =>
    <Chip
      key={s.id}
      sx={{ backgroundColor: 'tertiary.light', m: '4px' }}
      avatar={
        <Avatar
          alt={s.user.first_name}
          src={getRepresentation(s.user?.photo?.representations, representationVariants.TINY)}
          sx={{ width: 24, height: 24, textTransform: 'uppercase', boxShadow: 'inset 0px 0px 0px 1px white' }}
        >
          {s.user.first_name[0]}
        </Avatar>
      }
      label={
        <Box component='span' sx={{ display: 'flex', gap: '8px', color: '#3F3F3F' }}>
          <Typography>
            {s.user.first_name} {s.user.last_name}
          </Typography>
        </Box>
      }
    />
  );

  return (
    <Autocomplete
      fullWidth
      multiple
      options={members}
      renderOption={renderOption}
      renderTags={renderTags}
      getOptionLabel={o => `${o.user.first_name} ${o.user.last_name}`}
      isOptionEqualToValue={(o, v) => o.user.id === v.user.id}
      value={value}
      onChange={(_, v) => onChange(v)}
      renderInput={renderInput}
    />
  );
};

ShareSettingsMembers.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ShareSettingsMembers {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);