import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getFeedFiltersAmount as getFeedFiltersAmountAction,
  getFeedSharedWithMeOffers as getFeedSharedWithMeOffersAction,
  getFeedArchiveOffers as getFeedArchiveOffersAction,
  getFeedMySharedOffers as getFeedMySharedOffersAction,
  getFeedMarketOffers as getFeedMarketOffersAction,
  getFeedRecipientOffers as getFeedRecipientOffersAction,
  getFeedOfferDetails as getFeedOfferDetailsAction,
  clearFeedOfferData as clearFeedOfferDataAction,
  clearFeedRecipientData as clearFeedRecipientDataAction,
  //////////////////
  getPublishedOffersForChats as getPublishedOffersForChatsAction,
  getDealspaceOffers as getDealspaceOffersAction,
  getDealspaceOffersForChats as getDealspaceOffersForChatsAction,
  getSharedOffers as getSharedOffersAction,
  getDraftSharedOffers as getDraftSharedOffersAction,
  getActiveSharedOffers as getActiveSharedOffersAction,
  getContactActiveOffers as getContactActiveOffersAction,
  getEndedSharedOffers as getEndedSharedOffersAction,
  getInContractSharedOffers as getInContractSharedOffersAction,
  getClosedSharedOffers as getClosedSharedOffersAction,
  getGroupOffersForChats as getGroupOffersForChatsAction,
  getBuilderDraft as getBuilderDraftAction,
  getBuilderDraftActualFilesCount as getBuilderDraftActualFilesCountAction,
  getFeedMapOffers as getFeedMapOffersAction,
  getActivityLogOffer as getActivityLogOfferAction,
  getOfferActualRecipientsCount as getOfferActualRecipientsCountAction,
  getOfferPreview as getOfferPreviewAction,
  createOffer as createOfferAction,
  updateOffer as updateOfferAction,
  deleteOffer as deleteOfferAction,
  publishOffer as publishOfferAction,
  activateOffer as activateOfferAction,
  contractOffer as contractOfferAction,
  endOffer as endOfferAction,
  closeOffer as closeOfferAction,
  disinterestOffer as disinterestOfferAction,
  saveOffer as saveOfferAction,
  duplicateOffer as duplicateOfferAction,
  visitOffer as visitOfferAction,
  exportOffer as exportOfferAction,
  intakeOffer as intakeOfferAction,
  reshareActiveOffers as reshareActiveOffersAction,
  submitOfferShareForm as submitOfferShareFormAction,
  setOfferUnreadCountConversations as setOfferUnreadCountConversationsAction,
  setLocalOfferCounts as setLocalOfferCountsAction,
  clearLocalSharedOffers as clearLocalSharedOffersAction,
  clearLocalContactActiveOffers as clearLocalContactActiveOffersAction,
  clearLocalDealspaceOffers as clearLocalDealspaceOffersAction,
  clearLocalOffersForChats as clearLocalOffersForChatsAction,
  clearLocalBuilderOffer as clearLocalBuilderOfferAction,
  clearLocalFeedMapOffers as clearLocalFeedMapOffersAction,
  clearLocalFeedOffer as clearLocalFeedOfferAction,
  clearLocalActivityLogOffer as clearLocalActivityLogOfferAction,
  unlockLocalOffer as unlockLocalOfferAction,
  setOfferLocalFields as setOfferLocalFieldsAction,
  setBuilderState as setBuilderStateAction
} from 'actions/offers';

const useOffers = () => {
  const dispatch = useDispatch();
  const getFeedFiltersAmount = useCallback(filter => dispatch(getFeedFiltersAmountAction(filter)), [dispatch]);
  const getFeedSharedWithMeOffers = useCallback(({ limit, offset, orders, query, filter }) => dispatch(getFeedSharedWithMeOffersAction({ limit, offset, orders, query, filter })), [dispatch]);
  const getFeedArchiveOffers = useCallback(({ limit, offset, orders, query, filter }) => dispatch(getFeedArchiveOffersAction({ limit, offset, orders, query, filter })), [dispatch]);
  const getFeedMySharedOffers = useCallback(({ limit, offset, orders, query, filter }) => dispatch(getFeedMySharedOffersAction({ limit, offset, orders, query, filter })), [dispatch]);
  const getFeedMarketOffers = useCallback(({ limit, offset, orders, query, filter }) => dispatch(getFeedMarketOffersAction({ limit, offset, orders, query, filter })), [dispatch]);
  const getFeedRecipientOffers = useCallback(({ limit, offset, orders }) => dispatch(getFeedRecipientOffersAction({ limit, offset, orders })), [dispatch]);
  const getFeedOfferDetails = useCallback(id => dispatch(getFeedOfferDetailsAction(id)), [dispatch]);
  const clearFeedOfferData = useCallback(({ vertical, horizontal }) => dispatch(clearFeedOfferDataAction(({ vertical, horizontal }))), [dispatch]);
  const clearFeedRecipientData = useCallback(() => dispatch(clearFeedRecipientDataAction()), [dispatch]);
  //////////////////
  const getPublishedOffersForChats = useCallback(offset => dispatch(getPublishedOffersForChatsAction(offset)), [dispatch]);
  const getDealspaceOffers = useCallback(({ dealspace_id, offset, type }) => dispatch(getDealspaceOffersAction({ dealspace_id, offset, type })), [dispatch]);
  const getDealspaceOffersForChats = useCallback(({ dealspace_id, offset, orders }) => dispatch(getDealspaceOffersForChatsAction({ dealspace_id, offset, orders })), [dispatch]);
  const getSharedOffers = useCallback(({ group_id, query, offset }) => dispatch(getSharedOffersAction({ group_id, query, offset })), [dispatch]);
  const getDraftSharedOffers = useCallback(({ group_id, query, offset }) => dispatch(getDraftSharedOffersAction({ group_id, query, offset })), [dispatch]);
  const getActiveSharedOffers = useCallback(({ group_id, query, offset }) => dispatch(getActiveSharedOffersAction({ group_id, query, offset })), [dispatch]);
  const getContactActiveOffers = useCallback(({ offset, recipient_email }) => dispatch(getContactActiveOffersAction({ offset, recipient_email })), [dispatch]);
  const getEndedSharedOffers = useCallback(({ group_id, query, offset }) => dispatch(getEndedSharedOffersAction({ group_id, query, offset })), [dispatch]);
  const getInContractSharedOffers = useCallback(({ group_id, query, offset }) => dispatch(getInContractSharedOffersAction({ group_id, query, offset })), [dispatch]);
  const getClosedSharedOffers = useCallback(({ group_id, query, offset }) => dispatch(getClosedSharedOffersAction({ group_id, query, offset })), [dispatch]);
  const getGroupOffersForChats = useCallback(({ group_id, offset, orders }) => dispatch(getGroupOffersForChatsAction({ group_id, offset, orders })), [dispatch]);
  const getBuilderDraft = useCallback(id => dispatch(getBuilderDraftAction(id)), [dispatch]);
  const getBuilderDraftActualFilesCount = useCallback(id => dispatch(getBuilderDraftActualFilesCountAction(id)), [dispatch]);
  const getFeedMapOffers = useCallback(({ latitude_gt, latitude_lt, longitude_gt, longitude_lt }) => dispatch(getFeedMapOffersAction({ latitude_gt, latitude_lt, longitude_gt, longitude_lt })), [dispatch]);
  const getActivityLogOffer = useCallback(id => dispatch(getActivityLogOfferAction(id)), [dispatch]);
  const getOfferActualRecipientsCount = useCallback(id => dispatch(getOfferActualRecipientsCountAction(id)), [dispatch]);
  const getOfferPreview = useCallback(id => dispatch(getOfferPreviewAction(id)), [dispatch]);
  const createOffer = useCallback(({ dealspace_id, offer }) => dispatch(createOfferAction({ dealspace_id, offer })), [dispatch]);
  const updateOffer = useCallback(offer => dispatch(updateOfferAction(offer)), [dispatch]);
  const deleteOffer = useCallback(({ id, source }) => dispatch(deleteOfferAction({ id, source })), [dispatch]);
  const publishOffer = useCallback(({ id, message, notify, recipients_type, subject }) => dispatch(publishOfferAction({ id, message, notify, recipients_type, subject })), [dispatch]);
  const activateOffer = useCallback(id => dispatch(activateOfferAction(id)), [dispatch]);
  const contractOffer = useCallback(id => dispatch(contractOfferAction(id)), [dispatch]);
  const endOffer = useCallback(id => dispatch(endOfferAction(id)), [dispatch]);
  const closeOffer = useCallback(id => dispatch(closeOfferAction(id)), [dispatch]);
  const disinterestOffer = useCallback((id) => dispatch(disinterestOfferAction(id)), [dispatch]);
  const saveOffer = useCallback(({ offer_id, dealspace_id, group_id, locationType }) => dispatch(saveOfferAction({ offer_id, dealspace_id, group_id, locationType })), [dispatch]);
  const duplicateOffer = useCallback(({ id, type }) => dispatch(duplicateOfferAction({ id, type })), [dispatch]);
  const visitOffer = useCallback(offer_id => dispatch(visitOfferAction(offer_id)), [dispatch]);
  const exportOffer = useCallback(offer_id => dispatch(exportOfferAction(offer_id)), [dispatch]);
  const intakeOffer = useCallback(({ owner_id, message, user, properties, offer, images, documents }) => dispatch(intakeOfferAction({ owner_id, message, user, properties, offer, images, documents })), [dispatch]);
  const reshareActiveOffers = useCallback(email => dispatch(reshareActiveOffersAction(email)), [dispatch]);
  const submitOfferShareForm = useCallback(({ id, email, first_name, last_name, mobile_phone }) => dispatch(submitOfferShareFormAction({ id, email, first_name, last_name, mobile_phone })), [dispatch]);
  const setOfferUnreadCountConversations = useCallback(({ unread_conversations_count, offer_id }) => dispatch(setOfferUnreadCountConversationsAction({ unread_conversations_count, offer_id })), [dispatch]);
  const setLocalOfferCounts = useCallback(({ offer_id, recipients_count, unlocked_recipients_count, approval_requested_recipients_count }) => dispatch(setLocalOfferCountsAction({ offer_id, recipients_count, unlocked_recipients_count, approval_requested_recipients_count })), [dispatch]);
  const clearLocalSharedOffers = useCallback(type => dispatch(clearLocalSharedOffersAction(type)), [dispatch]);
  const clearLocalContactActiveOffers = useCallback(() => dispatch(clearLocalContactActiveOffersAction()), [dispatch]);
  const clearLocalDealspaceOffers = useCallback(() => dispatch(clearLocalDealspaceOffersAction()), [dispatch]);
  const clearLocalOffersForChats = useCallback(() => dispatch(clearLocalOffersForChatsAction()), [dispatch]);
  const clearLocalBuilderOffer = useCallback(() => dispatch(clearLocalBuilderOfferAction()), [dispatch]);
  const clearLocalFeedMapOffers = useCallback(() => dispatch(clearLocalFeedMapOffersAction()), [dispatch]);
  const clearLocalFeedOffer = useCallback(() => dispatch(clearLocalFeedOfferAction()), [dispatch]);
  const clearLocalActivityLogOffer = useCallback(() => dispatch(clearLocalActivityLogOfferAction()), [dispatch]);
  const unlockLocalOffer = useCallback(id => dispatch(unlockLocalOfferAction(id)), [dispatch]);
  const setOfferLocalFields = useCallback(fields => dispatch(setOfferLocalFieldsAction(fields)), [dispatch]);
  const setBuilderState = useCallback(state => dispatch(setBuilderStateAction(state)), [dispatch]);

  return {
    getFeedFiltersAmount,
    getFeedMySharedOffers,
    getFeedArchiveOffers,
    getFeedSharedWithMeOffers,
    getFeedMarketOffers,
    getFeedRecipientOffers,
    getFeedOfferDetails,
    clearFeedOfferData,
    clearFeedRecipientData,
    //////////////////
    getBuilderDraft,
    getBuilderDraftActualFilesCount,
    getFeedMapOffers,
    getOfferActualRecipientsCount,
    getDealspaceOffers,
    getPublishedOffersForChats,
    getDealspaceOffersForChats,
    getSharedOffers,
    getDraftSharedOffers,
    getActiveSharedOffers,
    getContactActiveOffers,
    getEndedSharedOffers,
    getInContractSharedOffers,
    getClosedSharedOffers,
    getGroupOffersForChats,
    getActivityLogOffer,
    getOfferPreview,
    createOffer,
    updateOffer,
    deleteOffer,
    publishOffer,
    activateOffer,
    contractOffer,
    endOffer,
    closeOffer,
    disinterestOffer,
    saveOffer,
    duplicateOffer,
    visitOffer,
    exportOffer,
    intakeOffer,
    reshareActiveOffers,
    submitOfferShareForm,
    clearLocalBuilderOffer,
    clearLocalFeedOffer,
    setOfferUnreadCountConversations,
    setLocalOfferCounts,
    clearLocalSharedOffers,
    clearLocalContactActiveOffers,
    clearLocalDealspaceOffers,
    clearLocalOffersForChats,
    clearLocalFeedMapOffers,
    clearLocalActivityLogOffer,
    unlockLocalOffer,
    setOfferLocalFields,
    setBuilderState
  };
};

export default useOffers;