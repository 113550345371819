import { forwardRef, memo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
// Local files
import CloseIcon from 'components/Common/Icons/CloseIcon';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const BaseDialog = ({
  open, onClose, hideCloseButton = false, hasTransition = true, title, content, actions, children, otherPaperStyle, otherTitleStyle,
  otherContentStyle, otherActionsStyle, otherCloseButtonStyle, customCloseButton = null, ...otherProps
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={!!hasTransition ? Transition : undefined}
      PaperProps={{
        sx: {
          borderRadius: '4px',
          boxShadow: 'none',
          ...otherPaperStyle
        }
      }}
      {...otherProps}
    >
      {!hideCloseButton && (
        customCloseButton ? customCloseButton :
          <Button
            onClick={onClose}
            disableRipple
            sx={{
              position: 'absolute',
              top: 2,
              right: 2,
              minWidth: 36,
              minHeight: 36,
              padding: 0,
              backgroundColor: 'transparent !important',
              zIndex: 1,
              ...otherCloseButtonStyle
            }}
          >
            <CloseIcon />
          </Button>
        )
      }
      {!!title && (
        <DialogTitle
          sx={{
            pt: 4,
            pb: 2,
            position: 'relative',
            fontSize: '1rem',
            fontWeight: 600,
            color: '#000',
            textAlign: 'center',
            ...otherTitleStyle
          }}
        >
          {title}
        </DialogTitle>
      )}
      {!!content && (
        <DialogContent sx={{ p: 0, ...otherContentStyle }}>
          {content}
        </DialogContent>
      )}
      {!!children && children}
      {!!actions && (<DialogActions sx={{ pt: 2, pb: 4, justifyContent: 'center', ...otherActionsStyle }}>{actions}</DialogActions>)}
    </Dialog>
  );
};

BaseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hasTransition: PropTypes.bool,
  title: PropTypes.node,
  content: PropTypes.node,
  actions: PropTypes.node,
  children: PropTypes.node,
  otherPaperStyle: PropTypes.object,
  otherTitleStyle: PropTypes.object,
  otherContentStyle: PropTypes.object,
  otherActionsStyle: PropTypes.object,
  otherCloseButtonStyle: PropTypes.object,
  customCloseButton: PropTypes.node
};

export default memo(BaseDialog);