import SvgIcon from '@mui/material/SvgIcon';

export default function PrintIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M20.2682 10.0949C20.2674 9.42204 19.9998 8.77693 19.5241 8.3012C19.0484 7.82548 18.4033 7.55792 17.7305 7.55716H16.9771V3.40061C16.9771 3.17944 16.7977 3 16.5765 3H7.69167C7.47049 3 7.29105 3.17944 7.29105 3.40061V7.55716H6.53771C5.86488 7.5579 5.21977 7.82548 4.74404 8.3012C4.26832 8.77693 4.00076 9.42199 4 10.0949V14.0164C4.00075 14.6891 4.26832 15.3342 4.74404 15.8101C5.21975 16.2858 5.86483 16.5534 6.53771 16.5541H7.28824V19.5994C7.28824 19.8206 7.46768 20 7.68904 20H16.5763C16.7974 20 16.9769 19.8206 16.9769 19.5994V16.5541H17.7276C18.4003 16.5534 19.0453 16.2858 19.5213 15.8101C19.997 15.3342 20.2645 14.6891 20.2653 14.0164L20.2682 10.0949ZM8.09242 3.80164H16.1754V7.55735H8.09242V3.80164ZM16.1754 19.1986H8.09242V13.1349H16.1754V19.1986ZM19.4638 14.0164V14.0166C19.4631 14.4768 19.2799 14.918 18.9544 15.2434C18.629 15.5689 18.1878 15.7521 17.7276 15.7528H16.9768V12.7343C16.9768 12.5131 16.7974 12.3337 16.5762 12.3337H7.69138C7.4702 12.3337 7.29077 12.5131 7.29077 12.7343V15.7528H6.53742C6.07718 15.7521 5.63596 15.5689 5.3104 15.2434C4.98488 14.9181 4.80171 14.4768 4.80114 14.0166V10.095C4.8017 9.63476 4.98487 9.19354 5.3104 8.86798C5.63592 8.54265 6.07715 8.35949 6.53742 8.35873H17.73C18.1903 8.35947 18.6315 8.54264 18.9571 8.86798C19.2826 9.19351 19.4657 9.63473 19.4663 10.095L19.4638 14.0164Z" />
      <path fill="currentColor" d="M16.3611 9.80908H16.0726C15.8514 9.80908 15.672 9.98852 15.672 10.2099C15.672 10.4311 15.8514 10.6105 16.0726 10.6105H16.3611C16.5824 10.6105 16.7619 10.4311 16.7619 10.2099C16.7619 9.98852 16.5824 9.80908 16.3611 9.80908Z" />
    </SvgIcon>
  );
}

