import { getThunkActionCreator } from 'redux-thunk-routine';
import { 
  setMapInstanceRoutine,
  setMapConfigsRoutine,
  centerMapRoutine,
  selectPolygonRoutine,
  clearMapDataRoutine,
  clearPolygonsRoutine
 } from '.';

export const setMapInstance = getThunkActionCreator(setMapInstanceRoutine, async instance => ({ instance }));
export const setMapConfigs = getThunkActionCreator(setMapConfigsRoutine, async ({ center, zoom, listOffset, widgetOffset, detailsOffset, mapWidth }) => ({ center, zoom, listOffset, widgetOffset, detailsOffset, mapWidth }));
export const centerMap = getThunkActionCreator(centerMapRoutine, async ({ minLatitude, minLongitude, maxLatitude, maxLongitude }) => ({ minLatitude, minLongitude, maxLatitude, maxLongitude }));
export const selectPolygon = getThunkActionCreator(selectPolygonRoutine, async id => ({ id }));
export const clearMapData = getThunkActionCreator(clearMapDataRoutine, async () => {});
export const clearPolygons = getThunkActionCreator(clearPolygonsRoutine, async () => {});