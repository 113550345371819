import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  createNylasUser as createNylasUserAction,
  getNylasUser as getNylasUserAction,
  deleteNylasUser as deleteNylasUserAction
} from 'actions/nylasUsers';

const useNylasUsers = () => {
  const dispatch = useDispatch();
  const createNylasUser = useCallback(nylas_code => dispatch(createNylasUserAction(nylas_code)), [dispatch]);
  const getNylasUser = useCallback(email => dispatch(getNylasUserAction(email)), [dispatch]);
  const deleteNylasUser = useCallback(email => dispatch(deleteNylasUserAction(email)), [dispatch]);

  return { createNylasUser, getNylasUser, deleteNylasUser };
};

export default useNylasUsers;