import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export const StyledHead = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '8px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    gap: '0px',
    '& > button': {
      marginBottom: '24px'
    }
  }
}));
export const StyledButton = styled((props) => (
  <Button color='primary' variant='contained' {...props} />
))(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));
