import styled from '@mui/material/styles/styled';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const Root = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px'
});

export const StatusButton = styled(ButtonBase)({
  gap: '6px',
  borderRadius: '4px',
  padding: '4px',
  marginLeft: '-4px'
});

export const Title = styled((props) => (
  <Typography variant='subtitle3' component='p'  {...props} />
))({
  color: 'rgba(0,0,0,.9)',
  padding: '4px 0'
});

export const Caption = styled((props) => (
  <Typography variant='small' component='p'  {...props} />
))({
  minHeight: 15,
  color: 'rgba(0,0,0,.4)'
});