import styled from '@mui/material/styles/styled';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';

export const Head = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '12px 8px',
  overflow: 'hidden',
  marginBottom: 16,
  [theme.breakpoints.up('md')]: {
    marginBottom: 0
  }
}));

export const StyledBreadcrumbs = styled(Breadcrumbs)({
  overflow: 'hidden',
  '& .MuiBreadcrumbs-separator': {
    color: 'rgba(0,0,0,.4)',
    marginLeft: '4px',
    marginRight: '4px'
  },
  '& .MuiBreadcrumbs-ol': {
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
  },
  '& .MuiBreadcrumbs-li:last-of-type': {
    overflow: 'hidden',
  }
});
export const HeadControls = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '2px',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    width: 'auto',
    gap: '16px'
  }
}));
export const StyledTab = styled(Tab)(({ theme }) => ({
  minWidth: 'unset',
  minHeight: '40px',
  padding: '8px 0',
  '&:not(:last-of-type)': {
    marginRight: 12
  },
  [theme.breakpoints.up('md')]: {
    padding: '4px 0',
    '&:not(:last-of-type)': {
      marginRight: 40
    }
  }
}))
