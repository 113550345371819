import styled from '@mui/material/styles/styled';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Root = styled(Container)(({ theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '32px 0 0',
  [theme.breakpoints.up('md')]: {
    gap: '40px',
    padding: '60px 0 0',
  }
}));
const Text = styled(props => (
  <Typography variant='caption' component={'p'} {...props} />
))({});

export { Root, Text };