import { memo, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
// Local files
import { Root } from './Input.styled';
import Uploader from 'components/Blobs/Uploader/Uploader';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import { ReactComponent as AttachIcon } from 'assets/icons/attach.svg';
import { checkEmptyString, getRandomString } from 'helpers';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useMessages from 'hooks/useMessages';
import useImages from 'hooks/useImages';
import useCustomSelector from 'hooks/useCustomSelector';
import useConversations from 'hooks/useConversations';
import usePrevious from 'hooks/usePrevious';

const Input = () => {
  const { offer_id } = useParams();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { createMessage, createLocalMessage, setLocalMessageError } = useMessages();
  const { getConversation, createConversation, createOfferConversation, setLocalUserIds } = useConversations();
  const { image, uploading, uploadImages, removeImage, cancel } = useImages();
  const { my_id, first_name, last_name, isMyShared, conversation_id, user_ids, data } = useCustomSelector(state => ({
    my_id: state.profile.user.id,
    first_name: state.profile.user.first_name,
    last_name: state.profile.user.last_name,
    isMyShared: !!_find(state.senders.all.data, ({ user }) => user?.id === state.profile.user.id) || !!state.recipients.activityLog.data?.length,
    conversation_id: state.conversations.conversation.id,
    user_ids: state.conversations.user_ids,
    data: state.conversations.user.general.data
  }));
  const [addFiles, setAddFiles] = useState(false);
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const prevConversationId = usePrevious(conversation_id);

  const clearFields = useCallback(() => {
    setText('');
    setAddFiles(false);
  }, []);

  useEffect(() => {
    !!conversation_id && conversation_id !== prevConversationId && clearFields();
  }, [conversation_id, prevConversationId, clearFields]);

  const handleAddImage = fl => {
    if (!!fl) {
      const files = Array.from(fl);

      uploadImages(_map(files, f => ({ f })));
      setAddFiles(false);
    }
  };
  const processMessage = (message, conversation_id) => {
    const local_id = getRandomString(20);

    createLocalMessage({ ...message, id: local_id, time: 'pending', user: { id: my_id, first_name, last_name } })
    .then(() =>
      createMessage({ message, conversation_id, local_id })
      .then(() => setLocalUserIds(null).then(() => document?.getElementById(`chat-${conversation_id}`)?.scrollIntoView(false))
      .catch((error) => setLocalMessageError(local_id).then(() => setError(error)))
      .finally(() => setLoading(false))
    )
    );
  };
  const handleKeyDown = ({ ctrlKey, metaKey, key }) => {
    ctrlKey && key === 'Enter' && handleSendClick();
    metaKey && key === 'Enter' && handleSendClick();
  };
  const createChat = () => (!!state?.offer_id || !!offer_id)? 
    createOfferConversation({ offer_id: state?.offer_id ||  offer_id, conversation: { user_ids, private: false }, isMyShared }) : 
    createConversation({ user_ids, private: user_ids.length === 1 });
  const handleSendClick = () => {    
    if (checkEmptyString(text) || !!image) {
      const message = {
        ...(checkEmptyString(text) && { text }),
        ...(!!image && { attachment: image.id })
      };
      setLoading(true);
      setText('');
      removeImage();

      if (!!user_ids?.length) {
        const conversation = _find(data, ({ users }) => _isEqual(_map(_filter(users, u => u.id !== my_id), (user) => user.id).sort(), user_ids?.sort()))
        
        if (!!conversation && !!!state?.offer_id) {
          getConversation(conversation.id)
          .then(() => setSuccess('You already have the chat with this user/s'))
          .catch((e) => setError(e))
          .finally(() => setLoading(false))
        } else {
          createChat()
          .then(({ payload: { response: { data: { conversation: { id } } } } }) => { 
            !!!offer_id && navigate(pathname, { state: _pick(state, ['previousLocation']) });
            processMessage(message, id);
          })
          .catch((e) => setError(e))
        }
      } else {
        processMessage(message, conversation_id);
      }
    }
  };

  return (
    <>
      <Root variant='glass'>
        <Snackbar
          open={uploading}
          message="Loading..."
          anchorOrigin={{  vertical: 'bottom', horizontal: 'center' }}
          action={<Button color="error" size="small" onClick={() => cancel()}>Undo</Button>}
        />
        <IconButton disabled={loading || uploading} onClick={() => setAddFiles(true)}><AttachIcon /></IconButton>
        <TextField
          fullWidth
          variant='standard'
          placeholder='Type a message'
          InputProps={{ disableUnderline: true }}
          value={text}
          multiline
          maxRows={3}
          onChange={({ target: { value } }) => setText(value)}
          onKeyDown={handleKeyDown}
        />
        <IconButton disabled={loading || uploading} onClick={handleSendClick}><SendIcon /></IconButton>
      </Root>
      <Uploader
        directly
        forChat
        label='Add images'
        id='imageFileUploader'
        fullWidth
        accept='image/*'
        formattedValue={image}
        disabled={uploading}
        onChange={handleAddImage}
        addFiles={addFiles}
        setAddFiles={(value) => setAddFiles(value)}
        onDelete={removeImage}
      />
    </>
  );
};

export default memo(Input);