import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import { clearStore as clearStoreAction } from 'actions/store';

const useStore = () => {
  const dispatch = useDispatch();
  const clearStore = useCallback(() => dispatch(clearStoreAction()), [dispatch]);

  return { clearStore };
};

export default useStore;