import { memo } from 'react';
import Typography from '@mui/material/Typography';
// Local files
import { ReactComponent as LocationIcon } from 'assets/icons/location-large.svg';
import AddIcon from 'components/Common/Icons/AddIcon';
import { Root, Content } from './Add.styled';

const Add = props => {
  return (
    <Root {...props}>
      <LocationIcon />
      <Content >
        <AddIcon />
        <Typography variant='subtitle0'>Add Location</Typography>
      </Content>
    </Root>
  );
};

export default memo(Add);