import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Root = styled(Box)({
  flexShrink: 0,
  width: '100%',
  height: '122px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: '4px',
  border: '1px solid #F1F3F6',
  boxShadow: '1px 1px 6px 0px rgba(0, 0, 0, 0.1)',
  padding: '16px',
  cursor: 'pointer',
  '&:hover': {
    borderColor: '#A4A4A4'
  }
});
export const ChipsContainer = styled(Box)({
  display: 'flex', 
  width: '100%', 
  alignItems: 'center', 
  fontSize: '12px', 
  gap: '6px', 
  overflow: 'hidden'
});