import { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
// Local files
import { LinkedinButton } from './LinkedIn.styled';
import { ReactComponent as LinkedinLogo } from 'assets/icons/linkedin-logo.svg';

const LinkedIn = ({ onClick }) => {
  return (
    <>
      <Box sx={{ width: '100%', mb: '12px' }}>
        <Divider>
          <Typography variant='caption'>or</Typography>
        </Divider>
      </Box>
      <LinkedinButton onClick={onClick}>
        <LinkedinLogo />Continue with LinkedIn
      </LinkedinButton>
    </>
  );
};

LinkedIn.propTypes = {
  onClick: PropTypes.func,isFinite
};

export default memo(LinkedIn);