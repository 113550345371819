import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useError from 'hooks/useError';
import useLists from 'hooks/useLists';
import useSuccess from 'hooks/useSuccess';

const Delete = ({ id, open, onClose }) => {
  const { setError } = useError();
  const { deleteList } = useLists();
  const { setSuccess } = useSuccess();
  const [processing, setProcessing] = useState(false);
  
  const handleClose = () => onClose();
  const handleConfirmClick = () => {
    setProcessing(false);
    deleteList(id)
    .then(() => setSuccess('List successfully removed').then(handleClose))
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };

  return (
    <BaseDialog
      open={open}
      onClose={() => processing ? {} : handleClose()}
      otherPaperStyle={{ width: '100%', maxWidth: 386 }}
      title=''
      actions={
        <>
          <Button
            disabled={processing}
            variant='text'
            onClick={handleClose}
            sx={{ fontSize: '12px', mr: '24px', py: '8px', px: '28px' }}
          >
            Cancel
          </Button>
          <Button
            disabled={processing}
            variant='contained'
            color='error'
            onClick={handleConfirmClick}
            sx={{ fontSize: '12px', px: '28px' }}
          >
            Delete
          </Button>
        </>
      }
    >
      <Box p={4}>
        <Typography fontWeight={600} fontSize='16px' lineHeight='20px' align='center'>
          Are you sure you would like to delete this list label? List contacts will not be deleted.
        </Typography>
      </Box>
    </BaseDialog>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Delete {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);