import { memo, useState, useEffect, useCallback, useMemo } from 'react';
import _filter from 'lodash/filter';
// Local Files
import DealspaceAvatar from 'components/Conversations/Common/Avatar/Avatar';
import { Root, Content, Badge, Text } from './Channel.styled';
import useCustomSelector from 'hooks/useCustomSelector';
import useConversations from 'hooks/useConversations';
import useMessages from 'hooks/useMessages';
import useError from 'hooks/useError';

const Channel = ({ conversation, title, cover, highlighted = false, sx }) => {
  const { setLocalConversation, setLocalUserIds } = useConversations();
  const { getMessages, clearLocalMessages } = useMessages();
  const { setError } = useError();
  const { conversation: { id: conversation_id }, user_ids } = useCustomSelector(state => state.conversations);
  const dealspace = useCustomSelector(state => state.dealspaces.dealspace);
  const { data: messages } = useCustomSelector(state => state.messages);
  const { user: { id: my_id } } = useCustomSelector(state => state.profile);
  const [loading, setLoading] = useState(false);
  const unreadCount = useMemo(() => conversation_id === dealspace?.conversation?.id && 
    _filter(messages, ({ id, read, user }) => id !== 'divider' && user?.id !== my_id && !read)?.length, [my_id, dealspace?.conversation?.id, conversation_id, messages]
  );

  const handleSelectChanel = () => conversation_id !== conversation?.id && getChanel(conversation);
  const fetchMessages = useCallback((conversation_id, my_id) => {
    clearLocalMessages()
    .then(() => 
      getMessages({ conversation_id, center_on_unread_message: true, my_id })
      .catch((e) => setError(e))
      .finally(() => setLoading(false))
    )
  }, [getMessages, clearLocalMessages, setError]);
  const getChanel = (conversation) => {
    setLoading(true);
    setLocalUserIds(null);
    clearLocalMessages()
    .then(() => setLocalConversation(conversation))
  };

  useEffect(() => {
    !!!user_ids && conversation_id === conversation?.id && fetchMessages(conversation_id, my_id);
  }, [conversation_id, conversation?.id, my_id, user_ids, fetchMessages]);

  return (
    <Root id={`chat-${conversation?.id}`} selected={conversation_id === conversation?.id} sx={sx} disabled={loading} onClick={handleSelectChanel}>
      <DealspaceAvatar src={cover} title={title} />
      <Content>
        <Text highlighted={highlighted}>{title}</Text>
        { !!unreadCount && <Badge variant="standard" badgeContent={unreadCount} color='success'/>}
      </Content>
    </Root>
  );
};

export default memo(Channel);