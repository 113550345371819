import { forwardRef, memo } from 'react';
import { NumericFormat } from 'react-number-format';
// Local files
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const NumericTextField = forwardRef(function NumericTextField(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => onChange({ value: values.floatValue, formattedValue: values.value })}
      thousandSeparator=','
      valueIsNumericString
    />
  );
});

const WrappedComponent = forwardRef((props, ref) => {
  return (
    <ErrorWrapper>
      <NumericTextField {...props} ref={ref} />
    </ErrorWrapper>
  );
});

export default memo(WrappedComponent);