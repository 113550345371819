import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

export const Root = styled(Paper)({
  width: '100%',
});

export const Title = styled((props) => (
  <Typography variant='body2' {...props} />
))({
  color: 'rgba(0,0,0,.7)',
  fontWeight: 500,
  marginBottom: '12px',
  '& > span': {
    color: 'rgba(0,0,0,.3)'
  }
});

export const Statuses = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: '16px',
});

export const StatusWrapper = styled('div',
  { shouldForwardProp: (prop) => prop !== 'selected' }
)(({ selected }) => ({
  fontSize: '0.75rem',
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
  borderRadius: 25,
  border: '1px solid #DEDEDE',
  padding: '3px 14px 3px 8px',
  cursor: 'pointer',
  ...(selected && {
    color: '#fff',
    backgroundColor: '#2E2E2E',
  })
}));

export const OffersContainer = styled(Container)(() => ({
  maxWidth: '1015px', 
  display: 'flex', 
  flexDirection: 'column', 
  gap: '24px',
  padding: '40px 24px 24px'
}));

export const Item = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%'
});

export const Image = styled(Box)({
  width: 54,
  height: 34,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center'
});