import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// Local files
import {
  getUserConversations as getUserConversationsAction,
  getGroupConversations as getGroupConversationsAction,
  getDealspaceConversations as getDealspaceConversationsAction,
  getOfferConversations as getOfferConversationsAction,
  getConversation as getConversationAction,
  createConversation as createConversationAction,
  createOfferConversation as createOfferConversationAction,
  updateConversation as updateConversationAction,
  deleteConversation as deleteConversationAction,
  addUserToConversation as addUserToConversationAction,
  deleteUserFromConversation as deleteUserFromConversationAction,
  setLocalUserIds as setLocalUserIdsAction,
  setLocalConversation as setLocalConversationAction,
  clearLocalUserConversations as clearLocalUserConversationsAction,
  clearLocalConversations as clearLocalConversationsAction,
  clearLocalActiveConversation as clearLocalActiveConversationAction
} from 'actions/conversations';
import { chatLevels } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useDealspaces from 'hooks/useDealspaces';
import useOffers from 'hooks/useOffers';
import useMessages from 'hooks/useMessages';

const useConversations = () => {
  const navigate = useNavigate();
  const { offer_id } = useParams();
  const previousLocation = useLocation();
  const { setChatsFilter } = useApp();
  const { clearLocalDealspacesForChats } = useDealspaces();
  const { clearLocalOffersForChats } = useOffers();
  const { clearLocalMessages } = useMessages();
  const { my_id, filter, groupId } = useCustomSelector(state => {
    return {
      filter: state.app.chats.filter,
      my_id: state.profile.user.id,
      groupId: state.offers.dealflow.details.offer?.dealspace?.group?.id || state.offers.activityLog.dealspace.group.id
    };
  });
  const dispatch = useDispatch();
  const getUserConversations = useCallback(({ offset, general, feed, query }) => dispatch(getUserConversationsAction({ offset, general, feed, query })), [dispatch]);
  const getGroupConversations = useCallback(({ offset, group_id, query }) => dispatch(getGroupConversationsAction({ offset, group_id, query })), [dispatch]);
  const getDealspaceConversations = useCallback(({ offset, dealspace_id, query }) => dispatch(getDealspaceConversationsAction({ offset, dealspace_id, query })), [dispatch]);
  const getOfferConversations = useCallback(({ offset, offer_id, feed, offer, query }) => dispatch(getOfferConversationsAction({ offset, offer_id, feed, offer, query })), [dispatch]);
  const getConversation = useCallback(id => dispatch(getConversationAction(id)), [dispatch]);
  const createConversation = useCallback((conversation) => dispatch(createConversationAction(conversation)), [dispatch]);
  const createOfferConversation = useCallback(({ offer_id, conversation, isMyShared }) => dispatch(createOfferConversationAction({ offer_id, conversation, isMyShared })), [dispatch]);
  const updateConversation = useCallback(conversation => dispatch(updateConversationAction(conversation)), [dispatch]);
  const deleteConversation = useCallback(id => dispatch(deleteConversationAction(id)), [dispatch]);
  const addUserToConversation = useCallback(({ conversation_id, user }) => dispatch(addUserToConversationAction({ conversation_id, user })), [dispatch]);
  const deleteUserFromConversation = useCallback(({ conversation_id, user_id }) => dispatch(deleteUserFromConversationAction({ conversation_id, user_id })), [dispatch]);
  const setLocalUserIds = useCallback(user_ids => dispatch(setLocalUserIdsAction(user_ids)), [dispatch]);
  const setLocalConversation = useCallback(conversation => dispatch(setLocalConversationAction(conversation)), [dispatch]);
  const clearLocalUserConversations = useCallback(() => dispatch(clearLocalUserConversationsAction()), [dispatch]);
  const clearLocalConversations = useCallback(() => dispatch(clearLocalConversationsAction()), [dispatch]);
  const clearLocalActiveConversation = useCallback(() => dispatch(clearLocalActiveConversationAction()), [dispatch]);

  const checkConversation = useCallback((ids, conversation) => 
    !!conversation ? setLocalConversation(conversation) : setLocalUserIds(typeof ids === 'string' ? [ids] : ids)
  , [setLocalConversation, setLocalUserIds]);
  const openOrCreateConversation = useCallback((ids, conversation, isMyShared = null) => {
    checkConversation(ids, conversation)
    .then(() => {
      setChatsFilter({ filter })
      .then(() => 
        navigate(`/chats/${(isMyShared !== null && isMyShared) ? chatLevels.GROUP : chatLevels.USER}/${(isMyShared !== null && isMyShared) ? groupId : my_id}`,
        { state: { previousLocation, ...(!!!conversation && { offer_id, my_id }) } }
      ));
    })
  }, [filter, previousLocation, groupId, my_id, offer_id, checkConversation, setChatsFilter]); // eslint-disable-line react-hooks/exhaustive-deps
  const clearLocalChatsData = useCallback(() => {
    clearLocalUserConversations();
    clearLocalConversations();
    clearLocalDealspacesForChats();
    clearLocalOffersForChats();
    clearLocalMessages();
    clearLocalActiveConversation();
    setChatsFilter({ filter: { dealspace_id: '', offer_id: '' } });
    setLocalUserIds(null);
  }, [clearLocalUserConversations, clearLocalConversations, clearLocalActiveConversation, clearLocalDealspacesForChats, clearLocalOffersForChats, clearLocalMessages, setChatsFilter, setLocalUserIds]);

  return {
    getUserConversations,
    getGroupConversations,
    getDealspaceConversations,
    getOfferConversations,
    getConversation,
    createConversation,
    createOfferConversation,
    updateConversation,
    deleteConversation,
    addUserToConversation,
    deleteUserFromConversation,
    setLocalUserIds,
    setLocalConversation,
    clearLocalUserConversations,
    clearLocalConversations,
    clearLocalActiveConversation,
    openOrCreateConversation,
    clearLocalChatsData
  };
};

export default useConversations;