import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const Text = styled(props => (
  <Typography variant='caption' component='div' noWrap {...props} />
))({
  paddingLeft: 8
});

const StyledButton = styled(props => (
  <Button variant='blank' color='black' {...props} />
))({
  flexShrink: 0,
  width: 60,
  height: 60,
  borderRadius: '50%',
  '& svg': {
    height: 20,
    width: 'auto'
  }
});

export { Text, StyledButton };