import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

export const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  textTransform: 'capitalize',
  fontWeight: 600,
}));

export const Counter = styled('span')({
  fontWeight: 400,
});