import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getPropertyDocumentsRoutine,
  getPropertyDocumentsAmountRoutine,
  getDealspaceDocumentsRoutine,
  getFolderDocumentsRoutine,
  getBuilderPublicDocumentsRoutine,
  getBuilderPrivateLevel1DocumentsRoutine,
  getBuilderPrivateLevel2DocumentsRoutine,
  getOfferPublicDocumentsRoutine,
  getOfferPrivateLevel1DocumentsRoutine,
  getOfferPrivateLevel2DocumentsRoutine,
  getPreviewPublicDocumentsRoutine,
  getDocumentRoutine,
  createPropertyDocumentRoutine,
  createDealspaceDocumentRoutine,
  createOfferDocumentRoutine,
  updateDocumentRoutine,
  deleteDocumentRoutine,
  clearLocalPropertyDocumentsRoutine,
  clearLocalDealspaceDocumentsRoutine,
  clearLocalFolderDocumentsRoutine,
  clearLocalOfferDocumentsRoutine,
  clearLocalActiveDocumentRoutine
} from 'actions';

const attributes = ['id', 'user', 'locked', 'approval_required', { document: ['id'] }, { file: ['id', 'filename', 'representations', 'content_type', 'byte_size'] }, 'name', 'created_at'];
const fullAttributes = ['id', { file: ['id', 'url', 'content_type', 'filename'] }];

export const getPropertyDocuments = getThunkActionCreator(
  getPropertyDocumentsRoutine,
  async ({ property_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const params = { limit, offset, orders, attributes };

    return await axios.get(`/properties/${property_id}/documents`, { params });
  }
);
export const getPropertyDocumentsAmount = getThunkActionCreator(
  getPropertyDocumentsAmountRoutine,
  async property_id => {
    const params = { limit: 0, offset: 0, attributes: ['id'] };

    return await axios.get(`/properties/${property_id}/documents`, { params });
  }
);
export const getDealspaceDocuments = getThunkActionCreator(
  getDealspaceDocumentsRoutine,
  async ({ folder_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const params = { limit, offset, orders, attributes };

    return await axios.get(`/folders/${folder_id}/documents`, { params });
  }
);
export const getFolderDocuments = getThunkActionCreator(
  getFolderDocumentsRoutine,
  async ({ folder_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const params = { limit, offset, orders, attributes };

    return await axios.get(`/folders/${folder_id}/documents`, { params });
  }
);
export const getBuilderPublicDocuments = getThunkActionCreator(
  getBuilderPublicDocumentsRoutine,
  async ({ folder_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const params = { limit, offset, orders, attributes, unlocked: true };

    return await axios.get(`/folders/${folder_id}/documents`, { params });
  }
);
export const getBuilderPrivateLevel1Documents = getThunkActionCreator(
  getBuilderPrivateLevel1DocumentsRoutine,
  async ({ folder_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const params = { limit, offset, orders, attributes, locked: true, no_approval_required: true };

    return await axios.get(`/folders/${folder_id}/documents`, { params });
  }
);
export const getBuilderPrivateLevel2Documents = getThunkActionCreator(
  getBuilderPrivateLevel2DocumentsRoutine,
  async ({ folder_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const params = { limit, offset, orders, attributes, locked: true, approval_required: true };

    return await axios.get(`/folders/${folder_id}/documents`, { params });
  }
);
export const getOfferPublicDocuments = getThunkActionCreator(
  getOfferPublicDocumentsRoutine,
  async ({ folder_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const params = { limit, offset, orders, attributes, unlocked: true };

    return await axios.get(`/folders/${folder_id}/documents`, { params });
  }
);
export const getOfferPrivateLevel1Documents = getThunkActionCreator(
  getOfferPrivateLevel1DocumentsRoutine,
  async ({ folder_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const params = { limit, offset, orders, attributes, locked: true, no_approval_required: true };

    return await axios.get(`/folders/${folder_id}/documents`, { params });
  }
);
export const getOfferPrivateLevel2Documents = getThunkActionCreator(
  getOfferPrivateLevel2DocumentsRoutine,
  async ({ folder_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const params = { limit, offset, orders, attributes, locked: true, approval_required: true };

    return await axios.get(`/folders/${folder_id}/documents`, { params });
  }
);
export const getPreviewPublicDocuments = getThunkActionCreator(
  getPreviewPublicDocumentsRoutine,
  async ({ folder_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const params = { limit, offset, orders, attributes, unlocked: true };

    return await axios.get(`/folders/${folder_id}/documents`, { params });
  }
);
export const getDocument = getThunkActionCreator(
  getDocumentRoutine,
  async id => {
    const params = { attributes: fullAttributes };

    return await axios.get(`/documents/${id}`, { params });
  }
);
export const createPropertyDocument = getThunkActionCreator(
  createPropertyDocumentRoutine,
  async ({ property_id, document, controller }) => {
    const data = { document, attributes };
    const config = { signal: controller.signal };

    return await axios.post(`/properties/${property_id}/documents`, data, config);
  }
);
export const createOfferDocument = getThunkActionCreator(
  createOfferDocumentRoutine,
  async ({ folder_id, document_id, document, controller }) => {
    const data = { ...document && { document }, ...document_id && { document_id }, attributes };
    const config = { signal: controller.signal };

    return await axios.post(`/folders/${folder_id}/documents`, data, config);
  }
);
export const createDealspaceDocument = getThunkActionCreator(
  createDealspaceDocumentRoutine,
  async ({ folder_id, document, skip = false, controller }) => {
    const data = { document, attributes };
    const config = { signal: controller.signal };
    const response = await axios.post(`/folders/${folder_id}/documents`, data, config);

    return { response, skip };
  }
);
export const updateDocument = getThunkActionCreator(
  updateDocumentRoutine,
  async ({ id, ...fields }) => {
    const data = { document: fields };
    const response = await axios.patch(`/documents/${id}`, data);

    return { response, note: { id, ...fields } };
  }
);
export const deleteDocument = getThunkActionCreator(
  deleteDocumentRoutine,
  async id => {
    const response = await axios.delete(`/documents/${id}`);

    return { response, id };
  }
);
export const clearLocalPropertyDocuments = getThunkActionCreator(clearLocalPropertyDocumentsRoutine, async () => {});
export const clearLocalDealspaceDocuments = getThunkActionCreator(clearLocalDealspaceDocumentsRoutine, async () => {});
export const clearLocalFolderDocuments = getThunkActionCreator(clearLocalFolderDocumentsRoutine, async () => {});
export const clearLocalOfferDocuments = getThunkActionCreator(clearLocalOfferDocumentsRoutine, async () => {});
export const clearLocalActiveDocument = getThunkActionCreator(clearLocalActiveDocumentRoutine, async () => {});