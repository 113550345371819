import { memo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import _map from 'lodash/map';
import _startsWith from 'lodash/startsWith';
import PropTypes from 'prop-types';
// Local files
import PreviewerIcon from 'components/Common/Icons/PreviewerIcon';
import DeleteIcon from 'components/Common/Icons/DeleteIcon';
import DocumentsDrawer from 'components/Drawers/Documents/Documents';
import { ReactComponent as UploadIcon } from 'assets/icons/upload-filled.svg';
import UploadAgreementDialog from 'components/Dialogs/UploadAgreement/UploadAgreement';
import BuilderErrorLabel from 'components/Common/DataDisplay/BuilderErrorLabel/BuilderErrorLabel';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const Agreement = ({ show, value, error, fetching, dealspaceFolderId, onChange, onRemove }) => {
  const [agreementDialog, setAgreementDialog] = useState({ open: false, blob: null, preview: null, documentId: null });
  const [documentsDrawer, setDocumentsDrawer] = useState({ open: false });
  const [loading, setLoading] = useState(false);
  const drawerProps = { ...documentsDrawer, files: [], folders: [], folderId: dealspaceFolderId, uploading: loading, locked: false, level: 3, totalSelected: 0 };
  const disabled = fetching || loading;

  const handleTemplateViewClick = ({ content_type, url }) => {
    if (content_type === 'application/pdf' || _startsWith(content_type, 'image/')) {
      setAgreementDialog({ open: true, blob: null, preview: url, documentId: null });
    } else {
      window.open(url, '_blank').focus();
    }
  };
  const handleLoading = () => {
    if (!loading) {
      setLoading(true);
    }
  };
  const handleDrawerClose = () => setDocumentsDrawer({ open: false });
  const handleDrawerChange = ({ reason, v, id }) => {
    if (reason === 'optionSelected') {
      setDocumentsDrawer({ open: false });
      setAgreementDialog({ open: true, blob: null, preview: null, documentId: id });
    }
    if (reason === 'optionUploaded') {
      setLoading(false);
      setDocumentsDrawer({ open: false });
      setAgreementDialog({ open: true, blob: v, preview: null, documentId: null });
    }
  };
  const handleAgreementDialogClose = () => setAgreementDialog({ open: false, blob: null, preview: null, documentId: null });
  const handleAgreementDialogChange = v => onChange(v);

  if (!show) return null;
  return (
    <>
      <UploadAgreementDialog
        {...agreementDialog}
        onClose={handleAgreementDialogClose}
        onChange={handleAgreementDialogChange}
      />
      <DocumentsDrawer
        {...drawerProps}
        onClose={handleDrawerClose}
        onChange={handleDrawerChange}
        onLoading={handleLoading}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: '50px', mb: '10px' }}>
        <Typography variant='small' color='textSecondary' component='div'>
          Confidentiality Agreement*
        </Typography>
        <Button
          variant='outlined'
          color='neutral'
          disabled={disabled}
          onClick={() => setDocumentsDrawer({ open: true })}
        >
          <UploadIcon style={{ margin: '0 6px 0 3px' }} /> Upload CA (PDF or Doc)
        </Button>
      </Box>
      {!!error && <BuilderErrorLabel value={error} />}
      <Box>
        {_map(value, t =>
          <Box
            key={t.id}
            sx={{ display: 'flex', alignItems: 'center', gap: '2px', color: 'rgba(0,0,0,.8)', borderBottom: '1px solid', borderColor: 'divider', mb: '16px' }}
          >
            <InsertDriveFileIcon />
            <Typography variant='caption' noWrap sx={{ flexGrow: 1, ml: 1.5 }}>{t.name}</Typography>
            <Button
              variant='blank'
              color='neutral'
              sx={{ flexShrink: 0 }}
              onClick={() => handleTemplateViewClick(t.file)}
            >
              <PreviewerIcon />
            </Button>
            <Button
              variant='blank'
              color='neutral'
              sx={{ flexShrink: 0 }}
              onClick={() => onRemove(t.id)}
            >
              <DeleteIcon />
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

Agreement.propTypes = {
  show: PropTypes.bool.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Agreement {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);