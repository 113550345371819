import { memo } from 'react';
import Badge from '@mui/material/Badge';
import PropTypes from 'prop-types';
// Local files
import { ReactComponent as CheckmarkCheckedIcon } from 'assets/icons/checkmark-checked.svg';
import UserAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import GroupAvatar from 'components/Groups/Avatar/Avatar';
import DeadlineIcon from 'components/Common/Icons/DeadlineIcon';
import ClockIcon from 'components/Common/Icons/ClockIcon';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { getRepresentation } from 'helpers';
import { notificationTypes, representationVariants } from 'helpers/constants';

const { CONTACT_INVITATION_ACCEPTED, RECIPIENT_CREATED } = notificationTypes;

const Icon = ({ type, user = null, group = null, dealspace = null, offer = null, invitation = null, deadline_at = null }) => {
  const deadline = deadline_at && Math.round((new Date(deadline_at).getTime() - new Date().getTime()) / 86_400_000);
  const firstName = user ? user.first_name : (offer?.primary_sender.user.first_name ?? ' ');
  const lastName = user ? user.last_name : (offer?.primary_sender.user.last_name ?? ' ');
  const src = user ? getRepresentation(user.photo?.representations, representationVariants.TINY) : (offer?.primary_sender?.user?.photo ? getRepresentation(offer?.primary_sender?.user?.photo?.representations, representationVariants.TINY) : null);

  if (user || type === RECIPIENT_CREATED || offer) {
    return (
      <Badge
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        badgeContent={type === CONTACT_INVITATION_ACCEPTED ? <CheckmarkCheckedIcon /> : null}
      >
        <UserAvatar
          firstName={firstName}
          lastName={lastName}
          src={src}
          sx={{ width: '36px !important', height: '36px !important' }}
        />
      </Badge>
    );
  }
  if (group || invitation || dealspace) {
    const src = group?.logo || invitation?.invitationable?.logo || dealspace?.group?.logo
      ? getRepresentation(group?.logo?.representations ?? invitation?.invitationable?.logo?.representations ?? dealspace?.group?.logo?.representations, representationVariants.TINY)
      : null;
    const title = group?.name ?? invitation?.invitationable?.name ?? dealspace?.group?.name;

    return (
      <GroupAvatar
        fetching={false}
        title={title}
        src={src}
        sx={{ width: '36px !important', height: '36px !important', fontSize: '16px !important' }}
      />
    );
  }
  if (deadline) {
    return (
      <>
        {deadline <= 1 && <ClockIcon htmlColor='#F28181' />}
        {deadline > 10 && deadline <= 30 && <DeadlineIcon htmlColor='#CECECE' />}
        {deadline <= 10 && deadline > 1 && <DeadlineIcon htmlColor='#F28181' />}
      </>
    );
  }
  return null;
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    photo: PropTypes.shape({
      representations: PropTypes.array
    })
  }),
  group: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.shape({
      representations: PropTypes.array
    })
  }),
  dealspace: PropTypes.shape({
    group: PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.shape({
        representations: PropTypes.array
      })
    })
  }),
  invitation: PropTypes.shape({
    invitationable: PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.shape({
        representations: PropTypes.array
      })
    })
  }),
  deadline_at: PropTypes.string
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Icon {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);