import { memo, useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Scrollbars from 'react-custom-scrollbars-2';
import _filter from 'lodash/filter';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
//Local files
import { Link, Root } from './Intake.styled';
import Header from 'components/Users/Intake/Header/Header';
import Stepper from 'components/Users/Intake/Stepper/Stepper';
import OfferingInfo from 'components/Users/Intake/Steps/OfferingInfo/OfferingInfo';
import AddFiles from 'components/Users/Intake/Steps/AddFiles/AddFiles';
import Share from 'components/Users/Intake/Steps/Share/Share';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { checkEmptyString, getFormattedShortDateWithTime } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useOffers from 'hooks/useOffers';
import useSessions from 'hooks/useSessions';
import useUsers from 'hooks/useUsers';

const Intake = () => {
  const scrollRef = useRef(null);
  const previousLocation = useLocation();
  const navigate = useNavigate();
  const { username, step_id } = useParams();
  const { setError } = useError();
  const { intakeOffer } = useOffers();
  const { createSession } = useSessions();
  const { getIntakeUser, validateUser } = useUsers();
  const owner = useCustomSelector(state => state.users.intake);
  const [validSteps, setValidSteps] = useState({ offeringInfo: false, addFiles: false, share: false });
  const [firstName, setFirstName] = useState({ value: '', error: '' });
  const [lastName, setLastName] = useState({ value: '', error: '' });
  const [email, setEmail] = useState({ value: '', error: '' });
  const [phone, setPhone] = useState({ value: '', formattedValue: '', error: '' });
  const [properties, setProperties] = useState({ value: [], error: '' });
  const [dealType, setDealType] = useState({ value: '', error: '' });
  const [propertyGrouping, setPropertyGrouping] = useState({ value: '', error: '' });
  const [customPrice, setCustomPrice] = useState('');
  const [assetClasses, setAssetClasses] = useState([]);
  const [priceCents, setPriceCents] = useState({ value: 0, formattedValue: '' });
  const [leasePeriod, setLeasePeriod] = useState('');
  const [loanRatio, setLoanRatio] = useState('');
  const [loanPercent, setLoanPercent] = useState('');
  const [investmentType, setInvestmentType] = useState('');
  const [riskProfiles, setRiskProfiles] = useState([]);
  const [headline, setHeadline] = useState({ value: '', error: '' });
  const [images, setImages] = useState({ value: [], error: '' });
  const [description, setDescription] = useState({ value: '', error: '', charsCount: 0 });
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState({ value: '', formattedValue: '' });
  const [password, setPassword] = useState({ value: '', error: '' });
  const [repeatPassword, setRepeatPassword] = useState({ value: '', error: '' });
  const [userFetching, setUserFetching] = useState(true);
  const [processing, setProcessing] = useState(false);
  const step = step_id ?? 'offering_info';
  const offeringInfoProps = {
    step, firstName, lastName, email, phone, properties, dealType, propertyGrouping, customPrice, assetClasses, priceCents, leasePeriod,
    loanRatio, loanPercent, investmentType, riskProfiles
  };
  const addFilesProps = { step, headline, images, description, files, message };
  const shareProps = { step, firstName: owner.first_name, password, repeatPassword, disabled: processing };

  const submitIntake = () => {
    const owner_id = owner.id;
    const u = {
      first_name: firstName.value,
      last_name: lastName.value,
      email: email.value,
      mobile_phone: phone.value,
      password: password.value
    };
    const p = _map(properties.value, v => _omit(v, ['id']));
    const o = {
      deal_type: dealType.value,
      name: `${getFormattedShortDateWithTime(new Date())} by ${firstName.value} ${lastName.value}`,
      headline: headline.value,
      description: description.value,
      property_grouping: propertyGrouping.value,
      ...riskProfiles.length && { risk_profiles: riskProfiles },
      ...assetClasses.length && { asset_classes: assetClasses },
      ...checkEmptyString(customPrice) && { custom_price: customPrice },
      ...priceCents.value && { price_cents: priceCents.value * 100 },
      ...!!leasePeriod && { lease_period: leasePeriod },
      ...!!loanRatio && { loan_ratio: loanRatio },
      ...!!loanPercent && { loan_percent: loanPercent },
      ...!!investmentType && { investment_type: investmentType },
    };
    const validation_attributes = ['first_name', 'last_name', 'email', 'mobile_phone', 'password'];
    const i = _map(images.value, (v, index) => ({ file: v.file, name: v.name, main: index === 0 }));
    const d = _map(files, v => ({ file: v.id, name: v.filename }));

    setProcessing(true);
    validateUser({ user: u, validation_attributes })
    .then(() =>
      intakeOffer({
        owner_id,
        user: u,
        properties: p,
        offer: o,
        images: i,
        documents: d,
        ...checkEmptyString(message.value) && { message: message.value }
      })
      .then(({ payload: { data: { offer: { id: offer_id } } } }) =>
        createSession({ email: email.value, password: password.value })
        .then(() => navigate(`/offerings/${offer_id}/activity_log`, { state: { previousLocation, default: true } }))
        .catch(e => setError(e))
        .finally(() => setProcessing(false))
      )
      .catch(e => setError(e).then(() => setProcessing(false)))
    )
    .catch(e => setError(e).then(() => setProcessing(false)));
  };
  const validateOfferingInfoStep = (s = null) => {
    let hasError = false;

    if (!checkEmptyString(firstName.value)) {
      setFirstName(prev => ({ ...prev, error: 'First Name can\'t be empty' }));
      hasError = true;
    }
    if (!checkEmptyString(lastName.value)) {
      setLastName(prev => ({ ...prev, error: 'Last Name can\'t be empty' }));
      hasError = true;
    }
    if (!checkEmptyString(email.value)) {
      setEmail(prev => ({ ...prev, error: 'Email can\'t be empty' }));
      hasError = true;
    }
    if (!checkEmptyString(phone.value)) {
      setPhone(prev => ({ ...prev, error: 'Phone can\'t be empty' }));
      hasError = true;
    }
    if (!properties.value.length) {
      setProperties(prev => ({ ...prev, error: 'Add at least one property' }));
      hasError = true;
    }
    if (!!properties.value.length && !dealType.value) {
      setDealType(prev => ({ ...prev, error: 'Deal Type can\'t be empty' }));
      hasError = true;
    }
    if (!!properties.value.length && properties.value.length > 1 && !propertyGrouping.value) {
      setPropertyGrouping(prev => ({ ...prev, error: 'Property Grouping can\'t be empty' }));
      hasError = true;
    }
    if (hasError) return;

    const user = {
      first_name: firstName.value,
      last_name: lastName.value,
      email: email.value,
      mobile_phone: phone.value
    };
    const validation_attributes = ['first_name', 'last_name', 'email', 'mobile_phone'];

    setProcessing(true);
    validateUser({ user, validation_attributes })
    .then(() => {
      setValidSteps(prev => ({ ...prev, offeringInfo: true }))
      if (s) {
        navigate(`/users/${username}/intake/${s}`);
        scrollRef.current.scrollTop();
      } else {
        submitIntake();
      }
    })
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };
  const validateAddFilesStep = (s = null) => {
    let hasError = false;

    if (!checkEmptyString(headline.value)) {
      setHeadline(prev => ({ ...prev, error: 'Headline can\'t be empty' }));
      hasError = true;
    }
    if (!images.value.length) {
      setImages(prev => ({ ...prev, error: 'Add at least one image' }));
      hasError = true;
    }
    if (!checkEmptyString(description.value)) {
      setDescription(prev => ({ ...prev, error: 'Description can\'t be empty' }));
      hasError = true;
    }
    if (checkEmptyString(description.value) && description.charsCount < 100) {
      setDescription(prev => ({ ...prev, error: 'At least 100 characters of description required' }));
      hasError = true;
    }
    if (hasError) return;
    
    setValidSteps(prev => ({ ...prev, addFiles: true }));
    if (s) {
      scrollRef.current.scrollTop();
      navigate(`/users/${username}/intake/${s}`);
    } else {
      submitIntake();
    }
  };
  const validateShareStep = (s = null) => {
    let hasError = false;

    if (!password.value) {
      setPassword(prev => ({ ...prev, error: 'Password can\'t be empty' }));
      hasError = true;
    }
    if (!repeatPassword.value) {
      setRepeatPassword(prev => ({ ...prev, error: 'Password can\'t be empty' }));
      hasError = true;
    }
    if (!!password.value && !!repeatPassword.value && password.value !== repeatPassword.value) {
      setPassword(prev => ({ ...prev, error: 'Passwords are different' }));
      setRepeatPassword(prev => ({ ...prev, error: 'Passwords are different' }));
      hasError = true;
    }
    if (hasError) return;

    setValidSteps(prev => ({ ...prev, share: true }));
    if (s) {
      scrollRef.current.scrollTop();
      navigate(`/users/${username}/intake/${s}`);
    } else {
      submitIntake();
    }
  };
  const handleStepperChange = s => {
    if (step === 'offering_info') validateOfferingInfoStep(s);
    if (step === 'add_files') validateAddFilesStep(s);
    if (step === 'share') validateShareStep(s);
  };
  const handleSubmit = () => {
    if (step === 'offering_info') {
      if (!validSteps.addFiles || !validSteps.share) {
        validateOfferingInfoStep(validSteps.addFiles ? 'share' : 'add_files');
      } else {
        validateOfferingInfoStep();
      }
    }
    if (step === 'add_files') {
      if (!validSteps.offeringInfo || !validSteps.share) {
        validateAddFilesStep(validSteps.offeringInfo ? 'share' : 'offering_info');
      } else {
        validateAddFilesStep();
      }
    }
    if (step === 'share') {
      if (!validSteps.offeringInfo || !validSteps.addFiles) {
        validateShareStep(validSteps.offeringInfo ? 'add_files' : 'offering_info');
      } else {
        validateShareStep();
      }
    }
  };
  const handleStepChange = ({
    firstName, lastName, email, phone, properties, dealType, propertyGrouping, customPrice, assetClasses, priceCents, leasePeriod, loanRatio,
    loanPercent, investmentType, riskProfiles, headline, images, description, files, message, password, repeatPassword
  }) => {
    if (!_isUndefined(firstName)) setFirstName(firstName);
    if (!_isUndefined(lastName)) setLastName(lastName);
    if (!_isUndefined(email)) setEmail(email);
    if (!_isUndefined(phone)) setPhone(phone);
    if (!_isUndefined(headline)) setHeadline(headline);
    if (!_isUndefined(images)) {
      setImages(prev => {
        if (images.reason === 'add') return { value: [...prev.value, images.v], error: '' };
        if (images.reason === 'remove') return { value: _filter(prev.value, v => v.file !== images.v), error: '' };
        if (images.reason === 'update') return { value: _map(prev.value, (v, i) => i === images.i ? ({ ...v, ...images.v }) : v), error: '' };
        return prev;
      });
    }
    if (!_isUndefined(description)) setDescription(description);
    if (!_isUndefined(files)) {
      setFiles(prev => {
        if (files.reason === 'add') return [...prev, files.v];
        if (files.reason === 'remove') return _filter(prev, p => p.id !== files.v);
        return prev;
      });
    }
    if (!_isUndefined(message)) setMessage(message);
    if (!_isUndefined(password)) setPassword(password);
    if (!_isUndefined(repeatPassword)) setRepeatPassword(repeatPassword);
    if (!_isUndefined(properties)) setProperties(properties);
    if (!_isUndefined(dealType)) setDealType(dealType);
    if (!_isUndefined(propertyGrouping)) setPropertyGrouping(propertyGrouping);
    if (!_isUndefined(customPrice)) setCustomPrice(customPrice);
    if (!_isUndefined(assetClasses)) setAssetClasses(assetClasses);
    if (!_isUndefined(priceCents)) setPriceCents(priceCents);
    if (!_isUndefined(leasePeriod)) setLeasePeriod(leasePeriod);
    if (!_isUndefined(loanRatio)) setLoanRatio(loanRatio);
    if (!_isUndefined(loanPercent)) setLoanPercent(loanPercent);
    if (!_isUndefined(investmentType)) setInvestmentType(investmentType);
    if (!_isUndefined(riskProfiles)) setRiskProfiles(riskProfiles);
  };

  useEffect(() => {
    getIntakeUser(username)
    .catch(e => setError(e))
    .finally(() => setUserFetching(false));
  }, [getIntakeUser, setError, username]);

  return (
    <Root>
      <Scrollbars ref={scrollRef}>
        <Container sx={{ pb: 6 }}>
          <Header
            disabled={userFetching || processing}
            user={owner}
            step={step}
            onClick={handleSubmit}
          />
          <Typography sx={{ fontSize: '1.125rem', fontWeight: 500, textAlign: 'center', mb: { xs: 5, md: 7 } }}>
            Share your deal with {owner.first_name} {owner.last_name}
          </Typography>
          <Stepper
            value={step}
            onChange={handleStepperChange}
          />
          <OfferingInfo
            {...offeringInfoProps}
            onChange={handleStepChange}
            onSubmit={handleSubmit}
          />
          <AddFiles
            {...addFilesProps}
            onChange={handleStepChange}
            onSubmit={handleSubmit}
          />
          <Share
            {...shareProps}
            onChange={handleStepChange}
            onSubmit={handleSubmit}
          />
          <Stack align='center'>
            <Typography sx={{ fontSize: '10px', fontStyle: 'italic' }}>
              Powered by
            </Typography>
            <Typography sx={{ color: '#1A1C28', fontSize: '1.125rem', fontWeight: 600}}>
              Qwincy<span style={{ fontWeight: 200, marginLeft: '.15rem' }}>®</span>
            </Typography>
            <Typography sx={{ fontSize: '12px', mt: '6px' }}>
              Collaborative Dealflow Management
            </Typography>
            <Link href='https://qwincy.com/' target='_blank' rel='noreferrer'>
              What is Qwincy Deal Sharing?
            </Link>
          </Stack>
        </Container>
      </Scrollbars>
    </Root>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Intake {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);