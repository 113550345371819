import styled from '@mui/material/styles/styled';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';

export const Root = styled(Box)({
  position: 'relative',
  marginTop: '14px',
});
export const Logo = styled('img')(({ theme }) => ({
  userSelect: 'none',
  display: 'block',
  width: '100%',
  maxWidth: 600,
  minHeight: 80,
  maxHeight: 200,
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    objectFit: 'contain',
    minHeight: 50
  }
}));
export const ScrollButton = styled(ButtonBase)(({ theme }) => ({
  width: '100%',
  maxWidth: 600 - 32,
  height: 56,
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 400,
  color: 'rgba(0,0,0,.7)',
  display: 'flex',
  gap: '8px',
  borderRadius: '4px',
  margin: '0 auto',
  padding: '8px 16px',
}));
export const LockedLocation = styled(Box)({
  width: 200,
  height: 200,
  position: 'absolute',
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
  zIndex: 1000,
  border: '4px solid #868686',
  borderRadius: '50%',
  gap: '8px',
  background: 'linear-gradient(to left top, rgba(0, 0, 0, .83), rgba(0, 0, 0, .61))' ,
  '&:hover': {
    background: 'linear-gradient(to left top, rgba(124, 130, 138, 1), rgba(30, 32, 36, 1))'
  }
});

export const Description = styled('div')({
  fontSize: '0.75rem',
  paddingTop: '0px',
  '& img': {
    maxWidth: '600px'
  }
});