import { memo } from 'react';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
// Local Files
import Channel from './Channel/Channel';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';

const DealspaceChannels = ({ single = false }) => {
  const { dealspace: { name, cover, conversation } } = useCustomSelector(state => state.dealspaces);
  const data = _orderBy(useCustomSelector(state => state.dealspaces.chats.data), ['updated_at'], ['desc']);
  const { user: { id: user_id } } = useCustomSelector(state => state.profile);

  return (
    <>
      {!single ? _map(data, ({ id, conversation, name, cover }) =>
        <Channel
          key={id} 
          title={name} 
          cover={getRepresentation(cover?.representations, representationVariants.TINY)} 
          conversation={conversation}
          highlighted={!!conversation.last_message && user_id !== conversation?.last_message?.user?.id && !conversation?.last_message?.read}
        />
      ) :
      <Channel
        title={name}
        cover={getRepresentation(cover?.representations, representationVariants.TINY)}
        conversation={conversation}
        highlighted={!!conversation.last_message && user_id !== conversation?.last_message?.user?.id && !conversation?.last_message?.read}
        sx={{ py: '24px', mb: '16px' }}
      /> }
    </>
  );
};

export default memo(DealspaceChannels);