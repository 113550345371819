import styled from '@mui/material/styles/styled';
import Tab from '@mui/material/Tab';

const StyledTab = styled(Tab)(({ theme }) => ({
  minWidth: 'unset',
  minHeight: '40px',
  padding: '8px 0',
  '&:not(:last-of-type)': {
    marginRight: 12
  },
  [theme.breakpoints.up('md')]: {
    padding: '4px 0',
    '&:not(:last-of-type)': {
      marginRight: 40
    }
  }
}))

export { StyledTab };