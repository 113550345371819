import {
  getAgreementsRoutine,
  getAgreementRoutine,
  createAgreementRoutine,
  clearLocalAgreementsRoutine
} from 'actions';

export const initialState = {
  all: { data: [], pagination: { limit: 0, offset: 0, count: 0, total_count: 0 } },
  agreement: { id: '', boldsign_template_url: '' }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getAgreementsRoutine.SUCCESS: {
      const { data: { agreements, pagination } } = action.payload;
      const all = { data: agreements, pagination };

      return { ...state, all };
    }
    case getAgreementRoutine.SUCCESS: {
      const { data: { agreement } } = action.payload;

      return { ...state, agreement };
    }
    case createAgreementRoutine.SUCCESS: {
      const { data: { agreement } } = action.payload;

      return { ...state, agreement };
    }
    case clearLocalAgreementsRoutine.SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  };
};

export default reducer;