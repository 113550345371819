import styled from '@mui/material/styles/styled';
import Avatar from '@mui/material/Avatar';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  flexShrink: 0,
  width: 32,
  height: 32,
  fontSize: 14,
  fontWeight: 700,
  backgroundColor: theme.palette.primary.main,
  borderRadius: '6px',
  textDecoration: 'none',
  [theme.breakpoints.up('md')]: {
    width: 80,
    height: 80,
    fontSize: 60,
    borderRadius: '10px'
  }
}));

export { StyledAvatar };