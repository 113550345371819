import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

const useSuccess = () => {
  const { enqueueSnackbar } = useSnackbar();
  const showSuccess = useCallback(success => enqueueSnackbar(success, { variant: 'info' }), [enqueueSnackbar]);
  const setSuccess = useCallback(success => new Promise(resolve => resolve(showSuccess(success))), [showSuccess]);

  return { setSuccess };
};

export default useSuccess;