import { memo } from 'react';
import IconButton from '@mui/material/IconButton';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
// Local files
import { Badge } from './Chats.styled';
import { StyledNavAction } from '../AppNavigation.styled';
import { ReactComponent as ChatIcon } from 'assets/icons/chat-outlined.svg';
import { ReactComponent as ChatMobileIcon } from 'assets/icons/appnav/chat-outlined-mobile.svg';
import { chatLevels } from 'helpers/constants';
import useCustomSelector from 'hooks/useCustomSelector';
import useApp from 'hooks/useApp';

const Chats = ({ mobile = false }) => {
  const navigate = useNavigate();
  const previousLocation = useLocation();
  const { setChatsFilter } = useApp();
  const { id: my_id } = useCustomSelector(state => state.profile.user);
  const { unreadMessagesCount, authorizedAsUser } = useCustomSelector(state => {
    const authorizedAsUser = state.sessions.session.userableType === 'user';
    const unreadGeneralConversationsCount = state.profile.user.unread_general_conversations_count;
    const unreadFeedConversationsCount = state.profile.user.unread_feed_conversations_count;
    const hasGroupUnread = _reduce(_map(state.groups.all.data, ({ unread_dealspaces_due_offer_conversations_count, unread_dealspaces_due_dealspace_conversations_count }) => 
    unread_dealspaces_due_offer_conversations_count + unread_dealspaces_due_dealspace_conversations_count), (sum, n) => sum + n, 0);
    const unreadMessagesCount = unreadGeneralConversationsCount + unreadFeedConversationsCount + hasGroupUnread;

    return { unreadMessagesCount, authorizedAsUser };
  });

  const handleChatOpen = () => setChatsFilter({ filter: { dealspace_id: '', offer_id: '' } }).then(() => navigate(`/chats/${chatLevels.USER}/${my_id}`, { state: { previousLocation } }));

  if (!authorizedAsUser) return null;
  if (!mobile) return (
    <IconButton
      sx={{ color: 'inherit' }}
      onClick={handleChatOpen}
    >
      <Badge variant='dot' color='success' invisible={!!!unreadMessagesCount}>
        <ChatIcon />
      </Badge>
    </IconButton>
  );
  return (
    <StyledNavAction
      onClick={handleChatOpen}
      label='Chats'
      value={2}
      icon={
        <Badge variant='dot' color='success' invisible={!!!unreadMessagesCount}>
          <ChatMobileIcon />
        </Badge>
      }
    />
  );
};

Chats.propTypes = {
  mobile: PropTypes.bool
};

const ErrorFallback = ({ error }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Chats {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);