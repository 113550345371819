import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

export const Root = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 28,
  left: 26,
  right: 26,
  background: 'linear-gradient(143deg, rgba(12, 29, 69, 0.88) -2.33%, rgba(41, 47, 53, 0.88) 64.67%, rgba(31, 64, 98, 0.88) 109.72%);',
  borderRadius: '6px',
  backdropFilter: 'blur(8px)',
  WebkitBackdropFilter: 'blur(8px)',
  padding: '26px 12px 30px',
  [theme.breakpoints.down('md')]: {
    bottom: 20,
    left: 20,
    right: 20,
    padding: '18px 12px 20px'
  }
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: '#fff',
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 600,
  marginBottom: '.4rem'
}));

export const Text = styled(Typography)(({ theme }) => ({
  color: '#fff',
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 400,
  '&:not(:last-of-type)': {
    marginBottom: '.4rem'
  }
}));

export const ClaimButton = styled((props) =>
<ButtonBase disableRipple {...props} />)(() => ({
  font: 'inherit',
  fontWeight: 600,
  textDecoration: 'underline'
}));