import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// Local files
import { Root, Title, Actions, StyledButton, Badge } from './InfoWithActions.styled';
import PersonIcon from 'components/Common/Icons/PersonIcon';
import ChatIcon from 'components/Common/Icons/ChatIcon';
import PhoneIcon from 'components/Common/Icons/PhoneIcon';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import useConversations from 'hooks/useConversations';
import useCustomSelector from 'hooks/useCustomSelector';

const InfoWithActions = ({ id, username, private_conversation, first_name, last_name, photo, mobile_phone }) => {
  const navigate = useNavigate();
  const { openOrCreateConversation } = useConversations();
  const { id: my_id } = useCustomSelector(state => state.profile.user);

  const handleConversationClick = () => openOrCreateConversation(id, private_conversation);
  const isVisible = () => {
    let isVisible = false;
    const c = private_conversation;

    isVisible = !!c?.last_message && c?.last_message?.user?.id !== my_id && !c?.last_message?.read;

    return isVisible;
  };

  return (
    <Root>
      <BaseAvatar
        src={getRepresentation(photo?.representations, representationVariants.TINY) ?? ''}
        firstName={first_name[0]}
        lastName={last_name[0]}
        sx={{ width: 60, height: 60, fontSize: 24, mx: 'auto' }}
      />
      <Title>{first_name} {last_name}</Title>
      <Actions>
        <StyledButton onClick={() => navigate(`/users/${username}`)}>
          <PersonIcon />
        </StyledButton>
        <StyledButton onClick={handleConversationClick}>
          <Badge color='success' variant='dot' invisible={!isVisible()}>
            <ChatIcon />
          </Badge>
        </StyledButton>
        <StyledButton onClick={() => window.open(`tel:${mobile_phone}`)}>
          <PhoneIcon />
        </StyledButton>
      </Actions>
    </Root>
  );
};

InfoWithActions.propTypes = {
  id: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  photo: PropTypes.object,
  mobile_phone: PropTypes.string.isRequired
};

export default memo(InfoWithActions);