import { useMemo } from 'react';
import { chatLevels } from 'helpers/constants';
import { useParams } from 'react-router-dom';

const { USER, DEALSPACE, GROUP } = chatLevels;

const useChatsLevel = () => {
  const { chat_level, chat_level_id } = useParams();
  const isUserLevel = useMemo(() => chat_level === USER, [chat_level]);
  const isDealspaceLevel = useMemo(() => chat_level === DEALSPACE, [chat_level]);
  const isGroupLevel = useMemo(() => chat_level === GROUP, [chat_level]);

  return { isUserLevel, isDealspaceLevel, isGroupLevel, chat_level, chat_level_id };
};

export default useChatsLevel;