import { memo, useEffect, useRef, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';
// Local files
import { Root } from './Notifications.styled';
import List from 'components/Notifications/List/List';
import CloseButton from 'components/Notifications/CloseButton/CloseButton';

const NotificationDrawer = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const prevOpen = useRef(open);

  const handleClose = () => {
    setOpen(false);
    prevOpen.current = true;
  };

  // Для плавного открытия с анимацией
  useEffect(() => {
    setOpen(true);
  }, []);
  useEffect(() => {
    if (prevOpen.current && !open) {
      navigate(-1);
    }
  }, [open]); // eslint-disable-line

  return (
    <Drawer
      anchor='right'
      open={open}
      variant='persistent'
      onClose={handleClose}
      PaperProps={{ sx: { width: { xs: '100%',  md: '40vw', lg: '30vw' } } }}
    >
      <Root>
        <List />
        <CloseButton onClick={handleClose} />
      </Root>
    </Drawer>
  );
}

export default memo(NotificationDrawer);