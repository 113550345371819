import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// Local files
import placeholder from 'assets/images/deal/placeholder-file.jpg';

const Root = styled('div', { shouldForwardProp: (prop) => prop !== 'own' }
)(({ own }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: own ? 'flex-end' : 'flex-start',
}));
const Wrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'own' }
)(({ own }) => ({
  maxWidth: '80%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: own ? 'flex-end' : 'flex-start',
  gap: '6px',
  marginBottom: own ? 'unset' : '-25px',
  marginLeft: own ? 'unset' : '34px',
}));
const Paper = styled('div', { shouldForwardProp: (prop) => prop !== 'own' }
)(({ theme, own }) => ({
  flexGrow: 1,
  backgroundColor: own ? theme.palette.section.chat.primary : theme.palette.background.surface,
  borderRadius: own ? '8px 8px 0px 8px' : '8px 8px 8px 0px',
  padding: '12px',
}));
const Name = styled((props) => (
  <Typography variant='caption' component='div' {...props} />
))(({ theme }) => ({
  fontWeight: 600,
  color:  theme.palette.section.chat.primary,
}));
const Text = styled((props) => (
    <Typography variant='caption' whiteSpace='pre-line' component='div' {...props} /> ),
  { shouldForwardProp: (prop) => prop !== 'own' }
)(({ theme, own }) => ({
  color: own ? '#fff' : theme.palette.text.primary,
}));
const Img = styled('img')(() => ({
  width: 184,
  height: 'auto',
  objectFit: 'cover', 
  borderRadius: '12px'
}));
const DocPlaceholder = styled(Box)({
  width: '100%',
  height: 80,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url('${placeholder}')`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '& > svg': {
    height: 56,
    width: 'auto',
  }
});

export const Details = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '4px'
});
const Time = styled((props) => (
  <Typography variant='caption' component='div' {...props} />),
  { shouldForwardProp: (prop) => prop !== 'own' }
)(({ own }) => ({
  flexShrink: 0,
  marginLeft: own ? 'unset' : '8px',
}));
const Status = styled('div')({
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  paddingBottom: 4,
});

export { Root, Wrapper, Paper, Name, Text, Img, DocPlaceholder, Time, Status };