import { memo, useRef, useState } from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Local files
import AudioPlayer from 'components/Common/AudioPlayer/AudioPlayer';
import { ReactComponent as ConnectPendingIcon } from 'assets/icons/connect-pending.svg';
import { ReactComponent as AddConnectIcon } from 'assets/icons/connect-to-add.svg';
import { ReactComponent as ConnectedIcon } from 'assets/icons/connect-done.svg';
import { ReactComponent as SubscribedIcon } from 'assets/icons/subscribed.svg';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { DELETE_CONTACT } from 'helpers/confirmations';
import useApp from 'hooks/useApp';
import useContacts from 'hooks/useContacts';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useUsers from 'hooks/useUsers';

const ConnectActions = ({ activeUser }) => {
  const audioRef = useRef(null);
  const navigatePath = useLocation();
  const { username } = useParams();
  const { openDeleteDialog } = useApp();
  const { createContact, updateContact } = useContacts();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { getUser } = useUsers();
  const [anchorEl, setAnchorEl] = useState(null);
  const [processing, setProcessing] = useState(false);
  const isConnected = !!activeUser.current_contact && activeUser.current_contact.user && activeUser.current_contact.status === 'active';
  const isPending = !!activeUser.current_contact && activeUser.current_contact?.status === 'invited';
  const showSubscribed = isPending || isConnected;

  const handleOpenMenu = ({ currentTarget }) => setAnchorEl(currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleConnectClick = () => {
    if (activeUser.current_contact) {
      setProcessing(true);
      updateContact({ id: activeUser.current_contact.id, invitation_attributes: { user_id: activeUser.id } })
      .then(() => {
        audioRef.current.playConnectionRequestSound();
        setSuccess('Connection invitation sent');
        setSuccess(`You are now Subscribed to ${activeUser.first_name} ${activeUser.last_name}`);
        getUser(username).catch(e => setError(e));
      })
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    } else {
      const contact = { invitation_attributes: { user_id: activeUser.id } };

      setProcessing(true);
      createContact({ contact })
      .then(() => {
        audioRef.current.playConnectionRequestSound();
        setSuccess('Connection invitation sent');
        setSuccess(`You are now Subscribed to ${activeUser.first_name} ${activeUser.last_name}`);
      })
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
  };
  const handleDisconnectClick = () => openDeleteDialog({ type: DELETE_CONTACT, id: activeUser?.current_contact?.id, navigatePath });

  if (!isPending && !isConnected) return (
    <>
      <Button
        variant='gradient'
        startIcon={<AddConnectIcon />}
        onClick={handleConnectClick}
        disabled={processing}
      >
        Connect
      </Button>
      <AudioPlayer ref={audioRef} />
    </>
  );
  return (
    <>
      {isPending &&
        <Button sx={{ color: 'rgba(0,0,0,.4)' }} disabled={processing}>
          <ConnectPendingIcon /><i>Pending</i>
        </Button>
      }
      {isConnected &&
        <Button onClick={handleOpenMenu} disabled={processing}>
          <ConnectedIcon style={{ marginRight: '2px' }}/>Connected
        </Button>
      }
      {showSubscribed &&
        <Button disabled={processing}>
          <SubscribedIcon style={{ marginRight: '2px', marginTop: '-2px' }} />Subscribed
        </Button>
      }
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        PaperProps={{ variant: 'popup', color: 'light', sx: { mt: 1 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box p={1} sx={{ maxWidth: '300px', cursor: 'pointer', px: 3 }} onClick={handleDisconnectClick}>
          <Typography variant='caption'>Disconnect</Typography>
        </Box>
      </Popover>
      <AudioPlayer ref={audioRef} />
    </>
  );
};

ConnectActions.propTypes = {
  activeUser: PropTypes.shape({
    current_contact: PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string
    }),
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired
  })
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <ConnectActions {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);