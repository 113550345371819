import { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
// Local files
import ArrowDropDownIcon from 'components/Common/Icons/ArrowDropDownIcon';

const Prompt = ({ primary, secondary, sx = {} }) => {
  return (
    <Box pl='4px' sx={sx}>
      {!!primary && (
        <Box display='flex'>
          <ArrowDropDownIcon sx={{ rotate: '90deg', color: '#525667' }} />
          <Typography variant='caption' sx={{ fontWeight: 500, color: '#525667', mt: '2px' }}>{primary}</Typography>
        </Box>
      )}
      <Typography variant='caption' color='text.hint' component='div' sx={{ fontWeight: 400, pl: '24px' }}>
        {secondary}
      </Typography>
    </Box>
  );
};

Prompt.propTypes = {
  primary: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  secondary: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  sx: PropTypes.object
};

export default memo(Prompt);