import styled from '@mui/material/styles/styled';
import DialogContent from '@mui/material/DialogContent';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';

export const SectionList = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  [theme.breakpoints.up('md')]: {
    minWidth: 170,
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '18px 32px',
  }
}));

export const SectionItem = styled((props) => <ButtonBase disableRipple {...props} />,
{ shouldForwardProp: (prop) => prop !== 'selected' }
)(({ selected, theme }) => ({
  color: '#000',
  fontSize: theme.typography.pxToRem(14),
  padding: '8px 16px',
  ...(selected && {
    fontWeight: 600,
    borderBottom: '2px solid #000',
  })
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 0,
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  }
}));

export const Content = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  height: '100%',
  padding: '24px 16px 40px',
  [theme.breakpoints.up('md')]: {
    padding: '30px 40px 50px',
  }
}));