import { memo, useRef, useState } from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Image, Root, StyledButton, FileInput } from './Header.styled';
import { ReactComponent as SelectImageIcon } from 'assets/icons/image-select.svg';
import PercentProgress from 'components/Common/Feedback/PercentProgress/PercentProgress';
import DropContainer from 'components/Common/Feedback/DropContainer/DropContainer';
import CropDialog from 'components/Dialogs/Crop/Crop';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useBlob from 'hooks/useBlob';
import useChecksum from 'hooks/useChecksum';

const CanvasHeader = ({ logo, onChange }) => {
  const inputRef = useRef(null);
  const { processBlob, percent, loading } = useBlob();
  const { processFiles } = useChecksum();
  const [cropDialog, setCropDialog] = useState({ open: false, type: '', src: '', width: 0, height: 0, showHeightSlider: false });

  const handleUploadClick = () => inputRef.current.click();
  const handleEditClick = () => inputRef.current.click();
  const handleFilesChange = ({ target: { files } }) => {
    const f = Array.from(files);

    onChange({ uploading: true });
    processFiles(f, ({ localUrl, width, height }) => {
      setCropDialog({ open: true, type: 'header', src: localUrl, width, height, showHeightSlider: true });
    }, 200, 600, true);
  };
  const handleDialogClose = () => setCropDialog({ open: false, type: '', src: '', width: 0, height: 0, showHeightSlider: false });
  const handleDialogSubmit = f => {
    processFiles([f], ({ file, checksum, localUrl }) => {
      processBlob({ file, checksum }, blob => {
        if (!blob.id) return;

        onChange({ value: blob.id, formattedValue: { url: localUrl } });
        setCropDialog({ open: false, type: '', src: '', width: 0, height: 0, showHeightSlider: false });
      });
    });
  };

  if (!logo.value) {
    return (
      <>
        <CropDialog
          {...cropDialog}
          onClose={handleDialogClose}
          onSubmit={handleDialogSubmit}
        />
        <ButtonBase
          variant='uploader'
          sx={{ height: 84 }}
          disable={loading}
          onClick={handleUploadClick}
        >
          {loading ? <PercentProgress value={percent} /> :
            <>
              <FileInput
                id='offerLogo'
                ref={inputRef}
                accept='image/png, image/jpeg, image/tiff, image/gif'
                type='file'
                onChange={handleFilesChange}
                onClick={(event) => event.target.value = ''}
              />
              <SelectImageIcon style={{ color: '#8AC5F3' }} />
              <Typography variant='subtitle3' mt={1}>Click to upload your logo</Typography>
              <Typography variant='caption'>PNG, JPEG, TIF, GIF  •  600 px wide max</Typography>
            </>
          }
        </ButtonBase>
      </>
    );
  };
  return (
    <DropContainer>
      <CropDialog
        {...cropDialog}
        onSubmit={handleDialogSubmit}
        onClose={handleDialogClose}
      />
      <Root>
        <Image
          src={logo.formattedValue?.localUrl ?? logo.formattedValue?.url}
          alt='logo'
        />
        <StyledButton onClick={handleEditClick}><EditIcon /></StyledButton>
        <FileInput
          id='offerLogo'
          ref={inputRef}
          accept='image/png, image/jpeg, image/tiff, image/gif'
          type='file'
          onChange={handleFilesChange}
          onClick={(event) => event.target.value = ''}
        />
      </Root>
    </DropContainer>
  );
};

CanvasHeader.propTypes = {
  logo: PropTypes.shape({
    value: PropTypes.string,
    formattedValue: PropTypes.object
  }),
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <CanvasHeader {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);