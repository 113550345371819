import { memo } from 'react';
import PropTypes from 'prop-types';
// Local files
import { Root, LeftMargin, RightMargin, Container } from './Paper.styled';

const Paper = ({ children, footer, mobile = false, sx = {} }) => {
  return (
    <Root sx={sx}>
      {!mobile && <LeftMargin />}
      {!mobile && <RightMargin />}
      <Container sx={{ ...mobile && { padding: '0 10px' } }}>
        {children}
      </Container>
      {!!footer && footer}
    </Root>
  );
};

Paper.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  mobile: PropTypes.bool,
  sx: PropTypes.object
};

export default memo(Paper);