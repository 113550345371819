import { memo } from 'react';
import Typography from '@mui/material/Typography';
import _map from 'lodash/map';
// Local files
import ContentCard from './ContentCard/ContentCard';
import { Container } from './Content.styled';

const Content = ({ activeUser }) => {
  return (
    <div>
      <Typography variant='caption' color='text.hint' component='div' pb={2}>Content</Typography>
      <Container>
        {_map(activeUser.contents, c => <ContentCard key={c.id} {...c} />)}
      </Container>
    </div>
  );
};

export default memo(Content);