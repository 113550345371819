import { memo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import { Root, Text } from './General.styled';
import Name from './Name/Name';
import Logo from './Logo/Logo';
import ContentBase from './Common/ContentBase/ContentBase';
import EditButton from './Common/EditButton/EditButton';
import Header from 'components/Offers/Builder/Form/Canvas/Header/Header';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useCustomSelector from 'hooks/useCustomSelector';
import useGroups from 'hooks/useGroups';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';

const General = () => {
  const { updateGroup } = useGroups();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const activeGroup = useCustomSelector(state => state.groups.group);
  const [name, setName] = useState(() => activeGroup.name ?? '');
  const [editName, setEditName] = useState(false);
  const [processing, setProcessing] = useState(false);
  const header = activeGroup.header ? { value: activeGroup.header.id, formattedValue: activeGroup.header } : { value: '', formattedValue: {} };

  const handleGroupUpdate = ({ name = null, logo = null, header = null }) => {
    const group = {
      id: activeGroup.id,
      ...name && { name },
      ...logo && {
        logo: logo.value,
        localLogo: logo.formattedValue
      },
      ...header && {
        header: header.value,
        localHeader: header.formattedValue
      }
    };

    setProcessing(true);
    updateGroup(group)
    .then(() => setSuccess('Group successfully updated').then(() => setEditName(false)))
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };

  const handleLogoChange = v => handleGroupUpdate({ logo: v });
  const handleHeaderChange = v => {
    if (!v.uploading) handleGroupUpdate({ header: v });
  };

  return (
    <Root maxWidth='md' disableGutters>
      <ContentBase 
        title='Group Name' 
        children={
          <Name 
            editButton={
              <EditButton
                disabled={processing}
                onClick={() => setEditName(prev => !prev)}
                sx={{ bottom: 0, right: 0, backgroundColor: 'transparent' }}
              />
            }
            edit={editName}
            value={name}
            onChange={v => setName(v)}
            onBlur={v => handleGroupUpdate({ name: v })}
          />
        }
      />
      <ContentBase 
        title='Group Avatar' 
        children={
          <Logo
            value={activeGroup.logo}
            onChange={handleLogoChange}
          />
        }
        bottomText={
          <Text mt={1.5}>
            60 x 60 | Max 1 MB | Use JPEG, PNG or GIF
          </Text>
        }
      />
      <ContentBase 
        title='Offering Header Logo' 
        children={
          <Header
            logo={header}
            onChange={handleHeaderChange}
          />
        }
        bottomText={
          <Text mt={1.5}>
            For best appearance results, use a white or transparent background <br />
            Max width 600 px | Max 2MB
          </Text>
        }
      />
    </Root>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <General {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);