import { memo } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Paper, Heading } from './Canvas.styled';
import MenuVert from 'components/Common/Icons/MenuVert';
import Header from 'components/Offers/Builder/Form/Canvas/Header/Header';
import Images from 'components/Images/BuilderImages/BuilderImages';
import TextEditor from 'components/Offers/Builder/Form/Canvas/TextEditor/TextEditor';
import PublicFiles from 'components/Documents/BuilderPublicFiles/BuilderPublicFiles';
import Level1PrivateFiles from 'components/Documents/BuilderLevel1PrivateFiles/BuilderLevel1PrivateFiles';
import Level2PrivateFiles from 'components/Documents/BuilderLevel2PrivateFiles/BuilderLevel2PrivateFiles';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useCustomSelector from 'hooks/useCustomSelector';

const Canvas = ({
  description, hasImages, hasLogo, images, publicFiles, publicFolders, privateLevel1Files, privateLevel2Files, privateLevel1Folders,
  privateLevel2Folders, logo, onChange
}) => {
  const { offerId, dealspaceFolderId } = useCustomSelector(state => ({
    offerId: state.offers.builder.offer.id,
    dealspaceFolderId: state.offers.builder.offer.dealspace.root_folder.id
  }));
  const handleEditorChange = ({ v, c }) => onChange({ description: { value: v, error: '', charsCount: c } });

  return (
    <Box sx={{ width: '100%' }}>
      <Heading>
        <Typography variant='caption' color='textSecondary' component='div' sx={{ flexGrow: 1 }}>Offering Canvas*</Typography>
        <FormControlLabel
          control={
            <Switch
              color='secondary'
              checked={hasLogo}
              onChange={({ target: { checked } }) => onChange({
                hasLogo: checked,
                ...!checked && { logo: { value: null, formattedValue: null } }
              })}
            />
          }
          label='Logo Header'
        />
        <FormControlLabel
          control={
            <Switch
              color='secondary'
              checked={hasImages}
              onChange={({ target: { checked } }) => onChange({ hasImages: checked })}
            />
          }
          label='Image Gallery'
        />
        <Button variant='blank' color='black'><MenuVert /></Button>
      </Heading>
      <Paper>
        {hasLogo &&
          <Header
            logo={logo}
            onChange={v => onChange({ logo: v })}
          />
        }
        {hasImages &&
          <Images
            dealspaceFolderId={dealspaceFolderId}
            fetching={images.fetching}
            uploading={images.uploading}
            images={images.value}
            error={images.error}
            onChange={v => onChange({ images: v })}
          />
        }
        {description.fetched &&
          <TextEditor
            offerId={offerId}
            value={description.value}
            error={description.error}
            onChange={handleEditorChange}
          />
        }
        <PublicFiles
          dealspaceFolderId={dealspaceFolderId}
          fetching={publicFiles.fetching}
          uploading={publicFiles.uploading}
          files={publicFiles.value}
          folders={publicFolders.value}
          onChange={v => onChange({ publicFiles: v })}
        />
        <Level1PrivateFiles
          dealspaceFolderId={dealspaceFolderId}
          fetching={privateLevel1Files.fetching}
          uploading={privateLevel1Files.uploading || privateLevel1Folders.uploading}
          files={privateLevel1Files.value}
          folders={privateLevel1Folders.value}
          onChange={v => onChange({ privateFiles: v })}
        />
        <Level2PrivateFiles
          dealspaceFolderId={dealspaceFolderId}
          fetching={privateLevel2Files.fetching}
          uploading={privateLevel2Files.uploading || privateLevel2Folders.uploading}
          files={privateLevel2Files.value}
          folders={privateLevel2Folders.value}
          onChange={v => onChange({ privateFiles: v })}
        />
      </Paper>
    </Box>
  );
};

Canvas.propTypes = {
  description: PropTypes.shape({
    value: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    charsCount: PropTypes.number.isRequired
  }),
  hasImages: PropTypes.bool.isRequired,
  hasLogo: PropTypes.bool.isRequired,
  images: PropTypes.shape({
    value: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    uploading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired
  }),
  logo: PropTypes.shape({
    value: PropTypes.string,
    formattedValue: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ])
  }),
  publicFiles: PropTypes.shape({
    value: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    uploading: PropTypes.bool.isRequired
  }),
  publicFolders: PropTypes.shape({
    value: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    uploading: PropTypes.bool.isRequired
  }),
  privateLevel1Files: PropTypes.shape({
    value: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    uploading: PropTypes.bool.isRequired
  }),
  privateLevel2Files: PropTypes.shape({
    value: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    uploading: PropTypes.bool.isRequired
  }),
  privateLevel1Folders: PropTypes.shape({
    value: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    uploading: PropTypes.bool.isRequired
  }),
  privateLevel2Folders: PropTypes.shape({
    value: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    uploading: PropTypes.bool.isRequired
  }),
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Canvas {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);