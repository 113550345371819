import { forwardRef, memo } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { TreeView } from '@mui/x-tree-view';
import { Link as RouterLink } from 'react-router-dom';
// Local files
import { StyledTreeItemRoot } from './Navigation.styled';
import { ReactComponent as FolderIcon } from 'assets/icons/folder.svg';

const TreeItem = forwardRef(function StyledTreeItem(props, ref) {
  const { label, path, ...other } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
          <Box component={FolderIcon} color="inherit" sx={{ mr: 1 }} />
          <Link component={RouterLink} to={path} underline='none'>
            {label}
          </Link>
        </Box>
      }
      {...other}
      ref={ref}
    />
  );
});

const Navigation = ({ folders }) => {
  const renderTree = nodes => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.label}
      path={nodes.path}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <TreeView sx={{ width: 200 }}>
      {renderTree(folders)}
    </TreeView>
  );
};

export default memo(Navigation);