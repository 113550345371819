import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export const Root = styled('div')({
  width: '100%',
  paddingBottom: 16
});

export const Header = styled(Box)({
  width: '100%',
  color: 'rgba(0,0,0,.6)',
  display: 'flex',
  alignItems: 'center',
  gap: '6px'
});

export const TextButton = styled(Button)({
  textDecoration: 'underline !important',
  color: 'rgba(0,0,0,.6)',
  fontSize: '0.625rem'
});

export const Grid = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 8
});