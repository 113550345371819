import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getOfferPublicFolders as getOfferPublicFoldersAction,
  getOfferPrivateLevel1Folders as getOfferPrivateLevel1FoldersAction,
  getOfferPrivateLevel2Folders as getOfferPrivateLevel2FoldersAction,
  getBuilderPublicFolders as getBuilderPublicFoldersAction,
  getBuilderPrivateLevel1Folders as getBuilderPrivateLevel1FoldersAction,
  getBuilderPrivateLevel2Folders as getBuilderPrivateLevel2FoldersAction,
  getPreviewPublicFolders as getPreviewPublicFoldersAction,
  getFolders as getFoldersAction,
  getFolder as getFolderAction,
  getActualFolderData as getActualFolderDataAction,
  createFolder as createFolderAction,
  updateFolder as updateFolderAction,
  deleteFolder as deleteFolderAction,
  clearLocalOfferFolders as clearLocalOfferFoldersAction,
  clearLocalFolders as clearLocalFoldersAction,
  clearLocalFolder as clearLocalFolderAction,
  setUploadingItem as setUploadingItemAction,
  removeUploadingItem as removeUploadingItemAction,
  hideUploadingItems as hideUploadingItemsAction,
  clearUploadingItems as clearUploadingItemsAction
} from 'actions/folders';

const useFolders = () => {
  const dispatch = useDispatch();
  const getOfferPublicFolders = useCallback(({ folder_id, limit, offset, orders }) => dispatch(getOfferPublicFoldersAction({ folder_id, limit, offset, orders })), [dispatch]);
  const getOfferPrivateLevel1Folders = useCallback(({ folder_id, limit, offset, orders }) => dispatch(getOfferPrivateLevel1FoldersAction({ folder_id, limit, offset, orders })), [dispatch]);
  const getOfferPrivateLevel2Folders = useCallback(({ folder_id, limit, offset, orders }) => dispatch(getOfferPrivateLevel2FoldersAction({ folder_id, limit, offset, orders })), [dispatch]);
  const getBuilderPublicFolders = useCallback(({ folder_id, limit, offset, orders }) => dispatch(getBuilderPublicFoldersAction({ folder_id, limit, offset, orders })), [dispatch]);
  const getBuilderPrivateLevel1Folders = useCallback(({ folder_id, limit, offset, orders }) => dispatch(getBuilderPrivateLevel1FoldersAction({ folder_id, limit, offset, orders })), [dispatch]);
  const getBuilderPrivateLevel2Folders = useCallback(({ folder_id, limit, offset, orders }) => dispatch(getBuilderPrivateLevel2FoldersAction({ folder_id, limit, offset, orders })), [dispatch]);
  const getPreviewPublicFolders = useCallback(({ folder_id, limit, offset, orders }) => dispatch(getPreviewPublicFoldersAction({ folder_id, limit, offset, orders })), [dispatch]);
  const getFolders = useCallback(({ folder_id, limit, offset, orders }) => dispatch(getFoldersAction({ folder_id, limit, offset, orders })), [dispatch]);
  const getFolder = useCallback(id => dispatch(getFolderAction(id)), [dispatch]);
  const getActualFolderData = useCallback(id => dispatch(getActualFolderDataAction(id)), [dispatch]);
  const createFolder = useCallback(({ folder_id, original_folder_id, folder, initial }) => dispatch(createFolderAction({ folder_id, original_folder_id, folder, initial })), [dispatch]);
  const updateFolder = useCallback(folder => dispatch(updateFolderAction(folder)), [dispatch]);
  const deleteFolder = useCallback(id => dispatch(deleteFolderAction(id)), [dispatch]);
  const clearLocalOfferFolders = useCallback(() => dispatch(clearLocalOfferFoldersAction()), [dispatch]);
  const clearLocalFolders = useCallback(() => dispatch(clearLocalFoldersAction()), [dispatch]);
  const clearLocalFolder = useCallback(() => dispatch(clearLocalFolderAction()), [dispatch]);
  const setUploadingItem = useCallback(items => dispatch(setUploadingItemAction(items)), [dispatch]);
  const removeUploadingItem = useCallback(id => dispatch(removeUploadingItemAction(id)), [dispatch]);
  const hideUploadingItems = useCallback(() => dispatch(hideUploadingItemsAction()), [dispatch]);
  const clearUploadingItems = useCallback(() => dispatch(clearUploadingItemsAction()), [dispatch]);

  return {
    getOfferPublicFolders,
    getOfferPrivateLevel1Folders,
    getOfferPrivateLevel2Folders,
    getBuilderPublicFolders,
    getBuilderPrivateLevel1Folders,
    getBuilderPrivateLevel2Folders,
    getPreviewPublicFolders,
    getFolders,
    getFolder,
    getActualFolderData,
    createFolder,
    updateFolder,
    deleteFolder,
    clearLocalOfferFolders,
    clearLocalFolders,
    clearLocalFolder,
    setUploadingItem,
    removeUploadingItem,
    hideUploadingItems,
    clearUploadingItems
  };
};

export default useFolders;