import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';

export const Image = styled(Box)({
  width: '100%',
  height: 135,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center'
});

export const StyledButton = styled(ButtonBase)(({ theme }) => ({
  height: 32,
  width: 32,
  color: theme.palette.primary.main,
  backgroundColor: 'rgba(255,255,255,.85)',
  borderRadius: '4px',
  position: 'relative',
  float: 'right',
  top: 32,
  right: 8
}));