import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getSuggestedUsers as getSuggestedUsersAction,
  getUsersList as getUsersListAction,
  getUsersWidgetList as getUsersWidgetListAction,
  getUser as getUserAction,
  getPublicUser as getPublicUserAction,
  getPublicUserVCard as getPublicUserVCardAction,
  getIntakeUser as getIntakeUserAction,
  createUser as createUserAction,
  changeUserPassword as changeUserPasswordAction,
  validateUser as validateUserAction,
  validateUserEmail as validateUserEmailAction,
  clearLocalSuggestedUsers as clearLocalSuggestedUsersAction,
  clearLocalUsersList as clearLocalUsersListAction,
  clearLocalUsersWidgetList as clearLocalUsersWidgetListAction,
  clearLocalActiveUser as clearLocalActiveUserAction
} from 'actions/users';

const useUsers = () => {
  const dispatch = useDispatch();
  const getSuggestedUsers = useCallback(({ query, offset, offer_id }) => dispatch(getSuggestedUsersAction({ query, offset, offer_id })), [dispatch]);
  const getUsersList = useCallback(({ query, suitable_by_group_id }) => dispatch(getUsersListAction({ query, suitable_by_group_id })), [dispatch]);
  const getUsersWidgetList = useCallback(query => dispatch(getUsersWidgetListAction(query)), [dispatch]);
  const getUser = useCallback(username => dispatch(getUserAction(username)), [dispatch]);
  const getPublicUser = useCallback(username => dispatch(getPublicUserAction(username)), [dispatch]);
  const getPublicUserVCard = useCallback(username => dispatch(getPublicUserVCardAction(username)), [dispatch]);
  const getIntakeUser = useCallback(username => dispatch(getIntakeUserAction(username)), [dispatch]);
  const createUser = useCallback(({ recipient_token, invitation_token, user, confirmation_code, linkedin_code }) => dispatch(createUserAction({ recipient_token, invitation_token, user, confirmation_code, linkedin_code })), [dispatch]);
  const changeUserPassword = useCallback(({ email, password, confirmation_code }) => dispatch(changeUserPasswordAction({ email, password, confirmation_code })), [dispatch]);
  const validateUser = useCallback(({ user, validation_attributes }) => dispatch(validateUserAction({ user, validation_attributes })), [dispatch]);
  const validateUserEmail = useCallback(email => dispatch(validateUserEmailAction(email)), [dispatch]);
  const clearLocalSuggestedUsers = useCallback(() => dispatch(clearLocalSuggestedUsersAction()), [dispatch]);
  const clearLocalUsersList = useCallback(() => dispatch(clearLocalUsersListAction()), [dispatch]);
  const clearLocalUsersWidgetList = useCallback(() => dispatch(clearLocalUsersWidgetListAction()), [dispatch]);
  const clearLocalActiveUser = useCallback(() => dispatch(clearLocalActiveUserAction()), [dispatch]);

  return {
    getSuggestedUsers,
    getUsersList,
    getUsersWidgetList,
    getUser,
    getPublicUser,
    getPublicUserVCard,
    getIntakeUser,
    createUser,
    changeUserPassword,
    validateUser,
    validateUserEmail,
    clearLocalSuggestedUsers,
    clearLocalUsersList,
    clearLocalUsersWidgetList,
    clearLocalActiveUser
  };
};

export default useUsers;