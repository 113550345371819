import { memo, useCallback, useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import { StyledButton, StyledHead } from './Details.styled';
import EmptyContent from './EmptyContent/EmptyContent';
import AddIcon from 'components/Common/Icons/AddIcon';
import DealspacesGrid from 'components/Dealspaces/Grid/Grid';
import SharedOfferings from 'components/Offers/SharedOfferings/SharedOfferings';
import DealspaceDialog from 'components/Dialogs/CreateDealspace/CreateDealspace';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useCustomSelector from 'hooks/useCustomSelector';
import useDealspaces from 'hooks/useDealspaces';
import useError from 'hooks/useError';
import useOffers from 'hooks/useOffers';
import usePrevious from 'hooks/usePrevious';

const Details = () => {
  const { fetching } = useOutletContext();
  const { group_id, type_id } = useParams();
  const { getDealspaces, clearLocalDealspaces } = useDealspaces();
  const { getDraftSharedOffers, getActiveSharedOffers, getEndedSharedOffers, getClosedSharedOffers, getInContractSharedOffers, clearLocalSharedOffers } = useOffers();
  const { setError } = useError();
  const { dealspacesCount, dealspacesOffset, draftOffersOffset, activeOffersOffset, endedOffersOffset, closedOffersOffset, inContractOffersOffset } = useCustomSelector(state => {
    const dealspacesCount = state.dealspaces.group.pagination.total_count;
    const dealspacesOffset = state.dealspaces.group.pagination.count + state.dealspaces.group.pagination.offset;
    const draftOffersOffset = state.offers.shared.draft.pagination.count + state.offers.shared.draft.pagination.offset;
    const activeOffersOffset = state.offers.shared.active.pagination.count + state.offers.shared.active.pagination.offset;
    const endedOffersOffset = state.offers.shared.ended.pagination.count + state.offers.shared.ended.pagination.offset;
    const inContractOffersOffset = state.offers.shared.inContract.pagination.count + state.offers.shared.inContract.pagination.offset;
    const closedOffersOffset = state.offers.shared.closed.pagination.count + state.offers.shared.closed.pagination.offset;

    return { dealspacesCount, dealspacesOffset, draftOffersOffset, activeOffersOffset, endedOffersOffset, closedOffersOffset, inContractOffersOffset };
  });
  const [tab, setTab] = useState(() => type_id ?? 'dealspaces');
  const [dealspacesQuery, setDealspacesQuery] = useState('');
  const [offeringsQuery, setOfferingsQuery] = useState('');
  const [offeringsType, setOfferingsType] = useState('active');
  const [dealspacesLoading, setDealspacesLoading] = useState(true);
  const [offeringsLoading, setOfferingsLoading] = useState(true);
  const [dealspaceDialog, setDealspaceDialog] = useState({ open: false, groupId: null, redirect: false });
  const previousOfferingQuery = usePrevious(offeringsQuery);
  const showCreateButton = tab === 'dealspaces';
  const showEmpty = dealspacesCount === 0 && !dealspacesQuery;

  const handleTabsChange = (_, newValue) => {
    if (newValue !== tab) {
      setTab(newValue);
      setDealspacesQuery('');
      setOfferingsQuery('');
    }
  };
  const handleCreateClick = () => setDealspaceDialog({ open: true, groupId: group_id, redirect: true });
  const handleDealspaceDialogClose = () => setDealspaceDialog({ open: false, groupId: null });
  /**
   * Dealspaces
   */
  const fetchDealspaces = useCallback(({ group_id, offset, query }) => {
    getDealspaces({ group_id, offset, query })
    .catch(e => setError(e))
    .finally(() => setDealspacesLoading(false));
  }, [getDealspaces, setError]);
  /**
   * Offerings
   */
  const fetchDraftOfferings = useCallback(({ group_id, offset, query }) => {
    getDraftSharedOffers({ group_id, offset, query })
    .catch(e => setError(e))
    .finally(() => setOfferingsLoading(false));
  }, [getDraftSharedOffers, setError]);
  const fetchActiveOfferings = useCallback(({ group_id, offset, query }) => {
    getActiveSharedOffers({ group_id, offset, query })
    .catch(e => setError(e))
    .finally(() => setOfferingsLoading(false));
  }, [getActiveSharedOffers, setError]);
  const fetchEndedOfferings = useCallback(({ group_id, offset, query }) => {
    getEndedSharedOffers({ group_id, offset, query })
    .catch(e => setError(e))
    .finally(() => setOfferingsLoading(false));
  }, [getEndedSharedOffers, setError]);
  const fetchClosedOfferings = useCallback(({ offset, group_id, query }) => {
    getClosedSharedOffers({ offset, group_id, query })
    .catch(e => setError(e))
    .finally(() => setOfferingsLoading(false));
  }, [getClosedSharedOffers, setError]);
  const fetchInContractOfferings = useCallback(({ group_id, offset, query }) => {
    getInContractSharedOffers({ group_id, offset, query })
    .catch(e => setError(e))
    .finally(() => setOfferingsLoading(false));
  }, [getInContractSharedOffers, setError]);
  const fetchOfferings = useCallback(({ group_id, type, query, offset }) => {
    if (type === 'draft') fetchDraftOfferings({ group_id, query, offset });
    if (type === 'active') fetchActiveOfferings({ group_id, query, offset });
    if (type === 'ended') fetchEndedOfferings({ group_id, query, offset });
    if (type === 'closed') fetchClosedOfferings({ group_id, query, offset });
    if (type === 'inContract') fetchInContractOfferings({ group_id, query, offset });
  }, [fetchActiveOfferings, fetchDraftOfferings, fetchEndedOfferings, fetchInContractOfferings, fetchClosedOfferings]);
  /**
   * Пагинация диллспейсов
   */
  const handleLoadMoreDealspaces = () => fetchDealspaces({ group_id, query: dealspacesQuery, offset: dealspacesOffset });
  /**
   * Пагинация офферингов
   */
  const handleLoadMoreOfferings = () => {
    if (offeringsType === 'draft') fetchOfferings({ type: offeringsType, group_id, query: offeringsQuery, offset: draftOffersOffset });
    if (offeringsType === 'active') fetchOfferings({ type: offeringsType, group_id, query: offeringsQuery, offset: activeOffersOffset });
    if (offeringsType === 'ended') fetchOfferings({ type: offeringsType, group_id, query: offeringsQuery, offset: endedOffersOffset });
    if (offeringsType === 'closed') fetchOfferings({ type: offeringsType, group_id, query: offeringsQuery, offset: closedOffersOffset });
    if (offeringsType === 'inContract') fetchOfferings({ type: offeringsType, group_id, query: offeringsQuery, offset: inContractOffersOffset });
  };
  /**
   * Загрузка диллспейсов
   */
  useEffect(() => {
    let timeout = setTimeout(() => {
      clearLocalDealspaces().then(() => {
        setDealspacesLoading(true);
        fetchDealspaces({ group_id, query: dealspacesQuery, offset: 0 });
      });
    }, !!dealspacesQuery ? 500 : 0);
    
    return () => {
      clearTimeout(timeout);
      clearLocalDealspaces();
    };
  }, [fetchDealspaces, clearLocalDealspaces, group_id, dealspacesQuery]);
  /**
   * Загрузка офферингов текущего типа. Работает только при смене query.
   */
  useEffect(() => {
    let timeout = setTimeout(() => {
      if (!!offeringsQuery || !!previousOfferingQuery) {
        clearLocalSharedOffers(offeringsType).then(() => {
          setOfferingsLoading(true);
          fetchOfferings({ type: offeringsType, group_id, query: offeringsQuery, offset: 0 });
        });
      }
    }, !!offeringsQuery ? 500 : 0);
    
    return () => {
      clearTimeout(timeout);
    };
  }, [fetchOfferings, clearLocalSharedOffers, group_id, offeringsQuery, offeringsType]); // eslint-disable-line react-hooks/exhaustive-deps


  /**
   * Загрузка офферингов других типов
   */
  useEffect(() => {
    fetchOfferings({ type: 'draft', group_id, query: '', offset: 0 });
    fetchOfferings({ type: 'active', group_id, query: '', offset: 0 });
    fetchOfferings({ type: 'ended', group_id, query: '', offset: 0 });
    fetchOfferings({ type: 'closed', group_id, query: '', offset: 0 });
    fetchOfferings({ type: 'inContract', group_id, query: '', offset: 0 });
    
    return () => {
      clearLocalSharedOffers('all');
    };
  }, [fetchOfferings, clearLocalSharedOffers, group_id]);

  return (
    <Box sx={{ padding: { xs: '24px 16px', md: '40px 16px' } }}>
      <Container maxWidth={false} disableGutters sx={{ maxWidth: 1224, display: 'flex', flexDirection: 'column', gap: { xs: '24px', md: '32px' }, padding: '12px' }}>
        <DealspaceDialog
          {...dealspaceDialog}
          onClose={handleDealspaceDialogClose}
        />
        {fetching ? <Skeleton variant='rectangular' width='100%' height={300} /> :
          showEmpty ? <EmptyContent onCreate={handleCreateClick} /> :
            <>
              <StyledHead>
                <Tabs sx={{ maxWidth: { xs: 320, sm: 480, md: 800 } }} variant="standard" scrollButtons="auto" value={tab} onChange={handleTabsChange}>
                  <Tab
                    value='dealspaces'
                    label='Dealspaces'
                    LinkComponent={Link}
                    to={`/groups/${group_id}/dealspaces`}
                  />
                  <Tab
                    value='offerings'
                    label='Shared Offerings'
                    LinkComponent={Link}
                    to={`/groups/${group_id}/offerings`}
                  />
                </Tabs>
                {showCreateButton && <StyledButton startIcon={<AddIcon />} onClick={handleCreateClick}>Create Dealspace</StyledButton>}
              </StyledHead>
              {tab === 'dealspaces' ?
                <DealspacesGrid
                  fetching={dealspacesLoading}
                  query={dealspacesQuery}
                  onQueryChange={v => setDealspacesQuery(v)}
                  onLoadMore={handleLoadMoreDealspaces}
                /> :
                <SharedOfferings
                  fetching={offeringsLoading}
                  query={offeringsQuery}
                  onQueryChange={v => setOfferingsQuery(v)}
                  type={offeringsType}
                  onTypeChange={v => setOfferingsType(v)}
                  onLoadMore={handleLoadMoreOfferings}
                />
              }
            </>
        }
      </Container>
    </Box>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Details {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);