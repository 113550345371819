import styled from '@mui/material/styles/styled';

export const Root = styled('div')(({ theme }) => ({
  width: '100%',
  borderTop: `1px solid ${theme.palette.divider}`,
  marginTop: 50,
  '& .tox-editor-header > .tox-toolbar':{
    background: 'unset !important',
  },
  '& .tox-toolbar > .tox-toolbar__group':{
    marginTop: 8,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  '& .tox-statusbar': {
    borderTop: 'none !important'
  },
  '& .tox-sidebar-wrap': {
    paddingTop: 25,
    paddingBottom: 5
  },
}));