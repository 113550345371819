import { memo } from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
// Local files
import { backgroundColor } from './Head.styled';
import TableSortingCell from 'components/Common/DataDisplay/BaseTable/TableSortingCell/TableSortingCell';

const headCells = [
  { id: 'name', title: 'Name', align: 'left', hideSort: true },
  { id: 'updated_at', title: 'Date', align: 'left' },
  { id: 'description', title: 'Action', align: 'left' }
];

const Head = ({ order, orderBy, onSortChange }) => {
  return (
    <TableHead sx={{ background: backgroundColor }}>
      <TableRow>
        <TableSortingCell
          sx={{
            whiteSpace: 'nowrap',
            background: backgroundColor,
            '& .Mui-active .MuiTableSortLabel-icon': {
              color: '#4877F0 !important'
            }
          }}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          changeSorting={onSortChange}
        />
      </TableRow>
    </TableHead>
  );
};

Head.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onSortChange: PropTypes.func.isRequired
};

export default memo(Head);