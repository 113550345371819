import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import WKT from 'terraformer-wkt-parser';
import _map from 'lodash/map';
import { useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import MenuVert from 'components/Common/Icons/MenuVert';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { Content, Map, MapContainer, MenuButton, Root, Text } from './Card.styled';
import useApp from 'hooks/useApp';
import { googleApiKey } from 'apis';
import { DELETE_LOCATION } from 'helpers/confirmations';

const Card = ({ id, address, latitude, longitude, parcel, ...otherProps }) => {
  const { property_id } = useParams();
  const { openDeleteDialog } = useApp();
  const coords = !!parcel ? WKT.parse(parcel).coordinates[0][0] : [];
  const result = _map(coords, c => c.reverse().join(','));
  const [anchorEl, setAnchorEl] = useState(null);
  const src = 'https://maps.googleapis.com/maps/api/staticmap?center='
    + `${latitude},${longitude}`
    + '&zoom=17&size=210x200'
    + `&key=${googleApiKey}`
    + '&map_id=277f6b2bc652ed12'
    + `&path=color:0x000000|weight:1|fillcolor:0xd4e0ff|`
    + result.join('|');

  const handleDeleteClick = () => {
    setAnchorEl(null);
    openDeleteDialog({ type: DELETE_LOCATION, id, data: { property_id } });
  };

  return (
    <Root {...otherProps}>
      <MenuButton onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>
        <MenuVert />
      </MenuButton>
      <Menu keepMounted open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        {/*<MenuItem onClick={handleEditClick}>
          <Typography variant='caption' component='div' color='inherit' noWrap sx={{ fontWeight: 600 }}>Edit</Typography>
        </MenuItem>*/}
        <MenuItem onClick={handleDeleteClick}>
          <Typography variant='caption' component='div' color='red' noWrap sx={{ fontWeight: 600 }}>Delete</Typography>
        </MenuItem>
      </Menu>
      <MapContainer>
        <Map>
          <img alt={address} src={src} />
        </Map>
      </MapContainer>
      <Content>
        <Text>{address}</Text>
      </Content>
    </Root>
  );
};

Card.propTypes = {
  id: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  parcel: PropTypes.string.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Card {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);