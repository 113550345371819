import styled from '@mui/material/styles/styled';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';

export const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection:  'column',
  justifyContent: 'center',
  gap: '12px',
  padding: '8px 0 32px',
  [theme.breakpoints.up('lg')]: {
    flexDirection:  'row',
  }
}));

export const NavContainer = styled(Box)(({ theme }) => ({
  //display: 'none',
  width: 170,
  padding: '24px 0px',
  [theme.breakpoints.up('md')]: {
    display: 'block'
  }
}));

export const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  gap: '8px',
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 600,
  color: 'rgba(0,0,0,.7)',
  paddingTop: 10,
  paddingBottom: 10,
  '& > span': {
    fontWeight: 400,
  }
}));

export const Item = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

export const DealspaceInfo = styled(Box)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  gap: '22px',
  marginBottom: '8px',
}));

export const Image = styled(Box)({
  width: 54,
  height: 34,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center'
});