import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getSavedOfferGroups as getSavedOfferGroupsAction,
  getGroups as getGroupsAction,
  getGroup as getGroupAction,
  createGroup as createGroupAction,
  updateGroup as updateGroupAction,
  deleteGroup as deleteGroupAction,
  setGroupUnreadCountConversations as setGroupUnreadCountConversationsAction,
  clearLocalSavedOfferGroups as clearLocalSavedOfferGroupsAction,
  clearLocalGroups as clearLocalGroupsAction,
  clearLocalActiveGroup as clearLocalActiveGroupAction
} from 'actions/groups';

const useGroups = () => {
  const dispatch = useDispatch();
  const getSavedOfferGroups = useCallback(offer_id => dispatch(getSavedOfferGroupsAction(offer_id)), [dispatch]);
  const getGroups = useCallback(({ limit, offset, orders }) => dispatch(getGroupsAction({ limit, offset, orders })), [dispatch]);
  const getGroup = useCallback(id => dispatch(getGroupAction(id)), [dispatch]);
  const createGroup = useCallback(group => dispatch(createGroupAction(group)), [dispatch]);
  const updateGroup = useCallback(group => dispatch(updateGroupAction(group)), [dispatch]);
  const deleteGroup = useCallback(id => dispatch(deleteGroupAction(id)), [dispatch]);
  const setGroupUnreadCountConversations = useCallback((params) => dispatch(setGroupUnreadCountConversationsAction(params)), [dispatch]);
  const clearLocalSavedOfferGroups = useCallback(() => dispatch(clearLocalSavedOfferGroupsAction()), [dispatch]);
  const clearLocalGroups = useCallback(() => dispatch(clearLocalGroupsAction()), [dispatch]);
  const clearLocalActiveGroup = useCallback(() => dispatch(clearLocalActiveGroupAction()), [dispatch]);

  return {
    getSavedOfferGroups,
    getGroups,
    getGroup,
    createGroup,
    updateGroup,
    deleteGroup,
    setGroupUnreadCountConversations,
    clearLocalSavedOfferGroups,
    clearLocalGroups,
    clearLocalActiveGroup
  };
};

export default useGroups;