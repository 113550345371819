import { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const About = ({ activeUser }) => {
  return (
    <Box>
      <Typography whiteSpace='pre-line' variant='caption'>{activeUser.about ?? ''}</Typography>
    </Box>
  );
};

export default memo(About);