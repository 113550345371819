import { memo, useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Scrollbars from 'react-custom-scrollbars-2';
import _find from 'lodash/find';
import _map from 'lodash/map';
// Local files
import { Root, Header, TitleContainer, Content } from './List.styled';
import Notification from '../Notification/Notification';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useNotifications from 'hooks/useNotifications';

const List = () => {
  const { setError } = useError();
  const { getNotifications, readNotifications } = useNotifications();
  const { notifications, total, needToLoadMore, newOffset, fetching } = useCustomSelector(state => ({
    notifications: state.notifications.all.data,
    total: state.notifications.count,
    needToLoadMore: state.notifications.all.pagination.total_count > state.notifications.all.pagination.count + state.notifications.all.pagination.offset,
    newOffset: state.notifications.all.pagination.limit + state.notifications.all.pagination.offset,
    fetching: state.notifications.all.fetching
  }));
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [processableNotifications, setProcessableNotification] = useState([]);

  const fetchNotifications = useCallback(offset => {
    getNotifications({ offset }).catch(e => setError(e));
  }, [getNotifications, setError]);
  const handleScroll = ({ target }) => {
    if (target.scrollTop === target.scrollHeight - target.clientHeight && !fetching && needToLoadMore) {
      fetchNotifications(newOffset);
    }
  };
  const handleReadNotification = id => {
    const foundNotification = _find(notifications, n => n.id === id && !n.read);

    if (foundNotification) {
      setProcessableNotification(prev => [...prev, id]);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!!processableNotifications.length) {
        readNotifications(processableNotifications)
        .then(() => setProcessableNotification([]))
        .catch(e => setError(e));
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [processableNotifications, readNotifications, setError]);

  return (
    <Root>
      <Header>
        <TitleContainer>
          <Typography variant='subtitle2'>Notifications {total > -1 ? total : ''}</Typography>
        </TitleContainer>
      </Header>
      <Content>
        {fetching &&
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        }
        <Scrollbars autoHide autoHideTimeout={1000} onScroll={handleScroll}>
          {_map(notifications, n =>
            <Notification
              key={n.id}
              selectedNotification={selectedNotification}
              onSelect={v => setSelectedNotification(v)}
              onRead={() => handleReadNotification(n.id)}
              {...n}
            />
          )}
        </Scrollbars>
      </Content>
    </Root>
  );
};

export default memo(List);