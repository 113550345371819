import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

export const Header = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px 0',
});

export const Title = styled(Typography)({
  fontWeight: 700,
  '& > span': {
    fontWeight: 400,
    marginLeft: '.5rem'
  }
});

export const StyledButton = styled(ButtonBase)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  textDecoration: 'underline',
  borderRadius: '2px',
  paddingRight: 4,
  marginRight: '-4px'
}));

export const Grid = styled(Box)({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
  gridRowGap: '20px',
  gridColumnGap: '16px',
  '& > div': {
    width: '100%',
    minWidth: 'unset'
  }
});

export const MoreButton = styled(ButtonBase)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 600,
  borderRadius: '4px',
  color: '#8f9ea8',
  background: '#F4F6F8',
  border: '0.5px solid #0000001A',
  '&:hover': {
    boxShadow: '3px 3px 5px -2px #020D174D',
  }
}));

export const FileCardSkeleton = styled(Skeleton)({
  transform: 'scale(1)',
  width: 185,
  height: 140,
});