import styled from '@mui/material/styles/styled';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const filterHeight = 100;

export const Root = styled(Paper)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '2px 4px 8px 0px #0000001A',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    borderRadius: '10px',
    overflow: 'hidden'
  }
}));

export const Nav = styled(Box)(({ theme }) => ({
  width: '180px',
  height: '100%',
  borderRight: '1px solid',
  borderColor: theme.palette.divider,
}));

export const Header = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  padding: '18px',
});

export const CreateButtonContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
    minHeight: filterHeight,
    paddingTop: 16,
    paddingLeft: 16,
  }
}));

export const FilterContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: filterHeight,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  background: theme.palette.background.paper,
  borderBottom: '1px solid',
  borderColor: theme.palette.divider,
  padding: '8px 18px',
  [theme.breakpoints.up('md')]: {
    padding: '8px',
  }
}));

export const Content = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.background.default,
  [theme.breakpoints.up('md')]: {
    width: 350,
  }
}));