import styled from '@mui/material/styles/styled';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

export const Root = styled('div')({
  flexGrow: 1,
  backgroundColor: '#F8F9FA',
  overflow: 'auto',
});

export const Header = styled('div')({
  width: '100%',
  color: 'rgba(0,0,0,.7)',
  backgroundColor: '#fff',
  padding: '30px 40px 20px',
});

export const Content = styled('div')({
  width: '100%',
  maxWidth: 650,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: 80,
  margin: '0 auto',
});

export const SenderAvatar = styled(Avatar)({
  width: 58,
  height: 58,
  border: '1px solid #D8D8D8',
  marginTop: 34,
});

export const StyledDivider = styled(Divider)({
  width: '100%',
  borderColor: '#D2CACA',
  marginTop: 30,
});

export const Paper = styled('div')({
  width: '100%',
  backgroundColor: '#fff',
  boxShadow: '2px 2px 8px 0px #5A54541A',
  border: '1px solid #E5E5E8',
  marginTop: 25
});

export const Tag = styled('div')({
  fontSize: '0.75rem',
  fontWeight: 600,
  color: '#51515D',
  border: '1px solid #B7B7B7',
  borderRadius: 35,
  padding: '2px 12px',
});

export const Logo = styled('img')({
  display: 'block',
  width: '100%',
  minHeight: 80,
  maxHeight: 200,
  objectFit: 'cover'
});

export const Photo = styled('img')({
  userSelect: 'none',
  display: 'block',
  height: '100%',
  maxHeight: 450,
  width: 'auto',
  maxWidth: '100%',
  objectFit: 'cover',
  margin: '0 auto'
});

export const Description = styled('div')({
  paddingTop: 0,
  paddingBottom: 0,
  '& img': {
    maxWidth: '600px'
  }
});

export const StyledButton = styled(Button)({
  height: 'auto',
  display: 'flex',
  fontSize: '0.875rem',
  fontWeight: 700,
  color: '#fff',
  backgroundColor: '#3A3A3A !important',
  padding: '8px 40px',
  margin: '0 auto',
});