import { memo } from 'react';
// Local files
import { StyledButton } from './EditButton.styled';
import EditIcon from 'components/Common/Icons/EditIcons';

const EditButton = ({ onClick, sx, disabled }) => {
  return (
    <StyledButton disabled={disabled} onClick={onClick} sx={sx}>
      <EditIcon />
    </StyledButton>
  );
};

export default memo(EditButton);