import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getAgreements as getAgreementsAction,
  getAgreement as getAgreementAction,
  createAgreement as createAgreementAction,
  clearLocalAgreements as clearLocalAgreementsAction
} from 'actions/agreements';

const useAgreements = () => {
  const dispatch = useDispatch();
  const getAgreements = useCallback(({ limit, offset, orders }) => dispatch(getAgreementsAction({ limit, offset, orders })), [dispatch]);
  const getAgreement = useCallback(id => dispatch(getAgreementAction(id)), [dispatch]);
  const createAgreement = useCallback(({ offer_id, agreement }) => dispatch(createAgreementAction({ offer_id, agreement })), [dispatch]);
  const clearLocalAgreements = useCallback(() => dispatch(clearLocalAgreementsAction()), [dispatch]);

  return { getAgreements, getAgreement, createAgreement, clearLocalAgreements };
};

export default useAgreements;