import { memo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import moment from 'moment';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars-2';
// Local files
import { TableCell } from './Table.styled';
import TablePagination from 'components/Common/DataDisplay/BaseTable/TablePagination/TablePagination';
import Head from './Head/Head';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { representationVariants, chatLevels } from 'helpers/constants';
import { getRepresentation, getFormattedDateWithTime } from 'helpers';
import useApp from 'hooks/useApp';
import useConversations from 'hooks/useConversations';
import useCustomSelector from 'hooks/useCustomSelector';

const getFormattedActivityType = v => {
  if (v === 'visited') return 'Visited Offering';
  if (v === 'email_opened') return 'Email Opens';
  if (v === 'tracked') return 'Tracked Offering';
  if (v === 'saved') return 'Saved Offering';
  if (v === 'unlocked') return 'Signed CA';
  return '';
};

const ActivityTable = ({ page, query, order, orderBy, onPageChange, onChange, rowsPerPage }) => {
  const navigate = useNavigate();
  const previousLocation = useLocation();
  const { offer_id } = useParams();
  const { setChatsFilter } = useApp();
  const { getOfferConversations, clearLocalConversations, openOrCreateConversation } = useConversations();
  const { fetching, data, activitiesTotalCount, groupId, myId, isMyShared } = useCustomSelector(state => ({
    ..._pick(state.activities.table, ['data', 'fetching']),
    activitiesTotalCount: state.activities.table.pagination.total_count,
    myId: state.profile.user.id,
    groupId: state.offers.activityLog.dealspace.group.id,
    isMyShared: !!state.senders.all.data?.length ? !!_find(state.senders.all.data, ({ user }) => user?.id === state.profile.user.id) : null
  }));
  const [processing, setProcessing] = useState(false);

  const handleOrdersChange = v => {
    if (v === orderBy) {
      onChange({ order: order === 'asc' ? 'desc' : 'asc' });
    } else {
      onChange({ order: 'asc', orderBy: v });
    }
  };
  /*** Conversation ***/
  const handleConversationClick = (user_ids = null) => {
    if (!!user_ids?.length) {
      setProcessing(true);
      clearLocalConversations()
      .then(() => {
        getOfferConversations({ offset: 0, offer_id, feed: !isMyShared, offer: isMyShared })
        .then(({ payload: { response: { data: { conversations } } } }) => {
          const conversation = _find(conversations, ({ users }) => _isEqual(_map(_filter(users, u => u.id !== myId), (user) => user.id).sort(), user_ids?.sort()));
    
          openOrCreateConversation(user_ids, conversation, isMyShared);
          setProcessing(false);
        })
      })
    } else {
      setChatsFilter({ filter: { offer_id } })
      .then(() => 
        navigate(`/chats/${isMyShared ? chatLevels.GROUP : chatLevels.USER}/${isMyShared ? groupId : myId}`, { state: { previousLocation } }
      ));
    }
  };

  return (
    <Box display='flex' flexDirection='column' style={{height: '100%', weight: '100%'}}>
      <Scrollbars autoHideTimeout={1000} >
        <Table stickyHeader sx={{ background: '#fff', borderTop: '1px solid #EAEAEA' }}>
          <Head
            query={query}
            order={order}
            orderBy={orderBy}
            onQueryChange={q => onChange({ query: q })}
            onSortChange={handleOrdersChange}
          />
          <TableBody>
            {fetching ?
              <TableRow>
                <TableCell><Skeleton animation='wave' /></TableCell>
                <TableCell><Skeleton animation='wave' /></TableCell>
                <TableCell><Skeleton animation='wave' /></TableCell>
                <TableCell><Skeleton animation='wave' /></TableCell>
                <TableCell><Skeleton animation='wave' /></TableCell>
              </TableRow>
            :
            _map(data, ({ id, recipient, activity_type, created_at, visits_duration }) => {
              const hasUser = !!recipient?.user;
              const photoSrc = hasUser ? getRepresentation(recipient.user.photo?.representations, representationVariants.TINY) : null;
              const firstName = hasUser ? recipient.user.first_name : recipient.first_name;
              const lastName = hasUser ? recipient.user.last_name : recipient.last_name;
              const email = hasUser ? recipient.user.email : recipient.email;
              const companyName = hasUser ? recipient.user.company_name : null;
              const duration = activity_type === 'visited' && visits_duration ? moment.duration(visits_duration).humanize() : '';
              const device = hasUser ? recipient.user.device : '';
              const location = hasUser ? recipient.user.actual_location : '';
              
              return (
                <TableRow hover={false} key={id}>
                  <TableCell align='left'>
                    <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', gap: '4px' }}>
                      <Button
                        sx={{ ...!hasUser && { visibility: 'hidden' } }}
                        variant='blank'
                        color='neutral'
                        disabled={processing || isMyShared === null}
                        onClick={() => handleConversationClick([recipient.user.id])}
                      >
                        <ChatIcon />
                      </Button>
                      <BaseAvatar
                        sx={{ mx: 1.5 }}
                        src={photoSrc}
                        firstName={firstName}
                        lastName={lastName}
                      />
                      <Typography variant='caption' pr={2}>
                        {firstName} {lastName}
                      </Typography>
                      <Typography variant='caption' px={1}>
                        {email}
                      </Typography>
                      <Typography variant='caption' px={1}>
                        {companyName ?? ''}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align='left' sx={{ fontWeight: 700 }}>
                    {getFormattedActivityType(activity_type)} {duration}
                  </TableCell>
                  <TableCell align='left'>
                    {getFormattedDateWithTime(created_at)}
                  </TableCell>
                  <TableCell align='left'>
                    {device}
                  </TableCell>
                  <TableCell align='left'>
                    {location}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbars>
      <TablePagination
        sx={{ overflow: 'hidden'}}
        backIconButtonProps={ fetching ? { disabled: fetching } : undefined }
        nextIconButtonProps={ fetching ? { disabled: fetching } : undefined }
        count={activitiesTotalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, newPage) => onPageChange(newPage)}
      />
    </Box>
  );
};

ActivityTable.propTypes = {
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  query: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ActivityTable {...props} />
    </ErrorBoundary>
  );
};

export const Fallback = () => {
  return (
    <TableRow>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
    </TableRow>
  );
};

export default memo(WrappedComponent);