import styled from '@mui/material/styles/styled';
import MuiBadge from '@mui/material/Badge';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const Root = styled('div')(({ theme }) => ({
  flexShrink: 0,
  height: '100%',
  width: '62px',
  backgroundColor: theme.palette.section.chat.background.main,
  boxShadow: '-2px 0px 6px 2px rgba(0, 0, 0, 0.08)',
}));

const Content = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  padding: '22px 0px',
});

const Badge = styled(MuiBadge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 3,
    right: 3,
    height: 10,
    width: 10,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.section.chat.background.main}`,
  },
}));

const StyledTabs = styled(Tabs)({
  width: '100%',
  '& .MuiTabs-indicator': {
    width: 4,
    backgroundColor: '#fff',
    borderTopLeftRadius: '12px',
    borderBottomLeftRadius: '12px',
  }
});

const StyledTab = styled(Tab)({
  minHeight: '47px',
  minWidth: 'unset',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  marginBottom: '12px'
});

export { Root, Content, Badge, StyledTabs, StyledTab };