import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';

export const Text = styled(Typography)({
  display: 'block',
  maxWidth: 315,
  lineHeight: 1.8,
  color: '#000',
  textAlign: 'center',
  paddingBottom: 18,
  margin: '0 auto'
});

export const StyledInput = styled((props) => (
  <Input disableUnderline {...props} />
))({
  fontSize: '10px',
  color: '#808080',
  backgroundColor: 'rgba(217,217,217,0.19)',
  borderRadius: '10px',
  padding: '8px 12px',
});
