import styled from '@mui/material/styles/styled';
import MuiTableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';

export const backgroundColor = '#F8F9FB';

export const TableCell = styled(MuiTableCell)({
  fontSize: '0.75rem',
  color: 'rgba(0,0,0,.7)',
  padding: '4px 12px 16px',
  '&:first-of-type': {
    padding: '4px 12px 4px',
  }
});

export const CustomBadge = styled(Box)({
  height: '15px',
  width: '15px',
  borderRadius: '35px',
  backgroundColor: 'rgba(18, 163, 59, 1)',
  fontWeight: 600,
  fontSize: '10px',
  lineHeight: '14px',
  color: 'white'
});

export const Title = styled('div')(() => ({
  width: '100%',
  display: 'block',
  fontSize: ' .75rem',
  position: 'relative',
  fontWeight: 700,
  color: '#45464C',
}));

export const Subtitle = styled('div')(() => ({
  position: 'absolute',
  bottom: '-10px',
  left: 0,
  fontSize: '10px',
  lineHeight: 1,
  minHeight: '12px',
  fontWeight: 500,
  color: '#45464C'
}));