import { memo } from 'react';
import TextField from '@mui/material/TextField';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';

const FormSelect = ({ multiple = false, disabled = false, onScroll, paperVariant = 'outlined', paperColor = 'default', error = false, helperText = null, onBlur, ...otherProps }) => {
  return (
    <TextField
      select
      disabled={disabled}
      SelectProps={{
        multiple,
        MenuProps: {
          PaperProps: { variant: paperVariant, color: paperColor, elevation: 0, sx: { marginTop: '4px' }, onScroll }
        }
      }}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      {...otherProps}
    />
  );
};

FormSelect.propTypes = {
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  onScroll: PropTypes.func,
  paperVariant: PropTypes.string,
  paperColor: PropTypes.string,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <FormSelect {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);