import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getMessagesRoutine,
  getMessageRoutine,
  createMessageRoutine,
  createLocalMessageRoutine,
  deleteMessageRoutine,
  deleteLocalMessageRoutine,
  readMessagesRoutine,
  setLocalMessageErrorRoutine,
  setLocalReadStatusRoutine,
  clearLocalMessagesRoutine,
  clearLocalActiveMessageRoutine
} from 'actions';

const attributes = [
  'id', 
  { user: ['id', 'username', 'first_name', 'last_name', { photo: ['id', 'representations'] }]}, 
  { attachment: ['id', 'filename', 'representations', 'url', 'content_type'] }, 
  'text', 'read', 'created_at'
];

export const getMessages = getThunkActionCreator(
  getMessagesRoutine,
  async ({ limit = 25, offset = 0, orders = { updated_at: 'desc' }, conversation_id, center_on_unread_message = false, direction = null, my_id = null }) => {
    const params = { limit, offset, orders, attributes, ...center_on_unread_message && { center_on_unread_message } };

    const response =  await axios.get(`/conversations/${conversation_id}/messages`, { params });

    return { response, center_on_unread_message, direction, my_id };
  }
);
export const getMessage = getThunkActionCreator(
  getMessageRoutine,
  async ({ conversation_id, message_id, isConversationMessage }) => {
    const params = { attributes };
    const response = await axios.get(`/messages/${message_id}`, { params });

    return { response, conversation_id, isConversationMessage };
  }
);
export const createMessage = getThunkActionCreator(
  createMessageRoutine,
  async ({ message, conversation_id, local_id }) => {
    const data = { message, attributes };
    const response = await axios.post(`/conversations/${conversation_id}/messages`, data);

    return { response, local_id, conversation_id };
  }
);
export const deleteMessage = getThunkActionCreator(
  deleteMessageRoutine,
  async id => {
    const response = await axios.delete(`/message/${id}`);

    return { response, id };
  }
);
export const readMessages = getThunkActionCreator(
  readMessagesRoutine,
  async ({ conversation_id, message_ids = null }) => {
    const data = { ...message_ids && { message_ids } };
    const response = await axios.post(`/conversations/${conversation_id}/read`, data);

    return { response, conversation_id, message_ids };
  }
);
export const createLocalMessage = getThunkActionCreator(createLocalMessageRoutine, async message => message);
export const deleteLocalMessage = getThunkActionCreator(deleteLocalMessageRoutine, async id => id);
export const setLocalMessageError = getThunkActionCreator(setLocalMessageErrorRoutine, async id => ({ id }));
export const setLocalReadStatus = getThunkActionCreator(setLocalReadStatusRoutine, async id => ({ id }));
export const clearLocalMessages = getThunkActionCreator(clearLocalMessagesRoutine, async () => {});
export const clearLocalActiveMessage = getThunkActionCreator(clearLocalActiveMessageRoutine, async () => {});