import { memo } from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const BackButton = ({ onClick }) => {
  return (
    <Typography
      fontWeight={400}
      fontSize='10px'
      lineHeight='20px'
      sx={{ cursor: 'pointer', textDecoration: 'underline', alignSelf: 'flex-start', mb: '75px' }}
      onClick={onClick}
    >
      Back to Sign In
    </Typography>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default memo(BackButton);