import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Root = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});
export const ImagePreview = styled('img')({
  display: 'block',
  width: 80,
  height: 80,
  objectFit: 'cover',
  borderRadius: '2px',
});
export const VariantsContainer = styled(Box)({
  display: 'flex',
  gap: '10px',
  padding: '18px 0 0',
});
export const UploadContainer = styled(Box)(({ theme }) => ({
  current: 'pointer',
  height: 48,
  width: 48,
  backgroundColor: 'transparent',
  border: `1px dashed ${theme.palette.primary.main}`,
  borderRadius: '3px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));
export const FileInput = styled('input')({ display: 'none' });