import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  setContactsFilters as setContactsFiltersAction,
  getContactsList as getContactsListAction,
  getContacts as getContactsAction,
  getSharedContacts as getSharedContactsAction,
  getSuggestedContacts as getSuggestedContactsAction,
  getContact as getContactAction,
  createContact as createContactAction,
  updateContact as updateContactAction,
  deleteContact as deleteContactAction,
  importConcatcs as importConcatcsAction,
  validateContactEmail as validateContactEmailAction,
  clearLocalContactsList as clearLocalContactsListAction,
  clearLocalContacts as clearLocalContactsAction,
  clearLocalSharedContacts as clearLocalSharedContactsAction,
  clearLocalActiveContact as clearLocalActiveContactAction,
  filterLocalContacts as filterLocalContactsAction,
  setUploadingFile as setUploadingFileAction,
  clearUploadingFiles as clearUploadingFilesAction
} from 'actions/contacts';

const useContacts = () => {
  const dispatch = useDispatch();
  const setContactsFilters = useCallback(({ page, offset, orders, query, lists, tab, member, pending, quarantined }) => dispatch(setContactsFiltersAction({ page, offset, orders, query, lists, tab, member, pending, quarantined })), [dispatch]);
  const getContactsList = useCallback(({ offset, query, member, not_member }) => dispatch(getContactsListAction({ offset, query, member, not_member })), [dispatch]);
  const getContacts = useCallback(({ limit, offset, orders, lists, query, pending, member, quarantined }) => dispatch(getContactsAction({ limit, offset, orders, lists, query, pending, member, quarantined })), [dispatch]);
  const getSharedContacts = useCallback(({ limit, offset, orders, list_ids, query, offer_id }) => dispatch(getSharedContactsAction({ limit, offset, orders, list_ids, query, offer_id })), [dispatch]);
  const getSuggestedContacts = useCallback(({ list_ids, included_contact_ids, excluded_contact_ids }) => dispatch(getSuggestedContactsAction({ list_ids, included_contact_ids, excluded_contact_ids })), [dispatch]);
  const getContact = useCallback(id => dispatch(getContactAction(id)), [dispatch]);
  const createContact = useCallback(({ contact, isAllContacts }) => dispatch(createContactAction({ contact, isAllContacts })), [dispatch]);
  const updateContact = useCallback(contact => dispatch(updateContactAction(contact)), [dispatch]);
  const deleteContact = useCallback(id => dispatch(deleteContactAction(id)), [dispatch]);
  const importContacts = useCallback(({ file, contact }) => dispatch(importConcatcsAction({ file, contact })), [dispatch]);
  const validateContactEmail = useCallback(email => dispatch(validateContactEmailAction(email)), [dispatch]);
  const clearLocalContactsList = useCallback(() => dispatch(clearLocalContactsListAction()), [dispatch]);
  const clearLocalContacts = useCallback(() => dispatch(clearLocalContactsAction()), [dispatch]);
  const clearLocalSharedContacts = useCallback(() => dispatch(clearLocalSharedContactsAction()), [dispatch]);
  const clearLocalActiveContact = useCallback(() => dispatch(clearLocalActiveContactAction()), [dispatch]);
  const filterLocalContacts = useCallback((ids) => dispatch(filterLocalContactsAction(ids)), [dispatch]);
  const setUploadingFile = useCallback(({ file_id, filename, lines_count, progress }) => dispatch(setUploadingFileAction({ file_id, filename, lines_count, progress })), [dispatch]);
  const clearUploadingFiles = useCallback(() => dispatch(clearUploadingFilesAction()), [dispatch]);

  return {
    setContactsFilters,
    getContactsList,
    getContacts,
    getSharedContacts,
    getSuggestedContacts,
    getContact,
    createContact,
    updateContact,
    deleteContact,
    importContacts,
    validateContactEmail,
    clearLocalContactsList,
    clearLocalContacts,
    clearLocalSharedContacts,
    clearLocalActiveContact,
    filterLocalContacts,
    setUploadingFile,
    clearUploadingFiles
  };
};

export default useContacts;