import styled from '@mui/material/styles/styled';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';

export const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  maxWidth: 150,
  color: 'rgba(0, 0, 0, 0.7)',
  [`& .${treeItemClasses.content}`]: {
    color: 'rgba(0, 0, 0, 0.7)',
    background: 'rgba(244, 246, 248, 1)',
    borderRadius: 5,
    paddingRight: theme.spacing(1),
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));