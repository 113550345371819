import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';
import MuiListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export const Banner = styled(Box)({
  minHeight: 80,
  width: '100%',
  position: 'relative',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center'
});

export const Main = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: '1px solid #000',
  padding: '0 24px 16px'
});

export const StyledButton = styled(props => (
  <Button variant='outlined' {...props}/>
))({
  fontWeight: 400,
  color: '#E3E6EA',
  borderColor: '#E3E6EA !important'
});

export const Title = styled(props => (
  <Typography variant='body2' align='center' noWrap {...props}/>
))({
  width: '100%',
  color: '#fff',
  padding: '8px 4px 4px'
});

export const Description = styled(props => (
  <Typography variant='small' align='center' noWrap {...props}/>
))({
  width: '100%',
  color: '#AAAEB2',
  padding: '0 8px 18px'
});

export const ListItemIcon = styled(MuiListItemIcon)({
  minWidth: 'unset',
  width: 32,
  height: 24,
  color: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingRight: 8
});

export const ListItem = styled(MuiListItem)(({ theme }) => ({
  padding: 0,
  '&:hover': {
    backgroundColor: theme.palette.background.hoverDark
  }
}));

export const ListItemText = styled((props) => (
  <MuiListItemText primaryTypographyProps={{ color: 'inherit', variant: 'caption' }} {...props} />
))(({ theme }) => ({
  fontSize: theme.typography.caption
}));