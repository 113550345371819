import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { sessionService } from 'redux-react-session';
import { createConsumer } from '@rails/actioncable';
import { useLocation } from 'react-router-dom';
import _omit from 'lodash/omit';
import _find from 'lodash/find';
// Local files
import { wsURL } from 'apis';
import AudioPlayer from 'components/Common/AudioPlayer/AudioPlayer';
import { cableMessageTypes } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useArchives from 'hooks/useArchives';
import useAudits from 'hooks/useAudits';
import useChameleon from 'hooks/useChameleon';
import useContacts from 'hooks/useContacts';
import useConversations from 'hooks/useConversations';
import useCustomSelector from 'hooks/useCustomSelector';
import useDealspaces from 'hooks/useDealspaces';
import useDocuments from 'hooks/useDocuments';
import useError from 'hooks/useError';
import useGroups from 'hooks/useGroups';
import useMessages from 'hooks/useMessages';
import useNotifications from 'hooks/useNotifications';
import useOffers from 'hooks/useOffers';
import useProfile from 'hooks/useProfile';
import useRecipients from 'hooks/useRecipients';
import useSession from 'hooks/useSession';
import useSuccess from 'hooks/useSuccess';
import useTemplates from 'hooks/useTemplates';
import useFolders from 'hooks/useFolders';

const {
  ARCHIVE_GENERATED,
  AUDIT_CREATED,
  MESSAGE_CREATED,
  READ_CREATED,
  USER_UNREAD_COUNTS_UPDATED,
  USER_UNVISITED_OFFERS_COUNT_UPDATED,
  USER_QUARANTINED_CONTACTS_COUNT_UPDATED,
  GROUP_UNREAD_COUNT_UPDATED,
  DEALSPACE_UNREAD_COUNT_UPDATED,
  DEALSPACE_UNREAD_CONVERSATION_UPDATED,
  OFFER_UNREAD_COUNT_UPDATED,
  OFFER_RECIPIENTS_COUNT_UPDATED,
  OFFER_UNLOCKED_RECIPIENTS_COUNT_UPDATED,
  OFFER_APPROVAL_REQUESTED_RECIPIENTS_COUNT_UPDATED,
  AGREEMENT_SIGNED,
  /* RECIPIENT_UPDATED, */
  TEMPLATE_CONFIGURED,
  CONTACTS_PROGRESS_UPDATED,
  CONTACTS_IMPORTED,
  NOTIFICATION_CREATED,
  USER_UNREAD_NOTIFICATIONS_COUNT_UPDATED
} = cableMessageTypes;

const Cable = () => {
  const audioRef = useRef(null);
  const { pathname } = useLocation();
  const { authenticated } = useSession();
  const { closeAgreementDialog } = useApp();
  const { getArchive } = useArchives();
  const { getCableCreatedAudit } = useAudits();
  const { track: trackChameleon } = useChameleon();
  const { setUploadingFile, setContactsFilters, getContacts, clearLocalContacts } = useContacts();
  const { setLocalUserCounts } = useProfile();
  const { getMessage, setLocalReadStatus } = useMessages();
  const { getNotification, setUnreadNotificationsCount } = useNotifications();
  const { setGroupUnreadCountConversations } = useGroups();
  const { setDealspaceUnreadCountConversations, setDealspaceUnreadCoversationUpdated } = useDealspaces();
  const { getOfferPrivateLevel1Documents, getOfferPrivateLevel2Documents } = useDocuments();
  const { getOfferPrivateLevel1Folders, getOfferPrivateLevel2Folders } = useFolders();
  const { getOfferConversations, getConversation } = useConversations();
  const { setOfferUnreadCountConversations, setLocalOfferCounts, getFeedOfferDetails } = useOffers();
  const { getRecipient } = useRecipients();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { setConfiguredTemplate } = useTemplates();
  const { my_id, conversationId, conversations, audits } = useCustomSelector(state => ({
    my_id: state.profile.user.id,
    conversationId: state.conversations.conversation.id,
    conversations: [...state.conversations.user.feed.data, ...state.conversations.offerings.data],
    audits: state.audits.all.data
  }));
  const [data, setData] = useState(null);
  const [consumer, setConsumer] = useState(null);

  const handleArchiveGenerated = useCallback(id => {
    setData(null);
    getArchive(id)
    .then(({ payload: { data: { archive: { file: { url } } } } }) => window.open(url, '_blank'))
    .catch(e => setError(e));
  }, [getArchive, setError]);
  const handleAuditCreated = useCallback(id => {
    setData(null);
    if (!_find(audits, a => a.id === id)){
      getCableCreatedAudit(id)
      .catch(e => setError(e));
    }
  }, [getCableCreatedAudit, audits, setError]);
  const handleMessageCreated = useCallback(({ conversation_id, message_id, user_id }) => {
    setData(null);
    if (user_id !== my_id) {
      getMessage({ conversation_id, message_id, isConversationMessage: conversation_id === conversationId })
      .then(() => {
        audioRef.current.playNewMessageSound();
        if (!!conversations.length && !!!_find(conversations, (conversation) => conversation.id === conversation_id)) {
          getConversation(conversation_id)
          .then(({ payload: { data: { conversation: { conversationable, conversationable_type } } } }) => {
            const isMyShared = conversationable?.primary_sender?.user?.id === my_id;

            conversationable_type === 'offer' && getOfferConversations({ offset: 0, offer_id: conversationable.id, feed: !isMyShared, offer: isMyShared })
          });
        }
      })
      .catch((e) => setError(e));
    }
  }, [my_id, conversationId, conversations, getMessage, getOfferConversations, getConversation, setError]); // eslint-disable-line
  const handleAgreementSigned = useCallback(({ offer_id }) => {
    setData(null);
    setSuccess('You are now accessing locked Offering content');
    setSuccess('Signed CA saved to your account');
    trackChameleon('CA Signed');
    audioRef.current.playUnlockOfferingSound();
    closeAgreementDialog()
    .then(() =>
      getFeedOfferDetails(offer_id)
      .then(({ payload: { data: { offer: { root_folder, approval_required } } } }) => {
        if (approval_required) setSuccess('Approval request sent');
        getOfferPrivateLevel1Documents({ folder_id: root_folder.id, offset: 0 }).catch(e => setError(e));
        getOfferPrivateLevel2Documents({ folder_id: root_folder.id, offset: 0 }).catch(e => setError(e));
        getOfferPrivateLevel1Folders({ folder_id: root_folder.id, offset: 0 }).catch(e => setError(e));
        getOfferPrivateLevel2Folders({ folder_id: root_folder.id, offset: 0 }).catch(e => setError(e));
      })
      .catch(e => setError(e))
    );
  }, [closeAgreementDialog, getFeedOfferDetails, getOfferPrivateLevel1Documents, getOfferPrivateLevel2Documents, getOfferPrivateLevel1Folders, getOfferPrivateLevel2Folders, setError, setSuccess]); // eslint-disable-line 
  const handleRecipientUpdated = useCallback(id => {
    setData(null);
    getRecipient(id)
    .catch(e => setError(e));
  }, [getRecipient, setError]);
  const handleTemplateConfigured = useCallback(templateId => {
    setData(null);
    setConfiguredTemplate(templateId);
  }, [setConfiguredTemplate]);
  const handleContactsProgressUpdated = useCallback(({ file_id, filename, lines_count, progress }) => {
    setData(null);
    setUploadingFile({ file_id, filename, lines_count, progress });
  }, [setUploadingFile]);
  const handleContactsImported = useCallback(({ data, pathname }) => {
    setData(null);
    setUploadingFile({ file_id: data.file_id });
    setSuccess(`"${data.filename}" succesfully uploaded. ${data.imported_contacts_count} of ${data.contacts_count} imported.`);
    if (pathname === '/contacts') {
      setContactsFilters({ offset: 0, orders: { created_at: 'desc' }, query: '', lists: [], tab: 'all', member: false, pending: null })
      .then(() =>
        clearLocalContacts()
        .then(() =>
          getContacts({ offset: 0 })
          .catch(e => setError(e))
        )
      );
    }
  }, [setSuccess, setUploadingFile, clearLocalContacts, getContacts, setContactsFilters, setError]);
  const handleNotificationCreated = useCallback(notificationId => {
    setData(null);
    getNotification(notificationId)
    .then(({ payload: { data: { notification: { notification_type } } } }) => {
      if (notification_type === 'recipient_created') {
        audioRef.current.playNewPrivateOfferingSound();
      }
    })
    .catch(e => setError(e));
  }, [getNotification, setError]);
  const handleUserUnreadNotificationsCountUpdated = useCallback(count => {
    setData(null);
    setUnreadNotificationsCount(count);
  }, [setUnreadNotificationsCount]);
  const handleOfferLocalCountsUpdated = useCallback(({ offer_id, recipients_count, unlocked_recipients_count, approval_requested_recipients_count }) => {
    setLocalOfferCounts({ offer_id, recipients_count, unlocked_recipients_count, approval_requested_recipients_count });
  }, [setLocalOfferCounts]);
  const received = data => setData(data);
  const rejected = consumer => {
    if (!!consumer) {
      consumer.disconnect();
      setConsumer(null);
      setData(null);
    }
  };

  useEffect(() => {
    if (!consumer && authenticated) {
      sessionService.loadSession()
      .then(({ accessToken }) => {
        const c = createConsumer(`${wsURL}?access_token=${accessToken}`);

        try {
          c.subscriptions.create('NotificationsChannel', { received, rejected: () => rejected(consumer) });
          setConsumer(c);  
        } catch (error) {
          console.error(error);
        } 
      })
      .catch(_ => {});
    }

    return () => {
      if (!!consumer) {
        consumer.disconnect();
        setConsumer(null);
        setData(null);
      }
    };
  }, [authenticated, consumer]);
  useEffect(() => {
    if (!!data && authenticated) {
      if (data.type === ARCHIVE_GENERATED) handleArchiveGenerated(data.archive_id);
      if (data.type === AUDIT_CREATED) handleAuditCreated(data.audit_id);
      if (data.type === MESSAGE_CREATED) handleMessageCreated(data);
      if (data.type === READ_CREATED) setLocalReadStatus(data.message_id);
      if (data.type === USER_UNREAD_COUNTS_UPDATED) setLocalUserCounts(_omit(data, ['type', 'user_id']));
      if (data.type === USER_UNVISITED_OFFERS_COUNT_UPDATED) setLocalUserCounts(_omit(data, ['type', 'user_id']));
      if (data.type === USER_QUARANTINED_CONTACTS_COUNT_UPDATED) setLocalUserCounts(_omit(data, ['type', 'user_id']));
      if (data.type === GROUP_UNREAD_COUNT_UPDATED) setGroupUnreadCountConversations(_omit(data, ['type']));
      if (data.type === DEALSPACE_UNREAD_COUNT_UPDATED) setDealspaceUnreadCountConversations(_omit(data, ['type']));
      if (data.type === DEALSPACE_UNREAD_CONVERSATION_UPDATED) setDealspaceUnreadCoversationUpdated(_omit(data, ['type']));
      if (data.type === OFFER_UNREAD_COUNT_UPDATED) setOfferUnreadCountConversations(_omit(data, ['type']));
      if (data.type === OFFER_RECIPIENTS_COUNT_UPDATED || data.type === OFFER_UNLOCKED_RECIPIENTS_COUNT_UPDATED || data.type === OFFER_APPROVAL_REQUESTED_RECIPIENTS_COUNT_UPDATED) handleOfferLocalCountsUpdated(data);
      if (data.type === AGREEMENT_SIGNED) handleAgreementSigned(_omit(data, ['type']));
      if (data.type === TEMPLATE_CONFIGURED) handleTemplateConfigured(data.template_id);
      if (data.type === CONTACTS_PROGRESS_UPDATED) handleContactsProgressUpdated(data);
      if (data.type === CONTACTS_IMPORTED) handleContactsImported({ data, pathname });
      if (data.type === NOTIFICATION_CREATED) handleNotificationCreated(data.notification_id);
      if (data.type === USER_UNREAD_NOTIFICATIONS_COUNT_UPDATED) handleUserUnreadNotificationsCountUpdated(data.unread_notifications_count);
    }
  }, [
      authenticated,
      data,
      handleArchiveGenerated,
      handleAuditCreated,
      handleMessageCreated, 
      setLocalReadStatus, 
      setLocalUserCounts, 
      setGroupUnreadCountConversations,
      setDealspaceUnreadCountConversations,
      setDealspaceUnreadCoversationUpdated,
      setOfferUnreadCountConversations,
      handleOfferLocalCountsUpdated,
      handleAgreementSigned,
      handleRecipientUpdated,
      handleTemplateConfigured,
      handleContactsProgressUpdated,
      handleContactsImported,
      handleNotificationCreated,
      handleUserUnreadNotificationsCountUpdated,
      pathname
  ]);

  return <AudioPlayer ref={audioRef} />;
};

export default memo(Cable);