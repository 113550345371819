import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const ThumbsContainer = styled(Box)({
  maxWidth: 600,
  paddingTop: 8,
  margin: '0 auto',
  '& .swiper-slide-thumb-active > div': {
    boxShadow: 'inset 0 0 0 2px rgba(0,0,0,.8)',
  }
});

export const Image = styled('img')(({ theme }) => ({
  userSelect: 'none',
  display: 'block',
  height: '100%',
  maxHeight: 450,
  width: 'auto',
  maxWidth: '100%',
  objectFit: 'cover',
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    objectFit: 'contain'
  }
}));

export const Preview = styled(Box)(() => ({
  display: 'block',
  width: 80,
  height: 46,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));
