import styled from '@mui/material/styles/styled';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const StyledTab = styled(Tab)(({ theme }) => ({
  minWidth: 'unset',
  minHeight: '40px',
  padding: '8px 0',
  '&:not(:last-of-type)': {
    marginRight: 12
  },
  [theme.breakpoints.up('md')]: {
    padding: '4px 0',
    '&:not(:last-of-type)': {
      marginRight: 24
    }
  }
}));

const Root = styled(Box)({
  paddingLeft: 32, 
  paddingRight: 32, 
  paddingBottom: 0, 
  paddingTop: 0, 
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden'
});

const Container = styled(Box)({
  width: '100%',
  height: '100%',
  overflow: 'auto'
});

export { StyledTab, Root, Container };