import { memo } from 'react';
import Box from '@mui/material/Box';
import _startCase from 'lodash/startCase'
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Caption, Root, StatusButton, Title } from './MainInfo.styled';
import MarketFilledIcon from 'components/Common/Icons/MarketFilledIcon';
import LockedFilledIcon from 'components/Common/Icons/LockedFilledIcon';
import EditIcon from 'components/Common/Icons/EditIcons';
import GroupTeam from 'components/Common/DataDisplay/Team/Team';
import Status from 'components/Offers/Status/Status';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';

const MainInfo = ({ senders, public: isPublic, name, tag, status, deadline_type, deadline_at, onStatusClick }) => {
  const hasDeadline = !!deadline_type && !!deadline_at;
  const deadline = !!deadline_at ? Math.round((new Date(deadline_at).getTime() - new Date().getTime()) / 86_400_000) : null;

  return (
    <Root>
      {isPublic
        ? <MarketFilledIcon sx={{ height: 30, width: 30, mb: '2px' }} />
        : <LockedFilledIcon sx={{ height: 30, width: 30, mb: '2px' }} />
      }
      <GroupTeam
        members={senders}
        sx={{ '& > div': { height: 24, width: 24, fontSize: 10 }, '.MuiAvatar-root': { height: 24, width: 24, fontSize: 10 } }}
      />
      <Box>
        <Title>{name}</Title>
        <Caption>{tag}</Caption>
      </Box>
      <Box pl={3}>
        <StatusButton onClick={onStatusClick}><Status value={status} /><EditIcon sx={{ fontSize: '14px' }} /></StatusButton>
        <Caption>{hasDeadline && `${_startCase(deadline_type)} in ${deadline} days`}</Caption>
      </Box>
    </Root>
  );
};

MainInfo.propTypes = {
  senders: PropTypes.array,
  public: PropTypes.bool,
  name: PropTypes.string,
  tag: PropTypes.string,
  status: PropTypes.string,
  deadline_type: PropTypes.string,
  deadline_at: PropTypes.string,
  onStatusClick: PropTypes.func
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <MainInfo {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);