import { memo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
// Local files
import { DesktopContent, MobileContent, DesktopTextContent, MobileTextContent, DesktopActions, MobileActions, Title, Headline, LocationContainer } from './MainCard.styled';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import LocationIcon from 'components/Common/Icons/LocationIcon';
import Banner from './Banner/Banner';
import Avatar from './Avatar/Avatar';
import ConnectActions from './ConnectActions/ConnectActions';
import useCustomSelector from 'hooks/useCustomSelector';
import useConversations from 'hooks/useConversations';
import useApp from 'hooks/useApp';

const MainCard = () => {
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { username } = useParams();
  const { openOrCreateConversation } = useConversations();
  const { openEditProfileDialog } = useApp();
  const { profile, user, authorizedAsUser } = useCustomSelector(state => ({
    profile: state.profile.user,
    user: state.users.user,
    authorizedAsUser: state.sessions.session.userableType === 'user'
  }));
  const [processing, setProcessing] = useState(false);
  const isProfile = username === profile.username;
  const activeUser = isProfile ? profile : user;

  const handleConversationClick = () => {
    setProcessing(true);
    openOrCreateConversation(activeUser?.id, activeUser?.private_conversation);
    setProcessing(false);
  };

  if (mediaDesktop) {
    return (
      <Paper variant='outlined' sx={{ overflow: 'hidden' }}>
        <Banner
          isProfile={isProfile}
          src={activeUser.header?.url ?? null}
        />
        <DesktopContent>
          <Avatar user={activeUser} edit={isProfile} />
          <DesktopTextContent>
            <Title>{activeUser.first_name} {activeUser.last_name}</Title>
            <Headline>{activeUser.headline}</Headline>
            {!!activeUser.location &&
              <LocationContainer>
                <LocationIcon /><Typography variant='caption'>{activeUser.location}</Typography>
              </LocationContainer>
            }
          </DesktopTextContent>
          <DesktopActions>
            {isProfile ? (
              <Button
                variant='contained'
                color='primary'
                disabled={processing}
                sx={{ whiteSpace: 'nowrap' }}
                onClick={openEditProfileDialog}
              >
                Edit my profile
              </Button>
            ) : authorizedAsUser && (
              <>
                <ConnectActions activeUser={activeUser} />
                <Button
                  variant='outlined'
                  color='neutral'
                  startIcon={<ChatIcon />}
                  sx={{ minHeight: 30, color: 'rgba(0,0,0,.8)', px: 1.5 }}
                  onClick={handleConversationClick}
                  disabled={processing}
                >
                  Chat
                </Button>
              </>
            )}
          </DesktopActions>
        </DesktopContent>
      </Paper>
    );
  }
  return (
    <Paper variant='outlined' sx={{ overflow: 'hidden' }}>
      <Banner
        isProfile={isProfile}
        src={activeUser.header?.url ?? null}
      />
      <MobileContent>
        <Avatar user={activeUser} edit={isProfile} />
        <MobileTextContent>
          <Title>{activeUser.first_name} {activeUser.last_name}</Title>
          <Headline>{activeUser.headline}</Headline>
          {!!activeUser.location &&
            <LocationContainer>
              <LocationIcon /><Typography variant='caption'>{activeUser.location}</Typography>
            </LocationContainer>
          }
        </MobileTextContent>
        <MobileActions>
          {isProfile ? (
            <Button
              variant='contained'
              color='primary'
              disabled={processing}
              sx={{ whiteSpace: 'nowrap' }}
              onClick={openEditProfileDialog}
            >
              Edit my profile
            </Button>
          ) : authorizedAsUser && (
            <Box display='flex' flexDirection='column' gap='13px' width={237}>
              <ConnectActions activeUser={activeUser} />
              <Button
                variant='outlined'
                color='neutral'
                startIcon={<ChatIcon />}
                sx={{ minHeight: 30, color: 'rgba(0,0,0,.8)', px: 1.5 }}
                onClick={handleConversationClick}
                disabled={processing}
              >
                Chat
              </Button>
            </Box>
          )}
        </MobileActions>
      </MobileContent>
    </Paper>
  );
};

export default memo(MainCard);