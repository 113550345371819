import { memo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
// Local files
import { darkTheme } from 'theme';

const FormDarkSelect = ({ defaultValue, children, ...otherProps }) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <TextField
        select
        fullWidth
        SelectProps={{
          MenuProps: {
            PaperProps: { sx: {
                background: '#4F5056',
                boxShadow: 'none',
                border: '1px solid',
                borderColor: 'rgba(255, 255, 255, 0.23)',
                marginTop: '4px',
                maxWidth: 280
              }},
            defaultValue
          }
        }}
        {...otherProps}
      >
        {children}
      </TextField>
    </ThemeProvider>
  )
}

export default memo(FormDarkSelect);