import styled from '@mui/material/styles/styled';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

export const Root = styled(Box)(() => ({
  flexGrow: 1,
  overflow: 'auto',
  padding: '5px',
  '& > *': {
    marginBottom: '6px'
  }
}));

export const OfferCardSkeleton = styled(Skeleton)({
  transform: 'scale(1)',
  height: 122,
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.05)',
  marginBottom: '6px',
});
