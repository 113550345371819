import { createRoot } from 'react-dom/client';
import { useLayoutEffect } from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk';
import { sessionService } from 'redux-react-session';
import { BrowserRouter } from 'react-router-dom';
import Appsignal from '@appsignal/javascript';
import { ErrorBoundary } from '@appsignal/react';
import { appsignalApiKey } from 'apis';
// Material-UI
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider  } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import './index.css';
// Reducers
import reducer from './reducers';
// Components
import StyledSnackbarProvider from 'components/Common/Feedback/StyledSnackbarProvider/StyledSnackbarProvider';
import App from 'components/App/App';
// Hooks
import useChameleon from 'hooks/useChameleon';
import useLogrocket from 'hooks/useLogrocket';
import useMixpanel from 'hooks/useMixpanel';
import useSentry from 'hooks/useSentry';

const store = configureStore({ reducer, middleware: [thunk] });
const container = document.getElementById('root');
const root = createRoot(container);
const appsignal = new Appsignal({ key: appsignalApiKey });

sessionService.initSessionService(store);

const Root = () => {
  const { init: initChameleon } = useChameleon();
  const { init: initLogrocket } = useLogrocket();
  const { init: initMixpanel } = useMixpanel();
  const { init: initSentry } = useSentry();

  useLayoutEffect(() => {
    initChameleon();
    initLogrocket();
    initMixpanel();
    initSentry();
  }, [initChameleon, initLogrocket, initMixpanel, initSentry]);

  return (
    <BrowserRouter>
      <StyledSnackbarProvider>
        <ErrorBoundary instance={appsignal}>
          <App />
        </ErrorBoundary>
      </StyledSnackbarProvider>
    </BrowserRouter>
  );
};

root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Root />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();

    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(async (names) => {
        await Promise.all(names.map(name => caches.delete(name)));
      });
    }
  });
}