import { memo } from 'react';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import PropTypes from 'prop-types';
// Local files
import { StyledAppBar, StyledToolbar, StyledToggleButtonGroup, Actions, StyledButton, StyledPrimaryButton } from './Header.styled';

const PreviewHeader = ({ id, disabled, name, version, tab, onChangeTab, onClose, onSave }) => {
  return (
    <StyledAppBar position='static'>
      <StyledToolbar>
        <Typography variant='body2'>
          <b>Previewing Offering</b> {name}
        </Typography>
        {!id &&
          <StyledToggleButtonGroup
            size='small'
            exclusive
            value={tab}
            onChange={onChangeTab}
          >
            <ToggleButton value='offering'>Offering</ToggleButton>
            <ToggleButton value='email'>Email Notification</ToggleButton>
          </StyledToggleButtonGroup>
        }
        <Actions>
          <StyledButton onClick={onClose}>{id ? 'Close' : 'Back to Editor'}</StyledButton>
          {!id &&
            <StyledPrimaryButton
              disabled={disabled}
              onClick={onSave}
            >
              {version === 1 ? 'Save' : 'Publish Update'}
            </StyledPrimaryButton>
          }
        </Actions>
      </StyledToolbar>
    </StyledAppBar>
  );
};

PreviewHeader.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  tab: PropTypes.string.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default memo(PreviewHeader);