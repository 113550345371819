import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MuiLink from '@mui/material/Link';
import Box from '@mui/material/Box';

export const Heading = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '24px 12px',
  padding: '60px 24px 68px'
});
export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18)
}));
export const Content = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '900px',
  display: 'flex',
  justifyContent: 'space-evenly',
  flexWrap: 'wrap',
  gap: '32px',
  padding: '0 40px',
  [theme.breakpoints.up('md')]: {
    width: '900px'
  }
}));
export const Img = styled('img')(({ theme }) => ({
  width: '280px',
  height: 'auto',
  objectFit: 'cover',
  [theme.breakpoints.up('md')]: {
    width: '320px'
  }
}));
export const TextContent = styled(Box)({
  flexShrink: 0,
  width: '100%',
  maxWidth: '350px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});
export const Link = styled(MuiLink)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  marginBottom: 30
}));
export const Footer = styled(Box)({
  minHeight: 35,
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '4px 24px'
});
export const SkipButton = styled(Button)(({theme}) => ({
  fontSize: theme.typography.pxToRem(12),
  color: '#4A4A4A !important',
  fontWeight: 400,
  fontStyle: 'italic',
  textDecoration: 'underline !important',
  whiteSpace: 'nowrap',
  backgroundColor: 'transparent !important',
  padding: 0,
  marginTop: '-8px'
}));