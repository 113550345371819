import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Local files
import Core from 'components/Extension/Core/Core';
import useError from 'hooks/useError';
import useCustomSelector from 'hooks/useCustomSelector';
import useNylasUsers from 'hooks/useNylasUsers';

const nylasUrl = 'https://api.us.nylas.com/v3/connect/auth?client_id=1b7acbfe-9006-4098-b5d6-53e199ab7757&redirect_uri=https://app-staging.qwincydev.com/nylas_redirect&response_type=code&provider=google&access_type=offline';

const Extension = () => {
  const navigate = useNavigate();
  const { setError } = useError();
  const { createNylasUser, getNylasUser, deleteNylasUser } = useNylasUsers();
  const userEmail = useCustomSelector(state => state.nylasUsers.nylasUser.email);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const processCode = useCallback((code, email) => {
    getNylasUser(email)
    .then(() => {
      navigate('/extension');
      setLoading(false);
      setDisabled(false);
      window.close();
    })
    .catch(e => {
      if (e.response?.status === 404) {
        createNylasUser(code)
        .then(() => {
          navigate('/extension');
          setShowInfo(true);
          setLoading(false);
        })
        .catch(e => setError(e))
        .finally(() => setDisabled(false));
      } else {
        setError(e);
      }
    });
  }, [createNylasUser, getNylasUser, setError]); // eslint-disable-line
  const handleSignOut = () => {
    setDisabled(true);
    deleteNylasUser(userEmail)
    .then(handleClose)
    .catch(e => setError(e))
    .finally(() => setDisabled(false));
  };
  const handleClose = () => window.parent.postMessage({ type: 'close' }, 'https://mail.google.com');

  const handleMessage = useCallback(message => {
    if (message?.data?.type === 'emailDetected') {
      getNylasUser(message.data.value)
      .then(() => setLoading(false))
      .catch(e => {
        if (e.response?.status === 404) {
          handleClose();
          window.open(`${nylasUrl}&state=${message.data.value}`, '_blank');
        } else {
          setError(e);
        }
      });
    }
  }, [getNylasUser, setError]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('click', handleMessage);
    };
  }, [handleMessage]);
  useEffect(() => {
    if (searchParams.has('code') && searchParams.has('email')) {
      processCode(searchParams.get('code'), searchParams.get('email'));
    }
  }, [processCode, searchParams]);

  if (showInfo) return <div>Sucess. Go back to Gmail.</div>;
  if (loading) return <div>Loading...</div>;
  return (
    <Core
      disabled={disabled}
      onSignOut={handleSignOut}
      onClose={handleClose}
    />
  );
};

export default memo(Extension);