import { memo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
// import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Skeleton from '@mui/material/Skeleton';
import ButtonBase from '@mui/material/ButtonBase';
import _omit from 'lodash/omit';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _isEqual from 'lodash/isEqual';
import moment from 'moment';
import currency from 'currency.js';
import { ErrorBoundary } from 'react-error-boundary';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars-2';
// Local files
import { TableCell, StyledIconButton, AddButton } from './Table.styled';
import TablePagination from 'components/Common/DataDisplay/BaseTable/TablePagination/TablePagination';
import { ReactComponent as MailSoftBounceIcon } from 'assets/icons/mail-soft-bounce.svg';
import { ReactComponent as MailHardBounceIcon } from 'assets/icons/mail-hard-bounce.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as SignedIcon } from 'assets/icons/lock-opened-colored.svg';
import { ReactComponent as TrackingIcon } from 'assets/icons/target-colored.svg';
import { ReactComponent as SignedOffIcon } from 'assets/icons/lock-opened-hint.svg';
import { ReactComponent as ResendMailIcon } from 'assets/icons/resend-mail.svg';
import { ReactComponent as SavedIcon } from 'assets/icons/offer-saved-colored.svg';
import { ReactComponent as AttachIcon } from 'assets/icons/attach.svg';
import AddIcon from 'components/Common/Icons/AddIcon';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import DateField from 'components/Common/Inputs/DateField/DateField';
import FormSelect from 'components/Common/Inputs/FormSelect/FormSelect';
// import Interest from 'components/Recipients/Interest/Interest';
import Head from './Head/Head';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import UnlockRecipientDialog from 'components/Dialogs/UnlockRecipient/UnlockRecipient';
import GrantAccessDialog from 'components/Dialogs/GrantAccess/GrantAccess';
import { getShortDate, formatPrice, getRepresentation } from 'helpers';
import { representationVariants, chatLevels } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useBlob from 'hooks/useBlob';
import useChecksum from 'hooks/useChecksum';
import useConversations from 'hooks/useConversations';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useRecipients from 'hooks/useRecipients';
import useSuccess from 'hooks/useSuccess';

const now = new Date();

const RecipientsTable = ({ page, rowsPerPage, query, order, orderBy, onPageChange, onQueryChange, onOrdersChange, onNotifyRecipientsDialog }) => {
  const fileRefs = {};
  const navigate = useNavigate();
  const previousLocation = useLocation();
  const { offer_id } = useParams();
  const { setChatsFilter, openViewFileDialog, openRecipientDrawer } = useApp();
  const { processBlob } = useBlob();
  const { processFiles } = useChecksum();
  const { getOfferConversations, clearLocalConversations, openOrCreateConversation } = useConversations();
  const { setError } = useError();
  const { updateRecipient } = useRecipients();
  const { setSuccess } = useSuccess();
  const { fetching, recipients, recipientsTotalCount, members, my_id, groupId, isLockedOffer, isApprovalOffer, isMyShared } = useCustomSelector(state => ({
    fetching: state.recipients.activityLog.fetching,
    recipients: state.recipients.activityLog.data,
    recipientsTotalCount: state.recipients.activityLog.pagination.total_count,
    members: state.members.dealspace.data,
    my_id: state.profile.user.id,
    groupId: state.offers.activityLog.dealspace.group.id,
    isLockedOffer: state.offers.activityLog.locked,
    isApprovalOffer: state.offers.activityLog.total_approval_required_documents_count > 0,
    isMyShared: !!state.senders.all.data?.length ? !!_find(state.senders.all.data, ({ user }) => user?.id === state.profile.user.id) : null
  }));
  const [editNote, setEditNote] = useState(null);
  const [editGroupContact, setEditGroupContact] = useState(null);
  const [editPrice, setEditPrice] = useState(null);
  const [editPropertyVisit, setEditPropertyVisit] = useState(null);
  const [editOfferDate, setEditOfferDate] = useState(null);
  const [price, setPrice] = useState('');
  const [date, setDate] = useState({ value: null, formattedValue: {}, error: '' });
  const [note, setNote] = useState('');
  const [propertyVisit, setPropertyVisit] = useState('');
  const [unlockRecipientDialog, setUnlockRecipientDialog] = useState({ open: false, recipient: null });
  const [grantAccessDialog, setGrantAccessDialog] = useState({ open: false, id: null });
  const [processing, setProcessing] = useState(false);
  const headProps = { query, order, orderBy };

  /*** Member ***/
  const handleGroupContactChange = ({ id, value }) => {
    const recipient = { id, member_id: value, localMember: _find(members, m => m.id === value) };

    setProcessing(true);
    updateRecipient(recipient)
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };
  /*** Interest level ***/
  // const handleInterestLevelChange = ({ id, value }) => {
  //   const recipient = { id, interest_level: value };

  //   setProcessing(true);
  //   updateRecipient(recipient)
  //   .catch(e => setError(e))
  //   .finally(() => setProcessing(false));
  // };
  /*** Offer amount cents ***/
  const handlePriceClick = ({ id, value }) => {
    if (!processing) {
      setEditPrice(id);
      setPrice(value / 100);
    }
  }
  const handlePriceBlur = ({ id, offered_amount_cents, value }) => {
    if (parseInt(value) * 100 !== offered_amount_cents) {
      const recipient = { id, offered_amount_cents: value * 100 };

      setProcessing(true);
      updateRecipient(recipient)
      .then(() => setEditPrice(null))
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    } else {
      setEditPrice(null);
    }
  };
  /*** Offered At ***/
  const handleDateClick = ({ id, value }) => {
    if (!!!editOfferDate) {
      setEditOfferDate(id);
      setDate({ value, formattedValue: value ? new Date(value) : new Date(), error: '' });
    }
  };
  const handleDateChange = ({ id, value, formattedValue, error }) => {
    const diff = now - formattedValue;
    const e = typeof formattedValue !== 'object' || (diff && diff >= 0) ? 'Invalid Date' : error;

    setDate({ value, formattedValue, error: e });

    if (!e) {
      const recipient = { id, offered_at: value };

      setProcessing(true);
      updateRecipient(recipient)
      .then(() => setEditOfferDate(null))
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
  };
  const handleDateBlur = ({ id, offered_at }) => {
    if (date.value !== offered_at) {
      const recipient = { id, offered_at: date.value };

      setProcessing(true);
      updateRecipient(recipient)
      .then(() => setEditOfferDate(null))
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
  };
  /*** Notes ***/
  const handleNoteClick = ({ id, value }) => {
    if (!processing) {
      setEditNote(id);
      setNote(value);
    }
  };
  const handleNoteBlur = ({ id, notes, value }) => {
    if (value !== notes) {
      const recipient = { id, notes: value };

      setProcessing(true);
      updateRecipient(recipient)
      .then(() => setEditNote(null))
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    } else {
      setEditNote(null);
    }
  };
   /*** Property Visits ***/
   const handlePropertyVisitClick = ({ id, value }) => {
    if (!processing) {
      setEditPropertyVisit(id);
      setPropertyVisit(value);
    }
  };
  const handlePropertyVisitBlur = ({ id, property_visits, value }) => {
    if (value !== property_visits) {
      const recipient = { id, property_visits: value };

      setProcessing(true);
      updateRecipient(recipient)
      .then(() => setEditPropertyVisit(null))
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    } else {
      setEditPropertyVisit(null);
    }
  };
  /*** Files ***/
  const handleFileChange = ({ id, oldFiles, newFiles }) => {
    if (!!newFiles[0]) handleAddFile({ id, oldFiles, value: newFiles[0]})
  };
  const handleFileClick = ({ file }) => {
    openViewFileDialog({ file });
  };
  const handleAddFile = ({ id, oldFiles, value }) => {
    processFiles([value], ({ file, checksum }) => {
      processBlob({ file, checksum }, blob => {
        if (!blob.id) return;

        const recipient = { id, files: [...oldFiles.map(f => f.id), blob.id], localFiles: [...oldFiles, blob] };

        setProcessing(true);
        updateRecipient(recipient, blob)
        .catch(e => setError(e))
        .finally(() => setProcessing(false));
      });
    });
  };
  /*** Conversation ***/
  const handleConversationClick = (user_ids = null) => {
    if (!!user_ids?.length) {
      setProcessing(true);
      clearLocalConversations()
      .then(() => {
        getOfferConversations({ offset: 0, offer_id, feed: !isMyShared, offer: isMyShared })
        .then(({ payload: { response: { data: { conversations } } } }) => {
          const conversation = _find(conversations, ({ users }) => _isEqual(_map(_filter(users, u => u.id !== my_id), (user) => user.id).sort(), user_ids?.sort()));
    
          openOrCreateConversation(user_ids, conversation, isMyShared);
          setProcessing(false);
        })
        .catch(e => setError(e))
      })
    } else {
      setChatsFilter({ filter: { offer_id } })
      .then(() => 
        navigate(`/chats/${isMyShared ? chatLevels.GROUP : chatLevels.USER}/${isMyShared ? groupId : my_id}`, { state: { previousLocation } }
      ));
    }
  };
  /*** Recipient unlock offer ***/
  const handleNotifyClick = recipient => onNotifyRecipientsDialog({ open: true, recipient });
  const handleUnlockRecipient = (recipient) => {
    setProcessing(true);
    updateRecipient(_omit(recipient, ['user']))
    .then(() => setSuccess(`Offering content unlocked for ${recipient?.user?.first_name} ${recipient?.user?.last_name}`).then(() => setUnlockRecipientDialog({ open: false, recipient: null })))
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };
  const handleGrantClick = ({ id, user }) => setGrantAccessDialog({ open: true, id, user });
console.log({fetching});
  return (
    <>
      <UnlockRecipientDialog 
        {...unlockRecipientDialog}
        processing={processing}
        onClose={() => setUnlockRecipientDialog({ open: false, recipient: null })}
        onUnlock={handleUnlockRecipient}
      />
      <GrantAccessDialog
        {...grantAccessDialog}
        onClose={() => setGrantAccessDialog({ open: false, id: null })}
      />
      <Box display='flex' flexDirection='column' style={{height: '100%', weight: '100%'}}>
        <Scrollbars autoHideTimeout={1000} >
          <Table stickyHeader sx={{ background: '#fff', borderTop: '1px solid #EAEAEA' }}>
            <Head
              onQueryChange={onQueryChange}
              onSortChange={onOrdersChange}
              onConversation={handleConversationClick}
              {...headProps}
            />
            <TableBody>
              {fetching ?
                <TableRow>
                  <TableCell><Skeleton animation='wave' /></TableCell>
                  <TableCell><Skeleton animation='wave' /></TableCell>
                  <TableCell><Skeleton animation='wave' /></TableCell>
                  <TableCell><Skeleton animation='wave' /></TableCell>
                  <TableCell><Skeleton animation='wave' /></TableCell>
                  <TableCell><Skeleton animation='wave' /></TableCell>
                  <TableCell><Skeleton animation='wave' /></TableCell>
                  <TableCell><Skeleton animation='wave' /></TableCell>
                  <TableCell><Skeleton animation='wave' /></TableCell>
                  <TableCell><Skeleton animation='wave' /></TableCell>
                  <TableCell><Skeleton animation='wave' /></TableCell>
                  <TableCell><Skeleton animation='wave' /></TableCell>
                  <TableCell><Skeleton animation='wave' /></TableCell>
                  <TableCell><Skeleton animation='wave' /></TableCell>
                  <TableCell><Skeleton animation='wave' /></TableCell>
                  <TableCell><Skeleton animation='wave' /></TableCell>
                </TableRow>
              :
              _map(recipients, ({ id, user, email: e, first_name, last_name, email_opens_count, email_bounce, visits_count, visits_duration, saved, tracked, locked, approved, interest_level, notes, property_visits, offered_amount_cents, offered_percent, offered_at, created_at, visited_at, member, files, unlocked_at }) => {
                const firstName = user?.first_name ?? first_name ?? '';
                const lastName = user?.last_name ?? last_name ?? '';
                const email = user?.email ?? e ?? '';
                const src = user ? getRepresentation(user.photo?.representations, representationVariants.TINY) : null;
                
                return (
                  <TableRow hover={false} key={id}>
                    <TableCell align='center' sx={{ position: { xs: 'unset', md: 'sticky' }, left: '0', zIndex: 1, background: '#fff', boxShadow: 'inset -2px 0 0 0 #EAEAEA' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', gap: '4px' }}>
                        {/* <Checkbox
                          color='secondary'
                          checked={!!_find(selectedItems, si => si === id || si === 'all')}
                          onChange={({ target: { checked } }) => onSelectedItemsChange({ id, checked })}
                        /> */}
                        <Button
                          id='chatButton'
                          variant='blank'
                          color='neutral'
                          disabled={processing || fetching || isMyShared === null}
                          onClick={() => handleConversationClick([user.id])}
                        >
                          <ChatIcon />
                        </Button>
                        <Button
                          id='resendButton'
                          variant='blank'
                          color='neutral'
                          disabled={processing || fetching}
                          onClick={() => handleNotifyClick({ id, firstName, lastName })}
                        >
                          <ResendMailIcon />
                        </Button>
                        <BaseAvatar src={src} lastName={lastName} firstName={firstName} sx={{ mx: 1.5 }} />
                        <Box
                          display='flex'
                          justifyContent='space-between'
                          width='100%'
                          sx={{ cursor: 'pointer' }}
                          onClick={() => openRecipientDrawer({ id, user, first_name, last_name, email, offered_amount_cents, offered_at, notes, files })}
                        >
                          <Typography variant='caption' pr={2} sx={{ textDecoration: 'underline', }}>{firstName} {lastName}</Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align='center'>
                      {email_opens_count === 0 && !email_bounce ? '-' : ''}
                      {email_opens_count > 0 && !email_bounce ? email_opens_count : ''}
                      {email_bounce === 'soft' && <MailSoftBounceIcon />}
                      {email_bounce === 'hard' && <MailHardBounceIcon />}
                    </TableCell>
                    <TableCell align='center'>
                      {!!visits_count ? visits_count : '-'}
                    </TableCell>
                    {isLockedOffer &&
                      <TableCell align='center'>
                        {locked ? 
                          <StyledIconButton
                            id='lockedIcon'
                            disabled={processing || fetching}
                            onClick={() => setUnlockRecipientDialog({ open: true, recipient: { id, user, locked: false } })}
                          >
                            <SignedOffIcon />
                          </StyledIconButton> : 
                          <>{unlocked_at ? getShortDate(unlocked_at) : ''}<SignedIcon id='unlockedIcon' /></>
                        }
                      </TableCell>
                    }
                    {isApprovalOffer &&
                      <TableCell align='center'>
                        {!locked &&
                          (!approved ?
                          <ButtonBase
                            sx={{ width: 98, height: 30, borderRadius: 6, backgroundColor: '#F8F9FB' }}
                            onClick={() => handleGrantClick({ id, user: `${firstName} ${lastName}` })}
                          >
                            <Typography fontWeight={400} fontSize='11px' lineHeight='14px' color='rgba(0, 0, 0, 0.7)'>
                              Grant Access
                            </Typography>
                          </ButtonBase> :
                          <Typography fontWeight={400} fontSize='11px' lineHeight='14px' color='rgba(74, 75, 75, 1)'>
                            Access Granted
                          </Typography>
                        )}
                      </TableCell>
                    }
                    <TableCell align='center'>
                      {tracked ? <TrackingIcon style={{ maxHeight: 14 }} /> : '-'}
                    </TableCell>
                    <TableCell align='center'>
                      {saved ? <SavedIcon /> : '-'}
                    </TableCell>
                    <TableCell
                      align='center'
                      onClick={() => !processing && setEditGroupContact(id)}
                      sx={{ minWidth: 160, cursor: 'pointer' }}
                    >
                      {editGroupContact === id ?
                        <FormSelect
                          fullWidth
                          paperColor='paper'
                          value={member?.id}
                          onChange={({ target: { value } }) => handleGroupContactChange({ id, value })}
                          onBlur={() => setEditGroupContact(null)}
                        >
                          {_map(members, ({ id, user }) =>
                            <MenuItem
                              key={id}
                              value={id}
                            >
                              {user.first_name} {user.last_name}
                            </MenuItem>
                          )}
                        </FormSelect> :
                        <span>{member ? `${member.user.first_name} ${member.user.last_name}` : '-'}</span>
                      }
                    </TableCell>
                    {/*** TODO delete later ***/}
                    {/* <TableCell align='center'>
                      <Interest
                        value={interest_level}
                        onClick={value => handleInterestLevelChange({ id, value })}
                      />
                    </TableCell> */}
                    <TableCell
                      align='center'
                      onClick={() => handlePriceClick({ id, value: offered_amount_cents })}
                      sx={{ whiteSpace: 'noWrap', cursor: 'pointer' }}
                    >
                      {editPrice === id ?
                        <TextField
                          value={price}
                          onChange={({ target: { value } }) => setPrice(value)}
                          onBlur={({ target: { value } }) => handlePriceBlur({ id, offered_amount_cents, value })}
                          InputProps={{ startAdornment: <InputAdornment position="start" sx={{ mr: '3px', '& > *':  { fontSize: '0.75rem' } }}>$</InputAdornment> }}
                          sx={{ width: 160 }}
                        /> :
                        (!!offered_amount_cents ? currency(offered_amount_cents, { fromCents: true, format: formatPrice }).format() : (!!offered_percent ? `${offered_percent}%` : '-'))
                      }
                    </TableCell>
                    <TableCell
                      align='center'
                      onClick={() => handleDateClick({ id, value: offered_at })}
                      sx={{ minWidth: 180, cursor: 'pointer' }}
                    >
                      {editOfferDate === id ?
                        <DateField
                          autoFocus
                          fullWidth
                          margin='dense'
                          label='Date'
                          disabled={processing || fetching}
                          disablePast
                          value={date.formattedValue}
                          helperText={date.error}
                          onChange={v => handleDateChange({ id, ...v })}
                          onBlur={() => handleDateBlur({ id, offered_at })}
                        />
                        : !!offered_at ? getShortDate(offered_at) : '-'
                      }
                    </TableCell>
                    <TableCell
                      align='center'
                      onClick={() => handleNoteClick({ id, value: notes })}
                      sx={{ cursor: 'pointer' }}
                    >
                      {editNote === id ?
                        <TextField
                          multiline
                          maxRows={3}
                          value={note}
                          onChange={({ target: { value } }) => setNote(value)}
                          onBlur={({ target: { value } }) => handleNoteBlur({ id, notes, value })}
                          sx={{ minWidth: 240, '& > div,': { fontSize: '0.675rem', p: '2px 8px' } }}
                        />
                        : !!notes ? 
                          <Box 
                            width={ notes.length < 34 ? '240px' : '500px' } 
                            sx={{ maxHeight: 55, overflow: 'auto' }}
                          >
                            {notes}
                          </Box> : 
                          <AddButton disabled={processing || fetching}>
                            <AddIcon />
                          </AddButton>
                      }
                    </TableCell>
                    <TableCell
                      align='center'
                      onClick={() => handlePropertyVisitClick({ id, value: property_visits })}
                      sx={{ cursor: 'pointer' }}
                    >
                      {editPropertyVisit === id ?
                        <TextField
                          multiline
                          maxRows={3}
                          value={propertyVisit}
                          onChange={({ target: { value } }) => setPropertyVisit(value)}
                          onBlur={({ target: { value } }) => handlePropertyVisitBlur({ id, property_visits, value })}
                          sx={{ minWidth: 240, '& > div,': { fontSize: '0.675rem', p: '2px 8px' } }}
                        />
                        : !!property_visits ? 
                          <Box 
                            width={ property_visits.length < 34 ? '240px' : '500px' } 
                            sx={{ maxHeight: 55, overflow: 'auto' }}
                          >
                            {property_visits}
                          </Box> : 
                          <AddButton disabled={processing || fetching}>
                            <AddIcon />
                          </AddButton>
                      }
                    </TableCell>
                    <TableCell align='center'>
                      <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', gap: '5px' }}>
                        {_map(files, f =>
                          <Button
                            key={f.id}
                            variant='outlined'
                            color='neutral'
                            startIcon={<AttachIcon />}
                            onClick={() => handleFileClick({ id, file: f })}
                            sx={{ px: 1.5 }}
                          >
                            {f.filename}
                          </Button>
                        )}
                        <AddButton
                          disabled={processing || fetching}
                          onClick={() => fileRefs[id].click()}
                        >
                          <AddIcon />
                          <input
                            hidden
                            ref={ref => fileRefs[id] = ref}
                            accept='*'
                            type='file'
                            onChange={({ target: { files: fl } }) => handleFileChange({ id, oldFiles: files, newFiles: fl })}
                          />
                        </AddButton>
                      </Box>
                    </TableCell>
                    <TableCell align='center'>
                      {getShortDate(created_at)}
                    </TableCell>
                    <TableCell align='center'>
                      {getShortDate(visited_at)}
                    </TableCell>
                    <TableCell align='center'>
                      <Typography variant='subtitle2'>
                        {visits_duration ? (visits_duration === 'PT0S' ? '-' : moment.duration(visits_duration).humanize()) : '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Scrollbars>
        <TablePagination
          sx={{ overflow: 'hidden'}}
          backIconButtonProps={ fetching ? { disabled: fetching } : undefined }
          nextIconButtonProps={ fetching ? { disabled: fetching } : undefined }
          count={recipientsTotalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => onPageChange(newPage)}
        />
      </Box>
    </>
  );
};

RecipientsTable.propTypes = {
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  query: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onOrdersChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <RecipientsTable {...props} />
    </ErrorBoundary>
  );
};
export const Fallback = () => {
  return (
    <TableRow>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
      <TableCell><Skeleton animation='wave' /></TableCell>
    </TableRow>
  );
};

export default memo(WrappedComponent);