import { memo } from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import TableSortingCell from 'components/Common/DataDisplay/BaseTable/TableSortingCell/TableSortingCell';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';

const headCells = [
  { id: 'user', title: 'User' },
  { id: 'status', title: 'Status' },
  { id: 'active_at', title: 'Last Active' },
  { id: 'role', title: 'User Role' },
  { id: 'permission', title: 'Dealspace Access Rights', hideSort: true },
  { id: 'empty', title: '', hideSort: true }
];

const Head = ({ order, orderBy, changeSorting }) => {
  return (
    <TableHead>
      <TableRow>
        <TableSortingCell
          align='left'
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          changeSorting={changeSorting}
        />
      </TableRow>
    </TableHead>
  );
};

Head.propTypes = {
  order: PropTypes.string.isRequired, 
  orderBy: PropTypes.string.isRequired,
  changeSorting: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Head {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);