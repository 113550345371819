import styled from '@mui/material/styles/styled';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  gap: 8,
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0,
    fontSize: theme.typography.pxToRem(12),
    textTransform: 'capitalize',
    border: 0,
    paddingTop: 4,
    paddingBottom: 4,
    '& > span': {
      marginLeft: '.3rem'
    },
    '& > .MuiTouchRipple-root': {
      marginLeft: 'unset'
    },
    '&.Mui-disabled': {
      border: 0
    },
    '&.Mui-selected': {
      backgroundColor: '#F7F8FD'
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius
    }
  }
}));

export const CustomBadge = styled(Box)({
  height: '18px',
  width: '18px',
  borderRadius: '35px',
  fontWeight: 600,
  fontSize: '10px',
  lineHeight: '18px',
  color: 'white'
});