import { memo, useEffect, useCallback, useRef, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useLocation } from 'react-router-dom';
import _find from 'lodash/find';
// Local files
import { Root, Container } from './Chats.styled';
import Nav from 'components/Conversations/Nav/Nav';
import Conversations from 'components/Conversations/Conversations';
import CloseButton from 'components/Conversations/Common/CloseButton/CloseButton';
import Header from 'components/Conversations/Header/Header';
import Messages from 'components/Messages/Messages';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useCustomSelector from 'hooks/useCustomSelector';
import useConversations from 'hooks/useConversations';
import useMessages from 'hooks/useMessages';
import useError from 'hooks/useError';
import useChatsLevel from 'hooks/useChatsLevel';

const ChatsDrawer = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { setError } = useError();
  const { 
    getUserConversations, 
    clearLocalUserConversations,
    getDealspaceConversations, 
    getGroupConversations,  
    clearLocalConversations,
    getOfferConversations,
    setLocalUserIds,
    clearLocalActiveConversation,
    clearLocalChatsData
  } = useConversations();
  const { clearLocalMessages } = useMessages();
  const { isDealspaceLevel, isGroupLevel, isUserLevel, chat_level_id } = useChatsLevel();
  const { dealspace_id, offer_id, user_ids, feedPagination, offeringsPagination, conversation_id, group } = useCustomSelector(state => ({ 
    dealspace_id: state.app.chats.filter.dealspace_id,
    offer_id: state.app.chats.filter.offer_id,
    user_ids: state.conversations.user_ids,
    feedPagination: state.conversations.user.feed.pagination,
    offeringsPagination: state.conversations.offerings.pagination,
    conversation_id: state.conversations.conversation.id,
    group: _find(state.groups.all.data, ({ id }) => id === chat_level_id)
  }));
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [query, setQuery] = useState('');
  const prevOpen = useRef(open);

  const fetchOfferConversations = useCallback((offer_id, isFeed, offset, query = null) => {
    getOfferConversations({ offset, offer_id, feed: isFeed, offer: !isFeed, query })
    .catch((e) => setError(e))
    .finally(() => setProcessing(false))
  }, [getOfferConversations, setError]);
  const fetchGroupConversations = useCallback((group_id, offset, query = null) => {
    getGroupConversations({ offset, group_id, query })
    .catch((e) => setError(e))
    .finally(() => setProcessing(false))
  }, [getGroupConversations, setError]);
  const fetchDealspaceConversations = useCallback((dealspace_id, offset, query = null) => {
    getDealspaceConversations({ offset, dealspace_id, query })
    .catch((e) => setError(e))
    .finally(() => setProcessing(false))
  }, [getDealspaceConversations, setError]);
  const fetchUserFeedConversations = useCallback((offset, query = null) => {
    getUserConversations({ offset, feed: true, query })
    .catch((e) => setError(e))
    .finally(() => setProcessing(false))
  }, [getUserConversations, setError]);
  const fetchUserConversations = useCallback((offer_id, offset, query) => {
    getUserConversations({ offset, general: true, query })
    .then(() => !!!offer_id ? fetchUserFeedConversations(offset, query) : setProcessing(false))
    .catch((e) => setError(e))
  }, [getUserConversations, fetchUserFeedConversations, setError]);
  const fetchConversations = useCallback((offset, query) => {
    setProcessing(true);
    isUserLevel && fetchUserConversations(offer_id, offset, query);
    (isDealspaceLevel || !!dealspace_id) && !!!offer_id && fetchDealspaceConversations(dealspace_id || chat_level_id, offset, query);
    isGroupLevel && !!!dealspace_id && !!!offer_id && fetchGroupConversations(chat_level_id, offset, query);
    !!offer_id && fetchOfferConversations(offer_id, isUserLevel, offset, query);
  }, [
      offer_id, 
      dealspace_id, 
      chat_level_id,
      isDealspaceLevel, isGroupLevel, isUserLevel,
      fetchUserConversations, 
      fetchDealspaceConversations, 
      fetchGroupConversations, 
      fetchOfferConversations
  ]);
  const handleContainerScroll = () => {
    if (isUserLevel && !!!offer_id && feedPagination.total_count > feedPagination.count + feedPagination.offset) {
      fetchUserFeedConversations(feedPagination.limit + feedPagination.offset);
    }
    if ((isDealspaceLevel || !!dealspace_id) && !!!offer_id && offeringsPagination.total_count > offeringsPagination.count + offeringsPagination.offset) {
      fetchDealspaceConversations(dealspace_id, offeringsPagination.limit + offeringsPagination.offset);
    }
    if (isGroupLevel && !!!dealspace_id && !!!offer_id && offeringsPagination.total_count > offeringsPagination.count + offeringsPagination.offset) {
      fetchGroupConversations(offeringsPagination.limit + offeringsPagination.offset)
    }
    if (!!offer_id && offeringsPagination.total_count > offeringsPagination.count + offeringsPagination.offset) {
      fetchOfferConversations(offer_id, isUserLevel, offeringsPagination.limit + offeringsPagination.offset)
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => fetchConversations(0, query), 500);

    return () => { 
      clearTimeout(timeout);
      clearLocalUserConversations();
      clearLocalConversations();
    };
  }, [query, fetchConversations, clearLocalUserConversations, clearLocalConversations]);
  // Для плавного открытия с анимацией
  useEffect(() => {
    setOpen(true);
  }, []);
  useEffect(() => {
    if (prevOpen.current && !open) {
      clearLocalChatsData();
      navigate(state.previousLocation.pathname, { state: state.previousLocation.state });  
    }
  }, [open, clearLocalChatsData]); // eslint-disable-line

  const handleClose = () => {
    setOpen(false);
    prevOpen.current = true;
  };
  const handleCreateCloseChat = () => 
  clearLocalActiveConversation()
  .then(() => 
    clearLocalMessages()
    .then(() => setLocalUserIds(!!user_ids ? null : 'user_id'))
  );
  const handleCloseContent = () => {
    clearLocalActiveConversation();
    clearLocalMessages();
    setLocalUserIds(null);
  };

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: { xs: '100%', md: '90vw', lg: '70vw' } } }}
    >
      <Root>
        <Nav mobile={!mediaDesktop} />
        <Conversations
          group={group}
          processing={processing}
          query={query}
          onQueryChange={q => setQuery(q)}
          handleCreateCloseChat={handleCreateCloseChat}
          handleScroll={handleContainerScroll}
        />
        <CloseButton onClick={handleClose} />
        {mediaDesktop ?
          <Container>
            <Header />
            <Messages />
          </Container> :
          <Dialog fullScreen open={!!conversation_id || !!user_ids} onClose={handleCloseContent}>   
            <Header onBack={handleCloseContent} />
            <Messages />
          </Dialog>
        }
      </Root>
    </Drawer>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <ChatsDrawer {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);