import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Root = styled(Box)(({ theme }) => ({
  minWidth: 260,
  width: '100%',
  minHeight: 260,
  display: 'block',
  textAlign: 'left',
  textDecoration: 'none',
  borderRadius: '8px',
  overflow: 'hidden',
  position: 'relative',
  color: theme.palette.text.primary,
  background: theme.palette.background.paper,
  boxShadow: '0px 4px 8px 5px #00000008',
  '&:hover': {
    boxShadow: '0px 4px 4px 0px #00000040',
  }
}));

export const NewTag = styled(Box)({
  position: 'absolute',
  top: 11, left: 10,
  fontSize: 12,
  fontWeight: 700,
  color: '#fff',
  background: 'rgba(0,0,0,0.6)',
  backdropFilter: 'blur(4px)',
  WebkitBackdropFilter: 'blur(4px)',
  borderRadius: '50px',
  padding: '2px 8px'
});

export const Image = styled(Box)({
  width: '100%',
  height: 135,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center'
});

export const Stack = styled(Box)({
  minHeight: 20,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

export const AddressAmount = styled(Box)(({ theme }) => ({
  minWidth: 20,
  minHeight: 20,
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 10,
  lineHeight: '100%',
  color: theme.palette.common.black,
  background: '#F1F2F5',
  borderRadius: '50%',
  padding: '3px'
}));