import { memo, useRef, useState, useMemo } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grow from '@mui/material/Grow';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import _find from 'lodash/find';
import _map from 'lodash/map';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import ArrowDropDownIcon from 'components/Common/Icons/ArrowDropDownIcon';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useCustomSelector from 'hooks/useCustomSelector';

const Actions = ({ tab, filters, onChange, onExport }) => {
  const anchorRef = useRef(null);
  const { recipientsAmount, activitiesAmount, auditsAmount } = useCustomSelector(state => ({
    recipientsAmount: state.recipients.activityLog.pagination.total_count,
    activitiesAmount: state.activities.table.pagination.total_count,
    auditsAmount: state.audits.all.pagination.total_count
  }));
  const [open, setOpen] = useState(false);
  const options = useMemo(() => {
    if (tab === 'shared') return [
      { value: 'locked', label: 'Locked' },
      { value: 'unlocked', label: 'Unlocked' },
      { value: 'tracked', label: 'Tracking' },
      { value: 'offered', label: 'Offers' },
      { value: 'visited', label: 'Visited' },
      { value: 'email_opened', label: 'Email Opens' }
    ];
    if (tab === 'activityLog') return [
      { value: 'unlocked', label: 'Unlocked' },
      { value: 'tracked', label: 'Tracking' },
      { value: 'visited', label: 'Visited' },
      { value: 'email_opened', label: 'Email Opens' }
    ];
    if (tab === 'history') return [];
    return [];
  }, [tab]);
  const amount = useMemo(() => {
    if (tab === 'shared') return recipientsAmount;
    if (tab === 'activityLog') return activitiesAmount;
    if (tab === 'history') return auditsAmount;
    return 0;
  }, [tab, recipientsAmount, activitiesAmount, auditsAmount]);

  const handleToggle = () => setOpen(prev => !prev);
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;

    setOpen(false);
  };

  return (
    <Box display='flex' alignItems='center'>
      <Box display='flex' alignItems='center'>
        {amount >= 0 && <Typography variant='small' noWrap sx={{ userSelect: 'none' }}>{`Showing ${amount} results`}</Typography>}
        <Box display='flex' alignItems='center' ml={1.5}>
          {_map(filters, f => (
            <Chip
              key={f}
              size='small'
              label={_find(options, o => o.value === f)?.label ?? '(undefined)'}
              onDelete={() => onChange({ reason: 'removeItem', value: f })}
              sx={{ m: '1px', fontWeight: 400, fontSize: '11px', lineHeight: '20px', color: '#4877F0', backgroundColor: 'rgba(72, 119, 240, 0.12)' }}
            />
          ))}
        </Box>
        <Button
          ref={anchorRef}
          onClick={handleToggle}
        >
          <FilterIcon style={{ marginRight: '4px' }} /><span>Filter</span><ArrowDropDownIcon />
        </Button>
        <Popper
          sx={{ zIndex: 10 }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) =>
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {_map(options, ({ value, label }) => (
                      <MenuItem
                        key={value}
                        selected={!!_find(filters, f => f === value)}
                        onClick={() => onChange({ reason: 'selectItem', value})}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          }
        </Popper>
      </Box>
      <Button onClick={onExport}>Export</Button>
    </Box>
  );
};

Actions.propTypes = {
  tab: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Actions {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);