import { memo } from 'react';
// Local files
import { Header, TitleContainer, Title, MainContainer } from 'components/Drawers/ActivityLog/ActivityLog.styled';
import { StyledSkeleton } from './Skeleton.styled';

const Skeleton = () => {
  return (
    <Header sx={{ minHeight: 200 }}>
      <TitleContainer>
        <Title>Manage Offering</Title>
      </TitleContainer>
      <MainContainer>
        <StyledSkeleton variant="circular" sx={{ width: 24, height: 24 }} />
        <StyledSkeleton variant="circular" sx={{ width: 24, height: 24 }} />
        <StyledSkeleton width={100} />
        <StyledSkeleton width={100} />
        <StyledSkeleton width={300} sx={{ ml: 'auto' }} />
      </MainContainer>
      <StyledSkeleton sx={{ width: 400, mt: 2 }} />
      <StyledSkeleton sx={{ width: 300, mt: 2 }} />
    </Header>
  );
};

export default memo(Skeleton);