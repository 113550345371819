import { memo, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import _isUndefined from 'lodash/isUndefined';
import _startsWith from 'lodash/startsWith';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Root, Image, DocPlaceholder, Description, Title, CancelButton, LoadingPlaceholder, ErrorInfo, StyledCheckbox, StyledRadio, BlurredPlaceholder } from './Card.styled';
import { ReactComponent as MenuIcon } from 'assets/icons/menu-vert.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock-filled2.svg';
import { ReactComponent as PendingIcon } from 'assets/icons/pending-white.svg';
import PercentProgress from 'components/Common/Feedback/PercentProgress/PercentProgress';
import DocIcon from './DocIcon/DocIcon';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useApp from 'hooks/useApp';
import { getFormattedDate, downloadFile } from 'helpers';
import useDocuments from 'hooks/useDocuments';

const FileCard = ({
  id, title, date, type, preview = '', hideMenu = false, blurred = false, showLastActivity = false, showFileSize = false, size, loading = false,
  percent, errorLoading = false, isRadio = false, isCheckbox = false, checked = false, onCheck = undefined, disabled = false,
  pending = false, onDelete = undefined, hideDelete = false, onClick = null
}) => {
  const { openViewFileDialog } = useApp();
  const { fetchDocument, clearLocalActiveDocument } = useDocuments();
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloading, setDownloading] = useState(false);

  const download = () => {
    if (!downloading) {
      setDownloading(true);
      fetchDocument(id)
      .then(({ payload: { data: { document: { file } } } }) => downloadFile(file, () => setDownloading(false)))
      .finally(clearLocalActiveDocument);
    }
  };
  const handleFileClick = () => {
    if (disabled) return;
    if (!blurred) {
      if (!_startsWith(type, 'application/pdf') && !_startsWith(type, 'image/')) {
        download();
      } else {
        openViewFileDialog({ id });
      }
    } else {
      onClick();
    }
  };
  const handleDeleteClick = () => {
    setAnchorEl(null);
    onDelete();
  };

  return (
    <Root>
      {blurred &&
        <BlurredPlaceholder onClick={handleFileClick}>
          <LockIcon />
        </BlurredPlaceholder>
      }
      {!blurred && pending &&
        <BlurredPlaceholder>
          <PendingIcon />
          <Typography fontWeight={600} fontSize='11px' lineHeight='20px' color='white'>
            Pending access approval...
          </Typography>
        </BlurredPlaceholder>
      }
      {loading &&
        <LoadingPlaceholder>
          {!_isUndefined(percent) && <PercentProgress color='#fff' value={percent} />}
        </LoadingPlaceholder>
      }
      {errorLoading &&
        <LoadingPlaceholder sx={{ background: '#FFE8E8E5' }}>
          <ErrorInfo><ErrorIcon />Error loading</ErrorInfo>
        </LoadingPlaceholder>
      }
      {isCheckbox && !loading &&
        <StyledCheckbox
          disabled={disabled}
          checked={checked}
          onChange={onCheck}
        />
      }
      {isRadio && !loading &&
        <StyledRadio
          disabled={disabled}
          checked={checked}
          onChange={onCheck}
        />
      }
      {onDelete && !hideDelete &&
        <CancelButton
          sx={loading ? {color: 'rgba(255,255,255,0.6)'} : {}}
          onClick={onDelete}
        >
          <CloseIcon />
        </CancelButton>
      }
      {_startsWith(type, 'image/') ? (
        <Image
          sx={{ backgroundImage: `url('${preview}')` }}
          onClick={handleFileClick}
        />
      ) : (
        <DocPlaceholder onClick={handleFileClick}>
          <DocIcon type={type} />
        </DocPlaceholder>
      )}
      <Box sx={{  width: '100%', padding: ' 4px 4px 4px 12px' }}>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', overflow: 'hidden', userSelect: blurred ? 'none' : 'unset' }}>
          <Title noWrap>{title}</Title>
          {!hideMenu &&
            <>
              <IconButton sx={{ width: 20, height: 20, p: 'unset',  zIndex: 1 }} onClick={({ currentTarget }) => setAnchorEl(currentTarget)}><MenuIcon /></IconButton>
              <Menu keepMounted open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                <MenuItem onClick={handleDeleteClick}>
                  <Typography variant='caption' component='div' color='red' noWrap sx={{ fontWeight: 600 }}>Delete</Typography>
                </MenuItem>
              </Menu>
            </>
          }
        </Box>
        {loading ? <Description>Loading...</Description> : (
          <>
            {showFileSize && <Description>{size}</Description>}
            {showLastActivity && <Description>Last activity {getFormattedDate(date)}</Description>}
          </>
        )}
      </Box>
    </Root>
  );
};

FileCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  date: PropTypes.string,
  type: PropTypes.string.isRequired,
  preview: PropTypes.string,
  hideMenu: PropTypes.bool,
  blurred: PropTypes.bool,
  showLastActivity: PropTypes.bool,
  showFileSize: PropTypes.bool,
  size: PropTypes.string,
  loading: PropTypes.bool,
  percent: PropTypes.number,
  errorLoading: PropTypes.bool,
  isRadio: PropTypes.bool,
  isCheckbox: PropTypes.bool,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
  hideDelete: PropTypes.bool
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <FileCard {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);