import { memo, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Scrollbars from 'react-custom-scrollbars-2';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _slice from 'lodash/slice';
// Local files
import { Root, PlaceholderContainer, ContentContainer, List, InputContainer } from './Messages.styled';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import BaseAvatarGroup from 'components/Common/DataDisplay/BaseAvatarGroup/BaseAvatarGroup';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import MessagesSkeleton from './MessagesSkeleton/MessagesSkeleton';
import Message from './Message/Message';
import NewDivider from './NewDivider/NewDivider';
import Input from './Input/Input';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useCustomSelector from 'hooks/useCustomSelector';
import useMessages from 'hooks/useMessages';
import useError from 'hooks/useError';
import usePrevious from 'hooks/usePrevious';

const Messages = () => {
  const { state } = useLocation();
  const { getMessages, readMessages, clearLocalMessages } = useMessages();
  const { setError } = useError();
  const scrollContainer = useRef();
  const divider = useRef(null);
  const { my_id, members, conversation_id, created, user_ids, data, downPagination, upPagination } = useCustomSelector(state => ({
    my_id: state.profile.user.id,
    members: [state.profile, ..._filter(state.senders.all.data, s => !!_find(state.conversations.user_ids, (u) => s?.user?.id === u))],
    conversation_id: state.conversations.conversation.id,
    created: state.conversations.conversation.created,
    user_ids: state.conversations.user_ids,
    data: state.messages.data, 
    downPagination: state.messages.downPagination, 
    upPagination: state.messages.upPagination
  }));
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const prevData = usePrevious(data);

  useEffect(() => {
    !!!_find(prevData, ({ id }) => id === 'divider') && !!_find(data, ({ id }) => id === 'divider') && divider.current?.scrollIntoView(false);
    !!!_find(data, ({ id }) => id === 'divider') && 
    !!_find(data, (message) => message?.time === 'pending' || (message?.user?.id !== my_id && !message?.read)) && scrollContainer.current.scrollToBottom();
  }, [data, my_id, prevData]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!!unreadMessages.length) {
        readMessages({ conversation_id, message_ids: unreadMessages })
        .then(() => setUnreadMessages([]))
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [conversation_id, readMessages, unreadMessages]);

  const fetchMessages = (offset, direction = null) => {
    if (!loading) {
      setLoading(true);
      getMessages({ conversation_id, offset, ...(!!direction && { direction }) })
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
    }
  };
  const handleContainerScroll = ({ top, scrollTop }) => {
    if ((scrollTop === 0 || scrollTop === 1) && !!downPagination.offset) {
      scrollContainer.current.scrollToBottom();
      fetchMessages(downPagination.offset - downPagination.limit >= 0 ? downPagination.offset - downPagination.limit : 0, 'down');
    } 
    if (Math.abs(top) >= 0.75 && upPagination.total_count > upPagination.count + upPagination.offset) {
      fetchMessages(upPagination.limit + upPagination.offset, 'up');
    }
  };
  const handleReadMessage = (id) => !!!_find(unreadMessages, m => m === id) && setUnreadMessages(prev => [...prev, id]);

  useEffect(() => {
    if (!!conversation_id && !created) {
      setLoading(true);
      clearLocalMessages()
      .then(() => 
        getMessages({ conversation_id, center_on_unread_message: true, my_id })
        .catch((e) => setError(e))
        .finally(() => setLoading(false))
      )
    }
  }, [conversation_id, created, my_id, getMessages, clearLocalMessages, setError]);

  return (
    <Root>
      { loading ? <MessagesSkeleton /> : <>
        {!!user_ids?.length && (!!state?.offer_id || !!state?.my_id) && <PlaceholderContainer>
          <BaseAvatarGroup sx={{ mt: 2, mb: 1, '.MuiAvatar-root': { marginLeft: '-24px', width: '120px', height: '120px', border: '4px solid #FFFFFF', boxShadow: '0px 0px 40px 0px #00000033' } }}>
            {_map(_slice(members, 0, 3), ({ id, user }, index) => !!user && (
              <BaseAvatar 
                key={id} 
                src={getRepresentation(user?.photo?.representations, representationVariants.TINY)} 
                firstName={user?.first_name} 
                lastName={user?.last_name}
                sx={{ zIndex: index }}
              />)
            )}
            {members.length > 3 && <Avatar>+{members.length - 3}</Avatar>}
          </BaseAvatarGroup>
          <Typography sx={{ fontSize: '1.25rem', fontStyle: 'italic', color: '#000' }}>Send the first message to start the chat</Typography>
        </PlaceholderContainer>}
        <ContentContainer>
          <Scrollbars 
            ref={scrollContainer} 
            autoHide 
            autoHideTimeout={100} 
            onScrollFrame={handleContainerScroll}
            renderView={({ style, ...props }) => <div {...props} style={{ ...style, ...{ display: 'flex', flexDirection: 'column-reverse' } }}/>}
          >
            <List>
              { _map(data, (item) => item.id !== 'divider' ? <Message key={item.id} message={item} readMessage={handleReadMessage} /> :
                <NewDivider divider={divider} key={item.id} /> 
              )}
            </List>
          </Scrollbars>
        </ContentContainer>
        { (!!conversation_id || !!user_ids?.length) && <InputContainer>
          <ErrorWrapper children={<Input />} />
        </InputContainer> }
      </> }
    </Root>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Messages {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);