import { memo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import ForwardIcon from 'components/Common/Icons/ForwardIcon';
// Local files
import { DesktopRoot, MobileRoot } from './CloseButton.styled';

const CloseButton = ({ onClick }) => {
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  if (mediaDesktop) {
    return (
      <DesktopRoot onClick={onClick}>
        <ForwardIcon />
      </DesktopRoot>
    );
  }
  return (
    <MobileRoot onClick={onClick}>
      <ForwardIcon />
    </MobileRoot>
  );
};

export default memo(CloseButton);