import styled from '@mui/material/styles/styled';
import bg from 'assets/images/background/conversation.jpg';

const Root = styled('div')({
  flexGrow: 1,
  width: '100%',
  position: 'relative',
  backgroundColor: '#fff',
  backgroundImage: `url('${bg}')`,
  backgroundSize: 'contain',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});
const PlaceholderContainer = styled('div')({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 16
});
const ContentContainer = styled('div')({
  flexGrow: 1,
  width: '100%',
  overflow: 'hidden',
  display:'block'
});
const List = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column-reverse',
  gap: '24px',
  padding: '30px 30px 90px 30px',
});
const InputContainer = styled('div')({
  flexShrink: 0,
  width: '100%',
  position: 'absolute',
  bottom: 0,
  overflow: 'hidden',
  padding: '16px',
});

export { Root, PlaceholderContainer, ContentContainer, List, InputContainer };