import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import bg from 'assets/images/background/dealflow-empty.jpg';

export const Root = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  overflow: 'hidden',
});

export const NavContainer = styled(Box)({
  height: '100%',
  padding: '14px 0 14px 12px',
});

export const DetailsContainer = styled(Box)({
  flexGrow: 1,
  height: '100%',
  overflowY: 'auto',
  padding: '0px 12px 14px 12px',
});

export const DetailsPaper = styled(Paper)({
  width: '100%',
  borderRadius: '10px',
  boxShadow: 'unset',
});

export const BackButton = styled(ButtonBase)(({ theme }) => ({
  position: 'fixed',
  left: 12, top: 12,
  zIndex: theme.zIndex.fab,
  minWidth: 80,
  minHeight: 37,
  fontWeight: 600,
  color: '#000',
  background: '#fff',
  borderRadius: '36px',
  paddingLeft: 4,
  paddingRight: 16,
  '& > svg': {
    height: '1rem',
    marginRight: 3,
  }
}));

export const EmptyContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'calc(100% - 12px)',
  backgroundImage: `radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 47.92%, ${theme.palette.background.default} 100%), url('${bg}')`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 12,
}));

export const EmptyTitle = styled(Typography)({
  fontWeight: 800,
  fontSize: '1.25rem',
  color: '#000',
  textAlign: 'center',
  marginBottom: 32,
});