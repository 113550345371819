import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import InputBase from '@mui/material/InputBase';
import { ReactComponent as SearchIcon } from 'assets/icons/extension/search.svg';

export const Container = styled(Box)(() => ({
  position: 'relative',
  backgroundColor: '#2F3137',
  overflow: 'auto'
}));

export const Header = styled(Box)(() => ({
  width: '100%',
  height: 52,
  backgroundColor: 'rgba(47,49,55,0.9)',
  backdropFilter: 'blur(4px)',
  WebkitBackdropFilter: 'blur(4px)',
  zIndex: 1
}));

export const HeaderContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '15px 20px'
}));

export const Title = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 600,
  color: '#fff',
  marginLeft: '6px',
  marginRight: '12px'
}));

export const AI = styled(props => <Typography component='span' {...props}>AI&nbsp;&nbsp;</Typography>)({
  fontSize: '15px',
  fontWeight: 700,
  background: '-webkit-linear-gradient(270deg, #A0BBFF, #1F5DFF)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent'
});

export const SignOutButton = styled(ButtonBase)(() => ({
  textDecoration: 'underline',
  fontWeight: 400,
  fontSize: '12px',
  color: '#fff',
  marginRight: .5
}));

export const MapContainer = styled(Box)(() => ({
  width: '100%',
  height: 300,
  position: 'relative',

  '& > *': {
    height: '100%'
  }
}));

export const Content = styled(Box)(() => ({
  position: 'relative',
  height: 'calc(650px - 300px + 30px - 4px)',
  backgroundColor: '#F3F3F5',
  overflow: 'hidden',
  borderTop: '2px solid #2F3137',
  marginTop: '-30px',
  zIndex: 3
}));

export const ContentHeader = styled(Box)(() => ({
  width: '100%',
  height: 40,
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  padding: '5px 20px',
}));

export const Search = styled(props => <InputBase startAdornment={<SearchIcon />} placeholder='Search' {...props} />)({
  flexBasis: '300px',

  '& input': {
    fontSize: '0.875rem',
    paddingLeft: '8px',
    fontWeight: 500
  }
});

export const Results = styled(Typography)(() => ({
  fontSize: '12px',
  color: '#000'
}));

export const ExportButton = styled(ButtonBase)(() => ({
  textDecoration: 'underline',
  fontSize: '12px',
  color: '#000'
}));

export const Footer = styled(Box)(() => ({
  position: 'absolute',
  bottom: 2,
  right: 0,
  left: 0,
  width: '100%',
  height: 30,
  backgroundColor: 'rgba(47,49,55,0.75)',
  backdropFilter: 'blur(4px)',
  WebkitBackdropFilter: 'blur(4px)'
}));