import { memo } from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
// Local files
import TableHeadCell from '../TableCellContent/TableCellContent';
import TableSortingCell from 'components/Common/DataDisplay/BaseTable/TableSortingCell/TableSortingCell';
import useCustomSelector from 'hooks/useCustomSelector';

const Head = ({ selectedItemsAmount, onSelectedChange, order, orderBy, changeSorting }) => {
  const { data, pagination } = useCustomSelector(state => state.contacts.all);
  const { pending } = useCustomSelector(state => state.contacts.all.filters);

  const pageContactsIds = _map(data, d => d.id);
  const pageContactsAmount = pagination.count;
  const headCells = [
    { id: 'first_name', title: 'First Name', align: 'left' },
    { id: 'last_name', title: 'Last Name', align: 'left' },
    { id: 'email', title: 'Email', align: 'left' },
    { id: 'lists', title: pending ? 'Actions' : 'Lists', align: 'left', hideSort: true },
    { id: 'mobile_phone', title: 'Mobile', align: 'left', hideSort: true },
    { id: 'office_phone', title: 'Office', align: 'left', hideSort: true },
    { id: 'office_phone_extension', title: 'Office Ext', align: 'left', hideSort: true },
    { id: 'notes', title: 'Notes', align: 'left', hideSort: true },
    { id: 'title', title: 'Title', align: 'left', hideSort: true },
    { id: 'company_name', title: 'Company', align: 'left', hideSort: true },
    { id: 'street_address', title: 'Street Address', align: 'left', hideSort: true },
    { id: 'second_address', title: 'Second Address', align: 'left', hideSort: true },
    { id: 'state', title: 'State', align: 'left', hideSort: true },
    { id: 'zip', title: 'Zip', align: 'left', hideSort: true },
    { id: 'city', title: 'City', align: 'left', hideSort: true },
    { id: 'location', title: 'Location', align: 'center', hideSort: true },
    { id: 'linkedin_profile_url', title: 'LinkedIn', align: 'center', hideSort: true },
    { id: 'website_url', title: 'Website', align: 'center', hideSort: true },
    { id: 'consent_status', title: 'Deal sharing consent status', align: 'center', hideSort: true },
    { id: 'created_at', title: 'Date Added', align: 'left' },
  ];
  
  const handleHeadCheckboxChange = checked => onSelectedChange(checked ? pageContactsIds : []);

  return (
    <TableHead>
      <TableRow>
        <TableHeadCell
          align='center'
          checkbox
          checked={pageContactsAmount === selectedItemsAmount}
          onChange={handleHeadCheckboxChange}
        />
        <TableHeadCell
          sx={{ whiteSpace: 'nowrap'}}
          title={!!selectedItemsAmount ? `${selectedItemsAmount} item${selectedItemsAmount > 1 ? 's' : ''} selected` : ''}
        />
        <TableSortingCell
          sx={{ whiteSpace: 'nowrap' }}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          changeSorting={changeSorting}
        />
      </TableRow>
    </TableHead>
  );
};

Head.propTypes = {
  order: PropTypes.string.isRequired, 
  orderBy: PropTypes.string.isRequired,
  changeSorting: PropTypes.func.isRequired,
  selectedItemsAmount: PropTypes.number.isRequired,
  onSelectedChange: PropTypes.func.isRequired
};

export default memo(Head);