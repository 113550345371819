import { memo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import { useNavigate, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import CloseIcon from 'components/Common/Icons/CloseIcon';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import UsersAutocomplete from 'components/Users/Autocomplete/Autocomplete';
import DealspacesSelect from 'components/Dealspaces/Select/Select';
import Team from 'components/Common/DataDisplay/Team/Team';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { checkEmptyString } from 'helpers';
import useBatch from 'hooks/useBatch';
import useChameleon from 'hooks/useChameleon';
import useCustomSelector from 'hooks/useCustomSelector';
import useDealspaces from 'hooks/useDealspaces';
import useError from 'hooks/useError';
import useMessages from 'hooks/useMessages';
import useOffers from 'hooks/useOffers';
import useSuccess from 'hooks/useSuccess';

const Dealspace = ({ open = false, edit = false, offerId = null, groupId = null, prefilledName = null, redirect = false, hideInvite = false, onClose }) => {
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { sendBatch } = useBatch();
  const { track: trackChameleon } = useChameleon();
  const { createDealspace, updateDealspace } = useDealspaces();
  const { setError } = useError();
  const { saveOffer, getFeedOfferDetails } = useOffers();
  const { createMessage } = useMessages();
  const { setSuccess } = useSuccess();
  const { activeDealspace, fullAccessDealspacesMembers, groupName } = useCustomSelector(state => ({ 
    activeDealspace: state.dealspaces.dealspace,
    fullAccessDealspacesMembers: _filter(state.members.groupList.data, (m) => !!m.user && m.user?.id !== state.profile.user?.id && (!!!m.permission || m.permission === 'full')),
    groupName: state.groups.group.name || _find(state.groups.all.data, (g) => g.id === groupId)?.name
  }));
  const [name, setName] = useState('');
  const [showInvites, setShowInvites] = useState(false);
  const [invites, setInvites] = useState([{ user: '' }]);
  const [message, setMessage] = useState('');
  const [dealspace, setDealspace] = useState('create');
  const [processing, setProcessing] = useState(false);
  const locationType = _includes(pathname, 'dashboard') ? 'dashboard' : _includes(pathname, ['groups', 'offerings']) ? 'group' : _includes(pathname, ['dealspaces', 'offerings']) ? 'dealspace' : 'feed';
  const title = offerId ? 'Save to a Dealspace' : `${edit ? 'Update' : 'Create new'} Dealspace`;
  const submitButtonText = offerId ? 'Save' : edit ? 'Update' : 'Create';

  const handleExited = () => {
    setName('');
    setShowInvites(false);
    setInvites([{ user: '' }]);
    setMessage('');
    setProcessing(false);
  };
  const handleEntering = () => {
    if (edit) setName(activeDealspace.name);
    if (prefilledName) setName(prefilledName);
  };
  const createInvites = ({ dealspaceId, groupId }) => {
    const requests = _map(invites, ({ user }) => ({
      method: 'post',
      url: `/groups/${groupId}/members`,
      params: { 
        member: {
          invitation_attributes: {
            ...(!user?.inputValue ? { user_id: user?.id } : { email: user?.inputValue }),
            ...(checkEmptyString(message) && { message })
          },
          role: 'collaborator',
          permission: 'partial',
          dealspace_ids: [dealspaceId]
        }
      }
    }));

    sendBatch(requests)
    .then(({ payload: { data: { responses } } }) => {
      if (!!_find(responses, r => r.status >= 400)) {
        setError(_find(responses, r => r.status >= 400)?.body?.error?.message);
      } else {
        if (!!offerId) setSuccess('Invitations to Dealspace sent');
      }
    })
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };
  const handleSubmit = () => {
    if (offerId) {
      setProcessing(true);

      if (dealspace === 'create') {
        createDealspace({ group_id: groupId, dealspace: { name } })
        .then(({ payload: { data: { dealspace: { id, conversation } } } }) => {
          trackChameleon('Dealspace created');
          setSuccess('Dealspace successfully created');
          setSuccess(
            <Typography
              component='div'
              sx={{ fontSize: '0.75rem', textDecoration: 'underline', fontWeight: 600, cursor: 'pointer' }}
              onClick={() => navigate(`/dealspaces/${id}/files`)}
            >
              Go to Dealspace
            </Typography>
          );
          saveOffer({ offer_id: offerId, dealspace_id: id, locationType })
          .then(() => {
            trackChameleon('Offering saved from feed')
            getFeedOfferDetails(offerId)
            .then(() => {
              setSuccess('Offering content and properties saved to Dealspace');
              onClose();
              if (!!message) createMessage({ conversation_id: conversation.id, message: { text: message }, local_id: '' }).catch(e => setError(e));
              if (showInvites) createInvites({ dealspaceId: id, groupId });
            })
            .catch(e => setError(e))
            .finally(() => setProcessing(false))
        })
        })
        .catch(e => setError(e).then(() => setProcessing(false)))
      } else {
        saveOffer({ offer_id: offerId, dealspace_id: dealspace, locationType })
        .then(() => {
          trackChameleon('Offering saved from feed');
          getFeedOfferDetails(offerId)
          .then(() => {
            setSuccess('Offering content and properties saved to Dealspace');
            onClose();
          })
          .catch(e => setError(e))
          .finally(() => setProcessing(false))
      })
        .catch(e => setError(e).then(() => setProcessing(false)))
      }
    } else {
      const dealspace = {
        ...edit && { id: activeDealspace.id },
        name
      };

      setProcessing(true);
      if (edit) {
        if (activeDealspace?.name !== dealspace?.name) {
          updateDealspace(dealspace)
          .then(() => {
            setSuccess('Offering content and properties saved to Dealspace');
            onClose();
          })
          .catch(e => setError(e))
          .finally(() => setProcessing(false))
        } else { 
          onClose();
        }
      } else {
        createDealspace({ group_id: groupId, dealspace })
        .then(({ payload: { data: { dealspace: { id, conversation } } } }) => {
          trackChameleon('Dealspace created');
          setSuccess('Dealspace successfully created');
          setSuccess(
            <Typography
              component='div'
              sx={{ fontSize: '0.75rem', textDecoration: 'underline', fontWeight: 600, cursor: 'pointer' }}
              onClick={() => navigate(`/dealspaces/${id}/properties`)}
            >
              Go to Dealspace
            </Typography>
          );
          onClose(id);
          if (showInvites) createInvites({ dealspaceId: id, groupId });
          if (!!message) createMessage({ conversation_id: conversation.id, message: { text: message }, local_id: '' }).catch(e => setError(e))
          if (redirect) navigate(`/dealspaces/${id}/properties`);
        })
        .catch(e => setError(e).then(() => setProcessing(false)))
        .finally(() => setProcessing(false));
      }
    }
  };
  const handleAddClick = () => {
    if (!showInvites) {
      setShowInvites(true);
    } else {
      setInvites(prev => [...prev, { user: '' }]);
    }
  };

  return (
    <BaseDialog
      open={open}
      onClose={() => onClose()}
      maxWidth={false}
      PaperProps={{ sx: { maxWidth: 700, width: '100%' } }}
      title={title}
      TransitionProps={{ onExited: handleExited, onEntering: handleEntering }}
      actions={
        <>
          <Button
            sx={{ px: '20px', minHeight: '37px' }}
            color='error'
            disabled={processing}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            sx={{ px: '30px' }}
            variant='contained'
            color='primary'
            disabled={processing}
            onClick={handleSubmit}
          >
            {submitButtonText}
          </Button>
        </>
      }
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: '16px !important' }}>
        <Box sx={{ width: '100%', maxWidth: '530px', margin: '0 auto'}}>
          {offerId && groupId &&
            <DealspacesSelect
              groupId={groupId}
              value={dealspace}
              onChange={setDealspace}
              disabled={processing}
              fullWidth
            />
          }
          {dealspace === 'create' &&
            <TextField
              fullWidth
              id='dealspaceNameInput'
              label='Dealspace Name'
              value={name}
              error={false}
              helperText={''}
              onChange={({ target: { value } }) => setName(value)}
              sx={{ mt: 2 }}
            />
          }
          {dealspace === 'create' && !hideInvite &&
            <>
              {!!fullAccessDealspacesMembers.length && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 4 }}>
                <Typography variant='caption'>Users with default access to all Dealspaces in <b>{groupName}</b>:</Typography>
                <Team members={fullAccessDealspacesMembers} sx={{ flexDirection: 'row', mt: 2, mb: 1, ml: '6px', '.MuiAvatar-root:last-child': { marginLeft: '-8px' } }} />
              </Box>}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                <Typography variant='caption'>Invite team members to this Dealspace (optional)</Typography>
                <Button sx={{ fontWeight: 700 }} onClick={handleAddClick} id='addInvitesButton'>+Add</Button>
              </Box>
              {showInvites && _map(invites, (invite, index) =>
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1.5, gap: '8px', flexWrap: { xs: 'wrap', sm: 'nowrap' } }}>
                  <UsersAutocomplete
                    sx={{...mediaDesktop && { width: '80%' } }}
                    value={invite.user}
                    groupId={groupId}
                    onChange={value => setInvites(prev => _map(prev, (p, i) => i === index ? ({ ...p, user: value }) : p))}
                  />
                  <IconButton onClick={() => setInvites(prev => _filter(prev, (_, i) => i !== index))}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
              {(showInvites || !!fullAccessDealspacesMembers.length) &&
                <TextField
                  placeholder='Start the conversation with a message...'
                  multiline
                  fullWidth
                  minRows={3}
                  maxRows={5}
                  sx={{ mt: 2 }}
                  value={message}
                  onChange={({ target: { value } }) => setMessage(value)}
                />
              }
            </>
          }
        </Box>
      </DialogContent>
    </BaseDialog>
  );
};

Dealspace.propTypes = {
  open: PropTypes.bool.isRequired,
  edit: PropTypes.bool,
  prefilledName: PropTypes.string,
  offerId: PropTypes.string,
  groupId: PropTypes.string,
  redirect: PropTypes.bool,
  hideInvite: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Dealspace {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);