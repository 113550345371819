import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import { sendBatch as sendBatchAction } from 'actions/batch';

const useBatch = () => {
  const dispatch = useDispatch();
  const sendBatch = useCallback(requests => dispatch(sendBatchAction(requests)), [dispatch]);

  return { sendBatch };
};

export default useBatch;