import { memo, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import _startsWith from 'lodash/startsWith';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import { downloadFile } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';
import useDocuments from 'hooks/useDocuments';
import useApp from 'hooks/useApp';

const ViewFile = () => {
  const { closeViewFileDialog } = useApp();
  const { fetchDocument, clearLocalActiveDocument } = useDocuments();
  const { id, file: f, open } = useCustomSelector(state => state.app.viewFileDialog);
  const [file, setFile] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const isPdf = useMemo(() => !!file && _startsWith(file?.content_type, 'application/pdf'), [file]);
  const isImage = useMemo(() => !!file && _startsWith(file?.content_type, 'image/'), [file]);

  const handleDownload = () => {
    setDownloading(true);
    downloadFile(file, () => setDownloading(false));
  };
  const onEntering = () => {
    if (id) fetchDocument(id).then(({ payload: { data: { document: { file } } } }) => setFile(file));
    if (f) setFile(f);
  };
  const onExited = () => {
    clearLocalActiveDocument();
    setFile(null);
  };

  return (
    <BaseDialog
      fullScreen
      open={open}
      onClose={closeViewFileDialog}
      otherPaperStyle={{ borderRadius: 0 }}
      otherActionsStyle={{ gap: '32px' }}
      TransitionProps={{ onEntering, onExited }}
      title={
        <>
          {isPdf && (
            <Button
              sx={{ position: 'absolute', left: { xs: '12px', sm: '24px'}, bottom: 8,  width: {xs: 'auto', sm: '160px'}, }}
              variant='contained'
              color='primary'
              onClick={handleDownload}
            >
              Download
            </Button>
          )}
          View File
        </>
      }
      content={
        <Box sx={{ height: '100%' }}>
          {!!file && isImage &&
            <img
              src={file?.url}
              style={{ display: 'block', width: '100%', height: '100%', objectFit: 'contain' }}
              alt='preview'
            />
          }
          {!!file && isPdf &&
            <iframe
              data-private
              style={{ display: 'block', width: '100%', height: '100%', border: 0 }}
              title="preview"
              src={file?.url+'#zoom=FitW'}
            />
          }
          {!!file && !isImage && !isPdf &&
            <Typography align='center' mt={4}>Preview unavailable</Typography>
          }
        </Box>
      }
      actions={!isPdf &&
        <>
          <Button
            sx={{ width: { xs: 'auto', sm: '160px' } }}
            onClick={closeViewFileDialog}
          >
            Close
          </Button>
          <Button
            sx={{ width: { xs: 'auto', sm: '160px' } }}
            variant='contained'
            color='primary'
            onClick={handleDownload}
            disabled={downloading}
          >
            Download
          </Button>
        </>
      }
    />
  );
};

export default memo(ViewFile);