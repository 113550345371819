import {
  sendConfirmationCodeRoutine,
  validateConfirmationCodeRoutine
} from 'actions';

export const initialState = {
  sended: false,
  validated: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case sendConfirmationCodeRoutine.SUCCESS: {
      const { status } = action.payload;

      return { ...state, sended: status === 204 };
    }
    case validateConfirmationCodeRoutine.SUCCESS: {
      const { status } = action.payload;

      return { ...state, validated: status === 204 };
    }
    default: {
      return state;
    }
  };
};

export default reducer;