import { memo, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import _round from 'lodash/round';
import Cropper from 'react-cropper';
import PropTypes from 'prop-types';
import 'cropperjs/dist/cropper.css';
// local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const Crop = ({ open, type, src, showHeightSlider = false, onClose, onSubmit }) => {
  const cropperRef = useRef(null);
  const [height, setHeight] = useState(80);
  const [scale, setScale] = useState(1);
  const [cropper, setCropper] = useState(null);
  const [processing, setProcessing] = useState(false);

  const getCroppedImg = fileName => {
    return new Promise((resolve, reject) => {
      cropper.getCroppedCanvas({
        width: 600,
        height: type === 'image' ? 450 : 200,
        imageSmoothingEnabled: false,
        fillColor: '#fff'
      })
      .toBlob(
        (blob) => {
          if (!blob) reject(new Error('Canvas is empty'));
          const file = new File([blob], fileName, { type: blob.type });

          resolve(file);
        },
        'image/jpeg',
        0.9
      );
    });
  }
  const handleSubmit = async () => {
    setProcessing(true);

    const croppedFile = await getCroppedImg('logo.png');

    onSubmit(croppedFile);
  };
  const handleEntering = () => {
    setHeight(type === 'image' ? 450 : 80);
  };
  const handleExited = () => {
    setHeight(80);
    setScale(1);
    setCropper(null);
    setProcessing(false);
  };
  const handleHeightChange = v => {
    setHeight(v);

    cropper.setCropBoxData({ height: v, top: 100 - (v / 2) });
  };
  const handleScaleChange = v => {
    setScale(_round(v, 1));

    cropper.scale(_round(v, 1), _round(v, 1));
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      otherPaperStyle={{ width: '100%', maxWidth: 670, height: '100%', maxHeight: 710 }}
      title={`Crop ${type === 'image' ? 'Image' : 'logo header'}`}
      TransitionProps={{ onEntering: handleEntering, onExited: handleExited }}
      actions={
        <>
          <Button
            sx={{ px: '20px', minHeight: '37px' }}
            color='error'
            disabled={processing}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ px: '30px' }}
            variant='contained'
            color='primary'
            disabled={processing}
            onClick={handleSubmit}
          >
            Apply
          </Button>
        </>
      }
    >
      <DialogContent sx={{ margin: '0 auto' }}>
        <Box display='flex' height={type === 'image' ? 450 : 200} width={600} justifyContent='center' mb={1}>
          <Cropper
            ref={cropperRef}
            src={src}
            style={{ width: 600, height: type === 'image' ? 450 : 200 }}
            dragMode='move'
            viewMode={0}
            movable
            rotatable={false}
            cropBoxMovable={false}
            cropBoxResizable={false}
            minCropBoxHeight={type === 'image' ? 450 : 80}
            minCropBoxWidth={600}
            center
            initialAspectRatio={type === 'image' ? 1.33 : 7.5}
            zoomOnTouch={false}
            zoomOnWheel={false}
            background={false}
            onInitialized={setCropper}
          />
        </Box>
        {showHeightSlider &&
          <Box>
            <Typography fontWeight={400} fontSize='12px' lineHeight='20px' textAlign='center'>
              Header height
            </Typography>
            <Box display='flex' alignItems='center'>
              <IconButton
                sx={{ width: 50, height: 50 }}
                onClick={() => handleHeightChange(height - 10)}
                disabled={height === 80}
              >
                <MinusIcon />
              </IconButton>
              <Slider
                color='secondary'
                value={height}
                min={80}
                max={200}
                step={10}
                marks
                onChange={(_, v) => handleHeightChange(v)}
              />
              <IconButton
                sx={{ width: 50, height: 50 }}
                onClick={() => handleHeightChange(height + 10)}
                disabled={height === 200}
              >
                <PlusIcon />
              </IconButton>
            </Box>
          </Box>
        }
        <Box>
          <Typography fontWeight={400} fontSize='12px' lineHeight='20px' textAlign='center'>
            Scale photo
          </Typography>
          <Box display='flex' alignItems='center'>
            <IconButton
              sx={{ width: 50, height: 50 }}
              onClick={() => handleScaleChange(scale - 0.1)}
              disabled={scale === 1}
            >
              <MinusIcon />
            </IconButton>
            <Slider
              color='secondary'
              value={scale}
              min={1}
              max={3}
              step={0.1}
              marks
              onChange={(_, v) => handleScaleChange(v)}
            />
            <IconButton
              sx={{ width: 50, height: 50 }}
              onClick={() => handleScaleChange(scale + 0.1)}
              disabled={scale === 3}
            >
              <PlusIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
    </BaseDialog>
  );
};

Crop.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  showHeightSlider: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

const WrappedCopmonent = props => {
  return (
    <ErrorWrapper>
      <Crop {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedCopmonent);