import groupBanner1 from 'assets/images/variants/group-banners/1.jpg';
import groupBanner2 from 'assets/images/variants/group-banners/2.jpg';
import groupBanner3 from 'assets/images/variants/group-banners/3.jpg';
import groupBanner4 from 'assets/images/variants/group-banners/4.jpg';
import userBanner1 from 'assets/images/variants/user-banners/1.jpg';
import userBanner2 from 'assets/images/variants/user-banners/2.jpg';
import userBanner3 from 'assets/images/variants/user-banners/3.jpg';
import userBanner4 from 'assets/images/variants/user-banners/4.jpg';
import dealspaceCover1 from 'assets/images/variants/dealspace-covers/1.jpg';
import dealspaceCover2 from 'assets/images/variants/dealspace-covers/2.jpg';
import dealspaceCover3 from 'assets/images/variants/dealspace-covers/3.jpg';
import dealspaceCover4 from 'assets/images/variants/dealspace-covers/4.jpg';

export const shortMonths = [
  'Jan', 'Feb', 'Mar',
  'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep',
  'Oct', 'Nov', 'Dec'
];

export const errorTypes = {
  AUTHORIZE_DATA_INVALID: 'not_authenticated'
};

export const errorMessages = {
  AUTHORIZE_DATA_INVALID: 'Incorrect email or password',
  ACCESS_FORBIDDEN: 'Access forbidden',
  RECORD_NOT_FOUND: 'Record not found',
  TOO_MANY_REQUESTS: 'Too many attempts, please try again later'
};

export const chatLevels = {
  USER: 'user',
  GROUP: 'group',
  DEALSPACE: 'dealspace'
};

export const cableMessageTypes = {
  ARCHIVE_GENERATED: 'archive_generated',
  AUDIT_CREATED: 'audit_created',
  MESSAGE_CREATED: 'message_created',
  READ_CREATED: 'read_created',
  USER_UNREAD_COUNTS_UPDATED: 'user_unread_general_feed_conversations_count_updated',
  USER_UNVISITED_OFFERS_COUNT_UPDATED: 'user_unvisited_offers_count_updated',
  USER_QUARANTINED_CONTACTS_COUNT_UPDATED: 'user_quarantined_contacts_count_updated',
  GROUP_UNREAD_COUNT_UPDATED: 'group_unread_dealspaces_count_updated',
  DEALSPACE_UNREAD_COUNT_UPDATED: 'dealspace_unread_offer_conversations_count_updated',
  DEALSPACE_UNREAD_CONVERSATION_UPDATED: 'dealspace_unread_conversation_updated',
  OFFER_UNREAD_COUNT_UPDATED: 'offer_unread_conversations_count_updated',
  OFFER_RECIPIENTS_COUNT_UPDATED: 'offer_recipients_count_updated',
  OFFER_UNLOCKED_RECIPIENTS_COUNT_UPDATED: 'offer_unlocked_recipients_count_updated',
  OFFER_APPROVAL_REQUESTED_RECIPIENTS_COUNT_UPDATED: 'offer_approval_requested_recipients_count_updated',
  AGREEMENT_SIGNED: 'agreement_signed',
  RECIPIENT_UPDATED: 'recipient_updated',
  CONTACTS_PROGRESS_UPDATED: 'contacts_progress_updated',
  CONTACTS_IMPORTED: 'contacts_imported',
  TEMPLATE_CONFIGURED: 'template_configured',
  NOTIFICATION_CREATED: 'notification_created',
  USER_UNREAD_NOTIFICATIONS_COUNT_UPDATED: 'user_unread_notifications_count_updated'
};

export const offerStatuses = {
  DRAFT: 'draft', 
  ACTIVE: 'active',
  IN_CONTRACT: 'in_contract', 
  ENDED: 'ended',
  CLOSED: 'closed',
  SAVED: 'saved'
};

export const riskProfilesList = ['core', 'core_plus', 'value_add', 'opportunistic', 'distressed'];

export const deadlineTypes = ['target_closing_date', 'closing_date', 'call_for_offers', 'final_call_for_offers', 'bid_deadline', 'final_bid_deadline'];

export const roles = [
  { value: 'owner', label: 'Owner', description: 'Can add/remove files, create/send Offerings, add/remove Collaborators in authorized dealspaces (can set permissions) and Activity Log access' },
  { value: 'collaborator', label: 'Collaborator', description: 'Can add/remove files, create/send Offerings, only add Collaborators in authorized dealspaces and Activity Log access (only where sender)' }
];

export const primaryActivities = [
  { value: 'residential_sales', label: 'Residential Sales' },
  { value: 'commercial_investment_sales', label: 'Commercial Investment Sales' },
  { value: 'investment_banking', label: 'Investment Banking' },
  { value: 'mortgage_brokering', label: 'Mortgage Brokering' },
  { value: 'capital_advisory', label: 'Capital Advisory' },
  { value: 'passive_investing', label: 'Passive Investing' },
  { value: 'active_investing', label: 'Active Investing (development, value-add etc)' },
  { value: 'institutional_lending', label: 'Institutional Lending' },
  { value: 'private_lending', label: 'Private Lending' },
  { value: 'property_management', label: 'Property Management' },
  { value: 'asset_management', label: 'Asset Management' },
  { value: 'fund_management', label: 'Fund Management' },
  { value: 'construction', label: 'Construction' },
  { value: 'construction_management', label: 'Construction Management' },
  { value: 'general_contracting', label: 'General Contracting' },
  { value: 'legal_services', label: 'Legal Services' },
  { value: 'title_services', label: 'Title Services' },
  { value: 'real_estate_insurance', label: 'Real Estate Insurance' },
  { value: 'property_appraisals', label: 'Property Appraisals / Valuations' },
  { value: 'market_studies_valuations', label: 'Market Studies' },
  { value: 'accounting', label: 'Accounting' },
  { value: 'design_engineering', label: 'Design/Engineering' },
  { value: 'owners_representation', label: 'Owner’s Representation' },
  { value: 'lenders_representation', label: 'Lender’s Representation'},
  { value: 'consulting', label: 'Consulting' },
  { value: 'marketing_and_communications', label: 'Marketing & Communications' },
  { value: 'technology', label: 'Technology' }
];

export const assetClassesList = [
  'land',
  'development_rights',
  'hotel',
  'motel',
  'resort',
  'timeshare',
  'entertainment_parks',
  'golf_course',
  'marina',
  'flex_industrial',
  'refrigerated',
  'warehouse',
  'distribution',
  'self_storage',
  'data_center',
  'office',
  'co_working',
  'medical_office',
  'multifamily',
  'residential_condominium',
  'single_family_residential',
  'student_housing',
  'senior_housing',
  'mobile_home_parks',
  'townhomes',
  'coops',
  'co_living',
  'single_tenant_retail',
  'shopping_mall',
  'storefront_retail',
  'strip_mall',
  'parking_garage',
  'parking_lot',
  'short_term_rental',
  'affordable_housing', 
  'mixed_use', 
  'tax_credits', 
  'restaurant', 
  'liquor_license', 
  'laundromat', 
  'car_wash', 
  'gas_station',
  'rv_park'
];

export const contentTypes = [
  'publication',
  'article',
  'website',
  'appearance',
  'interview',
  'promotion',
  'brochure',
  'project_website',
  'market_report',
  'market_study',
  'white_paper',
  'video',
  'closing',
  'work_sample',
  'award'
];

export const notificationTypes = {
  CONTACT_INVITATION_CREATED: 'contact_invitation_created',
  CONTACT_INVITATION_ACCEPTED: 'contact_invitation_accepted',
  GROUP_INVITATION_CREATED: 'group_invitation_created',
  GROUP_INVITATION_ACCEPTED: 'group_invitation_accepted',
  DEALSPACE_CREATED: 'dealspace_created',
  RECIPIENT_CREATED: 'recipient_created',
  OFFER_UNLOCKED: 'offer_unlocked',
  AGREEMENT_SIGNED: 'agreement_signed',
  RECIPIENT_APPROVED: 'recipient_approved',
  BOOSTER_NOTIFIED: 'booster_notified',
  SHARE_FORM_SUBMITTED: 'share_form_submitted'
};

export const builderSectionStatuses = {
  COMPLETE: 'complete',
  ERROR: 'error',
  WARNING: 'warning',
  UNCOMPLETE: 'uncomplete'
};

export const lockedPartsItems = {
  LOCATIONS: 'locations',
  DOCUMENTS: 'documents',
  CONTENT: 'content'
};

export const interestLevels = {
  ZERO: 'zero',
  LIGHT: 'light',
  MEDIUM: 'medium',
  STRONG: 'strong',
  CRITICAL: 'critical'
};

export const representationVariants = {
  TINY: 'tiny', // 250x250
  SMALL: 'small', // 500x500
  MEDIUM: 'medium', // 600x600
};

export const endpoints = {
  RECIPIENT: 'recipient',
  USER: 'user'
};

export const defaultGroupBanners = [
  { id: 'group_banner_1', name: 'group_banner_1.jpg', src: groupBanner1 },
  { id: 'group_banner_2', name: 'group_banner_2.jpg', src: groupBanner2 },
  { id: 'group_banner_3', name: 'group_banner_3.jpg', src: groupBanner3 },
  { id: 'group_banner_4', name: 'group_banner_4.jpg', src: groupBanner4 }
];
export const defaultUserBanners = [
  { id: 'user_banner_1', name: 'user_banner_1.jpg', src: userBanner1 },
  { id: 'user_banner_2', name: 'user_banner_2.jpg', src: userBanner2 },
  { id: 'user_banner_3', name: 'user_banner_3.jpg', src: userBanner3 },
  { id: 'user_banner_4', name: 'user_banner_4.jpg', src: userBanner4 }
];
export const defaultDealspaceCovers = [
  { id: 'dealspace_cover_1', name: 'dealspace_cover_1.jpg', src: dealspaceCover1 },
  { id: 'dealspace_cover_2', name: 'dealspace_cover_2.jpg', src: dealspaceCover2 },
  { id: 'dealspace_cover_3', name: 'dealspace_cover_3.jpg', src: dealspaceCover3 },
  { id: 'dealspace_cover_4', name: 'dealspace_cover_4.jpg', src: dealspaceCover4 }
];

export const states = [
  { value: 'al', label: 'Alabama' },
  { value: 'ak', label: 'Alaska' },
  { value: 'az', label: 'Arizona' },
  { value: 'ar', label: 'Arkansas' },
  { value: 'ca', label: 'California' },
  { value: 'co', label: 'Colorado' },
  { value: 'ct', label: 'Connecticut' },
  { value: 'de', label: 'Delaware' },
  { value: 'dc', label: 'District of Columbia' },
  { value: 'fl', label: 'Florida' },
  { value: 'ga', label: 'Georgia' },
  { value: 'hi', label: 'Hawaii' },
  { value: 'id', label: 'Idaho' },
  { value: 'il', label: 'Illinois' },
  { value: 'in', label: 'Indiana' },
  { value: 'ia', label: 'Iowa' },
  { value: 'ks', label: 'Kansas' },
  { value: 'ky', label: 'Kentucky' },
  { value: 'la', label: 'Louisiana' },
  { value: 'me', label: 'Maine' },
  { value: 'md', label: 'Maryland' },
  { value: 'ma', label: 'Massachusetts' },
  { value: 'mi', label: 'Michigan' },
  { value: 'mn', label: 'Minnesota' },
  { value: 'ms', label: 'Mississippi' },
  { value: 'mo', label: 'Missouri' },
  { value: 'mt', label: 'Montana' },
  { value: 'ne', label: 'Nebraska' },
  { value: 'nv', label: 'Nevada' },
  { value: 'nh', label: 'New Hampshire' },
  { value: 'nj', label: 'New Jersey' },
  { value: 'nm', label: 'New Mexico' },
  { value: 'ny', label: 'New York' },
  { value: 'nc', label: 'North Carolina' },
  { value: 'nd', label: 'North Dakota' },
  { value: 'oh', label: 'Ohio' },
  { value: 'ok', label: 'Oklahoma' },
  { value: 'or', label: 'Oregon' },
  { value: 'pa', label: 'Pennsylvania' },
  { value: 'ri', label: 'Rhode Island' },
  { value: 'sc', label: 'South Carolina' },
  { value: 'sd', label: 'South Dakota' },
  { value: 'tn', label: 'Tennessee' },
  { value: 'tx', label: 'Texas' },
  { value: 'ut', label: 'Utah' },
  { value: 'vt', label: 'Vermont' },
  { value: 'va', label: 'Virginia' },
  { value: 'wa', label: 'Washington' },
  { value: 'wv', label: 'West Virginia' },
  { value: 'wi', label: 'Wisconsin' },
  { value: 'wy', label: 'Wyoming' }
];

export const propertyGroupings = ['portfolio', 'assemblage', 'fund'];

export const singlePropertyDealTypes = [
  'active_equity_investment',
  'passive_equity_investment',
  'preferred_equity_investment',
  'partnership_opportunity',
  'property_for_sale',
  'development_rights_for_sale',
  'loan_for_sale',
  'ground_lease',
  'property_for_sale_leaseback',
  'permanent_loan_request',
  'construction_loan_request',
  'acquisition_loan_request',
  'bridge_loan_request'
];

export const portfolioDealTypes = [
  'property_for_sale',
  'loan_for_sale',
  'active_equity_investment',
  'passive_equity_investment',
  'preferred_equity_investment',
  'partnership_opportunity',
  'permanent_loan_request',
  'bridge_loan_request'
];

export const assemblageDealTypes = [
  'land_assemblage_for_sale',
  'active_equity_investment',
  'passive_equity_investment',
  'preferred_equity_investment',
  'partnership_opportunity',
  'acquisition_loan_request',
  'bridge_loan_request'
];

export const fundDealTypes = [
  'active_equity_investment',
  'passive_equity_investment',
  'partnership_opportunity'
];

export const contentAcceptTypes = 'image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const dragAndDropItemTypes = {
  SENDER: 'sender'
};