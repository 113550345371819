import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation } from 'react-router-dom';
// Local files
import { StyledButton } from './ShowAllChatsButton.styled';
import ArrowBackIcon from 'components/Common/Icons/ArrowBackIcon';
import useCustomSelector from 'hooks/useCustomSelector';
import useDealspaces from 'hooks/useDealspaces';
import useConversations from 'hooks/useConversations';
import useMessages from 'hooks/useMessages';
import useError from 'hooks/useError';
import useApp from 'hooks/useApp';
import { chatLevels } from 'helpers/constants';

const ShowAllChatsButton = ({ disabled }) => {
  const navigate = useNavigate();
  const previousLocation = useLocation();
  const { setError } = useError();
  const { getDealspacesForChats } = useDealspaces();
  const { clearLocalActiveConversation } = useConversations();
  const { clearLocalMessages } = useMessages();
  const { setChatsFilter } = useApp();
  const { group_id, filter } = useCustomSelector(state => ({ 
    group_id: state.conversations.conversation.conversationable?.group?.id,
    filter: state.app.chats.filter
  }));
  const [loading, setLoading] = useState(false);

  const handleBackClick = () => { 
    setLoading(true)
    getDealspacesForChats({ group_id, offset: 0 })
    .then(() => 
      clearLocalActiveConversation()
      .then(() => 
        clearLocalMessages()
        .then(() => setChatsFilter({ filter }).then(() => navigate(`/chats/${chatLevels.GROUP}/${group_id}`, { state: previousLocation.state })))
      )
    )
    .catch(e => setError(e))
    .finally(() => setLoading(false))
  };

  return (
    <StyledButton disableRipple disabled={loading || disabled} onClick={handleBackClick}>
      <ArrowBackIcon />
      <Typography variant='subtitle2' component='span'>Show all Chats</Typography>
    </StyledButton>
  );
};

export default memo(ShowAllChatsButton);