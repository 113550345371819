import styled from '@mui/material/styles/styled';
import illustrationSrc from 'assets/images/background/property-empty.png';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '12px',
  color: '#A8A8A8',
  padding: '32px 16px 40px',
  [theme.breakpoints.up('md')]: {
    padding: '50px 16px',
  }
}));

export const Mark = styled('span')({
  fontWeight: 700,
  textDecoration: 'underline'
});

export const Illustration = styled(props => (
  <img src={illustrationSrc} alt='illustration' {...props} />
))(() => ({
  display: 'block',
  width: '100%',
  maxWidth: 300,
  margin: '16px 0 16px',
}));