import styled from '@mui/material/styles/styled';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
// Local files
import KeyboardArrowDownIcon from 'components/Common/Icons/KeyboardArrowDownIcon';

const Root = styled(props => (
  <MuiAccordion elevation={0} {...props} />
))({
  width: '100%',
  backgroundColor: '#FCFCFC',
  borderRadius: '10px !important',
  border: '1px solid #E0E0E0',
  padding: 0,
  '&:before': {
    display: 'none'
  },
  '&.Mui-expanded': {
    margin: '0 0 0 0 !important'
  }
});

const Header = styled(props => (
  <MuiAccordionSummary expandIcon={<KeyboardArrowDownIcon sx={{ color: '#AAAEB2', }} />} {...props} />
))({
  minHeight: 56,
  padding: '0 16px 0 20px',
  '&.Mui-expanded': {
    minHeight: 56,
  },
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
    paddingTop: 8,
    paddingBottom: 8,
    '&.Mui-expanded': {
      margin: 0
    }
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent'
  }
});

const Content = styled(MuiAccordionDetails)({});

const Type = styled((props) => (
  <Typography variant='small' component='div' noWrap {...props} />
))({
  color: '#7D99E0',
  fontWeight: 700,
  textTransform: 'capitalize',
  marginBottom: 2,
});

const Title = styled((props) => (
  <Typography variant='caption'  color='text.primary' noWrap component='div' {...props} />
))({
  fontWeight: 600,
  lineHeight: '1.2'
});

const Actions = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '12px',
  paddingTop: 40,
});

export { Root, Header, Content, Type, Title, Actions };