import { memo } from 'react';
import _map from 'lodash/map';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import BaseAvatarGroup from 'components/Common/DataDisplay/BaseAvatarGroup/BaseAvatarGroup';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';

const GroupTeam = ({ members, sx }) => {
  return (
    <BaseAvatarGroup max={2} sx={sx} spacing='small'>
      {_map(members, ({ id, user }) => !!user && <BaseAvatar key={id} src={getRepresentation(user?.photo?.representations, representationVariants.TINY)} firstName={user?.first_name} lastName={user?.last_name} />)}
    </BaseAvatarGroup>
  );
};

GroupTeam.propTypes = {
  members: PropTypes.array.isRequired
};

const ErrorFallback = ({ error }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <GroupTeam {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);