import { memo, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// Local files
import { Folder, FolderContent, FolderName, FolderInfo, StyledCheckbox, DeleteButton, BlurredPlaceholder } from './Card.styled';
import { ReactComponent as FolderIcon } from 'assets/icons/folder-large-color.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu-vert.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock-filled2.svg';
import { ReactComponent as PendingIcon } from 'assets/icons/pending-white.svg';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import FolderDialog from 'components/Dialogs/Folder/Folder';
import { formatBytes } from 'helpers';
import { DELETE_FOLDER } from 'helpers/confirmations';
import useApp from 'hooks/useApp';
import useArchives from 'hooks/useArchives';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';

const Card = ({
  id, name, size, count, isCheckbox = false, disabled = false, checked = false, blurred = false, pending = false, downloadable = false, hideMenu = false,
  onCheck = null, onClick = null, onDelete = null
}) => {
  const navigate = useNavigate();
  const { openDeleteDialog } = useApp();
  const { createArchive } = useArchives();
  const { setError } = useError();
  const { dealspace_id } = useParams();
  const { setSuccess } = useSuccess();
  const [anchorEl, setAnchorEl] = useState(null);
  const [folderDialog, setFolderDialog] = useState({ open: false, data: null });
  const [processing, setProcessing] = useState(false);

  const handleFolderClick = () => {
    if (onDelete) return;
    if (downloadable && !blurred) {
      setProcessing(true);
      createArchive(id)
      .then(() => setSuccess('Preparing archive...'))
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
      return;
    }
    if (onClick) {
      onClick();
    }
    if (!onClick) {
      navigate(`/dealspaces/${dealspace_id}/files/${id}`);
    }
  };
  const handleCheckboxClick = e => {
    e.stopPropagation();
    onCheck();
  };
  const handleDialogClose = () => setFolderDialog({ open: false, data: null });
  const handleMenuClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleDownloadClick = e => {
    e.stopPropagation();
    setAnchorEl(null);
    setProcessing(true);
    createArchive(id)
    .then(() => setSuccess('Preparing archive...'))
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };
  const handleRenameClick = e => {
    e.stopPropagation();
    setAnchorEl(null);
    setFolderDialog({ open: true, data: { id, name } });
  };
  const handleDeleteClick = e => {
    e.stopPropagation();
    setAnchorEl(null);
    openDeleteDialog({ type: DELETE_FOLDER, id });
  };

  return (
    <Box>
      <FolderDialog
        {...folderDialog}
        onClose={handleDialogClose}
      />
      <Folder onClick={handleFolderClick}>
        {blurred &&
          <BlurredPlaceholder onClick={handleFolderClick}>
            <LockIcon />
          </BlurredPlaceholder>
        }
        {!blurred && pending &&
          <BlurredPlaceholder onClick={handleFolderClick}>
            <PendingIcon />
            <Typography fontWeight={600} fontSize='11px' lineHeight='20px' color='#FFFFFF'>
              Pending access approval...
            </Typography>
          </BlurredPlaceholder>
        }
        {isCheckbox &&
          <StyledCheckbox
            onClick={handleCheckboxClick}
            disabled={disabled}
            checked={checked}
          />
        }
        {onDelete &&
          <DeleteButton onClick={onDelete}>
            <CloseIcon />
          </DeleteButton>
        }
        <FolderContent sx={{ userSelect: blurred ? 'none' : 'unset' }}>
          <FolderIcon />
          <FolderName>{name}</FolderName>
          {!hideMenu &&
            <IconButton
              sx={{ position: 'absolute', right: 5, top: '50%', width: 20, height: 20, p: 'unset', zIndex: 1 }}
              onClick={handleMenuClick}
            >
              <MenuIcon />
            </IconButton>
          }
          {!hideMenu &&
            <Menu
              keepMounted
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem disabled={processing} onClick={handleDownloadClick}>
                <Typography fontSize='12px' lineHeight='18px' color='black' noWrap fontWeight={400}>
                  Download
                </Typography>
              </MenuItem>
              <MenuItem disabled={processing} onClick={handleRenameClick}>
                <Typography fontSize='12px' lineHeight='18px' color='black' noWrap fontWeight={400}>
                  Rename
                </Typography>
              </MenuItem>
              <MenuItem disabled={processing} onClick={handleDeleteClick}>
                <Typography fontSize='12px' lineHeight='18px' color='red' noWrap fontWeight={400}>
                  Delete
                </Typography>
              </MenuItem>
            </Menu>
          }
          <FolderInfo>{formatBytes(size)} | {count.toString()} file{count !== 1 ? 's' : ''}</FolderInfo>
        </FolderContent>
      </Folder>
    </Box>
  );
};

Card.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  isCheckbox: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  blurred: PropTypes.bool,
  downloadable: PropTypes.bool,
  hideMenu: PropTypes.bool,
  onCheck: PropTypes.func,
  onClick: PropTypes.func
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Card {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);