import styled from '@mui/material/styles/styled';

const Root = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden'
});
const Content = styled('div')({
  flexGrow: 1,
  width: '100%',
  padding: '24px 32px 32px',
  '& > *': {
    margin: '0 0 16px !important'
  }
});

export { Root, Content };