import { memo, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import _map from 'lodash/map';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// Local files
import Paper from 'components/Common/DataDisplay/BaseTable/Paper/Paper';
import TablePagination from 'components/Common/DataDisplay/BaseTable/TablePagination/TablePagination';
import TableCell from 'components/Common/DataDisplay/BaseTable/TableCell/TableCell';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import PaginationSkeleton from 'components/Common/DataDisplay/BaseTable/PaginationSkeleton/PaginationSkeleton';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { ReactComponent as FileIcon } from 'assets/icons/file.svg';
import { representationVariants } from 'helpers/constants';
import { getFormattedDate, getRepresentation } from 'helpers';
import useAgreements from 'hooks/useAgreements';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';

const headerCells = [
  { value: 'relatedOffering', label: 'Related Offering' },
  { value: 'primarySender', label: 'Primary Sender' },
  { value: 'dateSigned', label: 'Date Signed' },
  { value: 'agreement', label: 'Agreement' }
];

const AgreementsTable = ({ mobile = false }) => {
  const { getAgreements, clearLocalAgreements } = useAgreements();
  const { setError } = useError();
  const { agreements, pagination } = useCustomSelector(state => ({
    agreements: state.agreements.all.data,
    pagination: state.agreements.all.pagination,
  }));
  const [page, setPage] = useState(0);
  const [fetching, setFetching] = useState(true);
  const fullPages = parseInt(pagination.total_count / pagination.limit);
  const partialPage = pagination.total_count % pagination.limit === 0 ? 0 : 1;
  const showPagination = fullPages + partialPage > 1;

  const handleDownloadClick = url => window.open(url, '_blank');

  useEffect(() => {
    setFetching(true);
    getAgreements({ offset: page * 25 })
    .catch(e => setError(e))
    .finally(() => setFetching(false));
  }, [getAgreements, setError, page]);
  useEffect(() => {
    return clearLocalAgreements;
  }, [clearLocalAgreements]);

  return (
    <Paper
      footer={fetching ? <PaginationSkeleton /> : showPagination &&
        <TablePagination
          rowsPerPage={25}
          count={pagination.total_count}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
        />
      }
      sx={{ marginTop: 2 }}
      mobile={mobile}
    >
      <Table>
        <TableHead>
          <TableRow>
            {_map(headerCells, c =>
              <TableCell key={c.value} align='left' sx={{ fontWeight: 700, ...mobile && { minWidth: 150 } }}>
                {c.label}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {_map(agreements, ({ id, file, template, created_at }) =>
            <TableRow key={id}>
              <TableCell sx={{ fontWeight: 400, fontSize: '12px', lineHeight: '15px', ...mobile && { minWidth: 150 } }}>
                {template?.offer?.headline
                  ? <Link
                      to={`/deal_flow/${template.offer.id}`}
                      style={{ color: '#3C3C3C' }}
                    >
                      {template?.offer?.headline}
                    </Link>
                  : '-'
                }
              </TableCell>
              <TableCell sx={{ ...mobile && { minWidth: 150 } }}>
                {template?.offer?.primary_sender?.user ?
                  <Box display='flex' alignItems='center'>
                    <BaseAvatar
                      firstName={template.offer.primary_sender.user.first_name ?? ''}
                      lastName={template.offer.primary_sender.user.last_name ?? ''}
                      src={getRepresentation(template.offer.primary_sender.user.photo?.representations, representationVariants.TINY)}
                      sx={{ width: '18px', height: '18px', fontSize: '10px' }}
                    />
                    <Box ml={1}>
                      <Typography fontWeight={400} fontSize='12px' lineHeight='15px'>
                        {template.offer.primary_sender.user.first_name} {template.offer.primary_sender.user.last_name}
                      </Typography>
                    </Box>
                  </Box>
                : '-'}
              </TableCell>
              <TableCell sx={{ fontWeight: 400, fontSize: '12px', lineHeight: '15px', ...mobile && { minWidth: 150 } }}>
                {getFormattedDate(created_at)}
              </TableCell>
              <TableCell sx={{ ...mobile && { minWidth: 150 } }}>
                {file ?
                  <Button
                    startIcon={<FileIcon />}
                    variant='outlined'
                    onClick={() => handleDownloadClick(file.url)}
                  >
                    {file.filename}
                  </Button>
                  : '-'
                }
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

AgreementsTable.propTypes = {
  mobile: PropTypes.bool
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <AgreementsTable {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);