import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Root, Image, Content, Title, Description } from 'components/Recipients/ActivityLog/Empty/Empty.styled';
import offeringMarketSrc from 'assets/images/background/offering-market-share.png';
import offeringPrivateSrc from 'assets/images/background/offering-private-share.png';
import LockedFilledIcon from 'components/Common/Icons/LockedFilledIcon';
import SharingAccessDialog from 'components/Dialogs/SharingAccess/SharingAccess';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';

const Empty = ({ id, groupId, name, headline, public: isPublic = false, primary_sender, onUpdate }) => {
  const [sharingDialog, setSharingDialog] = useState({ open: false, name: '', public: false, headline: '', offerId: '', groupId: '', primarySender: null });

  const handleShareClick = () => {
    setSharingDialog({ open: true, name, headline, public: isPublic, offerId: id, groupId, primarySender: primary_sender });
  };
  const handleSharingDialogClose = needToUpdateRecipients => {
    setSharingDialog({ open: false, name: '', public: false, headline: '', offerId: '', groupId: '', primarySender: null });

    if (needToUpdateRecipients) onUpdate();
  };
  
  if (isPublic) {
    return (
      <Root>
        <SharingAccessDialog
          {...sharingDialog}
          onClose={handleSharingDialogClose}
        />
        <Image src={offeringMarketSrc} />
        <Content>
          <Title>Let’s get the word out</Title>
          <Description>Share this Offering with people in your network.</Description>
          <Button
            variant='gradient'
            sx={{ minWidth: 90 }}
            onClick={handleShareClick}
          >
            Share
          </Button>
        </Content>
      </Root>
    );
  }
  return (
    <Root>
      <SharingAccessDialog
        {...sharingDialog}
        onClose={handleSharingDialogClose}
      />
      <Image src={offeringPrivateSrc} />
      <Content>
        <Title>This Offering has not been shared with anyone</Title>
        <Description>Hey there, it’s going to be pretty hard to close this deal if nobody knows about it.</Description>
        <Button
          variant='gradient'
          sx={{ minWidth: 90 }}
          onClick={handleShareClick}
        >
          <LockedFilledIcon sx={{ color: 'inherit', fontSize: '16px', mr: '4px' }} />Share
        </Button>
      </Content>
    </Root>
  );
};

Empty.propTypes = {
  id: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  public: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  primary_sender: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Empty {...props} />
    </ErrorBoundary>
  );
};
export const Fallback = () => {
  return <Skeleton variant='rectangular' width={300} height={300} />;
};

export default memo(WrappedComponent);