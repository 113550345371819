import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Root = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  padding: 8,
  flexDirection: 'column',
  '& .gmnoprint': {
    display: 'none'
  },
  '& .gm-style-cc': {
    display: 'none'
  }
}));
const MapContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '320px',
  borderRadius: '6px',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    height: '480px',
  }
}));
const Head = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '32px 28px',
  [theme.breakpoints.up('md')]: {
    padding: '40px 40px'
  }
}));
const Title = styled((props) => (
  <Typography variant='caption' color='text.hint' component='div' {...props} />
))({
  flexShrink: 0
});
const TextContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center'
}));
const Text = styled((props) => (
  <Typography variant='caption' component='div' sx={{ textDecoration: 'underline', mr: '4px' }} {...props} />
))({});

export { Root, Head, MapContainer, TextContainer, Title, Text };