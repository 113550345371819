import SvgIcon from '@mui/material/SvgIcon';

export default function MenuVert(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M12.2794 8.33335C13.3133 8.33335 14.1593 7.50835 14.1593 6.50002C14.1593 5.49169 13.3133 4.66669 12.2794 4.66669C11.2454 4.66669 10.3994 5.49169 10.3994 6.50002C10.3994 7.50835 11.2454 8.33335 12.2794 8.33335ZM12.2794 10.1667C11.2454 10.1667 10.3994 10.9917 10.3994 12C10.3994 13.0084 11.2454 13.8334 12.2794 13.8334C13.3133 13.8334 14.1593 13.0084 14.1593 12C14.1593 10.9917 13.3133 10.1667 12.2794 10.1667ZM12.2794 15.6667C11.2454 15.6667 10.3994 16.4917 10.3994 17.5C10.3994 18.5084 11.2454 19.3334 12.2794 19.3334C13.3133 19.3334 14.1593 18.5084 14.1593 17.5C14.1593 16.4917 13.3133 15.6667 12.2794 15.6667Z" />
    </SvgIcon>
  );
}

