import styled from '@mui/material/styles/styled';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';

export const avatarStyles = {
  width: '32px !important',
  height: '32px !important',
  fontWeight: '600 !important',
  fontSize: '16px !important',
  borderRadius: '4px !important',
};
export const avatarInvitedStyles = {
  opacity: '0.4 !important',
};
export const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '6px',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'column',
  }
}));
export const AddButton = styled(ButtonBase)({
  width: '32px',
  height: '32px',
  color: '#ACB3BC',
  background: '#E5E8EC',
  borderRadius: '4px',
});