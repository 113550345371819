import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams } from 'react-router-dom';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { downloadFile } from 'helpers';
import UploadIcon from 'components/Common/Icons/UploadIcon';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useConversations from 'hooks/useConversations';

const Agreement = () => {
  const { offer_id } = useParams();
  const { closeAgreementDialog } = useApp();
  const { openOrCreateConversation } = useConversations();
  const { open, url, fileUrl, filename, template, conversation, isMyShared, primary_sender_id } = useCustomSelector(state => ({
    open: state.app.agreementDialog.open,
    url: state.app.agreementDialog.url,
    fileUrl: state.offers.dealflow.details.offer.agreement?.template?.file?.url ?? null,
    filename: state.offers.dealflow.details.offer.agreement?.template?.file?.filename ?? null, 
    primary_sender_id: state.offers.dealflow.details.offer.primary_sender?.user?.id,
    template: state.templates.offer.data.find(t => t.id === state.offers.dealflow.details.offer.agreement?.template?.id),
    conversation: _find(_filter(state.conversations.user.feed.data.concat(state.conversations.offerings.data), 
      (c) => c?.conversationable?.id === offer_id), (c) => c?.users?.length === 2 && !!_find(c?.users, (u) => u.id === state.offers.dealflow.details.offer.primary_sender?.user?.id)),
    isMyShared: !!_find(state.senders.all.data, ({ user }) => user?.id === state.profile.user.id)
  }));
  const mediaMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [processing, setProcessing] = useState(false);

  const handleConversationClick = () => {
    setProcessing(true);
    closeAgreementDialog()
    .then(() => openOrCreateConversation(primary_sender_id, conversation, isMyShared))
    setProcessing(false);
  };
  const handleDownloadClick = () => downloadFile({ url: fileUrl, filename });

  return (
    <BaseDialog
      open={open}
      onClose={closeAgreementDialog}
      otherPaperStyle={{ height: mediaMobile ? '100%' : '75vh' }}
      fullScreen={mediaMobile}
      title={
        <>
          <Box display='flex' justifyContent='flex-start' gap='20px'>
            <Link
              component={ButtonBase}
              disabled={processing}
              disableRipple
              sx={{ color: '#565F66' }}
              onClick={handleConversationClick}
            >
              <ChatIcon />
              <Typography variant='caption' sx={{ pl: '6px' }}>Discuss <Box component='span' sx={{ display: {xs: 'none', sm: 'inline'}}}>edits in chat</Box></Typography>
            </Link>
            <ButtonBase onClick={handleDownloadClick} sx={{ gap: '5px' }}>
              <UploadIcon sx={{ height: 20, width: 'auto'}} />
              <Typography component={'span'} variant='subtitle2' sx={{ textDecoration: 'underline' }}>Download</Typography>
            </ButtonBase>
          </Box>
          {!mediaMobile && <span>View Agreement</span>}
        </>
      }
      fullWidth
      maxWidth='md'
    >
      {!!url && <iframe src={url} title={template?.name ?? 'Agreement'} height='100%' style={{ border: 'none' }} />}
    </BaseDialog>
  );
};
export const Fallback = () => {
  return (
    <BaseDialog
      open={false}
      onClose={() => {}}
    />
  );
};

export default memo(Agreement);