import styled from '@mui/material/styles/styled';
import MuiTableCell from '@mui/material/TableCell';

const TableCell = styled(MuiTableCell)({
  borderColor: '#DDE2ED !important',
  padding: '32px 12px'
});
const Title = styled('div')({
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '18px',
  color: '#45464C'
});

export { TableCell, Title };