import { memo, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import GoogleAutocomplete from 'components/Common/Location/GoogleAutocomplete/GoogleAutocomplete';
import Map from 'components/Locations/Map/Map';
import useSuccess from 'hooks/useSuccess';

const Location = ({ open, property_id, locations, onClose, onAddLocation }) => {
  const { setSuccess } = useSuccess();
  const [address, setAddress] = useState({ value: '', formattedValue: '', latitude: null, longitude: null, parcel: null });
  const [processing, setProcessing] = useState(false);
  const disabled = processing || !address.latitude || !address.longitude;

  const handleExited = () => {
    setAddress({ value: '', formattedValue: '', latitude: null, longitude: null, parcel: null });
    setProcessing(false);
  };
  const handleAddressChange = formattedValue => setAddress(prev => ({ ...prev, formattedValue, value: '' }));
  const handleAddressSelect = data => setAddress(prev => ({ ...prev, ...data }));
  const handleSubmit = () => {
    const locations_attributes = [{
      address: address.formattedValue,
      latitude: address.latitude,
      longitude: address.longitude,
      ...(!!address.parcel && { parcel: address.parcel })
    }];

    setProcessing(true);
    onAddLocation({ id: property_id, locations_attributes, locations: [...locations, ...locations_attributes] });
    setSuccess('Location successfully created').then(onClose);
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      otherPaperStyle={{ width: '100%', maxWidth: 550 }}
      title='Select a parcel from the map'
      TransitionProps={{ onExited: handleExited }}
      actions={
        <>
          <Button
            sx={{ px: '20px', minHeight: '37px' }}
            color='error'
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ px: '30px' }}
            variant='contained'
            color='primary'
            disabled={disabled}
            onClick={handleSubmit}
          >
            Add Location
          </Button>
        </>
      }
    >
      <DialogContent sx={{ pt: '6px !important'}}>
        <Box sx={{ width: '100%', height: '360px', position: 'relative' }}>
          <Map
            address={address}
            onAddressSelect={handleAddressSelect}
          />
          <GoogleAutocomplete
            value={address.formattedValue}
            onChange={handleAddressChange}
            onSelect={handleAddressSelect}
          />
        </Box>
      </DialogContent>
    </BaseDialog>
  );
};

export default memo(Location);