import {
  getOfferPublicFoldersRoutine,
  getOfferPrivateLevel1FoldersRoutine,
  getOfferPrivateLevel2FoldersRoutine,
  getBuilderPublicFoldersRoutine,
  getBuilderPrivateLevel1FoldersRoutine,
  getBuilderPrivateLevel2FoldersRoutine,
  getPreviewPublicFoldersRoutine,
  getFoldersRoutine,
  getFolderRoutine,
  getActualFolderDataRoutine,
  createFolderRoutine,
  updateFolderRoutine,
  deleteFolderRoutine,
  clearLocalOfferFoldersRoutine,
  clearLocalFoldersRoutine,
  clearLocalFolderRoutine,
  setUploadingItemRoutine,
  removeUploadingItemRoutine,
  hideUploadingItemsRoutine,
  clearUploadingItemsRoutine
} from 'actions';
import _differenceBy from 'lodash/differenceBy';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';

export const initialState = {
  all: {
    data: [],
    pagination: { limit: 25, offset: 0, count: 0, total_count: 0 },
    fetching: true
  },
  offer: {
    public: {
      data: [],
      pagination: { limit: 25, offset: 0, count: 0, total_count: 0 },
      fetching: true
    },
    level1: {
      data: [],
      pagination: { limit: 25, offset: 0, count: 0, total_count: 0 },
      fetching: true
    },
    level2: {
      data: [],
      pagination: { limit: 25, offset: 0, count: 0, total_count: 0 },
      fetching: true
    }
  },
  breadcrumbs: [],
  folder: {
    id: '',
    name: '',
    ancestor_folders: [],
    parent_folder: { id: '', name: '' },
    ancestry_depth: 0,
    documents_count: 0,
    documents_byte_size: 0
  },
  configuredFolderId: '',
  snackbar: {
    silent: false,
    data: []
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getOfferPublicFoldersRoutine.REQUEST: {
      const offer = { ...state.offer, public: { ...state.offer.public, fetching: true } };

      return { ...state, offer };
    }
    case getOfferPublicFoldersRoutine.SUCCESS: {
      const { data: { folders, pagination } } = action.payload;
      const data = [...state.offer.public.data, ...folders];
      const offer = { ...state.offer, public: { data, pagination, fetching: false } };

      return { ...state, offer };
    }
    case getOfferPublicFoldersRoutine.FAILURE: {
      const offer = { ...state.offer, public: { ...state.offer.public, fetching: false } };

      return { ...state, offer };
    }
    case getOfferPrivateLevel1FoldersRoutine.REQUEST: {
      const offer = { ...state.offer, level1: { ...state.offer.level1, fetching: true } };

      return { ...state, offer };
    }
    case getOfferPrivateLevel1FoldersRoutine.SUCCESS: {
      const { data: { folders, pagination } } = action.payload;
      const data = [...state.offer.level1.data, ...folders];
      const offer = { ...state.offer, level1: { data, pagination, fetching: false } };

      return { ...state, offer };
    }
    case getOfferPrivateLevel1FoldersRoutine.FAILURE: {
      const offer = { ...state.offer, level1: { ...state.offer.level1, fetching: true } };

      return { ...state, offer };
    }
    case getOfferPrivateLevel2FoldersRoutine.REQUEST: {
      const offer = { ...state.offer, level2: { ...state.offer.level2, fetching: true } };

      return { ...state, offer };
    }
    case getOfferPrivateLevel2FoldersRoutine.SUCCESS: {
      const { data: { folders, pagination } } = action.payload;
      const data = [...state.offer.level2.data, ...folders];
      const offer = { ...state.offer, level2: { data, pagination, fetching: false } };

      return { ...state, offer };
    }
    case getOfferPrivateLevel2FoldersRoutine.FAILURE: {
      const offer = { ...state.offer, level2: { ...state.offer.level2, fetching: true } };

      return { ...state, offer };
    }
    case getBuilderPublicFoldersRoutine.SUCCESS: {
      return state;
    }
    case getBuilderPrivateLevel1FoldersRoutine.SUCCESS: {
      return state;
    }
    case getBuilderPrivateLevel2FoldersRoutine.SUCCESS: {
      return state;
    }
    case getPreviewPublicFoldersRoutine.SUCCESS: {
      return state;
    }
    case getFoldersRoutine.REQUEST: {
      const all = { ...state.all, fetching: true };

      return { ...state, all };
    }
    case getFoldersRoutine.SUCCESS: {
      const { data: { folders, pagination } } = action.payload;
      const all = { data: [...state.all.data, ...folders], pagination, fetching: false };

      return { ...state, all };
    }
    case getFoldersRoutine.FAILURE: {
      const all = { ...state.all, fetching: false };

      return { ...state, all };
    }
    case getFolderRoutine.SUCCESS: {
      const { data: { folder } } = action.payload;
      const breadcrumbs = [
        ..._map(folder.ancestor_folders, f => ({
          id: f.id,
          label: f.ancestry_depth === 0 ? 'All Files' : f.name,
          path: `/dealspaces/:dealspace_id/files/${f.id}`
        })),
        {
          id: folder.id,
          label: folder.ancestry_depth === 0 ? 'All Files' : folder.name,
          path: `/dealspaces/:dealspace_id/files/${folder.id}`
        }
      ];
      const updatedFolder = { ...state.folder, ...folder };
      const all = { ...state.all, data: _map(state.all.data, item => item.id === folder.id ? ({ ...item, ...folder }) : item) };

      return { ...state, folder: updatedFolder, all, breadcrumbs };
    }
    case getActualFolderDataRoutine.SUCCESS: {
      const { data: { folder } } = action.payload;
      const all = { ...state.all, data: _map(state.all.data, item => item.id === folder.id ? ({ ...item, ...folder }) : item) };

      return { ...state, all };
    }
    case createFolderRoutine.SUCCESS: {
      const { response: { data: { folder } }, initial = false } = action.payload;
      
      if (initial) {
        const all = { data: [folder, ...state.all.data], pagination: { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 } };

        return { ...state, all };
      }
      return state;
    }
    case updateFolderRoutine.SUCCESS: {
      const { response: { status }, folder } = action.payload;
      const data = _map(state.all.data, f => f.id === folder.id ? ({ ...f, ...folder }) : f);
      const pagination = state.all.pagination;

      return { ...state, ...(status === 204 && { all: { data, pagination } }) };
    }
    case deleteFolderRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const data = _filter(state.all.data, f => f.id !== id);
      const pagination = { ...state.all.pagination, count: state.all.pagination.count - 1, total_count: state.all.pagination.total_count - 1 };
      const all = { data, pagination };

      return { ...state, ...(status === 204 && { all }) };
    }
    case clearLocalOfferFoldersRoutine.SUCCESS: {
      return { ...state, offer: initialState.offer };
    }
    case clearLocalFoldersRoutine.SUCCESS: {
      return { ...state, all: initialState.all };
    }
    case clearLocalFolderRoutine.SUCCESS: {
      return { ...state, folder: initialState.folder, breadcrumbs: initialState.breadcrumbs };
    }
    case setUploadingItemRoutine.SUCCESS: {
      const { item } = action.payload;
      
      if (item.type === 'file') {
        const fileFound = !!_find(state.snackbar.data, i =>
          i.file.lastModified === item.file.lastModified &&
          i.file.name === item.file.name &&
          i.file.size === item.file.size &&
          i.file.type === item.file.type
        );
        if (fileFound) return state;

        const snackbar = { data: [...state.snackbar.data, item], silent: false };

        return { ...state, snackbar };
      }
      if (item.type === 'folder') {
        const difference = _differenceBy(item.data, state.snackbar.data, 'id');

        if (!difference.length) return state;

        const snackbar = { data: [...state.snackbar.data, { ...item, data: difference }], silent: false };

        return { ...state, snackbar };
      }
      return state;
    }
    case removeUploadingItemRoutine.SUCCESS: {
      const { id } = action.payload;
      const snackbar = { ...state.snackbar, data: _filter(state.snackbar.data, d => d.id !== id) };

      return { ...state, snackbar };
    }
    case hideUploadingItemsRoutine.SUCCESS: {
      const snackbar = { ...state.snackbar, silent: true };

      return { ...state, snackbar };
    };
    case clearUploadingItemsRoutine.SUCCESS: {
      return { ...state, snackbar: initialState.snackbar };
    }
    default: {
      return state;
    }
  };
};

export default reducer;