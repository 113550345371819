import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

export const PaperSx = {
  width: '100%',
  maxWidth: '312px',
  background: 'linear-gradient(143.3deg, rgba(12, 29, 69, 0.95) -2.33%, rgba(41, 47, 53, 0.95) 64.67%, rgba(31, 64, 98, 0.95) 109.72%)',
  boxShadow: 'unset',
  borderRadius: '6px',
};

export const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '32px 12px 36px'
});

export const Text = styled(Typography)({
  maxWidth: 230,
  textAlign: 'center',
  color: '#fff',
  padding: '20px 0 36px',
});

export const InfoText = styled(Typography)({
  textTransform: 'uppercase',
  color: '#fff',
  letterSpacing: '.18rem',
  marginBottom: 16,
});