import styled from '@mui/material/styles/styled';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';

const Root = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'selected'
})(({ selected, read }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  backgroundColor: selected ? '#2C2C2C' : read ? '#45464C' : '#4D4F56',
  overflow: 'hidden',
  padding: '12px 28px',
  '&:hover': {
    border: '1px solid #CECECE'
  }
}));
const Content = styled('div')({
  flexGrow: 1,
  overflow: 'hidden'
});
const Title = styled((props) => (
  <Typography variant='caption' component='div' noWrap {...props} />
),
  {
    shouldForwardProp: (prop) => prop !== 'read'
  })(({ read }) => ({
  color: read ? '#AAAEB2' : '#fff',
  fontWeight: 500
}));
const Text = styled((props) => (
  <Typography variant='caption' component='div' noWrap {...props} />
),
  {
    shouldForwardProp: (prop) => prop !== 'read'
  })(({ read }) => ({
  color: read ? '#AAAEB2' : '#fff',
  fontWeight: 300
}));


export { Root, Content, Title, Text };