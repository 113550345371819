import styled from '@mui/material/styles/styled';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';

const Root = styled(ButtonBase)(({ theme }) => ({
  minHeight: 280,
  maxWidth: 220,
  backgroundColor: theme.palette.background.paper,
  border: `1px dashed ${theme.palette.primary.main}`,
  borderRadius: '6px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '32px'
}));
const Content = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '4px'
});

export { Root, Content };