import { memo, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import ButtonBase from '@mui/material/ButtonBase';
import Badge from '@mui/material/Badge';
import AddIcon from '@mui/icons-material/Add';
import _map from 'lodash/map';
import _concat from 'lodash/concat';
// Local files
import { AddButton, Root, avatarStyles, avatarInvitedStyles } from './List.styled';
import GroupAvatar from 'components/Groups/Avatar/Avatar';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import { getRepresentation } from 'helpers';
import { representationVariants } from 'helpers/constants';

const List = () => {
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { openGroupInviteDialog, openGroupDialog } = useApp();
  const groups = useCustomSelector(state => state.groups.all.data);
  const invitations = useCustomSelector(state => state.invitations.all.data);
  const data = useMemo(() => _concat(groups, invitations), [groups, invitations]);

  const handleInvite = (invitation) => openGroupInviteDialog(invitation);
  const handleGroup = () => openGroupDialog();

  return (
    <Root sx={{ ...!mediaDesktop && { overflowY: 'auto' } }}>
      {_map(data, (item) => 
        <Badge 
          key={item.id} 
          color='success' 
          variant='dot' 
          invisible={!item?.unread_dealspaces_due_offer_conversations_count && !item?.unread_dealspaces_due_dealspace_conversations_count && !!!item?.invitationable}
        >
          <GroupAvatar 
            component={!!item?.invitationable ? ButtonBase : NavLink}
            title={item?.invitationable?.name?.slice(0,1) || item?.name?.slice(0,1)}
            fetching={false}
            src={getRepresentation(item?.invitationable?.logo?.representations || item?.logo?.representations, representationVariants.TINY)}
            sx={{ ...avatarStyles, ...(!!item?.invitationable && avatarInvitedStyles) }} 
            to={`/groups/${item.id}`}
            onClick={() => !!item?.invitationable && handleInvite(item)}
          />
        </Badge>
      )}
      <AddButton onClick={handleGroup}><AddIcon /></AddButton>
    </Root>
  );
};

export default memo(List);