import { memo, useMemo } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import _reduce from 'lodash/reduce';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
// Local files
import ConversationsSkeleton from 'components/Conversations/List/Common/ConversationsSkeleton/ConversationsSkeleton';
import Accordion from './Common/Accordion/Accordion';
import GeneralList from './GeneralList/GeneralList';
import DealspacesList from './DealspacesList/DealspacesList';
import OfferingsList from './OfferingsList/OfferingsList';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useChatsLevel from 'hooks/useChatsLevel';
import useCustomSelector from 'hooks/useCustomSelector';

const List = ({ processing, handleContainerScroll }) => {
  const { filter: { dealspace_id, offer_id } } = useCustomSelector(state => state.app.chats);
  const { chats: { data: dealspaces }, dealspace: { conversation, unread_offer_conversations_count } } = useCustomSelector(state => state.dealspaces);
  const { all: { data: groups } } = useCustomSelector(state => state.groups);
  const { user: { id: my_id, unread_general_conversations_count, unread_feed_conversations_count } } = useCustomSelector(state => state.profile);
  const { user: { feed: { data: feedData }, general: { data: generalData } }, offerings: { data: offeringsData }, conversation: { id: conversation_id } } = useCustomSelector(state => state.conversations);
  const { isDealspaceLevel, isGroupLevel, isUserLevel, chat_level_id } = useChatsLevel();
  const hasDealspacesConversations = !!_filter(dealspaces, (item) => !!item?.conversation?.users?.length)?.length || (conversation?.id === conversation_id && !!conversation?.users?.length);
  const dealspacesUnreadCount = _find(groups, (group) => group?.id === chat_level_id)?.unread_dealspaces_due_dealspace_conversations_count;
  const dealspacesOfferUnreadCount =  useMemo(() => _reduce(_map(dealspaces, ({ unread_offer_conversations_count }) => unread_offer_conversations_count), (sum, n) => sum + n, 0), [dealspaces]);
  const offersUnreadAmount = useMemo(() => _filter(offeringsData, ({ last_message }) => !!last_message && my_id !== last_message?.user?.id && !last_message?.read)?.length, [offeringsData, my_id]);

  const getChatAmount = () => {
    if (!!dealspace_id || !!offer_id) return offersUnreadAmount;
    if (isUserLevel) return unread_feed_conversations_count;
    if (isDealspaceLevel) return unread_offer_conversations_count;
    if (isGroupLevel) return dealspacesOfferUnreadCount;
  };

  return (
    <>
      { processing ? <ConversationsSkeleton /> : <>
        { (!!generalData.length || (!isUserLevel && hasDealspacesConversations)) ? (!isDealspaceLevel ? 
        <Scrollbars style={{ height: '50%', borderBottom: '2px solid #45464C' }} autoHide autoHideTimeout={1000} onScrollFrame={handleContainerScroll}>
          <Accordion
            title={isUserLevel ? 'General Chats' : 'Dealspace Channels'}
            amount={isUserLevel ? unread_general_conversations_count : dealspacesUnreadCount}
          >
            { <ErrorWrapper children={isUserLevel ? <GeneralList  /> : <DealspacesList />} /> }
          </Accordion>
        </Scrollbars> : <ErrorWrapper children={<DealspacesList single />} />) : null }
        { ((!!feedData.length || !!offeringsData.length) || (!!dealspace_id || !!offer_id)) ? 
        <Scrollbars style={!isDealspaceLevel ? { height: '50%' } : null} autoHide autoHideTimeout={1000} onScrollFrame={handleContainerScroll}>
          <Accordion
            title={isUserLevel ? 'Feed Chats' : 'Offering Chats'}
            amount={getChatAmount()}
          >
            <ErrorWrapper children={<OfferingsList />} />
          </Accordion>
        </Scrollbars> : null }
      </> }
    </>
  )


}

export default memo(List);