import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

export const Primary = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '23px',
  color: 'rgba(0, 0, 0, 0.7)'
}));

export const Secondary = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '23px',
  color: 'rgba(0, 0, 0, 0.5)'
}));