import { memo } from 'react';
import Radio from '@mui/material/Radio';
import PropTypes from 'prop-types';
// Local files
import { Description, LabelContent, StyledControl, StyledLabel, Title } from './NotificationRadioButton.styled';
import basicEmailImage from 'assets/icons/email-preview-basic.svg';
import contentBodyEmailImage from 'assets/icons/email-preview-content-body.svg';

const NotificationRadioButton = ({ value }) => {
  switch (value) {
    case 'basic':
      return (
        <StyledControl
          value={value}
          control={<Radio color='secondary' />}
          label={
            <StyledLabel>
              <LabelContent>
                <Title>Basic<span>Default</span></Title>
                <Description>
                  <p>Only includes tags, headline & main image in email notification.</p>
                  <p><span>See preview on next step.</span></p>
                </Description>
              </LabelContent>
              <img src={basicEmailImage} alt='Basic preview' />
            </StyledLabel>
          }
        />
      );
    case 'full':
      return (
        <StyledControl
          value={value}
          control={<Radio color='secondary' />}
          label={
            <StyledLabel>
              <LabelContent>
                <Title>Include content body</Title>
                <Description>
                  <p>Basic, plus all content body, except for files.</p>
                  <p><span>See preview on next step.</span></p>
                </Description>
              </LabelContent>
              <img src={contentBodyEmailImage} alt='Full Content preview' />
            </StyledLabel>
          }
        />
      );
    default:
      return null;
  };
};

NotificationRadioButton.propTypes = {
  value: PropTypes.string.isRequired
};

export default memo(NotificationRadioButton);