import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
// Local files
import { frameBaseURL } from 'apis';
import { StyledInput, Text } from './ShareForm.styled';
import { ReactComponent as CopyIcon } from 'assets/icons/link.svg';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useSuccess from 'hooks/useSuccess';

const ShareForm = ({ open, offerId, onClose }) => {
  const { setSuccess } = useSuccess();
  const [code, setCode] = useState('');

  const onEntering = () => setCode(`<iframe src="${frameBaseURL}/share_form/${offerId}" frameborder="0" name="qwincyFrame" width="100%" height="100%" />`);
  const onExited = () => setCode('');
  const handleCopyClick = () => {
    try {
      navigator.clipboard.writeText(code);
      setSuccess('Copied to the clipboard');
    } catch (error) {
      console.error(error); 
    }
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{ sx: { maxWidth: 450, width: '100%' } }}
      title='Embed Share Form'
      otherContentStyle={{ p: { xs: '0 12px', md: '0 24px' } }}
      TransitionProps={{ onExited, onEntering }}
      content={
        <>
          <Text variant='small'>
            Embed on any webpage for this Offering to get automatically shared with those who successfully complete the form.
          </Text>
          <StyledInput
            fullWidth
            inputProps={{ readOnly: true }}
            multiline
            value={code}
            sx={{ wordBreak: 'break-all' }}
            onClick={handleCopyClick}
          />
          <Button
            variant='gradient'
            startIcon={<CopyIcon />}
            sx={{ display: 'flex', mx: 'auto', my: 3 }}
            onClick={handleCopyClick}
          >
            Copy
          </Button>
        </>
      }
    />
  );
};

ShareForm.propTypes = {
  open: PropTypes.bool.isRequired,
  offerId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <ShareForm {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);