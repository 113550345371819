import { memo } from 'react';
import Typography from '@mui/material/Typography';
import AddIcon from 'components/Common/Icons/AddIcon';
import { ReactComponent as ImageIcon } from 'assets/icons/image-large.svg';
import { ImageRoot, ImageContent } from './ImageUploader.styled';
import PropTypes from 'prop-types';

const ImageUploader = ({ label, onClick }) => {
  return (
    <ImageRoot onClick={onClick}>
      <ImageIcon />
      <ImageContent>
        <AddIcon />
        <Typography variant='subtitle0'>{label}</Typography>
      </ImageContent>
    </ImageRoot>
  );
};


ImageUploader.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default memo(ImageUploader);