import styled from '@mui/material/styles/styled';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

const Root = styled(ListItemButton,
  { shouldForwardProp: (prop) => prop !== 'selected' }
  )(({ selected }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  backgroundColor: selected ? '#2C2C2C' : 'transparent',
  overflow: 'hidden',
  padding: '12px 28px',
  '&:hover': {
    backgroundColor: '#45464C'
  }
}));

const Content = styled('div')({
 flexGrow: 1,
  overflow: 'hidden',
});

const Text = styled((props) => (
  <Typography variant='caption' component='div' noWrap {...props} />
),
  {
    shouldForwardProp: (prop) => prop !== 'highlighted'
  })(({ highlighted }) => ({
  color: highlighted ? '#fff' : '#AAAEB2',
  fontWeight: highlighted ? 700 : 400,
}));

const StyledAvatar = styled(Avatar)(( { theme }) => ({
  width: 34,
  height: 34,
  color: theme.palette.section.chat.text,
  backgroundColor: theme.palette.section.chat.background.dark,
}));

export { Root, Content, Text, StyledAvatar };