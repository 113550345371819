import { memo } from 'react';
import PropTypes from 'prop-types';
// Local files
import { Root, Title, Counter } from './Status.styled';
import { ReactComponent as DraftIcon } from 'assets/icons/draft-bullet.svg';
import { ReactComponent as ActiveIcon } from 'assets/icons/published-bullet.svg';
import { ReactComponent as EndedIcon } from 'assets/icons/ended-bullet.svg';
import { ReactComponent as InContractIcon } from 'assets/icons/in-contract-bullet.svg';
import { ReactComponent as ClosedIcon } from 'assets/icons/closed-bullet.svg';

const Status = ({ value, withoutText = false, counter = null }) => {
  switch (value) {
    case 'draft': {
      if (withoutText) {
        return <DraftIcon />;
      } else {
        return <Root><DraftIcon /><Title>Draft{counter && <Counter>&nbsp;&nbsp;{counter}</Counter>}</Title></Root>;
      }
    }
    case 'active': {
      if (withoutText) {
        return <ActiveIcon />;
      } else {
        return <Root><ActiveIcon /><Title>Active{counter && <Counter>&nbsp;&nbsp;{counter}</Counter>}</Title></Root>;
      }
    }
    // TODO
    case 'saved': {
      if (withoutText) {
        return <InContractIcon />;
      } else {
        return <Root><InContractIcon /><Title>Saved{counter && <Counter>&nbsp;&nbsp;{counter}</Counter>}</Title></Root>;
      }
    }
    case 'ended': {
      if (withoutText) {
        return <EndedIcon />;
      } else {
        return <Root><EndedIcon /><Title>Ended{counter && <Counter>&nbsp;&nbsp;{counter}</Counter>}</Title></Root>;
      }
    }
    case 'closed': {
      if (withoutText) {
        return <ClosedIcon />;
      } else {
        return <Root><ClosedIcon /><Title>Closed{counter && <Counter>&nbsp;&nbsp;{counter}</Counter>}</Title></Root>;
      }
    }
    case 'in_contract': {
      if (withoutText) {
        return <InContractIcon />;
      } else {
        return <Root><InContractIcon /><Title>In contract{counter && <Counter>&nbsp;&nbsp;{counter}</Counter>}</Title></Root>;
      }
    }
    default: {
      return null;
    }
  }
};

Status.propTypes = {
  value: PropTypes.string.isRequired,
  withoutText: PropTypes.bool,
  counter: PropTypes.number
};

export default memo(Status);