import { useCallback } from 'react';
import mixpanel from 'mixpanel-browser';
import { mode, mixpanelToken } from 'apis';

const env_check = mode !== 'development';

const useMixpanel = () => {
  const init = useCallback(() => {
    if (env_check) mixpanel.init(mixpanelToken);
  }, []);
  const identify = useCallback(id => {
    if (env_check) mixpanel.identify(id);
  }, []);
  const alias = useCallback(id => {
    if (env_check) mixpanel.alias(id);
  }, []);
  const track = useCallback((name, props) => {
    if (env_check) mixpanel.track(name, props);
  }, []);
  const people = {
    set: props => env_check && mixpanel.people.set(props)
  };

  return { init, identify, alias, track, people };
};

export default useMixpanel;