import { memo } from 'react';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { NavLink } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import { StyledTab } from '../AppNavigation.styled';
import NetworkIcon from 'components/Common/Icons/NetworkIcon';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';

const Contacts = () => {
  const { setAuthData, /*openAccessBetaDialog*/ } = useApp();
  const { /*isQwincyMember, hasRecipientSession,*/ authorizedAsUser, pendingContactsCount } = useCustomSelector(state => ({ 
    // isQwincyMember: !!state.profile.recipient.user?.email,
    // hasRecipientSession: !!state.profile.recipient.id,
    authorizedAsUser: state.sessions.session.userableType === 'user',
    pendingContactsCount: state.profile.user.pending_contacts_count
  }));

  const checkIsQwincyMember = () => {
    setAuthData({ open: true });
    /**
     * Временное решение
     */
    // (isQwincyMember || !hasRecipientSession) ? setAuthData({ open: true }) : openAccessBetaDialog();
  };
  const handleClick = () => !authorizedAsUser && checkIsQwincyMember();

  return (
    <StyledTab
      component={authorizedAsUser ? NavLink : Button}
      iconPosition='start'
      to='/contacts'
      onClick={handleClick}
      icon={<NetworkIcon />}
      label={<Badge badgeContent={pendingContactsCount} color='success' overlap='circular' sx={{ width: 80 }}>Contacts</Badge>}
      value={2}
    />
  );
};

const ErrorFallback = ({ error }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Contacts {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);