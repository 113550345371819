import { memo } from 'react';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
// Local files
import { TableCell, Title } from './TableCellContent.styled';

const TableHeadCell = ({ title, align= 'inherit', checkbox = false, checked = false, onChange }) => {
  return (
    <TableCell align={align}>
      {checkbox
        ? <Checkbox
            checked={checked}
            onChange={({ target: { checked } }) => onChange(checked)}
          />
        : <Title>{title}</Title>
      }
    </TableCell>
  );
};

TableHeadCell.propTypes = {
  title: PropTypes.string,
  align: PropTypes.string,
  checkbox: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func
};

export default memo(TableHeadCell);