import { memo, useCallback, useEffect, useState, useRef } from 'react';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
// Local files
import GoogleMap from 'components/Common/Location/GoogleMap/GoogleMap';
import { Root, MapContainer, Head, Title, TextContainer, Text } from './GeographicFocuses.styled';
import avatar from 'assets/images/avatar-default.png';
import useCustomSelector from 'hooks/useCustomSelector';
import { getRepresentation } from 'helpers';
import { representationVariants } from 'helpers/constants';

const options = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  gestureHandling: 'none',
  streetViewControl: false,
  fullscreenControl: false,
  mapId: '60cf71342a076aef'
};

const GeographicFocuses = ({ geographic_focuses, first_name, latitude = 27.7567667, longitude = -81.4639835, photo }) => {
  const { mapInstance } = useCustomSelector(state => ({
    mapInstance: state.map.instance
  }));
  const [placeIds, setPlaceIds] = useState([]);
  const [bounds, setBounds] = useState(null);
  const markerRef = useRef();

  const drawCustomMarker = useCallback((map, lat, lng, image) => {
    const marker = window.google.maps.importLibrary('marker');

    marker.then(({AdvancedMarkerElement}) => {
      const beachFlagImg = document.createElement('img');
      beachFlagImg.style = 'border-radius: 50%; border: 2px solid #FFFFFF; width: 48px; height: 48px; object-fit: cover'
      beachFlagImg.src = image;
      beachFlagImg.alt = first_name;
    
      markerRef.current = new AdvancedMarkerElement({
        map,
        position: { lat, lng },
        content: beachFlagImg,
        title: "A marker using a custom PNG Image",
      });
    });
  }, [first_name]);
  const drawPolygon = useCallback((map, focus) => {
    const place = window.google.maps.importLibrary('places');
    const styleFill = {
      strokeColor: '#9DB4F1',
      strokeOpacity: 1.0,
      strokeWeight: 1.0,
      fillColor: '#9DB4F1',
      fillOpacity: 0.5,
    };
    let featureLayerLocality = map.getFeatureLayer('LOCALITY');
    let featureLayerCountry = map.getFeatureLayer('COUNTRY');
    let featureLayerState = map.getFeatureLayer('ADMINISTRATIVE_AREA_LEVEL_1');
    let featureLayerCounty = map.getFeatureLayer('ADMINISTRATIVE_AREA_LEVEL_2');
    
    place.then((p) => {
      const request = {
        query: focus?.address,
        fields: ['place_id', 'geometry']
      };
      const places = new p.PlacesService(map);

      places.findPlaceFromQuery(request, (places) => {
        if (places?.length) {
          const place = places[0];
          
          if (!!!_find(placeIds, (id) => id === place.place_id)) {
            setPlaceIds([...placeIds, ...[place.place_id]]);
            setBounds(prev => !!prev ? prev.union(place?.geometry?.viewport) : new window.google.maps.LatLngBounds(new window.google.maps.LatLng(latitude, longitude)).union(place?.geometry?.viewport));
          }

          featureLayerLocality.style = (params) => (params.feature.placeId === place.place_id || !!_find(placeIds, (id) => id === params.feature.placeId)) && styleFill;
          featureLayerCountry.style = (params) => (params.feature.placeId === place.place_id || !!_find(placeIds, (id) => id === params.feature.placeId)) && styleFill;
          featureLayerState.style = (params) => (params.feature.placeId === place.place_id || !!_find(placeIds, (id) => id === params.feature.placeId)) && styleFill;
          featureLayerCounty.style = (params) => (params.feature.placeId === place.place_id || !!_find(placeIds, (id) => id === params.feature.placeId)) && styleFill;
            
        } else {
          console.log('No results');
        }
      })
    });
  }, [placeIds]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!!mapInstance && !!geographic_focuses?.length) {
      if (markerRef.current) markerRef.current.map = null;
      _forEach(geographic_focuses, (focus) => drawPolygon(mapInstance, focus));
      drawCustomMarker(mapInstance, latitude, longitude, !!photo?.representations?.length ? getRepresentation(photo?.representations, representationVariants.TINY) ?? photo?.representations[0]?.url : avatar);    
    }
  }, [mapInstance, geographic_focuses, latitude, longitude, photo?.representations, drawCustomMarker, drawPolygon]);
  useEffect(() => {
    if (!!mapInstance && !!geographic_focuses?.length && geographic_focuses?.length === placeIds?.length && !!bounds) {
      mapInstance.fitBounds(bounds, { left: 32, top: 32, right: 32, bottom: 32 });    
    }
  }, [mapInstance, geographic_focuses, placeIds, bounds]);

  return (
    <Root>
      <Head>
        <Title>{`${first_name}'s Geographic Focus`}</Title>
        <TextContainer>{_map(geographic_focuses, (focus, i) => 
          <Text key={focus.id}>{`${focus.address}${i !== geographic_focuses?.length - 1 ? ';' : ''}`}</Text>)}
        </TextContainer>
      </Head> 
      <MapContainer>
        <GoogleMap options={options} />
      </MapContainer>
    </Root>
  );
};

export default memo(GeographicFocuses);