import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';

export const Root = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  padding: '24px 32px 32px'
});
export const Content = styled('div')({
  flexGrow: 1,
  width: '100%',
  marginTop: '40px',
  '& > *': {
    margin: '0 0 16px !important'
  }
});
export const InfoItem = styled('div')({
  display: 'flex',
  fontSize: '0.75rem',
  '& > span:first-of-type': {
    flexBasis: 100,
  },
});
export const AddButton = styled(Button)({
  width: 30,
  height: 30,
  color: 'rgba(0,0,0,.3)',
  background: '#F8F9FB',
  padding: '2px',
  '& svg': {
    fontSize: '1.25rem',
  }
});