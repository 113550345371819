import { memo, useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import _orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
// Local files
import { Image, Preview, ThumbsContainer } from './Images.styled';
import { ImagesSkeleton } from 'components/Offers/Details/Skeletons/Skeletons';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';

/**
 * 
 * @param {array=} data 
 * @returns {node}
 */

const Images = ({ data = null }) => {
  const { fetching, offerData } = useCustomSelector(state => ({
    fetching: state.images.offer.fetching,
    offerData: state.images.offer.data
  }));
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const images = data ?? offerData;

  useEffect(() => {
    setThumbsSwiper(null);
  }, []);

  if (images.length === 0 && !fetching) return null;
  if (!data && fetching) return (
    <Box sx={{ width: '100%', px: '24px', pb: 1 }}>
      <ImagesSkeleton />
    </Box>
  );
  return (
    <Container maxWidth={false} sx={{ maxWidth: '624px', px: { xs: 0, md: '12px !important' }, pb: 1  }}>
      <Swiper
        autoHeight
        loop
        navigation
        spaceBetween={10}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[Thumbs, Navigation]}
        style={{
          '--swiper-navigation-color': 'rgba(0,0,0,.8)',
          '--swiper-pagination-color': '#fff'
        }}
      >
        {_map(_orderBy(images, ['main'], ['desc']), i =>
          <SwiperSlide key={i.id} style={mediaDesktop ? { height: 450, width: 600 } : { maxHeight: 450, maxWidth: 600 }}>
            <Image src={getRepresentation(i.file.representations, representationVariants.MEDIUM)} loading='lazy' />
          </SwiperSlide>
        )}
      </Swiper>
      {images.length > 1 &&
        <ThumbsContainer>
          <Swiper
            onSwiper={setThumbsSwiper}
            loop
            spaceBetween={10}
            slidesPerView={6}
            freeMode
            watchSlidesProgress
            centerInsufficientSlides
            modules={[Thumbs]}
          >
            {_map(_orderBy(images, ['main'], ['desc']), i =>
              <SwiperSlide key={i.id}>
                <Preview sx={{ backgroundImage: `url('${getRepresentation(i.file.representations, representationVariants.SMALL)}')` }} />
              </SwiperSlide>
            )}
          </Swiper>
        </ThumbsContainer>
      }
    </Container>
  );
};

Images.propTypes = {
  data: PropTypes.array
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Images {...props} />
    </ErrorWrapper>
  );
};
export const Fallback = () => {
  return (
    <Box sx={{ width: '100%', px: '24px', pb: 1 }}>
      <ImagesSkeleton />
    </Box>
  );
};

export default memo(WrappedComponent);