import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Root = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  fontSize: '12px',

  '&:not(:last-of-type)': {
    marginBottom: .5
  }
}));

export const Icon = styled(props => <Box component='img' alt={props.alt} width={14} src={props.src} sx={{ height: 'auto', objectFit: 'contain' }} />)({});

export const Name = styled(props => <Typography noWrap sx={{ fontSize: 'inherit' }}>{props.children}</Typography>)({});

export const Size = styled(Typography)({
  fontSize: 'inherit',
  color: '#C0B8B8'
});