import { memo, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import _map from 'lodash/map';
import Scrollbars from 'react-custom-scrollbars-2';
import PropTypes from 'prop-types';
// Local files
import { Root, LeftSide, LeftSideHeader, BackButton, LeftSideHeaderTitle, LeftSideHeaderSubtitle, LeftSideFormContainer, Peoples, Footer, RightSide, SummaryContainer, Summary, More, /*Fields*/ } from './Details.styled';
import CheckboxForm from './CheckboxForm/CheckboxForm';
import FileItem from './FileItem/FileItem';
import { ReactComponent as SummaryIcon } from 'assets/icons/extension/qwincy-summary.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/extension/download.svg';
import { ReactComponent as BackIcon } from 'assets/icons/extension/arrow-back.svg';
import imageFile from 'assets/images/file/pdf.png';//copy all docs icons from assets/images/file
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useEmails from 'hooks/useEmails';

const Details = ({ selectedEmail, onBack }) => {
  const { setError } = useError();
  const { getEmail, clearLocalEmail } = useEmails();
  const email = useCustomSelector(state => state.emails.email);
  const [locationsChecked, setLocationsChecked] = useState(true);
  const [linksChecked, setLinksChecked] = useState(true);
  const [filesChecked, setFilesChecked] = useState(true);
  const [imagesChecked, setImagesChecked] = useState(true);
  const [contactChecked, setContactChecked] = useState(true);
  const [inviteChecked, setInviteChecked] = useState(true);
  const [messageChecked, setMessageChecked] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  console.log(email);
  useEffect(() => {
    getEmail(selectedEmail).catch(e => setError(e));

    return clearLocalEmail;
  }, [clearLocalEmail, getEmail, selectedEmail, setError]);

  return (
    <Root>
      <LeftSide>
        <LeftSideHeader>
          <Box sx={{ width: '100%', display: 'flex', gap: '12px' }}>
            <BackButton onClick={onBack}>
              <BackIcon />
            </BackButton>
            <LeftSideHeaderTitle>New Opportunity Detected!</LeftSideHeaderTitle>
          </Box>
          <LeftSideHeaderSubtitle>Select items for download:</LeftSideHeaderSubtitle>
        </LeftSideHeader>
        <Scrollbars autoHideTimeout={1000}>
          <LeftSideFormContainer>
            <CheckboxForm
              checked={locationsChecked}
              label={
                <>
                  <Typography variant='caption' color='textSecondary'>Detected Locations</Typography>
                  {_map(email.addresses, a => <Link key={a} variant='caption' component={NavLink} to=''>{a}</Link>)}
                </>
              }
              onChange={() => setLocationsChecked(prev => !prev)}
            />
            <CheckboxForm
              checked={linksChecked}
              label={
                <>
                  <Typography variant='caption' color='textSecondary'>Detected Links</Typography>
                  {_map(email.links, l => <Link key={l} variant='caption' component={'a'} href={l} noWrap>{l}</Link>)}
                </>
              }
              onChange={() => setLinksChecked(prev => !prev)}
            />
            <CheckboxForm
              checked={filesChecked}
              label={
                <>
                  <Typography variant='caption' color='textSecondary'>Attachments ({email.attachments.length})</Typography>
                  {_map(email.attachments, a => <FileItem key={a.id} icon={imageFile} name={a.filename} size={a.byte_size} />)}
                </>
              }
              onChange={() => setFilesChecked(prev => !prev)}
            />
            <CheckboxForm
              checked={imagesChecked}
              label={<Typography variant='caption' color='textSecondary' sx={{ mt: .5 }}>Images ({email.images.length})</Typography>}
              onChange={() => setImagesChecked(prev => !prev)}
            />
            <Peoples>
              <Typography variant='caption' color='textSecondary'>People ({email.senders?.length ?? 0})</Typography>
              {_map(email.senders, s => <Typography key={s.id} variant='caption' sx={{ color: '#000' }}>{s}</Typography>)}
              {!!email.senders?.length &&
                <>
                  <CheckboxForm
                    checked={contactChecked}
                    label={<Typography variant='caption' color='textSecondary'>Add to my contacts</Typography>}
                    onChange={() => setContactChecked(prev => !prev)}
                  />
                  <CheckboxForm
                    checked={inviteChecked}
                    label={<Typography variant='caption' color='textSecondary'>Invite to Qwincy</Typography>}
                    onChange={() => setInviteChecked(prev => !prev)}
                  />
                </>
              }
            </Peoples>
            <CheckboxForm
              checked={messageChecked}
              label={<Typography variant='caption' color='textSecondary'>Text from email(s)</Typography>}  
              onChange={() => setMessageChecked(prev => !prev)}
            />
          </LeftSideFormContainer>
        </Scrollbars>
        <Footer />
      </LeftSide>
      <RightSide>
        <SummaryContainer>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <SummaryIcon />
            <Typography variant='subtitle2' sx={{ color: 'inherit' }}>Qwincy’s Summary</Typography>
          </Box>
          <Scrollbars autoHideTimeout={1000}>
            <Summary>{email.summary}</Summary>
          </Scrollbars>
          <More>Tell me more</More>
        </SummaryContainer>
        {/* <Fields>
          <TextField label='Select a Group' />
          <TextField label='Dealspace Name' />
        </Fields> */}
        <Button
          variant='contained'
          color='secondary'
          sx={{ flexShrink: 0, width: '100%', py: '10px', minHeight: '35px' }}
          onClick={() => setButtonLoading(!buttonLoading)}
        >
          {!buttonLoading ? <DownloadIcon /> :  <CircularProgress size={15} sx={{ color: '#fff' }}/>}
          <Typography variant='subtitle2' sx={{ color: 'inherit', ml: '8px' }}>Download to Pipeline</Typography>
        </Button>
      </RightSide>
    </Root>
  );
};

Details.propTypes = {
  selectedEmail: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired
};

export default memo(Details);