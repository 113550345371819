import {
  setInvitationTokenRoutine,
  getInvitationsRoutine,
  getInvitationRoutine,
  acceptInvitationRoutine,
  declineInvitationRoutine,
  notifyInviteRoutine
} from 'actions';

export const initialState = {
  all: { data: [], pagination: { limit: 0, offset: 0, count: 0, total_count: 0 } },
  invitation_token: '',
  invitation: {
    id: '',
    inviter: {},
    invitationable: '',
    invitation_type: '',
    email: '',
    message: ''
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setInvitationTokenRoutine.SUCCESS: {
      const { invitation_token } = action.payload;

      return { ...state, invitation_token };
    }
    case getInvitationsRoutine.SUCCESS: {
      const { data: { invitations: data, pagination } } = action.payload;

      return { ...state, all: { data, pagination } };
    }
    case getInvitationRoutine.SUCCESS: {
      const { data: { invitation } } = action.payload;

      return { ...state, invitation: { ...state.invitation, ...invitation } };
    }
    case acceptInvitationRoutine.SUCCESS: {
      const { response: { status } } = action.payload;

      if (status === 204) return initialState;
      return state;
    }
    case declineInvitationRoutine.SUCCESS: {
      const { response: { status } } = action.payload;

      if (status === 204) return initialState;
      return state;
    }
    case notifyInviteRoutine.SUCCESS: {
      return state;
    }
    default: {
      return state;
    }
  };
};

export default reducer;