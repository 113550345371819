import { instance as axios } from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getBlobRoutine,
  createBlobRoutine
} from '.';
import _includes from 'lodash/includes';
import _replace from 'lodash/replace';

const attributes = ['id', 'content_type', 'filename', 'url'];
const createAttributes = [...attributes, 'direct_upload_url', 'direct_upload_headers'];

export const getBlob = getThunkActionCreator(
  getBlobRoutine,
  async id => {
    const params = { attributes };

    return await axios.get(`/user/blobs/${id}`, { params })
  }
);
export const createBlob = getThunkActionCreator(
  createBlobRoutine,
  async ({ name: filename, size: byte_size, type: content_type, checksum, file, onUploadProgress }) => {
    const checkFileName = _includes(filename, 'jfif');
    const data = { 
      blob: { 
        filename: checkFileName ? _replace(filename, 'jfif', 'jpeg') : filename, 
        byte_size,
        content_type, 
        checksum 
      }, 
      attributes: createAttributes 
    };
    const response = await axios.post('/user/blobs', data);
    const putUrl = response.data.blob.direct_upload_url;
    const config = {
      headers: response.data.blob.direct_upload_headers,
      onUploadProgress
    };

    await axios.put(putUrl, file, config);

    return response;
  }
);