import styled from '@mui/material/styles/styled';
import ButtonBase from '@mui/material/ButtonBase';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

export const Root = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '24px',
});

export const Content = styled(Box)({});

export const SearchContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  borderRadius: '6px',
  maxHeight: '271px',
  width: '331px'   
});

export const StyledLink = styled(Link)({
  width: '100%',
  display: 'flex',
  textDecoration: 'none',
  color: 'rgba(0,0,0,.7)',
  cursor: 'pointer',
  padding: '8px 0',
  '&:not(:last-child)': {
    borderBottom: '1px solid rgba(0,0,0,.1)',
  },
  '& > div': {
    width: '50%',
    flexBasic: '50%',
  }
});

export const InviteLink = styled(Link)({
  fontSize: '12px', 
  fontWeight: 400, 
  color: '#000'
});

export const Actions = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  gap: '12px',
});

export const IconContainer = styled(Box)({
  height: 30,
  width: 30,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledButton = styled(ButtonBase)(({ theme }) => ({
  height: 70,
  width: 70,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '2px',
  background: '#FAFBFE',
  border: '1px solid #E5E8F1',
  borderRadius: 7,
  '& > span': {
    fontSize: '0.75rem',
    color: 'rgba(0,0,0,.4)',
  },
  [theme.breakpoints.up(450)]: {
    height: 102,
    width: 102,
  }
}));