import { memo, useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import _concat from 'lodash/concat';
import _uniqBy from 'lodash/uniqBy';
// Local files
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useUsers from 'hooks/useUsers';
import { getRandomString } from 'helpers';

const UsersAutocomplete = ({ value, onChange, multiple = false, groupId = null, sx }) => {
  const { getUsersList, clearLocalUsersList } = useUsers();
  const { setError } = useError();
  const data = useCustomSelector(state => state.users.list);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchUsers = useCallback(({ query, suitable_by_group_id }) => {
    setLoading(true);
    getUsersList({ query, suitable_by_group_id })
    .catch(e => setError(e))
    .finally(() => setLoading(false));
  }, [getUsersList, setError]);
  const handleChange = (_, newValue) => {
    if (typeof newValue === 'string') {
      onChange({ title: newValue });
    } else if (Array.isArray(newValue) && newValue.length > value.length) {
      onChange(_uniqBy(_concat(value, newValue), 'id'));
      setOptions(_uniqBy(_concat(options, newValue), 'id'));
    } else {
      onChange(newValue);
    }
  };
  const filterOptions = (o, p) => {
    const { inputValue } = p;
    const isExisting = o.some(i => !!i?.id);

    if (inputValue !== '' && !isExisting) o.push({ id: getRandomString(24), inputValue, title: `Add "${inputValue}"` });

    return o;
  };
  const getOptionLabel = o => {
    if (typeof o === 'string') return o;
    if (o.inputValue) return o.inputValue;
    return o.title;
  };
  const renderOption = (p, o) => (
    <Box {...p}>
      {o.id &&
        <Avatar
          alt={o.title}
          src={getRepresentation(o.data?.photo?.representations, representationVariants.TINY)}
          sx={{ mr: 1 }}
        >
          {o.data?.avatarPlaceholder}
        </Avatar>
      }
      <Typography variant='subtitle1'>{o.title}</Typography>
    </Box>
  );
  const renderInput = p => (
    <TextField
      {...p}
      placeholder='Enter email or Qwincy member'
      InputProps={{
        ...p.InputProps,
        endAdornment: <>
          {loading && <CircularProgress color="inherit" size={20} />}
          {p.InputProps.endAdornment}
        </>
      }}
    />
  );
  const isOptionEqualToValue = (o, v) => o.id === v.id;

  useEffect(() => {
    let timeout = setTimeout(() => clearLocalUsersList().then(() => fetchUsers({ query, suitable_by_group_id: groupId })), 500);

    return () => clearTimeout(timeout);
  }, [fetchUsers, clearLocalUsersList, query, groupId]);

  useEffect(() => {
    !!data.length && setOptions(data);

    return () => setOptions([]);
  }, [data]);

  return (
    <Autocomplete
      multiple={multiple}
      loading={loading}
      value={value}
      onChange={handleChange}
      isOptionEqualToValue={isOptionEqualToValue}
      filterOptions={filterOptions}
      inputValue={query}
      onInputChange={(_, value) => setQuery(value)}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id='usersAutocomplete'
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      sx={sx}
      fullWidth
      renderInput={renderInput}
    />
  );
};

export default memo(UsersAutocomplete);