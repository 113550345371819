import styled from '@mui/material/styles/styled';
import Link from '@mui/material/Link';

export const Root = styled('div')(() => ({
  flexGrow: 1,
  width: '100%',
  maxWidth: 620,
  minHeight: 300,
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  '& .tox-statusbar': {
    borderTop: 'none !important'
  },
  '& .tox-sidebar-wrap': {
    paddingTop: 25,
    paddingBottom: 5,
  },
}));

export const StyledLink = styled(Link)({
  cursor: 'pointer',
  fontSize: '0.75rem',
  marginLeft: 'auto'
});