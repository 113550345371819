import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

export const Root = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden'
});

export const Content = styled('div')({
  flexGrow: 1,
  width: '100%',
  padding: '0px 32px 32px',
});

export const Actions = styled('div')({
  flexShrink: 0,
  display: 'flex',
  justifyContent: 'center',
  gap: '4px',
  padding: '16px 0',
  marginTop: 'auto'
});

export const Title = styled(Typography)({
  flexShrink: 0,
  fontWeight: 600,
  fontSize: '1rem',
  textAlign: 'center',
  padding: '32px',
});

export const Text = styled(Typography)({
  display: 'block',
  fontSize: '0.75rem',
  color: '#000',
});

export const SmallText = styled(Typography)({
  display: 'block',
  fontSize: '0.675rem',
  color: 'rgba(102, 102, 102, 1)',
});