import { useState } from 'react';
import useBlob from 'hooks/useBlob';
import useChecksum from 'hooks/useChecksum';
import { getRandomString } from 'helpers';

const useFilesChange = (directly = false, onChange = null, maxHeight = null, maxWidth = null) => {
  const { processBlob } = useBlob();
  const { processFiles } = useChecksum();
  const [loading, setLoading] = useState(false);

  const changeFiles = (files) => {
    if (directly) {
      onChange(files);
    } else {
      setLoading(true);
      processFiles(files, ({ file, checksum, localUrl }) => {
        onChange({ newFormattedValue: { id: getRandomString(24), filename: file.name, localUrl } });
        processBlob({ file, checksum }, blob => {
          if (!blob.id) return;

          onChange({ newValue: blob.id, name: blob.filename });
          setLoading(false);
        });
      }, maxHeight, maxWidth);
    }
  };

  return { loading, changeFiles }
};

export default useFilesChange;