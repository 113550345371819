import { memo, useCallback, useEffect, useState } from 'react';
import _isEqual from 'lodash/isEqual';
import _uniqWith from 'lodash/uniqWith';
import _pick from 'lodash/pick';
import _map from 'lodash/map';
import WKT from 'terraformer-wkt-parser';
import PropTypes from 'prop-types';
// Local files
import GoogleMap from 'components/Common/Location/GoogleMap/GoogleMap';
import useLocations from 'hooks/useLocations';
import usePrevious from 'hooks/usePrevious';
import useMap from 'hooks/useMap';

const options = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  mapId: 'd8fd43d603f67612'
};

const Map = ({ address = null, polygon = null, addresses = [], onAddressSelect, onPolygonSelect }) => {
  const { getLocationParcel } = useLocations();
  const { clearMapData, selectPolygon } = useMap();
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState({ lat: 40.730610, lng: -73.935242 });
  const [markers, setMarkers] = useState([]);
  const prevLength = usePrevious(addresses?.length);

  const handleClick = e => {
    const coordinates = e.latLng.toJSON();
    const geocoder = new window.google.maps.Geocoder();

    if (!_isEqual(center, coordinates)) {
      const location = { latitude: coordinates.lat, longitude: coordinates.lng };

      geocoder.geocode({
        'latLng': e.latLng
      }, (results) => {
        processCoordinates(location, [], true, !!address, results[0]?.formatted_address);
        zoom < 18 && setZoom(18);
      });
    }
  };
  const processCoordinates = useCallback((coordinates, addresses = [], needCenter = false, isAddress = false, googleAddress) => {
    getLocationParcel(coordinates)
    .then(({ payload: { data: { results } } }) => {

      const location = {
        ...(!!results.length && {
          address: googleAddress,
          latitude: parseFloat(results[0]?.latitude), 
          longitude: parseFloat(results[0]?.longitude), 
          parcel: results[0]?.geom_as_wkt
        })
      };
      !!needCenter && setCenter({ lat: coordinates.latitude, lng: coordinates.longitude });

      if (!!location.parcel) {
        const parcel = WKT.parse(location.parcel);
        const parcelAddress = { value: location.address, formattedValue: location.address, ..._pick(location, ['latitude', 'longitude', 'parcel']), paths: _uniqWith(parcel.coordinates[0][0].map(i => ({ lat: i[1], lng: i[0] })), _isEqual) };
        
        if (!!addresses?.length) {
          selectPolygon(location.address)
          .then(() => onAddressSelect(_map(addresses, (a) =>  coordinates?.latitude === a?.latitude ? parcelAddress : a)))
        } else {
          isAddress ? selectPolygon(location.address).then(() => onAddressSelect(parcelAddress)) : onPolygonSelect(parcelAddress);
        }
      } else {
        setMarkers(prev => [...prev, coordinates]);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const coordinates = _pick(addresses[addresses?.length - 1], ['latitude', 'longitude']);

    if (!!addresses?.length && addresses?.length !== prevLength) {
      processCoordinates(coordinates, addresses, true, false, addresses[addresses?.length - 1]?.value);
      zoom < 18 && setZoom(18);
    }
  }, [processCoordinates, addresses, prevLength, zoom]);
  useEffect(() => {
    if (!!address) {
      const coordinates = { latitude: address?.latitude, longitude: address?.longitude };

      if (!!coordinates.latitude && !!coordinates.longitude && coordinates.longitude !== center.lng && coordinates.latitude !== center.lat) {
        processCoordinates(coordinates, [], true, true, address.value);
        zoom < 18 && setZoom(18);
      }
    }
  }, [address, center, processCoordinates, zoom]);
  useEffect(() => {
    return clearMapData;
  }, [clearMapData]);

  return (
    <GoogleMap
      zoom={zoom}
      center={center}
      onClick={handleClick}
      markers={markers}
      polygons={!!polygon ? addresses.concat([polygon]) : !!address ? [address] : addresses}
      options={options}
    />
  );
};

Map.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  onAddressSelect: PropTypes.func.isRequired
};

export default memo(Map);