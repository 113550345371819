import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getMessages as getMessagesAction,
  getMessage as getMessageAction,
  createMessage as createMessageAction,
  createLocalMessage as createLocalMessageAction,
  deleteMessage as deleteMessageAction,
  deleteLocalMessage as deleteLocalMessageAction,
  readMessages as readMessagesAction,
  setLocalMessageError as setLocalMessageErrorAction,
  setLocalReadStatus as setLocalReadStatusAction,
  clearLocalMessages as clearLocalMessagesAction,
  clearLocalActiveMessage as clearLocalActiveMessageAction
} from 'actions/messages';

const useMessages = () => {
  const dispatch = useDispatch();
  const getMessages = useCallback(({ conversation_id, offset, orders, center_on_unread_message, direction, my_id }) => 
    dispatch(getMessagesAction({ conversation_id, offset, orders, center_on_unread_message, direction, my_id })), [dispatch]);
  const getMessage = useCallback(({ conversation_id, message_id, isConversationMessage }) => dispatch(getMessageAction({ conversation_id, message_id, isConversationMessage })), [dispatch]);
  const createMessage = useCallback(({ conversation_id, message, local_id }) => dispatch(createMessageAction({ conversation_id, message, local_id })), [dispatch]);
  const createLocalMessage = (params) => dispatch(createLocalMessageAction(params));
  const deleteMessage = useCallback(id => dispatch(deleteMessageAction(id)), [dispatch]);
  const deleteLocalMessage = (id) => dispatch(deleteLocalMessageAction(id));
  const readMessages = useCallback((params) => dispatch(readMessagesAction(params)), [dispatch]);
  const setLocalMessageError = useCallback((params) => dispatch(setLocalMessageErrorAction(params)), [dispatch]);
  const setLocalReadStatus = useCallback((id) => dispatch(setLocalReadStatusAction(id)), [dispatch]);
  const clearLocalMessages = useCallback(() => dispatch(clearLocalMessagesAction()), [dispatch]);
  const clearLocalActiveMessage = useCallback(() => dispatch(clearLocalActiveMessageAction()), [dispatch]);

  return {
    getMessages,
    getMessage,
    createMessage,
    createLocalMessage,
    deleteMessage,
    deleteLocalMessage,
    readMessages,
    setLocalMessageError,
    setLocalReadStatus,
    clearLocalMessages,
    clearLocalActiveMessage
  };
};

export default useMessages;