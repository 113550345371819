import { memo, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import _isUndefined from 'lodash/isUndefined';
// Local files
import { VersionChip, VersionChipLabel } from './RepeatPublishOffering.styled';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import Notifications from 'components/Recipients/Notifications/Notifications';
import useChameleon from 'hooks/useChameleon';
import useError from 'hooks/useError';
import useOffers from 'hooks/useOffers';
import useSuccess from 'hooks/useSuccess';

const PublishOffering = ({ open, id, public: p, name, version, headline, offer, onClose, onPublish }) => {
  const { track: trackChameleon } = useChameleon();
  const { setError } = useError();
  const { publishOffer } = useOffers();
  const { setSuccess } = useSuccess();
  const [notify, setNotify] = useState(false);
  const [recipientsType, setRecipientsType] = useState('');
  const [subject, setSubject] = useState({ value: '', error: '' });
  const [message, setMessage] = useState('');
  const [processing, setProcessing] = useState(false);
  const notificationsProps = { subject, notify, message, recipientsType, offer, disabled: processing };

  const handleEntering = () => setSubject({ value: `Offering Update | ${headline || offer?.headline || ''}`, error: '' });
  const handleExited = () => {
    setNotify(false);
    setRecipientsType('');
    setSubject({ value: '', error: '' });
    setMessage('');
  };
  const handleCancelClick = () => onClose();
  const handlePublishClick = () => {
    setProcessing(true);
    publishOffer({ id, message, notify, ...!!recipientsType && recipientsType !== 'all' && { recipients_type: recipientsType }, subject: subject.value })
    .then(() => {
      trackChameleon(`${p ? 'Market' : 'Private'} offering published`);
      setSuccess('New version saved successfully').then(onPublish);

      if (notify && !!recipientsType) {
        const amount = recipientsType === 'all'
          ? offer?.recipients_count
          : (recipientsType === 'email_opened'
            ? offer?.email_opened_recipients_count
            : (recipientsType === 'visited' ? offer?.visited_recipients_count : offer?.unlocked_recipients_count)
          );
        
        setSuccess(`Email notification successfully sent to ${amount} recipient${amount > 1 ? 's' : ''}`);
      }
    })
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };
  const handleNotificationsChange = ({ s, n, m, r }) => {
    if (!_isUndefined(s)) setSubject(s);
    if (!_isUndefined(n)) setNotify(n);
    if (!_isUndefined(n) && n) setRecipientsType('all');
    if (!_isUndefined(m)) setMessage(m);
    if (!_isUndefined(r)) setRecipientsType(r);
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{ sx: { maxWidth: 780, width: '100%' } }}
      TransitionProps={{ onExited: handleExited, onEntering: handleEntering }}
      actions={
        <>
          <Button
            color='error'
            sx={{ minWidth: 100 }}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
          <Button
            disabled={processing}
            variant='contained'
            sx={{ minWidth: 140 }}
            onClick={handlePublishClick}
          >
            Publish Now
          </Button>
        </>
      }
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: '56px !important', pb: '40px', pl: 8, pr: 8 }}>
        <VersionChip><VersionChipLabel>V{version}</VersionChipLabel></VersionChip>
        <Typography variant='subtitle1' mb={2} align='center'>Your new version of {name} will be saved over the current one</Typography>
        <Typography variant='body2' mb={4} align='center'>Recipients will not be notified of changes by default</Typography>
        <Notifications
          {...notificationsProps}
          notifyLabel='Notify recipients that this Offering has been updated'
          selectLabel='Notify the following:'
          tinyPlaceholder='Add optional, personalized message about this update'
          onChange={handleNotificationsChange}
        />
      </DialogContent>
    </BaseDialog>
  );
};

PublishOffering.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  headline: PropTypes.string.isRequired,
  offer: PropTypes.shape({
    recipients_count: PropTypes.number,
    email_opened_recipients_count: PropTypes.number,
    visited_recipients_count: PropTypes.number,
    unlocked_recipients_count: PropTypes.number
  }),
  onClose: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <PublishOffering {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);