import { memo, useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
// Local files
import { Container, Header, HeaderContainer, Title, AI, SignOutButton, MapContainer, Content, ContentHeader, Search, Results, ExportButton, Footer } from './Core.styled';
import { ReactComponent as LogoIcon } from 'assets/icons/qwincy.svg'
import { ReactComponent as DashboardIcon } from 'assets/icons/extension/dashboard.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/extension/settings.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/extension/close.svg';
import Table from 'components/Emails/Table/Table';
import Loading from './Loading/Loading';
import Details from '../../Emails/Details/Details';
import ExtensionMap from './Map/Map';
import DeleteDialog from 'components/Dialogs/Delete/Delete';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useCustomSelector from 'hooks/useCustomSelector';
import useEmails from 'hooks/useEmails';
import useError from 'hooks/useError';

const Core = ({ disabled, onSignOut, onClose }) => {
  const { getEmails, clearLocalEmails } = useEmails();
  const { setError } = useError();
  const { nylasUserId, totalEmails, fetching } = useCustomSelector(state => ({
    nylasUserId: state.nylasUsers.nylasUser.id,
    totalEmails: state.emails.all.pagination.total_count,
    fetching: state.emails.all.fetching
  }));
  const [selectedEmail, setSelectedEmail] = useState(false);
  const [query, setQuery] = useState('');

  const handleSignOutClick = () => onSignOut();
  const handleDashboardClick = () => window.open('/dashboard', '_blank', 'noreferrer');
  const handleSettingsClick = () => {
    console.log('settings');
  };
  const handleClose = () => onClose();
  const handleQueryChange = ({ target: { value } }) => {
    setQuery(value);
  };
  const handleExportClick = () => {
    console.log('export');
  };
  const handleTableItemClick = v => setSelectedEmail(v);
  const handleDetailsBackClick = () => setSelectedEmail(null);
  const fetchEmails = useCallback(({ nylas_user_id, offset, query }) => {
    getEmails({ nylas_user_id, offset, query })
    .catch(e => setError(e));
  }, [getEmails, setError]);
  const handleLoadMore = offset => fetchEmails({ offset, query });

  useEffect(() => {
    let timeout = setTimeout(() =>
      clearLocalEmails()
      .then(() => fetchEmails({ nylas_user_id: nylasUserId, offset: 0, query }))
    , 500);

    return () => clearTimeout(timeout);
  }, [nylasUserId, query, clearLocalEmails, fetchEmails]);

  return (
    <Container>
      <Header>
        <HeaderContainer>
          <LogoIcon style={{ height: 25, color: '#fff' }} />
          <Title>Qwincy <AI /></Title>
          {fetching && <Loading />}
          <Box sx={{ ml: 'auto' }}>
            <SignOutButton
              disabled={disabled}
              onClick={handleSignOutClick}
            >
              Sign out
            </SignOutButton>
            <Button
              variant='blank'
              color='neutral'
              disabled={disabled}
              onClick={handleDashboardClick}
            >
              <DashboardIcon />
            </Button>
            <Button
              variant='blank'
              color='neutral'
              disabled={disabled}
              onClick={handleSettingsClick}
            >
              <SettingsIcon />
            </Button>
            <Button
              variant='blank'
              color='neutral'
              sx={{ mr: '-8px' }}
              disabled={disabled}
              onClick={handleClose}
            >
              <CloseIcon />
            </Button>
          </Box>
        </HeaderContainer>
      </Header>
      <MapContainer>
        <ExtensionMap />
      </MapContainer>
      <Content>
        {!selectedEmail &&
          <ContentHeader>
            <Search value={query} onChange={handleQueryChange} />
            <Box sx={{ flexGrow: 1 }} />
            <Results>{!fetching && `Showing ${totalEmails} deals`}</Results>
            <ExportButton onClick={handleExportClick}>
              Export
            </ExportButton>
          </ContentHeader>
        }
        {selectedEmail ?
          <Details
            selectedEmail={selectedEmail}
            onBack={handleDetailsBackClick}
          /> :
          <Table
            onItemClick={handleTableItemClick}
            onLoadMore={handleLoadMore}
          />
        }
      </Content>
      {!selectedEmail && <Footer />}
      <DeleteDialog />
    </Container>
  );
};

Core.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onSignOut: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Core {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);