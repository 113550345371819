import { memo, useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
// Local files
import Banner from 'components/Users/MainCard/Banner/Banner';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import AuthorizationDialog from 'components/Dialogs/Authorization/Authorization';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as QwincyR } from 'assets/icons/qwincy-r.svg';
import { getRepresentation } from 'helpers';
import { representationVariants } from 'helpers/constants';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useUsers from 'hooks/useUsers';

const User = () => {
  const navigate = useNavigate();
  const { username } = useParams();
  const { setAuthData } = useApp();
  const { setError } = useError();
  const authorizedAsUser = useCustomSelector(state => state.sessions.session.userableType === 'user');
  const { getPublicUser, getPublicUserVCard } = useUsers();
  const [user, setUser] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const bannerSrc = user?.header ? getRepresentation(user.header.representations, representationVariants.MEDIUM) : null;
  const avatarSrc = user?.photo ? getRepresentation(user.photo.representations, representationVariants.TINY) : null;

  const handleConnectClick = () => {
    if (authorizedAsUser) {
      navigate(`/users/${user.username}`);
    } else {
      setAuthData({ open: true, mode: 'signUp', redirectAction: { type: 'connect_click', data: { user } } });
    }
  };
  const handleDownloadClick = () => {
    setDisabled(true);
    getPublicUserVCard(username)
    .then(({ payload: { data } }) => {
      const a = document.createElement('a');
      const file = new Blob([data], { type: 'text/vcard' });

      a.href = URL.createObjectURL(file);
      a.download = `${user.first_name} ${user.last_name}`;
      a.click();

      URL.revokeObjectURL(a.href);
    })
    .catch(e => setError(e))
    .finally(() => setDisabled(false));
  };
  const handleSignInClick = () => window.open('https://app.qwincy.com');

  useEffect(() => {
    getPublicUser(username)
    .then(({ payload: { data } }) => setUser(data.user))
    .catch(e => setError(e));

    return () => {
      setUser(null);
    };
  }, [getPublicUser, setError, username]);

  return (
    <>
      <Container sx={{ px: { xs: 0, md: '32px' }, py: { xs: 0, md: '32px' }}}>
        <Container
          maxWidth='md'
          disableGutters
          sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
        >
          <Banner src={bannerSrc} />
          {user && 
            <Box display='flex' flexDirection='column' alignItems='center'>
              <BaseAvatar
                src={avatarSrc}
                firstName={user.first_name}
                lastName={user.last_name}
                sx={{
                  width: 139,
                  height: 139,
                  borderRadius: '50%',
                  fontSize: '56px',
                  marginTop: '-85px',
                  border: '2px solid rgba(255, 255, 255, 1)'
                }}
              />
              <Typography marginTop='20px' fontWeight={600} fontSize='30px' lineHeight='20px' color='rgba(0, 0, 0, 0.9)'>
                {user.first_name} {user.last_name}
              </Typography>
              <Typography marginTop='10px' fontWeight={300} fontSize='14px' lineHeight='20px' color='rgba(0, 0, 0, 0.9)' maxWidth={290} textAlign='center'>
                {user.headline}
              </Typography>
              <Typography marginTop='30px' fontWeight={400} fontSize='15px' lineHeight='20px' color='rgba(0, 0, 0, 0.9)' maxWidth={230} textAlign='center'>
                Let’s connect on Qwincy to privately share dealflow.
              </Typography>
              <Button
                variant='gradient'
                onClick={handleConnectClick}
                sx={{
                  marginTop: '24px',
                  width: '100%',
                  maxWidth: '283px',
                  height: 53,
                  fontSize: '23px',
                  fontWeight: 600,
                  lineHeight: '20px'
                }}
                disabled={disabled}
              >
                Connect
              </Button>
              <Button
                variant='text'
                startIcon={<DownloadIcon />}
                onClick={handleDownloadClick}
                sx={{
                  marginTop: '28px',
                  textDecoration: 'underline',
                  fontSize: '14px',
                  fontWeight: 400,
                  color: 'rgba(54, 83, 212, 1)'
                }}
                disabled={disabled}
              >
                Download vCard
              </Button>
            </Box>
          }
          <Box
            display='flex'
            alignItems='center'
            flexDirection='column'
            position='absolute'
            bottom={20}
            left={0}
            width='100%'
          >
            <Typography fontWeight={400} fontSize='10px' lineHeight='11px' marginBottom='10px'>
              Powered by
            </Typography>
            <QwincyR />
            <Box display='flex' alignItems='center'>
              <Typography fontWeight={300} fontSize='10px' lineHeight='11px' marginTop='7px'>
                Collaborative Dealflow Management
              </Typography>
              <span style={{ fontWeight: 300, fontSize: '7px' }}>&trade;</span>
            </Box>
            <Button
              variant='text'
              sx={{ textDecoration: 'underline', marginTop: '7px' }}
              onClick={handleSignInClick}
            >
              Sign In
            </Button>
          </Box>
        </Container>
      </Container>
      <AuthorizationDialog />
    </>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <User {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);