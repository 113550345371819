import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _startCase from 'lodash/startCase';
import Scrollbars from 'react-custom-scrollbars-2';
import { memo, useEffect, useState } from 'react';
// Local files
import { ReactComponent as CheckmarkCheckedIcon } from 'assets/icons/checkmark-checked.svg';
import { ReactComponent as CheckmarkUncheckedIcon } from 'assets/icons/checkmark-unchecked.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin.svg';
import { ReactComponent as WebsiteIcon } from 'assets/icons/website.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as EnabledSharedListIcon } from 'assets/icons/shared-list-enabled-small.svg';
import Head from './Head/Head';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import TableCell from 'components/Common/DataDisplay/BaseTable/TableCell/TableCell';
import TableRow from 'components/Common/DataDisplay/BaseTable/TableRow/TableRow';
import TableSkeleton from 'components/Common/DataDisplay/BaseTable/TableSkeleton/TableSkeleton';
import { getFormattedPhone, getRepresentation, getShortDate } from 'helpers';
import { representationVariants } from 'helpers/constants';
import useContacts from 'hooks/useContacts';
import useConversations from 'hooks/useConversations';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useInvitations from 'hooks/useInvitations';
import { Link } from 'react-router-dom';

const ContactsTable = ({ onChange, checkedContacts, onCheckedContactsChange, onEdit }) => {
  const { getContacts } = useContacts();
  const { openOrCreateConversation } = useConversations();
  const { acceptInvitation, declineInvitation } = useInvitations();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const myId = useCustomSelector(state => state.profile.user.id);
  const { data, fetching } = useCustomSelector(state => state.contacts.all);
  const params = useCustomSelector(state => state.contacts.all.filters);
  const [processing, setProcessing] = useState(true);
  const isByUserOrder = Object.keys(params.orders)[0] === 'user';
  const orderBy = isByUserOrder ? Object.entries(Object.entries(params.orders)[0][1])[0][0] : Object.entries(params.orders)[0][0];
  const order = isByUserOrder ? Object.entries(Object.entries(params.orders)[0][1])[0][1] : Object.entries(params.orders)[0][1];
  const { pending, quarantined, authorizedAsUser } = useCustomSelector(state => ({
    ...state.contacts.all.filters,
    authorizedAsUser: state.sessions.session.userableType === 'user'
  }));

  const handleEditClick = id => onEdit(_find(data, d => d.id === id));
  const handleConversationClick = (e, user) => {
    e.stopPropagation();
    setProcessing(true);
    openOrCreateConversation(user?.id, user?.private_conversation);
    setProcessing(false);
  };
  const handleCheckboxClick = ({ id, checked }) => onCheckedContactsChange(prev => checked ? [...prev, id] : _filter(prev, p => p !== id));
  const handleChangeSort = activeColumn => {
    const isAsc = orderBy === activeColumn && order === 'asc';

    onChange({ order: isAsc ? 'desc' : 'asc', orderBy: activeColumn });
  };
  const handleAccept = (invitation_token, inviter_id) => {
    setProcessing(true);
    acceptInvitation({ invitation_token, inviter_id })
    .then(() => setSuccess('Invite successfully accepted'))
    .finally(() => setProcessing(false))
  };
  const handleIgnore = (invitation_token, inviter_id) => {
    setProcessing(true);
    declineInvitation({ invitation_token, inviter_id })
    .then(() => setSuccess('Invite successfully declined'))
    .finally(() => setProcessing(false))
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (authorizedAsUser) {  
        setProcessing(true);
        getContacts(params)
        .then(() => setProcessing(false))
        .catch(e => setError(e));
      }
    }, !!params?.query ? 500 : 0);
    
    return () => clearTimeout(timeout);
  }, [params, authorizedAsUser, getContacts, setError]);

  return (
    <Scrollbars
      autoHideTimeout={1000}
      onScrollFrame={({top}) => onChange({top})}
    >
      {fetching && params?.offset === 0 ? <TableSkeleton /> :
        <Table stickyHeader>
          <Head
            selectedItemsAmount={checkedContacts.length}
            onSelectedChange={onCheckedContactsChange}
            changeSorting={handleChangeSort}
            { ...{ order, orderBy }}
          />
          <TableBody>
            {_map(data, ({ id, first_name, last_name, email: e, quarantine_type, quarantined_at, title : t, lists, mobile_phone, office_phone, office_phone_extension, 
              user, location: l, street_address, second_address, state: s, zip: z, city: c,  linkedin_profile_url, website_url, invitation,
              status, notes: n, company_name, created_at, sharing_subscribed, suggested_user
            }) => {
              let invitationData = null;
              if (!!invitation?.inviter && invitation?.inviter?.id !== myId) invitationData = invitation?.inviter;
              if (!!invitation?.user && invitation?.user?.id !== myId) invitationData = invitation?.user;
              const avatarSrc = getRepresentation(user?.photo?.representations ?? invitationData?.photo?.representations, representationVariants.TINY);
              const avatarFirstName = user?.first_name ?? invitationData?.first_name ?? first_name;
              const avatarLastName = user?.last_name ?? invitationData?.last_name ?? last_name;
              const firstName = user?.first_name ?? invitationData?.first_name ?? first_name;
              const lastName = user?.last_name ?? invitationData?.last_name ?? last_name;
              const email = user?.email ?? invitationData?.email ?? e;
              const title = user?.title ?? invitationData?.title ?? t;
              const mobilePhone = getFormattedPhone(user?.mobile_phone ?? invitationData?.mobile_phone ?? mobile_phone);
              const officePhone = getFormattedPhone(user?.office_phone ?? invitationData?.office_phone ?? office_phone);
              const officePhoneExtension = user?.office_phone_extension ?? invitationData?.office_phone_extension ?? office_phone_extension;
              const notes = user?.notes ?? invitationData?.notes ?? n;
              const companyName = user?.company_name ?? invitationData?.company_name ?? company_name;
              const streetAddress = user?.street_address ?? invitationData?.street_address ?? street_address;
              const seconAddress = user?.second_address ?? invitationData?.second_address ?? second_address;
              const state = user?.state ?? invitationData?.state ?? s;
              const zip = user?.zip ?? invitationData?.zip ?? z;
              const city = user?.city ?? invitationData?.city ?? c;
              const location = user?.location ?? invitationData?.location ?? l ?? '';
              const linkedinProfileUrl = user?.linkedin_profile_url ?? invitationData?.linkedin_profile_url ?? linkedin_profile_url ?? null;
              const websiteUrl = user?.website_url ?? invitationData?.website_url ?? website_url ??  null;

              return (
                <TableRow key={id} onClick={() => status !== 'invited' && handleEditClick(id)}>
                  <TableCell align='center'>
                    <Checkbox 
                      checked={!!_find(checkedContacts, cc => cc  === id)} 
                      onChange={({ target: { checked } }) => handleCheckboxClick({ id, checked })} 
                      onClick={(e) => e.stopPropagation()} 
                    />
                  </TableCell>
                  <TableCell align='right' sx={{ minWidth: 130, alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      {!!user &&
                        <Button variant='blank' color='neutral' sx={{ mr: '16px' }} onClick={(e) => handleConversationClick(e, user)}>
                          <ChatIcon />
                        </Button>
                      }
                      {!!user ? <CheckmarkCheckedIcon style={{ marginRight: 20 }} /> : <CheckmarkUncheckedIcon style={{ marginRight: 20 }} />}
                      {(!!user || !!invitationData) && 
                        <BaseAvatar
                          src={avatarSrc}
                          firstName={avatarFirstName}
                          lastName={avatarLastName}
                          sx={{ width: 28, height: 28, fontSize: 11 }}
                        />
                      }
                    </Box>
                  </TableCell>
                  <TableCell align='left' sx={{ ...(quarantined && { color: '#8b8c91' }) }}>
                    {firstName}
                  </TableCell>
                  <TableCell align='left' sx={{ ...(quarantined && { color: '#8b8c91' }) }}>
                    {lastName}
                  </TableCell>
                  <TableCell align='left' sx={{ minWidth: 248, ...(quarantined && { color: '#D60000', fontSize: '11px', fontWeight: 400, lineHeight: '15px' }) }}>
                    <Box>
                      <Box>{email}</Box>
                      {quarantined &&
                        <Box>
                          Quarantined: {_startCase(quarantine_type)} on {getShortDate(quarantined_at)}
                        </Box>
                      }
                      {!user && suggested_user && invitation?.user?.id !== myId &&
                        <Typography color='#4877F0' fontSize='11px'>
                          Qwincy user found! <Link to={`/users/${suggested_user.username}`}>Go to profile</Link>
                        </Typography>
                      }
                    </Box>
                  </TableCell>
                  <TableCell align='left' sx={{ minWidth: 400, ...(quarantined && { color: '#8b8c91' }) }} >
                      {!pending && _map(_filter(lists, l => !l.default), l =>
                        <Chip
                          icon={!!l.groups.length ? <EnabledSharedListIcon width={12} /> : null}
                          key={l.id}
                          label={l.name}
                          size='small'
                          sx={{ m: '1px', fontWeight: 400, fontSize: '11px', lineHeight: '20px', color: '#4877F0', backgroundColor: 'rgba(72, 119, 240, 0.12)' }}
                        />
                      )}
                    {pending && <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                      <Button color='primary' variant='outlined' disabled={processing} onClick={() => handleIgnore(invitation?.invitation_token, invitation?.inviter?.id)}>
                        Ignore
                      </Button>
                      <Button color='primary' variant='contained' disabled={processing} onClick={() => handleAccept(invitation?.invitation_token, invitation?.inviter?.id)}>
                        Accept
                      </Button>
                    </Box>}
                  </TableCell>
                  <TableCell align='left' sx={{ whiteSpace: 'nowrap', ...(quarantined && { color: '#8b8c91' }) }}>
                    {mobilePhone}
                  </TableCell>
                  <TableCell align='left' sx={{ whiteSpace: 'nowrap', ...(quarantined && { color: '#8b8c91' }) }}>
                    {officePhone}
                  </TableCell>
                  <TableCell align='left' sx={{ whiteSpace: 'nowrap', ...(quarantined && { color: '#8b8c91' }) }}>
                    {officePhoneExtension}
                  </TableCell>
                  <TableCell align='left' sx={{ whiteSpace: 'nowrap', ...(quarantined && { color: '#8b8c91' }) }}>
                    {notes}
                  </TableCell>
                  <TableCell align='left' sx={{ whiteSpace: 'nowrap', ...(quarantined && { color: '#8b8c91' }) }}>
                    {title}
                  </TableCell>
                  <TableCell align='left' sx={{ whiteSpace: 'nowrap', ...(quarantined && { color: '#8b8c91' }) }}>
                    {companyName}
                  </TableCell>
                  <TableCell align='left' sx={{ whiteSpace: 'nowrap', ...(quarantined && { color: '#8b8c91' }) }}>
                    {streetAddress}
                  </TableCell>
                  <TableCell align='left' sx={{ whiteSpace: 'nowrap', ...(quarantined && { color: '#8b8c91' }) }}>
                    {seconAddress}
                  </TableCell>
                  <TableCell align='left' sx={{ whiteSpace: 'nowrap', ...(quarantined && { color: '#8b8c91' }) }}>
                    {state}
                  </TableCell>
                  <TableCell align='left' sx={{ whiteSpace: 'nowrap', ...(quarantined && { color: '#8b8c91' }) }}>
                    {zip}
                  </TableCell>
                  <TableCell align='left' sx={{ whiteSpace: 'nowrap', ...(quarantined && { color: '#8b8c91' }) }}>
                    {city}
                  </TableCell>
                  <TableCell align='left' sx={{ ...(quarantined && { color: '#8b8c91' }) }}>
                    {location}
                  </TableCell>
                  <TableCell align='center' sx={{ ...(quarantined && { color: '#8b8c91' }) }}>
                    {!!linkedinProfileUrl ? <IconButton href={`//${linkedinProfileUrl}`} target='_blank'><LinkedinIcon /></IconButton> : ''}
                  </TableCell>
                  <TableCell align='center' sx={{ ...(quarantined && { color: '#8b8c91' }) }}>
                    {!!websiteUrl ? <IconButton href={`//${websiteUrl}`} target='_blank'><WebsiteIcon /></IconButton> : ''}
                  </TableCell>
                  <TableCell align='center' sx={{ ...(quarantined && { color: '#8b8c91' }) }}>
                    {sharing_subscribed ? 'Subscribed' : 'Unsubscribed'}
                  </TableCell>
                  <TableCell align='left' sx={{ whiteSpace: 'nowrap', ...(quarantined && { color: '#8b8c91' }) }}>
                    {new Date(created_at).toLocaleDateString('en-US') }
                  </TableCell>
                </TableRow>
              );
            })}
            {fetching &&
            <TableRow>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
              <TableCell><Skeleton animation='wave' /></TableCell>
            </TableRow>
          }
          </TableBody>
        </Table>
      }
    </Scrollbars>
  );
};

export default memo(ContactsTable);