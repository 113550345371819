import { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// Local files
import { ReactComponent as LockIcon } from 'assets/icons/lock-group.svg';

const PrivateLabel = () => {
  return (
    <Box
      sx={{
        flexShrink: 0,
        lineHeight: 'normal',
        display: 'flex',
        alignItems: 'center'
      }}
    >
    <LockIcon />
    <Typography
      variant='caption'
      sx={{
        fontWeight: 700,
        background: '-webkit-linear-gradient(100deg, #4F4200 -5.03%, #E3C735 148.63%, #937B00 152.97%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        marginLeft: '8px'
      }}
    >
      Private Group
    </Typography>
  </Box>
  );
};

export default memo(PrivateLabel);