import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

const Item = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'flex-start',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  '& > *': {
    '&:first-of-type': {
      flexGrow: 1,
      maxWidth: '70%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: '100%'
      }
    },
    '&:last-of-type': {
      flexBasis: '30%',
      flexShrink: 0,
      [theme.breakpoints.down('md')]: {
        flexBasis: '100%',
        marginTop: '24px',
        marginBottom: '0px'
      }
    }
  }
}));
const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export { Item, ListItem };