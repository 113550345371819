import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getSharesRoutine,
  getShareRoutine,
  createShareRoutine,
  updateShareRoutine,
  clearLocalSharesRoutine
} from 'actions';
import _omit from 'lodash/omit';

const attributes = [
  'id',
  { members: ['id', { user: ['first_name', 'last_name', { photo: ['representations'] }] }] },
  { boosters: ['id', 'notify_at', 'status', 'scheduled_delivery'] },
  'email_style',
  'scheduled_deliveries',
  'notify_at',
  'created_at'
];

export const getShares = getThunkActionCreator(
  getSharesRoutine,
  async ({ offer_id, limit = 25, offset = 0, orders = { updated_at: 'desc' }, query = null }) => {
    const params = { limit, offset, orders, attributes, with_scheduled_booster: true, ...query && { query } };

    return await axios.get(`/offers/${offer_id}/shares`, { params });
  }
);
export const getShare = getThunkActionCreator(
  getShareRoutine,
  async id => {
    const params = { attributes };

    return await axios.get(`/shares/${id}`, { params });
  }
);
export const createShare = getThunkActionCreator(
  createShareRoutine,
  async ({ offer_id, share }) => {
    const data = { share, attributes };

    return await axios.post(`/offers/${offer_id}/shares`, data);
  }
);
export const updateShare = getThunkActionCreator(
  updateShareRoutine,
  async ({ id, ...share }) => {
    const response = await axios.patch(`/shares/${id}`, { share: _omit(share, ['remove']) });

    return { response, share: { id, ...share } };
  }
);
export const clearLocalShares = getThunkActionCreator(clearLocalSharesRoutine, async () => {});