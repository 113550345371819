import styled from '@mui/material/styles/styled';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export const HeadCell = styled(TableCell)(() => ({
  fontSize: '12px',
  backgroundColor: '#F3F3F5',
  padding: '6px',
  '&:first-of-type': {
    paddingLeft: '20px'
  },
}));

export const StyledRow = styled(TableRow,
  { shouldForwardProp: (prop) => prop !== 'unseen' && prop !== 'downloaded' }
)(({ unseen, downloaded }) => ({
  cursor: 'pointer',
  width: '100%',
  overflow: 'hidden',
  backgroundColor: unseen ? '#ffffff' : downloaded ? '#8FF6C5' : '#F3F3F5',
  fontWeight: (unseen || downloaded) ? 600 : 'inherit',
}));

export const StyledCell = styled(TableCell,
  { shouldForwardProp: (prop) => prop !== 'unseen' && prop !== 'downloaded' }
)(() => ({
  fontSize: '12px',
  fontWeight: 'inherit',
  overflow: 'hidden',
  padding: '6px',
  '&:first-of-type': {
    paddingLeft: '20px'
  },
}));

export const Text = styled((props) =>
<Typography noWrap component='div' {...props} />)({
  width: 'inherit',
  color: 'inherit',
  font: 'inherit',
});

export const DeleteButton = styled(Button)(() => ({
  minWidth: 'unset',
  minHeight: 'unset',
  height: 28,
  padding: '0px 4px',
}));
