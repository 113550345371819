import { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import PropTypes from 'prop-types';
// Local files
import { Actions, Content, Description, Info, SaveInfoContainer, StatusContainer, StyledAppBar, StyledButton, StyledPrimaryButton, StyledToolbar } from './Header.styled';
import ConfirmDiscardChanges from 'components/Dialogs/ConfirmDiscardChanges/ConfirmDiscardChanges';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import { checkEmptyString, needToUpdateField } from 'helpers';
import useError from 'hooks/useError';
import useCustomSelector from 'hooks/useCustomSelector';
import useOffers from 'hooks/useOffers';
import useSuccess from 'hooks/useSuccess';

/**
 * @param {function} onClose
 * @param {function} onPreview
 * @returns {node}
 */

const Header = forwardRef(({ onClose, onPreview }, ref) => {
  const { setError } = useError();
  const { updateOffer, deleteOffer, setBuilderState } = useOffers();
  const { setSuccess } = useSuccess();
  const { offer, fetching, deleting, saving } = useCustomSelector(state => state.offers.builder);
  const [name, setName] = useState({ value: '', error: '' });
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const handleNameChange = ({ target: { value } }) => setName({ value, error: !checkEmptyString(value) ? 'Name can\'t be empty' : '' });
  const handleNameBlur = ({ target: { value } }) => {
    const error = !checkEmptyString(value);

    if (error) return;
    if (needToUpdateField(offer.name, name.value)) {
      setBuilderState({ saving: true })
      .then(() =>
        updateOffer({ id: offer.id, name: name.value })
        .catch(e => setError(e))
        .finally(() => setBuilderState({ saving: false }))
      );
    }
  };
  const handleDiscardClick = () => {
    setShowConfirmationDialog(true);
  };
  const handleConfirmationDialogClose = result => {
    if (result) {
      setBuilderState({ deleting: true })
      .then(() =>
        deleteOffer({ id: offer.id, source: 'builder' })
        .then(() =>
          setSuccess('Offering draft has been deleted')
          .then(() => onClose())
        )
        .catch(e => setError(e))
        .finally(() => setBuilderState({ deleting: false }))
      );
    }
    setShowConfirmationDialog(false);
  };

  /*** Начальное значение ***/
  useEffect(() => {
    if (!fetching) {
      setName({ value: offer.name, error: '' });
    }
  }, [fetching, offer]);

  useImperativeHandle(ref, () => {
    return {
      checkRequired() {
        if (!checkEmptyString(name.value)) {
          setName(prev => ({ ...prev, error: 'Name can\'t be empty' }));
          return false;
        }
        return true;
      }
    };
  }, [name]);

  return (
    <StyledAppBar position='static'>
      <StyledToolbar>
        <Content>
          <ConfirmDiscardChanges
            open={showConfirmationDialog}
            onClose={handleConfirmationDialogClose}
          />
          {fetching ? <Skeleton width={300} animation='wave' variant='text' /> :
            <Info>
              <Typography variant='subtitle2'>{offer.version !== 1 ? `Edit v${offer.version} Offering Draft` : 'Edit Offering Draft'}</Typography>
              <Description>
                <Breadcrumbs sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: '.75rem', color: 'rgba(255, 255, 255, 0.5)' }}>
                    {offer.dealspace.group.name}
                  </Typography>
                  <Typography sx={{ fontSize: '.75rem', color: 'rgba(255, 255, 255, 0.5)' }}>
                    {offer.dealspace.name}
                  </Typography>
                  <TextField
                    id='offerName'
                    placeholder='Name'
                    fullWidth
                    value={name.value}
                    variant='standard'
                    InputProps={{ disableUnderline: true }}
                    onChange={handleNameChange}
                    onBlur={handleNameBlur}
                    error={!!name.error}
                    helperText={name.error}
                    sx={{ minWidth: 240, '& input': { color: '#fff', fontSize: '0.75rem', py: '5px' } }}
                  />
                </Breadcrumbs>
              </Description>
            </Info>
          }
          <StatusContainer>
            <SaveInfoContainer>
              {saving && <Typography variant='small'>Saving...</Typography>}
            </SaveInfoContainer>
          </StatusContainer>
        </Content>
        <Actions>
          <StyledButton
            disabled={fetching || saving || deleting}
            onClick={handleDiscardClick}
          >
            Discard {offer.version > 1 ? `v${offer.version}` : ''} draft
          </StyledButton>
          <StyledButton
            disabled={fetching || saving || deleting}
            onClick={() => onClose(true)}
          >
            Save as draft & close
          </StyledButton>
          <StyledPrimaryButton
            disabled={fetching || saving || deleting}
            onClick={onPreview}
          >
            Next: {offer.version > 1 ? 'Preview updates' : 'Preview'}
          </StyledPrimaryButton>
        </Actions>
      </StyledToolbar>
    </StyledAppBar>
  );
});

Header.propTypes = {
  onClose: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired
};

const WrappedComponent = forwardRef((props, ref) => {
  return (
    <ErrorWrapper>
      <Header {...props} ref={ref} />
    </ErrorWrapper>
  );
});

export default memo(WrappedComponent);