import {
  getEmailsRoutine,
  getEmailRoutine,
  deleteEmailRoutine,
  clearLocalEmailsRoutine,
  clearLocalEmailRoutine
} from 'actions';
import _filter from 'lodash/filter';
import _map from 'lodash/map';

export const initialState = {
  all: {
    data: [],
    pagination: { limit: 25, offset: 0, count: 0, total_count: 0 },
    fetching: true
  },
  email: {
    id: '',
    attachments: [],
    images: [],
    senders: [],
    deal_type: '',
    asset_classes: [],
    price_cents: 0,
    addresses: [],
    deadline_at: '',
    received_at: '',
    links: [],
    summary: '',
    nylas_message_id: '',
    status: ''
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getEmailsRoutine.REQUEST: {
      const all = { ...state.all, fetching: true };

      return { ...state, all };
    }
    case getEmailsRoutine.SUCCESS: {
      const { data: { emails, pagination } } = action.payload;
      const all = { data: [...state.all.data, ...emails], pagination, fetching: false };

      return { ...state, all };
    }
    case getEmailsRoutine.FAILURE: {
      const all = { ...state.all, fetching: false };

      return { ...state, all };
    }
    case getEmailRoutine.SUCCESS: {
      const { data: { email } } = action.payload;
      const data = _map(state.all.data, item => item.id === email.id ? { ...item, ...email } : item);
      const all = { ...state.all, data };

      return { ...state, all, email };
    }
    case deleteEmailRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const data = status === 204 ? _filter(state.all.data, (item) => item.id !== id) : state.all.data;
      const pagination = { ...state.all.pagination, ...status === 204 && { count: state.all.pagination.count - 1, total_count: state.all.pagination.total_count - 1 } };
      const all = { data, pagination };

      return { ...state, all };
    }
    case clearLocalEmailsRoutine.SUCCESS: {
      return { ...state, all: initialState.all };
    }
    case clearLocalEmailRoutine.SUCCESS: {
      return { ...state, email: initialState.email };
    }
    default: {
      return state;
    }
  };
};

export default reducer;