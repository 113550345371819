import { memo } from 'react';
import _startsWith from 'lodash/startsWith';
import PropTypes from 'prop-types';
// Local files
import excel from 'assets/images/file/excel.png';
import pdf from 'assets/images/file/pdf.png';
import zip from 'assets/images/file/zip.png';
import word from 'assets/images/file/word.png';
import powerpoint from 'assets/images/file/powerpoint.png';
import defaultDoc from 'assets/images/file/default.png';

const DocIcon = ({ type }) => {
  if (_startsWith(type, 'application/pdf')) return <img src={pdf} alt='pdf document' />;
  if (_startsWith(type, 'application/zip')) return <img src={zip} alt='zip document' />;
  if (type.includes('wordprocessingml')) return <img src={word} alt='word document' />;
  if (type.includes('spreadsheetml')) return <img src={excel} alt='excel document' />;
  if (type.includes('presentationml')) return <img src={powerpoint} alt='powerpoint document' />;
  return <img src={defaultDoc} alt='document' />;
};

DocIcon.propTypes = {
  type: PropTypes.string.isRequired
};

export default memo(DocIcon);