import { memo } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// Local files
import { ReactComponent as ActiveIcon } from 'assets/icons/published-bullet.svg';
import Links from './Links/Links';
import Team from './Team/Team';
import Focus from './Focus/Focus';
import About from './About/About';
import Content from './Content/Content';
import GeographicFocuses from './GeographicFocuses/GeographicFocuses';
import { Root, GridContainer, Container } from './DetailsCard.styled';
import useCustomSelector from 'hooks/useCustomSelector';

const DetailsCard = () => {
  const navigate = useNavigate();
  const { username } = useParams();
  const profile = useCustomSelector(state => state.profile.user);
  const user = useCustomSelector(state => state.users.user);
  const isProfile = username === profile.username;
  const activeUser = isProfile ? profile : user;
  const showLinks = activeUser.company_name || activeUser.linkedin_profile_url || activeUser.website_url;
  const showTeam = !!activeUser.teammates.length;
  const showAbout = !!activeUser.about;
  const showFocus = !!activeUser.primary_activities.length || !!activeUser.risk_profiles.length || !!activeUser.asset_classes.length;
  const showContent = !!activeUser.contents.length;
  const showGeographicFocuses = !!activeUser.geographic_focuses?.length;

  const handleViewOfferingsClick = () => {
    navigate(`/deal_flow`, { state: {query: `${activeUser?.first_name} ${activeUser?.last_name}`, vertical: 'market'} })
  };
  if (!showAbout && !showContent && !showFocus && !showGeographicFocuses && !showLinks && !showTeam) return null;
  return (
    <Root>
      <GridContainer>
        <Container>
          {!!activeUser?.public_active_offers_count && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{  display: 'flex', alignItems: 'baseline',}}>
              <ActiveIcon />
              <Typography
                sx={{ fontSize: '12px', fontWeight: 700, pl: '10px' }}
                color='#000000CC'
                component='div'
                mb={2}
                ml={.5}
              >
                {activeUser.public_active_offers_count}
              </Typography>
              <Typography
                sx={{ fontSize: '12px', fontWeight: 400 }}
                color='#000000CC'
                component='div'
                mb={2}
                ml={.5}
              >
                Active Offerings on the Market
              </Typography>
            </Box>
            <Button
              variant='outlined'
              color='neutral'
              sx={{ color: 'rgba(0,0,0,.8)', px: 1.5, maxWidth: '167px' }}
              onClick={handleViewOfferingsClick}
            >
              View Market Offerings
            </Button>
          </Box>}
          { showLinks && <Links {...{activeUser}} /> }
          { showTeam && <Team {...{activeUser}} /> }
        </Container>
        <Container>
          { showAbout && <About {...{activeUser}} /> }
          { showFocus && <Focus {...{activeUser}} /> }
          { showContent && <Content {...{activeUser}} /> }
        </Container>
      </GridContainer>
      { showGeographicFocuses && <GeographicFocuses {...activeUser} /> }
    </Root>
  );
};

export default memo(DetailsCard);