import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const Action = styled(Box)(({ theme }) => ({
  paddingLeft: '14px',
  width: '100%',
  display: 'flex',
  gap: '13px',
  justifyContent: 'flex-start',
  alignItems: 'center'
}));