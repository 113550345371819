import { memo, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import {
  DELETE_DEALSPACE,
  DELETE_DOCUMENT,
  DELETE_CONTACT,
  DELETE_IMAGE,
  DELETE_LOCATION,
  DELETE_MEMBER,
  DELETE_DEALSPACE_MEMBER,
  DELETE_PROPERTY,
  DELETE_FOLDER,
  DELETE_EMAIL
} from 'helpers/confirmations';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useContacts from 'hooks/useContacts';
import useDealspaces from 'hooks/useDealspaces';
import useDocuments from 'hooks/useDocuments';
import useEmails from 'hooks/useEmails';
import useError from 'hooks/useError';
import useFolders from 'hooks/useFolders';
import useImages from 'hooks/useImages';
import useProperties from 'hooks/useProperties';
import useSuccess from 'hooks/useSuccess';
import useMembers from 'hooks/useMembers';

const Delete = () => {
  const navigate = useNavigate();
  const { closeDeleteDialog } = useApp();
  const { deleteContact } = useContacts();
  const { deleteDealspace } = useDealspaces();
  const { deleteDocument } = useDocuments();
  const { deleteFolder } = useFolders();
  const { deleteImage } = useImages();
  const { updateMember, deleteMember } = useMembers();
  const { deleteProperty, updateProperty } = useProperties();
  const { deleteEmail } = useEmails();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { open, type, id, navigatePath, data } = useCustomSelector(state => state.app.deleteDialog);
  const { activeProperty } = useCustomSelector(state => ({
    activeProperty: _find(state.properties.dealspace.data, d => d.id === data?.property_id)
  }));

  const content = useMemo(() => {
    if (type === DELETE_DEALSPACE) return 'Are you sure you want to delete this dealspace?';
    if (type === DELETE_DOCUMENT) return 'Are you sure you want to delete this file?';
    if (type === DELETE_CONTACT) return 'Are you sure you want to delete this contact?';
    if (type === DELETE_FOLDER) return 'Are you sure you would like to delete this item?';
    if (type === DELETE_IMAGE) return 'Are you sure you want to delete this image?';
    if (type === DELETE_LOCATION) return 'Are you sure you want to delete this location?';
    if (type === DELETE_MEMBER) return 'Are you sure you want to revoke access?';
    if (type === DELETE_DEALSPACE_MEMBER) return 'Are you sure you want to revoke access?';
    if (type === DELETE_PROPERTY) return 'Are you sure you want to delete this property?';
    if (type === DELETE_EMAIL) return 'Are you sure you want to delete this email?';
    return '';
  }, [type]);
  const [processing, setProcessing] = useState(false);
  
  const handleClose = () => closeDeleteDialog();
  const handleConfirmClick = () => {
    setProcessing(true);
    if (type === DELETE_DEALSPACE) {
      deleteDealspace(id)
      .then(() =>
        setSuccess('Dealspace successfully removed')
        .then(closeDeleteDialog)
        .then(() => navigate(navigatePath))
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
    if (type === DELETE_DOCUMENT) {
      deleteDocument(id)
      .then(() =>
        setSuccess('File successfully removed')
        .then(closeDeleteDialog)
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
    if (type === DELETE_FOLDER) {
      deleteFolder(id)
      .then(() =>
        setSuccess('Item successfully moved to trash')
        .then(closeDeleteDialog)
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
    if (type === DELETE_CONTACT) {
      deleteContact(id)
      .then(() =>
        setSuccess('Contact successfully removed')
        .then(closeDeleteDialog)
        .then(() => navigate(navigatePath))
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
    if (type === DELETE_IMAGE) {
      deleteImage(id)
      .then(() =>
        setSuccess('Contact successfully removed')
        .then(closeDeleteDialog)
        .then(() => navigate(navigatePath))
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
    if (type === DELETE_LOCATION) {
      const property = { 
        id: activeProperty.id,
        locations_attributes: _map(_filter(activeProperty.locations, (l) => l.id === id), (l) => ({ id: l.id, _destroy: true })),
        locations: _filter(activeProperty.locations, (l) => l.id !== id)
      };

      updateProperty(property)
      .then(() =>
        setSuccess('Location successfully removed')
        .then(closeDeleteDialog)
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
    if (type === DELETE_MEMBER) {
      deleteMember(id)
      .then(() =>
        setSuccess('Member access successfully revoked')
        .then(closeDeleteDialog)
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
    if (type === DELETE_DEALSPACE_MEMBER) {
      updateMember({ id, ...data })
      .then(() =>
        setSuccess('Member access successfully revoked')
        .then(closeDeleteDialog)
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
    if (type === DELETE_PROPERTY) {
      deleteProperty({ id, reason: 'dealspace' })
      .then(() =>
        setSuccess('Property successfully removed')
        .then(closeDeleteDialog)
        .then(() => navigate(navigatePath))
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
    if (type === DELETE_EMAIL) {
      deleteEmail(id)
      .then(() =>
        setSuccess('Email successfully removed')
        .then(closeDeleteDialog)
      )
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
  };

  return (
    <BaseDialog
      open={open}
      onClose={() => processing ? {} : handleClose()}
      otherPaperStyle={{ width: '100%', maxWidth: 500 }}
      title='Warning'
      actions={
        <>
          <Button
            disabled={processing}
            variant='text'
            onClick={handleClose}
            sx={{ fontSize: '12px', mr: '24px', py: '8px', px: '28px' }}
          >
            No
          </Button>
          <Button
            disabled={processing}
            variant='outlined'
            color='error'
            onClick={handleConfirmClick}
            sx={{ fontSize: '12px', px: '28px' }}
          >
            Yes
          </Button>
        </>
      }
    >
      <Box sx={{ px: 4, pb: 3 }}>
        <Typography variant='body2' align='center' p='16px 0px 8px'>
          {content}
        </Typography>
      </Box>
    </BaseDialog>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Delete {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);