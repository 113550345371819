import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export const Root = styled(Box,
  { shouldForwardProp: (prop) => prop !== 'blurred'  })(({ blurred }) => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  inset: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  paddingTop: '8px',
  ...(blurred && {
    background: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(4px)',
    WebkitBackdropFilter: 'blur(4px)',
  })
}));

export const ButtonContainer = styled(Box)({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 32,
  display: 'flex',
  justifyContent: 'center',
});

export const StartChatButton = styled((props) => (
  <Button variant='gradient' {...props} />
))({
  '& svg > line': {
    stroke: '#4371ea'
  }
});