import { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// Local files
import ImageUploader from '../ImageUploader/ImageUploader';
import FileUploader from '../FileUploader/FIleUploader';
import UploadedImage from '../UploadedImage/UploadedImage';
import { Root, FileInput } from './Uploader.styled';
import { getRepresentation } from 'helpers';
import { representationVariants } from 'helpers/constants';
import UploaderSkeleton from '../UploaderSkeleton/UploaderSkeleton';
import useFilesChange from 'hooks/useFilesChange';

const Uploader = ({
  id,
  accept,
  label,
  onChange,
  onDelete,
  forChat = false,
  fullWidth = false,
  formattedValue = null,
  images = null,
  disabled = false,
  addFiles = false,
  setAddFiles = null,
  directly = false,
  width = null,
  multiple = false,
  minHeight = 140,
  maxWidth = null,
  maxHeight = null
}) => {
  const inputRef = useRef(null);
  const { loading, changeFiles } = useFilesChange(directly, onChange, maxHeight, maxWidth);

  useEffect(() => {
    if (addFiles) {
      handleUploadClick();
      setAddFiles(false);
    }
  }, [addFiles, setAddFiles]);

  const handleUploadClick = () => inputRef.current.click();
  const handleFilesChange = ({ target: { files } }) => changeFiles(files);
  
  return (
    <>
      { loading ? <UploaderSkeleton /> : <>
        {!!images || forChat
          ? <>
              <FileInput
                id={id}
                ref={inputRef}
                accept={accept}
                type='file'
                multiple={multiple}
                onChange={handleFilesChange}
                onClick={(event) => event.target.value = ''}
              />
              {!!!images?.length && !forChat && <ImageUploader label={label} onClick={handleUploadClick} />}
              {forChat && !!formattedValue &&
                <UploadedImage
                  id={id}
                  disabled={disabled || loading}
                  src={formattedValue.localUrl || getRepresentation(formattedValue.representations, representationVariants.SMALL)}
                  showImage
                  width={64}
                  onClick={onDelete}
                />
              }
            </>
          : <Root sx={{ width: fullWidth ? '100%' : !!width ? width : 'auto', minHeight }} disabled={loading || disabled}>
            {!!formattedValue
            ? <UploadedImage
                id={id}
                disabled={disabled || loading}
                src={formattedValue.localUrl || getRepresentation(formattedValue.representations, representationVariants.SMALL)}
                filename={formattedValue.filename}
                onClick={onDelete}
              />
            : <FileUploader
                id={id}
                onClick={handleUploadClick}
                ref={inputRef}
                accept={accept}
                onChange={handleFilesChange}
                label={label}
                multiple={multiple}
              />
            }
        </Root> }
      </> }
    </>
  );
};

Uploader.propTypes = {
  id: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  formattedValue: PropTypes.object,
  images: PropTypes.array,
  disabled: PropTypes.bool,
  addFiles: PropTypes.bool,
  setAddFiles: PropTypes.func,
  directly: PropTypes.bool
};

export default memo(Uploader);