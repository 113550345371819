import { memo } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
// Local files
import { Root, Header, TitleContainer, Content } from './Conversations.styled';
import ShowAllChatsButton from './ShowAllChatsButton/ShowAllChatsButton';
import Search from './Search/Search';
import List from './List/List';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useChatsLevel from 'hooks/useChatsLevel';
import useCustomSelector from 'hooks/useCustomSelector';

const Conversations = ({ processing, query, group, onQueryChange, handleCreateCloseChat, handleScroll }) => {
  const { user_ids } = useCustomSelector(state => state.conversations);
  const { first_name, last_name } = useCustomSelector(state => state.profile.user);
  const { isDealspaceLevel, isGroupLevel, isUserLevel } = useChatsLevel();

  return (
    <Root>
      <Header>
        <TitleContainer>
          { isDealspaceLevel ?
          <ErrorWrapper children={<ShowAllChatsButton disabled={processing} />} /> : 
          <>
            <Typography variant='subtitle2'>{isUserLevel ? `${first_name} ${last_name}` : group?.name}</Typography>
            { (isUserLevel || (isGroupLevel && !!user_ids)) && 
            <Button 
              color='inherit' 
              sx={{ fontWeight: 400, mr: '-12px' }} 
              onClick={handleCreateCloseChat}
              disabled={processing}
            >
              { !!user_ids ? 'Cancel' : '+ New Chat' }
            </Button> }
          </> }
        </TitleContainer>
        <Search placeholder='Search people or channels' value={query} onChange={onQueryChange} />
      </Header>
      <Content>
        <ErrorWrapper children={<List processing={processing} handleContainerScroll={handleScroll} />} />
      </Content>
    </Root>
  )
};

Conversations.propTypes = {
  processing: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
  group: PropTypes.shape({
    name: PropTypes.string
  }),
  onQueryChange: PropTypes.func.isRequired,
  handleCreateCloseChat: PropTypes.func.isRequired,
  handleScroll: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Conversations {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);