import { memo } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import _map from 'lodash/map';
// Local Files
import DealspaceAvatar from 'components/Conversations/Common/Avatar/Avatar';
import BaseAvatarGroup from 'components/Common/DataDisplay/BaseAvatarGroup/BaseAvatarGroup';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import useCustomSelector from 'hooks/useCustomSelector';

const DealspaceContent= () => {
  const { conversation: { id, conversationable, users } } = useCustomSelector(state => state.conversations);

  if (!!id) {
    return (
      <>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
          <DealspaceAvatar title={conversationable?.name} src={getRepresentation(conversationable?.cover?.representations, representationVariants.TINY)} />
          <Typography variant='subtitle2' noWrap pl='12px'>{conversationable?.name}</Typography>
        </Box>
        <BaseAvatarGroup max={4}>
          {_map(users, user =>
            <BaseAvatar
              key={user?.id}
              src={getRepresentation(user?.photo?.representations, representationVariants.TINY)}
              firstName={user?.first_name}
              lastName={user?.last_name}
            />
          )}
        </BaseAvatarGroup>
      </>
    );
  }

  return null;
};

export default memo(DealspaceContent);