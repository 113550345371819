import { memo } from 'react';
import InputBase from '@mui/material/InputBase';
// Local files
import SearchIcon from 'components/Common/Icons/Search';

const Search = ({ placeholder='Search...', value, onChange }) => {
  return (
    <InputBase
      startAdornment={<SearchIcon sx={{ color: '#AAAEB2', height: '18px', width: 'auto' }} />}
      placeholder={placeholder}
      fullWidth
      sx={{
        '& input': {
          color: '#AAAEB2',
          fontSize: '0.75rem',
          paddingLeft: '8px',
          fontWeight: 400,
        }
      }}
      value={value}
      onChange={({ target: { value } }) => onChange(value)}
    />
  );
};

export default memo(Search);
