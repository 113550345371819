import { memo } from 'react';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { NavLink } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { StyledNavAction, StyledTab } from '../AppNavigation.styled';
import { ReactComponent as DealFlowIcon } from 'assets/icons/appnav/dealflow.svg';
import { ReactComponent as DealFlowMobileIcon } from 'assets/icons/appnav/dealflow-mobile.svg';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';

const MyDealflow = ({ mobile = false }) => {
  const { setAuthData, /*openAccessBetaDialog*/ } = useApp();
  const { authorizedAsUser, unvisitedOffersCount, /*hasRecipientSession, isQwincyMember*/ } = useCustomSelector(state => ({
    authorizedAsUser: state.sessions.session.userableType === 'user',
    unvisitedOffersCount: state.profile.user.unvisited_offers_count,
    // hasRecipientSession: !!state.profile.recipient.id,
    // isQwincyMember: !!state.profile.recipient.user?.email
  }));

  const checkIsQwincyMember = () => {
    setAuthData({ open: true });
    /**
     * Временное решение
     */
    // (isQwincyMember || !hasRecipientSession) ? setAuthData({ open: true }) : openAccessBetaDialog();
  };
  const handleClick = () => !authorizedAsUser && checkIsQwincyMember();

  if (!mobile) {
    return (
      <StyledTab
        component={authorizedAsUser ? NavLink : Button}
        iconPosition='start'
        to='/deal_flow'
        onClick={handleClick}
        icon={<DealFlowIcon />}
        value={1}
        label={<Badge badgeContent={unvisitedOffersCount} color='success' overlap='circular' sx={{ width: 100 }} slotProps={{ badge: { style: { marginRight: -4 } } }}>My Dealflow</Badge>}
      />
    );
  }
  return (
    <StyledNavAction
      component={authorizedAsUser ? NavLink : Button}
      to='/deal_flow'
      onClick={handleClick}
      label='My Dealflow'
      value={1}
      icon={<DealFlowMobileIcon />}
    />
  );
};

MyDealflow.propTypes = {
  mobile: PropTypes.bool
};

const ErrorFallback = ({ error }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <MyDealflow {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);