import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';

const ImageContent = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '4px'
});
const ImageRoot = styled(ButtonBase)(({ theme }) => ({
  minHeight: 170,
  minWidth: 210,
  backgroundColor: theme.palette.background.paper,
  border: `1px dashed ${theme.palette.primary.main}`,
  borderRadius: '6px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '32px'
}));

export { ImageContent, ImageRoot };