import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

export const FieldsGroup = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
  margin: '60px 0 100px',
  '& > *': {
    width: '100%'
  },
  [theme.breakpoints.up('md')]: {
    '& > *': {
      width: 'calc(50% - 8px)'
    }
  }
}));