import { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { mode, sentryDsn as dsn } from 'apis';

const env_check = mode !== 'local';

const useSentry = () => {
  const init = useCallback(() => {
    if (env_check) Sentry.init({ dsn });
  }, []);

  return { init };
};

export default useSentry;