import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  getChartActivities as getChartActivitiesAction,
  getTableActivities as getTableActivitiesAction,
  getActivity as getActivityAction,
  clearChartActivities as clearChartActivitiesAction,
  clearTableActivities as clearTableActivitiesAction
} from 'actions/activities';

const useActivities = () => {
  const dispatch = useDispatch();
  const getChartActivities = useCallback(({ offer_id, unlocked, tracked, visited, email_opened }) => dispatch(getChartActivitiesAction({ offer_id, unlocked, tracked, visited, email_opened })), [dispatch]);
  const getTableActivities = useCallback(({ offer_id, limit, offset, orders, query, unlocked, tracked, visited, email_opened }) => dispatch(getTableActivitiesAction({ offer_id, limit, offset, orders, query, unlocked, tracked, visited, email_opened })), [dispatch]);
  const getActivity = useCallback(id => dispatch(getActivityAction(id)), [dispatch]);
  const clearChartActivities = useCallback(() => dispatch(clearChartActivitiesAction()), [dispatch]);
  const clearTableActivities = useCallback(() => dispatch(clearTableActivitiesAction()), [dispatch]);

  return { getChartActivities, getTableActivities, getActivity, clearChartActivities, clearTableActivities };
};

export default useActivities;