import {
  getSharesRoutine,
  getShareRoutine,
  createShareRoutine,
  updateShareRoutine,
  clearLocalSharesRoutine
} from 'actions';
import _filter from 'lodash/filter';
import _map from 'lodash/map';

export const initialState = {
  all: {
    data: [],
    pagination: { limit: 25, offset: 0, count: 0, total_count: 0 },
    fetching: true
  },
  share: { id: '', email_style: '', scheduled_deliveries: [], notify_at: '' }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getSharesRoutine.REQUEST: {
      const all = { ...state.all, fetching: true };

      return { ...state, all };
    }
    case getSharesRoutine.SUCCESS: {
      const { data: { shares, pagination } } = action.payload;
      const all = { data: shares, pagination, fetching: false };

      return { ...state, all };
    }
    case getSharesRoutine.FAILURE: {
      const all = { ...state.all, fetching: false };

      return { ...state, all };
    }
    case getShareRoutine.SUCCESS: {
      const { data: { share } } = action.payload;
      const updatedShare = { ...state.share, ...share };

      return { ...state, share: updatedShare, all: { ...state.all, data: _map(state.all.data, s => s.id === share.id ? share : s) } };
    }
    case createShareRoutine.SUCCESS: {
      const { data: { share } } = action.payload;
      if (!share.scheduled_deliveries.length) {
        return state; // Не добавляем в таблицу запись без бустеров
      }
      const all = {
        ...state.all,
        data: [share, ...state.all.data],
        pagination: { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 }
      };

      return { ...state, all };
    }
    case updateShareRoutine.SUCCESS: {
      const { response: { status }, share } = action.payload;
      
      if (share.remove) {
        const data = _filter(state.all.data, d => d.id !== share.id);
        const pagination = { ...state.all.pagination, count: state.all.pagination.count -1, total_count: state.all.pagination.total_count - 1 };
  
        return { ...state, ...(status === 204 && { all: { data, pagination } }) };
      } else {
        const data = _map(state.all.data, s => s.id === share.id ? ({ ...s, ...share }) : s);
        const pagination = state.all.pagination;
  
        return { ...state, ...(status === 204 && { all: { data, pagination } }) };
      }
    }
    case clearLocalSharesRoutine.SUCCESS: {
      return { ...state, offeallr: initialState.all };
    }
    default: {
      return state;
    }
  };
};

export default reducer;