import { memo, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import _isUndefined from 'lodash/isUndefined';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import Notifications from 'components/Recipients/Notifications/Notifications';

const NotifyRecipients = ({ offer, recipient = null, processing, open, onClose, onSend }) => {
  const [subject, setSubject] = useState({ value: '', error: '' });
  const [recipientsType, setRecipientsType] = useState('');
  const [message, setMessage] = useState('');
  const notificationsProps = { subject, notify: true, message, recipientsType, ...!recipient && { offer }, disabled: processing };

  const handleEntering = () => {
    setSubject({ value: offer?.headline || '', error: '' });
    !recipient && setRecipientsType('all');
  };
  const handleExited = () => {
    setSubject({ value: '', error: '' });
    setMessage('');
    setRecipientsType('');
  };
  const handleSendClick = () => { 
    let hasError = false;

    if (!subject.value) {
      setSubject(prev => ({ ...prev, error: `Subject can't be empty` }));
      hasError = true;
    }

    if (hasError) return;

    onSend(recipient?.id, recipientsType, subject.value, message);
  };
  const handleNotificationsChange = ({ s, m, r }) => {
    if (!_isUndefined(s)) setSubject(s);
    if (!_isUndefined(m)) setMessage(m);
    if (!_isUndefined(r)) setRecipientsType(r);
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{ sx: { maxWidth: 780, width: '100%' } }}
      onBackdropClick={e => e.stopPropagation()}
      TransitionProps={{ onExited: handleExited, onEntering: handleEntering }}
      title={!!recipient ? `Sending email notification to ${recipient?.firstName} ${recipient?.lastName}` : 'Bulk sending Offering email notification'}
      actions={
        <>
          <Button
            disabled={processing}
            color='error'
            onClick={onClose}
            sx={{ fontSize: '12px', px: '28px' }}
          >
            Cancel
          </Button>
          <Button
            disabled={processing}
            variant='contained'
            onClick={handleSendClick}
            sx={{ fontSize: '12px', px: '28px' }}
          >
            Send email now
          </Button>
        </>
      }
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: '16px !important', gap: '16px' }}>
        <Notifications
          {...notificationsProps}
          selectLabel='Recipients'
          onChange={handleNotificationsChange}
        />
      </DialogContent>
    </BaseDialog>
  );
};

NotifyRecipients.propTypes = {
  offer: PropTypes.shape({
    headline: PropTypes.string
  }),
  recipient: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <NotifyRecipients {...props} />
    </ErrorBoundary>
  );
};
export const Fallback = () => {
  return (
    <BaseDialog
      open={false}
      onClose={() => {}}
    />
  );
};

export default memo(WrappedComponent);