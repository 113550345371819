import { memo, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
// Local files
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useApp from 'hooks/useApp';
import useContacts from 'hooks/useContacts';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useInvitations from 'hooks/useInvitations';
import useSuccess from 'hooks/useSuccess';

const Invitation = () => {
  const navigate = useNavigate();
  const { openGroupInviteDialog, setAuthData } = useApp();
  const { getContacts, setContactsFilters } = useContacts();
  const { setError } = useError();
  const { acceptInvitation, getInvitation } = useInvitations();
  const { pathname } = useLocation();
  const { invitation_token } = useParams();
  const { setSuccess } = useSuccess();
  const localSession = useCustomSelector(state => state.sessions.session);

  useEffect(() => {
    if (invitation_token && localSession.checked) {
      if (localSession.userableType === 'user') {
        getInvitation(invitation_token)
        .then(({ payload: { data: { invitation } } }) => {
          const isGroupInvitation = invitation.invitation_type === 'group';
          const isContactInvitation = invitation.invitation_type === 'contact';
          const isInvitationFromPush = pathname === `/p/${invitation_token}`;
          const redUrl = isGroupInvitation ? `/groups/${invitation.invitationable.id}/dealspaces` : (isContactInvitation ? `/users/${invitation.invitationable.username}` : '/dashboard');

          if (isInvitationFromPush) {
            if (isContactInvitation) {
              setContactsFilters({ tab: 'pending', pending: true, member: false }).then(() => navigate('/contacts'));
            } else if (isGroupInvitation) {
              openGroupInviteDialog(invitation).then(() => navigate('/dashboard'));
            } else {
              navigate('/dashboard');
            }
          } else {
            setAuthData({ redirectUrl: redUrl })
            .then(() =>
              acceptInvitation({ invitation_token })
              .then(() =>
                setSuccess('Invite successfully accepted')
                .then(() => {
                  if (isContactInvitation) {
                    getContacts({ limit: 1 })
                    .then(({ payload: { data: { contacts } } }) => {
                      const inviter = `${contacts[0].user.first_name} ${contacts[0].user.last_name}`;
            
                      setSuccess(`You are now connected to ${inviter}!`)
                      .then(() =>
                        setAuthData({ open: false, email: '', password: '', recipientToken: '', invitationToken: '' })
                        .then(() => navigate(redUrl))
                      );
                    })
                    .catch(e => setError(e).then(() => navigate(redUrl)));
                  } else {
                    setAuthData({ open: false, email: '', password: '', recipientToken: '', invitationToken: '' })
                    .then(() => navigate(redUrl))
                  }
                })
              )
              .catch(e => setError(e))
            ); 
          }
        })
        .catch(e => setError(e));
      } else {
        getInvitation(invitation_token)
        .then(({ payload: { data: { invitation } } }) => {
          const redirectUrl = invitation.invitation_type === 'group' ? `/groups/${invitation.invitationable.id}/dealspaces` : '/dashboard';
  
          setAuthData({
            mode: invitation.user?.email ? 'signIn' : 'signUp',
            open: true,
            invitationToken: invitation_token,
            email: invitation.email ?? invitation.user.email,
            redirectUrl
          });
        })
        .catch(e => e.response?.status === 404 ? setAuthData({ open: true }) : setError(e));
      }
    }
  }, [// eslint-disable-line
    acceptInvitation, getContacts, getInvitation, invitation_token, localSession, openGroupInviteDialog, pathname, setAuthData,
    setContactsFilters, setError, setSuccess
  ]);

  return null;
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Invitation {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);