import { memo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import _pick from 'lodash/pick';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import PreviewHeader from 'components/Offers/Preview/Header/Header';
import Offer from 'components/Offers/Preview/Offer/Offer';
import Email from 'components/Offers/Preview/Email/Email';
import FirstMarketPublishOffering from '../FirstMarketPublishOffering/FirstMarketPublishOffering';
import RepeatPublishOffering from '../RepeatPublishOffering/RepeatPublishOffering';
import useChameleon from 'hooks/useChameleon';
import useDocuments from 'hooks/useDocuments';
import useError from 'hooks/useError';
import useImages from 'hooks/useImages';
import useFolders from 'hooks/useFolders';
import useOffers from 'hooks/useOffers';
import useSenders from 'hooks/useSenders';
import useSuccess from 'hooks/useSuccess';

const Preview = ({ open, id = null, offer = null, onClose }) => {
  const previousLocation = useLocation();
  const navigate = useNavigate();
  const { track: trackChameleon } = useChameleon();
  const { getPreviewPublicDocuments, getBuilderPublicDocuments } = useDocuments();
  const { setError } = useError();
  const { getPreviewImages, getBuilderImages } = useImages();
  const { getPreviewPublicFolders, getBuilderPublicFolders } = useFolders();
  const { publishOffer, getOfferPreview } = useOffers();
  const { getPreviewSenders, getBuilderSenders } = useSenders();
  const { setSuccess } = useSuccess();
  const [tab, setTab] = useState('offering');
  const [localOffer, setLocalOffer] = useState(null);
  const [localSenders, setLocalSenders] = useState([]);
  const [localImages, setLocalImages] = useState([]);
  const [localFiles, setLocalFiles] = useState([]);
  const [localFolders, setLocalFolders] = useState([]);
  const [firstPublishOfferingDialogOpen, setFirstPublishOfferingDialogOpen] = useState(false);
  const [repeatPublishOfferingDialogOpen, setRepeatPublishOfferingDialogOpen] = useState(false);
  const [procesing, setProcessing] = useState(false);
  const [fetching, setFetching] = useState(true);
  const offerProps = { offer: localOffer, senders: localSenders, images: localImages, publicFiles: localFiles, publicFolders: localFolders, onClose };
  const emailProps = { offer: localOffer, senders: localSenders, images: localImages, logo: localOffer?.logo ?? null };
  const firstPublishOfferingDialog = {
    open: firstPublishOfferingDialogOpen,
    ..._pick(offer, ['id'])
  };
  const repeatPublishOfferingDialog = {
    open: repeatPublishOfferingDialogOpen,
    ..._pick(offer, ['id', 'public', 'name', 'version', 'headline', 'offer'])
  };

  const handleEntering = () => {
    if (offer) {
      setLocalOffer(offer);
      /*** Получаем изображения ***/
      getBuilderImages({ offer_id: offer.id, offset: 0 })
      .then(({ payload: { data: { images } } }) => setLocalImages(images))
      .catch(e => setError(e));
      /*** Получаем открытые файлы ***/
      getBuilderPublicDocuments({ folder_id: offer.root_folder.id, offset: 0 })
      .then(({ payload: { data: { documents } } }) => setLocalFiles(documents))
      .catch(e => setError(e));
      /*** Получаем открытые папки ***/
      getBuilderPublicFolders({ folder_id: offer.root_folder.id, offset: 0 })
      .then(({ payload: { data: { folders } } }) => setLocalFolders(folders))
      .catch(e => setError(e));
      /*** Получаем сендеров ***/
      getBuilderSenders({ offer_id: offer.id, offset: 0 })
      .then(({ payload: { data: { senders } } }) => setLocalSenders(senders))
      .catch(e => setError(e));
      setFetching(false);
    } else {
      setFetching(true);
      getOfferPreview(id)
      .then(({ payload: { data } }) => {
        setLocalOffer(data.offer);
        setFetching(false);
        getPreviewPublicDocuments({ folder_id: data.offer.root_folder.id, offset: 0 })
        .then(({ payload: { data } }) => setLocalFiles(prev => [...prev, ...data.documents]))
        .catch(e => setError(e));
        getPreviewPublicFolders({ folder_id: data.offer.root_folder.id, offset: 0 })
        .then(({ payload: { data } }) => setLocalFolders(prev => [...prev, ...data.folders]))
        .catch(e => setError(e));
        getPreviewImages({ offer_id: id, offset: 0 })
        .then(({ payload: { data } }) => setLocalImages(data.images))
        .catch(e => setError(e));
        getPreviewSenders({ offer_id: id, offset: 0 })
        .then(({ payload: { data } }) => setLocalSenders(data.senders))
        .catch(e => setError(e));
      })
      .catch(e => setError(e))
      .finally(() => setProcessing(false));
    }
  };
  const handleExited = () => {
    setLocalOffer(null);
    setLocalFiles([]);
    setLocalImages([]);
    setLocalSenders([]);
  };
  const handleChangeView = (_, value) => {
    if (value) setTab(value);
  };
  const handleSave = () => {
    if (offer.version === 1) {
      if (offer.public) {
        setFirstPublishOfferingDialogOpen(true);
      } else {
        setProcessing(true);
        publishOffer({ id: offer.id })
        .then(() => {
          trackChameleon('Private offering saved');
          setSuccess('Your Private Offering is now Active and ready to be shared.')
          .then(() => navigate(`/offerings/${offer.id}/activity_log`, { state: { previousLocation }, replace: true }));
        })
        .catch(e => setError(e))
        .finally(() => setProcessing(false));
      }
    } else {
      setRepeatPublishOfferingDialogOpen(true);
    }
  };
  const handleFirstPublishDialogClose = () => {
    setFirstPublishOfferingDialogOpen(false);
  };
  const handleRepeatPublishDialogClose = () => {
    setRepeatPublishOfferingDialogOpen(false);
  };
  const handleFirstPublishDialogSubmit = () => {
    setFirstPublishOfferingDialogOpen(false);
    navigate(`/offerings/${offer.id}/activity_log`, { state: { previousLocation, public: offer.public }, replace: true });
  };
  const handleRepeatPublishDialogSubmit = () => {
    setRepeatPublishOfferingDialogOpen(false);
    navigate(`/offerings/${offer.offer ? offer.offer.id : offer.id}/activity_log`, { state: { previousLocation }, replace: true });
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      hideCloseButton
      fullScreen
      TransitionProps={{ onExited: handleExited, onEntering: handleEntering }}
    >
      <FirstMarketPublishOffering
        {...firstPublishOfferingDialog}
        onClose={handleFirstPublishDialogClose}
        onPublish={handleFirstPublishDialogSubmit}
      />
      <RepeatPublishOffering
        {...repeatPublishOfferingDialog}
        onClose={handleRepeatPublishDialogClose}
        onPublish={handleRepeatPublishDialogSubmit}
      />
      {localOffer && !fetching ?
        <>
          <PreviewHeader
            id={id}
            disabled={procesing}
            name={localOffer.name}
            version={localOffer.version}
            tab={tab}
            onChangeTab={handleChangeView}
            onClose={onClose}
            onSave={handleSave}
          />
          {tab === 'offering' && <Offer {...offerProps} />}
          {tab === 'email' && <Email {...emailProps} />}
        </> : <Skeleton animation='wave' width='100%' height='100%' />
      }
    </BaseDialog>
  );
};

Preview.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.string,
  offer: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    version: PropTypes.number,
    headline: PropTypes.string,
    public: PropTypes.bool,
    locked: PropTypes.bool,
    saved_dealspace: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }),
    tracked: PropTypes.bool,
    status: PropTypes.string,
    lease_period: PropTypes.string,
    loan_ratio: PropTypes.string,
    loan_percent: PropTypes.number,
    investment_type: PropTypes.string,
    price_cents: PropTypes.number,
    custom_price: PropTypes.string,
    main_location: PropTypes.shape({
      address: PropTypes.string
    }),
    deadline_at: PropTypes.string,
    deadline_type: PropTypes.string,
    offer: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  onClose: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Preview {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);