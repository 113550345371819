import {
  getTemplatesRoutine,
  getTemplateRoutine,
  createTemplateRoutine,
  updateTemplateRoutine,
  deleteTemplateRoutine,
  clearLocalTemplatesRoutine,
  clearLocalActiveTemplateRoutine,
  setConfiguredTemplateRoutine
} from 'actions';
import _filter from 'lodash/filter';
import _map from 'lodash/map';

export const initialState = {
  offer: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  template: { id: '', name: '', boldsign_template_url: '', updated_at: '' },
  configuredTemplateId: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getTemplatesRoutine.SUCCESS: {
      const { data: { templates, pagination } } = action.payload;

      return { ...state, offer: { data: templates, pagination } };
    }
    case getTemplateRoutine.SUCCESS: {
      const { data: { template } } = action.payload;
      const updatedTemplate = { ...state.template, ...template };

      return { ...state, template: updatedTemplate, offer: { ...state.offer, data: _map(state.offer.data, (item) => item.id === template.id ? template : item) } };
    }
    case createTemplateRoutine.SUCCESS: {
      const { data: { template } } = action.payload;

      return { ...state, template: { ...state.template, ...template } };
    }
    case updateTemplateRoutine.SUCCESS: {
      const { response: { status }, template } = action.payload;
      const data = _map(state.offer.data, t => t.id === template.id ? ({ ...t, ...template }) : t);
      const pagination = state.offer.pagination;

      return { ...state, ...(status === 204 && { offer: { data, pagination } }) };
    }
    case deleteTemplateRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const data = _filter(state.offer.data, t => t.id !== id);
      const pagination = { ...state.offer.pagination, count: state.offer.pagination.count - 1, total_count: state.offer.pagination.total_count - 1 };
      const offer = { data, pagination };

      return { ...state, ...(status === 204 && { offer }) };
    }
    case clearLocalTemplatesRoutine.SUCCESS: {
      return { ...state, offer: initialState.offer };
    }
    case clearLocalActiveTemplateRoutine.SUCCESS: {
      return { ...state, template: initialState.template }
    }
    case setConfiguredTemplateRoutine.SUCCESS: {
      const { id } = action.payload;

      return { ...state, configuredTemplateId: id };
    }
    default: {
      return state;
    }
  };
};

export default reducer;