import { memo } from 'react';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { AddressAmount, Image, NewTag, Root, Stack } from './Card.styled';
import image from 'assets/images/deal/default-cover.jpg';
import { ReactComponent as PropertyIcon } from 'assets/icons/company.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location-rounded.svg';
import Team from 'components/Common/DataDisplay/Team/Team';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';

const Card = ({ id, own_view, name, properties_count, main_location, locations_count, tag, members, cover, root_folder }) => {
  const notification = null;

  return (
    <Badge badgeContent={notification} color='success' sx={{ userSelect: 'none' }}>
      <Root component={NavLink} to={`/dealspaces/${id}/${!!properties_count && root_folder ? `files/${root_folder.id}` : 'properties'}`}>
        {!own_view && <NewTag>New!</NewTag>}
        <Image sx={{ backgroundImage: `url('${!!cover ? getRepresentation(cover?.representations, representationVariants.TINY)  : image}')` }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', padding: '10px 16px' }}>
          <Typography variant='subtitle2' noWrap sx={{ color: '#1E1E1E' }}>{name}</Typography>
          <Stack>
            {properties_count > 1 ?
              <>
                <PropertyIcon style={{ flexShrink: 0 }} />
                <Typography variant='caption' color='textSecondary' noWrap sx={{ flexGrow: 1 }}>Properties {properties_count}</Typography>
              </> :
              <>
                {main_location &&
                  <>
                    <LocationIcon style={{ flexShrink: 0 }} />
                    <Typography variant='caption' color='textSecondary' noWrap sx={{ flexGrow: 1 }}>{main_location.address}</Typography>
                    {locations_count > 1 && <AddressAmount>+ {locations_count}</AddressAmount>}
                  </>
                }
              </>
            }
          </Stack>
          <Stack>
            {!!tag && <Chip color='secondary' size='small' label={tag} />}
            {!!members?.length && <Team members={members} sx={{ ml: 'auto' }} />}
          </Stack>
        </Box>
      </Root>
    </Badge>
  );
};

Card.propTypes = {
  id: PropTypes.string.isRequired,
  own_view: PropTypes.object,
  name: PropTypes.string.isRequired,
  properties_count: PropTypes.number.isRequired,
  main_location: PropTypes.shape({
    address: PropTypes.string
  }),
  locations_count: PropTypes.number.isRequired,
  tag: PropTypes.string,
  members: PropTypes.array,
  cover: PropTypes.shape({
    representations: PropTypes.array
  }),
  root_folder: PropTypes.shape({
    id: PropTypes.string.isRequired
  })
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Card {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);