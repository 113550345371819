import { memo } from 'react';
// Local files
import { Root } from './Variant.styled';
import { ReactComponent as SelectedIcon } from 'assets/icons/success-circle-large.svg';

const Variant = ({ src, disabled = false, selected = false, onClick }) => {

  return (
    <Root
      disabled={disabled}
      onClick={onClick}
      disableRipple
      sx={{
        backgroundImage: !selected ? `url('${src}')` : `linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url('${src}')`
      }}
    >
      {selected && <SelectedIcon />}
    </Root>
  );
};

export default memo(Variant);