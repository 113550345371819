import { memo } from 'react';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';
import { CardStyled } from './MessagesSkeleton.styled';
import _map from 'lodash/map';

const ContentSkeleton = () => {
  const count = 8;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {_map(Array(count), (_, index) => 
        <CardStyled sx={{ ...index % 2 === 0 && { marginLeft: '50%' } }}>
          <CardHeader
            title={
              <Skeleton
                animation="wave"
                height={10}
                width="80%"
                style={{ marginBottom: 6 }}
              />
            }
            subheader={<Skeleton animation="wave" height={10} width="40%" />}
          />
        </CardStyled> 
      )}
    </Box>
  )
};

export default memo(ContentSkeleton);