import { memo } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as MuiMobileTimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { toIsoString } from 'helpers';

const TimePicker = ({ fullWidth = false, sx, margin = 'none', label, processing, error, helperText = undefined, value, onChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiMobileTimePicker
        disabled={processing}
        label={label}
        value={value === '' ? null : value}
        onChange={(newValue, context) => {
          onChange({
            value: !!newValue && !context?.validationError ? toIsoString(new Date(newValue)) : '',
            formattedValue: newValue,
            error: newValue?.toString() === 'Invalid Date' || context?.validationError ? 'Date is invalid' : ''
          })
        }}
        renderInput={p => <TextField error={error} fullWidth={fullWidth} sx={sx} helperText={helperText} margin={margin} {...p} />}
      />
    </LocalizationProvider>
  );
};

TimePicker.propTypes = {
  fullWidth: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  margin: PropTypes.string,
  label: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string, PropTypes.object
  ]),
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <TimePicker {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);