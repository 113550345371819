import { memo, useMemo, useRef, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
// Local files
import { Container, Image, Content, PrimaryButton } from './Authorization.styled';
import { ReactComponent as QwincyText } from 'assets/images/qwincy-tm.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check_email.svg';
import { ReactComponent as AuthLogo } from 'assets/icons/authLogo.svg';
import qwincylogo from 'assets/images/logo-with-gradient.png';
import AudioPlayer from 'components/Common/AudioPlayer/AudioPlayer';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import Switcher from 'components/Auth/Switcher/Switcher';
import BackButton from 'components/Auth/BackButton/BackButton';
import LinkedIn from 'components/Auth/LinkedIn/LinkedIn';
import BuilderErrorLabel from 'components/Common/DataDisplay/BuilderErrorLabel/BuilderErrorLabel';
import BuilderSuccessLabel from 'components/Common/DataDisplay/BuilderSuccessLabel/BuilderSuccessLabel';
import { linkedInClientId, linkedInRedirectUri } from 'apis';
import { checkEmptyString, checkValidEmail } from 'helpers';
import useApp from 'hooks/useApp';
import useConfirmations from 'hooks/useConfirmations';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useSessions from 'hooks/useSessions';
import useUsers from 'hooks/useUsers';

const Authorization = () => {
  const audioRef = useRef(null);
  const navigate = useNavigate();
  const { setAuthData } = useApp();
  const { sendConfirmationCode, validateConfirmationCode } = useConfirmations();
  const { setError } = useError();
  const { createSession } = useSessions();
  const { createUser, changeUserPassword, validateUser, validateUserEmail } = useUsers();
  const {
    open, mode, recipientToken, firstName, lastName, email, password, repeatPassword, confirmationCode, codeSended, codeValidated,
    linkedInCode, hasRecipientSession, isQwincyMember
  } = useCustomSelector(state => ({
    ...state.app.authDialog,
    hasRecipientSession: !!state.profile.recipient.id,
    isQwincyMember: !!state.profile.recipient.user?.email
  }));
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const [processing, setProcessing] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState('pending');
  const { linkedInLogin } = useLinkedIn({
    clientId: linkedInClientId,
    redirectUri: linkedInRedirectUri,
    onSuccess: (code) => handleSignInClick(code),
    onError: (error) => console.log(error),
    scope: 'r_liteprofile r_emailaddress'
  });
  const showBackButton = mode === 'forgotPassword';
  const showCheckIcon = mode !== 'signIn' && codeSended;
  const showSubtitle = showCheckIcon;
  const showSignUpFields = mode === 'signUp' && !linkedInCode && !codeSended;
  const showEmail = !linkedInCode && !codeSended;
  const showPassword = useMemo(() => {
    if (!linkedInCode) {
      if (mode === 'signIn') return true;
      if (mode === 'signUp') return !codeSended;
      if (mode === 'forgotPassword') return codeValidated;
    }
    return false;
  }, [codeSended, codeValidated, linkedInCode, mode]);
  const showRepeatPassword = useMemo(() => {
    if (!linkedInCode) {
      if (mode === 'signIn') return false;
      if (mode === 'signUp') return false;
      if (mode === 'forgotPassword') return codeValidated;
    }
    return false;
  }, [codeValidated, linkedInCode, mode]);
  const showConfirmationCode = codeSended && !codeValidated && mode !== 'signIn';
  const showLinkedIn = !linkedInCode && mode !== 'forgotPassword' && !codeSended;
  const showForgotPassword = mode === 'signIn';
  const disabled = useMemo(() => {
    if (processing) return true;
    if (mode === 'signIn') {
      return !email || !password;
    }
    if (mode === 'signUp') {
      if (codeSended) return !confirmationCode;
      return !firstName || !lastName || !email || !password;
    }
    if (mode === 'forgotPassword' && codeSended && password !== repeatPassword) return true;
    return false;
  }, [confirmationCode, email, firstName, lastName, codeSended, mode, password, processing, repeatPassword]);
  const title = useMemo(() => {
    if (mode === 'signIn') return 'Sign In to Qwincy';
    if (mode === 'signUp') {
      if (codeSended) return 'Check your email';
      return 'Sign Up for Free';
    }
    if (mode === 'forgotPassword') {
      if (codeSended) return 'Check your email';
      return 'Reset your password';
    }
    return '';
  }, [mode, codeSended]);

  const handleEntering = () => {
    if (hasRecipientSession && !isQwincyMember) setAuthData({ mode: 'signUp' });
  };
  const handleExited = () => {
    setProcessing(false);
    setVerificationStatus('pending');
  };
  const handleKeyDown = ({ key }) => key === 'Enter' && handleSignInClick();
  const handleSignInClick = linkedInCode => {
    setProcessing(true);

    if (!!linkedInCode) setAuthData({ linkedInCode });

    const session = { ...linkedInCode ? { linkedin_code: linkedInCode } : { email, password } };

    createUserSession(session);
  };
  const createUserSession = session => {
    createSession(session)
    .then(() => setProcessing(false))
    .catch(e => {
      setProcessing(false);
      if (!!linkedInCode) {
        setAuthData({ mode: 'signUp' });
      } else {
        setError(e, true);
      }
    });
  };
  const handleBackClick = () => setAuthData({ mode: 'signIn', codeSended: false });
  const handleSignUpClick = () => {
    const user = { ...!linkedInCode && { email, first_name: firstName, last_name: lastName }, password };

    setProcessing(true);
    if (codeSended || !!linkedInCode) {
      if (!!linkedInCode) {
        setUpUser(user);
      } else {
        validateConfirmationCode({ email, confirmation_code: confirmationCode })
        .then(() => setUpUser(user))
        .catch(e => setError(e).then(() => setProcessing(false)));
      }
    } else {
      validateUser({ user, validation_attributes: ['first_name', 'last_name', 'email', 'password'] })
      .then(() =>
        sendConfirmationCode(email)
        .then(() => setAuthData({ codeSended: true }))
        .catch(e => setError(e))
        .finally(() => setProcessing(false))   
      )
      .catch(e => setError(e).then(() => setProcessing(false)));
    }
  };
  const setUpUser = user => {
    createUser({ 
      user,
      ...(!!recipientToken && { recipient_token: recipientToken }), 
      ...(!!linkedInCode ? { linkedin_code: linkedInCode } : { confirmation_code: confirmationCode })
    })
    .then(() => handleSignInClick(linkedInCode))
    .catch(e => setError(e).then(() => setProcessing(false)));
  };
  const handleChangePasswordClick = () => {
    setProcessing(true);

    if (!codeSended) {
      sendConfirmationCode(email)
      .then(() => setAuthData({ codeSended: true }))
      .catch(e => setError(e))
      .finally(() => setProcessing(false))   
    } else {
      if (!codeValidated) {
        validateConfirmationCode({ email, confirmation_code: confirmationCode })
        .then(() => setAuthData({ codeValidated: true }))
        .catch(e => setError(e))
        .finally(() => setProcessing(false))  ;
      } else {
        changeUserPassword({ email, password, confirmation_code: confirmationCode })
        .then(() => setAuthData({ codeSended: false, codeValidated: false, mode: 'signIn' }))
        .catch(e => setError(e))
        .finally(() => setProcessing(false));
      }
    }
  };
  const handleEmailBlur = e => {
    if (mode !== 'signUp') {
      return;      
    }
    if (!checkEmptyString(e) || !checkValidEmail(e)) {
      setVerificationStatus('error');
      return;
    }
    validateUserEmail(e)
    .then(() => setVerificationStatus('verified'))
    .catch(e => {
      if (e.response?.data?.error?.type === 'user_already_exists') {
        setVerificationStatus('exist');
        setTimeout(() => {
          setAuthData({ mode: 'signIn', codeSended: false });
          setVerificationStatus('pending');
        }, 3000);
        return;
      }
      setError(e).then(() => setVerificationStatus('error'));
    });
  };
  const handleClose = () => setAuthData({ open: false }).then(() => {
    if (!hasRecipientSession) {
      navigate('/');
    }
  });

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      maxWidth={false}
      TransitionProps={{ onExited: handleExited, onEntering: handleEntering }}
      otherCloseButtonStyle={!mediaDesktop ? { color: '#fff' } : {}}
      otherPaperStyle={!mediaDesktop ? { borderRadius: 0 } : {}}
      fullScreen={!mediaDesktop}
    >
      <Container>
        {mediaDesktop &&
          <Image>
            <img src={qwincylogo} alt='logo' />
            <Typography variant='body2' pt='22px'>Real Estate Deal Sharing. <b>Reinvented.</b></Typography>
            <QwincyText style={{ position: 'absolute', bottom: 15 }} />
          </Image>
        }
        <Content>
          {showBackButton && <BackButton onClick={handleBackClick} />}
          {showCheckIcon && <CheckIcon style={{ marginBottom: '22px' }} />}
          {mode === 'forgotPassword' ?
            <Typography variant='subtitle2' align='center' pb={showSubtitle ? '13px' : '60px'}>
              {title}
            </Typography> :
              (codeSended && !linkedInCode) ?
              <Typography fontWeight={600} fontSize='18px' lineHeight='20px' align='center' mb='20px'>
                Check your email
              </Typography>  :
              <Switcher value={mode} onClick={v => setAuthData({ mode: v })} />
          }
          {showSubtitle &&
            <Typography fontWeight={400} fontSize='12px' lineHeight='20px' textAlign='center'>
              Please check your email to verify your account and sign in. If you don’t receive it in the next five minutes, please check your spam folder.
            </Typography>
          }
          {showSignUpFields &&
            <Box sx={{ display: 'flex', gap: '8px', width: '100%' }}>
              <TextField
                label='First Name'
                value={firstName}
                onChange={({ target: { value } }) => setAuthData({ firstName: value })}
                fullWidth
                margin='normal'
              />
              <TextField
                label='Last Name'
                value={lastName}
                onChange={({ target: { value } }) => setAuthData({ lastName: value })}
                fullWidth
                margin='normal'
              />
            </Box>
          }
          {showEmail &&
            <Box width='100%'>
              <TextField
                label='Email Address'
                value={email}
                onChange={({ target: { value } }) => setAuthData({ email: value })}
                fullWidth
                margin='normal'
                onKeyDown={handleKeyDown}
                onBlur={({ target: { value } }) => handleEmailBlur(value)}
              />
              {mode === 'signUp' && verificationStatus === 'error' && <BuilderErrorLabel value='Invalid email' />}
              {mode === 'signUp' && verificationStatus === 'verified' && <BuilderSuccessLabel value='Email verified' />}
              {mode === 'signUp' && verificationStatus === 'exist' && <BuilderSuccessLabel value='You already have an account! Please login' />}
            </Box>
          }
          {showPassword &&
            <TextField
              label='Password'
              value={password}
              onChange={({ target: { value } }) => setAuthData({ password: value })}
              fullWidth
              type='password'
              margin='normal'
              onKeyDown={handleKeyDown}
            />
          }
          {showRepeatPassword &&
            <TextField
              label='Repeat Password'
              value={repeatPassword}
              onChange={({ target: { value } }) => setAuthData({ repeatPassword: value })}
              fullWidth
              type='password'
              margin='normal'
              onKeyDown={handleKeyDown}
            />
          }
          {showConfirmationCode &&
            <TextField
              label='Confirmation code'
              value={confirmationCode}
              onChange={({ target: { value } }) => setAuthData({ confirmationCode: value })}
              fullWidth
              sx={{ mt: '30px', mb: 0 }}
              margin='normal'
              inputProps={{ maxLength: 5 }}
            />
          }
          {showForgotPassword &&
            <Typography
              fontWeight={400}
              fontSize='10px'
              lineHeight='20px'
              color='#616161'
              sx={{ mt: 1, cursor: 'pointer', alignSelf: 'self-start' }}
              onClick={() => setAuthData({ mode: 'forgotPassword' })}
            >
              Forgot your password?
            </Typography>
          }
          <Box flexGrow={1} />
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', py: '30px' }}>
            {mode === 'signIn' &&
              <PrimaryButton
                fullWidth
                disabled={disabled}
                onClick={() => handleSignInClick()}
              >
                Sign In
              </PrimaryButton>
            }
            {mode === 'signUp' &&
              <PrimaryButton disabled={disabled} onClick={handleSignUpClick}>
                {!!linkedInCode && 'Sign Up'}
                {codeSended && !linkedInCode ? 'Verify my account' : 'Continue with email'}
              </PrimaryButton>
            }
            {mode === 'forgotPassword' &&
              <>
                <PrimaryButton disabled={processing} onClick={handleChangePasswordClick}>
                  {codeSended ? (codeValidated ? 'Change password' : 'Verify my code') : 'Send a password reset email'}
                </PrimaryButton>
              </>
            }
          </Box>
          {showLinkedIn && <LinkedIn onClick={linkedInLogin} />}
          {mode === 'signUp' &&
            <Typography fontWeight={400} fontSize='9px' lineHeight='20px' textAlign='center' sx={{ mt: '56px' }}>
              By signing up to Qwincy, you agree to the&nbsp;
              <Link href='https://www.qwincy.com/terms-of-service' target='_blank' rel='noreferrer'>Terms of Service</Link>
              &nbsp;and&nbsp;
              <Link href='https://www.qwincy.com/privacy-policy' target='_blank' rel='noreferrer'>Privacy Policy</Link>
            </Typography>
          }
          {!mediaDesktop &&
            <Box marginTop='35px'>
              <AuthLogo />
            </Box>
          }
          <AudioPlayer ref={audioRef} />
        </Content>
      </Container>
    </BaseDialog>
  );
};

const ErrorFallback = ({ error }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Authorization {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);