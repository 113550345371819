import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

export const Content = styled(Box)(({ theme }) => ({
  height: 140,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: '8px 12px',
  [theme.breakpoints.up('md')]: {
    height: 280 - 120,
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));
export const Map = styled(Box)(({ theme }) => ({
  width: 120,
  height: 132,
  backgroundColor: theme.palette.grey['300'],
  overflow: 'hidden',
  borderRadius: '6px',
  '& > img': {
    objectFit: 'center',
    height: 'inherit',
    width: 'inherit',
  },
  [theme.breakpoints.up('md')]: {
    height: 200,
    width: 210,
    borderRadius: '6px 6px 0 0'
  }
}));
export const MapContainer = styled(Box)(({ theme }) => ({
  padding: '4px',
  [theme.breakpoints.up('md')]: {
    width: '100%',
    padding: '4px 4px 0'
  }
}));
export const MenuButton = styled(IconButton)({
  width: 30,
  height: 30,
  position: 'absolute',
  top: 4,
  right: 4
});
export const Root = styled(Box)(({ theme }) => ({
  height: 140,
  width: 220,
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '6px',
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    height: 280,
    flexDirection: 'column',
    justifyContent: 'flex-start'
  }
}));
export const Text = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  display: ' -webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 4,
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    WebkitLineClamp: 3
  }
}));