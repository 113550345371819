import styled from '@mui/material/styles/styled';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';


export const IconContainer = styled(Box)({
  height: 18,
  width: 18,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledButton = styled(ButtonBase)({
  height: 40,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#295CDF',
  gap: '2px',
  background: '#FAFBFE',
  border: '1px solid #E5E8F1',
});