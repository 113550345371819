import {
  getDealspaceImagesRoutine,
  getOfferImagesRoutine,
  getBuilderImagesRoutine,
  getPreviewImagesRoutine,
  getImageRoutine,
  createDealspaceDocumentRoutine,
  createOfferImageRoutine,
  deleteImageRoutine,
  mainImageRoutine,
  clearLocalDealspaceImagesRoutine,
  clearLocalOfferImagesRoutine,
  clearLocalActiveImageRoutine
} from 'actions';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';

export const initialState = {
  dealspace: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  offer: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 }, fetching: true },
  image: { id: '', file: null }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getDealspaceImagesRoutine.SUCCESS: {
      const { data: { documents, pagination } } = action.payload;

      return { ...state, dealspace: { data: [...state.dealspace.data, ...documents], pagination } };
    }
    case getOfferImagesRoutine.REQUEST: {
      const offer = { ...state.offer, fetching: true };

      return { ...state, offer };
    }
    case getOfferImagesRoutine.SUCCESS: {
      const { data: { images, pagination } } = action.payload;

      return {...state, offer: { data: [...state.offer.data, ...images], pagination, fetching: false } };
    }
    case getOfferImagesRoutine.FAILURE: {
      const offer = { ...state.offer, fetching: false };

      return { ...state, offer };
    }
    case getBuilderImagesRoutine.SUCCESS: {
      return state;
    }
    case getPreviewImagesRoutine.SUCCESS: {
      return state;
    }
    case getImageRoutine.SUCCESS: {
      const { data: { image } } = action.payload;
      const updatedImage = { ...state.image, ...image };

      return { ...state, image: updatedImage };
    }
    case createDealspaceDocumentRoutine.SUCCESS: {
      const { response: { data: { document } }, skip } = action.payload;
      
      if (skip) return state;
      const oldDealspaceData = state.dealspace.data; 
      const data = [document, ...oldDealspaceData];
      const pagination = { ...state.dealspace.pagination, count: state.dealspace.pagination.count + 1, total_count: state.dealspace.pagination.total_count + 1 };

      return { ...state, dealspace: { data, pagination } };
    }
    case createOfferImageRoutine.SUCCESS: {
      return state;
    }
    case deleteImageRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const foundOfferImage = !!_find(state.offer.data, d => d.id === id);

      let data = [];
      let pagination = {};

      if (foundOfferImage) {
        data = _filter(state.offer.data, item => item.id !== id);
        pagination = { ...state.offer.pagination, count: state.offer.pagination.count - 1, total_count: state.offer.pagination.total_count - 1 };
      }
      const offer = foundOfferImage ? { data, pagination } : state.offer;

      return { ...state, ...(status === 204 && { offer }) };
    }
    case mainImageRoutine.SUCCESS: {
      const { id } = action.payload;
      const data = _map(state.offer.data, d => ({ ...d, main: d.id === id }));

      return { ...state, offer: { ...state.offer, data } };
    }
    case clearLocalDealspaceImagesRoutine.SUCCESS: {
      return { ...state, dealspace: initialState.dealspace };
    }
    case clearLocalOfferImagesRoutine.SUCCESS: {
      return { ...state, offer: initialState.offer };
    }
    case clearLocalActiveImageRoutine.SUCCESS: {
      return { ...state, image: initialState.image };
    }
    default: {
      return state;
    }
  };
};

export default reducer;