import { createTheme } from '@mui/material/styles';

//Plus Jakarta Sans: ExtraLight 200, Regular 400, Medium 500, SemiBold 600, Bold 700
// mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'))

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 990,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: '"Plus Jakarta Sans", sans-serif',
    h5: {
      //font-size: 24px
      fontWeight: 600,
    },
    subtitle0: {
      fontSize: '1.125rem', //18px
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 600,
    },
    subtitle2: {
      fontWeight: 600,
    },
    subtitle3: {
      fontSize: '0.75rem',
      fontWeight: 600,
    },
    small: {
      fontSize: '0.625rem',
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      light: '#383838',
      main: '#252B30',
    },
    secondary: {
      light: '#6C95FF',
      main: '#4670C3',
      //light: 'rgba(0,44,202,0.03)',
      //main: 'rgba(72,119,240,0.12)',
      contrastText: '#fff'
    },
    tertiary: {
      light: '#F7F8FD',
      main: '#E9EFFD',
      contrastText: '#7E889D',
    },
    neutral: {
      main: 'rgba(0,0,0,.65)',
      contrastText: '#D8DADC',
    },
    error: {
      main: '#E30000',
    },
    warning: {
      main: '#CE7C00'
    },
    success: {
      main: '#00CC76',
    },
    background: {
      default: '#F1F2F5',
      surface: '#F3F5F8',
      surfaceDark: '#232424',
      active: 'linear-gradient(151.67deg, #F1F7FF 1.95%, #D9E7FC 62.89%, #E7F0FE 101.95%)',
      hoverDark: 'rgba(248, 248, 248, 0.1)',
      error: 'rgba(246,204,204,0.6)',
    },
    border: {
      error: 'rgba(227, 161, 161, 1)',
    },
    text: {
      primary: 'rgba(0,0,0,.7)',
      secondary: '#606060',
      hint: '#9FA2AC',
    },
    grey: {
      500: '#979797',
      700: '#606060',
      A700: '#606060',
    },
    section: {
      chat: {
        primary: '#3786F4',
        background: {
          dark: '#2C2C2C',
          main: '#45464C',
          light: '#4F5056',
        },
        text: {
          main: '#AAAEB2',
        }
      },
      table: {
        background: '#F3F5F8',
        backgroundGlass: 'rgba(243, 245, 248, 0.5)',
        hover: '#F7F8F9',
        border: '#DDE2ED',
        actionDisabled: '#E1E1E1',
      },
      offer: {
        background: {
          default: '#FAFBFC',
        }
      },
      activity: {
        emailOpens: '#4D7CF6',
        emailHardBounces: '#DF503C',
        emailSoftBounces: '#F1CD47',
        visits: '#5E5ACE',
        caSigned: '#83D473',
        replies: '#DF4859',
        tracking: '#5AABE7',
        saved: '#F1CD47',
        offers: '#E79A37',
      }
    },
  },
  components: {
    MuiInput: {
      defaultProps: {
        //disableUnderline: true
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
        notchedOutline: {
          borderRadius: '8px',
         // borderColor: 'rgba(0,0,0,1)'
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        colorSuccess: {
          color: '#fff',
          fontWeight: 600,
        },
        colorError: {
          border: '1px solid #fff'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        colorError: {
          border: '1px solid #fff'
        },
        colorSuccess: {
          color: '#fff',
          fontWeight: 600,
        },
        sizeSmall: {
          fontSize: '.69rem',
          height: '22px'
        },
        colorSecondary: {
          color: '#4670C3',
          backgroundColor: 'rgba(72,119,240,0.12)', //tertiary main
        }
      },
      variants: [
        {
          props: { variant: 'default' },
          style: {
            width: 'fit-content',
            color: '#1E1E1E',
            backgroundColor: '#F4F6FB',
            fontWeight: 600,
          }
        },
        {
          props: { variant: 'badge', size: 'small' },
          style: {
            height: 20,
            width: 20,
            fontSize: '10px',
            '& > span': {
              paddingLeft: 2,
              paddingRight: 2,
            }
          }
        },
        {
          props: { color: 'tertiary' },
          style: {
            backgroundColor: 'rgba(0,44,202,0.03)', //tertiary light
          }
        }
        /*{
          props: { color: 'info-light' },
          style: {
            width: 'fit-content',
            main: 'red',
            contrastText: '#1E1E1E',
            backgroundColor: '#F4F6FB',
            fontWeight: 600,
          }
        },*/
      ]
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          '& .MuiAvatar-root': {
            borderColor: '#fff'
          }
        }
      }
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'caption', color: 'text.hint' },
          style: {
            fontWeight: 500,
          }
        }
      ]
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          borderRadius: '8px',
        }
      },
      variants: [
        {
          props: { variant: 'glass' },
          style: {
            background: 'rgba(255, 255, 255, 0.6)',
            border: '1px solid rgba(255,255,255,0.2)',
            backdropFilter: 'blur(20px)',
            WebkitBackdropFilter: 'blur(20px)',
          }
        },
        {
          props: { variant: 'popup', color: 'light' },
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.15)'
          }
        },
        {
          props: { variant: 'popup', color: 'dark' },
          style: {
            color: '#fff',
            backgroundColor: '#252B30',
            borderRadius: '8px',
            boxShadow: '0 4px 10px 0 rgba(0,4,0,.25)',
          }
        },
        {
          props: { variant: 'outlined', color: 'default' },
          style: {
            border: '1px solid',
            borderColor: 'rgba(0, 0, 0, 0.23)', //like notchedOutline input
            backgroundColor: '#F1F2F5', //background.default
            borderRadius: '8px',
            boxShadow: 'none !important',
          }
        },
        {
          props: { variant: 'outlined', color: 'paper' },
          style: {
            border: '1px solid',
            borderColor: 'rgba(0, 0, 0, 0.23)', //like notchedOutline input
            backgroundColor: '#ffffff', //background.paper
            borderRadius: '8px',
            boxShadow: 'none !important',
          }
        },
      ]
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
          boxShadow: '0 4px 10px 0 rgba(0,0,0,.15)',
        },
      },
      variants: [
        {
          props: { variant: 'default' },
          style: {
            background: 'rgba(255,4,4,0.6)',
          }
        }
      ]
    },
    MuiDialog: {
      styleOverrides: {
        paperFullScreen: {
          borderRadius: '0 !important'
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#F4F4F4',
          '&::after': {
            background: 'linear-gradient(90deg, transparent, rgba(0,0,0,0.04), transparent)'
          }
        }
      }
    },
    MuiButtonBase: {
      variants: [
        {
          props: { variant: 'glass' },
          style: {
            background: 'rgba(255, 255, 255, 0.6)',
            backdropFilter: 'blur(20px)',
            WebkitBackdropFilter: 'blur(20px)',
            '&:hover': {
              background: 'rgba(255, 255, 255, 0.6)',
            }
          }
        },
        {
          props: { variant: 'uploader' },
          style: {
            width: '100%',
            background: '#FCFCFC',
            border: '2px dashed #ECECEC',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
              background: '#F4F4F4',
              borderColor: '#EEEEEE',
            }
          }
        },
      ]
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        text: {
          height: 30,
          minWidth: 30,
          borderRadius: '6px',
          fontSize: '0.75rem',
        },
        contained: {
          height: 30,
          minWidth: 30,
          fontWeight: 500,
          fontSize: '0.75rem',
          borderRadius: '6px',
          boxShadow: 'none !important',
          padding: '2px 12px',
        },
        containedPrimary: {
        },
        containedSecondary: {
          backgroundColor: '#5582FF',
          '&:hover, :active': {
            backgroundColor: '#4670C3',
          }
        },
        outlinedPrimary: {
          height: 30,
          minWidth: 30,
          fontWeight: 500,
          fontSize: '0.75rem',
          borderRadius: '6px',
          borderColor: '#252B30',
        }
      },
      variants: [
        {
          props: { variant: 'outlined', color: 'neutral' },
          style: {
            height: 30,
            minWidth: 30,
            fontSize: '0.75rem',
            color: 'rgba(0,0,0,.65)',
            borderColor: '#D8DADC',
            borderRadius: '6px',
            paddingLeft: '5px',
            paddingRight: '5px',
          }
        },
        {
          props: { variant: 'blank', color: 'neutral' },
          style: {
            height: 30,
            minWidth: 30,
            color: 'rgba(0,0,0,.7)',
            borderRadius: '6px',
            paddingLeft: '5px',
            paddingRight: '5px',
          }
        },
        {
          props: { variant: 'blank', color: 'black' },
          style: {
            height: 30,
            minWidth: 30,
            color: 'rgba(0,0,0)',
            borderRadius: '6px',
            paddingLeft: '5px',
            paddingRight: '5px',
          }
        },
        {
          props: { variant: 'soft' },
          style: {
            height: 30,
            minWidth: 30,
            fontSize: '0.75rem',
            color: '#517EF1',
            backgroundColor: '#F2F5FB',
            borderRadius: '6px',
          }
        },
        {
          props: { variant: 'gradient' },
          style: {
            height: 30,
            minWidth: 30,
            fontSize: '0.75rem',
            color: '#fff',
            background: 'linear-gradient(131.52deg, #4473EB 12.56%, #3149CD 98.19%)',
            borderRadius: '6px',
          }
        }
      ]
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#252B30',
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            height: 3,
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '1rem',
          fontWeight: 500,
          color: 'rgba(37, 43, 48, .4)',
          '&.Mui-selected': {
            color: 'rgba(37, 43, 48, 1)',
            fontWeight: 600,
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '0.75rem'
        }
      }
    }
  }
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  }
})

export default theme;