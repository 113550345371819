import {
  checkGroupMemberRoutine,
  getGroupMembersListRoutine,
  getGroupMembersRoutine,
  getDealspaceMembersListRoutine,
  getDealspaceMembersRoutine,
  getMemberRoutine,
  createMemberRoutine,
  updateMemberRoutine,
  deleteMemberRoutine,
  clearLocalGroupMembersListRoutine,
  clearLocalGroupMembersRoutine,
  clearLocalDealspaceMembersListRoutine,
  clearLocalDealspaceMembersRoutine,
  clearLocalActiveMemberRoutine
} from 'actions';
import _dropRight from 'lodash/dropRight';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _uniqBy from 'lodash/uniqBy';

export const initialState = {
  groupList: {
    data: [],
    pagination: { limit: 25, offset: 0, count: 0, total_count: 0 }
  },
  group: {
    data: [],
    pagination: { limit: 25, offset: 0, count: 0, total_count: 0 }
  },
  dealspaceList: {
    data: [],
    pagination: { limit: 25, offset: 0, count: 0, total_count: 0 }
  },
  dealspace: {
    data: [],
    pagination: { limit: 25, offset: 0, count: 0, total_count: 0 }
  },
  member: {
    id: '',
    user: { id: '', first_name: '', last_name: '', email: '' },
    invitation: {},
    dealspaces: [],
    status: '',
    role: '',
    permission: '',
    updted_at: ''
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case checkGroupMemberRoutine.SUCCESS: {
      return state;
    }
    case getGroupMembersListRoutine.SUCCESS: {
      const { data: { members, pagination } } = action.payload;
      
      return { ...state, groupList: { data: [...state.groupList.data, ...members], pagination } };
    }
    case getGroupMembersRoutine.SUCCESS: {
      const { data: { members, pagination } } = action.payload;

      return { ...state, group: { ...state.group, data: members, pagination } };
    }
    case getDealspaceMembersListRoutine.SUCCESS: {
      const { data: { members, pagination } } = action.payload;
      
      return { ...state, dealspaceList: { data: _uniqBy([...state.dealspaceList.data, ...members], 'id'), pagination } };
    }
    case getDealspaceMembersRoutine.SUCCESS: {
      const { data: { members, pagination } } = action.payload;

      return { ...state, dealspace: { data: members, pagination } };
    }
    case getMemberRoutine.SUCCESS: {
      const { data: { member } } = action.payload;
      const updatedMember = { ...state.member, ...member };

      return { ...state, member: updatedMember };
    }
    case createMemberRoutine.SUCCESS: {
      const { data: { member } } = action.payload;
      const oldGroupData = state.group.pagination.total_count > 24 ? _dropRight(state.group.data) : state.group.data;
      const groupData = [member, ...oldGroupData];
      const groupPagination = { ...state.group.pagination, count: state.group.pagination.count + 1, total_count: state.group.pagination.total_count + 1 };
      const group = { data: groupData, pagination: groupPagination };
      const dealspaceListData = [...state.dealspaceList.data, member];
      const dealspaceListPagination = { ...state.dealspaceList.pagination, count: state.dealspaceList.pagination.count + 1, total_count: state.dealspaceList.pagination.total_count + 1 };
      const dealspaceList = { data: dealspaceListData, pagination: dealspaceListPagination };

      return { ...state, group, dealspaceList };
    }
    case updateMemberRoutine.SUCCESS: {
      const { response: { status }, member } = action.payload;
      const foundedGroupMember = _find(state.group.data, m => m.id === member.id);
      const foundedDealspaceMember = _find(state.dealspaceList.data, m => m.id === member.id);
      const groupData = (member.dealspace_ids?.length < foundedGroupMember?.dealspaces?.length) ? 
        _map(state.group.data, m => m.id === member.id ? { ...m, dealspaces: _filter(m.dealspaces, (d) => !!_find(member.dealspace_ids, (id) => d.id === id)) } : m) : 
        _map(state.group.data, m => m.id === member.id ? { ...m, dealspaces: [...member?.newDealspaces] } : m);
      const group = { ...state.group, data: groupData };
      const dealspaceListData = !!foundedDealspaceMember ? _filter(state.dealspaceList.data, m => m.id !== member.id) : state.dealspaceList.data;
      const dealspaceListPagination = !!foundedDealspaceMember
        ? { ...state.dealspaceList.pagination, count: state.dealspaceList.pagination.count - 1, total_count: state.dealspaceList.pagination.total_count - 1 }
        : state.dealspaceList.pagination;
      const dealspaceList = { data: dealspaceListData, pagination: dealspaceListPagination };

      return {
        ...state,
        ...(status === 204 && { group, dealspaceList })
      };
    }
    case deleteMemberRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;
      const groupFound = !!_find(state.group.data, item => item.id === id);
      const dealspaceListFound = !!_find(state.dealspaceList.data, item => item.id === id);
      let group = { ...state.group };
      let dealspaceList = { ...state.dealspaceList };

      if (groupFound) {
        group.data = _filter(state.group.data, (item) => item.id !== id);
        group.pagination = { ...state.group.pagination, count: state.group.pagination.count - 1, total_count: state.group.pagination.total_count - 1 };
      }
      if (dealspaceListFound) {
        dealspaceList.data = _filter(state.dealspaceList.data, (item) => item.id !== id);
        dealspaceList.pagination = { ...state.dealspaceList.pagination, count: state.dealspaceList.pagination.count - 1, total_count: state.dealspaceList.pagination.total_count - 1 };
      }

      return { ...state, ...(status === 204 && { group, dealspaceList }) };
    }
    case clearLocalGroupMembersListRoutine.SUCCESS: {
      return { ...state, groupList: initialState.groupList };
    }
    case clearLocalGroupMembersRoutine.SUCCESS: {
      return { ...state, group: initialState.group };
    }
    case clearLocalDealspaceMembersListRoutine.SUCCESS: {
      return { ...state, dealspaceList: initialState.dealspaceList };
    }
    case clearLocalDealspaceMembersRoutine.SUCCESS: {
      return { ...state, dealspace: initialState.dealspace };
    }
    case clearLocalActiveMemberRoutine.SUCCESS: {
      return { ...state, member: initialState.member };
    }
    default: {
      return state;
    }
  };
};

export default reducer;