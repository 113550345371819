import axios, { reportAllApiKey } from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import { getLocationParcelRoutine } from 'actions';

export const getLocationParcel = getThunkActionCreator(
  getLocationParcelRoutine,
  async ({ longitude, latitude }) => {
    const params = { client: reportAllApiKey, v: 8, spatial_intersect: `POINT(${longitude} ${latitude})`, si_srid: 4326, rpp: 1 };

    return await axios.get(`https://reportallusa.com/api/parcels?`, { params });
  }
);