import axios from 'apis';
import { getThunkActionCreator } from 'redux-thunk-routine';
import {
  getOfferFromRecipientTokenRoutine,
  getActivityLogRecipientsRoutine,
  getSharedRecipientsRoutine,
  getRecipientRoutine,
  createRecipientRoutine,
  updateRecipientRoutine,
  deleteRecipientRoutine,
  notifyRecipientsRoutine,
  clearLocalActivityLogRecipientsRoutine,
  clearLocalSharedRecipientsRoutine
} from 'actions';
import _find from 'lodash/find';
import _omit from 'lodash/omit';

export const getOfferFromRecipientToken = getThunkActionCreator(
  getOfferFromRecipientTokenRoutine,
  async recipient_token => {
    const attributes = [{ offer: ['id'] }];
    const params = { attributes };

    return await axios.get(`/recipients/${recipient_token}`, { params });
  }
);
export const getActivityLogRecipients = getThunkActionCreator(
  getActivityLogRecipientsRoutine,
  async ({ offer_id, limit = 50, offset = 0, orders = { offered_amount_cents: 'asc', approval_required: 'asc', locked: 'asc', created_at: 'desc' }, query = null, filters, ids = null }) => {
    const locked = !!_find(filters, f => f === 'locked');
    const unlocked = !!_find(filters, f => f === 'unlocked');
    const tracked = !!_find(filters, f => f === 'tracked');
    const offered = !!_find(filters, f => f === 'offered');
    const visited = !!_find(filters, f => f === 'visited');
    const email_opened = !!_find(filters, f => f === 'email_opened');    
    
    const attributes = [
      'id',
      { user: ['id', 'username', 'mobile_phone', 'office_phone', 'office_phone_extension', 'email', 'first_name', 'last_name', 'email', 'company_name', 'title', { photo: ['representations'] }] },
      { list: ['name'] },
      { member: ['id', { user: ['first_name', 'last_name'] }] },
      { files: ['id', 'filename', 'url', 'content_type'] },
      'tracked',
      'locked',
      'approved',
      'email',
      'first_name',
      'last_name',
      'notes',
      'property_visits',
      'offered_amount_cents',
      'offered_percent',
      'offered_at',
      'email_opens_count',
      'email_bounce',
      'visits_count',
      'visits_duration',
      'saved',
      'interest_level',
      'created_at',
      'visited_at',
      'unlocked_at'
    ];
    const params = {
      limit,
      offset,
      orders,
      ...query && { query },
      ...ids && { ids },
      ...locked && { locked },
      ...unlocked && { unlocked },
      ...tracked && { tracked },
      ...offered && { offered },
      ...visited && { visited }, 
      ...email_opened && { email_opened },
      attributes,
      not_sender: true
    };
    const response = await axios.get(`/offers/${offer_id}/recipients`, { params });

    return { response, query, ids };
  }
);
export const getSharedRecipients = getThunkActionCreator(
  getSharedRecipientsRoutine,
  async ({ offer_id, limit = 25, offset = 0, orders = { updated_at: 'desc' } }) => {
    const attributes = ['id', { user: ['id', 'first_name', 'last_name', 'email', { photo: ['representations'] }] }];
    const params = { limit, offset, orders, attributes };

    return await axios.get(`/offers/${offer_id}/recipients`, { params });
  }
);
export const getRecipient = getThunkActionCreator(
  getRecipientRoutine,
  async id => {
    const attributes = [
      'id',
      { user: ['id', 'first_name', 'last_name', 'email', 'company_name', { photo: ['representations'] }] },
      { list: ['name'] },
      { member: ['id', { user: ['first_name', 'last_name'] }] },
      { files: ['id', 'filename', 'url', 'content_type'] },
      'tracked',
      'locked',
      'approved',
      'email',
      'first_name',
      'last_name',
      'notes',
      'property_visits',
      'offered_amount_cents',
      'offered_percent',
      'offered_at',
      'email_opens_count',
      'email_bounce',
      'visits_count',
      'visits_duration',
      'saved',
      'interest_level',
      'created_at',
      'visited_at'
    ];
    const params = { attributes };

    return await axios.get(`/recipients/${id}`, { params });
  }
);
export const createRecipient = getThunkActionCreator(
  createRecipientRoutine,
  async ({ offer_id, recipient }) => {
    const data = { recipient };

    return await axios.post(`/offers/${offer_id}/recipients`, data);
  }
);
export const notifyRecipients = getThunkActionCreator(
  notifyRecipientsRoutine,
  async ({ offer_id, recipient_id = null, recipients_type = null, subject, message }) => {
    const data = { ...recipient_id && { recipient_id }, ...!recipient_id && recipients_type !== 'all' && { recipients_type }, subject, message };

    return await axios.post(`/offers/${offer_id}/recipients/notify`, data);
  }
);
export const updateRecipient = getThunkActionCreator(
  updateRecipientRoutine,
  async ({ id, ...fields }) => {
    const data = { recipient: _omit(fields, ['localMember', 'localFiles']) };
    const response = await axios.patch(`/recipients/${id}`, data);

    return { response, recipient: { id, ...fields } };
  }
);
export const deleteRecipient = getThunkActionCreator(
  deleteRecipientRoutine,
  async ({ id, count = 0 }) => {
    const response = await axios.delete(`/recipients/${id}`);

    return { response, id, count };
  }
);
export const clearLocalActivityLogRecipients = getThunkActionCreator(clearLocalActivityLogRecipientsRoutine, async () => {});
export const clearLocalSharedRecipients = getThunkActionCreator(clearLocalSharedRecipientsRoutine, async () => {});