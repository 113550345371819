import styled from '@mui/material/styles/styled';
import Avatar from '@mui/material/Avatar';
import _toUpper from 'lodash/toUpper';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  flexShrink: 0,
  width: 30,
  height: 30,
  fontSize: 14,
  fontWeight: 700,
  backgroundColor: theme.palette.primary.main,
  borderRadius: '2px',
}));

const DealspaceAvatar = ({ src, title, sx }) => {
  return (
    <StyledAvatar
      alt={`${title ?? ''}`}
      src={src}
      variant='rounded'
      sx={sx}
    >
      {_toUpper(title[0]) ?? ''}
    </StyledAvatar>
  );
};

export default DealspaceAvatar;