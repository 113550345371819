import {
  getSuggestedUsersRoutine,
  getUsersListRoutine,
  getUsersWidgetListRoutine,
  getUserRoutine,
  getPublicUserRoutine,
  getPublicUserVCardRoutine,
  getIntakeUserRoutine,
  createUserRoutine,
  changeUserPasswordRoutine,
  createContactRoutine,
  deleteContactRoutine,
  validateUserRoutine,
  validateUserEmailRoutine,
  clearLocalSuggestedUsersRoutine,
  clearLocalUsersListRoutine,
  clearLocalUsersWidgetListRoutine,
  clearLocalActiveUserRoutine,
  createConversationRoutine
} from 'actions';
import _map from 'lodash/map';
import _pick from 'lodash/pick';

export const initialState = {
  suggested: { data: [], pagination: { limit: 0, offset: 0, count: 0, total_count: 0 } },
  list: [],
  widgetList: { data: [], fetching: true},
  user: {
    id: '',
    photo: null,
    header: null,
    headline: '',
    current_contact: null,
    private_conversation: null,
    title: '',
    company_name: '',
    location: '',
    latitude: 0,
    longitude: 0,
    about: '',
    email: '',
    first_name: '',
    last_name: '',
    mobile_phone: '',
    office_phone: '',
    office_phone_extension: '',
    geographic_focuses: [],
    linkedin_profile_url: '',
    website_url: '',
    primary_activities: [],
    risk_profiles: [],
    asset_classes: [],
    contents: [],
    teammates: [],
    public_active_offers_count: 0
  },
  intake: {
    id: '',
    first_name: '',
    last_name: '',
    photo: null
  },
  created: false,
  validated: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getSuggestedUsersRoutine.SUCCESS: {
      const { data: { users, pagination } } = action.payload;
      const uploadedData = _map(users, ({ id, first_name, last_name, photo }) =>
        ({
          id,
          title: `${first_name} ${last_name}`,
          data: { id, avatarPlaceholder: first_name[0] + last_name[0], photo, first_name, last_name }
        })
      );

      return { ...state, suggested: { data: [...state.suggested.data, ...uploadedData], pagination } };
    }
    case getUsersListRoutine.SUCCESS: {
      const { data: { users } } = action.payload;
      const uploadedData = _map(users, ({ id, first_name, last_name, photo }) =>
        ({
          id,
          title: `${first_name} ${last_name}`,
          data: { id, avatarPlaceholder: first_name[0] + last_name[0], photo, first_name, last_name }
        })
      );

      return { ...state, list: [...state.list, ...uploadedData] };
    }
    case getUsersWidgetListRoutine.SUCCESS: {
      const { data: { users } } = action.payload;
      const widgetList = { data: users, fetching: false };

      return { ...state, widgetList };
    }
    case getUsersWidgetListRoutine.REQUEST: {
      const widgetList = { ...state.widgetList, fetching: true };

      return { ...state, widgetList };
    }
    case getUsersWidgetListRoutine.FAILURE: {
      const widgetList = { ...state.widgetList, fetching: false };

      return { ...state, widgetList };
    }
    case getUserRoutine.SUCCESS: {
      const { data: { user } } = action.payload;

      return { ...state, user: { ...state.user, ...user } };
    }
    case getPublicUserRoutine.SUCCESS: {
      return state;
    }
    case getPublicUserVCardRoutine.SUCCESS: {
      return state;
    }
    case getIntakeUserRoutine.SUCCESS: {
      const { data: { user } } = action.payload;

      return { ...state, intake: user };
    }
    case createUserRoutine.SUCCESS: {
      const { data: { user: { id } } } = action.payload;

      return { ...state, created: !!id }
    }
    case changeUserPasswordRoutine.SUCCESS: {
      return state;
    }
    case createContactRoutine.SUCCESS: {
      const { response: { data: { contact } } } = action.payload;

      return { ...state, user: { ...state.user, current_contact: _pick(contact, ['id', 'status']) } };
    }
    case deleteContactRoutine.SUCCESS: {
      const { response: { status }, id } = action.payload;

      return { ...state, ...(status === 204 && state.user?.current_contact?.id === id && { user: { ...state.user, current_contact: null } }) };
    }
    case validateUserRoutine.SUCCESS: {
      const { status } = action.payload;

      return { ...state, validated: status === 204 };
    }
    case validateUserEmailRoutine.SUCCESS: {
      return state;
    }
    case clearLocalSuggestedUsersRoutine.SUCCESS: {
      return { ...state, suggested: initialState.suggested };
    }
    case clearLocalUsersListRoutine.SUCCESS: {
      return { ...state, list: initialState.list };
    }
    case clearLocalUsersWidgetListRoutine.SUCCESS: {
      return { ...state, widgetList: initialState.widgetList };
    }
    case clearLocalActiveUserRoutine.SUCCESS: {
      return { ...state, user: initialState.user };
    }
    case createConversationRoutine.SUCCESS: {
      const { data: { conversation: private_conversation } } = action.payload.response;

      return { ...state, user: { ...state.user, private_conversation } };
    }
    default: {
      return state;
    }
  };
};

export default reducer;