import { memo, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Text, StyledButton } from './Menu.styled';
import MenuVert from 'components/Common/Icons/MenuVert';
import EditIcon from 'components/Common/Icons/EditIcons';
import PreviewerIcon from 'components/Common/Icons/PreviewerIcon';
import DuplicateIcon from 'components/Common/Icons/DuplicateIcon';
import DeleteIcon from 'components/Common/Icons/DeleteIcon';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';

const OfferingMenu = ({ disabled = false, showEdit, showPreview, showDuplicate, showDelete, onEdit, onPreview, onDuplicate, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleEditClick = e => {
    setAnchorEl(null);
    onEdit(e);
  };
  const handlePreviewClick = () => {
    setAnchorEl(null);
    onPreview();
  };
  const handleDuplicateClick = () => {
    setAnchorEl(null);
    onDuplicate();
  };
  const handleDeleteClick = () => {
    setAnchorEl(null);
    onDelete();
  };

  return (
    <>
      <StyledButton onClick={handleMenuClick} disabled={disabled}>
        <MenuVert />
      </StyledButton>
      <Menu
        keepMounted
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        PaperProps={{ variant: 'popup', color: 'dark', sx: { mt: '2px', width: '180px' } }}
        onClick={e => e.stopPropagation()}
        disabled={disabled}
      >
        {showEdit &&
          <MenuItem onClick={handleEditClick} disabled={disabled}>
            <EditIcon /><Text>Edit</Text>
          </MenuItem>
        }
        {showPreview &&
          <MenuItem onClick={handlePreviewClick} disabled={disabled}>
            <PreviewerIcon /><Text>Preview</Text>
          </MenuItem>
        }
        {showDuplicate &&
          <MenuItem onClick={handleDuplicateClick} disabled={disabled}>
            <DuplicateIcon /><Text>Duplicate</Text>
          </MenuItem>
        }
        {showDelete &&
          <MenuItem onClick={handleDeleteClick} disabled={disabled}>
            <DeleteIcon /><Text>Delete Draft</Text>
          </MenuItem>
        }
      </Menu>
    </>
  );
};

OfferingMenu.propTypes = {
  disabled: PropTypes.bool,
  showEdit: PropTypes.bool.isRequired,
  showPreview: PropTypes.bool.isRequired,
  showDuplicate: PropTypes.bool.isRequired,
  showDelete: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <OfferingMenu {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);