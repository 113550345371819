export const fieldSx = {
  backgroundColor: '#ECEEF366',
  padding: 0,
  '& .intl-tel-input': {
    padding: '4px 0 !important',
  },
  '& input': {
    fontSize: '14px',
    color: '#000',
  },
  '& .Mui-error input': {
    color: 'red !important'
  }
}