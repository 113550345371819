import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
// Local files
import {
  setInvitationToken as setInvitationTokenAction,
  getInvitations as getInvitationsAction,
  getInvitation as getInvitationAction,
  acceptInvitation as acceptInvitationAction,
  declineInvitation as declineInvitationAction,
  notifyInvite as notifyInviteAction
} from 'actions/invitations';

const useInvitations = () => {
  const dispatch = useDispatch();
  const setInvitationToken = useCallback(invitation_token => dispatch(setInvitationTokenAction(invitation_token)), [dispatch]);
  const getInvitations = useCallback(({ offset, invitation_type }) => dispatch(getInvitationsAction({ offset, invitation_type })), [dispatch]);
  const getInvitation = useCallback(invitation_token => dispatch(getInvitationAction(invitation_token)), [dispatch]);
  const acceptInvitation = useCallback(({ invitation_token, inviter_id }) => dispatch(acceptInvitationAction(({ invitation_token, inviter_id }))), [dispatch]);
  const declineInvitation = useCallback(({ invitation_token, inviter_id }) => dispatch(declineInvitationAction(({ invitation_token, inviter_id }))), [dispatch]);
  const notifyInvite = useCallback(id => dispatch(notifyInviteAction(id)), [dispatch]);

  return { setInvitationToken, getInvitations, getInvitation, acceptInvitation, declineInvitation, notifyInvite };
};

export default useInvitations;