import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export const Root = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
  overflow: 'hidden',
}));

export const Content = styled(Box)({
 flexGrow: 1,
 overflow: 'auto',
});

export const ButtonContainer = styled(Box)({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 32,
  display: 'flex',
  justifyContent: 'center',
});

export const StartChatButton = styled((props) => (
  <Button variant='gradient' {...props} />
))({
  '& svg > line': {
    stroke: '#4371ea'
  }
});