import {
  getUserConversationsRoutine,
  getGroupConversationsRoutine,
  getDealspaceConversationsRoutine,
  getOfferConversationsRoutine,
  getConversationRoutine,
  createConversationRoutine,
  createOfferConversationRoutine,
  updateConversationRoutine,
  addUserToConversationRoutine,
  deleteUserFromConversationRoutine,
  setLocalUserIdsRoutine,
  setLocalConversationRoutine,
  setOfferUnreadCountConversationsRoutine,
  clearLocalUserConversationsRoutine,
  clearLocalConversationsRoutine,
  clearLocalActiveConversationRoutine,
  readMessagesRoutine,
  createMessageRoutine,
  getMessageRoutine
} from 'actions';
import _dropRight from 'lodash/dropRight';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';

export const initialState = {
  user: {
    general: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: -1 } },
    feed: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } }
  },
  offerings: { data: [], pagination: { limit: 25, offset: 0, count: 0, total_count: 0 } },
  conversation: { id: '', offer: null, last_message: null, users: [], updated_at: '' , private: false, created: false },
  user_ids: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getUserConversationsRoutine.SUCCESS: {
      const { response : { data: { conversations, pagination } }, feed, general } = action.payload;

      return { 
        ...state, 
        user: { ...state.user,
          ...(general && {
            general: { data: [...state.user.general.data, ...conversations], pagination }
          }),
          ...(feed && {
            feed: { data: _uniqBy([...state.user.feed.data, ...conversations], 'id'), pagination }
          })
        } 
      };
    }
    case getGroupConversationsRoutine.SUCCESS: {
      const { data: { conversations, pagination } } = action.payload;

      return { ...state, offerings: { ...state.offerings, data: [...state.offerings.data, ...conversations], pagination } };
    }
    case getDealspaceConversationsRoutine.SUCCESS: {
      const { data: { conversations, pagination } } = action.payload;

      return { ...state, offerings: { ...state.offerings, data: [...state.offerings.data, ...conversations], pagination } };
    }
    case getOfferConversationsRoutine.SUCCESS: {
      const { response: { data: { conversations, pagination } }, feed } = action.payload;

      return { 
        ...state, 
        ...(feed ? { user: { ...state.user, feed: { data: _uniqBy([...state.user.feed.data, ...conversations], 'id'), pagination } } } :
          { offerings: { ...state.offerings, data: _uniqBy([...state.offerings.data, ...conversations], 'id'), pagination } })

      };
    }
    case getConversationRoutine.SUCCESS: {
      const { data: { conversation } } = action.payload;
      const updatedConversation = { ...state.conversation, ...conversation };

      return { ...state, conversation: updatedConversation };
    }
    case createConversationRoutine.SUCCESS: {
      const { data: { conversation } } = action.payload.response;
      const updatedConversation = { ...conversation, created: true };
      const oldAllData = state.user.general.pagination.total_count > 24 ? _dropRight(state.user.general.data) : state.user.general.data;
      const data = [...[updatedConversation], ...oldAllData];
      const pagination = { ...state.user.general.pagination, count: state.user.general.pagination.count + 1, total_count: state.user.general.pagination.total_count + 1 };

      return { 
        ...state, 
        ...(state.user.general.pagination.total_count !== -1 && { user: { ...state.user, general: { data, pagination } } }), 
        conversation: updatedConversation 
      };
    }
    case createOfferConversationRoutine.SUCCESS: {
      const { response: { data: { conversation } }, isMyShared } = action.payload;
      const updatedConversation = { ...conversation, created: true };
      const oldPagination = isMyShared ? state.offerings.pagination : state.user.feed.pagination
      const oldAllData = oldPagination.total_count > 24 ? _dropRight(isMyShared ? state.offerings.data : state.user.feed.data) : (isMyShared ? state.offerings.data : state.user.feed.data);
      const data = [...[updatedConversation], ...oldAllData];
      const pagination = { ...oldPagination, count: oldPagination.count + 1, total_count: oldPagination.total_count + 1 };

      return { 
        ...state, 
        ...(isMyShared && { offerings: { data, pagination } }),
        ...(!isMyShared && { user: { ...state.user, feed: { data, pagination } } }), 
        conversation: updatedConversation
      };
    }
    case updateConversationRoutine.SUCCESS: {
      const { response: { status }, conversation } = action.payload;
      const updatedConversation = { ...state.conversation, ...conversation };

      return { ...state, ...(status === 204 && { conversation: updatedConversation }) };
    }
    case addUserToConversationRoutine.SUCCESS: {
      const { response: { status }, conversation_id, user } = action.payload;

      return { ...state, 
        ...(status === 204 && {
          user: { ...state.user, 
            general: { ...state.user.general, data: _map(state.user.general.data, (item) => 
              item.id === conversation_id ? { ...item, users: [...item.users, user] } : item
            )},
            feed: { ...state.user.feed, data: _map(state.user.feed.data, (item) => 
              item.id === conversation_id ? { ...item, users: [...item.users, user] } : item
            )}
          },
          offerings: {
            ...state.offerings,
            data: _map(state.offerings.data, (item) => item.id === conversation_id ? { ...item, users: [...item.users, user] } : item)
          },
          conversation: { ...state.conversation, users: [...state.conversation.users, user] }
        }) 
      };
    }
    case deleteUserFromConversationRoutine.SUCCESS: {
      const { response: { status }, conversation_id, user_id } = action.payload;

      return { ...state, 
        ...(status === 204 && {
          user: { ...state.user, 
            general: { ...state.user.general, data: _map(state.user.general.data, (item) => 
              item.id === conversation_id ? { ...item, users: _filter(item.users, (user) => user.id !== user_id ) } : item
            )},
            feed: { ...state.user.feed, data: _map(state.user.feed.data, (item) => 
              item.id === conversation_id ? { ...item, users: _filter(item.users, (user) => user.id !== user_id ) } : item
            )}
          },
          offerings: {
            ...state.offerings,
            data: _map(state.offerings.data, (item) => item.id === conversation_id ? { ...item, users: _filter(item.users, (user) => user.id !== user_id ) } : item
            )
          },
          conversation: { ...state.conversation, users: _filter(state.conversation.users, (user) => user.id !== user_id ) }
        }) 
      };
    }
    case setLocalUserIdsRoutine.SUCCESS: {
      return { ...state, user_ids: action.payload };
    }
    case setLocalConversationRoutine.SUCCESS: {
      const isFeedCoversation = !!_find(state.user.feed.data, (c) => c.id === action.payload?.id);
      const isOfferCoversation = !!_find(state.offerings.data, (c) => c.id === action.payload?.id);

      return { 
        ...state, 
        ...isFeedCoversation && {
          user: {...state.user, 
            feed: {...state.user.feed, data: _map(state.user.feed.data, (c) => c?.id === action.payload?.id ? { ...c, ...action.payload } : c ) } 
          }
        },
        ...isOfferCoversation && {
          offerings: {...state.offerings, data: _map(state.offerings.data, (c) => c?.id === action.payload?.id ? { ...c, ...action.payload } : c ) }
        },
        conversation: action.payload 
      };
    }
    case setOfferUnreadCountConversationsRoutine.SUCCESS: {
      const { unread_conversations_count, offer_id } = action.payload;

      return { ...state,
        ...(!!_find(state.user.feed.data, ({ offer }) => offer?.id === offer_id) && {
          user: {...state.user, 
            feed: {...state.user.feed, data: _map(state.user.feed.data, (item) => item?.offer?.id === offer_id ? { ...item, unread_conversations_count } : item ) } 
          }
        }),
        ...(!!_find(state.offerings.data, ({ offer }) => offer?.id === offer_id) && {
          offerings: {...state.offerings, data: _map(state.offerings.data, (item) => item?.offer?.id === offer_id ? { ...item, offer: { ...item.offer, unread_conversations_count } } : item ) }
        }),
        ...(state.conversation.offer?.id === offer_id && { conversation: { ...state.conversation, offer: { ...state.conversation.offer, unread_conversations_count } } })
      }
    }
    case clearLocalUserConversationsRoutine.SUCCESS: {
      return { ...state, user: initialState.user }
    }
    case clearLocalConversationsRoutine.SUCCESS: {
      return { ...state, offerings: initialState.offerings }
    }
    case clearLocalActiveConversationRoutine.SUCCESS: {
      return { ...state, conversation: initialState.conversation }
    }
    case readMessagesRoutine.SUCCESS: {
      const { response: { status }, message_ids } = action.payload;
      
      return { ...state,
        ...(status === 204 && { 
          ...(!!_find(state.user.feed.data, (item) => _find(message_ids, (message) => message === item?.last_message?.id)) && {
            user: {...state.user, 
              feed: {...state.user.feed, 
                data: _map(state.user.feed.data, (item) => !!_find(message_ids, (message) => message === item?.last_message?.id) ? 
                  { ...item, last_message: {...item.last_message, read: true } } : item ) } 
            }
          }),
          ...(!!_find(state.user.general.data, (item) => _find(message_ids, (message) => message === item?.last_message?.id)) && {
            user: {...state.user, 
              general: {...state.user.general, 
                data: _map(state.user.general.data, (item) => !!_find(message_ids, (message) => message === item?.last_message?.id) ? 
                { ...item, last_message: {...item.last_message, read: true } } : item ) }
            }
          }),
          ...(!!_find(state.offerings.data, (item) => _find(message_ids, (message) => message === item?.last_message?.id)) && {
            offerings: {...state.offerings, data: _map(state.offerings.data, (item) => !!_find(message_ids, (message) => message === item?.last_message?.id) ? 
              { ...item, last_message: {...item.last_message, read: true } } : item ) } 
          })
        })
      }
    } 
    case createMessageRoutine.SUCCESS: {
      const { response: { data: { message } }, conversation_id } = action.payload;

      return { ...state, 
        ...(!!_find(state.user.general.data, ({ id }) => id === conversation_id) && {
          user: { ...state.user, general: { ...state.user.general, 
            data: _map(state.user.general.data, (item) => item?.id === conversation_id ? { ...item, last_message: message, updated_at: message.created_at } : item) 
          } }
        }),
        ...(!!_find(state.user.feed.data, ({ id }) => id === conversation_id) && {
          user: { ...state.user, feed: { ...state.user.feed, 
            data: _map(state.user.feed.data, (item) => item?.id === conversation_id ? { ...item, last_message: message, updated_at: message.created_at } : item) 
          } }
        }),
        ...(!!_find(state.offerings.data, ({ id }) => id === conversation_id) && {
          offerings: { ...state.offerings, data: _map(state.offerings.data, (item) => item?.id === conversation_id ? { ...item, last_message: message, updated_at: message.created_at } : item) }
        }),
        conversation: { ...state.conversation, last_message: message, updated_at: message.created_at } 
      }
    }
    case getMessageRoutine.SUCCESS: {
      const { response: { data: { message } }, conversation_id } = action.payload;

      return { ...state, 
        ...(!!_find(state.user.general.data, ({ id }) => id === conversation_id) && {
          user: { ...state.user, general: { ...state.user.general, 
            data: _map(state.user.general.data, (item) => item?.id === conversation_id ? { ...item, last_message: message, updated_at: message.created_at } : item) 
          } }
        }),
        ...(!!_find(state.user.feed.data, ({ id }) => id === conversation_id) && {
          user: { ...state.user, feed: { ...state.user.feed, 
            data: _map(state.user.feed.data, (item) => item?.id === conversation_id ? { ...item, last_message: message, updated_at: message.created_at } : item) 
          } }
        }),
        ...(!!_find(state.offerings.data, ({ id }) => id === conversation_id) && {
          offerings: { ...state.offerings, data: _map(state.offerings.data, (item) => item?.id === conversation_id ? { ...item, last_message: message, updated_at: message.created_at } : item) }
        }),
        ...((state.conversation.id === conversation_id) && { conversation: { ...state.conversation, last_message: message, updated_at: message.created_at } }) 
      }
    }
    default: {
      return state;
    }
  };
};

export default reducer;