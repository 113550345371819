import { memo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
// Local files
import { Content, SectionItem, SectionList, StyledDialogContent } from './AccountSettings.styled';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import LinkForm from './LinkForm/LinkForm';
import useCustomSelector from 'hooks/useCustomSelector';
import useProfile from 'hooks/useProfile';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';

const AccountSettings = ({ open, handleClose }) => {
  const username = useCustomSelector(state => state.profile.user.username);
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { changeProfilePassword } = useProfile();
  const [currentPassword, setCurrentPassword] = useState({ value: '', error: '' });
  const [newPassword, setNewPassword] = useState({ value: '', error: '' });
  const [repeatedNewPassword, setRepeatedNewPassword] = useState({ value: '', error: '' });
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState('security');

  const handleSubmit = () => {
    let hasError = false;

    if (!currentPassword.value) {
      setCurrentPassword(prev => ({ ...prev, error: `Current password can't be empty` }));
      hasError = true;
    }
    if (!newPassword.value) {
      setNewPassword(prev => ({ ...prev, error: `New password can't be empty` }));
      hasError = true;
    }
    if (newPassword.value.length < 6) {
      setNewPassword(prev => ({ ...prev, error: `New password can't be less then 6` }));
      hasError = true;
    }
    if (!repeatedNewPassword.value) {
      setRepeatedNewPassword(prev => ({ ...prev, error: `Please repeat new password` }));
      hasError = true;
    }
    if (newPassword.value !== repeatedNewPassword.value) {
      setRepeatedNewPassword(prev => ({ ...prev, error: `New password and repeat new password values must be the same` }));
      hasError = true;
    }

    if (hasError) return;

    setLoading(true);
    changeProfilePassword({ current_password: currentPassword.value, password: newPassword.value })
    .then(() => setSuccess('Password successfully changed').then(handleClose))
    .catch(e => setError(e))
    .finally(() => setLoading(false));
  };
  const handleExited = () => {
    setCurrentPassword({ value: '', error: '' });
    setNewPassword({ value: '', error: '' });
    setRepeatedNewPassword({ value: '', error: '' });
  };

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      title='Account Settings'
      PaperProps={{ sx: { maxWidth: 'unset', width: 'min(100%, 760px)', height: { xs: 'auto', md: '480px' } } }}
      TransitionProps={{ onExited: handleExited }}
    >
      <StyledDialogContent>
        <SectionList>
          <SectionItem
            selected={section === 'security'}
            onClick={() => setSection('security')}
          >
            Security
          </SectionItem>
          <SectionItem
            selected={section === 'links'}
            onClick={() => setSection('links')}
          >
            My Links
          </SectionItem>
        </SectionList>
        <Content>
          {section === 'security' &&
            <Stack sx={{ alignItems: { xs: 'center', /*md: 'flex-start'*/}, gap: '12px' }}>
              <TextField
                fullWidth
                sx={{ maxWidth: 290 }}
                size='small'
                margin='dense'
                type='password'
                label='Current Password'
                value={currentPassword.value}
                onChange={({ target: { value } }) => setCurrentPassword({ value, error: '' })}
                error={!!currentPassword.error}
                helperText={currentPassword.error}
              />
              <TextField
                fullWidth
                sx={{ maxWidth: 290 }}
                size='small'
                margin='dense'
                type='password'
                label='New Password'
                value={newPassword.value}
                onChange={({ target: { value } }) => {
                  setNewPassword({ value, error: '' });
                  setRepeatedNewPassword(prev => ({ ...prev, error: '' }));
                }}
                error={!!newPassword.error}
                helperText={newPassword.error}
              />
              <TextField
                fullWidth
                sx={{ maxWidth: 290 }}
                size='small'
                margin='dense'
                type='password'
                label='Repeat New Password'
                value={repeatedNewPassword.value}
                onChange={({ target: { value } }) => {
                  setNewPassword(prev => ({ ...prev, error: '' }));
                  setRepeatedNewPassword({ value, error: '' });
                }}
                error={!!repeatedNewPassword.error}
                helperText={repeatedNewPassword.error}
              />
              <Box sx={{ display: 'flex', gap: '16px', mt: 2 }}>
                <Button
                  disabled={loading}
                  color='error'
                  sx={{ px: '24px', minWidth: 100, minHeight:  30 }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  disabled={loading}
                  color='primary'
                  variant='contained'
                  sx={{ px: '24px', whiteSpace: 'nowrap' }}
                  onClick={handleSubmit}
                >
                  Change password
                </Button>
              </Box>
            </Stack>
          }
          {section === 'links' &&
            <Stack sx={{ width: '100%', alignItems: { xs: 'center', md: 'flex-start' }, gap: '42px' }}>
              <LinkForm
                title='Deal intake form link'
                description='Share this link to receive deals directly to your feed'
                link={`${window.location.origin}/users/${username}/intake`}
              />
              <LinkForm
                title='Invitation link'
                description='Share this link to invite someone to connect with you on Qwincy'
                link={`${window.location.origin}/users/${username}/connect`}
              />
            </Stack>
          }
        </Content>
      </StyledDialogContent>
    </BaseDialog>
  );
};

AccountSettings.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default memo(AccountSettings);