import {
  getAuditsRoutine,
  getAuditRoutine,
  getCableCreatedAuditRoutine,
  createAuditRoutine,
  clearLocalAuditsRoutine
} from 'actions';
import _map from 'lodash/map';
import _dropRight from 'lodash/dropRight';

export const initialState = {
  all: {
    data: [],
    pagination: { limit: 25, offset: 0, count: 0, total_count: -1 },
    fetching: true
  },
  audit: {
    id: '',
    user: { id: '', first_name: '', last_name: '' },
    description: '',
    created_at: '',
    updated_at: ''
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case getAuditsRoutine.SUCCESS: {
      const { data: { audits, pagination } } = action.payload;
      const all = { data: audits, pagination, fetching: false };

      return { ...state, all };
    }
    case getAuditsRoutine.REQUEST: {
      const all = { ...state.all, fetching: true };

      return { ...state, all };
    }
    case getAuditsRoutine.FAILURE: {
      const all = { ...state.all, fetching: false };

      return { ...state, all };
    }
    case getAuditRoutine.SUCCESS: {
      const { data: { audit } } = action.payload;
      const updatedAudit = { ...state.audit, ...audit };
      const all = { ...state.all, data: _map(state.all.data, a => a.id === audit.id ? { ...a, ...audit } : a) };

      return { ...state, all, audit: updatedAudit };
    }
    case getCableCreatedAuditRoutine.SUCCESS: {
      const { data: { audit } } = action.payload;
      const oldAllData = state.all.pagination.total_count > 49 ? _dropRight(state.all.data) : state.all.data;
      const data = [...[audit], ...oldAllData];
      const pagination = { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 };

      return { ...state, all: { data, pagination } };
    }
    case createAuditRoutine.SUCCESS: {
      const { data: { audit } } = action.payload;
      const oldAllData = state.all.pagination.total_count > 49 ? _dropRight(state.all.data) : state.all.data;
      const data = [...[audit], ...oldAllData];
      const pagination = { ...state.all.pagination, count: state.all.pagination.count + 1, total_count: state.all.pagination.total_count + 1 };

      return { ...state, all: { data, pagination } };
    }
    case clearLocalAuditsRoutine.SUCCESS: {
      return { ...state, all: initialState.all };
    }
    default: {
      return state;
    }
  };
};

export default reducer;