import { lazy, memo, Suspense, useEffect, useMemo, useRef } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import _pick from 'lodash/pick';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';
import { Parser } from 'html-to-react';
import { useParams, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
// Local files
import { Root, Logo, ScrollButton, LockedLocation, Description } from './Details.styled';
import axios, { baseURL } from 'apis';
import { ReactComponent as ScrollIcon } from 'assets/icons/scrollToTop.svg';
import { ReactComponent as Locked } from 'assets/icons/lock-filled.svg';
import { DescriptionSkeleton, ImagesSkeleton, MainSkeleton } from './Skeletons/Skeletons';
import { Fallback as AgreementFallback } from 'components/Dialogs/Agreement/Agreement';
import { Fallback as MainFallback } from './Main/Main';
import { Fallback as ImagesFallback } from './Images/Images';
import { Fallback as FilesFallback } from './Files/Files';
import { Fallback as SendersFallback } from './Senders/Senders';
import { Fallback as MapFallback } from './Map/Map';
import DelayedRender from 'components/Common/DelayedRender/DelayedRender';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import useApp from 'hooks/useApp';
import useError from 'hooks/useError';
import useCustomSelector from 'hooks/useCustomSelector';
import useConversations from 'hooks/useConversations';
import useDocuments from 'hooks/useDocuments';
import useImages from 'hooks/useImages';
import useFolders from 'hooks/useFolders';
import useOffers from 'hooks/useOffers';
import useMixpanel from 'hooks/useMixpanel';
import useSenders from 'hooks/useSenders';
import useTemplates from 'hooks/useTemplates';

const Agreement = lazy(() => import('components/Dialogs/Agreement/Agreement'));
const Main = lazy(() => import('./Main/Main'));
const Images = lazy(() => import('./Images/Images'));
const Files = lazy(() => import('./Files/Files'));
const Senders = lazy(() => import('./Senders/Senders'));
const Map = lazy(() => import('./Map/Map'));

const OfferDetails = () => {
  const { offer_id } = useParams();
  const { state } = useLocation();
  const refMain = useRef(null);
  const refFiles = useRef(null);
  const refSenders = useRef(null);
  const refMap = useRef(null);
  const { /*openAccessBetaDialog,*/ openAgreementDialog, openLockedContentDialog, setAuthData } = useApp();
  const { setError } = useError();
  const { getOfferImages, clearLocalOfferImages } = useImages({});
  const { openOrCreateConversation, getOfferConversations, clearLocalConversations } = useConversations();
  const { getOfferPublicDocuments, getOfferPrivateLevel1Documents, getOfferPrivateLevel2Documents, clearLocalOfferDocuments } = useDocuments();
  const { getOfferPublicFolders, getOfferPrivateLevel1Folders, getOfferPrivateLevel2Folders, clearLocalOfferFolders } = useFolders();
  const { getTemplates, clearLocalTemplates } = useTemplates();
  const { getFeedOfferDetails, clearLocalFeedOffer, visitOffer, setOfferLocalFields } = useOffers();
  const { track } = useMixpanel();
  const { getSenders, clearLocalSenders } = useSenders();
  const {
    profileFetched, hasRecipientSession, generalFetching, activeOffer, my_id, senders, sendersFetching, publicFiles, publicFolders,
    privateLevel1Files, privateLevel2Files, privateLevel1Folders, privateLevel2Folders, conversations, isMyShared, showChatButton, localSession
  } = useCustomSelector(state => ({
    profileFetched: !!state.profile.user.id || !!state.profile.recipient.id,
    localSession: state.sessions.session,
    hasRecipientSession: !!state.profile.recipient.id,
    // isQwincyMember: !!state.profile.recipient.user?.email,
    generalFetching: state.offers.dealflow.details.fetching,
    activeOffer: state.offers.dealflow.details.offer,
    my_id: state.profile.user.id,
    senders: state.senders.all.data,
    sendersFetching: state.senders.all.fetching,
    publicFiles: state.documents.offer.public.data,
    publicFolders: state.folders.offer.public.data,
    privateLevel1Files: state.documents.offer.level1.data,
    privateLevel2Files: state.documents.offer.level2.data,
    privateLevel1Folders: state.folders.offer.level1.data,
    privateLevel2Folders: state.folders.offer.level2.data,
    conversations: _filter(state.conversations.user.feed.data.concat(state.conversations.offerings.data), (c) => c?.conversationable?.id === offer_id),
    isMyShared: !!_find(state.senders.all.data, ({ user }) => user?.id === state.profile.user.id),
    showChatButton: state.senders.all.data.length > 1 || state.senders.all.data[0]?.user?.id !== state.profile.user.id
  }));
  const showLocatedMap = !isMyShared && activeOffer?.locations_locked && activeOffer?.locked;
  const mainProps = {
    ..._pick(activeOffer, [
      'id', 'tag', 'primary_sender', 'locked', 'saved_dealspace', 'tracked', 'headline', 'status', 'lease_period', 'loan_ratio', 'loan_percent',
      'investment_type', 'price_cents', 'custom_price', 'main_location', 'shared_at', 'deadline_at', 'deadline_type', 'public', 'locations_locked',
      'asset_classes', 'properties_count', 'disinterested', 'risk_profiles', 'total_locked_documents_count', 'total_unlocked_documents_count',
      'total_approval_required_documents_count', 'approval_required'
    ]),
    senders,
    isMyShared,
    checking: generalFetching || sendersFetching
  };

  const filesProps = { 
    publicFiles,
    privateLevel1Files: [
      ...privateLevel1Files,
      ...!!activeOffer.locked_documents_count && activeOffer.locked && !isMyShared
        ? _map([...Array(activeOffer.locked_documents_count).keys()], f => ({ id: f, file: { filename: f, content_type: '' }, locked: true, approval_required: false }))
        : []
    ],
    privateLevel2Files: [
      ...privateLevel2Files,
      ...!!activeOffer.approval_required_documents_count && activeOffer.approval_required && !isMyShared
        ? _map([...Array(activeOffer.approval_required_documents_count).keys()], f => ({ id: f, file: { filename: f, content_type: '' }, locked: true, approval_required: true }))
        : []
    ],
    publicFolders,
    privateLevel1Folders: [
      ...privateLevel1Folders,
      ...activeOffer.locked_folders_count && activeOffer.locked && !isMyShared
        ? _map([...Array(activeOffer.locked_folders_count).keys()], f => ({ id: f, name: '', documents_count: 0, documents_byte_size: 0, locked: true, approval_required: true }))
        : []
    ],
    privateLevel2Folders: [
      ...privateLevel2Folders,
      ...!!activeOffer.approval_required_folders_count && activeOffer.approval_required && !isMyShared
        ? _map([...Array(activeOffer.approval_required_folders_count).keys()], f => ({ id: f, name: '', documents_count: 0, documents_byte_size: 0, locked: true, approval_required: true }))
        : []
    ],
    total: activeOffer.total_locked_documents_count + activeOffer.total_unlocked_documents_count + activeOffer.total_approval_required_documents_count,
    showUnlock: !isMyShared && activeOffer.locked && (activeOffer.locations_locked || activeOffer.total_locked_documents_count > 0 || activeOffer.total_approval_required_documents_count > 0),
    offerApproved: !activeOffer.approval_required,
    isMyShared
  };

  const mapProps = { activeOffer, showLocatedMap };
  const isFetched = useMemo(() => {
    return !!refMain && !!activeOffer.id && !generalFetching
  }, [refMain, generalFetching, activeOffer.id]);

  const handleUnlockClick = () => {
    if (activeOffer.locked && !isMyShared) {
      if (hasRecipientSession) {
        setAuthData({
          open: true,
          ...!activeOffer.agreement && { redirectAction: { type: 'unlock_click', data: { offerId: offer_id } } }
        });
      } else {
        if (!!activeOffer.agreement) {
          openAgreementDialog(activeOffer.agreement?.boldsign_document_url);
        } else {
          openLockedContentDialog();
        }
      }
    }
  };
  const scrollToMain = () => refMain.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  const scrollToFiles = () => refFiles.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  const scrollToSenders = () => refSenders.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  const scrollToMap = () => refMap.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  const checkIsQwincyMember = () => {
    setAuthData({ open: true });
    /**
     * Временное решение
     */
    // (isQwincyMember || !hasRecipientSession) ? setAuthData({ open: true }) : openAccessBetaDialog();
  };
  // const handleFilesLoadMore = () => {
  //   setDocumentsFetching(true);
  //   getOfferPublicDocuments({ offer_id, offset: nextOffset })
  //   .catch(e => setError(e))
  //   .finally(() => setDocumentsFetching(false));
  // };
  const handleConversationClick = (user_ids) => {
    if (localSession.userableType === 'user') {
      const conversation = _find(conversations, ({ users }) => _isEqual(_map(_filter(users, u => u.id !== my_id), (user) => user.id).sort(), user_ids?.sort()));

      openOrCreateConversation(user_ids, conversation, isMyShared);
    } else {
      checkIsQwincyMember();
    }
  };
  
  useEffect(() => {
    if (localSession.userableType && profileFetched) {
      axios.defaults.baseURL = `${baseURL}/${localSession.userableType}`;
      axios.defaults.params = { ...axios.defaults.params, access_token: localSession.accessToken };
      axios.defaults.data = { ...axios.defaults.params, access_token: localSession.accessToken };

      getFeedOfferDetails(offer_id)
      .then(({ payload: { data: { offer: { root_folder, locked } } } }) => {
        track('Visit an Offering');
        visitOffer(offer_id).catch(e => setError(e));
        clearLocalSenders().then(() => getSenders({ offer_id, offset: 0 }).catch(e => setError(e)));
        clearLocalOfferImages().then(() => getOfferImages({ offer_id, offset: 0 }).catch(e => setError(e)));
        clearLocalOfferDocuments()
        .then(() => {
          getOfferPublicDocuments({ folder_id: root_folder.id, offset: 0 }).catch(e => setError(e));
          if (localSession.userableType === 'user') {
            getOfferPrivateLevel1Documents({ folder_id: root_folder.id, offset: 0 }).catch(e => setError(e));
            getOfferPrivateLevel2Documents({ folder_id: root_folder.id, offset: 0 }).catch(e => setError(e));
          }
        });
        clearLocalOfferFolders()
        .then(() => {
          getOfferPublicFolders({ folder_id: root_folder.id, offset: 0 }).catch(e => setError(e));
          if (localSession.userableType === 'user') {
            getOfferPrivateLevel1Folders({ folder_id: root_folder.id, offset: 0 }).catch(e => setError(e));
            getOfferPrivateLevel2Folders({ folder_id: root_folder.id, offset: 0 }).catch(e => setError(e));
          }
        });
        if (locked) clearLocalTemplates().then(() => getTemplates({ offer_id, offset: 0 }).catch(e => setError(e)));
      })
      .catch(e => setError(e))
    }

    return () => {
      clearLocalFeedOffer();
      clearLocalSenders();
      clearLocalOfferImages();
      clearLocalOfferDocuments();
      clearLocalOfferFolders();
      clearLocalTemplates();
    };
  }, [
    profileFetched, clearLocalOfferFolders, getFeedOfferDetails, getSenders, offer_id, setError, getOfferImages, getOfferPublicDocuments,
    getOfferPublicFolders, getOfferPrivateLevel1Documents, getOfferPrivateLevel2Documents, getOfferPrivateLevel1Folders, getOfferPrivateLevel2Folders,
    getTemplates, clearLocalFeedOffer, clearLocalOfferImages, clearLocalOfferDocuments, track, clearLocalSenders, clearLocalTemplates, visitOffer,
    localSession
  ]);
  useEffect(() => {
    if (showChatButton && localSession.userableType === 'user' && !state?.previousLocation) {
      getOfferConversations({ offset: 0, offer_id, feed: !isMyShared, offer: isMyShared })
      .catch(e => setError(e))
    }

    return clearLocalConversations;
  }, [localSession, showChatButton, state?.previousLocation, offer_id, isMyShared, getOfferConversations, clearLocalConversations, setError]);

  // TODO подумать тут над оптимальным решением
  useEffect(() => {
    if (isFetched) {
      scrollToMain();
      setOfferLocalFields({ id: activeOffer.id, detailsFetched: true });
    }
  }, [isFetched, activeOffer.id, setOfferLocalFields]);
  useEffect(() => {
    const allowVisit = isFetched && !isMyShared;
    const intervalId = setInterval(() => allowVisit && visitOffer(activeOffer.id).catch(e => setError(e)), 15000);
  
    return () => clearInterval(intervalId);
  }, [isFetched, activeOffer, isMyShared, visitOffer, setError]);

  if (generalFetching) return (
    <Stack sx={{ mt: '14px', px: '24px', gap: '24px', minHeight: 'calc(100vh - 50px - 14px - 14px)' }}>
      <MainSkeleton />
      <ImagesSkeleton />
      <DescriptionSkeleton />
    </Stack>
  );
  return (
    <Root>
      <Suspense fallback={<AgreementFallback />}>
        <Agreement />
      </Suspense>
      <Suspense fallback={<MainFallback />}>
        <Main
          ref={refMain}
          scrollToFiles={scrollToFiles}
          scrollToSenders={scrollToSenders}
          scrollToMap={scrollToMap}
          onUnlock={handleUnlockClick}
          {...mainProps}
        />
      </Suspense>
      {!!activeOffer.logo &&
        <Logo
          src={activeOffer.logo.url}
          alt='logo'
        />
      }
      <Stack sx={{ alignItems: 'center', '& > *:not(:first-of-type)': { borderTop: generalFetching ? '1px solid #fff' : '1px solid #e0e0e0' } }}>
        <Suspense fallback={<ImagesFallback />}>
          <Images />
        </Suspense>
        {!!activeOffer.description &&
          <Container
            maxWidth={false}
            sx={{ maxWidth: '624px', overflow: 'auto', px: '12px !important', pt: 0 }}
          >
            <Description>{Parser().parse(activeOffer.description)}</Description>
          </Container>
        }
        <Suspense fallback={<FilesFallback />}>
          <Files
            ref={refFiles}
            // onLoadMore={handleFilesLoadMore}
            onUnlock={handleUnlockClick}
            {...filesProps}
          />
        </Suspense>
      </Stack>
      <Suspense fallback={<SendersFallback />}>
        <Senders
          ref={refSenders}
          onConversation={handleConversationClick}
        />
      </Suspense>
      <ScrollButton onClick={scrollToMain}>
        <ScrollIcon /><span>Scroll to top</span>
      </ScrollButton>
      <Box
        ref={refMap}
        sx={{
          height: { xs: '92vh', md: '50vh', lg: '70vh' },
          width: '100%',
          ...showLocatedMap && { display: 'flex', justifyContent: 'center', alignItems: 'center' }
        }}
      >
        {showLocatedMap &&
          <LockedLocation onClick={handleUnlockClick}>
            <Locked style={{ width: 28, height: 28, color: '#fff' }} />
            <Typography variant='caption' sx={{ fontSize: '16px', color: 'white', textAlign: 'center' }}>
              Click to Unlock <br /> Precise Location
            </Typography>
          </LockedLocation>
        }
        <Suspense fallback={<DelayedRender delay={500}><MapFallback /></DelayedRender>}>
          <Map {...mapProps} />
        </Suspense>
      </Box>
    </Root>
  );
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <OfferDetails {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);