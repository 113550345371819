import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import _filter from 'lodash/filter';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import UploadIcon from 'components/Common/Icons/UploadIcon';
import Uploader from 'components/Blobs/Uploader/Uploader';
import SaveIcon from 'components/Common/Icons/SaveIcon';
import ListsAutocomplete from 'components/Lists/Autocomplete/Autocomplete';
import useApp from 'hooks/useApp';
import useContacts from 'hooks/useContacts';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useLists from 'hooks/useLists';

const UploadContacts = () => {
  const { closeUploadContactsDialog } = useApp();
  const { importContacts } = useContacts();
  const { setError } = useError();
  const { clearLocalLists } = useLists();
  const open = useCustomSelector(state => state.app.uploadContactsDialog.open);
  const [file, setFile] = useState({ value: null, formattedValue: null });
  const [lists, setLists] = useState([]);
  const [processing, setProcessing] = useState(false);
  const disabled = processing || !file.value;

  const handleTemplateClick = () => {
    fetch('contactsUploadTemplate.csv').then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');

        alink.href = fileURL;
        alink.target = '_blank';
        alink.download = 'Qwincy Contacts Template';
        alink.click();

        document.body.removeChild(alink);
      });
    });
  };
  const handleUploadClick = () => {
    const listAttributes = _filter(lists, l => !!l.inputValue);
    const listIds = _filter(lists, l => !!l.id && !!!l.inputValue);
    const data = {
      file: file.value,
      ...((!!listAttributes.length || !!listIds.length) && { contact: {
        ...(!!listAttributes.length && { lists_attributes: _map(listAttributes, la => ({ name: la.inputValue })) }),
        ...(!!listIds.length && { list_ids: _map(listIds, l => l.id) })
      }})
    };

    setProcessing(true);
    importContacts(data)
    .then(() => {
      !!listAttributes.length && clearLocalLists();
      closeUploadContactsDialog();
    })
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };
  const onExited = () => {
    setFile({ value: null, formattedValue: null });
    setLists([]);
  };

  return (
    <BaseDialog
      open={open}
      onClose={() => !processing && closeUploadContactsDialog()}
      scroll='body'
      otherPaperStyle={{ width: '100%', maxWidth: { xs: 'calc(100% - 64px)', md: 700} }}
      otherContentStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pb: 4, pt: '8px !important' }}
      TransitionProps={{ onExited }}
      title='Upload Contact List'
      content={
        <>
          <Box sx={{ px: 6, pb: 3 }}>
            <Typography variant='subtitle2' align='center' >
              Upload your contacts in.csv format using the following template.
            </Typography>
            <Typography variant='body2' align='center' color='error'>
              TIP: Keep header row in the file. If you have multiple lists, upload a file for each list.
            </Typography>
            <Typography variant='subtitle2' align='center' color='error'>
              First name, Last name and Email address are required.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }} onClick={handleTemplateClick}>
            <UploadIcon sx={{ height: 20, width: 'auto'}} />
            <Typography component={'span'} variant='subtitle2' sx={{ textDecoration: 'underline' }}>Download csv template</Typography>
          </Box>
          <Box sx={{ minWidth: 140, '& > *': {width: '100% !important'}, py: 4 }}>
            <Uploader
              id='contactsUploader'
              accept='.csv'
              label='Select file'
              onChange={({ newValue, newFormattedValue }) =>
                setFile(prev => ({
                  ...prev,
                  ...(!_isUndefined(newValue) && { value: newValue }),
                  ...(!_isUndefined(newFormattedValue) && { formattedValue: newFormattedValue })
                }))
              }
              onDelete={() => setFile({ value: null, formattedValue: null })}
            />
          </Box>
          <Box sx={{ maxWidth: 360, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px 8px', py: 1, px: 2 }}>
            {!!file.value &&
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: '12px', width: '100%' }}>
                <SaveIcon />
                <Typography variant='body2' noWrap>{file.formattedValue.filename}</Typography>
              </Box>
            }
            <ListsAutocomplete value={lists} onChange={setLists} />
          </Box>
        </>
      }
      actions={
        <>
          <Button
            disabled={processing}
            variant='text'
            color='error'
            onClick={closeUploadContactsDialog}
            sx={{ fontSize: '12px', py: '8px', px: '28px' }}
          >
            Cancel
          </Button>
          <Button
            disabled={disabled}
            variant='contained'
            color='primary'
            sx={{ fontSize: '12px', px: '28px' }}
            onClick={handleUploadClick}
          >
            Upload
          </Button>
        </>
      }
    />
  );
};

export default memo(UploadContacts);