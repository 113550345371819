import { memo } from 'react';
import Typography from '@mui/material/Typography';
import DocIcon from '@mui/icons-material/Article';
import _startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
// Local files
import { Root, Content, Type, Title, Image, DocPlaceholder } from './ContentCard.styled';
import { representationVariants } from 'helpers/constants';
import { getFormattedDate, getRepresentation } from 'helpers';

const ContentCard = ({ id, content_type, title, published_at, url = null, avatar = null }) => {
  // const handleViewContent = () => navigate(`/contents/${id}`, { state: { backgroundLocation } });
  const handleClick = () => {};

  return (
    <Root
      component={url ? 'a' : 'div'}
      href={url}
      target='_blank'
      rel="noopener noreferrer"
      onClick={handleClick}
    >
      {avatar ?
        <Image
          sx={{ backgroundImage: `url('${getRepresentation(avatar?.representations, representationVariants.SMALL) ?? avatar.localUrl}')` }}
        /> :
        <DocPlaceholder>
          <DocIcon />
        </DocPlaceholder>
      }
      <Content>
        <Type>{_startCase(content_type)}</Type>
        <Title>{title}</Title>
        <Typography variant='caption' color='text.primary' noWrap component='div'>{getFormattedDate(published_at)}</Typography>
      </Content>
    </Root>
  );
};

ContentCard.propTypes = {
  content_type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  published_at: PropTypes.string.isRequired,
  url: PropTypes.string,
  avatar: PropTypes.object
};

export default memo(ContentCard);