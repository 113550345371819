import { memo, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// Local files
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import useApp from 'hooks/useApp';
import useContacts from 'hooks/useContacts';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useInvitations from 'hooks/useInvitations';
import useSuccess from 'hooks/useSuccess';

const AfterAuth = () => {
  const navigate = useNavigate();
  const { clearAuthData, openLockedContentDialog } = useApp();
  const { createContact } = useContacts();
  const { authDialog, user, localSession } = useCustomSelector(state => ({
    authDialog: state.app.authDialog,
    user: state.profile.user,
    localSession: state.sessions.session
  }));
  const { setError } = useError();
  const { acceptInvitation } = useInvitations();
  const { setSuccess } = useSuccess();
  const audioRef = useRef(null);

  useEffect(() => {
    if (localSession.accessToken && localSession.userableType === 'user' && authDialog.open) {
      if (authDialog.invitationToken) {
        acceptInvitation({ invitation_token: authDialog.invitationToken })
        .then(() => setSuccess('Invite successfully accepted').then(clearAuthData))
        .catch(e => setError(e));
        return;
      }
      if (authDialog.redirectAction) {
        if (authDialog.redirectAction.type === 'connect_click') {
          if (user.id === authDialog.redirectAction.data.user.id) {
            navigate(`/users/${user.username}`)
            clearAuthData();
          } else {
            const contact = { invitation_attributes: { user_id: authDialog.redirectAction.data.user.id } };

            createContact({ contact })
            .then(() => {
              const subscribedUser = authDialog.redirectAction.data.user.first_name + ' ' + authDialog.redirectAction.data.user.last_name;

              audioRef.current.playConnectionRequestSound();
              setSuccess('Connection invitation sent');
              setSuccess(`You are now Subscribed to ${subscribedUser}`);
            })
            .finally(() => {
              navigate(`/users/${authDialog.redirectAction.data.user.username}`);
              clearAuthData();
            }); 
          }
        }
        if (authDialog.redirectAction.type === 'unlock_click') {
          navigate(`/deal_flow/${authDialog.redirectAction.data.offerId}`);
          openLockedContentDialog();
          clearAuthData();
        }
      } else {
        navigate(authDialog.redirectUrl);
        clearAuthData();
      }
    }
  }, [acceptInvitation, authDialog, clearAuthData, createContact, localSession, openLockedContentDialog, setError, setSuccess, user]); // eslint-disable-line

  return null;
}

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <AfterAuth {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);