import styled from '@mui/material/styles/styled';
import MuiTableRow from '@mui/material/TableRow';

const TableRow = styled(MuiTableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.section.table.hover,
    overflow: 'hidden'
  },
  '& > td': {
    borderColor: theme.palette.section.table.border,
    '&:first-of-type': {
      borderRadius: '8px 0 0 8px'
    },
    '&:last-of-type': {
      borderRadius: '0 8px 8px 0'
    }
  }
}));

export default TableRow;