import { memo } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import _map from 'lodash/map';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// Local files
import { Primary, Secondary } from './Breadcrumbs.styled';
import { ReactComponent as BackIcon } from 'assets/icons/files-back.svg';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';

const LinkRouter = props => {
  return <Link {...props} component={RouterLink} />;
};

const FoldersBreadcrumbs = ({ crumbs }) => {
  if (!crumbs.length) return <div />;
  return (
    <Box display='flex' alignItems='center' gap='15px'>
      <LinkRouter to={crumbs[0].path}>
        <BackIcon />
      </LinkRouter>
      <Breadcrumbs>
        {_map(crumbs, (f, i) => i === crumbs.length - 1 ?
          <LinkRouter key={i} to={f.path} underline='none'>
            <Primary>{f.label}</Primary>
          </LinkRouter> :
          <LinkRouter key={i} to={f.path} underline='none'>
            <Secondary>{f.label}</Secondary>
          </LinkRouter>
        )}
      </Breadcrumbs>
    </Box>
  );
};

FoldersBreadcrumbs.propTypes = {
  crumbs: PropTypes.array.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <FoldersBreadcrumbs {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);