import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import _startCase from 'lodash/startCase';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Root, ChipsContainer } from './FlowCard.styled';
import { ReactComponent as TargetOffIcon } from 'assets/icons/target.svg';
import { ReactComponent as TargetIcon } from 'assets/icons/target-colored.svg';
import { ReactComponent as DeadlineRedIcon } from 'assets/icons/offer-deadline.svg';
import { ReactComponent as DeadlineGrayIcon } from 'assets/icons/offer-deadline-gray.svg';
import { ReactComponent as SavedIcon } from 'assets/icons/offer-saved-colored.svg';
import { ReactComponent as PropertyIcon } from 'assets/icons/company.svg';
import MenuVert from 'components/Common/Icons/MenuVert';
import ArchiveIcon from 'components/Common/Icons/ArchiveIcon';
import BaseAvatar from 'components/Common/DataDisplay/BaseAvatar/BaseAvatar';
import Status from 'components/Offers/Status/Status';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { representationVariants } from 'helpers/constants';
import { getRepresentation } from 'helpers';
import useApp from 'hooks/useApp';
import useOffers from 'hooks/useOffers';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useCustomSelector from 'hooks/useCustomSelector';

/**
 * @param {string} id
 * @param {bool} tracked
 * @param {object=} saved_dealspace
 * @param {bool} visited
 * @param {string=} tag
 * @param {array=} asset_classes
 * @param {object=} main_location
 * @param {string} headline
 * @param {string=} shared_at
 * @param {object} primary_sender
 * @param {number} properties_count
 * @param {number} senders_count
 * @param {string=} deadline_at
 * @param {string} status
 * @param {bool} disabled
 * @param {bool} public
 * @param {bool} disinterested
 * @param {array=} risk_profiles
 * @param {bool} sent
 * @param {object=} sx
 * @returns {node}
 */

const Card = ({ 
  id, tracked, saved_dealspace, visited, tag, asset_classes, main_location, headline, shared_at, primary_sender, properties_count,
  senders_count, deadline_at, status, disabled, public: p, disinterested, risk_profiles, sent, sx = null
}) => {
  const navigate = useNavigate();
  const { offer_id } = useParams();
  const { /*openAccessBetaDialog,*/ setAuthData } = useApp();
  const { disinterestOffer } = useOffers();
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  //  Это костыль, чтобы в табе shared with me показывать все оферы как просмотренные изначально, сделан на скорую ибо нужно оч много менять логики на бэке.
  const { authorizedAsUser, hasRecipientSession } = useCustomSelector(state => ({
    authorizedAsUser: state.sessions.session.userableType === 'user',
    hasRecipientSession: !!state.profile.recipient.id,
    // isQwincyMember: !!state.profile.recipient.user?.email,
  }));
  const [anchorEl, setAnchorEl] = useState(null);
  const [processing, setProcessing] = useState(false);
  const selected = id === offer_id;
  const showTargetIcon = !sent;
  const showSavedIcon = !sent && !!saved_dealspace;
  const cardBackground = selected
    ? 'linear-gradient(109.11deg, #F0F6FF 18.48%, #E1ECFD 86.38%)'
    : (visited || sent) ? '#F1F3F6' : '#fff';
  const cardBorderColor = selected ? '#4786FF' : (visited || sent) ? '#F1F3F6' : '#fff';
  const deadline = !!deadline_at ? Math.round((new Date(deadline_at).getTime() - new Date().getTime()) / 86_400_000) : false;
  const showRedDeadline = !!deadline_at && deadline < 30 && deadline >= 0;
  const showGrayDeadline = !!deadline_at && deadline < 0;
  const allow = !disinterested && !sent;
  const showMenu = !p && allow;
  const showMainDate = !!shared_at;
  const showProperties = !!main_location;
  const shadowColor = selected ? '#E1ECFD' : (visited || sent ? '#F1F3F6' : '#fff');

  const handleMenuClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClick = () => {
    if (disabled || processing) return;
    if (offer_id !== id) {
      if (hasRecipientSession) {
        setAuthData({ redirectUrl: `/deal_flow/${id}` }).then(() => navigate(`/deal_flow/${id}`));
      } else {
        navigate(`/deal_flow/${id}`);
      }
    }
  };
  const handleArchiveClick = () => {
    setAnchorEl(null);
    disinterestOffer(id)
    .then(() => setSuccess('Offering successfully archived'))
    .catch(e => setError(e))
    .finally(() => setProcessing(false));
  };
  const checkIsQwincyMember = () => {
    setAuthData({ open: true });
    /**
     * Временное решение
     */
    // (isQwincyMember || !hasRecipientSession) ? setAuthData({ open: true }) : openAccessBetaDialog();
  };

  return (
    <>
      <Menu
        keepMounted
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        PaperProps={{ variant: 'popup', color: 'dark', sx: { mt: '2px', width: '120px', } }}
        disabled={processing}
      >
        <MenuItem
          disabled={disabled || processing}
          sx={{ px: '12px', '&:hover': { backgroundColor: 'background.hoverDark'} }}
          onClick={handleArchiveClick}
        >
          <ArchiveIcon />
          <Typography variant='caption' ml={1.5}>Archive</Typography>
        </MenuItem>
      </Menu>
      <Root onClick={handleClick} sx={{ background: cardBackground, borderColor: cardBorderColor, ...sx }}>
        <ChipsContainer>
          {showTargetIcon && (tracked ? <TargetIcon /> : <TargetOffIcon style={{ color: 'rgba(0,0,0,.3)' }} />)}
          <Status value={status} withoutText />
          <Box
            sx={{
              display: 'flex',
              width: '99%',
              gap: '4px',
              overflow: 'hidden',
              borderRadius: 16,
              position: 'relative'
            }}
          >
            {!!tag &&
              <Typography component='div'>
                <Chip label={tag} size='small' color='secondary' />
              </Typography>
            }
            {!!asset_classes?.length &&
              <Typography component='div'>
                <Chip label={_startCase(asset_classes[0])} size='small' color='tertiary' />
              </Typography>
            }
            {!!risk_profiles?.length &&
              <Typography component='div'>
                <Chip label={_startCase(risk_profiles[0])} size='small' color='tertiary' />
              </Typography>
            }
            <Box
              width={30}
              height={25}
              position='absolute'
              right='0%'
              sx={{ borderTopRightRadius: 16, borderBottomRightRadius: 16, background: `linear-gradient(to right, transparent, transparent 0%, ${shadowColor} 100%, #333 100%)` }}
            />
          </Box>
          <Box flex='1 1' />
          {showSavedIcon && <SavedIcon />}
          {showRedDeadline && <DeadlineRedIcon />}
          {showGrayDeadline && <DeadlineGrayIcon />}
          {showMenu &&
            <MenuVert
              sx={{ width: 15, height: 15, mr: '-6px' }}
              onClick={e => authorizedAsUser ? handleMenuClick(e) : checkIsQwincyMember()}
              disabled={processing}
            />
          }
        </ChipsContainer>
        <Box display='flex' mb={.5}>
          <Typography variant='caption' noWrap sx={{ flexGrow: 1, fontWeight: 500 }}>
            {headline}
          </Typography>
          {showMainDate &&
            <Typography variant='caption'>
              {new Date(shared_at).toLocaleString('en-US', { year: '2-digit', month: 'numeric', day: 'numeric' })}
            </Typography>
          }
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', justifyContent: 'space-between' }}>
          <BaseAvatar
            src={getRepresentation(primary_sender.user.photo?.representations, representationVariants.TINY)}
            firstName={primary_sender.user.first_name ?? ''}
            lastName={primary_sender.user.last_name ?? ''}
            sx={{ flexShrink: 0, width: '19px', height: '19px', fontSize: '10px' }}
          />
          <Typography variant='small' sx={{ flexShrink: 0 }}>
            {primary_sender.user.first_name} {primary_sender.user.last_name} {senders_count > 1 ? `+${senders_count - 1}` : ''}
          </Typography>
          <Box flexGrow={1} />
          {showProperties && (properties_count > 1 ?
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <PropertyIcon style={{ width: 12, height: 12, flexShrink: 0 }} />
              <Typography variant='small' color='textSecondary' noWrap sx={{ flexGrow: 1 }}>Properties {properties_count}</Typography>
            </Box> :
            <Typography variant='small' noWrap>
              {main_location.address ?? main_location.approximate_address}
            </Typography>
          )}
        </Box>
      </Root>
    </>
  );
};

Card.propTypes = {
  id: PropTypes.string.isRequired,
  tracked: PropTypes.bool.isRequired,
  saved_dealspace: PropTypes.object,
  visited: PropTypes.bool.isRequired,
  tag: PropTypes.string,
  asset_classes: PropTypes.array,
  main_location: PropTypes.shape({
    address: PropTypes.string,
    approximate_address: PropTypes.string
  }),
  headline: PropTypes.string.isRequired,
  shared_at: PropTypes.string,
  primary_sender: PropTypes.shape({
    user: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      photo: PropTypes.shape({
        representations: PropTypes.array
      })
    })
  }),
  properties_count: PropTypes.number.isRequired,
  senders_count: PropTypes.number.isRequired,
  deadline_at: PropTypes.string,
  status: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  public: PropTypes.bool.isRequired,
  disinterested: PropTypes.bool.isRequired,
  risk_profiles: PropTypes.array,
  sent: PropTypes.bool.isRequired,
  sx: PropTypes.object
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Card {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);