import { memo } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
// Local files
import { StyledStack } from 'views/Intake/Intake.styled';
import { FieldWithCounterContainer, CanvasContainer, TinyContainer, Paper } from './AddFiles.styled';
import BuilderErrorLabel from 'components/Common/DataDisplay/BuilderErrorLabel/BuilderErrorLabel';
import IntakeImages from 'components/Images/IntakeImages/IntakeImages';
import IntakeFiles from 'components/Documents/IntakeFiles/IntakeFiles.js';
import Tiny from 'components/Tiny/Tiny';
import SymbolCounter from 'components/Common/DataDisplay/SymbolCounter/SymbolCounter';
//import Canvas from 'components/Offers/Builder/Form/Canvas/Canvas';

const AddFiles = ({ step, headline, images, description, files, message, onChange, onSubmit }) => {
  const handleImagesChange = ({ i = null, v, reason }) => onChange({ images: { reason, v, i } });
  const handleFilesChange = ({ v, reason }) => onChange({ files: { reason, v } });
  
  if (step !== 'add_files') return null;
  return (
    <StyledStack>
      <FieldWithCounterContainer>
        <Box width='100%'>
          <TextField
            variant='standard'
            label='Offering Headline:'
            required
            fullWidth
            multiline
            inputProps={{ maxLength: 160 }}
            value={headline.value}
            onChange={({ target: { value } }) => onChange({ headline: { value, error: '' } })}
            error={!!headline.error}
          />
          {!!headline.error && <BuilderErrorLabel value={headline.error} />}
        </Box>
        <SymbolCounter max={160} current={headline.value.length} />
      </FieldWithCounterContainer>
      <CanvasContainer>
        <Typography variant='caption' color='textSecondary' component='div' sx={{ flexGrow: 1, paddingBottom: '19px' }}>
          Offering Canvas*
        </Typography>
        <Paper>
          <IntakeImages
            {...images}
            onChange={handleImagesChange}
          />
          <Box pl={0} pr={0} pt='22px' pb='22px'>
            <Tiny
              id='intakeDescription'
              value={description.value}
              onChange={({ v, c }) => onChange({ description: { value: v, error: '', charsCount: c } })}
              plugins={['lists', 'link', 'autolink', 'emoticons', 'charmap', 'formatpainter', 'table', 'advtable', 'autoresize', 'quickbars', 'wordcount', 'powerpaste']}
              toolbar={'bold italic underline forecolor fontfamily fontsize indent align hr bullist numlist link h2 h3 emoticons table formatpainter blockquote'}
              otherInitProps={{
                height: 300,
                max_width: 600,
                menubar: false,
                placeholder: 'Add Offering description',
                skin: 'borderless',
                paste_webkit_styles: 'all',
                elementpath: false,
                branding: false,
                object_resizing: true,
                image_title: true,
                file_picker_types: 'image media',
                content_style: 'body { margin: 0px; padding: 0px; } img { max-width: 100% } table { max-width: 100% } td { word-break: break-all }'
              }}
            />
            {!!description.error && <BuilderErrorLabel value={description.error} />}
          </Box>
          <IntakeFiles
            value={files}
            onChange={handleFilesChange}
          />
        </Paper>
      </CanvasContainer>
      <Typography variant='caption' color='textSecondary' component='div' sx={{ flexGrow: 1, mt: '55px', mb: '16px'}}>
        Add a Message
      </Typography>
      <TinyContainer>
        <Tiny
          id='intakeMessage'
          value={message.formattedValue}
          onChange={({ v, t }) => onChange({ message: { value: t, formattedValue: v } })}
          plugins={['lists', 'link', 'autolink', 'emoticons', 'charmap']}
          toolbar='bold italic underline forecolor indent align bullist link h2 h3 emoticons'
          otherInitProps={{
            height: 300,
            menubar: false,
            elementpath: false,
            paste_webkit_styles: 'all',
            branding: false,
            placeholder: 'Add a message to this Offering',
            object_resizing: false,
            skin: 'borderless'
          }}
        />
      </TinyContainer>
      <Button
        variant='gradient'
        sx={{ minWidth: 130, display: 'flex', m: '60px auto 140px' }}
        onClick={onSubmit}
      >
        Finalize
      </Button>
    </StyledStack>
  );
};

AddFiles.propTypes = {
  step: PropTypes.string.isRequired,
  headline: PropTypes.shape({
    value: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired
  }),
  images: PropTypes.shape({
    value: PropTypes.array.isRequired,
    error: PropTypes.string.isRequired
  }),
  description: PropTypes.shape({
    value: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    charsCount: PropTypes.number.isRequired
  }),
  files: PropTypes.array.isRequired,
  message: PropTypes.shape({
    value: PropTypes.string.isRequired,
    formattedValue: PropTypes.string.isRequired
  }),
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default memo(AddFiles);