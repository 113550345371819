import { memo } from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const CloseSnackbarAction = ({ id }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(id)}>
      <CloseIcon />
    </IconButton>
  );
};

const StyledSnackbarProvider = ({ children }) => {
  return (
    <SnackbarProvider 
      maxSnack={3} 
      autoHideDuration={4000}
      hideIconVariant
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      style={{ fontSize: '0.75rem' }}
      action={id => <CloseSnackbarAction id={id} />}
    >
      {children}
    </SnackbarProvider>
  );
};

export default memo(StyledSnackbarProvider);