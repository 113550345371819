import { memo, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import _map from 'lodash/map';
// Local files
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import useError from 'hooks/useError';
import useSuccess from 'hooks/useSuccess';
import useBatch from 'hooks/useBatch';
import useContacts from 'hooks/useContacts';

const Confirmation = ({ ids, open, onClose }) => {
  const { setError } = useError();
  const { setSuccess } = useSuccess();
  const { sendBatch } = useBatch();
  const { filterLocalContacts } = useContacts();
  const [disabled, setDisabled] = useState(false);

  const handleClose = clear => onClose(clear);
  const handleDeleteClick = () => {
    const requests = _map(ids, id => ({
      method: 'delete',
      url: `/contacts/${id}`,
      params: {}
    }));

    setDisabled(true);
    sendBatch(requests)
    .then(() => 
      filterLocalContacts(_map(ids, (id) => ({ id })))
      .then(() => setSuccess('Contacts successfully deleted').then(() => handleClose(true)))
    )
    .catch(e => setError(e))
    .finally(() => setDisabled(false));
  };

  return (
    <BaseDialog
      open={open}
      onClose={() => !disabled && handleClose()}
      otherPaperStyle={{ width: '100%', maxWidth: 430 }}
      title='Confirm the deletion'
      actions={
      <>
        <Button
          sx={{ px: '40px', minHeight: '37px' }}
          disabled={disabled}
          onClick={() => handleClose()}
        >
          No
        </Button>
        <Button
          sx={{ px: '40px' }}
          variant='contained'
          color='error'
          disabled={disabled}
          onClick={handleDeleteClick}
        >
          Yes
        </Button>
      </>
      }
    >
      <DialogContent>
        <Typography variant='body2' align='center' p='16px 0px 8px'>Are you sure you want to delete selected contacts?</Typography>
      </DialogContent>
    </BaseDialog>
  );
};

export default memo(Confirmation);