import { memo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import { v4 as uuidv4 } from 'uuid';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
// Local files
import { Actions, AddParcelButton, ContentContainer, ListHeading, MapContainer, StyledDialogContent } from './IntakeProperty.styled';
import CloseIcon from 'components/Common/Icons/CloseIcon';
import BaseDialog from 'components/Common/Feedback/BaseDialog/BaseDialog';
import GoogleAutocomplete from 'components/Common/Location/GoogleAutocomplete/GoogleAutocomplete';
import Map from 'components/Locations/Map/Map';
import ErrorFallback from 'components/Common/ErrorFallback/ErrorFallback';
import { checkEmptyString } from 'helpers';
import useMap from 'hooks/useMap';

const IntakeProperty = ({ open, property = null, onClose }) => {
  const mediaDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { selectPolygon } = useMap();
  const [id, setId] = useState('');
  const [name, setName] = useState({ value: '', error: '' });
  const [inputAddress, setInputAddress] = useState(''); 
  const [addresses, setAddresses] = useState({ value: [], error: '' });
  const [polygon, setPolygon] = useState(null);
  const [processing, setProcessing] = useState(false);
  const disabled = processing;

  const handleSaveClick = () => {
    let hasError = false;

    if (!checkEmptyString(name.value)) {
      setName(prev => ({ ...prev, error: 'Enter a property name' }));
      hasError = true;
    };
    if (!addresses.value.length) {
      setAddresses(prev => ({ ...prev, error: 'At least 1 parcel required to be added to save a property' }));
      hasError = true;
    }
    if (hasError) return;

    const property = {
      id,
      name: name.value,
      ...!!addresses.value.length && {
        locations_attributes: _map(addresses.value, a => ({
          address: a.value,
          latitude: a.latitude,
          longitude: a.longitude,
          ...a.parcel && { parcel: a.parcel }
        }))
      }
    };

    onClose(property);
  };
  const onEntering = () => {
    if (property) {
      setId(property.id);
      setName({ value: property.name, error: '' });
      setAddresses({
        value: _map(property.locations_attributes, la => ({
          value: la.address,
          formattedValue: la.address,
          latitude: la.latitude,
          longitude: la.longitude,
          ...la.parcel && { parcel: la.parcel }
        })),
        error: ''
      });
    } else {
      setId(uuidv4());
    }
  };
  const onExited = () => {
    setName({ value: '', error: '' });
    setAddresses({ value: [], error: '' });
    setProcessing(false);
  };
  const handleAddressSelect = data => {
    setAddresses(prev => ({ value: [...prev.value, data], error: '' }));
    setName({ value: data.value.substring(0, data.value.indexOf(',')) });
    setInputAddress('');
  };
  const handleAddParcel = () => {
    selectPolygon(polygon?.value)
    .then(() => {
      setAddresses(prev => ({ value: [...prev.value, polygon], error: '' }));
      setName({ value: polygon.value.substring(0, polygon.value.indexOf(',')) });
      setPolygon(null);
    });
  };

  return (
    <BaseDialog
      open={open}
      onClose={() => onClose()}
      otherPaperStyle={{ width: '100%', maxWidth: 1010 }}
      fullScreen={!mediaDesktop}
      title=''
      TransitionProps={{ onEntering, onExited }}
    >
      <StyledDialogContent>
        <MapContainer>
          <Map
            onAddressSelect={value => setAddresses({ value, error: '' })}
            onPolygonSelect={setPolygon}
            addresses={addresses.value}
            polygon={polygon}
          />
          <GoogleAutocomplete
            placeholder='Search an address'
            value={inputAddress}
            onChange={setInputAddress}
            onSelect={handleAddressSelect}
          />
          {!!polygon && <AddParcelButton disabled={!!!polygon} onClick={handleAddParcel}>Add Parcel</AddParcelButton>}
        </MapContainer>
        <ContentContainer>
          <TextField
            placeholder='Type a Property Name'
            variant='standard'
            fullWidth
            InputProps={{ disableUnderline: true }}
            value={name.value}
            error={!!name.error}
            helperText={name.error}
            onChange={({ target: { value } }) => setName({ value, error: '' })}
          />
          {!!addresses.value.length ? (
            <Box sx={{  width: '100%', overflow: 'auto' }}>
              <ListHeading>Property Parcels ({addresses.value.length})</ListHeading>
              <List disablePadding sx={{ minHeight: 200 }}>
                {_map(addresses.value, a =>
                  <ListItem
                    key={a.value}
                    disableGutters
                    sx={{ color: '#696969' }}
                    secondaryAction={
                      <Button
                        variant='blank'
                        color='black'
                        onClick={() => setAddresses(prev => ({ value: _filter(prev.value, p => p.value !== a.value), error: '' }))}
                      >
                        <CloseIcon />
                      </Button>
                    }
                  >
                    {a.formattedValue}
                  </ListItem>
                )}
              </List>
            </Box>
          ) : (
            <Box>
              <Typography sx={{ fontSize: 18, maxWidth: 380, fontWeight: 600, textAlign: 'center', mx: 'auto', pb: '16px' }}>
                Search address or click parcel <br/>
                directly from the map  
              </Typography>
              <Typography sx={{ fontSize: 12, maxWidth: 325, textAlign: 'center', mx: 'auto', paddingBottom: '50px' }}>
                <b>NOTE:</b> Multiple parcels may be added for a single property, however, if this is related to a portfolio or assemblage, multiple properties should be added.
              </Typography>
              {!!addresses.error &&
                <Typography fontWeight={400} color='#E30000' fontSize='0.75rem' lineHeight={1.66}>
                  {addresses.error}
                </Typography>
              }
            </Box>
          )}
          <Actions>
            <Button
              sx={{ px: '30px' }}
              color='error'
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              sx={{ px: '30px' }}
              variant='contained'
              color='primary'
              disabled={disabled}
              onClick={handleSaveClick}
            >
              Save Property
            </Button>
          </Actions>
        </ContentContainer>
      </StyledDialogContent>
    </BaseDialog>
  );
};

IntakeProperty.propTypes = {
  open: PropTypes.bool.isRequired,
  property: PropTypes.shape({
    name: PropTypes.string,
    locations_attributes: PropTypes.array
  }),
  onClose: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <IntakeProperty {...props} />
    </ErrorBoundary>
  );
};

export default memo(WrappedComponent);