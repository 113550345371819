import { memo, useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MuiAutocomplete from '@mui/material/Autocomplete';
import _concat from 'lodash/concat';
import _uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import Paper from "@mui/material/Paper";
// Local files
import { IconContainer, StyledButton } from './Autocomplete.styled';
import { ReactComponent as SharedListIcon } from 'assets/icons/shared-list-enabled.svg';
import { ReactComponent as ListSettingsIcon } from 'assets/icons/list-settings.svg';
import ErrorWrapper from 'components/Common/ErrorWrapper/ErrorWrapper';
import ListSettingsDialog from 'components/Dialogs/ListSettings/ListSettings';
import useApp from 'hooks/useApp';
import useCustomSelector from 'hooks/useCustomSelector';
import useError from 'hooks/useError';
import useLists from 'hooks/useLists';

const CustomPaper = ({ children, buttons, ...other }) => {
  return (
    <Paper {...other}>
      {buttons}
      {children}
    </Paper>
  );
}

const Autocomplete = ({ label = 'Contact lists', size = 'medium', value, onChange }) => {
  const { openCreateListDialog } = useApp();
  const { getMyLists, clearLocalMyLists } = useLists();
  const { setError } = useError();
  const { options, needToLoadMore, newOffset } = useCustomSelector(state => ({
    options: state.lists.my.data,
    needToLoadMore: state.lists.my.pagination.offset + state.lists.my.pagination.count < state.lists.my.pagination.total_count && !state.lists.my.loading,
    newOffset: state.lists.my.pagination.limit + state.lists.my.pagination.offset
  }));
  const [query, setQuery] = useState('');
  const [settingsDialog, setSettingsDialog] = useState({ open: false });

  const handleDialogClose = () => setSettingsDialog({ open: false });
  const handleChange = (_, newValue) => {
    if (typeof newValue === 'string') {
      onChange([...value, { name: newValue }]);
    } else if (Array.isArray(newValue) && newValue.length > value.length) {
      onChange(_uniqBy(_concat(value, newValue), 'id'));
      // setOptions(_uniqBy(_concat(options, newValue), 'id'));
    } else {
      onChange(newValue);
    }
  };
  const getOptionLabel = option => {
    if (option.type) return '';
    if (typeof option === 'string') return option;
    if (option.inputValue) return option.inputValue;
    return option.name;
  };
  const isOptionEqualToValue = (o, v) => o.id === v.id;
  const fetchLists = useCallback(({offset, query}) => {
    getMyLists({ offset, query, not_def: true }).catch(e => setError(e));
  }, [getMyLists, setError]);
  const handleScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    if (scrollTop + clientHeight + 65 >= scrollHeight && needToLoadMore) {
      fetchLists({offset: newOffset, query});
    }
  };
  const renderOption = (props, option) => {
    return (
      <Box {...props}>
        <SharedListIcon style={{ visibility: !!option.groups.length ? 'visible' : 'hidden' }} />
        <Typography fontWeight={400} sx={{ marginLeft: '.6rem' }}>
          {option.name}
        </Typography>
      </Box>
    );
  };
  
  const buttons = (
    <Box>
      <StyledButton 
        color='primary' 
        onClick={() => openCreateListDialog()} 
        sx={{ width: '100%', color: '#295CDF'}}
        onMouseDown={(event) => event.preventDefault()}
      >
        <Typography color='#295CDF' fontWeight={500}>+ Add List</Typography>
      </StyledButton>
      <StyledButton 
        color='primary' 
        onClick={() => setSettingsDialog({ open: true })} 
        onMouseDown={(event) => event.preventDefault()}
      >
        <IconContainer><ListSettingsIcon /></IconContainer>
        <Typography color='#295CDF' fontWeight={500}>Manage Lists</Typography>
      </StyledButton>
    </Box>
  );
  useEffect(() => {
    let timeout = setTimeout(() => clearLocalMyLists().then(() => fetchLists({ offset: 0, query })), !!query ? 500 : 0);

    return () => {
      clearTimeout(timeout);
    }
  }, [fetchLists, clearLocalMyLists, query]);

  return (
    <>
      <ListSettingsDialog {...settingsDialog} onClose={handleDialogClose} />
      <MuiAutocomplete
        size={size}
        multiple
        value={value}
        inputValue={query}
        onInputChange={(_, value) => setQuery(value)}
        onChange={handleChange}
        id="contactsLists"
        options={options}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={renderOption}
        ListboxProps={{ onScroll: handleScroll }}
        PaperComponent={CustomPaper}
        componentsProps={{ paper: { buttons: buttons } }}
        fullWidth
        freeSolo
        disableClearable
        renderInput={params => <TextField {...params} label={label} />}
      />
    </>
  );
};

Autocomplete.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

const WrappedComponent = props => {
  return (
    <ErrorWrapper>
      <Autocomplete {...props} />
    </ErrorWrapper>
  );
};

export default memo(WrappedComponent);